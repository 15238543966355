{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.FlexControls;

interface

uses
  Classes, JS, WEBLib.Controls, WEBLib.StdCtrls, WEBLib.Graphics, Web, WEBLib.WebTools,
  WEBLib.Dialogs;

type
  TStretchPanel = class(TCustomControl)
  private
    FStretchContent: TJSElement;
    FFixedContent: TJSElement;
    FFixedHeight: integer;
    procedure SetFixedHeight(const Value: integer);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderColor;
    property BorderStyle;
    property ChildOrder;
    property Color;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property FixedHeight: integer read FFixedHeight write SetFixedHeight;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property WidthPercent;
    property WidthStyle;
    property Visible;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
  end;

  TWebStretchPanel = class(TStretchPanel);

implementation

uses
  SysUtils;

{ TStretchPanel }

function TStretchPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');

  FStretchContent := document.createElement('DIV');
  FFixedContent := document.createElement('DIV');

  Result.appendChild(FStretchContent);
  Result.appendChild(FFixedContent);
end;

procedure TStretchPanel.CreateInitialize;
begin
  inherited;
  FFixedHeight := 100;
  BorderStyle := bsSingle;
  Color := clWindow;
  CustomBorder := true;
  Width := 400;
  Height := 300;
end;

procedure TStretchPanel.SetFixedHeight(const Value: integer);
begin
  FFixedHeight := Value;
end;

procedure TStretchPanel.UpdateElement;
var
  i: integer;
  fragment: TJSDocumentFragment;
  ctrlid: string;
  deltay: integer;
  el: TJSElement;
begin
  inherited;

  if Assigned(ElementHandle) and not IsUpdating and not (csLoading in ComponentState) then
  begin
    if not Assigned(FFixedContent) then
    begin
      FStretchContent := document.createElement('DIV');
      FFixedContent := document.createElement('DIV');

      ElementHandle.appendChild(FStretchContent);
      ElementHandle.appendChild(FFixedContent);
    end;

    TJSHTMLElement(FFixedContent).style.setProperty('position','relative');

    if not (csDesigning in ComponentState) then
      TJSHTMLElement(FFixedContent).style.setProperty('height',IntToStr(FixedHeight)+'px')
    else
      TJSHTMLElement(FFixedContent).style.setProperty('height',IntToStr(Height)+'px');

    ElementHandle.style.setProperty('height','');

    // do not block selection in child controls
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');

    if WidthStyle = ssAbsolute then
      TJSHTMLElement(FStretchContent).style.setProperty('width',IntToStr(Width -8)+'px');

    deltay := Height - FixedHeight;

    for i := 0 to ControlCount - 1 do
    begin
      fragment := document.createDocumentFragment();
      ctrlid := Controls[i].GetID;

      if Controls[i].Top < Height - FixedHeight then
      begin
        el := document.getElementById(ctrlid);
        if Assigned(el) then
        begin
          fragment.appendChild(el);
          FStretchContent.appendChild(fragment);
          Controls[i].ElementHandle.style.setProperty('position','relative');
        end;
      end
      else
      begin
        Controls[i].Top := Controls[i].Top - deltay;
        Controls[i].ElementHandle.style.setProperty('top',IntToStr(Controls[i].Top - deltay)+'px');
        el := document.getElementById(ctrlid);
        if Assigned(el) then
        begin
          fragment.appendChild(el);
          FFixedContent.appendChild(fragment);
        end;
      end;
    end;
  end;
end;

end.
