unit unit_BelegeUndPositionen;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.DB;

type
  TBelegePositionen = class(TForm)
    xdsBelege: TXDataWebDataSet;
    xdsPositionen: TXDataWebDataSet;
    sourceBelege: TDataSource;
    TC: TTableControl;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    btnzurueck: TButton;
    comboBelegart: TComboBox;
    btnAnzeigen: TButton;
    edtsuchbegriff: TEdit;
    btnSuchePos: TButton;
    lblKunde: TLabel;
    Container3: THTMLContainer;
    container4: THTMLContainer;
    Container5: THTMLContainer;
    lbKunde: TLabel;
    procedure WebFormShow(Sender: TObject);
    procedure btnAnzeigenClick(Sender: TObject);
    procedure xdsBelegeAfterOpen(DataSet: TDataSet);
    procedure btnSuchePosClick(Sender: TObject);
    procedure xdsPositionenAfterOpen(DataSet: TDataSet);
    procedure TCClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure btnzurueckClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    BPKundennummer:string;
    BPName:string;
    BP:boolean;
  end;

var
  BelegePositionen: TBelegePositionen;

implementation
  uses unit1, unit2, unit_EinzelBeleg;

procedure TBelegePositionen.btnAnzeigenClick(Sender: TObject);
begin
  decodedate(date,ajahr,amonat,atag);
  ajahr:=ajahr-2;
  xdspositionen.Close;
  xdsbelege.Close;
  if comboBelegart.Text='Angebote' then xdsbelege.EntitySetName:='Angebote';
  if comboBelegart.Text='Aufträge' then xdsbelege.EntitySetName:='Auftrag';
  if comboBelegart.Text='Lieferscheine' then xdsbelege.EntitySetName:='Lieferschein';
  if comboBelegart.Text='Rechnungen' then xdsbelege.EntitySetName:='Rechnung';
  if comboBelegart.Text='Anfragen' then xdsbelege.EntitySetName:='Anfrage';
  if comboBelegart.Text='Bestellungen' then xdsbelege.EntitySetName:='Bestellung';
  if comboBelegart.Text='Wareneingänge' then xdsbelege.EntitySetName:='Wareneingang';
  if comboBelegart.Text='EingangsRechnungen' then xdsbelege.EntitySetName:='EingangsRE';
  if bp then
  begin
    xdsbelege.QueryString:='$filter=Kundennummer eq '+QuotedStr(BPKundennummer)+'&$orderby=Nummerkomplett DESC';
  end
  else
  begin
    xdsbelege.QueryString:='$expand=Kundennummer&$filter=Jahr gt '+inttostr(ajahr)+'&$orderby=Nummerkomplett DESC';
  end;
  xdsbelege.Load;
end;

procedure TBelegePositionen.btnSuchePosClick(Sender: TObject);
var
  mysuchbegriff:string;
begin
  mysuchbegriff:=trim(edtsuchbegriff.Text);
  xdspositionen.Close;
  xdsbelege.Close;
  if comboBelegart.Text='Angebote' then xdsPositionen.EntitySetName:='PositionenAngebote';
  if comboBelegart.Text='Aufträge' then xdsPositionen.EntitySetName:='PositionenAuftrag';
  if comboBelegart.Text='Lieferscheine' then xdsPositionen.EntitySetName:='PositionenLieferschein';
  if comboBelegart.Text='Rechnungen' then xdsPositionen.EntitySetName:='PositionenRechnung';
  if comboBelegart.Text='Anfragen' then xdsPositionen.EntitySetName:='PositionenAnfrage';
  if comboBelegart.Text='Bestellungen' then xdsPositionen.EntitySetName:='PositionenBestellung';
  if comboBelegart.Text='Wareneingänge' then xdsPositionen.EntitySetName:='PositionenWareneingang';
  if comboBelegart.Text='EingangsRechnungen' then xdsPositionen.EntitySetName:='PositionenEingangsRE';

  if bp then
  begin
    xdsPositionen.QueryTop:=0;
    if mysuchbegriff='' then
    begin
      xdsPositionen.QueryString:='$expand=Nummerkomplett,Artikelnummer&$filter=Nummerkomplett/Kundennummer eq '+QuotedStr(BPKundennummer)+'&$orderby=Nummerkomplett/Nummerkomplett DESC';
    end
    else
    begin
      xdsPositionen.QueryString:='$expand=Nummerkomplett,Artikelnummer&$filter=((Nummerkomplett/Kundennummer eq '+QuotedStr(BPKundennummer)+')';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+' AND ((contains(Artikelbeschreibung,'+quotedstr(mysuchbegriff)+'))';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+' OR (contains(Artikelueberschrift,'+quotedstr(mysuchbegriff)+'))))';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+'&$orderby=Nummerkomplett/Nummerkomplett DESC';
    end;
  end
  else
  begin
    xdsPositionen.QueryTop:=100;
    if mysuchbegriff='' then
    begin
      xdsPositionen.QueryString:='$expand=Nummerkomplett,Artikelnummer&$orderby=Nummerkomplett/Nummerkomplett DESC';
    end
    else
    begin
      xdsPositionen.QueryString:='$expand=Nummerkomplett,Artikelnummer&$filter=(';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+' ((contains(Artikelbeschreibung,'+quotedstr(mysuchbegriff)+'))';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+' OR (contains(Artikelueberschrift,'+quotedstr(mysuchbegriff)+'))))';
      xdsPositionen.QueryString:=xdsPositionen.QueryString+'&$orderby=Nummerkomplett/Nummerkomplett DESC';
    end;
  end;
  xdspositionen.Load;
end;

procedure TBelegePositionen.btnzurueckClick(Sender: TObject);
begin
  xdspositionen.Close;
  xdsbelege.Close;
  self.Close;
end;

procedure TBelegePositionen.TCClickCell(Sender: TObject; ACol, ARow: Integer);
var
  EinzelBeleg:TEinzelBeleg;
    procedure AfterCreate(AForm:TObject);
    begin
      (AForm as TEinzelBeleg).Belegnummer:=tc.Cells[0,arow];
      (AForm as TEinzelBeleg).Belegart:=comboBelegart.text;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //nothing to do
    end;

  begin
  EinzelBeleg:=TEinzelBeleg.CreateNew(@AfterCreate);
  EinzelBeleg.ShowModal(@AfterShowmodal);
end;

procedure TBelegePositionen.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Belege/Positionen</h5></nav>';
  myelement:=container1.ElementHandle;
  btnzurueck.ParentElement:=myelement;

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;

  container3.parentelement:=myelement;
  container4.parentelement:=myelement;
  container5.parentelement:=myelement;
  myelement:=container3.ElementHandle;
  combobelegart.ParentElement:=myelement;
  btnanzeigen.ParentElement:=myelement;

  myelement:=container4.ElementHandle;
  edtsuchbegriff.ParentElement:=myelement;
  btnSuchePos.ParentElement:=myelement;

  myelement:=container5.ElementHandle;
  lbKunde.ParentElement:=myelement;
  lblkunde.ParentElement:=myelement;
  if bpKundennummer<>'' then
  begin
    bp:=true;
    lblkunde.Caption:=BPKundennummer+' '+BPName;
  end
  else
  begin
    bp:=false;
    lblkunde.Caption:='ALLE';
  end;
  btnAnzeigenClick(sender);
end;

procedure TBelegePositionen.xdsBelegeAfterOpen(DataSet: TDataSet);
var
  i:integer;
  o:integer;
begin
  if bp then o:=0 else o:=2;

  if comboBelegart.Text='Angebote' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=5+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Auftrag erhalten';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      if xdsbelege.FieldByName('Auftragerhaltenam').AsFloat>0 then
      begin
        TC.Cells[4+o,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Auftragerhaltenam').asdatetime);
      end
      else
      begin
        if xdsbelege.FieldByName('Auftragnichterhalten').asboolean then
        begin
          TC.Cells[4+o,i]:='nicht erhalten';
        end
        else
        begin
          TC.Cells[4+o,i]:='noch nicht entschieden';
        end;
      end;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Aufträge' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=6+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Status';
    tc.Cells[5+o,0]:='Lieferschein';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      TC.Cells[4+o,i]:=xdsbelege.fieldbyname('AuftragStatus').asstring;
      TC.Cells[5+o,i]:=xdsbelege.fieldbyname('ReferenzLieferschein').asstring;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Lieferscheine' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=6+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Fakturiert';
    tc.Cells[5+o,0]:='Rechnung';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      TC.Cells[4+o,i]:=xdsbelege.fieldbyname('VollstaendigFakturiert').asstring;
      TC.Cells[5+o,i]:=xdsbelege.fieldbyname('ReferenzRechnung').asstring;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Rechnungen' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=6+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Restbetrag(brutto)';
    tc.Cells[5+o,0]:='Belegvariante';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      TC.Cells[4+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Restbetrag').asfloat);
      TC.Cells[5+o,i]:=xdsbelege.fieldbyname('Belegvariante').asstring;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Anfragen' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=5+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Angebot erhalten';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      if xdsbelege.fieldbyname('Angeboterhaltenam').asfloat>0 then TC.Cells[4+o,i]:=xdsbelege.fieldbyname('Angeboterhaltenam').asstring;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Bestellungen' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=5+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='geliefert';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      if xdsbelege.fieldbyname('vollstaendiggeliefert').asboolean=true then TC.Cells[4+o,i]:='JA' else TC.Cells[4+o,i]:='NEIN';
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='Wareneingänge' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=5+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='abgerechnet';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      TC.Cells[4+o,i]:=xdsbelege.fieldbyname('vollstaendigabgerechnet').asstring;
      xdsbelege.Next;
    end;
  end;

  if comboBelegart.Text='EingangsRechnungen' then
  begin
    xdsBelege.First;
    tc.RowCount:=xdsbelege.RecordCount+1;
    tc.ColCount:=6+o;
    tc.Cells[0,0]:='Belegnummer';
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='K-Nr';
      tc.Cells[3,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Auftragsbezeichnung';
    tc.Cells[3+o,0]:='Nettosumme';
    tc.Cells[4+o,0]:='Restbetrag(brutto)';
    tc.Cells[5+o,0]:='Belegvariante';
    while not xdsbelege.eof do
    begin
      i:=xdsbelege.RecNo;
      TC.Cells[0,i]:=xdsbelege.fieldbyname('Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsbelege.fieldbyname('Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdsbelege.fieldbyname('Kundennummer.Kundennummer').asstring;
        tc.Cells[3,i]:=xdsbelege.fieldbyname('Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsbelege.fieldbyname('Auftragsbezeichnung').asstring;
      TC.Cells[3+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Nettosumme').asfloat);
      TC.Cells[4+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsbelege.fieldbyname('Restbetrag').asfloat);
      TC.Cells[5+o,i]:=xdsbelege.fieldbyname('Belegvariante').asstring;
      xdsbelege.Next;
    end;
  end;
end;

procedure TBelegePositionen.xdsPositionenAfterOpen(DataSet: TDataSet);
var
  i:integer;
  einkauf:boolean;
  o:integer;
begin
    if bp then o:=0 else o:=1;
    xdsPositionen.First;
    tc.RowCount:=xdsPositionen.RecordCount+1;
    tc.ColCount:=7+o;
    einkauf:=false;
    if comboBelegart.Text='Angebote' then tc.Cells[0,0]:='Angebot';
    if comboBelegart.Text='Aufträge' then tc.Cells[0,0]:='Auftrag';
    if comboBelegart.Text='Lieferscheine' then tc.Cells[0,0]:='Lieferschein';
    if comboBelegart.Text='Rechnungen' then tc.Cells[0,0]:='Rechnung';
    if comboBelegart.Text='Anfragen' then tc.Cells[0,0]:='Anfrage';
    if comboBelegart.Text='Bestellungen' then tc.Cells[0,0]:='Bestellung';
    if comboBelegart.Text='Wareneingänge' then tc.Cells[0,0]:='Wareneingang';
    if comboBelegart.Text='EingangsRechnungen' then tc.Cells[0,0]:='EingangsRechnung';
    if comboBelegart.Text='Anfragen' then einkauf:=true;
    if comboBelegart.Text='Bestellungen' then einkauf:=true;
    if comboBelegart.Text='Wareneingänge' then einkauf:=true;
    if comboBelegart.Text='EingangsRechnungen' then einkauf:=true;
    tc.Cells[1,0]:='Datum';
    if not bp then
    begin
      tc.Cells[2,0]:='Nachname';
    end;
    tc.Cells[2+o,0]:='Artikelnr.';
    tc.Cells[3+o,0]:='Artikelüberschrift';
    tc.Cells[4+o,0]:='Beschreibung';
    tc.Cells[5+o,0]:='Menge';
    tc.Cells[6+o,0]:='Einzel-VKP';
    if einkauf then tc.Cells[6+o,0]:='Einzel-EKP';

    while not xdsPositionen.eof do
    begin
      i:=xdsPositionen.RecNo;
      TC.Cells[0,i]:=xdsPositionen.fieldbyname('Nummerkomplett.Nummerkomplett').asstring;
      TC.Cells[1,i]:=formatdatetime('DD.MM.YYYY',xdsPositionen.fieldbyname('Nummerkomplett.Datum').asdatetime);
      if not bp then
      begin
        tc.Cells[2,i]:=xdspositionen.fieldbyname('Nummerkomplett.Nachname').asstring;
      end;
      TC.Cells[2+o,i]:=xdsPositionen.fieldbyname('Artikelnummer.Artikelnummer').asstring;
      TC.Cells[3+o,i]:=xdsPositionen.fieldbyname('Artikelueberschrift').asstring;
      TC.Cells[4+o,i]:=xdsPositionen.fieldbyname('Artikelbeschreibung').asstring;
      TC.Cells[5+o,i]:='<p class="text-right">'+xdsPositionen.fieldbyname('Menge').asstring;
      if not einkauf then TC.Cells[6+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Verkaufspreis').asfloat);
      if einkauf then TC.Cells[6+o,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Einkaufspreis').asfloat);

      xdsPositionen.Next;
    end;

end;

procedure TBelegePositionen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblKunde := TLabel.Create(Self);
  lbKunde := TLabel.Create(Self);
  TC := TTableControl.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  comboBelegart := TComboBox.Create(Self);
  btnAnzeigen := TButton.Create(Self);
  edtsuchbegriff := TEdit.Create(Self);
  btnSuchePos := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  container4 := THTMLContainer.Create(Self);
  Container5 := THTMLContainer.Create(Self);
  xdsBelege := TXDataWebDataSet.Create(Self);
  xdsPositionen := TXDataWebDataSet.Create(Self);
  sourceBelege := TDataSource.Create(Self);

  lblKunde.BeforeLoadDFMValues;
  lbKunde.BeforeLoadDFMValues;
  TC.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  comboBelegart.BeforeLoadDFMValues;
  btnAnzeigen.BeforeLoadDFMValues;
  edtsuchbegriff.BeforeLoadDFMValues;
  btnSuchePos.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  container4.BeforeLoadDFMValues;
  Container5.BeforeLoadDFMValues;
  xdsBelege.BeforeLoadDFMValues;
  xdsPositionen.BeforeLoadDFMValues;
  sourceBelege.BeforeLoadDFMValues;
  try
    Name := 'BelegePositionen';
    Width := 255;
    Height := 442;
    Caption := 'Belege und Positionen';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblKunde.SetParentComponent(Self);
    lblKunde.Name := 'lblKunde';
    lblKunde.Left := 181;
    lblKunde.Top := 187;
    lblKunde.Width := 40;
    lblKunde.Height := 13;
    lblKunde.Caption := 'lblKunde';
    lblKunde.ChildOrder := 9;
    lblKunde.ElementClassName := 'h5 text-info m-2';
    lblKunde.ElementFont := efCSS;
    lblKunde.HeightStyle := ssAuto;
    lblKunde.WidthStyle := ssAuto;
    lbKunde.SetParentComponent(Self);
    lbKunde.Name := 'lbKunde';
    lbKunde.Left := 189;
    lbKunde.Top := 195;
    lbKunde.Width := 58;
    lbKunde.Height := 13;
    lbKunde.Caption := 'Kontakt(e): ';
    lbKunde.ChildOrder := 9;
    lbKunde.ElementClassName := 'h5 text-info m-2';
    lbKunde.ElementFont := efCSS;
    lbKunde.HeightStyle := ssAuto;
    lbKunde.WidthStyle := ssAuto;
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 256;
    TC.Width := 247;
    TC.Height := 186;
    TC.ElementClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.HeightStyle := ssAuto;
    TC.WidthStyle := ssPercent;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementPosition := epRelative;
    TC.ElementTableClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    SetEvent(TC, Self, 'OnClickCell', 'TCClickCell');
    TC.ColCount := 2;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 0;
    btnzurueck.Top := 156;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    comboBelegart.SetParentComponent(Self);
    comboBelegart.Name := 'comboBelegart';
    comboBelegart.Left := 0;
    comboBelegart.Top := 187;
    comboBelegart.Width := 175;
    comboBelegart.Height := 21;
    comboBelegart.ChildOrder := 5;
    comboBelegart.ElementClassName := 'form-control';
    comboBelegart.ElementFont := efCSS;
    comboBelegart.HeightStyle := ssAuto;
    comboBelegart.Text := 'Angebote';
    comboBelegart.ItemIndex := 0;
    comboBelegart.Items.BeginUpdate;
    try
      comboBelegart.Items.Clear;
      comboBelegart.Items.Add('Angebote');
      comboBelegart.Items.Add('Auftr'#228'ge');
      comboBelegart.Items.Add('Lieferscheine');
      comboBelegart.Items.Add('Rechnungen');
      comboBelegart.Items.Add('Anfragen');
      comboBelegart.Items.Add('Bestellungen');
      comboBelegart.Items.Add('Wareneing'#228'nge');
      comboBelegart.Items.Add('EingangsRechnungen');
    finally
      comboBelegart.Items.EndUpdate;
    end;
    btnAnzeigen.SetParentComponent(Self);
    btnAnzeigen.Name := 'btnAnzeigen';
    btnAnzeigen.Left := 0;
    btnAnzeigen.Top := 214;
    btnAnzeigen.Width := 96;
    btnAnzeigen.Height := 25;
    btnAnzeigen.Caption := 'Anzeigen';
    btnAnzeigen.ChildOrder := 6;
    btnAnzeigen.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnAnzeigen.ElementFont := efCSS;
    btnAnzeigen.HeightStyle := ssAuto;
    btnAnzeigen.WidthStyle := ssAuto;
    SetEvent(btnAnzeigen, Self, 'OnClick', 'btnAnzeigenClick');
    edtsuchbegriff.SetParentComponent(Self);
    edtsuchbegriff.Name := 'edtsuchbegriff';
    edtsuchbegriff.Left := 102;
    edtsuchbegriff.Top := 162;
    edtsuchbegriff.Width := 121;
    edtsuchbegriff.Height := 19;
    edtsuchbegriff.ChildOrder := 7;
    edtsuchbegriff.ElementClassName := 'form-control input-sm';
    edtsuchbegriff.ElementFont := efCSS;
    edtsuchbegriff.HeightStyle := ssAuto;
    edtsuchbegriff.TextHint := 'Suchbegriff';
    btnSuchePos.SetParentComponent(Self);
    btnSuchePos.Name := 'btnSuchePos';
    btnSuchePos.Left := 102;
    btnSuchePos.Top := 214;
    btnSuchePos.Width := 170;
    btnSuchePos.Height := 25;
    btnSuchePos.Caption := 'in Positionen suchen';
    btnSuchePos.ChildOrder := 8;
    btnSuchePos.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnSuchePos.ElementFont := efCSS;
    btnSuchePos.HeightStyle := ssAuto;
    btnSuchePos.WidthStyle := ssAuto;
    SetEvent(btnSuchePos, Self, 'OnClick', 'btnSuchePosClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    container4.SetParentComponent(Self);
    container4.Name := 'container4';
    container4.Left := 8;
    container4.Top := 102;
    container4.Width := 169;
    container4.Height := 41;
    container4.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    container4.HeightStyle := ssAuto;
    container4.WidthStyle := ssAuto;
    container4.ChildOrder := 2;
    container4.ElementFont := efCSS;
    container4.ElementPosition := epRelative;
    Container5.SetParentComponent(Self);
    Container5.Name := 'Container5';
    Container5.Left := 16;
    Container5.Top := 110;
    Container5.Width := 169;
    Container5.Height := 41;
    Container5.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container5.HeightStyle := ssAuto;
    Container5.WidthStyle := ssAuto;
    Container5.ChildOrder := 2;
    Container5.ElementFont := efCSS;
    Container5.ElementPosition := epRelative;
    xdsBelege.SetParentComponent(Self);
    xdsBelege.Name := 'xdsBelege';
    xdsBelege.AfterOpen := xdsBelegeAfterOpen;
    xdsBelege.Connection := DM2.XDataWebConnection1;
    xdsBelege.SubpropsDepth := 1;
    xdsBelege.Left := 72;
    xdsBelege.Top := 8;
    xdsPositionen.SetParentComponent(Self);
    xdsPositionen.Name := 'xdsPositionen';
    xdsPositionen.AfterOpen := xdsPositionenAfterOpen;
    xdsPositionen.Connection := DM2.XDataWebConnection1;
    xdsPositionen.SubpropsDepth := 1;
    xdsPositionen.Left := 24;
    xdsPositionen.Top := 64;
    sourceBelege.SetParentComponent(Self);
    sourceBelege.Name := 'sourceBelege';
    sourceBelege.DataSet := xdsBelege;
    sourceBelege.Left := 24;
    sourceBelege.Top := 8;
  finally
    lblKunde.AfterLoadDFMValues;
    lbKunde.AfterLoadDFMValues;
    TC.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    comboBelegart.AfterLoadDFMValues;
    btnAnzeigen.AfterLoadDFMValues;
    edtsuchbegriff.AfterLoadDFMValues;
    btnSuchePos.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    container4.AfterLoadDFMValues;
    Container5.AfterLoadDFMValues;
    xdsBelege.AfterLoadDFMValues;
    xdsPositionen.AfterLoadDFMValues;
    sourceBelege.AfterLoadDFMValues;
  end;
end;

end.