unit unit_EinzelKontakt;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.Buttons, WEBLib.DBCtrls, WEBLib.ExtCtrls, DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Grids, strutils,
  WEBLib.ComCtrls, XData.Web.Client;

type
  TEinzelKontakt = class(TForm)
    xdsEinzelkontakt: TXDataWebDataSet;
    xdsEinzelkontaktKundennummer: TStringField;
    xdsEinzelkontaktGruppeKunden: TBooleanField;
    xdsEinzelkontaktGruppeLieferanten: TBooleanField;
    xdsEinzelkontaktGruppeSonstige: TBooleanField;
    xdsEinzelkontaktGruppeNichtkunden: TBooleanField;
    xdsEinzelkontaktKontaktgruppe: TStringField;
    xdsEinzelkontaktNachname: TStringField;
    xdsEinzelkontaktVorname: TStringField;
    xdsEinzelkontaktAdresszeile2: TStringField;
    xdsEinzelkontaktAdresszeile3: TStringField;
    xdsEinzelkontaktStrasse: TStringField;
    xdsEinzelkontaktPostleitzahl: TStringField;
    xdsEinzelkontaktOrt: TStringField;
    xdsEinzelkontaktLaenderkuerzel: TStringField;
    xdsEinzelkontaktLand: TStringField;
    xdsEinzelkontaktLaenderkennzeichen: TBooleanField;
    xdsEinzelkontaktAdressanrede: TStringField;
    xdsEinzelkontaktBriefanrede1: TStringField;
    xdsEinzelkontaktBriefanrede2: TStringField;
    xdsEinzelkontaktAdresseBild: TStringField;
    xdsEinzelkontaktLieferanschrift: TStringField;
    xdsEinzelkontaktGeburtsdatum: TDateTimeField;
    xdsEinzelkontaktTelefonNummer: TStringField;
    xdsEinzelkontaktTelefaxNummer: TStringField;
    xdsEinzelkontaktMobilTelefon: TStringField;
    xdsEinzelkontaktEMail: TStringField;
    xdsEinzelkontaktInternet: TStringField;
    xdsEinzelkontaktUmsatzsteuerID: TStringField;
    xdsEinzelkontaktUmsatzsteuerausweis: TBooleanField;
    xdsEinzelkontaktBemerkungen: TStringField;
    xdsEinzelkontaktBranche: TStringField;
    xdsEinzelkontaktSelektiert1: TBooleanField;
    xdsEinzelkontaktSelektiert2: TBooleanField;
    xdsEinzelkontaktSelektiert3: TBooleanField;
    xdsEinzelkontaktZahlungsbedingung: TIntegerField;
    xdsEinzelkontaktTransportbedingung: TStringField;
    xdsEinzelkontaktKilometer: TIntegerField;
    xdsEinzelkontaktRabattsatz: TFloatField;
    xdsEinzelkontaktStundensatz: TFloatField;
    xdsEinzelkontaktKontonummer: TStringField;
    xdsEinzelkontaktBank: TStringField;
    xdsEinzelkontaktBankleitzahl: TFloatField;
    xdsEinzelkontaktKontoinhaber: TStringField;
    xdsEinzelkontaktBetreuer: TStringField;
    xdsEinzelkontaktQuelle: TStringField;
    xdsEinzelkontaktSuchmerkmal: TStringField;
    xdsEinzelkontaktWarenkreditlimit: TFloatField;
    xdsEinzelkontaktWarenkreditgesperrt: TBooleanField;
    xdsEinzelkontaktKundennummerFremd: TStringField;
    xdsEinzelkontaktKeinNewsletter: TBooleanField;
    xdsEinzelkontaktDebitorennummer: TStringField;
    xdsEinzelkontaktKreditorennummer: TStringField;
    xdsEinzelkontaktTelefonAlternativ: TStringField;
    xdsEinzelkontaktKostentraeger: TStringField;
    xdsEinzelkontaktIBAN: TStringField;
    xdsEinzelkontaktBIC: TStringField;
    xdsEinzelkontaktEmailkonto: TStringField;
    xdsEinzelkontaktBenutzername: TStringField;
    xdsEinzelkontaktPasswort: TStringField;
    xdsEinzelkontaktExportOutlook: TBooleanField;
    xdsEinzelkontaktNichtInListeAnzeigen: TBooleanField;
    xdsEinzelkontaktKreditkarteArt: TStringField;
    xdsEinzelkontaktKreditkarteInhaber: TStringField;
    xdsEinzelkontaktKreditkarteNummer: TStringField;
    xdsEinzelkontaktKreditkarteGueltigMonat: TStringField;
    xdsEinzelkontaktKreditkarteGueltigJahr: TIntegerField;
    xdsEinzelkontaktKreditkartePruefziffer: TStringField;
    xdsEinzelkontaktDatumNeu: TDateTimeField;
    xdsEinzelkontaktDatumgeaendert: TDateTimeField;
    xdsEinzelkontaktTitel: TStringField;
    xdsEinzelkontaktStundensatzMontage: TFloatField;
    xdsEinzelkontaktStdMontageU25: TFloatField;
    xdsEinzelkontaktStdMontageU50: TFloatField;
    xdsEinzelkontaktStdMontageU70: TFloatField;
    xdsEinzelkontaktStdMontageU100: TFloatField;
    xdsEinzelkontaktStdMontageU150: TFloatField;
    xdsEinzelkontaktStundensatzWerkstatt: TFloatField;
    xdsEinzelkontaktStdWerkstattU25: TFloatField;
    xdsEinzelkontaktStdWerkstattU50: TFloatField;
    xdsEinzelkontaktStdWerkstattU70: TFloatField;
    xdsEinzelkontaktStdWerkstattU100: TFloatField;
    xdsEinzelkontaktStdWerkstattU150: TFloatField;
    xdsEinzelkontaktArchivReferenz: TStringField;
    xdsEinzelkontaktErloesKontoUST1: TStringField;
    xdsEinzelkontaktErloesKontoUST2: TStringField;
    xdsEinzelkontaktErloesKontoUST3: TStringField;
    xdsEinzelkontaktGebKal: TBooleanField;
    xdsEinzelkontaktEmailversand: TBooleanField;
    xdsEinzelkontaktMahnspesen: TBooleanField;
    xdsEinzelkontaktRechnungSchluss: TStringField;
    xdsEinzelkontaktBenutzerNeu: TStringField;
    xdsEinzelkontaktBenutzerGeaendert: TStringField;
    xdsEinzelkontaktBelegsprache: TStringField;
    xdsEinzelkontaktkmSatz: TFloatField;
    xdsEinzelkontaktKundenklassetext: TStringField;
    xdsEinzelkontaktKundenklasseFarbe: TIntegerField;
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger: TStringField;
    xdsEinzelkontaktProtokoll: TStringField;
    xdsEinzelkontaktILNTyp1: TStringField;
    xdsEinzelkontaktILNTyp2: TStringField;
    xdsEinzelkontaktKontaktGUID: TStringField;
    xdsEinzelkontaktPLZGUID: TStringField;
    xdsEinzelkontaktPreislistenVariantePakete: TStringField;
    xdsEinzelkontaktSchluesselRechnungslauf: TStringField;
    xdsEinzelkontaktRechnungEmail: TStringField;
    xdsEinzelkontaktZahlungsbedingungEK: TIntegerField;
    xdsEinzelkontaktTransportbedingungEK: TStringField;
    xdsEinzelkontaktRabattsatzEK: TFloatField;
    xdsEinzelkontaktVertreterMatchcode: TStringField;
    xdsEinzelkontaktVertreterProvisionssatz: TFloatField;
    xdsEinzelkontaktUmsatzVKJahr: TFloatField;
    xdsEinzelkontaktUmsatzVKVorjahr: TFloatField;
    xdsEinzelkontaktUmsatzEKJahr: TFloatField;
    xdsEinzelkontaktUmsatzEKVorjahr: TFloatField;
    xdsEinzelkontaktSteuernummer: TStringField;
    xdsEinzelkontaktLoginPW: TStringField;
    xdsEinzelkontaktREperFax: TBooleanField;
    xdsEinzelkontaktErreichbarkeit: TStringField;
    xdsEinzelkontaktErloesKontoUST4: TStringField;
    xdsEinzelkontaktErloesKontoUST5: TStringField;
    xdsEinzelkontaktErloesKontoUST6: TStringField;
    xdsEinzelkontaktBesteuerungKey: TIntegerField;
    xdsEinzelkontaktAuftraegeJahr: TFloatField;
    xdsEinzelkontaktAuftraegeVorjahr: TFloatField;
    xdsEinzelkontaktLieferadresseID: TIntegerField;
    xdsEinzelkontaktUSTIDgeprueft: TDateTimeField;
    xdsEinzelkontaktKontaktverbund: TStringField;
    xdsEinzelkontaktHinweis: TStringField;
    xdsEinzelkontaktLatitude: TFloatField;
    xdsEinzelkontaktLongitude: TFloatField;
    xdsEinzelkontaktkmLuftlinie: TFloatField;
    xdsEinzelkontaktGoogleID: TStringField;
    xdsEinzelkontaktGoogleUpdatedUTC: TDateTimeField;
    xdsEinzelkontaktBundesland: TStringField;
    xdsEinzelkontaktRegion: TStringField;
    xdsEinzelkontaktMandatsreferenz: TStringField;
    xdsEinzelkontaktMandatsreferenzVom: TDateTimeField;
    xdsEinzelkontaktLastschriftArt: TStringField;
    xdsEinzelkontaktMahnsperre: TBooleanField;
    xdsEinzelkontaktSuchbegriff: TStringField;
    xdsEinzelkontaktLieferanschriftDaten: TStringField;
    xdsEinzelkontaktExportKasse: TBooleanField;
    xdsEinzelkontaktLoeschvormerkungDatum: TDateTimeField;
    xdsEinzelkontaktLoeschvormerkungGrund: TStringField;
    xdsEinzelkontaktPseudonymisiert: TStringField;
    xdsEinzelkontaktLoginAktiv: TIntegerField;
    xdsEinzelkontaktSonstigeDaten: TStringField;
    xdsEinzelkontaktB2C: TBooleanField;
    xdsEinzelkontaktTIN: TStringField;
    xdsEinzelkontaktMindestbestellwert: TFloatField;
    sourceEinzelkontakt: TDataSource;
    TC: TTableControl;
    WClient: TXDataWebClient;
    procedure xdsEinzelkontaktAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    KundennummerID:string;
    buttonzurueck:TButton;
    buttonkarte:TButton;
    buttonbelege:TButton;
    buttonpreise:TButton;
    BemerkungenText:string;
    procedure buttonzurueckclick(Sender:TObject);
    procedure buttonkarteclick(Sender:TObject);
    procedure buttonbelegeclick(Sender:TObject);
    procedure buttonpreiseclick(Sender:TObject);
    procedure fensteraufbauen;
  end;

var
  EinzelKontakt: TEinzelKontakt;

implementation
  uses unit1, unit2, unit_Kontaktsuche, unit_karte, unit_BelegeUndPositionen, unit_Preise;

procedure TEinzelKontakt.buttonzurueckclick(Sender:TObject);
begin
  xdsEinzelkontakt.Close;
  self.Close;
end;

procedure TEinzelKontakt.buttonkarteclick(Sender:TObject);
var
  LFrm:TKarte;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TKarte;
    begin
      LForm:=(AForm as TKarte);
      lform.mykundehint:=xdsEinzelkontaktKundennummer.AsString+' '+xdsEinzelkontaktNachname.AsString+', '+xdsEinzelkontaktVorname.asstring;
      lForm.edttoaddress.Text:= xdsEinzelkontaktPostleitzahl.AsString+' '+xdsEinzelkontaktort.AsString+', '+xdsEinzelkontaktStrasse.AsString;

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //MerkerKontaktnummer:=lfrm.Kontaktnummer;

    end;
  begin
    if LizenzOK then
    begin
      LFrm:=TKarte.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);
    end
    else
    begin
      showmessage('Für diese Funktion sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;

end;

procedure TEinzelKontakt.buttonbelegeclick(Sender:TObject);
var
  LFrm:TBelegePositionen;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TBelegePositionen;
    begin
      LForm:=(AForm as TBelegePositionen);
      lform.BPKundennummer:=xdsEinzelkontaktKundennummer.AsString;
      lForm.BPName:=xdsEinzelkontaktNachname.asstring;

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //MerkerKontaktnummer:=lfrm.Kontaktnummer;

    end;
  begin

      LFrm:=TBelegePositionen.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);

end;

procedure TEinzelKontakt.buttonpreiseclick(Sender:TObject);
var
  LFrm:TPreise;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TPreise;
    begin
      LForm:=(AForm as TPreise);
      lform.mykontaktnummer:=xdsEinzelkontaktKundennummer.AsString;
      lform.mykontaktname:=xdsEinzelkontaktNachname.AsString;
      if xdseinzelkontaktvorname.AsString<>'' then lform.mykontaktname:=lform.mykontaktname+', '+xdsEinzelkontaktVorname.asstring;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //MerkerKontaktnummer:=lfrm.Kontaktnummer;

    end;
  begin
    if LizenzOK then
    begin
      LFrm:=TPreise.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);
    end
    else
    begin
      showmessage('Für diese Funktion sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;
end;

procedure TEinzelKontakt.WebFormShow(Sender: TObject);
begin
  xdsEinzelkontakt.Close;
  xdseinzelkontakt.QueryString:='$filter=Kundennummer eq '+quotedstr(KundennummerID);
  xdseinzelkontakt.Load;
end;

procedure TEinzelKontakt.xdsEinzelkontaktAfterOpen(DataSet: TDataSet);

  procedure OnResponse(Response:TXDataClientResponse);
  var
    mydownload:string;
  begin
    mydownload:=string(TJSObject(response.Result)['value']);
    BemerkungenText:=ReplaceStr(mydownload,#13+#10,'<BR>');
    fensteraufbauen;


  end;
begin
  WClient.RawInvoke('IServerService.RTFTOTEXT',[xdsEinzelkontaktBemerkungen.AsString],@OnResponse);
end;

procedure TEinzelkontakt.fensteraufbauen;
var
  i:integer;
  myelement:TJSHTMLElement;
begin

  TC.RowCount:=21;
  TC.ColCount:=2;
  i:=0;
  TC.Cells[0,i]:='<h4>Einzelkontakt';
  inc(i);
  myelement:=TC.cellelements[0,i];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;

  TC.Cells[1,i]:='';



  myelement:=TC.cellelements[1,i];
  buttonbelege:=TButton.Create(self);
  buttonbelege.ElementClassName:='btn btn-info text-left';
  buttonbelege.HeightStyle:=ssauto;
  buttonbelege.WidthStyle:=ssauto;
  buttonbelege.tag:=0;
  buttonbelege.Caption:='Belege';
  buttonbelege.OnClick:=@buttonbelegeClick;
  buttonbelege.ParentElement:=myelement;
  inc(i);
  TC.Cells[0,i]:='Kontakt-/Kundennummer';
  TC.Cells[1,i]:='<h5>'+xdsEinzelkontaktKundennummer.asstring;
  inc(i);
  TC.Cells[0,i]:='Nachname/Firma';
  TC.Cells[1,i]:='<h5>'+xdsEinzelkontaktNachname.asstring;
  inc(i);
  TC.Cells[0,i]:='Anschrift';
  TC.Cells[1,i]:='<h5>'+ReplaceStr(xdsEinzelkontaktAdresseBild.asstring,#13+#10,'<BR>');
  myelement:=TC.cellelements[1,i];
  buttonkarte:=TButton.Create(self);
  buttonkarte.ElementClassName:='btn btn-info text-left';
  buttonkarte.HeightStyle:=ssauto;
  buttonkarte.WidthStyle:=ssauto;
  buttonkarte.tag:=0;
  buttonkarte.Caption:='Karte(Maps)';
  buttonkarte.OnClick:=@buttonkarteClick;
  buttonkarte.ParentElement:=myelement;


  inc(i);
  TC.Cells[0,i]:='Telefon';
  TC.Cells[1,i]:='<a href="tel:'+xdsEinzelkontaktTelefonnummer.Asstring+'">'+xdsEinzelkontaktTelefonnummer.Asstring+'</a>';
  inc(i);
  TC.Cells[0,i]:='T-Alternativ';
  TC.Cells[1,i]:='<a href="tel:'+xdsEinzelkontaktTelefonAlternativ.Asstring+'">'+xdsEinzelkontaktTelefonAlternativ.Asstring+'</a>';

  inc(i);
  TC.Cells[0,i]:='Mobil';
  TC.Cells[1,i]:='<a href="tel:'+xdsEinzelkontaktMobiltelefon.Asstring+'">'+xdsEinzelkontaktMobiltelefon.Asstring+'</a>';

  inc(i);
  TC.Cells[0,i]:='Email';
  TC.Cells[1,i]:='<a href="mailto:'+xdsEinzelkontaktEmail.Asstring+'">'+xdsEinzelkontaktEmail.AsString+'</a>';
  inc(i);
  TC.Cells[0,i]:='Webseite';
  TC.Cells[1,i]:='<a href="http://'+xdsEinzelkontaktInternet.Asstring+'" target="_blank">'+xdsEinzelkontaktInternet.AsString+'</a>';
  inc(i);
  TC.Cells[0,i]:='Kontaktgruppe';
  TC.Cells[1,i]:=xdsEinzelkontaktKontaktgruppe.Asstring;
  inc(i);
  TC.Cells[0,i]:='Kundenklasse';
  TC.Cells[1,i]:=xdsEinzelkontaktKundenklassetext.Asstring;
  inc(i);
  TC.Cells[0,i]:='Betreuer/MA';
  TC.Cells[1,i]:=xdsEinzelkontaktBetreuer.Asstring;
  inc(i);
  TC.Cells[0,i]:='Vertreter';
  TC.Cells[1,i]:=xdsEinzelkontaktVertreterMatchcode.Asstring;
  inc(i);
  TC.Cells[0,i]:='Erreichbarkeit';
  TC.Cells[1,i]:=xdsEinzelkontaktErreichbarkeit.Asstring;
  inc(i);
  TC.Cells[0,i]:='Entfernung/km';
  TC.Cells[1,i]:=xdsEinzelkontaktKilometer.AsString;
  inc(i);
  TC.Cells[0,i]:='Lieferanschrift';
  TC.Cells[1,i]:=ReplaceStr(xdsEinzelkontaktLieferanschrift.AsString,#13+#10,'<BR>');
  inc(i);
  TC.Cells[0,i]:='USt-ID';
  TC.Cells[1,i]:=xdsEinzelkontaktUmsatzsteuerID.AsString;
  inc(i);
  TC.Cells[0,i]:='Erreichbarkeit';
  TC.Cells[1,i]:=xdsEinzelkontaktErreichbarkeit.AsString;
  inc(i);
  TC.Cells[0,i]:='Bemerkungen';
  TC.Cells[1,i]:=BemerkungenText;

  inc(i);
  myelement:=TC.cellelements[0,i];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;

  myelement:=TC.cellelements[1,i];
  myelement.innerHTML:='';
  buttonpreise:=TButton.Create(self);
  buttonpreise.ElementClassName:='btn btn-info text-left';
  buttonpreise.HeightStyle:=ssauto;
  buttonpreise.WidthStyle:=ssauto;
  buttonpreise.tag:=0;
  buttonpreise.Caption:='Preise';
  buttonpreise.OnClick:=@buttonpreiseClick;
  buttonpreise.ParentElement:=myelement;
  end;


procedure TEinzelKontakt.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  xdsEinzelkontakt := TXDataWebDataSet.Create(Self);
  xdsEinzelkontaktKundennummer := TStringField.Create(Self);
  xdsEinzelkontaktGruppeKunden := TBooleanField.Create(Self);
  xdsEinzelkontaktGruppeLieferanten := TBooleanField.Create(Self);
  xdsEinzelkontaktGruppeSonstige := TBooleanField.Create(Self);
  xdsEinzelkontaktGruppeNichtkunden := TBooleanField.Create(Self);
  xdsEinzelkontaktKontaktgruppe := TStringField.Create(Self);
  xdsEinzelkontaktNachname := TStringField.Create(Self);
  xdsEinzelkontaktVorname := TStringField.Create(Self);
  xdsEinzelkontaktAdresszeile2 := TStringField.Create(Self);
  xdsEinzelkontaktAdresszeile3 := TStringField.Create(Self);
  xdsEinzelkontaktStrasse := TStringField.Create(Self);
  xdsEinzelkontaktPostleitzahl := TStringField.Create(Self);
  xdsEinzelkontaktOrt := TStringField.Create(Self);
  xdsEinzelkontaktLaenderkuerzel := TStringField.Create(Self);
  xdsEinzelkontaktLand := TStringField.Create(Self);
  xdsEinzelkontaktLaenderkennzeichen := TBooleanField.Create(Self);
  xdsEinzelkontaktAdressanrede := TStringField.Create(Self);
  xdsEinzelkontaktBriefanrede1 := TStringField.Create(Self);
  xdsEinzelkontaktBriefanrede2 := TStringField.Create(Self);
  xdsEinzelkontaktAdresseBild := TStringField.Create(Self);
  xdsEinzelkontaktLieferanschrift := TStringField.Create(Self);
  xdsEinzelkontaktGeburtsdatum := TDateTimeField.Create(Self);
  xdsEinzelkontaktTelefonNummer := TStringField.Create(Self);
  xdsEinzelkontaktTelefaxNummer := TStringField.Create(Self);
  xdsEinzelkontaktMobilTelefon := TStringField.Create(Self);
  xdsEinzelkontaktEMail := TStringField.Create(Self);
  xdsEinzelkontaktInternet := TStringField.Create(Self);
  xdsEinzelkontaktUmsatzsteuerID := TStringField.Create(Self);
  xdsEinzelkontaktUmsatzsteuerausweis := TBooleanField.Create(Self);
  xdsEinzelkontaktBemerkungen := TStringField.Create(Self);
  xdsEinzelkontaktBranche := TStringField.Create(Self);
  xdsEinzelkontaktSelektiert1 := TBooleanField.Create(Self);
  xdsEinzelkontaktSelektiert2 := TBooleanField.Create(Self);
  xdsEinzelkontaktSelektiert3 := TBooleanField.Create(Self);
  xdsEinzelkontaktZahlungsbedingung := TIntegerField.Create(Self);
  xdsEinzelkontaktTransportbedingung := TStringField.Create(Self);
  xdsEinzelkontaktKilometer := TIntegerField.Create(Self);
  xdsEinzelkontaktRabattsatz := TFloatField.Create(Self);
  xdsEinzelkontaktStundensatz := TFloatField.Create(Self);
  xdsEinzelkontaktKontonummer := TStringField.Create(Self);
  xdsEinzelkontaktBank := TStringField.Create(Self);
  xdsEinzelkontaktBankleitzahl := TFloatField.Create(Self);
  xdsEinzelkontaktKontoinhaber := TStringField.Create(Self);
  xdsEinzelkontaktBetreuer := TStringField.Create(Self);
  xdsEinzelkontaktQuelle := TStringField.Create(Self);
  xdsEinzelkontaktSuchmerkmal := TStringField.Create(Self);
  xdsEinzelkontaktWarenkreditlimit := TFloatField.Create(Self);
  xdsEinzelkontaktWarenkreditgesperrt := TBooleanField.Create(Self);
  xdsEinzelkontaktKundennummerFremd := TStringField.Create(Self);
  xdsEinzelkontaktKeinNewsletter := TBooleanField.Create(Self);
  xdsEinzelkontaktDebitorennummer := TStringField.Create(Self);
  xdsEinzelkontaktKreditorennummer := TStringField.Create(Self);
  xdsEinzelkontaktTelefonAlternativ := TStringField.Create(Self);
  xdsEinzelkontaktKostentraeger := TStringField.Create(Self);
  xdsEinzelkontaktIBAN := TStringField.Create(Self);
  xdsEinzelkontaktBIC := TStringField.Create(Self);
  xdsEinzelkontaktEmailkonto := TStringField.Create(Self);
  xdsEinzelkontaktBenutzername := TStringField.Create(Self);
  xdsEinzelkontaktPasswort := TStringField.Create(Self);
  xdsEinzelkontaktExportOutlook := TBooleanField.Create(Self);
  xdsEinzelkontaktNichtInListeAnzeigen := TBooleanField.Create(Self);
  xdsEinzelkontaktKreditkarteArt := TStringField.Create(Self);
  xdsEinzelkontaktKreditkarteInhaber := TStringField.Create(Self);
  xdsEinzelkontaktKreditkarteNummer := TStringField.Create(Self);
  xdsEinzelkontaktKreditkarteGueltigMonat := TStringField.Create(Self);
  xdsEinzelkontaktKreditkarteGueltigJahr := TIntegerField.Create(Self);
  xdsEinzelkontaktKreditkartePruefziffer := TStringField.Create(Self);
  xdsEinzelkontaktDatumNeu := TDateTimeField.Create(Self);
  xdsEinzelkontaktDatumgeaendert := TDateTimeField.Create(Self);
  xdsEinzelkontaktTitel := TStringField.Create(Self);
  xdsEinzelkontaktStundensatzMontage := TFloatField.Create(Self);
  xdsEinzelkontaktStdMontageU25 := TFloatField.Create(Self);
  xdsEinzelkontaktStdMontageU50 := TFloatField.Create(Self);
  xdsEinzelkontaktStdMontageU70 := TFloatField.Create(Self);
  xdsEinzelkontaktStdMontageU100 := TFloatField.Create(Self);
  xdsEinzelkontaktStdMontageU150 := TFloatField.Create(Self);
  xdsEinzelkontaktStundensatzWerkstatt := TFloatField.Create(Self);
  xdsEinzelkontaktStdWerkstattU25 := TFloatField.Create(Self);
  xdsEinzelkontaktStdWerkstattU50 := TFloatField.Create(Self);
  xdsEinzelkontaktStdWerkstattU70 := TFloatField.Create(Self);
  xdsEinzelkontaktStdWerkstattU100 := TFloatField.Create(Self);
  xdsEinzelkontaktStdWerkstattU150 := TFloatField.Create(Self);
  xdsEinzelkontaktArchivReferenz := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST1 := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST2 := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST3 := TStringField.Create(Self);
  xdsEinzelkontaktGebKal := TBooleanField.Create(Self);
  xdsEinzelkontaktEmailversand := TBooleanField.Create(Self);
  xdsEinzelkontaktMahnspesen := TBooleanField.Create(Self);
  xdsEinzelkontaktRechnungSchluss := TStringField.Create(Self);
  xdsEinzelkontaktBenutzerNeu := TStringField.Create(Self);
  xdsEinzelkontaktBenutzerGeaendert := TStringField.Create(Self);
  xdsEinzelkontaktBelegsprache := TStringField.Create(Self);
  xdsEinzelkontaktkmSatz := TFloatField.Create(Self);
  xdsEinzelkontaktKundenklassetext := TStringField.Create(Self);
  xdsEinzelkontaktKundenklasseFarbe := TIntegerField.Create(Self);
  xdsEinzelkontaktAbweichenderRechnungsEmpfaenger := TStringField.Create(Self);
  xdsEinzelkontaktProtokoll := TStringField.Create(Self);
  xdsEinzelkontaktILNTyp1 := TStringField.Create(Self);
  xdsEinzelkontaktILNTyp2 := TStringField.Create(Self);
  xdsEinzelkontaktKontaktGUID := TStringField.Create(Self);
  xdsEinzelkontaktPLZGUID := TStringField.Create(Self);
  xdsEinzelkontaktPreislistenVariantePakete := TStringField.Create(Self);
  xdsEinzelkontaktSchluesselRechnungslauf := TStringField.Create(Self);
  xdsEinzelkontaktRechnungEmail := TStringField.Create(Self);
  xdsEinzelkontaktZahlungsbedingungEK := TIntegerField.Create(Self);
  xdsEinzelkontaktTransportbedingungEK := TStringField.Create(Self);
  xdsEinzelkontaktRabattsatzEK := TFloatField.Create(Self);
  xdsEinzelkontaktVertreterMatchcode := TStringField.Create(Self);
  xdsEinzelkontaktVertreterProvisionssatz := TFloatField.Create(Self);
  xdsEinzelkontaktUmsatzVKJahr := TFloatField.Create(Self);
  xdsEinzelkontaktUmsatzVKVorjahr := TFloatField.Create(Self);
  xdsEinzelkontaktUmsatzEKJahr := TFloatField.Create(Self);
  xdsEinzelkontaktUmsatzEKVorjahr := TFloatField.Create(Self);
  xdsEinzelkontaktSteuernummer := TStringField.Create(Self);
  xdsEinzelkontaktLoginPW := TStringField.Create(Self);
  xdsEinzelkontaktREperFax := TBooleanField.Create(Self);
  xdsEinzelkontaktErreichbarkeit := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST4 := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST5 := TStringField.Create(Self);
  xdsEinzelkontaktErloesKontoUST6 := TStringField.Create(Self);
  xdsEinzelkontaktBesteuerungKey := TIntegerField.Create(Self);
  xdsEinzelkontaktAuftraegeJahr := TFloatField.Create(Self);
  xdsEinzelkontaktAuftraegeVorjahr := TFloatField.Create(Self);
  xdsEinzelkontaktLieferadresseID := TIntegerField.Create(Self);
  xdsEinzelkontaktUSTIDgeprueft := TDateTimeField.Create(Self);
  xdsEinzelkontaktKontaktverbund := TStringField.Create(Self);
  xdsEinzelkontaktHinweis := TStringField.Create(Self);
  xdsEinzelkontaktLatitude := TFloatField.Create(Self);
  xdsEinzelkontaktLongitude := TFloatField.Create(Self);
  xdsEinzelkontaktkmLuftlinie := TFloatField.Create(Self);
  xdsEinzelkontaktGoogleID := TStringField.Create(Self);
  xdsEinzelkontaktGoogleUpdatedUTC := TDateTimeField.Create(Self);
  xdsEinzelkontaktBundesland := TStringField.Create(Self);
  xdsEinzelkontaktRegion := TStringField.Create(Self);
  xdsEinzelkontaktMandatsreferenz := TStringField.Create(Self);
  xdsEinzelkontaktMandatsreferenzVom := TDateTimeField.Create(Self);
  xdsEinzelkontaktLastschriftArt := TStringField.Create(Self);
  xdsEinzelkontaktMahnsperre := TBooleanField.Create(Self);
  xdsEinzelkontaktSuchbegriff := TStringField.Create(Self);
  xdsEinzelkontaktLieferanschriftDaten := TStringField.Create(Self);
  xdsEinzelkontaktExportKasse := TBooleanField.Create(Self);
  xdsEinzelkontaktLoeschvormerkungDatum := TDateTimeField.Create(Self);
  xdsEinzelkontaktLoeschvormerkungGrund := TStringField.Create(Self);
  xdsEinzelkontaktPseudonymisiert := TStringField.Create(Self);
  xdsEinzelkontaktLoginAktiv := TIntegerField.Create(Self);
  xdsEinzelkontaktSonstigeDaten := TStringField.Create(Self);
  xdsEinzelkontaktB2C := TBooleanField.Create(Self);
  xdsEinzelkontaktTIN := TStringField.Create(Self);
  xdsEinzelkontaktMindestbestellwert := TFloatField.Create(Self);
  sourceEinzelkontakt := TDataSource.Create(Self);
  WClient := TXDataWebClient.Create(Self);

  TC.BeforeLoadDFMValues;
  xdsEinzelkontakt.BeforeLoadDFMValues;
  xdsEinzelkontaktKundennummer.BeforeLoadDFMValues;
  xdsEinzelkontaktGruppeKunden.BeforeLoadDFMValues;
  xdsEinzelkontaktGruppeLieferanten.BeforeLoadDFMValues;
  xdsEinzelkontaktGruppeSonstige.BeforeLoadDFMValues;
  xdsEinzelkontaktGruppeNichtkunden.BeforeLoadDFMValues;
  xdsEinzelkontaktKontaktgruppe.BeforeLoadDFMValues;
  xdsEinzelkontaktNachname.BeforeLoadDFMValues;
  xdsEinzelkontaktVorname.BeforeLoadDFMValues;
  xdsEinzelkontaktAdresszeile2.BeforeLoadDFMValues;
  xdsEinzelkontaktAdresszeile3.BeforeLoadDFMValues;
  xdsEinzelkontaktStrasse.BeforeLoadDFMValues;
  xdsEinzelkontaktPostleitzahl.BeforeLoadDFMValues;
  xdsEinzelkontaktOrt.BeforeLoadDFMValues;
  xdsEinzelkontaktLaenderkuerzel.BeforeLoadDFMValues;
  xdsEinzelkontaktLand.BeforeLoadDFMValues;
  xdsEinzelkontaktLaenderkennzeichen.BeforeLoadDFMValues;
  xdsEinzelkontaktAdressanrede.BeforeLoadDFMValues;
  xdsEinzelkontaktBriefanrede1.BeforeLoadDFMValues;
  xdsEinzelkontaktBriefanrede2.BeforeLoadDFMValues;
  xdsEinzelkontaktAdresseBild.BeforeLoadDFMValues;
  xdsEinzelkontaktLieferanschrift.BeforeLoadDFMValues;
  xdsEinzelkontaktGeburtsdatum.BeforeLoadDFMValues;
  xdsEinzelkontaktTelefonNummer.BeforeLoadDFMValues;
  xdsEinzelkontaktTelefaxNummer.BeforeLoadDFMValues;
  xdsEinzelkontaktMobilTelefon.BeforeLoadDFMValues;
  xdsEinzelkontaktEMail.BeforeLoadDFMValues;
  xdsEinzelkontaktInternet.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzsteuerID.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzsteuerausweis.BeforeLoadDFMValues;
  xdsEinzelkontaktBemerkungen.BeforeLoadDFMValues;
  xdsEinzelkontaktBranche.BeforeLoadDFMValues;
  xdsEinzelkontaktSelektiert1.BeforeLoadDFMValues;
  xdsEinzelkontaktSelektiert2.BeforeLoadDFMValues;
  xdsEinzelkontaktSelektiert3.BeforeLoadDFMValues;
  xdsEinzelkontaktZahlungsbedingung.BeforeLoadDFMValues;
  xdsEinzelkontaktTransportbedingung.BeforeLoadDFMValues;
  xdsEinzelkontaktKilometer.BeforeLoadDFMValues;
  xdsEinzelkontaktRabattsatz.BeforeLoadDFMValues;
  xdsEinzelkontaktStundensatz.BeforeLoadDFMValues;
  xdsEinzelkontaktKontonummer.BeforeLoadDFMValues;
  xdsEinzelkontaktBank.BeforeLoadDFMValues;
  xdsEinzelkontaktBankleitzahl.BeforeLoadDFMValues;
  xdsEinzelkontaktKontoinhaber.BeforeLoadDFMValues;
  xdsEinzelkontaktBetreuer.BeforeLoadDFMValues;
  xdsEinzelkontaktQuelle.BeforeLoadDFMValues;
  xdsEinzelkontaktSuchmerkmal.BeforeLoadDFMValues;
  xdsEinzelkontaktWarenkreditlimit.BeforeLoadDFMValues;
  xdsEinzelkontaktWarenkreditgesperrt.BeforeLoadDFMValues;
  xdsEinzelkontaktKundennummerFremd.BeforeLoadDFMValues;
  xdsEinzelkontaktKeinNewsletter.BeforeLoadDFMValues;
  xdsEinzelkontaktDebitorennummer.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditorennummer.BeforeLoadDFMValues;
  xdsEinzelkontaktTelefonAlternativ.BeforeLoadDFMValues;
  xdsEinzelkontaktKostentraeger.BeforeLoadDFMValues;
  xdsEinzelkontaktIBAN.BeforeLoadDFMValues;
  xdsEinzelkontaktBIC.BeforeLoadDFMValues;
  xdsEinzelkontaktEmailkonto.BeforeLoadDFMValues;
  xdsEinzelkontaktBenutzername.BeforeLoadDFMValues;
  xdsEinzelkontaktPasswort.BeforeLoadDFMValues;
  xdsEinzelkontaktExportOutlook.BeforeLoadDFMValues;
  xdsEinzelkontaktNichtInListeAnzeigen.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkarteArt.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkarteInhaber.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkarteNummer.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkarteGueltigMonat.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkarteGueltigJahr.BeforeLoadDFMValues;
  xdsEinzelkontaktKreditkartePruefziffer.BeforeLoadDFMValues;
  xdsEinzelkontaktDatumNeu.BeforeLoadDFMValues;
  xdsEinzelkontaktDatumgeaendert.BeforeLoadDFMValues;
  xdsEinzelkontaktTitel.BeforeLoadDFMValues;
  xdsEinzelkontaktStundensatzMontage.BeforeLoadDFMValues;
  xdsEinzelkontaktStdMontageU25.BeforeLoadDFMValues;
  xdsEinzelkontaktStdMontageU50.BeforeLoadDFMValues;
  xdsEinzelkontaktStdMontageU70.BeforeLoadDFMValues;
  xdsEinzelkontaktStdMontageU100.BeforeLoadDFMValues;
  xdsEinzelkontaktStdMontageU150.BeforeLoadDFMValues;
  xdsEinzelkontaktStundensatzWerkstatt.BeforeLoadDFMValues;
  xdsEinzelkontaktStdWerkstattU25.BeforeLoadDFMValues;
  xdsEinzelkontaktStdWerkstattU50.BeforeLoadDFMValues;
  xdsEinzelkontaktStdWerkstattU70.BeforeLoadDFMValues;
  xdsEinzelkontaktStdWerkstattU100.BeforeLoadDFMValues;
  xdsEinzelkontaktStdWerkstattU150.BeforeLoadDFMValues;
  xdsEinzelkontaktArchivReferenz.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST1.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST2.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST3.BeforeLoadDFMValues;
  xdsEinzelkontaktGebKal.BeforeLoadDFMValues;
  xdsEinzelkontaktEmailversand.BeforeLoadDFMValues;
  xdsEinzelkontaktMahnspesen.BeforeLoadDFMValues;
  xdsEinzelkontaktRechnungSchluss.BeforeLoadDFMValues;
  xdsEinzelkontaktBenutzerNeu.BeforeLoadDFMValues;
  xdsEinzelkontaktBenutzerGeaendert.BeforeLoadDFMValues;
  xdsEinzelkontaktBelegsprache.BeforeLoadDFMValues;
  xdsEinzelkontaktkmSatz.BeforeLoadDFMValues;
  xdsEinzelkontaktKundenklassetext.BeforeLoadDFMValues;
  xdsEinzelkontaktKundenklasseFarbe.BeforeLoadDFMValues;
  xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.BeforeLoadDFMValues;
  xdsEinzelkontaktProtokoll.BeforeLoadDFMValues;
  xdsEinzelkontaktILNTyp1.BeforeLoadDFMValues;
  xdsEinzelkontaktILNTyp2.BeforeLoadDFMValues;
  xdsEinzelkontaktKontaktGUID.BeforeLoadDFMValues;
  xdsEinzelkontaktPLZGUID.BeforeLoadDFMValues;
  xdsEinzelkontaktPreislistenVariantePakete.BeforeLoadDFMValues;
  xdsEinzelkontaktSchluesselRechnungslauf.BeforeLoadDFMValues;
  xdsEinzelkontaktRechnungEmail.BeforeLoadDFMValues;
  xdsEinzelkontaktZahlungsbedingungEK.BeforeLoadDFMValues;
  xdsEinzelkontaktTransportbedingungEK.BeforeLoadDFMValues;
  xdsEinzelkontaktRabattsatzEK.BeforeLoadDFMValues;
  xdsEinzelkontaktVertreterMatchcode.BeforeLoadDFMValues;
  xdsEinzelkontaktVertreterProvisionssatz.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzVKJahr.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzVKVorjahr.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzEKJahr.BeforeLoadDFMValues;
  xdsEinzelkontaktUmsatzEKVorjahr.BeforeLoadDFMValues;
  xdsEinzelkontaktSteuernummer.BeforeLoadDFMValues;
  xdsEinzelkontaktLoginPW.BeforeLoadDFMValues;
  xdsEinzelkontaktREperFax.BeforeLoadDFMValues;
  xdsEinzelkontaktErreichbarkeit.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST4.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST5.BeforeLoadDFMValues;
  xdsEinzelkontaktErloesKontoUST6.BeforeLoadDFMValues;
  xdsEinzelkontaktBesteuerungKey.BeforeLoadDFMValues;
  xdsEinzelkontaktAuftraegeJahr.BeforeLoadDFMValues;
  xdsEinzelkontaktAuftraegeVorjahr.BeforeLoadDFMValues;
  xdsEinzelkontaktLieferadresseID.BeforeLoadDFMValues;
  xdsEinzelkontaktUSTIDgeprueft.BeforeLoadDFMValues;
  xdsEinzelkontaktKontaktverbund.BeforeLoadDFMValues;
  xdsEinzelkontaktHinweis.BeforeLoadDFMValues;
  xdsEinzelkontaktLatitude.BeforeLoadDFMValues;
  xdsEinzelkontaktLongitude.BeforeLoadDFMValues;
  xdsEinzelkontaktkmLuftlinie.BeforeLoadDFMValues;
  xdsEinzelkontaktGoogleID.BeforeLoadDFMValues;
  xdsEinzelkontaktGoogleUpdatedUTC.BeforeLoadDFMValues;
  xdsEinzelkontaktBundesland.BeforeLoadDFMValues;
  xdsEinzelkontaktRegion.BeforeLoadDFMValues;
  xdsEinzelkontaktMandatsreferenz.BeforeLoadDFMValues;
  xdsEinzelkontaktMandatsreferenzVom.BeforeLoadDFMValues;
  xdsEinzelkontaktLastschriftArt.BeforeLoadDFMValues;
  xdsEinzelkontaktMahnsperre.BeforeLoadDFMValues;
  xdsEinzelkontaktSuchbegriff.BeforeLoadDFMValues;
  xdsEinzelkontaktLieferanschriftDaten.BeforeLoadDFMValues;
  xdsEinzelkontaktExportKasse.BeforeLoadDFMValues;
  xdsEinzelkontaktLoeschvormerkungDatum.BeforeLoadDFMValues;
  xdsEinzelkontaktLoeschvormerkungGrund.BeforeLoadDFMValues;
  xdsEinzelkontaktPseudonymisiert.BeforeLoadDFMValues;
  xdsEinzelkontaktLoginAktiv.BeforeLoadDFMValues;
  xdsEinzelkontaktSonstigeDaten.BeforeLoadDFMValues;
  xdsEinzelkontaktB2C.BeforeLoadDFMValues;
  xdsEinzelkontaktTIN.BeforeLoadDFMValues;
  xdsEinzelkontaktMindestbestellwert.BeforeLoadDFMValues;
  sourceEinzelkontakt.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  try
    Name := 'EinzelKontakt';
    Width := 152;
    Height := 230;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 152;
    TC.Height := 230;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    xdsEinzelkontakt.SetParentComponent(Self);
    xdsEinzelkontakt.Name := 'xdsEinzelkontakt';
    xdsEinzelkontakt.AfterOpen := xdsEinzelkontaktAfterOpen;
    xdsEinzelkontakt.EntitySetName := 'Adressen';
    xdsEinzelkontakt.Connection := DM2.XDataWebConnection1;
    xdsEinzelkontakt.QueryTop := 1;
    xdsEinzelkontakt.Left := 20;
    xdsEinzelkontakt.Top := 125;
    xdsEinzelkontaktKundennummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKundennummer.Name := 'xdsEinzelkontaktKundennummer';
    xdsEinzelkontaktKundennummer.FieldName := 'Kundennummer';
    xdsEinzelkontaktKundennummer.Required := True;
    xdsEinzelkontaktKundennummer.Size := 13;
    xdsEinzelkontaktGruppeKunden.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGruppeKunden.Name := 'xdsEinzelkontaktGruppeKunden';
    xdsEinzelkontaktGruppeKunden.FieldName := 'GruppeKunden';
    xdsEinzelkontaktGruppeLieferanten.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGruppeLieferanten.Name := 'xdsEinzelkontaktGruppeLieferanten';
    xdsEinzelkontaktGruppeLieferanten.FieldName := 'GruppeLieferanten';
    xdsEinzelkontaktGruppeSonstige.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGruppeSonstige.Name := 'xdsEinzelkontaktGruppeSonstige';
    xdsEinzelkontaktGruppeSonstige.FieldName := 'GruppeSonstige';
    xdsEinzelkontaktGruppeNichtkunden.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGruppeNichtkunden.Name := 'xdsEinzelkontaktGruppeNichtkunden';
    xdsEinzelkontaktGruppeNichtkunden.FieldName := 'GruppeNichtkunden';
    xdsEinzelkontaktKontaktgruppe.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKontaktgruppe.Name := 'xdsEinzelkontaktKontaktgruppe';
    xdsEinzelkontaktKontaktgruppe.FieldName := 'Kontaktgruppe';
    xdsEinzelkontaktKontaktgruppe.Size := 50;
    xdsEinzelkontaktNachname.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktNachname.Name := 'xdsEinzelkontaktNachname';
    xdsEinzelkontaktNachname.FieldName := 'Nachname';
    xdsEinzelkontaktNachname.Size := 40;
    xdsEinzelkontaktVorname.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktVorname.Name := 'xdsEinzelkontaktVorname';
    xdsEinzelkontaktVorname.FieldName := 'Vorname';
    xdsEinzelkontaktVorname.Size := 40;
    xdsEinzelkontaktAdresszeile2.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAdresszeile2.Name := 'xdsEinzelkontaktAdresszeile2';
    xdsEinzelkontaktAdresszeile2.FieldName := 'Adresszeile2';
    xdsEinzelkontaktAdresszeile2.Size := 40;
    xdsEinzelkontaktAdresszeile3.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAdresszeile3.Name := 'xdsEinzelkontaktAdresszeile3';
    xdsEinzelkontaktAdresszeile3.FieldName := 'Adresszeile3';
    xdsEinzelkontaktAdresszeile3.Size := 40;
    xdsEinzelkontaktStrasse.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStrasse.Name := 'xdsEinzelkontaktStrasse';
    xdsEinzelkontaktStrasse.FieldName := 'Strasse';
    xdsEinzelkontaktStrasse.Size := 40;
    xdsEinzelkontaktPostleitzahl.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktPostleitzahl.Name := 'xdsEinzelkontaktPostleitzahl';
    xdsEinzelkontaktPostleitzahl.FieldName := 'Postleitzahl';
    xdsEinzelkontaktPostleitzahl.Size := 6;
    xdsEinzelkontaktOrt.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktOrt.Name := 'xdsEinzelkontaktOrt';
    xdsEinzelkontaktOrt.FieldName := 'Ort';
    xdsEinzelkontaktOrt.Size := 40;
    xdsEinzelkontaktLaenderkuerzel.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLaenderkuerzel.Name := 'xdsEinzelkontaktLaenderkuerzel';
    xdsEinzelkontaktLaenderkuerzel.FieldName := 'Laenderkuerzel';
    xdsEinzelkontaktLaenderkuerzel.Size := 5;
    xdsEinzelkontaktLand.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLand.Name := 'xdsEinzelkontaktLand';
    xdsEinzelkontaktLand.FieldName := 'Land';
    xdsEinzelkontaktLand.Size := 40;
    xdsEinzelkontaktLaenderkennzeichen.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLaenderkennzeichen.Name := 'xdsEinzelkontaktLaenderkennzeichen';
    xdsEinzelkontaktLaenderkennzeichen.FieldName := 'Laenderkennzeichen';
    xdsEinzelkontaktAdressanrede.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAdressanrede.Name := 'xdsEinzelkontaktAdressanrede';
    xdsEinzelkontaktAdressanrede.FieldName := 'Adressanrede';
    xdsEinzelkontaktAdressanrede.Size := 15;
    xdsEinzelkontaktBriefanrede1.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBriefanrede1.Name := 'xdsEinzelkontaktBriefanrede1';
    xdsEinzelkontaktBriefanrede1.FieldName := 'Briefanrede1';
    xdsEinzelkontaktBriefanrede1.Size := 255;
    xdsEinzelkontaktBriefanrede2.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBriefanrede2.Name := 'xdsEinzelkontaktBriefanrede2';
    xdsEinzelkontaktBriefanrede2.FieldName := 'Briefanrede2';
    xdsEinzelkontaktBriefanrede2.Size := 40;
    xdsEinzelkontaktAdresseBild.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAdresseBild.Name := 'xdsEinzelkontaktAdresseBild';
    xdsEinzelkontaktAdresseBild.FieldName := 'AdresseBild';
    xdsEinzelkontaktAdresseBild.Size := 255;
    xdsEinzelkontaktLieferanschrift.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLieferanschrift.Name := 'xdsEinzelkontaktLieferanschrift';
    xdsEinzelkontaktLieferanschrift.FieldName := 'Lieferanschrift';
    xdsEinzelkontaktLieferanschrift.Size := 255;
    xdsEinzelkontaktGeburtsdatum.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGeburtsdatum.Name := 'xdsEinzelkontaktGeburtsdatum';
    xdsEinzelkontaktGeburtsdatum.FieldName := 'Geburtsdatum';
    xdsEinzelkontaktTelefonNummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTelefonNummer.Name := 'xdsEinzelkontaktTelefonNummer';
    xdsEinzelkontaktTelefonNummer.FieldName := 'TelefonNummer';
    xdsEinzelkontaktTelefonNummer.Size := 25;
    xdsEinzelkontaktTelefaxNummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTelefaxNummer.Name := 'xdsEinzelkontaktTelefaxNummer';
    xdsEinzelkontaktTelefaxNummer.FieldName := 'TelefaxNummer';
    xdsEinzelkontaktTelefaxNummer.Size := 25;
    xdsEinzelkontaktMobilTelefon.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMobilTelefon.Name := 'xdsEinzelkontaktMobilTelefon';
    xdsEinzelkontaktMobilTelefon.FieldName := 'MobilTelefon';
    xdsEinzelkontaktMobilTelefon.Size := 25;
    xdsEinzelkontaktEMail.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktEMail.Name := 'xdsEinzelkontaktEMail';
    xdsEinzelkontaktEMail.FieldName := 'EMail';
    xdsEinzelkontaktEMail.Size := 50;
    xdsEinzelkontaktInternet.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktInternet.Name := 'xdsEinzelkontaktInternet';
    xdsEinzelkontaktInternet.FieldName := 'Internet';
    xdsEinzelkontaktInternet.Size := 50;
    xdsEinzelkontaktUmsatzsteuerID.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzsteuerID.Name := 'xdsEinzelkontaktUmsatzsteuerID';
    xdsEinzelkontaktUmsatzsteuerID.FieldName := 'UmsatzsteuerID';
    xdsEinzelkontaktUmsatzsteuerausweis.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzsteuerausweis.Name := 'xdsEinzelkontaktUmsatzsteuerausweis';
    xdsEinzelkontaktUmsatzsteuerausweis.FieldName := 'Umsatzsteuerausweis';
    xdsEinzelkontaktBemerkungen.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBemerkungen.Name := 'xdsEinzelkontaktBemerkungen';
    xdsEinzelkontaktBemerkungen.FieldName := 'Bemerkungen';
    xdsEinzelkontaktBemerkungen.Size := 255;
    xdsEinzelkontaktBranche.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBranche.Name := 'xdsEinzelkontaktBranche';
    xdsEinzelkontaktBranche.FieldName := 'Branche';
    xdsEinzelkontaktBranche.Size := 50;
    xdsEinzelkontaktSelektiert1.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSelektiert1.Name := 'xdsEinzelkontaktSelektiert1';
    xdsEinzelkontaktSelektiert1.FieldName := 'Selektiert1';
    xdsEinzelkontaktSelektiert2.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSelektiert2.Name := 'xdsEinzelkontaktSelektiert2';
    xdsEinzelkontaktSelektiert2.FieldName := 'Selektiert2';
    xdsEinzelkontaktSelektiert3.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSelektiert3.Name := 'xdsEinzelkontaktSelektiert3';
    xdsEinzelkontaktSelektiert3.FieldName := 'Selektiert3';
    xdsEinzelkontaktZahlungsbedingung.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktZahlungsbedingung.Name := 'xdsEinzelkontaktZahlungsbedingung';
    xdsEinzelkontaktZahlungsbedingung.FieldName := 'Zahlungsbedingung';
    xdsEinzelkontaktTransportbedingung.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTransportbedingung.Name := 'xdsEinzelkontaktTransportbedingung';
    xdsEinzelkontaktTransportbedingung.FieldName := 'Transportbedingung';
    xdsEinzelkontaktTransportbedingung.Size := 100;
    xdsEinzelkontaktKilometer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKilometer.Name := 'xdsEinzelkontaktKilometer';
    xdsEinzelkontaktKilometer.FieldName := 'Kilometer';
    xdsEinzelkontaktRabattsatz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktRabattsatz.Name := 'xdsEinzelkontaktRabattsatz';
    xdsEinzelkontaktRabattsatz.FieldName := 'Rabattsatz';
    xdsEinzelkontaktStundensatz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStundensatz.Name := 'xdsEinzelkontaktStundensatz';
    xdsEinzelkontaktStundensatz.FieldName := 'Stundensatz';
    xdsEinzelkontaktKontonummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKontonummer.Name := 'xdsEinzelkontaktKontonummer';
    xdsEinzelkontaktKontonummer.FieldName := 'Kontonummer';
    xdsEinzelkontaktKontonummer.Size := 50;
    xdsEinzelkontaktBank.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBank.Name := 'xdsEinzelkontaktBank';
    xdsEinzelkontaktBank.FieldName := 'Bank';
    xdsEinzelkontaktBank.Size := 50;
    xdsEinzelkontaktBankleitzahl.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBankleitzahl.Name := 'xdsEinzelkontaktBankleitzahl';
    xdsEinzelkontaktBankleitzahl.FieldName := 'Bankleitzahl';
    xdsEinzelkontaktKontoinhaber.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKontoinhaber.Name := 'xdsEinzelkontaktKontoinhaber';
    xdsEinzelkontaktKontoinhaber.FieldName := 'Kontoinhaber';
    xdsEinzelkontaktKontoinhaber.Size := 50;
    xdsEinzelkontaktBetreuer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBetreuer.Name := 'xdsEinzelkontaktBetreuer';
    xdsEinzelkontaktBetreuer.FieldName := 'Betreuer';
    xdsEinzelkontaktBetreuer.Size := 50;
    xdsEinzelkontaktQuelle.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktQuelle.Name := 'xdsEinzelkontaktQuelle';
    xdsEinzelkontaktQuelle.FieldName := 'Quelle';
    xdsEinzelkontaktQuelle.Size := 100;
    xdsEinzelkontaktSuchmerkmal.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSuchmerkmal.Name := 'xdsEinzelkontaktSuchmerkmal';
    xdsEinzelkontaktSuchmerkmal.FieldName := 'Suchmerkmal';
    xdsEinzelkontaktSuchmerkmal.Size := 250;
    xdsEinzelkontaktWarenkreditlimit.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktWarenkreditlimit.Name := 'xdsEinzelkontaktWarenkreditlimit';
    xdsEinzelkontaktWarenkreditlimit.FieldName := 'Warenkreditlimit';
    xdsEinzelkontaktWarenkreditgesperrt.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktWarenkreditgesperrt.Name := 'xdsEinzelkontaktWarenkreditgesperrt';
    xdsEinzelkontaktWarenkreditgesperrt.FieldName := 'Warenkreditgesperrt';
    xdsEinzelkontaktKundennummerFremd.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKundennummerFremd.Name := 'xdsEinzelkontaktKundennummerFremd';
    xdsEinzelkontaktKundennummerFremd.FieldName := 'KundennummerFremd';
    xdsEinzelkontaktKeinNewsletter.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKeinNewsletter.Name := 'xdsEinzelkontaktKeinNewsletter';
    xdsEinzelkontaktKeinNewsletter.FieldName := 'KeinNewsletter';
    xdsEinzelkontaktDebitorennummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktDebitorennummer.Name := 'xdsEinzelkontaktDebitorennummer';
    xdsEinzelkontaktDebitorennummer.FieldName := 'Debitorennummer';
    xdsEinzelkontaktDebitorennummer.Size := 13;
    xdsEinzelkontaktKreditorennummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditorennummer.Name := 'xdsEinzelkontaktKreditorennummer';
    xdsEinzelkontaktKreditorennummer.FieldName := 'Kreditorennummer';
    xdsEinzelkontaktKreditorennummer.Size := 13;
    xdsEinzelkontaktTelefonAlternativ.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTelefonAlternativ.Name := 'xdsEinzelkontaktTelefonAlternativ';
    xdsEinzelkontaktTelefonAlternativ.FieldName := 'TelefonAlternativ';
    xdsEinzelkontaktTelefonAlternativ.Size := 50;
    xdsEinzelkontaktKostentraeger.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKostentraeger.Name := 'xdsEinzelkontaktKostentraeger';
    xdsEinzelkontaktKostentraeger.FieldName := 'Kostentraeger';
    xdsEinzelkontaktKostentraeger.Size := 13;
    xdsEinzelkontaktIBAN.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktIBAN.Name := 'xdsEinzelkontaktIBAN';
    xdsEinzelkontaktIBAN.FieldName := 'IBAN';
    xdsEinzelkontaktIBAN.Size := 50;
    xdsEinzelkontaktBIC.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBIC.Name := 'xdsEinzelkontaktBIC';
    xdsEinzelkontaktBIC.FieldName := 'BIC';
    xdsEinzelkontaktEmailkonto.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktEmailkonto.Name := 'xdsEinzelkontaktEmailkonto';
    xdsEinzelkontaktEmailkonto.FieldName := 'Emailkonto';
    xdsEinzelkontaktEmailkonto.Size := 50;
    xdsEinzelkontaktBenutzername.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBenutzername.Name := 'xdsEinzelkontaktBenutzername';
    xdsEinzelkontaktBenutzername.FieldName := 'Benutzername';
    xdsEinzelkontaktBenutzername.Size := 50;
    xdsEinzelkontaktPasswort.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktPasswort.Name := 'xdsEinzelkontaktPasswort';
    xdsEinzelkontaktPasswort.FieldName := 'Passwort';
    xdsEinzelkontaktPasswort.Size := 25;
    xdsEinzelkontaktExportOutlook.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktExportOutlook.Name := 'xdsEinzelkontaktExportOutlook';
    xdsEinzelkontaktExportOutlook.FieldName := 'ExportOutlook';
    xdsEinzelkontaktNichtInListeAnzeigen.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktNichtInListeAnzeigen.Name := 'xdsEinzelkontaktNichtInListeAnzeigen';
    xdsEinzelkontaktNichtInListeAnzeigen.FieldName := 'NichtInListeAnzeigen';
    xdsEinzelkontaktKreditkarteArt.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkarteArt.Name := 'xdsEinzelkontaktKreditkarteArt';
    xdsEinzelkontaktKreditkarteArt.FieldName := 'KreditkarteArt';
    xdsEinzelkontaktKreditkarteArt.Size := 30;
    xdsEinzelkontaktKreditkarteInhaber.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkarteInhaber.Name := 'xdsEinzelkontaktKreditkarteInhaber';
    xdsEinzelkontaktKreditkarteInhaber.FieldName := 'KreditkarteInhaber';
    xdsEinzelkontaktKreditkarteInhaber.Size := 50;
    xdsEinzelkontaktKreditkarteNummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkarteNummer.Name := 'xdsEinzelkontaktKreditkarteNummer';
    xdsEinzelkontaktKreditkarteNummer.FieldName := 'KreditkarteNummer';
    xdsEinzelkontaktKreditkarteGueltigMonat.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkarteGueltigMonat.Name := 'xdsEinzelkontaktKreditkarteGueltigMonat';
    xdsEinzelkontaktKreditkarteGueltigMonat.FieldName := 'KreditkarteGueltigMonat';
    xdsEinzelkontaktKreditkarteGueltigMonat.Size := 2;
    xdsEinzelkontaktKreditkarteGueltigJahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkarteGueltigJahr.Name := 'xdsEinzelkontaktKreditkarteGueltigJahr';
    xdsEinzelkontaktKreditkarteGueltigJahr.FieldName := 'KreditkarteGueltigJahr';
    xdsEinzelkontaktKreditkartePruefziffer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKreditkartePruefziffer.Name := 'xdsEinzelkontaktKreditkartePruefziffer';
    xdsEinzelkontaktKreditkartePruefziffer.FieldName := 'KreditkartePruefziffer';
    xdsEinzelkontaktKreditkartePruefziffer.Size := 10;
    xdsEinzelkontaktDatumNeu.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktDatumNeu.Name := 'xdsEinzelkontaktDatumNeu';
    xdsEinzelkontaktDatumNeu.FieldName := 'DatumNeu';
    xdsEinzelkontaktDatumgeaendert.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktDatumgeaendert.Name := 'xdsEinzelkontaktDatumgeaendert';
    xdsEinzelkontaktDatumgeaendert.FieldName := 'Datumgeaendert';
    xdsEinzelkontaktTitel.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTitel.Name := 'xdsEinzelkontaktTitel';
    xdsEinzelkontaktTitel.FieldName := 'Titel';
    xdsEinzelkontaktStundensatzMontage.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStundensatzMontage.Name := 'xdsEinzelkontaktStundensatzMontage';
    xdsEinzelkontaktStundensatzMontage.FieldName := 'StundensatzMontage';
    xdsEinzelkontaktStdMontageU25.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdMontageU25.Name := 'xdsEinzelkontaktStdMontageU25';
    xdsEinzelkontaktStdMontageU25.FieldName := 'StdMontageU25';
    xdsEinzelkontaktStdMontageU50.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdMontageU50.Name := 'xdsEinzelkontaktStdMontageU50';
    xdsEinzelkontaktStdMontageU50.FieldName := 'StdMontageU50';
    xdsEinzelkontaktStdMontageU70.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdMontageU70.Name := 'xdsEinzelkontaktStdMontageU70';
    xdsEinzelkontaktStdMontageU70.FieldName := 'StdMontageU70';
    xdsEinzelkontaktStdMontageU100.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdMontageU100.Name := 'xdsEinzelkontaktStdMontageU100';
    xdsEinzelkontaktStdMontageU100.FieldName := 'StdMontageU100';
    xdsEinzelkontaktStdMontageU150.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdMontageU150.Name := 'xdsEinzelkontaktStdMontageU150';
    xdsEinzelkontaktStdMontageU150.FieldName := 'StdMontageU150';
    xdsEinzelkontaktStundensatzWerkstatt.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStundensatzWerkstatt.Name := 'xdsEinzelkontaktStundensatzWerkstatt';
    xdsEinzelkontaktStundensatzWerkstatt.FieldName := 'StundensatzWerkstatt';
    xdsEinzelkontaktStdWerkstattU25.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdWerkstattU25.Name := 'xdsEinzelkontaktStdWerkstattU25';
    xdsEinzelkontaktStdWerkstattU25.FieldName := 'StdWerkstattU25';
    xdsEinzelkontaktStdWerkstattU50.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdWerkstattU50.Name := 'xdsEinzelkontaktStdWerkstattU50';
    xdsEinzelkontaktStdWerkstattU50.FieldName := 'StdWerkstattU50';
    xdsEinzelkontaktStdWerkstattU70.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdWerkstattU70.Name := 'xdsEinzelkontaktStdWerkstattU70';
    xdsEinzelkontaktStdWerkstattU70.FieldName := 'StdWerkstattU70';
    xdsEinzelkontaktStdWerkstattU100.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdWerkstattU100.Name := 'xdsEinzelkontaktStdWerkstattU100';
    xdsEinzelkontaktStdWerkstattU100.FieldName := 'StdWerkstattU100';
    xdsEinzelkontaktStdWerkstattU150.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktStdWerkstattU150.Name := 'xdsEinzelkontaktStdWerkstattU150';
    xdsEinzelkontaktStdWerkstattU150.FieldName := 'StdWerkstattU150';
    xdsEinzelkontaktArchivReferenz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktArchivReferenz.Name := 'xdsEinzelkontaktArchivReferenz';
    xdsEinzelkontaktArchivReferenz.FieldName := 'ArchivReferenz';
    xdsEinzelkontaktArchivReferenz.Size := 55;
    xdsEinzelkontaktErloesKontoUST1.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST1.Name := 'xdsEinzelkontaktErloesKontoUST1';
    xdsEinzelkontaktErloesKontoUST1.FieldName := 'ErloesKontoUST1';
    xdsEinzelkontaktErloesKontoUST1.Size := 10;
    xdsEinzelkontaktErloesKontoUST2.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST2.Name := 'xdsEinzelkontaktErloesKontoUST2';
    xdsEinzelkontaktErloesKontoUST2.FieldName := 'ErloesKontoUST2';
    xdsEinzelkontaktErloesKontoUST2.Size := 10;
    xdsEinzelkontaktErloesKontoUST3.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST3.Name := 'xdsEinzelkontaktErloesKontoUST3';
    xdsEinzelkontaktErloesKontoUST3.FieldName := 'ErloesKontoUST3';
    xdsEinzelkontaktErloesKontoUST3.Size := 10;
    xdsEinzelkontaktGebKal.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGebKal.Name := 'xdsEinzelkontaktGebKal';
    xdsEinzelkontaktGebKal.FieldName := 'GebKal';
    xdsEinzelkontaktEmailversand.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktEmailversand.Name := 'xdsEinzelkontaktEmailversand';
    xdsEinzelkontaktEmailversand.FieldName := 'Emailversand';
    xdsEinzelkontaktMahnspesen.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMahnspesen.Name := 'xdsEinzelkontaktMahnspesen';
    xdsEinzelkontaktMahnspesen.FieldName := 'Mahnspesen';
    xdsEinzelkontaktRechnungSchluss.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktRechnungSchluss.Name := 'xdsEinzelkontaktRechnungSchluss';
    xdsEinzelkontaktRechnungSchluss.FieldName := 'RechnungSchluss';
    xdsEinzelkontaktRechnungSchluss.Size := 255;
    xdsEinzelkontaktBenutzerNeu.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBenutzerNeu.Name := 'xdsEinzelkontaktBenutzerNeu';
    xdsEinzelkontaktBenutzerNeu.FieldName := 'BenutzerNeu';
    xdsEinzelkontaktBenutzerNeu.Size := 50;
    xdsEinzelkontaktBenutzerGeaendert.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBenutzerGeaendert.Name := 'xdsEinzelkontaktBenutzerGeaendert';
    xdsEinzelkontaktBenutzerGeaendert.FieldName := 'BenutzerGeaendert';
    xdsEinzelkontaktBenutzerGeaendert.Size := 50;
    xdsEinzelkontaktBelegsprache.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBelegsprache.Name := 'xdsEinzelkontaktBelegsprache';
    xdsEinzelkontaktBelegsprache.FieldName := 'Belegsprache';
    xdsEinzelkontaktBelegsprache.Size := 10;
    xdsEinzelkontaktkmSatz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktkmSatz.Name := 'xdsEinzelkontaktkmSatz';
    xdsEinzelkontaktkmSatz.FieldName := 'kmSatz';
    xdsEinzelkontaktKundenklassetext.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKundenklassetext.Name := 'xdsEinzelkontaktKundenklassetext';
    xdsEinzelkontaktKundenklassetext.FieldName := 'Kundenklassetext';
    xdsEinzelkontaktKundenklassetext.Size := 50;
    xdsEinzelkontaktKundenklasseFarbe.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKundenklasseFarbe.Name := 'xdsEinzelkontaktKundenklasseFarbe';
    xdsEinzelkontaktKundenklasseFarbe.FieldName := 'KundenklasseFarbe';
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.Name := 'xdsEinzelkontaktAbweichenderRechnungsEmpfaenger';
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.FieldName := 'AbweichenderRechnungsEmpfaenger';
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.Size := 13;
    xdsEinzelkontaktProtokoll.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktProtokoll.Name := 'xdsEinzelkontaktProtokoll';
    xdsEinzelkontaktProtokoll.FieldName := 'Protokoll';
    xdsEinzelkontaktProtokoll.Size := 255;
    xdsEinzelkontaktILNTyp1.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktILNTyp1.Name := 'xdsEinzelkontaktILNTyp1';
    xdsEinzelkontaktILNTyp1.FieldName := 'ILNTyp1';
    xdsEinzelkontaktILNTyp1.Size := 13;
    xdsEinzelkontaktILNTyp2.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktILNTyp2.Name := 'xdsEinzelkontaktILNTyp2';
    xdsEinzelkontaktILNTyp2.FieldName := 'ILNTyp2';
    xdsEinzelkontaktILNTyp2.Size := 13;
    xdsEinzelkontaktKontaktGUID.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKontaktGUID.Name := 'xdsEinzelkontaktKontaktGUID';
    xdsEinzelkontaktKontaktGUID.FieldName := 'KontaktGUID';
    xdsEinzelkontaktKontaktGUID.Size := 38;
    xdsEinzelkontaktPLZGUID.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktPLZGUID.Name := 'xdsEinzelkontaktPLZGUID';
    xdsEinzelkontaktPLZGUID.FieldName := 'PLZGUID';
    xdsEinzelkontaktPLZGUID.Size := 38;
    xdsEinzelkontaktPreislistenVariantePakete.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktPreislistenVariantePakete.Name := 'xdsEinzelkontaktPreislistenVariantePakete';
    xdsEinzelkontaktPreislistenVariantePakete.FieldName := 'PreislistenVariantePakete';
    xdsEinzelkontaktPreislistenVariantePakete.Size := 50;
    xdsEinzelkontaktSchluesselRechnungslauf.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSchluesselRechnungslauf.Name := 'xdsEinzelkontaktSchluesselRechnungslauf';
    xdsEinzelkontaktSchluesselRechnungslauf.FieldName := 'SchluesselRechnungslauf';
    xdsEinzelkontaktSchluesselRechnungslauf.Size := 50;
    xdsEinzelkontaktRechnungEmail.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktRechnungEmail.Name := 'xdsEinzelkontaktRechnungEmail';
    xdsEinzelkontaktRechnungEmail.FieldName := 'RechnungEmail';
    xdsEinzelkontaktRechnungEmail.Size := 50;
    xdsEinzelkontaktZahlungsbedingungEK.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktZahlungsbedingungEK.Name := 'xdsEinzelkontaktZahlungsbedingungEK';
    xdsEinzelkontaktZahlungsbedingungEK.FieldName := 'ZahlungsbedingungEK';
    xdsEinzelkontaktTransportbedingungEK.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTransportbedingungEK.Name := 'xdsEinzelkontaktTransportbedingungEK';
    xdsEinzelkontaktTransportbedingungEK.FieldName := 'TransportbedingungEK';
    xdsEinzelkontaktTransportbedingungEK.Size := 100;
    xdsEinzelkontaktRabattsatzEK.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktRabattsatzEK.Name := 'xdsEinzelkontaktRabattsatzEK';
    xdsEinzelkontaktRabattsatzEK.FieldName := 'RabattsatzEK';
    xdsEinzelkontaktVertreterMatchcode.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktVertreterMatchcode.Name := 'xdsEinzelkontaktVertreterMatchcode';
    xdsEinzelkontaktVertreterMatchcode.FieldName := 'VertreterMatchcode';
    xdsEinzelkontaktVertreterMatchcode.Size := 50;
    xdsEinzelkontaktVertreterProvisionssatz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktVertreterProvisionssatz.Name := 'xdsEinzelkontaktVertreterProvisionssatz';
    xdsEinzelkontaktVertreterProvisionssatz.FieldName := 'VertreterProvisionssatz';
    xdsEinzelkontaktUmsatzVKJahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzVKJahr.Name := 'xdsEinzelkontaktUmsatzVKJahr';
    xdsEinzelkontaktUmsatzVKJahr.FieldName := 'UmsatzVKJahr';
    xdsEinzelkontaktUmsatzVKVorjahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzVKVorjahr.Name := 'xdsEinzelkontaktUmsatzVKVorjahr';
    xdsEinzelkontaktUmsatzVKVorjahr.FieldName := 'UmsatzVKVorjahr';
    xdsEinzelkontaktUmsatzEKJahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzEKJahr.Name := 'xdsEinzelkontaktUmsatzEKJahr';
    xdsEinzelkontaktUmsatzEKJahr.FieldName := 'UmsatzEKJahr';
    xdsEinzelkontaktUmsatzEKVorjahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUmsatzEKVorjahr.Name := 'xdsEinzelkontaktUmsatzEKVorjahr';
    xdsEinzelkontaktUmsatzEKVorjahr.FieldName := 'UmsatzEKVorjahr';
    xdsEinzelkontaktSteuernummer.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSteuernummer.Name := 'xdsEinzelkontaktSteuernummer';
    xdsEinzelkontaktSteuernummer.FieldName := 'Steuernummer';
    xdsEinzelkontaktSteuernummer.Size := 25;
    xdsEinzelkontaktLoginPW.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLoginPW.Name := 'xdsEinzelkontaktLoginPW';
    xdsEinzelkontaktLoginPW.FieldName := 'LoginPW';
    xdsEinzelkontaktREperFax.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktREperFax.Name := 'xdsEinzelkontaktREperFax';
    xdsEinzelkontaktREperFax.FieldName := 'REperFax';
    xdsEinzelkontaktErreichbarkeit.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErreichbarkeit.Name := 'xdsEinzelkontaktErreichbarkeit';
    xdsEinzelkontaktErreichbarkeit.FieldName := 'Erreichbarkeit';
    xdsEinzelkontaktErreichbarkeit.Size := 250;
    xdsEinzelkontaktErloesKontoUST4.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST4.Name := 'xdsEinzelkontaktErloesKontoUST4';
    xdsEinzelkontaktErloesKontoUST4.FieldName := 'ErloesKontoUST4';
    xdsEinzelkontaktErloesKontoUST4.Size := 10;
    xdsEinzelkontaktErloesKontoUST5.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST5.Name := 'xdsEinzelkontaktErloesKontoUST5';
    xdsEinzelkontaktErloesKontoUST5.FieldName := 'ErloesKontoUST5';
    xdsEinzelkontaktErloesKontoUST5.Size := 10;
    xdsEinzelkontaktErloesKontoUST6.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktErloesKontoUST6.Name := 'xdsEinzelkontaktErloesKontoUST6';
    xdsEinzelkontaktErloesKontoUST6.FieldName := 'ErloesKontoUST6';
    xdsEinzelkontaktErloesKontoUST6.Size := 10;
    xdsEinzelkontaktBesteuerungKey.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBesteuerungKey.Name := 'xdsEinzelkontaktBesteuerungKey';
    xdsEinzelkontaktBesteuerungKey.FieldName := 'BesteuerungKey';
    xdsEinzelkontaktAuftraegeJahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAuftraegeJahr.Name := 'xdsEinzelkontaktAuftraegeJahr';
    xdsEinzelkontaktAuftraegeJahr.FieldName := 'AuftraegeJahr';
    xdsEinzelkontaktAuftraegeVorjahr.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktAuftraegeVorjahr.Name := 'xdsEinzelkontaktAuftraegeVorjahr';
    xdsEinzelkontaktAuftraegeVorjahr.FieldName := 'AuftraegeVorjahr';
    xdsEinzelkontaktLieferadresseID.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLieferadresseID.Name := 'xdsEinzelkontaktLieferadresseID';
    xdsEinzelkontaktLieferadresseID.FieldName := 'LieferadresseID';
    xdsEinzelkontaktUSTIDgeprueft.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktUSTIDgeprueft.Name := 'xdsEinzelkontaktUSTIDgeprueft';
    xdsEinzelkontaktUSTIDgeprueft.FieldName := 'USTIDgeprueft';
    xdsEinzelkontaktKontaktverbund.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktKontaktverbund.Name := 'xdsEinzelkontaktKontaktverbund';
    xdsEinzelkontaktKontaktverbund.FieldName := 'Kontaktverbund';
    xdsEinzelkontaktKontaktverbund.Size := 25;
    xdsEinzelkontaktHinweis.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktHinweis.Name := 'xdsEinzelkontaktHinweis';
    xdsEinzelkontaktHinweis.FieldName := 'Hinweis';
    xdsEinzelkontaktHinweis.Size := 255;
    xdsEinzelkontaktLatitude.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLatitude.Name := 'xdsEinzelkontaktLatitude';
    xdsEinzelkontaktLatitude.FieldName := 'Latitude';
    xdsEinzelkontaktLongitude.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLongitude.Name := 'xdsEinzelkontaktLongitude';
    xdsEinzelkontaktLongitude.FieldName := 'Longitude';
    xdsEinzelkontaktkmLuftlinie.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktkmLuftlinie.Name := 'xdsEinzelkontaktkmLuftlinie';
    xdsEinzelkontaktkmLuftlinie.FieldName := 'kmLuftlinie';
    xdsEinzelkontaktGoogleID.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGoogleID.Name := 'xdsEinzelkontaktGoogleID';
    xdsEinzelkontaktGoogleID.FieldName := 'GoogleID';
    xdsEinzelkontaktGoogleID.Size := 50;
    xdsEinzelkontaktGoogleUpdatedUTC.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktGoogleUpdatedUTC.Name := 'xdsEinzelkontaktGoogleUpdatedUTC';
    xdsEinzelkontaktGoogleUpdatedUTC.FieldName := 'GoogleUpdatedUTC';
    xdsEinzelkontaktBundesland.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktBundesland.Name := 'xdsEinzelkontaktBundesland';
    xdsEinzelkontaktBundesland.FieldName := 'Bundesland';
    xdsEinzelkontaktBundesland.Size := 50;
    xdsEinzelkontaktRegion.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktRegion.Name := 'xdsEinzelkontaktRegion';
    xdsEinzelkontaktRegion.FieldName := 'Region';
    xdsEinzelkontaktRegion.Size := 50;
    xdsEinzelkontaktMandatsreferenz.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMandatsreferenz.Name := 'xdsEinzelkontaktMandatsreferenz';
    xdsEinzelkontaktMandatsreferenz.FieldName := 'Mandatsreferenz';
    xdsEinzelkontaktMandatsreferenz.Size := 34;
    xdsEinzelkontaktMandatsreferenzVom.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMandatsreferenzVom.Name := 'xdsEinzelkontaktMandatsreferenzVom';
    xdsEinzelkontaktMandatsreferenzVom.FieldName := 'MandatsreferenzVom';
    xdsEinzelkontaktLastschriftArt.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLastschriftArt.Name := 'xdsEinzelkontaktLastschriftArt';
    xdsEinzelkontaktLastschriftArt.FieldName := 'LastschriftArt';
    xdsEinzelkontaktLastschriftArt.Size := 2;
    xdsEinzelkontaktMahnsperre.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMahnsperre.Name := 'xdsEinzelkontaktMahnsperre';
    xdsEinzelkontaktMahnsperre.FieldName := 'Mahnsperre';
    xdsEinzelkontaktSuchbegriff.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSuchbegriff.Name := 'xdsEinzelkontaktSuchbegriff';
    xdsEinzelkontaktSuchbegriff.FieldName := 'Suchbegriff';
    xdsEinzelkontaktSuchbegriff.Size := 25;
    xdsEinzelkontaktLieferanschriftDaten.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLieferanschriftDaten.Name := 'xdsEinzelkontaktLieferanschriftDaten';
    xdsEinzelkontaktLieferanschriftDaten.FieldName := 'LieferanschriftDaten';
    xdsEinzelkontaktLieferanschriftDaten.Size := 255;
    xdsEinzelkontaktExportKasse.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktExportKasse.Name := 'xdsEinzelkontaktExportKasse';
    xdsEinzelkontaktExportKasse.FieldName := 'ExportKasse';
    xdsEinzelkontaktLoeschvormerkungDatum.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLoeschvormerkungDatum.Name := 'xdsEinzelkontaktLoeschvormerkungDatum';
    xdsEinzelkontaktLoeschvormerkungDatum.FieldName := 'LoeschvormerkungDatum';
    xdsEinzelkontaktLoeschvormerkungGrund.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLoeschvormerkungGrund.Name := 'xdsEinzelkontaktLoeschvormerkungGrund';
    xdsEinzelkontaktLoeschvormerkungGrund.FieldName := 'LoeschvormerkungGrund';
    xdsEinzelkontaktLoeschvormerkungGrund.Size := 250;
    xdsEinzelkontaktPseudonymisiert.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktPseudonymisiert.Name := 'xdsEinzelkontaktPseudonymisiert';
    xdsEinzelkontaktPseudonymisiert.FieldName := 'Pseudonymisiert';
    xdsEinzelkontaktPseudonymisiert.Size := 255;
    xdsEinzelkontaktLoginAktiv.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktLoginAktiv.Name := 'xdsEinzelkontaktLoginAktiv';
    xdsEinzelkontaktLoginAktiv.FieldName := 'LoginAktiv';
    xdsEinzelkontaktSonstigeDaten.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktSonstigeDaten.Name := 'xdsEinzelkontaktSonstigeDaten';
    xdsEinzelkontaktSonstigeDaten.FieldName := 'SonstigeDaten';
    xdsEinzelkontaktSonstigeDaten.Size := 255;
    xdsEinzelkontaktB2C.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktB2C.Name := 'xdsEinzelkontaktB2C';
    xdsEinzelkontaktB2C.FieldName := 'B2C';
    xdsEinzelkontaktTIN.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktTIN.Name := 'xdsEinzelkontaktTIN';
    xdsEinzelkontaktTIN.FieldName := 'TIN';
    xdsEinzelkontaktTIN.Size := 25;
    xdsEinzelkontaktMindestbestellwert.SetParentComponent(xdsEinzelkontakt);
    xdsEinzelkontaktMindestbestellwert.Name := 'xdsEinzelkontaktMindestbestellwert';
    xdsEinzelkontaktMindestbestellwert.FieldName := 'Mindestbestellwert';
    sourceEinzelkontakt.SetParentComponent(Self);
    sourceEinzelkontakt.Name := 'sourceEinzelkontakt';
    sourceEinzelkontakt.DataSet := xdsEinzelkontakt;
    sourceEinzelkontakt.Left := 20;
    sourceEinzelkontakt.Top := 69;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 24;
    WClient.Top := 8;
  finally
    TC.AfterLoadDFMValues;
    xdsEinzelkontakt.AfterLoadDFMValues;
    xdsEinzelkontaktKundennummer.AfterLoadDFMValues;
    xdsEinzelkontaktGruppeKunden.AfterLoadDFMValues;
    xdsEinzelkontaktGruppeLieferanten.AfterLoadDFMValues;
    xdsEinzelkontaktGruppeSonstige.AfterLoadDFMValues;
    xdsEinzelkontaktGruppeNichtkunden.AfterLoadDFMValues;
    xdsEinzelkontaktKontaktgruppe.AfterLoadDFMValues;
    xdsEinzelkontaktNachname.AfterLoadDFMValues;
    xdsEinzelkontaktVorname.AfterLoadDFMValues;
    xdsEinzelkontaktAdresszeile2.AfterLoadDFMValues;
    xdsEinzelkontaktAdresszeile3.AfterLoadDFMValues;
    xdsEinzelkontaktStrasse.AfterLoadDFMValues;
    xdsEinzelkontaktPostleitzahl.AfterLoadDFMValues;
    xdsEinzelkontaktOrt.AfterLoadDFMValues;
    xdsEinzelkontaktLaenderkuerzel.AfterLoadDFMValues;
    xdsEinzelkontaktLand.AfterLoadDFMValues;
    xdsEinzelkontaktLaenderkennzeichen.AfterLoadDFMValues;
    xdsEinzelkontaktAdressanrede.AfterLoadDFMValues;
    xdsEinzelkontaktBriefanrede1.AfterLoadDFMValues;
    xdsEinzelkontaktBriefanrede2.AfterLoadDFMValues;
    xdsEinzelkontaktAdresseBild.AfterLoadDFMValues;
    xdsEinzelkontaktLieferanschrift.AfterLoadDFMValues;
    xdsEinzelkontaktGeburtsdatum.AfterLoadDFMValues;
    xdsEinzelkontaktTelefonNummer.AfterLoadDFMValues;
    xdsEinzelkontaktTelefaxNummer.AfterLoadDFMValues;
    xdsEinzelkontaktMobilTelefon.AfterLoadDFMValues;
    xdsEinzelkontaktEMail.AfterLoadDFMValues;
    xdsEinzelkontaktInternet.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzsteuerID.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzsteuerausweis.AfterLoadDFMValues;
    xdsEinzelkontaktBemerkungen.AfterLoadDFMValues;
    xdsEinzelkontaktBranche.AfterLoadDFMValues;
    xdsEinzelkontaktSelektiert1.AfterLoadDFMValues;
    xdsEinzelkontaktSelektiert2.AfterLoadDFMValues;
    xdsEinzelkontaktSelektiert3.AfterLoadDFMValues;
    xdsEinzelkontaktZahlungsbedingung.AfterLoadDFMValues;
    xdsEinzelkontaktTransportbedingung.AfterLoadDFMValues;
    xdsEinzelkontaktKilometer.AfterLoadDFMValues;
    xdsEinzelkontaktRabattsatz.AfterLoadDFMValues;
    xdsEinzelkontaktStundensatz.AfterLoadDFMValues;
    xdsEinzelkontaktKontonummer.AfterLoadDFMValues;
    xdsEinzelkontaktBank.AfterLoadDFMValues;
    xdsEinzelkontaktBankleitzahl.AfterLoadDFMValues;
    xdsEinzelkontaktKontoinhaber.AfterLoadDFMValues;
    xdsEinzelkontaktBetreuer.AfterLoadDFMValues;
    xdsEinzelkontaktQuelle.AfterLoadDFMValues;
    xdsEinzelkontaktSuchmerkmal.AfterLoadDFMValues;
    xdsEinzelkontaktWarenkreditlimit.AfterLoadDFMValues;
    xdsEinzelkontaktWarenkreditgesperrt.AfterLoadDFMValues;
    xdsEinzelkontaktKundennummerFremd.AfterLoadDFMValues;
    xdsEinzelkontaktKeinNewsletter.AfterLoadDFMValues;
    xdsEinzelkontaktDebitorennummer.AfterLoadDFMValues;
    xdsEinzelkontaktKreditorennummer.AfterLoadDFMValues;
    xdsEinzelkontaktTelefonAlternativ.AfterLoadDFMValues;
    xdsEinzelkontaktKostentraeger.AfterLoadDFMValues;
    xdsEinzelkontaktIBAN.AfterLoadDFMValues;
    xdsEinzelkontaktBIC.AfterLoadDFMValues;
    xdsEinzelkontaktEmailkonto.AfterLoadDFMValues;
    xdsEinzelkontaktBenutzername.AfterLoadDFMValues;
    xdsEinzelkontaktPasswort.AfterLoadDFMValues;
    xdsEinzelkontaktExportOutlook.AfterLoadDFMValues;
    xdsEinzelkontaktNichtInListeAnzeigen.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkarteArt.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkarteInhaber.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkarteNummer.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkarteGueltigMonat.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkarteGueltigJahr.AfterLoadDFMValues;
    xdsEinzelkontaktKreditkartePruefziffer.AfterLoadDFMValues;
    xdsEinzelkontaktDatumNeu.AfterLoadDFMValues;
    xdsEinzelkontaktDatumgeaendert.AfterLoadDFMValues;
    xdsEinzelkontaktTitel.AfterLoadDFMValues;
    xdsEinzelkontaktStundensatzMontage.AfterLoadDFMValues;
    xdsEinzelkontaktStdMontageU25.AfterLoadDFMValues;
    xdsEinzelkontaktStdMontageU50.AfterLoadDFMValues;
    xdsEinzelkontaktStdMontageU70.AfterLoadDFMValues;
    xdsEinzelkontaktStdMontageU100.AfterLoadDFMValues;
    xdsEinzelkontaktStdMontageU150.AfterLoadDFMValues;
    xdsEinzelkontaktStundensatzWerkstatt.AfterLoadDFMValues;
    xdsEinzelkontaktStdWerkstattU25.AfterLoadDFMValues;
    xdsEinzelkontaktStdWerkstattU50.AfterLoadDFMValues;
    xdsEinzelkontaktStdWerkstattU70.AfterLoadDFMValues;
    xdsEinzelkontaktStdWerkstattU100.AfterLoadDFMValues;
    xdsEinzelkontaktStdWerkstattU150.AfterLoadDFMValues;
    xdsEinzelkontaktArchivReferenz.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST1.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST2.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST3.AfterLoadDFMValues;
    xdsEinzelkontaktGebKal.AfterLoadDFMValues;
    xdsEinzelkontaktEmailversand.AfterLoadDFMValues;
    xdsEinzelkontaktMahnspesen.AfterLoadDFMValues;
    xdsEinzelkontaktRechnungSchluss.AfterLoadDFMValues;
    xdsEinzelkontaktBenutzerNeu.AfterLoadDFMValues;
    xdsEinzelkontaktBenutzerGeaendert.AfterLoadDFMValues;
    xdsEinzelkontaktBelegsprache.AfterLoadDFMValues;
    xdsEinzelkontaktkmSatz.AfterLoadDFMValues;
    xdsEinzelkontaktKundenklassetext.AfterLoadDFMValues;
    xdsEinzelkontaktKundenklasseFarbe.AfterLoadDFMValues;
    xdsEinzelkontaktAbweichenderRechnungsEmpfaenger.AfterLoadDFMValues;
    xdsEinzelkontaktProtokoll.AfterLoadDFMValues;
    xdsEinzelkontaktILNTyp1.AfterLoadDFMValues;
    xdsEinzelkontaktILNTyp2.AfterLoadDFMValues;
    xdsEinzelkontaktKontaktGUID.AfterLoadDFMValues;
    xdsEinzelkontaktPLZGUID.AfterLoadDFMValues;
    xdsEinzelkontaktPreislistenVariantePakete.AfterLoadDFMValues;
    xdsEinzelkontaktSchluesselRechnungslauf.AfterLoadDFMValues;
    xdsEinzelkontaktRechnungEmail.AfterLoadDFMValues;
    xdsEinzelkontaktZahlungsbedingungEK.AfterLoadDFMValues;
    xdsEinzelkontaktTransportbedingungEK.AfterLoadDFMValues;
    xdsEinzelkontaktRabattsatzEK.AfterLoadDFMValues;
    xdsEinzelkontaktVertreterMatchcode.AfterLoadDFMValues;
    xdsEinzelkontaktVertreterProvisionssatz.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzVKJahr.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzVKVorjahr.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzEKJahr.AfterLoadDFMValues;
    xdsEinzelkontaktUmsatzEKVorjahr.AfterLoadDFMValues;
    xdsEinzelkontaktSteuernummer.AfterLoadDFMValues;
    xdsEinzelkontaktLoginPW.AfterLoadDFMValues;
    xdsEinzelkontaktREperFax.AfterLoadDFMValues;
    xdsEinzelkontaktErreichbarkeit.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST4.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST5.AfterLoadDFMValues;
    xdsEinzelkontaktErloesKontoUST6.AfterLoadDFMValues;
    xdsEinzelkontaktBesteuerungKey.AfterLoadDFMValues;
    xdsEinzelkontaktAuftraegeJahr.AfterLoadDFMValues;
    xdsEinzelkontaktAuftraegeVorjahr.AfterLoadDFMValues;
    xdsEinzelkontaktLieferadresseID.AfterLoadDFMValues;
    xdsEinzelkontaktUSTIDgeprueft.AfterLoadDFMValues;
    xdsEinzelkontaktKontaktverbund.AfterLoadDFMValues;
    xdsEinzelkontaktHinweis.AfterLoadDFMValues;
    xdsEinzelkontaktLatitude.AfterLoadDFMValues;
    xdsEinzelkontaktLongitude.AfterLoadDFMValues;
    xdsEinzelkontaktkmLuftlinie.AfterLoadDFMValues;
    xdsEinzelkontaktGoogleID.AfterLoadDFMValues;
    xdsEinzelkontaktGoogleUpdatedUTC.AfterLoadDFMValues;
    xdsEinzelkontaktBundesland.AfterLoadDFMValues;
    xdsEinzelkontaktRegion.AfterLoadDFMValues;
    xdsEinzelkontaktMandatsreferenz.AfterLoadDFMValues;
    xdsEinzelkontaktMandatsreferenzVom.AfterLoadDFMValues;
    xdsEinzelkontaktLastschriftArt.AfterLoadDFMValues;
    xdsEinzelkontaktMahnsperre.AfterLoadDFMValues;
    xdsEinzelkontaktSuchbegriff.AfterLoadDFMValues;
    xdsEinzelkontaktLieferanschriftDaten.AfterLoadDFMValues;
    xdsEinzelkontaktExportKasse.AfterLoadDFMValues;
    xdsEinzelkontaktLoeschvormerkungDatum.AfterLoadDFMValues;
    xdsEinzelkontaktLoeschvormerkungGrund.AfterLoadDFMValues;
    xdsEinzelkontaktPseudonymisiert.AfterLoadDFMValues;
    xdsEinzelkontaktLoginAktiv.AfterLoadDFMValues;
    xdsEinzelkontaktSonstigeDaten.AfterLoadDFMValues;
    xdsEinzelkontaktB2C.AfterLoadDFMValues;
    xdsEinzelkontaktTIN.AfterLoadDFMValues;
    xdsEinzelkontaktMindestbestellwert.AfterLoadDFMValues;
    sourceEinzelkontakt.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
  end;
end;

end.
