{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2017 - 2020                                 }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCTableView;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTreeView, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCStyles, WEBLib.TMSFNCTreeViewData, WEBLib.TMSFNCCustomControl,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCCustomTreeView, WEBLib.TMSFNCBitmapContainer,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCEdit, WEBLib.TMSFNCToolBar, WEBLib.Controls, WEBLib.ExtCtrls
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  ,UITypes, Types, Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs
  {$ENDIF}
  ,TypInfo
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 3; // Release nr.
  BLD_VER = 0; // Build nr.
  CLP_FMT = '#TableView#';
  SWIPEOFFSET = 30;

  // version history
  // v1.0.0.0 : first release
  // v1.0.1.0 : New : SwipeBounceGesture property to toggle bounce effect when swiping up/down on the list
  //          : Fixed : Issue with lookup category
  // v1.0.2.0 : New : KeyboardEscapeMode property to clear or stop filtering
  //          : Improved : CategoryID can be a number that doesn't start from 0
  // v1.0.2.1 : Fixed : Issue with badge drawing
  // v1.0.2.2 : Fixed : Issue with showing filter button
  // v1.0.2.3 : Fixed : Issue with filtering condition
  // v1.0.2.4 : Fixed : Issue with scrolling to item with categories
  // v1.0.2.5 : Fixed : Issue with resizing
  // v1.0.3.0 : New : OnHeaderAnchorClick, OnFooterAnchorClick and OnItemTitleAnchorClick events implemented

type
  TTMSFNCTableViewItemAccessory = (tviaNone, tviaDetail, tviaProgress, tviaButton, tviaBadge, tviaCustom);
  TTMSFNCTableViewItemAccessories = set of TTMSFNCTableViewItemAccessory;

const
  ClickAccessories = [tviaButton, tviaCustom];

type
  TTMSFNCCustomTableView = class;
  TTMSFNCTableViewInteraction = class;

  TTMSFNCTableViewCategory = class;

  TTMSFNCTableViewItemCheckType = (tvictNone, tvictCheckBox, tvictRadioButton);

  TTMSFNCTableViewItem = class(TCollectionItem)
  private
    FRow: Integer;
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTableView: TTMSFNCCustomTableView;
    FText: String;
    FDBKey: String;
    FChecked: Boolean;
    FDataBoolean: Boolean;
    FWordWrapping: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FSelectedTextColor: TTMSFNCGraphicsColor;
    FTextColor: TTMSFNCGraphicsColor;
    FDisabledTextColor: TTMSFNCGraphicsColor;
    FEnabled: Boolean;
    FBitmapName: string;
    FBitmap: TTMSFNCBitmap;
    FHeight: Double;
    FDataPointer: Pointer;
    FHTMLTemplateItems: TStringList;
    FVisible: Boolean;
    FCategoryID: integer;
    FDetailControl: TControl;
    FAccessory: TTMSFNCTableViewItemAccessory;
    FAccessoryWidth: Single;
    FAccessoryProgress: Single;
    FAccessoryHeight: Single;
    FAccessoryText: String;
    FCheckType: TTMSFNCTableViewItemCheckType;
    FAccessoryFontColor: TTMSFNCGraphicsColor;
    FAccessoryBorderColor: TTMSFNCGraphicsColor;
    FAccessoryColor: TTMSFNCGraphicsColor;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FEnableMoreOptions: Boolean;
    FTitleTrimming: TTMSFNCGraphicsTextTrimming;
    FTitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitle: String;
    FDisabledTitleColor: TTMSFNCGraphicsColor;
    FTitleWordWrapping: Boolean;
    FSelectedTitleColor: TTMSFNCGraphicsColor;
    FTitleColor: TTMSFNCGraphicsColor;
    FTitleVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleExpanded: Boolean;
    procedure SetText(const Value: String);
    procedure SetHeight(const Value: Double);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSelectedTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetEnabled(const Value: Boolean);
    procedure SetBitmapName(const Value: string);
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetChecked(const Value: Boolean);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function GetStrippedHTMLText: string;
    function IsHeightStored: Boolean;
    procedure SetHTMLTemplateItems(const Value: TStringList);
    procedure SetVisible(const Value: Boolean);
    procedure SetCategoryID(const Value: integer);
    procedure SetDetailControl(const Value: TControl);
    procedure SetAccessory(const Value: TTMSFNCTableViewItemAccessory);
    function IsAccessoryWidthStored: Boolean;
    procedure SetAccessoryWidth(const Value: Single);
    function IsAccessoryProgressStored: Boolean;
    procedure SetAccessoryProgress(const Value: Single);
    function IsAccessoryHeightStored: Boolean;
    procedure SetAccessoryHeight(const Value: Single);
    procedure SetAccessoryText(const Value: String);
    procedure SetCheckType(const Value: TTMSFNCTableViewItemCheckType);
    procedure SetAccessoryBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetAccessoryColor(const Value: TTMSFNCGraphicsColor);
    procedure SetAccessoryFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetDisabledTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitle(const Value: String);
    procedure SetTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitleHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetTitleVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleWordWrapping(const Value: Boolean);
    procedure SetTitleExpanded(const Value: Boolean);
  protected
    procedure UpdateItem;
    procedure Changed(Sender: TObject);
    procedure BitmapChanged(Sender: TObject);
    procedure TemplateItemsChanged(Sender: TObject);
  public
    function GetTableView: TTMSFNCCustomTableView;
    function GetText: string;
    function GetTitle: string;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    function SaveToString(ATextOnly: Boolean = True): String; virtual;
    function IsSelected: Boolean; virtual;
    procedure LoadFromString(AString: String); virtual;
    procedure Assign(Source: TPersistent); override;
    procedure AssignData(Source: TPersistent); virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
    property StrippedHTMLText: String read GetStrippedHTMLText;
    property TableView: TTMSFNCCustomTableView read GetTableView;
    procedure ShowDetailControl; virtual;
    procedure HideDetailControl; virtual;
    property Title: String read FTitle write SetTitle;
    property TitleExpanded: Boolean read FTitleExpanded write SetTitleExpanded default True;
    property TitleWordWrapping: Boolean read FTitleWordWrapping write SetTitleWordWrapping default True;
    property TitleTrimming: TTMSFNCGraphicsTextTrimming read FTitleTrimming write SetTitleTrimming default gttNone;
    property TitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTitleHorizontalTextAlign write SetTitleHorizontalTextAlign default gtaLeading;
    property TitleVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTitleVerticalTextAlign write SetTitleVerticalTextAlign default gtaLeading;
    property TitleColor: TTMSFNCGraphicsColor read FTitleColor write SetTitleColor default gcBlack;
    property SelectedTitleColor: TTMSFNCGraphicsColor read FSelectedTitleColor write SetSelectedTitleColor default gcWhite;
    property DisabledTitleColor: TTMSFNCGraphicsColor read FDisabledTitleColor write SetDisabledTitleColor default gcSilver;
  published
    property Checked: Boolean read FChecked write SetChecked default False;
    property DetailControl: TControl read FDetailControl write SetDetailControl;
    property Text: String read FText write SetText;
    property HTMLTemplateItems: TStringList read FHTMLTemplateItems write SetHTMLTemplateItems;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property BitmapName: string read FBitmapName write SetBitmapName;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default True;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaLeading;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaLeading;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property SelectedTextColor: TTMSFNCGraphicsColor read FSelectedTextColor write SetSelectedTextColor default gcWhite;
    property DisabledTextColor: TTMSFNCGraphicsColor read FDisabledTextColor write SetDisabledTextColor default gcSilver;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Height: Double read FHeight write SetHeight stored IsHeightStored nodefault;
    property Visible: Boolean read FVisible write SetVisible default True;
    property CategoryID: integer read FCategoryID write SetCategoryID default -1;
    property Tag: NativeInt read FTag write FTag default 0;
    property AccessoryWidth: Single read FAccessoryWidth write SetAccessoryWidth stored IsAccessoryWidthStored nodefault;
    property AccessoryHeight: Single read FAccessoryHeight write SetAccessoryHeight stored IsAccessoryHeightStored nodefault;
    property Accessory: TTMSFNCTableViewItemAccessory read FAccessory write SetAccessory default tviaNone;
    property AccessoryProgress: Single read FAccessoryProgress write SetAccessoryProgress stored IsAccessoryProgressStored nodefault;
    property AccessoryText: String read FAccessoryText write SetAccessoryText;
    property AccessoryColor: TTMSFNCGraphicsColor read FAccessoryColor write SetAccessoryColor default gcNull;
    property AccessoryBorderColor: TTMSFNCGraphicsColor read FAccessoryBorderColor write SetAccessoryBorderColor default gcNull;
    property AccessoryFontColor: TTMSFNCGraphicsColor read FAccessoryFontColor write SetAccessoryFontColor default gcNull;
    property CheckType: TTMSFNCTableViewItemCheckType read FCheckType write SetCheckType default tvictNone;
    property EnableMoreOptions: Boolean read FEnableMoreOptions write FEnableMoreOptions default True;
  end;

  TTMSFNCTableViewItemsSortMode = (tvismAscending, tvismDescending);
  TTMSFNCTableViewItemsSortKind = (tviskNone, tviskAscending, tviskDescending);

  {$IFDEF WEBLIB}
  TTMSFNCTableViewItems = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTableViewItem>)
  {$ENDIF}
  private
    FTableView: TTMSFNCCustomTableView;
    function GetItem(Index: Integer): TTMSFNCTableViewItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewItem);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
    function Compare(AItem1, AItem2: TTMSFNCTableViewItem; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewItemsSortMode = tvismAscending): Integer; virtual;
    procedure QuickSort(L, R: Integer; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewItemsSortMode = tvismAscending); virtual;
  public
    function TableView: TTMSFNCCustomTableView;
    constructor Create(ATableView: TTMSFNCCustomTableView);
    function Add: TTMSFNCTableViewItem; virtual;
    function Insert(Index: Integer): TTMSFNCTableViewItem;
    procedure Clear; virtual;
    procedure Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewItemsSortMode = tvismAscending);
    property Items[Index: Integer]: TTMSFNCTableViewItem read GetItem write SetItem; default;
  end;

  TTMSFNCTableViewLookup = class(TPersistent)
  private
    FOwner: TTMSFNCTableViewInteraction;
    function GetAutoSelect: Boolean;
    function GetCaseSensitive: Boolean;
    function GetEnabled: Boolean;
    function GetIncremental: Boolean;
    procedure SetAutoSelect(const Value: Boolean);
    procedure SetCaseSensitive(const Value: Boolean);
    procedure SetEnabled(const Value: Boolean);
    procedure SetIncremental(const Value: Boolean);
  public
    constructor Create(AOwner: TTMSFNCTableViewInteraction);
    procedure Assign(Source: TPersistent); override;
  published
    property CaseSensitive: Boolean read GetCaseSensitive write SetCaseSensitive default False;
    property Enabled: Boolean read GetEnabled write SetEnabled default True;
    property Incremental: Boolean read GetIncremental write SetIncremental default True;
    property AutoSelect: Boolean read GetAutoSelect write SetAutoSelect default True;
  end;

  TTMSFNCTableViewFiltering = class(TPersistent)
  private
    FOwner: TTMSFNCTableViewInteraction;
    function GetDropDownHeight: integer;
    function GetDropDownWidth: integer;
    function GetEnabled: Boolean;
    procedure SetDropDownHeight(const Value: integer);
    procedure SetDropDownWidth(const Value: integer);
    procedure SetEnabled(const Value: Boolean);
  public
    constructor Create(AOwner: TTMSFNCTableViewInteraction);
    procedure Assign(Source: TPersistent); override;
  published
    property Enabled: Boolean read GetEnabled write SetEnabled default False;
    property DropDownWidth: integer read GetDropDownWidth write SetDropDownWidth default 100;
    property DropDownHeight: integer read GetDropDownHeight write SetDropDownHeight default 120;
  end;

  TTMSFNCTableViewMouseEditMode = (tvmemDoubleClick, tvmemSingleClick, tvmemSingleClickOnSelectedItem);
  TTMSFNCTableViewClipboardMode = (tvcmNone, tvcmTextOnly, tvcmFull);
  TTMSFNCTableViewDragDropMode = (tvdmNone, tvdmMove, tvdmCopy);
  TTMSFNCTableViewSorting = (tvcsNone, tvcsNormal, tvcsNormalCaseSensitive);
  TTMSFNCTableViewShowDetailMode = (tvdtNone, tvdtAfterSelectItem);
  TTMSFNCTableViewKeyboardEscapeMode = (tvkeNone, tvkeClearEdit, tvkeStopFilter);

  TTMSFNCTableViewInteraction = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FLookup: TTMSFNCTableViewLookup;
    FFiltering: TTMSFNCTableViewFiltering;
    FShowDetailMode: TTMSFNCTableViewShowDetailMode;
    FShowEditButton: Boolean;
    FShowFilterButton: Boolean;
    FAccessoryClickDetection: TTMSFNCTableViewItemAccessories;
    FLongPressToEdit: Boolean;
    FSwipeToShowMoreOptions: Boolean;
    FSwipeBounceGesture: Boolean;
    FKeyboardEscapeMode: TTMSFNCTableViewKeyboardEscapeMode;
    function GetMultiSelect: Boolean;
    procedure SetMultiSelect(const Value: Boolean);
    function GetClipboardMode: TTMSFNCTableViewClipboardMode;
    function GetDragDropMode: TTMSFNCTableViewDragDropMode;
    function GetReorder: Boolean;
    function GetTouchScrolling: Boolean;
    procedure SetDragDropMode(const Value: TTMSFNCTableViewDragDropMode);
    procedure SetFiltering(const Value: TTMSFNCTableViewFiltering);
    procedure SetLookup(const Value: TTMSFNCTableViewLookup);
    procedure SetReorder(const Value: Boolean);
    procedure SetTouchScrolling(const Value: Boolean);
    procedure SetClipboardMode(const Value: TTMSFNCTableViewClipboardMode);
    function GetSorting: TTMSFNCTableViewSorting;
    procedure SetSorting(const Value: TTMSFNCTableViewSorting);
    procedure SetShowDetailMode(const Value: TTMSFNCTableViewShowDetailMode);
    procedure SetShowEditButton(const Value: Boolean);
    procedure SetShowFilterButton(const Value: Boolean);
    procedure SetAccessoryClickDetection(
      const Value: TTMSFNCTableViewItemAccessories);
    function GetAutoOpenURL: Boolean;
    procedure SetAutoOpenURL(const Value: Boolean);
  protected
    property ClipboardMode: TTMSFNCTableViewClipboardMode read GetClipboardMode write SetClipboardMode default tvcmNone;
    property Reorder: Boolean read GetReorder write SetReorder default False;
    property Sorting: TTMSFNCTableViewSorting read GetSorting write SetSorting default tvcsNone;
    property Filtering: TTMSFNCTableViewFiltering read FFiltering write SetFiltering;
    property Lookup: TTMSFNCTableViewLookup read FLookup write SetLookup;
  public
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DragDropMode: TTMSFNCTableViewDragDropMode read GetDragDropMode write SetDragDropMode default tvdmNone;
  published
    property AutoOpenURL: Boolean read GetAutoOpenURL write SetAutoOpenURL default True;
    property MultiSelect: Boolean read GetMultiSelect write SetMultiSelect default False;
    property TouchScrolling: Boolean read GetTouchScrolling write SetTouchScrolling default True;
    property ShowDetailMode: TTMSFNCTableViewShowDetailMode read FShowDetailMode write SetShowDetailMode default tvdtAfterSelectItem;
    property ShowEditButton: Boolean read FShowEditButton write SetShowEditButton default False;
    property ShowFilterButton: Boolean read FShowFilterButton write SetShowFilterButton default False;
    property SwipeBounceGesture: Boolean read FSwipeBounceGesture write FSwipeBounceGesture default True;
    property AccessoryClickDetection: TTMSFNCTableViewItemAccessories read FAccessoryClickDetection write SetAccessoryClickDetection default ClickAccessories;
    property SwipeToShowMoreOptions: Boolean read FSwipeToShowMoreOptions write FSwipeToShowMoreOptions default True;
    property LongPressToEdit: Boolean read FLongPressToEdit write FLongPressToEdit default true;
    property KeyboardEscapeMode: TTMSFNCTableViewKeyboardEscapeMode read FKeyboardEscapeMode write FKeyboardEscapeMode default tvkeStopFilter;
  end;

  TTMSFNCTableViewItemHeightMode = (tvhmFixed, tvhmVariable);

  TTMSFNCTableViewItemAppearance = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FHTMLTemplate: string;
    FAccessoryDetailBitmaps: TTMSFNCScaledBitmaps;
    FAccessoryFont: TTMSFNCGraphicsFont;
    FAccessoryStroke: TTMSFNCGraphicsStroke;
    FAccessoryFill: TTMSFNCGraphicsFill;
    FHeight: Double;
    function GetFont: TTMSFNCGraphicsFont;
    function GetSelectedFill: TTMSFNCGraphicsFill;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedFill(const Value: TTMSFNCGraphicsFill);
    function GetSelectedStroke: TTMSFNCGraphicsStroke;
    procedure SetSelectedStroke(const Value: TTMSFNCGraphicsStroke);
    function GetDisabledFill: TTMSFNCGraphicsFill;
    function GetDisabledStroke: TTMSFNCGraphicsStroke;
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function GetHeightMode: TTMSFNCTableViewItemHeightMode;
    procedure SetHeightMode(const Value: TTMSFNCTableViewItemHeightMode);
    function GetFixedHeight: Double;
    procedure SetFixedHeight(const Value: Double);
    procedure SetHTMLTemplate(const Value: string);
    procedure SetAccessoryDetailBitmaps(const Value: TTMSFNCScaledBitmaps);
    procedure SetAccessoryFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAccessoryFill(const Value: TTMSFNCGraphicsFill);
    procedure SetAccessoryStroke(const Value: TTMSFNCGraphicsStroke);
    function IsHeightStored: Boolean;
    procedure SetHeight(const Value: Double);
    function GetShowFocus: Boolean;
    procedure SetShowFocus(const Value: Boolean);
  protected
    procedure DoAccessoryDetailBitmapsChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    function GetOwner: TPersistent; override;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property SelectedFill: TTMSFNCGraphicsFill read GetSelectedFill write SetSelectedFill;
    property SelectedStroke: TTMSFNCGraphicsStroke read GetSelectedStroke write SetSelectedStroke;
    property DisabledFill: TTMSFNCGraphicsFill read GetDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read GetDisabledStroke write SetDisabledStroke;
    property HeightMode: TTMSFNCTableViewItemHeightMode read GetHeightMode write SetHeightMode default tvhmVariable;
    property FixedHeight: Double read GetFixedHeight write SetFixedHeight;
    property Height: Double read FHeight write SetHeight stored IsHeightStored nodefault;
    property HTMLTemplate: string read FHTMLTemplate write SetHTMLTemplate;
    property AccessoryDetailBitmaps: TTMSFNCScaledBitmaps read FAccessoryDetailBitmaps write SetAccessoryDetailBitmaps;
    property AccessoryFont: TTMSFNCGraphicsFont read FAccessoryFont write SetAccessoryFont;
    property AccessoryStroke: TTMSFNCGraphicsStroke read FAccessoryStroke write SetAccessoryStroke;
    property AccessoryFill: TTMSFNCGraphicsFill read FAccessoryFill write SetAccessoryFill;
    property ShowFocus: Boolean read GetShowFocus write SetShowFocus default True;
  end;

  TTMSFNCTableViewCategoryAppearance = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FHeight: Single;
    function GetFont: TTMSFNCGraphicsFont;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function IsHeightStored: Boolean;
    procedure SetHeight(const Value: Single);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property Height: Single read FHeight write SetHeight stored IsHeightStored nodefault;
  end;

  TTMSFNCTableViewMoreOptionAppearance = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCTableViewIntegerArray = array of Integer;

  TTMSFNCTableViewHeaderFooter = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FText: String;
    FWordWrapping: Boolean;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    function GetVisible: Boolean;
    procedure SetVisible(const Value: Boolean);
    procedure SetText(const Value: String);
    function GetFill: TTMSFNCGraphicsFill;
    function GetHeight: Single;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetHeight(const Value: Single);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    function GetFont: TTMSFNCGraphicsFont;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    function GetSortIndicatorColor: TTMSFNCGraphicsColor;
    procedure SetSortIndicatorColor(const Value: TTMSFNCGraphicsColor);
  protected
    property Visible: Boolean read GetVisible write SetVisible default True;
    property Text: String read FText write SetText;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property Height: Single read GetHeight write SetHeight;
    property SortIndicatorColor: TTMSFNCGraphicsColor read GetSortIndicatorColor write SetSortIndicatorColor default TTMSFNCTreeViewColorSelection;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATableView: TTMSFNCCustomTableView); virtual;
  end;

  TTMSFNCTableViewHeader = class(TTMSFNCTableViewHeaderFooter)
  published
    property Visible;
    property Text;
    property Fill;
    property Font;
    property Stroke;
    property HorizontalTextAlign;
    property VerticalTextAlign;
    property WordWrapping;
    property Trimming;
    property Height;
  end;

  TTMSFNCTableViewFooter = class(TTMSFNCTableViewHeaderFooter)
  published
    property Visible;
    property Text;
    property Fill;
    property Font;
    property Stroke;
    property HorizontalTextAlign;
    property VerticalTextAlign;
    property WordWrapping;
    property Trimming;
    property Height;
  end;

  TTMSFNCTableViewCopyItems = class(TTMSFNCTableViewItems);

  TTMSFNCTableViewBeforeDrawSortIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASortIndex: Integer; ASortKind: TTMSFNCTableViewItemsSortKind; var ADefaultDraw: Boolean) of object;
  TTMSFNCTableViewAfterDrawSortIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASortIndex: Integer; ASortKind: TTMSFNCTableViewItemsSortKind) of object;
  TTMSFNCTableViewNeedFilterDropDownDataEvent = procedure(Sender: TObject; AValues: TStrings) of object;
  TTMSFNCTableViewItemAnchorClickEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; AAnchor: String) of object;
  TTMSFNCTableViewBeforeReorderItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCTableViewItem; var ACanReorder: Boolean) of object;
  TTMSFNCTableViewAfterReorderItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewBeforeDropItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCTableViewItem; var ACanDrop: Boolean) of object;
  TTMSFNCTableViewAfterDropItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewBeforeCopyToClipboardEvent = procedure(Sender: TObject; var ACanCopy: Boolean) of object;
  TTMSFNCTableViewBeforeCutToClipboardEvent = procedure(Sender: TObject; var ACanCut: Boolean) of object;
  TTMSFNCTableViewBeforePasteFromClipboardEvent = procedure(Sender: TObject; var ACanPaste: Boolean) of object;
  TTMSFNCTableViewAfterCopyToClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCTableViewAfterCutToClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCTableViewAfterPasteFromClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCTableViewBeforeDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AText: String; var AAllow: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AText: String) of object;
  TTMSFNCTableViewBeforeDrawItemTitleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; ATitle: String; var AAllow: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemTitleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; ATitle: String) of object;
  TTMSFNCTableViewItemSelectedEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemUnSelectedEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemSelectionChangedEvent = TNotifyEvent;
  TTMSFNCTableViewItemClickEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemMouseEnterEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemMouseLeaveEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemCheckChangedEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewScrollEvent = procedure(Sender: TObject; APosition: Single) of object;
  TTMSFNCTableViewGetItemHeightEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; var AHeight: Double) of object;
  TTMSFNCTableViewBeforeDrawItemIconEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AIcon:TTMSFNCBitmap; var AAllow: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemIconEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AIcon:TTMSFNCBitmap) of object;
  TTMSFNCTableViewBeforeDrawItemCheckEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; ACheck: TTMSFNCBitmap; var AAllow: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemCheckEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; ACheck: TTMSFNCBitmap) of object;
  TTMSFNCTableViewBeforeDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemCompareEvent = procedure(Sender: TObject; Item1, Item2: TTMSFNCTableViewItem; var ACompareResult: Integer) of object;
  TTMSFNCTableViewCategoryCompareEvent = procedure(Sender: TObject; Category1, Category2: TTMSFNCTableViewCategory; var ACompareResult: Integer) of object;
  TTMSFNCTableViewFilterSelectEvent = procedure(Sender: TObject; var ACondition: string) of object;
  TTMSFNCTableViewGetHTMLTemplateValueEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; AName: string; var AValue: string) of object;
  TTMSFNCTableViewGetHTMLTemplateEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; var AHTMLTemplate: string) of object;
  TTMSFNCTableViewAnchorHeaderFooterClick = procedure(Sender: TObject; AAnchor: string) of object;

  TTMSFNCTableViewFilterOperation = (tvfoSHORT, tvfoNONE, tvfoAND, tvfoXOR, tvfoOR);

  TTMSFNCTableViewFilterData = class(TCollectionItem)
  private
    FCondition: string;
    FCaseSensitive: Boolean;
    FSuffix: string;
    FPrefix: string;
    FOperation: TTMSFNCTableViewFilterOperation;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Condition:string read FCondition write FCondition;
    property CaseSensitive: Boolean read FCaseSensitive write FCaseSensitive default True;
    property Prefix: string read FPrefix write FPrefix;
    property Suffix: string read FSuffix write FSuffix;
    property Operation: TTMSFNCTableViewFilterOperation read FOperation write FOperation;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTableViewFilter = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewFilter = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTableViewFilterData>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomTableView;
    function GetItem(Index: Integer): TTMSFNCTableViewFilterData;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewFilterData);
  public
    constructor Create(AOwner: TTMSFNCCustomTableView);
    function Add: TTMSFNCTableViewFilterData;
    function Insert(index: Integer): TTMSFNCTableViewFilterData;
    property Items[Index: Integer]: TTMSFNCTableViewFilterData read GetItem write SetItem; default;
  end;

  TTMSFNCTableViewBeforeApplyFilterEvent = procedure(Sender: TObject; AFilter: TTMSFNCTableViewFilterData; var AAllow: Boolean) of object;
  TTMSFNCTableViewAfterApplyFilterEvent = procedure(Sender: TObject; AFilter: TTMSFNCTableViewFilterData) of object;

  {$IFDEF WEBLIB}
  TTMSFNCTreeViewTableViewFloatingNodes = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
  public
    property Items[Index: Integer]: TTMSFNCTreeViewVirtualNode read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTreeViewTableViewFloatingNodes = class(TObjectList<TTMSFNCTreeViewVirtualNode>);
  {$ENDIF}

  TTMSFNCTableViewMoreOption = class;

  TTMSFNCTreeViewTableView = class(TTMSFNCTreeView)
  private
    FDownMoreOption: TTMSFNCTableViewMoreOption;
    FMoreOffset, FMoreOffsetTo: Double;
    FMoreOffsetItem: TTMSFNCTableViewItem;
    FDownAction: Boolean;
    FDownCount: Integer;
    FDownTimer: Boolean;
    FDownX, FDownY: Single;
    FAnimTimer: TTimer;
    FAnimateMoreOptions, FAnimateMoreOptionsClose: Boolean;
    FMouseDownOnAccessory: Boolean;
    FAnimMarqueeSwitch: Boolean;
    FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle, FAnimShiftAngle: Single;
    FManualProgressSweepAngle, FManualProgressStartAngle: Single;
    FReloadActive: Boolean;
    FMouseDownOnLookupBar: Boolean;
    FTableView: TTMSFNCCustomTableView;
    FFloatingNodes: TTMSFNCTreeViewTableViewFloatingNodes;
    FOnFooterAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick;
    FOnHeaderAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick;
  protected
    procedure AnimateTimerChanged(Sender: TObject);
    procedure OffsetNodeRects(ANode: TTMSFNCTreeViewVirtualNode; AX, AY: Single; var ARect: TRectF); override;
    procedure CustomizeNodeCache({%H-}AGraphics: TTMSFNCGraphics; {%H-}AStartY: Single); override;
    procedure CustomizeScrollPosition({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}APosition, {%H-}ATopPosition: Double); override;
    procedure DrawLookupBar(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawReload(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure ProcessLookup(X, Y: Single; DoLookup: Boolean = True); virtual;
    procedure StartReload; override;
    procedure UpdateCheckState({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AChecked: Boolean); override;
    function ScrollLimitation: Boolean; override;
    function NeedsReload(AVerticalOffset: Single): Boolean; override;
    function GetReloadOffset: Single; override;
    function GetNodesRect: TRectF; override;
    function GetContentRect: TRectF; override;
    function GetCategoryAtXY(X, Y: Single): Integer; virtual;
    function GetLookupBarRect: TRectF; virtual;
    function GetContentClipRect: TRectF; override;
    function CanApplyVerticalOffset: Boolean; override;
    function XYToHeaderAnchor(X, Y: Single): string;
    function XYToFooterAnchor(X, Y: Single): string;
    procedure DoHeaderAnchorClick(AAnchor: String); virtual;
    procedure DoFooterAnchorClick(AAnchor: String); virtual;
    property OnFooterAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick read FOnFooterAnchorClick write FOnFooterAnchorClick;
    property OnHeaderAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick read FOnHeaderAnchorClick write FOnHeaderAnchorClick;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    function GetTotalColumnNodeWidth: Double; override;
    function GetColumnWidth(AColumn: Integer): Double; override;
    property CustomScrollBars;
  end;

  TTMSFNCTableViewDisplayItemKind = (tvikNone, tvikItem, tvikCategory);

  TTMSFNCTableViewDisplayItem = record
    Kind: TTMSFNCTableViewDisplayItemKind;
    Item: TTMSFNCTableViewItem;
    CategoryID: Integer;
    Row: Integer;
    Text: string;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCTableViewDisplayItem) b : boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTableViewDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTableViewDisplayItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewDisplayItem);
  public
    property Items[Index: Integer]: TTMSFNCTableViewDisplayItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewDisplayList = class(TList<TTMSFNCTableViewDisplayItem>);
  {$ENDIF}

  TTMSFNCTableViewLookupBarDisplayItem = record
    Rect: TRectF;
    Text: string;
    Active: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCTableViewLookupBarDisplayItem) b : boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTableViewLookupBarDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTableViewLookupBarDisplayItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewLookupBarDisplayItem);
  public
    property Items[Index: Integer]: TTMSFNCTableViewLookupBarDisplayItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewLookupBarDisplayList = class(TList<TTMSFNCTableViewLookupBarDisplayItem>);
  {$ENDIF}

  TTMSFNCTableViewLookupBarPosition = (tvlbpLeft, tvlbpRight);

  TTMSFNCTableViewLookupBar = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FVisible: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FAutoLookup: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FWidth: Single;
    FPosition: TTMSFNCTableViewLookupBarPosition;
    FDownFill: TTMSFNCGraphicsFill;
    FDownStroke: TTMSFNCGraphicsStroke;
    FInActiveFont: TTMSFNCGraphicsFont;
    procedure SetVisible(const Value: Boolean);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAutoLookup(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function IsWidthStored: Boolean;
    procedure SetPosition(const Value: TTMSFNCTableViewLookupBarPosition);
    procedure SetWidth(const Value: Single);
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetInActiveFont(const Value: TTMSFNCGraphicsFont);
  protected
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
  public
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Width: Single read FWidth write SetWidth stored IsWidthStored nodefault;
    property Position: TTMSFNCTableViewLookupBarPosition read FPosition write SetPosition default tvlbpRight;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property InActiveFont: TTMSFNCGraphicsFont read FInActiveFont write SetInActiveFont;
    property AutoLookup: Boolean read FAutoLookup write SetAutoLookup default True;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
  end;

  TTMSFNCTableViewReloadProgressMode = (tvrpmMarquee, tvrpmMarqueeAlternate, tvrpmManual);

  TTMSFNCTableViewReload = class(TPersistent)
  private
    FTableView: TTMSFNCCustomTableView;
    FOffset: Single;
    FEnabled: Boolean;
    FStroke: TTMSFNCGraphicsStroke;
    FSize: Single;
    FProgressMode: TTMSFNCTableViewReloadProgressMode;
    function IsOffsetStored: Boolean;
    procedure SetOffset(const Value: Single);
    procedure SetEnabled(const Value: Boolean);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function IsSizeStored: Boolean;
    procedure SetSize(const Value: Single);
    procedure SetProgressMode(const Value: TTMSFNCTableViewReloadProgressMode);
  public
    constructor Create(ATableView: TTMSFNCCustomTableView);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Offset: Single read FOffset write SetOffset stored IsOffsetStored nodefault;
    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Size: Single read FSize write SetSize stored IsSizeStored nodefault;
    property ProgressMode: TTMSFNCTableViewReloadProgressMode read FProgressMode write SetProgressMode default tvrpmMarquee;
  end;

  TTMSFNCTableViewCategory = class(TCollectionItem)
  private
    FTableView: TTMSFNCCustomTableView;
    FText: String;
    FID: integer;
    FLookupText: String;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDBKey: String;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    procedure SetText(const Value: String);
    procedure SetId(const Value: integer);
    procedure SetLookupText(const Value: String);
    function GetStrippedHTMLText: String;
  protected
    procedure Changed;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property StrippedHTMLText: String read GetStrippedHTMLText;
  published
    property Text: String read FText write SetText;
    property LookupText: String read FLookupText write SetLookupText;
    property Id: integer read FID write SetId;
  end;

  TTMSFNCTableViewCategoriesSortMode = (tvcsmAscending, tvcsmDescending);

  {$IFDEF WEBLIB}
  TTMSFNCTableViewCategories = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewCategories = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTableViewCategory>)
  {$ENDIF}
  private
    FTableView: TTMSFNCCustomTableView;
    FOnChange: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCTableViewCategory;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewCategory);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    function Compare(AItem1, AItem2: TTMSFNCTableViewCategory; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewCategoriesSortMode = tvcsmAscending): Integer; virtual;
    procedure QuickSort(L, R: Integer; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewCategoriesSortMode = tvcsmAscending); virtual;
  public
    constructor Create(ATableView: TTMSFNCCustomTableView);
    property Items[Index: Integer]: TTMSFNCTableViewCategory read GetItem write SetItem; default;
    function ItemById(id: integer): TTMSFNCTableViewCategory;
    function ItemIndexById(id: integer): integer;
    function Add: TTMSFNCTableViewCategory;
    function Insert(Index: Integer): TTMSFNCTableViewCategory;
    procedure Delete(Index: Integer);
    procedure Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewCategoriesSortMode = tvcsmAscending);
    procedure Clear;
  end;

  TTMSFNCTableViewMoreOption = class(TCollectionItem)
  private
    FTableView: TTMSFNCCustomTableView;
    FText: String;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDBKey: String;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FWidth: Single;
    FColor: TTMSFNCGraphicsColor;
    FFontColor: TTMSFNCGraphicsColor;
    FBorderColor: TTMSFNCGraphicsColor;
    procedure SetText(const Value: String);
    function GetStrippedHTMLText: String;
    function IsWidthStored: Boolean;
    procedure SetWidth(const Value: Single);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderColor(const Value: TTMSFNCGraphicsColor);
  protected
    procedure Changed;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property StrippedHTMLText: String read GetStrippedHTMLText;
  published
    property Text: String read FText write SetText;
    property Width: Single read FWidth write SetWidth stored IsWidthStored nodefault;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcNull;
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write SetBorderColor default gcNull;
    property FontColor: TTMSFNCGraphicsColor read FFontColor write SetFontColor default gcNull;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTableViewMoreOptions = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewMoreOptions = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTableViewMoreOption>)
  {$ENDIF}
  private
    FTableView: TTMSFNCCustomTableView;
    FOnChange: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCTableViewMoreOption;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewMoreOption);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    constructor Create(ATableView: TTMSFNCCustomTableView);
    property Items[Index: Integer]: TTMSFNCTableViewMoreOption read GetItem write SetItem; default;
    function ItemById(id: integer): TTMSFNCTableViewMoreOption;
    function ItemIndexById(id: integer): integer;
    function Add: TTMSFNCTableViewMoreOption;
    function Insert(Index: Integer): TTMSFNCTableViewMoreOption;
    procedure Delete(Index: Integer);
    procedure Clear;
  end;

  TTMSFNCTableViewCategoryType = (tvctNone, tvctAlphaBetic, tvctAlphaNumericFirst, tvctAlphaNumericLast, tvctCustom);

  TTMSFNCTableViewLookupCategoryEvent = procedure(Sender: TObject; ACharacter: String; ACharacterID: Integer) of object;
  TTMSFNCTableViewDrawItemCustomAccessoryEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem) of object;
  TTMSFNCTableViewItemMoreOptionClickEvent = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption) of object;
  TTMSFNCTableViewBeforeDrawItemMoreOptionEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption; var ADefaultDraw: Boolean) of object;
  TTMSFNCTableViewAfterDrawItemMoreOptionEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption) of object;
  TTMSFNCTableViewBeforeItemShowDetailControl = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean) of object;
  TTMSFNCTableViewBeforeItemHideDetailControl = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean) of object;
  TTMSFNCTableViewItemShowDetailControl = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; ADetailControl: TControl) of object;
  TTMSFNCTableViewItemHideDetailControl = procedure(Sender: TObject; AItem: TTMSFNCTableViewItem; ADetailControl: TControl) of object;

  TTMSFNCTableViewItemArray = array of TTMSFNCTableViewItem;
  TTMSFNCTableViewCheckedItems = TTMSFNCTableViewItemArray;
  TTMSFNCTableViewSelectedItems = TTMSFNCTableViewItemArray;

  TTMSFNCTableViewCustomChar = class
  private
    FID: Integer;
    FActive: Boolean;
  public
    constructor Create(AID: Integer; AActive: Boolean);
    property ID: Integer read FID write FID;
    property Active: Boolean read FActive write FActive;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTableViewCustomCharList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCTableViewCustomChar;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTableViewCustomChar);
  public
    property Items[Index: Integer]: TTMSFNCTableViewCustomChar read GetItem write SetItem; default;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTableViewCustomCharList = class(TObjectList<TTMSFNCTableViewCustomChar>)
  public
  {$ENDIF}
    procedure ActivateID(AID: Integer);
    function IsIDActive(AID: Integer): Boolean;
  end;

  TTMSFNCTableViewEdit = class(TTMSFNCEdit)
  private
    FTableView: TTMSFNCCustomTableView;
  protected
    {$IFDEF FMXLIB}
    procedure KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    {$ENDIF}
  end;

  {$IFDEF FNCLIB}
  TTMSFNCCustomTableView = class(TTMSFNCCustomControl, ITMSFNCStylesManager, ITMSFNCBitmapContainer)
  {$ENDIF}
  {$IFNDEF FNCLIB}
  TTMSFNCCustomTableView = class(TTMSFNCCustomControl)
  {$ENDIF}
  private
    FFirstLoad: Boolean;
    FFilterActive: Boolean;
    FEditMode: Boolean;
    FDoneButton, FEditButton, FBackButton, FFilterButton: TTMSFNCToolBarButton;
    FEdit: TTMSFNCTableViewEdit;
    FActiveAccessoryItem: TTMSFNCTableViewItem;
    FDetailControlTimer: TTimer;
    FDetailControlOffset: Double;
    FShowDetailControl: Boolean;
    FActiveDetailControl: TControl;
    FActiveDetailItem: TTMSFNCTableViewItem;
    FCustomChar: TTMSFNCTableViewCustomCharList;
    FLookupBarDisplayList: TTMSFNCTableViewLookupBarDisplayList;
    FFilterApplied: Boolean;
    FUpdateCount: Integer;
    FDisplayList: TTMSFNCTableViewDisplayList;
    FCategoryList: TTMSFNCTableViewDisplayList;
    FSaveSelectedItem: Integer;
    FCopyItems: TTMSFNCTableViewCopyItems;
    FAccepted: Boolean;
    FItems: TTMSFNCTableViewItems;
    FTreeView: TTMSFNCTreeViewTableView;
    FColumn: TTMSFNCTreeViewColumn;
    FDefaultItem: TTMSFNCTableViewItem;
    FItemAppearance: TTMSFNCTableViewItemAppearance;
    FOnItemSelected: TTMSFNCTableViewItemSelectedEvent;
    FInteraction: TTMSFNCTableViewInteraction;
    FHeader: TTMSFNCTableViewHeader;
    FFooter: TTMSFNCTableViewFooter;
    FOnItemAnchorClick: TTMSFNCTableViewItemAnchorClickEvent;
    FOnAfterCutToClipboard: TTMSFNCTableViewAfterCutToClipboardEvent;
    FOnBeforePasteFromClipboard: TTMSFNCTableViewBeforePasteFromClipboardEvent;
    FOnAfterReorderItem: TTMSFNCTableViewAfterReorderItemEvent;
    FOnBeforeDropItem: TTMSFNCTableViewBeforeDropItemEvent;
    FOnBeforeDrawItemText: TTMSFNCTableViewBeforeDrawItemTextEvent;
    FOnVScroll: TTMSFNCTableViewScrollEvent;
    FOnBeforeCopyToClipboard: TTMSFNCTableViewBeforeCopyToClipboardEvent;
    FOnItemDblClick: TTMSFNCTableViewItemClickEvent;
    FOnBeforeDrawItemCheck: TTMSFNCTableViewBeforeDrawItemCheckEvent;
    FOnAfterDrawItemIcon: TTMSFNCTableViewAfterDrawItemIconEvent;
    FOnBeforeDrawItem: TTMSFNCTableViewBeforeDrawItemEvent;
    FOnAfterPasteFromClipboard: TTMSFNCTableViewAfterPasteFromClipboardEvent;
    FOnItemClick: TTMSFNCTableViewItemClickEvent;
    FOnAfterDropItem: TTMSFNCTableViewAfterDropItemEvent;
    FOnAfterDrawItemText: TTMSFNCTableViewAfterDrawItemTextEvent;
    FOnItemCompare: TTMSFNCTableViewItemCompareEvent;
    FOnBeforeCutToClipboard: TTMSFNCTableViewBeforeCutToClipboardEvent;
    FOnBeforeReorderItem: TTMSFNCTableViewBeforeReorderItemEvent;
    FOnNeedFilterDropDownData: TTMSFNCTableViewNeedFilterDropDownDataEvent;
    FOnAfterCopyToClipboard: TTMSFNCTableViewAfterCopyToClipboardEvent;
    FOnFilterSelect: TTMSFNCTableViewFilterSelectEvent;
    FOnAfterDrawItemCheck: TTMSFNCTableViewAfterDrawItemCheckEvent;
    FOnAfterDrawItem: TTMSFNCTableViewAfterDrawItemEvent;
    FOnBeforeDrawItemIcon: TTMSFNCTableViewBeforeDrawItemIconEvent;
    FFilter: TTMSFNCTableViewFilter;
    FOnBeforeDrawSortIndicator: TTMSFNCTableViewBeforeDrawSortIndicatorEvent;
    FOnAfterDrawSortIndicator: TTMSFNCTableViewAfterDrawSortIndicatorEvent;
    FOnGetHTMLTemplateValue: TTMSFNCTableViewGetHTMLTemplateValueEvent;
    FOnGetHTMLTemplate: TTMSFNCTableViewGetHTMLTemplateEvent;
    FCategoryType: TTMSFNCTableViewCategoryType;
    FCategories: TTMSFNCTableViewCategories;
    FOnCategoryCompare: TTMSFNCTableViewCategoryCompareEvent;
    FLookupBar: TTMSFNCTableViewLookupBar;
    FOnManualLookupCategory: TTMSFNCTableViewLookupCategoryEvent;
    FOnCategoryClick: TTMSFNCTableViewLookupCategoryEvent;
    FReload: TTMSFNCTableViewReload;
    FOnStopReload: TNotifyEvent;
    FOnStartReload: TNotifyEvent;
    FOnDrawItemCustomAccessory: TTMSFNCTableViewDrawItemCustomAccessoryEvent;
    FOnItemAccessoryClick: TTMSFNCTableViewItemClickEvent;
    FCategoryAppearance: TTMSFNCTableViewCategoryAppearance;
    FOnItemCheckChanged: TTMSFNCTableViewItemCheckChangedEvent;
    FOnAfterApplyFilter: TTMSFNCTableViewAfterApplyFilterEvent;
    FOnBeforeApplyFilter: TTMSFNCTableViewBeforeApplyFilterEvent;
    FMoreOptions: TTMSFNCTableViewMoreOptions;
    FMoreOptionAppearance: TTMSFNCTableViewMoreOptionAppearance;
    FOnItemMoreOptionClick: TTMSFNCTableViewItemMoreOptionClickEvent;
    FOnAfterDrawItemMoreOption: TTMSFNCTableViewAfterDrawItemMoreOptionEvent;
    FOnBeforeDrawItemMoreOption: TTMSFNCTableViewBeforeDrawItemMoreOptionEvent;
    FOnItemShowDetailControl: TTMSFNCTableViewItemShowDetailControl;
    FOnItemHideDetailControl: TTMSFNCTableViewItemHideDetailControl;
    FOnBeforeItemShowDetailControl: TTMSFNCTableViewBeforeItemShowDetailControl;
    FOnBeforeItemHideDetailControl: TTMSFNCTableViewBeforeItemHideDetailControl;
    FOnItemSelectionChanged: TTMSFNCTableViewItemSelectionChangedEvent;
    FOnItemUnSelected: TTMSFNCTableViewItemUnSelectedEvent;
    FOnAfterDrawItemTitle: TTMSFNCTableViewAfterDrawItemTitleEvent;
    FOnBeforeDrawItemTitle: TTMSFNCTableViewBeforeDrawItemTitleEvent;
    FOnItemMouseLeave: TTMSFNCTableViewItemMouseLeaveEvent;
    FOnItemMouseEnter: TTMSFNCTableViewItemMouseEnterEvent;
    FOnFooterAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick;
    FOnHeaderAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick;
    FOnItemTitleAnchorClick: TTMSFNCTableViewItemAnchorClickEvent;
    procedure SetItems(const Value: TTMSFNCTableViewItems);
    procedure SetDefaultItem(const Value: TTMSFNCTableViewItem);
    procedure SetItemAppearance(const Value: TTMSFNCTableViewItemAppearance);
    function GetItemIndex: Integer;
    procedure SetItemIndex(const Value: Integer);
    procedure SetInteraction(const Value: TTMSFNCTableViewInteraction);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetHeader(const Value: TTMSFNCTableViewHeader);
    procedure SetFooter(const Value: TTMSFNCTableViewFooter);
    function GetSelectedItem: TTMSFNCTableViewItem;
    procedure SetSelectedItem(const Value: TTMSFNCTableViewItem);
    function GetSelItem(AIndex: Integer): TTMSFNCTableViewItem;
    function GetVerticalScrollBarVisible: Boolean;
    procedure SetVerticalScrollBarVisible(const Value: Boolean);
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCategoryType(const Value: TTMSFNCTableViewCategoryType);
    procedure SetCategories(const Value: TTMSFNCTableViewCategories);
    procedure SetLookupBar(const Value: TTMSFNCTableViewLookupBar);
    procedure SetReload(const Value: TTMSFNCTableViewReload);
    procedure SetCategoryAppearance(
      const Value: TTMSFNCTableViewCategoryAppearance);
    function GetChecked(AItemIndex: Integer): Boolean;
    function GetCheckedItem(AItem: TTMSFNCTableViewItem): Boolean;
    procedure SetChecked(AItemIndex: Integer; const Value: Boolean);
    procedure SetCheckedItem(AItem: TTMSFNCTableViewItem;
      const Value: Boolean);
    procedure SetMoreOptions(const Value: TTMSFNCTableViewMoreOptions);
    procedure SetMoreOptionAppearance(
      const Value: TTMSFNCTableViewMoreOptionAppearance);
  protected
    {$IFDEF FNCLIB}
    function GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
    {$ENDIF}
    function GetVersion: string; override;
    function GetItemsRect: TRectF; virtual;
    function CreateTreeView: TTMSFNCTreeViewTableView; virtual;
    function CreateItems: TTMSFNCTableViewItems; virtual;
    function GetRowForItemIndex(AItemIndex: Integer): Integer; virtual;
    function GetDisplayCategoryIndex(AIndex: Integer): Integer; virtual;
    function GetDisplayItemIndex(AIndex: Integer): Integer; virtual;
    function GetNextDisplayCategoryIndex(AIndex: Integer): Integer; virtual;
    function GetItemIndexForNode(ANode: TTMSFNCTreeViewVirtualNode): Integer; virtual;
    function GetItemForNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTableViewItem; virtual;
    function GetNodeForItemIndex(AItemIndex: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    function HTMLReplace(AValue: string; AItem: TTMSFNCTableViewItem): string;
    function ItemFromDifferentCategory(AItem1, AItem2: TTMSFNCTableViewItem): Boolean; virtual;
    function MatchFilter(AItem: TTMSFNCTableViewItem): Boolean; virtual;
    function GetCharacterForItem(AItem: TTMSFNCTableViewItem): String; virtual;
    function GetCharacter(Idx: Integer): String; virtual;
    function GetCharacterCount: Integer; virtual;
    function FindCategoryWithCharacter(ACategory: String): TTMSFNCTableViewDisplayItem;
    function FindCategoryWithCharacterID(ACategoryID: Integer): TTMSFNCTableViewDisplayItem;
    function FindFirstItemWithCategory(ACategory: String): TTMSFNCTableViewItem; virtual;
    function FindFirstItemWithCategoryID(ACategoryID: Integer): TTMSFNCTableViewItem; virtual;
    function GetMoreOptionsSize: Single; virtual;
    function IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DoEnter; override;
    procedure DoItemMoreOptionClick(AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption); virtual;
    procedure DoFilterButtonClicked(Sender: TObject); virtual;
    procedure DoEditButtonClicked(Sender: TObject); virtual;
    procedure DoEditChange(Sender: TObject); virtual;
    procedure DoBeforeApplyFilter(AFilter: TTMSFNCTableViewFilterData; AAllow: Boolean); virtual;
    procedure DoAfterApplyFilter(AFilter: TTMSFNCTableViewFilterData); virtual;
    procedure DoDoneButtonClicked(Sender: TObject); virtual;
    procedure DoBackButtonClicked(Sender: TObject); virtual;
    procedure DoItemAccessoryClick(AItem: TTMSFNCTableViewItem); virtual;
    procedure DoDetailControlTimer(Sender: TObject);
    procedure DoItemShowDetailControl(AItem: TTMSFNCTableViewItem; ADetailControl: TControl); virtual;
    procedure DoItemHideDetailControl(AItem: TTMSFNCTableViewItem; ADetailControl: TControl); virtual;
    procedure DoBeforeItemShowDetailControl(AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean); virtual;
    procedure DoBeforeItemHideDetailControl(AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean); virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure DoStartReload; virtual;
    procedure DoStopReload; virtual;
    procedure ScrollToCategoryId(ACategoryID: Integer); virtual;
    procedure ScrollToCategoryCharacter(ACharacter: String); virtual;
    procedure RegisterRuntimeClasses; override;
    procedure ApplyStyle; override;
    procedure UpdateControlAfterResize; override;
    procedure ResetToDefaultStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure UpdateTableView(ADisplayOnly: Boolean = False); virtual;
    procedure CustomizeButtons; virtual;
    procedure SelectAllCheckedItems; virtual;
    procedure UnSelectAllItems; virtual;
    procedure CalculateItems; virtual;
    procedure CalculateLookupBar; virtual;
    procedure AddDisplayCategory(AItem: TTMSFNCTableViewItem); virtual;
    procedure AddDisplayItem(AItem: TTMSFNCTableViewItem); virtual;
    procedure DoGetNodeHeight(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHeight: Double); virtual;
    procedure DoGetNodeIcon(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ALarge: Boolean; var AIcon: TTMSFNCBitmap); virtual;
    procedure DoGetNodeSides(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides); virtual;
    procedure DoGetColumnText(Sender: TObject; {%H-}AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: String); virtual;
    procedure DoGetColumnTrimming(Sender: TObject; {%H-}AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetColumnWordWrapping(Sender: TObject; {%H-}AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean); virtual;
    procedure DoGetColumnHorizontalTextAlign(Sender: TObject; {%H-}AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetColumnVerticalTextAlign(Sender: TObject; {%H-}AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoIsNodeChecked(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AIsChecked: Boolean); virtual;
    procedure DoGetNodeCheckType(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType); virtual;
    procedure DoAfterCheckNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer); virtual;
    procedure DoAfterUnCheckNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer); virtual;
    procedure DoItemCheckChanged(AItem: TTMSFNCTableViewItem); virtual;
    procedure DoGetNumberOfNodes(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer); virtual;
    procedure DoGetHTMLTemplateValue(AItem: TTMSFNCTableViewItem; AName: string; var AValue: string); virtual;
    procedure DoGetHTMLTemplate(AItem: TTMSFNCTableViewItem; var AHTMLTemplate: string); virtual;
    procedure DoIsNodeExtended(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AExtended: Boolean); virtual;
    procedure DoGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AMode: TTMSFNCTreeViewNodeTextMode; var AText: String); virtual;
    procedure DoGetNodeTitle(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AMode: TTMSFNCTreeViewNodeTextMode; var ATitle: String); virtual;
    procedure DoGetNodeTitleExpanded(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AExpanded: Boolean); virtual;
    procedure DoGetNodeTrimming(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetNodeTitleTrimming(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DrawBadge(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem); virtual;
    procedure DoDrawNodeExtra(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoDrawItemCustomAccessory(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem); virtual;
    procedure DoBeforeDrawItemMoreOption(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItemMoreOption(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption); virtual;
    procedure DoGetNodeWordWrapping(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AWordWrapping: Boolean); virtual;
    procedure DoGetNodeTitleWordWrapping(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AWordWrapping: Boolean); virtual;
    procedure DoGetNodeExtraSize(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AExtraSize: Single); virtual;
    procedure DoGetNodeTitleHorizontalTextAlign(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeTitleVerticalTextAlign(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeHorizontalTextAlign(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeVerticalTextAlign(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeSelectedTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ASelectedTextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ADisabledTextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeTitleColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeSelectedTitleColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ASelectedTitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledTitleColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ADisabledTitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoIsNodeEnabled(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean); virtual;
    procedure DoAfterSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoAfterUnSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNeedFilterDropDownData(Sender: TObject; {%H-}AColumn: Integer; AValues: TStrings); virtual;
    procedure DoFilterSelect(Sender: TObject; {%H-}AColumn: integer; var ACondition: string); virtual;
    procedure DoBeforeDrawSortIndicator(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSortIndicator(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind); virtual;
    procedure DoBeforeDrawNode(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawNode(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoItemCompare(AItem1, AItem2: TTMSFNCTableViewItem; var ACompareResult: Integer); virtual;
    procedure DoCategoryCompare(ACategory1, ACategory2: TTMSFNCTableViewCategory; var ACompareResult: Integer); virtual;
    procedure DoBeforeDrawNodeText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: String); virtual;
    procedure DoBeforeDrawNodeTitle(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeTitle(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: String); virtual;
    procedure DoBeforeDrawNodeIcon(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeIcon(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap); virtual;
    procedure DoBeforeDrawNodeCheck(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeCheck(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap); virtual;
    procedure DoNodeAnchorClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; AAnchor: String); virtual;
    procedure DoNodeTitleAnchorClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; AAnchor: String); virtual;
    procedure DoNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoVScroll(Sender: TObject; APosition: Single); virtual;
    procedure DoBeforeCutToClipboard(Sender: TObject; var ACanCut: Boolean); virtual;
    procedure DoBeforeCopyToClipboard(Sender: TObject; var ACanCopy: Boolean); virtual;
    procedure DoBeforePasteFromClipboard(Sender: TObject; var ACanPaste: Boolean); virtual;
    procedure DoAfterCutToClipboard(Sender: TObject); virtual;
    procedure DoAfterCopyToClipboard(Sender: TObject); virtual;
    procedure DoAfterPasteFromClipboard(Sender: TObject); virtual;
    procedure DoBeforeReorderItem(AFromItem, AToItem: TTMSFNCTableViewItem; var ACanReorder: Boolean); virtual;
    procedure DoAfterReorderItem(AFromItem, AToItem: TTMSFNCTableViewItem); virtual;
    procedure DoBeforeDropItem(AFromItem, AToItem: TTMSFNCTableViewItem; var ACanDrop: Boolean); virtual;
    procedure DoAfterDropItem(AFromItem, AToItem: TTMSFNCTableViewItem); virtual;
    procedure CustomizeTreeView({%H-}ATreeView: TTMSFNCTreeView); virtual;
    procedure DoCustomDragOver(Sender: TObject; Source: TObject; {%H-}Point: TPointF; var Accept: Boolean); virtual;
    procedure DoCustomDragDrop(Sender: TObject; Source: TObject; {%H-}Point: TPointF); virtual;
    procedure DragItemMove({%H-}AFromItem, {%H-}AToItem: TTMSFNCTableViewItem); virtual;
    procedure DragItemAdd({%H-}ANewItem, {%H-}AAssignItem, {%H-}AInsertItem: TTMSFNCTableViewItem); virtual;
    procedure DragItemDelete({%H-}AItem: TTMSFNCTableViewItem); virtual;
    procedure DoCustomReorder(Sender: TObject; AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoCopyToClipboard(Sender: TObject); virtual;
    procedure DoCutToClipboard(Sender: TObject); virtual;
    procedure DoPasteFromClipboard(Sender: TObject); virtual;
    procedure DoColumnSort(Sender: TObject; {%H-}AColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode); virtual;
    procedure DoLookup(Sender: TObject; ALookupString: String); virtual;
    procedure UpdateActiveDetailControl; virtual;
    procedure DoFooterAnchorClick(Sender: TObject; AAnchor: String);
    procedure DoHeaderAnchorClick(Sender: TObject; AAnchor: String);
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property Version: String read GetVersion;
    property TreeView: TTMSFNCTreeViewTableView read FTreeView;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Items: TTMSFNCTableViewItems read FItems write SetItems;
    property DefaultItem: TTMSFNCTableViewItem read FDefaultItem write SetDefaultItem;
    property ItemAppearance: TTMSFNCTableViewItemAppearance read FItemAppearance write SetItemAppearance;
    property CategoryAppearance: TTMSFNCTableViewCategoryAppearance read FCategoryAppearance write SetCategoryAppearance;
    property MoreOptionAppearance: TTMSFNCTableViewMoreOptionAppearance read FMoreOptionAppearance write SetMoreOptionAppearance;
    property Interaction: TTMSFNCTableViewInteraction read FInteraction write SetInteraction;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property LookupBar: TTMSFNCTableViewLookupBar read FLookupBar write SetLookupBar;
    property Reload: TTMSFNCTableViewReload read FReload write SetReload;
    property Categories: TTMSFNCTableViewCategories read FCategories write SetCategories;
    property MoreOptions: TTMSFNCTableViewMoreOptions read FMoreOptions write SetMoreOptions;
    property CategoryType: TTMSFNCTableViewCategoryType read FCategoryType write SetCategoryType default tvctNone;
    property OnItemSelected: TTMSFNCTableViewItemSelectedEvent read FOnItemSelected write FOnItemSelected;
    property OnItemUnSelected: TTMSFNCTableViewItemUnSelectedEvent read FOnItemUnSelected write FOnItemUnSelected;
    property OnItemSelectionChanged: TTMSFNCTableViewItemSelectionChangedEvent read FOnItemSelectionChanged write FOnItemSelectionChanged;
    property Header: TTMSFNCTableViewHeader read FHeader write SetHeader;
    property Footer: TTMSFNCTableViewFooter read FFooter write SetFooter;
    property VerticalScrollBarVisible: Boolean read GetVerticalScrollBarVisible write SetVerticalScrollBarVisible default False;
    property OnNeedFilterDropDownData: TTMSFNCTableViewNeedFilterDropDownDataEvent read FOnNeedFilterDropDownData write FOnNeedFilterDropDownData;
    property OnFilterSelect: TTMSFNCTableViewFilterSelectEvent read FOnFilterSelect write FOnFilterSelect;
    property OnBeforeDrawItem: TTMSFNCTableViewBeforeDrawItemEvent read FOnBeforeDrawItem write FOnBeforeDrawItem;
    property OnAfterDrawItem: TTMSFNCTableViewAfterDrawItemEvent read FOnAfterDrawItem write FOnAfterDrawItem;
    property OnBeforeDrawItemMoreOption: TTMSFNCTableViewBeforeDrawItemMoreOptionEvent read FOnBeforeDrawItemMoreOption write FOnBeforeDrawItemMoreOption;
    property OnAfterDrawItemMoreOption: TTMSFNCTableViewAfterDrawItemMoreOptionEvent read FOnAfterDrawItemMoreOption write FOnAfterDrawItemMoreOption;
    property OnItemCompare: TTMSFNCTableViewItemCompareEvent read FOnItemCompare write FOnItemCompare;
    property OnCategoryCompare: TTMSFNCTableViewCategoryCompareEvent read FOnCategoryCompare write FOnCategoryCompare;
    property OnAfterDrawItemIcon: TTMSFNCTableViewAfterDrawItemIconEvent read FOnAfterDrawItemIcon write FOnAfterDrawItemIcon;
    property OnBeforeDrawItemIcon: TTMSFNCTableViewBeforeDrawItemIconEvent read FOnBeforeDrawItemIcon write FOnBeforeDrawItemIcon;
    property OnAfterDrawItemCheck: TTMSFNCTableViewAfterDrawItemCheckEvent read FOnAfterDrawItemCheck write FOnAfterDrawItemCheck;
    property OnBeforeDrawItemCheck: TTMSFNCTableViewBeforeDrawItemCheckEvent read FOnBeforeDrawItemCheck write FOnBeforeDrawItemCheck;
    property OnBeforeDrawItemText: TTMSFNCTableViewBeforeDrawItemTextEvent read FOnBeforeDrawItemText write FOnBeforeDrawItemText;
    property OnAfterDrawItemText: TTMSFNCTableViewAfterDrawItemTextEvent read FOnAfterDrawItemText write FOnAfterDrawItemText;
    property OnBeforeDrawItemTitle: TTMSFNCTableViewBeforeDrawItemTitleEvent read FOnBeforeDrawItemTitle write FOnBeforeDrawItemTitle;
    property OnAfterDrawItemTitle: TTMSFNCTableViewAfterDrawItemTitleEvent read FOnAfterDrawItemTitle write FOnAfterDrawItemTitle;
    property OnAfterDrawSortIndicator: TTMSFNCTableViewAfterDrawSortIndicatorEvent read FOnAfterDrawSortIndicator write FOnAfterDrawSortIndicator;
    property OnBeforeDrawSortIndicator: TTMSFNCTableViewBeforeDrawSortIndicatorEvent read FOnBeforeDrawSortIndicator write FOnBeforeDrawSortIndicator;
    property OnItemAnchorClick: TTMSFNCTableViewItemAnchorClickEvent read FOnItemAnchorClick write FOnItemAnchorClick;
    property OnItemTitleAnchorClick: TTMSFNCTableViewItemAnchorClickEvent read FOnItemTitleAnchorClick write FOnItemTitleAnchorClick;
    property OnItemClick: TTMSFNCTableViewItemClickEvent read FOnItemClick write FOnItemClick;
    property OnItemMouseLeave: TTMSFNCTableViewItemMouseLeaveEvent read FOnItemMouseLeave write FOnItemMouseLeave;
    property OnItemMouseEnter: TTMSFNCTableViewItemMouseEnterEvent read FOnItemMouseEnter write FOnItemMouseEnter;
    property OnItemDblClick: TTMSFNCTableViewItemClickEvent read FOnItemDblClick write FOnItemDblClick;
    property OnVScroll: TTMSFNCTableViewScrollEvent read FOnVScroll write FOnVScroll;
    property OnBeforeReorderItem: TTMSFNCTableViewBeforeReorderItemEvent read FOnBeforeReorderItem write FOnBeforeReorderItem;
    property OnAfterReorderItem: TTMSFNCTableViewAfterReorderItemEvent read FOnAfterReorderItem write FOnAfterReorderItem;
    property OnBeforeDropItem: TTMSFNCTableViewBeforeDropItemEvent read FOnBeforeDropItem write FOnBeforeDropItem;
    property OnAfterDropItem: TTMSFNCTableViewAfterDropItemEvent read FOnAfterDropItem write FOnAfterDropItem;
    property OnBeforeCutToClipboard: TTMSFNCTableViewBeforeCutToClipboardEvent read FOnBeforeCutToClipboard write FOnBeforeCutToClipboard;
    property OnBeforeCopyToClipboard: TTMSFNCTableViewBeforeCopyToClipboardEvent read FOnBeforeCopyToClipboard write FOnBeforeCopyToClipboard;
    property OnBeforePasteFromClipboard: TTMSFNCTableViewBeforePasteFromClipboardEvent read FOnBeforePasteFromClipboard write FOnBeforePasteFromClipboard;
    property OnAfterCutToClipboard: TTMSFNCTableViewAfterCutToClipboardEvent read FOnAfterCutToClipboard write FOnAfterCutToClipboard;
    property OnAfterCopyToClipboard: TTMSFNCTableViewAfterCopyToClipboardEvent read FOnAfterCopyToClipboard write FOnAfterCopyToClipboard;
    property OnAfterPasteFromClipboard: TTMSFNCTableViewAfterPasteFromClipboardEvent read FOnAfterPasteFromClipboard write FOnAfterPasteFromClipboard;
    property OnGetHTMLTemplateValue: TTMSFNCTableViewGetHTMLTemplateValueEvent read FOnGetHTMLTemplateValue write FOnGetHTMLTemplateValue;
    property OnGetHTMLTemplate: TTMSFNCTableViewGetHTMLTemplateEvent read FOnGetHTMLTemplate write FOnGetHTMLTemplate;
    property OnManualLookupCategory: TTMSFNCTableViewLookupCategoryEvent read FOnManualLookupCategory write FOnManualLookupCategory;
    property OnCategoryClick: TTMSFNCTableViewLookupCategoryEvent read FOnCategoryClick write FOnCategoryClick;
    property OnStartReload: TNotifyEvent read FOnStartReload write FOnStartReload;
    property OnStopReload: TNotifyEvent read FOnStopReload write FOnStopReload;
    property OnDrawItemCustomAccessory: TTMSFNCTableViewDrawItemCustomAccessoryEvent read FOnDrawItemCustomAccessory write FOnDrawItemCustomAccessory;
    property OnItemAccessoryClick: TTMSFNCTableViewItemClickEvent read FOnItemAccessoryClick write FOnItemAccessoryClick;
    property OnItemCheckChanged: TTMSFNCTableViewItemCheckChangedEvent read FOnItemCheckChanged write FOnItemCheckChanged;
    property OnBeforeApplyFilter: TTMSFNCTableViewBeforeApplyFilterEvent read FOnBeforeApplyFilter write FOnBeforeApplyFilter;
    property OnAfterApplyFilter: TTMSFNCTableViewAfterApplyFilterEvent read FOnAfterApplyFilter write FOnAfterApplyFilter;
    property OnItemMoreOptionClick: TTMSFNCTableViewItemMoreOptionClickEvent read FOnItemMoreOptionClick write FOnItemMoreOptionClick;
    property OnBeforeItemShowDetailControl: TTMSFNCTableViewBeforeItemShowDetailControl read FOnBeforeItemShowDetailControl write FOnBeforeItemShowDetailControl;
    property OnBeforeItemHideDetailControl: TTMSFNCTableViewBeforeItemHideDetailControl read FOnBeforeItemHideDetailControl write FOnBeforeItemHideDetailControl;
    property OnItemShowDetailControl: TTMSFNCTableViewItemShowDetailControl read FOnItemShowDetailControl write FOnItemShowDetailControl;
    property OnItemHideDetailControl: TTMSFNCTableViewItemHideDetailControl read FOnItemHideDetailControl write FOnItemHideDetailControl;
    property OnHeaderAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick read FOnHeaderAnchorClick write FOnHeaderAnchorClick;
    property OnFooterAnchorClick: TTMSFNCTableViewAnchorHeaderFooterClick read FOnFooterAnchorClick write FOnFooterAnchorClick;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Draw({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure Assign(Source: TPersistent); override;
    function AddItem(AText: string = ''): TTMSFNCTableViewItem; virtual;
    function XYToAccessoryItem(X, Y: Single): TTMSFNCTableViewItem; virtual;
    function XYToItem(X, Y: Single): TTMSFNCTableViewItem; virtual;
    function XYToMoreOption(X, Y: Single): TTMSFNCTableViewMoreOption; virtual;
    function XYToItemIndex(X, Y: Single): Integer; virtual;
    function SelectedItemCount: Integer; virtual;
    function GetItemsFromClipboard: TTMSFNCTableViewCopyItems; virtual;
    function LookupItem(ALookupString: String; ACaseSensitive: Boolean = False; AAutoSelect: Boolean = False): TTMSFNCTableViewItem; virtual;
    function IsItemSelectable(AItem: TTMSFNCTableViewItem): Boolean; virtual;
    function ReloadProgress: Single; virtual;
    function DetailControlActive: Boolean; virtual;
    function GetCheckedItems: TTMSFNCTableViewCheckedItems; virtual;
    function GetSelectedItems: TTMSFNCTableViewSelectedItems; virtual;
    procedure LookupCustomCategory(ACategoryID: Integer); virtual;
    procedure LookupCategory(ACategory: String);virtual;
    procedure RemoveItem(AItem: TTMSFNCTableViewItem); virtual;
    procedure EnableInteraction; virtual;
    procedure DisableInteraction; virtual;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure InitSample; virtual;
    procedure ScrollToItem(AItemIndex: Integer); virtual;
    procedure ClearSelection; virtual;
    procedure UnCheckAllItems; virtual;
    procedure StartFiltering; virtual;
    procedure StopFiltering; virtual;
    procedure StartEditMode; virtual;
    procedure ShowMoreOptions(AItem: TTMSFNCTableViewItem); virtual;
    procedure HideMoreOptions; virtual;
    procedure StopEditMode; virtual;
    procedure ToggleEditMode; virtual;
    procedure CheckAllItems; virtual;
    procedure SelectItem(AItemIndex: Integer); virtual;
    procedure SelectItems(AItemIndexes: TTMSFNCTableViewIntegerArray); virtual;
    procedure LoadFromStrings(AStrings: TStrings); virtual;
    {$IFNDEF WEBLIB}
    procedure LoadFromFile(AFileName: string); virtual;
    procedure LoadFromStream(AStream: TStream); virtual;
    procedure SaveToStrings(AStrings: TStrings); virtual;
    procedure SaveToFile(AFileName: string; ATextOnly: Boolean = True); virtual;
    procedure SaveToStream(AStream: TStream; ATextOnly: Boolean = True); virtual;
    {$ENDIF}
    procedure CutToClipboard(ATextOnly: Boolean = False); virtual;
    procedure CopyToClipboard(ATextOnly: Boolean = False); virtual;
    procedure PasteFromClipboard; virtual;
    procedure Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewItemsSortMode = tvismAscending); {$IFDEF FMXLIB}reintroduce;{$ENDIF} virtual;
    procedure ApplyFilter; virtual;
    procedure RemoveFilter; virtual;
    procedure RemoveFilters; virtual;
    procedure StopReload; virtual;
    procedure StartReload; virtual;
    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;
    procedure ShowDetailControl(AItemIndex: Integer = -1); virtual;
    procedure HideDetailControl; virtual;
    procedure UpdateReloadProgress(AProgress: Single; AAutoStopReload: Boolean = True); virtual;
    property Filter: TTMSFNCTableViewFilter read FFilter;
    property SelectedItem: TTMSFNCTableViewItem read GetSelectedItem write SetSelectedItem;
    property SelectedItems[AIndex: Integer]: TTMSFNCTableViewItem read GetSelItem;
    property CheckedItem[AItem: TTMSFNCTableViewItem]: Boolean read GetCheckedItem write SetCheckedItem;
    property Checked[AItemIndex: Integer]: Boolean read GetChecked write SetChecked;
    property DoneButton: TTMSFNCToolBarButton read FDoneButton;
    property EditButton: TTMSFNCToolBarButton read FEditButton;
    property FilterButton: TTMSFNCToolBarButton read FFilterButton;
    property BackButton: TTMSFNCToolBarButton read FBackButton;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCTableView = class(TTMSFNCCustomTableView)
  protected
    procedure RegisterRuntimeClasses; override;
    property OnNeedFilterDropDownData;
    property OnFilterSelect;
    property OnBeforeReorderItem;
    property OnAfterReorderItem;
    property OnBeforeDropItem;
    property OnAfterDropItem;
    property OnBeforeCutToClipboard;
    property OnBeforeCopyToClipboard;
    property OnBeforePasteFromClipboard;
    property OnAfterCutToClipboard;
    property OnAfterCopyToClipboard;
    property OnAfterPasteFromClipboard;
  public
    property TreeView;
  published
    property Fill;
    property Stroke;
    property BitmapContainer;
    property ItemAppearance;
    property DefaultItem;
    property Items;
    property Header;
    property Footer;
    property ItemIndex;
    property Interaction;
    property LookupBar;
    property Reload;
    property Categories;
    property MoreOptions;
    property MoreOptionAppearance;
    property CategoryAppearance;
    property CategoryType;
    property OnItemSelected;
    property OnItemUnSelected;
    property OnItemSelectionChanged;
    property Version;
    property VerticalScrollBarVisible;
    property OnBeforeDrawItem;
    property OnAfterDrawItem;
    property OnItemCompare;
    property OnCategoryCompare;
    property OnCategoryClick;
    property OnManualLookupCategory;
    property OnAfterDrawItemIcon;
    property OnBeforeDrawItemIcon;
    property OnBeforeDrawItemText;
    property OnAfterDrawItemText;
    property OnItemAnchorClick;
    property OnItemClick;
    property OnItemMouseLeave;
    property OnItemMouseEnter;
    property OnItemDblClick;
    property OnVScroll;
    property OnStartReload;
    property OnStopReload;
    property OnDrawItemCustomAccessory;
    property OnItemAccessoryClick;
    property OnItemCheckChanged;
    property OnAfterDrawItemCheck;
    property OnBeforeDrawItemCheck;
    property OnBeforeApplyFilter;
    property OnAfterApplyFilter;
    property OnItemMoreOptionClick;
    property OnBeforeItemShowDetailControl;
    property OnBeforeItemHideDetailControl;
    property OnItemShowDetailControl;
    property OnItemHideDetailControl;
    property OnHeaderAnchorClick;
    property OnFooterAnchorClick;
  end;

{$IFDEF WEBLIB}
const
  TMSFNCTABLEVIEWSEARCH = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXElEQVQ4jY2TP0'+
                           '/CUBTFf1VJGhmoEzCQIIkDG4y4gNGJ5cnoiKMTfACCpoubDk5OfAPoUpcm4sRYEkzcsEkHJNGIRgwJiwPUtKVFz3Tzzj3n3PdPYg3KDS0NlID0cq'+
                           'mrq6Lr7pFChArQBGoBtAVUHaMwAxPIReUIR/kUhWyS8fs3Rt9m8PzqtFV0VXRWDMoN7RxoZhIxLk/3icoRD2+YNldtE2AC7G76DfaKJ+2oHJFvzo'+
                           'orYoBMMoYEDKw3GRhv+NJLgFLIJgLFDg7zKacUG0ENcWU7VOznAw2+ZvO1BlMX7zFwrsYwbU+TH1pv6JQPQRNcT2dzbu8eA8XDlw86C4MJ0NoKCg'+
                           'BqhmkzHH0iChniO4s9955G7vS6rgrL8w7KDS0H3ANK6PyL5Lquiha4XqJPPAEOltSxS2wBHV0VE2dBChPrquivmeIXziG6x678V+w2uFgmV/3f9S'+
                           '/8AOOWdCNEGLMkAAAAAElFTkSuQmCC';
{$ENDIF}

implementation

{$R 'TMSFNCTableView.res'}

uses
  Math, WEBLib.TMSFNCUtils, SysUtils, WEBLib.Graphics;

type
  TTMSFNCTreeViewOpen = class(TTMSFNCTreeViewTableView);
  TTMSFNCCustomTreeViewOpen = class(TTMSFNCCustomTreeView);
  TTMSFNCCustomTableViewOpen = class(TTMSFNCCustomTableView);
  TTMSFNCTreeViewColumnOpen = class(TTMSFNCTreeViewColumn);

function AnimateDouble(var Start, Stop: Double; Delta, Margin: Double): Boolean;
begin
  Result := true;
  if (Start > Stop - Margin) and (Start < Stop + Margin) then
  begin
    Start := Stop;
    Result := false;
  end
  else
  begin
    Delta := Max(Margin, Delta);
    if Start < Stop then
      Start := Start + Delta
    else
      Start := Start - Delta;
  end;
end;

procedure TTMSFNCCustomTableView.AddDisplayItem(AItem: TTMSFNCTableViewItem);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  dp.CategoryID := -1;
  dp.Item := AItem;
  dp.Kind := tvikItem;
  dp.Text := '';
  dp.Row := FDisplayList.Count;
  if Assigned(AItem) then
    AItem.FRow := dp.Row;
  FDisplayList.Add(dp);
end;

procedure TTMSFNCCustomTableView.AddDisplayCategory(AItem: TTMSFNCTableViewItem);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if AItem.Visible then
  begin
    dp.Item := nil;
    dp.Kind := tvikCategory;
    dp.Text := GetCharacterForItem(AItem);
    dp.CategoryID := AItem.CategoryID;
    dp.Row := FDisplayList.Count;
    if Assigned(AItem) then
      AItem.FRow := dp.Row;
    FDisplayList.Add(dp);
    FCategoryList.Add(dp);
  end;
end;

function TTMSFNCCustomTableView.AddItem(AText: string = ''): TTMSFNCTableViewItem;
begin
  Result := Items.Add;
  Result.Text := AText;
end;

procedure TTMSFNCCustomTableView.ApplyFilter;
begin
  FFilterApplied := True;
  UpdateTableView;
end;

procedure TTMSFNCCustomTableView.ApplyStyle;
var
  I: Integer;
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    for I := 0 to Items.Count - 1 do
    begin
      Items[I].TextColor := c;
      Items[I].TitleColor := c;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomTableView) then
  begin
    FTreeView.Assign((Source as TTMSFNCCustomTableView).TreeView);
    FItems.Assign((Source as TTMSFNCCustomTableView).Items);
    FItemAppearance.Assign((Source as TTMSFNCCustomTableView).ItemAppearance);
    FCategoryAppearance.Assign((Source as TTMSFNCCustomTableView).CategoryAppearance);
    FCategoryType := (Source as TTMSFNCCustomTableView).CategoryType;
    FFilter.Assign((Source as TTMSFNCCustomTableView).Filter);
    FHeader.Assign((Source as TTMSFNCCustomTableView).Header);
    FFooter.Assign((Source as TTMSFNCCustomTableView).Footer);
    FDefaultItem.Assign((Source as TTMSFNCCustomTableView).DefaultItem);
    FInteraction.Assign((Source as TTMSFNCCustomTableView).Interaction);
    FReload.Assign((Source as TTMSFNCCustomTableView).Reload);
    FLookupBar.Assign((Source as TTMSFNCCustomTableView).LookupBar);
  end;
end;

procedure TTMSFNCCustomTableView.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
  if Assigned(FTreeView) then
  begin
    if Assigned(SelectedItem) then
      FSaveSelectedItem := SelectedItem.Index
    else
      FSaveSelectedItem := -1;

    FTreeView.BeginUpdate;
    FTreeView.ClearNodeList;
  end;
end;

procedure TTMSFNCCustomTableView.CalculateItems;
var
  I: Integer;
  J: Integer;
  v: Boolean;
  it: TTMSFNCTableViewItem;
begin
  if (FUpdateCount > 0) or IsDestroying then
    Exit;

  FDisplayList.Clear;
  FCategoryList.Clear;

  j := 0;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    v := it.Visible;
    if FFilterApplied then
      v := v and MatchFilter(it);

    if CategoryType <> tvctNone then
    begin
      if FDisplayList.Count = 0 then
      begin
        if v then
        begin
          AddDisplayCategory(it);
          AddDisplayItem(it);
        end;
        Inc(J);
      end
      else
      begin
        if ItemFromDifferentCategory(it, Items[J - 1]) then
        begin
          if v then
          begin
            AddDisplayCategory(it);
            AddDisplayItem(it);
          end;
          Inc(J);
        end
        else
        begin
          if v then
            AddDisplayItem(it);
          Inc(J);
        end;
      end;
    end
    else if v then
      AddDisplayItem(it);
  end;
end;

procedure TTMSFNCCustomTableView.CalculateLookupBar;
var
  s: Integer;
  I: Integer;
  str: string;
  r, cr: TRectF;
  ldp: TTMSFNCTableViewLookupBarDisplayItem;
  ypos: Single;
  g: TTMSFNCGraphics;
  j: Integer;
begin
  if (FUpdateCount > 0) or IsDestroying or not Assigned(FTreeView) then
    Exit;

  FLookupBarDisplayList.Clear;

  if LookupBar.Visible and (CategoryType <> tvctNone) then
  begin
    cr := FTreeView.GetLookupBarRect;

    case CategoryType of
      tvctAlphaBetic, tvctAlphaNumericFirst, tvctAlphaNumericLast:
      begin
        s := GetCharacterCount;
        for I := 1 to s do
        begin
          str := GetCharacter(I);
          r.Left := cr.Left;
          r.Right := cr.Right;
          r.Top := cr.Top + ((I - 1) * ((cr.Bottom - cr.Top) / s));
          r.Bottom := r.Top + ((cr.Bottom - cr.Top) / s);

          ldp.Rect := r;
          ldp.Text := str;
          ldp.Active := Assigned(FindFirstItemWithCategory(str));

          FLookupBarDisplayList.Add(ldp);
        end;
      end;
      tvctCustom:
      begin
        FCustomChar.Clear;
        for I := 0 to Categories.Count - 1 do
          FCustomChar.Add(TTMSFNCTableViewCustomChar.Create(Categories[I].Id, False));

        for I := 0 to Items.Count - 1 do
        begin
          str := Items[I].StrippedHTMLText;
          if (str <> '') then
          begin
            j := Items[I].CategoryID;
            FCustomChar.ActivateID(j);
          end;
        end;

        g := TTMSFNCGraphics.CreateBitmapCanvas;
        try
          g.Font.Assign(LookupBar.Font);
          s := Categories.Count - 1;
          ypos := cr.Top + 3;
          for I := 0 to s do
          begin
            str := Categories[I].LookupText;

            r.Left := cr.Left;
            r.Right := cr.Right;
            r.Top := ypos;
            r.Bottom := r.Top + g.CalculateTextHeight(str);
            ypos := ypos + (r.Bottom - r.Top) + 3;

            ldp.Rect := r;
            ldp.Text := str;
            ldp.Active := Assigned(FindFirstItemWithCategoryID(Categories[I].Id));

            FLookupBarDisplayList.Add(ldp);
          end;
        finally
          g.Free;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  UpdateControlAfterResize;
end;

procedure TTMSFNCCustomTableView.CheckAllItems;
var
  I: Integer;
begin
  BeginUpdate;
  for I := 0 to Items.Count - 1 do
    Items[I].Checked := True;
  EndUpdate;
end;

procedure TTMSFNCCustomTableView.ClearSelection;
begin
  if Assigned(FTreeView) then
  begin
    if FEditMode then
      UnCheckAllItems;
    FTreeView.UnSelectAllNodes;
  end;
end;

function TTMSFNCCustomTableView.GetCheckedItems: TTMSFNCTableViewCheckedItems;
var
  I: Integer;
  n: TTMSFNCTableViewItem;
begin
  Result := nil;
  for I := 0 to Items.Count - 1 do
  begin
    n := Items[I] as TTMSFNCTableViewItem;
    if n.Checked then
    begin
      SetLength(Result, Length(Result) + 1);
      Result[Length(Result) - 1] := n;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.CopyToClipboard(ATextOnly: Boolean);
var
  s: String;
  I: Integer;
  it: TTMSFNCTableViewItem;
  cl: TTMSFNCTreeViewIntegerList;
begin
  cl := TTMSFNCTreeViewIntegerList.Create;
  try
    s := '';
    for I := 0 to SelectedItemCount - 1 do
    begin
      it := SelectedItems[I];
      if Assigned(it) and (cl.IndexOf(it.Index) = -1) then
      begin
        s := s + it.SaveToString(ATextOnly) + ENDOFLINE;
        cl.Add(it.Index);
      end;
    end;

    if s <> '' then
      TTMSFNCClipBoard.SetText(CLP_FMT + s);
  finally
    cl.Free;
  end;
end;

constructor TTMSFNCCustomTableView.Create(AOwner: TComponent);
begin
  inherited;
  FFirstLoad := False;
  FEdit := TTMSFNCtableviewEdit.Create(Self);
  FEdit.FTableView := Self;
  FEdit.OnChange := @DoEditChange;
  {$IFDEF FMXLIB}
  FEdit.OnChangeTracking := DoEditChange;
  FEdit.Stored := False;
  {$ENDIF}

  FEditButton := TTMSFNCToolBarButton.Create(Self);
  FEditButton.OnClick := DoEditButtonClicked;
  FEditButton.Stored := False;
  FEditButton.Width := 50;
  FEditButton.Appearance.FlatStyle := True;

  FDoneButton := TTMSFNCToolBarButton.Create(Self);
  FDoneButton.OnClick := DoDoneButtonClicked;
  FDoneButton.Stored := False;
  FDoneButton.Width := 50;
  FDoneButton.Appearance.FlatStyle := True;

  FFilterButton := TTMSFNCToolBarButton.Create(Self);
  FFilterButton.OnClick := DoFilterButtonClicked;
  FFilterButton.Stored := False;
  FFilterButton.Width := 30;
  FFilterButton.Appearance.FlatStyle := True;
  FFilterButton.Bitmaps.AddBitmapFromResource(TMSFNCTABLEVIEWSEARCH, HInstance, 1.0);
  FFilterButton.BitmapVisible := True;

  FBackButton := TTMSFNCToolBarButton.Create(Self);
  FBackButton.OnClick := DoBackButtonClicked;
  FBackButton.Stored := False;
  FBackButton.Width := 50;
  FBackButton.Appearance.FlatStyle := True;

  FEditButton.Text := 'Edit';
  FDoneButton.Text := 'Done';
  FBackButton.Text := 'Back';

  FDetailControlTimer := TTimer.Create(Self);
  FDetailControlTimer.OnTimer := DoDetailControlTimer;
  FDetailControlTimer.Enabled := False;
  FDetailControlTimer.Interval := 1;

  FDisplayList := TTMSFNCTableViewDisplayList.Create;
  FCategoryList := TTMSFNCTableViewDisplayList.Create;
  FLookupBarDisplayList := TTMSFNCTableViewLookupBarDisplayList.Create;
  FItems := CreateItems;
  FLookupBar := TTMSFNCTableViewLookupBar.Create(Self);
  FReload := TTMSFNCTableViewReload.Create(Self);
  FCategories := TTMSFNCTableViewCategories.Create(Self);
  FMoreOptions := TTMSFNCTableViewMoreOptions.Create(Self);
  FCategoryType := tvctNone;

  FCustomChar := TTMSFNCTableViewCustomCharList.Create;

  FTreeView := CreateTreeView;
  FTreeView.OptimizedHTMLDrawing := True;
  FTreeView.FTableView := Self;
  FTreeView.Stored := False;
  FTreeView.Parent := Self;
  FTreeView.Columns.Clear;
  FColumn := FTreeView.Columns.Add;
  FTreeView.ClearNodes;
  FTreeView.ColumnsAppearance.Layouts := [tclTop, tclBottom];
  FTreeView.ColumnsAppearance.TopSize := 30;
  FTreeView.ColumnsAppearance.BottomSize := 30;
  FTreeView.StretchScrollBars := True;
  FTreeView.VerticalScrollBarVisible := False;
  FTreeview.NodesAppearance.Stroke.Kind := gskSolid;
  FTreeView.NodesAppearance.ExpandColumn := -1;
  FTreeView.NodesAppearance.LevelIndent := 0;
  FTreeView.NodesAppearance.ShowLines := False;
  FTreeView.NodesAppearance.ExpandWidth := 0;
  FTreeView.NodesAppearance.ExpandHeight := 0;
  FTreeView.NodesAppearance.SelectionArea := tsaDefault;
  FTreeView.NodesAppearance.ShowFocus := True;
  FTreeView.NodesAppearance.SelectedFill.Color := MakeGraphicsColor(217, 217, 217);
  FTreeView.NodesAppearance.SelectedStroke.Color := MakeGraphicsColor(217, 217, 217);
  FTreeView.NodesAppearance.Stroke.Color := MakeGraphicsColor(200, 199, 204);

  FFilter := TTMSFNCTableViewFilter.Create(Self);
  FHeader := TTMSFNCTableViewHeader.Create(Self);
  FFooter := TTMSFNCTableViewFooter.Create(Self);
  FItemAppearance := TTMSFNCTableViewItemAppearance.Create(Self);
  FCategoryAppearance := TTMSFNCTableViewCategoryAppearance.Create(Self);
  FMoreOptionAppearance := TTMSFNCTableViewMoreOptionAppearance.Create(Self);
  FDefaultItem := TTMSFNCTableViewItem.Create(nil);
  FInteraction := TTMSFNCTableViewInteraction.Create(Self);
  FCopyItems := TTMSFNCTableViewCopyItems.Create(nil);

  FTreeView.OnGetNodeSides := DoGetNodeSides;
  FTreeView.OnGetColumnText := DoGetColumnText;
  FTreeView.OnGetColumnWordWrapping := DoGetColumnWordWrapping;
  FTreeView.OnGetColumnHorizontalTextAlign := DoGetColumnHorizontalTextAlign;
  FTreeView.OnGetColumnVerticalTextAlign := DoGetColumnVerticalTextAlign;
  FTreeView.OnGetColumnTrimming := DoGetColumnTrimming;
  FTreeView.OnGetNumberOfNodes := DoGetNumberOfNodes;
  FTreeView.OnIsNodeChecked := DoIsNodeChecked;
  FTreeView.OnGetNodeCheckType := DoGetNodeCheckType;
  FTreeView.OnAfterUnCheckNode := DoAfterUnCheckNode;
  FTreeView.OnAfterCheckNode := DoAfterCheckNode;
  FTreeView.OnGetNodeHeight := DoGetNodeHeight;
  FTreeView.OnGetNodeIcon := DoGetNodeIcon;
  FTreeView.OnGetNodeTrimming := DoGetNodeTrimming;
  FTreeView.OnGetNodeWordWrapping := DoGetNodeWordWrapping;
  FTreeView.OnGetNodeTitleTrimming := DoGetNodeTitleTrimming;
  FTreeView.OnGetNodeTitleWordWrapping := DoGetNodeTitleWordWrapping;
  FTreeView.OnDrawNodeExtra := DoDrawNodeExtra;
  FTreeView.OnGetNodeExtraSize := DoGetNodeExtraSize;
  FTreeView.OnGetNodeTextColor := DoGetNodeTextColor;
  FTreeView.OnGetNodeTitleColor := DoGetNodeTitleColor;
  FTreeView.OnIsNodeExtended := DoIsNodeExtended;
  FTreeView.OnGetNodeSelectedTextColor := DoGetNodeSelectedTextColor;
  FTreeView.OnGetNodeDisabledTextColor := DoGetNodeDisabledTextColor;
  FTreeView.OnGetNodeSelectedTitleColor := DoGetNodeSelectedTitleColor;
  FTreeView.OnGetNodeDisabledTitleColor := DoGetNodeDisabledTitleColor;
  FTreeView.OnGetNodeText := DoGetNodeText;
  FTreeView.OnGetNodeTitle := DoGetNodeTitle;
  FTreeView.OnGetNodeTitleExpanded := DoGetNodeTitleExpanded;
  FTreeView.OnGetNodeHorizontalTextAlign := DoGetNodeHorizontalTextAlign;
  FTreeView.OnGetNodeVerticalTextAlign := DoGetNodeVerticalTextAlign;
  FTreeView.OnGetNodeTitleHorizontalTextAlign := DoGetNodeTitleHorizontalTextAlign;
  FTreeView.OnGetNodeTitleVerticalTextAlign := DoGetNodeTitleVerticalTextAlign;
  FTreeView.OnIsNodeEnabled := DoIsNodeEnabled;
  FTreeView.OnAfterSelectNode := DoAfterSelectNode;
  FTreeView.OnAfterUnSelectNode := DoAfterUnSelectNode;
  FTreeView.OnNeedFilterDropDownData := DoNeedFilterDropDownData;
  FTreeView.OnFilterSelect := DoFilterSelect;
  FTreeView.OnBeforeDrawNode := DoBeforeDrawNode;
  FTreeView.OnAfterDrawNode := DoAfterDrawNode;
  FTreeView.OnAfterDrawNodeIcon := DoAfterDrawNodeIcon;
  FTreeView.OnBeforeDrawNodeIcon := DoBeforeDrawNodeIcon;
  FTreeView.OnAfterDrawNodeCheck := DoAfterDrawNodeCheck;
  FTreeView.OnBeforeDrawNodeCheck := DoBeforeDrawNodeCheck;
  FTreeView.OnBeforeDrawNodeText := DoBeforeDrawNodeText;
  FTreeView.OnAfterDrawNodeText := DoAfterDrawNodeText;
  FTreeView.OnBeforeDrawNodeTitle := DoBeforeDrawNodeTitle;
  FTreeView.OnAfterDrawNodeTitle := DoAfterDrawNodeTitle;
  FTreeView.OnNodeAnchorClick := DoNodeAnchorClick;
  FTreeView.OnNodeTitleAnchorClick := @DoNodeTitleAnchorClick;
  FTreeView.OnBeforeDrawSortIndicator := DoBeforeDrawSortIndicator;
  FTreeView.OnAfterDrawSortIndicator := DoAfterDrawSortIndicator;
  FTreeView.OnNodeClick := DoNodeClick;
  FTreeView.OnNodeMouseLeave := DoNodeMouseLeave;
  FTreeView.OnNodeMouseEnter := DoNodeMouseEnter;
  FTreeView.OnNodeDblClick := DoNodeDblClick;
  FTreeView.OnVScroll := DoVScroll;
  FTreeView.OnBeforeCutToClipboard := DoBeforeCutToClipboard;
  FTreeView.OnBeforeCopyToClipboard := DoBeforeCopyToClipboard;
  FTreeView.OnBeforePasteFromClipboard := DoBeforePasteFromClipboard;
  FTreeView.OnAfterCutToClipboard := DoAfterCutToClipboard;
  FTreeView.OnAfterCopyToClipboard := DoAfterCopyToClipboard;
  FTreeView.OnAfterPasteFromClipboard := DoAfterPasteFromClipboard;
  FTreeView.OnHeaderAnchorClick := @DoHeaderAnchorClick;
  FTreeView.OnFooterAnchorClick := @DoFooterAnchorClick;

  TTMSFNCTreeViewOpen(FTreeView).OnCustomReorder := DoCustomReorder;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomDragOver := DoCustomDragOver;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomDragDrop := DoCustomDragDrop;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomCopyToClipboard := DoCopyToClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomCutToClipboard := DoCutToClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomPasteFromClipboard := DoPasteFromClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomColumnSort := DoColumnSort;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomLookup := DoLookup;

  CustomizeTreeView(FTreeView);

  Width := 250;
  Height := 350;
  if IsDesignTime then
  begin
    Stroke.Color := MakeGraphicsColor(200, 199, 204);
    InitSample;
  end;
end;

function TTMSFNCCustomTableView.CreateItems: TTMSFNCTableViewItems;
begin
  Result := TTMSFNCTableViewItems.Create(Self);
end;

function TTMSFNCCustomTableView.CreateTreeView: TTMSFNCTreeViewTableView;
begin
  Result := TTMSFNCTreeViewTableView.Create(Self);
end;

procedure TTMSFNCCustomTableView.CustomizeTreeView(
  ATreeView: TTMSFNCTreeView);
begin

end;

procedure TTMSFNCCustomTableView.CutToClipboard(ATextOnly: Boolean);
var
  I: Integer;
begin
  BeginUpdate;
  CopyToClipboard(ATextOnly);
  for I := SelectedItemCount - 1 downto 0 do
    RemoveItem(SelectedItems[I]);
  EndUpdate;
end;

destructor TTMSFNCCustomTableView.Destroy;
begin
  FEditButton.Free;
  FFilterButton.Free;
  FEdit.Free;
  FBackButton.Free;
  FDoneButton.Free;

  FDetailControlTimer.Free;
  FMoreOptions.Free;
  FCategories.Free;
  FReload.Free;
  FLookupBar.Free;
  FCustomChar.Free;
  FDisplayList.Free;
  FCategoryList.Free;
  FLookupBarDisplayList.Free;
  FFilter.Free;
  FCopyItems.Free;
  FHeader.Free;
  FFooter.Free;
  FDefaultItem.Free;
  FInteraction.Free;
  FItems.Free;
  FTreeView.Free;
  FItemAppearance.Free;
  FCategoryAppearance.Free;
  FMoreOptionAppearance.Free;
  inherited;
end;

function TTMSFNCCustomTableView.DetailControlActive: Boolean;
begin
  Result := Assigned(FActiveDetailControl);
end;

procedure TTMSFNCCustomTableView.StopEditMode;
begin
  if (Assigned(FTreeView) and FTreeView.FReloadActive) or not Assigned(FTreeView) then
    Exit;

  FEditMode := False;
  UpdateTableView;
  UnSelectAllItems;
  UnCheckAllItems;
end;

procedure TTMSFNCCustomTableView.StopFiltering;
begin
  if (Assigned(FTreeView) and FTreeView.FReloadActive) or not Assigned(FTreeView) then
    Exit;

  FFilterActive := False;
  RemoveFilters;
  UpdateTableView;
end;

procedure TTMSFNCCustomTableView.DisableInteraction;
begin
  if Assigned(FTreeView) then
    FTreeView.BlockUserInput := True;
end;

procedure TTMSFNCCustomTableView.DoAfterApplyFilter(
  AFilter: TTMSFNCTableViewFilterData);
begin
  if Assigned(OnAfterApplyFilter) then
    OnAfterApplyFilter(Self, AFilter);
end;

procedure TTMSFNCCustomTableView.DoAfterCheckNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
        begin
          dp.Item.Checked := True;
          if Assigned(FTreeView) then
            FTreeView.DoAfterSelectNode(ANode);
          DoItemCheckChanged(dp.Item);
        end;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoAfterCopyToClipboard(Sender: TObject);
begin
  if Assigned(OnAfterCopyToClipboard) then
    OnAfterCopyToClipboard(Self);
end;

procedure TTMSFNCCustomTableView.DoAfterCutToClipboard(Sender: TObject);
begin
  if Assigned(OnAfterCutToClipboard) then
    OnAfterCutToClipboard(Self);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawItemMoreOption(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem;
  AMoreOption: TTMSFNCTableViewMoreOption);
begin
  if Assigned(OnAfterDrawItemMoreOption) then
    OnAfterDrawItemMoreOption(Self, AGraphics, ARect, AItem, AMoreOption);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawNode(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnAfterDrawItem) then
    OnAfterDrawItem(Self, AGraphics, ARect, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomTableView.DoAfterDrawNodeCheck(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap);
begin
  if Assigned(OnAfterDrawItemCheck) then
    OnAfterDrawItemCheck(Self, AGraphics, ARect, GetItemForNode(ANode), ACheck);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawNodeIcon(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap);
begin
  if Assigned(OnAfterDrawItemIcon) then
    OnAfterDrawItemIcon(Self, AGraphics, ARect, GetItemForNode(ANode), AIcon);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawNodeText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AText: String);
begin
  if Assigned(OnAfterDrawItemText) then
    OnAfterDrawItemText(Self, AGraphics, ARect, GetItemForNode(ANode), AText);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawNodeTitle(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ATitle: String);
begin
  if Assigned(OnAfterDrawItemTitle) then
    OnAfterDrawItemTitle(Self, AGraphics, ARect, GetItemForNode(ANode), ATitle);
end;

procedure TTMSFNCCustomTableView.DoAfterDrawSortIndicator(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind);
var
  i: Integer;
  sk: TTMSFNCTableViewItemsSortKind;
begin
  if Assigned(OnAfterDrawSortIndicator) then
  begin
    i := Integer(ASortKind);
    sk := TTMSFNCTableViewItemsSortKind(i);
    OnAfterDrawSortIndicator(Self, AGraphics, ARect, ASortIndex, sk);
  end;
end;

procedure TTMSFNCCustomTableView.DoAfterDropItem(AFromItem: TTMSFNCTableViewItem; AToItem: TTMSFNCTableViewItem);
begin
  if Assigned(OnAfterDropItem) then
    OnAfterDropItem(Self, AFromItem, AToItem);
end;

procedure TTMSFNCCustomTableView.DoAfterPasteFromClipboard(Sender: TObject);
begin
  if Assigned(OnAfterPasteFromClipboard) then
    OnAfterPasteFromClipboard(Self);
end;

procedure TTMSFNCCustomTableView.DoAfterReorderItem(AFromItem, AToItem: TTMSFNCTableViewItem);
begin
  if Assigned(OnAfterReorderItem) then
    OnAfterReorderItem(Self, AFromItem, AToItem);
end;

procedure TTMSFNCCustomTableView.DoAfterSelectNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, GetItemForNode(ANode));

  if Assigned(OnItemSelectionChanged) then
    OnItemSelectionChanged(Self);
end;

procedure TTMSFNCCustomTableView.DoAfterUnCheckNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
        begin
          dp.Item.Checked := False;
          if Assigned(FTreeView) then
            FTreeView.DoAfterUnSelectNode(ANode);
          DoItemCheckChanged(dp.Item);
        end;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoAfterUnSelectNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemUnSelected) then
    OnItemUnSelected(Self, GetItemForNode(ANode));

  if Assigned(OnItemSelectionChanged) then
    OnItemSelectionChanged(Self);
end;

procedure TTMSFNCCustomTableView.DoBackButtonClicked(Sender: TObject);
begin
  HideDetailControl;
end;

procedure TTMSFNCCustomTableView.DoBeforeApplyFilter(
  AFilter: TTMSFNCTableViewFilterData; AAllow: Boolean);
begin
  if Assigned(OnBeforeApplyFilter) then
    OnBeforeApplyFilter(Self, AFilter, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeCopyToClipboard(Sender: TObject;
  var ACanCopy: Boolean);
begin
  if Assigned(OnBeforeCopyToClipboard) then
    OnBeforeCopyToClipboard(Self, ACanCopy);
end;

procedure TTMSFNCCustomTableView.DoBeforeCutToClipboard(Sender: TObject;
  var ACanCut: Boolean);
begin
  if Assigned(OnBeforeCutToClipboard) then
    OnBeforeCutToClipboard(Self, ACanCut);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawItemMoreOption(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem;
  AMoreOption: TTMSFNCTableViewMoreOption; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItemMoreOption) then
    OnBeforeDrawItemMoreOption(Self, AGraphics, ARect, AItem, AMoreOption, ADefaultDraw);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawNode(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItem) then
    OnBeforeDrawItem(Self, AGraphics, ARect, GetItemForNode(ANode), AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawNodeCheck(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemCheck) then
    OnBeforeDrawItemCheck(Self, AGraphics, ARect, GetItemForNode(ANode), ACheck, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawNodeIcon(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemIcon) then
    OnBeforeDrawItemIcon(Self, AGraphics, ARect, GetItemForNode(ANode), AIcon, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawNodeTitle(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ATitle: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemTitle) then
    OnBeforeDrawItemTitle(Self, AGraphics, ARect, GetItemForNode(ANode), ATitle, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawNodeText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemText) then
    OnBeforeDrawItemText(Self, AGraphics, ARect, GetItemForNode(ANode), AText, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeDrawSortIndicator(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean);
var
  i: Integer;
  sk: TTMSFNCTableViewItemsSortKind;
begin
  if Assigned(OnBeforeDrawSortIndicator) then
  begin
    i := Integer(ASortKind);
    sk := TTMSFNCTableViewItemsSortKind(i);
    OnBeforeDrawSortIndicator(Self, AGraphics, ARect, ASortIndex, sk, ADefaultDraw);
  end;
end;

procedure TTMSFNCCustomTableView.DoBeforeDropItem(AFromItem: TTMSFNCTableViewItem; AToItem: TTMSFNCTableViewItem; var ACanDrop: Boolean);
begin
  if Assigned(OnBeforeDropItem) then
    OnBeforeDropItem(Self, AFromItem, AToItem, ACanDrop);
end;

procedure TTMSFNCCustomTableView.DoBeforeItemHideDetailControl(
  AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean);
begin
  if Assigned(OnBeforeItemHideDetailControl) then
    OnBeforeItemHideDetailControl(Self, AItem, ADetailControl, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforeItemShowDetailControl(
  AItem: TTMSFNCTableViewItem; ADetailControl: TControl; var AAllow: Boolean);
begin
  if Assigned(OnBeforeItemShowDetailControl) then
    OnBeforeItemShowDetailControl(Self, AItem, ADetailControl, AAllow);
end;

procedure TTMSFNCCustomTableView.DoBeforePasteFromClipboard(Sender: TObject;
  var ACanPaste: Boolean);
begin
  if Assigned(OnBeforePasteFromClipboard) then
    OnBeforePasteFromClipboard(Self, ACanPaste);
end;

procedure TTMSFNCCustomTableView.DoBeforeReorderItem(AFromItem, AToItem: TTMSFNCTableViewItem; var ACanReorder: Boolean);
begin
  if Assigned(OnBeforeReorderItem) then
    OnBeforeReorderItem(Self, AFromItem, AToItem, ACanReorder);
end;

procedure TTMSFNCCustomTableView.DoDoneButtonClicked(Sender: TObject);
begin
  StopEditMode;
  StopFiltering;
end;

procedure TTMSFNCCustomTableView.DoCategoryCompare(ACategory1,
  ACategory2: TTMSFNCTableViewCategory; var ACompareResult: Integer);
begin
  if Assigned(OnCategoryCompare) then
    OnCategoryCompare(Self, ACategory1, ACategory2, ACompareResult);
end;

procedure TTMSFNCCustomTableView.DoItemAccessoryClick(
  AItem: TTMSFNCTableViewItem);
begin
  if Assigned(OnItemAccessoryClick) then
    OnItemAccessoryClick(Self, AItem);
end;

procedure TTMSFNCCustomTableView.DoHeaderAnchorClick(Sender: TObject; AAnchor: String);
begin
  if Assigned(OnHeaderAnchorClick) then
    OnHeaderAnchorClick(Self, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomTableView.DoColumnSort(Sender: TObject; AColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode);
var
  i: Integer;
begin
  i := Integer(ASortMode);
  Items.Sort(Interaction.Sorting in [tvcsNormalCaseSensitive], TTMSFNCTableViewItemsSortMode(i));
end;

procedure TTMSFNCCustomTableView.DoCopyToClipboard(Sender: TObject);
begin
  CopyToClipboard(Interaction.ClipboardMode = tvcmTextOnly);
end;

procedure TTMSFNCCustomTableView.DoCustomDragDrop(Sender, Source: TObject;
  Point: TPointF);
var
  di, dragi, li, dic, newi: TTMSFNCTableViewItem;
  dragn: TTMSFNCTreeViewVirtualNode;
  b: Boolean;
  tv: TTMSFNCCustomTableViewOpen;
begin
  if FAccepted and Assigned(Source) and (Source is TTMSFNCCustomTreeView) and ((Source as TTMSFNCCustomTreeView).Parent is TTMSFNCCustomTableView)
    and (TTMSFNCCustomTreeViewOpen(Source).Interaction.DragDropMode  <> tdmNone) then
  begin
    dragn := (Source as TTMSFNCCustomTreeView).DragNode;
    if Assigned(dragn) then
    begin
      dragi := TTMSFNCCustomTableViewOpen((Source as TTMSFNCCustomTreeView).Parent).GetItemForNode(dragn);
      di := XYToItem(Point.X, Point.Y);
      if (di <> dragi) and Assigned(dragi) and PtInRectEx(GetItemsRect, Point) then
      begin
        b := True;
        if Assigned(di) then
          DoBeforeDropItem(dragi, di, b)
        else
        begin
          Inc(FUpdateCount);
          newi := Items.Add;
          Dec(FUpdateCount);
          DoBeforeDropItem(dragi, newi, b);
          newi.Free;
        end;

        if b then
        begin
          dic := nil;
          BeginUpdate;
          tv := TTMSFNCCustomTableViewOpen((Source as TTMSFNCCustomTreeView).Parent);
          tv.BeginUpdate;
          if Assigned(di) then
          begin
            case tv.Interaction.DragDropMode of
              tvdmMove:
              begin
                if (Source as TTMSFNCCustomTreeView).Parent = Self then
                begin
                  DragItemMove(dragi, di);
                  dragi.Index := di.Index;
                  dic := di;
                end
                else
                begin
                  li := Items.Add;
                  DragItemAdd(li, dragi, di);
                  tv.DragItemDelete(dragi);
                  li.Assign(dragi);
                  li.Index := di.Index;
                  tv.Items.Delete(dragi.Index);
                  tv.ItemIndex := tv.ItemIndex;
                  dic := li;
                  dragi := nil;
                end;
              end;
              tvdmCopy:
              begin
                li := Items.Add;
                DragItemAdd(li, dragi, di);
                li.Assign(dragi);
                li.Index := di.Index;
                dic := li;
              end;
            end;
          end
          else
          begin
            li := Items.Add;
            DragItemAdd(li, dragi, nil);
            li.Assign(dragi);
            case tv.Interaction.DragDropMode of
              tvdmMove:
              begin
                tv.DragItemDelete(dragi);
                tv.Items.Delete(dragi.Index);
                tv.ItemIndex := tv.ItemIndex;
                dragi := nil;
              end;
            end;
            dic := li;
          end;
          (Source as TTMSFNCCustomTreeView).DragNode := nil;
          if Assigned(dic) then
            FSaveSelectedItem := dic.Index;
          tv.EndUpdate;
          EndUpdate;
          DoAfterDropItem(dragi, dic);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoCustomDragOver(Sender, Source: TObject;
  Point: TPointF; var Accept: Boolean);
begin
  Accept := (Interaction.DragDropMode <> tvdmNone) and (Source is TTMSFNCCustomTreeView) and Assigned((Source as TTMSFNCCustomTreeView).DragNode);
  Accept := Accept and PtInRectEx(GetItemsRect, Point);
  FAccepted := Accept;
end;

procedure TTMSFNCCustomTableView.CustomizeButtons;
begin

end;

procedure TTMSFNCCustomTableView.DoCustomReorder(Sender: TObject; AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
var
  it, itt: TTMSFNCTableViewItem;
  b: Boolean;
begin
  if not Assigned(AFromNode) or not Assigned(AToNode) then
    Exit;

  b := True;
  it := GetItemForNode(AFromNode);
  itt := GetItemForNode(AToNode);
  DoBeforeReorderItem(it, itt, b);
  if b then
  begin
    BeginUpdate;
    it.Index := itt.Index;
    EndUpdate;
    DoAfterReorderItem(it, itt);
  end;
end;

procedure TTMSFNCCustomTableView.DoCutToClipboard(Sender: TObject);
begin
  CutToClipboard(Interaction.ClipboardMode = tvcmTextOnly);
end;

procedure TTMSFNCCustomTableView.DoDetailControlTimer(Sender: TObject);
var
  d: Double;
  p: Double;
  anim: Boolean;
  cr: TRectF;
begin
  if Assigned(FActiveDetailControl) and Assigned(FTreeView) then
  begin
    if not FShowDetailControl then
      p := 0
    else
    begin
      cr := FTreeView.GetContentRect;
      p := cr.Right - cr.Left;
    end;

    d := Abs(FDetailControlOffset - p) / ANIMATIONFACTOR;
    anim := AnimateDouble(FDetailControlOffset, p, d, 0.01);

    if not anim then
    begin
      FDetailControlOffset := p;
      FShowDetailControl := not FShowDetailControl;
      if not FShowDetailControl then
      begin
        DoItemHideDetailControl(FActiveDetailItem, FActiveDetailControl);
        FActiveDetailControl.Visible := False;
        FActiveDetailControl.Parent := nil;
        FActiveDetailControl := nil;
        FActiveDetailItem := nil;
      end
      else
        DoItemShowDetailControl(FActiveDetailItem, FActiveDetailControl);

      FDetailControlTimer.Enabled := False;
    end;

    UpdateTableView(True);
    UpdateActiveDetailControl;
  end
  else
    FDetailControlTimer.Enabled := False;
end;

procedure TTMSFNCCustomTableView.DoDrawItemCustomAccessory(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCTableViewItem);
begin
  if Assigned(OnDrawItemCustomAccessory) then
    OnDrawItemCustomAccessory(Self, AGraphics, ARect, AItem);
end;

procedure TTMSFNCCustomTableView.DoDrawNodeExtra(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode);
var
  dp: TTMSFNCTableViewDisplayItem;
  bmp: TTMSFNCBitmap;
  r: TRectF;
  h: Single;
  I: Integer;
  mo: TTMSFNCTableViewMoreOption;
  sz: Single;
  w: Single;
  a: Boolean;
  c, fc: TTMSFNCGraphicsColor;
begin
  if not Assigned(FTreeView) then
    Exit;

  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
        begin
          if (FTreeView.FMoreOffsetItem = dp.Item) and (MoreOptions.Count > 0) then
          begin
            AGraphics.Font.Assign(MoreOptionAppearance.Font);
            AGraphics.Fill.Assign(MoreOptionAppearance.Fill);
            AGraphics.Stroke.Assign(MoreOptionAppearance.Stroke);

            sz := FTreeView.FMoreOffset;
            w := sz / MoreOptions.Count;

            for I := 0 to MoreOptions.Count - 1 do
            begin
              mo := MoreOptions[I];
              if mo.Color <> gcNull then
                AGraphics.Fill.Color := mo.Color;
              if mo.BorderColor <> gcNull then
                AGraphics.Stroke.Color := mo.BorderColor;
              if mo.FontColor <> gcNull then
                AGraphics.Font.Color := mo.FontColor;
              r := RectF(ARect.Right - 1 + w * I, ARect.Top, ARect.Right - 1 + w * (I + 1), ARect.Bottom);
              a := True;
              DoBeforeDrawItemMoreOption(AGraphics, r, dp.Item, mo, a);
              if a then
              begin
                if FTreeview.FDownMoreOption = mo then
                begin
                  AGraphics.Fill.Color := Lighter(AGraphics.Fill.Color, 20);
                  AGraphics.Stroke.Color := Lighter(AGraphics.Stroke.Color, 20);
                end;

                AGraphics.DrawRectangle(r, gcrmNone);
                InflateRectEx(r, -2, -2);
                AGraphics.DrawText(r, mo.Text, False, gtaCenter);
                DoAfterDrawItemMoreOption(AGraphics, r, dp.Item, mo);
              end;
            end;
          end;

          if (dp.Item.AccessoryWidth > 0) and (dp.Item.AccessoryHeight > 0)  then
          begin
            r := ARect;
            if dp.Item = FTreeView.FMoreOffsetItem then
              r.Left := r.Left + FTreeView.FMoreOffset;

            InflateRectEx(r, -4, -4);
            h := dp.Item.AccessoryHeight;
            r := RectF(r.Left, r.Top + ((r.Bottom - r.Top) - h) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - h) / 2 + h);

            AGraphics.Font.Assign(ItemAppearance.AccessoryFont);

            if dp.Item.AccessoryFontColor <> gcNull then
              AGraphics.Font.Color := dp.Item.AccessoryFontColor;

            case dp.Item.Accessory of
              tviaDetail:
              begin
                bmp := TTMSFNCGraphics.GetScaledBitmap(ItemAppearance.AccessoryDetailBitmaps, 0, BitmapContainer);
                if Assigned(bmp) and not IsBitmapEmpty(bmp) then
                  AGraphics.DrawBitmap(r, bmp);
              end;
              tviaProgress:
              begin
                c := ItemAppearance.AccessoryFill.Color;
                if dp.Item.AccessoryColor <> gcNull then
                  c := dp.Item.AccessoryColor;
                fc := ItemAppearance.AccessoryFont.Color;
                if dp.Item.AccessoryFontColor <> gcNull then
                  fc := dp.Item.AccessoryFontColor;
                AGraphics.DrawProgressBar(r, dp.Item.AccessoryProgress, '%.0f%%', 100, c, fc);
              end;
              tviaBadge: DrawBadge(AGraphics, r, dp.Item);
              tviaButton:
              begin
                AGraphics.DrawButton(r, dp.Item = FActiveAccessoryItem);
                AGraphics.DrawText(r, dp.Item.AccessoryText, True, gtaCenter);
              end;
              tviaCustom: DoDrawItemCustomAccessory(AGraphics, r, dp.Item);
            end;
          end;
        end;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoEditButtonClicked(Sender: TObject);
begin
  StartEditMode;
end;

procedure TTMSFNCCustomTableView.DoEditChange(Sender: TObject);
var
  f: TTMSFNCTableViewFilterData;
  a: Boolean;
begin
  RemoveFilters;
  f := Filter.Add;
  f.CaseSensitive := False;
  f.Condition := '*'+FEdit.Text+'*';

  a := True;
  DoBeforeApplyFilter(f, a);
  if a then
  begin
    ApplyFilter;
    DoAfterApplyFilter(f);
  end;
end;

procedure TTMSFNCCustomTableView.DoEnter;
begin
  inherited;
  if Assigned(FTreeView) and FTreeView.AllowFocus then
    FTreeView.SetFocus;
end;

procedure TTMSFNCCustomTableView.DoFilterSelect(Sender: TObject; AColumn: integer;
  var ACondition: string);
begin
  if Assigned(OnFilterSelect) then
    OnFilterSelect(Self, ACondition);
end;

procedure TTMSFNCCustomTableView.DoFooterAnchorClick(Sender: TObject; AAnchor: String);
begin
  if Assigned(OnFooterAnchorClick) then
    OnFooterAnchorClick(Self, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomTableView.DoGetColumnHorizontalTextAlign(Sender: TObject;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  case AKind of
    ikColumnTop: AHorizontalTextAlign := Header.HorizontalTextAlign;
    ikColumnBottom: AHorizontalTextAlign := Footer.HorizontalTextAlign;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetColumnText(Sender: TObject;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: String);
begin
  case AKind of
    ikColumnTop: AText := Header.Text;
    ikColumnBottom: AText := Footer.Text;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetColumnTrimming(Sender: TObject;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  case AKind of
    ikColumnTop: ATrimming := Header.Trimming;
    ikColumnBottom: ATrimming := Footer.Trimming;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetColumnVerticalTextAlign(Sender: TObject;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  case AKind of
    ikColumnTop: AVerticalTextAlign := Header.VerticalTextAlign;
    ikColumnBottom: AVerticalTextAlign := Footer.VerticalTextAlign;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetColumnWordWrapping(Sender: TObject;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var AWordWrapping: Boolean);
begin
  case AKind of
    ikColumnTop: AWordWrapping := Header.WordWrapping;
    ikColumnBottom: AWordWrapping := Footer.WordWrapping;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetHTMLTemplate(AItem: TTMSFNCTableViewItem;
  var AHTMLTemplate: string);
begin
  if Assigned(OnGetHTMLTemplate) then
    OnGetHTMLTemplate(Self, AItem, AHTMLTemplate);
end;

procedure TTMSFNCCustomTableView.DoGetHTMLTemplateValue(
  AItem: TTMSFNCTableViewItem; AName: string; var AValue: string);
begin
  if Assigned(OnGetHTMLTemplateValue) then
    OnGetHTMLTemplateValue(Self, AItem, AName, AValue);
end;

procedure TTMSFNCCustomTableView.DoIsNodeExtended(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AExtended: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikCategory: AExtended := True;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeCheckType(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ACheckType: TTMSFNCTreeViewNodeCheckType);
var
  dp: TTMSFNCTableViewDisplayItem;
  i: Integer;
begin
  if FEditMode then
  begin
    if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
    begin
      dp := FDisplayList[ANode.Row];
      case dp.Kind of
        tvikItem:
        begin
          if Assigned(dp.Item) then
          begin
            i := Integer(dp.Item.CheckType);
            ACheckType := TTMSFNCTreeViewNodeCheckType(i);
          end;
        end;
        tvikCategory: ;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeDisabledTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ADisabledTextColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ADisabledTextColor := dp.Item.DisabledTextColor;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeDisabledTitleColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ADisabledTitleColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ADisabledTitleColor := dp.Item.DisabledTitleColor;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeHeight(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if ItemAppearance.Height > -1 then
          AHeight := ItemAppearance.Height;

        if Assigned(dp.Item) and (dp.Item.Height > -1) then
          AHeight := dp.Item.Height;
      end;
      tvikCategory:
      begin
        if CategoryAppearance.Height > -1 then
          AHeight := CategoryAppearance.Height;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeHorizontalTextAlign(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AHorizontalTextAlign := dp.Item.HorizontalTextAlign;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeVerticalTextAlign(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AVerticalTextAlign := dp.Item.VerticalTextAlign;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeIcon(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  var AIcon: TTMSFNCBitmap);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
        begin
          if Assigned(BitmapContainer) and (dp.Item.BitmapName <> '') then
            AIcon := TTMSFNCBitmap(BitmapContainer.FindBitmap(dp.Item.BitmapName));

          if not Assigned(AIcon) then
            AIcon := dp.Item.Bitmap;
        end;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeSelectedTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ASelectedTextColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ASelectedTextColor := dp.Item.SelectedTextColor;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeSelectedTitleColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ASelectedTitleColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ASelectedTitleColor := dp.Item.SelectedTitleColor;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeSides(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides);
var
  dp: TTMSFNCTableViewDisplayItem;
  n: TTMSFNCTreeViewVirtualNode;
begin
  if Assigned(FTreeView) then
  begin
    if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
    begin
      dp := FDisplayList[ANode.Row];
      if not FTreeView.IsVirtualNodeSelected(ANode) and not (dp.Kind = tvikCategory) then
      begin
        if FTreeView.GetLastVirtualNode = ANode then
          ASides := []
        else
        begin
          n := FTreeView.GetNextVirtualNode(ANode);
          if Assigned(n) and (n.Row >= 0) and (n.Row <= FDisplayList.Count - 1) then
          begin
            dp := FDisplayList[n.Row];
            if dp.Kind = tvikItem then
              ASides := [gsBottom]
            else
              ASides := [];
          end;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.HideDetailControl;
var
  a: Boolean;
begin
  if (Assigned(FTreeView) and FTreeView.FReloadActive) or not Assigned(FTreeView) then
    Exit;

  FTreeView.StopAnimationTimer;

  a := True;
  DoBeforeItemHideDetailControl(FActiveDetailItem, FActiveDetailControl, a);

  if Assigned(FActiveDetailControl) and Assigned(FTreeView) and a then
  begin
    FShowDetailControl := True;
    UpdateActiveDetailControl;
    FDetailControlTimer.Enabled := True;
  end;
end;

procedure TTMSFNCCustomTableView.HideMoreOptions;
begin
  if (Assigned(FTreeView) and (FTreeView.FReloadActive or FFilterActive or Assigned(FActiveDetailControl))) or not Assigned(FTreeView) then
    Exit;

  FTreeView.FAnimateMoreOptions := False;
  FTreeView.FAnimateMoreOptionsClose := True;
  FTreeView.FMoreOffsetTo := 0;
  FTreeView.FAnimTimer.Enabled := True;
  FTreeView.BlockMouseClick := False;
end;

function TTMSFNCCustomTableView.HTMLReplace(AValue: string; AItem: TTMSFNCTableViewItem): string;
var
  beforetag, aftertag, nm, vl: string;
  i, j: integer;
begin
  Result := '';
  if not Assigned(AItem) then
    Exit;

  beforetag := '';

  while Pos('<#', AValue) > 0 do
  begin
    i := pos('<#', AValue);
    beforetag := beforetag + copy(AValue, 1, i - 1); //part prior to the tag
    aftertag := copy(AValue, i, length(AValue)); //part after the tag
    j := pos('>', aftertag);
    nm := copy(aftertag, 1, j - 1);
    Delete(nm, 1, 2);
    Delete(AValue, 1, i + j - 1);
    vl := AItem.HTMLTemplateItems.Values[nm];
    DoGetHTMLTemplateValue(AItem, nm, vl);
    beforetag := beforetag + vl;
  end;

  Result := beforetag + AValue;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleExpanded(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExpanded: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AExpanded := dp.Item.TitleExpanded;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitle(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var ATitle: String);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ATitle := dp.Item.GetTitle;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ATitleColor := dp.Item.TitleColor;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleHorizontalTextAlign(
  Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AHorizontalTextAlign := dp.Item.TitleHorizontalTextAlign;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleTrimming(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ATrimming := dp.Item.TitleTrimming;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleVerticalTextAlign(
  Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AVerticalTextAlign := dp.Item.TitleVerticalTextAlign;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTitleWordWrapping(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AWordWrapping := dp.Item.TitleWordWrapping;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: String);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AText := dp.Item.GetText;
      end;
      tvikCategory: AText := dp.Text;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ATextColor := dp.Item.TextColor;
      end;
      tvikCategory: ATextColor := CategoryAppearance.Font.Color;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeTrimming(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          ATrimming := dp.Item.Trimming;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeWordWrapping(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AWordWrapping := dp.Item.WordWrapping;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNodeExtraSize(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AExtraSize: Single);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if not Assigned(FTreeView) then
    Exit;

  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) and (dp.Item.AccessoryWidth > 0) then
          AExtraSize := dp.Item.AccessoryWidth + 8;

        if dp.Item = FTreeView.FMoreOffsetItem then
          AExtraSize := AExtraSize + FTreeView.FMoreOffset;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoGetNumberOfNodes(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer);
begin
  if ANode.Level = -1 then
    ANumberOfNodes := FDisplayList.Count;
end;

procedure TTMSFNCCustomTableView.DoIsNodeChecked(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AIsChecked: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AIsChecked := dp.Item.Checked;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoIsNodeEnabled(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  if (ANode.Row >= 0) and (ANode.Row <= FDisplayList.Count - 1) then
  begin
    dp := FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          AEnabled := dp.Item.Enabled;
      end;
      tvikCategory: ;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.DoLookup(Sender: TObject; ALookupString: String);
begin
  LookupItem(ALookupString, Interaction.Lookup.CaseSensitive, Interaction.Lookup.AutoSelect)
end;

procedure TTMSFNCCustomTableView.DoItemMoreOptionClick(AItem: TTMSFNCTableViewItem; AMoreOption: TTMSFNCTableViewMoreOption);
begin
  if Assigned(OnItemMoreOptionClick) then
    OnItemMoreOptionClick(Self, AItem, AMoreOption);
end;

procedure TTMSFNCCustomTableView.DoItemShowDetailControl(
  AItem: TTMSFNCTableViewItem; ADetailControl: TControl);
begin
  if Assigned(OnItemShowDetailControl) then
    OnItemShowDetailControl(Self, AItem, ADetailControl);
end;

procedure TTMSFNCCustomTableView.DoNeedFilterDropDownData(Sender: TObject;
  AColumn: Integer; AValues: TStrings);
begin
  if Assigned(OnNeedFilterDropDownData) then
    OnNeedFilterDropDownData(Self, AValues);
end;

procedure TTMSFNCCustomTableView.DoNodeAnchorClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: String);
begin
  if Assigned(OnItemAnchorClick) then
    OnItemAnchorClick(Self, GetItemForNode(ANode), AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomTableView.DoNodeClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
var
  it: TTMSFNCTableViewItem;
begin
  it := GetItemForNode(ANode);

  if Assigned(it) and (Interaction.ShowDetailMode = tvdtAfterSelectItem) and not FEditMode then
  begin
    FActiveDetailItem := it;
    it.ShowDetailControl;
  end;

  if Assigned(it) and FEditMode then
  begin
    if it.Checked then
    begin
      it.Checked := False;
      FTreeView.DoAfterUnSelectNode(ANode);
    end
    else
    begin
      it.Checked := True;
      FTreeView.DoAfterSelectNode(ANode);
    end;

    SelectAllCheckedItems;
  end;

  if Assigned(OnItemClick) then
    OnItemClick(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomTableView.DoNodeMouseEnter(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemMouseEnter) then
    OnItemMouseEnter(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomTableView.DoNodeMouseLeave(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemMouseLeave) then
    OnItemMouseLeave(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomTableView.DoNodeTitleAnchorClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: String);
begin
  if Assigned(OnItemTitleAnchorClick) then
    OnItemTitleAnchorClick(Self, GetItemForNode(ANode), AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomTableView.DoItemCheckChanged(AItem: TTMSFNCTableViewItem);
begin
  if Assigned(OnItemCheckChanged) then
    OnItemCheckChanged(Self, AItem);
end;

procedure TTMSFNCCustomTableView.DoItemCompare(AItem1: TTMSFNCTableViewItem; AItem2: TTMSFNCTableViewItem; var ACompareResult: Integer);
begin
  if Assigned(OnItemCompare) then
    OnItemCompare(Self, AItem1, AItem2, ACompareResult);
end;

procedure TTMSFNCCustomTableView.DoItemHideDetailControl(
  AItem: TTMSFNCTableViewItem; ADetailControl: TControl);
begin
  if Assigned(OnItemHideDetailControl) then
    OnItemHideDetailControl(Self, AItem, ADetailControl);
end;

procedure TTMSFNCCustomTableView.DoNodeDblClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemDblClick) then
    OnItemDblClick(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomTableView.DoPasteFromClipboard(Sender: TObject);
begin
  PasteFromClipboard;
end;

procedure TTMSFNCCustomTableView.DoFilterButtonClicked(Sender: TObject);
begin
  StartFiltering;
end;

procedure TTMSFNCCustomTableView.DoStartReload;
begin
  if Assigned(OnStartReload) then
    OnStartReload(Self);
end;

procedure TTMSFNCCustomTableView.DoStopReload;
begin
  if Assigned(OnStopReload) then
    OnStopReload(Self);
end;

procedure TTMSFNCCustomTableView.DoVScroll(Sender: TObject; APosition: Single);
begin
  if Assigned(OnVScroll) then
    OnVScroll(Self, APosition);
end;

procedure TTMSFNCCustomTableView.DragItemAdd(ANewItem, AAssignItem, AInsertItem: TTMSFNCTableViewItem);
begin
end;

procedure TTMSFNCCustomTableView.DragItemDelete(AItem: TTMSFNCTableViewItem);
begin
end;

procedure TTMSFNCCustomTableView.DragItemMove(AFromItem,
  AToItem: TTMSFNCTableViewItem);
begin
end;

procedure TTMSFNCCustomTableView.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  if not FFirstLoad then
  begin
    FFirstLoad := True;
    UpdateControlAfterResize;
  end;
end;

procedure TTMSFNCCustomTableView.DrawBadge(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCTableViewItem);
var
  r: TRectF;
  sz: TSizeF;
  s: String;
  pth: TTMSFNCGraphicsPath;
  rnd: Single;
begin
  if (AItem.AccessoryText = '') then
    Exit;

  AGraphics.Font.Assign(ItemAppearance.AccessoryFont);
  AGraphics.Stroke.Assign(ItemAppearance.AccessoryStroke);
  AGraphics.Fill.Assign(ItemAppearance.AccessoryFill);

  if AItem.AccessoryFontColor <> gcNull then
    AGraphics.Font.Color := AItem.AccessoryFontColor;

  if AItem.AccessoryBorderColor <> gcNull then
    AGraphics.Stroke.Color := AItem.AccessoryBorderColor;

  if AItem.AccessoryColor <> gcNull then
    AGraphics.Fill.Color := AItem.AccessoryColor;

  s := AItem.AccessoryText;
  sz := AGraphics.CalculateTextSize(s);
  sz.cy := sz.cy + 4;
  sz.cx := Max(sz.cy, sz.cx + 6);

  r := ARect;

  r := RectF(r.Left + ((r.Right - r.Left) - sz.cx) / 2, r.Top + ((r.Bottom - r.Top) - sz.cy) / 2,
    r.Left + ((r.Right - r.Left) - sz.cx) / 2 + sz.cx, r.Top + ((r.Bottom - r.Top) - sz.cy) / 2 + sz.cy);

  rnd := (r.Bottom - r.Top) / 2;

  pth := TTMSFNCGraphicsPath.Create;
  try
    pth.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
    pth.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, 90);
    pth.ClosePath;
    AGraphics.DrawPath(pth);
  finally
    pth.Free;
  end;

  AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);
end;

procedure TTMSFNCCustomTableView.StartEditMode;
begin
  if (Assigned(FTreeView) and FTreeView.FReloadActive) or not Assigned(FTreeView) then
    Exit;

  FEditMode := True;
  UpdateTableView;
  SelectAllCheckedItems ;
end;

procedure TTMSFNCCustomTableView.StartFiltering;
begin
  if (Assigned(FTreeView) and (FTreeView.FReloadActive or FFilterActive)) or not Assigned(FTreeView) then
    Exit;

  FFilterActive := True;
  RemoveFilters;
  UpdateTableView;
  FEdit.Text := '';
  FEdit.SetFocus;
end;

procedure TTMSFNCCustomTableView.StartReload;
begin
  if Assigned(FTreeView) then
    FTreeView.StartReload;
end;

procedure TTMSFNCCustomTableView.EnableInteraction;
begin
  if Assigned(FTreeView) then
    FTreeView.BlockUserInput := False;
end;

procedure TTMSFNCCustomTableView.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    UpdateTableView;

  if Assigned(FTreeView) then
    FTreeView.EndUpdate;

  SelectItem(FSaveSelectedItem);
end;

function TTMSFNCCustomTableView.FindCategoryWithCharacter(
  ACategory: String): TTMSFNCTableViewDisplayItem;
var
  I: Integer;
  cat: TTMSFNCTableViewDisplayItem;
begin
  Result.Kind := tvikNone;
  Result.Item := nil;
  Result.CategoryID := -1;
  Result.Text := '';
  for I := 0 to FCategoryList.Count - 1 do
  begin
    cat := FCategoryList[I];
    if cat.Text = ACategory then
    begin
      Result := cat;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.FindCategoryWithCharacterID(
  ACategoryID: Integer): TTMSFNCTableViewDisplayItem;
var
  I: Integer;
  cat: TTMSFNCTableViewDisplayItem;
begin
  Result.Kind := tvikNone;
  Result.Item := nil;
  Result.CategoryID := -1;
  Result.Text := '';
  for I := 0 to FCategoryList.Count - 1 do
  begin
    cat := FCategoryList[I];
    if cat.CategoryID = ACategoryID then
    begin
      Result := cat;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.FindFirstItemWithCategory(
  ACategory: String): TTMSFNCTableViewItem;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Items.Count - 1 do
  begin
    if GetCharacterForItem(Items[I]) = ACategory then
    begin
      Result := Items[I];
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.FindFirstItemWithCategoryID(
  ACategoryID: Integer): TTMSFNCTableViewItem;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Items.Count - 1 do
  begin
    if Items[I].CategoryID = ACategoryID then
    begin
      Result := Items[I];
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.BitmapContainer;
end;

function TTMSFNCCustomTableView.GetCharacter(Idx: Integer): String;
begin
  Result := '';
  if (CategoryType = tvctAlphaNumericLast) or (CategoryType = tvctAlphaBetic) then
  begin
    if Idx < 27 then
      Result := chr(ord('A') + (Idx - 1))
    else
      Result := chr(ord('0') + (Idx - 27));
  end
  else
  begin
    if Idx < 11 then
      Result := chr(ord('0') + (Idx - 1))
    else
      Result := chr(ord('A') + (Idx - 11));
  end;
end;

function TTMSFNCCustomTableView.GetCharacterCount: Integer;
begin
  Result := 26;
  if (CategoryType = tvctAlphaNumericFirst) or (CategoryType = tvctAlphaNumericLast) then
    Result := 36;
end;

function TTMSFNCCustomTableView.GetCharacterForItem(
  AItem: TTMSFNCTableViewItem): String;
var
  cat: TTMSFNCTableViewCategory;
  s: string;
  idx: Integer;
begin
  Result := '';
  case CategoryType of
    tvctAlphaNumericFirst, tvctAlphaNumericLast, tvctAlphaBetic:
    begin
      s := AItem.StrippedHTMLText;
      if Length(s) > 0 then
      begin
        {$IFDEF ZEROSTRINGINDEX}
        Result := s[0]
        {$ELSE}
        Result := s[1]
        {$ENDIF}
      end
      else
        Result := '';
    end;
    tvctCustom:
    begin
      idx := Categories.ItemIndexByID(AItem.CategoryID);
      if (idx >= 0) and (idx <= Categories.Count - 1) then
      begin
        cat := Categories[idx];
        Result := cat.Text;
      end;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetChecked(AItemIndex: Integer): Boolean;
begin
  Result := False;
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    Result := Items[AItemIndex].Checked;
end;

function TTMSFNCCustomTableView.GetCheckedItem(
  AItem: TTMSFNCTableViewItem): Boolean;
begin
  Result := False;
  if Assigned(AItem) then
    Result := AItem.Checked;
end;

function TTMSFNCCustomTableView.GetDisplayCategoryIndex(AIndex: Integer): Integer;
var
  I: Integer;
begin
  Result := -1;
  for I := FDisplayList.Count - 1 downto 0 do
  begin
    if (FDisplayList[I].Kind = tvikCategory) and (I <= AIndex) then
    begin
      Result := I;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetDisplayItemIndex(AIndex: Integer): Integer;
var
  I: Integer;
begin
  Result := -1;
  for I := FDisplayList.Count - 1 downto 0 do
  begin
    if (FDisplayList[I].Kind = tvikItem) and (I <= AIndex) then
    begin
      Result := I;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).Fill;
end;

function TTMSFNCCustomTableView.GetItemForNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTableViewItem;
var
  i: Integer;
begin
  Result := nil;
  i := GetItemIndexForNode(ANode);
  if (i >= 0) and (i <= Items.Count - 1) then
    Result := Items[i];
end;

function TTMSFNCCustomTableView.GetItemIndex: Integer;
var
  it: TTMSFNCTableViewItem;
begin
  Result := -1;
  if Assigned(FTreeView) and (FTreeView.SelectedVirtualNodeCount > 0) and Assigned(FTreeView.SelectedVirtualNodes[0]) then
  begin
    it := GetItemForNode(FTreeView.SelectedVirtualNode);
    if Assigned(it) then
      Result := it.Index;
  end;
end;

function TTMSFNCCustomTableView.GetItemIndexForNode(
  ANode: TTMSFNCTreeViewVirtualNode): Integer;
var
  I: Integer;
begin
  Result := -1;
  if Assigned(ANode) then
  begin
    for I := 0 to FDisplayList.Count - 1 do
    begin
      if (FDisplayList[I].Row = ANode.Row) and Assigned(FDisplayList[I].Item) and Assigned(FDisplayList[I].Item.Collection) then
      begin
        Result := FDisplayList[I].Item.Index;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetItemsFromClipboard: TTMSFNCTableViewCopyItems;
var
  s: String;
  a: TStringList;
  AItem: TTMSFNCTableViewItem;
  I: Integer;
  CurrStr: String;
  templ: TTMSFNCCustomTableView;
begin
  if TTMSFNCClipBoard.HasFormat(TTMSFNCClipBoardFormat.cfText) then
  begin
    s := TTMSFNCClipBoard.GetText;
    if (Pos(CLP_FMT, s) > 0) then
    begin
      templ := TTMSFNCCustomTableView.Create(nil);
      a := TStringList.Create;
      try
        s := StringReplace(s, CLP_FMT, '', [rfReplaceAll]);
        a.Text := s;
        try
          for I := 0 to a.Count - 1 do
          begin
            CurrStr := a[i];
            AItem := templ.AddItem;
            AItem.LoadFromString(CurrStr);
          end;
        finally
        end;
      finally
        FCopyItems.Assign(templ.Items);
        a.Free;
        templ.Free;
      end;
    end;
  end;

  Result := FCopyItems;
end;

function TTMSFNCCustomTableView.GetItemsRect: TRectF;
begin
  Result := EmptyRect;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNodesRect;
end;

function TTMSFNCCustomTableView.GetMoreOptionsSize: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to MoreOptions.Count - 1 do
    Result := Result + MoreOptions[I].Width;
end;

function TTMSFNCCustomTableView.GetNextDisplayCategoryIndex(
  AIndex: Integer): Integer;
var
  I: Integer;
begin
  Result := -1;
  for I := AIndex + 1 to FDisplayList.Count - 1 do
  begin
    if FDisplayList[I].Kind = tvikCategory then
    begin
      Result := I;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetNodeForItemIndex(
  AItemIndex: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).GetNodeForRow(GetRowForItemIndex(AItemIndex));
end;

function TTMSFNCCustomTableView.GetRowForItemIndex(
  AItemIndex: Integer): Integer;
var
  I: Integer;
  dp: TTMSFNCTableViewDisplayItem;
begin
  Result := -1;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    dp := FDisplayList[I];
    if Assigned(dp.Item) and Assigned(dp.Item.Collection) and (dp.Item.Index = AItemIndex) and (dp.Kind = tvikItem) then
    begin
      Result := dp.Item.FRow;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTableView.GetSelectedItem: TTMSFNCTableViewItem;
begin
  Result := nil;
  if (ItemIndex >= 0) and (ItemIndex <= Items.Count - 1) then
    Result := Items[ItemIndex];
end;

function TTMSFNCCustomTableView.GetSelectedItems: TTMSFNCTableViewSelectedItems;
var
  I: Integer;
  n: TTMSFNCTableViewItem;
begin
  Result := nil;
  for I := 0 to SelectedItemCount - 1 do
  begin
    n := SelectedItems[I] as TTMSFNCTableViewItem;
    SetLength(Result, Length(Result) + 1);
    Result[Length(Result) - 1] := n;
  end;
end;

function TTMSFNCCustomTableView.GetSelItem(AIndex: Integer): TTMSFNCTableViewItem;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := GetItemForNode(FTreeView.SelectedVirtualNodes[AIndex]);
end;

function TTMSFNCCustomTableView.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).Stroke;
end;

{$IFDEF FNCLIB}
function TTMSFNCCustomTableView.GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
begin
  SetLength(Result, 1);
  Result[0] := FTreeView;
end;
{$ENDIF}

function TTMSFNCCustomTableView.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomTableView.GetVerticalScrollBarVisible: Boolean;
begin
  Result := False;
  if Assigned(FTreeView) then
    Result := FTreeView.VerticalScrollBarVisible;
end;

procedure TTMSFNCCustomTableView.InitSample;
begin
  BeginUpdate;
  Header.Text := 'Item List';
  Footer.Text := '';
  Items.Clear;
  AddItem('Mercedes');
  AddItem('Audi');
  AddItem('BMW');
  AddItem('Land Rover');
  AddItem('Bugatti');
  EndUpdate;
end;

function TTMSFNCCustomTableView.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Header') or (APropertyName = 'Footer');
end;

function TTMSFNCCustomTableView.IsItemSelectable(AItem: TTMSFNCTableViewItem): Boolean;
begin
  Result := True;
  if Assigned(AItem) then
  begin
    Result := AItem.Enabled;
    if not (TTMSFNCTreeViewOpen(FTreeView).VisibleNodes.IndexOf(GetNodeForItemIndex(AItem.Index)) > -1) then
      Result := False;
  end
end;

function TTMSFNCCustomTableView.ItemFromDifferentCategory(AItem1,
  AItem2: TTMSFNCTableViewItem): Boolean;
var
  s1, s2: string;
begin
  Result := False;
  if (AItem1 = nil) or (AItem2 = nil) then
  begin
    Result := true
  end
  else
  begin
    case CategoryType of
      tvctAlphaBetic, tvctAlphaNumericFirst, tvctAlphaNumericLast:
      begin
        s1 := AItem1.StrippedHTMLText;
        s2 := AItem2.StrippedHTMLText;
        if (Length(s1) > 0) and (Length(s2) > 0) then
        begin
          {$IFDEF ZEROSTRINGINDEX}
          Result := (s1[0] <> s2[0]);
          {$ELSE}
          Result := (s1[1] <> s2[1]);
          {$ENDIF}
        end
        else
        begin
          if (Length(s1) = 0) and (Length(s2) = 0) then
            Result := false
          else
            Result := true;
        end;
      end;
      tvctCustom:
      begin
        if (AItem1.CategoryID > -1) and (AItem2.CategoryID > -1) then
          Result := (AItem1.CategoryID <> AItem2.CategoryID)
        else
          Result := not ((AItem1.CategoryID = -1) and (AItem2.CategoryID = -1));
      end;
    end;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTableView.LoadFromFile(AFileName: string);
var
  Stream: TStream;
begin
  Stream := TFileStream.Create(AFileName, fmOpenRead);
  try
    LoadFromStream(Stream);
  finally
    Stream.Free;
  end;
end;

procedure TTMSFNCCustomTableView.LoadFromStream(AStream: TStream);
var
  List: TStringList;
  AItem: TTMSFNCTableViewItem;
  i: Integer;
  CurrStr: string;
begin
  List := TStringList.Create;
  BeginUpdate;
  try
    try
      Items.Clear;
      List.LoadFromStream(AStream);
      for i := 0 to List.Count - 1 do
      begin
        CurrStr := List[i];
        AItem := AddItem;
        if Assigned(AItem) then
          AItem.LoadFromString(CurrStr);
      end;
    finally
      EndUpdate;
      List.Free;
    end;
  except
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomTableView.LoadFromStrings(AStrings: TStrings);
var
  I: Integer;
begin
  BeginUpdate;
  Items.Clear;
  for I := 0 to AStrings.Count - 1 do
    Items.Add.Text := AStrings[I];
  EndUpdate;
end;

procedure TTMSFNCCustomTableView.LoadSettingsFromFile(AFileName: string);
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
begin
  c := DefaultItem.TextColor;
  inherited;
  if (c = DefaultItem.TextColor) and (c <> ItemAppearance.Font.Color) then
  begin
    DefaultItem.TextColor := ItemAppearance.Font.Color;
    for I := 0 to Items.Count - 1 do
      Items[I].TextColor := DefaultItem.TextColor;
  end;
end;

procedure TTMSFNCCustomTableView.LoadSettingsFromStream(AStream: TStreamEx);
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
begin
  c := DefaultItem.TextColor;
  inherited;
  if (c = DefaultItem.TextColor) and (c <> ItemAppearance.Font.Color) then
  begin
    DefaultItem.TextColor := ItemAppearance.Font.Color;
    for I := 0 to Items.Count - 1 do
      Items[I].TextColor := DefaultItem.TextColor;
  end;
end;

procedure TTMSFNCCustomTableView.LookupCategory(ACategory: String);
begin
  if LookupBar.AutoLookup then
    ScrollToCategoryCharacter(ACategory)
  else
  begin
    if Assigned(OnManualLookupCategory) then
      OnManualLookupCategory(Self, ACategory, -1);
  end;
end;

procedure TTMSFNCCustomTableView.LookupCustomCategory(ACategoryID: Integer);
begin
  if LookupBar.AutoLookup then
    ScrollToCategoryID(ACategoryID)
  else
  begin
    if Assigned(OnManualLookupCategory) then
      OnManualLookupCategory(Self, '', ACategoryID);
  end;
end;

function TTMSFNCCustomTableView.LookupItem(ALookupString: String; ACaseSensitive,
  AAutoSelect: Boolean): TTMSFNCTableViewItem;
var
  v: String;
  it: TTMSFNCTableViewItem;
  i: Integer;
  s: String;
begin
  Result := nil;
  v := ALookupString;
  if not ACaseSensitive then
    v := Uppercase(v);

  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    s := it.StrippedHTMLText;

    if not ACaseSensitive then
      s := UpperCase(s);

    if Pos(v, s) = 1 then
    begin
      Result := it;
      Break;
    end;
  end;

  if Assigned(Result) and IsItemSelectable(Result) and AAutoSelect then
  begin
    SelectItem(Result.Index);
    ScrollToItem(Result.Index);
  end;
end;

function TTMSFNCCustomTableView.MatchFilter(
  AItem: TTMSFNCTableViewItem): Boolean;
var
  i: Integer;
  s:string;
  temp: Boolean;
begin
  Result := True;
  for i := 1 to FFilter.Count do
  begin
    with FFilter.Items[i - 1] do
    begin
      s := AItem.StrippedHTMLText;
      s := Trim(s);

      if Prefix <> '' then
        if Pos(Prefix,s) = 1 then
          Delete(s,1,Length(FFilter.Items[i - 1].Prefix));

      if Suffix <> '' then
        if Pos(Suffix,s) = 1 + Length(s) - Length(Suffix) then
          Delete(s,1 + Length(s) - Length(Suffix),Length(s));

      temp := true;
      try
        if FFilter.Items[i - 1].Condition <> '' then
          temp := TTMSFNCUtils.MatchStrEx(Condition,s,CaseSensitive);
      except
        temp := false;
      end;

      case FFilter.Items[i - 1].Operation of
        tvfoSHORT:
        begin
          Result := temp;
          if not Result then
            Break;
        end;
        tvfoNONE: Result := temp;
        tvfoAND: Result := Result AND temp;
        tvfoOR:  Result := Result OR temp;
        tvfoXOR: Result := Result XOR temp;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTableView.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  i: Integer;
begin
  inherited;
  if not (csDestroying in ComponentState) and (Operation = opRemove) then
  begin
    for i := 0 to Items.Count - 1 do
    begin
      if AComponent = Items[i].FDetailControl then
        Items[i].FDetailControl := nil;
    end;

    if Assigned(DefaultItem) and (AComponent = DefaultItem.FDetailControl) then
      DefaultItem.FDetailControl := nil;
  end;
end;

procedure TTMSFNCCustomTableView.PasteFromClipboard;
var
  s: String;
  a: TStringList;
  AItem: TTMSFNCTableViewItem;
  I: Integer;
  CurrStr: String;
begin
  if TTMSFNCClipBoard.HasFormat(TTMSFNCClipBoardFormat.cfText) then
  begin
    s := TTMSFNCClipBoard.GetText;
    if (Pos(CLP_FMT, s) > 0) then
    begin
      a := TStringList.Create;
      try
        s := StringReplace(s, CLP_FMT, '', [rfReplaceAll]);
        a.Text := s;
        try
          for I := 0 to a.Count - 1 do
          begin
            CurrStr := a[i];
            AItem := AddItem;
            AItem.LoadFromString(CurrStr);
          end;
        finally
        end;
      finally
        a.Free;
      end;
    end;
  end;
end;

function TTMSFNCCustomTableView.ReloadProgress: Single;
begin
  Result := 0;
  if Assigned(FTreeView) and (Reload.ProgressMode = tvrpmManual) then
    Result := FTreeView.FManualProgressSweepAngle / 360 * 100;
end;

procedure TTMSFNCCustomTableView.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCustomTableView);
end;

procedure TTMSFNCCustomTableView.RemoveFilter;
begin
  FFilterApplied := False;
  UpdateTableView;
end;

procedure TTMSFNCCustomTableView.RemoveFilters;
begin
  FFilterApplied := False;
  Filter.Clear;
  UpdateTableView;
end;

procedure TTMSFNCCustomTableView.RemoveItem(AItem: TTMSFNCTableViewItem);
begin
  if Assigned(AItem) then
    Items.Delete(AItem.Index);
end;

procedure TTMSFNCCustomTableView.ResetToDefaultStyle;
var
  I: Integer;
begin
  inherited;
  Stroke.Color := MakeGraphicsColor(178, 178, 178);
  Header.Fill.Color := MakeGraphicsColor(249, 249, 249);
  Header.Fill.Kind := gfkSolid;
  Header.Stroke.Color := MakeGraphicsColor(178, 178, 178);
  Footer.Fill.Color := MakeGraphicsColor(249, 249, 249);
  Footer.Fill.Kind := gfkSolid;
  Footer.Stroke.Color := MakeGraphicsColor(178, 178, 178);
  ItemAppearance.SelectedFill.Color := MakeGraphicsColor(217, 217, 217);
  ItemAppearance.SelectedStroke.Color := MakeGraphicsColor(217, 217, 217);

  TTMSFNCUtils.SetFontSize(Header.Font, 16);
  TTMSFNCUtils.SetFontSize(Footer.Font, 16);

  for I := 0 to Items.Count - 1 do
  begin
    Items[I].TextColor := gcBlack;
    Items[I].SelectedTextColor := gcBlack;
    Items[I].TitleColor := gcBlack;
    Items[I].SelectedTitleColor := gcBlack;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTableView.SaveToFile(AFileName: string; ATextOnly: Boolean = True);
var
  Stream: TStream;
begin
  Stream := TFileStream.Create(AFileName, fmCreate);
  try
    SaveToStream(Stream, ATextOnly);
  finally
    Stream.Free;
  end;
end;

procedure TTMSFNCCustomTableView.SaveToStream(AStream: TStream; ATextOnly: Boolean = True);
var
  AItem: TTMSFNCTableViewItem;
  ItemStr: string;
  Buffer: TBytes;
  K: Integer;
  {$IFDEF LCLLIB}
  I: Integer;
  {$ENDIF}
begin
  for K := 0 to Items.Count - 1 do
  begin
    AItem := Items[K];
    ItemStr := AItem.SaveToString(ATextOnly);
    ItemStr := ItemStr + EndOfLine;
    {$IFNDEF LCLLIB}
    Buffer := TEncoding.Default.GetBytes(ItemStr);
    {$ENDIF}
    {$IFDEF LCLLIB}
    SetLength(Buffer, Length(ItemStr));
    for I := 1 to Length(ItemStr) do
      Buffer[I - 1] := Ord(ItemStr[I]);
    {$ENDIF}

    {$IFDEF CMNLIB}
    AStream.Write(Buffer[0], Length(Buffer));
    {$ELSE}
    AStream.Write(Buffer, Length(Buffer));
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTableView.SaveToStrings(AStrings: TStrings);
var
  I: Integer;
begin
  for I := 0 to Items.Count - 1 do
    AStrings.Add(Items[I].Text);
end;
{$ENDIF}

procedure TTMSFNCCustomTableView.ScrollToCategoryCharacter(ACharacter: String);
var
  cat: TTMSFNCTableViewDisplayItem;
  t: TTMSFNCTreeViewOpen;
begin
  if Assigned(FTreeView) then
  begin
    cat := FindCategoryWithCharacter(ACharacter);
    if (cat.Row >= 0) and (cat.Row <= FDisplayList.Count - 1) then
    begin
      t := TTMSFNCTreeViewOpen(FTreeView);
      t.ScrollToVirtualNode(t.GetNodeForRow(cat.Row), True, tvnspTop, True);
    end;
  end;
end;

procedure TTMSFNCCustomTableView.ScrollToCategoryId(ACategoryID: Integer);
var
  cat: TTMSFNCTableViewDisplayItem;
  t: TTMSFNCTreeViewOpen;
begin
  if Assigned(FTreeView) then
  begin
    cat := FindCategoryWithCharacterID(ACategoryID);
    if (cat.Row >= 0) and (cat.Row <= FDisplayList.Count - 1) then
    begin
      t := TTMSFNCTreeViewOpen(FTreeView);
      t.ScrollToVirtualNode(t.GetNodeForRow(cat.Row), True, tvnspTop, True);
    end;
  end;
end;

procedure TTMSFNCCustomTableView.ScrollToItem(AItemIndex: Integer);
var
  t: TTMSFNCTreeViewOpen;
begin
  if Assigned(FTreeView) then
  begin
    if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    begin
      t := TTMSFNCTreeViewOpen(FTreeView);
      t.ScrollToVirtualNode(t.GetNodeForRow(GetRowForItemIndex(AItemIndex)), True, tvnspTop, True);
    end;
  end;
end;

procedure TTMSFNCCustomTableView.SelectAllCheckedItems;
var
  I: Integer;
  arr: TTMSFNCTableViewIntegerArray;
begin
  SetLength(arr, 0);
  for I := 0 to Items.Count - 1 do
  begin
    if Items[I].Checked then
    begin
      SetLength(arr, Length(arr) + 1);
      arr[Length(arr) - 1] := I;
    end;
  end;

  SelectItems(arr);
end;

function TTMSFNCCustomTableView.SelectedItemCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := FTreeView.SelectedVirtualNodeCount;
end;

procedure TTMSFNCCustomTableView.SelectItem(AItemIndex: Integer);
var
  t: TTMSFNCTreeViewOpen;
  i: Integer;
begin
  if Assigned(FTreeView) and (AItemIndex <> -1) then
  begin
    i := Max(0, Min(AItemIndex, Items.Count - 1));
    if (i >= 0) and (i <= Items.Count - 1) then
    begin
      t := TTMSFNCTreeViewOpen(FTreeView);
      t.SelectVirtualNode(t.GetNodeForRow(GetRowForItemIndex(i)));
    end;
  end;
end;

procedure TTMSFNCCustomTableView.SelectItems(
  AItemIndexes: TTMSFNCTableViewIntegerArray);
var
  t: TTMSFNCTreeViewOpen;
  I: Integer;
  na: TTMSFNCTreeViewVirtualNodeArray;
begin
  if Assigned(FTreeView) then
  begin
    t := TTMSFNCTreeViewOpen(FTreeView);
    SetLength(na, Length(AItemIndexes));
    for I := 0 to Length(AItemIndexes) - 1 do
      na[I] := t.GetNodeForRow(GetRowForItemIndex(AItemIndexes[I]));

    t.SelectVirtualNodes(na);
  end;
end;

procedure TTMSFNCCustomTableView.SetItemAppearance(
  const Value: TTMSFNCTableViewItemAppearance);
begin
  FItemAppearance.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetLookupBar(const Value: TTMSFNCTableViewLookupBar);
begin
  FLookupBar.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetMoreOptionAppearance(
  const Value: TTMSFNCTableViewMoreOptionAppearance);
begin
  FMoreOptionAppearance.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetMoreOptions(
  const Value: TTMSFNCTableViewMoreOptions);
begin
  FMoreOptions.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetReload(
  const Value: TTMSFNCTableViewReload);
begin
  FReload.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetSelectedItem(const Value: TTMSFNCTableViewItem);
begin
  if Assigned(Value) then
    ItemIndex := Value.Index;
end;

procedure TTMSFNCCustomTableView.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTreeView) then
    TTMSFNCTreeViewOpen(FTreeView).Stroke.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetVerticalScrollBarVisible(
  const Value: Boolean);
begin
  if Assigned(FTreeView) then
    FTreeView.VerticalScrollBarVisible := Value;
end;

procedure TTMSFNCCustomTableView.ShowDetailControl(AItemIndex: Integer);
var
  a: Boolean;
  cr: TRectF;
begin
  if (Assigned(FTreeView) and FTreeView.FReloadActive) or not Assigned(FTreeView) then
    Exit;

  FTreeView.StopAnimationTimer;

  HideDetailControl;

  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    FActiveDetailControl := Items[AItemIndex].DetailControl;

  if not Assigned(FActiveDetailControl) then
    FActiveDetailControl := DefaultItem.DetailControl;

  a := True;
  DoBeforeItemShowDetailControl(FActiveDetailItem, FActiveDetailControl, a);

  if Assigned(FActiveDetailControl) and Assigned(FTreeView) and a then
  begin
    FShowDetailControl := False;
    FActiveDetailControl.Parent := Self;
    cr := FTreeView.GetContentRect;
    FDetailControlOffset := cr.Right - cr.Left;
    UpdateActiveDetailControl;
    FActiveDetailControl.Visible := True;
    FDetailControlTimer.Enabled := True;
  end
  else
  begin
    FActiveDetailControl := nil;
    FActiveDetailItem := nil;
  end;
end;

procedure TTMSFNCCustomTableView.ShowMoreOptions(AItem: TTMSFNCTableViewItem);
begin
  if (Assigned(FTreeView) and (FTreeView.FReloadActive or FFilterActive or Assigned(FActiveDetailControl))) or not Assigned(FTreeView) then
    Exit;

  FTreeView.FMoreOffsetItem := AItem;
  FTreeView.FMoreOffsetTo := GetMoreOptionsSize;
  FTreeView.BlockMouseClick := True;
  FTreeView.FAnimateMoreOptions := True;
  FTreeView.FAnimTimer.Enabled := True;
end;

procedure TTMSFNCCustomTableView.Sort(ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCTableViewItemsSortMode);
begin
  Items.Sort(ACaseSensitive, ASortingMode);
end;

procedure TTMSFNCCustomTableView.StopReload;
begin
  if Assigned(FTreeView) then
  begin
    FTreeView.FManualProgressSweepAngle := 0;
    FTreeView.FManualProgressStartAngle := 0;
    FTreeView.FAnimTimer.Enabled := False;
    FTreeView.BlockUserInput := False;
    FTreeView.FReloadActive := False;
    FTreeView.SetVerticalOffset(0);
    FTreeView.StartVerticalOffsetAnimation;
    DoStopReload;
  end;
end;

procedure TTMSFNCCustomTableView.ToggleEditMode;
begin
  if FEditMode then
    StopEditMode
  else
    StartEditMode;
end;

procedure TTMSFNCCustomTableView.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FTreeView) then
    FTreeView.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomTableView.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FTreeView.BitmapContainer := Value;
end;

procedure TTMSFNCCustomTableView.SetCategories(
  const Value: TTMSFNCTableViewCategories);
begin
  FCategories.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetCategoryAppearance(
  const Value: TTMSFNCTableViewCategoryAppearance);
begin
  FCategoryAppearance.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetCategoryType(
  const Value: TTMSFNCTableViewCategoryType);
begin
  if FCategoryType <> Value then
  begin
    FCategoryType := Value;
    UpdateTableView;
  end;
end;

procedure TTMSFNCCustomTableView.SetChecked(AItemIndex: Integer;
  const Value: Boolean);
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    Items[AItemIndex].Checked := Value;
end;

procedure TTMSFNCCustomTableView.SetCheckedItem(
  AItem: TTMSFNCTableViewItem; const Value: Boolean);
begin
  if Assigned(AItem) then
    AItem.Checked := Value;
end;

procedure TTMSFNCCustomTableView.SetDefaultItem(const Value: TTMSFNCTableViewItem);
begin
  FDefaultItem.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTreeView) then
    TTMSFNCTreeViewOpen(FTreeView).Fill.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetFooter(const Value: TTMSFNCTableViewFooter);
begin
  FFooter.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetHeader(const Value: TTMSFNCTableViewHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetInteraction(
  const Value: TTMSFNCTableViewInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomTableView.SetItemIndex(const Value: Integer);
begin
  SelectItem(Value);
end;

procedure TTMSFNCCustomTableView.SetItems(const Value: TTMSFNCTableViewItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomTableView.UnCheckAllItems;
var
  I: Integer;
begin
  BeginUpdate;
  for I := 0 to Items.Count - 1 do
    Items[I].Checked := False;
  EndUpdate;
end;

procedure TTMSFNCCustomTableView.UnSelectAllItems;
begin
  if Assigned(FTreeView) then
    FTreeView.UnSelectAllNodes;
end;

procedure TTMSFNCCustomTableView.UpdateActiveDetailControl;
var
  r: TRectF;
begin
  if Assigned(FActiveDetailControl) and Assigned(FTreeView) then
  begin
    r := FTreeView.GetContentRect;
    InflateRectEx(r, -1, -1);
    r := RectF(r.Left + FDetailControlOffset, r.Top, r.Right + FDetailControlOffset, r.Bottom);
    FActiveDetailControl.SetBounds(Round(r.Left), Round(R.Top), Round((r.Right - r.Left)), Round((r.Bottom - r.Top)));
  end;
end;

procedure TTMSFNCCustomTableView.UpdateControlAfterResize;
begin
  inherited;
  if Assigned(FTreeView) then
    FTreeView.SetBounds(0, 0, Width, Height);

  UpdateTableView(False);
  UpdateActiveDetailControl;
end;

procedure TTMSFNCCustomTableView.UpdateReloadProgress(AProgress: Single; AAutoStopReload: Boolean = True);
begin
  if Assigned(FTreeView) then
  begin
    FTreeView.FManualProgressStartAngle := 0;
    FTreeView.FManualProgressSweepAngle := AProgress / 100 * 360;

    if AAutoStopReload and (AProgress >= 100) then
      StopReload
    else
      FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCCustomTableView.UpdateTableView(ADisplayOnly: Boolean = False);
var
  sel: TTMSFNCTableViewItem;
  cr, hr: TRectF;
  szd: Single;
  szr: Single;
  szt: Single;
  b: Boolean;
begin
  if (FUpdateCount > 0) or IsDestroying then
    Exit;

  if Assigned(FTreeView) then
  begin
    if not ADisplayOnly then
      CalculateItems;

    sel := SelectedItem;

    if not FDetailControlTimer.Enabled then
    begin
      if FShowDetailControl then
        FDetailControlOffset := 0
      else
      begin
        cr := FTreeView.GetContentRect;
        FDetailControlOffset := cr.Right - cr.Left;
      end;
    end;

    if ADisplayOnly then
      FTreeView.UpdateTreeViewDisplay
    else
    begin
      FTreeView.BeginUpdate;
      FTreeView.ClearNodeList;
      FTreeView.EndUpdate;
    end;

    CalculateLookupBar;
    SelectedItem := sel;

    hr := FTreeView.GetColumnsTopRect;    

    b := False;
    if Assigned(FColumn) then
      b := FColumn.Expanded;

    if not IsDesignTime and FFilterActive and b then
      FEdit.Parent := Self
    else
      FEdit.Parent := nil;

    FDoneButton.Visible := FEditMode or FFilterActive and not Assigned(FActiveDetailControl) and b;
    if not IsDesignTime and ((Header.Height > 0) and Header.Visible) then
      FDoneButton.Parent := Self
    else
      FDoneButton.Parent := nil;

    FBackButton.Visible := Assigned(FActiveDetailControl) and ((FShowDetailControl and not FDetailControlTimer.Enabled) or not FShowDetailControl) and b;
    if not IsDesignTime and ((Header.Height > 0) and Header.Visible) then
      FBackButton.Parent := Self
    else
      FBackButton.Parent := nil;

    FEditButton.Visible := not FEditMode and not FFilterActive and Interaction.ShowEditButton and not Assigned(FActiveDetailControl) and b;
    if not IsDesignTime and ((Header.Height > 0) and Header.Visible) then
      FEditButton.Parent := Self
    else
      FEditButton.Parent := nil;

    FFilterButton.Visible := Interaction.ShowFilterButton and not FFilterActive and not Assigned(FActiveDetailControl) and b;
    if not IsDesignTime and ((Header.Height > 0) and Header.Visible) then
      FFilterButton.Parent := Self
    else
      FFilterButton.Parent := nil;

    FBackButton.Height := Round((hr.Bottom - hr.Top) - 4);
    FDoneButton.Height := Round((hr.Bottom - hr.Top) - 4);
    FEditButton.Height := Round((hr.Bottom - hr.Top) - 4);
    FFilterButton.Height := Round((hr.Bottom - hr.Top) - 4);

    CustomizeButtons;

    szt := 0;
    if Assigned(FColumn) then
    begin
      if FColumn.Filtering.Enabled then
      begin
        szd := FColumn.Filtering.ButtonSize;
        szt := szt + szd + 4;
      end;

      if FColumn.Expandable then
      begin
        szr := FColumn.ExpandingButtonSize;
        szt := szt + szr + 6;
      end;

      if (TTMSFNCTreeViewColumnOpen(FColumn).SortKind <> nskNone) then
      begin
        szr := 8;
        szt := szt + szr + 6;
      end;
    end;

    FBackButton.SetBounds(Round(hr.Left + 3), Round(hr.Top + ((hr.Bottom - hr.Top) - FBackButton.Height) / 2), FBackButton.Width, FBackButton.Height);
    if FFilterButton.Visible then
    begin
      FFilterButton.SetBounds(Round(hr.Right - 3 - FFilterButton.Width - szt), Round(hr.Top + ((hr.Bottom - hr.Top) - FFilterButton.Height) / 2), FFilterButton.Width, FFilterButton.Height);
      FDoneButton.SetBounds(Round(hr.Right - 6 - FDoneButton.Width - FFilterButton.Width - szt), Round(hr.Top + Round(((hr.Bottom - hr.Top) - FDoneButton.Height) / 2)), FDoneButton.Width, FDoneButton.Height);
      FEditButton.SetBounds(Round(hr.Right - 6 - FEditButton.Width - FFilterButton.Width - szt), Round(hr.Top + Round(((hr.Bottom - hr.Top) - FEditButton.Height) / 2)), FEditButton.Width, FEditButton.Height);
    end
    else
    begin
      FDoneButton.SetBounds(Round(hr.Right - 3 - FDoneButton.Width - szt), Round(hr.Top + ((hr.Bottom - hr.Top) - FDoneButton.Height) / 2), FDoneButton.Width, FDoneButton.Height);
      FEditButton.SetBounds(Round(hr.Right - 3 - FEditButton.Width - szt), Round(hr.Top + ((hr.Bottom - hr.Top) - FEditButton.Height) / 2), FEditButton.Width, FEditButton.Height);
    end;

    if LookupBar.Visible and (CategoryType <> tvctNone) then
    begin
      case LookupBar.Position of
        tvlbpLeft: FEdit.SetBounds(Round(hr.Left + 1 + LookupBar.Width), Round(hr.Bottom), Round((hr.Right - hr.Left) - LookupBar.Width - 2), FEdit.Height);
        tvlbpRight: FEdit.SetBounds(Round(hr.Left + 1), Round(hr.Bottom + 1), Round((hr.Right - hr.Left) - LookupBar.Width - 2), FEdit.Height);
      end;
    end
    else
      FEdit.SetBounds(Round(hr.Left + 1), Round(hr.Bottom), Round((hr.Right - hr.Left) - 2), FEdit.Height);

    FTreeView.Invalidate;
  end;
end;

function TTMSFNCCustomTableView.XYToAccessoryItem(X,
  Y: Single): TTMSFNCTableViewItem;
var
  n: TTMSFNCTreeViewVirtualNode;
  r: TRectF;
  it: TTMSFNCTableViewItem;
  h: Single;
begin
  Result := nil;
  if Assigned(FTreeView) then
  begin
    n := FTreeView.XYToNode(X, Y);
    if Assigned(n) and (n.Row >= 0) and (n.Row <= Items.Count - 1)  then
    begin
      it := GetItemForNode(n);
      if Assigned(it) and (it.Accessory in Interaction.AccessoryClickDetection) then
      begin
        r := n.ExtraRects[0];
        InflateRectEx(r, 0, -4);
        h := it.AccessoryHeight;
        r := RectF(r.Left, r.Top + ((r.Bottom - r.Top) - h) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - h) / 2 + h);
        if PtInRectEx(r, PointF(X, Y)) then
          Result := it;
      end;
    end;
  end;
end;

function TTMSFNCCustomTableView.XYToItem(X, Y: Single): TTMSFNCTableViewItem;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
  begin
    n := FTreeView.XYToNode(X, Y);
    if Assigned(n) and (n.Row >= 0) and (n.Row <= FDisplayList.Count - 1) then
      Result := GetItemForNode(n);
  end;
end;

function TTMSFNCCustomTableView.XYToItemIndex(X, Y: Single): Integer;
var
  it: TTMSFNCTableViewItem;
begin
  Result := - 1;
  it := XYToItem(X, Y);
  if Assigned(it) then
    Result := it.Index;
end;

function TTMSFNCCustomTableView.XYToMoreOption(X,
  Y: Single): TTMSFNCTableViewMoreOption;
var
  sz: Single;
  w: Single;
  r, rt: TRectF;
  n: TTMSFNCTreeViewVirtualNode;
  I: Integer;
  mo: TTMSFNCTableViewMoreOption;
begin
  Result := nil;

  if not Assigned(FTreeView) then
    Exit;

  n := FTreeView.XYToNode(X, Y, FTreeView.FMoreOffset);

  if not Assigned(n) or (GetItemForNode(n) <> FTreeView.FMoreOffsetItem) then
    Exit;

  if MoreOptions.Count > 0 then
  begin
    rt := n.ExtraRects[0];
    sz := FTreeView.FMoreOffset;
    w := sz / MoreOptions.Count;

    for I := 0 to MoreOptions.Count - 1 do
    begin
      mo := MoreOptions[I];
      r := RectF(rt.Right + w * I, rt.Top, rt.Right + w * (I + 1), rt.Bottom);
      if PtInRectEx(r, PointF(X, Y)) then
      begin
        Result := mo;
        Break;
      end;
    end;
  end;
end;

{ TTMSFNCTableViewItems }

function TTMSFNCTableViewItems.Add: TTMSFNCTableViewItem;
begin
  Result := TTMSFNCTableViewItem(inherited Add);
end;

procedure TTMSFNCTableViewItems.Clear;
var
  l: TTMSFNCCustomTableView;
begin
  l := TableView;
  if Assigned(l) then
    l.BeginUpdate;
  inherited Clear;
  if Assigned(l) then
    l.EndUpdate;
end;

function TTMSFNCTableViewItems.Compare(AItem1, AItem2: TTMSFNCTableViewItem;
  ACaseSensitive: Boolean; ASortingMode: TTMSFNCTableViewItemsSortMode): Integer;
var
  cIdx1, cIdx2: integer;
  itemcap1, itemcap2: String;
  l: TTMSFNCCustomTableView;
begin
  Result := 0;

  l := FTableView;
  if Assigned(l) then
  begin
    case l.CategoryType of
      tvctNone, tvctAlphaBetic, tvctAlphaNumericFirst, tvctAlphaNumericLast:
      begin
        if not ACaseSensitive then
          Result := AnsiCompareStr(UpperCase(AItem1.StrippedHTMLText), UpperCase(AItem2.StrippedHTMLText))
        else
          Result := AnsiCompareStr(AItem1.StrippedHTMLText, AItem2.StrippedHTMLText)
      end;
      tvctCustom:
      begin
        cIdx1 := l.Categories.ItemIndexById(AItem1.CategoryID);
        cIdx2 := l.Categories.ItemIndexById(AItem2.CategoryID);

        if cIdx1 < cIdx2 then
          result :=  -1
        else if cIdx1 > cIdx2 then
          result := 1
        else
        begin
          itemcap1 := AItem1.StrippedHTMLText;
          itemcap2 := AItem2.StrippedHTMLText;
          if not ACaseSensitive then
          begin
            itemcap1 := UpperCase(itemcap1);
            itemcap2 := UpperCase(itemcap2);
          end;

          if itemcap1 < itemcap2 then
            result :=  -1
          else if itemcap1 > itemcap2 then
            result := 1
          else
            result := 0
        end;
      end;
    end;

    case ASortingMode of
      tvismDescending: Result := Result * -1;
    end;

    l.DoItemCompare(AItem1, AItem1, Result);
  end;
end;

constructor TTMSFNCTableViewItems.Create(ATableView: TTMSFNCCustomTableView);
begin
  inherited Create(ATableView, GetItemClass);
  FTableView := ATableView;
end;

function TTMSFNCTableViewItems.GetItem(Index: Integer): TTMSFNCTableViewItem;
begin
  Result := TTMSFNCTableViewItem(inherited Items[Index]);
end;

function TTMSFNCTableViewItems.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCTableViewItem;
end;

function TTMSFNCTableViewItems.Insert(Index: Integer): TTMSFNCTableViewItem;
begin
  Result := TTMSFNCTableViewItem(inherited Insert(Index));
end;

function TTMSFNCTableViewItems.TableView: TTMSFNCCustomTableView;
begin
  Result := FTableView;
end;

type
  {$HINTS OFF}
  TShadowedCollection = class(TPersistent)
  private
    {%H-}FItemClass: TCollectionItemClass;
    {$IFDEF LCLWEBLIB}
    FItems: TFPList;
    {$ENDIF}
    {$IFNDEF LCLWEBLIB}
    FItems: TList<TCollectionItem>;
    {$ENDIF}
  end;
  {$HINTS ON}

procedure TTMSFNCTableViewItems.QuickSort(L, R: Integer; ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCTableViewItemsSortMode);
var
  I, J, p: Integer;
  {$IFDEF LCLWEBLIB}
  SortList: TFPList;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    {$IFDEF WEBLIB}
    Save: JSValue;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    Save: Pointer;
    {$ENDIF}
  begin
    Save := SortList.Items[Index1];
    SortList.Items[Index1] := SortList.Items[Index2];
    SortList.Items[Index2] := Save;
  end;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  SortList: TList<TCollectionItem>;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    Save: TCollectionItem;
  begin
    Save := SortList[Index1];
    SortList[Index1] := SortList[Index2];
    SortList[Index2] := Save;
    Save.Index := Index2;
  end;
  {$ENDIF}
begin
  //This cast allows us to get at the private elements in the base class
  {$IFNDEF WEBLIB}
  SortList := {%H-}TShadowedCollection(Self).FItems;
  {$ENDIF}
  {$IFDEF WEBLIB}
  SortList := Self.FPList;
  {$ENDIF}

  repeat
    I := L;
    J := R;
    P := (L + R) shr 1;
    repeat
      while Compare(Items[I], Items[P], ACaseSensitive, ASortingMode) < 0 do
        Inc(I);
      while Compare(Items[J], Items[P], ACaseSensitive, ASortingMode) > 0 do
        Dec(J);
      if I <= J then
      begin
        ExchangeItems(I, J);
        if P = I then
          P := J
        else if P = J then
          P := I;
        Inc(I);
        Dec(J);
      end;
    until I > J;
    if L < J then
      QuickSort(L, J, ACaseSensitive, ASortingMode);
    L := I;
  until I >= R;
end;

procedure TTMSFNCTableViewItems.SetItem(Index: Integer;
  const Value: TTMSFNCTableViewItem);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCTableViewItems.Sort(ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCTableViewItemsSortMode);
var
  l: TTMSFNCCustomTableView;
begin
  l := TableView;
  if Assigned(l) then
  begin
    l.BeginUpdate;
    BeginUpdate;
    if Count > 1 then
      QuickSort(0, Pred(Count), ACaseSensitive, ASortingMode);
    EndUpdate;
    l.EndUpdate;
  end;
end;

{ TTMSFNCTableViewItem }

procedure TTMSFNCTableViewItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewItem then
  begin
    FTag := (Source as TTMSFNCTableViewItem).Tag;
    FHTMLTemplateItems.Assign((Source as TTMSFNCTableViewItem).HTMLTemplateItems);
    FText := (Source as TTMSFNCTableViewItem).Text;
    FTrimming := (Source as TTMSFNCTableViewItem).Trimming;
    FWordWrapping := (Source as TTMSFNCTableViewItem).WordWrapping;
    FHorizontalTextAlign := (Source as TTMSFNCTableViewItem).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCTableViewItem).VerticalTextAlign;
    FTextColor := (Source as TTMSFNCTableViewItem).TextColor;
    FSelectedTextColor := (Source as TTMSFNCTableViewItem).SelectedTextColor;
    FDisabledTextColor := (Source as TTMSFNCTableViewItem).DisabledTextColor;
    FTitle := (Source as TTMSFNCTableViewItem).Title;
    FTitleTrimming := (Source as TTMSFNCTableViewItem).TitleTrimming;
    FTitleWordWrapping := (Source as TTMSFNCTableViewItem).TitleWordWrapping;
    FTitleHorizontalTextAlign := (Source as TTMSFNCTableViewItem).TitleHorizontalTextAlign;
    FTitleVerticalTextAlign := (Source as TTMSFNCTableViewItem).TitleVerticalTextAlign;
    FTitleColor := (Source as TTMSFNCTableViewItem).TitleColor;
    FSelectedTitleColor := (Source as TTMSFNCTableViewItem).SelectedTitleColor;
    FDisabledTitleColor := (Source as TTMSFNCTableViewItem).DisabledTitleColor;
    FEnabled := (Source as TTMSFNCTableViewItem).Enabled;
    FAccessory := (Source as TTMSFNCTableViewItem).Accessory;
    FVisible := (Source as TTMSFNCTableViewItem).Visible;
    FBitmapName := (Source as TTMSFNCTableViewItem).BitmapName;
    FBitmap.Assign((Source as TTMSFNCTableViewItem).Bitmap);
    FHeight := (Source as TTMSFNCTableViewItem).Height;
    FCategoryID := (Source as TTMSFNCTableViewItem).CategoryID;
    FAccessoryWidth := (Source as TTMSFNCTableViewItem).AccessoryWidth;
    FAccessoryHeight := (Source as TTMSFNCTableViewItem).AccessoryHeight;
    FAccessoryProgress := (Source as TTMSFNCTableViewItem).AccessoryProgress;
    FAccessoryText := (Source as TTMSFNCTableViewItem).AccessoryText;
    FChecked := (Source as TTMSFNCTableViewItem).Checked;
    FCheckType := (Source as TTMSFNCTableViewItem).CheckType;
    FAccessoryFontColor := (Source as TTMSFNCTableViewItem).AccessoryFontColor;
    FAccessoryBorderColor := (Source as TTMSFNCTableViewItem).AccessoryBorderColor;
    FAccessoryColor := (Source as TTMSFNCTableViewItem).AccessoryColor;
    FEnableMoreOptions := (Source as TTMSFNCTableViewItem).EnableMoreOptions;
    AssignData(Source);
  end;
end;

procedure TTMSFNCTableViewItem.AssignData(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewItem then
  begin
    FDataString := (Source as TTMSFNCTableViewItem).DataString;
    FDataBoolean := (Source as TTMSFNCTableViewItem).DataBoolean;
    FDataObject := (Source as TTMSFNCTableViewItem).DataObject;
    FDataInteger := (Source as TTMSFNCTableViewItem).DataInteger;
    FDataPointer := (Source as TTMSFNCTableViewItem).DataPointer;
    FDBKey := (Source as TTMSFNCTableViewItem).DBKey;
  end;
end;

procedure TTMSFNCTableViewItem.BitmapChanged(Sender: TObject);
begin
  UpdateItem;
end;

procedure TTMSFNCTableViewItem.Changed(Sender: TObject);
begin
  UpdateItem;
end;

constructor TTMSFNCTableViewItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FTableView := (Collection as TTMSFNCTableViewItems).TableView;

  FTextColor := gcBlack;
  FEnableMoreOptions := True;
  FTitleExpanded := True;
  FChecked := False;
  FCheckType := tvictNone;
  FCategoryID := -1;
  FAccessoryFontColor := gcNull;
  FAccessoryColor := gcNull;
  FAccessoryBorderColor := gcNull;
  FHeight := -1;
  FEnabled := True;
  FAccessory := tviaNone;
  FAccessoryWidth := 24;
  FAccessoryHeight := 24;
  FAccessoryProgress := 0;
  if Assigned(FTableView) and FTableView.IsDesignTime then
    FAccessoryText := 'Button';
  FVisible := True;
  FHTMLTemplateItems := TStringList.Create;
  FHTMLTemplateItems.OnChange := @TemplateItemsChanged;
  FTitleColor := gcBlack;
  FSelectedTextColor := gcBlack;
  FDisabledTextColor := gcSilver;
  FWordWrapping := true;
  FHorizontalTextAlign := gtaLeading;
  FVerticalTextAlign := gtaCenter;
  FTrimming := gttNone;
  FSelectedTitleColor := gcBlack;
  FDisabledTitleColor := gcSilver;
  FTitleWordWrapping := true;
  FTitleHorizontalTextAlign := gtaLeading;
  FTitleVerticalTextAlign := gtaCenter;
  FTitleTrimming := gttNone;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;
  if Assigned(FTableView) then
    Self.Assign(FTableView.DefaultItem);
  UpdateItem;
end;

destructor TTMSFNCTableViewItem.Destroy;
begin
  FBitmap.Free;
  FHTMLTemplateItems.Free;
  inherited;
  UpdateItem;
end;

procedure TTMSFNCTableViewItem.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetHTMLTemplateItems(const Value: TStringList);
begin
  if FHTMLTemplateItems <> Value then
  begin
    FHTMLTemplateItems.Assign(Value);
    UpdateItem;
  end;
end;

function TTMSFNCTableViewItem.GetBitmapContainer: TTMSFNCBitmapContainer;
var
  l: TTMSFNCCustomTableView;
begin
  Result := nil;
  l := TableView;
  if Assigned(l) then
    Result := l.BitmapContainer;
end;

function TTMSFNCTableViewItem.GetTableView: TTMSFNCCustomTableView;
begin
  Result := FTableView;
end;

procedure TTMSFNCTableViewItem.TemplateItemsChanged(Sender: TObject);
begin
  UpdateItem;
end;

procedure TTMSFNCTableViewItem.LoadFromString(AString: String);
var
  k: Integer;
  a: TStringList;
  s: TStringList;
  y: Integer;
  st: string;
  ps: Integer;
  subst: string;
begin
  a := TStringList.Create;
  try
    st := AString;
    while st <> '' do
    begin
      ps := Pos('{0}', st);
      if ps = 0 then
      begin
        a.Add(st);
        Break;
      end;
      subst := Copy(st, 1, ps - 1);
      a.Add(subst);
      Delete(st, 1, ps - 1 + Length('{0}'));
    end;

    for K := 0 to a.Count - 1 do
    begin
      if a[K] <> '' then
      begin
        if K = 0 then
          Text := a[K]
        else if K = 1 then
        begin
          s := TStringList.Create;
          try
            TTMSFNCUtils.Split(';', a[K], s);
            for y := 0 to s.Count - 1 do
            begin
              if s[y] <> '' then
              begin
                if y = 0 then
                  Checked := StrToBool(s[y])
                else if y = 1 then
                  BitmapName := s[y]
                else if y = 2 then
                  TTMSFNCUtils.LoadBitmapFromHexStr(s[y], Bitmap);
              end;
            end;
          finally
            s.Free;
          end;
        end
        else if K = 2 then
          Enabled := StrToBool(a[K]);
      end;
    end;
  finally
    a.Free;
  end;
end;

function TTMSFNCTableViewItem.GetStrippedHTMLText: String;
begin
  Result := TTMSFNCUtils.HTMLStrip(GetText);
end;

function TTMSFNCTableViewItem.GetText: string;
var
  ht: string;
  s: string;
begin
  s := '';
  if Assigned(FTableView) then
  begin
    ht := FTableView.ItemAppearance.HTMLTemplate;
    if ht = '' then
      s := Text
    else
    begin
      FTableView.DoGetHTMLTemplate(Self, ht);
      s := FTableView.HTMLReplace(ht, Self);
    end;
  end;

  Result := s;
end;

function TTMSFNCTableViewItem.GetTitle: string;
begin
  Result := Title;
end;

procedure TTMSFNCTableViewItem.HideDetailControl;
begin
  if Assigned(FTableView) then
    FTableView.HideDetailControl;
end;

function TTMSFNCTableViewItem.IsAccessoryHeightStored: Boolean;
begin
  Result := AccessoryHeight <> 24;
end;

function TTMSFNCTableViewItem.IsAccessoryProgressStored: Boolean;
begin
  Result := AccessoryProgress <> 0;
end;

function TTMSFNCTableViewItem.IsAccessoryWidthStored: Boolean;
begin
  Result := AccessoryWidth <> 24;
end;

function TTMSFNCTableViewItem.IsHeightStored: Boolean;
begin
  Result := Height <> -1;
end;

function TTMSFNCTableViewItem.IsSelected: Boolean;
var
  i: Integer;
begin
  Result := False;
  if Assigned(FTableView) then
  begin
    for I := 0 to FTableView.SelectedItemCount - 1 do
    begin
      if FTableView.SelectedItems[I] = Self then
      begin
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCTableViewItem.SaveToString(ATextOnly: Boolean): String;
var
  s: String;
begin
  if not Assigned(FTableView) then
    Exit;

  Result := '';
  Result := Result + Text;

  if not ATextOnly then
    Result := Result + '{0}';

  if not ATextOnly then
  begin
    Result := Result + BoolToStr(Checked) + ';';
    Result := Result + BitmapName + ';';
    Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(Bitmap) + ';';
    Result := Result + s;
  end;

  if not ATextOnly then
  begin
    Result := Result + '{0}';
    Result := Result + BoolToStr(Enabled);
  end;
end;

procedure TTMSFNCTableViewItem.SetCategoryID(const Value: integer);
begin
  if FCategoryID <> Value then
  begin
    FCategoryID := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetChecked(const Value: Boolean);
var
  l: TTMSFNCCustomTableView;
  n: TTMSFNCTreeViewVirtualNode;
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    l := TableView;
    if Assigned(l) then
    begin
      n := TTMSFNCCustomTableViewOpen(l).GetNodeForItemIndex(Index);
      if Assigned(n) and (n.CheckStates[0] <> FChecked) then
      begin
        if FChecked then
          TTMSFNCCustomTableViewOpen(l).TreeView.CheckVirtualNode(n, 0)
        else
          TTMSFNCCustomTableViewOpen(l).TreeView.UnCheckVirtualNode(n, 0);
      end;
    end;
  end;
end;

procedure TTMSFNCTableViewItem.SetCheckType(
  const Value: TTMSFNCTableViewItemCheckType);
begin
  if FCheckType <> Value then
  begin
    FCheckType := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleExpanded(const Value: Boolean);
begin
  if FTitleExpanded <> Value then
  begin
    FTitleExpanded := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessory(
  const Value: TTMSFNCTableViewItemAccessory);
begin
  if FAccessory <> Value then
  begin
    FAccessory := Value;
    case FAccessory of
      tviaDetail:
      begin
        AccessoryWidth := 32;
        AccessoryHeight := 24;
      end;
      tviaProgress:
      begin
        AccessoryWidth := 75;
        AccessoryHeight := 20;
      end;
      tviaButton:
      begin
        AccessoryWidth := 75;
        AccessoryHeight := 20;
      end;
      tviaBadge:
      begin
        AccessoryWidth := 32;
        AccessoryHeight := 24;
      end;
      tviaCustom: ;
    end;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryBorderColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FAccessoryBorderColor <> Value then
  begin
    FAccessoryBorderColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FAccessoryColor <> Value then
  begin
    FAccessoryColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FAccessoryFontColor <> Value then
  begin
    FAccessoryFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryHeight(const Value: Single);
begin
  if FAccessoryHeight <> Value then
  begin
    FAccessoryHeight := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryProgress(const Value: Single);
begin
  if FAccessoryProgress <> Value then
  begin
    FAccessoryProgress := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryText(const Value: String);
begin
  if FAccessoryText <> Value then
  begin
    FAccessoryText := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetAccessoryWidth(const Value: Single);
begin
  if FAccessoryWidth <> Value then
  begin
    FAccessoryWidth := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetBitmap(const Value: TTMSFNCBitmap);
begin
  if FBitmap <> Value then
  begin
    FBitmap.Assign(Value);
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetBitmapName(const Value: string);
begin
  if FBitmapName <> Value then
  begin
    FBitmapName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetDetailControl(const Value: TControl);
begin
  if Value = nil then
  begin
    if Assigned(FDetailControl) then
      FDetailControl.Visible := True;
  end;

  FDetailControl := Value;
  if Assigned(FDetailControl) then
    FDetailControl.Visible := False;
end;

procedure TTMSFNCTableViewItem.SetDisabledTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTextColor <> Value then
  begin
    FDisabledTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetDisabledTitleColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTitleColor <> Value then
  begin
    FDisabledTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetSelectedTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTextColor <> Value then
  begin
    FSelectedTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetSelectedTitleColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTitleColor <> Value then
  begin
    FSelectedTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTextColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTextColor <> Value then
  begin
    FTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitle(const Value: String);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTitleColor <> Value then
  begin
    FTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleHorizontalTextAlign <> Value then
  begin
    FTitleHorizontalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTitleTrimming <> Value then
  begin
    FTitleTrimming := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleVerticalTextAlign <> Value then
  begin
    FTitleVerticalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTitleWordWrapping(const Value: Boolean);
begin
  if FTitleWordWrapping <> Value then
  begin
    FTitleWordWrapping := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCTableViewItem.ShowDetailControl;
begin
  if Assigned(FTableView) then
    FTableView.ShowDetailControl(Index);
end;

procedure TTMSFNCTableViewItem.UpdateItem;
var
  l: TTMSFNCCustomTableView;
begin
  l := TableView;
  if Assigned(l) then
    l.UpdateTableView;
end;

{ TTMSFNCTableViewItemAppearance }

procedure TTMSFNCTableViewItemAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewItemAppearance then
  begin
    Font.Assign((Source as TTMSFNCTableViewItemAppearance).Font);
    Fill.Assign((Source as TTMSFNCTableViewItemAppearance).Fill);
    Stroke.Assign((Source as TTMSFNCTableViewItemAppearance).Stroke);
    SelectedFill.Assign((Source as TTMSFNCTableViewItemAppearance).SelectedFill);
    SelectedStroke.Assign((Source as TTMSFNCTableViewItemAppearance).SelectedStroke);
    DisabledFill.Assign((Source as TTMSFNCTableViewItemAppearance).DisabledFill);
    DisabledStroke.Assign((Source as TTMSFNCTableViewItemAppearance).DisabledStroke);
    HeightMode := (Source as TTMSFNCTableViewItemAppearance).HeightMode;
    FixedHeight := (Source as TTMSFNCTableViewItemAppearance).FixedHeight;
    FHTMLTemplate := (Source as TTMSFNCTableViewItemAppearance).HTMLTemplate;
    FAccessoryDetailBitmaps.Assign((Source as TTMSFNCTableViewItemAppearance).AccessoryDetailBitmaps);
    FAccessoryFont.Assign((Source as TTMSFNCTableViewItemAppearance).AccessoryFont);
    FAccessoryStroke.Assign((Source as TTMSFNCTableViewItemAppearance).AccessoryStroke);
    FAccessoryFill.Assign((Source as TTMSFNCTableViewItemAppearance).AccessoryFill);
  end;
end;

constructor TTMSFNCTableViewItemAppearance.Create(
  ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;

  FAccessoryFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcYellowgreen);
  FAccessoryFill.OnChanged := @FillChanged;
  FAccessoryStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcBlack);
  FAccessoryStroke.OnChanged := @StrokeChanged;
  FAccessoryFont := TTMSFNCGraphicsFont.Create;
  if Assigned(FTableView) and FTableView.IsDesignTime then
  begin
    FAccessoryFont.Color := gcDarkred;
    FAccessoryFont.Style := [TFontStyle.fsBold];
  end;
  FAccessoryFont.OnChanged := @FontChanged;

  FAccessoryDetailBitmaps := TTMSFNCScaledBitmaps.Create(Self);
  FAccessoryDetailBitmaps.OnChange := @DoAccessoryDetailBitmapsChanged;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) and FTableView.IsDesignTime then
  begin
    FTableView.FTreeView.NodesAppearance.DisabledFill.Kind := gfkNone;
    FTableView.FTreeView.NodesAppearance.DisabledStroke.Kind := gskNone;
    FTableView.FTreeView.NodesAppearance.SelectedStroke.Assign(FTableView.FTreeView.NodesAppearance.SelectedFill);
  end;
  HeightMode := tvhmVariable;
  FHeight := -1;
end;

destructor TTMSFNCTableViewItemAppearance.Destroy;
begin
  FAccessoryFill.Free;
  FAccessoryStroke.Free;
  FAccessoryFont.Free;
  FAccessoryDetailBitmaps.Free;
  inherited;
end;

procedure TTMSFNCTableViewItemAppearance.DoAccessoryDetailBitmapsChanged(
  Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

procedure TTMSFNCTableViewItemAppearance.FontChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

procedure TTMSFNCTableViewItemAppearance.StrokeChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

procedure TTMSFNCTableViewItemAppearance.FillChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

function TTMSFNCTableViewItemAppearance.GetDisabledFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.DisabledFill;
end;

function TTMSFNCTableViewItemAppearance.GetDisabledStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.DisabledStroke;
end;

function TTMSFNCTableViewItemAppearance.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.Fill;
end;

function TTMSFNCTableViewItemAppearance.GetFixedHeight: Double;
begin
  Result := 0;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.FixedHeight;
end;

function TTMSFNCTableViewItemAppearance.GetFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.Font;
end;

function TTMSFNCTableViewItemAppearance.GetHeightMode: TTMSFNCTableViewItemHeightMode;
var
  i: Integer;
begin
  Result := tvhmVariable;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(FTableView.FTreeView.NodesAppearance.HeightMode);
    Result := TTMSFNCTableViewItemHeightMode(i);
  end;
end;

function TTMSFNCTableViewItemAppearance.GetOwner: TPersistent;
begin
  Result := FTableView;
end;

function TTMSFNCTableViewItemAppearance.GetSelectedFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.SelectedFill;
end;

function TTMSFNCTableViewItemAppearance.GetSelectedStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.SelectedStroke;
end;

function TTMSFNCTableViewItemAppearance.GetShowFocus: Boolean;
begin
  Result := True;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.ShowFocus;
end;

function TTMSFNCTableViewItemAppearance.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.Stroke;
end;

function TTMSFNCTableViewItemAppearance.IsHeightStored: Boolean;
begin
  Result := Height <> -1;
end;

procedure TTMSFNCTableViewItemAppearance.SetAccessoryDetailBitmaps(
  const Value: TTMSFNCScaledBitmaps);
begin
  FAccessoryDetailBitmaps.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetAccessoryFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FAccessoryFill.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetAccessoryFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FAccessoryFont.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetAccessoryStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FAccessoryStroke.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.DisabledFill.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.DisabledStroke.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.Fill.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetFixedHeight(const Value: Double);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.FixedHeight := Value;
end;

procedure TTMSFNCTableViewItemAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.Font.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewItemAppearance.SetHeightMode(
  const Value: TTMSFNCTableViewItemHeightMode);
var
  i: Integer;
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(Value);
    FTableView.FTreeView.NodesAppearance.HeightMode := TTMSFNCTreeViewNodeHeightMode(i);
  end;
end;

procedure TTMSFNCTableViewItemAppearance.SetHTMLTemplate(const Value: string);
begin
  if FHTMLTemplate <> Value then
  begin
    FHTMLTemplate := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewItemAppearance.SetSelectedFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.SelectedFill.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetSelectedStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.SelectedStroke.Assign(Value);
end;

procedure TTMSFNCTableViewItemAppearance.SetShowFocus(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.ShowFocus := Value;
end;

procedure TTMSFNCTableViewItemAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.Stroke.Assign(Value);
end;

{ TTMSFNCTableViewCategoryAppearance }

procedure TTMSFNCTableViewCategoryAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewCategoryAppearance then
  begin
    Font.Assign((Source as TTMSFNCTableViewCategoryAppearance).Font);
    Fill.Assign((Source as TTMSFNCTableViewCategoryAppearance).Fill);
    Stroke.Assign((Source as TTMSFNCTableViewCategoryAppearance).Stroke);
    FHeight := (Source as TTMSFNCTableViewCategoryAppearance).Height;
  end;
end;

constructor TTMSFNCTableViewCategoryAppearance.Create(
  ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  FHeight := -1;
end;

destructor TTMSFNCTableViewCategoryAppearance.Destroy;
begin
  inherited;
end;

function TTMSFNCTableViewCategoryAppearance.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.ExtendedFill;
end;

function TTMSFNCTableViewCategoryAppearance.GetFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.ExtendedFont;
end;

function TTMSFNCTableViewCategoryAppearance.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.NodesAppearance.ExtendedStroke;
end;

function TTMSFNCTableViewCategoryAppearance.IsHeightStored: Boolean;
begin
  Result := Height <> -1;
end;

procedure TTMSFNCTableViewCategoryAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.ExtendedFill.Assign(Value);
end;

procedure TTMSFNCTableViewCategoryAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.ExtendedFont.Assign(Value);
end;

procedure TTMSFNCTableViewCategoryAppearance.SetHeight(const Value: Single);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewCategoryAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.NodesAppearance.ExtendedStroke.Assign(Value);
end;

{ TTMSFNCTableViewMoreOptionAppearance }

procedure TTMSFNCTableViewMoreOptionAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewMoreOptionAppearance then
  begin
    FFont.Assign((Source as TTMSFNCTableViewMoreOptionAppearance).Font);
    FFill.Assign((Source as TTMSFNCTableViewMoreOptionAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCTableViewMoreOptionAppearance).Stroke);
  end;
end;

constructor TTMSFNCTableViewMoreOptionAppearance.Create(
  ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  FFont := TTMSFNCGraphicsFont.Create;
  if Assigned(FTableView) and FTableView.IsDesignTime then
    FFont.Color := gcWhite;
  FFont.OnChanged := @FontChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcSteelblue);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcSteelblue);
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCTableViewMoreOptionAppearance.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCTableViewMoreOptionAppearance.FillChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.Invalidate;
end;

procedure TTMSFNCTableViewMoreOptionAppearance.FontChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.Invalidate;
end;

procedure TTMSFNCTableViewMoreOptionAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCTableViewMoreOptionAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCTableViewMoreOptionAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCTableViewMoreOptionAppearance.StrokeChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.Invalidate;
end;

{ TTMSFNCTableViewInteraction }

procedure TTMSFNCTableViewInteraction.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTableViewInteraction) then
  begin
    FLookup.Assign((Source as TTMSFNCTableViewInteraction).Lookup);
    Filtering.Assign((Source as TTMSFNCTableViewInteraction).Filtering);
    Sorting := (Source as TTMSFNCTableViewInteraction).Sorting;
    FShowDetailMode := (Source as TTMSFNCTableViewInteraction).ShowDetailMode;
    DragDropMode := (Source as TTMSFNCTableViewInteraction).DragDropMode;
    MultiSelect := (Source as TTMSFNCTableViewInteraction).MultiSelect;
    TouchScrolling := (Source as TTMSFNCTableViewInteraction).TouchScrolling;
    ClipboardMode := (Source as TTMSFNCTableViewInteraction).ClipboardMode;
    Reorder := (Source as TTMSFNCTableViewInteraction).Reorder;
    FShowEditButton := (Source as TTMSFNCTableViewInteraction).ShowEditButton;
    FShowFilterButton := (Source as TTMSFNCTableViewInteraction).ShowFilterButton;
    FAccessoryClickDetection := (Source as TTMSFNCTableViewInteraction).AccessoryClickDetection;
    FLongPressToEdit := (Source as TTMSFNCTableViewInteraction).LongPressToEdit;
    FSwipeToShowMoreOptions := (Source as TTMSFNCTableViewInteraction).SwipeToShowMoreOptions;
    FSwipeBounceGesture := (Source as TTMSFNCTableViewInteraction).SwipeBounceGesture;
  end;
end;

constructor TTMSFNCTableViewInteraction.Create(ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  FLongPressToEdit := True;
  FSwipeToShowMoreOptions := True;
  FAccessoryClickDetection := ClickAccessories;
  FLookup := TTMSFNCTableViewLookup.Create(Self);
  FFiltering := TTMSFNCTableViewFiltering.Create(Self);
  FShowDetailMode := tvdtAfterSelectItem;
  FShowEditButton := False;
  FShowFilterButton := False;
  FSwipeBounceGesture := True;
  FKeyboardEscapeMode := tvkeStopFilter;
end;

destructor TTMSFNCTableViewInteraction.Destroy;
begin
  FLookup.Free;
  FFiltering.Free;
  inherited;
end;

function TTMSFNCTableViewInteraction.GetAutoOpenURL: Boolean;
begin
  Result := True;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.Interaction.AutoOpenURL;
end;

function TTMSFNCTableViewInteraction.GetClipboardMode: TTMSFNCTableViewClipboardMode;
var
  i: Integer;
begin
  Result := tvcmNone;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(FTableView.FTreeView.Interaction.ClipboardMode);
    Result := TTMSFNCTableViewClipboardMode(i);
  end;
end;

function TTMSFNCTableViewInteraction.GetDragDropMode: TTMSFNCTableViewDragDropMode;
var
  i: Integer;
begin
  Result := tvdmNone;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(FTableView.FTreeView.Interaction.DragDropMode);
    Result := TTMSFNCTableViewDragDropMode(i);
  end;
end;

function TTMSFNCTableViewInteraction.GetMultiSelect: Boolean;
begin
  Result := False;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.Interaction.MultiSelect;
end;

function TTMSFNCTableViewInteraction.GetReorder: Boolean;
begin
  Result := False;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.Interaction.Reorder;
end;

function TTMSFNCTableViewInteraction.GetSorting: TTMSFNCTableViewSorting;
begin
  Result := tvcsNone;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) and (FTableView.FTreeView.Columns.Count > 0) then
  begin
    case FTableView.FTreeView.Columns[0].Sorting of
      tcsNone: Result := tvcsNone;
      tcsNormal: Result := tvcsNormal;
      tcsNormalCaseSensitive: Result := tvcsNormalCaseSensitive;
    end;
  end;
end;

function TTMSFNCTableViewInteraction.GetTouchScrolling: Boolean;
begin
  Result := True;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.Interaction.TouchScrolling;
end;

procedure TTMSFNCTableViewInteraction.SetAccessoryClickDetection(
  const Value: TTMSFNCTableViewItemAccessories);
begin
  FAccessoryClickDetection := Value;
end;

procedure TTMSFNCTableViewInteraction.SetAutoOpenURL(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.Interaction.AutoOpenURL := Value;
end;

procedure TTMSFNCTableViewInteraction.SetClipboardMode(
  const Value: TTMSFNCTableViewClipboardMode);
var
  i: Integer;
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(Value);
    FTableView.FTreeView.Interaction.ClipboardMode := TTMSFNCTreeViewClipboardMode(i);
  end;
end;

procedure TTMSFNCTableViewInteraction.SetShowDetailMode(
  const Value: TTMSFNCTableViewShowDetailMode);
begin
  if FShowDetailMode <> Value then
    FShowDetailMode := Value;
end;

procedure TTMSFNCTableViewInteraction.SetShowEditButton(const Value: Boolean);
begin
  if FShowEditButton <> Value then
  begin
    FShowEditButton := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewInteraction.SetShowFilterButton(const Value: Boolean);
begin
  if FShowFilterButton <> Value then
  begin
    FShowFilterButton := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewInteraction.SetDragDropMode(
  const Value: TTMSFNCTableViewDragDropMode);
var
  i: Integer;
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    i := Integer(Value);
    FTableView.FTreeView.Interaction.DragDropMode := TTMSFNCTreeViewDragDropMode(i);
  end;
end;

procedure TTMSFNCTableViewInteraction.SetFiltering(
  const Value: TTMSFNCTableViewFiltering);
begin
  FFiltering.Assign(Value);
end;

procedure TTMSFNCTableViewInteraction.SetLookup(
  const Value: TTMSFNCTableViewLookup);
begin
  FLookup.Assign(Value);
end;

procedure TTMSFNCTableViewInteraction.SetMultiSelect(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.Interaction.MultiSelect := Value;
end;

procedure TTMSFNCTableViewInteraction.SetReorder(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.Interaction.Reorder := Value;
end;

procedure TTMSFNCTableViewInteraction.SetSorting(
  const Value: TTMSFNCTableViewSorting);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) and (FTableView.FTreeView.Columns.Count > 0) then
  begin
    case Value of
      tvcsNone: FTableView.FTreeView.Columns[0].Sorting := tcsNone;
      tvcsNormal: FTableView.FTreeView.Columns[0].Sorting := tcsNormal;
      tvcsNormalCaseSensitive: FTableView.FTreeView.Columns[0].Sorting := tcsNormalCaseSensitive;
    end;
  end;
end;

procedure TTMSFNCTableViewInteraction.SetTouchScrolling(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.Interaction.TouchScrolling := Value;
end;

{ TTMSFNCTableViewLookup }

procedure TTMSFNCTableViewLookup.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewLookup then
  begin
    CaseSensitive := (Source as TTMSFNCTableViewLookup).CaseSensitive;
    Enabled := (Source as TTMSFNCTableViewLookup).Enabled;
    Incremental := (Source as TTMSFNCTableViewLookup).Incremental;
    AutoSelect := (Source as TTMSFNCTableViewLookup).AutoSelect;
  end;
end;

constructor TTMSFNCTableViewLookup.Create(AOwner: TTMSFNCTableViewInteraction);
begin
  FOwner := AOwner;
end;

function TTMSFNCTableViewLookup.GetAutoSelect: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    Result := FOwner.FTableView.FTreeView.Interaction.Lookup.AutoSelect;
end;

function TTMSFNCTableViewLookup.GetCaseSensitive: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    Result := FOwner.FTableView.FTreeView.Interaction.Lookup.CaseSensitive;
end;

function TTMSFNCTableViewLookup.GetEnabled: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    Result := FOwner.FTableView.FTreeView.Interaction.Lookup.Enabled;
end;

function TTMSFNCTableViewLookup.GetIncremental: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    Result := FOwner.FTableView.FTreeView.Interaction.Lookup.Incremental;
end;

procedure TTMSFNCTableViewLookup.SetAutoSelect(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    FOwner.FTableView.FTreeView.Interaction.Lookup.AutoSelect := Value;
end;

procedure TTMSFNCTableViewLookup.SetCaseSensitive(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    FOwner.FTableView.FTreeView.Interaction.Lookup.CaseSensitive := Value;
end;

procedure TTMSFNCTableViewLookup.SetEnabled(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    FOwner.FTableView.FTreeView.Interaction.Lookup.Enabled := Value;
end;

procedure TTMSFNCTableViewLookup.SetIncremental(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) then
    FOwner.FTableView.FTreeView.Interaction.Lookup.Incremental := Value;
end;

{ TTMSFNCTableViewFiltering }

procedure TTMSFNCTableViewFiltering.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewFiltering then
  begin
    Enabled := (Source as TTMSFNCTableViewFiltering).Enabled;
    DropDownWidth := (Source as TTMSFNCTableViewFiltering).DropDownWidth;
    DropDownHeight := (Source as TTMSFNCTableViewFiltering).DropDownHeight;
  end;
end;

constructor TTMSFNCTableViewFiltering.Create(
  AOwner: TTMSFNCTableViewInteraction);
begin
  FOwner := AOwner;
end;

function TTMSFNCTableViewFiltering.GetDropDownHeight: integer;
begin
  Result := 120;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    Result := FOwner.FTableView.FTreeView.Columns[0].Filtering.DropDownHeight;
end;

function TTMSFNCTableViewFiltering.GetDropDownWidth: integer;
begin
  Result := 100;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    Result := FOwner.FTableView.FTreeView.Columns[0].Filtering.DropDownWidth;
end;

function TTMSFNCTableViewFiltering.GetEnabled: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    Result := FOwner.FTableView.FTreeView.Columns[0].Filtering.Enabled;
end;

procedure TTMSFNCTableViewFiltering.SetDropDownHeight(const Value: integer);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    FOwner.FTableView.FTreeView.Columns[0].Filtering.DropDownHeight := Value;
end;

procedure TTMSFNCTableViewFiltering.SetDropDownWidth(const Value: integer);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    FOwner.FTableView.FTreeView.Columns[0].Filtering.DropDownWidth := Value;
end;

procedure TTMSFNCTableViewFiltering.SetEnabled(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FTableView) and Assigned(FOwner.FTableView.FTreeView) and (FOwner.FTableView.FTreeView.Columns.Count > 0) then
    FOwner.FTableView.FTreeView.Columns[0].Filtering.Enabled := Value;
end;

{ TTMSFNCTableViewHeaderFooter }

procedure TTMSFNCTableViewHeaderFooter.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewHeaderFooter then
  begin
    Visible := (Source as TTMSFNCTableViewHeaderFooter).Visible;
    Text := (Source as TTMSFNCTableViewHeaderFooter).Text;
    Fill.Assign((Source as TTMSFNCTableViewHeaderFooter).Fill);
    Font.Assign((Source as TTMSFNCTableViewHeaderFooter).Font);
    Stroke.Assign((Source as TTMSFNCTableViewHeaderFooter).Stroke);
    HorizontalTextAlign := (Source as TTMSFNCTableViewHeaderFooter).HorizontalTextAlign;
    VerticalTextAlign := (Source as TTMSFNCTableViewHeaderFooter).VerticalTextAlign;
    WordWrapping := (Source as TTMSFNCTableViewHeaderFooter).WordWrapping;
    Trimming := (Source as TTMSFNCTableViewHeaderFooter).Trimming;
    Height := (Source as TTMSFNCTableViewHeaderFooter).Height;
    SortIndicatorColor := (Source as TTMSFNCTableViewHeaderFooter).SortIndicatorColor;
  end;
end;

constructor TTMSFNCTableViewHeaderFooter.Create(ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  if Assigned(FTableView) and FTableView.IsDesignTime then
  begin
    if Self is TTMSFNCTableViewHeader then
      FText := 'Header'
    else
      FText := 'Footer';

    Fill.Color := MakeGraphicsColor(249, 249, 249);
    Fill.Kind := gfkSolid;
    Stroke.Color := MakeGraphicsColor(178, 178, 178);
    Stroke.Kind := gskSolid;
  end;
end;

function TTMSFNCTableViewHeaderFooter.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      Result := FTableView.FTreeView.ColumnsAppearance.TopFill
    else
      Result := FTableView.FTreeView.ColumnsAppearance.BottomFill
  end;
end;

function TTMSFNCTableViewHeaderFooter.GetFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      Result := FTableView.FTreeView.ColumnsAppearance.TopFont
    else
      Result := FTableView.FTreeView.ColumnsAppearance.BottomFont
  end;
end;

function TTMSFNCTableViewHeaderFooter.GetHeight: Single;
begin
  Result := 30;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      Result := FTableView.FTreeView.ColumnsAppearance.TopSize
    else
      Result := FTableView.FTreeView.ColumnsAppearance.BottomSize
  end;
end;

function TTMSFNCTableViewHeaderFooter.GetSortIndicatorColor: TTMSFNCGraphicsColor;
begin
  Result := TTMSFNCTreeViewColorSelection;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    Result := FTableView.FTreeView.ColumnsAppearance.SortIndicatorColor;
end;

function TTMSFNCTableViewHeaderFooter.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      Result := FTableView.FTreeView.ColumnsAppearance.TopStroke
    else
      Result := FTableView.FTreeView.ColumnsAppearance.BottomStroke
  end;
end;

function TTMSFNCTableViewHeaderFooter.GetVisible: Boolean;
begin
  Result := False;
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      Result := tclTop in FTableView.FTreeView.ColumnsAppearance.Layouts
    else
      Result := tclBottom in FTableView.FTreeView.ColumnsAppearance.Layouts;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      FTableView.FTreeView.ColumnsAppearance.TopFill.Assign(Value)
    else
      FTableView.FTreeView.ColumnsAppearance.BottomFill.Assign(Value);
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      FTableView.FTreeView.ColumnsAppearance.TopFill.Assign(Value)
    else
      FTableView.FTreeView.ColumnsAppearance.BottomFill.Assign(Value);
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
      FTableView.FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetHeight(const Value: Single);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
      FTableView.FTreeView.ColumnsAppearance.TopSize := Value
    else
      FTableView.FTreeView.ColumnsAppearance.BottomSize := Value;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetSortIndicatorColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.ColumnsAppearance.SortIndicatorColor := Value;
end;

procedure TTMSFNCTableViewHeaderFooter.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
    FTableView.FTreeView.ColumnsAppearance.TopStroke.Assign(Value);
end;

procedure TTMSFNCTableViewHeaderFooter.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
      FTableView.FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
      FTableView.FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
      FTableView.FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetVisible(const Value: Boolean);
begin
  if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
  begin
    if Self is TTMSFNCTableViewHeader then
    begin
      if Value then
        FTableView.FTreeView.ColumnsAppearance.Layouts := FTableView.FTreeView.ColumnsAppearance.Layouts + [tclTop]
      else
        FTableView.FTreeView.ColumnsAppearance.Layouts := FTableView.FTreeView.ColumnsAppearance.Layouts - [tclTop];
    end
    else
    begin
      if Value then
        FTableView.FTreeView.ColumnsAppearance.Layouts := FTableView.FTreeView.ColumnsAppearance.Layouts + [tclBottom]
      else
        FTableView.FTreeView.ColumnsAppearance.Layouts := FTableView.FTreeView.ColumnsAppearance.Layouts - [tclBottom];
    end;

    FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewHeaderFooter.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    if Assigned(FTableView) and Assigned(FTableView.FTreeView) then
      FTableView.FTreeView.Invalidate;
  end;
end;

{ TTMSFNCTableViewFilterData }

procedure TTMSFNCTableViewFilterData.Assign(Source: TPersistent);
var
  ASrcFilterData: TTMSFNCTableViewFilterData;
begin
  ASrcFilterData := Source as TTMSFNCTableViewFilterData;
  if Assigned(ASrcFilterData) then
  begin
    FCondition := ASrcFilterData.Condition;
    FCaseSensitive := ASrcFilterData.CaseSensitive;
    FPrefix := ASrcFilterData.Prefix;
    FSuffix := ASrcFilterData.Suffix;
    FOperation := ASrcFilterData.Operation;
  end;
end;

constructor TTMSFNCTableViewFilterData.Create(ACollection: TCollection);
begin
  inherited;
  FCaseSensitive := True;
end;

{ TTMSFNCTableViewFilter }

function TTMSFNCTableViewFilter.Add: TTMSFNCTableViewFilterData;
begin
  Result := TTMSFNCTableViewFilterData(inherited Add);
  if Count = 1 then
    Result.Operation := tvfoNone
  else
    Result.Operation := tvfoAND;
end;

constructor TTMSFNCTableViewFilter.Create(AOwner: TTMSFNCCustomTableView);
begin
  inherited Create(AOwner, TTMSFNCTableViewFilterData);
  FOwner := AOwner;
end;

function TTMSFNCTableViewFilter.GetItem(Index: Integer): TTMSFNCTableViewFilterData;
begin
  Result := TTMSFNCTableViewFilterData(inherited GetItem(Index));
end;

function TTMSFNCTableViewFilter.Insert(index: Integer): TTMSFNCTableViewFilterData;
begin
  Result := TTMSFNCTableViewFilterData(inherited Insert(Index));
end;

procedure TTMSFNCTableViewFilter.SetItem(Index: Integer; const Value: TTMSFNCTableViewFilterData);
begin
  inherited SetItem(Index, Value);
end;

{ TTMSFNCTableView }

procedure TTMSFNCTableView.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCTableView, TTMSFNCTableViewItem]);
end;

{ TTMSFNCTreeViewTableView }

procedure TTMSFNCTreeViewTableView.AnimateTimerChanged(Sender: TObject);
var
  d, pos: Double;
  anim: Boolean;
begin
  if FAnimateMoreOptions or FAnimateMoreOptionsClose then
  begin
    pos := FMoreOffset;
    d := Abs(FMoreOffsetTo - pos) / ANIMATIONFACTOR;
    anim := AnimateDouble(pos, FMoreOffsetTo, d, 0.01);

    if anim then
      FMoreOffset := pos
    else
    begin
      FMoreOffset := FMoreOffsetTo;
      FAnimTimer.Enabled := False;
      if FAnimateMoreOptionsClose then
      begin
        FMoreOffsetItem := nil;
        FAnimateMoreOptionsClose := False;
      end;
    end;

    if Assigned(FTableView) then
      FTableView.UpdateTableView(True);
  end
  else
  begin
    if FDownTimer then
    begin
      Inc(FDownCount);
      if FDownCount = 50 then
      begin
        FDownAction := True;
        FAnimTimer.Enabled := False;
        if Assigned(FTableView) then
          FTableView.ToggleEditMode;
      end;
    end
    else
    begin
      FAnimShiftAngle := FAnimShiftAngle + 5;
      if FAnimShiftAngle = 360 then
        FAnimShiftAngle := 0;

      if not FAnimMarqueeSwitch then
        FAnimMarqueeSweepAngle := FAnimMarqueeSweepAngle + 10
      else
      begin
        FAnimMarqueeStartAngle := FAnimMarqueeStartAngle + 10;
        FAnimMarqueeSweepAngle := FAnimMarqueeSweepAngle - 10;
      end;

      if (FAnimMarqueeSweepAngle = 360) and (FAnimMarqueeStartAngle = 0) then
        FAnimMarqueeSwitch := True
      else if ((FAnimMarqueeStartAngle = 360) and (FAnimMarqueeSweepAngle = 0)) then
      begin
        FAnimMarqueeSwitch := False;
        FAnimMarqueeStartAngle := 0;
      end;

      Invalidate;
    end;
  end;
end;

function TTMSFNCTreeViewTableView.CanApplyVerticalOffset: Boolean;
begin
  Result := False;
  if Assigned(FTableView) and Assigned(FTableView.Interaction) then
    Result := FTableView.Interaction.SwipeBounceGesture;
end;

constructor TTMSFNCTreeViewTableView.Create(AOwner: TComponent);
begin
  inherited;
  FAnimMarqueeStartAngle := 0;
  FManualProgressSweepAngle := 0;
  FManualProgressStartAngle := 0;
  FAnimMarqueeSweepAngle := 0;
  FMoreOffset := 0;
  FMoreOffsetTo := 0;
  FAnimMarqueeSwitch := False;
  FFloatingNodes := TTMSFNCTreeViewTableViewFloatingNodes.Create;
  FAnimTimer := TTimer.Create(Self);
  FAnimTimer.Enabled := False;
  FAnimTimer.Interval := 1;
  FAnimTimer.OnTimer := AnimateTimerChanged;
end;

destructor TTMSFNCTreeViewTableView.Destroy;
begin
  FAnimTimer.Free;
  FFloatingNodes.Free;
  inherited;
end;

procedure TTMSFNCTreeViewTableView.DoFooterAnchorClick(AAnchor: String);
begin
  if Assigned(OnFooterAnchorClick) then
    OnFooterAnchorClick(Self, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCTreeViewTableView.DoHeaderAnchorClick(AAnchor: String);
begin
  if Assigned(OnHeaderAnchorClick) then
    OnHeaderAnchorClick(Self, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCTreeViewTableView.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  DrawLookupBar(AGraphics, ARect);
  DrawReload(AGraphics, ARect);
end;

procedure TTMSFNCTreeViewTableView.DrawLookupBar(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  I: Integer;
  ldp: TTMSFNCTableViewLookupBarDisplayItem;
  cr: TRectF;
begin
  if Assigned(FTableView) and (FTableView.LookupBar.Visible and (FTableView.CategoryType <> tvctNone)) then
  begin
    cr := GetLookupBarRect;
    if FMouseDownOnLookupBar then
    begin
      AGraphics.Fill.Assign(FTableView.LookupBar.DownFill);
      AGraphics.Stroke.Assign(FTableView.LookupBar.DownStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(FTableView.LookupBar.Fill);
      AGraphics.Stroke.Assign(FTableView.LookupBar.Stroke);
    end;

    AGraphics.DrawRectangle(cr);

    for I := 0 to FTableView.FLookupBarDisplayList.Count - 1 do
    begin
      ldp := FTableView.FLookupBarDisplayList[I];
      if ldp.Active then
        AGraphics.Font.Assign(FTableView.LookupBar.Font)
      else
        AGraphics.Font.Assign(FTableView.LookupBar.InActiveFont);
      AGraphics.DrawText(ldp.Rect, ldp.Text, False, gtaCenter, gtaCenter);
    end;
  end;
end;

procedure TTMSFNCTreeViewTableView.DrawReload(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
  sz: Single;
begin
  if Assigned(FTableView) and FTableView.Reload.Enabled and FReloadActive then
  begin
    r := GetNodesRect;
    sz := FTableView.Reload.Size;
    r := RectF(r.Left + ((r.Right - r.Left) - sz) / 2, r.Top + (FTableView.Reload.Offset - sz) / 2, r.Left + ((r.Right - r.Left) - sz) / 2 + sz, r.Top + (FTableView.Reload.Offset - sz) / 2 + sz);
    AGraphics.Stroke.Assign(FTableView.Reload.Stroke);
    case FTableView.Reload.ProgressMode of
      tvrpmMarquee: AGraphics.DrawArc(CenterPointEx(r), PointF((r.Right - r.Left) / 2, (r.Bottom - r.Top) / 2), FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle);
      tvrpmMarqueeAlternate: AGraphics.DrawArc(CenterPointEx(r), PointF((r.Right - r.Left) / 2, (r.Bottom - r.Top) / 2), FAnimShiftAngle + FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle);
      tvrpmManual: AGraphics.DrawArc(CenterPointEx(r), PointF((r.Right - r.Left) / 2, (r.Bottom - r.Top) / 2), FManualProgressStartAngle, FManualProgressSweepAngle);
    end;
  end;
end;

procedure TTMSFNCTreeViewTableView.CustomizeNodeCache(
  AGraphics: TTMSFNCGraphics; AStartY: Single);
var
  n: TTMSFNCTreeViewVirtualNode;
  s: Single;
  dp: Integer;
begin
  inherited;
  if Assigned(FTableView) and (FTableView.FUpdateCount = 0) and (FTableView.CategoryType <> tvctNone) then
  begin
    dp := FTableView.GetDisplayCategoryIndex(StartRow);
    if (dp > -1) then
    begin
      FFloatingNodes.Clear;
      n := TTMSFNCTreeViewVirtualNode.Create(Self);
      n.CopyFrom(GetNodeForRow(dp));
      s := 0;
      ConfigureNode(AGraphics, 0, n, s);
      FFloatingNodes.Add(n);
    end;
  end;
end;

procedure TTMSFNCTreeViewTableView.CustomizeScrollPosition(
  ANode: TTMSFNCTreeViewVirtualNode; var APosition, ATopPosition: Double);
begin
  inherited;
  if (FFloatingNodes.Count > 0) and not ANode.Extended {and (CompareValue(APosition, GetVerticalScrollPosition) = LessThanValue)} then
  begin
    APosition := APosition - FFloatingNodes[0].Height;
    ATopPosition := ATopPosition - FFloatingNodes[0].Height;
  end;
end;

function TTMSFNCTreeViewTableView.GetCategoryAtXY(X, Y: Single): Integer;
var
  i, s: Integer;
  r: TRectF;
  str: String;
  ypos: Single;
  g: TTMSFNCGraphics;
  cr: TRectF;
begin
  Result := -1;

  if not Assigned(FTableView) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  try
    g.Font.Assign(FTableView.LookupBar.Font);
    s := FTableView.Categories.Count - 1;
    cr := GetLookupBarRect;
    ypos := cr.Top + 3;
    for I := 0 to s do
    begin
      str := FTableView.Categories[I].LookupText;
      r.Left := cr.Left;
      r.Right := cr.Right;
      r.Top := ypos;
      r.Bottom := r.Top + g.CalculateTextHeight(str);
      ypos := ypos + (r.Bottom - r.Top) + 3;

      if PtInRectEx(r, PointF(X, Y)) then
      begin
        Result := I;
        Break;
      end;
    end;
  finally
    g.Free;
  end;
end;

function TTMSFNCTreeViewTableView.GetColumnWidth(AColumn: Integer): Double;
begin
  Result := inherited GetColumnWidth(AColumn);
  if Assigned(FTableView) and (FTableView.LookupBar.Visible and (FTableView.CategoryType <> tvctNone)) then
    Result := Result - FTableView.LookupBar.Width;
end;

function TTMSFNCTreeViewTableView.GetContentClipRect: TRectF;
begin
  Result := inherited GetContentRect;
  Result.Left := Result.Left + 1;
  Result.Right := Result.Right - 1;
end;

function TTMSFNCTreeViewTableView.GetContentRect: TRectF;
begin
  Result := inherited GetContentRect;
  if Assigned(FTableView) and FTableView.FFilterActive then
    Result.Top := Result.Top + FTableView.FEdit.Height;
end;

function TTMSFNCTreeViewTableView.GetNodesRect: TRectF;
var
  d: Double;
begin
  Result := inherited GetNodesRect;
  if Assigned(FTableView) then
  begin
    d := (Result.Right - Result.Left) - FTableView.FDetailControlOffset;
    if (FTableView.LookupBar.Visible and (FTableView.CategoryType <> tvctNone)) then
    begin
      case FTableView.LookupBar.Position of
        tvlbpLeft:
        begin
          Result.Left := Result.Left + FTableView.LookupBar.Width - 1;
          Result.Right := Result.Right - FTableView.LookupBar.Width - 1;
        end;
        tvlbpRight: Result.Right := Result.Right - FTableView.LookupBar.Width;
      end;
    end;

    Result.Left := Result.Left - d;
    Result.Right := Result.Right - d;
  end;
end;

function TTMSFNCTreeViewTableView.GetReloadOffset: Single;
begin
  Result := inherited GetReloadOffset;
  if Assigned(FTableView) then
  begin
    if FTableView.Reload.Enabled then
      Result := Result + FTableView.Reload.Offset;
  end;
end;

function TTMSFNCTreeViewTableView.GetTotalColumnNodeWidth: Double;
begin
  Result := inherited GetTotalColumnNodeWidth;
  if Assigned(FTableView) and (FTableView.LookupBar.Visible and (FTableView.CategoryType <> tvctNone)) then
    Result := Result - FTableView.LookupBar.Width;
end;

function TTMSFNCTreeViewTableView.GetLookupBarRect: TRectF;
var
  sz: Single;
  d: Single;
begin
  Result := EmptyRect;
  if Assigned(FTableView) and (FTableView.LookupBar.Visible and (FTableView.CategoryType <> tvctNone)) then
  begin
    sz := FTableView.LookupBar.Width;
    Result := GetContentRect;
    d := (Result.Right - Result.Left) - FTableView.FDetailControlOffset;
    case FTableView.LookupBar.Position of
      tvlbpLeft:
      begin
        Result.Right := Result.Left + sz;
        Result.Left := Result.Left + 1;
        Result.Top := Result.Top + 1;
        Result.Bottom := Result.Bottom - 1;
      end;
      tvlbpRight:
      begin
        Result.Left := Result.Right - sz;
        Result.Top := Result.Top + 1;
        Result.Bottom := Result.Bottom - 1;
        Result.Right := Result.Right - 1;
      end;
    end;

    Result.Left := Result.Left - d;
    Result.Right := Result.Right - d;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCTableViewEdit.KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCTableViewEdit.KeyDown(var Key: Word; Shift: TShiftState);
{$ENDIF}
begin
  inherited;
  if not Assigned(FTableView) then
    Exit;

  if (Key in [KEY_ESCAPE]) and FTableView.FFilterActive then
  begin
    case FTableView.Interaction.KeyboardEscapeMode of
      tvkeNone: ;
      tvkeClearEdit:
      begin
        FTableView.FFilterActive := False;
        FTableView.StartFiltering;
      end;
      tvkeStopFilter: FTableView.StopFiltering;
    end;
  end;
end;

procedure TTMSFNCTreeViewTableView.HandleKeyDown(var Key: Word;
  Shift: TShiftState);
begin
  inherited;
  if BlockUserInput or not Assigned(FTableView) then
    Exit;

  if (Key in [KEY_ESCAPE]) and FTableView.FFilterActive then
  begin
    case FTableView.Interaction.KeyboardEscapeMode of
      tvkeNone: ;
      tvkeClearEdit:
      begin
        FTableView.FFilterActive := False;
        FTableView.StartFiltering;
      end;
      tvkeStopFilter: FTableView.StopFiltering;
    end;
  end;
end;

procedure TTMSFNCTreeViewTableView.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  it: TTMSFNCTableViewItem;
begin
  if Assigned(FTableView) then
  begin
    FDownMoreOption := FTableView.XYToMoreOption(X, Y);
    if Assigned(FDownMoreOption) then
    begin
      BlockUserInput := True;
      inherited;
      BlockUserInput := False;
      Invalidate;
      Exit;
    end;
  end;

  if FAnimateMoreOptions then
  begin
    FAnimateMoreOptions := False;
    FAnimateMoreOptionsClose := True;
    FMoreOffsetTo := 0;
    FAnimTimer.Enabled := True;
    inherited;
    BlockMouseClick := False;
    Exit;
  end;

  if BlockUserInput then
  begin
    inherited;
    Exit;
  end;

  it := nil;
  FMouseDownOnLookupBar := PtInRectEx(GetLookupBarRect, PointF(X, Y));
  if not FMouseDownOnLookupBar and Assigned(FTableView) and not IsAnimating then
  begin
    it := FTableView.XYToAccessoryItem(X, Y);
    FMouseDownOnAccessory := Assigned(it);
  end;

  BlockUserInput := FMouseDownOnLookupBar or FMouseDownOnAccessory;
  inherited;

  if FMouseDownOnLookupBar then
  begin
    CaptureEx;
    StopAnimationTimer;
    ProcessLookup(X, Y);
    Invalidate;
  end;

  if FMouseDownOnAccessory then
  begin
    CaptureEx;
    StopAnimationTimer;
    if Assigned(FTableView) then
      FTableView.FActiveAccessoryItem := it;
    Invalidate;
  end;

  if not FMouseDownOnAccessory and not FMouseDownOnLookupBar and (Assigned(FTableView) and FTableView.Interaction.LongPressToEdit) then
  begin
    FDownAction := False;
    FDownTimer := True;
    FDownX := X;
    FDownY := Y;
    FDownCount := 0;
    FAnimTimer.Enabled := True;
  end;
end;

procedure TTMSFNCTreeViewTableView.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
var
  a: string;
  crs: smallInt;
begin
  inherited;
  crs := Cursor;
  if FDownTimer and not (FAnimateMoreOptions or FAnimateMoreOptionsClose) then
  begin
    if (Abs(X - FDownX) > 5) or (Abs(Y - FDownY) > 5) then
    begin
      FDownAction := False;
      FDownCount := 0;
      FDownTimer := False;
      FAnimTimer.Enabled := False;
    end;
  end;

  if Assigned(FTableView) and (FTableView.MoreOptions.Count > 0) and FTableView.Interaction.SwipeToShowMoreOptions and not Assigned(FMoreOffsetItem) and IsMouseDown and
    not BlockUserInput and not BlockMouseClick and (Abs(X - FDownX) > SWIPEOFFSET) and (Abs(Y - FDownY) <= 5) then
  begin
    FMoreOffsetItem := FTableView.XYToItem(X, Y);
    if Assigned(FMoreOffsetItem) then
    begin
      if FMoreOffsetItem.EnableMoreOptions then
      begin
        FMoreOffsetTo := FTableView.GetMoreOptionsSize;
        BlockMouseClick := True;
        FAnimateMoreOptions := True;
        FAnimTimer.Enabled := True;
      end;
    end;
  end;

  a := XYToHeaderAnchor(X,Y);
  if a <> '' then
    crs := crHandPoint
  else
  begin
    a := XYToFooterAnchor(X,Y);
    if a <> '' then
      crs := crHandPoint;
  end;

  if crs <> Cursor then
    Cursor := crs;

  if FMouseDownOnLookupBar then
    ProcessLookup(X, Y);
end;

procedure TTMSFNCTreeViewTableView.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  rp: Boolean;
  mo: TTMSFNCTableViewMoreOption;
  a: string;
begin
  if Assigned(FDownMoreOption) then
  begin
    mo := FDownMoreOption;
    FDownMoreOption := nil;
    Invalidate;
    if FAnimateMoreOptions then
    begin
      FAnimateMoreOptions := False;
      FAnimateMoreOptionsClose := True;
      FMoreOffsetTo := 0;
      FAnimTimer.Enabled := True;
      inherited;
      BlockMouseClick := False;
      if Assigned(FTableView) and Assigned(FMoreOffsetItem) and Assigned(mo) then
        FTableView.DoItemMoreOptionClick(FMoreOffsetItem, mo);
      Exit;
    end;
  end;

  if FDownTimer and not (FAnimateMoreOptions or FAnimateMoreOptionsClose) then
  begin
    FDownCount := 0;
    FDownTimer := False;
    FAnimTimer.Enabled := False;
    if FDownAction then
    begin
      FDownAction := False;
      BlockUserInput := True;
      inherited;
      IsMouseDown := False;
      BlockUserInput := False;
      Exit;
    end;
  end;

  inherited;

  rp := False;
  if FMouseDownOnLookupBar then
  begin
    ReleaseCaptureEx;
    ProcessLookup(X, Y, False);
    rp := True;
  end;

  if FMouseDownOnAccessory then
  begin
    ReleaseCaptureEx;
    if Assigned(FTableView) then
    begin
      if Assigned(FTableView.FActiveAccessoryItem) then
        FTableView.DoItemAccessoryClick(FTableView.FActiveAccessoryItem);
      FTableView.FActiveAccessoryItem := nil;
    end;
    rp := True;
  end;

  if rp then
  begin
    FMouseDownOnAccessory := False;
    FMouseDownOnLookupBar := False;
    BlockUserInput := False;
  end;

  a := XYToFooterAnchor(X, Y);
  if a <> '' then
     DoFooterAnchorClick(a)
  else
  begin
    a := XYToHeaderAnchor(X, Y);
    if a <> '' then
       DoHeaderAnchorClick(a)
  end;

  Invalidate;
end;

function TTMSFNCTreeViewTableView.NeedsReload(AVerticalOffset: Single): Boolean;
begin
  Result := inherited NeedsReload(AVerticalOffset);
  if Assigned(FTableView) and FTableView.Reload.Enabled and not Assigned(FTableView.FActiveDetailControl) and not FTableView.FEditMode and not FTableView.FFilterActive then
    Result := AVerticalOffset >= FTableView.Reload.Offset;
end;

procedure TTMSFNCTreeViewTableView.OffsetNodeRects(
  ANode: TTMSFNCTreeViewVirtualNode; AX, AY: Single; var ARect: TRectF);
var
  i: Integer;
begin
  if not Assigned(FFloatingNodes) then
  begin
    inherited;
    Exit;
  end;

  i := FFloatingNodes.IndexOf(ANode);
  if i > -1 then
    inherited OffsetNodeRects(ANode, AX, Max(0, AY), ARect)
  else
  begin
    if (FMoreOffsetItem = FTableView.GetItemForNode(ANode)) then
      inherited OffsetNodeRects(ANode, AX - FMoreOffset, AY, ARect)
    else
      inherited;
  end;
end;

procedure TTMSFNCTreeViewTableView.ProcessLookup(X, Y: Single;
  DoLookup: Boolean);
var
  l: Integer;
  ch: String;
  catid: Integer;
  I: Integer;
begin
  if Assigned(FTableView) then
  begin
    case FTableView.CategoryType of
      tvctCustom:
      begin
        l := GetCategoryAtXY(X, Y);
        if (l > -1) and (l < FTableView.Categories.Count) then
        begin
          catid := FTableView.Categories[l].Id;
          if FTableView.FCustomChar.IsIDActive(catID) then
          begin
            if DoLookup then
              FTableView.LookupCustomCategory(catid)
            else if Assigned(FTableView.OnCategoryClick) then
              FTableView.OnCategoryClick(Self, '', catid);
          end;
        end;
      end;
      tvctAlphaBetic, tvctAlphaNumericFirst, tvctAlphaNumericLast:
      begin
        l := 0;
        for I := 0 to FTableView.FLookupBarDisplayList.Count - 1 do
        begin
          if PtInRectEx(FTableView.FLookupBarDisplayList[I].Rect, PointF(X, Y)) then
          begin
            l := I + 1;
            Break;
          end;
        end;

        if (l >= 1) and (l <= FTableView.GetCharacterCount) then
        begin
          if Assigned(FTableView) then
          begin
            ch := FTableView.GetCharacter(l);
            if (ch <> '') then
            begin
              if DoLookup then
                FTableView.LookupCategory(ch)
              else if Assigned(FTableView.OnCategoryClick) then
                FTableView.OnCategoryClick(Self, ch, -1);
            end;
          end;
        end;
      end;
    end;
  end;
end;

function TTMSFNCTreeViewTableView.ScrollLimitation: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCTreeViewTableView.StartReload;
begin
  inherited;
  FReloadActive := True;
  BlockUserInput := True;
  if Assigned(FTableView) then
  begin
    FManualProgressSweepAngle := 0;
    FManualProgressStartAngle := 0;
    FAnimTimer.Enabled := (FTableView.Reload.ProgressMode <> tvrpmManual);
    FTableView.DoStartReload;
  end;
end;

procedure TTMSFNCTreeViewTableView.UpdateCheckState(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AChecked: Boolean);
var
  dp: TTMSFNCTableViewDisplayItem;
begin
  inherited;
  if not Assigned(FTableView) then
    Exit;

  if (ANode.Row >= 0) and (ANode.Row <= FTableView.FDisplayList.Count - 1) then
  begin
    dp := FTableView.FDisplayList[ANode.Row];
    case dp.Kind of
      tvikItem:
      begin
        if Assigned(dp.Item) then
          dp.Item.FChecked := AChecked;

        FTableView.SelectAllCheckedItems;
      end;
      tvikCategory: ;
    end;
  end;
end;

function TTMSFNCTreeViewTableView.XYToHeaderAnchor(X, Y: Single): string;
var
  r : TRectF;
  g: TTMSFNCGraphics;
begin
  Result := '';

  r := RectF(0,0, Width, FTableView.Header.Height);

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BitmapContainer := BitmapContainer;
  try
    Result := g.DrawText(r, FTableView.Header.Text, FTableView.Header.WordWrapping, FTableView.Header.HorizontalTextAlign , FTableView.Header.VerticalTextAlign, FTableView.Header.Trimming, 0, -1, -1, True, True, X, Y);
  finally
    g.Free;
  end;
end;

function TTMSFNCTreeViewTableView.XYToFooterAnchor(X, Y: Single): string;
var
  r : TRectF;
  g: TTMSFNCGraphics;
begin
  Result := '';

  r := RectF(0, Height - FTableView.Footer.Height, Width, Height);

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BitmapContainer := BitmapContainer;
  try
    Result := g.DrawText(r, FTableView.Footer.Text, FTableView.Footer.WordWrapping, FTableView.Footer.HorizontalTextAlign , FTableView.Footer.VerticalTextAlign, FTableView.Footer.Trimming, 0, -1, -1, True, True, X, Y);
  finally
    g.Free;
  end;
end;

{ TTMSFNCTableViewCategory }

procedure TTMSFNCTableViewCategory.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTableViewCategory) then
  begin
    FText := (Source as TTMSFNCTableViewCategory).Text;
    FLookupText := (Source as TTMSFNCTableViewCategory).LookupText;
    FId := (Source as TTMSFNCTableViewCategory).Id;
    Changed;
  end;
end;

procedure TTMSFNCTableViewCategory.Changed;
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

constructor TTMSFNCTableViewCategory.Create(ACollection: TCollection);
begin
  inherited;
  FTableView := (Collection as TTMSFNCTableViewCategories).FTableView;
  FID := (Collection as TTMSFNCTableViewCategories).Count - 1;
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

destructor TTMSFNCTableViewCategory.Destroy;
begin
  inherited;
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

function TTMSFNCTableViewCategory.GetStrippedHTMLText: String;
begin
  Result := TTMSFNCUtils.HTMLStrip(Text);
end;

procedure TTMSFNCTableViewCategory.SetId(const Value: integer);
begin
  if FId <> Value then
  begin
    FId := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewCategory.SetLookupText(const Value: String);
begin
  if FLookupText <> value then
  begin
    FLookupText := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewCategory.SetText(const Value: String);
begin
  if FText <> value then
  begin
    FText := Value;
    Changed;
  end;
end;

{ TTMSFNCTableViewCategories }

function TTMSFNCTableViewCategories.Add: TTMSFNCTableViewCategory;
begin
  Result := TTMSFNCTableViewCategory(inherited Add);
end;

procedure TTMSFNCTableViewCategories.Clear;
var
  it: TTMSFNCTableViewCategory;
begin
  if Count > 0 then
  begin
    {$IFNDEF LCLWEBLIB}
    while Count > 0 do
    begin
      it := Items[Count - 1];
      it.DisposeOf;
    end;
    {$ENDIF}
    {$IFDEF LCLWEBLIB}
    while Count > 0 do
    begin
      it := Items[Count - 1];
      it.Free;
    end;
    {$ENDIF}
  end;
end;

function TTMSFNCTableViewCategories.Compare(AItem1,
  AItem2: TTMSFNCTableViewCategory; ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCTableViewCategoriesSortMode): Integer;
var
  l: TTMSFNCCustomTableView;
begin
  Result := 0;

  l := FTableView;
  if not Assigned(l) then
    Exit;

  if not ACaseSensitive then
    Result := AnsiCompareStr(UpperCase(AItem1.StrippedHTMLText), UpperCase(AItem2.StrippedHTMLText))
  else
    Result := AnsiCompareStr(AItem1.StrippedHTMLText, AItem2.StrippedHTMLText);

  case ASortingMode of
    tvcsmDescending: Result := Result * -1;
  end;

  l.DoCategoryCompare(AItem1, AItem2, Result);
end;

constructor TTMSFNCTableViewCategories.Create(ATableView: TTMSFNCCustomTableView);
begin
  inherited Create(ATableView, TTMSFNCTableViewCategory);
  FTableView := ATableView;
end;

procedure TTMSFNCTableViewCategories.Delete(Index: Integer);
var
  it: TTMSFNCTableViewCategory;
begin
  it := Items[Index];
  it.Free;
end;

function TTMSFNCTableViewCategories.GetItem(
  Index: Integer): TTMSFNCTableViewCategory;
begin
  Result := TTMSFNCTableViewCategory(inherited Items[Index]);
end;

function TTMSFNCTableViewCategories.Insert(
  Index: Integer): TTMSFNCTableViewCategory;
begin
  Result := TTMSFNCTableViewCategory(inherited Insert(Index));
end;

function TTMSFNCTableViewCategories.ItemById(
  id: integer): TTMSFNCTableViewCategory;
var
  i: integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    Result := Items[I];
    if Result.ID = id then
      break;
  end;
end;

function TTMSFNCTableViewCategories.ItemIndexByID(id: integer): integer;
var
  ci: TTMSFNCTableViewCategory;
begin
  ci := ItemByID(id);
  if Assigned(ci) then
    result := ci.Index
  else
    result := -1;
end;

procedure TTMSFNCTableViewCategories.QuickSort(L: Integer; R: Integer; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewCategoriesSortMode = tvcsmAscending);
var
  I, J, p: Integer;
  {$IFDEF LCLWEBLIB}
  SortList: TFPList;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    {$IFDEF WEBLIB}
    Save: JSValue;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    Save: Pointer;
    {$ENDIF}
  begin
    Save := SortList.Items[Index1];
    SortList.Items[Index1] := SortList.Items[Index2];
    SortList.Items[Index2] := Save;
  end;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  SortList: TList<TCollectionItem>;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    Save: TCollectionItem;
  begin
    Save := SortList[Index1];
    SortList[Index1] := SortList[Index2];
    SortList[Index2] := Save;
    Save.Index := Index2;
  end;
  {$ENDIF}
begin
  //This cast allows us to get at the private elements in the base class
  {$IFNDEF WEBLIB}
  SortList := {%H-}TShadowedCollection(Self).FItems;
  {$ENDIF}
  {$IFDEF WEBLIB}
  SortList := Self.FPList;
  {$ENDIF}

  repeat
    I := L;
    J := R;
    P := (L + R) shr 1;
    repeat
      while Compare(Items[I], Items[P], ACaseSensitive, ASortingMode) < 0 do
        Inc(I);
      while Compare(Items[J], Items[P], ACaseSensitive, ASortingMode) > 0 do
        Dec(J);
      if I <= J then
      begin
        ExchangeItems(I, J);
        if P = I then
          P := J
        else if P = J then
          P := I;
        Inc(I);
        Dec(J);
      end;
    until I > J;
    if L < J then
      QuickSort(L, J, ACaseSensitive, ASortingMode);
    L := I;
  until I >= R;
end;

procedure TTMSFNCTableViewCategories.SetItem(Index: Integer;
  const Value: TTMSFNCTableViewCategory);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCTableViewCategories.Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTableViewCategoriesSortMode = tvcsmAscending);
var
  l: TTMSFNCCustomTableView;
begin
  l := FTableView;
  if Assigned(l) then
  begin
    l.BeginUpdate;
    BeginUpdate;
    if Count > 1 then
      QuickSort(0, Pred(Count), ACaseSensitive, ASortingMode);
    EndUpdate;
    l.EndUpdate;
  end;
end;

{ TTMSFNCTableViewMoreOption }

procedure TTMSFNCTableViewMoreOption.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTableViewMoreOption) then
  begin
    FText := (Source as TTMSFNCTableViewMoreOption).Text;
    FWidth := (Source as TTMSFNCTableViewMoreOption).Width;
    FColor := (Source as TTMSFNCTableViewMoreOption).Color;
    FBorderColor := (Source as TTMSFNCTableViewMoreOption).BorderColor;
    FFontColor := (Source as TTMSFNCTableViewMoreOption).FontColor;
  end;
end;

procedure TTMSFNCTableViewMoreOption.Changed;
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

constructor TTMSFNCTableViewMoreOption.Create(ACollection: TCollection);
begin
  inherited;
  FTableView := (Collection as TTMSFNCTableViewMoreOptions).FTableView;
  FWidth := 60;
  FColor := gcNull;
  FBorderColor := gcNull;
  FFontColor := gcNull;
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

destructor TTMSFNCTableViewMoreOption.Destroy;
begin
  inherited;
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

function TTMSFNCTableViewMoreOption.GetStrippedHTMLText: String;
begin
  Result := TTMSFNCUtils.HTMLStrip(Text);
end;

function TTMSFNCTableViewMoreOption.IsWidthStored: Boolean;
begin
  Result := Width <> 60;
end;

procedure TTMSFNCTableViewMoreOption.SetBorderColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBorderColor <> Value then
  begin
    FBorderColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewMoreOption.SetColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewMoreOption.SetFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FFontColor <> Value then
  begin
    FFontColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewMoreOption.SetText(const Value: String);
begin
  if FText <> value then
  begin
    FText := Value;
    Changed;
  end;
end;

procedure TTMSFNCTableViewMoreOption.SetWidth(const Value: Single);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    Changed;
  end;
end;

{ TTMSFNCTableViewMoreOptions }

function TTMSFNCTableViewMoreOptions.Add: TTMSFNCTableViewMoreOption;
begin
  Result := TTMSFNCTableViewMoreOption(inherited Add);
end;

procedure TTMSFNCTableViewMoreOptions.Clear;
var
  it: TTMSFNCTableViewMoreOption;
begin
  if Count > 0 then
  begin
    {$IFNDEF LCLWEBLIB}
    while Count > 0 do
    begin
      it := Items[Count - 1];
      it.DisposeOf;
    end;
    {$ENDIF}
    {$IFDEF LCLWEBLIB}
    while Count > 0 do
    begin
      it := Items[Count - 1];
      it.Free;
    end;
    {$ENDIF}
  end;
end;

constructor TTMSFNCTableViewMoreOptions.Create(ATableView: TTMSFNCCustomTableView);
begin
  inherited Create(ATableView, TTMSFNCTableViewMoreOption);
  FTableView := ATableView;
end;

procedure TTMSFNCTableViewMoreOptions.Delete(Index: Integer);
var
  it: TTMSFNCTableViewMoreOption;
begin
  it := Items[Index];
  it.Free;
end;

function TTMSFNCTableViewMoreOptions.GetItem(
  Index: Integer): TTMSFNCTableViewMoreOption;
begin
  Result := TTMSFNCTableViewMoreOption(inherited Items[Index]);
end;

function TTMSFNCTableViewMoreOptions.Insert(
  Index: Integer): TTMSFNCTableViewMoreOption;
begin
  Result := TTMSFNCTableViewMoreOption(inherited Insert(Index));
end;

function TTMSFNCTableViewMoreOptions.ItemById(
  id: integer): TTMSFNCTableViewMoreOption;
var
  i: integer;
begin
  Result := nil;
  for I := 0 to Count - 1 do
  begin
    Result := Items[I];
    if Result.ID = id then
      break;
  end;
end;

function TTMSFNCTableViewMoreOptions.ItemIndexByID(id: integer): integer;
var
  ci: TTMSFNCTableViewMoreOption;
begin
  ci := ItemByID(id);
  if Assigned(ci) then
    Result := ci.Index
  else
    Result := -1;
end;

procedure TTMSFNCTableViewMoreOptions.SetItem(Index: Integer;
  const Value: TTMSFNCTableViewMoreOption);
begin
  inherited Items[Index] := Value;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCTableViewDisplayItem.=(z1, z2: TTMSFNCTableViewDisplayItem)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCTableViewLookupBarDisplayItem.=(z1, z2: TTMSFNCTableViewLookupBarDisplayItem)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{ TTMSFNCTableViewLookupBar }

procedure TTMSFNCTableViewLookupBar.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTableViewLookupBar) then
  begin
    FVisible := (Source as TTMSFNCTableViewLookupBar).Visible;
    FFont.Assign((Source as TTMSFNCTableViewLookupBar).Font);
    FInActiveFont.Assign((Source as TTMSFNCTableViewLookupBar).InActiveFont);
    FStroke.Assign((Source as TTMSFNCTableViewLookupBar).Stroke);
    FFill.Assign((Source as TTMSFNCTableViewLookupBar).Fill);
    FAutoLookup := (Source as TTMSFNCTableViewLookupBar).AutoLookup;
    FWidth := (Source as TTMSFNCTableViewLookupBar).Width;
    FPosition := (Source as TTMSFNCTableViewLookupBar).Position;
    FDownFill.Assign((Source as TTMSFNCTableViewLookupBar).DownFill);
    FDownStroke.Assign((Source as TTMSFNCTableViewLookupBar).DownStroke);
  end;
end;

constructor TTMSFNCTableViewLookupBar.Create(
  ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  FWidth := 25;
  FVisible := True;
  FPosition := tvlbpRight;
  FAutoLookup := True;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FInActiveFont := TTMSFNCGraphicsFont.Create;
  if Assigned(FTableView) and FTableView.IsDesignTime then
    FInActiveFont.Color := gcSilver;
  FInActiveFont.OnChanged := @FontChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcWhite);
  FStroke.OnChanged := @StrokeChanged;

  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(249, 249, 249));
  FDownFill.OnChanged := @FillChanged;
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, MakeGraphicsColor(249, 249, 249));
  FDownStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCTableViewLookupBar.Destroy;
begin
  FFont.Free;
  FInActiveFont.Free;
  FFill.Free;
  FStroke.Free;
  FDownFill.Free;
  FDownStroke.Free;
  inherited;
end;

procedure TTMSFNCTableViewLookupBar.FillChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

procedure TTMSFNCTableViewLookupBar.FontChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

function TTMSFNCTableViewLookupBar.IsWidthStored: Boolean;
begin
  Result := Width <> 25;
end;

procedure TTMSFNCTableViewLookupBar.SetAutoLookup(const Value: Boolean);
begin
  FAutoLookup := Value;
end;

procedure TTMSFNCTableViewLookupBar.SetDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetInActiveFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FInActiveFont.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetPosition(
  const Value: TTMSFNCTableViewLookupBarPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewLookupBar.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCTableViewLookupBar.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewLookupBar.SetWidth(const Value: Single);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    if Assigned(FTableView) then
      FTableView.UpdateTableView;
  end;
end;

procedure TTMSFNCTableViewLookupBar.StrokeChanged(Sender: TObject);
begin
  if Assigned(FTableView) then
    FTableView.UpdateTableView;
end;

{ TTMSFNCTableViewReload }

procedure TTMSFNCTableViewReload.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTableViewReload then
  begin
    FOffset := (Source as TTMSFNCTableViewReload).Offset;
    FSize := (Source as TTMSFNCTableViewReload).Size;
    FEnabled := (Source as TTMSFNCTableViewReload).Enabled;
    FStroke.Assign((Source as TTMSFNCTableViewReload).Stroke);
    FProgressMode := (Source as TTMSFNCTableViewReload).ProgressMode;
  end;
end;

constructor TTMSFNCTableViewReload.Create(ATableView: TTMSFNCCustomTableView);
begin
  FTableView := ATableView;
  FOffset := 40;
  FSize := 20;
  FEnabled := False;
  FProgressMode := tvrpmMarquee;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  if Assigned(FTableView) and FTableView.IsDesignTime then
    FStroke.Width := 2;
end;

destructor TTMSFNCTableViewReload.Destroy;
begin
  FStroke.Free;
  inherited;
end;

function TTMSFNCTableViewReload.IsOffsetStored: Boolean;
begin
  Result := Offset <> 40;
end;

function TTMSFNCTableViewReload.IsSizeStored: Boolean;
begin
  Result := Size <> 20;
end;

procedure TTMSFNCTableViewReload.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
  end;
end;

procedure TTMSFNCTableViewReload.SetOffset(const Value: Single);
begin
  if FOffset <> Value then
  begin
    FOffset := Value;
  end;
end;

procedure TTMSFNCTableViewReload.SetProgressMode(
  const Value: TTMSFNCTableViewReloadProgressMode);
begin
  if FProgressMode <> Value then
  begin
    FProgressMode := Value;
  end;
end;

procedure TTMSFNCTableViewReload.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
  end;
end;

procedure TTMSFNCTableViewReload.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

{$IFDEF WEBLIB}
function TTMSFNCTreeViewTableViewFloatingNodes.GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := TTMSFNCTreeViewVirtualNode(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewTableViewFloatingNodes.SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTableViewLookupBarDisplayList.GetItem(Index: Integer): TTMSFNCTableViewLookupBarDisplayItem;
begin
  Result := TTMSFNCTableViewLookupBarDisplayItem(inherited Items[Index]);
end;

procedure TTMSFNCTableViewLookupBarDisplayList.SetItem(Index: Integer; const Value: TTMSFNCTableViewLookupBarDisplayItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTableViewDisplayList.GetItem(Index: Integer): TTMSFNCTableViewDisplayItem;
begin
  Result := TTMSFNCTableViewDisplayItem(inherited Items[Index]);
end;

procedure TTMSFNCTableViewDisplayList.SetItem(Index: Integer; const Value: TTMSFNCTableViewDisplayItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTableViewCustomCharList.GetItem(Index: Integer): TTMSFNCTableViewCustomChar;
begin
  Result := TTMSFNCTableViewCustomChar(inherited Items[Index]);
end;

procedure TTMSFNCTableViewCustomCharList.SetItem(Index: Integer; const Value: TTMSFNCTableViewCustomChar);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCTableViewCustomChar }

constructor TTMSFNCTableViewCustomChar.Create(AID: Integer; AActive: Boolean);
begin
  FID := AID;
  FActive := AActive;
end;

{ TTMSFNCTableViewCustomCharList }

procedure TTMSFNCTableViewCustomCharList.ActivateID(AID: Integer);
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
  begin
    if Items[I].ID = AID then
    begin
      Items[I].Active := True;
      Break;
    end;
  end;
end;

function TTMSFNCTableViewCustomCharList.IsIDActive(AID: Integer): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    if Items[I].ID = AID then
    begin
      Result := Items[I].Active;
      Break;
    end;
  end;
end;


end.
