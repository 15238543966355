unit unit_Einzeltermin;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, strutils, WEBLib.Buttons,
  WEBLib.TMSFNCDateTimePicker, WEBLib.WebCtrls;

type
  TfrmEinzeltermin = class(TForm)
    myDataSet: TXDataWebDataSet;
    Source: TDataSource;
    myDataSetArtikelnummer: TXDataWebEntityField;
    myDataSetUID: TStringField;
    myDataSetBeginn: TDateTimeField;
    myDataSetEnde: TDateTimeField;
    myDataSetBetreff: TStringField;
    myDataSetTerminNotiz: TStringField;
    myDataSetQuelle: TStringField;
    myDataSetGUIDOutlook: TStringField;
    myDataSetShape: TIntegerField;
    myDataSetColor: TIntegerField;
    myDataSetAlarm: TBooleanField;
    myDataSetResource: TIntegerField;
    myDataSetLastModificationTime: TDateTimeField;
    myDataSetGeburtstag: TBooleanField;
    myDataSetAlarmBefore: TIntegerField;
    myDataSetPersonalnummer: TIntegerField;
    myDataSetKundennummer: TStringField;
    myDataSetAllDayEventOutlook: TBooleanField;
    myDataSetTMSCaption: TBooleanField;
    myDataSetRECURRENCY: TStringField;
    myDataSetMINTIME: TDateTimeField;
    myDataSetMAXTIME: TDateTimeField;
    myDataSetNachname: TStringField;
    myDataSetObjektReferenz: TStringField;
    myDataSetObjektnummer: TStringField;
    myDataSetTerminArt: TIntegerField;
    myDataSetBeginnDatum: TDateTimeField;
    myDataSetBeginnUhrzeit: TDateTimeField;
    myDataSetEndeDatum: TDateTimeField;
    myDataSetEndeUhrzeit: TDateTimeField;
    myDataSetKalenderAnzeige: TBooleanField;
    myDataSetStundensatz: TFloatField;
    myDataSetDauerStunden: TFloatField;
    myDataSetgefahrenekm: TFloatField;
    myDataSetAufwand: TFloatField;
    myDataSetabgerechnet: TBooleanField;
    myDataSetverteilt: TDateTimeField;
    myDataSetTerminErledigt: TDateTimeField;
    myDataSetAnsprechpartner: TStringField;
    myDataSetLocation: TStringField;
    myDataSetGoogleID: TStringField;
    myDataSetGoogleCreatedUTC: TDateTimeField;
    myDataSetGoogleUpdatedUTC: TDateTimeField;
    myDataSetGoogleStatus: TStringField;
    myDataSetVorname: TStringField;
    myDataSetPNachname: TStringField;
    myDataSetPVorname: TStringField;
    myDataSetArtikelueberschrift: TStringField;
    myDataSetPFarbe: TIntegerField;
    myDataSetKontaktart: TStringField;
    dsArtikel: TXDataWebDataSet;
    dsArtikelArtikelnummer: TStringField;
    dskontakt: TXDataWebDataSet;
    dskontaktKundennummer: TStringField;
    dskontaktNachname: TStringField;
    dskontaktVorname: TStringField;
    dskontaktAdresszeile2: TStringField;
    dskontaktAdresszeile3: TStringField;
    dskontaktStrasse: TStringField;
    dskontaktPostleitzahl: TStringField;
    dskontaktOrt: TStringField;
    dskontaktLaenderkuerzel: TStringField;
    dskontaktLand: TStringField;
    dskontaktKilometer: TIntegerField;
    dskontaktStundensatz: TFloatField;
    dsKArten: TXDataWebDataSet;
    dsKArtenKontaktart: TStringField;
    dsKArtenArtikelnummer: TStringField;
    dsObjekt: TXDataWebDataSet;
    dsObjektObjektReferenz: TStringField;
    dsObjektKurzbezeichnung: TStringField;
    dsObjektObjektnummer: TStringField;
    dsAP: TXDataWebDataSet;
    dsAPKundennummer: TXDataWebEntityField;
    dsAPAnsprechpartner: TStringField;
    dsAPNachname: TStringField;
    dsAPVorname: TStringField;
    Container1: THTMLContainer;
    btnObjektwahl: TButton;
    btnzurueck: TButton;
    btnSpeichern: TButton;
    Container2: THTMLContainer;
    btnWV: TButton;
    btnAktivitaet: TButton;
    comboKontaktart: TDBComboBox;
    lblTerminart: TLabel;
    Container3: THTMLContainer;
    lblBeginn: TLabel;
    lblEnde: TLabel;
    lblBetreff: TLabel;
    lblNotiz: TLabel;
    PickDateBeginn: TDBDateTimePicker;
    pickDateEnde: TDBDateTimePicker;
    PickTimeBeginn: TDBDateTimePicker;
    picktimeEnde: TDBDateTimePicker;
    edtBetreff: TDBEdit;
    edtNotiz: TMemo;
    ContainerBeginn: THTMLContainer;
    ContainerEnde: THTMLContainer;
    Container4: THTMLContainer;
    Container5: THTMLContainer;
    Container6: THTMLContainer;
    btnKontaktZuordnen: TButton;
    Container7: THTMLContainer;
    Container8: THTMLContainer;
    comboap: TDBComboBox;
    lblAnsprechpartner: TLabel;
    lblort: TLabel;
    edtOrt: TDBEdit;
    lblkm: TLabel;
    edtkm: TDBEdit;
    Container9: THTMLContainer;
    Container10: THTMLContainer;
    lblMitarbeiter: TLabel;
    Container11: THTMLContainer;
    btnTerminerledigt: TButton;
    edtTerminerledigt: TDBEdit;
    edtAlarmBefore: TDBSpinEdit;
    edtErinnerung: TDBCheckBox;
    WebLabel2: TLabel;
    Container12: THTMLContainer;
    lblKontakt: TLabel;
    XDSlookupObjekt: TXDataWebDataSet;
    XDSlookupObjektObjektReferenz: TStringField;
    XDSlookupObjektKurzbezeichnung: TStringField;
    XDSlookupObjektObjektnummer: TStringField;
    DivTextIntern: THTMLDiv;
    DivTextObjekt: THTMLDiv;
    DivTextAP: THTMLDiv;

    procedure btncloseClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure myDataSetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure myDataSetBeforePost(DataSet: TDataSet);
    procedure btnKontaktZuordnenClick(Sender: TObject);
    procedure myDataSetAfterOpen(DataSet: TDataSet);
    procedure myDataSetNewRecord(DataSet: TDataSet);
    procedure myDataSetAfterPost(DataSet: TDataSet);
    procedure dskontaktAfterOpen(DataSet: TDataSet);
    procedure edtNotizEnter(Sender: TObject);
    procedure btnObjektwahlClick(Sender: TObject);
    procedure comboAPEnter(Sender: TObject);
    procedure dsAPAfterOpen(DataSet: TDataSet);
    procedure dsKArtenAfterOpen(DataSet: TDataSet);
    procedure myDataSetBeforeEdit(DataSet: TDataSet);
    procedure btnWVClick(Sender: TObject);
    procedure btnaktivitaetClick(Sender: TObject);
    procedure dsArtikelAfterOpen(DataSet: TDataSet);
    procedure btnzurueckClick(Sender: TObject);
    procedure SourceStateChange(Sender: TObject);
    procedure btnSpeichernClick(Sender: TObject);
    procedure btnTerminerledigtClick(Sender: TObject);
    procedure XDSlookupObjektAfterOpen(DataSet: TDataSet);
    procedure dsObjektAfterOpen(DataSet: TDataSet);
  protected
    procedure LoadDFMValues; override;

    public
    TerminUID:string;
    NeuStartDateTime:TDateTime;
    NeuEndDateTime:TDateTime;
    procedure setcontrols;
  end;

var
  frmEinzeltermin: TfrmEinzeltermin;

implementation
  uses unit1, unit2, unit_Kontaktsuche, unit_Artikelsuche, unit_Objektsuche;


procedure TfrmEinzeltermin.setcontrols;
begin
  lblMitarbeiter.Caption:='Mitarbeiter <b>'+trim(myDataSetPVorname.AsString+' '+myDataSetPNachname.AsString)+' ('+myDataSetPersonalnummer.asstring+')';
  divtextintern.HTML.text:='<small><i>geändert: '+formatdatetime('DD.MM.YYYY hh:mm:ss',myDataSetLastModificationTime.AsDateTime)+'<br>ID: '+myDataSetUID.AsString+'<br>Google-ID: '+myDataSetGoogleID.AsString;
  lblkontakt.Caption:=myDataSetKundennummer.AsString+' '+trim(myDataSetVorname.AsString+' '+myDataSetNachname.asstring);
  if myDataSetAnsprechpartner.AsString<>'' then
  begin
    DivTextAP.HTML.text:=myDataSetAnsprechpartner.AsString;
  end
  else
  begin
    DivTextAP.HTML.text:='<small><i>kein AP zugeordnet';
  end;

  if myDataSetObjektReferenz.AsString<>'' then
  begin
    xdslookupobjekt.Close;
    xdslookupobjekt.QueryString:='$filter=Objektreferenz eq '+QuotedStr(myDataSetObjektReferenz.asstring);
    xdslookupobjekt.Load;

  end
  else
  begin
    divtextobjekt.HTML.text:='<small><i>kein Objekt zugeordnet';
  end;
end;

procedure TfrmEinzeltermin.btncloseClick(Sender: TObject);
begin
  self.Close;
end;

procedure TfrmEinzeltermin.btnKontaktZuordnenClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
      lform.Kontaktnummer:='';
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if lfrm.Kontaktnummer<>'' then
      begin
        dskontakt.Close;
        dskontakt.QueryString:='$filter=Kundennummer eq '+QuotedStr(lfrm.kontaktnummer);
        dskontakt.Load;
      end;


    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TfrmEinzeltermin.btnObjektwahlClick(Sender: TObject);
var
  LFrm:TfrmObjektsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmObjektsuche;
    begin
      LForm:=(AForm as TfrmObjektsuche);
      LForm.Objektreferenz:='';


    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if lfrm.Objektreferenz<>'' then
      begin
        dsobjekt.Close;
        dsobjekt.QueryString:='$filter=Objektreferenz eq '+QuotedStr(lfrm.Objektreferenz);
        dsobjekt.Load;
      end;


    end;

  begin
  LFrm:=TfrmObjektsuche.CreateNew(@AfterCreate);
 // LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TfrmEinzeltermin.btnSpeichernClick(Sender: TObject);
begin
  mydataset.Post;
end;

procedure TfrmEinzeltermin.btnTerminerledigtClick(Sender: TObject);
begin
  mydataset.Edit;
  myDataSetTerminErledigt.AsDateTime:=Date;
end;

procedure TfrmEinzeltermin.btnWVClick(Sender: TObject);
begin
  if myDataSetKundennummer.AsString='' then
  begin
    showmessage('Es ist kein Kontakt zugeordnet');
    exit;
  end;
  mydataset.Edit;
  myDataSetTerminArt.AsInteger:=1;
  mydataset.Post;
  lblTerminart.Caption:='Wiedervorlage';
  comboKontaktart.enabled:=false;
  btnWV.enabled:=false;
end;

procedure TfrmEinzeltermin.btnzurueckClick(Sender: TObject);
begin
  self.Close;
end;

procedure TfrmEinzeltermin.comboAPEnter(Sender: TObject);
begin
  dsap.Close;
  comboAP.Items.Clear;
  if mydatasetkundennummer.AsString='' then
  begin
    showmessage('Es ist kein Kontakt zugeordnet');
  end
  else
  begin
    dsap.QueryString:='$filter=Kundennummer eq '+quotedstr(myDataSetKundennummer.AsString);
    dsap.Load;
  end;
end;

procedure TfrmEinzeltermin.dsAPAfterOpen(DataSet: TDataSet);
begin
  dsap.First;
  while not dsap.eof do
  begin
    comboAP.Items.Add(dsAPAnsprechpartner.asstring);
    dsap.Next;
  end;
end;

procedure TfrmEinzeltermin.dsArtikelAfterOpen(DataSet: TDataSet);
begin
  dsKArten.load;
end;

procedure TfrmEinzeltermin.dsKArtenAfterOpen(DataSet: TDataSet);
begin
  combokontaktart.Items.Clear;
  dskarten.First;
  while not dskarten.eof do
  begin
    combokontaktart.Items.Add(dsKArtenKontaktart.asstring);
    dskarten.Next;
  end;
  if TerminUID='' then
  begin
    myDataSet.Close;
    myDataSet.QueryString:='$expand=Artikelnummer&$orderby=LastModificationTime DESC';
    myDataset.QueryTop:=1;
    myDataSet.Load;
  end
  else
  begin
    myDataSet.Close;
    myDataSet.QueryString:='$expand=Artikelnummer&$filter=UID eq '+quotedstr(TerminUID);
    myDataSet.Load;
  end;
end;

procedure TfrmEinzeltermin.dskontaktAfterOpen(DataSet: TDataSet);
begin
  mydataset.Edit;
  myDataSetKundennummer.AsString:=dskontaktKundennummer.asstring;
  myDataSetNachname.AsString:=dskontaktNachname.AsString;
  myDataSetVorname.AsString:=dskontaktVorname.AsString;
  if mydatasetlocation.AsString='' then mydatasetLocation.AsString:=dskontaktLaenderkuerzel.AsString+'-'+dskontaktPostleitzahl.AsString+' '+dskontaktOrt.AsString+', '+dskontaktStrasse.AsString;
  myDataSetgefahrenekm.AsFloat:=dskontaktKilometer.AsFloat*2;
  myDataSetStundensatz.AsFloat:=dskontaktStundensatz.AsFloat;
  dskontakt.Close;
  setcontrols;
end;

procedure TfrmEinzeltermin.dsObjektAfterOpen(DataSet: TDataSet);
begin
   myDataSet.Edit;
   myDataSetObjektReferenz.AsString:=dsObjektObjektReferenz.AsString;
   myDataSetObjektnummer.AsString:=dsObjektObjektnummer.AsString;
   setcontrols;
end;

procedure TfrmEinzeltermin.myDataSetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  if info.Records[0].Error='' then weblabel2.caption:='gespeichert' else weblabel2.caption:=info.Records[0].Error;
  setcontrols;
end;

procedure TfrmEinzeltermin.myDataSetAfterOpen(DataSet: TDataSet);
begin
  if TerminUID='' then
  begin
    mydataset.insert;
  end;
  if TerminUID<>'' then
  begin
    edtNotiz.Text:=myDataSetTerminNotiz.AsString;
  end;
  if myDataSetTerminArt.AsInteger=0 then
  begin
    lblTerminart.Caption:='<b>Termin';
    comboKontaktart.enabled:=false;
    btnWV.enabled:=true;
    btnaktivitaet.enabled:=true;
  end;
  if myDataSetTerminArt.AsInteger=1 then
  begin
    lblTerminart.Caption:='<b>Wiedervorlage';
    comboKontaktart.enabled:=false;
    btnWV.enabled:=false;
    btnaktivitaet.enabled:=true;
  end;
  if myDataSetTerminArt.AsInteger=2 then
  begin
    lblTerminart.Caption:='<b>Geburtstag';
    comboKontaktart.enabled:=false;
    btnWV.enabled:=false;
    btnaktivitaet.enabled:=false;
    btnObjektwahl.Enabled:=false;
  end;
  if myDataSetTerminArt.AsInteger=3 then
  begin
    lblTerminart.Caption:='<b>Aktivität';
    comboKontaktart.enabled:=true;
    btnWV.enabled:=false;
    btnaktivitaet.enabled:=false;
  end;
  if myDataSetTerminArt.AsInteger=4 then
  begin
    lblTerminart.Caption:='<b>unklar';
    comboKontaktart.enabled:=false;
    btnWV.enabled:=false;
    btnaktivitaet.enabled:=false;
    btnobjektwahl.Enabled:=false;
  end;
  if myDataSetTerminArt.AsInteger=5 then
  begin
    lblTerminart.Caption:='<b>Feiertag';
    comboKontaktart.enabled:=false;
    btnWV.enabled:=false;
    btnaktivitaet.enabled:=false;
    btnobjektwahl.Enabled:=false;
  end;
  setcontrols;
  edtBetreff.SetFocus;
end;

procedure TfrmEinzeltermin.myDataSetAfterPost(DataSet: TDataSet);
begin
  setcontrols;
  weblabel2.caption:='wird gespeichert';
  if LizenzOK then
  begin
   mydataset.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TfrmEinzeltermin.myDataSetBeforeEdit(DataSet: TDataSet);
begin
  if myDataSetTerminArt.AsInteger=2 then dataset.Cancel;
  if myDataSetTerminArt.AsInteger=4 then dataset.Cancel;
  if myDataSetTerminArt.AsInteger=5 then dataset.Cancel;
end;

procedure TfrmEinzeltermin.myDataSetBeforePost(DataSet: TDataSet);
begin
  myDataSetTerminNotiz.AsString:=edtnotiz.Text;
  if myDataSetAllDayEventOutlook.AsBoolean then
  begin
    if myDataSetendedatum.asdatetime<myDataSetbeginndatum.asdatetime then myDataSetendedatum.asdatetime:=myDataSetbeginndatum.asdatetime;
    myDataSetbeginnuhrzeit.asdatetime:=encodetime(0,0,0,0);
    myDataSetendeuhrzeit.asdatetime:=encodetime(0,0,0,0);
  end;
  myDataSetbeginn.AsDateTime:=trunc(myDataSetBeginnDatum.Asfloat)+frac(myDataSetbeginnuhrzeit.Asfloat);
  myDataSetende.AsDateTime:=trunc(myDataSetEndeDatum.Asfloat)+frac(myDataSetEndeUhrzeit.asfloat);
  if myDataSetende.asdatetime<myDataSetbeginn.asdatetime then
  begin
    myDataSetende.asdatetime:=myDataSetbeginn.asdatetime+(1/24);
    myDataSetEndeDatum.asfloat:=trunc(myDataSetende.asfloat);
    myDataSetEndeUhrzeit.asfloat:=frac(myDataSetende.asfloat);
  end;
  myDataSetdauerstunden.AsFloat:=(myDataSetEnde.asfloat-myDataSetBeginn.asfloat)*24;
  if myDataSetTerminNotiz.AsString='' then myDataSetTerminNotiz.asstring:=' ';
  myDataSetaufwand.asfloat:=myDataSetstundensatz.asfloat*myDataSetdauerstunden.asfloat;
  if myDataSetterminerledigt.asstring<>'' then myDataSetAlarm.asboolean:=False;
  myDataSetlastmodificationtime.asdatetime:=date+time;
end;

procedure TfrmEinzeltermin.myDataSetNewRecord(DataSet: TDataSet);
begin
  myDataSetgeburtstag.asboolean:=FALSE;
  myDataSetterminart.asinteger:=0;
  myDataSetAlarm.AsBoolean:=True;
  myDataSetTerminNotiz.AsString:='';
  myDataSetShape.AsInteger:=0;
  myDataSetColor.AsInteger:=16777215;
  myDataSetabgerechnet.AsBoolean:=false;
  myDataSetgefahrenekm.AsFloat:=0;
  myDataSetStundensatz.AsFloat:=0;
  myDataSetAllDayEventOutlook.AsBoolean:=false;
  myDataSetKalenderAnzeige.AsBoolean:=true;
  myDataSetDauerStunden.AsFloat:=0;
  myDataSetAufwand.AsFloat:=0;
  myDataSetPersonalnummer.AsString:=BenutzerPersonalnummer;
  myDataSetPNachname.AsString:=BenutzerNachname;
  myDataSetPVorname.AsString:=BenutzerVorname;
  myDataSetPFarbe.AsInteger:=BenutzerFarbe;

  myDataSetAlarmBefore.AsInteger:=15;
  myDataSetBeginnDatum.AsDateTime:=trunc(NeuStartDateTime);
  myDataSetEndeDatum.AsDateTime:=trunc(NeuEndDateTime);
  myDataSetBeginnUhrzeit.AsDateTime:=frac(NeuStartDateTime);
  mydatasetendeUhrzeit.AsDateTime:=frac(NeuEndDateTime);
  mydatasetartikelnummer.Value:=dsArtikel.CurrentData;
  edtnotiz.Lines.Clear;
end;

procedure TfrmEinzeltermin.SourceStateChange(Sender: TObject);
begin
  if myDataSet.State in dseditmodes then btnspeichern.Enabled:=true else btnspeichern.Enabled:=false;
end;

procedure TfrmEinzeltermin.btnaktivitaetClick(Sender: TObject);
begin
   if myDataSetKundennummer.AsString='' then
  begin
    showmessage('Es ist kein Kontakt zugeordnet');
    exit;
  end;
  mydataset.Edit;
  myDataSetTerminArt.AsInteger:=3;
  myDataSetColor.AsInteger:=16776960;
  mydataset.Post;
  lblTerminart.Caption:='Aktivität';
  comboKontaktart.enabled:=true;
  btnWV.enabled:=false;
  btnaktivitaet.enabled:=false;
end;

procedure TfrmEinzeltermin.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Einzel-Termin</h5></nav>';
  myelement:=container1.ElementHandle;
  WebLabel2.ParentElement:=myelement;
  lblterminart.ParentElement:=myelement;

  myelement:=container2.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  btnspeichern.ParentElement:=myelement;

  btnwv.ParentElement:=myelement;
  btnAktivitaet.ParentElement:=myelement;
  comboKontaktart.ParentElement:=myelement;

  myelement:=container3.ElementHandle;
  containerbeginn.ParentElement:=myelement;
  containerende.ParentElement:=myelement;

  myelement:=ContainerBeginn.ElementHandle;
  lblBeginn.ParentElement:=myelement;
  PickDateBeginn.ParentElement:=myelement;
  PickTimeBeginn.ParentElement:=myelement;

  myelement:=ContainerEnde.ElementHandle;
  lblEnde.ParentElement:=myelement;
  PickDateEnde.ParentElement:=myelement;
  PickTimeEnde.ParentElement:=myelement;

  myelement:=container4.ElementHandle;
  lblBetreff.ParentElement:=myelement;
  edtBetreff.ParentElement:=myelement;

  myelement:=container5.ElementHandle;
  lblNotiz.ParentElement:=myelement;
  edtNotiz.ParentElement:=myelement;

  myelement:=container6.ElementHandle;
  btnKontaktZuordnen.ParentElement:=myelement;
  lblkontakt.ParentElement:=myelement;

  myelement:=container7.ElementHandle;
  lblAnsprechpartner.ParentElement:=myelement;
  comboap.ParentElement:=myelement;
  DivTextAP.ParentElement:=myelement;

  myelement:=container8.ElementHandle;
  lblort.ParentElement:=myelement;
  edtort.ParentElement:=myelement;
  lblkm.ParentElement:=myelement;
  edtkm.ParentElement:=myelement;

  myelement:=container9.ElementHandle;
  btnObjektwahl.parentelement:=myelement;
  divtextobjekt.ParentElement:=myelement;

  myelement:=container10.ElementHandle;
  lblMitarbeiter.ParentElement:=myelement;

  myelement:=container11.ElementHandle;
  edtErinnerung.ParentElement:=myelement;
  edtAlarmBefore.ParentElement:=myelement;
  btnTerminerledigt.ParentElement:=myelement;
  edtTerminerledigt.ParentElement:=myelement;

  myelement:=container12.ElementHandle;
  divtextintern.ParentElement:=myelement;

  dsKArten.Close;
  dsartikel.Close;
  dsartikel.querystring:='$filter=Artikelnummer eq '+QuotedStr('0');
  dsartikel.Load;
end;

procedure TfrmEinzeltermin.XDSlookupObjektAfterOpen(DataSet: TDataSet);
begin
  if XDSlookupObjekt.RecordCount>0 then
  begin
    divtextobjekt.HTML.text:='<small><i>Ref.: </i></small>'+XDSlookupObjektObjektReferenz.asstring+'<small><i> Nr.: </i></small>'+XDSlookupObjektObjektnummer.asstring+' '+XDSlookupObjektKurzbezeichnung.asstring;
  end
  else
  begin
    divtextobjekt.HTML.text:='nicht gefunden';
  end;

end;

procedure TfrmEinzeltermin.edtNotizEnter(Sender: TObject);
begin
  myDataSet.Edit;
end;

procedure TfrmEinzeltermin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTerminart := TLabel.Create(Self);
  lblBeginn := TLabel.Create(Self);
  lblEnde := TLabel.Create(Self);
  lblBetreff := TLabel.Create(Self);
  lblNotiz := TLabel.Create(Self);
  lblAnsprechpartner := TLabel.Create(Self);
  lblort := TLabel.Create(Self);
  lblkm := TLabel.Create(Self);
  lblMitarbeiter := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  lblKontakt := TLabel.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  btnObjektwahl := TButton.Create(Self);
  btnzurueck := TButton.Create(Self);
  btnSpeichern := TButton.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnWV := TButton.Create(Self);
  btnAktivitaet := TButton.Create(Self);
  comboKontaktart := TDBComboBox.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  PickDateBeginn := TDBDateTimePicker.Create(Self);
  pickDateEnde := TDBDateTimePicker.Create(Self);
  PickTimeBeginn := TDBDateTimePicker.Create(Self);
  picktimeEnde := TDBDateTimePicker.Create(Self);
  edtBetreff := TDBEdit.Create(Self);
  edtNotiz := TMemo.Create(Self);
  ContainerBeginn := THTMLContainer.Create(Self);
  ContainerEnde := THTMLContainer.Create(Self);
  Container4 := THTMLContainer.Create(Self);
  Container5 := THTMLContainer.Create(Self);
  Container6 := THTMLContainer.Create(Self);
  btnKontaktZuordnen := TButton.Create(Self);
  Container7 := THTMLContainer.Create(Self);
  Container8 := THTMLContainer.Create(Self);
  comboap := TDBComboBox.Create(Self);
  edtOrt := TDBEdit.Create(Self);
  edtkm := TDBEdit.Create(Self);
  Container9 := THTMLContainer.Create(Self);
  Container10 := THTMLContainer.Create(Self);
  Container11 := THTMLContainer.Create(Self);
  btnTerminerledigt := TButton.Create(Self);
  edtTerminerledigt := TDBEdit.Create(Self);
  edtAlarmBefore := TDBSpinEdit.Create(Self);
  edtErinnerung := TDBCheckBox.Create(Self);
  Container12 := THTMLContainer.Create(Self);
  DivTextIntern := THTMLDiv.Create(Self);
  DivTextObjekt := THTMLDiv.Create(Self);
  DivTextAP := THTMLDiv.Create(Self);
  myDataSet := TXDataWebDataSet.Create(Self);
  myDataSetArtikelnummer := TXDataWebEntityField.Create(Self);
  myDataSetUID := TStringField.Create(Self);
  myDataSetBeginn := TDateTimeField.Create(Self);
  myDataSetEnde := TDateTimeField.Create(Self);
  myDataSetBetreff := TStringField.Create(Self);
  myDataSetTerminNotiz := TStringField.Create(Self);
  myDataSetQuelle := TStringField.Create(Self);
  myDataSetGUIDOutlook := TStringField.Create(Self);
  myDataSetShape := TIntegerField.Create(Self);
  myDataSetColor := TIntegerField.Create(Self);
  myDataSetAlarm := TBooleanField.Create(Self);
  myDataSetResource := TIntegerField.Create(Self);
  myDataSetLastModificationTime := TDateTimeField.Create(Self);
  myDataSetGeburtstag := TBooleanField.Create(Self);
  myDataSetAlarmBefore := TIntegerField.Create(Self);
  myDataSetPersonalnummer := TIntegerField.Create(Self);
  myDataSetKundennummer := TStringField.Create(Self);
  myDataSetAllDayEventOutlook := TBooleanField.Create(Self);
  myDataSetTMSCaption := TBooleanField.Create(Self);
  myDataSetRECURRENCY := TStringField.Create(Self);
  myDataSetMINTIME := TDateTimeField.Create(Self);
  myDataSetMAXTIME := TDateTimeField.Create(Self);
  myDataSetNachname := TStringField.Create(Self);
  myDataSetObjektReferenz := TStringField.Create(Self);
  myDataSetObjektnummer := TStringField.Create(Self);
  myDataSetTerminArt := TIntegerField.Create(Self);
  myDataSetBeginnDatum := TDateTimeField.Create(Self);
  myDataSetBeginnUhrzeit := TDateTimeField.Create(Self);
  myDataSetEndeDatum := TDateTimeField.Create(Self);
  myDataSetEndeUhrzeit := TDateTimeField.Create(Self);
  myDataSetKalenderAnzeige := TBooleanField.Create(Self);
  myDataSetStundensatz := TFloatField.Create(Self);
  myDataSetDauerStunden := TFloatField.Create(Self);
  myDataSetgefahrenekm := TFloatField.Create(Self);
  myDataSetAufwand := TFloatField.Create(Self);
  myDataSetabgerechnet := TBooleanField.Create(Self);
  myDataSetverteilt := TDateTimeField.Create(Self);
  myDataSetTerminErledigt := TDateTimeField.Create(Self);
  myDataSetAnsprechpartner := TStringField.Create(Self);
  myDataSetLocation := TStringField.Create(Self);
  myDataSetGoogleID := TStringField.Create(Self);
  myDataSetGoogleCreatedUTC := TDateTimeField.Create(Self);
  myDataSetGoogleUpdatedUTC := TDateTimeField.Create(Self);
  myDataSetGoogleStatus := TStringField.Create(Self);
  myDataSetVorname := TStringField.Create(Self);
  myDataSetPNachname := TStringField.Create(Self);
  myDataSetPVorname := TStringField.Create(Self);
  myDataSetArtikelueberschrift := TStringField.Create(Self);
  myDataSetPFarbe := TIntegerField.Create(Self);
  myDataSetKontaktart := TStringField.Create(Self);
  Source := TDataSource.Create(Self);
  dsArtikel := TXDataWebDataSet.Create(Self);
  dsArtikelArtikelnummer := TStringField.Create(Self);
  dskontakt := TXDataWebDataSet.Create(Self);
  dskontaktKundennummer := TStringField.Create(Self);
  dskontaktNachname := TStringField.Create(Self);
  dskontaktVorname := TStringField.Create(Self);
  dskontaktAdresszeile2 := TStringField.Create(Self);
  dskontaktAdresszeile3 := TStringField.Create(Self);
  dskontaktStrasse := TStringField.Create(Self);
  dskontaktPostleitzahl := TStringField.Create(Self);
  dskontaktOrt := TStringField.Create(Self);
  dskontaktLaenderkuerzel := TStringField.Create(Self);
  dskontaktLand := TStringField.Create(Self);
  dskontaktKilometer := TIntegerField.Create(Self);
  dskontaktStundensatz := TFloatField.Create(Self);
  dsKArten := TXDataWebDataSet.Create(Self);
  dsKArtenKontaktart := TStringField.Create(Self);
  dsKArtenArtikelnummer := TStringField.Create(Self);
  dsObjekt := TXDataWebDataSet.Create(Self);
  dsObjektObjektReferenz := TStringField.Create(Self);
  dsObjektKurzbezeichnung := TStringField.Create(Self);
  dsObjektObjektnummer := TStringField.Create(Self);
  dsAP := TXDataWebDataSet.Create(Self);
  dsAPKundennummer := TXDataWebEntityField.Create(Self);
  dsAPAnsprechpartner := TStringField.Create(Self);
  dsAPNachname := TStringField.Create(Self);
  dsAPVorname := TStringField.Create(Self);
  XDSlookupObjekt := TXDataWebDataSet.Create(Self);
  XDSlookupObjektObjektReferenz := TStringField.Create(Self);
  XDSlookupObjektKurzbezeichnung := TStringField.Create(Self);
  XDSlookupObjektObjektnummer := TStringField.Create(Self);

  lblTerminart.BeforeLoadDFMValues;
  lblBeginn.BeforeLoadDFMValues;
  lblEnde.BeforeLoadDFMValues;
  lblBetreff.BeforeLoadDFMValues;
  lblNotiz.BeforeLoadDFMValues;
  lblAnsprechpartner.BeforeLoadDFMValues;
  lblort.BeforeLoadDFMValues;
  lblkm.BeforeLoadDFMValues;
  lblMitarbeiter.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  lblKontakt.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  btnObjektwahl.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  btnSpeichern.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnWV.BeforeLoadDFMValues;
  btnAktivitaet.BeforeLoadDFMValues;
  comboKontaktart.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  PickDateBeginn.BeforeLoadDFMValues;
  pickDateEnde.BeforeLoadDFMValues;
  PickTimeBeginn.BeforeLoadDFMValues;
  picktimeEnde.BeforeLoadDFMValues;
  edtBetreff.BeforeLoadDFMValues;
  edtNotiz.BeforeLoadDFMValues;
  ContainerBeginn.BeforeLoadDFMValues;
  ContainerEnde.BeforeLoadDFMValues;
  Container4.BeforeLoadDFMValues;
  Container5.BeforeLoadDFMValues;
  Container6.BeforeLoadDFMValues;
  btnKontaktZuordnen.BeforeLoadDFMValues;
  Container7.BeforeLoadDFMValues;
  Container8.BeforeLoadDFMValues;
  comboap.BeforeLoadDFMValues;
  edtOrt.BeforeLoadDFMValues;
  edtkm.BeforeLoadDFMValues;
  Container9.BeforeLoadDFMValues;
  Container10.BeforeLoadDFMValues;
  Container11.BeforeLoadDFMValues;
  btnTerminerledigt.BeforeLoadDFMValues;
  edtTerminerledigt.BeforeLoadDFMValues;
  edtAlarmBefore.BeforeLoadDFMValues;
  edtErinnerung.BeforeLoadDFMValues;
  Container12.BeforeLoadDFMValues;
  DivTextIntern.BeforeLoadDFMValues;
  DivTextObjekt.BeforeLoadDFMValues;
  DivTextAP.BeforeLoadDFMValues;
  myDataSet.BeforeLoadDFMValues;
  myDataSetArtikelnummer.BeforeLoadDFMValues;
  myDataSetUID.BeforeLoadDFMValues;
  myDataSetBeginn.BeforeLoadDFMValues;
  myDataSetEnde.BeforeLoadDFMValues;
  myDataSetBetreff.BeforeLoadDFMValues;
  myDataSetTerminNotiz.BeforeLoadDFMValues;
  myDataSetQuelle.BeforeLoadDFMValues;
  myDataSetGUIDOutlook.BeforeLoadDFMValues;
  myDataSetShape.BeforeLoadDFMValues;
  myDataSetColor.BeforeLoadDFMValues;
  myDataSetAlarm.BeforeLoadDFMValues;
  myDataSetResource.BeforeLoadDFMValues;
  myDataSetLastModificationTime.BeforeLoadDFMValues;
  myDataSetGeburtstag.BeforeLoadDFMValues;
  myDataSetAlarmBefore.BeforeLoadDFMValues;
  myDataSetPersonalnummer.BeforeLoadDFMValues;
  myDataSetKundennummer.BeforeLoadDFMValues;
  myDataSetAllDayEventOutlook.BeforeLoadDFMValues;
  myDataSetTMSCaption.BeforeLoadDFMValues;
  myDataSetRECURRENCY.BeforeLoadDFMValues;
  myDataSetMINTIME.BeforeLoadDFMValues;
  myDataSetMAXTIME.BeforeLoadDFMValues;
  myDataSetNachname.BeforeLoadDFMValues;
  myDataSetObjektReferenz.BeforeLoadDFMValues;
  myDataSetObjektnummer.BeforeLoadDFMValues;
  myDataSetTerminArt.BeforeLoadDFMValues;
  myDataSetBeginnDatum.BeforeLoadDFMValues;
  myDataSetBeginnUhrzeit.BeforeLoadDFMValues;
  myDataSetEndeDatum.BeforeLoadDFMValues;
  myDataSetEndeUhrzeit.BeforeLoadDFMValues;
  myDataSetKalenderAnzeige.BeforeLoadDFMValues;
  myDataSetStundensatz.BeforeLoadDFMValues;
  myDataSetDauerStunden.BeforeLoadDFMValues;
  myDataSetgefahrenekm.BeforeLoadDFMValues;
  myDataSetAufwand.BeforeLoadDFMValues;
  myDataSetabgerechnet.BeforeLoadDFMValues;
  myDataSetverteilt.BeforeLoadDFMValues;
  myDataSetTerminErledigt.BeforeLoadDFMValues;
  myDataSetAnsprechpartner.BeforeLoadDFMValues;
  myDataSetLocation.BeforeLoadDFMValues;
  myDataSetGoogleID.BeforeLoadDFMValues;
  myDataSetGoogleCreatedUTC.BeforeLoadDFMValues;
  myDataSetGoogleUpdatedUTC.BeforeLoadDFMValues;
  myDataSetGoogleStatus.BeforeLoadDFMValues;
  myDataSetVorname.BeforeLoadDFMValues;
  myDataSetPNachname.BeforeLoadDFMValues;
  myDataSetPVorname.BeforeLoadDFMValues;
  myDataSetArtikelueberschrift.BeforeLoadDFMValues;
  myDataSetPFarbe.BeforeLoadDFMValues;
  myDataSetKontaktart.BeforeLoadDFMValues;
  Source.BeforeLoadDFMValues;
  dsArtikel.BeforeLoadDFMValues;
  dsArtikelArtikelnummer.BeforeLoadDFMValues;
  dskontakt.BeforeLoadDFMValues;
  dskontaktKundennummer.BeforeLoadDFMValues;
  dskontaktNachname.BeforeLoadDFMValues;
  dskontaktVorname.BeforeLoadDFMValues;
  dskontaktAdresszeile2.BeforeLoadDFMValues;
  dskontaktAdresszeile3.BeforeLoadDFMValues;
  dskontaktStrasse.BeforeLoadDFMValues;
  dskontaktPostleitzahl.BeforeLoadDFMValues;
  dskontaktOrt.BeforeLoadDFMValues;
  dskontaktLaenderkuerzel.BeforeLoadDFMValues;
  dskontaktLand.BeforeLoadDFMValues;
  dskontaktKilometer.BeforeLoadDFMValues;
  dskontaktStundensatz.BeforeLoadDFMValues;
  dsKArten.BeforeLoadDFMValues;
  dsKArtenKontaktart.BeforeLoadDFMValues;
  dsKArtenArtikelnummer.BeforeLoadDFMValues;
  dsObjekt.BeforeLoadDFMValues;
  dsObjektObjektReferenz.BeforeLoadDFMValues;
  dsObjektKurzbezeichnung.BeforeLoadDFMValues;
  dsObjektObjektnummer.BeforeLoadDFMValues;
  dsAP.BeforeLoadDFMValues;
  dsAPKundennummer.BeforeLoadDFMValues;
  dsAPAnsprechpartner.BeforeLoadDFMValues;
  dsAPNachname.BeforeLoadDFMValues;
  dsAPVorname.BeforeLoadDFMValues;
  XDSlookupObjekt.BeforeLoadDFMValues;
  XDSlookupObjektObjektReferenz.BeforeLoadDFMValues;
  XDSlookupObjektKurzbezeichnung.BeforeLoadDFMValues;
  XDSlookupObjektObjektnummer.BeforeLoadDFMValues;
  try
    Name := 'frmEinzeltermin';
    Width := 781;
    Height := 709;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    ShowHint := True;
    TabStop := True;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblTerminart.SetParentComponent(Self);
    lblTerminart.Name := 'lblTerminart';
    lblTerminart.AlignWithMargins := True;
    lblTerminart.Left := 8;
    lblTerminart.Top := 585;
    lblTerminart.Width := 41;
    lblTerminart.Height := 16;
    lblTerminart.Caption := 'Termin';
    lblTerminart.ElementClassName := 'text-lg ml-2';
    lblTerminart.ElementFont := efCSS;
    lblTerminart.Font.Charset := DEFAULT_CHARSET;
    lblTerminart.Font.Color := clWindowText;
    lblTerminart.Font.Height := -13;
    lblTerminart.Font.Name := 'Tahoma';
    lblTerminart.Font.Style := [];
    lblTerminart.HeightStyle := ssAuto;
    lblTerminart.ParentFont := False;
    lblTerminart.Transparent := False;
    lblTerminart.WidthStyle := ssAuto;
    lblBeginn.SetParentComponent(Self);
    lblBeginn.Name := 'lblBeginn';
    lblBeginn.Left := 8;
    lblBeginn.Top := 173;
    lblBeginn.Width := 40;
    lblBeginn.Height := 13;
    lblBeginn.AutoSize := False;
    lblBeginn.Caption := 'Beginn';
    lblBeginn.ElementClassName := 'text-small m-2';
    lblBeginn.ElementFont := efCSS;
    lblBeginn.HeightStyle := ssAuto;
    lblBeginn.Transparent := False;
    lblEnde.SetParentComponent(Self);
    lblEnde.Name := 'lblEnde';
    lblEnde.Left := 49;
    lblEnde.Top := 192;
    lblEnde.Width := 40;
    lblEnde.Height := 13;
    lblEnde.AutoSize := False;
    lblEnde.Caption := 'Ende';
    lblEnde.ElementClassName := 'text small m-2';
    lblEnde.ElementFont := efCSS;
    lblEnde.Transparent := False;
    lblBetreff.SetParentComponent(Self);
    lblBetreff.Name := 'lblBetreff';
    lblBetreff.AlignWithMargins := True;
    lblBetreff.Left := 29;
    lblBetreff.Top := 231;
    lblBetreff.Width := 50;
    lblBetreff.Height := 13;
    lblBetreff.AutoSize := False;
    lblBetreff.Caption := 'Betreff:';
    lblBetreff.ElementClassName := 'text small m-2';
    lblBetreff.ElementFont := efCSS;
    lblBetreff.Font.Charset := DEFAULT_CHARSET;
    lblBetreff.Font.Color := clWindowText;
    lblBetreff.Font.Height := -11;
    lblBetreff.Font.Name := 'Tahoma';
    lblBetreff.Font.Style := [];
    lblBetreff.ParentFont := False;
    lblBetreff.Transparent := False;
    lblNotiz.SetParentComponent(Self);
    lblNotiz.Name := 'lblNotiz';
    lblNotiz.AlignWithMargins := True;
    lblNotiz.Left := 40;
    lblNotiz.Top := 266;
    lblNotiz.Width := 50;
    lblNotiz.Height := 13;
    lblNotiz.AutoSize := False;
    lblNotiz.Caption := 'Notiz:';
    lblNotiz.ElementClassName := 'text small m-2';
    lblNotiz.ElementFont := efCSS;
    lblNotiz.Font.Charset := DEFAULT_CHARSET;
    lblNotiz.Font.Color := clWindowText;
    lblNotiz.Font.Height := -11;
    lblNotiz.Font.Name := 'Tahoma';
    lblNotiz.Font.Style := [];
    lblNotiz.ParentFont := False;
    lblNotiz.Transparent := False;
    lblAnsprechpartner.SetParentComponent(Self);
    lblAnsprechpartner.Name := 'lblAnsprechpartner';
    lblAnsprechpartner.AlignWithMargins := True;
    lblAnsprechpartner.Left := 209;
    lblAnsprechpartner.Top := 376;
    lblAnsprechpartner.Width := 50;
    lblAnsprechpartner.Height := 17;
    lblAnsprechpartner.AutoSize := False;
    lblAnsprechpartner.Caption := 'K-Person';
    lblAnsprechpartner.ElementClassName := 'text small m-2';
    lblAnsprechpartner.ElementFont := efCSS;
    lblAnsprechpartner.Font.Charset := DEFAULT_CHARSET;
    lblAnsprechpartner.Font.Color := clWindowText;
    lblAnsprechpartner.Font.Height := -11;
    lblAnsprechpartner.Font.Name := 'Tahoma';
    lblAnsprechpartner.Font.Style := [];
    lblAnsprechpartner.HeightStyle := ssAuto;
    lblAnsprechpartner.ParentFont := False;
    lblAnsprechpartner.Transparent := False;
    lblort.SetParentComponent(Self);
    lblort.Name := 'lblort';
    lblort.AlignWithMargins := True;
    lblort.Left := 8;
    lblort.Top := 435;
    lblort.Width := 50;
    lblort.Height := 13;
    lblort.AutoSize := False;
    lblort.Caption := 'Ort:';
    lblort.ElementClassName := 'text small m-2';
    lblort.ElementFont := efCSS;
    lblort.Font.Charset := DEFAULT_CHARSET;
    lblort.Font.Color := clWindowText;
    lblort.Font.Height := -11;
    lblort.Font.Name := 'Tahoma';
    lblort.Font.Style := [];
    lblort.HeightStyle := ssAuto;
    lblort.ParentFont := False;
    lblort.Transparent := False;
    lblkm.SetParentComponent(Self);
    lblkm.Name := 'lblkm';
    lblkm.Left := 329;
    lblkm.Top := 435;
    lblkm.Width := 13;
    lblkm.Height := 13;
    lblkm.AutoSize := False;
    lblkm.Caption := 'km';
    lblkm.ElementClassName := 'text small m-2';
    lblkm.ElementFont := efCSS;
    lblkm.HeightStyle := ssAuto;
    lblMitarbeiter.SetParentComponent(Self);
    lblMitarbeiter.Name := 'lblMitarbeiter';
    lblMitarbeiter.AlignWithMargins := True;
    lblMitarbeiter.Left := 3;
    lblMitarbeiter.Top := 470;
    lblMitarbeiter.Width := 99;
    lblMitarbeiter.Height := 11;
    lblMitarbeiter.AutoSize := False;
    lblMitarbeiter.Caption := 'Mitarbeiter:';
    lblMitarbeiter.ElementClassName := 'text small m-2';
    lblMitarbeiter.ElementFont := efCSS;
    lblMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    lblMitarbeiter.Font.Color := clWindowText;
    lblMitarbeiter.Font.Height := -11;
    lblMitarbeiter.Font.Name := 'Tahoma';
    lblMitarbeiter.Font.Style := [];
    lblMitarbeiter.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 576;
    WebLabel2.Top := 560;
    WebLabel2.Width := 3;
    WebLabel2.Height := 13;
    WebLabel2.ElementClassName := 'text-small text-danger ml-2';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.WidthStyle := ssAuto;
    lblKontakt.SetParentComponent(Self);
    lblKontakt.Name := 'lblKontakt';
    lblKontakt.Left := 32;
    lblKontakt.Top := 120;
    lblKontakt.Width := 47;
    lblKontakt.Height := 13;
    lblKontakt.Caption := 'lblKontakt';
    lblKontakt.ElementClassName := 'h6 m-2';
    lblKontakt.ElementFont := efCSS;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 781;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    btnObjektwahl.SetParentComponent(Self);
    btnObjektwahl.Name := 'btnObjektwahl';
    btnObjektwahl.Left := 48;
    btnObjektwahl.Top := 536;
    btnObjektwahl.Width := 96;
    btnObjektwahl.Height := 25;
    btnObjektwahl.Caption := 'Objekt';
    btnObjektwahl.ChildOrder := 12;
    btnObjektwahl.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnObjektwahl.ElementFont := efCSS;
    btnObjektwahl.HeightStyle := ssAuto;
    btnObjektwahl.WidthStyle := ssAuto;
    SetEvent(btnObjektwahl, Self, 'OnClick', 'btnObjektwahlClick');
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 157;
    btnzurueck.Top := 536;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 12;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    btnSpeichern.SetParentComponent(Self);
    btnSpeichern.Name := 'btnSpeichern';
    btnSpeichern.Left := 259;
    btnSpeichern.Top := 536;
    btnSpeichern.Width := 96;
    btnSpeichern.Height := 25;
    btnSpeichern.Caption := 'speichern';
    btnSpeichern.ChildOrder := 4;
    btnSpeichern.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnSpeichern.ElementFont := efCSS;
    btnSpeichern.HeightStyle := ssAuto;
    btnSpeichern.WidthStyle := ssAuto;
    SetEvent(btnSpeichern, Self, 'OnClick', 'btnSpeichernClick');
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 472;
    Container2.Top := 41;
    Container2.Width := 174;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnWV.SetParentComponent(Self);
    btnWV.Name := 'btnWV';
    btnWV.Left := 157;
    btnWV.Top := 576;
    btnWV.Width := 96;
    btnWV.Height := 25;
    btnWV.Caption := 'WV';
    btnWV.ChildOrder := 12;
    btnWV.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnWV.ElementFont := efCSS;
    btnWV.HeightStyle := ssAuto;
    btnWV.WidthStyle := ssAuto;
    SetEvent(btnWV, Self, 'OnClick', 'btnWVClick');
    btnAktivitaet.SetParentComponent(Self);
    btnAktivitaet.Name := 'btnAktivitaet';
    btnAktivitaet.Left := 157;
    btnAktivitaet.Top := 607;
    btnAktivitaet.Width := 96;
    btnAktivitaet.Height := 25;
    btnAktivitaet.Caption := 'Aktivit'#228't';
    btnAktivitaet.ChildOrder := 12;
    btnAktivitaet.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnAktivitaet.ElementFont := efCSS;
    btnAktivitaet.HeightStyle := ssAuto;
    btnAktivitaet.WidthStyle := ssAuto;
    SetEvent(btnAktivitaet, Self, 'OnClick', 'btnaktivitaetClick');
    comboKontaktart.SetParentComponent(Self);
    comboKontaktart.Name := 'comboKontaktart';
    comboKontaktart.AlignWithMargins := True;
    comboKontaktart.Left := 157;
    comboKontaktart.Top := 638;
    comboKontaktart.Width := 200;
    comboKontaktart.Height := 21;
    comboKontaktart.ElementClassName := 'form-control form-control-sm m-1';
    comboKontaktart.ElementFont := efCSS;
    comboKontaktart.HeightStyle := ssAuto;
    comboKontaktart.ItemIndex := -1;
    comboKontaktart.DataField := 'Kontaktart';
    comboKontaktart.DataSource := Source;
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 472;
    Container3.Top := 82;
    Container3.Width := 174;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    PickDateBeginn.SetParentComponent(Self);
    PickDateBeginn.Name := 'PickDateBeginn';
    PickDateBeginn.Left := 73;
    PickDateBeginn.Top := 173;
    PickDateBeginn.Width := 164;
    PickDateBeginn.Height := 30;
    PickDateBeginn.ElementClassName := 'form-control form-control-sm m-1';
    PickDateBeginn.HeightStyle := ssAuto;
    PickDateBeginn.BorderStyle := bsSingle;
    PickDateBeginn.ChildOrder := 24;
    PickDateBeginn.Color := clWhite;
    PickDateBeginn.Date := 43625.314923055560000000;
    PickDateBeginn.ElementFont := efCSS;
    PickDateBeginn.Role := '';
    PickDateBeginn.Text := '';
    PickDateBeginn.DataField := 'BeginnDatum';
    PickDateBeginn.DataSource := Source;
    pickDateEnde.SetParentComponent(Self);
    pickDateEnde.Name := 'pickDateEnde';
    pickDateEnde.Left := 73;
    pickDateEnde.Top := 184;
    pickDateEnde.Width := 164;
    pickDateEnde.Height := 30;
    pickDateEnde.ElementClassName := 'form-control form-control-sm m-1';
    pickDateEnde.HeightStyle := ssAuto;
    pickDateEnde.BorderStyle := bsSingle;
    pickDateEnde.ChildOrder := 25;
    pickDateEnde.Color := clWhite;
    pickDateEnde.Date := 43625.314923055560000000;
    pickDateEnde.ElementFont := efCSS;
    pickDateEnde.Role := '';
    pickDateEnde.Text := '';
    pickDateEnde.DataField := 'EndeDatum';
    pickDateEnde.DataSource := Source;
    PickTimeBeginn.SetParentComponent(Self);
    PickTimeBeginn.Name := 'PickTimeBeginn';
    PickTimeBeginn.Left := 263;
    PickTimeBeginn.Top := 175;
    PickTimeBeginn.Width := 140;
    PickTimeBeginn.Height := 30;
    PickTimeBeginn.ElementClassName := 'form-control form-control-sm m-1';
    PickTimeBeginn.HeightStyle := ssAuto;
    PickTimeBeginn.BorderStyle := bsSingle;
    PickTimeBeginn.ChildOrder := 26;
    PickTimeBeginn.Color := clWhite;
    PickTimeBeginn.Date := 43625.314923055560000000;
    PickTimeBeginn.ElementFont := efCSS;
    PickTimeBeginn.Kind := dtkTime;
    PickTimeBeginn.Role := '';
    PickTimeBeginn.Text := '';
    PickTimeBeginn.DataField := 'BeginnUhrzeit';
    PickTimeBeginn.DataSource := Source;
    picktimeEnde.SetParentComponent(Self);
    picktimeEnde.Name := 'picktimeEnde';
    picktimeEnde.Left := 263;
    picktimeEnde.Top := 184;
    picktimeEnde.Width := 140;
    picktimeEnde.Height := 30;
    picktimeEnde.ElementClassName := 'form-control form-control-sm m-1';
    picktimeEnde.HeightStyle := ssAuto;
    picktimeEnde.BorderStyle := bsSingle;
    picktimeEnde.ChildOrder := 27;
    picktimeEnde.Color := clWhite;
    picktimeEnde.Date := 43625.314923055560000000;
    picktimeEnde.ElementFont := efCSS;
    picktimeEnde.Kind := dtkTime;
    picktimeEnde.Role := '';
    picktimeEnde.Text := '';
    picktimeEnde.DataField := 'EndeUhrzeit';
    picktimeEnde.DataSource := Source;
    edtBetreff.SetParentComponent(Self);
    edtBetreff.Name := 'edtBetreff';
    edtBetreff.Left := 73;
    edtBetreff.Top := 227;
    edtBetreff.Width := 353;
    edtBetreff.Height := 21;
    edtBetreff.AutoSize := True;
    edtBetreff.ChildOrder := 28;
    edtBetreff.ElementClassName := 'form-control form-control-sm m-1';
    edtBetreff.ElementFont := efCSS;
    edtBetreff.HeightStyle := ssAuto;
    edtBetreff.Text := 'edtBetreff';
    edtBetreff.WidthStyle := ssPercent;
    edtBetreff.WidthPercent := 80;
    edtBetreff.DataField := 'Betreff';
    edtBetreff.DataSource := Source;
    edtNotiz.SetParentComponent(Self);
    edtNotiz.Name := 'edtNotiz';
    edtNotiz.Left := 74;
    edtNotiz.Top := 266;
    edtNotiz.Width := 352;
    edtNotiz.Height := 91;
    edtNotiz.AutoSize := False;
    edtNotiz.ElementClassName := 'form-control form-control-sm m-1';
    edtNotiz.ElementFont := efCSS;
    edtNotiz.Lines.BeginUpdate;
    try
      edtNotiz.Lines.Clear;
      edtNotiz.Lines.Add('WebMemo1');
    finally
      edtNotiz.Lines.EndUpdate;
    end;
    edtNotiz.SelLength := 0;
    edtNotiz.SelStart := 0;
    edtNotiz.WidthStyle := ssPercent;
    edtNotiz.WidthPercent := 80;
    SetEvent(edtNotiz, Self, 'OnEnter', 'edtNotizEnter');
    ContainerBeginn.SetParentComponent(Self);
    ContainerBeginn.Name := 'ContainerBeginn';
    ContainerBeginn.Left := 284;
    ContainerBeginn.Top := 576;
    ContainerBeginn.Width := 152;
    ContainerBeginn.Height := 41;
    ContainerBeginn.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    ContainerBeginn.HeightStyle := ssAuto;
    ContainerBeginn.WidthStyle := ssAuto;
    ContainerBeginn.ChildOrder := 4;
    ContainerBeginn.ElementFont := efCSS;
    ContainerEnde.SetParentComponent(Self);
    ContainerEnde.Name := 'ContainerEnde';
    ContainerEnde.Left := 284;
    ContainerEnde.Top := 623;
    ContainerEnde.Width := 152;
    ContainerEnde.Height := 41;
    ContainerEnde.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    ContainerEnde.HeightStyle := ssAuto;
    ContainerEnde.WidthStyle := ssAuto;
    ContainerEnde.ChildOrder := 4;
    ContainerEnde.ElementFont := efCSS;
    Container4.SetParentComponent(Self);
    Container4.Name := 'Container4';
    Container4.Left := 472;
    Container4.Top := 124;
    Container4.Width := 174;
    Container4.Height := 41;
    Container4.ElementClassName := 'd-flex flex-wrap m-1';
    Container4.HeightStyle := ssAuto;
    Container4.WidthStyle := ssAuto;
    Container4.ChildOrder := 3;
    Container4.ElementFont := efCSS;
    Container4.ElementPosition := epRelative;
    Container5.SetParentComponent(Self);
    Container5.Name := 'Container5';
    Container5.Left := 472;
    Container5.Top := 167;
    Container5.Width := 174;
    Container5.Height := 41;
    Container5.ElementClassName := 'd-flex flex-wrap m-1';
    Container5.HeightStyle := ssAuto;
    Container5.WidthStyle := ssAuto;
    Container5.ChildOrder := 4;
    Container5.ElementFont := efCSS;
    Container5.ElementPosition := epRelative;
    Container6.SetParentComponent(Self);
    Container6.Name := 'Container6';
    Container6.Left := 472;
    Container6.Top := 210;
    Container6.Width := 174;
    Container6.Height := 41;
    Container6.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container6.HeightStyle := ssAuto;
    Container6.WidthStyle := ssPercent;
    Container6.WidthPercent := 85;
    Container6.ChildOrder := 5;
    Container6.ElementFont := efCSS;
    Container6.ElementPosition := epRelative;
    btnKontaktZuordnen.SetParentComponent(Self);
    btnKontaktZuordnen.Name := 'btnKontaktZuordnen';
    btnKontaktZuordnen.Left := 361;
    btnKontaktZuordnen.Top := 536;
    btnKontaktZuordnen.Width := 96;
    btnKontaktZuordnen.Height := 25;
    btnKontaktZuordnen.Caption := 'Kontakt';
    btnKontaktZuordnen.ChildOrder := 12;
    btnKontaktZuordnen.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnKontaktZuordnen.ElementFont := efCSS;
    btnKontaktZuordnen.HeightStyle := ssAuto;
    btnKontaktZuordnen.WidthStyle := ssAuto;
    SetEvent(btnKontaktZuordnen, Self, 'OnClick', 'btnKontaktZuordnenClick');
    Container7.SetParentComponent(Self);
    Container7.Name := 'Container7';
    Container7.Left := 472;
    Container7.Top := 257;
    Container7.Width := 174;
    Container7.Height := 41;
    Container7.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container7.HeightStyle := ssAuto;
    Container7.WidthStyle := ssPercent;
    Container7.WidthPercent := 85;
    Container7.ChildOrder := 6;
    Container7.ElementFont := efCSS;
    Container7.ElementPosition := epRelative;
    Container8.SetParentComponent(Self);
    Container8.Name := 'Container8';
    Container8.Left := 472;
    Container8.Top := 304;
    Container8.Width := 174;
    Container8.Height := 41;
    Container8.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container8.HeightStyle := ssAuto;
    Container8.WidthStyle := ssPercent;
    Container8.WidthPercent := 85;
    Container8.ChildOrder := 7;
    Container8.ElementFont := efCSS;
    Container8.ElementPosition := epRelative;
    comboap.SetParentComponent(Self);
    comboap.Name := 'comboap';
    comboap.Left := 11;
    comboap.Top := 388;
    comboap.Width := 145;
    comboap.Height := 21;
    comboap.ElementClassName := 'form-control form-control-sm m-1';
    comboap.ElementFont := efCSS;
    comboap.HeightStyle := ssAuto;
    SetEvent(comboap, Self, 'OnEnter', 'comboAPEnter');
    comboap.ItemIndex := -1;
    comboap.DataField := 'Ansprechpartner';
    comboap.DataSource := Source;
    edtOrt.SetParentComponent(Self);
    edtOrt.Name := 'edtOrt';
    edtOrt.Left := 54;
    edtOrt.Top := 432;
    edtOrt.Width := 269;
    edtOrt.Height := 21;
    edtOrt.ChildOrder := 41;
    edtOrt.ElementClassName := 'form-control form-control-sm m-1';
    edtOrt.ElementFont := efCSS;
    edtOrt.HeightStyle := ssAuto;
    edtOrt.Text := 'edtOrt';
    edtOrt.WidthStyle := ssPercent;
    edtOrt.WidthPercent := 60;
    edtOrt.DataField := 'Location';
    edtOrt.DataSource := Source;
    edtkm.SetParentComponent(Self);
    edtkm.Name := 'edtkm';
    edtkm.Left := 348;
    edtkm.Top := 432;
    edtkm.Width := 60;
    edtkm.Height := 21;
    edtkm.ChildOrder := 43;
    edtkm.ElementClassName := 'form-control form-control-sm m-1';
    edtkm.ElementFont := efCSS;
    edtkm.HeightStyle := ssAuto;
    edtkm.Text := 'edtkm';
    edtkm.DataField := 'gefahrenekm';
    edtkm.DataSource := Source;
    Container9.SetParentComponent(Self);
    Container9.Name := 'Container9';
    Container9.Left := 472;
    Container9.Top := 351;
    Container9.Width := 174;
    Container9.Height := 41;
    Container9.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container9.HeightStyle := ssAuto;
    Container9.WidthStyle := ssPercent;
    Container9.WidthPercent := 85;
    Container9.ChildOrder := 8;
    Container9.ElementFont := efCSS;
    Container9.ElementPosition := epRelative;
    Container10.SetParentComponent(Self);
    Container10.Name := 'Container10';
    Container10.Left := 472;
    Container10.Top := 398;
    Container10.Width := 174;
    Container10.Height := 41;
    Container10.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container10.HeightStyle := ssAuto;
    Container10.WidthStyle := ssPercent;
    Container10.WidthPercent := 85;
    Container10.ChildOrder := 9;
    Container10.ElementFont := efCSS;
    Container10.ElementPosition := epRelative;
    Container11.SetParentComponent(Self);
    Container11.Name := 'Container11';
    Container11.Left := 472;
    Container11.Top := 445;
    Container11.Width := 174;
    Container11.Height := 41;
    Container11.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container11.HeightStyle := ssAuto;
    Container11.WidthStyle := ssPercent;
    Container11.WidthPercent := 85;
    Container11.ChildOrder := 10;
    Container11.ElementFont := efCSS;
    Container11.ElementPosition := epRelative;
    btnTerminerledigt.SetParentComponent(Self);
    btnTerminerledigt.Name := 'btnTerminerledigt';
    btnTerminerledigt.Left := 361;
    btnTerminerledigt.Top := 505;
    btnTerminerledigt.Width := 96;
    btnTerminerledigt.Height := 25;
    btnTerminerledigt.Caption := 'Termin erledigt';
    btnTerminerledigt.ChildOrder := 12;
    btnTerminerledigt.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnTerminerledigt.ElementFont := efCSS;
    btnTerminerledigt.HeightStyle := ssAuto;
    btnTerminerledigt.WidthStyle := ssAuto;
    SetEvent(btnTerminerledigt, Self, 'OnClick', 'btnTerminerledigtClick');
    edtTerminerledigt.SetParentComponent(Self);
    edtTerminerledigt.Name := 'edtTerminerledigt';
    edtTerminerledigt.Left := 214;
    edtTerminerledigt.Top := 500;
    edtTerminerledigt.Width := 109;
    edtTerminerledigt.Height := 24;
    edtTerminerledigt.Margins.Left := 25;
    edtTerminerledigt.ChildOrder := 47;
    edtTerminerledigt.ElementClassName := 'form-control form-control-sm m-1';
    edtTerminerledigt.ElementFont := efCSS;
    edtTerminerledigt.HeightStyle := ssAuto;
    edtTerminerledigt.Text := 'edtTerminerledigt';
    edtTerminerledigt.DataField := 'TerminErledigt';
    edtTerminerledigt.DataSource := Source;
    edtAlarmBefore.SetParentComponent(Self);
    edtAlarmBefore.Name := 'edtAlarmBefore';
    edtAlarmBefore.AlignWithMargins := True;
    edtAlarmBefore.Left := 157;
    edtAlarmBefore.Top := 470;
    edtAlarmBefore.Width := 65;
    edtAlarmBefore.Height := 24;
    edtAlarmBefore.ElementClassName := 'form-control form-control-sm m-1';
    edtAlarmBefore.HeightStyle := ssAuto;
    edtAlarmBefore.AutoSize := False;
    edtAlarmBefore.BorderStyle := bsSingle;
    edtAlarmBefore.ChildOrder := 48;
    edtAlarmBefore.Color := clWhite;
    edtAlarmBefore.ElementFont := efCSS;
    edtAlarmBefore.Increment := 1;
    edtAlarmBefore.MaxValue := 60;
    edtAlarmBefore.MinValue := 1;
    edtAlarmBefore.Role := '';
    edtAlarmBefore.Value := 0;
    edtAlarmBefore.DataField := 'AlarmBefore';
    edtAlarmBefore.DataSource := Source;
    edtErinnerung.SetParentComponent(Self);
    edtErinnerung.Name := 'edtErinnerung';
    edtErinnerung.AlignWithMargins := True;
    edtErinnerung.Left := 8;
    edtErinnerung.Top := 358;
    edtErinnerung.Width := 131;
    edtErinnerung.Height := 24;
    edtErinnerung.Caption := 'Erinnerung / Min.';
    edtErinnerung.ChildOrder := 49;
    edtErinnerung.Color := clNone;
    edtErinnerung.ElementClassName := 'custom-control custom-checkbox m-1';
    edtErinnerung.ElementButtonClassName := 'custom-control-input';
    edtErinnerung.ElementLabelClassName := 'custom-control-label';
    edtErinnerung.ElementFont := efCSS;
    edtErinnerung.HeightStyle := ssAuto;
    edtErinnerung.State := cbUnchecked;
    edtErinnerung.WidthStyle := ssAuto;
    edtErinnerung.DataField := 'Alarm';
    edtErinnerung.DataSource := Source;
    Container12.SetParentComponent(Self);
    Container12.Name := 'Container12';
    Container12.Left := 472;
    Container12.Top := 492;
    Container12.Width := 174;
    Container12.Height := 41;
    Container12.ElementClassName := 'd-flex flex-wrap m-1';
    Container12.HeightStyle := ssAuto;
    Container12.WidthStyle := ssPercent;
    Container12.WidthPercent := 85;
    Container12.ChildOrder := 11;
    Container12.ElementFont := efCSS;
    Container12.ElementPosition := epRelative;
    DivTextIntern.SetParentComponent(Self);
    DivTextIntern.Name := 'DivTextIntern';
    DivTextIntern.Left := 546;
    DivTextIntern.Top := 539;
    DivTextIntern.Width := 100;
    DivTextIntern.Height := 41;
    DivTextIntern.ElementClassName := 'h6 ml-1';
    DivTextIntern.HeightStyle := ssAuto;
    DivTextIntern.WidthStyle := ssAuto;
    DivTextIntern.ChildOrder := 48;
    DivTextIntern.ElementFont := efCSS;
    DivTextIntern.Role := '';
    DivTextObjekt.SetParentComponent(Self);
    DivTextObjekt.Name := 'DivTextObjekt';
    DivTextObjekt.Left := 576;
    DivTextObjekt.Top := 586;
    DivTextObjekt.Width := 100;
    DivTextObjekt.Height := 41;
    DivTextObjekt.ElementClassName := 'h6 m-2';
    DivTextObjekt.HeightStyle := ssAuto;
    DivTextObjekt.WidthStyle := ssAuto;
    DivTextObjekt.ChildOrder := 48;
    DivTextObjekt.ElementFont := efCSS;
    DivTextObjekt.Role := '';
    DivTextAP.SetParentComponent(Self);
    DivTextAP.Name := 'DivTextAP';
    DivTextAP.Left := 576;
    DivTextAP.Top := 633;
    DivTextAP.Width := 100;
    DivTextAP.Height := 41;
    DivTextAP.ElementClassName := 'h6 m-2';
    DivTextAP.HeightStyle := ssAuto;
    DivTextAP.WidthStyle := ssAuto;
    DivTextAP.ChildOrder := 48;
    DivTextAP.ElementFont := efCSS;
    DivTextAP.Role := '';
    myDataSet.SetParentComponent(Self);
    myDataSet.Name := 'myDataSet';
    myDataSet.AfterApplyUpdates := myDataSetAfterApplyUpdates;
    myDataSet.AfterOpen := myDataSetAfterOpen;
    myDataSet.BeforeEdit := myDataSetBeforeEdit;
    myDataSet.BeforePost := myDataSetBeforePost;
    myDataSet.AfterPost := myDataSetAfterPost;
    SetEvent(myDataSet, Self, 'OnNewRecord', 'myDataSetNewRecord');
    myDataSet.EntitySetName := 'TermineNeu';
    myDataSet.Connection := DM2.XDataWebConnection1;
    myDataSet.SubpropsDepth := 1;
    myDataSet.QueryTop := 1;
    myDataSet.Left := 392;
    myDataSet.Top := 15;
    myDataSetArtikelnummer.SetParentComponent(myDataSet);
    myDataSetArtikelnummer.Name := 'myDataSetArtikelnummer';
    myDataSetArtikelnummer.FieldName := 'Artikelnummer';
    myDataSetUID.SetParentComponent(myDataSet);
    myDataSetUID.Name := 'myDataSetUID';
    myDataSetUID.FieldName := 'UID';
    myDataSetUID.Required := True;
    myDataSetUID.Size := 38;
    myDataSetBeginn.SetParentComponent(myDataSet);
    myDataSetBeginn.Name := 'myDataSetBeginn';
    myDataSetBeginn.FieldName := 'Beginn';
    myDataSetEnde.SetParentComponent(myDataSet);
    myDataSetEnde.Name := 'myDataSetEnde';
    myDataSetEnde.FieldName := 'Ende';
    myDataSetBetreff.SetParentComponent(myDataSet);
    myDataSetBetreff.Name := 'myDataSetBetreff';
    myDataSetBetreff.FieldName := 'Betreff';
    myDataSetBetreff.Size := 255;
    myDataSetTerminNotiz.SetParentComponent(myDataSet);
    myDataSetTerminNotiz.Name := 'myDataSetTerminNotiz';
    myDataSetTerminNotiz.FieldName := 'TerminNotiz';
    myDataSetTerminNotiz.Size := 255;
    myDataSetQuelle.SetParentComponent(myDataSet);
    myDataSetQuelle.Name := 'myDataSetQuelle';
    myDataSetQuelle.FieldName := 'Quelle';
    myDataSetQuelle.Size := 10;
    myDataSetGUIDOutlook.SetParentComponent(myDataSet);
    myDataSetGUIDOutlook.Name := 'myDataSetGUIDOutlook';
    myDataSetGUIDOutlook.FieldName := 'GUIDOutlook';
    myDataSetGUIDOutlook.Size := 250;
    myDataSetShape.SetParentComponent(myDataSet);
    myDataSetShape.Name := 'myDataSetShape';
    myDataSetShape.FieldName := 'Shape';
    myDataSetColor.SetParentComponent(myDataSet);
    myDataSetColor.Name := 'myDataSetColor';
    myDataSetColor.FieldName := 'Color';
    myDataSetAlarm.SetParentComponent(myDataSet);
    myDataSetAlarm.Name := 'myDataSetAlarm';
    myDataSetAlarm.FieldName := 'Alarm';
    myDataSetResource.SetParentComponent(myDataSet);
    myDataSetResource.Name := 'myDataSetResource';
    myDataSetResource.FieldName := 'Resource';
    myDataSetLastModificationTime.SetParentComponent(myDataSet);
    myDataSetLastModificationTime.Name := 'myDataSetLastModificationTime';
    myDataSetLastModificationTime.FieldName := 'LastModificationTime';
    myDataSetGeburtstag.SetParentComponent(myDataSet);
    myDataSetGeburtstag.Name := 'myDataSetGeburtstag';
    myDataSetGeburtstag.FieldName := 'Geburtstag';
    myDataSetAlarmBefore.SetParentComponent(myDataSet);
    myDataSetAlarmBefore.Name := 'myDataSetAlarmBefore';
    myDataSetAlarmBefore.FieldName := 'AlarmBefore';
    myDataSetPersonalnummer.SetParentComponent(myDataSet);
    myDataSetPersonalnummer.Name := 'myDataSetPersonalnummer';
    myDataSetPersonalnummer.FieldName := 'Personalnummer';
    myDataSetKundennummer.SetParentComponent(myDataSet);
    myDataSetKundennummer.Name := 'myDataSetKundennummer';
    myDataSetKundennummer.FieldName := 'Kundennummer';
    myDataSetKundennummer.Size := 13;
    myDataSetAllDayEventOutlook.SetParentComponent(myDataSet);
    myDataSetAllDayEventOutlook.Name := 'myDataSetAllDayEventOutlook';
    myDataSetAllDayEventOutlook.FieldName := 'AllDayEventOutlook';
    myDataSetTMSCaption.SetParentComponent(myDataSet);
    myDataSetTMSCaption.Name := 'myDataSetTMSCaption';
    myDataSetTMSCaption.FieldName := 'TMSCaption';
    myDataSetRECURRENCY.SetParentComponent(myDataSet);
    myDataSetRECURRENCY.Name := 'myDataSetRECURRENCY';
    myDataSetRECURRENCY.FieldName := 'RECURRENCY';
    myDataSetRECURRENCY.Size := 255;
    myDataSetMINTIME.SetParentComponent(myDataSet);
    myDataSetMINTIME.Name := 'myDataSetMINTIME';
    myDataSetMINTIME.FieldName := 'MINTIME';
    myDataSetMAXTIME.SetParentComponent(myDataSet);
    myDataSetMAXTIME.Name := 'myDataSetMAXTIME';
    myDataSetMAXTIME.FieldName := 'MAXTIME';
    myDataSetNachname.SetParentComponent(myDataSet);
    myDataSetNachname.Name := 'myDataSetNachname';
    myDataSetNachname.FieldName := 'Nachname';
    myDataSetNachname.Size := 40;
    myDataSetObjektReferenz.SetParentComponent(myDataSet);
    myDataSetObjektReferenz.Name := 'myDataSetObjektReferenz';
    myDataSetObjektReferenz.FieldName := 'ObjektReferenz';
    myDataSetObjektReferenz.Size := 13;
    myDataSetObjektnummer.SetParentComponent(myDataSet);
    myDataSetObjektnummer.Name := 'myDataSetObjektnummer';
    myDataSetObjektnummer.FieldName := 'Objektnummer';
    myDataSetObjektnummer.Size := 25;
    myDataSetTerminArt.SetParentComponent(myDataSet);
    myDataSetTerminArt.Name := 'myDataSetTerminArt';
    myDataSetTerminArt.FieldName := 'TerminArt';
    myDataSetBeginnDatum.SetParentComponent(myDataSet);
    myDataSetBeginnDatum.Name := 'myDataSetBeginnDatum';
    myDataSetBeginnDatum.FieldName := 'BeginnDatum';
    myDataSetBeginnUhrzeit.SetParentComponent(myDataSet);
    myDataSetBeginnUhrzeit.Name := 'myDataSetBeginnUhrzeit';
    myDataSetBeginnUhrzeit.FieldName := 'BeginnUhrzeit';
    myDataSetBeginnUhrzeit.DisplayFormat := 'hh:mm';
    myDataSetEndeDatum.SetParentComponent(myDataSet);
    myDataSetEndeDatum.Name := 'myDataSetEndeDatum';
    myDataSetEndeDatum.FieldName := 'EndeDatum';
    myDataSetEndeUhrzeit.SetParentComponent(myDataSet);
    myDataSetEndeUhrzeit.Name := 'myDataSetEndeUhrzeit';
    myDataSetEndeUhrzeit.FieldName := 'EndeUhrzeit';
    myDataSetEndeUhrzeit.DisplayFormat := 'hh:mm';
    myDataSetKalenderAnzeige.SetParentComponent(myDataSet);
    myDataSetKalenderAnzeige.Name := 'myDataSetKalenderAnzeige';
    myDataSetKalenderAnzeige.FieldName := 'KalenderAnzeige';
    myDataSetStundensatz.SetParentComponent(myDataSet);
    myDataSetStundensatz.Name := 'myDataSetStundensatz';
    myDataSetStundensatz.FieldName := 'Stundensatz';
    myDataSetStundensatz.DisplayFormat := '0.00';
    myDataSetDauerStunden.SetParentComponent(myDataSet);
    myDataSetDauerStunden.Name := 'myDataSetDauerStunden';
    myDataSetDauerStunden.FieldName := 'DauerStunden';
    myDataSetDauerStunden.DisplayFormat := '0.00 h';
    myDataSetgefahrenekm.SetParentComponent(myDataSet);
    myDataSetgefahrenekm.Name := 'myDataSetgefahrenekm';
    myDataSetgefahrenekm.FieldName := 'gefahrenekm';
    myDataSetAufwand.SetParentComponent(myDataSet);
    myDataSetAufwand.Name := 'myDataSetAufwand';
    myDataSetAufwand.FieldName := 'Aufwand';
    myDataSetAufwand.DisplayFormat := '#,##0.00';
    myDataSetabgerechnet.SetParentComponent(myDataSet);
    myDataSetabgerechnet.Name := 'myDataSetabgerechnet';
    myDataSetabgerechnet.FieldName := 'abgerechnet';
    myDataSetverteilt.SetParentComponent(myDataSet);
    myDataSetverteilt.Name := 'myDataSetverteilt';
    myDataSetverteilt.FieldName := 'verteilt';
    myDataSetTerminErledigt.SetParentComponent(myDataSet);
    myDataSetTerminErledigt.Name := 'myDataSetTerminErledigt';
    myDataSetTerminErledigt.FieldName := 'TerminErledigt';
    myDataSetAnsprechpartner.SetParentComponent(myDataSet);
    myDataSetAnsprechpartner.Name := 'myDataSetAnsprechpartner';
    myDataSetAnsprechpartner.FieldName := 'Ansprechpartner';
    myDataSetAnsprechpartner.Size := 50;
    myDataSetLocation.SetParentComponent(myDataSet);
    myDataSetLocation.Name := 'myDataSetLocation';
    myDataSetLocation.FieldName := 'Location';
    myDataSetLocation.Size := 100;
    myDataSetGoogleID.SetParentComponent(myDataSet);
    myDataSetGoogleID.Name := 'myDataSetGoogleID';
    myDataSetGoogleID.FieldName := 'GoogleID';
    myDataSetGoogleID.Size := 50;
    myDataSetGoogleCreatedUTC.SetParentComponent(myDataSet);
    myDataSetGoogleCreatedUTC.Name := 'myDataSetGoogleCreatedUTC';
    myDataSetGoogleCreatedUTC.FieldName := 'GoogleCreatedUTC';
    myDataSetGoogleUpdatedUTC.SetParentComponent(myDataSet);
    myDataSetGoogleUpdatedUTC.Name := 'myDataSetGoogleUpdatedUTC';
    myDataSetGoogleUpdatedUTC.FieldName := 'GoogleUpdatedUTC';
    myDataSetGoogleStatus.SetParentComponent(myDataSet);
    myDataSetGoogleStatus.Name := 'myDataSetGoogleStatus';
    myDataSetGoogleStatus.FieldName := 'GoogleStatus';
    myDataSetGoogleStatus.Size := 50;
    myDataSetVorname.SetParentComponent(myDataSet);
    myDataSetVorname.Name := 'myDataSetVorname';
    myDataSetVorname.FieldName := 'Vorname';
    myDataSetVorname.Size := 40;
    myDataSetPNachname.SetParentComponent(myDataSet);
    myDataSetPNachname.Name := 'myDataSetPNachname';
    myDataSetPNachname.FieldName := 'PNachname';
    myDataSetPNachname.Size := 40;
    myDataSetPVorname.SetParentComponent(myDataSet);
    myDataSetPVorname.Name := 'myDataSetPVorname';
    myDataSetPVorname.FieldName := 'PVorname';
    myDataSetPVorname.Size := 40;
    myDataSetArtikelueberschrift.SetParentComponent(myDataSet);
    myDataSetArtikelueberschrift.Name := 'myDataSetArtikelueberschrift';
    myDataSetArtikelueberschrift.FieldName := 'Artikelueberschrift';
    myDataSetArtikelueberschrift.Size := 50;
    myDataSetPFarbe.SetParentComponent(myDataSet);
    myDataSetPFarbe.Name := 'myDataSetPFarbe';
    myDataSetPFarbe.FieldName := 'PFarbe';
    myDataSetKontaktart.SetParentComponent(myDataSet);
    myDataSetKontaktart.Name := 'myDataSetKontaktart';
    myDataSetKontaktart.FieldName := 'Kontaktart';
    myDataSetKontaktart.Size := 25;
    Source.SetParentComponent(Self);
    Source.Name := 'Source';
    Source.DataSet := myDataSet;
    SetEvent(Source, Self, 'OnStateChange', 'SourceStateChange');
    Source.Left := 392;
    Source.Top := 63;
    dsArtikel.SetParentComponent(Self);
    dsArtikel.Name := 'dsArtikel';
    dsArtikel.AfterOpen := dsArtikelAfterOpen;
    dsArtikel.EntitySetName := 'Artikel';
    dsArtikel.Connection := DM2.XDataWebConnection1;
    dsArtikel.Left := 440;
    dsArtikel.Top := 87;
    dsArtikelArtikelnummer.SetParentComponent(dsArtikel);
    dsArtikelArtikelnummer.Name := 'dsArtikelArtikelnummer';
    dsArtikelArtikelnummer.FieldName := 'Artikelnummer';
    dsArtikelArtikelnummer.Required := True;
    dsArtikelArtikelnummer.Size := 25;
    dskontakt.SetParentComponent(Self);
    dskontakt.Name := 'dskontakt';
    dskontakt.AfterOpen := dskontaktAfterOpen;
    dskontakt.EntitySetName := 'Adressen';
    dskontakt.Connection := DM2.XDataWebConnection1;
    dskontakt.Left := 400;
    dskontakt.Top := 159;
    dskontaktKundennummer.SetParentComponent(dskontakt);
    dskontaktKundennummer.Name := 'dskontaktKundennummer';
    dskontaktKundennummer.FieldName := 'Kundennummer';
    dskontaktKundennummer.Required := True;
    dskontaktKundennummer.Size := 13;
    dskontaktNachname.SetParentComponent(dskontakt);
    dskontaktNachname.Name := 'dskontaktNachname';
    dskontaktNachname.FieldName := 'Nachname';
    dskontaktNachname.Size := 40;
    dskontaktVorname.SetParentComponent(dskontakt);
    dskontaktVorname.Name := 'dskontaktVorname';
    dskontaktVorname.FieldName := 'Vorname';
    dskontaktVorname.Size := 40;
    dskontaktAdresszeile2.SetParentComponent(dskontakt);
    dskontaktAdresszeile2.Name := 'dskontaktAdresszeile2';
    dskontaktAdresszeile2.FieldName := 'Adresszeile2';
    dskontaktAdresszeile2.Size := 40;
    dskontaktAdresszeile3.SetParentComponent(dskontakt);
    dskontaktAdresszeile3.Name := 'dskontaktAdresszeile3';
    dskontaktAdresszeile3.FieldName := 'Adresszeile3';
    dskontaktAdresszeile3.Size := 40;
    dskontaktStrasse.SetParentComponent(dskontakt);
    dskontaktStrasse.Name := 'dskontaktStrasse';
    dskontaktStrasse.FieldName := 'Strasse';
    dskontaktStrasse.Size := 40;
    dskontaktPostleitzahl.SetParentComponent(dskontakt);
    dskontaktPostleitzahl.Name := 'dskontaktPostleitzahl';
    dskontaktPostleitzahl.FieldName := 'Postleitzahl';
    dskontaktPostleitzahl.Size := 6;
    dskontaktOrt.SetParentComponent(dskontakt);
    dskontaktOrt.Name := 'dskontaktOrt';
    dskontaktOrt.FieldName := 'Ort';
    dskontaktOrt.Size := 40;
    dskontaktLaenderkuerzel.SetParentComponent(dskontakt);
    dskontaktLaenderkuerzel.Name := 'dskontaktLaenderkuerzel';
    dskontaktLaenderkuerzel.FieldName := 'Laenderkuerzel';
    dskontaktLaenderkuerzel.Size := 5;
    dskontaktLand.SetParentComponent(dskontakt);
    dskontaktLand.Name := 'dskontaktLand';
    dskontaktLand.FieldName := 'Land';
    dskontaktLand.Size := 40;
    dskontaktKilometer.SetParentComponent(dskontakt);
    dskontaktKilometer.Name := 'dskontaktKilometer';
    dskontaktKilometer.FieldName := 'Kilometer';
    dskontaktStundensatz.SetParentComponent(dskontakt);
    dskontaktStundensatz.Name := 'dskontaktStundensatz';
    dskontaktStundensatz.FieldName := 'Stundensatz';
    dsKArten.SetParentComponent(Self);
    dsKArten.Name := 'dsKArten';
    dsKArten.AfterOpen := dsKArtenAfterOpen;
    dsKArten.EntitySetName := 'kontaktart';
    dsKArten.Connection := DM2.XDataWebConnection1;
    dsKArten.Left := 400;
    dsKArten.Top := 215;
    dsKArtenKontaktart.SetParentComponent(dsKArten);
    dsKArtenKontaktart.Name := 'dsKArtenKontaktart';
    dsKArtenKontaktart.FieldName := 'Kontaktart';
    dsKArtenKontaktart.Required := True;
    dsKArtenKontaktart.Size := 25;
    dsKArtenArtikelnummer.SetParentComponent(dsKArten);
    dsKArtenArtikelnummer.Name := 'dsKArtenArtikelnummer';
    dsKArtenArtikelnummer.FieldName := 'Artikelnummer';
    dsKArtenArtikelnummer.Size := 25;
    dsObjekt.SetParentComponent(Self);
    dsObjekt.Name := 'dsObjekt';
    dsObjekt.AfterOpen := dsObjektAfterOpen;
    dsObjekt.EntitySetName := 'Objekte';
    dsObjekt.Connection := DM2.XDataWebConnection1;
    dsObjekt.Left := 400;
    dsObjekt.Top := 271;
    dsObjektObjektReferenz.SetParentComponent(dsObjekt);
    dsObjektObjektReferenz.Name := 'dsObjektObjektReferenz';
    dsObjektObjektReferenz.FieldName := 'ObjektReferenz';
    dsObjektObjektReferenz.Required := True;
    dsObjektObjektReferenz.Size := 13;
    dsObjektKurzbezeichnung.SetParentComponent(dsObjekt);
    dsObjektKurzbezeichnung.Name := 'dsObjektKurzbezeichnung';
    dsObjektKurzbezeichnung.FieldName := 'Kurzbezeichnung';
    dsObjektKurzbezeichnung.Size := 25;
    dsObjektObjektnummer.SetParentComponent(dsObjekt);
    dsObjektObjektnummer.Name := 'dsObjektObjektnummer';
    dsObjektObjektnummer.FieldName := 'Objektnummer';
    dsObjektObjektnummer.Size := 25;
    dsAP.SetParentComponent(Self);
    dsAP.Name := 'dsAP';
    dsAP.AfterOpen := dsAPAfterOpen;
    dsAP.EntitySetName := 'Ansprechpartner';
    dsAP.Connection := DM2.XDataWebConnection1;
    dsAP.Left := 400;
    dsAP.Top := 327;
    dsAPKundennummer.SetParentComponent(dsAP);
    dsAPKundennummer.Name := 'dsAPKundennummer';
    dsAPKundennummer.FieldName := 'Kundennummer';
    dsAPKundennummer.Required := True;
    dsAPAnsprechpartner.SetParentComponent(dsAP);
    dsAPAnsprechpartner.Name := 'dsAPAnsprechpartner';
    dsAPAnsprechpartner.FieldName := 'Ansprechpartner';
    dsAPAnsprechpartner.Size := 50;
    dsAPNachname.SetParentComponent(dsAP);
    dsAPNachname.Name := 'dsAPNachname';
    dsAPNachname.FieldName := 'Nachname';
    dsAPNachname.Size := 40;
    dsAPVorname.SetParentComponent(dsAP);
    dsAPVorname.Name := 'dsAPVorname';
    dsAPVorname.FieldName := 'Vorname';
    dsAPVorname.Size := 40;
    XDSlookupObjekt.SetParentComponent(Self);
    XDSlookupObjekt.Name := 'XDSlookupObjekt';
    XDSlookupObjekt.AfterOpen := XDSlookupObjektAfterOpen;
    XDSlookupObjekt.EntitySetName := 'Objekte';
    XDSlookupObjekt.Connection := DM2.XDataWebConnection1;
    XDSlookupObjekt.QueryTop := 1;
    XDSlookupObjekt.Left := 304;
    XDSlookupObjekt.Top := 64;
    XDSlookupObjektObjektReferenz.SetParentComponent(XDSlookupObjekt);
    XDSlookupObjektObjektReferenz.Name := 'XDSlookupObjektObjektReferenz';
    XDSlookupObjektObjektReferenz.FieldName := 'ObjektReferenz';
    XDSlookupObjektObjektReferenz.Required := True;
    XDSlookupObjektObjektReferenz.Size := 13;
    XDSlookupObjektKurzbezeichnung.SetParentComponent(XDSlookupObjekt);
    XDSlookupObjektKurzbezeichnung.Name := 'XDSlookupObjektKurzbezeichnung';
    XDSlookupObjektKurzbezeichnung.FieldName := 'Kurzbezeichnung';
    XDSlookupObjektKurzbezeichnung.Size := 25;
    XDSlookupObjektObjektnummer.SetParentComponent(XDSlookupObjekt);
    XDSlookupObjektObjektnummer.Name := 'XDSlookupObjektObjektnummer';
    XDSlookupObjektObjektnummer.FieldName := 'Objektnummer';
    XDSlookupObjektObjektnummer.Size := 25;
  finally
    lblTerminart.AfterLoadDFMValues;
    lblBeginn.AfterLoadDFMValues;
    lblEnde.AfterLoadDFMValues;
    lblBetreff.AfterLoadDFMValues;
    lblNotiz.AfterLoadDFMValues;
    lblAnsprechpartner.AfterLoadDFMValues;
    lblort.AfterLoadDFMValues;
    lblkm.AfterLoadDFMValues;
    lblMitarbeiter.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    lblKontakt.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    btnObjektwahl.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    btnSpeichern.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnWV.AfterLoadDFMValues;
    btnAktivitaet.AfterLoadDFMValues;
    comboKontaktart.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    PickDateBeginn.AfterLoadDFMValues;
    pickDateEnde.AfterLoadDFMValues;
    PickTimeBeginn.AfterLoadDFMValues;
    picktimeEnde.AfterLoadDFMValues;
    edtBetreff.AfterLoadDFMValues;
    edtNotiz.AfterLoadDFMValues;
    ContainerBeginn.AfterLoadDFMValues;
    ContainerEnde.AfterLoadDFMValues;
    Container4.AfterLoadDFMValues;
    Container5.AfterLoadDFMValues;
    Container6.AfterLoadDFMValues;
    btnKontaktZuordnen.AfterLoadDFMValues;
    Container7.AfterLoadDFMValues;
    Container8.AfterLoadDFMValues;
    comboap.AfterLoadDFMValues;
    edtOrt.AfterLoadDFMValues;
    edtkm.AfterLoadDFMValues;
    Container9.AfterLoadDFMValues;
    Container10.AfterLoadDFMValues;
    Container11.AfterLoadDFMValues;
    btnTerminerledigt.AfterLoadDFMValues;
    edtTerminerledigt.AfterLoadDFMValues;
    edtAlarmBefore.AfterLoadDFMValues;
    edtErinnerung.AfterLoadDFMValues;
    Container12.AfterLoadDFMValues;
    DivTextIntern.AfterLoadDFMValues;
    DivTextObjekt.AfterLoadDFMValues;
    DivTextAP.AfterLoadDFMValues;
    myDataSet.AfterLoadDFMValues;
    myDataSetArtikelnummer.AfterLoadDFMValues;
    myDataSetUID.AfterLoadDFMValues;
    myDataSetBeginn.AfterLoadDFMValues;
    myDataSetEnde.AfterLoadDFMValues;
    myDataSetBetreff.AfterLoadDFMValues;
    myDataSetTerminNotiz.AfterLoadDFMValues;
    myDataSetQuelle.AfterLoadDFMValues;
    myDataSetGUIDOutlook.AfterLoadDFMValues;
    myDataSetShape.AfterLoadDFMValues;
    myDataSetColor.AfterLoadDFMValues;
    myDataSetAlarm.AfterLoadDFMValues;
    myDataSetResource.AfterLoadDFMValues;
    myDataSetLastModificationTime.AfterLoadDFMValues;
    myDataSetGeburtstag.AfterLoadDFMValues;
    myDataSetAlarmBefore.AfterLoadDFMValues;
    myDataSetPersonalnummer.AfterLoadDFMValues;
    myDataSetKundennummer.AfterLoadDFMValues;
    myDataSetAllDayEventOutlook.AfterLoadDFMValues;
    myDataSetTMSCaption.AfterLoadDFMValues;
    myDataSetRECURRENCY.AfterLoadDFMValues;
    myDataSetMINTIME.AfterLoadDFMValues;
    myDataSetMAXTIME.AfterLoadDFMValues;
    myDataSetNachname.AfterLoadDFMValues;
    myDataSetObjektReferenz.AfterLoadDFMValues;
    myDataSetObjektnummer.AfterLoadDFMValues;
    myDataSetTerminArt.AfterLoadDFMValues;
    myDataSetBeginnDatum.AfterLoadDFMValues;
    myDataSetBeginnUhrzeit.AfterLoadDFMValues;
    myDataSetEndeDatum.AfterLoadDFMValues;
    myDataSetEndeUhrzeit.AfterLoadDFMValues;
    myDataSetKalenderAnzeige.AfterLoadDFMValues;
    myDataSetStundensatz.AfterLoadDFMValues;
    myDataSetDauerStunden.AfterLoadDFMValues;
    myDataSetgefahrenekm.AfterLoadDFMValues;
    myDataSetAufwand.AfterLoadDFMValues;
    myDataSetabgerechnet.AfterLoadDFMValues;
    myDataSetverteilt.AfterLoadDFMValues;
    myDataSetTerminErledigt.AfterLoadDFMValues;
    myDataSetAnsprechpartner.AfterLoadDFMValues;
    myDataSetLocation.AfterLoadDFMValues;
    myDataSetGoogleID.AfterLoadDFMValues;
    myDataSetGoogleCreatedUTC.AfterLoadDFMValues;
    myDataSetGoogleUpdatedUTC.AfterLoadDFMValues;
    myDataSetGoogleStatus.AfterLoadDFMValues;
    myDataSetVorname.AfterLoadDFMValues;
    myDataSetPNachname.AfterLoadDFMValues;
    myDataSetPVorname.AfterLoadDFMValues;
    myDataSetArtikelueberschrift.AfterLoadDFMValues;
    myDataSetPFarbe.AfterLoadDFMValues;
    myDataSetKontaktart.AfterLoadDFMValues;
    Source.AfterLoadDFMValues;
    dsArtikel.AfterLoadDFMValues;
    dsArtikelArtikelnummer.AfterLoadDFMValues;
    dskontakt.AfterLoadDFMValues;
    dskontaktKundennummer.AfterLoadDFMValues;
    dskontaktNachname.AfterLoadDFMValues;
    dskontaktVorname.AfterLoadDFMValues;
    dskontaktAdresszeile2.AfterLoadDFMValues;
    dskontaktAdresszeile3.AfterLoadDFMValues;
    dskontaktStrasse.AfterLoadDFMValues;
    dskontaktPostleitzahl.AfterLoadDFMValues;
    dskontaktOrt.AfterLoadDFMValues;
    dskontaktLaenderkuerzel.AfterLoadDFMValues;
    dskontaktLand.AfterLoadDFMValues;
    dskontaktKilometer.AfterLoadDFMValues;
    dskontaktStundensatz.AfterLoadDFMValues;
    dsKArten.AfterLoadDFMValues;
    dsKArtenKontaktart.AfterLoadDFMValues;
    dsKArtenArtikelnummer.AfterLoadDFMValues;
    dsObjekt.AfterLoadDFMValues;
    dsObjektObjektReferenz.AfterLoadDFMValues;
    dsObjektKurzbezeichnung.AfterLoadDFMValues;
    dsObjektObjektnummer.AfterLoadDFMValues;
    dsAP.AfterLoadDFMValues;
    dsAPKundennummer.AfterLoadDFMValues;
    dsAPAnsprechpartner.AfterLoadDFMValues;
    dsAPNachname.AfterLoadDFMValues;
    dsAPVorname.AfterLoadDFMValues;
    XDSlookupObjekt.AfterLoadDFMValues;
    XDSlookupObjektObjektReferenz.AfterLoadDFMValues;
    XDSlookupObjektKurzbezeichnung.AfterLoadDFMValues;
    XDSlookupObjektObjektnummer.AfterLoadDFMValues;
  end;
end;

end.
