{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.EditAutocomplete;

{$modeswitch externalclass}

interface

uses
  Classes, SysUtils, Types, WEBLib.Controls, WEBLib.StdCtrls, WEBLib.Graphics,
  Web, JS;

type
  TEditAutoCompleteLookupType = (ltFirstCharacter, ltAnywhere);

  TEditAutoCompleteRenderItemEventArgs = class(TPersistent)
  private
    FItemElement: TJSHTMLElement;
    FItemIndex: Integer;
  public
    property ItemIndex: Integer read FItemIndex write FItemIndex;
    property ItemElement: TJSHTMLElement read FItemElement write FItemElement;
  end;

  TEditAutoCompleteRenderItemEvent = procedure(Sender: TObject; Args: TEditAutoCompleteRenderItemEventArgs) of object;

  TEditAutoCompleteSelectEventArgs = class(TPersistent)
  private
    FItemIndex: Integer;
  public
    property ItemIndex: Integer read FItemIndex write FItemIndex;
  end;

  TEditAutoCompleteSelectEvent = procedure(Sender: TObject; Args: TEditAutoCompleteSelectEventArgs) of object;

  TEditAutoComplete = class(TCustomInput)
  private
    FText: string;
    FItemIndex: Integer;
    FItems: TStringList;
    FPopupHeight: Integer;
    FActiveItemClassName: string;
    FPopupClassName: string;
    FItemClassName: string;
    FOnRenderItem: TEditAutoCompleteRenderItemEvent;
    FOnSelect: TEditAutoCompleteSelectEvent;
    FTextHint: string;
    FReadOnly: boolean;
    FCharCase: TEditCharCase;
    FOnChange: TNotifyEvent;
    FLookupCaseSensitive: Boolean;
    FlookupMinLength: Integer;
    FLookupType: TEditAutoCompleteLookupType;
    procedure AddScript(Script: string);
    function GetText: string;
    procedure SetText(const Value: string);
    procedure SetItems(const Value: TStringList);
    function GetItemIndex: Integer;
    procedure SetItemIndex(const Value: Integer);
    procedure SetPopupHeight(const Value: Integer);
    procedure SetActiveItemClassName(const Value: string);
    procedure SetItemClassName(const Value: string);
    procedure SetPopupClassName(const Value: string);
    procedure SetTextHint(const Value: string);
    procedure SetReadOnly(const Value: boolean);
    procedure SetCharcase(const Value: TEditCharCase);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetLookupCaseSensitive(const Value: Boolean);
    procedure SetLookupMinLength(const Value: Integer);
    procedure SetLookupType(const Value: TEditAutoCompleteLookupType);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    function HandleRenderItem(Item: TJSHTMLElement; Index: Integer): TJSHTMLElement;
    function HandleSelect(Index: Integer): Boolean;
    function HandleDoChange(Event: TJSMouseEvent): Boolean; virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetFocus; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    procedure Change; virtual;
    procedure Refresh; virtual;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle;
    property Color;
    property CharCase: TEditCharCase read FCharCase write SetCharcase default wecNormal;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property Margins;
    property ReadOnly: boolean read FReadOnly write SetReadOnly default False;
    property ShowFocus;
    property ShowHint;
    property TextHint: string read FTextHint write SetTextHint;
    property TabStop;
    property TabOrder;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;

    property Items: TStringList read FItems write SetItems;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property Text: string read GetText write SetText;
    property PopupHeight: Integer read FPopupHeight write SetPopupHeight default 0;
    property PopupClassName: string read FPopupClassName write SetPopupClassName;
    property ItemClassName: string read FItemClassName write SetItemClassName;
    property ActiveItemClassName: string read FActiveItemClassName write SetActiveItemClassName;
    property LookupType: TEditAutoCompleteLookupType read FLookupType write SetLookupType default ltFirstCharacter;
    property LookupCaseSensitive: Boolean read FLookupCaseSensitive write SetLookupCaseSensitive default False;
    property LookupMinLength: Integer read FlookupMinLength write SetLookupMinLength default 1;

    property OnRenderItem: TEditAutoCompleteRenderItemEvent read FOnRenderItem write FOnRenderItem;
    property OnSelect: TEditAutoCompleteSelectEvent read FOnSelect write FOnSelect;
  end;

  TWebEditAutoComplete = class(TEditAutoComplete);

implementation


{ TEditAutoComplete }

procedure TEditAutoComplete.BindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementInputHandle) then
  begin
    eh := ElementInputHandle;

    eh.addEventListener('change',@HandleDoChange);
    eh.addEventListener('click',@HandleDoClick);
    eh.addEventListener('dblclick',@HandleDoDblClick);
    eh.addEventListener('mousedown',@HandleDoMouseDown);
    eh.addEventListener('mouseup',@HandleDoMouseUp);
    eh.addEventListener('mousemove',@HandleDoMouseMove);
    eh.addEventListener('mouseleave',@HandleDoMouseLeave);
    eh.addEventListener('mouseenter',@HandleDoMouseEnter);
    eh.addEventListener('keydown',@HandleDoKeyDown);
    eh.addEventListener('keyup',@HandleDoKeyUp);
    eh.addEventListener('keypress',@HandleDoKeyPress);
    eh.addEventListener('focus',@HandleDoEnter);
    eh.addEventListener('blur',@HandleDoExit);
  end;
end;

procedure TEditAutoComplete.Change;
begin
  if Assigned(ElementHandle) then
    FText := ElementInputHandle.value;

  if Assigned(OnChange) then
    OnChange(Self);
end;

function TEditAutoComplete.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TEditAutoComplete.CreateInitialize;
begin
  FItems := TStringList.Create;
  FText := '';
  FItemIndex := -1;
  FPopupHeight := 0;
  FPopupClassName := '';
  FItemClassName := '';
  FActiveItemClassName := '';
  FLookupType := ltFirstCharacter;
  FLookupMinLength := 1;
  FLookupCaseSensitive := False;

  inherited;

  TabStop := True;
  ReadOnly := False;
  ElementFont := efProperty;
  CharCase := wecNormal;
  Width := 121;
  Height := 25;
end;

destructor TEditAutoComplete.Destroy;
begin
  FItems.Free;
  inherited;
end;

function TEditAutoComplete.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(ElementHandle.childNodes[0]);
end;

function TEditAutoComplete.GetItemIndex: Integer;
begin
  Result := FItemIndex;

  if not Assigned(ElementHandle) then
    Exit;

  Result := Items.IndexOf(Text);
end;

function TEditAutoComplete.GetText: string;
var
  input: TJSHTMLInputElement;
begin
  Result := FText;

  if not Assigned(ElementHandle) then
    Exit;

  input := TJSHTMLInputElement(ElementHandle.childNodes[0]);
  if Assigned(input) then
    Result := input.value;
end;

function TEditAutoComplete.HandleDoChange(Event: TJSMouseEvent): Boolean;
begin
  Change;
  Result := True;
end;

function TEditAutoComplete.HandleRenderItem(Item: TJSHTMLElement; Index: Integer): TJSHTMLElement;
var
  Args: TEditAutoCompleteRenderItemEventArgs;
begin
  if Assigned(OnRenderItem) then
  begin
    Args := TEditAutoCompleteRenderItemEventArgs.Create;
    Args.ItemElement := Item;
    Args.ItemIndex := Index;
    OnRenderItem(Self, Args);
    Args.Free;
  end;
  Result := Item;
end;

function TEditAutoComplete.HandleSelect(Index: Integer): Boolean;
var
  Args: TEditAutoCompleteSelectEventArgs;
begin
  if Assigned(OnSelect) then
  begin
    Args := TEditAutoCompleteSelectEventArgs.Create;
    Args.ItemIndex := Index;
    OnSelect(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TEditAutoComplete.Refresh;
begin
 //
end;

procedure TEditAutoComplete.SetActiveItemClassName(const Value: string);
begin
  if FActiveItemClassName <> Value then
  begin
    FActiveItemClassName := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetCharcase(const Value: TEditCharCase);
begin
  if FCharCase <> Value then
  begin
    FCharCase := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetFocus;
var
  input: TJSHTMLInputElement;
begin
  input := TJSHTMLInputElement(ElementHandle.childNodes[0]);
  if Assigned(input) then
  begin
    input.focus();
  end
  else
    inherited;
end;

procedure TEditAutoComplete.SetItemClassName(const Value: string);
begin
  if FItemClassName <> Value then
  begin
    FItemClassName := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetItemIndex(const Value: Integer);
begin
  if (FItemIndex <> Value) and (Value < Items.Count) then
  begin
    FItemIndex := Value;
    if FItemIndex >= 0 then
      Text := Items[FItemIndex]
    else
      Text := '';
    UpdateElement;
  end;

  if Value = -100 then
  begin
    HandleRenderItem(nil,-100);
    HandleSelect(-100);
  end;

end;

procedure TEditAutoComplete.SetItems(const Value: TStringList);
begin
  FItems.Assign(Value);
end;

procedure TEditAutoComplete.SetLookupCaseSensitive(const Value: Boolean);
begin
  if FlookupCaseSensitive <> Value then
  begin
    FLookupCaseSensitive := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetLookupMinLength(const Value: Integer);
begin
  if (FLookupMinLength <> Value) and (Value > 0) then
  begin
    FlookupMinLength := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetLookupType(
  const Value: TEditAutoCompleteLookupType);
begin
  if FLookupType <> Value then
  begin
    FLookupType := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetPopupClassName(const Value: string);
begin
  if FPopupClassName <> Value then
  begin
    FPopupClassName := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetPopupHeight(const Value: Integer);
begin
  if FPopupHeight <> Value then
  begin
    FPopupHeight := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetReadOnly(const Value: boolean);
begin
  if FReadOnly <> Value then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.SetTextHint(const Value: string);
begin
  if FTextHint <> Value then
  begin
    FTextHint := Value;
    UpdateElement;
  end;
end;

procedure TEditAutoComplete.AddScript(Script: string);
begin
  asm
    var scrObj = document.createElement('script');
    scrObj.innerHTML = Script;
    document.head.appendChild(scrObj);
  end;
end;

{$HINTS OFF}
procedure TEditAutoComplete.UpdateElement;
var
  elid, sstyle: string;
  classpopup, classitem, classactive: string;
  newel: TJSHTMLElement;
  itemdata: TJSArray;
  I: Integer;
  spopup, tabindex: string;
  cl: TObject;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not Assigned(ElementHandle) then
    Exit;

  elid := ElementID + '_input';

  if PopupClassName = '' then
    classpopup := ElementID + '_autocomplete-items'
  else
    classpopup := PopupClassName;

  if ItemClassName = '' then
    classitem := ElementID + '_autocomplete-item'
  else
    classitem := ItemClassName;

  if ActiveItemClassName = '' then
    classactive := ElementID + '_active-item'
  else
    classactive := ActiveItemClassName;

  ElementHandle.style.setProperty('overflow', 'visible');
  ElementHandle.setAttribute('tabindex', '-1');
  ElementHandle.removeAttribute('class');
  while ElementHandle.hasChildNodes do
    ElementHandle.removeChild(ElementHandle.firstChild);

  newel := TJSHTMLElement(document.createElement('input'));
  if ElementClassName <> '' then
    newel.setAttribute('class', ElementClassName);
  newel.setAttribute('type', 'text');
  newel.setAttribute('id', elid);
  newel.setAttribute('autocomplete','off');
  newel.style.setProperty('width', '100%');
  newel.style.setProperty('height', '100%');

  if Enabled then
    newel.removeAttribute('disabled')
  else
    newel.setAttribute('disabled', 'true');

  if ReadOnly then
    newel.setAttribute('readonly', 'true')
  else
    newel.removeAttribute('readonly');

  if TabStop then
    tabindex := IntToStr(TabOrder)
  else
    tabindex := '-1';
  newel.setAttribute('tabindex', tabindex);

  newel.setAttribute('placeholder', TextHint);

  newel.style.setProperty('background-color', ColorToHtml(color));

  if ElementClassName = '' then
  begin
    if Enabled and (ElementFont = efProperty) then
      newel.style.setProperty('color', ColorToHtml(Font.Color));
  end;

  SetHTMLElementFont(newel, Font, not ((ElementClassName = '') and (ElementFont = efProperty)));

  case CharCase of
    wecUpperCase: newel.style.setProperty('text-transform', 'uppercase');
    wecLowerCase: newel.style.setProperty('text-transform', 'lowercase');
    wecMixedCase: newel.style.setProperty('text-transform', 'capitalize');
    wecNormal: newel.style.setProperty('text-transform', 'initial');
  end;

//  newel.style.setProperty('width', IntToStr(Width) + 'px');
//  newel.style.setProperty('height', IntToStr(Height) + 'px');
  newel.style.setProperty('box-sizing', 'border-box');
  newel.setAttribute('value', Text);
  ElementHandle.appendChild(newel);

  newel := TJSHTMLElement(document.createElement('input'));
  newel.setAttribute('type', 'hidden');
  newel.setAttribute('id', elid + 'index');
  ElementHandle.appendChild(newel);

  BindEvents;

  if PopupHeight > 0 then
  begin
    spopup :=
      '  max-height: ' + IntToStr(PopupHeight) + 'px;' + #13 +
      '  overflow: auto;' + #13;
  end;

  sstyle :=
    '#' + ElementID + ' input[type=text] {' + #13 +
    '  width: 100%;' + #13 +
    '}' + #13 +

    '.' + classpopup + ' {' + #13 +
    '  position: absolute;' + #13 +
    '  z-index: 99;' + #13 +
    '  top: 100%;' + #13 +
    '  min-width: 100%;' + #13 +
//    '  width: 100%;' + #13 +
    '  left: 0;' + #13 +
//    '  right: 0;' + #13 +
    spopup;

  if PopupClassName = '' then
  begin
    sstyle := sstyle +
      '  background-color: #fff;' + #13 +
      '  border: 1px solid #d4d4d4;'
  end;

  sstyle := sstyle +
    '}' + #13;

  if ItemClassName = '' then
  begin
    sstyle := sstyle +
      '.' + classitem + ' {' + #13 +
      '  padding: 10px;' + #13 +
      '  cursor: pointer;' + #13 +
      '  border-bottom: 1px solid #d4d4d4;' + #13 +
      '}' + #13 +

      '.' + classitem + ':hover {' + #13 +
      '  background-color: #e9e9e9 !important;' + #13 +
      '}' + #13;
  end;

  if ActiveItemClassName = '' then
  begin
    sstyle := sstyle +
      '.' + classactive + ' {' + #13 +
      '  background-color: #e9e9e9 !important;' + #13 +
      '  color: #000;' + #13 +
      '}';
  end;

  AddInstanceStyle(sstyle);

  AddScript(
    'function doautocomplete(inp, hinp, arr, myclass) {' + #13 +
    '  var currentFocus;' + #13 +
    '  inp.addEventListener("input", function(e) {' + #13 +
    '      var a, b, i, h, lookup, minlength, cs, found, val = this.value;' + #13 +
    '      lookup = ' + IntToStr(Ord(LookupType)) + ';' + #13 +
    '      minlength = ' + IntToStr(LookupMinLength) + ';' + #13 +
    '      cs = ' + BoolToStr(LookupCaseSensitive) + ';' + #13 +

    '      ' + elid + 'closeAllLists();' + #13 +

    '      if (!val) { return false;}' + #13 +
    '      if (val.length < minlength) { return false;}' + #13 +

    '      currentFocus = -1;' + #13 +
    '      a = document.createElement("DIV");' + #13 +
    '      a.setAttribute("id", this.id + "autocomplete-list");' + #13 +
    '      a.setAttribute("class", "' + classpopup + '");' + #13 +
    '      a.addEventListener("mousedown", function (e) {' + #13 +
    '           e.preventDefault();' + #13 +
    '      });' + #13 +
    '      this.parentNode.appendChild(a);' + #13 +
    '      for (i = 0; i < arr.length; i++) {' + #13 +
    '        var q = val;' + #13 +
    '        var l = arr[i];' + #13 +
    '        var s = l;' + #13 +
    '        if (!cs) {' + #13 +
    '          q = q.toUpperCase();' + #13 +
    '          l = l.toUpperCase();' + #13 +
    '        }' + #13 +

    '        found = false;' + #13 +
    '        if ((lookup == 0) && (l.substr(0, q.length) == q)) {' + #13 +
    '          found = true;' + #13 +
    '          s = "<strong>" + arr[i].substr(0, val.length) + "</strong>";' + #13 +
    '          s += arr[i].substr(val.length);' + #13 +
    '        }' + #13 +
    '        else if ((lookup == 1) && (l.indexOf(q) >= 0)) {' + #13 +
    '          found = true;' + #13 +
    '          s = arr[i].substr(0, l.indexOf(q));' + #13 +
    '          s += "<strong>" + arr[i].substr(l.indexOf(q), val.length) + "</strong>";' + #13 +
    '          s += arr[i].substr(l.indexOf(q) + val.length);' + #13 +
    '        }' + #13 +

    '        if (found) {' + #13 +
    '          b = document.createElement("DIV");' + #13 +
    '          b.setAttribute("id", this.id + "|" + i);' + #13 +
    '          b.setAttribute("class", "' + classitem + '");' + #13 +
    '          b.innerHTML = s;' + #13 +
    '          b = myclass.HandleRenderItem(b, i);'+
    '          b.addEventListener("click", function(e) {' + #13 +
    '              var selindex = this.id.substring(this.id.indexOf("|") + 1, this.id.length);' + #13 +
    '              inp.value = arr[selindex]' + #13 +
    '              inp.focus();' + #13 +
    '              hinp.value = selindex;' + #13 +
    '              myclass.HandleSelect(selindex);'+
    '              ' + elid + 'closeAllLists();' + #13 +
    '          });' + #13 +
    '          a.appendChild(b);' + #13 +
    '        }' + #13 +
    '      }' + #13 +
    '      if (a.childNodes.length <= 0) ' + elid + 'closeAllLists();'+
    '  });' + #13 +

    '  inp.addEventListener("blur", function(e) {' + #13 +
    '       ' + elid + 'closeAllLists(e.target, true);' + #13 +
    '  });' + #13 +

    '  inp.addEventListener("keydown", function(e) {' + #13 +
    '      var popup = document.getElementById(this.id + "autocomplete-list");' + #13 +
    '      var x;' + #13 +
    '      if (popup) x = popup.getElementsByTagName("div");' + #13 +
    '      if (x) {' + #13 +
    '        if (e.keyCode == 27) {' + #13 +
    '         ' + elid + 'closeAllLists(e.target, true);' + #13 +
    '        } else if (e.keyCode == 40) {' + #13 +
    '          currentFocus++;' + #13 +
    '          ' + elid + 'addActive(x);' + #13 +
    '          if ((x[currentFocus].offsetTop + x[currentFocus].offsetHeight) > (popup.offsetHeight + popup.scrollTop))' +
    '            popup.scrollTop = ((x[currentFocus].offsetTop + x[currentFocus].offsetHeight) - popup.offsetHeight);' + #13 +
    '        } else if (e.keyCode == 38) { ' + #13 +
    '          currentFocus--;' + #13 +
    '          ' + elid + 'addActive(x);' + #13 +
    '          if ((x[currentFocus].offsetTop) < (popup.scrollTop))' +
    '            popup.scrollTop = (x[currentFocus].offsetTop);' + #13 +
    '        } else if (e.keyCode == 13) {' + #13 +
    '          e.preventDefault();' + #13 +
    '          if (currentFocus > -1) {' + #13 +
    '            if (x) x[currentFocus].click();' + #13 +
    '          } else { '+elid +'closeAllLists(e.target,true);}' + #13 +
    '        }' + #13 +
    '      }' + #13 +
    '  });' + #13 +
    '  function ' + elid + 'addActive(x) {' + #13 +
    '    if (!x) return false;' + #13 +
    '    ' + elid + 'removeActive(x);' + #13 +
    '    if (currentFocus >= x.length) currentFocus = x.length - 1;' + #13 +
    '    if (currentFocus < 0) currentFocus = 0;' + #13 +
    '    x[currentFocus].classList.add("' + classactive + '");' + #13 +
    '  }' + #13 +
    '  function ' + elid + 'removeActive(x) {' + #13 +
    '    for (var i = 0; i < x.length; i++) {' + #13 +
    '      x[i].classList.remove("' + classactive + '");' + #13 +
    '    }' + #13 +
    '  }' + #13 +
    '  function ' + elid + 'closeAllLists(elmnt, doblur) {' + #13 +
    '    var x = document.getElementsByClassName("' + classpopup + '");' + #13 +
    '    var isinput = (elmnt != inp);' + #13 +
    '    if (doblur) isinput = (elmnt == inp);' + #13 +
    '    for (var i = 0; i < x.length; i++) {' + #13 +
    '      if (elmnt != x[i] && isinput) {' + #13 +
    '        x[i].parentNode.removeChild(x[i]);' + #13 +
    '      }' + #13 +
    '    }' + #13 +
    '  }' + #13 +
    '  document.addEventListener("click", function (e) {' + #13 +
    '       ' + elid + 'closeAllLists(e.target);' + #13 +
    '  });' + #13 +
    '}'
  );

  itemdata := TJSArray.new;
  for I := 0 to Items.Count - 1 do
  begin
    itemdata.push(Items[I]);
  end;

  cl := Self;
  asm
    //cl = this;
    var el = document.getElementById(elid);
    var elindex = document.getElementById(elid + 'index');
    if (el && elindex)
      doautocomplete(el, elindex, itemdata, cl);
  end;
end;
{$HINTS ON}

end.
