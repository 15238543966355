unit Bcl.SysUtils;

{$I Bcl.inc}

interface

function TryStrToNumber(const S: string; out Value: double): Boolean;
function NumberToStr(const Value: double): string; {$IFDEF PAS2JS}assembler;{$ENDIF}

implementation

uses
  {$IFDEF PAS2JS}
  JS;
  {$ELSE}
  SysUtils;
  {$ENDIF}

function TryStrToNumber(const S: string; out Value: double): Boolean;
{$IFDEF PAS2JS}
var
  J: JSValue;
begin
  J := parseFloat(S);
  Result := not jsIsNaN(J);
  if Result then
    Value := double(J);
end;
{$ELSE}
var
  FS: TFormatSettings;
begin
  FS.DecimalSeparator := '.';
  FS.ThousandSeparator := #0;
  Result := TryStrToFloat(S, Value);
end;
{$ENDIF}

{$IFDEF PAS2JS}
function NumberToStr(const Value: double): string; assembler;
asm
  return Value.toString();
end;
{$ELSE}
function NumberToStr(const Value: double): string;
var
  FS: TFormatSettings;
begin
  FS.DecimalSeparator := '.';
  FS.ThousandSeparator := #0;
  Result := FloatToStr(Value);
end;
{$ENDIF}

end.
