unit unit_Anrufe;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, WEBLib.Buttons, WEBLib.FlexControls, WEBLib.WebCtrls;

type
  TfrmAnrufe = class(TForm)
    xdsAnrufe: TXDataWebDataSet;
    xdsAnrufeID: TStringField;
    xdsAnrufeAnrufTyp: TIntegerField;
    xdsAnrufeDatum: TDateTimeField;
    xdsAnrufeRufnummer: TStringField;
    xdsAnrufeNameFritzBox: TStringField;
    xdsAnrufeNebenstelle: TStringField;
    xdsAnrufeAnrufArt: TStringField;
    xdsAnrufeDauer: TDateTimeField;
    xdsAnrufeKundennummer: TStringField;
    xdsAnrufeTeilnehmer: TStringField;
    xdsAnrufeNotiz: TStringField;
    xdsAnrufebis: TDateTimeField;
    xdsAnrufeDauerMinuten: TIntegerField;
    xdsAnrufeJahr: TIntegerField;
    xdsAnrufeMonat: TIntegerField;
    xdsAnrufeTag: TIntegerField;
    xdsAnrufeMonatJahr: TDateTimeField;
    xdsAnrufeDatumKurz: TDateTimeField;
    xdsAnrufeAPID: TStringField;
    xdsAnrufeFBox: TStringField;
    xdsAnrufeabrechnen: TBooleanField;
    xdsAnrufeAbgerechnetAm: TDateTimeField;
    xdsAnrufeStundensatz: TFloatField;
    xdsAnrufeKosten: TFloatField;
    xdsAnrufeerstellt: TDateTimeField;
    xdsAnrufegeaendert: TDateTimeField;
    xdsAnrufeAnrufArtText: TStringField;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    Container3: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure xdsAnrufeCalcFields(DataSet: TDataSet);
    procedure xdsAnrufeAfterOpen(DataSet: TDataSet);
  protected
    procedure LoadDFMValues; override;

    public
    buttonzurueck:TButton;
    EinzelCard:THTMLDiv;
    procedure buttonzurueckclick(Sender:TObject);
    procedure EinzelCardclick(Sender:TObject);
  end;

var
  frmAnrufe: TfrmAnrufe;

implementation
  uses unit1, unit2, unit_EinzelAnruf;

procedure TfrmAnrufe.buttonzurueckclick(Sender:TObject);
begin
  xdsAnrufe.Close;
  self.close;
end;

procedure TfrmAnrufe.EinzelCardclick(Sender:TObject);
var
  EinzelAnruf:TEinzelAnruf;
  MyMerkerAnruf:string;
begin
  myMerkerAnruf:=(sender as THTMLDiv).hint;
  Container3.HTML.text:='';
  EinzelAnruf := TEinzelAnruf.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEinzelAnruf).MerkerAnruf:=myMerkerAnruf;
    end
    );

  EinzelAnruf.ShowModal(procedure(AValue: TModalResult)
    begin
      xdsAnrufe.close;
      xdsAnrufe.Load;
    end
    );
end;



procedure TfrmAnrufe.WebFormShow(Sender: TObject);
begin
  xdsAnrufe.Load;
end;

procedure TfrmAnrufe.xdsAnrufeAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
  EinzelKarte:string;
begin
  xdsanrufe.First;
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Anrufe</h5></nav>';

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info m-1 text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;

  while not xdsanrufe.eof do
  begin
    Einzelkarte:='<div class="card-header"><h5>'+formatdatetime('DD.MM.YYYY hh:mm:ss',xdsAnrufeDatum.asdatetime)+'<br>' +xdsAnrufeRufnummer.asstring+'</h5></div>';
    Einzelkarte:=Einzelkarte+'<div class="card-body "><h4>'+xdsAnrufeTeilnehmer.asstring+'</h4><br>';
    Einzelkarte:=Einzelkarte+'<i>'+xdsAnrufeAnrufArtText.asstring+'</i><br>';
    Einzelkarte:=Einzelkarte+'Dauer: '+xdsAnrufeDauerMinuten.asstring+' Minuten<br>';
    Einzelkarte:=Einzelkarte+'Nebenstelle: '+xdsAnrufeNebenstelle.asstring+'<br>';
    Einzelkarte:=Einzelkarte+'Kundennummer: '+xdsAnrufeKundennummer.asstring+'<br>';
    Einzelkarte:=Einzelkarte+xdsAnrufeNotiz.asstring;
    if xdsAnrufeRufnummer.Asstring<>'' then Einzelkarte:=Einzelkarte+'<a href="tel:'+xdsAnrufeRufnummer.Asstring+'">anrufen</a>';
    Einzelkarte:=Einzelkarte+'</div>';

    myelement:=Container3.ElementHandle;
    EinzelCard:=THTMLDiv.Create(self);
    EinzelCard.ElementID:=xdsanrufeid.asstring;
    EinzelCard.HeightStyle:=ssauto;
    EinzelCard.WidthStyle:=ssPercent;
    EinzelCard.hint:=xdsAnrufeID.asstring;
    EinzelCard.HTML.Text:=Einzelkarte;
    EinzelCard.ElementClassName:='card m-1 border-info';
    EinzelCard.OnClick:=@EinzelCardClick;
    EinzelCard.ParentElement:=myelement;

    xdsanrufe.Next;
  end;
end;

procedure TfrmAnrufe.xdsAnrufeCalcFields(DataSet: TDataSet);
begin
  try
    if xdsAnrufeAnrufTyp.AsInteger=1 then xdsAnrufeAnrufArtText.AsString:='eingehend';
    if xdsAnrufeAnrufTyp.AsInteger=2 then xdsAnrufeAnrufArtText.AsString:='verpasst';
    if xdsAnrufeAnrufTyp.AsInteger=3 then xdsAnrufeAnrufArtText.AsString:='nicht erreicht';
    if xdsAnrufeAnrufTyp.AsInteger=4 then xdsAnrufeAnrufArtText.AsString:='ausgehend';
  except
  end;
end;

procedure TfrmAnrufe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  xdsAnrufe := TXDataWebDataSet.Create(Self);
  xdsAnrufeID := TStringField.Create(Self);
  xdsAnrufeAnrufTyp := TIntegerField.Create(Self);
  xdsAnrufeDatum := TDateTimeField.Create(Self);
  xdsAnrufeRufnummer := TStringField.Create(Self);
  xdsAnrufeNameFritzBox := TStringField.Create(Self);
  xdsAnrufeNebenstelle := TStringField.Create(Self);
  xdsAnrufeAnrufArt := TStringField.Create(Self);
  xdsAnrufeDauer := TDateTimeField.Create(Self);
  xdsAnrufeKundennummer := TStringField.Create(Self);
  xdsAnrufeTeilnehmer := TStringField.Create(Self);
  xdsAnrufeNotiz := TStringField.Create(Self);
  xdsAnrufebis := TDateTimeField.Create(Self);
  xdsAnrufeDauerMinuten := TIntegerField.Create(Self);
  xdsAnrufeJahr := TIntegerField.Create(Self);
  xdsAnrufeMonat := TIntegerField.Create(Self);
  xdsAnrufeTag := TIntegerField.Create(Self);
  xdsAnrufeMonatJahr := TDateTimeField.Create(Self);
  xdsAnrufeDatumKurz := TDateTimeField.Create(Self);
  xdsAnrufeAPID := TStringField.Create(Self);
  xdsAnrufeFBox := TStringField.Create(Self);
  xdsAnrufeabrechnen := TBooleanField.Create(Self);
  xdsAnrufeAbgerechnetAm := TDateTimeField.Create(Self);
  xdsAnrufeStundensatz := TFloatField.Create(Self);
  xdsAnrufeKosten := TFloatField.Create(Self);
  xdsAnrufeerstellt := TDateTimeField.Create(Self);
  xdsAnrufegeaendert := TDateTimeField.Create(Self);
  xdsAnrufeAnrufArtText := TStringField.Create(Self);

  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  xdsAnrufe.BeforeLoadDFMValues;
  xdsAnrufeID.BeforeLoadDFMValues;
  xdsAnrufeAnrufTyp.BeforeLoadDFMValues;
  xdsAnrufeDatum.BeforeLoadDFMValues;
  xdsAnrufeRufnummer.BeforeLoadDFMValues;
  xdsAnrufeNameFritzBox.BeforeLoadDFMValues;
  xdsAnrufeNebenstelle.BeforeLoadDFMValues;
  xdsAnrufeAnrufArt.BeforeLoadDFMValues;
  xdsAnrufeDauer.BeforeLoadDFMValues;
  xdsAnrufeKundennummer.BeforeLoadDFMValues;
  xdsAnrufeTeilnehmer.BeforeLoadDFMValues;
  xdsAnrufeNotiz.BeforeLoadDFMValues;
  xdsAnrufebis.BeforeLoadDFMValues;
  xdsAnrufeDauerMinuten.BeforeLoadDFMValues;
  xdsAnrufeJahr.BeforeLoadDFMValues;
  xdsAnrufeMonat.BeforeLoadDFMValues;
  xdsAnrufeTag.BeforeLoadDFMValues;
  xdsAnrufeMonatJahr.BeforeLoadDFMValues;
  xdsAnrufeDatumKurz.BeforeLoadDFMValues;
  xdsAnrufeAPID.BeforeLoadDFMValues;
  xdsAnrufeFBox.BeforeLoadDFMValues;
  xdsAnrufeabrechnen.BeforeLoadDFMValues;
  xdsAnrufeAbgerechnetAm.BeforeLoadDFMValues;
  xdsAnrufeStundensatz.BeforeLoadDFMValues;
  xdsAnrufeKosten.BeforeLoadDFMValues;
  xdsAnrufeerstellt.BeforeLoadDFMValues;
  xdsAnrufegeaendert.BeforeLoadDFMValues;
  xdsAnrufeAnrufArtText.BeforeLoadDFMValues;
  try
    Name := 'frmAnrufe';
    Width := 172;
    Height := 173;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormStyle := fsStayOnTop;
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 2;
    Container1.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    xdsAnrufe.SetParentComponent(Self);
    xdsAnrufe.Name := 'xdsAnrufe';
    xdsAnrufe.AfterOpen := xdsAnrufeAfterOpen;
    SetEvent(xdsAnrufe, Self, 'OnCalcFields', 'xdsAnrufeCalcFields');
    xdsAnrufe.EntitySetName := 'FritzBoxAnrufe';
    xdsAnrufe.Connection := DM2.XDataWebConnection1;
    xdsAnrufe.QueryString := '$orderby=Datum DESC';
    xdsAnrufe.QueryTop := 100;
    xdsAnrufe.Left := 13;
    xdsAnrufe.Top := 109;
    xdsAnrufeID.SetParentComponent(xdsAnrufe);
    xdsAnrufeID.Name := 'xdsAnrufeID';
    xdsAnrufeID.FieldName := 'ID';
    xdsAnrufeID.Required := True;
    xdsAnrufeID.Size := 38;
    xdsAnrufeAnrufTyp.SetParentComponent(xdsAnrufe);
    xdsAnrufeAnrufTyp.Name := 'xdsAnrufeAnrufTyp';
    xdsAnrufeAnrufTyp.FieldName := 'AnrufTyp';
    xdsAnrufeDatum.SetParentComponent(xdsAnrufe);
    xdsAnrufeDatum.Name := 'xdsAnrufeDatum';
    xdsAnrufeDatum.FieldName := 'Datum';
    xdsAnrufeRufnummer.SetParentComponent(xdsAnrufe);
    xdsAnrufeRufnummer.Name := 'xdsAnrufeRufnummer';
    xdsAnrufeRufnummer.FieldName := 'Rufnummer';
    xdsAnrufeRufnummer.Size := 50;
    xdsAnrufeNameFritzBox.SetParentComponent(xdsAnrufe);
    xdsAnrufeNameFritzBox.Name := 'xdsAnrufeNameFritzBox';
    xdsAnrufeNameFritzBox.FieldName := 'NameFritzBox';
    xdsAnrufeNameFritzBox.Size := 50;
    xdsAnrufeNebenstelle.SetParentComponent(xdsAnrufe);
    xdsAnrufeNebenstelle.Name := 'xdsAnrufeNebenstelle';
    xdsAnrufeNebenstelle.FieldName := 'Nebenstelle';
    xdsAnrufeNebenstelle.Size := 50;
    xdsAnrufeAnrufArt.SetParentComponent(xdsAnrufe);
    xdsAnrufeAnrufArt.Name := 'xdsAnrufeAnrufArt';
    xdsAnrufeAnrufArt.FieldName := 'AnrufArt';
    xdsAnrufeAnrufArt.Size := 50;
    xdsAnrufeDauer.SetParentComponent(xdsAnrufe);
    xdsAnrufeDauer.Name := 'xdsAnrufeDauer';
    xdsAnrufeDauer.FieldName := 'Dauer';
    xdsAnrufeKundennummer.SetParentComponent(xdsAnrufe);
    xdsAnrufeKundennummer.Name := 'xdsAnrufeKundennummer';
    xdsAnrufeKundennummer.FieldName := 'Kundennummer';
    xdsAnrufeKundennummer.Size := 13;
    xdsAnrufeTeilnehmer.SetParentComponent(xdsAnrufe);
    xdsAnrufeTeilnehmer.Name := 'xdsAnrufeTeilnehmer';
    xdsAnrufeTeilnehmer.FieldName := 'Teilnehmer';
    xdsAnrufeTeilnehmer.Size := 150;
    xdsAnrufeNotiz.SetParentComponent(xdsAnrufe);
    xdsAnrufeNotiz.Name := 'xdsAnrufeNotiz';
    xdsAnrufeNotiz.FieldName := 'Notiz';
    xdsAnrufeNotiz.Size := 255;
    xdsAnrufebis.SetParentComponent(xdsAnrufe);
    xdsAnrufebis.Name := 'xdsAnrufebis';
    xdsAnrufebis.FieldName := 'bis';
    xdsAnrufeDauerMinuten.SetParentComponent(xdsAnrufe);
    xdsAnrufeDauerMinuten.Name := 'xdsAnrufeDauerMinuten';
    xdsAnrufeDauerMinuten.FieldName := 'DauerMinuten';
    xdsAnrufeJahr.SetParentComponent(xdsAnrufe);
    xdsAnrufeJahr.Name := 'xdsAnrufeJahr';
    xdsAnrufeJahr.FieldName := 'Jahr';
    xdsAnrufeMonat.SetParentComponent(xdsAnrufe);
    xdsAnrufeMonat.Name := 'xdsAnrufeMonat';
    xdsAnrufeMonat.FieldName := 'Monat';
    xdsAnrufeTag.SetParentComponent(xdsAnrufe);
    xdsAnrufeTag.Name := 'xdsAnrufeTag';
    xdsAnrufeTag.FieldName := 'Tag';
    xdsAnrufeMonatJahr.SetParentComponent(xdsAnrufe);
    xdsAnrufeMonatJahr.Name := 'xdsAnrufeMonatJahr';
    xdsAnrufeMonatJahr.FieldName := 'MonatJahr';
    xdsAnrufeDatumKurz.SetParentComponent(xdsAnrufe);
    xdsAnrufeDatumKurz.Name := 'xdsAnrufeDatumKurz';
    xdsAnrufeDatumKurz.FieldName := 'DatumKurz';
    xdsAnrufeAPID.SetParentComponent(xdsAnrufe);
    xdsAnrufeAPID.Name := 'xdsAnrufeAPID';
    xdsAnrufeAPID.FieldName := 'APID';
    xdsAnrufeAPID.Size := 38;
    xdsAnrufeFBox.SetParentComponent(xdsAnrufe);
    xdsAnrufeFBox.Name := 'xdsAnrufeFBox';
    xdsAnrufeFBox.FieldName := 'FBox';
    xdsAnrufeFBox.Size := 50;
    xdsAnrufeabrechnen.SetParentComponent(xdsAnrufe);
    xdsAnrufeabrechnen.Name := 'xdsAnrufeabrechnen';
    xdsAnrufeabrechnen.FieldName := 'abrechnen';
    xdsAnrufeAbgerechnetAm.SetParentComponent(xdsAnrufe);
    xdsAnrufeAbgerechnetAm.Name := 'xdsAnrufeAbgerechnetAm';
    xdsAnrufeAbgerechnetAm.FieldName := 'AbgerechnetAm';
    xdsAnrufeStundensatz.SetParentComponent(xdsAnrufe);
    xdsAnrufeStundensatz.Name := 'xdsAnrufeStundensatz';
    xdsAnrufeStundensatz.FieldName := 'Stundensatz';
    xdsAnrufeKosten.SetParentComponent(xdsAnrufe);
    xdsAnrufeKosten.Name := 'xdsAnrufeKosten';
    xdsAnrufeKosten.FieldName := 'Kosten';
    xdsAnrufeerstellt.SetParentComponent(xdsAnrufe);
    xdsAnrufeerstellt.Name := 'xdsAnrufeerstellt';
    xdsAnrufeerstellt.FieldName := 'erstellt';
    xdsAnrufegeaendert.SetParentComponent(xdsAnrufe);
    xdsAnrufegeaendert.Name := 'xdsAnrufegeaendert';
    xdsAnrufegeaendert.FieldName := 'geaendert';
    xdsAnrufeAnrufArtText.SetParentComponent(xdsAnrufe);
    xdsAnrufeAnrufArtText.Name := 'xdsAnrufeAnrufArtText';
    xdsAnrufeAnrufArtText.FieldKind := fkCalculated;
    xdsAnrufeAnrufArtText.FieldName := 'AnrufArtText';
    xdsAnrufeAnrufArtText.Calculated := True;
  finally
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    xdsAnrufe.AfterLoadDFMValues;
    xdsAnrufeID.AfterLoadDFMValues;
    xdsAnrufeAnrufTyp.AfterLoadDFMValues;
    xdsAnrufeDatum.AfterLoadDFMValues;
    xdsAnrufeRufnummer.AfterLoadDFMValues;
    xdsAnrufeNameFritzBox.AfterLoadDFMValues;
    xdsAnrufeNebenstelle.AfterLoadDFMValues;
    xdsAnrufeAnrufArt.AfterLoadDFMValues;
    xdsAnrufeDauer.AfterLoadDFMValues;
    xdsAnrufeKundennummer.AfterLoadDFMValues;
    xdsAnrufeTeilnehmer.AfterLoadDFMValues;
    xdsAnrufeNotiz.AfterLoadDFMValues;
    xdsAnrufebis.AfterLoadDFMValues;
    xdsAnrufeDauerMinuten.AfterLoadDFMValues;
    xdsAnrufeJahr.AfterLoadDFMValues;
    xdsAnrufeMonat.AfterLoadDFMValues;
    xdsAnrufeTag.AfterLoadDFMValues;
    xdsAnrufeMonatJahr.AfterLoadDFMValues;
    xdsAnrufeDatumKurz.AfterLoadDFMValues;
    xdsAnrufeAPID.AfterLoadDFMValues;
    xdsAnrufeFBox.AfterLoadDFMValues;
    xdsAnrufeabrechnen.AfterLoadDFMValues;
    xdsAnrufeAbgerechnetAm.AfterLoadDFMValues;
    xdsAnrufeStundensatz.AfterLoadDFMValues;
    xdsAnrufeKosten.AfterLoadDFMValues;
    xdsAnrufeerstellt.AfterLoadDFMValues;
    xdsAnrufegeaendert.AfterLoadDFMValues;
    xdsAnrufeAnrufArtText.AfterLoadDFMValues;
  end;
end;

end.
