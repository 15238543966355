unit WEBLib.Consts;

interface

resourcestring
  SWarning = 'Warning';
  SError = 'Error';
  SInformation = 'Information';
  SConfirm = 'Confirm';
  SOK = 'OK';
  SCancel = 'Cancel';
  SYes = 'Yes';
  SNo = 'No';
  SAbort = 'Abort';
  SRetry = 'Retry';
  SIgnore = 'Ignore';
  SHelp = 'Help';
  SClose = 'Close';
  SAll = 'All';
  SYesToAll = 'Yes to all';
  SNoToAll = 'No to all';

  SReadError = 'Stream read error';
  SWriteError = 'Stream write error';
  SCannotGetFile = 'Cannot get the file from the URL %s because of the error %d!';

  SErrorLoadFromFile = 'There was an error during the download of the file %s!';
  SLoadFromFileAborted = 'The download of the file %s was aborted!';

implementation

end.