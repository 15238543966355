unit unit_Kontaktsuche;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, strutils,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, WEBLib.Buttons;

type
  TfrmKontaktsuche = class(TForm)
    DataSet: TXDataWebDataSet;
    Source: TDataSource;
    DataSetKundennummer: TStringField;
    DataSetNachname: TStringField;
    DataSetVorname: TStringField;
    TC: TTableControl;
    DataSetAdresseBild: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure DataSetAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  protected
    procedure LoadDFMValues; override;

    public
    Kontaktnummer:string;
    weiterzuEinzelkontakt:boolean;
    infolabel:TLabel;
    buttonzurueck:TButton;
    buttonscan:TButton;
    buttonkundennummer:TButton;
    edtsuchbegriff: TEdit;
    procedure buttonzurueckclick(Sender:TObject);
    procedure edtsuchbegriffChange(Sender: TObject);
    procedure buttonscanclick(Sender:TObject);
    procedure buttonkundennummerclick(Sender:TObject);
  end;

var
  frmKontaktsuche: TfrmKontaktsuche;

implementation
  uses unit1, unit2, unit_EinzelKontakt, unit_BarcodeScan;

procedure TfrmKontaktsuche.buttonzurueckclick(Sender:TObject);
begin
  self.close;
end;

procedure TfrmKontaktsuche.buttonscanclick(Sender:TObject);
var
  LFrm:TBarcodeScan;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TBarcodeScan;
    begin
      LForm:=(AForm as TBarcodeScan);
      //lform.mykundehint:=xdsEinzelkontaktKundennummer.AsString+' '+xdsEinzelkontaktNachname.AsString+', '+xdsEinzelkontaktVorname.asstring;
      //lForm.edttoaddress.Text:= xdsEinzelkontaktPostleitzahl.AsString+' '+xdsEinzelkontaktort.AsString+', '+xdsEinzelkontaktStrasse.AsString;

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      edtsuchbegriff.text:=lfrm.ScanErgebnis;
      edtsuchbegriffChange(self);
    end;
  begin
    if LizenzOK then
    begin
      LFrm:=TBarcodeScan.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);
    end
    else
    begin
      showmessage('Für diese Funktion sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;
end;

procedure TfrmKontaktsuche.buttonkundennummerclick(Sender:TObject);
var
  MFrm:TEinzelkontakt;
    procedure AfterCreate(AForm:TObject);
    var
      MForm:TEinzelkontakt;
    begin
      MForm:=(AForm as TEinzelkontakt);
      MForm.KundennummerID:=Kontaktnummer;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //nothing to do
    end;

  begin

  Kontaktnummer:=(sender as TButton).Caption;
  if weiterzueinzelkontakt then
  begin
    MFrm:=TEinzelkontakt.CreateNew(@AfterCreate);
    MFrm.ShowModal(@AfterShowmodal);
  end
  else
  begin
    self.Close;
  end;
end;

procedure TfrmKontaktsuche.DataSetAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin
  i:=3;

  tc.RowCount:=dataset.RecordCount+4;
  dataset.First;
  if dataset.RecordCount=0 then infolabel.Caption:='nichts gefunden';
  if dataset.RecordCount>0 then infolabel.Caption:='Übereinstimmungen: '+inttostr(dataset.RecordCount);
  if dataset.RecordCount>20 then infolabel.Caption:='mehr als 20 Übereinstimmungen';
  tc.BeginUpdate;
  while not dataset.eof do
  begin
    inc(i);
    myelement:=TC.cellelements[0,i];
    myelement.innerHTML:='';
    buttonkundennummer:=TButton.Create(self);
    buttonkundennummer.ElementClassName:='btn btn-sm btn-info';
    buttonkundennummer.HeightStyle:=ssauto;
    buttonkundennummer.Width:=150;
    buttonkundennummer.tag:=0;
    buttonkundennummer.Caption:=DataSetKundennummer.AsString;
    buttonkundennummer.OnClick:=@buttonkundennummerClick;
    buttonkundennummer.ParentElement:=myelement;
    TC.Cells[1,i]:=DataSetNachname.AsString;
    TC.Cells[2,i]:=DataSetVorname.AsString;
    TC.Cells[3,i]:='<h6>'+ReplaceStr(DataSetAdresseBild.AsString,#13+#10,'<BR>');;
    dataset.Next;
  end;
  Tc.EndUpdate;
end;

procedure TfrmKontaktsuche.edtsuchbegriffChange(Sender: TObject);
var
  suchtextkontakt:string;
begin
  suchtextkontakt:=edtsuchbegriff.Text;
  if length(suchtextkontakt)>0 then
  begin
  dataset.Close;
  dataset.QueryString:='$filter=contains(Nachname, '''+edtsuchbegriff.Text+''') OR contains(Kundennummer, '''+edtsuchbegriff.Text+''') OR contains(AdresseBild, '''+edtsuchbegriff.Text+''')';
  dataset.Load;
  end;
end;

procedure TfrmKontaktsuche.WebFormCreate(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  TC.ColCount:=4;
  TC.RowCount:=5;
  TC.Cells[0,0]:='<h4>Kontaktsuche';

  myelement:=TC.cellelements[0,1];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  myelement:=TC.cellelements[0,2];
  myelement.innerHTML:='';
  infolabel:=TLabel.Create(self);
  infolabel.Caption:='Suchbegriff eingeben oder SCAN klicken';
  infolabel.ElementFont:=efCSS;
  infolabel.ParentElement:=myelement;
  edtsuchbegriff:=TEdit.Create(self);
  edtsuchbegriff.ElementClassName:='form-control';
  edtsuchbegriff.ElementFont:=efCSS;
  edtsuchbegriff.HeightStyle:=ssauto;
  edtsuchbegriff.Width:=150;
  edtsuchbegriff.OnChange:=@edtsuchbegriffChange;
  edtsuchbegriff.ParentElement:=myelement;
  myelement:=TC.cellelements[1,2];
  myelement.innerHTML:='';
  buttonscan:=TButton.Create(self);
  buttonscan.ElementClassName:='btn btn-info text-left';
  buttonscan.HeightStyle:=ssauto;
  buttonscan.WidthStyle:=ssauto;
  buttonscan.tag:=0;
  buttonscan.Caption:='Scan';
  buttonscan.OnClick:=@buttonscanClick;
  buttonscan.ParentElement:=myelement;

  tc.Cells[0,3]:='<b>Kundennummer';
  tc.Cells[1,3]:='<b>Nachname';
  tc.Cells[2,3]:='<b>Vorname';
  tc.Cells[3,3]:='<b>Anschrift';



end;

procedure TfrmKontaktsuche.WebFormShow(Sender: TObject);
begin
  Kontaktnummer:='';
  edtsuchbegriff.SetFocus;
end;

procedure TfrmKontaktsuche.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  DataSet := TXDataWebDataSet.Create(Self);
  DataSetKundennummer := TStringField.Create(Self);
  DataSetNachname := TStringField.Create(Self);
  DataSetVorname := TStringField.Create(Self);
  DataSetAdresseBild := TStringField.Create(Self);
  Source := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  DataSet.BeforeLoadDFMValues;
  DataSetKundennummer.BeforeLoadDFMValues;
  DataSetNachname.BeforeLoadDFMValues;
  DataSetVorname.BeforeLoadDFMValues;
  DataSetAdresseBild.BeforeLoadDFMValues;
  Source.BeforeLoadDFMValues;
  try
    Name := 'frmKontaktsuche';
    Width := 172;
    Height := 265;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormStyle := fsStayOnTop;
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 172;
    TC.Height := 265;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 1;
    TC.RowCount := 5;
    DataSet.SetParentComponent(Self);
    DataSet.Name := 'DataSet';
    DataSet.AfterOpen := DataSetAfterOpen;
    DataSet.EntitySetName := 'Adressen';
    DataSet.Connection := DM2.XDataWebConnection1;
    DataSet.QueryTop := 20;
    DataSet.Left := 8;
    DataSet.Top := 56;
    DataSetKundennummer.SetParentComponent(DataSet);
    DataSetKundennummer.Name := 'DataSetKundennummer';
    DataSetKundennummer.FieldName := 'Kundennummer';
    DataSetKundennummer.Required := True;
    DataSetKundennummer.Size := 13;
    DataSetNachname.SetParentComponent(DataSet);
    DataSetNachname.Name := 'DataSetNachname';
    DataSetNachname.FieldName := 'Nachname';
    DataSetNachname.Required := True;
    DataSetNachname.Size := 40;
    DataSetVorname.SetParentComponent(DataSet);
    DataSetVorname.Name := 'DataSetVorname';
    DataSetVorname.FieldName := 'Vorname';
    DataSetVorname.Size := 40;
    DataSetAdresseBild.SetParentComponent(DataSet);
    DataSetAdresseBild.Name := 'DataSetAdresseBild';
    DataSetAdresseBild.FieldName := 'AdresseBild';
    DataSetAdresseBild.Size := 255;
    Source.SetParentComponent(Self);
    Source.Name := 'Source';
    Source.DataSet := DataSet;
    Source.Left := 56;
    Source.Top := 56;
  finally
    TC.AfterLoadDFMValues;
    DataSet.AfterLoadDFMValues;
    DataSetKundennummer.AfterLoadDFMValues;
    DataSetNachname.AfterLoadDFMValues;
    DataSetVorname.AfterLoadDFMValues;
    DataSetAdresseBild.AfterLoadDFMValues;
    Source.AfterLoadDFMValues;
  end;
end;

end.
