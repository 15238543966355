unit libfilereader;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  Classes, JS, Web;

type
  //FileReader helpers
  TJSFileReader = class external name 'FileReader' (TJSEventTarget)
  private
    FResult: JSValue; external name 'result';
  public
    constructor new;
    procedure readAsBinaryString(aFile: TJSHtmlFile);
    property result : JSValue read FResult;
  end;

  TFileReaderReadDoneEvent = procedure(aFileName: string; AResult: JSValue) of object;

  TFileReader = class(TComponent)
    FOnReadDone: TFileReaderReadDoneEvent;
  public
    constructor Create(AOwner: TComponent); overload; override;
    procedure readAsBinaryString(AFile: TJSHtmlFile);
    function doOnLoad(Event: TEventListenerEvent): boolean;

    property OnReadDone: TFileReaderReadDoneEvent read FOnReadDone write FOnReadDone;
  end;

  TWebFileReader = class(TFileReader);

implementation

constructor TFileReader.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure setFileName(var aRdr: TJSFileReader; aFileName: string);
begin
  TJSObject(aRdr)['fname'] := aFileName;
end;

function getFileName(var aRdr: TJSFileReader): string;
begin
  Result := string(TJSObject(aRdr)['fname']);
end;

procedure TFileReader.readAsBinaryString(aFile: TJSHtmlFile);
var
  aFileReader: TJSFileReader;
begin
  aFileReader := TJSFileReader.New;
  setFileName(aFileReader, aFile.name);
  aFileReader.addEventListener('load',@doOnLoad);
  aFileReader.readAsBinaryString(aFile);
end;

function TFileReader.doOnLoad(Event: TEventListenerEvent): boolean;
var
  aReader: TJSFileReader;
begin
  aReader := Event.target as TJSFileReader;
  if Assigned(FOnReadDone) then
    FOnReadDone(getFileName(aReader), aReader.result);
  Result := true;
end;

end.
