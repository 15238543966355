unit unit_AuftragTermine;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.DB, WEBLib.WebCtrls;

type
  TAuftragTermine = class(TForm)
    dsTermine: TXDataWebDataSet;
    dsTermineArtikelnummer: TXDataWebEntityField;
    dsTermineUID: TStringField;
    dsTermineBeginn: TDateTimeField;
    dsTermineEnde: TDateTimeField;
    dsTermineBetreff: TStringField;
    dsTermineTerminNotiz: TStringField;
    dsTermineQuelle: TStringField;
    dsTermineGUIDOutlook: TStringField;
    dsTermineShape: TIntegerField;
    dsTermineColor: TIntegerField;
    dsTermineAlarm: TBooleanField;
    dsTermineResource: TIntegerField;
    dsTermineLastModificationTime: TDateTimeField;
    dsTermineGeburtstag: TBooleanField;
    dsTermineAlarmBefore: TIntegerField;
    dsTerminePersonalnummer: TIntegerField;
    dsTermineKundennummer: TStringField;
    dsTermineAllDayEventOutlook: TBooleanField;
    dsTermineTMSCaption: TBooleanField;
    dsTermineRECURRENCY: TStringField;
    dsTermineMINTIME: TDateTimeField;
    dsTermineMAXTIME: TDateTimeField;
    dsTermineNachname: TStringField;
    dsTermineObjektReferenz: TStringField;
    dsTermineObjektnummer: TStringField;
    dsTermineTerminArt: TIntegerField;
    dsTermineBeginnDatum: TDateTimeField;
    dsTermineBeginnUhrzeit: TDateTimeField;
    dsTermineEndeDatum: TDateTimeField;
    dsTermineEndeUhrzeit: TDateTimeField;
    dsTermineKalenderAnzeige: TBooleanField;
    dsTermineStundensatz: TFloatField;
    dsTermineDauerStunden: TFloatField;
    dsTerminegefahrenekm: TFloatField;
    dsTermineAufwand: TFloatField;
    dsTermineabgerechnet: TBooleanField;
    dsTermineverteilt: TDateTimeField;
    dsTermineTerminErledigt: TDateTimeField;
    dsTermineAnsprechpartner: TStringField;
    dsTermineLocation: TStringField;
    dsTermineGoogleID: TStringField;
    dsTermineGoogleCreatedUTC: TDateTimeField;
    dsTermineGoogleUpdatedUTC: TDateTimeField;
    dsTermineGoogleStatus: TStringField;
    dsTermineVorname: TStringField;
    dsTerminePNachname: TStringField;
    dsTerminePVorname: TStringField;
    dsTermineArtikelueberschrift: TStringField;
    dsTerminePFarbe: TIntegerField;
    dsTermineKontaktart: TStringField;
    dsTermineAuftrag: TStringField;
    sourceTermine: TDataSource;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    Container3: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure dsTermineAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    MerkerAuftragTermine:string;
    buttonzurueck:TButton;
    EinzelCard:THTMLDiv;
    procedure buttonzurueckclick(Sender:TObject);
    procedure EinzelCardclick(Sender:TObject);
  end;

var
  AuftragTermine: TAuftragTermine;

implementation
  uses unit1, unit2, unit_Einzeltermin;

procedure TAuftragTermine.buttonzurueckclick(Sender:TObject);
begin
  dsTermine.Close;
  self.close;
end;

procedure TAuftragTermine.EinzelCardclick(Sender:TObject);
var
  LFrm:TfrmEinzeltermin;
  myTerminUID:string;
begin
  myTerminUID:=(sender as THTMLDiv).ElementID;
  Container3.HTML.text:='';
  frmEinzeltermin := TfrmEinzeltermin.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TfrmEinzeltermin).TerminUID:=myTerminUID;
    end
    );

  frmEinzeltermin.ShowModal(procedure(AValue: TModalResult)
    begin
      dsTermine.close;
      dsTermine.Load;
    end
    );
end;


procedure TAuftragTermine.dsTermineAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
  EinzelKarte:string;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Auftragstermine</h5></nav>';

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info m-1 text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  while not dsTermine.eof do
  begin

    Einzelkarte:='<div class="card-header"><h5>'+formatdatetime('DD.MM.YY hh:mm',dsTermineBeginn.Asdatetime)+' - '+formatdatetime('DD.MM.YY hh:mm',dsTermineEnde.Asdatetime)+'</h5><h6>'+dsTermineBetreff.AsString+'</h6></div>';
    Einzelkarte:=Einzelkarte+'<div class="card-body ">'+trim(dsTerminePVorname.AsString+' '+dsTerminePNachname.AsString);
    Einzelkarte:=Einzelkarte+'<br><b>'+dsTermineTerminNotiz.AsString+'</b><br>';
    Einzelkarte:=Einzelkarte+dsTermineKundennummer.AsString+' '+dsTermineNachname.AsString+'<br>';
    Einzelkarte:=Einzelkarte+'</div>';


    myelement:=Container3.ElementHandle;
    EinzelCard:=THTMLDiv.Create(self);
    EinzelCard.ElementID:=dsTermineUID.asstring;
    EinzelCard.HeightStyle:=ssauto;
    EinzelCard.Width:=350;
    EinzelCard.HTML.Text:=Einzelkarte;
    EinzelCard.ElementClassName:='card m-1 border-info';
    EinzelCard.OnClick:=@EinzelCardClick;
    EinzelCard.ParentElement:=myelement;

    dsTermine.Next;
  end;

end;

procedure TAuftragTermine.WebFormShow(Sender: TObject);
begin
  dsTermine.Close;
  dsTermine.QueryString:='$filter=Auftrag eq '+QuotedStr(MerkerAuftragTermine);
  dsTermine.Load;
end;

procedure TAuftragTermine.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  dsTermine := TXDataWebDataSet.Create(Self);
  dsTermineArtikelnummer := TXDataWebEntityField.Create(Self);
  dsTermineUID := TStringField.Create(Self);
  dsTermineBeginn := TDateTimeField.Create(Self);
  dsTermineEnde := TDateTimeField.Create(Self);
  dsTermineBetreff := TStringField.Create(Self);
  dsTermineTerminNotiz := TStringField.Create(Self);
  dsTermineQuelle := TStringField.Create(Self);
  dsTermineGUIDOutlook := TStringField.Create(Self);
  dsTermineShape := TIntegerField.Create(Self);
  dsTermineColor := TIntegerField.Create(Self);
  dsTermineAlarm := TBooleanField.Create(Self);
  dsTermineResource := TIntegerField.Create(Self);
  dsTermineLastModificationTime := TDateTimeField.Create(Self);
  dsTermineGeburtstag := TBooleanField.Create(Self);
  dsTermineAlarmBefore := TIntegerField.Create(Self);
  dsTerminePersonalnummer := TIntegerField.Create(Self);
  dsTermineKundennummer := TStringField.Create(Self);
  dsTermineAllDayEventOutlook := TBooleanField.Create(Self);
  dsTermineTMSCaption := TBooleanField.Create(Self);
  dsTermineRECURRENCY := TStringField.Create(Self);
  dsTermineMINTIME := TDateTimeField.Create(Self);
  dsTermineMAXTIME := TDateTimeField.Create(Self);
  dsTermineNachname := TStringField.Create(Self);
  dsTermineObjektReferenz := TStringField.Create(Self);
  dsTermineObjektnummer := TStringField.Create(Self);
  dsTermineTerminArt := TIntegerField.Create(Self);
  dsTermineBeginnDatum := TDateTimeField.Create(Self);
  dsTermineBeginnUhrzeit := TDateTimeField.Create(Self);
  dsTermineEndeDatum := TDateTimeField.Create(Self);
  dsTermineEndeUhrzeit := TDateTimeField.Create(Self);
  dsTermineKalenderAnzeige := TBooleanField.Create(Self);
  dsTermineStundensatz := TFloatField.Create(Self);
  dsTermineDauerStunden := TFloatField.Create(Self);
  dsTerminegefahrenekm := TFloatField.Create(Self);
  dsTermineAufwand := TFloatField.Create(Self);
  dsTermineabgerechnet := TBooleanField.Create(Self);
  dsTermineverteilt := TDateTimeField.Create(Self);
  dsTermineTerminErledigt := TDateTimeField.Create(Self);
  dsTermineAnsprechpartner := TStringField.Create(Self);
  dsTermineLocation := TStringField.Create(Self);
  dsTermineGoogleID := TStringField.Create(Self);
  dsTermineGoogleCreatedUTC := TDateTimeField.Create(Self);
  dsTermineGoogleUpdatedUTC := TDateTimeField.Create(Self);
  dsTermineGoogleStatus := TStringField.Create(Self);
  dsTermineVorname := TStringField.Create(Self);
  dsTerminePNachname := TStringField.Create(Self);
  dsTerminePVorname := TStringField.Create(Self);
  dsTermineArtikelueberschrift := TStringField.Create(Self);
  dsTerminePFarbe := TIntegerField.Create(Self);
  dsTermineKontaktart := TStringField.Create(Self);
  dsTermineAuftrag := TStringField.Create(Self);
  sourceTermine := TDataSource.Create(Self);

  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  dsTermine.BeforeLoadDFMValues;
  dsTermineArtikelnummer.BeforeLoadDFMValues;
  dsTermineUID.BeforeLoadDFMValues;
  dsTermineBeginn.BeforeLoadDFMValues;
  dsTermineEnde.BeforeLoadDFMValues;
  dsTermineBetreff.BeforeLoadDFMValues;
  dsTermineTerminNotiz.BeforeLoadDFMValues;
  dsTermineQuelle.BeforeLoadDFMValues;
  dsTermineGUIDOutlook.BeforeLoadDFMValues;
  dsTermineShape.BeforeLoadDFMValues;
  dsTermineColor.BeforeLoadDFMValues;
  dsTermineAlarm.BeforeLoadDFMValues;
  dsTermineResource.BeforeLoadDFMValues;
  dsTermineLastModificationTime.BeforeLoadDFMValues;
  dsTermineGeburtstag.BeforeLoadDFMValues;
  dsTermineAlarmBefore.BeforeLoadDFMValues;
  dsTerminePersonalnummer.BeforeLoadDFMValues;
  dsTermineKundennummer.BeforeLoadDFMValues;
  dsTermineAllDayEventOutlook.BeforeLoadDFMValues;
  dsTermineTMSCaption.BeforeLoadDFMValues;
  dsTermineRECURRENCY.BeforeLoadDFMValues;
  dsTermineMINTIME.BeforeLoadDFMValues;
  dsTermineMAXTIME.BeforeLoadDFMValues;
  dsTermineNachname.BeforeLoadDFMValues;
  dsTermineObjektReferenz.BeforeLoadDFMValues;
  dsTermineObjektnummer.BeforeLoadDFMValues;
  dsTermineTerminArt.BeforeLoadDFMValues;
  dsTermineBeginnDatum.BeforeLoadDFMValues;
  dsTermineBeginnUhrzeit.BeforeLoadDFMValues;
  dsTermineEndeDatum.BeforeLoadDFMValues;
  dsTermineEndeUhrzeit.BeforeLoadDFMValues;
  dsTermineKalenderAnzeige.BeforeLoadDFMValues;
  dsTermineStundensatz.BeforeLoadDFMValues;
  dsTermineDauerStunden.BeforeLoadDFMValues;
  dsTerminegefahrenekm.BeforeLoadDFMValues;
  dsTermineAufwand.BeforeLoadDFMValues;
  dsTermineabgerechnet.BeforeLoadDFMValues;
  dsTermineverteilt.BeforeLoadDFMValues;
  dsTermineTerminErledigt.BeforeLoadDFMValues;
  dsTermineAnsprechpartner.BeforeLoadDFMValues;
  dsTermineLocation.BeforeLoadDFMValues;
  dsTermineGoogleID.BeforeLoadDFMValues;
  dsTermineGoogleCreatedUTC.BeforeLoadDFMValues;
  dsTermineGoogleUpdatedUTC.BeforeLoadDFMValues;
  dsTermineGoogleStatus.BeforeLoadDFMValues;
  dsTermineVorname.BeforeLoadDFMValues;
  dsTerminePNachname.BeforeLoadDFMValues;
  dsTerminePVorname.BeforeLoadDFMValues;
  dsTermineArtikelueberschrift.BeforeLoadDFMValues;
  dsTerminePFarbe.BeforeLoadDFMValues;
  dsTermineKontaktart.BeforeLoadDFMValues;
  dsTermineAuftrag.BeforeLoadDFMValues;
  sourceTermine.BeforeLoadDFMValues;
  try
    Name := 'AuftragTermine';
    Width := 174;
    Height := 201;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 1;
    Container1.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    dsTermine.SetParentComponent(Self);
    dsTermine.Name := 'dsTermine';
    dsTermine.AfterOpen := dsTermineAfterOpen;
    dsTermine.EntitySetName := 'TermineNeu';
    dsTermine.Connection := DM2.XDataWebConnection1;
    dsTermine.Left := 116;
    dsTermine.Top := 72;
    dsTermineArtikelnummer.SetParentComponent(dsTermine);
    dsTermineArtikelnummer.Name := 'dsTermineArtikelnummer';
    dsTermineArtikelnummer.FieldName := 'Artikelnummer';
    dsTermineUID.SetParentComponent(dsTermine);
    dsTermineUID.Name := 'dsTermineUID';
    dsTermineUID.FieldName := 'UID';
    dsTermineUID.Required := True;
    dsTermineUID.Size := 38;
    dsTermineBeginn.SetParentComponent(dsTermine);
    dsTermineBeginn.Name := 'dsTermineBeginn';
    dsTermineBeginn.FieldName := 'Beginn';
    dsTermineEnde.SetParentComponent(dsTermine);
    dsTermineEnde.Name := 'dsTermineEnde';
    dsTermineEnde.FieldName := 'Ende';
    dsTermineBetreff.SetParentComponent(dsTermine);
    dsTermineBetreff.Name := 'dsTermineBetreff';
    dsTermineBetreff.FieldName := 'Betreff';
    dsTermineBetreff.Size := 255;
    dsTermineTerminNotiz.SetParentComponent(dsTermine);
    dsTermineTerminNotiz.Name := 'dsTermineTerminNotiz';
    dsTermineTerminNotiz.FieldName := 'TerminNotiz';
    dsTermineTerminNotiz.Size := 255;
    dsTermineQuelle.SetParentComponent(dsTermine);
    dsTermineQuelle.Name := 'dsTermineQuelle';
    dsTermineQuelle.FieldName := 'Quelle';
    dsTermineQuelle.Size := 10;
    dsTermineGUIDOutlook.SetParentComponent(dsTermine);
    dsTermineGUIDOutlook.Name := 'dsTermineGUIDOutlook';
    dsTermineGUIDOutlook.FieldName := 'GUIDOutlook';
    dsTermineGUIDOutlook.Size := 250;
    dsTermineShape.SetParentComponent(dsTermine);
    dsTermineShape.Name := 'dsTermineShape';
    dsTermineShape.FieldName := 'Shape';
    dsTermineColor.SetParentComponent(dsTermine);
    dsTermineColor.Name := 'dsTermineColor';
    dsTermineColor.FieldName := 'Color';
    dsTermineAlarm.SetParentComponent(dsTermine);
    dsTermineAlarm.Name := 'dsTermineAlarm';
    dsTermineAlarm.FieldName := 'Alarm';
    dsTermineResource.SetParentComponent(dsTermine);
    dsTermineResource.Name := 'dsTermineResource';
    dsTermineResource.FieldName := 'Resource';
    dsTermineLastModificationTime.SetParentComponent(dsTermine);
    dsTermineLastModificationTime.Name := 'dsTermineLastModificationTime';
    dsTermineLastModificationTime.FieldName := 'LastModificationTime';
    dsTermineGeburtstag.SetParentComponent(dsTermine);
    dsTermineGeburtstag.Name := 'dsTermineGeburtstag';
    dsTermineGeburtstag.FieldName := 'Geburtstag';
    dsTermineAlarmBefore.SetParentComponent(dsTermine);
    dsTermineAlarmBefore.Name := 'dsTermineAlarmBefore';
    dsTermineAlarmBefore.FieldName := 'AlarmBefore';
    dsTerminePersonalnummer.SetParentComponent(dsTermine);
    dsTerminePersonalnummer.Name := 'dsTerminePersonalnummer';
    dsTerminePersonalnummer.FieldName := 'Personalnummer';
    dsTermineKundennummer.SetParentComponent(dsTermine);
    dsTermineKundennummer.Name := 'dsTermineKundennummer';
    dsTermineKundennummer.FieldName := 'Kundennummer';
    dsTermineKundennummer.Size := 13;
    dsTermineAllDayEventOutlook.SetParentComponent(dsTermine);
    dsTermineAllDayEventOutlook.Name := 'dsTermineAllDayEventOutlook';
    dsTermineAllDayEventOutlook.FieldName := 'AllDayEventOutlook';
    dsTermineTMSCaption.SetParentComponent(dsTermine);
    dsTermineTMSCaption.Name := 'dsTermineTMSCaption';
    dsTermineTMSCaption.FieldName := 'TMSCaption';
    dsTermineRECURRENCY.SetParentComponent(dsTermine);
    dsTermineRECURRENCY.Name := 'dsTermineRECURRENCY';
    dsTermineRECURRENCY.FieldName := 'RECURRENCY';
    dsTermineRECURRENCY.Size := 255;
    dsTermineMINTIME.SetParentComponent(dsTermine);
    dsTermineMINTIME.Name := 'dsTermineMINTIME';
    dsTermineMINTIME.FieldName := 'MINTIME';
    dsTermineMAXTIME.SetParentComponent(dsTermine);
    dsTermineMAXTIME.Name := 'dsTermineMAXTIME';
    dsTermineMAXTIME.FieldName := 'MAXTIME';
    dsTermineNachname.SetParentComponent(dsTermine);
    dsTermineNachname.Name := 'dsTermineNachname';
    dsTermineNachname.FieldName := 'Nachname';
    dsTermineNachname.Size := 40;
    dsTermineObjektReferenz.SetParentComponent(dsTermine);
    dsTermineObjektReferenz.Name := 'dsTermineObjektReferenz';
    dsTermineObjektReferenz.FieldName := 'ObjektReferenz';
    dsTermineObjektReferenz.Size := 13;
    dsTermineObjektnummer.SetParentComponent(dsTermine);
    dsTermineObjektnummer.Name := 'dsTermineObjektnummer';
    dsTermineObjektnummer.FieldName := 'Objektnummer';
    dsTermineObjektnummer.Size := 25;
    dsTermineTerminArt.SetParentComponent(dsTermine);
    dsTermineTerminArt.Name := 'dsTermineTerminArt';
    dsTermineTerminArt.FieldName := 'TerminArt';
    dsTermineBeginnDatum.SetParentComponent(dsTermine);
    dsTermineBeginnDatum.Name := 'dsTermineBeginnDatum';
    dsTermineBeginnDatum.FieldName := 'BeginnDatum';
    dsTermineBeginnUhrzeit.SetParentComponent(dsTermine);
    dsTermineBeginnUhrzeit.Name := 'dsTermineBeginnUhrzeit';
    dsTermineBeginnUhrzeit.FieldName := 'BeginnUhrzeit';
    dsTermineEndeDatum.SetParentComponent(dsTermine);
    dsTermineEndeDatum.Name := 'dsTermineEndeDatum';
    dsTermineEndeDatum.FieldName := 'EndeDatum';
    dsTermineEndeUhrzeit.SetParentComponent(dsTermine);
    dsTermineEndeUhrzeit.Name := 'dsTermineEndeUhrzeit';
    dsTermineEndeUhrzeit.FieldName := 'EndeUhrzeit';
    dsTermineKalenderAnzeige.SetParentComponent(dsTermine);
    dsTermineKalenderAnzeige.Name := 'dsTermineKalenderAnzeige';
    dsTermineKalenderAnzeige.FieldName := 'KalenderAnzeige';
    dsTermineStundensatz.SetParentComponent(dsTermine);
    dsTermineStundensatz.Name := 'dsTermineStundensatz';
    dsTermineStundensatz.FieldName := 'Stundensatz';
    dsTermineDauerStunden.SetParentComponent(dsTermine);
    dsTermineDauerStunden.Name := 'dsTermineDauerStunden';
    dsTermineDauerStunden.FieldName := 'DauerStunden';
    dsTerminegefahrenekm.SetParentComponent(dsTermine);
    dsTerminegefahrenekm.Name := 'dsTerminegefahrenekm';
    dsTerminegefahrenekm.FieldName := 'gefahrenekm';
    dsTermineAufwand.SetParentComponent(dsTermine);
    dsTermineAufwand.Name := 'dsTermineAufwand';
    dsTermineAufwand.FieldName := 'Aufwand';
    dsTermineabgerechnet.SetParentComponent(dsTermine);
    dsTermineabgerechnet.Name := 'dsTermineabgerechnet';
    dsTermineabgerechnet.FieldName := 'abgerechnet';
    dsTermineverteilt.SetParentComponent(dsTermine);
    dsTermineverteilt.Name := 'dsTermineverteilt';
    dsTermineverteilt.FieldName := 'verteilt';
    dsTermineTerminErledigt.SetParentComponent(dsTermine);
    dsTermineTerminErledigt.Name := 'dsTermineTerminErledigt';
    dsTermineTerminErledigt.FieldName := 'TerminErledigt';
    dsTermineAnsprechpartner.SetParentComponent(dsTermine);
    dsTermineAnsprechpartner.Name := 'dsTermineAnsprechpartner';
    dsTermineAnsprechpartner.FieldName := 'Ansprechpartner';
    dsTermineAnsprechpartner.Size := 50;
    dsTermineLocation.SetParentComponent(dsTermine);
    dsTermineLocation.Name := 'dsTermineLocation';
    dsTermineLocation.FieldName := 'Location';
    dsTermineLocation.Size := 100;
    dsTermineGoogleID.SetParentComponent(dsTermine);
    dsTermineGoogleID.Name := 'dsTermineGoogleID';
    dsTermineGoogleID.FieldName := 'GoogleID';
    dsTermineGoogleID.Size := 50;
    dsTermineGoogleCreatedUTC.SetParentComponent(dsTermine);
    dsTermineGoogleCreatedUTC.Name := 'dsTermineGoogleCreatedUTC';
    dsTermineGoogleCreatedUTC.FieldName := 'GoogleCreatedUTC';
    dsTermineGoogleUpdatedUTC.SetParentComponent(dsTermine);
    dsTermineGoogleUpdatedUTC.Name := 'dsTermineGoogleUpdatedUTC';
    dsTermineGoogleUpdatedUTC.FieldName := 'GoogleUpdatedUTC';
    dsTermineGoogleStatus.SetParentComponent(dsTermine);
    dsTermineGoogleStatus.Name := 'dsTermineGoogleStatus';
    dsTermineGoogleStatus.FieldName := 'GoogleStatus';
    dsTermineGoogleStatus.Size := 50;
    dsTermineVorname.SetParentComponent(dsTermine);
    dsTermineVorname.Name := 'dsTermineVorname';
    dsTermineVorname.FieldName := 'Vorname';
    dsTermineVorname.Size := 40;
    dsTerminePNachname.SetParentComponent(dsTermine);
    dsTerminePNachname.Name := 'dsTerminePNachname';
    dsTerminePNachname.FieldName := 'PNachname';
    dsTerminePNachname.Size := 40;
    dsTerminePVorname.SetParentComponent(dsTermine);
    dsTerminePVorname.Name := 'dsTerminePVorname';
    dsTerminePVorname.FieldName := 'PVorname';
    dsTerminePVorname.Size := 40;
    dsTermineArtikelueberschrift.SetParentComponent(dsTermine);
    dsTermineArtikelueberschrift.Name := 'dsTermineArtikelueberschrift';
    dsTermineArtikelueberschrift.FieldName := 'Artikelueberschrift';
    dsTermineArtikelueberschrift.Size := 50;
    dsTerminePFarbe.SetParentComponent(dsTermine);
    dsTerminePFarbe.Name := 'dsTerminePFarbe';
    dsTerminePFarbe.FieldName := 'PFarbe';
    dsTermineKontaktart.SetParentComponent(dsTermine);
    dsTermineKontaktart.Name := 'dsTermineKontaktart';
    dsTermineKontaktart.FieldName := 'Kontaktart';
    dsTermineKontaktart.Size := 25;
    dsTermineAuftrag.SetParentComponent(dsTermine);
    dsTermineAuftrag.Name := 'dsTermineAuftrag';
    dsTermineAuftrag.FieldName := 'Auftrag';
    dsTermineAuftrag.Size := 10;
    sourceTermine.SetParentComponent(Self);
    sourceTermine.Name := 'sourceTermine';
    sourceTermine.DataSet := dsTermine;
    sourceTermine.Left := 32;
    sourceTermine.Top := 72;
  finally
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    dsTermine.AfterLoadDFMValues;
    dsTermineArtikelnummer.AfterLoadDFMValues;
    dsTermineUID.AfterLoadDFMValues;
    dsTermineBeginn.AfterLoadDFMValues;
    dsTermineEnde.AfterLoadDFMValues;
    dsTermineBetreff.AfterLoadDFMValues;
    dsTermineTerminNotiz.AfterLoadDFMValues;
    dsTermineQuelle.AfterLoadDFMValues;
    dsTermineGUIDOutlook.AfterLoadDFMValues;
    dsTermineShape.AfterLoadDFMValues;
    dsTermineColor.AfterLoadDFMValues;
    dsTermineAlarm.AfterLoadDFMValues;
    dsTermineResource.AfterLoadDFMValues;
    dsTermineLastModificationTime.AfterLoadDFMValues;
    dsTermineGeburtstag.AfterLoadDFMValues;
    dsTermineAlarmBefore.AfterLoadDFMValues;
    dsTerminePersonalnummer.AfterLoadDFMValues;
    dsTermineKundennummer.AfterLoadDFMValues;
    dsTermineAllDayEventOutlook.AfterLoadDFMValues;
    dsTermineTMSCaption.AfterLoadDFMValues;
    dsTermineRECURRENCY.AfterLoadDFMValues;
    dsTermineMINTIME.AfterLoadDFMValues;
    dsTermineMAXTIME.AfterLoadDFMValues;
    dsTermineNachname.AfterLoadDFMValues;
    dsTermineObjektReferenz.AfterLoadDFMValues;
    dsTermineObjektnummer.AfterLoadDFMValues;
    dsTermineTerminArt.AfterLoadDFMValues;
    dsTermineBeginnDatum.AfterLoadDFMValues;
    dsTermineBeginnUhrzeit.AfterLoadDFMValues;
    dsTermineEndeDatum.AfterLoadDFMValues;
    dsTermineEndeUhrzeit.AfterLoadDFMValues;
    dsTermineKalenderAnzeige.AfterLoadDFMValues;
    dsTermineStundensatz.AfterLoadDFMValues;
    dsTermineDauerStunden.AfterLoadDFMValues;
    dsTerminegefahrenekm.AfterLoadDFMValues;
    dsTermineAufwand.AfterLoadDFMValues;
    dsTermineabgerechnet.AfterLoadDFMValues;
    dsTermineverteilt.AfterLoadDFMValues;
    dsTermineTerminErledigt.AfterLoadDFMValues;
    dsTermineAnsprechpartner.AfterLoadDFMValues;
    dsTermineLocation.AfterLoadDFMValues;
    dsTermineGoogleID.AfterLoadDFMValues;
    dsTermineGoogleCreatedUTC.AfterLoadDFMValues;
    dsTermineGoogleUpdatedUTC.AfterLoadDFMValues;
    dsTermineGoogleStatus.AfterLoadDFMValues;
    dsTermineVorname.AfterLoadDFMValues;
    dsTerminePNachname.AfterLoadDFMValues;
    dsTerminePVorname.AfterLoadDFMValues;
    dsTermineArtikelueberschrift.AfterLoadDFMValues;
    dsTerminePFarbe.AfterLoadDFMValues;
    dsTermineKontaktart.AfterLoadDFMValues;
    dsTermineAuftrag.AfterLoadDFMValues;
    sourceTermine.AfterLoadDFMValues;
  end;
end;

end.
