{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2016 - 2020                                 }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCTreeView;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomTreeView, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCTreeViewData, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  ,UITypes
  {$IFEND}
  {$HINTS ON}
  ,Types
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Layouts
  {$ENDIF}
  ;

type
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCTreeView = class(TTMSFNCTreeViewPublished)
  protected
    procedure RegisterRuntimeClasses; override;
    {$IFDEF FMXLIB}
    procedure DoAbsoluteChanged; override;
    {$ENDIF}
    procedure DrawBorders(AGraphics: TTMSFNCGraphics); override;
    procedure DrawEmptySpaces(AGraphics: TTMSFNCGraphics); override;
    procedure DrawNodeColumns(AGraphics: TTMSFNCGraphics); override;

    procedure DrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; ACaching: Boolean = False; AOffsetX: Single = 0; AOffsetY: Single = 0); override;
    procedure DrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup: Integer; AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColor: TTMSFNCGraphicsColor; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind); virtual;
  end;

implementation

uses
  Math, SysUtils, WEBLib.TMSFNCUtils, WEBLib.Graphics;

type
  TTMSFNCTreeViewColumnOpen = class(TTMSFNCTreeViewColumn);

{ TTMSFNCTreeView }

{$IFDEF FMXLIB}
procedure TTMSFNCTreeView.DoAbsoluteChanged;
begin
  inherited;
  if Parent is TScaledLayout then
    UpdateTreeViewCache;
end;
{$ENDIF}

procedure TTMSFNCTreeView.DrawBorders(AGraphics: TTMSFNCGraphics);
var
  rrt, rrb, trl, grt, grb: TRectF;
  nr: TRectF;
begin
  inherited;
  grt := GetGroupsTopRect;
  grb := GetGroupsBottomRect;
  rrt := GetColumnsTopRect;
  rrb := GetColumnsBottomRect;
  nr := GetContentClipRect;

  {$IFDEF CMNLIB}
  grt.Right := grt.Right - 1;
  grb.Right := grb.Right - 1;
  rrt.Right := rrt.Right - 1;
  rrb.Right := rrb.Right - 1;
  {$ENDIF}

  trl := RectF(nr.Right + 1, rrt.Bottom, nr.Right + 1, rrb.Top);

  AGraphics.Stroke.Assign(ColumnsAppearance.TopStroke);

  if (trl.Right - trl.Left > 0) and (trl.Bottom - trl.Top > 0) then
  begin
    if HorizontalScrollBar.Visible or ColumnsAppearance.Stretch then
      AGraphics.DrawLine(PointF(trl.Right, trl.Top), PointF(trl.Right, trl.Bottom), gcpmLeftDown, gcpmLeftUp)
    else
      AGraphics.DrawLine(PointF(trl.Right, trl.Top), PointF(trl.Right, trl.Bottom), gcpmRightDown, gcpmRightUp);
  end;

  if HorizontalScrollBar.Visible or ColumnsAppearance.Stretch then
  begin
    AGraphics.Stroke.Assign(ColumnsAppearance.TopStroke);
    if (tclTop in ColumnsAppearance.Layouts) and (ColumnsAppearance.TopSize > 0) then
    begin
      AGraphics.DrawLine(PointF(rrt.Left, rrt.Top), PointF(rrt.Left, rrt.Bottom), gcpmRightDown, gcpmRightUp);
      AGraphics.DrawLine(PointF(rrt.Right, rrt.Top), PointF(rrt.Right, rrt.Bottom), gcpmLeftDown, gcpmLeftUp);
    end
    else if not ((tglTop in GroupsAppearance.Layouts) or (GroupsAppearance.TopSize <= 0) or (DisplayGroups.Count = 0)) and (Stroke.Kind <> gskNone) then
      AGraphics.DrawLine(PointF(rrt.Left, rrt.Bottom), PointF(rrt.Right, rrt.Bottom), gcpmRightDown, gcpmLeftDown);

    AGraphics.Stroke.Assign(ColumnsAppearance.BottomStroke);
    if (tclBottom in ColumnsAppearance.Layouts) and (ColumnsAppearance.Bottomsize > 0) then
    begin
      AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Left, rrb.Bottom), gcpmRightDown, gcpmRightUp);
      AGraphics.DrawLine(PointF(rrb.Right, rrb.Top), PointF(rrb.Right, rrb.Bottom), gcpmLeftDown, gcpmLeftUp);
    end
    else if (not (tglBottom in GroupsAppearance.Layouts) or (GroupsAppearance.BottomSize <= 0) or (DisplayGroups.Count = 0)) and (Stroke.Kind <> gskNone) then
      AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Right, rrb.Top), gcpmRightUp, gcpmLeftUp);

    AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);
    if (tglTop in GroupsAppearance.Layouts) and (DisplayGroups.Count > 0) and (GroupsAppearance.TopSize > 0) then
    begin
      AGraphics.DrawLine(PointF(grt.Left, grt.Top), PointF(grt.Left, grt.Bottom), gcpmRightDown, gcpmRightUp);
      AGraphics.DrawLine(PointF(grt.Right, grt.Top), PointF(grt.Right, grt.Bottom), gcpmLeftDown, gcpmLeftUp);
    end;

    if (not (tclTop in ColumnsAppearance.Layouts) or (ColumnsAppearance.TopSize <= 0)) and (Stroke.Kind <> gskNone) then
      AGraphics.DrawLine(PointF(grt.Left, grt.Bottom), PointF(grt.Right, grt.Bottom), gcpmRightDown, gcpmLeftDown);

    AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);
    if (tglBottom in GroupsAppearance.Layouts) and (DisplayGroups.Count > 0) and (GroupsAppearance.BottomSize > 0) then
    begin
      AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Left, grb.Bottom), gcpmRightDown, gcpmRightUp);
      AGraphics.DrawLine(PointF(grb.Right, grb.Top), PointF(grb.Right, grb.Bottom), gcpmLeftDown, gcpmLeftUp);
    end;

    if (not (tclBottom in ColumnsAppearance.Layouts) or (ColumnsAppearance.BottomSize <= 0)) and (Stroke.Kind <> gskNone) then
      AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Right, grb.Top), gcpmRightUp, gcpmLeftUp);
  end;
end;

procedure TTMSFNCTreeView.DrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind);
var
  b: Boolean;
  str: String;
  df: Boolean;
  txtr: TRectF;
  def: Boolean;
  col: TTMSFNCTreeViewColumn;
  trim: TTMSFNCGraphicsTextTrimming;
  ha, va: TTMSFNCGraphicsTextAlign;
  ww: Boolean;
  r: TRectF;
  sr, dr: TRectF;
  szd: Single;
  szr: Single;
  szt: Single;
begin
  inherited;
  r := ARect;
  def := True;
  col := nil;
  if (AColumn >= 0) and (AColumn <= Columns.Count - 1) then
  begin
    col := Columns[AColumn];
    if not col.UseDefaultAppearance then
    begin
      def := False;
      case AKind of
        ikColumnTop:
        begin
          AGraphics.Stroke.Assign(col.TopStroke);
          AGraphics.Fill.Assign(col.TopFill);
        end;
        ikColumnBottom:
        begin
          AGraphics.Stroke.Assign(col.BottomStroke);
          AGraphics.Fill.Assign(col.BottomFill);
        end;
      end;
    end;
  end;

  if def then
  begin
    case AKind of
      ikColumnTop:
      begin
        AGraphics.Stroke.Assign(ColumnsAppearance.TopStroke);
        AGraphics.Fill.Assign(ColumnsAppearance.TopFill);
      end;
      ikColumnBottom:
      begin
        AGraphics.Stroke.Assign(ColumnsAppearance.BottomStroke);
        AGraphics.Fill.Assign(ColumnsAppearance.BottomFill);
      end;
    end;
  end;

  b := True;
  df := True;
  DoBeforeDrawColumnHeader(AGraphics, r, AColumn, AKind, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(r, gcrmNone);

    sr := r;
    szt := 0;

    if Assigned(col) then
    begin
      if col.Filtering.Enabled then
      begin
        szd := col.Filtering.ButtonSize;
        dr := RectF(Round(r.Right - szd - 4), Round(r.Top + ((r.Bottom - r.Top) - szd) / 2), Round(r.Right - 4), Round(r.Top + ((r.Bottom - r.Top) - szd) / 2 + szd));
        AGraphics.DrawDropDownButton(dr, False, False, True, False, AdaptToStyle);
        sr.Right := dr.Left;
        szt := szt + szd + 4;
      end;

      if col.Expandable then
      begin
        szr := col.ExpandingButtonSize;
        sr := RectF(Round(sr.Right - szr - 6), Round(sr.Top + ((sr.Bottom - sr.Top) - szr) / 2), Round(sr.Right - 6), Round(sr.Top + ((sr.Bottom - sr.Top) - szr) / 2 + szr));
        if col.Expanded then
          AGraphics.DrawCompactButton(sr, gcsExpanded, False, False, True, False)
        else
          AGraphics.DrawCompactButton(sr, gcsCollapsed, False, False, True, False);
        sr.Right := sr.Left;
        szt := szt + szr + 6;
      end;

      if (TTMSFNCTreeViewColumnOpen(col).SortKind <> nskNone) and (SortColumn = AColumn) then
      begin
        szr := 8;
        sr := RectF(Round(sr.Right - szr - 6), Round(sr.Top + ((sr.Bottom - sr.Top) - szr) / 2), Round(sr.Right - 6), Round(sr.Top + ((sr.Bottom - sr.Top) - szr) / 2 + szr));
        DrawSortIndicator(AGraphics, sr, ColumnsAppearance.SortIndicatorColor, AColumn, TTMSFNCTreeViewColumnOpen(col).SortIndex, TTMSFNCTreeViewColumnOpen(col).SortKind);
        szt := szt + szr + 6;
      end;
    end;

    if def then
    begin
      case AKind of
        ikColumnTop: AGraphics.Font.Assign(ColumnsAppearance.TopFont);
        ikColumnBottom: AGraphics.Font.Assign(ColumnsAppearance.BottomFont);
      end;
    end
    else if Assigned(col) then
    begin
      case AKind of
        ikColumnTop: AGraphics.Font.Assign(col.TopFont);
        ikColumnBottom: AGraphics.Font.Assign(col.BottomFont);
      end;
    end;

    str := GetColumnText(AColumn);
    DoGetColumnText(AColumn, AKind, str);

    ha := gtaLeading;
    va := gtaCenter;
    ww := False;
    trim := gttNone;
    if Assigned(col) then
    begin
      ha := col.HorizontalTextAlign;
      va := col.VerticalTextAlign;
      ww := col.WordWrapping;
      trim := col.Trimming;
    end;

    DoGetColumnTrimming(AColumn, AKind, trim);
    DoGetColumnWordWrapping(AColumn, AKind, ww);
    DoGetColumnHorizontalTextAlign(AColumn, AKind, ha);
    DoGetColumnVerticalTextAlign(AColumn, AKind, va);

    b := True;
    txtr := r;
    InflateRectEx(txtr, -2, -2);
    txtr.Right := Max(txtr.Left, txtr.Right - szt);

    DoBeforeDrawColumnText(AGraphics, txtr, AColumn, AKind, str, b);
    if b then
    begin
      case AKind of
        ikColumnTop:
        begin
          if ColumnsAppearance.TopVerticalText then
            AGraphics.DrawText(txtr, str, ww, ha, va, trim, -90)
          else
            AGraphics.DrawText(txtr, str, ww, ha, va, trim)
        end;
        ikColumnBottom:
        begin
          if ColumnsAppearance.BottomVerticalText then
            AGraphics.DrawText(txtr, str, ww, ha, va, trim, 90)
          else
            AGraphics.DrawText(txtr, str, ww, ha, va, trim)
        end;
      end;
      DoAfterDrawColumnText(AGraphics, txtr, AColumn, AKind, str);
    end;
    DoAfterDrawColumnHeader(AGraphics, ARect, AColumn, AKind);
  end;
end;

procedure TTMSFNCTreeView.DrawEmptySpaces(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  b, df: Boolean;
begin
  inherited;
  if ColumnsAppearance.FillEmptySpaces and not StretchScrollBars then
  begin
    if (tclTop in ColumnsAppearance.Layouts) and (ColumnsAppearance.TopSize > 0) then
    begin
      //Column top right
      r := GetColumnTopRightEmptyRect;
      {$IFDEF CMNLIB}
      r.Left := r.Left - 1;
      r.Right := r.Right + 1;
      r.Bottom := r.Bottom + 1;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(ColumnsAppearance.TopFill);
      AGraphics.Stroke.Assign(ColumnsAppearance.TopStroke);

      DoBeforeDrawColumnEmptySpace(AGraphics, r, tcesTopRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftDownAndExpandWidth);

        DoAfterDrawColumnEmptySpace(AGraphics, r, tcesTopRight);
      end;
    end;

    if (tclBottom in ColumnsAppearance.Layouts) and (ColumnsAppearance.BottomSize > 0) then
    begin
      //Column bottom right
      r := GetColumnBottomRightEmptyRect;
      {$IFDEF CMNLIB}
      r.Left := r.Left - 1;
      r.Right := r.Right + 1;
      r.Top := r.Top - 1;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(ColumnsAppearance.BottomFill);
      AGraphics.Stroke.Assign(ColumnsAppearance.BottomStroke);

      DoBeforeDrawColumnEmptySpace(AGraphics, r, tcesBottomRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftUpAndExpandWidth);

        DoAfterDrawColumnEmptySpace(AGraphics, r, tcesBottomRight);
      end;
    end;
  end;

  if GroupsAppearance.FillEmptySpaces and not StretchScrollBars then
  begin
    if (tglTop in GroupsAppearance.Layouts) and (GroupsAppearance.TopSize > 0) then
    begin
      //Group top right
      r := GetGroupTopRightEmptyRect;
      {$IFDEF CMNLIB}
      r.Left := r.Left - 1;
      r.Bottom := r.Bottom + 1;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.TopFill);
      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, tgesTopRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftLeftDown);

        DoAfterDrawGroupEmptySpace(AGraphics, r, tgesTopRight);
      end;
    end;

    if (tglBottom in GroupsAppearance.Layouts) and (GroupsAppearance.BottomSize > 0) then
    begin
      //Group bottom right
      r := GetGroupBottomRightEmptyRect;
      {$IFDEF CMNLIB}
      r.Left := r.Left - 1;
      r.Right := r.Right + 1;
      r.Top := r.Top - 1;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.BottomFill);
      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, tgesBottomRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftLeftUp);

        DoAfterDrawGroupEmptySpace(AGraphics, r, tgesBottomRight);
      end;
    end;
  end;
end;

procedure TTMSFNCTreeView.DrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup,
  AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind);
var
  b, df: Boolean;
  str: String;
  txtr: TRectF;
  grp: TTMSFNCTreeViewGroup;
  def: Boolean;
begin
  grp := nil;
  def := True;
  if (AGroup >= 0) and (AGroup <= Groups.Count - 1) then
  begin
    grp := Groups[AGroup];
    if not grp.UseDefaultAppearance then
    begin
      def := False;
      case AKind of
        ikGroupTop:
        begin
          AGraphics.Stroke.Assign(grp.TopStroke);
          AGraphics.Fill.Assign(grp.TopFill);
        end;
        ikGroupBottom:
        begin
          AGraphics.Stroke.Assign(grp.BottomStroke);
          AGraphics.Fill.Assign(grp.BottomFill);
        end;
      end;
    end;
  end;

  if def then
  begin
    case AKind of
      ikGroupTop:
      begin
        AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);
        AGraphics.Fill.Assign(GroupsAppearance.TopFill);
      end;
      ikGroupBottom:
      begin
        AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);
        AGraphics.Fill.Assign(GroupsAppearance.BottomFill);
      end;
    end;
  end;

  b := True;
  df := True;
  DoBeforeDrawGroup(AGraphics, ARect, AGroup, AStartColumn, AEndColumn, AKind, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(ARect, gcrmNone);

    if def then
    begin
      case AKind of
        ikGroupTop: AGraphics.Font.Assign(GroupsAppearance.TopFont);
        ikGroupBottom: AGraphics.Font.Assign(GroupsAppearance.BottomFont);
      end;
    end
    else if Assigned(grp) then
    begin
      case AKind of
        ikGroupTop: AGraphics.Font.Assign(grp.TopFont);
        ikGroupBottom: AGraphics.Font.Assign(grp.BottomFont);
      end;
    end;

    txtr := ARect;
    InflateRectEx(txtr, -2, -2);

    str := GetGroupText(AGroup);
    DoGetGroupText(AGroup, AKind, str);
    b := True;
    DoBeforeDrawGroupText(AGraphics, txtr, AGroup, AStartColumn, AEndColumn, AKind, str, b);
    if b then
    begin
      case AKind of
        ikGroupTop:
        begin
          if GroupsAppearance.TopVerticalText then
            AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, -90)
          else
            AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone)
        end;
        ikGroupBottom:
        begin
          if GroupsAppearance.BottomVerticalText then
            AGraphics.DrawText(txtr, str, False, GroupsAppearance.BottomHorizontalTextAlign, GroupsAppearance.BottomVerticalTextAlign, gttNone, 90)
          else
            AGraphics.DrawText(txtr, str, False, GroupsAppearance.BottomHorizontalTextAlign, GroupsAppearance.BottomVerticalTextAlign, gttNone)
        end;
      end;
      DoAfterDrawGroupText(AGraphics, txtr, AGroup, AStartColumn, AEndColumn, AKind, str);
    end;
    DoAfterDrawGroup(AGraphics, ARect, AGroup, AStartColumn, AEndColumn, AKind);
  end;
end;

procedure TTMSFNCTreeView.DrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ANode: TTMSFNCTreeViewVirtualNode; ACaching: Boolean = False; AOffsetX: Single = 0; AOffsetY: Single = 0);
var
  str: String;
  b, df: Boolean;
  txtr, titr: TRectF;
  I: Integer;
  bmp: TTMSFNCBitmap;
  bmpnr, extr: TRectF;
  AColor: TTMSFNCGraphicsColor;
  sts, stp: Integer;
  bmpn: TTMSFNCBitmap;
  ha, va: TTMSFNCGraphicsTextAlign;
  c: TTMSFNCTreeViewColumn;
  ww: Boolean;
  trim: TTMSFNCGraphicsTextTrimming;
  en: Boolean;
  colw: Double;
  chk: TTMSFNCTreeViewNodeCheckType;
  chkr: TRectF;
  chkbmp: TTMSFNCBitmap;
  ck, ext: Boolean;
  lr, dr: TRectF;
  bmpexr, dexr: TRectF;
  sel: Boolean;
  cl: TTMSFNCTreeViewColumn;
  rfoc: TRectF;
  p, lp, n: TTMSFNCTreeViewVirtualNode;
  rsel, r: TRectF;
  ns: Double;
  dpi: Boolean;
  g: TTMSFNCGraphics;
  bmpa: TBitmap;
  bt: TTMSFNCBitmap;
  s: TTMSFNCGraphicsSides;
begin
  inherited;
  if not Assigned(ANode) or ((DragNode = ANode) and DragModeStarted) then
    Exit;

  dpi := TTMSFNCUtils.IsHighDPIScale;

  en := True;
  DoIsNodeEnabled(ANode, en);
  ext := False;
  DoIsNodeExtended(ANode, ext);
  sel := IsVirtualNodeSelected(ANode);

  if en then
  begin
    if sel then
    begin
      if ext then
      begin
        AGraphics.Fill.Assign(NodesAppearance.ExtendedSelectedFill);
        AGraphics.Stroke.Assign(NodesAppearance.ExtendedSelectedStroke);
      end
      else
      begin
        AGraphics.Fill.Assign(NodesAppearance.SelectedFill);
        AGraphics.Stroke.Assign(NodesAppearance.SelectedStroke);
      end;

      AColor := AGraphics.Fill.Color;
      DoGetNodeSelectedColor(ANode, AColor);
      AGraphics.Fill.Color := AColor;
    end
    else
    begin
      if ext then
      begin
        AGraphics.Fill.Assign(NodesAppearance.ExtendedFill);
        AGraphics.Stroke.Assign(NodesAppearance.ExtendedStroke);
      end
      else
      begin
        AGraphics.Fill.Assign(NodesAppearance.Fill);
        AGraphics.Stroke.Assign(NodesAppearance.Stroke);
      end;

      AColor := AGraphics.Fill.Color;
      DoGetNodeColor(ANode, AColor);
      AGraphics.Fill.Color := AColor;
    end;
  end
  else
  begin
    if ext then
    begin
      AGraphics.Fill.Assign(NodesAppearance.ExtendedDisabledFill);
      AGraphics.Stroke.Assign(NodesAppearance.ExtendedDisabledStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(NodesAppearance.DisabledFill);
      AGraphics.Stroke.Assign(NodesAppearance.DisabledStroke);
    end;

    AColor := AGraphics.Fill.Color;
    DoGetNodeDisabledColor(ANode, AColor);
    AGraphics.Fill.Color := AColor;
  end;

  df := True;
  b := True;
  DoBeforeDrawNode(AGraphics, ARect, ANode, b, df);

  if b then
  begin
    if df then
    begin
      rsel := ARect;
      if sel and not ext then
      begin
        case NodesAppearance.SelectionArea of
          tsaFull: rsel.Left := rsel.Left + 1.5;
          tsaFromText:
          begin
            if Length(ANode.TextRects) > 0 then
              rsel.Left := ANode.TextRects[0].Left;
          end;
        end;
      end;

      s := AllSides;
      DoGetNodeSides(ANode, s);
      AGraphics.DrawRectangle(rsel, s, gcrmNone);

      if (ANode = FocusedVirtualNode) and IsFocused and NodesAppearance.ShowFocus then
      begin
        rfoc := rsel;
        InflateRectEx(rfoc, -2, -2);
        AGraphics.DrawFocusRectangle(rfoc);
      end;
    end;

    if not CompactMode then
    begin
      sts := 0;
      stp := ColumnCount - 1;
      if ext then
        stp := Min(0, ColumnCount - 1);

      for I := sts to stp do
      begin
        cl := nil;
        if (I >= 0) and (I <= Columns.Count - 1) then
          cl := Columns[I];

        if  (I >= 0) and (I <= Length(ANode.ExpandRects) - 1) and NodesAppearance.ShowLines and (I = NodesAppearance.ExpandColumn) and (NodesAppearance.ExpandWidth > 0) then
        begin
          r := ANode.ExpandRects[I];
          OffsetRectEx(r, AOffsetX, AOffsetY);
          AGraphics.Stroke.Assign(NodesAppearance.LineStroke);

          if (NodesAppearance.LineStroke.Kind in [gskDash, gskDot, gskDashDot, gskDashDotDot]) then
            AGraphics.StartSpecialPen;

          ns := NodesAppearance.LevelIndent;
          lr := RectF(r.Left + NodesAppearance.ExpandWidth / 2, ARect.Top, r.Left + NodesAppearance.ExpandWidth / 2 + ns / 2, ARect.Bottom);

          AGraphics.DrawLine(PointF(lr.Left, lr.Top + (lr.Bottom - lr.Top) / 2), PointF(lr.Right, lr.Top + (lr.Bottom - lr.Top) / 2));

          if VisibleNodes.Count > 1 then
          begin
            n := ANode;
            p := n.GetParent;

            dr := lr;
            while Assigned(p) do
            begin
              if (((n = ANode) and (n.Index <= p.Children - 1)) or ((n <> ANode) and (n.Index < p.Children - 1))) then
              begin
                if n.Index < p.Children - 1 then
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top), PointF(dr.Left, dr.Bottom), gcpmRightDown, gcpmRightUp)
                else
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top), PointF(dr.Left, dr.Top + (dr.Bottom - dr.Top) / 2), gcpmRightDown, gcpmRightUp);
              end;

              n := p;
              p := n.GetParent;
              OffsetRectEx(dr, -ns, 0);
            end;

            if NodeStructure.Count - ANode.TotalChildren - 1 > 0 then
            begin
              p := ANode.GetParent;
              if not Assigned(p) then
              begin
                if (ANode.Row = NodeStructure.Count - ANode.TotalChildren - 1) then
                begin
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top), PointF(dr.Left, dr.Top + (dr.Bottom - dr.Top) / 2), gcpmRightDown, gcpmRightUp)
                end
                else if ANode.Row = 0 then
                begin
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top + (dr.Bottom - dr.Top) / 2), PointF(dr.Left, Int(dr.Bottom) - 0.5), gcpmRightDown, gcpmRightUp)
                end
                else
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top), PointF(dr.Left, dr.Bottom), gcpmRightDown, gcpmRightUp)
              end
              else if Assigned(p) then
              begin
                lp := p;
                p := p.GetParent;
                dr := lr;
                OffsetRectEx(dr, -ns, 0);
                while Assigned(p) do
                begin
                  lp := p;
                  p := p.GetParent;
                  OffsetRectEx(dr, -ns, 0);
                end;

                if Assigned(lp) and (lp.Row < NodeStructure.Count - lp.TotalChildren - 1)  then
                  AGraphics.DrawLine(PointF(dr.Left, dr.Top), PointF(dr.Left, dr.Bottom), gcpmRightDown, gcpmRightUp);
              end;
            end;
          end;

          if (NodesAppearance.LineStroke.Kind in [gskDash, gskDot, gskDashDot, gskDashDotDot]) then
            AGraphics.StopSpecialPen;
        end;

        if Assigned(cl) and not cl.UseDefaultAppearance and not ext then
          AGraphics.Font.Assign(cl.Font)
        else
        begin
          if ext then
            AGraphics.Font.Assign(NodesAppearance.ExtendedFont)
          else
            AGraphics.Font.Assign(NodesAppearance.Font);
        end;

        colw := ColumnWidths[I];
        if (colw > 0) or ext then
        begin
          if en then
          begin
            if sel then
            begin
              if ext then
                AColor := NodesAppearance.ExtendedSelectedFontColor
              else
                AColor := NodesAppearance.SelectedFontColor;

              DoGetNodeSelectedTextColor(ANode, I, AColor);
              AGraphics.Font.Color := AColor;
            end
            else
            begin
              if Assigned(cl) and not cl.UseDefaultAppearance and not ext then
                AColor := cl.Font.Color
              else
              begin
                if ext then
                  AColor := NodesAppearance.ExtendedFontColor
                else
                  AColor := NodesAppearance.Font.Color;
              end;

              DoGetNodeTextColor(ANode, I, AColor);
              AGraphics.Font.Color := AColor;
            end;
          end
          else
          begin
            if ext then
              AColor := NodesAppearance.ExtendedDisabledFontColor
            else
              AColor := NodesAppearance.DisabledFontColor;

            DoGetNodeDisabledTextColor(ANode, I, AColor);
            AGraphics.Font.Color := AColor;
          end;

          if (I >= 0) and (I <= Length(ANode.CheckRects) - 1) and (I <= Length(ANode.CheckStates) - 1) then
          begin
            chk := tvntNone;
            DoGetNodeCheckType(ANode, I, chk);
            if chk <> tvntNone then
            begin
              chkbmp := nil;
              ck := ANode.CheckStates[I];
              DoIsNodeChecked(ANode, I, ck);

              case chk of
                tvntCheckBox: chkbmp := TTMSFNCBitmap(GetCheckBoxBitmap(ck, False));
                tvntRadioButton: chkbmp := TTMSFNCBitmap(GetRadioButtonBitmap(ck, False));
              end;

              if Assigned(chkbmp) then
              begin
                chkr := ANode.CheckRects[I];
                OffsetRectEx(chkr, AOffsetX, AOffsetY);

                b := True;
                DoBeforeDrawNodeCheck(AGraphics, chkr, I, ANode, chkbmp, b);
                if b then
                begin
                  AGraphics.DrawBitmap(chkr, chkbmp);
                  DoAfterDrawNodeCheck(AGraphics, chkr, I, ANode, chkbmp);
                end;
              end;
            end;
          end;

          if (I >= 0) and (I <= Length(ANode.BitmapRects) - 1) then
          begin
            bmpn := nil;
            DoGetNodeIcon(ANode, I, False, bmpn);
            if not Assigned(bmpn) and dpi then
              DoGetNodeIcon(ANode, I, True, bmpn);

            if Assigned(bmpn) and not IsBitmapEmpty(bmpn) then
            begin
              bmpnr := ANode.BitmapRects[I];
              OffsetRectEx(bmpnr, AOffsetX, AOffsetY);

              b := True;
              DoBeforeDrawNodeIcon(AGraphics, bmpnr, I, ANode, bmpn, b);
              if b then
              begin
                AGraphics.DrawBitmap(bmpnr, bmpn);
                DoAfterDrawNodeIcon(AGraphics, bmpnr, I, ANode, bmpn);
              end;
            end;
          end;

          if (I >= 0) and (I <= Length(ANode.ExpandRects) - 1) and (ANode.Children > 0) and (I = NodesAppearance.ExpandColumn) and (NodesAppearance.ExpandWidth > 0) then
          begin
            if dpi then
            begin
              if ANode.Expanded then
                bmp := NodesAppearance.CollapseNodeIconLarge
              else
                bmp := NodesAppearance.ExpandNodeIconLarge;
            end
            else
            begin
              if ANode.Expanded then
                bmp := NodesAppearance.CollapseNodeIcon
              else
                bmp := NodesAppearance.ExpandNodeIcon;
            end;

            bmpa := nil;
            if AdaptToStyle then
            begin
              bmpa := TBitmap.Create;
              bmpa.SetSize(bmp.Width, bmp.Height);
              g := TTMSFNCGraphics.Create(bmpa.Canvas);
              try
                g.BeginScene;
                g.Fill.Color := TTMSFNCGraphics.DefaultSelectionFillColor;
                g.Fill.Kind := gfkSolid;
                g.Stroke.Kind := gskSolid;
                g.Stroke.Color := TTMSFNCGraphics.DefaultTextFontColor;
                g.DrawRectangle(RectF(0, 0, bmp.Width, bmp.Height));
                if dpi then
                  g.Stroke.Width := 2;

                if not ANode.Expanded then
                  g.DrawLine(PointF(bmp.Width div 2, 0), PointF(bmp.Width div 2, bmp.Height), gcpmRightDown);

                g.DrawLine(PointF(0, bmp.Height div 2), PointF(bmp.Width, bmp.Height div 2), gcpmRightDown);
              finally
                g.EndScene;
                g.Free;
              end;
            end;

            bmpexr := ANode.ExpandRects[I];
            OffsetRectEx(bmpexr, AOffsetX, AOffsetY);

            b := True;
            DoBeforeDrawNodeExpand(AGraphics, bmpexr, I, ANode, bmp, b);
            if b then
            begin
              if dpi then
              begin
                dexr := RectF(Int(bmpexr.Left + ((bmpexr.Right - bmpexr.Left) - bmp.Width / 2) / 2), Int(bmpexr.Top + ((bmpexr.Bottom - bmpexr.Top) - bmp.Height / 2) / 2),
                  Int(bmpexr.Left + ((bmpexr.Right - bmpexr.Left) - bmp.Width / 2) / 2) + bmp.Width / 2, Int(bmpexr.Top + ((bmpexr.Bottom - bmpexr.Top) - bmp.Height / 2) / 2) + bmp.Height / 2);
              end
              else
              begin
                dexr := RectF(Int(bmpexr.Left + ((bmpexr.Right - bmpexr.Left) - bmp.Width) / 2), Int(bmpexr.Top + ((bmpexr.Bottom - bmpexr.Top) - bmp.Height) / 2),
                  Int(bmpexr.Left + ((bmpexr.Right - bmpexr.Left) - bmp.Width) / 2) + bmp.Width, Int(bmpexr.Top + ((bmpexr.Bottom - bmpexr.Top) - bmp.Height) / 2) + bmp.Height);
              end;

              if AdaptToStyle and Assigned(bmpa) then
              begin
                bt := TTMSFNCBitmap.Create;
                try
                  bt.Assign(bmpa);
                  AGraphics.DrawBitmap(dexr, bt);
                finally
                  bt.Free;
                end;
                bmpa.Free;
                bmpa := nil;
              end
              else
                AGraphics.DrawBitmap(dexr, bmp);

              DoAfterDrawNodeExpand(AGraphics, bmpexr, I, ANode, bmp);
            end;

            if Assigned(bmpa) then
              bmpa.Free;
          end;

          if (I >= 0) and (I <= Length(ANode.TextRects) - 1) and ANode.TitleExpanded[I] then
          begin
            txtr := ANode.TextRects[I];
            OffsetRectEx(txtr, AOffsetX, AOffsetY);

            InflateRectEx(txtr, -2, 0);

            c := nil;
            if (I >= 0) and (I <= Columns.Count - 1) then
              c := Columns[I];

            ha := gtaLeading;
            va := gtaCenter;
            ww := False;
            trim := gttNone;
            if Assigned(c) then
            begin
              ha := c.HorizontalTextAlign;
              va := c.VerticalTextAlign;
              ww := c.WordWrapping;
              trim := c.Trimming;
            end;

            DoGetNodeTrimming(ANode, I, trim);
            DoGetNodeWordWrapping(ANode, I, ww);
            DoGetNodeHorizontalTextAlign(ANode, I, ha);
            DoGetNodeVerticalTextAlign(ANode, I,va);

            str := '';
            DoGetNodeText(ANode, I, tntmDrawing, str);
            b := True;
            DoBeforeDrawNodeText(AGraphics, txtr, I, ANode, str, b);
            if b then
            begin
              if InplaceEditorActive and ((UpdateNodeColumn <> I) or (FocusedVirtualNode <> ANode)) or not InplaceEditorActive then
                AGraphics.DrawText(txtr, str, ww, ha, va, trim);

              DoAfterDrawNodeText(AGraphics, txtr, I, ANode, str);
            end;
          end;

          if Assigned(cl) and not cl.UseDefaultAppearance then
            AGraphics.Font.Assign(cl.TitleFont)
          else
            AGraphics.Font.Assign(NodesAppearance.TitleFont);

          if en then
          begin
            if sel then
            begin
              AColor := NodesAppearance.SelectedTitleFontColor;
              DoGetNodeSelectedTitleColor(ANode, I, AColor);
              AGraphics.Font.Color := AColor;
            end
            else
            begin
              if Assigned(cl) and not cl.UseDefaultAppearance then
                AColor := cl.TitleFont.Color
              else
                AColor := NodesAppearance.TitleFont.Color;

              DoGetNodeTitleColor(ANode, I, AColor);
              AGraphics.Font.Color := AColor;
            end;
          end
          else
          begin
            AColor := NodesAppearance.DisabledTitleFontColor;
            DoGetNodeDisabledTitleColor(ANode, I, AColor);
            AGraphics.Font.Color := AColor;
          end;

          if (I >= 0) and (I <= Length(ANode.TitleRects) - 1) then
          begin
            titr := ANode.TitleRects[I];
            OffsetRectEx(titr, AOffsetX, AOffsetY);

            InflateRectEx(titr, -2, 0);

            c := nil;
            if (I >= 0) and (I <= Columns.Count - 1) then
              c := Columns[I];

            ha := gtaLeading;
            va := gtaCenter;
            ww := False;
            trim := gttNone;
            if Assigned(c) then
            begin
              ha := c.TitleHorizontalTextAlign;
              va := c.TitleVerticalTextAlign;
              ww := c.TitleWordWrapping;
              trim := c.TitleTrimming;
            end;

            DoGetNodeTitleTrimming(ANode, I, trim);
            DoGetNodeTitleWordWrapping(ANode, I, ww);
            DoGetNodeTitleHorizontalTextAlign(ANode, I, ha);
            DoGetNodeTitleVerticalTextAlign(ANode, I,va);

            str := '';
            DoGetNodeTitle(ANode, I, tntmDrawing, str);
            b := True;
            DoBeforeDrawNodeTitle(AGraphics, titr, I, ANode, str, b);
            if b then
            begin
  //            if InplaceEditorActive and ((UpdateNodeColumn <> I) or (FocusedVirtualNode <> ANode)) or not InplaceEditorActive then
                AGraphics.DrawText(titr, str, ww, ha, va, trim);

              DoAfterDrawNodeTitle(AGraphics, titr, I, ANode, str);
            end;
          end;

          if (I >= 0) and (I <= Length(ANode.ExtraRects) - 1) then
          begin
            extr := ANode.ExtraRects[I];
            OffsetRectEx(extr, AOffsetX, AOffsetY);
            b := True;
            DoBeforeDrawNodeExtra(AGraphics, extr, I, ANode, b);
            if b then
            begin
              DoDrawNodeExtra(AGraphics, extr, I, ANode);
              DoAfterDrawNodeExtra(AGraphics, extr, I, ANode);
            end;
          end;

          if (I >= 0) and (I <= Length(ANode.TitleExtraRects) - 1) then
          begin
            extr := ANode.TitleExtraRects[I];
            OffsetRectEx(extr, AOffsetX, AOffsetY);
            b := True;
            DoBeforeDrawNodeTitleExtra(AGraphics, extr, I, ANode, b);
            if b then
            begin
              DoDrawNodeTitleExtra(AGraphics, extr, I, ANode);
              DoAfterDrawNodeTitleExtra(AGraphics, extr, I, ANode);
            end;
          end;
        end;
      end;
    end;

    DoAfterDrawNode(AGraphics, ARect, ANode);
  end;
end;

procedure TTMSFNCTreeView.DrawNodeColumns(AGraphics: TTMSFNCGraphics);
var
  b, df: Boolean;
  I: Integer;
  r: TRectF;
  x, w: Double;
  cr: TRectF;
  crcl: TRectF;
  hs: Double;
  c: TTMSFNCTreeViewColumn;
begin
  inherited;
  cr := GetContentRect;
  crcl := GetContentClipRect;
  hs := GetHorizontalScrollPosition;
  for I := 1 to ColumnCount - 1 do
  begin
    if (I >= 0) and (I < Columns.Count - 1) then
    begin
      x := Int(ColumnPositions[I]) - Int(hs);
      w := Int(ColumnWidths[I]);

      if (ColumnStroke.Color <> gcNull) and (ColumnStroke.Kind <> gskNone) then
      begin
        AGraphics.Stroke.Assign(ColumnStroke);
        b := True;
        df := True;
        r := RectF(x, cr.Top, x + w, cr.Bottom);

        DoBeforeDrawColumn(AGraphics, r, I, b, df);

        if b then
        begin
          if df then
            AGraphics.DrawRectangle(r, gcrmShiftRightAndShrinkHeight);

          DoAfterDrawColumn(AGraphics, r, I);
        end;
      end;
    end;
  end;

  if NodeStructure.Count > 0 then
  begin
    for I := 0 to ColumnCount - 1 do
    begin
      if (I >= 0) and (I <= Columns.Count - 1) then
      begin
        c := Columns[I];
        x := Int(ColumnPositions[I]) - Int(hs);
        w := Int(ColumnWidths[I]);

        b := True;
        df := True;

        AGraphics.Fill.Kind := gfkNone;
        AGraphics.Stroke.Assign(NodesAppearance.ColumnStroke);
        if not c.UseDefaultAppearance then
        begin
          AGraphics.Stroke.Assign(c.Stroke);
          AGraphics.Fill.Assign(c.Fill);
        end;

        r := RectF(x, crcl.Top, x + w, crcl.Bottom);

        DoBeforeDrawNodeColumn(AGraphics, r, I, b, df);

        if b then
        begin
          if df then
            AGraphics.DrawRectangle(r, [gsTop, gsRight, gsBottom], gcrmShiftRightAndShrinkHeight);

          DoAfterDrawNodeColumn(AGraphics, r, I);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCTreeView.DrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColor: TTMSFNCGraphicsColor; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind);
var
  pth: TTMSFNCGraphicsPath;
  vertt: TTMSFNCGraphicsTextAlign;
  c: TTMSFNCGraphicsColor;
  txtr: TRectF;
  b: Boolean;
begin
  inherited;
  c := AColor;
  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Fill.Color := c;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Stroke.Color := c;

  b := True;
  DoBeforeDrawSortIndicator(AGraphics, ARect, AColumn, ASortIndex, ASortKind, b);
  if b then
  begin
    vertt := gtaCenter;
    txtr := ARect;
    pth := TTMSFNCGraphicsPath.Create;
    try
      case ASortKind of
        nskAscending:
        begin
          vertt := gtaTrailing;
          pth.MoveTo(PointF(ARect.Left + (ARect.Right - ARect.Left) / 2, ARect.Top));
          pth.LineTo(PointF(ARect.Right, ARect.Bottom));
          pth.LineTo(PointF(ARect.Left, ARect.Bottom));
          txtr := RectF(ARect.Left, ARect.Top + 2, ARect.Right, ARect.Bottom + 2);
        end;
        nskDescending:
        begin
          vertt := gtaLeading;
          pth.MoveTo(PointF(ARect.Left, ARect.Top));
          pth.LineTo(PointF(ARect.Right, ARect.Top));
          pth.LineTo(PointF(ARect.Left + (ARect.Right - ARect.Left) / 2, ARect.Bottom));
          txtr := RectF(ARect.Left, ARect.Top - 2, ARect.Right, ARect.Bottom - 2);
        end;
      end;
      pth.ClosePath;
      AGraphics.DrawPath(pth);

      if ASortIndex <> -1 then
      begin
        AGraphics.Font.Color := gcWhite;
        TTMSFNCUtils.SetFontSize(AGraphics.Font, 9);
        AGraphics.DrawText(txtr, IntToStr(ASortIndex), False, gtaCenter, vertt);
      end;
    finally
      pth.Free;
    end;
    DoAfterDrawSortIndicator(AGraphics, ARect, AColumn, ASortIndex, ASortKind);
  end;
end;

procedure TTMSFNCTreeView.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCTreeView, TTMSFNCTreeViewColumn, TTMSFNCTreeViewNode, TTMSFNCTreeViewNodeValue]);
end;

end.
