unit unit_AuftragPosEinzel;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, XData.Web.JsonDataset, XData.Web.Dataset,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.ExtCtrls, dateutils, strutils,
  WEBLib.DBCtrls;

type
  TAuftragPosEinzel = class(TForm)
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    WTCPosition: TTableControl;
    btnPosAbbrechen: TButton;
    btnPosSpeichern: TButton;
    btnPositionLoeschen: TButton;
    xdsPosition: TXDataWebDataSet;
    xdsPositionID: TIntegerField;
    xdsPositionPosNr: TFloatField;
    xdsPositionPosUNr: TFloatField;
    xdsPositionArtikelueberschrift: TStringField;
    xdsPositionArtikelbeschreibung: TStringField;
    xdsPositionMenge: TFloatField;
    xdsPositionArtderMenge: TStringField;
    xdsPositionVerkaufspreis: TFloatField;
    xdsPositionEinkaufspreis: TFloatField;
    xdsPositionArtikelnummer: TXDataWebEntityField;
    xdsPositionMonteurP: TIntegerField;
    xdsPositionArbeitszeitDatum: TDateTimeField;
    xdsPositionArbeitszeitVON: TDateTimeField;
    xdsPositionArbeitszeitBIS: TDateTimeField;
    xdsPositionSumme: TBooleanField;
    xdsPositionSeriennummer: TStringField;
    xdsPositionWarengruppe: TStringField;
    xdsPositionWarenuntergruppe: TStringField;
    xdsPositionArtikelnummerKunde: TStringField;
    xdsPositionLieferant: TStringField;
    xdsPositionGewicht: TFloatField;
    xdsPositionLaenge: TFloatField;
    xdsPositionBreite: TFloatField;
    xdsPositionHoehe: TFloatField;
    xdsPositionMehrwertsteuersatz: TFloatField;
    xdsPositionEndpreis: TFloatField;
    xdsPositionPosMWST: TFloatField;
    xdsPositionBruttoEndpreis: TFloatField;
    xdsPositionEndpreis_EK: TFloatField;
    xdsPositionRabattsatz: TFloatField;
    xdsPositionPreisvorRabatt: TFloatField;
    xdsPositionPKZ: TFloatField;
    xdsPositionTextfrei10: TStringField;
    xdsPositionTextfrei20: TStringField;
    xdsPositionProduktionAbgeschlossen: TDateTimeField;
    xdsPositionEinzelpreisBrutto: TFloatField;
    xdsPositionPosMarge: TFloatField;
    xdsPositionPosMargeProzent: TFloatField;
    xdsPositionBereitsGeliefert: TFloatField;
    xdsPositionausliefern: TFloatField;
    xdsPositionBestellungErledigt: TBooleanField;
    xdsPositionLager: TStringField;
    xdsPositionbestellen: TBooleanField;
    xdsPositionWarenbestand: TBooleanField;
    xdsPositionArtikelbeschreibungRTF: TStringField;
    xdsPositionQuellartikel: TStringField;
    xdsPositionArtikelnummerWB: TStringField;
    xdsPositionPreisanteilNetto: TFloatField;
    xdsPositionSetHPos: TStringField;
    xdsPositionSetUPos: TStringField;
    xdsPositionProvisionssatz: TFloatField;
    xdsPositionProvisionsbetrag: TFloatField;
    xdsPositionArtikelsprache: TStringField;
    xdsPositionEKvom: TDateTimeField;
    xdsPositionMengenberechnungID: TStringField;
    xdsPositionTaraID: TStringField;
    xdsPositionTara: TFloatField;
    xdsPositionBruttoGewicht: TFloatField;
    xdsPositionAussenflaeche: TFloatField;
    xdsPositionLadeort: TStringField;
    xdsPositionSeitenumbruch: TBooleanField;
    xdsPositionPlanerFarbe: TIntegerField;
    xdsPositionMWStKey: TIntegerField;
    xdsPositionZSnachPos: TBooleanField;
    xdsPositionLieferzeit: TStringField;
    xdsPositionPreisVorRabattWHG: TFloatField;
    xdsPositionReferenzKalkulation: TStringField;
    xdsPositionPKey: TIntegerField;
    xdsPositionAuftragsnummer: TStringField;
    xdsPositionlwbID: TStringField;
    xdsPositionBelegdatum: TDateTimeField;
    xdsPositionWarenkontrollnummern: TStringField;
    xdsPositionVolumen: TFloatField;
    xdsPositionPosVolumen: TFloatField;
    xdsPositionArtikelAttribute: TStringField;
    xdsPositionNummerkomplett: TXDataWebEntityField;
    sourcePosition: TDataSource;
    dsArtikel: TXDataWebDataSet;
    dsArtikelHersteller: TXDataWebEntityField;
    dsArtikelArtderMenge: TXDataWebEntityField;
    dsArtikelPfandArt: TXDataWebEntityField;
    dsArtikelWarengruppe: TXDataWebEntityField;
    dsArtikelWarenuntergruppe: TXDataWebEntityField;
    dsArtikelArtikelnummer: TStringField;
    dsArtikelArtikelueberschrift: TStringField;
    dsArtikelArtikelbeschreibung: TStringField;
    dsArtikelVerkaufspreis: TFloatField;
    dsArtikelMindestbestand: TFloatField;
    dsArtikelPreisliste: TStringField;
    dsArtikelGewicht: TFloatField;
    dsArtikelLaenge: TFloatField;
    dsArtikelBreite: TFloatField;
    dsArtikelHoehe: TFloatField;
    dsArtikelArtikelZusatz: TStringField;
    dsArtikelLagerort: TStringField;
    dsArtikelBuchungskonto: TIntegerField;
    dsArtikelEingangMenge: TFloatField;
    dsArtikelEingangEndpreis: TFloatField;
    dsArtikelAusgangMenge: TFloatField;
    dsArtikelAusgangEndpreisEK: TFloatField;
    dsArtikelAusgangEndpreisVK: TFloatField;
    dsArtikelBestand: TFloatField;
    dsArtikelBestandswert: TFloatField;
    dsArtikelMarge: TFloatField;
    dsArtikelMargeProzent: TFloatField;
    dsArtikelArtikelnummer2: TStringField;
    dsArtikelArtikelmatchcode: TStringField;
    dsArtikelRabattpreis1: TFloatField;
    dsArtikelRabattpreis2: TFloatField;
    dsArtikelRabattpreis3: TFloatField;
    dsArtikelRabattpreis4: TFloatField;
    dsArtikelRabattpreis5: TFloatField;
    dsArtikelArtikelnichtmehrgueltig: TBooleanField;
    dsArtikelEinkaufspreis: TFloatField;
    dsArtikelAufschlag: TFloatField;
    dsArtikelWarenbestand: TBooleanField;
    dsArtikelTextfrei10: TStringField;
    dsArtikelTextfrei20: TStringField;
    dsArtikelArtikelbeschreibungRTF: TStringField;
    dsArtikelArtikeldatei1: TStringField;
    dsArtikelArtikeldatei2: TStringField;
    dsArtikelArtikeldatei3: TStringField;
    dsArtikelArtikeldatei4: TStringField;
    dsArtikelArtikeldatei5: TStringField;
    dsArtikelEinkaufspreisVom: TDateTimeField;
    dsArtikelEAN: TStringField;
    dsArtikelVGPFaktor: TFloatField;
    dsArtikelVGPMengenart: TStringField;
    dsArtikelVerleihpreis: TFloatField;
    dsArtikelZusatzPreis: TFloatField;
    dsArtikelVerpackungsart: TStringField;
    dsArtikelVerpackungsMenge: TFloatField;
    dsArtikelQuellartikel: TStringField;
    dsArtikelrabattierfaehig: TBooleanField;
    dsArtikelAngebotAlternativeAN: TStringField;
    dsArtikelPreisanteilNetto: TFloatField;
    dsArtikelFarbe: TIntegerField;
    dsArtikelAEinkauf: TBooleanField;
    dsArtikelAVerkauf: TBooleanField;
    dsArtikelArtikelnummerLieferant: TStringField;
    dsArtikelLieferantnummer: TStringField;
    dsArtikelVerkaufspreisBrutto: TFloatField;
    dsArtikelArtikelSet: TBooleanField;
    dsArtikelProvisionssatz: TFloatField;
    dsArtikelWarentarifnummer: TStringField;
    dsArtikelHandelsartikel: TBooleanField;
    dsArtikelUrsprungsland: TStringField;
    dsArtikelKategorie: TStringField;
    dsArtikelStandardRabattsatzEK: TFloatField;
    dsArtikelStandardRabattsatzVK: TFloatField;
    dsArtikelRabattpreiseManuell: TBooleanField;
    dsArtikelMengeBestellt: TFloatField;
    dsArtikelMengeBeauftragt: TFloatField;
    dsArtikelBestandDisponiert: TFloatField;
    dsArtikelMengeAngeboten: TFloatField;
    dsArtikelBestandDispInclAngebot: TFloatField;
    dsArtikelMWStKey: TIntegerField;
    dsArtikelShopAmazon: TBooleanField;
    dsArtikelShopQuelle: TBooleanField;
    dsArtikelGeraet: TBooleanField;
    dsArtikelArtgewicht: TFloatField;
    dsArtikelVolumen: TFloatField;
    dsArtikelPacklisteNichtAnzeigen: TBooleanField;
    dsArtikelKeineAufschlagskalkulation: TBooleanField;
    dsArtikelKorrekturMenge: TFloatField;
    dsArtikelKorrekturWert: TFloatField;
    dsArtikelNachfolgeartikel: TStringField;
    dsArtikelMindestbestellmenge: TFloatField;
    dsArtikelSchnittEK: TFloatField;
    dsArtikelVerkaufspreisVom: TDateTimeField;
    dsArtikelPruefungChargeSNR: TIntegerField;
    dsArtikelArtikelbeschreibungHTML: TStringField;
    dsArtikelProduktKurzbeschreibungHTML: TStringField;
    dsArtikelStatusFuerShop: TStringField;
    dsArtikelAmpelFuerShop: TStringField;
    dsArtikelEXP_Anzahl: TFloatField;
    dsArtikelEXP_Inhalt: TFloatField;
    dsArtikelEXP_Faktor: TFloatField;
    dsArtikelFach: TStringField;
    dsArtikelExportKasse: TIntegerField;
    dsArtikelWKNR: TIntegerField;
    dsArtikelArtikelAttribute: TStringField;
    dsArtikelErstelltAm: TDateTimeField;
    dsArtikelVarianteVonAN: TStringField;
    dsArtikelGurtmassWerte: TStringField;
    dsArtikelGurtmass: TFloatField;
    dsArtikelAltersgrenze: TIntegerField;
    dsArtikelMWStKeyImHaus: TIntegerField;
    dsArtikelKasseBewegung: TFloatField;
    dsArtikelKasseBewegungEK: TFloatField;
    dsArtikelKasseBewegungVK: TFloatField;
    dsArtikelProduktionBewegung: TFloatField;
    dsArtikelProduktionBewegungEK: TFloatField;
    dsArtikelProduktionBewegungVK: TFloatField;
    dsArtikelWaWiAB: TIntegerField;
    dsArtikelGeaendertAm: TDateTimeField;
    dsArtikelGefahrgutKennzeichen: TStringField;
    dsArtikelVerpackG: TBooleanField;
    dsArtikelUPE: TFloatField;
    dsArtikelArtikelbeschreibungEinkauf: TStringField;
    dsArtikelHerstellerArtikelnummer: TStringField;
    dsArtikelNatura: TStringField;
    dsArtikelArbeitszeit: TBooleanField;
    dsArtikelArtderMengeArtderMenge: TStringField;
    xdsKundenpreise: TXDataWebDataSet;
    xdsKundenpreiseArtikelnummer: TXDataWebEntityField;
    xdsKundenpreiseKundennummer: TXDataWebEntityField;
    xdsKundenpreiseVerkaufspreis: TFloatField;
    xdsKundenpreiseNachname: TStringField;
    xdsKundenpreiseRabattsatz: TFloatField;
    xdsKundenpreiseVerleihpreis: TFloatField;
    xdsKundenpreiseZusatzPreis: TFloatField;
    xdsKundenpreiseArtikelnummerKunde: TStringField;
    xdsKundenpreiseDatumGeaendert: TDateTimeField;
    xdsKundenpreiseArtikelueberschrift: TStringField;
    xdsKundenpreiseArtikelnummerArtikelnummer: TStringField;
    xdsKundenpreiseArtikelnummerArtikelueberschrift: TStringField;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung: TStringField;
    dsBeleg2: TXDataWebDataSet;
    dsBeleg2Kundennummer: TXDataWebEntityField;
    dsBeleg2Nummerkomplett: TStringField;
    dsBeleg2ReferenzAngebot: TStringField;
    dsBeleg2ReferenzLieferschein: TStringField;
    dsBeleg2ReferenzRechnung: TStringField;
    dsBeleg2Datum: TDateTimeField;
    dsBeleg2Jahr: TFloatField;
    dsBeleg2Kalenderwoche: TIntegerField;
    dsBeleg2Nachname: TStringField;
    dsBeleg2Vorname: TStringField;
    dsBeleg2Postleitzahl: TStringField;
    dsBeleg2Ort: TStringField;
    dsBeleg2Laenderkuerzel: TStringField;
    dsBeleg2Land: TStringField;
    dsBeleg2Briefanrede1: TStringField;
    dsBeleg2Briefanrede2: TStringField;
    dsBeleg2EMail: TStringField;
    dsBeleg2AdresseBild: TStringField;
    dsBeleg2LieferanschriftBild: TStringField;
    dsBeleg2Zahlungsbedingung: TIntegerField;
    dsBeleg2ZahlungsbedingungText: TStringField;
    dsBeleg2Auftragsbezeichnung: TStringField;
    dsBeleg2AuftragnummerKunde: TStringField;
    dsBeleg2BestelldatumKunde: TDateTimeField;
    dsBeleg2ILN: TStringField;
    dsBeleg2Kopftext: TStringField;
    dsBeleg2Schlussbemerkung: TStringField;
    dsBeleg2Gruss: TStringField;
    dsBeleg2Mehrwertsteuersatz1: TFloatField;
    dsBeleg2Mehrwertsteuersatz2: TFloatField;
    dsBeleg2Nettosumme01: TFloatField;
    dsBeleg2Nettosumme02: TFloatField;
    dsBeleg2Nettosumme: TFloatField;
    dsBeleg2Mehrwertsteuer01: TFloatField;
    dsBeleg2Mehrwertsteuer02: TFloatField;
    dsBeleg2Mehrwertsteuer: TFloatField;
    dsBeleg2Bruttosumme: TFloatField;
    dsBeleg2Versanddatum: TDateTimeField;
    dsBeleg2Skontosatz: TFloatField;
    dsBeleg2NettosummeEK: TFloatField;
    dsBeleg2NettoMarge: TFloatField;
    dsBeleg2Schreibzeichen: TStringField;
    dsBeleg2Unterzeichner: TStringField;
    dsBeleg2TelefonMA: TStringField;
    dsBeleg2EmailMA: TStringField;
    dsBeleg2USTID: TStringField;
    dsBeleg2Umsatzsteuerausweis: TBooleanField;
    dsBeleg2gebucht: TBooleanField;
    dsBeleg2Lieferdatum: TDateTimeField;
    dsBeleg2Transportbedingung: TStringField;
    dsBeleg2Mehrwertsteuersatz3: TFloatField;
    dsBeleg2Mehrwertsteuer03: TFloatField;
    dsBeleg2Nettosumme03: TFloatField;
    dsBeleg2RundungsDifferenz: TFloatField;
    dsBeleg2EmailText: TStringField;
    dsBeleg2Auftragerledigt: TBooleanField;
    dsBeleg2Rabattstufe: TStringField;
    dsBeleg2KFZKennzeichen: TStringField;
    dsBeleg2Fahrgestellnummer: TStringField;
    dsBeleg2KFZHersteller: TStringField;
    dsBeleg2Erstzulassungsdatum: TDateTimeField;
    dsBeleg2KMStand: TStringField;
    dsBeleg2KFZType: TStringField;
    dsBeleg2Inspektionsdatum: TDateTimeField;
    dsBeleg2Debitorennummer: TStringField;
    dsBeleg2Kostentraeger: TStringField;
    dsBeleg2Rabattsatz: TFloatField;
    dsBeleg2ObjektReferenz: TStringField;
    dsBeleg2Bank: TStringField;
    dsBeleg2Bankleitzahl: TFloatField;
    dsBeleg2Kontonummer: TStringField;
    dsBeleg2Kontoinhaber: TStringField;
    dsBeleg2Telefaxnummer: TStringField;
    dsBeleg2DatumNeu: TDateTimeField;
    dsBeleg2Datumgeaendert: TDateTimeField;
    dsBeleg2Objektnummer: TStringField;
    dsBeleg2Liefertermin: TStringField;
    dsBeleg2Emailversand: TBooleanField;
    dsBeleg2Telefonnummer: TStringField;
    dsBeleg2MobilTelefon: TStringField;
    dsBeleg2AuftragFax: TBooleanField;
    dsBeleg2REDatum: TDateTimeField;
    dsBeleg2BenutzerNeu: TStringField;
    dsBeleg2BenutzerGeaendert: TStringField;
    dsBeleg2BerechnungBrutto: TBooleanField;
    dsBeleg2Bruttosumme01: TFloatField;
    dsBeleg2Bruttosumme02: TFloatField;
    dsBeleg2Bruttosumme03: TFloatField;
    dsBeleg2Notiz: TStringField;
    dsBeleg2MargeProzent: TFloatField;
    dsBeleg2Warenkreditgesperrt: TBooleanField;
    dsBeleg2Belegsprache: TStringField;
    dsBeleg2Duplicate: TStringField;
    dsBeleg2perEmail: TBooleanField;
    dsBeleg2Ansprechpartner: TStringField;
    dsBeleg2KundennummerFremd: TStringField;
    dsBeleg2Protokoll: TStringField;
    dsBeleg2Verleihauftrag: TBooleanField;
    dsBeleg2VerleihVon: TDateTimeField;
    dsBeleg2VerleihBis: TDateTimeField;
    dsBeleg2AuftragGenehmigt: TBooleanField;
    dsBeleg2gesperrt: TBooleanField;
    dsBeleg2VerleihvonText: TStringField;
    dsBeleg2VerleihbisText: TStringField;
    dsBeleg2DruckWHG: TStringField;
    dsBeleg2DruckWHGKurs: TFloatField;
    dsBeleg2ProzentsatzZusatzposition: TFloatField;
    dsBeleg2TextZusatzposition: TStringField;
    dsBeleg2TextzusatzBeschreibung: TStringField;
    dsBeleg2Layoutname: TStringField;
    dsBeleg2Gesamtgewicht: TFloatField;
    dsBeleg2BetragZusatzposition: TFloatField;
    dsBeleg2ReferenzBestellung: TStringField;
    dsBeleg2Termin1: TDateTimeField;
    dsBeleg2Termin2: TDateTimeField;
    dsBeleg2Zielkontaktnummer: TStringField;
    dsBeleg2Zielkontaktname: TStringField;
    dsBeleg2Bilddatei1: TStringField;
    dsBeleg2Bilddatei2: TStringField;
    dsBeleg2Zielkontaktnummer2: TStringField;
    dsBeleg2Zielkontaktname2: TStringField;
    dsBeleg2bestaetigt: TDateTimeField;
    dsBeleg2Zielkontaktnummer3: TStringField;
    dsBeleg2Zielkontaktname3: TStringField;
    dsBeleg2Termin3: TDateTimeField;
    dsBeleg2Termin4: TDateTimeField;
    dsBeleg2Muster: TBooleanField;
    dsBeleg2KW: TIntegerField;
    dsBeleg2Datenlink: TStringField;
    dsBeleg2Mehrwertsteuersatz4: TFloatField;
    dsBeleg2Mehrwertsteuersatz5: TFloatField;
    dsBeleg2Mehrwertsteuersatz6: TFloatField;
    dsBeleg2BesteuerungKey: TIntegerField;
    dsBeleg2VertreterMatchcode: TStringField;
    dsBeleg2Verfasser: TStringField;
    dsBeleg2LieferadresseID: TIntegerField;
    dsBeleg2AuftragStatus: TStringField;
    dsBeleg2MonatJahr: TDateTimeField;
    dsBeleg2Belegvariante: TStringField;
    dsBeleg2Produktionsfreigabe: TBooleanField;
    dsBeleg2ReferenzRahmenAuftrag: TStringField;
    dsBeleg2DatumRahmenAuftrag: TDateTimeField;
    dsBeleg2FahrzeugID: TIntegerField;
    dsBeleg2AuslieferungKW: TStringField;
    dsBeleg2BereitstellungDatum: TDateTimeField;
    dsBeleg2VTUID: TStringField;
    dsBeleg2LieferanschriftDaten: TStringField;
    dsBeleg2GesamtVolumen: TFloatField;
    dsBeleg2BelegPKey: TIntegerField;
    dsBeleg2DruckParameter: TStringField;
    dsBeleg2ReferenzService: TStringField;
    dsBeleg2ZusatzDaten: TStringField;
    dsBeleg2StandPDF: TStringField;
    dsBeleg2Paketdienst: TStringField;
    dsBeleg2Monteur: TIntegerField;
    dsBeleg2KundennummerKundennummer: TStringField;
    dsBeleg2ServiceBemerkung: TStringField;
    edtPosNr: TDBEdit;
    edtPosUNr: TDBEdit;
    edtArtikelueberschrift: TDBEdit;
    edtMenge: TDBEdit;
    btnArtikelnummer: TButton;
    edtArbeitszeitDatum: TDBDateTimePicker;
    edtArbeitszeitVON: TDBDateTimePicker;
    edtArbeitszeitBIS: TDBDateTimePicker;
    edtArtikelbeschreibung: TDBMemo;
    PosComboMonteur: TComboBox;
    edtMengenart: TDBLookupComboBox;
    btnNeuePosition: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure btnPosAbbrechenClick(Sender: TObject);
    procedure xdsPositionAfterOpen(DataSet: TDataSet);
    procedure xdsPositionAfterDelete(DataSet: TDataSet);
    procedure xdsPositionAfterPost(DataSet: TDataSet);
    procedure xdsPositionBeforePost(DataSet: TDataSet);
    procedure xdsPositionNewRecord(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
    procedure xdsPositionAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure btnPositionLoeschenClick(Sender: TObject);
    procedure btnPosSpeichernClick(Sender: TObject);
    procedure xdsKundenpreiseAfterOpen(DataSet: TDataSet);
    procedure dsArtikelAfterOpen(DataSet: TDataSet);
    procedure dsBeleg2AfterOpen(DataSet: TDataSet);
    procedure sourcePositionStateChange(Sender: TObject);
    procedure edtArbeitszeitDatumEnter(Sender: TObject);
    procedure PosComboMonteurEnter(Sender: TObject);
    procedure btnArtikelnummerClick(Sender: TObject);
    procedure btnNeuePositionClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    PositionsID:string;
    tmpArtikelnummer:string;
    tmpKundennummer:string;
    tmpMerkerAuftrag:string;
    tmpLetztePosNr:double;
    NeuePosition:boolean;
    Arbeitszeitstringlist:TStringList;
    Monteurstringlist:TStringList;
    AltePosition:boolean;
  end;

var
  AuftragPosEinzel: TAuftragPosEinzel;

implementation
  uses unit1, unit2, unit_EinzelAuftrag, unit_Artikelsuche;


procedure TAuftragPosEinzel.edtArbeitszeitDatumEnter(Sender: TObject);
begin
  xdsPosition.Edit;
  if xdsPositionArbeitszeitDatum.AsDateTime<date-30000 then xdsPositionArbeitszeitDatum.AsDateTime:=date;
end;



procedure TAuftragPosEinzel.PosComboMonteurEnter(Sender: TObject);
begin
  xdsposition.Edit;
end;



procedure TAuftragPosEinzel.sourcePositionStateChange(Sender: TObject);
begin
  if xdsPosition.State in dseditmodes then btnPosspeichern.Enabled:=true else btnPosspeichern.Enabled:=false;
end;

procedure TAuftragPosEinzel.btnArtikelnummerClick(Sender: TObject);
var
  LFrm:TfrmArtikelsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmArtikelsuche;
    begin
      LForm:=(AForm as TfrmArtikelsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Artikelnummer<>'' then
      begin
        tmpArtikelnummer:=lfrm.Artikelnummer;
        if tmpArtikelnummer<>'' then
        begin
          dsArtikel.Close;
          dsArtikel.QueryString:='$filter=Artikelnummer eq '+QuotedStr(tmpArtikelnummer)+'&$expand=ArtderMenge,Hersteller';
          dsArtikel.Load;
        end
        else
        begin
          if NeuePosition then btnPosAbbrechenClick(nil);

        end;
      end;
    end;

  begin
  AltePosition:=true;
  LFrm:=TfrmArtikelsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TAuftragPosEinzel.btnNeuePositionClick(Sender: TObject);
var
  LFrm:TfrmArtikelsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmArtikelsuche;
    begin
      LForm:=(AForm as TfrmArtikelsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Artikelnummer<>'' then
      begin
        tmpArtikelnummer:=lfrm.Artikelnummer;
        if tmpArtikelnummer<>'' then
        begin
          dsArtikel.Close;
          dsArtikel.QueryString:='$filter=Artikelnummer eq '+QuotedStr(tmpArtikelnummer)+'&$expand=ArtderMenge,Hersteller';
          dsArtikel.Load;
        end
        else
        begin
          if NeuePosition then btnPosAbbrechenClick(nil);

        end;
      end;
    end;

  begin
  AltePosition:=false;
  LFrm:=TfrmArtikelsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TAuftragPosEinzel.btnPosAbbrechenClick(Sender: TObject);
begin
  dsartikel.Close;
  xdsposition.close;
  self.Close;
end;

procedure TAuftragPosEinzel.btnPositionLoeschenClick(Sender: TObject);
begin
  xdsposition.Delete;
end;

procedure TAuftragPosEinzel.btnPosSpeichernClick(Sender: TObject);
begin
  if xdsPosition.State=dsbrowse then xdsposition.Edit;

   if xdsPosition.State in dseditmodes then
  begin
    xdsPosition.Post;
  end
  else
  begin

    xdsPosition.Close;

  end;
end;

procedure TAuftragPosEinzel.dsArtikelAfterOpen(DataSet: TDataSet);
begin
  xdsKundenpreise.Close;
  xdskundenpreise.QueryString:='$expand=Artikelnummer&$filter=Kundennummer eq '+QuotedStr(tmpKundennummer)+ ' AND Artikelnummer eq '+QuotedStr(dsArtikelArtikelnummer.asstring);
  xdsKundenpreise.Load;
end;

procedure TAuftragPosEinzel.dsBeleg2AfterOpen(DataSet: TDataSet);
begin

  if NeuePosition then btnNeuePositionClick(nil);

  if tmpArtikelnummer<>'' then
  begin
    dsArtikel.Close;
    dsArtikel.QueryString:='$filter=Artikelnummer eq '+QuotedStr(tmpArtikelnummer)+'&$expand=ArtderMenge,Hersteller';
    dsArtikel.Load;
  end;
end;

procedure TAuftragPosEinzel.WebFormCloseQuery(Sender: TObject;
  var CanClose: Boolean);
begin
Arbeitszeitstringlist.Free;
end;

procedure TAuftragPosEinzel.WebFormCreate(Sender: TObject);
begin
  Arbeitszeitstringlist:=TStringList.Create;
  Arbeitszeitstringlist.Delimiter:='|';
  Arbeitszeitstringlist.StrictDelimiter:=true;
  Monteurstringlist:=TStringList.Create;
  Monteurstringlist.Delimiter:='|';
  Monteurstringlist.StrictDelimiter:=true;
end;

procedure TAuftragPosEinzel.WebFormShow(Sender: TObject);
 var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Position-Auftrag</h5></nav>';
  myelement:=container2.ElementHandle;
  btnPosAbbrechen.ParentElement:=myelement;
  btnPosSpeichern.ParentElement:=myelement;
  btnPositionLoeschen.ParentElement:=myelement;
  WTCPosition.ColCount:=2;
  WTCPosition.RowCount:=13;
  WTCPosition.Cells[0,0]:='<small><i>Beleg';

  WTCPosition.Cells[0,1]:='<small><i>Pos-Nr';
  myelement:=WTCPosition.CellElements[1,1];
  edtPosNr.ParentElement:=myelement;

  WTCPosition.Cells[0,2]:='<small><i>Pos.UNr';
  myelement:=WTCPosition.CellElements[1,2];
  edtPosUNr.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[0,3];
  btnArtikelnummer.ParentElement:=myelement;

  WTCPosition.Cells[0,4]:='<small><i>Artikelüberschrift';
  myelement:=WTCPosition.CellElements[1,4];
  edtArtikelueberschrift.ParentElement:=myelement;

  WTCPosition.Cells[0,5]:='<small><i>Artikelbeschreibung';

  WTCPosition.Cells[0,6]:='<small><i>Menge';
  myelement:=WTCPosition.CellElements[1,6];
  edtMenge.ParentElement:=myelement;

  WTCPosition.Cells[0,7]:='<small><i>Mengenart';
  WTCPosition.Cells[0,8]:='<small><i>Arbeitszeit Datum';
  WTCPosition.Cells[0,9]:='<small><i>AZ Beginn';
  WTCPosition.Cells[0,10]:='<small><i>AZ Ende';
  WTCPosition.Cells[0,11]:='<small><i>Monteur';

  dsBeleg2.Close;
  dsBeleg2.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(tmpMerkerAuftrag);
  dsBeleg2.Load;
end;

procedure TAuftragPosEinzel.xdsKundenpreiseAfterOpen(DataSet: TDataSet);
begin
    xdsPosition.Close;
    if PositionsID<>'' then xdsPosition.QueryString:='$filter=ID eq '+QuotedStr(PositionsID);
    if PositionsID='' then xdsPosition.QueryString:='';
    xdsPosition.Load;
end;

procedure TAuftragPosEinzel.xdsPositionAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  xdsposition.Close;
  self.Close;
end;

procedure TAuftragPosEinzel.xdsPositionAfterDelete(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    xdsposition.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TAuftragPosEinzel.xdsPositionAfterOpen(DataSet: TDataSet);
 var
  myelement:TJSHTMLElement;
begin

  myelement:=WTCPosition.CellElements[1,5];
  edtArtikelbeschreibung.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[1,7];
  edtMengenart.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[1,8];
  edtArbeitszeitDatum.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[1,9];
  edtArbeitszeitVON.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[1,10];
  edtArbeitszeitBIS.ParentElement:=myelement;

  myelement:=WTCPosition.CellElements[1,11];
  PosComboMonteur.ParentElement:=myelement;
  PosComboMonteur.Items.clear;
  dm2.queryMonteur.First;
  while not dm2.queryMonteur.eof do
  begin
    PosComboMonteur.Items.Add(dm2.queryMonteurPersonalnummer.asstring+'| '+dm2.queryMonteurNachname.asstring+', '+dm2.queryMonteurVorname.asstring);
    dm2.queryMonteur.Next;
  end;
  PosComboMonteur.Items.Add('');

  WTCPosition.Cells[1,0]:=dsBeleg2Nummerkomplett.asstring+' | '+xdsPositionID.asstring;

  WTCPosition.Cells[1,3]:=tmpArtikelnummer;
  WTCPosition.Cells[1,7]:=xdsPositionArtderMenge.AsString;
  if xdsPositionMonteurP.AsString<>'' then
  begin
    dm2.queryMonteur.Locate('Personalnummer',xdsPositionMonteurP.AsString,[]);
    Poscombomonteur.Text:=dm2.queryMonteurPersonalnummer.asstring+'| '+dm2.queryMonteurNachname.asstring+', '+dm2.queryMonteurVorname.asstring;
  end
  else
  begin
    PosComboMonteur.Text:='';
  end;
  if neueposition=true then xdsposition.append;
  if AltePosition=true then
  begin
    xdsPosition.Edit;
    xdsPositionArtikelnummer.value:=dsArtikel.CurrentData;
    xdsPositionMenge.AsInteger:=1;
    xdsPositionArtderMenge.asstring:=dsArtikelArtderMengeArtderMenge.AsString;
    xdsPositionVerkaufspreis.AsFloat:=dsArtikelVerkaufspreis.asfloat;
    if dsBeleg2rabattsatz.asfloat=0 then xdsPositionRabattsatz.asfloat:=dsArtikelstandardRabattsatzVK.asfloat;
    if dsBeleg2rabattstufe.asstring='Rabattpreis1' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis1.asstring;
    if dsBeleg2rabattstufe.asstring='Rabattpreis2' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis2.asstring;
    if dsBeleg2rabattstufe.asstring='Rabattpreis3' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis3.asstring;
    if dsBeleg2rabattstufe.asstring='Rabattpreis4' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis4.asstring;
    if dsBeleg2rabattstufe.asstring='Rabattpreis5' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis5.asstring;
    xdsPositionPreisvorRabatt.asfloat:=xdsPositionVerkaufspreis.AsFloat;
    xdsPositionPreisanteilNetto.asfloat:=dsArtikelpreisanteilnetto.asfloat;
    xdsPositionEinkaufspreis.asfloat:=dsArtikelEinkaufspreis.asfloat;
    xdsPositionRabattsatz.AsFloat:=dsBeleg2Rabattsatz.asfloat;
    if xdsKundenpreise.RecordCount>0 then
    begin
      xdsPositionPreisvorrabatt.asfloat:=xdsKundenpreiseVerkaufspreis.asfloat;
      if xdsKundenpreiseRabattsatz.AsFloat<>0 then xdsPositionRabattsatz.AsFloat:=xdsKundenpreiseRabattsatz.AsFloat;
    end;
    xdsPositionPKZ.asfloat:=0;
    if dsBeleg2verleihauftrag.AsBoolean=true then xdsPositionpkz.asfloat:=2;
    xdsPositionposmwst.asfloat:=0;
    xdsPositionMWStKey.asinteger:=1;
    xdsPositionEndpreis.asfloat:=0;
    xdsPositionEndpreis_EK.asfloat:=0;
    xdsPositionBruttoEndpreis.asfloat:=0;
    xdsPositionEinzelpreisBrutto.asfloat:=0;
    xdsPositionbereitsGeliefert.asfloat:=0;
    xdsPositionBestellungErledigt.asboolean:=false;
    xdsPositionausliefern.asfloat:=0;
    xdsPositionbestellen.asboolean:=false;
    xdsPositionlager.asstring:='[-]';
    xdsPositionwarenbestand.asboolean:=dsArtikelWarenbestand.asboolean;
    xdsPositionPosMargeProzent.asfloat:=0;
    xdsPositionPosMarge.asfloat:=0;
    xdsPositionPreisanteilnetto.asfloat:=0;
    xdsPositionprovisionssatz.asfloat:=0;
    xdsPositionprovisionsbetrag.asfloat:=0;
    xdsPositionaussenflaeche.asfloat:=0;
    xdsPositionBruttogewicht.asfloat:=0;
    xdsPositionTara.asfloat:=0;
    xdsPositionPlanerFarbe.asinteger:=0;
    xdsPositionSeitenumbruch.asboolean:=FALSE;
    xdsPositionZSnachPos.asboolean:=False;
    xdsPositionPreisanteilnetto.asfloat:=0;
    xdsPositionPreisVorRabattWHG.asfloat:=0;
    xdsPositionpkey.asinteger:=0;
    xdsPositionVolumen.AsFloat:=0;
    xdsPositionPosVolumen.AsFloat:=0;
    xdsPositionArtikelueberschrift.asstring:=dsArtikelArtikelueberschrift.asstring;
    xdsPositionArtikelbeschreibung.asstring:=dsArtikelArtikelbeschreibung.asstring;
    xdsPositionArtikelbeschreibungRTF.value:=dsArtikelArtikelbeschreibungRTF.value;
    xdsPositionArtikelsprache.value:='';
    xdsPositiongewicht.asstring:=dsArtikelGewicht.AsString;
    xdsPositionLaenge.asstring:=dsArtikelLaenge.AsString;
    xdsPositionBreite.asstring:=dsArtikelBreite.AsString;
    xdsPositionHoehe.asstring:=dsArtikelHoehe.AsString;
    xdsPositionMWStKey.asInteger:=dsArtikelMWStKey.asInteger;
    xdsPositionWarenbestand.asboolean:=dsArtikelWarenbestand.asboolean;
    xdsPositionLieferant.asstring:=dsArtikellieferantnummer.asstring;
    xdsPositionEinkaufspreis.AsString:=dsArtikeleinkaufspreis.asstring;
    xdsPositionEKvom.asstring:=dsArtikeleinkaufspreisvom.asstring;
    xdsPositionLager.asstring:=dsArtikelLagerort.asstring;
    xdsPositionVolumen.AsFloat:=dsArtikelVolumen.AsFloat;
    xdsPositionArtikelAttribute.AsString:=dsArtikelArtikelAttribute.AsString;
    xdsPositionTextfrei10.asstring:=dsArtikeltextfrei10.asstring;
    xdsPositionTextfrei20.asstring:=dsArtikeltextfrei20.asstring;

    // Preis nach PKZ holen
    if xdsPositionpkz.asfloat=2 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelverleihpreis.asfloat;
    if xdsPositionpkz.asfloat=3 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelZusatzpreis.asfloat;
    if xdsPositionpkz.asfloat=4 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelverleihpreis.asfloat + dsArtikelZusatzpreis.asfloat;

  end;

  if dsartikel.active then
  begin
    if dsartikelarbeitszeit.asboolean=true then
    begin
      edtArbeitszeitDatum.visible:=true;
      edtArbeitszeitVON.visible:=true;
      edtArbeitszeitBIS.visible:=true;
    end
    else
    begin
      edtArbeitszeitDatum.visible:=false;
      edtArbeitszeitVON.visible:=false;
      edtArbeitszeitBIS.visible:=false;
    end;
  end;

end;

procedure TAuftragPosEinzel.xdsPositionAfterPost(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    xdsPosition.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TAuftragPosEinzel.xdsPositionBeforePost(DataSet: TDataSet);
begin
  if dsArtikelArbeitszeit.AsBoolean=true then
  begin
    arbeitszeitstringlist.DelimitedText:=xdsPositionArtikelueberschrift.AsString;
    arbeitszeitstringlist[0]:=leftstr(arbeitszeitstringlist[0],21);
    xdspositionArtikelueberschrift.AsString:=arbeitszeitstringlist[0]+' | '+Formatdatetime('DD.MM.YYYY',xdsPositionArbeitszeitDatum.AsDateTime)+' | ' +formatdatetime('hh:mm',xdsPositionArbeitszeitVON.AsDateTime)+' - '+formatdatetime('hh:mm',xdspositionArbeitszeitBIS.AsDateTime);
    xdsPositionArbeitszeitVON.AsDateTime:=trunc(xdsPositionArbeitszeitDatum.AsDateTime)+frac(xdsPositionArbeitszeitVon.AsDateTime);
    xdsPositionArbeitszeitBIS.AsDateTime:=trunc(xdsPositionArbeitszeitDatum.AsDateTime)+frac(xdsPositionArbeitszeitBIS.AsDateTime);
    xdsPositionMenge.AsFloat:=dm2.roundkfm(MinutesBetween(xdspositionArbeitszeitVON.AsDateTime,xdspositionArbeitszeitBIS.asdatetime)/60,-2);
  end;


  if PosComboMonteur.Text='' then
  begin
    xdsPositionMonteurP.AsString:='';
  end
  else
  begin
    Monteurstringlist.DelimitedText:=PosComboMonteur.Text;
    xdsPositionMonteurP.AsString:=Monteurstringlist[0];
  end;

  xdsPositionBelegdatum.AsDateTime:=dsBeleg2Datum.AsDateTime;
  if xdsPositionMWStKey.asinteger=1 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz1.asfloat;
  if xdsPositionMWStKey.asinteger=2 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz2.asfloat;
  if xdsPositionMWStKey.asinteger=3 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz3.asfloat;
  if xdsPositionMWStKey.asinteger=4 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz4.asfloat;
  if xdsPositionMWStKey.asinteger=5 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz5.asfloat;
  if xdsPositionMWStKey.asinteger=6 then xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2Mehrwertsteuersatz6.asfloat;
  if dsBeleg2Umsatzsteuerausweis.asboolean=false then xdsPositionMehrwertsteuersatz.AsFloat:=0;

  // Artikelnummer für den Warenbestand setzen
  if xdsPositionquellartikel.asstring='' then
  begin
    xdsPositionartikelnummerwb.asstring:=xdsPositionartikelnummer.asstring;
  end
  else
  begin
    xdsPositionartikelnummerwb.asstring:=xdsPositionquellartikel.asstring;
  end;

  if xdsPositionpreisvorrabatt.asstring='' then xdsPositionpreisvorrabatt.asfloat:=0;
  if xdsPositionpkz.asfloat=3 then xdsPositionrabattsatz.asfloat:=0;
  if xdsPositioneinkaufspreis.asstring='' then xdsPositioneinkaufspreis.asstring:='0';

  // Prüfung auf Textpositionen
  if leftstr(xdsPositionArtikelnummer.asstring,4)='Text' then xdsPositionpreisvorrabatt.asfloat:=0;

  {
  // Pruefung wegen Staffelpreis
  if dm2.tablesysdatStaffelpreise.asboolean then
  begin
    tempstp:=GF.checkstaffelpreis(EinzelAuftrag.dsBelegKundennummer.asstring,xdsPositionArtikelnummer.asstring,EinzelAuftrag.dsBelegDatum.asdatetime,xdsPositionMenge.asfloat,xdsPositionPreisVorRabatt.asfloat);
    if tempstp<>0 then
    begin
      messagetext:=MSGStaffelpreisuebernehmen+' '+formatfloat(fstr,tempstp);
      if MessageDlg(MSGStaffelpreisuebernehmen, mtConfirmation, [mbYes, mbNo], 0) = mrYes then
      begin
        xdsPositionpreisvorrabatt.asfloat:=tempstp;
        xdsPositionEinkaufspreis.asfloat:=tempeinkaufspreis;
      end;
    end;
  end;
  }
  // Pruefung wegen Rabattfaehig
  if xdsPositionrabattsatz.AsFloat<>0 then
  begin
    if (dm2.checkrabattierung(xdsPositionArtikelnummer.asstring)=false) then xdsPositionrabattsatz.asfloat:=0;
  end;

  // check der SET-Positionen
  if (xdsPositionSetHPos.AsString<>'') AND (xdsPositionSetHPos.AsString<>'00000000-0000-0000-0000-000000000000')  then xdsPositioneinkaufspreis.asfloat:=0;
  if (xdsPositionSetUPos.AsString<>'') AND (xdsPositionSetUPos.AsString<>'00000000-0000-0000-0000-000000000000') then
  begin
    xdsPositionPreisVorRabatt.asfloat:=0;
    xdsPositioneinzelpreisbrutto.asfloat:=0;
  end;

  {
  // wenn währungserfassung
  if (EinzelAuftrag.dsBelegDruckWHG.AsString<>'') AND (dm2.tablesysdatMultipleWHG.AsBoolean=TRUE) then
  begin
    if EinzelAuftrag.dsBelegDruckWHG.AsString<>dm2.tablesysdatBWHG.asstring then
    begin
      xdsPositionPreisvorRabatt.AsFloat:=xdsPositionPreisVorRabattWHG.AsFloat/EinzelAuftrag.dsBelegDruckWHGKurs.AsFloat;
    end;
  end;
  }


    xdsPositionVerkaufspreis.asfloat:=xdsPositionPreisvorRabatt.asfloat-((xdsPositionPreisvorRabatt.asfloat-xdsPositionpreisanteilnetto.asfloat)*xdsPositionRabattsatz.AsFloat/100);
    xdsPositioneinzelpreisbrutto.asfloat:=xdsPositionverkaufspreis.asfloat+(xdsPositionVerkaufspreis.asfloat*xdsPositionmehrwertsteuersatz.asfloat/100);


  if dm2.tablesysdatrundungIT.asboolean=true then xdsPositionVerkaufspreis.asfloat:=dm2.roundkfm(xdsPositionVerkaufspreis.asfloat,-2);

  tempvgpdivisor:=DM2.holevkpdivisor(xdsPositionArtderMenge.asstring);
  xdsPositionEndpreis.asfloat:=xdsPositionMenge.AsFloat*xdsPositionVerkaufspreis.AsFloat/tempvgpdivisor;
  xdsPositionEndpreis_EK.asfloat:=dm2.roundkfm(xdsPositionMenge.AsFloat*xdsPositionEinkaufspreis.AsFloat/tempvgpdivisor,frund);

  xdsPositionPosMWST.asfloat:=xdsPositionEndpreis.asfloat*xdsPositionMehrwertsteuersatz.asfloat/100;
  xdsPositionBruttoEndpreis.asfloat:=xdsPositionposMWST.asfloat+xdsPositionEndpreis.asfloat;
  xdsPositionProvisionsbetrag.asfloat:=dm2.roundkfm((xdsPositionEndpreis.asfloat*xdsPositionProvisionssatz.asfloat/100),-2);

  xdsPositionPosMarge.asfloat:=xdsPositionEndpreis.asfloat-xdsPositionEndpreis_EK.AsFloat;
  if xdsPositionEndpreis_EK.AsFloat<>0 then
  begin
    xdsPositionPosMargeProzent.asfloat:=xdsPositionPosMarge.asfloat/xdsPositionEndpreis_EK.asfloat*100;
  end
  else
  begin
    xdsPositionPosMargeProzent.AsFloat:=0;
  end;

  {
  // Pruefung auf die Artikelverfügbarkeit
  if xdsPositionBereitsGeliefert.AsFloat<>xdsPositionMenge.asfloat then
  begin
    if xdsPositionWarenbestand.asboolean=false then xdsPositionbestellen.AsBoolean:=false;
    if xdsPositionWarenbestand.asboolean=true then
    begin
      dm2.queryeinzelartikelbestand.close;
      dm2.queryEinzelartikelbestand.SQL.Clear;
      dm2.queryeinzelartikelbestand.SQL.append('select Bestand+Mindestbestand AS Bestand from Artikel where Artikelnummer = '''+xdsPositionartikelnummerwb.asstring+'''');
      dm2.queryEinzelartikelbestand.open;
      if dm2.queryEinzelartikelbestandBestand.asfloat>=xdsPositionMenge.asfloat then
      begin
        xdsPositionbestellen.asboolean:=false;
      end
      else
      begin
        xdsPositionbestellen.asboolean:=true;
      end;
      dm2.queryEinzelartikelbestand.close;
    end;
  end;
  }

  //RTF-Austausch Beginn
  //xdsPositionArtikelbeschreibung.value:=Auftrag.richartikel.Text;
  xdsPositionArtikelbeschreibungRTF.asstring:=xdsPositionArtikelbeschreibung.AsString;
  //RTF-Austausch fertig
  if xdsPositionArtikelbeschreibung.asstring='' then xdsPositionArtikelbeschreibung.asstring:=' ';
  xdsPositionPosVolumen.AsFloat:=xdsPositionVolumen.AsFloat*xdsPositionMenge.AsFloat;

end;

procedure TAuftragPosEinzel.xdsPositionNewRecord(DataSet: TDataSet);
var
  guid:TGUID;
  guidstring:string;
begin
  createguid(guid);
  guidstring:=guidtostring(guid);
  guidstring:=ReplaceStr(guidstring,'{','');
  guidstring:=ReplaceStr(guidstring,'}','');
  xdsPositionlwbID.Asstring:=guidstring;
  xdsPositionNummerkomplett.value:=dsBeleg2.CurrentData;
  xdsPositionArtikelnummer.value:=dsArtikel.CurrentData;
  xdsPositionBelegdatum.AsDateTime:=dsBeleg2Datum.AsDateTime;
  xdsPositionposnr.AsFloat:=tmpLetztePosNr+1;
  xdsPositionPosUNr.AsFloat:=0;
  xdsPositionSumme.asboolean:=false;
  xdsPositionMenge.AsInteger:=1;
  xdsPositionArtderMenge.asstring:=dsArtikelArtderMengeArtderMenge.AsString;
  xdsPositionMehrwertsteuersatz.AsFloat:=dsBeleg2mehrwertsteuersatz1.AsFloat;
  xdsPositionVerkaufspreis.AsFloat:=dsArtikelVerkaufspreis.asfloat;
  if dsBeleg2rabattsatz.asfloat=0 then xdsPositionRabattsatz.asfloat:=dsArtikelstandardRabattsatzVK.asfloat;
  if dsBeleg2rabattstufe.asstring='Rabattpreis1' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis1.asstring;
  if dsBeleg2rabattstufe.asstring='Rabattpreis2' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis2.asstring;
  if dsBeleg2rabattstufe.asstring='Rabattpreis3' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis3.asstring;
  if dsBeleg2rabattstufe.asstring='Rabattpreis4' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis4.asstring;
  if dsBeleg2rabattstufe.asstring='Rabattpreis5' then xdsPositionverkaufspreis.asstring:=dsArtikelRabattpreis5.asstring;
  xdsPositionPreisvorRabatt.asfloat:=xdsPositionVerkaufspreis.AsFloat;
  xdsPositionPreisanteilNetto.asfloat:=dsArtikelpreisanteilnetto.asfloat;
  xdsPositionEinkaufspreis.asfloat:=dsArtikelEinkaufspreis.asfloat;
  xdsPositionRabattsatz.AsFloat:=dsBeleg2Rabattsatz.asfloat;
  if xdsKundenpreise.RecordCount>0 then
  begin
    xdsPositionPreisvorrabatt.asfloat:=xdsKundenpreiseVerkaufspreis.asfloat;
    if xdsKundenpreiseRabattsatz.AsFloat<>0 then xdsPositionRabattsatz.AsFloat:=xdsKundenpreiseRabattsatz.AsFloat;

  end;
  xdsPositionPKZ.asfloat:=0;
  if dsBeleg2verleihauftrag.AsBoolean=true then xdsPositionpkz.asfloat:=2;
  xdsPositionposmwst.asfloat:=0;
  xdsPositionMWStKey.asinteger:=1;
  xdsPositionEndpreis.asfloat:=0;
  xdsPositionEndpreis_EK.asfloat:=0;
  xdsPositionBruttoEndpreis.asfloat:=0;
  xdsPositionEinzelpreisBrutto.asfloat:=0;
  xdsPositionbereitsGeliefert.asfloat:=0;
  xdsPositionBestellungErledigt.asboolean:=false;
  xdsPositionausliefern.asfloat:=0;
  xdsPositionbestellen.asboolean:=false;
  xdsPositionlager.asstring:='[-]';
  xdsPositionwarenbestand.asboolean:=dsArtikelWarenbestand.asboolean;
  xdsPositionPosMargeProzent.asfloat:=0;
  xdsPositionPosMarge.asfloat:=0;
  xdsPositionPreisanteilnetto.asfloat:=0;
  xdsPositionprovisionssatz.asfloat:=0;
  xdsPositionprovisionsbetrag.asfloat:=0;
  xdsPositionaussenflaeche.asfloat:=0;
  xdsPositionBruttogewicht.asfloat:=0;
  xdsPositionTara.asfloat:=0;
  xdsPositionPlanerFarbe.asinteger:=0;
  xdsPositionSeitenumbruch.asboolean:=FALSE;
  xdsPositionZSnachPos.asboolean:=False;
  xdsPositionPreisanteilnetto.asfloat:=0;
  xdsPositionPreisVorRabattWHG.asfloat:=0;
  xdsPositionpkey.asinteger:=0;
  xdsPositionVolumen.AsFloat:=0;
  xdsPositionPosVolumen.AsFloat:=0;
  xdsPositionArtikelueberschrift.asstring:=dsArtikelArtikelueberschrift.asstring;
  xdsPositionArtikelbeschreibung.asstring:=dsArtikelArtikelbeschreibung.asstring;
  xdsPositionArtikelbeschreibungRTF.value:=dsArtikelArtikelbeschreibungRTF.value;
  xdsPositionArtikelsprache.value:='';
  {
  if xdsPositionPKZ.asfloat=3 then
  begin
    xdsPositionArtikelbeschreibung.asstring:=dm2.tablesysdatZusatzpreisText.asstring+' '+dsArtikelArtikelbeschreibung.asstring;
    xdsPositionArtikelbeschreibungRTF.value:=dm2.tablesysdatZusatzpreisText.asstring+' '+dsArtikelArtikelbeschreibungRTF.value;
  end;

  if xdsPositionPKZ.asfloat=4 then
  begin
    xdsPositionArtikelbeschreibung.asstring:=dsArtikelArtikelbeschreibung.asstring+#13+#10+dm2.tablesysdatZusatzpreisText.asstring;
    xdsPositionArtikelbeschreibungRTF.value:=dsArtikelArtikelbeschreibungRTF.value+#13+#10+dm2.tablesysdatZusatzpreisText.asstring;
  end;
   }

  xdsPositiongewicht.asstring:=dsArtikelGewicht.AsString;
  xdsPositionLaenge.asstring:=dsArtikelLaenge.AsString;
  xdsPositionBreite.asstring:=dsArtikelBreite.AsString;
  xdsPositionHoehe.asstring:=dsArtikelHoehe.AsString;
  xdsPositionMWStKey.asInteger:=dsArtikelMWStKey.asInteger;
  xdsPositionWarenbestand.asboolean:=dsArtikelWarenbestand.asboolean;
  xdsPositionLieferant.asstring:=dsArtikellieferantnummer.asstring;
  xdsPositionEinkaufspreis.AsString:=dsArtikeleinkaufspreis.asstring;
  xdsPositionEKvom.asstring:=dsArtikeleinkaufspreisvom.asstring;
  xdsPositionLager.asstring:=dsArtikelLagerort.asstring;
  xdsPositionVolumen.AsFloat:=dsArtikelVolumen.AsFloat;
  xdsPositionArtikelAttribute.AsString:=dsArtikelArtikelAttribute.AsString;
  {
  xdsPositionProvisionssatz.Asfloat:=GF.GetProvisionssatz(dsBelegVertreterMatchcode.AsString,dsBelegKundennummer.AsString,xdsPositionartikelnummer.AsString);


  xdsPositionquellartikel.asstring:=dsArtikelquellartikel.asstring;
  xdsPositionEinkaufspreis.Asfloat:=GF.ekpreisquellartikel(xdsPositionquellartikel.asstring,xdsPositionEinkaufspreis.Asfloat);
  }
  xdsPositionTextfrei10.asstring:=dsArtikeltextfrei10.asstring;
  xdsPositionTextfrei20.asstring:=dsArtikeltextfrei20.asstring;

  // Preis nach PKZ holen
  if xdsPositionpkz.asfloat=2 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelverleihpreis.asfloat;
  if xdsPositionpkz.asfloat=3 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelZusatzpreis.asfloat;
  if xdsPositionpkz.asfloat=4 then xdsPositionpreisvorrabatt.asfloat:=dsArtikelverleihpreis.asfloat + dsArtikelZusatzpreis.asfloat;

  {

  //Zusatz
  if xdsPositionPKZ.asfloat=3 then
  begin
    if dm2.queryKundenpreisZusatzpreis.AsFloat<>0 then
    begin
      xdsPositionPreisvorRabatt.AsFloat:=dm2.queryKundenpreisZusatzpreis.AsFloat;
      xdsPositionRabattsatz.asfloat:=0;
      xdsPositionVerkaufspreis.asfloat:=xdsPositionPreisvorRabatt.asfloat;
    end;
  end;

  //Verleih+Zusatzpreis kundenbezogen
  if xdsPositionPKZ.asfloat=4 then
  begin
    if dm2.queryKundenpreisVerleihpreis.AsFloat<>0 then
    begin
      xdsPositionPreisvorRabatt.AsFloat:=dm2.queryKundenpreisVerleihpreis.AsFloat+dm2.querykundenpreiszusatzpreis.asfloat;
      xdsPositionRabattsatz.asfloat:=0;
      xdsPositionVerkaufspreis.asfloat:=xdsPositionPreisvorRabatt.asfloat;
    end;
  end;

  dm2.queryKundenpreis.close;




  //Preiszuschlag für Artikel Beginn
  if gf.checkpreiszuschlag(AN) then
  begin
    Application.CreateForm(TArtikelAuswahlPreiszuschlag, ArtikelAuswahlPreiszuschlag);
    ArtikelAuswahlPreiszuschlag.zuschlagartikelnummer:=AN;
    ArtikelAuswahlPreiszuschlag.showmodal;
    if artikelauswahlpreiszuschlag.resultzuschlagpreisgruppe<>'' then
    begin
      xdsPositiontextfrei10.asstring:=artikelauswahlpreiszuschlag.resultzuschlagtextfrei10;
      xdsPositiontextfrei20.asstring:=artikelauswahlpreiszuschlag.resultzuschlagtextfrei20;
      if artikelauswahlpreiszuschlag.resultzuschlagvk>0 then
      begin
        xdsPositionpreisvorrabatt.asfloat:=xdsPositionpreisvorrabatt.asfloat+artikelauswahlpreiszuschlag.resultzuschlagvk;
      end
      else
      begin
        if artikelauswahlpreiszuschlag.resultzuschlagvkprozent<>0 then
        begin
          xdsPositionpreisvorrabatt.asfloat:=xdsPositionpreisvorrabatt.asfloat+(xdsPositionpreisvorrabatt.asfloat*artikelauswahlpreiszuschlag.resultzuschlagvkprozent/100);
        end;
      end;
    end;
    ArtikelauswahlPreiszuschlag.free;
  end;
  // Preiszuschlag für Artikel ENDE

  // Sonderpreis holen
   xdsPositionpreisvorrabatt.AsFloat:=gf.artikelsonderpreisholenVK(AN,dsBelegDatum.AsDateTime,xdsPositionPreisvorRabatt.asfloat);
  // Fremdwährungspreis holen
  if dm2.tablesysdatMultipleWHG.asboolean then
  begin
    if dsBelegDruckWHG.AsString<>dm2.tablesysdatBWHG.asstring then
    begin
      xdsPositionpreisvorrabattwhg.asfloat:=gf.artikelpreisWHGholenVK(AN, dsBelegdruckwhg.asstring,dsBelegdruckwhgkurs.asfloat,xdsPositionpreisvorrabatt.asfloat);
    end;
  end;
  // Artikeltranslation gemäß Belegsprache
  if (dsBelegbelegsprache.asstring<>'German') AND (dsBelegbelegsprache.asstring<>'')  then
  begin
    dm2.tablesprachen.locate('Belegsprache',dsBelegbelegsprache.asstring,[LOCASEINSENSITIVE]);
    dm2.adoquery1.close;
    dm2.adoquery1.sql.clear;
    dm2.adoquery1.sql.append('select Artikelsprache from ArtikelTranslation WHERE Artikelnummer='''+AN+''' AND ISO6392Code='''+dm2.tablespracheniso6392Code.asstring+'''');
    dm2.adoquery1.open;
    if dm2.adoquery1.fieldbyname('Artikelsprache').asstring<>'' then xdsPositionArtikelsprache.value:=dm2.adoquery1.fieldbyname('Artikelsprache').value;
    dm2.adoquery1.close;
  end;
  }





end;

procedure TAuftragPosEinzel.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  WTCPosition := TTableControl.Create(Self);
  btnPosAbbrechen := TButton.Create(Self);
  btnPosSpeichern := TButton.Create(Self);
  btnPositionLoeschen := TButton.Create(Self);
  edtPosNr := TDBEdit.Create(Self);
  edtPosUNr := TDBEdit.Create(Self);
  edtArtikelueberschrift := TDBEdit.Create(Self);
  edtMenge := TDBEdit.Create(Self);
  btnArtikelnummer := TButton.Create(Self);
  edtArbeitszeitDatum := TDBDateTimePicker.Create(Self);
  edtArbeitszeitVON := TDBDateTimePicker.Create(Self);
  edtArbeitszeitBIS := TDBDateTimePicker.Create(Self);
  edtArtikelbeschreibung := TDBMemo.Create(Self);
  PosComboMonteur := TComboBox.Create(Self);
  edtMengenart := TDBLookupComboBox.Create(Self);
  btnNeuePosition := TButton.Create(Self);
  xdsPosition := TXDataWebDataSet.Create(Self);
  xdsPositionID := TIntegerField.Create(Self);
  xdsPositionPosNr := TFloatField.Create(Self);
  xdsPositionPosUNr := TFloatField.Create(Self);
  xdsPositionArtikelueberschrift := TStringField.Create(Self);
  xdsPositionArtikelbeschreibung := TStringField.Create(Self);
  xdsPositionMenge := TFloatField.Create(Self);
  xdsPositionArtderMenge := TStringField.Create(Self);
  xdsPositionVerkaufspreis := TFloatField.Create(Self);
  xdsPositionEinkaufspreis := TFloatField.Create(Self);
  xdsPositionArtikelnummer := TXDataWebEntityField.Create(Self);
  xdsPositionMonteurP := TIntegerField.Create(Self);
  xdsPositionArbeitszeitDatum := TDateTimeField.Create(Self);
  xdsPositionArbeitszeitVON := TDateTimeField.Create(Self);
  xdsPositionArbeitszeitBIS := TDateTimeField.Create(Self);
  xdsPositionSumme := TBooleanField.Create(Self);
  xdsPositionSeriennummer := TStringField.Create(Self);
  xdsPositionWarengruppe := TStringField.Create(Self);
  xdsPositionWarenuntergruppe := TStringField.Create(Self);
  xdsPositionArtikelnummerKunde := TStringField.Create(Self);
  xdsPositionLieferant := TStringField.Create(Self);
  xdsPositionGewicht := TFloatField.Create(Self);
  xdsPositionLaenge := TFloatField.Create(Self);
  xdsPositionBreite := TFloatField.Create(Self);
  xdsPositionHoehe := TFloatField.Create(Self);
  xdsPositionMehrwertsteuersatz := TFloatField.Create(Self);
  xdsPositionEndpreis := TFloatField.Create(Self);
  xdsPositionPosMWST := TFloatField.Create(Self);
  xdsPositionBruttoEndpreis := TFloatField.Create(Self);
  xdsPositionEndpreis_EK := TFloatField.Create(Self);
  xdsPositionRabattsatz := TFloatField.Create(Self);
  xdsPositionPreisvorRabatt := TFloatField.Create(Self);
  xdsPositionPKZ := TFloatField.Create(Self);
  xdsPositionTextfrei10 := TStringField.Create(Self);
  xdsPositionTextfrei20 := TStringField.Create(Self);
  xdsPositionProduktionAbgeschlossen := TDateTimeField.Create(Self);
  xdsPositionEinzelpreisBrutto := TFloatField.Create(Self);
  xdsPositionPosMarge := TFloatField.Create(Self);
  xdsPositionPosMargeProzent := TFloatField.Create(Self);
  xdsPositionBereitsGeliefert := TFloatField.Create(Self);
  xdsPositionausliefern := TFloatField.Create(Self);
  xdsPositionBestellungErledigt := TBooleanField.Create(Self);
  xdsPositionLager := TStringField.Create(Self);
  xdsPositionbestellen := TBooleanField.Create(Self);
  xdsPositionWarenbestand := TBooleanField.Create(Self);
  xdsPositionArtikelbeschreibungRTF := TStringField.Create(Self);
  xdsPositionQuellartikel := TStringField.Create(Self);
  xdsPositionArtikelnummerWB := TStringField.Create(Self);
  xdsPositionPreisanteilNetto := TFloatField.Create(Self);
  xdsPositionSetHPos := TStringField.Create(Self);
  xdsPositionSetUPos := TStringField.Create(Self);
  xdsPositionProvisionssatz := TFloatField.Create(Self);
  xdsPositionProvisionsbetrag := TFloatField.Create(Self);
  xdsPositionArtikelsprache := TStringField.Create(Self);
  xdsPositionEKvom := TDateTimeField.Create(Self);
  xdsPositionMengenberechnungID := TStringField.Create(Self);
  xdsPositionTaraID := TStringField.Create(Self);
  xdsPositionTara := TFloatField.Create(Self);
  xdsPositionBruttoGewicht := TFloatField.Create(Self);
  xdsPositionAussenflaeche := TFloatField.Create(Self);
  xdsPositionLadeort := TStringField.Create(Self);
  xdsPositionSeitenumbruch := TBooleanField.Create(Self);
  xdsPositionPlanerFarbe := TIntegerField.Create(Self);
  xdsPositionMWStKey := TIntegerField.Create(Self);
  xdsPositionZSnachPos := TBooleanField.Create(Self);
  xdsPositionLieferzeit := TStringField.Create(Self);
  xdsPositionPreisVorRabattWHG := TFloatField.Create(Self);
  xdsPositionReferenzKalkulation := TStringField.Create(Self);
  xdsPositionPKey := TIntegerField.Create(Self);
  xdsPositionAuftragsnummer := TStringField.Create(Self);
  xdsPositionlwbID := TStringField.Create(Self);
  xdsPositionBelegdatum := TDateTimeField.Create(Self);
  xdsPositionWarenkontrollnummern := TStringField.Create(Self);
  xdsPositionVolumen := TFloatField.Create(Self);
  xdsPositionPosVolumen := TFloatField.Create(Self);
  xdsPositionArtikelAttribute := TStringField.Create(Self);
  xdsPositionNummerkomplett := TXDataWebEntityField.Create(Self);
  sourcePosition := TDataSource.Create(Self);
  dsArtikel := TXDataWebDataSet.Create(Self);
  dsArtikelHersteller := TXDataWebEntityField.Create(Self);
  dsArtikelArtderMenge := TXDataWebEntityField.Create(Self);
  dsArtikelPfandArt := TXDataWebEntityField.Create(Self);
  dsArtikelWarengruppe := TXDataWebEntityField.Create(Self);
  dsArtikelWarenuntergruppe := TXDataWebEntityField.Create(Self);
  dsArtikelArtikelnummer := TStringField.Create(Self);
  dsArtikelArtikelueberschrift := TStringField.Create(Self);
  dsArtikelArtikelbeschreibung := TStringField.Create(Self);
  dsArtikelVerkaufspreis := TFloatField.Create(Self);
  dsArtikelMindestbestand := TFloatField.Create(Self);
  dsArtikelPreisliste := TStringField.Create(Self);
  dsArtikelGewicht := TFloatField.Create(Self);
  dsArtikelLaenge := TFloatField.Create(Self);
  dsArtikelBreite := TFloatField.Create(Self);
  dsArtikelHoehe := TFloatField.Create(Self);
  dsArtikelArtikelZusatz := TStringField.Create(Self);
  dsArtikelLagerort := TStringField.Create(Self);
  dsArtikelBuchungskonto := TIntegerField.Create(Self);
  dsArtikelEingangMenge := TFloatField.Create(Self);
  dsArtikelEingangEndpreis := TFloatField.Create(Self);
  dsArtikelAusgangMenge := TFloatField.Create(Self);
  dsArtikelAusgangEndpreisEK := TFloatField.Create(Self);
  dsArtikelAusgangEndpreisVK := TFloatField.Create(Self);
  dsArtikelBestand := TFloatField.Create(Self);
  dsArtikelBestandswert := TFloatField.Create(Self);
  dsArtikelMarge := TFloatField.Create(Self);
  dsArtikelMargeProzent := TFloatField.Create(Self);
  dsArtikelArtikelnummer2 := TStringField.Create(Self);
  dsArtikelArtikelmatchcode := TStringField.Create(Self);
  dsArtikelRabattpreis1 := TFloatField.Create(Self);
  dsArtikelRabattpreis2 := TFloatField.Create(Self);
  dsArtikelRabattpreis3 := TFloatField.Create(Self);
  dsArtikelRabattpreis4 := TFloatField.Create(Self);
  dsArtikelRabattpreis5 := TFloatField.Create(Self);
  dsArtikelArtikelnichtmehrgueltig := TBooleanField.Create(Self);
  dsArtikelEinkaufspreis := TFloatField.Create(Self);
  dsArtikelAufschlag := TFloatField.Create(Self);
  dsArtikelWarenbestand := TBooleanField.Create(Self);
  dsArtikelTextfrei10 := TStringField.Create(Self);
  dsArtikelTextfrei20 := TStringField.Create(Self);
  dsArtikelArtikelbeschreibungRTF := TStringField.Create(Self);
  dsArtikelArtikeldatei1 := TStringField.Create(Self);
  dsArtikelArtikeldatei2 := TStringField.Create(Self);
  dsArtikelArtikeldatei3 := TStringField.Create(Self);
  dsArtikelArtikeldatei4 := TStringField.Create(Self);
  dsArtikelArtikeldatei5 := TStringField.Create(Self);
  dsArtikelEinkaufspreisVom := TDateTimeField.Create(Self);
  dsArtikelEAN := TStringField.Create(Self);
  dsArtikelVGPFaktor := TFloatField.Create(Self);
  dsArtikelVGPMengenart := TStringField.Create(Self);
  dsArtikelVerleihpreis := TFloatField.Create(Self);
  dsArtikelZusatzPreis := TFloatField.Create(Self);
  dsArtikelVerpackungsart := TStringField.Create(Self);
  dsArtikelVerpackungsMenge := TFloatField.Create(Self);
  dsArtikelQuellartikel := TStringField.Create(Self);
  dsArtikelrabattierfaehig := TBooleanField.Create(Self);
  dsArtikelAngebotAlternativeAN := TStringField.Create(Self);
  dsArtikelPreisanteilNetto := TFloatField.Create(Self);
  dsArtikelFarbe := TIntegerField.Create(Self);
  dsArtikelAEinkauf := TBooleanField.Create(Self);
  dsArtikelAVerkauf := TBooleanField.Create(Self);
  dsArtikelArtikelnummerLieferant := TStringField.Create(Self);
  dsArtikelLieferantnummer := TStringField.Create(Self);
  dsArtikelVerkaufspreisBrutto := TFloatField.Create(Self);
  dsArtikelArtikelSet := TBooleanField.Create(Self);
  dsArtikelProvisionssatz := TFloatField.Create(Self);
  dsArtikelWarentarifnummer := TStringField.Create(Self);
  dsArtikelHandelsartikel := TBooleanField.Create(Self);
  dsArtikelUrsprungsland := TStringField.Create(Self);
  dsArtikelKategorie := TStringField.Create(Self);
  dsArtikelStandardRabattsatzEK := TFloatField.Create(Self);
  dsArtikelStandardRabattsatzVK := TFloatField.Create(Self);
  dsArtikelRabattpreiseManuell := TBooleanField.Create(Self);
  dsArtikelMengeBestellt := TFloatField.Create(Self);
  dsArtikelMengeBeauftragt := TFloatField.Create(Self);
  dsArtikelBestandDisponiert := TFloatField.Create(Self);
  dsArtikelMengeAngeboten := TFloatField.Create(Self);
  dsArtikelBestandDispInclAngebot := TFloatField.Create(Self);
  dsArtikelMWStKey := TIntegerField.Create(Self);
  dsArtikelShopAmazon := TBooleanField.Create(Self);
  dsArtikelShopQuelle := TBooleanField.Create(Self);
  dsArtikelGeraet := TBooleanField.Create(Self);
  dsArtikelArtgewicht := TFloatField.Create(Self);
  dsArtikelVolumen := TFloatField.Create(Self);
  dsArtikelPacklisteNichtAnzeigen := TBooleanField.Create(Self);
  dsArtikelKeineAufschlagskalkulation := TBooleanField.Create(Self);
  dsArtikelKorrekturMenge := TFloatField.Create(Self);
  dsArtikelKorrekturWert := TFloatField.Create(Self);
  dsArtikelNachfolgeartikel := TStringField.Create(Self);
  dsArtikelMindestbestellmenge := TFloatField.Create(Self);
  dsArtikelSchnittEK := TFloatField.Create(Self);
  dsArtikelVerkaufspreisVom := TDateTimeField.Create(Self);
  dsArtikelPruefungChargeSNR := TIntegerField.Create(Self);
  dsArtikelArtikelbeschreibungHTML := TStringField.Create(Self);
  dsArtikelProduktKurzbeschreibungHTML := TStringField.Create(Self);
  dsArtikelStatusFuerShop := TStringField.Create(Self);
  dsArtikelAmpelFuerShop := TStringField.Create(Self);
  dsArtikelEXP_Anzahl := TFloatField.Create(Self);
  dsArtikelEXP_Inhalt := TFloatField.Create(Self);
  dsArtikelEXP_Faktor := TFloatField.Create(Self);
  dsArtikelFach := TStringField.Create(Self);
  dsArtikelExportKasse := TIntegerField.Create(Self);
  dsArtikelWKNR := TIntegerField.Create(Self);
  dsArtikelArtikelAttribute := TStringField.Create(Self);
  dsArtikelErstelltAm := TDateTimeField.Create(Self);
  dsArtikelVarianteVonAN := TStringField.Create(Self);
  dsArtikelGurtmassWerte := TStringField.Create(Self);
  dsArtikelGurtmass := TFloatField.Create(Self);
  dsArtikelAltersgrenze := TIntegerField.Create(Self);
  dsArtikelMWStKeyImHaus := TIntegerField.Create(Self);
  dsArtikelKasseBewegung := TFloatField.Create(Self);
  dsArtikelKasseBewegungEK := TFloatField.Create(Self);
  dsArtikelKasseBewegungVK := TFloatField.Create(Self);
  dsArtikelProduktionBewegung := TFloatField.Create(Self);
  dsArtikelProduktionBewegungEK := TFloatField.Create(Self);
  dsArtikelProduktionBewegungVK := TFloatField.Create(Self);
  dsArtikelWaWiAB := TIntegerField.Create(Self);
  dsArtikelGeaendertAm := TDateTimeField.Create(Self);
  dsArtikelGefahrgutKennzeichen := TStringField.Create(Self);
  dsArtikelVerpackG := TBooleanField.Create(Self);
  dsArtikelUPE := TFloatField.Create(Self);
  dsArtikelArtikelbeschreibungEinkauf := TStringField.Create(Self);
  dsArtikelHerstellerArtikelnummer := TStringField.Create(Self);
  dsArtikelNatura := TStringField.Create(Self);
  dsArtikelArbeitszeit := TBooleanField.Create(Self);
  dsArtikelArtderMengeArtderMenge := TStringField.Create(Self);
  xdsKundenpreise := TXDataWebDataSet.Create(Self);
  xdsKundenpreiseArtikelnummer := TXDataWebEntityField.Create(Self);
  xdsKundenpreiseKundennummer := TXDataWebEntityField.Create(Self);
  xdsKundenpreiseVerkaufspreis := TFloatField.Create(Self);
  xdsKundenpreiseNachname := TStringField.Create(Self);
  xdsKundenpreiseRabattsatz := TFloatField.Create(Self);
  xdsKundenpreiseVerleihpreis := TFloatField.Create(Self);
  xdsKundenpreiseZusatzPreis := TFloatField.Create(Self);
  xdsKundenpreiseArtikelnummerKunde := TStringField.Create(Self);
  xdsKundenpreiseDatumGeaendert := TDateTimeField.Create(Self);
  xdsKundenpreiseArtikelueberschrift := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelnummer := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelueberschrift := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelbeschreibung := TStringField.Create(Self);
  dsBeleg2 := TXDataWebDataSet.Create(Self);
  dsBeleg2Kundennummer := TXDataWebEntityField.Create(Self);
  dsBeleg2Nummerkomplett := TStringField.Create(Self);
  dsBeleg2ReferenzAngebot := TStringField.Create(Self);
  dsBeleg2ReferenzLieferschein := TStringField.Create(Self);
  dsBeleg2ReferenzRechnung := TStringField.Create(Self);
  dsBeleg2Datum := TDateTimeField.Create(Self);
  dsBeleg2Jahr := TFloatField.Create(Self);
  dsBeleg2Kalenderwoche := TIntegerField.Create(Self);
  dsBeleg2Nachname := TStringField.Create(Self);
  dsBeleg2Vorname := TStringField.Create(Self);
  dsBeleg2Postleitzahl := TStringField.Create(Self);
  dsBeleg2Ort := TStringField.Create(Self);
  dsBeleg2Laenderkuerzel := TStringField.Create(Self);
  dsBeleg2Land := TStringField.Create(Self);
  dsBeleg2Briefanrede1 := TStringField.Create(Self);
  dsBeleg2Briefanrede2 := TStringField.Create(Self);
  dsBeleg2EMail := TStringField.Create(Self);
  dsBeleg2AdresseBild := TStringField.Create(Self);
  dsBeleg2LieferanschriftBild := TStringField.Create(Self);
  dsBeleg2Zahlungsbedingung := TIntegerField.Create(Self);
  dsBeleg2ZahlungsbedingungText := TStringField.Create(Self);
  dsBeleg2Auftragsbezeichnung := TStringField.Create(Self);
  dsBeleg2AuftragnummerKunde := TStringField.Create(Self);
  dsBeleg2BestelldatumKunde := TDateTimeField.Create(Self);
  dsBeleg2ILN := TStringField.Create(Self);
  dsBeleg2Kopftext := TStringField.Create(Self);
  dsBeleg2Schlussbemerkung := TStringField.Create(Self);
  dsBeleg2Gruss := TStringField.Create(Self);
  dsBeleg2Mehrwertsteuersatz1 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuersatz2 := TFloatField.Create(Self);
  dsBeleg2Nettosumme01 := TFloatField.Create(Self);
  dsBeleg2Nettosumme02 := TFloatField.Create(Self);
  dsBeleg2Nettosumme := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuer01 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuer02 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuer := TFloatField.Create(Self);
  dsBeleg2Bruttosumme := TFloatField.Create(Self);
  dsBeleg2Versanddatum := TDateTimeField.Create(Self);
  dsBeleg2Skontosatz := TFloatField.Create(Self);
  dsBeleg2NettosummeEK := TFloatField.Create(Self);
  dsBeleg2NettoMarge := TFloatField.Create(Self);
  dsBeleg2Schreibzeichen := TStringField.Create(Self);
  dsBeleg2Unterzeichner := TStringField.Create(Self);
  dsBeleg2TelefonMA := TStringField.Create(Self);
  dsBeleg2EmailMA := TStringField.Create(Self);
  dsBeleg2USTID := TStringField.Create(Self);
  dsBeleg2Umsatzsteuerausweis := TBooleanField.Create(Self);
  dsBeleg2gebucht := TBooleanField.Create(Self);
  dsBeleg2Lieferdatum := TDateTimeField.Create(Self);
  dsBeleg2Transportbedingung := TStringField.Create(Self);
  dsBeleg2Mehrwertsteuersatz3 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuer03 := TFloatField.Create(Self);
  dsBeleg2Nettosumme03 := TFloatField.Create(Self);
  dsBeleg2RundungsDifferenz := TFloatField.Create(Self);
  dsBeleg2EmailText := TStringField.Create(Self);
  dsBeleg2Auftragerledigt := TBooleanField.Create(Self);
  dsBeleg2Rabattstufe := TStringField.Create(Self);
  dsBeleg2KFZKennzeichen := TStringField.Create(Self);
  dsBeleg2Fahrgestellnummer := TStringField.Create(Self);
  dsBeleg2KFZHersteller := TStringField.Create(Self);
  dsBeleg2Erstzulassungsdatum := TDateTimeField.Create(Self);
  dsBeleg2KMStand := TStringField.Create(Self);
  dsBeleg2KFZType := TStringField.Create(Self);
  dsBeleg2Inspektionsdatum := TDateTimeField.Create(Self);
  dsBeleg2Debitorennummer := TStringField.Create(Self);
  dsBeleg2Kostentraeger := TStringField.Create(Self);
  dsBeleg2Rabattsatz := TFloatField.Create(Self);
  dsBeleg2ObjektReferenz := TStringField.Create(Self);
  dsBeleg2Bank := TStringField.Create(Self);
  dsBeleg2Bankleitzahl := TFloatField.Create(Self);
  dsBeleg2Kontonummer := TStringField.Create(Self);
  dsBeleg2Kontoinhaber := TStringField.Create(Self);
  dsBeleg2Telefaxnummer := TStringField.Create(Self);
  dsBeleg2DatumNeu := TDateTimeField.Create(Self);
  dsBeleg2Datumgeaendert := TDateTimeField.Create(Self);
  dsBeleg2Objektnummer := TStringField.Create(Self);
  dsBeleg2Liefertermin := TStringField.Create(Self);
  dsBeleg2Emailversand := TBooleanField.Create(Self);
  dsBeleg2Telefonnummer := TStringField.Create(Self);
  dsBeleg2MobilTelefon := TStringField.Create(Self);
  dsBeleg2AuftragFax := TBooleanField.Create(Self);
  dsBeleg2REDatum := TDateTimeField.Create(Self);
  dsBeleg2BenutzerNeu := TStringField.Create(Self);
  dsBeleg2BenutzerGeaendert := TStringField.Create(Self);
  dsBeleg2BerechnungBrutto := TBooleanField.Create(Self);
  dsBeleg2Bruttosumme01 := TFloatField.Create(Self);
  dsBeleg2Bruttosumme02 := TFloatField.Create(Self);
  dsBeleg2Bruttosumme03 := TFloatField.Create(Self);
  dsBeleg2Notiz := TStringField.Create(Self);
  dsBeleg2MargeProzent := TFloatField.Create(Self);
  dsBeleg2Warenkreditgesperrt := TBooleanField.Create(Self);
  dsBeleg2Belegsprache := TStringField.Create(Self);
  dsBeleg2Duplicate := TStringField.Create(Self);
  dsBeleg2perEmail := TBooleanField.Create(Self);
  dsBeleg2Ansprechpartner := TStringField.Create(Self);
  dsBeleg2KundennummerFremd := TStringField.Create(Self);
  dsBeleg2Protokoll := TStringField.Create(Self);
  dsBeleg2Verleihauftrag := TBooleanField.Create(Self);
  dsBeleg2VerleihVon := TDateTimeField.Create(Self);
  dsBeleg2VerleihBis := TDateTimeField.Create(Self);
  dsBeleg2AuftragGenehmigt := TBooleanField.Create(Self);
  dsBeleg2gesperrt := TBooleanField.Create(Self);
  dsBeleg2VerleihvonText := TStringField.Create(Self);
  dsBeleg2VerleihbisText := TStringField.Create(Self);
  dsBeleg2DruckWHG := TStringField.Create(Self);
  dsBeleg2DruckWHGKurs := TFloatField.Create(Self);
  dsBeleg2ProzentsatzZusatzposition := TFloatField.Create(Self);
  dsBeleg2TextZusatzposition := TStringField.Create(Self);
  dsBeleg2TextzusatzBeschreibung := TStringField.Create(Self);
  dsBeleg2Layoutname := TStringField.Create(Self);
  dsBeleg2Gesamtgewicht := TFloatField.Create(Self);
  dsBeleg2BetragZusatzposition := TFloatField.Create(Self);
  dsBeleg2ReferenzBestellung := TStringField.Create(Self);
  dsBeleg2Termin1 := TDateTimeField.Create(Self);
  dsBeleg2Termin2 := TDateTimeField.Create(Self);
  dsBeleg2Zielkontaktnummer := TStringField.Create(Self);
  dsBeleg2Zielkontaktname := TStringField.Create(Self);
  dsBeleg2Bilddatei1 := TStringField.Create(Self);
  dsBeleg2Bilddatei2 := TStringField.Create(Self);
  dsBeleg2Zielkontaktnummer2 := TStringField.Create(Self);
  dsBeleg2Zielkontaktname2 := TStringField.Create(Self);
  dsBeleg2bestaetigt := TDateTimeField.Create(Self);
  dsBeleg2Zielkontaktnummer3 := TStringField.Create(Self);
  dsBeleg2Zielkontaktname3 := TStringField.Create(Self);
  dsBeleg2Termin3 := TDateTimeField.Create(Self);
  dsBeleg2Termin4 := TDateTimeField.Create(Self);
  dsBeleg2Muster := TBooleanField.Create(Self);
  dsBeleg2KW := TIntegerField.Create(Self);
  dsBeleg2Datenlink := TStringField.Create(Self);
  dsBeleg2Mehrwertsteuersatz4 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuersatz5 := TFloatField.Create(Self);
  dsBeleg2Mehrwertsteuersatz6 := TFloatField.Create(Self);
  dsBeleg2BesteuerungKey := TIntegerField.Create(Self);
  dsBeleg2VertreterMatchcode := TStringField.Create(Self);
  dsBeleg2Verfasser := TStringField.Create(Self);
  dsBeleg2LieferadresseID := TIntegerField.Create(Self);
  dsBeleg2AuftragStatus := TStringField.Create(Self);
  dsBeleg2MonatJahr := TDateTimeField.Create(Self);
  dsBeleg2Belegvariante := TStringField.Create(Self);
  dsBeleg2Produktionsfreigabe := TBooleanField.Create(Self);
  dsBeleg2ReferenzRahmenAuftrag := TStringField.Create(Self);
  dsBeleg2DatumRahmenAuftrag := TDateTimeField.Create(Self);
  dsBeleg2FahrzeugID := TIntegerField.Create(Self);
  dsBeleg2AuslieferungKW := TStringField.Create(Self);
  dsBeleg2BereitstellungDatum := TDateTimeField.Create(Self);
  dsBeleg2VTUID := TStringField.Create(Self);
  dsBeleg2LieferanschriftDaten := TStringField.Create(Self);
  dsBeleg2GesamtVolumen := TFloatField.Create(Self);
  dsBeleg2BelegPKey := TIntegerField.Create(Self);
  dsBeleg2DruckParameter := TStringField.Create(Self);
  dsBeleg2ReferenzService := TStringField.Create(Self);
  dsBeleg2ZusatzDaten := TStringField.Create(Self);
  dsBeleg2StandPDF := TStringField.Create(Self);
  dsBeleg2Paketdienst := TStringField.Create(Self);
  dsBeleg2Monteur := TIntegerField.Create(Self);
  dsBeleg2KundennummerKundennummer := TStringField.Create(Self);
  dsBeleg2ServiceBemerkung := TStringField.Create(Self);

  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  WTCPosition.BeforeLoadDFMValues;
  btnPosAbbrechen.BeforeLoadDFMValues;
  btnPosSpeichern.BeforeLoadDFMValues;
  btnPositionLoeschen.BeforeLoadDFMValues;
  edtPosNr.BeforeLoadDFMValues;
  edtPosUNr.BeforeLoadDFMValues;
  edtArtikelueberschrift.BeforeLoadDFMValues;
  edtMenge.BeforeLoadDFMValues;
  btnArtikelnummer.BeforeLoadDFMValues;
  edtArbeitszeitDatum.BeforeLoadDFMValues;
  edtArbeitszeitVON.BeforeLoadDFMValues;
  edtArbeitszeitBIS.BeforeLoadDFMValues;
  edtArtikelbeschreibung.BeforeLoadDFMValues;
  PosComboMonteur.BeforeLoadDFMValues;
  edtMengenart.BeforeLoadDFMValues;
  btnNeuePosition.BeforeLoadDFMValues;
  xdsPosition.BeforeLoadDFMValues;
  xdsPositionID.BeforeLoadDFMValues;
  xdsPositionPosNr.BeforeLoadDFMValues;
  xdsPositionPosUNr.BeforeLoadDFMValues;
  xdsPositionArtikelueberschrift.BeforeLoadDFMValues;
  xdsPositionArtikelbeschreibung.BeforeLoadDFMValues;
  xdsPositionMenge.BeforeLoadDFMValues;
  xdsPositionArtderMenge.BeforeLoadDFMValues;
  xdsPositionVerkaufspreis.BeforeLoadDFMValues;
  xdsPositionEinkaufspreis.BeforeLoadDFMValues;
  xdsPositionArtikelnummer.BeforeLoadDFMValues;
  xdsPositionMonteurP.BeforeLoadDFMValues;
  xdsPositionArbeitszeitDatum.BeforeLoadDFMValues;
  xdsPositionArbeitszeitVON.BeforeLoadDFMValues;
  xdsPositionArbeitszeitBIS.BeforeLoadDFMValues;
  xdsPositionSumme.BeforeLoadDFMValues;
  xdsPositionSeriennummer.BeforeLoadDFMValues;
  xdsPositionWarengruppe.BeforeLoadDFMValues;
  xdsPositionWarenuntergruppe.BeforeLoadDFMValues;
  xdsPositionArtikelnummerKunde.BeforeLoadDFMValues;
  xdsPositionLieferant.BeforeLoadDFMValues;
  xdsPositionGewicht.BeforeLoadDFMValues;
  xdsPositionLaenge.BeforeLoadDFMValues;
  xdsPositionBreite.BeforeLoadDFMValues;
  xdsPositionHoehe.BeforeLoadDFMValues;
  xdsPositionMehrwertsteuersatz.BeforeLoadDFMValues;
  xdsPositionEndpreis.BeforeLoadDFMValues;
  xdsPositionPosMWST.BeforeLoadDFMValues;
  xdsPositionBruttoEndpreis.BeforeLoadDFMValues;
  xdsPositionEndpreis_EK.BeforeLoadDFMValues;
  xdsPositionRabattsatz.BeforeLoadDFMValues;
  xdsPositionPreisvorRabatt.BeforeLoadDFMValues;
  xdsPositionPKZ.BeforeLoadDFMValues;
  xdsPositionTextfrei10.BeforeLoadDFMValues;
  xdsPositionTextfrei20.BeforeLoadDFMValues;
  xdsPositionProduktionAbgeschlossen.BeforeLoadDFMValues;
  xdsPositionEinzelpreisBrutto.BeforeLoadDFMValues;
  xdsPositionPosMarge.BeforeLoadDFMValues;
  xdsPositionPosMargeProzent.BeforeLoadDFMValues;
  xdsPositionBereitsGeliefert.BeforeLoadDFMValues;
  xdsPositionausliefern.BeforeLoadDFMValues;
  xdsPositionBestellungErledigt.BeforeLoadDFMValues;
  xdsPositionLager.BeforeLoadDFMValues;
  xdsPositionbestellen.BeforeLoadDFMValues;
  xdsPositionWarenbestand.BeforeLoadDFMValues;
  xdsPositionArtikelbeschreibungRTF.BeforeLoadDFMValues;
  xdsPositionQuellartikel.BeforeLoadDFMValues;
  xdsPositionArtikelnummerWB.BeforeLoadDFMValues;
  xdsPositionPreisanteilNetto.BeforeLoadDFMValues;
  xdsPositionSetHPos.BeforeLoadDFMValues;
  xdsPositionSetUPos.BeforeLoadDFMValues;
  xdsPositionProvisionssatz.BeforeLoadDFMValues;
  xdsPositionProvisionsbetrag.BeforeLoadDFMValues;
  xdsPositionArtikelsprache.BeforeLoadDFMValues;
  xdsPositionEKvom.BeforeLoadDFMValues;
  xdsPositionMengenberechnungID.BeforeLoadDFMValues;
  xdsPositionTaraID.BeforeLoadDFMValues;
  xdsPositionTara.BeforeLoadDFMValues;
  xdsPositionBruttoGewicht.BeforeLoadDFMValues;
  xdsPositionAussenflaeche.BeforeLoadDFMValues;
  xdsPositionLadeort.BeforeLoadDFMValues;
  xdsPositionSeitenumbruch.BeforeLoadDFMValues;
  xdsPositionPlanerFarbe.BeforeLoadDFMValues;
  xdsPositionMWStKey.BeforeLoadDFMValues;
  xdsPositionZSnachPos.BeforeLoadDFMValues;
  xdsPositionLieferzeit.BeforeLoadDFMValues;
  xdsPositionPreisVorRabattWHG.BeforeLoadDFMValues;
  xdsPositionReferenzKalkulation.BeforeLoadDFMValues;
  xdsPositionPKey.BeforeLoadDFMValues;
  xdsPositionAuftragsnummer.BeforeLoadDFMValues;
  xdsPositionlwbID.BeforeLoadDFMValues;
  xdsPositionBelegdatum.BeforeLoadDFMValues;
  xdsPositionWarenkontrollnummern.BeforeLoadDFMValues;
  xdsPositionVolumen.BeforeLoadDFMValues;
  xdsPositionPosVolumen.BeforeLoadDFMValues;
  xdsPositionArtikelAttribute.BeforeLoadDFMValues;
  xdsPositionNummerkomplett.BeforeLoadDFMValues;
  sourcePosition.BeforeLoadDFMValues;
  dsArtikel.BeforeLoadDFMValues;
  dsArtikelHersteller.BeforeLoadDFMValues;
  dsArtikelArtderMenge.BeforeLoadDFMValues;
  dsArtikelPfandArt.BeforeLoadDFMValues;
  dsArtikelWarengruppe.BeforeLoadDFMValues;
  dsArtikelWarenuntergruppe.BeforeLoadDFMValues;
  dsArtikelArtikelnummer.BeforeLoadDFMValues;
  dsArtikelArtikelueberschrift.BeforeLoadDFMValues;
  dsArtikelArtikelbeschreibung.BeforeLoadDFMValues;
  dsArtikelVerkaufspreis.BeforeLoadDFMValues;
  dsArtikelMindestbestand.BeforeLoadDFMValues;
  dsArtikelPreisliste.BeforeLoadDFMValues;
  dsArtikelGewicht.BeforeLoadDFMValues;
  dsArtikelLaenge.BeforeLoadDFMValues;
  dsArtikelBreite.BeforeLoadDFMValues;
  dsArtikelHoehe.BeforeLoadDFMValues;
  dsArtikelArtikelZusatz.BeforeLoadDFMValues;
  dsArtikelLagerort.BeforeLoadDFMValues;
  dsArtikelBuchungskonto.BeforeLoadDFMValues;
  dsArtikelEingangMenge.BeforeLoadDFMValues;
  dsArtikelEingangEndpreis.BeforeLoadDFMValues;
  dsArtikelAusgangMenge.BeforeLoadDFMValues;
  dsArtikelAusgangEndpreisEK.BeforeLoadDFMValues;
  dsArtikelAusgangEndpreisVK.BeforeLoadDFMValues;
  dsArtikelBestand.BeforeLoadDFMValues;
  dsArtikelBestandswert.BeforeLoadDFMValues;
  dsArtikelMarge.BeforeLoadDFMValues;
  dsArtikelMargeProzent.BeforeLoadDFMValues;
  dsArtikelArtikelnummer2.BeforeLoadDFMValues;
  dsArtikelArtikelmatchcode.BeforeLoadDFMValues;
  dsArtikelRabattpreis1.BeforeLoadDFMValues;
  dsArtikelRabattpreis2.BeforeLoadDFMValues;
  dsArtikelRabattpreis3.BeforeLoadDFMValues;
  dsArtikelRabattpreis4.BeforeLoadDFMValues;
  dsArtikelRabattpreis5.BeforeLoadDFMValues;
  dsArtikelArtikelnichtmehrgueltig.BeforeLoadDFMValues;
  dsArtikelEinkaufspreis.BeforeLoadDFMValues;
  dsArtikelAufschlag.BeforeLoadDFMValues;
  dsArtikelWarenbestand.BeforeLoadDFMValues;
  dsArtikelTextfrei10.BeforeLoadDFMValues;
  dsArtikelTextfrei20.BeforeLoadDFMValues;
  dsArtikelArtikelbeschreibungRTF.BeforeLoadDFMValues;
  dsArtikelArtikeldatei1.BeforeLoadDFMValues;
  dsArtikelArtikeldatei2.BeforeLoadDFMValues;
  dsArtikelArtikeldatei3.BeforeLoadDFMValues;
  dsArtikelArtikeldatei4.BeforeLoadDFMValues;
  dsArtikelArtikeldatei5.BeforeLoadDFMValues;
  dsArtikelEinkaufspreisVom.BeforeLoadDFMValues;
  dsArtikelEAN.BeforeLoadDFMValues;
  dsArtikelVGPFaktor.BeforeLoadDFMValues;
  dsArtikelVGPMengenart.BeforeLoadDFMValues;
  dsArtikelVerleihpreis.BeforeLoadDFMValues;
  dsArtikelZusatzPreis.BeforeLoadDFMValues;
  dsArtikelVerpackungsart.BeforeLoadDFMValues;
  dsArtikelVerpackungsMenge.BeforeLoadDFMValues;
  dsArtikelQuellartikel.BeforeLoadDFMValues;
  dsArtikelrabattierfaehig.BeforeLoadDFMValues;
  dsArtikelAngebotAlternativeAN.BeforeLoadDFMValues;
  dsArtikelPreisanteilNetto.BeforeLoadDFMValues;
  dsArtikelFarbe.BeforeLoadDFMValues;
  dsArtikelAEinkauf.BeforeLoadDFMValues;
  dsArtikelAVerkauf.BeforeLoadDFMValues;
  dsArtikelArtikelnummerLieferant.BeforeLoadDFMValues;
  dsArtikelLieferantnummer.BeforeLoadDFMValues;
  dsArtikelVerkaufspreisBrutto.BeforeLoadDFMValues;
  dsArtikelArtikelSet.BeforeLoadDFMValues;
  dsArtikelProvisionssatz.BeforeLoadDFMValues;
  dsArtikelWarentarifnummer.BeforeLoadDFMValues;
  dsArtikelHandelsartikel.BeforeLoadDFMValues;
  dsArtikelUrsprungsland.BeforeLoadDFMValues;
  dsArtikelKategorie.BeforeLoadDFMValues;
  dsArtikelStandardRabattsatzEK.BeforeLoadDFMValues;
  dsArtikelStandardRabattsatzVK.BeforeLoadDFMValues;
  dsArtikelRabattpreiseManuell.BeforeLoadDFMValues;
  dsArtikelMengeBestellt.BeforeLoadDFMValues;
  dsArtikelMengeBeauftragt.BeforeLoadDFMValues;
  dsArtikelBestandDisponiert.BeforeLoadDFMValues;
  dsArtikelMengeAngeboten.BeforeLoadDFMValues;
  dsArtikelBestandDispInclAngebot.BeforeLoadDFMValues;
  dsArtikelMWStKey.BeforeLoadDFMValues;
  dsArtikelShopAmazon.BeforeLoadDFMValues;
  dsArtikelShopQuelle.BeforeLoadDFMValues;
  dsArtikelGeraet.BeforeLoadDFMValues;
  dsArtikelArtgewicht.BeforeLoadDFMValues;
  dsArtikelVolumen.BeforeLoadDFMValues;
  dsArtikelPacklisteNichtAnzeigen.BeforeLoadDFMValues;
  dsArtikelKeineAufschlagskalkulation.BeforeLoadDFMValues;
  dsArtikelKorrekturMenge.BeforeLoadDFMValues;
  dsArtikelKorrekturWert.BeforeLoadDFMValues;
  dsArtikelNachfolgeartikel.BeforeLoadDFMValues;
  dsArtikelMindestbestellmenge.BeforeLoadDFMValues;
  dsArtikelSchnittEK.BeforeLoadDFMValues;
  dsArtikelVerkaufspreisVom.BeforeLoadDFMValues;
  dsArtikelPruefungChargeSNR.BeforeLoadDFMValues;
  dsArtikelArtikelbeschreibungHTML.BeforeLoadDFMValues;
  dsArtikelProduktKurzbeschreibungHTML.BeforeLoadDFMValues;
  dsArtikelStatusFuerShop.BeforeLoadDFMValues;
  dsArtikelAmpelFuerShop.BeforeLoadDFMValues;
  dsArtikelEXP_Anzahl.BeforeLoadDFMValues;
  dsArtikelEXP_Inhalt.BeforeLoadDFMValues;
  dsArtikelEXP_Faktor.BeforeLoadDFMValues;
  dsArtikelFach.BeforeLoadDFMValues;
  dsArtikelExportKasse.BeforeLoadDFMValues;
  dsArtikelWKNR.BeforeLoadDFMValues;
  dsArtikelArtikelAttribute.BeforeLoadDFMValues;
  dsArtikelErstelltAm.BeforeLoadDFMValues;
  dsArtikelVarianteVonAN.BeforeLoadDFMValues;
  dsArtikelGurtmassWerte.BeforeLoadDFMValues;
  dsArtikelGurtmass.BeforeLoadDFMValues;
  dsArtikelAltersgrenze.BeforeLoadDFMValues;
  dsArtikelMWStKeyImHaus.BeforeLoadDFMValues;
  dsArtikelKasseBewegung.BeforeLoadDFMValues;
  dsArtikelKasseBewegungEK.BeforeLoadDFMValues;
  dsArtikelKasseBewegungVK.BeforeLoadDFMValues;
  dsArtikelProduktionBewegung.BeforeLoadDFMValues;
  dsArtikelProduktionBewegungEK.BeforeLoadDFMValues;
  dsArtikelProduktionBewegungVK.BeforeLoadDFMValues;
  dsArtikelWaWiAB.BeforeLoadDFMValues;
  dsArtikelGeaendertAm.BeforeLoadDFMValues;
  dsArtikelGefahrgutKennzeichen.BeforeLoadDFMValues;
  dsArtikelVerpackG.BeforeLoadDFMValues;
  dsArtikelUPE.BeforeLoadDFMValues;
  dsArtikelArtikelbeschreibungEinkauf.BeforeLoadDFMValues;
  dsArtikelHerstellerArtikelnummer.BeforeLoadDFMValues;
  dsArtikelNatura.BeforeLoadDFMValues;
  dsArtikelArbeitszeit.BeforeLoadDFMValues;
  dsArtikelArtderMengeArtderMenge.BeforeLoadDFMValues;
  xdsKundenpreise.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummer.BeforeLoadDFMValues;
  xdsKundenpreiseKundennummer.BeforeLoadDFMValues;
  xdsKundenpreiseVerkaufspreis.BeforeLoadDFMValues;
  xdsKundenpreiseNachname.BeforeLoadDFMValues;
  xdsKundenpreiseRabattsatz.BeforeLoadDFMValues;
  xdsKundenpreiseVerleihpreis.BeforeLoadDFMValues;
  xdsKundenpreiseZusatzPreis.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerKunde.BeforeLoadDFMValues;
  xdsKundenpreiseDatumGeaendert.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelueberschrift.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelnummer.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelueberschrift.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelbeschreibung.BeforeLoadDFMValues;
  dsBeleg2.BeforeLoadDFMValues;
  dsBeleg2Kundennummer.BeforeLoadDFMValues;
  dsBeleg2Nummerkomplett.BeforeLoadDFMValues;
  dsBeleg2ReferenzAngebot.BeforeLoadDFMValues;
  dsBeleg2ReferenzLieferschein.BeforeLoadDFMValues;
  dsBeleg2ReferenzRechnung.BeforeLoadDFMValues;
  dsBeleg2Datum.BeforeLoadDFMValues;
  dsBeleg2Jahr.BeforeLoadDFMValues;
  dsBeleg2Kalenderwoche.BeforeLoadDFMValues;
  dsBeleg2Nachname.BeforeLoadDFMValues;
  dsBeleg2Vorname.BeforeLoadDFMValues;
  dsBeleg2Postleitzahl.BeforeLoadDFMValues;
  dsBeleg2Ort.BeforeLoadDFMValues;
  dsBeleg2Laenderkuerzel.BeforeLoadDFMValues;
  dsBeleg2Land.BeforeLoadDFMValues;
  dsBeleg2Briefanrede1.BeforeLoadDFMValues;
  dsBeleg2Briefanrede2.BeforeLoadDFMValues;
  dsBeleg2EMail.BeforeLoadDFMValues;
  dsBeleg2AdresseBild.BeforeLoadDFMValues;
  dsBeleg2LieferanschriftBild.BeforeLoadDFMValues;
  dsBeleg2Zahlungsbedingung.BeforeLoadDFMValues;
  dsBeleg2ZahlungsbedingungText.BeforeLoadDFMValues;
  dsBeleg2Auftragsbezeichnung.BeforeLoadDFMValues;
  dsBeleg2AuftragnummerKunde.BeforeLoadDFMValues;
  dsBeleg2BestelldatumKunde.BeforeLoadDFMValues;
  dsBeleg2ILN.BeforeLoadDFMValues;
  dsBeleg2Kopftext.BeforeLoadDFMValues;
  dsBeleg2Schlussbemerkung.BeforeLoadDFMValues;
  dsBeleg2Gruss.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz1.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz2.BeforeLoadDFMValues;
  dsBeleg2Nettosumme01.BeforeLoadDFMValues;
  dsBeleg2Nettosumme02.BeforeLoadDFMValues;
  dsBeleg2Nettosumme.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuer01.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuer02.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuer.BeforeLoadDFMValues;
  dsBeleg2Bruttosumme.BeforeLoadDFMValues;
  dsBeleg2Versanddatum.BeforeLoadDFMValues;
  dsBeleg2Skontosatz.BeforeLoadDFMValues;
  dsBeleg2NettosummeEK.BeforeLoadDFMValues;
  dsBeleg2NettoMarge.BeforeLoadDFMValues;
  dsBeleg2Schreibzeichen.BeforeLoadDFMValues;
  dsBeleg2Unterzeichner.BeforeLoadDFMValues;
  dsBeleg2TelefonMA.BeforeLoadDFMValues;
  dsBeleg2EmailMA.BeforeLoadDFMValues;
  dsBeleg2USTID.BeforeLoadDFMValues;
  dsBeleg2Umsatzsteuerausweis.BeforeLoadDFMValues;
  dsBeleg2gebucht.BeforeLoadDFMValues;
  dsBeleg2Lieferdatum.BeforeLoadDFMValues;
  dsBeleg2Transportbedingung.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz3.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuer03.BeforeLoadDFMValues;
  dsBeleg2Nettosumme03.BeforeLoadDFMValues;
  dsBeleg2RundungsDifferenz.BeforeLoadDFMValues;
  dsBeleg2EmailText.BeforeLoadDFMValues;
  dsBeleg2Auftragerledigt.BeforeLoadDFMValues;
  dsBeleg2Rabattstufe.BeforeLoadDFMValues;
  dsBeleg2KFZKennzeichen.BeforeLoadDFMValues;
  dsBeleg2Fahrgestellnummer.BeforeLoadDFMValues;
  dsBeleg2KFZHersteller.BeforeLoadDFMValues;
  dsBeleg2Erstzulassungsdatum.BeforeLoadDFMValues;
  dsBeleg2KMStand.BeforeLoadDFMValues;
  dsBeleg2KFZType.BeforeLoadDFMValues;
  dsBeleg2Inspektionsdatum.BeforeLoadDFMValues;
  dsBeleg2Debitorennummer.BeforeLoadDFMValues;
  dsBeleg2Kostentraeger.BeforeLoadDFMValues;
  dsBeleg2Rabattsatz.BeforeLoadDFMValues;
  dsBeleg2ObjektReferenz.BeforeLoadDFMValues;
  dsBeleg2Bank.BeforeLoadDFMValues;
  dsBeleg2Bankleitzahl.BeforeLoadDFMValues;
  dsBeleg2Kontonummer.BeforeLoadDFMValues;
  dsBeleg2Kontoinhaber.BeforeLoadDFMValues;
  dsBeleg2Telefaxnummer.BeforeLoadDFMValues;
  dsBeleg2DatumNeu.BeforeLoadDFMValues;
  dsBeleg2Datumgeaendert.BeforeLoadDFMValues;
  dsBeleg2Objektnummer.BeforeLoadDFMValues;
  dsBeleg2Liefertermin.BeforeLoadDFMValues;
  dsBeleg2Emailversand.BeforeLoadDFMValues;
  dsBeleg2Telefonnummer.BeforeLoadDFMValues;
  dsBeleg2MobilTelefon.BeforeLoadDFMValues;
  dsBeleg2AuftragFax.BeforeLoadDFMValues;
  dsBeleg2REDatum.BeforeLoadDFMValues;
  dsBeleg2BenutzerNeu.BeforeLoadDFMValues;
  dsBeleg2BenutzerGeaendert.BeforeLoadDFMValues;
  dsBeleg2BerechnungBrutto.BeforeLoadDFMValues;
  dsBeleg2Bruttosumme01.BeforeLoadDFMValues;
  dsBeleg2Bruttosumme02.BeforeLoadDFMValues;
  dsBeleg2Bruttosumme03.BeforeLoadDFMValues;
  dsBeleg2Notiz.BeforeLoadDFMValues;
  dsBeleg2MargeProzent.BeforeLoadDFMValues;
  dsBeleg2Warenkreditgesperrt.BeforeLoadDFMValues;
  dsBeleg2Belegsprache.BeforeLoadDFMValues;
  dsBeleg2Duplicate.BeforeLoadDFMValues;
  dsBeleg2perEmail.BeforeLoadDFMValues;
  dsBeleg2Ansprechpartner.BeforeLoadDFMValues;
  dsBeleg2KundennummerFremd.BeforeLoadDFMValues;
  dsBeleg2Protokoll.BeforeLoadDFMValues;
  dsBeleg2Verleihauftrag.BeforeLoadDFMValues;
  dsBeleg2VerleihVon.BeforeLoadDFMValues;
  dsBeleg2VerleihBis.BeforeLoadDFMValues;
  dsBeleg2AuftragGenehmigt.BeforeLoadDFMValues;
  dsBeleg2gesperrt.BeforeLoadDFMValues;
  dsBeleg2VerleihvonText.BeforeLoadDFMValues;
  dsBeleg2VerleihbisText.BeforeLoadDFMValues;
  dsBeleg2DruckWHG.BeforeLoadDFMValues;
  dsBeleg2DruckWHGKurs.BeforeLoadDFMValues;
  dsBeleg2ProzentsatzZusatzposition.BeforeLoadDFMValues;
  dsBeleg2TextZusatzposition.BeforeLoadDFMValues;
  dsBeleg2TextzusatzBeschreibung.BeforeLoadDFMValues;
  dsBeleg2Layoutname.BeforeLoadDFMValues;
  dsBeleg2Gesamtgewicht.BeforeLoadDFMValues;
  dsBeleg2BetragZusatzposition.BeforeLoadDFMValues;
  dsBeleg2ReferenzBestellung.BeforeLoadDFMValues;
  dsBeleg2Termin1.BeforeLoadDFMValues;
  dsBeleg2Termin2.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktnummer.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktname.BeforeLoadDFMValues;
  dsBeleg2Bilddatei1.BeforeLoadDFMValues;
  dsBeleg2Bilddatei2.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktnummer2.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktname2.BeforeLoadDFMValues;
  dsBeleg2bestaetigt.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktnummer3.BeforeLoadDFMValues;
  dsBeleg2Zielkontaktname3.BeforeLoadDFMValues;
  dsBeleg2Termin3.BeforeLoadDFMValues;
  dsBeleg2Termin4.BeforeLoadDFMValues;
  dsBeleg2Muster.BeforeLoadDFMValues;
  dsBeleg2KW.BeforeLoadDFMValues;
  dsBeleg2Datenlink.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz4.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz5.BeforeLoadDFMValues;
  dsBeleg2Mehrwertsteuersatz6.BeforeLoadDFMValues;
  dsBeleg2BesteuerungKey.BeforeLoadDFMValues;
  dsBeleg2VertreterMatchcode.BeforeLoadDFMValues;
  dsBeleg2Verfasser.BeforeLoadDFMValues;
  dsBeleg2LieferadresseID.BeforeLoadDFMValues;
  dsBeleg2AuftragStatus.BeforeLoadDFMValues;
  dsBeleg2MonatJahr.BeforeLoadDFMValues;
  dsBeleg2Belegvariante.BeforeLoadDFMValues;
  dsBeleg2Produktionsfreigabe.BeforeLoadDFMValues;
  dsBeleg2ReferenzRahmenAuftrag.BeforeLoadDFMValues;
  dsBeleg2DatumRahmenAuftrag.BeforeLoadDFMValues;
  dsBeleg2FahrzeugID.BeforeLoadDFMValues;
  dsBeleg2AuslieferungKW.BeforeLoadDFMValues;
  dsBeleg2BereitstellungDatum.BeforeLoadDFMValues;
  dsBeleg2VTUID.BeforeLoadDFMValues;
  dsBeleg2LieferanschriftDaten.BeforeLoadDFMValues;
  dsBeleg2GesamtVolumen.BeforeLoadDFMValues;
  dsBeleg2BelegPKey.BeforeLoadDFMValues;
  dsBeleg2DruckParameter.BeforeLoadDFMValues;
  dsBeleg2ReferenzService.BeforeLoadDFMValues;
  dsBeleg2ZusatzDaten.BeforeLoadDFMValues;
  dsBeleg2StandPDF.BeforeLoadDFMValues;
  dsBeleg2Paketdienst.BeforeLoadDFMValues;
  dsBeleg2Monteur.BeforeLoadDFMValues;
  dsBeleg2KundennummerKundennummer.BeforeLoadDFMValues;
  dsBeleg2ServiceBemerkung.BeforeLoadDFMValues;
  try
    Name := 'AuftragPosEinzel';
    Width := 235;
    Height := 511;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 235;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 41;
    Container2.Width := 235;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.Align := alTop;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    WTCPosition.SetParentComponent(Self);
    WTCPosition.Name := 'WTCPosition';
    WTCPosition.Left := 0;
    WTCPosition.Top := 82;
    WTCPosition.Width := 235;
    WTCPosition.Height := 75;
    WTCPosition.ElementClassName := 'table';
    WTCPosition.HeightStyle := ssAuto;
    WTCPosition.WidthStyle := ssPercent;
    WTCPosition.Align := alTop;
    WTCPosition.BorderColor := clSilver;
    WTCPosition.ChildOrder := 2;
    WTCPosition.ColHeader := False;
    WTCPosition.ElementHeaderClassName := 'table-info';
    WTCPosition.ElementPosition := epRelative;
    WTCPosition.ElementTableClassName := 'table table-bordered';
    WTCPosition.ColCount := 2;
    btnPosAbbrechen.SetParentComponent(Self);
    btnPosAbbrechen.Name := 'btnPosAbbrechen';
    btnPosAbbrechen.Left := 0;
    btnPosAbbrechen.Top := 206;
    btnPosAbbrechen.Width := 96;
    btnPosAbbrechen.Height := 25;
    btnPosAbbrechen.Caption := 'Abbrechen';
    btnPosAbbrechen.ChildOrder := 4;
    btnPosAbbrechen.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnPosAbbrechen.ElementFont := efCSS;
    btnPosAbbrechen.HeightStyle := ssAuto;
    btnPosAbbrechen.WidthStyle := ssAuto;
    SetEvent(btnPosAbbrechen, Self, 'OnClick', 'btnPosAbbrechenClick');
    btnPosSpeichern.SetParentComponent(Self);
    btnPosSpeichern.Name := 'btnPosSpeichern';
    btnPosSpeichern.Left := 0;
    btnPosSpeichern.Top := 237;
    btnPosSpeichern.Width := 96;
    btnPosSpeichern.Height := 25;
    btnPosSpeichern.Caption := 'Speichern';
    btnPosSpeichern.ChildOrder := 4;
    btnPosSpeichern.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnPosSpeichern.ElementFont := efCSS;
    btnPosSpeichern.HeightStyle := ssAuto;
    btnPosSpeichern.WidthStyle := ssAuto;
    SetEvent(btnPosSpeichern, Self, 'OnClick', 'btnPosSpeichernClick');
    btnPositionLoeschen.SetParentComponent(Self);
    btnPositionLoeschen.Name := 'btnPositionLoeschen';
    btnPositionLoeschen.Left := 0;
    btnPositionLoeschen.Top := 268;
    btnPositionLoeschen.Width := 96;
    btnPositionLoeschen.Height := 25;
    btnPositionLoeschen.Caption := 'Position l'#246'schen';
    btnPositionLoeschen.ChildOrder := 4;
    btnPositionLoeschen.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnPositionLoeschen.ElementFont := efCSS;
    btnPositionLoeschen.HeightStyle := ssAuto;
    btnPositionLoeschen.WidthStyle := ssAuto;
    SetEvent(btnPositionLoeschen, Self, 'OnClick', 'btnPositionLoeschenClick');
    edtPosNr.SetParentComponent(Self);
    edtPosNr.Name := 'edtPosNr';
    edtPosNr.Left := 8;
    edtPosNr.Top := 299;
    edtPosNr.Width := 121;
    edtPosNr.Height := 22;
    edtPosNr.ChildOrder := 12;
    edtPosNr.EditType := weNumeric;
    edtPosNr.ElementClassName := 'form-control form-control-sm';
    edtPosNr.ElementFont := efCSS;
    edtPosNr.HeightStyle := ssAuto;
    edtPosNr.WidthStyle := ssPercent;
    edtPosNr.WidthPercent := 95;
    edtPosNr.DataField := 'PosNr';
    edtPosNr.DataSource := sourcePosition;
    edtPosUNr.SetParentComponent(Self);
    edtPosUNr.Name := 'edtPosUNr';
    edtPosUNr.Left := 8;
    edtPosUNr.Top := 327;
    edtPosUNr.Width := 121;
    edtPosUNr.Height := 22;
    edtPosUNr.ChildOrder := 12;
    edtPosUNr.EditType := weNumeric;
    edtPosUNr.ElementClassName := 'form-control form-control-sm';
    edtPosUNr.ElementFont := efCSS;
    edtPosUNr.HeightStyle := ssAuto;
    edtPosUNr.WidthStyle := ssPercent;
    edtPosUNr.WidthPercent := 95;
    edtPosUNr.DataField := 'PosUNr';
    edtPosUNr.DataSource := sourcePosition;
    edtArtikelueberschrift.SetParentComponent(Self);
    edtArtikelueberschrift.Name := 'edtArtikelueberschrift';
    edtArtikelueberschrift.Left := 8;
    edtArtikelueberschrift.Top := 355;
    edtArtikelueberschrift.Width := 121;
    edtArtikelueberschrift.Height := 22;
    edtArtikelueberschrift.ChildOrder := 12;
    edtArtikelueberschrift.ElementClassName := 'form-control form-control-sm';
    edtArtikelueberschrift.ElementFont := efCSS;
    edtArtikelueberschrift.HeightStyle := ssAuto;
    edtArtikelueberschrift.WidthStyle := ssPercent;
    edtArtikelueberschrift.WidthPercent := 95;
    edtArtikelueberschrift.DataField := 'Artikelueberschrift';
    edtArtikelueberschrift.DataSource := sourcePosition;
    edtMenge.SetParentComponent(Self);
    edtMenge.Name := 'edtMenge';
    edtMenge.Left := 8;
    edtMenge.Top := 383;
    edtMenge.Width := 121;
    edtMenge.Height := 22;
    edtMenge.ChildOrder := 12;
    edtMenge.EditType := weFloat;
    edtMenge.ElementClassName := 'form-control form-control-sm';
    edtMenge.ElementFont := efCSS;
    edtMenge.HeightStyle := ssAuto;
    edtMenge.WidthStyle := ssPercent;
    edtMenge.WidthPercent := 95;
    edtMenge.DataField := 'Menge';
    edtMenge.DataSource := sourcePosition;
    btnArtikelnummer.SetParentComponent(Self);
    btnArtikelnummer.Name := 'btnArtikelnummer';
    btnArtikelnummer.Left := 8;
    btnArtikelnummer.Top := 411;
    btnArtikelnummer.Width := 96;
    btnArtikelnummer.Height := 25;
    btnArtikelnummer.Caption := 'Artikel w'#228'hlen';
    btnArtikelnummer.ChildOrder := 4;
    btnArtikelnummer.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnArtikelnummer.ElementFont := efCSS;
    btnArtikelnummer.HeightStyle := ssAuto;
    btnArtikelnummer.WidthStyle := ssAuto;
    SetEvent(btnArtikelnummer, Self, 'OnClick', 'btnArtikelnummerClick');
    edtArbeitszeitDatum.SetParentComponent(Self);
    edtArbeitszeitDatum.Name := 'edtArbeitszeitDatum';
    edtArbeitszeitDatum.Left := 139;
    edtArbeitszeitDatum.Top := 299;
    edtArbeitszeitDatum.Width := 88;
    edtArbeitszeitDatum.Height := 22;
    edtArbeitszeitDatum.ElementClassName := 'form-control form-control-sm';
    edtArbeitszeitDatum.HeightStyle := ssAuto;
    edtArbeitszeitDatum.WidthStyle := ssPercent;
    edtArbeitszeitDatum.WidthPercent := 95;
    edtArbeitszeitDatum.BorderStyle := bsSingle;
    edtArbeitszeitDatum.ChildOrder := 11;
    edtArbeitszeitDatum.Color := clWhite;
    edtArbeitszeitDatum.Date := 44129.377583032410000000;
    edtArbeitszeitDatum.ElementFont := efCSS;
    edtArbeitszeitDatum.Role := '';
    edtArbeitszeitDatum.Text := '';
    SetEvent(edtArbeitszeitDatum, Self, 'OnEnter', 'edtArbeitszeitDatumEnter');
    edtArbeitszeitDatum.DataField := 'ArbeitszeitDatum';
    edtArbeitszeitDatum.DataSource := sourcePosition;
    edtArbeitszeitVON.SetParentComponent(Self);
    edtArbeitszeitVON.Name := 'edtArbeitszeitVON';
    edtArbeitszeitVON.Left := 139;
    edtArbeitszeitVON.Top := 327;
    edtArbeitszeitVON.Width := 88;
    edtArbeitszeitVON.Height := 22;
    edtArbeitszeitVON.ElementClassName := 'form-control form-control-sm';
    edtArbeitszeitVON.HeightStyle := ssAuto;
    edtArbeitszeitVON.WidthStyle := ssPercent;
    edtArbeitszeitVON.WidthPercent := 95;
    edtArbeitszeitVON.BorderStyle := bsSingle;
    edtArbeitszeitVON.ChildOrder := 11;
    edtArbeitszeitVON.Color := clWhite;
    edtArbeitszeitVON.Date := 44129.377583032410000000;
    edtArbeitszeitVON.ElementFont := efCSS;
    edtArbeitszeitVON.Kind := dtkTime;
    edtArbeitszeitVON.Role := '';
    edtArbeitszeitVON.Text := '';
    edtArbeitszeitVON.DataField := 'ArbeitszeitVON';
    edtArbeitszeitVON.DataSource := sourcePosition;
    edtArbeitszeitBIS.SetParentComponent(Self);
    edtArbeitszeitBIS.Name := 'edtArbeitszeitBIS';
    edtArbeitszeitBIS.Left := 139;
    edtArbeitszeitBIS.Top := 355;
    edtArbeitszeitBIS.Width := 88;
    edtArbeitszeitBIS.Height := 22;
    edtArbeitszeitBIS.ElementClassName := 'form-control form-control-sm';
    edtArbeitszeitBIS.HeightStyle := ssAuto;
    edtArbeitszeitBIS.WidthStyle := ssPercent;
    edtArbeitszeitBIS.WidthPercent := 95;
    edtArbeitszeitBIS.BorderStyle := bsSingle;
    edtArbeitszeitBIS.ChildOrder := 11;
    edtArbeitszeitBIS.Color := clWhite;
    edtArbeitszeitBIS.Date := 44129.377583032410000000;
    edtArbeitszeitBIS.ElementFont := efCSS;
    edtArbeitszeitBIS.Kind := dtkTime;
    edtArbeitszeitBIS.Role := '';
    edtArbeitszeitBIS.Text := '';
    edtArbeitszeitBIS.DataField := 'ArbeitszeitBIS';
    edtArbeitszeitBIS.DataSource := sourcePosition;
    edtArtikelbeschreibung.SetParentComponent(Self);
    edtArtikelbeschreibung.Name := 'edtArtikelbeschreibung';
    edtArtikelbeschreibung.Left := 144;
    edtArtikelbeschreibung.Top := 384;
    edtArtikelbeschreibung.Width := 185;
    edtArtikelbeschreibung.Height := 200;
    edtArtikelbeschreibung.AutoSize := False;
    edtArtikelbeschreibung.BorderStyle := bsNone;
    edtArtikelbeschreibung.ElementClassName := 'form-control form-control-sm';
    edtArtikelbeschreibung.ElementFont := efCSS;
    edtArtikelbeschreibung.Lines.BeginUpdate;
    try
      edtArtikelbeschreibung.Lines.Clear;
      edtArtikelbeschreibung.Lines.Add('edtArtikelbeschreibung');
    finally
      edtArtikelbeschreibung.Lines.EndUpdate;
    end;
    edtArtikelbeschreibung.SelLength := 0;
    edtArtikelbeschreibung.SelStart := 0;
    edtArtikelbeschreibung.WidthStyle := ssPercent;
    edtArtikelbeschreibung.WidthPercent := 95;
    edtArtikelbeschreibung.DataField := 'Artikelbeschreibung';
    edtArtikelbeschreibung.DataSource := sourcePosition;
    PosComboMonteur.SetParentComponent(Self);
    PosComboMonteur.Name := 'PosComboMonteur';
    PosComboMonteur.Left := 8;
    PosComboMonteur.Top := 442;
    PosComboMonteur.Width := 145;
    PosComboMonteur.Height := 21;
    PosComboMonteur.ElementClassName := 'form-control form-control-sm';
    PosComboMonteur.ElementFont := efCSS;
    PosComboMonteur.HeightStyle := ssAuto;
    PosComboMonteur.Text := 'PosComboMonteur';
    PosComboMonteur.WidthStyle := ssPercent;
    PosComboMonteur.WidthPercent := 95;
    SetEvent(PosComboMonteur, Self, 'OnEnter', 'PosComboMonteurEnter');
    PosComboMonteur.ItemIndex := -1;
    edtMengenart.SetParentComponent(Self);
    edtMengenart.Name := 'edtMengenart';
    edtMengenart.Left := 8;
    edtMengenart.Top := 472;
    edtMengenart.Width := 145;
    edtMengenart.Height := 22;
    edtMengenart.ElementClassName := 'form-control form-control-sm';
    edtMengenart.ElementFont := efCSS;
    edtMengenart.HeightStyle := ssAuto;
    edtMengenart.WidthStyle := ssPercent;
    edtMengenart.WidthPercent := 95;
    edtMengenart.DataField := 'ArtderMenge';
    edtMengenart.DataSource := sourcePosition;
    edtMengenart.KeyField := 'ArtderMenge';
    edtMengenart.ListField := 'ArtderMenge';
    edtMengenart.ListSource := DM2.sourceMengenarten;
    btnNeuePosition.SetParentComponent(Self);
    btnNeuePosition.Name := 'btnNeuePosition';
    btnNeuePosition.Left := 0;
    btnNeuePosition.Top := 175;
    btnNeuePosition.Width := 96;
    btnNeuePosition.Height := 25;
    btnNeuePosition.Caption := '+ NEU';
    btnNeuePosition.ChildOrder := 4;
    btnNeuePosition.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnNeuePosition.ElementFont := efCSS;
    btnNeuePosition.HeightStyle := ssAuto;
    btnNeuePosition.Visible := False;
    btnNeuePosition.WidthStyle := ssAuto;
    SetEvent(btnNeuePosition, Self, 'OnClick', 'btnNeuePositionClick');
    xdsPosition.SetParentComponent(Self);
    xdsPosition.Name := 'xdsPosition';
    xdsPosition.AfterApplyUpdates := xdsPositionAfterApplyUpdates;
    xdsPosition.AfterOpen := xdsPositionAfterOpen;
    xdsPosition.BeforePost := xdsPositionBeforePost;
    xdsPosition.AfterPost := xdsPositionAfterPost;
    xdsPosition.AfterDelete := xdsPositionAfterDelete;
    SetEvent(xdsPosition, Self, 'OnNewRecord', 'xdsPositionNewRecord');
    xdsPosition.EntitySetName := 'PositionenAuftrag';
    xdsPosition.Connection := DM2.XDataWebConnection1;
    xdsPosition.QueryTop := 1;
    xdsPosition.Left := 92;
    xdsPosition.Top := 101;
    xdsPositionID.SetParentComponent(xdsPosition);
    xdsPositionID.Name := 'xdsPositionID';
    xdsPositionID.FieldName := 'ID';
    xdsPositionID.Required := True;
    xdsPositionPosNr.SetParentComponent(xdsPosition);
    xdsPositionPosNr.Name := 'xdsPositionPosNr';
    xdsPositionPosNr.FieldName := 'PosNr';
    xdsPositionPosNr.Required := True;
    xdsPositionPosUNr.SetParentComponent(xdsPosition);
    xdsPositionPosUNr.Name := 'xdsPositionPosUNr';
    xdsPositionPosUNr.FieldName := 'PosUNr';
    xdsPositionArtikelueberschrift.SetParentComponent(xdsPosition);
    xdsPositionArtikelueberschrift.Name := 'xdsPositionArtikelueberschrift';
    xdsPositionArtikelueberschrift.FieldName := 'Artikelueberschrift';
    xdsPositionArtikelueberschrift.Size := 50;
    xdsPositionArtikelbeschreibung.SetParentComponent(xdsPosition);
    xdsPositionArtikelbeschreibung.Name := 'xdsPositionArtikelbeschreibung';
    xdsPositionArtikelbeschreibung.FieldName := 'Artikelbeschreibung';
    xdsPositionArtikelbeschreibung.Size := 255;
    xdsPositionMenge.SetParentComponent(xdsPosition);
    xdsPositionMenge.Name := 'xdsPositionMenge';
    xdsPositionMenge.FieldName := 'Menge';
    xdsPositionArtderMenge.SetParentComponent(xdsPosition);
    xdsPositionArtderMenge.Name := 'xdsPositionArtderMenge';
    xdsPositionArtderMenge.FieldName := 'ArtderMenge';
    xdsPositionArtderMenge.Size := 50;
    xdsPositionVerkaufspreis.SetParentComponent(xdsPosition);
    xdsPositionVerkaufspreis.Name := 'xdsPositionVerkaufspreis';
    xdsPositionVerkaufspreis.FieldName := 'Verkaufspreis';
    xdsPositionEinkaufspreis.SetParentComponent(xdsPosition);
    xdsPositionEinkaufspreis.Name := 'xdsPositionEinkaufspreis';
    xdsPositionEinkaufspreis.FieldName := 'Einkaufspreis';
    xdsPositionArtikelnummer.SetParentComponent(xdsPosition);
    xdsPositionArtikelnummer.Name := 'xdsPositionArtikelnummer';
    xdsPositionArtikelnummer.FieldName := 'Artikelnummer';
    xdsPositionMonteurP.SetParentComponent(xdsPosition);
    xdsPositionMonteurP.Name := 'xdsPositionMonteurP';
    xdsPositionMonteurP.FieldName := 'MonteurP';
    xdsPositionArbeitszeitDatum.SetParentComponent(xdsPosition);
    xdsPositionArbeitszeitDatum.Name := 'xdsPositionArbeitszeitDatum';
    xdsPositionArbeitszeitDatum.FieldName := 'ArbeitszeitDatum';
    xdsPositionArbeitszeitVON.SetParentComponent(xdsPosition);
    xdsPositionArbeitszeitVON.Name := 'xdsPositionArbeitszeitVON';
    xdsPositionArbeitszeitVON.FieldName := 'ArbeitszeitVON';
    xdsPositionArbeitszeitVON.DisplayFormat := 'hh:mm';
    xdsPositionArbeitszeitBIS.SetParentComponent(xdsPosition);
    xdsPositionArbeitszeitBIS.Name := 'xdsPositionArbeitszeitBIS';
    xdsPositionArbeitszeitBIS.FieldName := 'ArbeitszeitBIS';
    xdsPositionArbeitszeitBIS.DisplayFormat := 'hh:mm';
    xdsPositionSumme.SetParentComponent(xdsPosition);
    xdsPositionSumme.Name := 'xdsPositionSumme';
    xdsPositionSumme.FieldName := 'Summe';
    xdsPositionSeriennummer.SetParentComponent(xdsPosition);
    xdsPositionSeriennummer.Name := 'xdsPositionSeriennummer';
    xdsPositionSeriennummer.FieldName := 'Seriennummer';
    xdsPositionSeriennummer.Size := 30;
    xdsPositionWarengruppe.SetParentComponent(xdsPosition);
    xdsPositionWarengruppe.Name := 'xdsPositionWarengruppe';
    xdsPositionWarengruppe.FieldName := 'Warengruppe';
    xdsPositionWarengruppe.Size := 50;
    xdsPositionWarenuntergruppe.SetParentComponent(xdsPosition);
    xdsPositionWarenuntergruppe.Name := 'xdsPositionWarenuntergruppe';
    xdsPositionWarenuntergruppe.FieldName := 'Warenuntergruppe';
    xdsPositionWarenuntergruppe.Size := 50;
    xdsPositionArtikelnummerKunde.SetParentComponent(xdsPosition);
    xdsPositionArtikelnummerKunde.Name := 'xdsPositionArtikelnummerKunde';
    xdsPositionArtikelnummerKunde.FieldName := 'ArtikelnummerKunde';
    xdsPositionLieferant.SetParentComponent(xdsPosition);
    xdsPositionLieferant.Name := 'xdsPositionLieferant';
    xdsPositionLieferant.FieldName := 'Lieferant';
    xdsPositionLieferant.Size := 13;
    xdsPositionGewicht.SetParentComponent(xdsPosition);
    xdsPositionGewicht.Name := 'xdsPositionGewicht';
    xdsPositionGewicht.FieldName := 'Gewicht';
    xdsPositionLaenge.SetParentComponent(xdsPosition);
    xdsPositionLaenge.Name := 'xdsPositionLaenge';
    xdsPositionLaenge.FieldName := 'Laenge';
    xdsPositionBreite.SetParentComponent(xdsPosition);
    xdsPositionBreite.Name := 'xdsPositionBreite';
    xdsPositionBreite.FieldName := 'Breite';
    xdsPositionHoehe.SetParentComponent(xdsPosition);
    xdsPositionHoehe.Name := 'xdsPositionHoehe';
    xdsPositionHoehe.FieldName := 'Hoehe';
    xdsPositionMehrwertsteuersatz.SetParentComponent(xdsPosition);
    xdsPositionMehrwertsteuersatz.Name := 'xdsPositionMehrwertsteuersatz';
    xdsPositionMehrwertsteuersatz.FieldName := 'Mehrwertsteuersatz';
    xdsPositionEndpreis.SetParentComponent(xdsPosition);
    xdsPositionEndpreis.Name := 'xdsPositionEndpreis';
    xdsPositionEndpreis.FieldName := 'Endpreis';
    xdsPositionPosMWST.SetParentComponent(xdsPosition);
    xdsPositionPosMWST.Name := 'xdsPositionPosMWST';
    xdsPositionPosMWST.FieldName := 'PosMWST';
    xdsPositionBruttoEndpreis.SetParentComponent(xdsPosition);
    xdsPositionBruttoEndpreis.Name := 'xdsPositionBruttoEndpreis';
    xdsPositionBruttoEndpreis.FieldName := 'BruttoEndpreis';
    xdsPositionEndpreis_EK.SetParentComponent(xdsPosition);
    xdsPositionEndpreis_EK.Name := 'xdsPositionEndpreis_EK';
    xdsPositionEndpreis_EK.FieldName := 'Endpreis_EK';
    xdsPositionRabattsatz.SetParentComponent(xdsPosition);
    xdsPositionRabattsatz.Name := 'xdsPositionRabattsatz';
    xdsPositionRabattsatz.FieldName := 'Rabattsatz';
    xdsPositionPreisvorRabatt.SetParentComponent(xdsPosition);
    xdsPositionPreisvorRabatt.Name := 'xdsPositionPreisvorRabatt';
    xdsPositionPreisvorRabatt.FieldName := 'PreisvorRabatt';
    xdsPositionPKZ.SetParentComponent(xdsPosition);
    xdsPositionPKZ.Name := 'xdsPositionPKZ';
    xdsPositionPKZ.FieldName := 'PKZ';
    xdsPositionTextfrei10.SetParentComponent(xdsPosition);
    xdsPositionTextfrei10.Name := 'xdsPositionTextfrei10';
    xdsPositionTextfrei10.FieldName := 'Textfrei10';
    xdsPositionTextfrei10.Size := 50;
    xdsPositionTextfrei20.SetParentComponent(xdsPosition);
    xdsPositionTextfrei20.Name := 'xdsPositionTextfrei20';
    xdsPositionTextfrei20.FieldName := 'Textfrei20';
    xdsPositionTextfrei20.Size := 50;
    xdsPositionProduktionAbgeschlossen.SetParentComponent(xdsPosition);
    xdsPositionProduktionAbgeschlossen.Name := 'xdsPositionProduktionAbgeschlossen';
    xdsPositionProduktionAbgeschlossen.FieldName := 'ProduktionAbgeschlossen';
    xdsPositionEinzelpreisBrutto.SetParentComponent(xdsPosition);
    xdsPositionEinzelpreisBrutto.Name := 'xdsPositionEinzelpreisBrutto';
    xdsPositionEinzelpreisBrutto.FieldName := 'EinzelpreisBrutto';
    xdsPositionPosMarge.SetParentComponent(xdsPosition);
    xdsPositionPosMarge.Name := 'xdsPositionPosMarge';
    xdsPositionPosMarge.FieldName := 'PosMarge';
    xdsPositionPosMargeProzent.SetParentComponent(xdsPosition);
    xdsPositionPosMargeProzent.Name := 'xdsPositionPosMargeProzent';
    xdsPositionPosMargeProzent.FieldName := 'PosMargeProzent';
    xdsPositionBereitsGeliefert.SetParentComponent(xdsPosition);
    xdsPositionBereitsGeliefert.Name := 'xdsPositionBereitsGeliefert';
    xdsPositionBereitsGeliefert.FieldName := 'BereitsGeliefert';
    xdsPositionausliefern.SetParentComponent(xdsPosition);
    xdsPositionausliefern.Name := 'xdsPositionausliefern';
    xdsPositionausliefern.FieldName := 'ausliefern';
    xdsPositionBestellungErledigt.SetParentComponent(xdsPosition);
    xdsPositionBestellungErledigt.Name := 'xdsPositionBestellungErledigt';
    xdsPositionBestellungErledigt.FieldName := 'BestellungErledigt';
    xdsPositionLager.SetParentComponent(xdsPosition);
    xdsPositionLager.Name := 'xdsPositionLager';
    xdsPositionLager.FieldName := 'Lager';
    xdsPositionLager.Size := 50;
    xdsPositionbestellen.SetParentComponent(xdsPosition);
    xdsPositionbestellen.Name := 'xdsPositionbestellen';
    xdsPositionbestellen.FieldName := 'bestellen';
    xdsPositionWarenbestand.SetParentComponent(xdsPosition);
    xdsPositionWarenbestand.Name := 'xdsPositionWarenbestand';
    xdsPositionWarenbestand.FieldName := 'Warenbestand';
    xdsPositionArtikelbeschreibungRTF.SetParentComponent(xdsPosition);
    xdsPositionArtikelbeschreibungRTF.Name := 'xdsPositionArtikelbeschreibungRTF';
    xdsPositionArtikelbeschreibungRTF.FieldName := 'ArtikelbeschreibungRTF';
    xdsPositionArtikelbeschreibungRTF.Size := 255;
    xdsPositionQuellartikel.SetParentComponent(xdsPosition);
    xdsPositionQuellartikel.Name := 'xdsPositionQuellartikel';
    xdsPositionQuellartikel.FieldName := 'Quellartikel';
    xdsPositionQuellartikel.Size := 25;
    xdsPositionArtikelnummerWB.SetParentComponent(xdsPosition);
    xdsPositionArtikelnummerWB.Name := 'xdsPositionArtikelnummerWB';
    xdsPositionArtikelnummerWB.FieldName := 'ArtikelnummerWB';
    xdsPositionArtikelnummerWB.Size := 25;
    xdsPositionPreisanteilNetto.SetParentComponent(xdsPosition);
    xdsPositionPreisanteilNetto.Name := 'xdsPositionPreisanteilNetto';
    xdsPositionPreisanteilNetto.FieldName := 'PreisanteilNetto';
    xdsPositionSetHPos.SetParentComponent(xdsPosition);
    xdsPositionSetHPos.Name := 'xdsPositionSetHPos';
    xdsPositionSetHPos.FieldName := 'SetHPos';
    xdsPositionSetHPos.Size := 38;
    xdsPositionSetUPos.SetParentComponent(xdsPosition);
    xdsPositionSetUPos.Name := 'xdsPositionSetUPos';
    xdsPositionSetUPos.FieldName := 'SetUPos';
    xdsPositionSetUPos.Size := 38;
    xdsPositionProvisionssatz.SetParentComponent(xdsPosition);
    xdsPositionProvisionssatz.Name := 'xdsPositionProvisionssatz';
    xdsPositionProvisionssatz.FieldName := 'Provisionssatz';
    xdsPositionProvisionsbetrag.SetParentComponent(xdsPosition);
    xdsPositionProvisionsbetrag.Name := 'xdsPositionProvisionsbetrag';
    xdsPositionProvisionsbetrag.FieldName := 'Provisionsbetrag';
    xdsPositionArtikelsprache.SetParentComponent(xdsPosition);
    xdsPositionArtikelsprache.Name := 'xdsPositionArtikelsprache';
    xdsPositionArtikelsprache.FieldName := 'Artikelsprache';
    xdsPositionArtikelsprache.Size := 255;
    xdsPositionEKvom.SetParentComponent(xdsPosition);
    xdsPositionEKvom.Name := 'xdsPositionEKvom';
    xdsPositionEKvom.FieldName := 'EKvom';
    xdsPositionMengenberechnungID.SetParentComponent(xdsPosition);
    xdsPositionMengenberechnungID.Name := 'xdsPositionMengenberechnungID';
    xdsPositionMengenberechnungID.FieldName := 'MengenberechnungID';
    xdsPositionMengenberechnungID.Size := 38;
    xdsPositionTaraID.SetParentComponent(xdsPosition);
    xdsPositionTaraID.Name := 'xdsPositionTaraID';
    xdsPositionTaraID.FieldName := 'TaraID';
    xdsPositionTaraID.Size := 38;
    xdsPositionTara.SetParentComponent(xdsPosition);
    xdsPositionTara.Name := 'xdsPositionTara';
    xdsPositionTara.FieldName := 'Tara';
    xdsPositionBruttoGewicht.SetParentComponent(xdsPosition);
    xdsPositionBruttoGewicht.Name := 'xdsPositionBruttoGewicht';
    xdsPositionBruttoGewicht.FieldName := 'BruttoGewicht';
    xdsPositionAussenflaeche.SetParentComponent(xdsPosition);
    xdsPositionAussenflaeche.Name := 'xdsPositionAussenflaeche';
    xdsPositionAussenflaeche.FieldName := 'Aussenflaeche';
    xdsPositionLadeort.SetParentComponent(xdsPosition);
    xdsPositionLadeort.Name := 'xdsPositionLadeort';
    xdsPositionLadeort.FieldName := 'Ladeort';
    xdsPositionLadeort.Size := 50;
    xdsPositionSeitenumbruch.SetParentComponent(xdsPosition);
    xdsPositionSeitenumbruch.Name := 'xdsPositionSeitenumbruch';
    xdsPositionSeitenumbruch.FieldName := 'Seitenumbruch';
    xdsPositionPlanerFarbe.SetParentComponent(xdsPosition);
    xdsPositionPlanerFarbe.Name := 'xdsPositionPlanerFarbe';
    xdsPositionPlanerFarbe.FieldName := 'PlanerFarbe';
    xdsPositionMWStKey.SetParentComponent(xdsPosition);
    xdsPositionMWStKey.Name := 'xdsPositionMWStKey';
    xdsPositionMWStKey.FieldName := 'MWStKey';
    xdsPositionZSnachPos.SetParentComponent(xdsPosition);
    xdsPositionZSnachPos.Name := 'xdsPositionZSnachPos';
    xdsPositionZSnachPos.FieldName := 'ZSnachPos';
    xdsPositionLieferzeit.SetParentComponent(xdsPosition);
    xdsPositionLieferzeit.Name := 'xdsPositionLieferzeit';
    xdsPositionLieferzeit.FieldName := 'Lieferzeit';
    xdsPositionLieferzeit.Size := 50;
    xdsPositionPreisVorRabattWHG.SetParentComponent(xdsPosition);
    xdsPositionPreisVorRabattWHG.Name := 'xdsPositionPreisVorRabattWHG';
    xdsPositionPreisVorRabattWHG.FieldName := 'PreisVorRabattWHG';
    xdsPositionReferenzKalkulation.SetParentComponent(xdsPosition);
    xdsPositionReferenzKalkulation.Name := 'xdsPositionReferenzKalkulation';
    xdsPositionReferenzKalkulation.FieldName := 'ReferenzKalkulation';
    xdsPositionReferenzKalkulation.Size := 10;
    xdsPositionPKey.SetParentComponent(xdsPosition);
    xdsPositionPKey.Name := 'xdsPositionPKey';
    xdsPositionPKey.FieldName := 'PKey';
    xdsPositionAuftragsnummer.SetParentComponent(xdsPosition);
    xdsPositionAuftragsnummer.Name := 'xdsPositionAuftragsnummer';
    xdsPositionAuftragsnummer.FieldName := 'Auftragsnummer';
    xdsPositionlwbID.SetParentComponent(xdsPosition);
    xdsPositionlwbID.Name := 'xdsPositionlwbID';
    xdsPositionlwbID.FieldName := 'lwbID';
    xdsPositionlwbID.Size := 38;
    xdsPositionBelegdatum.SetParentComponent(xdsPosition);
    xdsPositionBelegdatum.Name := 'xdsPositionBelegdatum';
    xdsPositionBelegdatum.FieldName := 'Belegdatum';
    xdsPositionWarenkontrollnummern.SetParentComponent(xdsPosition);
    xdsPositionWarenkontrollnummern.Name := 'xdsPositionWarenkontrollnummern';
    xdsPositionWarenkontrollnummern.FieldName := 'Warenkontrollnummern';
    xdsPositionWarenkontrollnummern.Size := 255;
    xdsPositionVolumen.SetParentComponent(xdsPosition);
    xdsPositionVolumen.Name := 'xdsPositionVolumen';
    xdsPositionVolumen.FieldName := 'Volumen';
    xdsPositionPosVolumen.SetParentComponent(xdsPosition);
    xdsPositionPosVolumen.Name := 'xdsPositionPosVolumen';
    xdsPositionPosVolumen.FieldName := 'PosVolumen';
    xdsPositionArtikelAttribute.SetParentComponent(xdsPosition);
    xdsPositionArtikelAttribute.Name := 'xdsPositionArtikelAttribute';
    xdsPositionArtikelAttribute.FieldName := 'ArtikelAttribute';
    xdsPositionArtikelAttribute.Size := 255;
    xdsPositionNummerkomplett.SetParentComponent(xdsPosition);
    xdsPositionNummerkomplett.Name := 'xdsPositionNummerkomplett';
    xdsPositionNummerkomplett.FieldName := 'Nummerkomplett';
    sourcePosition.SetParentComponent(Self);
    sourcePosition.Name := 'sourcePosition';
    sourcePosition.DataSet := xdsPosition;
    SetEvent(sourcePosition, Self, 'OnStateChange', 'sourcePositionStateChange');
    sourcePosition.Left := 29;
    sourcePosition.Top := 101;
    dsArtikel.SetParentComponent(Self);
    dsArtikel.Name := 'dsArtikel';
    dsArtikel.AfterOpen := dsArtikelAfterOpen;
    dsArtikel.EntitySetName := 'Artikel';
    dsArtikel.Connection := DM2.XDataWebConnection1;
    dsArtikel.SubpropsDepth := 1;
    dsArtikel.QueryTop := 1;
    dsArtikel.Left := 156;
    dsArtikel.Top := 104;
    dsArtikelHersteller.SetParentComponent(dsArtikel);
    dsArtikelHersteller.Name := 'dsArtikelHersteller';
    dsArtikelHersteller.FieldName := 'Hersteller';
    dsArtikelHersteller.Required := True;
    dsArtikelArtderMenge.SetParentComponent(dsArtikel);
    dsArtikelArtderMenge.Name := 'dsArtikelArtderMenge';
    dsArtikelArtderMenge.FieldName := 'ArtderMenge';
    dsArtikelArtderMenge.Required := True;
    dsArtikelPfandArt.SetParentComponent(dsArtikel);
    dsArtikelPfandArt.Name := 'dsArtikelPfandArt';
    dsArtikelPfandArt.FieldName := 'PfandArt';
    dsArtikelWarengruppe.SetParentComponent(dsArtikel);
    dsArtikelWarengruppe.Name := 'dsArtikelWarengruppe';
    dsArtikelWarengruppe.FieldName := 'Warengruppe';
    dsArtikelWarenuntergruppe.SetParentComponent(dsArtikel);
    dsArtikelWarenuntergruppe.Name := 'dsArtikelWarenuntergruppe';
    dsArtikelWarenuntergruppe.FieldName := 'Warenuntergruppe';
    dsArtikelArtikelnummer.SetParentComponent(dsArtikel);
    dsArtikelArtikelnummer.Name := 'dsArtikelArtikelnummer';
    dsArtikelArtikelnummer.FieldName := 'Artikelnummer';
    dsArtikelArtikelnummer.Required := True;
    dsArtikelArtikelnummer.Size := 25;
    dsArtikelArtikelueberschrift.SetParentComponent(dsArtikel);
    dsArtikelArtikelueberschrift.Name := 'dsArtikelArtikelueberschrift';
    dsArtikelArtikelueberschrift.FieldName := 'Artikelueberschrift';
    dsArtikelArtikelueberschrift.Size := 50;
    dsArtikelArtikelbeschreibung.SetParentComponent(dsArtikel);
    dsArtikelArtikelbeschreibung.Name := 'dsArtikelArtikelbeschreibung';
    dsArtikelArtikelbeschreibung.FieldName := 'Artikelbeschreibung';
    dsArtikelArtikelbeschreibung.Size := 255;
    dsArtikelVerkaufspreis.SetParentComponent(dsArtikel);
    dsArtikelVerkaufspreis.Name := 'dsArtikelVerkaufspreis';
    dsArtikelVerkaufspreis.FieldName := 'Verkaufspreis';
    dsArtikelMindestbestand.SetParentComponent(dsArtikel);
    dsArtikelMindestbestand.Name := 'dsArtikelMindestbestand';
    dsArtikelMindestbestand.FieldName := 'Mindestbestand';
    dsArtikelPreisliste.SetParentComponent(dsArtikel);
    dsArtikelPreisliste.Name := 'dsArtikelPreisliste';
    dsArtikelPreisliste.FieldName := 'Preisliste';
    dsArtikelPreisliste.Size := 150;
    dsArtikelGewicht.SetParentComponent(dsArtikel);
    dsArtikelGewicht.Name := 'dsArtikelGewicht';
    dsArtikelGewicht.FieldName := 'Gewicht';
    dsArtikelLaenge.SetParentComponent(dsArtikel);
    dsArtikelLaenge.Name := 'dsArtikelLaenge';
    dsArtikelLaenge.FieldName := 'Laenge';
    dsArtikelBreite.SetParentComponent(dsArtikel);
    dsArtikelBreite.Name := 'dsArtikelBreite';
    dsArtikelBreite.FieldName := 'Breite';
    dsArtikelHoehe.SetParentComponent(dsArtikel);
    dsArtikelHoehe.Name := 'dsArtikelHoehe';
    dsArtikelHoehe.FieldName := 'Hoehe';
    dsArtikelArtikelZusatz.SetParentComponent(dsArtikel);
    dsArtikelArtikelZusatz.Name := 'dsArtikelArtikelZusatz';
    dsArtikelArtikelZusatz.FieldName := 'ArtikelZusatz';
    dsArtikelArtikelZusatz.Size := 255;
    dsArtikelLagerort.SetParentComponent(dsArtikel);
    dsArtikelLagerort.Name := 'dsArtikelLagerort';
    dsArtikelLagerort.FieldName := 'Lagerort';
    dsArtikelLagerort.Size := 50;
    dsArtikelBuchungskonto.SetParentComponent(dsArtikel);
    dsArtikelBuchungskonto.Name := 'dsArtikelBuchungskonto';
    dsArtikelBuchungskonto.FieldName := 'Buchungskonto';
    dsArtikelEingangMenge.SetParentComponent(dsArtikel);
    dsArtikelEingangMenge.Name := 'dsArtikelEingangMenge';
    dsArtikelEingangMenge.FieldName := 'EingangMenge';
    dsArtikelEingangEndpreis.SetParentComponent(dsArtikel);
    dsArtikelEingangEndpreis.Name := 'dsArtikelEingangEndpreis';
    dsArtikelEingangEndpreis.FieldName := 'EingangEndpreis';
    dsArtikelAusgangMenge.SetParentComponent(dsArtikel);
    dsArtikelAusgangMenge.Name := 'dsArtikelAusgangMenge';
    dsArtikelAusgangMenge.FieldName := 'AusgangMenge';
    dsArtikelAusgangEndpreisEK.SetParentComponent(dsArtikel);
    dsArtikelAusgangEndpreisEK.Name := 'dsArtikelAusgangEndpreisEK';
    dsArtikelAusgangEndpreisEK.FieldName := 'AusgangEndpreisEK';
    dsArtikelAusgangEndpreisVK.SetParentComponent(dsArtikel);
    dsArtikelAusgangEndpreisVK.Name := 'dsArtikelAusgangEndpreisVK';
    dsArtikelAusgangEndpreisVK.FieldName := 'AusgangEndpreisVK';
    dsArtikelBestand.SetParentComponent(dsArtikel);
    dsArtikelBestand.Name := 'dsArtikelBestand';
    dsArtikelBestand.FieldName := 'Bestand';
    dsArtikelBestandswert.SetParentComponent(dsArtikel);
    dsArtikelBestandswert.Name := 'dsArtikelBestandswert';
    dsArtikelBestandswert.FieldName := 'Bestandswert';
    dsArtikelMarge.SetParentComponent(dsArtikel);
    dsArtikelMarge.Name := 'dsArtikelMarge';
    dsArtikelMarge.FieldName := 'Marge';
    dsArtikelMargeProzent.SetParentComponent(dsArtikel);
    dsArtikelMargeProzent.Name := 'dsArtikelMargeProzent';
    dsArtikelMargeProzent.FieldName := 'MargeProzent';
    dsArtikelArtikelnummer2.SetParentComponent(dsArtikel);
    dsArtikelArtikelnummer2.Name := 'dsArtikelArtikelnummer2';
    dsArtikelArtikelnummer2.FieldName := 'Artikelnummer2';
    dsArtikelArtikelnummer2.Size := 25;
    dsArtikelArtikelmatchcode.SetParentComponent(dsArtikel);
    dsArtikelArtikelmatchcode.Name := 'dsArtikelArtikelmatchcode';
    dsArtikelArtikelmatchcode.FieldName := 'Artikelmatchcode';
    dsArtikelArtikelmatchcode.Size := 30;
    dsArtikelRabattpreis1.SetParentComponent(dsArtikel);
    dsArtikelRabattpreis1.Name := 'dsArtikelRabattpreis1';
    dsArtikelRabattpreis1.FieldName := 'Rabattpreis1';
    dsArtikelRabattpreis2.SetParentComponent(dsArtikel);
    dsArtikelRabattpreis2.Name := 'dsArtikelRabattpreis2';
    dsArtikelRabattpreis2.FieldName := 'Rabattpreis2';
    dsArtikelRabattpreis3.SetParentComponent(dsArtikel);
    dsArtikelRabattpreis3.Name := 'dsArtikelRabattpreis3';
    dsArtikelRabattpreis3.FieldName := 'Rabattpreis3';
    dsArtikelRabattpreis4.SetParentComponent(dsArtikel);
    dsArtikelRabattpreis4.Name := 'dsArtikelRabattpreis4';
    dsArtikelRabattpreis4.FieldName := 'Rabattpreis4';
    dsArtikelRabattpreis5.SetParentComponent(dsArtikel);
    dsArtikelRabattpreis5.Name := 'dsArtikelRabattpreis5';
    dsArtikelRabattpreis5.FieldName := 'Rabattpreis5';
    dsArtikelArtikelnichtmehrgueltig.SetParentComponent(dsArtikel);
    dsArtikelArtikelnichtmehrgueltig.Name := 'dsArtikelArtikelnichtmehrgueltig';
    dsArtikelArtikelnichtmehrgueltig.FieldName := 'Artikelnichtmehrgueltig';
    dsArtikelEinkaufspreis.SetParentComponent(dsArtikel);
    dsArtikelEinkaufspreis.Name := 'dsArtikelEinkaufspreis';
    dsArtikelEinkaufspreis.FieldName := 'Einkaufspreis';
    dsArtikelAufschlag.SetParentComponent(dsArtikel);
    dsArtikelAufschlag.Name := 'dsArtikelAufschlag';
    dsArtikelAufschlag.FieldName := 'Aufschlag';
    dsArtikelWarenbestand.SetParentComponent(dsArtikel);
    dsArtikelWarenbestand.Name := 'dsArtikelWarenbestand';
    dsArtikelWarenbestand.FieldName := 'Warenbestand';
    dsArtikelTextfrei10.SetParentComponent(dsArtikel);
    dsArtikelTextfrei10.Name := 'dsArtikelTextfrei10';
    dsArtikelTextfrei10.FieldName := 'Textfrei10';
    dsArtikelTextfrei10.Size := 50;
    dsArtikelTextfrei20.SetParentComponent(dsArtikel);
    dsArtikelTextfrei20.Name := 'dsArtikelTextfrei20';
    dsArtikelTextfrei20.FieldName := 'Textfrei20';
    dsArtikelTextfrei20.Size := 50;
    dsArtikelArtikelbeschreibungRTF.SetParentComponent(dsArtikel);
    dsArtikelArtikelbeschreibungRTF.Name := 'dsArtikelArtikelbeschreibungRTF';
    dsArtikelArtikelbeschreibungRTF.FieldName := 'ArtikelbeschreibungRTF';
    dsArtikelArtikelbeschreibungRTF.Size := 255;
    dsArtikelArtikeldatei1.SetParentComponent(dsArtikel);
    dsArtikelArtikeldatei1.Name := 'dsArtikelArtikeldatei1';
    dsArtikelArtikeldatei1.FieldName := 'Artikeldatei1';
    dsArtikelArtikeldatei1.Size := 255;
    dsArtikelArtikeldatei2.SetParentComponent(dsArtikel);
    dsArtikelArtikeldatei2.Name := 'dsArtikelArtikeldatei2';
    dsArtikelArtikeldatei2.FieldName := 'Artikeldatei2';
    dsArtikelArtikeldatei2.Size := 255;
    dsArtikelArtikeldatei3.SetParentComponent(dsArtikel);
    dsArtikelArtikeldatei3.Name := 'dsArtikelArtikeldatei3';
    dsArtikelArtikeldatei3.FieldName := 'Artikeldatei3';
    dsArtikelArtikeldatei3.Size := 255;
    dsArtikelArtikeldatei4.SetParentComponent(dsArtikel);
    dsArtikelArtikeldatei4.Name := 'dsArtikelArtikeldatei4';
    dsArtikelArtikeldatei4.FieldName := 'Artikeldatei4';
    dsArtikelArtikeldatei4.Size := 255;
    dsArtikelArtikeldatei5.SetParentComponent(dsArtikel);
    dsArtikelArtikeldatei5.Name := 'dsArtikelArtikeldatei5';
    dsArtikelArtikeldatei5.FieldName := 'Artikeldatei5';
    dsArtikelArtikeldatei5.Size := 255;
    dsArtikelEinkaufspreisVom.SetParentComponent(dsArtikel);
    dsArtikelEinkaufspreisVom.Name := 'dsArtikelEinkaufspreisVom';
    dsArtikelEinkaufspreisVom.FieldName := 'EinkaufspreisVom';
    dsArtikelEAN.SetParentComponent(dsArtikel);
    dsArtikelEAN.Name := 'dsArtikelEAN';
    dsArtikelEAN.FieldName := 'EAN';
    dsArtikelEAN.Size := 15;
    dsArtikelVGPFaktor.SetParentComponent(dsArtikel);
    dsArtikelVGPFaktor.Name := 'dsArtikelVGPFaktor';
    dsArtikelVGPFaktor.FieldName := 'VGPFaktor';
    dsArtikelVGPMengenart.SetParentComponent(dsArtikel);
    dsArtikelVGPMengenart.Name := 'dsArtikelVGPMengenart';
    dsArtikelVGPMengenart.FieldName := 'VGPMengenart';
    dsArtikelVGPMengenart.Size := 50;
    dsArtikelVerleihpreis.SetParentComponent(dsArtikel);
    dsArtikelVerleihpreis.Name := 'dsArtikelVerleihpreis';
    dsArtikelVerleihpreis.FieldName := 'Verleihpreis';
    dsArtikelZusatzPreis.SetParentComponent(dsArtikel);
    dsArtikelZusatzPreis.Name := 'dsArtikelZusatzPreis';
    dsArtikelZusatzPreis.FieldName := 'ZusatzPreis';
    dsArtikelVerpackungsart.SetParentComponent(dsArtikel);
    dsArtikelVerpackungsart.Name := 'dsArtikelVerpackungsart';
    dsArtikelVerpackungsart.FieldName := 'Verpackungsart';
    dsArtikelVerpackungsart.Size := 50;
    dsArtikelVerpackungsMenge.SetParentComponent(dsArtikel);
    dsArtikelVerpackungsMenge.Name := 'dsArtikelVerpackungsMenge';
    dsArtikelVerpackungsMenge.FieldName := 'VerpackungsMenge';
    dsArtikelQuellartikel.SetParentComponent(dsArtikel);
    dsArtikelQuellartikel.Name := 'dsArtikelQuellartikel';
    dsArtikelQuellartikel.FieldName := 'Quellartikel';
    dsArtikelQuellartikel.Size := 25;
    dsArtikelrabattierfaehig.SetParentComponent(dsArtikel);
    dsArtikelrabattierfaehig.Name := 'dsArtikelrabattierfaehig';
    dsArtikelrabattierfaehig.FieldName := 'rabattierfaehig';
    dsArtikelAngebotAlternativeAN.SetParentComponent(dsArtikel);
    dsArtikelAngebotAlternativeAN.Name := 'dsArtikelAngebotAlternativeAN';
    dsArtikelAngebotAlternativeAN.FieldName := 'AngebotAlternativeAN';
    dsArtikelAngebotAlternativeAN.Size := 25;
    dsArtikelPreisanteilNetto.SetParentComponent(dsArtikel);
    dsArtikelPreisanteilNetto.Name := 'dsArtikelPreisanteilNetto';
    dsArtikelPreisanteilNetto.FieldName := 'PreisanteilNetto';
    dsArtikelFarbe.SetParentComponent(dsArtikel);
    dsArtikelFarbe.Name := 'dsArtikelFarbe';
    dsArtikelFarbe.FieldName := 'Farbe';
    dsArtikelAEinkauf.SetParentComponent(dsArtikel);
    dsArtikelAEinkauf.Name := 'dsArtikelAEinkauf';
    dsArtikelAEinkauf.FieldName := 'AEinkauf';
    dsArtikelAVerkauf.SetParentComponent(dsArtikel);
    dsArtikelAVerkauf.Name := 'dsArtikelAVerkauf';
    dsArtikelAVerkauf.FieldName := 'AVerkauf';
    dsArtikelArtikelnummerLieferant.SetParentComponent(dsArtikel);
    dsArtikelArtikelnummerLieferant.Name := 'dsArtikelArtikelnummerLieferant';
    dsArtikelArtikelnummerLieferant.FieldName := 'ArtikelnummerLieferant';
    dsArtikelLieferantnummer.SetParentComponent(dsArtikel);
    dsArtikelLieferantnummer.Name := 'dsArtikelLieferantnummer';
    dsArtikelLieferantnummer.FieldName := 'Lieferantnummer';
    dsArtikelLieferantnummer.Size := 13;
    dsArtikelVerkaufspreisBrutto.SetParentComponent(dsArtikel);
    dsArtikelVerkaufspreisBrutto.Name := 'dsArtikelVerkaufspreisBrutto';
    dsArtikelVerkaufspreisBrutto.FieldName := 'VerkaufspreisBrutto';
    dsArtikelArtikelSet.SetParentComponent(dsArtikel);
    dsArtikelArtikelSet.Name := 'dsArtikelArtikelSet';
    dsArtikelArtikelSet.FieldName := 'ArtikelSet';
    dsArtikelProvisionssatz.SetParentComponent(dsArtikel);
    dsArtikelProvisionssatz.Name := 'dsArtikelProvisionssatz';
    dsArtikelProvisionssatz.FieldName := 'Provisionssatz';
    dsArtikelWarentarifnummer.SetParentComponent(dsArtikel);
    dsArtikelWarentarifnummer.Name := 'dsArtikelWarentarifnummer';
    dsArtikelWarentarifnummer.FieldName := 'Warentarifnummer';
    dsArtikelHandelsartikel.SetParentComponent(dsArtikel);
    dsArtikelHandelsartikel.Name := 'dsArtikelHandelsartikel';
    dsArtikelHandelsartikel.FieldName := 'Handelsartikel';
    dsArtikelUrsprungsland.SetParentComponent(dsArtikel);
    dsArtikelUrsprungsland.Name := 'dsArtikelUrsprungsland';
    dsArtikelUrsprungsland.FieldName := 'Ursprungsland';
    dsArtikelUrsprungsland.Size := 5;
    dsArtikelKategorie.SetParentComponent(dsArtikel);
    dsArtikelKategorie.Name := 'dsArtikelKategorie';
    dsArtikelKategorie.FieldName := 'Kategorie';
    dsArtikelKategorie.Size := 25;
    dsArtikelStandardRabattsatzEK.SetParentComponent(dsArtikel);
    dsArtikelStandardRabattsatzEK.Name := 'dsArtikelStandardRabattsatzEK';
    dsArtikelStandardRabattsatzEK.FieldName := 'StandardRabattsatzEK';
    dsArtikelStandardRabattsatzVK.SetParentComponent(dsArtikel);
    dsArtikelStandardRabattsatzVK.Name := 'dsArtikelStandardRabattsatzVK';
    dsArtikelStandardRabattsatzVK.FieldName := 'StandardRabattsatzVK';
    dsArtikelRabattpreiseManuell.SetParentComponent(dsArtikel);
    dsArtikelRabattpreiseManuell.Name := 'dsArtikelRabattpreiseManuell';
    dsArtikelRabattpreiseManuell.FieldName := 'RabattpreiseManuell';
    dsArtikelMengeBestellt.SetParentComponent(dsArtikel);
    dsArtikelMengeBestellt.Name := 'dsArtikelMengeBestellt';
    dsArtikelMengeBestellt.FieldName := 'MengeBestellt';
    dsArtikelMengeBeauftragt.SetParentComponent(dsArtikel);
    dsArtikelMengeBeauftragt.Name := 'dsArtikelMengeBeauftragt';
    dsArtikelMengeBeauftragt.FieldName := 'MengeBeauftragt';
    dsArtikelBestandDisponiert.SetParentComponent(dsArtikel);
    dsArtikelBestandDisponiert.Name := 'dsArtikelBestandDisponiert';
    dsArtikelBestandDisponiert.FieldName := 'BestandDisponiert';
    dsArtikelMengeAngeboten.SetParentComponent(dsArtikel);
    dsArtikelMengeAngeboten.Name := 'dsArtikelMengeAngeboten';
    dsArtikelMengeAngeboten.FieldName := 'MengeAngeboten';
    dsArtikelBestandDispInclAngebot.SetParentComponent(dsArtikel);
    dsArtikelBestandDispInclAngebot.Name := 'dsArtikelBestandDispInclAngebot';
    dsArtikelBestandDispInclAngebot.FieldName := 'BestandDispInclAngebot';
    dsArtikelMWStKey.SetParentComponent(dsArtikel);
    dsArtikelMWStKey.Name := 'dsArtikelMWStKey';
    dsArtikelMWStKey.FieldName := 'MWStKey';
    dsArtikelShopAmazon.SetParentComponent(dsArtikel);
    dsArtikelShopAmazon.Name := 'dsArtikelShopAmazon';
    dsArtikelShopAmazon.FieldName := 'ShopAmazon';
    dsArtikelShopQuelle.SetParentComponent(dsArtikel);
    dsArtikelShopQuelle.Name := 'dsArtikelShopQuelle';
    dsArtikelShopQuelle.FieldName := 'ShopQuelle';
    dsArtikelGeraet.SetParentComponent(dsArtikel);
    dsArtikelGeraet.Name := 'dsArtikelGeraet';
    dsArtikelGeraet.FieldName := 'Geraet';
    dsArtikelArtgewicht.SetParentComponent(dsArtikel);
    dsArtikelArtgewicht.Name := 'dsArtikelArtgewicht';
    dsArtikelArtgewicht.FieldName := 'Artgewicht';
    dsArtikelVolumen.SetParentComponent(dsArtikel);
    dsArtikelVolumen.Name := 'dsArtikelVolumen';
    dsArtikelVolumen.FieldName := 'Volumen';
    dsArtikelPacklisteNichtAnzeigen.SetParentComponent(dsArtikel);
    dsArtikelPacklisteNichtAnzeigen.Name := 'dsArtikelPacklisteNichtAnzeigen';
    dsArtikelPacklisteNichtAnzeigen.FieldName := 'PacklisteNichtAnzeigen';
    dsArtikelKeineAufschlagskalkulation.SetParentComponent(dsArtikel);
    dsArtikelKeineAufschlagskalkulation.Name := 'dsArtikelKeineAufschlagskalkulation';
    dsArtikelKeineAufschlagskalkulation.FieldName := 'KeineAufschlagskalkulation';
    dsArtikelKorrekturMenge.SetParentComponent(dsArtikel);
    dsArtikelKorrekturMenge.Name := 'dsArtikelKorrekturMenge';
    dsArtikelKorrekturMenge.FieldName := 'KorrekturMenge';
    dsArtikelKorrekturWert.SetParentComponent(dsArtikel);
    dsArtikelKorrekturWert.Name := 'dsArtikelKorrekturWert';
    dsArtikelKorrekturWert.FieldName := 'KorrekturWert';
    dsArtikelNachfolgeartikel.SetParentComponent(dsArtikel);
    dsArtikelNachfolgeartikel.Name := 'dsArtikelNachfolgeartikel';
    dsArtikelNachfolgeartikel.FieldName := 'Nachfolgeartikel';
    dsArtikelNachfolgeartikel.Size := 25;
    dsArtikelMindestbestellmenge.SetParentComponent(dsArtikel);
    dsArtikelMindestbestellmenge.Name := 'dsArtikelMindestbestellmenge';
    dsArtikelMindestbestellmenge.FieldName := 'Mindestbestellmenge';
    dsArtikelSchnittEK.SetParentComponent(dsArtikel);
    dsArtikelSchnittEK.Name := 'dsArtikelSchnittEK';
    dsArtikelSchnittEK.FieldName := 'SchnittEK';
    dsArtikelVerkaufspreisVom.SetParentComponent(dsArtikel);
    dsArtikelVerkaufspreisVom.Name := 'dsArtikelVerkaufspreisVom';
    dsArtikelVerkaufspreisVom.FieldName := 'VerkaufspreisVom';
    dsArtikelPruefungChargeSNR.SetParentComponent(dsArtikel);
    dsArtikelPruefungChargeSNR.Name := 'dsArtikelPruefungChargeSNR';
    dsArtikelPruefungChargeSNR.FieldName := 'PruefungChargeSNR';
    dsArtikelArtikelbeschreibungHTML.SetParentComponent(dsArtikel);
    dsArtikelArtikelbeschreibungHTML.Name := 'dsArtikelArtikelbeschreibungHTML';
    dsArtikelArtikelbeschreibungHTML.FieldName := 'ArtikelbeschreibungHTML';
    dsArtikelArtikelbeschreibungHTML.Size := 255;
    dsArtikelProduktKurzbeschreibungHTML.SetParentComponent(dsArtikel);
    dsArtikelProduktKurzbeschreibungHTML.Name := 'dsArtikelProduktKurzbeschreibungHTML';
    dsArtikelProduktKurzbeschreibungHTML.FieldName := 'ProduktKurzbeschreibungHTML';
    dsArtikelProduktKurzbeschreibungHTML.Size := 255;
    dsArtikelStatusFuerShop.SetParentComponent(dsArtikel);
    dsArtikelStatusFuerShop.Name := 'dsArtikelStatusFuerShop';
    dsArtikelStatusFuerShop.FieldName := 'StatusFuerShop';
    dsArtikelStatusFuerShop.Size := 25;
    dsArtikelAmpelFuerShop.SetParentComponent(dsArtikel);
    dsArtikelAmpelFuerShop.Name := 'dsArtikelAmpelFuerShop';
    dsArtikelAmpelFuerShop.FieldName := 'AmpelFuerShop';
    dsArtikelAmpelFuerShop.Size := 25;
    dsArtikelEXP_Anzahl.SetParentComponent(dsArtikel);
    dsArtikelEXP_Anzahl.Name := 'dsArtikelEXP_Anzahl';
    dsArtikelEXP_Anzahl.FieldName := 'EXP_Anzahl';
    dsArtikelEXP_Inhalt.SetParentComponent(dsArtikel);
    dsArtikelEXP_Inhalt.Name := 'dsArtikelEXP_Inhalt';
    dsArtikelEXP_Inhalt.FieldName := 'EXP_Inhalt';
    dsArtikelEXP_Faktor.SetParentComponent(dsArtikel);
    dsArtikelEXP_Faktor.Name := 'dsArtikelEXP_Faktor';
    dsArtikelEXP_Faktor.FieldName := 'EXP_Faktor';
    dsArtikelFach.SetParentComponent(dsArtikel);
    dsArtikelFach.Name := 'dsArtikelFach';
    dsArtikelFach.FieldName := 'Fach';
    dsArtikelExportKasse.SetParentComponent(dsArtikel);
    dsArtikelExportKasse.Name := 'dsArtikelExportKasse';
    dsArtikelExportKasse.FieldName := 'ExportKasse';
    dsArtikelWKNR.SetParentComponent(dsArtikel);
    dsArtikelWKNR.Name := 'dsArtikelWKNR';
    dsArtikelWKNR.FieldName := 'WKNR';
    dsArtikelArtikelAttribute.SetParentComponent(dsArtikel);
    dsArtikelArtikelAttribute.Name := 'dsArtikelArtikelAttribute';
    dsArtikelArtikelAttribute.FieldName := 'ArtikelAttribute';
    dsArtikelArtikelAttribute.Size := 255;
    dsArtikelErstelltAm.SetParentComponent(dsArtikel);
    dsArtikelErstelltAm.Name := 'dsArtikelErstelltAm';
    dsArtikelErstelltAm.FieldName := 'ErstelltAm';
    dsArtikelVarianteVonAN.SetParentComponent(dsArtikel);
    dsArtikelVarianteVonAN.Name := 'dsArtikelVarianteVonAN';
    dsArtikelVarianteVonAN.FieldName := 'VarianteVonAN';
    dsArtikelVarianteVonAN.Size := 25;
    dsArtikelGurtmassWerte.SetParentComponent(dsArtikel);
    dsArtikelGurtmassWerte.Name := 'dsArtikelGurtmassWerte';
    dsArtikelGurtmassWerte.FieldName := 'GurtmassWerte';
    dsArtikelGurtmassWerte.Size := 250;
    dsArtikelGurtmass.SetParentComponent(dsArtikel);
    dsArtikelGurtmass.Name := 'dsArtikelGurtmass';
    dsArtikelGurtmass.FieldName := 'Gurtmass';
    dsArtikelAltersgrenze.SetParentComponent(dsArtikel);
    dsArtikelAltersgrenze.Name := 'dsArtikelAltersgrenze';
    dsArtikelAltersgrenze.FieldName := 'Altersgrenze';
    dsArtikelMWStKeyImHaus.SetParentComponent(dsArtikel);
    dsArtikelMWStKeyImHaus.Name := 'dsArtikelMWStKeyImHaus';
    dsArtikelMWStKeyImHaus.FieldName := 'MWStKeyImHaus';
    dsArtikelKasseBewegung.SetParentComponent(dsArtikel);
    dsArtikelKasseBewegung.Name := 'dsArtikelKasseBewegung';
    dsArtikelKasseBewegung.FieldName := 'KasseBewegung';
    dsArtikelKasseBewegungEK.SetParentComponent(dsArtikel);
    dsArtikelKasseBewegungEK.Name := 'dsArtikelKasseBewegungEK';
    dsArtikelKasseBewegungEK.FieldName := 'KasseBewegungEK';
    dsArtikelKasseBewegungVK.SetParentComponent(dsArtikel);
    dsArtikelKasseBewegungVK.Name := 'dsArtikelKasseBewegungVK';
    dsArtikelKasseBewegungVK.FieldName := 'KasseBewegungVK';
    dsArtikelProduktionBewegung.SetParentComponent(dsArtikel);
    dsArtikelProduktionBewegung.Name := 'dsArtikelProduktionBewegung';
    dsArtikelProduktionBewegung.FieldName := 'ProduktionBewegung';
    dsArtikelProduktionBewegungEK.SetParentComponent(dsArtikel);
    dsArtikelProduktionBewegungEK.Name := 'dsArtikelProduktionBewegungEK';
    dsArtikelProduktionBewegungEK.FieldName := 'ProduktionBewegungEK';
    dsArtikelProduktionBewegungVK.SetParentComponent(dsArtikel);
    dsArtikelProduktionBewegungVK.Name := 'dsArtikelProduktionBewegungVK';
    dsArtikelProduktionBewegungVK.FieldName := 'ProduktionBewegungVK';
    dsArtikelWaWiAB.SetParentComponent(dsArtikel);
    dsArtikelWaWiAB.Name := 'dsArtikelWaWiAB';
    dsArtikelWaWiAB.FieldName := 'WaWiAB';
    dsArtikelGeaendertAm.SetParentComponent(dsArtikel);
    dsArtikelGeaendertAm.Name := 'dsArtikelGeaendertAm';
    dsArtikelGeaendertAm.FieldName := 'GeaendertAm';
    dsArtikelGefahrgutKennzeichen.SetParentComponent(dsArtikel);
    dsArtikelGefahrgutKennzeichen.Name := 'dsArtikelGefahrgutKennzeichen';
    dsArtikelGefahrgutKennzeichen.FieldName := 'GefahrgutKennzeichen';
    dsArtikelGefahrgutKennzeichen.Size := 50;
    dsArtikelVerpackG.SetParentComponent(dsArtikel);
    dsArtikelVerpackG.Name := 'dsArtikelVerpackG';
    dsArtikelVerpackG.FieldName := 'VerpackG';
    dsArtikelUPE.SetParentComponent(dsArtikel);
    dsArtikelUPE.Name := 'dsArtikelUPE';
    dsArtikelUPE.FieldName := 'UPE';
    dsArtikelArtikelbeschreibungEinkauf.SetParentComponent(dsArtikel);
    dsArtikelArtikelbeschreibungEinkauf.Name := 'dsArtikelArtikelbeschreibungEinkauf';
    dsArtikelArtikelbeschreibungEinkauf.FieldName := 'ArtikelbeschreibungEinkauf';
    dsArtikelArtikelbeschreibungEinkauf.Size := 255;
    dsArtikelHerstellerArtikelnummer.SetParentComponent(dsArtikel);
    dsArtikelHerstellerArtikelnummer.Name := 'dsArtikelHerstellerArtikelnummer';
    dsArtikelHerstellerArtikelnummer.FieldName := 'HerstellerArtikelnummer';
    dsArtikelHerstellerArtikelnummer.Size := 25;
    dsArtikelNatura.SetParentComponent(dsArtikel);
    dsArtikelNatura.Name := 'dsArtikelNatura';
    dsArtikelNatura.FieldName := 'Natura';
    dsArtikelNatura.Size := 2;
    dsArtikelArbeitszeit.SetParentComponent(dsArtikel);
    dsArtikelArbeitszeit.Name := 'dsArtikelArbeitszeit';
    dsArtikelArbeitszeit.FieldName := 'Arbeitszeit';
    dsArtikelArtderMengeArtderMenge.SetParentComponent(dsArtikel);
    dsArtikelArtderMengeArtderMenge.Name := 'dsArtikelArtderMengeArtderMenge';
    dsArtikelArtderMengeArtderMenge.FieldName := 'ArtderMenge.ArtderMenge';
    dsArtikelArtderMengeArtderMenge.Size := 50;
    xdsKundenpreise.SetParentComponent(Self);
    xdsKundenpreise.Name := 'xdsKundenpreise';
    xdsKundenpreise.AfterOpen := xdsKundenpreiseAfterOpen;
    xdsKundenpreise.EntitySetName := 'ArtikelKundenpreise';
    xdsKundenpreise.Connection := DM2.XDataWebConnection1;
    xdsKundenpreise.SubpropsDepth := 1;
    xdsKundenpreise.QueryString := '$expand=Artikelnummer';
    xdsKundenpreise.Left := 151;
    xdsKundenpreise.Top := 174;
    xdsKundenpreiseArtikelnummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummer.Name := 'xdsKundenpreiseArtikelnummer';
    xdsKundenpreiseArtikelnummer.FieldName := 'Artikelnummer';
    xdsKundenpreiseArtikelnummer.Required := True;
    xdsKundenpreiseKundennummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseKundennummer.Name := 'xdsKundenpreiseKundennummer';
    xdsKundenpreiseKundennummer.FieldName := 'Kundennummer';
    xdsKundenpreiseKundennummer.Required := True;
    xdsKundenpreiseVerkaufspreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseVerkaufspreis.Name := 'xdsKundenpreiseVerkaufspreis';
    xdsKundenpreiseVerkaufspreis.FieldName := 'Verkaufspreis';
    xdsKundenpreiseNachname.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseNachname.Name := 'xdsKundenpreiseNachname';
    xdsKundenpreiseNachname.FieldName := 'Nachname';
    xdsKundenpreiseNachname.Size := 40;
    xdsKundenpreiseRabattsatz.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseRabattsatz.Name := 'xdsKundenpreiseRabattsatz';
    xdsKundenpreiseRabattsatz.FieldName := 'Rabattsatz';
    xdsKundenpreiseVerleihpreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseVerleihpreis.Name := 'xdsKundenpreiseVerleihpreis';
    xdsKundenpreiseVerleihpreis.FieldName := 'Verleihpreis';
    xdsKundenpreiseZusatzPreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseZusatzPreis.Name := 'xdsKundenpreiseZusatzPreis';
    xdsKundenpreiseZusatzPreis.FieldName := 'ZusatzPreis';
    xdsKundenpreiseArtikelnummerKunde.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerKunde.Name := 'xdsKundenpreiseArtikelnummerKunde';
    xdsKundenpreiseArtikelnummerKunde.FieldName := 'ArtikelnummerKunde';
    xdsKundenpreiseArtikelnummerKunde.Size := 25;
    xdsKundenpreiseDatumGeaendert.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseDatumGeaendert.Name := 'xdsKundenpreiseDatumGeaendert';
    xdsKundenpreiseDatumGeaendert.FieldName := 'DatumGeaendert';
    xdsKundenpreiseArtikelueberschrift.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelueberschrift.Name := 'xdsKundenpreiseArtikelueberschrift';
    xdsKundenpreiseArtikelueberschrift.FieldName := 'Artikelueberschrift';
    xdsKundenpreiseArtikelueberschrift.Size := 50;
    xdsKundenpreiseArtikelnummerArtikelnummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelnummer.Name := 'xdsKundenpreiseArtikelnummerArtikelnummer';
    xdsKundenpreiseArtikelnummerArtikelnummer.FieldName := 'Artikelnummer.Artikelnummer';
    xdsKundenpreiseArtikelnummerArtikelnummer.Size := 25;
    xdsKundenpreiseArtikelnummerArtikelueberschrift.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelueberschrift.Name := 'xdsKundenpreiseArtikelnummerArtikelueberschrift';
    xdsKundenpreiseArtikelnummerArtikelueberschrift.FieldName := 'Artikelnummer.Artikelueberschrift';
    xdsKundenpreiseArtikelnummerArtikelueberschrift.Size := 50;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.Name := 'xdsKundenpreiseArtikelnummerArtikelbeschreibung';
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.FieldName := 'Artikelnummer.Artikelbeschreibung';
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.Size := 255;
    dsBeleg2.SetParentComponent(Self);
    dsBeleg2.Name := 'dsBeleg2';
    dsBeleg2.AfterOpen := dsBeleg2AfterOpen;
    dsBeleg2.EntitySetName := 'Auftrag';
    dsBeleg2.Connection := DM2.XDataWebConnection1;
    dsBeleg2.SubpropsDepth := 1;
    dsBeleg2.QueryString := '$expand=Kundennummer';
    dsBeleg2.Left := 152;
    dsBeleg2.Top := 240;
    dsBeleg2Kundennummer.SetParentComponent(dsBeleg2);
    dsBeleg2Kundennummer.Name := 'dsBeleg2Kundennummer';
    dsBeleg2Kundennummer.FieldName := 'Kundennummer';
    dsBeleg2Kundennummer.Required := True;
    dsBeleg2Nummerkomplett.SetParentComponent(dsBeleg2);
    dsBeleg2Nummerkomplett.Name := 'dsBeleg2Nummerkomplett';
    dsBeleg2Nummerkomplett.FieldName := 'Nummerkomplett';
    dsBeleg2Nummerkomplett.Required := True;
    dsBeleg2Nummerkomplett.Size := 10;
    dsBeleg2ReferenzAngebot.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzAngebot.Name := 'dsBeleg2ReferenzAngebot';
    dsBeleg2ReferenzAngebot.FieldName := 'ReferenzAngebot';
    dsBeleg2ReferenzAngebot.Size := 10;
    dsBeleg2ReferenzLieferschein.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzLieferschein.Name := 'dsBeleg2ReferenzLieferschein';
    dsBeleg2ReferenzLieferschein.FieldName := 'ReferenzLieferschein';
    dsBeleg2ReferenzLieferschein.Size := 10;
    dsBeleg2ReferenzRechnung.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzRechnung.Name := 'dsBeleg2ReferenzRechnung';
    dsBeleg2ReferenzRechnung.FieldName := 'ReferenzRechnung';
    dsBeleg2ReferenzRechnung.Size := 10;
    dsBeleg2Datum.SetParentComponent(dsBeleg2);
    dsBeleg2Datum.Name := 'dsBeleg2Datum';
    dsBeleg2Datum.FieldName := 'Datum';
    dsBeleg2Datum.Required := True;
    dsBeleg2Jahr.SetParentComponent(dsBeleg2);
    dsBeleg2Jahr.Name := 'dsBeleg2Jahr';
    dsBeleg2Jahr.FieldName := 'Jahr';
    dsBeleg2Kalenderwoche.SetParentComponent(dsBeleg2);
    dsBeleg2Kalenderwoche.Name := 'dsBeleg2Kalenderwoche';
    dsBeleg2Kalenderwoche.FieldName := 'Kalenderwoche';
    dsBeleg2Nachname.SetParentComponent(dsBeleg2);
    dsBeleg2Nachname.Name := 'dsBeleg2Nachname';
    dsBeleg2Nachname.FieldName := 'Nachname';
    dsBeleg2Nachname.Size := 50;
    dsBeleg2Vorname.SetParentComponent(dsBeleg2);
    dsBeleg2Vorname.Name := 'dsBeleg2Vorname';
    dsBeleg2Vorname.FieldName := 'Vorname';
    dsBeleg2Vorname.Size := 50;
    dsBeleg2Postleitzahl.SetParentComponent(dsBeleg2);
    dsBeleg2Postleitzahl.Name := 'dsBeleg2Postleitzahl';
    dsBeleg2Postleitzahl.FieldName := 'Postleitzahl';
    dsBeleg2Postleitzahl.Size := 10;
    dsBeleg2Ort.SetParentComponent(dsBeleg2);
    dsBeleg2Ort.Name := 'dsBeleg2Ort';
    dsBeleg2Ort.FieldName := 'Ort';
    dsBeleg2Ort.Size := 50;
    dsBeleg2Laenderkuerzel.SetParentComponent(dsBeleg2);
    dsBeleg2Laenderkuerzel.Name := 'dsBeleg2Laenderkuerzel';
    dsBeleg2Laenderkuerzel.FieldName := 'Laenderkuerzel';
    dsBeleg2Laenderkuerzel.Size := 5;
    dsBeleg2Land.SetParentComponent(dsBeleg2);
    dsBeleg2Land.Name := 'dsBeleg2Land';
    dsBeleg2Land.FieldName := 'Land';
    dsBeleg2Land.Size := 50;
    dsBeleg2Briefanrede1.SetParentComponent(dsBeleg2);
    dsBeleg2Briefanrede1.Name := 'dsBeleg2Briefanrede1';
    dsBeleg2Briefanrede1.FieldName := 'Briefanrede1';
    dsBeleg2Briefanrede1.Size := 255;
    dsBeleg2Briefanrede2.SetParentComponent(dsBeleg2);
    dsBeleg2Briefanrede2.Name := 'dsBeleg2Briefanrede2';
    dsBeleg2Briefanrede2.FieldName := 'Briefanrede2';
    dsBeleg2Briefanrede2.Size := 40;
    dsBeleg2EMail.SetParentComponent(dsBeleg2);
    dsBeleg2EMail.Name := 'dsBeleg2EMail';
    dsBeleg2EMail.FieldName := 'EMail';
    dsBeleg2EMail.Size := 50;
    dsBeleg2AdresseBild.SetParentComponent(dsBeleg2);
    dsBeleg2AdresseBild.Name := 'dsBeleg2AdresseBild';
    dsBeleg2AdresseBild.FieldName := 'AdresseBild';
    dsBeleg2AdresseBild.Size := 255;
    dsBeleg2LieferanschriftBild.SetParentComponent(dsBeleg2);
    dsBeleg2LieferanschriftBild.Name := 'dsBeleg2LieferanschriftBild';
    dsBeleg2LieferanschriftBild.FieldName := 'LieferanschriftBild';
    dsBeleg2LieferanschriftBild.Size := 255;
    dsBeleg2Zahlungsbedingung.SetParentComponent(dsBeleg2);
    dsBeleg2Zahlungsbedingung.Name := 'dsBeleg2Zahlungsbedingung';
    dsBeleg2Zahlungsbedingung.FieldName := 'Zahlungsbedingung';
    dsBeleg2ZahlungsbedingungText.SetParentComponent(dsBeleg2);
    dsBeleg2ZahlungsbedingungText.Name := 'dsBeleg2ZahlungsbedingungText';
    dsBeleg2ZahlungsbedingungText.FieldName := 'ZahlungsbedingungText';
    dsBeleg2ZahlungsbedingungText.Size := 250;
    dsBeleg2Auftragsbezeichnung.SetParentComponent(dsBeleg2);
    dsBeleg2Auftragsbezeichnung.Name := 'dsBeleg2Auftragsbezeichnung';
    dsBeleg2Auftragsbezeichnung.FieldName := 'Auftragsbezeichnung';
    dsBeleg2Auftragsbezeichnung.Size := 255;
    dsBeleg2AuftragnummerKunde.SetParentComponent(dsBeleg2);
    dsBeleg2AuftragnummerKunde.Name := 'dsBeleg2AuftragnummerKunde';
    dsBeleg2AuftragnummerKunde.FieldName := 'AuftragnummerKunde';
    dsBeleg2AuftragnummerKunde.Size := 50;
    dsBeleg2BestelldatumKunde.SetParentComponent(dsBeleg2);
    dsBeleg2BestelldatumKunde.Name := 'dsBeleg2BestelldatumKunde';
    dsBeleg2BestelldatumKunde.FieldName := 'BestelldatumKunde';
    dsBeleg2ILN.SetParentComponent(dsBeleg2);
    dsBeleg2ILN.Name := 'dsBeleg2ILN';
    dsBeleg2ILN.FieldName := 'ILN';
    dsBeleg2ILN.Size := 50;
    dsBeleg2Kopftext.SetParentComponent(dsBeleg2);
    dsBeleg2Kopftext.Name := 'dsBeleg2Kopftext';
    dsBeleg2Kopftext.FieldName := 'Kopftext';
    dsBeleg2Kopftext.Size := 255;
    dsBeleg2Schlussbemerkung.SetParentComponent(dsBeleg2);
    dsBeleg2Schlussbemerkung.Name := 'dsBeleg2Schlussbemerkung';
    dsBeleg2Schlussbemerkung.FieldName := 'Schlussbemerkung';
    dsBeleg2Schlussbemerkung.Size := 255;
    dsBeleg2Gruss.SetParentComponent(dsBeleg2);
    dsBeleg2Gruss.Name := 'dsBeleg2Gruss';
    dsBeleg2Gruss.FieldName := 'Gruss';
    dsBeleg2Gruss.Size := 255;
    dsBeleg2Mehrwertsteuersatz1.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz1.Name := 'dsBeleg2Mehrwertsteuersatz1';
    dsBeleg2Mehrwertsteuersatz1.FieldName := 'Mehrwertsteuersatz1';
    dsBeleg2Mehrwertsteuersatz2.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz2.Name := 'dsBeleg2Mehrwertsteuersatz2';
    dsBeleg2Mehrwertsteuersatz2.FieldName := 'Mehrwertsteuersatz2';
    dsBeleg2Nettosumme01.SetParentComponent(dsBeleg2);
    dsBeleg2Nettosumme01.Name := 'dsBeleg2Nettosumme01';
    dsBeleg2Nettosumme01.FieldName := 'Nettosumme01';
    dsBeleg2Nettosumme02.SetParentComponent(dsBeleg2);
    dsBeleg2Nettosumme02.Name := 'dsBeleg2Nettosumme02';
    dsBeleg2Nettosumme02.FieldName := 'Nettosumme02';
    dsBeleg2Nettosumme.SetParentComponent(dsBeleg2);
    dsBeleg2Nettosumme.Name := 'dsBeleg2Nettosumme';
    dsBeleg2Nettosumme.FieldName := 'Nettosumme';
    dsBeleg2Mehrwertsteuer01.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuer01.Name := 'dsBeleg2Mehrwertsteuer01';
    dsBeleg2Mehrwertsteuer01.FieldName := 'Mehrwertsteuer01';
    dsBeleg2Mehrwertsteuer02.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuer02.Name := 'dsBeleg2Mehrwertsteuer02';
    dsBeleg2Mehrwertsteuer02.FieldName := 'Mehrwertsteuer02';
    dsBeleg2Mehrwertsteuer.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuer.Name := 'dsBeleg2Mehrwertsteuer';
    dsBeleg2Mehrwertsteuer.FieldName := 'Mehrwertsteuer';
    dsBeleg2Bruttosumme.SetParentComponent(dsBeleg2);
    dsBeleg2Bruttosumme.Name := 'dsBeleg2Bruttosumme';
    dsBeleg2Bruttosumme.FieldName := 'Bruttosumme';
    dsBeleg2Versanddatum.SetParentComponent(dsBeleg2);
    dsBeleg2Versanddatum.Name := 'dsBeleg2Versanddatum';
    dsBeleg2Versanddatum.FieldName := 'Versanddatum';
    dsBeleg2Skontosatz.SetParentComponent(dsBeleg2);
    dsBeleg2Skontosatz.Name := 'dsBeleg2Skontosatz';
    dsBeleg2Skontosatz.FieldName := 'Skontosatz';
    dsBeleg2NettosummeEK.SetParentComponent(dsBeleg2);
    dsBeleg2NettosummeEK.Name := 'dsBeleg2NettosummeEK';
    dsBeleg2NettosummeEK.FieldName := 'NettosummeEK';
    dsBeleg2NettoMarge.SetParentComponent(dsBeleg2);
    dsBeleg2NettoMarge.Name := 'dsBeleg2NettoMarge';
    dsBeleg2NettoMarge.FieldName := 'NettoMarge';
    dsBeleg2Schreibzeichen.SetParentComponent(dsBeleg2);
    dsBeleg2Schreibzeichen.Name := 'dsBeleg2Schreibzeichen';
    dsBeleg2Schreibzeichen.FieldName := 'Schreibzeichen';
    dsBeleg2Schreibzeichen.Size := 10;
    dsBeleg2Unterzeichner.SetParentComponent(dsBeleg2);
    dsBeleg2Unterzeichner.Name := 'dsBeleg2Unterzeichner';
    dsBeleg2Unterzeichner.FieldName := 'Unterzeichner';
    dsBeleg2Unterzeichner.Size := 50;
    dsBeleg2TelefonMA.SetParentComponent(dsBeleg2);
    dsBeleg2TelefonMA.Name := 'dsBeleg2TelefonMA';
    dsBeleg2TelefonMA.FieldName := 'TelefonMA';
    dsBeleg2TelefonMA.Size := 30;
    dsBeleg2EmailMA.SetParentComponent(dsBeleg2);
    dsBeleg2EmailMA.Name := 'dsBeleg2EmailMA';
    dsBeleg2EmailMA.FieldName := 'EmailMA';
    dsBeleg2EmailMA.Size := 50;
    dsBeleg2USTID.SetParentComponent(dsBeleg2);
    dsBeleg2USTID.Name := 'dsBeleg2USTID';
    dsBeleg2USTID.FieldName := 'USTID';
    dsBeleg2Umsatzsteuerausweis.SetParentComponent(dsBeleg2);
    dsBeleg2Umsatzsteuerausweis.Name := 'dsBeleg2Umsatzsteuerausweis';
    dsBeleg2Umsatzsteuerausweis.FieldName := 'Umsatzsteuerausweis';
    dsBeleg2gebucht.SetParentComponent(dsBeleg2);
    dsBeleg2gebucht.Name := 'dsBeleg2gebucht';
    dsBeleg2gebucht.FieldName := 'gebucht';
    dsBeleg2Lieferdatum.SetParentComponent(dsBeleg2);
    dsBeleg2Lieferdatum.Name := 'dsBeleg2Lieferdatum';
    dsBeleg2Lieferdatum.FieldName := 'Lieferdatum';
    dsBeleg2Transportbedingung.SetParentComponent(dsBeleg2);
    dsBeleg2Transportbedingung.Name := 'dsBeleg2Transportbedingung';
    dsBeleg2Transportbedingung.FieldName := 'Transportbedingung';
    dsBeleg2Transportbedingung.Size := 100;
    dsBeleg2Mehrwertsteuersatz3.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz3.Name := 'dsBeleg2Mehrwertsteuersatz3';
    dsBeleg2Mehrwertsteuersatz3.FieldName := 'Mehrwertsteuersatz3';
    dsBeleg2Mehrwertsteuer03.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuer03.Name := 'dsBeleg2Mehrwertsteuer03';
    dsBeleg2Mehrwertsteuer03.FieldName := 'Mehrwertsteuer03';
    dsBeleg2Nettosumme03.SetParentComponent(dsBeleg2);
    dsBeleg2Nettosumme03.Name := 'dsBeleg2Nettosumme03';
    dsBeleg2Nettosumme03.FieldName := 'Nettosumme03';
    dsBeleg2RundungsDifferenz.SetParentComponent(dsBeleg2);
    dsBeleg2RundungsDifferenz.Name := 'dsBeleg2RundungsDifferenz';
    dsBeleg2RundungsDifferenz.FieldName := 'RundungsDifferenz';
    dsBeleg2EmailText.SetParentComponent(dsBeleg2);
    dsBeleg2EmailText.Name := 'dsBeleg2EmailText';
    dsBeleg2EmailText.FieldName := 'EmailText';
    dsBeleg2EmailText.Size := 255;
    dsBeleg2Auftragerledigt.SetParentComponent(dsBeleg2);
    dsBeleg2Auftragerledigt.Name := 'dsBeleg2Auftragerledigt';
    dsBeleg2Auftragerledigt.FieldName := 'Auftragerledigt';
    dsBeleg2Rabattstufe.SetParentComponent(dsBeleg2);
    dsBeleg2Rabattstufe.Name := 'dsBeleg2Rabattstufe';
    dsBeleg2Rabattstufe.FieldName := 'Rabattstufe';
    dsBeleg2Rabattstufe.Size := 15;
    dsBeleg2KFZKennzeichen.SetParentComponent(dsBeleg2);
    dsBeleg2KFZKennzeichen.Name := 'dsBeleg2KFZKennzeichen';
    dsBeleg2KFZKennzeichen.FieldName := 'KFZKennzeichen';
    dsBeleg2KFZKennzeichen.Size := 13;
    dsBeleg2Fahrgestellnummer.SetParentComponent(dsBeleg2);
    dsBeleg2Fahrgestellnummer.Name := 'dsBeleg2Fahrgestellnummer';
    dsBeleg2Fahrgestellnummer.FieldName := 'Fahrgestellnummer';
    dsBeleg2KFZHersteller.SetParentComponent(dsBeleg2);
    dsBeleg2KFZHersteller.Name := 'dsBeleg2KFZHersteller';
    dsBeleg2KFZHersteller.FieldName := 'KFZHersteller';
    dsBeleg2Erstzulassungsdatum.SetParentComponent(dsBeleg2);
    dsBeleg2Erstzulassungsdatum.Name := 'dsBeleg2Erstzulassungsdatum';
    dsBeleg2Erstzulassungsdatum.FieldName := 'Erstzulassungsdatum';
    dsBeleg2KMStand.SetParentComponent(dsBeleg2);
    dsBeleg2KMStand.Name := 'dsBeleg2KMStand';
    dsBeleg2KMStand.FieldName := 'KMStand';
    dsBeleg2KMStand.Size := 6;
    dsBeleg2KFZType.SetParentComponent(dsBeleg2);
    dsBeleg2KFZType.Name := 'dsBeleg2KFZType';
    dsBeleg2KFZType.FieldName := 'KFZType';
    dsBeleg2KFZType.Size := 25;
    dsBeleg2Inspektionsdatum.SetParentComponent(dsBeleg2);
    dsBeleg2Inspektionsdatum.Name := 'dsBeleg2Inspektionsdatum';
    dsBeleg2Inspektionsdatum.FieldName := 'Inspektionsdatum';
    dsBeleg2Debitorennummer.SetParentComponent(dsBeleg2);
    dsBeleg2Debitorennummer.Name := 'dsBeleg2Debitorennummer';
    dsBeleg2Debitorennummer.FieldName := 'Debitorennummer';
    dsBeleg2Debitorennummer.Size := 13;
    dsBeleg2Kostentraeger.SetParentComponent(dsBeleg2);
    dsBeleg2Kostentraeger.Name := 'dsBeleg2Kostentraeger';
    dsBeleg2Kostentraeger.FieldName := 'Kostentraeger';
    dsBeleg2Kostentraeger.Size := 13;
    dsBeleg2Rabattsatz.SetParentComponent(dsBeleg2);
    dsBeleg2Rabattsatz.Name := 'dsBeleg2Rabattsatz';
    dsBeleg2Rabattsatz.FieldName := 'Rabattsatz';
    dsBeleg2ObjektReferenz.SetParentComponent(dsBeleg2);
    dsBeleg2ObjektReferenz.Name := 'dsBeleg2ObjektReferenz';
    dsBeleg2ObjektReferenz.FieldName := 'ObjektReferenz';
    dsBeleg2ObjektReferenz.Size := 13;
    dsBeleg2Bank.SetParentComponent(dsBeleg2);
    dsBeleg2Bank.Name := 'dsBeleg2Bank';
    dsBeleg2Bank.FieldName := 'Bank';
    dsBeleg2Bank.Size := 50;
    dsBeleg2Bankleitzahl.SetParentComponent(dsBeleg2);
    dsBeleg2Bankleitzahl.Name := 'dsBeleg2Bankleitzahl';
    dsBeleg2Bankleitzahl.FieldName := 'Bankleitzahl';
    dsBeleg2Kontonummer.SetParentComponent(dsBeleg2);
    dsBeleg2Kontonummer.Name := 'dsBeleg2Kontonummer';
    dsBeleg2Kontonummer.FieldName := 'Kontonummer';
    dsBeleg2Kontonummer.Size := 50;
    dsBeleg2Kontoinhaber.SetParentComponent(dsBeleg2);
    dsBeleg2Kontoinhaber.Name := 'dsBeleg2Kontoinhaber';
    dsBeleg2Kontoinhaber.FieldName := 'Kontoinhaber';
    dsBeleg2Kontoinhaber.Size := 50;
    dsBeleg2Telefaxnummer.SetParentComponent(dsBeleg2);
    dsBeleg2Telefaxnummer.Name := 'dsBeleg2Telefaxnummer';
    dsBeleg2Telefaxnummer.FieldName := 'Telefaxnummer';
    dsBeleg2Telefaxnummer.Size := 25;
    dsBeleg2DatumNeu.SetParentComponent(dsBeleg2);
    dsBeleg2DatumNeu.Name := 'dsBeleg2DatumNeu';
    dsBeleg2DatumNeu.FieldName := 'DatumNeu';
    dsBeleg2Datumgeaendert.SetParentComponent(dsBeleg2);
    dsBeleg2Datumgeaendert.Name := 'dsBeleg2Datumgeaendert';
    dsBeleg2Datumgeaendert.FieldName := 'Datumgeaendert';
    dsBeleg2Objektnummer.SetParentComponent(dsBeleg2);
    dsBeleg2Objektnummer.Name := 'dsBeleg2Objektnummer';
    dsBeleg2Objektnummer.FieldName := 'Objektnummer';
    dsBeleg2Objektnummer.Size := 25;
    dsBeleg2Liefertermin.SetParentComponent(dsBeleg2);
    dsBeleg2Liefertermin.Name := 'dsBeleg2Liefertermin';
    dsBeleg2Liefertermin.FieldName := 'Liefertermin';
    dsBeleg2Liefertermin.Size := 50;
    dsBeleg2Emailversand.SetParentComponent(dsBeleg2);
    dsBeleg2Emailversand.Name := 'dsBeleg2Emailversand';
    dsBeleg2Emailversand.FieldName := 'Emailversand';
    dsBeleg2Telefonnummer.SetParentComponent(dsBeleg2);
    dsBeleg2Telefonnummer.Name := 'dsBeleg2Telefonnummer';
    dsBeleg2Telefonnummer.FieldName := 'Telefonnummer';
    dsBeleg2Telefonnummer.Size := 35;
    dsBeleg2MobilTelefon.SetParentComponent(dsBeleg2);
    dsBeleg2MobilTelefon.Name := 'dsBeleg2MobilTelefon';
    dsBeleg2MobilTelefon.FieldName := 'MobilTelefon';
    dsBeleg2MobilTelefon.Size := 25;
    dsBeleg2AuftragFax.SetParentComponent(dsBeleg2);
    dsBeleg2AuftragFax.Name := 'dsBeleg2AuftragFax';
    dsBeleg2AuftragFax.FieldName := 'AuftragFax';
    dsBeleg2REDatum.SetParentComponent(dsBeleg2);
    dsBeleg2REDatum.Name := 'dsBeleg2REDatum';
    dsBeleg2REDatum.FieldName := 'REDatum';
    dsBeleg2BenutzerNeu.SetParentComponent(dsBeleg2);
    dsBeleg2BenutzerNeu.Name := 'dsBeleg2BenutzerNeu';
    dsBeleg2BenutzerNeu.FieldName := 'BenutzerNeu';
    dsBeleg2BenutzerNeu.Size := 50;
    dsBeleg2BenutzerGeaendert.SetParentComponent(dsBeleg2);
    dsBeleg2BenutzerGeaendert.Name := 'dsBeleg2BenutzerGeaendert';
    dsBeleg2BenutzerGeaendert.FieldName := 'BenutzerGeaendert';
    dsBeleg2BenutzerGeaendert.Size := 50;
    dsBeleg2BerechnungBrutto.SetParentComponent(dsBeleg2);
    dsBeleg2BerechnungBrutto.Name := 'dsBeleg2BerechnungBrutto';
    dsBeleg2BerechnungBrutto.FieldName := 'BerechnungBrutto';
    dsBeleg2Bruttosumme01.SetParentComponent(dsBeleg2);
    dsBeleg2Bruttosumme01.Name := 'dsBeleg2Bruttosumme01';
    dsBeleg2Bruttosumme01.FieldName := 'Bruttosumme01';
    dsBeleg2Bruttosumme02.SetParentComponent(dsBeleg2);
    dsBeleg2Bruttosumme02.Name := 'dsBeleg2Bruttosumme02';
    dsBeleg2Bruttosumme02.FieldName := 'Bruttosumme02';
    dsBeleg2Bruttosumme03.SetParentComponent(dsBeleg2);
    dsBeleg2Bruttosumme03.Name := 'dsBeleg2Bruttosumme03';
    dsBeleg2Bruttosumme03.FieldName := 'Bruttosumme03';
    dsBeleg2Notiz.SetParentComponent(dsBeleg2);
    dsBeleg2Notiz.Name := 'dsBeleg2Notiz';
    dsBeleg2Notiz.FieldName := 'Notiz';
    dsBeleg2Notiz.Size := 255;
    dsBeleg2MargeProzent.SetParentComponent(dsBeleg2);
    dsBeleg2MargeProzent.Name := 'dsBeleg2MargeProzent';
    dsBeleg2MargeProzent.FieldName := 'MargeProzent';
    dsBeleg2Warenkreditgesperrt.SetParentComponent(dsBeleg2);
    dsBeleg2Warenkreditgesperrt.Name := 'dsBeleg2Warenkreditgesperrt';
    dsBeleg2Warenkreditgesperrt.FieldName := 'Warenkreditgesperrt';
    dsBeleg2Belegsprache.SetParentComponent(dsBeleg2);
    dsBeleg2Belegsprache.Name := 'dsBeleg2Belegsprache';
    dsBeleg2Belegsprache.FieldName := 'Belegsprache';
    dsBeleg2Belegsprache.Size := 10;
    dsBeleg2Duplicate.SetParentComponent(dsBeleg2);
    dsBeleg2Duplicate.Name := 'dsBeleg2Duplicate';
    dsBeleg2Duplicate.FieldName := 'Duplicate';
    dsBeleg2perEmail.SetParentComponent(dsBeleg2);
    dsBeleg2perEmail.Name := 'dsBeleg2perEmail';
    dsBeleg2perEmail.FieldName := 'perEmail';
    dsBeleg2Ansprechpartner.SetParentComponent(dsBeleg2);
    dsBeleg2Ansprechpartner.Name := 'dsBeleg2Ansprechpartner';
    dsBeleg2Ansprechpartner.FieldName := 'Ansprechpartner';
    dsBeleg2Ansprechpartner.Size := 105;
    dsBeleg2KundennummerFremd.SetParentComponent(dsBeleg2);
    dsBeleg2KundennummerFremd.Name := 'dsBeleg2KundennummerFremd';
    dsBeleg2KundennummerFremd.FieldName := 'KundennummerFremd';
    dsBeleg2Protokoll.SetParentComponent(dsBeleg2);
    dsBeleg2Protokoll.Name := 'dsBeleg2Protokoll';
    dsBeleg2Protokoll.FieldName := 'Protokoll';
    dsBeleg2Protokoll.Size := 255;
    dsBeleg2Verleihauftrag.SetParentComponent(dsBeleg2);
    dsBeleg2Verleihauftrag.Name := 'dsBeleg2Verleihauftrag';
    dsBeleg2Verleihauftrag.FieldName := 'Verleihauftrag';
    dsBeleg2VerleihVon.SetParentComponent(dsBeleg2);
    dsBeleg2VerleihVon.Name := 'dsBeleg2VerleihVon';
    dsBeleg2VerleihVon.FieldName := 'VerleihVon';
    dsBeleg2VerleihBis.SetParentComponent(dsBeleg2);
    dsBeleg2VerleihBis.Name := 'dsBeleg2VerleihBis';
    dsBeleg2VerleihBis.FieldName := 'VerleihBis';
    dsBeleg2AuftragGenehmigt.SetParentComponent(dsBeleg2);
    dsBeleg2AuftragGenehmigt.Name := 'dsBeleg2AuftragGenehmigt';
    dsBeleg2AuftragGenehmigt.FieldName := 'AuftragGenehmigt';
    dsBeleg2gesperrt.SetParentComponent(dsBeleg2);
    dsBeleg2gesperrt.Name := 'dsBeleg2gesperrt';
    dsBeleg2gesperrt.FieldName := 'gesperrt';
    dsBeleg2VerleihvonText.SetParentComponent(dsBeleg2);
    dsBeleg2VerleihvonText.Name := 'dsBeleg2VerleihvonText';
    dsBeleg2VerleihvonText.FieldName := 'VerleihvonText';
    dsBeleg2VerleihvonText.Size := 50;
    dsBeleg2VerleihbisText.SetParentComponent(dsBeleg2);
    dsBeleg2VerleihbisText.Name := 'dsBeleg2VerleihbisText';
    dsBeleg2VerleihbisText.FieldName := 'VerleihbisText';
    dsBeleg2VerleihbisText.Size := 50;
    dsBeleg2DruckWHG.SetParentComponent(dsBeleg2);
    dsBeleg2DruckWHG.Name := 'dsBeleg2DruckWHG';
    dsBeleg2DruckWHG.FieldName := 'DruckWHG';
    dsBeleg2DruckWHG.Size := 3;
    dsBeleg2DruckWHGKurs.SetParentComponent(dsBeleg2);
    dsBeleg2DruckWHGKurs.Name := 'dsBeleg2DruckWHGKurs';
    dsBeleg2DruckWHGKurs.FieldName := 'DruckWHGKurs';
    dsBeleg2ProzentsatzZusatzposition.SetParentComponent(dsBeleg2);
    dsBeleg2ProzentsatzZusatzposition.Name := 'dsBeleg2ProzentsatzZusatzposition';
    dsBeleg2ProzentsatzZusatzposition.FieldName := 'ProzentsatzZusatzposition';
    dsBeleg2TextZusatzposition.SetParentComponent(dsBeleg2);
    dsBeleg2TextZusatzposition.Name := 'dsBeleg2TextZusatzposition';
    dsBeleg2TextZusatzposition.FieldName := 'TextZusatzposition';
    dsBeleg2TextZusatzposition.Size := 255;
    dsBeleg2TextzusatzBeschreibung.SetParentComponent(dsBeleg2);
    dsBeleg2TextzusatzBeschreibung.Name := 'dsBeleg2TextzusatzBeschreibung';
    dsBeleg2TextzusatzBeschreibung.FieldName := 'TextzusatzBeschreibung';
    dsBeleg2TextzusatzBeschreibung.Size := 50;
    dsBeleg2Layoutname.SetParentComponent(dsBeleg2);
    dsBeleg2Layoutname.Name := 'dsBeleg2Layoutname';
    dsBeleg2Layoutname.FieldName := 'Layoutname';
    dsBeleg2Layoutname.Size := 50;
    dsBeleg2Gesamtgewicht.SetParentComponent(dsBeleg2);
    dsBeleg2Gesamtgewicht.Name := 'dsBeleg2Gesamtgewicht';
    dsBeleg2Gesamtgewicht.FieldName := 'Gesamtgewicht';
    dsBeleg2BetragZusatzposition.SetParentComponent(dsBeleg2);
    dsBeleg2BetragZusatzposition.Name := 'dsBeleg2BetragZusatzposition';
    dsBeleg2BetragZusatzposition.FieldName := 'BetragZusatzposition';
    dsBeleg2ReferenzBestellung.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzBestellung.Name := 'dsBeleg2ReferenzBestellung';
    dsBeleg2ReferenzBestellung.FieldName := 'ReferenzBestellung';
    dsBeleg2ReferenzBestellung.Size := 10;
    dsBeleg2Termin1.SetParentComponent(dsBeleg2);
    dsBeleg2Termin1.Name := 'dsBeleg2Termin1';
    dsBeleg2Termin1.FieldName := 'Termin1';
    dsBeleg2Termin2.SetParentComponent(dsBeleg2);
    dsBeleg2Termin2.Name := 'dsBeleg2Termin2';
    dsBeleg2Termin2.FieldName := 'Termin2';
    dsBeleg2Zielkontaktnummer.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktnummer.Name := 'dsBeleg2Zielkontaktnummer';
    dsBeleg2Zielkontaktnummer.FieldName := 'Zielkontaktnummer';
    dsBeleg2Zielkontaktnummer.Size := 13;
    dsBeleg2Zielkontaktname.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktname.Name := 'dsBeleg2Zielkontaktname';
    dsBeleg2Zielkontaktname.FieldName := 'Zielkontaktname';
    dsBeleg2Zielkontaktname.Size := 40;
    dsBeleg2Bilddatei1.SetParentComponent(dsBeleg2);
    dsBeleg2Bilddatei1.Name := 'dsBeleg2Bilddatei1';
    dsBeleg2Bilddatei1.FieldName := 'Bilddatei1';
    dsBeleg2Bilddatei1.Size := 255;
    dsBeleg2Bilddatei2.SetParentComponent(dsBeleg2);
    dsBeleg2Bilddatei2.Name := 'dsBeleg2Bilddatei2';
    dsBeleg2Bilddatei2.FieldName := 'Bilddatei2';
    dsBeleg2Bilddatei2.Size := 255;
    dsBeleg2Zielkontaktnummer2.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktnummer2.Name := 'dsBeleg2Zielkontaktnummer2';
    dsBeleg2Zielkontaktnummer2.FieldName := 'Zielkontaktnummer2';
    dsBeleg2Zielkontaktnummer2.Size := 13;
    dsBeleg2Zielkontaktname2.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktname2.Name := 'dsBeleg2Zielkontaktname2';
    dsBeleg2Zielkontaktname2.FieldName := 'Zielkontaktname2';
    dsBeleg2Zielkontaktname2.Size := 40;
    dsBeleg2bestaetigt.SetParentComponent(dsBeleg2);
    dsBeleg2bestaetigt.Name := 'dsBeleg2bestaetigt';
    dsBeleg2bestaetigt.FieldName := 'bestaetigt';
    dsBeleg2Zielkontaktnummer3.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktnummer3.Name := 'dsBeleg2Zielkontaktnummer3';
    dsBeleg2Zielkontaktnummer3.FieldName := 'Zielkontaktnummer3';
    dsBeleg2Zielkontaktnummer3.Size := 13;
    dsBeleg2Zielkontaktname3.SetParentComponent(dsBeleg2);
    dsBeleg2Zielkontaktname3.Name := 'dsBeleg2Zielkontaktname3';
    dsBeleg2Zielkontaktname3.FieldName := 'Zielkontaktname3';
    dsBeleg2Zielkontaktname3.Size := 40;
    dsBeleg2Termin3.SetParentComponent(dsBeleg2);
    dsBeleg2Termin3.Name := 'dsBeleg2Termin3';
    dsBeleg2Termin3.FieldName := 'Termin3';
    dsBeleg2Termin4.SetParentComponent(dsBeleg2);
    dsBeleg2Termin4.Name := 'dsBeleg2Termin4';
    dsBeleg2Termin4.FieldName := 'Termin4';
    dsBeleg2Muster.SetParentComponent(dsBeleg2);
    dsBeleg2Muster.Name := 'dsBeleg2Muster';
    dsBeleg2Muster.FieldName := 'Muster';
    dsBeleg2KW.SetParentComponent(dsBeleg2);
    dsBeleg2KW.Name := 'dsBeleg2KW';
    dsBeleg2KW.FieldName := 'KW';
    dsBeleg2Datenlink.SetParentComponent(dsBeleg2);
    dsBeleg2Datenlink.Name := 'dsBeleg2Datenlink';
    dsBeleg2Datenlink.FieldName := 'Datenlink';
    dsBeleg2Datenlink.Size := 255;
    dsBeleg2Mehrwertsteuersatz4.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz4.Name := 'dsBeleg2Mehrwertsteuersatz4';
    dsBeleg2Mehrwertsteuersatz4.FieldName := 'Mehrwertsteuersatz4';
    dsBeleg2Mehrwertsteuersatz5.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz5.Name := 'dsBeleg2Mehrwertsteuersatz5';
    dsBeleg2Mehrwertsteuersatz5.FieldName := 'Mehrwertsteuersatz5';
    dsBeleg2Mehrwertsteuersatz6.SetParentComponent(dsBeleg2);
    dsBeleg2Mehrwertsteuersatz6.Name := 'dsBeleg2Mehrwertsteuersatz6';
    dsBeleg2Mehrwertsteuersatz6.FieldName := 'Mehrwertsteuersatz6';
    dsBeleg2BesteuerungKey.SetParentComponent(dsBeleg2);
    dsBeleg2BesteuerungKey.Name := 'dsBeleg2BesteuerungKey';
    dsBeleg2BesteuerungKey.FieldName := 'BesteuerungKey';
    dsBeleg2VertreterMatchcode.SetParentComponent(dsBeleg2);
    dsBeleg2VertreterMatchcode.Name := 'dsBeleg2VertreterMatchcode';
    dsBeleg2VertreterMatchcode.FieldName := 'VertreterMatchcode';
    dsBeleg2VertreterMatchcode.Size := 50;
    dsBeleg2Verfasser.SetParentComponent(dsBeleg2);
    dsBeleg2Verfasser.Name := 'dsBeleg2Verfasser';
    dsBeleg2Verfasser.FieldName := 'Verfasser';
    dsBeleg2Verfasser.Size := 10;
    dsBeleg2LieferadresseID.SetParentComponent(dsBeleg2);
    dsBeleg2LieferadresseID.Name := 'dsBeleg2LieferadresseID';
    dsBeleg2LieferadresseID.FieldName := 'LieferadresseID';
    dsBeleg2AuftragStatus.SetParentComponent(dsBeleg2);
    dsBeleg2AuftragStatus.Name := 'dsBeleg2AuftragStatus';
    dsBeleg2AuftragStatus.FieldName := 'AuftragStatus';
    dsBeleg2AuftragStatus.Size := 30;
    dsBeleg2MonatJahr.SetParentComponent(dsBeleg2);
    dsBeleg2MonatJahr.Name := 'dsBeleg2MonatJahr';
    dsBeleg2MonatJahr.FieldName := 'MonatJahr';
    dsBeleg2Belegvariante.SetParentComponent(dsBeleg2);
    dsBeleg2Belegvariante.Name := 'dsBeleg2Belegvariante';
    dsBeleg2Belegvariante.FieldName := 'Belegvariante';
    dsBeleg2Belegvariante.Size := 25;
    dsBeleg2Produktionsfreigabe.SetParentComponent(dsBeleg2);
    dsBeleg2Produktionsfreigabe.Name := 'dsBeleg2Produktionsfreigabe';
    dsBeleg2Produktionsfreigabe.FieldName := 'Produktionsfreigabe';
    dsBeleg2ReferenzRahmenAuftrag.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzRahmenAuftrag.Name := 'dsBeleg2ReferenzRahmenAuftrag';
    dsBeleg2ReferenzRahmenAuftrag.FieldName := 'ReferenzRahmenAuftrag';
    dsBeleg2ReferenzRahmenAuftrag.Size := 10;
    dsBeleg2DatumRahmenAuftrag.SetParentComponent(dsBeleg2);
    dsBeleg2DatumRahmenAuftrag.Name := 'dsBeleg2DatumRahmenAuftrag';
    dsBeleg2DatumRahmenAuftrag.FieldName := 'DatumRahmenAuftrag';
    dsBeleg2FahrzeugID.SetParentComponent(dsBeleg2);
    dsBeleg2FahrzeugID.Name := 'dsBeleg2FahrzeugID';
    dsBeleg2FahrzeugID.FieldName := 'FahrzeugID';
    dsBeleg2AuslieferungKW.SetParentComponent(dsBeleg2);
    dsBeleg2AuslieferungKW.Name := 'dsBeleg2AuslieferungKW';
    dsBeleg2AuslieferungKW.FieldName := 'AuslieferungKW';
    dsBeleg2AuslieferungKW.Size := 25;
    dsBeleg2BereitstellungDatum.SetParentComponent(dsBeleg2);
    dsBeleg2BereitstellungDatum.Name := 'dsBeleg2BereitstellungDatum';
    dsBeleg2BereitstellungDatum.FieldName := 'BereitstellungDatum';
    dsBeleg2VTUID.SetParentComponent(dsBeleg2);
    dsBeleg2VTUID.Name := 'dsBeleg2VTUID';
    dsBeleg2VTUID.FieldName := 'VTUID';
    dsBeleg2VTUID.Size := 38;
    dsBeleg2LieferanschriftDaten.SetParentComponent(dsBeleg2);
    dsBeleg2LieferanschriftDaten.Name := 'dsBeleg2LieferanschriftDaten';
    dsBeleg2LieferanschriftDaten.FieldName := 'LieferanschriftDaten';
    dsBeleg2LieferanschriftDaten.Size := 255;
    dsBeleg2GesamtVolumen.SetParentComponent(dsBeleg2);
    dsBeleg2GesamtVolumen.Name := 'dsBeleg2GesamtVolumen';
    dsBeleg2GesamtVolumen.FieldName := 'GesamtVolumen';
    dsBeleg2BelegPKey.SetParentComponent(dsBeleg2);
    dsBeleg2BelegPKey.Name := 'dsBeleg2BelegPKey';
    dsBeleg2BelegPKey.FieldName := 'BelegPKey';
    dsBeleg2DruckParameter.SetParentComponent(dsBeleg2);
    dsBeleg2DruckParameter.Name := 'dsBeleg2DruckParameter';
    dsBeleg2DruckParameter.FieldName := 'DruckParameter';
    dsBeleg2DruckParameter.Size := 255;
    dsBeleg2ReferenzService.SetParentComponent(dsBeleg2);
    dsBeleg2ReferenzService.Name := 'dsBeleg2ReferenzService';
    dsBeleg2ReferenzService.FieldName := 'ReferenzService';
    dsBeleg2ReferenzService.Size := 10;
    dsBeleg2ZusatzDaten.SetParentComponent(dsBeleg2);
    dsBeleg2ZusatzDaten.Name := 'dsBeleg2ZusatzDaten';
    dsBeleg2ZusatzDaten.FieldName := 'ZusatzDaten';
    dsBeleg2ZusatzDaten.Size := 255;
    dsBeleg2StandPDF.SetParentComponent(dsBeleg2);
    dsBeleg2StandPDF.Name := 'dsBeleg2StandPDF';
    dsBeleg2StandPDF.FieldName := 'StandPDF';
    dsBeleg2StandPDF.Size := 255;
    dsBeleg2Paketdienst.SetParentComponent(dsBeleg2);
    dsBeleg2Paketdienst.Name := 'dsBeleg2Paketdienst';
    dsBeleg2Paketdienst.FieldName := 'Paketdienst';
    dsBeleg2Paketdienst.Size := 30;
    dsBeleg2Monteur.SetParentComponent(dsBeleg2);
    dsBeleg2Monteur.Name := 'dsBeleg2Monteur';
    dsBeleg2Monteur.FieldName := 'Monteur';
    dsBeleg2KundennummerKundennummer.SetParentComponent(dsBeleg2);
    dsBeleg2KundennummerKundennummer.Name := 'dsBeleg2KundennummerKundennummer';
    dsBeleg2KundennummerKundennummer.FieldName := 'Kundennummer.Kundennummer';
    dsBeleg2KundennummerKundennummer.Size := 13;
    dsBeleg2ServiceBemerkung.SetParentComponent(dsBeleg2);
    dsBeleg2ServiceBemerkung.Name := 'dsBeleg2ServiceBemerkung';
    dsBeleg2ServiceBemerkung.FieldName := 'ServiceBemerkung';
    dsBeleg2ServiceBemerkung.Size := 255;
  finally
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    WTCPosition.AfterLoadDFMValues;
    btnPosAbbrechen.AfterLoadDFMValues;
    btnPosSpeichern.AfterLoadDFMValues;
    btnPositionLoeschen.AfterLoadDFMValues;
    edtPosNr.AfterLoadDFMValues;
    edtPosUNr.AfterLoadDFMValues;
    edtArtikelueberschrift.AfterLoadDFMValues;
    edtMenge.AfterLoadDFMValues;
    btnArtikelnummer.AfterLoadDFMValues;
    edtArbeitszeitDatum.AfterLoadDFMValues;
    edtArbeitszeitVON.AfterLoadDFMValues;
    edtArbeitszeitBIS.AfterLoadDFMValues;
    edtArtikelbeschreibung.AfterLoadDFMValues;
    PosComboMonteur.AfterLoadDFMValues;
    edtMengenart.AfterLoadDFMValues;
    btnNeuePosition.AfterLoadDFMValues;
    xdsPosition.AfterLoadDFMValues;
    xdsPositionID.AfterLoadDFMValues;
    xdsPositionPosNr.AfterLoadDFMValues;
    xdsPositionPosUNr.AfterLoadDFMValues;
    xdsPositionArtikelueberschrift.AfterLoadDFMValues;
    xdsPositionArtikelbeschreibung.AfterLoadDFMValues;
    xdsPositionMenge.AfterLoadDFMValues;
    xdsPositionArtderMenge.AfterLoadDFMValues;
    xdsPositionVerkaufspreis.AfterLoadDFMValues;
    xdsPositionEinkaufspreis.AfterLoadDFMValues;
    xdsPositionArtikelnummer.AfterLoadDFMValues;
    xdsPositionMonteurP.AfterLoadDFMValues;
    xdsPositionArbeitszeitDatum.AfterLoadDFMValues;
    xdsPositionArbeitszeitVON.AfterLoadDFMValues;
    xdsPositionArbeitszeitBIS.AfterLoadDFMValues;
    xdsPositionSumme.AfterLoadDFMValues;
    xdsPositionSeriennummer.AfterLoadDFMValues;
    xdsPositionWarengruppe.AfterLoadDFMValues;
    xdsPositionWarenuntergruppe.AfterLoadDFMValues;
    xdsPositionArtikelnummerKunde.AfterLoadDFMValues;
    xdsPositionLieferant.AfterLoadDFMValues;
    xdsPositionGewicht.AfterLoadDFMValues;
    xdsPositionLaenge.AfterLoadDFMValues;
    xdsPositionBreite.AfterLoadDFMValues;
    xdsPositionHoehe.AfterLoadDFMValues;
    xdsPositionMehrwertsteuersatz.AfterLoadDFMValues;
    xdsPositionEndpreis.AfterLoadDFMValues;
    xdsPositionPosMWST.AfterLoadDFMValues;
    xdsPositionBruttoEndpreis.AfterLoadDFMValues;
    xdsPositionEndpreis_EK.AfterLoadDFMValues;
    xdsPositionRabattsatz.AfterLoadDFMValues;
    xdsPositionPreisvorRabatt.AfterLoadDFMValues;
    xdsPositionPKZ.AfterLoadDFMValues;
    xdsPositionTextfrei10.AfterLoadDFMValues;
    xdsPositionTextfrei20.AfterLoadDFMValues;
    xdsPositionProduktionAbgeschlossen.AfterLoadDFMValues;
    xdsPositionEinzelpreisBrutto.AfterLoadDFMValues;
    xdsPositionPosMarge.AfterLoadDFMValues;
    xdsPositionPosMargeProzent.AfterLoadDFMValues;
    xdsPositionBereitsGeliefert.AfterLoadDFMValues;
    xdsPositionausliefern.AfterLoadDFMValues;
    xdsPositionBestellungErledigt.AfterLoadDFMValues;
    xdsPositionLager.AfterLoadDFMValues;
    xdsPositionbestellen.AfterLoadDFMValues;
    xdsPositionWarenbestand.AfterLoadDFMValues;
    xdsPositionArtikelbeschreibungRTF.AfterLoadDFMValues;
    xdsPositionQuellartikel.AfterLoadDFMValues;
    xdsPositionArtikelnummerWB.AfterLoadDFMValues;
    xdsPositionPreisanteilNetto.AfterLoadDFMValues;
    xdsPositionSetHPos.AfterLoadDFMValues;
    xdsPositionSetUPos.AfterLoadDFMValues;
    xdsPositionProvisionssatz.AfterLoadDFMValues;
    xdsPositionProvisionsbetrag.AfterLoadDFMValues;
    xdsPositionArtikelsprache.AfterLoadDFMValues;
    xdsPositionEKvom.AfterLoadDFMValues;
    xdsPositionMengenberechnungID.AfterLoadDFMValues;
    xdsPositionTaraID.AfterLoadDFMValues;
    xdsPositionTara.AfterLoadDFMValues;
    xdsPositionBruttoGewicht.AfterLoadDFMValues;
    xdsPositionAussenflaeche.AfterLoadDFMValues;
    xdsPositionLadeort.AfterLoadDFMValues;
    xdsPositionSeitenumbruch.AfterLoadDFMValues;
    xdsPositionPlanerFarbe.AfterLoadDFMValues;
    xdsPositionMWStKey.AfterLoadDFMValues;
    xdsPositionZSnachPos.AfterLoadDFMValues;
    xdsPositionLieferzeit.AfterLoadDFMValues;
    xdsPositionPreisVorRabattWHG.AfterLoadDFMValues;
    xdsPositionReferenzKalkulation.AfterLoadDFMValues;
    xdsPositionPKey.AfterLoadDFMValues;
    xdsPositionAuftragsnummer.AfterLoadDFMValues;
    xdsPositionlwbID.AfterLoadDFMValues;
    xdsPositionBelegdatum.AfterLoadDFMValues;
    xdsPositionWarenkontrollnummern.AfterLoadDFMValues;
    xdsPositionVolumen.AfterLoadDFMValues;
    xdsPositionPosVolumen.AfterLoadDFMValues;
    xdsPositionArtikelAttribute.AfterLoadDFMValues;
    xdsPositionNummerkomplett.AfterLoadDFMValues;
    sourcePosition.AfterLoadDFMValues;
    dsArtikel.AfterLoadDFMValues;
    dsArtikelHersteller.AfterLoadDFMValues;
    dsArtikelArtderMenge.AfterLoadDFMValues;
    dsArtikelPfandArt.AfterLoadDFMValues;
    dsArtikelWarengruppe.AfterLoadDFMValues;
    dsArtikelWarenuntergruppe.AfterLoadDFMValues;
    dsArtikelArtikelnummer.AfterLoadDFMValues;
    dsArtikelArtikelueberschrift.AfterLoadDFMValues;
    dsArtikelArtikelbeschreibung.AfterLoadDFMValues;
    dsArtikelVerkaufspreis.AfterLoadDFMValues;
    dsArtikelMindestbestand.AfterLoadDFMValues;
    dsArtikelPreisliste.AfterLoadDFMValues;
    dsArtikelGewicht.AfterLoadDFMValues;
    dsArtikelLaenge.AfterLoadDFMValues;
    dsArtikelBreite.AfterLoadDFMValues;
    dsArtikelHoehe.AfterLoadDFMValues;
    dsArtikelArtikelZusatz.AfterLoadDFMValues;
    dsArtikelLagerort.AfterLoadDFMValues;
    dsArtikelBuchungskonto.AfterLoadDFMValues;
    dsArtikelEingangMenge.AfterLoadDFMValues;
    dsArtikelEingangEndpreis.AfterLoadDFMValues;
    dsArtikelAusgangMenge.AfterLoadDFMValues;
    dsArtikelAusgangEndpreisEK.AfterLoadDFMValues;
    dsArtikelAusgangEndpreisVK.AfterLoadDFMValues;
    dsArtikelBestand.AfterLoadDFMValues;
    dsArtikelBestandswert.AfterLoadDFMValues;
    dsArtikelMarge.AfterLoadDFMValues;
    dsArtikelMargeProzent.AfterLoadDFMValues;
    dsArtikelArtikelnummer2.AfterLoadDFMValues;
    dsArtikelArtikelmatchcode.AfterLoadDFMValues;
    dsArtikelRabattpreis1.AfterLoadDFMValues;
    dsArtikelRabattpreis2.AfterLoadDFMValues;
    dsArtikelRabattpreis3.AfterLoadDFMValues;
    dsArtikelRabattpreis4.AfterLoadDFMValues;
    dsArtikelRabattpreis5.AfterLoadDFMValues;
    dsArtikelArtikelnichtmehrgueltig.AfterLoadDFMValues;
    dsArtikelEinkaufspreis.AfterLoadDFMValues;
    dsArtikelAufschlag.AfterLoadDFMValues;
    dsArtikelWarenbestand.AfterLoadDFMValues;
    dsArtikelTextfrei10.AfterLoadDFMValues;
    dsArtikelTextfrei20.AfterLoadDFMValues;
    dsArtikelArtikelbeschreibungRTF.AfterLoadDFMValues;
    dsArtikelArtikeldatei1.AfterLoadDFMValues;
    dsArtikelArtikeldatei2.AfterLoadDFMValues;
    dsArtikelArtikeldatei3.AfterLoadDFMValues;
    dsArtikelArtikeldatei4.AfterLoadDFMValues;
    dsArtikelArtikeldatei5.AfterLoadDFMValues;
    dsArtikelEinkaufspreisVom.AfterLoadDFMValues;
    dsArtikelEAN.AfterLoadDFMValues;
    dsArtikelVGPFaktor.AfterLoadDFMValues;
    dsArtikelVGPMengenart.AfterLoadDFMValues;
    dsArtikelVerleihpreis.AfterLoadDFMValues;
    dsArtikelZusatzPreis.AfterLoadDFMValues;
    dsArtikelVerpackungsart.AfterLoadDFMValues;
    dsArtikelVerpackungsMenge.AfterLoadDFMValues;
    dsArtikelQuellartikel.AfterLoadDFMValues;
    dsArtikelrabattierfaehig.AfterLoadDFMValues;
    dsArtikelAngebotAlternativeAN.AfterLoadDFMValues;
    dsArtikelPreisanteilNetto.AfterLoadDFMValues;
    dsArtikelFarbe.AfterLoadDFMValues;
    dsArtikelAEinkauf.AfterLoadDFMValues;
    dsArtikelAVerkauf.AfterLoadDFMValues;
    dsArtikelArtikelnummerLieferant.AfterLoadDFMValues;
    dsArtikelLieferantnummer.AfterLoadDFMValues;
    dsArtikelVerkaufspreisBrutto.AfterLoadDFMValues;
    dsArtikelArtikelSet.AfterLoadDFMValues;
    dsArtikelProvisionssatz.AfterLoadDFMValues;
    dsArtikelWarentarifnummer.AfterLoadDFMValues;
    dsArtikelHandelsartikel.AfterLoadDFMValues;
    dsArtikelUrsprungsland.AfterLoadDFMValues;
    dsArtikelKategorie.AfterLoadDFMValues;
    dsArtikelStandardRabattsatzEK.AfterLoadDFMValues;
    dsArtikelStandardRabattsatzVK.AfterLoadDFMValues;
    dsArtikelRabattpreiseManuell.AfterLoadDFMValues;
    dsArtikelMengeBestellt.AfterLoadDFMValues;
    dsArtikelMengeBeauftragt.AfterLoadDFMValues;
    dsArtikelBestandDisponiert.AfterLoadDFMValues;
    dsArtikelMengeAngeboten.AfterLoadDFMValues;
    dsArtikelBestandDispInclAngebot.AfterLoadDFMValues;
    dsArtikelMWStKey.AfterLoadDFMValues;
    dsArtikelShopAmazon.AfterLoadDFMValues;
    dsArtikelShopQuelle.AfterLoadDFMValues;
    dsArtikelGeraet.AfterLoadDFMValues;
    dsArtikelArtgewicht.AfterLoadDFMValues;
    dsArtikelVolumen.AfterLoadDFMValues;
    dsArtikelPacklisteNichtAnzeigen.AfterLoadDFMValues;
    dsArtikelKeineAufschlagskalkulation.AfterLoadDFMValues;
    dsArtikelKorrekturMenge.AfterLoadDFMValues;
    dsArtikelKorrekturWert.AfterLoadDFMValues;
    dsArtikelNachfolgeartikel.AfterLoadDFMValues;
    dsArtikelMindestbestellmenge.AfterLoadDFMValues;
    dsArtikelSchnittEK.AfterLoadDFMValues;
    dsArtikelVerkaufspreisVom.AfterLoadDFMValues;
    dsArtikelPruefungChargeSNR.AfterLoadDFMValues;
    dsArtikelArtikelbeschreibungHTML.AfterLoadDFMValues;
    dsArtikelProduktKurzbeschreibungHTML.AfterLoadDFMValues;
    dsArtikelStatusFuerShop.AfterLoadDFMValues;
    dsArtikelAmpelFuerShop.AfterLoadDFMValues;
    dsArtikelEXP_Anzahl.AfterLoadDFMValues;
    dsArtikelEXP_Inhalt.AfterLoadDFMValues;
    dsArtikelEXP_Faktor.AfterLoadDFMValues;
    dsArtikelFach.AfterLoadDFMValues;
    dsArtikelExportKasse.AfterLoadDFMValues;
    dsArtikelWKNR.AfterLoadDFMValues;
    dsArtikelArtikelAttribute.AfterLoadDFMValues;
    dsArtikelErstelltAm.AfterLoadDFMValues;
    dsArtikelVarianteVonAN.AfterLoadDFMValues;
    dsArtikelGurtmassWerte.AfterLoadDFMValues;
    dsArtikelGurtmass.AfterLoadDFMValues;
    dsArtikelAltersgrenze.AfterLoadDFMValues;
    dsArtikelMWStKeyImHaus.AfterLoadDFMValues;
    dsArtikelKasseBewegung.AfterLoadDFMValues;
    dsArtikelKasseBewegungEK.AfterLoadDFMValues;
    dsArtikelKasseBewegungVK.AfterLoadDFMValues;
    dsArtikelProduktionBewegung.AfterLoadDFMValues;
    dsArtikelProduktionBewegungEK.AfterLoadDFMValues;
    dsArtikelProduktionBewegungVK.AfterLoadDFMValues;
    dsArtikelWaWiAB.AfterLoadDFMValues;
    dsArtikelGeaendertAm.AfterLoadDFMValues;
    dsArtikelGefahrgutKennzeichen.AfterLoadDFMValues;
    dsArtikelVerpackG.AfterLoadDFMValues;
    dsArtikelUPE.AfterLoadDFMValues;
    dsArtikelArtikelbeschreibungEinkauf.AfterLoadDFMValues;
    dsArtikelHerstellerArtikelnummer.AfterLoadDFMValues;
    dsArtikelNatura.AfterLoadDFMValues;
    dsArtikelArbeitszeit.AfterLoadDFMValues;
    dsArtikelArtderMengeArtderMenge.AfterLoadDFMValues;
    xdsKundenpreise.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummer.AfterLoadDFMValues;
    xdsKundenpreiseKundennummer.AfterLoadDFMValues;
    xdsKundenpreiseVerkaufspreis.AfterLoadDFMValues;
    xdsKundenpreiseNachname.AfterLoadDFMValues;
    xdsKundenpreiseRabattsatz.AfterLoadDFMValues;
    xdsKundenpreiseVerleihpreis.AfterLoadDFMValues;
    xdsKundenpreiseZusatzPreis.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerKunde.AfterLoadDFMValues;
    xdsKundenpreiseDatumGeaendert.AfterLoadDFMValues;
    xdsKundenpreiseArtikelueberschrift.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelnummer.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelueberschrift.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.AfterLoadDFMValues;
    dsBeleg2.AfterLoadDFMValues;
    dsBeleg2Kundennummer.AfterLoadDFMValues;
    dsBeleg2Nummerkomplett.AfterLoadDFMValues;
    dsBeleg2ReferenzAngebot.AfterLoadDFMValues;
    dsBeleg2ReferenzLieferschein.AfterLoadDFMValues;
    dsBeleg2ReferenzRechnung.AfterLoadDFMValues;
    dsBeleg2Datum.AfterLoadDFMValues;
    dsBeleg2Jahr.AfterLoadDFMValues;
    dsBeleg2Kalenderwoche.AfterLoadDFMValues;
    dsBeleg2Nachname.AfterLoadDFMValues;
    dsBeleg2Vorname.AfterLoadDFMValues;
    dsBeleg2Postleitzahl.AfterLoadDFMValues;
    dsBeleg2Ort.AfterLoadDFMValues;
    dsBeleg2Laenderkuerzel.AfterLoadDFMValues;
    dsBeleg2Land.AfterLoadDFMValues;
    dsBeleg2Briefanrede1.AfterLoadDFMValues;
    dsBeleg2Briefanrede2.AfterLoadDFMValues;
    dsBeleg2EMail.AfterLoadDFMValues;
    dsBeleg2AdresseBild.AfterLoadDFMValues;
    dsBeleg2LieferanschriftBild.AfterLoadDFMValues;
    dsBeleg2Zahlungsbedingung.AfterLoadDFMValues;
    dsBeleg2ZahlungsbedingungText.AfterLoadDFMValues;
    dsBeleg2Auftragsbezeichnung.AfterLoadDFMValues;
    dsBeleg2AuftragnummerKunde.AfterLoadDFMValues;
    dsBeleg2BestelldatumKunde.AfterLoadDFMValues;
    dsBeleg2ILN.AfterLoadDFMValues;
    dsBeleg2Kopftext.AfterLoadDFMValues;
    dsBeleg2Schlussbemerkung.AfterLoadDFMValues;
    dsBeleg2Gruss.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz1.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz2.AfterLoadDFMValues;
    dsBeleg2Nettosumme01.AfterLoadDFMValues;
    dsBeleg2Nettosumme02.AfterLoadDFMValues;
    dsBeleg2Nettosumme.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuer01.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuer02.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuer.AfterLoadDFMValues;
    dsBeleg2Bruttosumme.AfterLoadDFMValues;
    dsBeleg2Versanddatum.AfterLoadDFMValues;
    dsBeleg2Skontosatz.AfterLoadDFMValues;
    dsBeleg2NettosummeEK.AfterLoadDFMValues;
    dsBeleg2NettoMarge.AfterLoadDFMValues;
    dsBeleg2Schreibzeichen.AfterLoadDFMValues;
    dsBeleg2Unterzeichner.AfterLoadDFMValues;
    dsBeleg2TelefonMA.AfterLoadDFMValues;
    dsBeleg2EmailMA.AfterLoadDFMValues;
    dsBeleg2USTID.AfterLoadDFMValues;
    dsBeleg2Umsatzsteuerausweis.AfterLoadDFMValues;
    dsBeleg2gebucht.AfterLoadDFMValues;
    dsBeleg2Lieferdatum.AfterLoadDFMValues;
    dsBeleg2Transportbedingung.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz3.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuer03.AfterLoadDFMValues;
    dsBeleg2Nettosumme03.AfterLoadDFMValues;
    dsBeleg2RundungsDifferenz.AfterLoadDFMValues;
    dsBeleg2EmailText.AfterLoadDFMValues;
    dsBeleg2Auftragerledigt.AfterLoadDFMValues;
    dsBeleg2Rabattstufe.AfterLoadDFMValues;
    dsBeleg2KFZKennzeichen.AfterLoadDFMValues;
    dsBeleg2Fahrgestellnummer.AfterLoadDFMValues;
    dsBeleg2KFZHersteller.AfterLoadDFMValues;
    dsBeleg2Erstzulassungsdatum.AfterLoadDFMValues;
    dsBeleg2KMStand.AfterLoadDFMValues;
    dsBeleg2KFZType.AfterLoadDFMValues;
    dsBeleg2Inspektionsdatum.AfterLoadDFMValues;
    dsBeleg2Debitorennummer.AfterLoadDFMValues;
    dsBeleg2Kostentraeger.AfterLoadDFMValues;
    dsBeleg2Rabattsatz.AfterLoadDFMValues;
    dsBeleg2ObjektReferenz.AfterLoadDFMValues;
    dsBeleg2Bank.AfterLoadDFMValues;
    dsBeleg2Bankleitzahl.AfterLoadDFMValues;
    dsBeleg2Kontonummer.AfterLoadDFMValues;
    dsBeleg2Kontoinhaber.AfterLoadDFMValues;
    dsBeleg2Telefaxnummer.AfterLoadDFMValues;
    dsBeleg2DatumNeu.AfterLoadDFMValues;
    dsBeleg2Datumgeaendert.AfterLoadDFMValues;
    dsBeleg2Objektnummer.AfterLoadDFMValues;
    dsBeleg2Liefertermin.AfterLoadDFMValues;
    dsBeleg2Emailversand.AfterLoadDFMValues;
    dsBeleg2Telefonnummer.AfterLoadDFMValues;
    dsBeleg2MobilTelefon.AfterLoadDFMValues;
    dsBeleg2AuftragFax.AfterLoadDFMValues;
    dsBeleg2REDatum.AfterLoadDFMValues;
    dsBeleg2BenutzerNeu.AfterLoadDFMValues;
    dsBeleg2BenutzerGeaendert.AfterLoadDFMValues;
    dsBeleg2BerechnungBrutto.AfterLoadDFMValues;
    dsBeleg2Bruttosumme01.AfterLoadDFMValues;
    dsBeleg2Bruttosumme02.AfterLoadDFMValues;
    dsBeleg2Bruttosumme03.AfterLoadDFMValues;
    dsBeleg2Notiz.AfterLoadDFMValues;
    dsBeleg2MargeProzent.AfterLoadDFMValues;
    dsBeleg2Warenkreditgesperrt.AfterLoadDFMValues;
    dsBeleg2Belegsprache.AfterLoadDFMValues;
    dsBeleg2Duplicate.AfterLoadDFMValues;
    dsBeleg2perEmail.AfterLoadDFMValues;
    dsBeleg2Ansprechpartner.AfterLoadDFMValues;
    dsBeleg2KundennummerFremd.AfterLoadDFMValues;
    dsBeleg2Protokoll.AfterLoadDFMValues;
    dsBeleg2Verleihauftrag.AfterLoadDFMValues;
    dsBeleg2VerleihVon.AfterLoadDFMValues;
    dsBeleg2VerleihBis.AfterLoadDFMValues;
    dsBeleg2AuftragGenehmigt.AfterLoadDFMValues;
    dsBeleg2gesperrt.AfterLoadDFMValues;
    dsBeleg2VerleihvonText.AfterLoadDFMValues;
    dsBeleg2VerleihbisText.AfterLoadDFMValues;
    dsBeleg2DruckWHG.AfterLoadDFMValues;
    dsBeleg2DruckWHGKurs.AfterLoadDFMValues;
    dsBeleg2ProzentsatzZusatzposition.AfterLoadDFMValues;
    dsBeleg2TextZusatzposition.AfterLoadDFMValues;
    dsBeleg2TextzusatzBeschreibung.AfterLoadDFMValues;
    dsBeleg2Layoutname.AfterLoadDFMValues;
    dsBeleg2Gesamtgewicht.AfterLoadDFMValues;
    dsBeleg2BetragZusatzposition.AfterLoadDFMValues;
    dsBeleg2ReferenzBestellung.AfterLoadDFMValues;
    dsBeleg2Termin1.AfterLoadDFMValues;
    dsBeleg2Termin2.AfterLoadDFMValues;
    dsBeleg2Zielkontaktnummer.AfterLoadDFMValues;
    dsBeleg2Zielkontaktname.AfterLoadDFMValues;
    dsBeleg2Bilddatei1.AfterLoadDFMValues;
    dsBeleg2Bilddatei2.AfterLoadDFMValues;
    dsBeleg2Zielkontaktnummer2.AfterLoadDFMValues;
    dsBeleg2Zielkontaktname2.AfterLoadDFMValues;
    dsBeleg2bestaetigt.AfterLoadDFMValues;
    dsBeleg2Zielkontaktnummer3.AfterLoadDFMValues;
    dsBeleg2Zielkontaktname3.AfterLoadDFMValues;
    dsBeleg2Termin3.AfterLoadDFMValues;
    dsBeleg2Termin4.AfterLoadDFMValues;
    dsBeleg2Muster.AfterLoadDFMValues;
    dsBeleg2KW.AfterLoadDFMValues;
    dsBeleg2Datenlink.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz4.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz5.AfterLoadDFMValues;
    dsBeleg2Mehrwertsteuersatz6.AfterLoadDFMValues;
    dsBeleg2BesteuerungKey.AfterLoadDFMValues;
    dsBeleg2VertreterMatchcode.AfterLoadDFMValues;
    dsBeleg2Verfasser.AfterLoadDFMValues;
    dsBeleg2LieferadresseID.AfterLoadDFMValues;
    dsBeleg2AuftragStatus.AfterLoadDFMValues;
    dsBeleg2MonatJahr.AfterLoadDFMValues;
    dsBeleg2Belegvariante.AfterLoadDFMValues;
    dsBeleg2Produktionsfreigabe.AfterLoadDFMValues;
    dsBeleg2ReferenzRahmenAuftrag.AfterLoadDFMValues;
    dsBeleg2DatumRahmenAuftrag.AfterLoadDFMValues;
    dsBeleg2FahrzeugID.AfterLoadDFMValues;
    dsBeleg2AuslieferungKW.AfterLoadDFMValues;
    dsBeleg2BereitstellungDatum.AfterLoadDFMValues;
    dsBeleg2VTUID.AfterLoadDFMValues;
    dsBeleg2LieferanschriftDaten.AfterLoadDFMValues;
    dsBeleg2GesamtVolumen.AfterLoadDFMValues;
    dsBeleg2BelegPKey.AfterLoadDFMValues;
    dsBeleg2DruckParameter.AfterLoadDFMValues;
    dsBeleg2ReferenzService.AfterLoadDFMValues;
    dsBeleg2ZusatzDaten.AfterLoadDFMValues;
    dsBeleg2StandPDF.AfterLoadDFMValues;
    dsBeleg2Paketdienst.AfterLoadDFMValues;
    dsBeleg2Monteur.AfterLoadDFMValues;
    dsBeleg2KundennummerKundennummer.AfterLoadDFMValues;
    dsBeleg2ServiceBemerkung.AfterLoadDFMValues;
  end;
end;

end.