unit unit_ListeLieferschein;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Buttons,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, DB,
  WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.WebCtrls;

type
  TListeLieferschein = class(TForm)
    xdsLieferschein: TXDataWebDataSet;
    xdsLieferscheinNummerkomplett: TStringField;
    xdsLieferscheinReferenzAngebot: TStringField;
    xdsLieferscheinReferenzAuftragbest: TStringField;
    xdsLieferscheinReferenzRechnung: TStringField;
    xdsLieferscheinDatum: TDateTimeField;
    xdsLieferscheinJahr: TFloatField;
    xdsLieferscheinKalenderwoche: TIntegerField;
    xdsLieferscheinNachname: TStringField;
    xdsLieferscheinVorname: TStringField;
    xdsLieferscheinPostleitzahl: TStringField;
    xdsLieferscheinOrt: TStringField;
    xdsLieferscheinLaenderkuerzel: TStringField;
    xdsLieferscheinLand: TStringField;
    xdsLieferscheinBriefanrede1: TStringField;
    xdsLieferscheinBriefanrede2: TStringField;
    xdsLieferscheinEMail: TStringField;
    xdsLieferscheinAdresseBild: TStringField;
    xdsLieferscheinLieferanschriftBild: TStringField;
    xdsLieferscheinZahlungsbedingung: TIntegerField;
    xdsLieferscheinZahlungsbedingungText: TStringField;
    xdsLieferscheinAuftragsbezeichnung: TStringField;
    xdsLieferscheinAuftragnummerKunde: TStringField;
    xdsLieferscheinBestelldatumKunde: TDateTimeField;
    xdsLieferscheinILN: TStringField;
    xdsLieferscheinKopftext: TStringField;
    xdsLieferscheinSchlussbemerkung: TStringField;
    xdsLieferscheinGruss: TStringField;
    xdsLieferscheinMehrwertsteuersatz1: TFloatField;
    xdsLieferscheinMehrwertsteuersatz2: TFloatField;
    xdsLieferscheinNettosumme01: TFloatField;
    xdsLieferscheinNettosumme02: TFloatField;
    xdsLieferscheinNettosumme: TFloatField;
    xdsLieferscheinMehrwertsteuer01: TFloatField;
    xdsLieferscheinMehrwertsteuer02: TFloatField;
    xdsLieferscheinMehrwertsteuer: TFloatField;
    xdsLieferscheinBruttosumme: TFloatField;
    xdsLieferscheinVersanddatum: TDateTimeField;
    xdsLieferscheinSkontosatz: TFloatField;
    xdsLieferscheinNettosummeEK: TFloatField;
    xdsLieferscheinNettoMarge: TFloatField;
    xdsLieferscheinSchreibzeichen: TStringField;
    xdsLieferscheinUnterzeichner: TStringField;
    xdsLieferscheinTelefonMA: TStringField;
    xdsLieferscheinEmailMA: TStringField;
    xdsLieferscheinUSTID: TStringField;
    xdsLieferscheinUmsatzsteuerausweis: TBooleanField;
    xdsLieferscheingebucht: TBooleanField;
    xdsLieferscheinLieferdatum: TDateTimeField;
    xdsLieferscheinTransportbedingung: TStringField;
    xdsLieferscheinMehrwertsteuersatz3: TFloatField;
    xdsLieferscheinMehrwertsteuer03: TFloatField;
    xdsLieferscheinNettosumme03: TFloatField;
    xdsLieferscheinRundungsDifferenz: TFloatField;
    xdsLieferscheinEmailText: TStringField;
    xdsLieferscheinRabattstufe: TStringField;
    xdsLieferscheinDebitorennummer: TStringField;
    xdsLieferscheinKostentraeger: TStringField;
    xdsLieferscheinRabattsatz: TFloatField;
    xdsLieferscheinObjektReferenz: TStringField;
    xdsLieferscheinBank: TStringField;
    xdsLieferscheinBankleitzahl: TFloatField;
    xdsLieferscheinKontonummer: TStringField;
    xdsLieferscheinKontoinhaber: TStringField;
    xdsLieferscheinTelefaxnummer: TStringField;
    xdsLieferscheinDatumNeu: TDateTimeField;
    xdsLieferscheinDatumgeaendert: TDateTimeField;
    xdsLieferscheinObjektnummer: TStringField;
    xdsLieferscheinLiefertermin: TStringField;
    xdsLieferscheinEmailversand: TBooleanField;
    xdsLieferscheinTelefonnummer: TStringField;
    xdsLieferscheinMobilTelefon: TStringField;
    xdsLieferscheinBenutzerNeu: TStringField;
    xdsLieferscheinBenutzerGeaendert: TStringField;
    xdsLieferscheinBerechnungBrutto: TBooleanField;
    xdsLieferscheinBruttosumme01: TFloatField;
    xdsLieferscheinBruttosumme02: TFloatField;
    xdsLieferscheinBruttosumme03: TFloatField;
    xdsLieferscheinNotiz: TStringField;
    xdsLieferscheinMargeProzent: TFloatField;
    xdsLieferscheinBelegsprache: TStringField;
    xdsLieferscheinDuplicate: TStringField;
    xdsLieferscheinAnsprechpartner: TStringField;
    xdsLieferscheinKundennummerFremd: TStringField;
    xdsLieferscheinProtokoll: TStringField;
    xdsLieferscheinVollstaendigFakturiert: TBooleanField;
    xdsLieferscheinVerleihauftrag: TBooleanField;
    xdsLieferscheinVerleihVon: TDateTimeField;
    xdsLieferscheinVerleihBis: TDateTimeField;
    xdsLieferscheinRueckgabeschein: TBooleanField;
    xdsLieferscheinRueckgabeReferenz: TStringField;
    xdsLieferscheingesperrt: TBooleanField;
    xdsLieferscheinVerleihvonText: TStringField;
    xdsLieferscheinVerleihbisText: TStringField;
    xdsLieferscheinNotizExtern: TStringField;
    xdsLieferscheinDruckWHG: TStringField;
    xdsLieferscheinDruckWHGKurs: TFloatField;
    xdsLieferscheinProzentsatzZusatzposition: TFloatField;
    xdsLieferscheinTextZusatzposition: TStringField;
    xdsLieferscheinTextzusatzBeschreibung: TStringField;
    xdsLieferscheinExport: TBooleanField;
    xdsLieferscheinExportbescheinigung: TBooleanField;
    xdsLieferscheinBestimmungsland: TStringField;
    xdsLieferscheinLayoutname: TStringField;
    xdsLieferscheinGesamtgewicht: TFloatField;
    xdsLieferscheinBetragZusatzposition: TFloatField;
    xdsLieferscheinPaketdienst: TStringField;
    xdsLieferscheinPaketnummer: TStringField;
    xdsLieferscheinMehrwertsteuersatz4: TFloatField;
    xdsLieferscheinMehrwertsteuersatz5: TFloatField;
    xdsLieferscheinMehrwertsteuersatz6: TFloatField;
    xdsLieferscheinBesteuerungKey: TIntegerField;
    xdsLieferscheinVertreterMatchcode: TStringField;
    xdsLieferscheinVerfasser: TStringField;
    xdsLieferscheinLieferadresseID: TIntegerField;
    xdsLieferscheinBelegvariante: TStringField;
    xdsLieferscheinBereitstellungDatum: TDateTimeField;
    xdsLieferscheinBereitstellungErledigt: TBooleanField;
    xdsLieferscheinFahrzeugID: TIntegerField;
    xdsLieferscheinMonatJahr: TDateTimeField;
    xdsLieferscheinLieferanschriftDaten: TStringField;
    xdsLieferscheinGesamtVolumen: TFloatField;
    xdsLieferscheinBelegPKey: TIntegerField;
    xdsLieferscheinDruckParameter: TStringField;
    xdsLieferscheinFakturierungNichtVor: TDateTimeField;
    xdsLieferscheinQuittungSIG: TBlobField;
    xdsLieferscheinKundennummerKundennummer: TStringField;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    Container3: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure xdsLieferscheinAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    buttonzurueck:TButton;
    EinzelCard:THTMLDiv;
    procedure buttonzurueckclick(Sender:TObject);
    procedure EinzelCardclick(Sender:TObject);
  end;

var
  ListeLieferschein: TListeLieferschein;

implementation
  uses unit1, unit2, unit_EinzelLieferschein;

procedure TListeLieferschein.buttonzurueckclick(Sender:TObject);
begin
  xdsLieferschein.Close;
  self.close;
end;

procedure TListeLieferschein.EinzelCardclick(Sender:TObject);
var
  EinzelLieferschein:TEinzelLieferschein;
  MyMerkerLieferschein:string;
begin
  myMerkerLieferschein:=(sender as THTMLDiv).ElementID;
  Container3.HTML.text:='';
  EinzelLieferschein := TEinzelLieferschein.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEinzelLieferschein).MerkerLieferschein:=myMerkerLieferschein;
    end
    );

  EinzelLieferschein.ShowModal(procedure(AValue: TModalResult)
    begin
      xdsLieferschein.close;
      xdsLieferschein.Load;
    end
    );
end;



procedure TListeLieferschein.WebFormShow(Sender: TObject);
begin
  xdsLieferschein.Load;
end;

procedure TListeLieferschein.xdsLieferscheinAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
  EinzelKarte:string;
begin

  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Lieferscheine offen</h5></nav>';

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info m-1 text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;

  while not xdsLieferschein.eof do
  begin

    Einzelkarte:='<div class="card-header"><h5>'+xdsLieferscheinNummerkomplett.asstring+' | '+formatdatetime('DD.MM.YYYY',xdsLieferscheinDatum.AsDateTime)+'</h5><i>'+xdsLieferscheinBelegvariante.AsString+'</i></div>';
    Einzelkarte:=Einzelkarte+'<div class="card-body ">'+xdsLieferscheinKundennummerKundennummer.AsString;
    Einzelkarte:=Einzelkarte+' | '+xdsLieferscheinNachname.asstring+'<br>';
    Einzelkarte:=Einzelkarte+'<b>'+xdsLieferscheinAuftragsbezeichnung.AsString+'</b><br>';
    Einzelkarte:=Einzelkarte+'<b>'+xdsLieferscheinLieferanschriftbild.AsString+'</b><br>';
    Einzelkarte:=Einzelkarte+'</div>';


    myelement:=Container3.ElementHandle;
    EinzelCard:=THTMLDiv.Create(self);
    EinzelCard.ElementID:=xdsLieferscheinNummerkomplett.asstring;
    EinzelCard.HeightStyle:=ssauto;
    EinzelCard.Width:=350;
    EinzelCard.HTML.Text:=Einzelkarte;
    EinzelCard.ElementClassName:='card m-1 border-info';
    EinzelCard.OnClick:=@EinzelCardClick;
    EinzelCard.ParentElement:=myelement;

    xdsLieferschein.Next;
  end;

end;

procedure TListeLieferschein.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  xdsLieferschein := TXDataWebDataSet.Create(Self);
  xdsLieferscheinNummerkomplett := TStringField.Create(Self);
  xdsLieferscheinReferenzAngebot := TStringField.Create(Self);
  xdsLieferscheinReferenzAuftragbest := TStringField.Create(Self);
  xdsLieferscheinReferenzRechnung := TStringField.Create(Self);
  xdsLieferscheinDatum := TDateTimeField.Create(Self);
  xdsLieferscheinJahr := TFloatField.Create(Self);
  xdsLieferscheinKalenderwoche := TIntegerField.Create(Self);
  xdsLieferscheinNachname := TStringField.Create(Self);
  xdsLieferscheinVorname := TStringField.Create(Self);
  xdsLieferscheinPostleitzahl := TStringField.Create(Self);
  xdsLieferscheinOrt := TStringField.Create(Self);
  xdsLieferscheinLaenderkuerzel := TStringField.Create(Self);
  xdsLieferscheinLand := TStringField.Create(Self);
  xdsLieferscheinBriefanrede1 := TStringField.Create(Self);
  xdsLieferscheinBriefanrede2 := TStringField.Create(Self);
  xdsLieferscheinEMail := TStringField.Create(Self);
  xdsLieferscheinAdresseBild := TStringField.Create(Self);
  xdsLieferscheinLieferanschriftBild := TStringField.Create(Self);
  xdsLieferscheinZahlungsbedingung := TIntegerField.Create(Self);
  xdsLieferscheinZahlungsbedingungText := TStringField.Create(Self);
  xdsLieferscheinAuftragsbezeichnung := TStringField.Create(Self);
  xdsLieferscheinAuftragnummerKunde := TStringField.Create(Self);
  xdsLieferscheinBestelldatumKunde := TDateTimeField.Create(Self);
  xdsLieferscheinILN := TStringField.Create(Self);
  xdsLieferscheinKopftext := TStringField.Create(Self);
  xdsLieferscheinSchlussbemerkung := TStringField.Create(Self);
  xdsLieferscheinGruss := TStringField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz1 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz2 := TFloatField.Create(Self);
  xdsLieferscheinNettosumme01 := TFloatField.Create(Self);
  xdsLieferscheinNettosumme02 := TFloatField.Create(Self);
  xdsLieferscheinNettosumme := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuer01 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuer02 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuer := TFloatField.Create(Self);
  xdsLieferscheinBruttosumme := TFloatField.Create(Self);
  xdsLieferscheinVersanddatum := TDateTimeField.Create(Self);
  xdsLieferscheinSkontosatz := TFloatField.Create(Self);
  xdsLieferscheinNettosummeEK := TFloatField.Create(Self);
  xdsLieferscheinNettoMarge := TFloatField.Create(Self);
  xdsLieferscheinSchreibzeichen := TStringField.Create(Self);
  xdsLieferscheinUnterzeichner := TStringField.Create(Self);
  xdsLieferscheinTelefonMA := TStringField.Create(Self);
  xdsLieferscheinEmailMA := TStringField.Create(Self);
  xdsLieferscheinUSTID := TStringField.Create(Self);
  xdsLieferscheinUmsatzsteuerausweis := TBooleanField.Create(Self);
  xdsLieferscheingebucht := TBooleanField.Create(Self);
  xdsLieferscheinLieferdatum := TDateTimeField.Create(Self);
  xdsLieferscheinTransportbedingung := TStringField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz3 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuer03 := TFloatField.Create(Self);
  xdsLieferscheinNettosumme03 := TFloatField.Create(Self);
  xdsLieferscheinRundungsDifferenz := TFloatField.Create(Self);
  xdsLieferscheinEmailText := TStringField.Create(Self);
  xdsLieferscheinRabattstufe := TStringField.Create(Self);
  xdsLieferscheinDebitorennummer := TStringField.Create(Self);
  xdsLieferscheinKostentraeger := TStringField.Create(Self);
  xdsLieferscheinRabattsatz := TFloatField.Create(Self);
  xdsLieferscheinObjektReferenz := TStringField.Create(Self);
  xdsLieferscheinBank := TStringField.Create(Self);
  xdsLieferscheinBankleitzahl := TFloatField.Create(Self);
  xdsLieferscheinKontonummer := TStringField.Create(Self);
  xdsLieferscheinKontoinhaber := TStringField.Create(Self);
  xdsLieferscheinTelefaxnummer := TStringField.Create(Self);
  xdsLieferscheinDatumNeu := TDateTimeField.Create(Self);
  xdsLieferscheinDatumgeaendert := TDateTimeField.Create(Self);
  xdsLieferscheinObjektnummer := TStringField.Create(Self);
  xdsLieferscheinLiefertermin := TStringField.Create(Self);
  xdsLieferscheinEmailversand := TBooleanField.Create(Self);
  xdsLieferscheinTelefonnummer := TStringField.Create(Self);
  xdsLieferscheinMobilTelefon := TStringField.Create(Self);
  xdsLieferscheinBenutzerNeu := TStringField.Create(Self);
  xdsLieferscheinBenutzerGeaendert := TStringField.Create(Self);
  xdsLieferscheinBerechnungBrutto := TBooleanField.Create(Self);
  xdsLieferscheinBruttosumme01 := TFloatField.Create(Self);
  xdsLieferscheinBruttosumme02 := TFloatField.Create(Self);
  xdsLieferscheinBruttosumme03 := TFloatField.Create(Self);
  xdsLieferscheinNotiz := TStringField.Create(Self);
  xdsLieferscheinMargeProzent := TFloatField.Create(Self);
  xdsLieferscheinBelegsprache := TStringField.Create(Self);
  xdsLieferscheinDuplicate := TStringField.Create(Self);
  xdsLieferscheinAnsprechpartner := TStringField.Create(Self);
  xdsLieferscheinKundennummerFremd := TStringField.Create(Self);
  xdsLieferscheinProtokoll := TStringField.Create(Self);
  xdsLieferscheinVollstaendigFakturiert := TBooleanField.Create(Self);
  xdsLieferscheinVerleihauftrag := TBooleanField.Create(Self);
  xdsLieferscheinVerleihVon := TDateTimeField.Create(Self);
  xdsLieferscheinVerleihBis := TDateTimeField.Create(Self);
  xdsLieferscheinRueckgabeschein := TBooleanField.Create(Self);
  xdsLieferscheinRueckgabeReferenz := TStringField.Create(Self);
  xdsLieferscheingesperrt := TBooleanField.Create(Self);
  xdsLieferscheinVerleihvonText := TStringField.Create(Self);
  xdsLieferscheinVerleihbisText := TStringField.Create(Self);
  xdsLieferscheinNotizExtern := TStringField.Create(Self);
  xdsLieferscheinDruckWHG := TStringField.Create(Self);
  xdsLieferscheinDruckWHGKurs := TFloatField.Create(Self);
  xdsLieferscheinProzentsatzZusatzposition := TFloatField.Create(Self);
  xdsLieferscheinTextZusatzposition := TStringField.Create(Self);
  xdsLieferscheinTextzusatzBeschreibung := TStringField.Create(Self);
  xdsLieferscheinExport := TBooleanField.Create(Self);
  xdsLieferscheinExportbescheinigung := TBooleanField.Create(Self);
  xdsLieferscheinBestimmungsland := TStringField.Create(Self);
  xdsLieferscheinLayoutname := TStringField.Create(Self);
  xdsLieferscheinGesamtgewicht := TFloatField.Create(Self);
  xdsLieferscheinBetragZusatzposition := TFloatField.Create(Self);
  xdsLieferscheinPaketdienst := TStringField.Create(Self);
  xdsLieferscheinPaketnummer := TStringField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz4 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz5 := TFloatField.Create(Self);
  xdsLieferscheinMehrwertsteuersatz6 := TFloatField.Create(Self);
  xdsLieferscheinBesteuerungKey := TIntegerField.Create(Self);
  xdsLieferscheinVertreterMatchcode := TStringField.Create(Self);
  xdsLieferscheinVerfasser := TStringField.Create(Self);
  xdsLieferscheinLieferadresseID := TIntegerField.Create(Self);
  xdsLieferscheinBelegvariante := TStringField.Create(Self);
  xdsLieferscheinBereitstellungDatum := TDateTimeField.Create(Self);
  xdsLieferscheinBereitstellungErledigt := TBooleanField.Create(Self);
  xdsLieferscheinFahrzeugID := TIntegerField.Create(Self);
  xdsLieferscheinMonatJahr := TDateTimeField.Create(Self);
  xdsLieferscheinLieferanschriftDaten := TStringField.Create(Self);
  xdsLieferscheinGesamtVolumen := TFloatField.Create(Self);
  xdsLieferscheinBelegPKey := TIntegerField.Create(Self);
  xdsLieferscheinDruckParameter := TStringField.Create(Self);
  xdsLieferscheinFakturierungNichtVor := TDateTimeField.Create(Self);
  xdsLieferscheinQuittungSIG := TBlobField.Create(Self);
  xdsLieferscheinKundennummerKundennummer := TStringField.Create(Self);

  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  xdsLieferschein.BeforeLoadDFMValues;
  xdsLieferscheinNummerkomplett.BeforeLoadDFMValues;
  xdsLieferscheinReferenzAngebot.BeforeLoadDFMValues;
  xdsLieferscheinReferenzAuftragbest.BeforeLoadDFMValues;
  xdsLieferscheinReferenzRechnung.BeforeLoadDFMValues;
  xdsLieferscheinDatum.BeforeLoadDFMValues;
  xdsLieferscheinJahr.BeforeLoadDFMValues;
  xdsLieferscheinKalenderwoche.BeforeLoadDFMValues;
  xdsLieferscheinNachname.BeforeLoadDFMValues;
  xdsLieferscheinVorname.BeforeLoadDFMValues;
  xdsLieferscheinPostleitzahl.BeforeLoadDFMValues;
  xdsLieferscheinOrt.BeforeLoadDFMValues;
  xdsLieferscheinLaenderkuerzel.BeforeLoadDFMValues;
  xdsLieferscheinLand.BeforeLoadDFMValues;
  xdsLieferscheinBriefanrede1.BeforeLoadDFMValues;
  xdsLieferscheinBriefanrede2.BeforeLoadDFMValues;
  xdsLieferscheinEMail.BeforeLoadDFMValues;
  xdsLieferscheinAdresseBild.BeforeLoadDFMValues;
  xdsLieferscheinLieferanschriftBild.BeforeLoadDFMValues;
  xdsLieferscheinZahlungsbedingung.BeforeLoadDFMValues;
  xdsLieferscheinZahlungsbedingungText.BeforeLoadDFMValues;
  xdsLieferscheinAuftragsbezeichnung.BeforeLoadDFMValues;
  xdsLieferscheinAuftragnummerKunde.BeforeLoadDFMValues;
  xdsLieferscheinBestelldatumKunde.BeforeLoadDFMValues;
  xdsLieferscheinILN.BeforeLoadDFMValues;
  xdsLieferscheinKopftext.BeforeLoadDFMValues;
  xdsLieferscheinSchlussbemerkung.BeforeLoadDFMValues;
  xdsLieferscheinGruss.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz1.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz2.BeforeLoadDFMValues;
  xdsLieferscheinNettosumme01.BeforeLoadDFMValues;
  xdsLieferscheinNettosumme02.BeforeLoadDFMValues;
  xdsLieferscheinNettosumme.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuer01.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuer02.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuer.BeforeLoadDFMValues;
  xdsLieferscheinBruttosumme.BeforeLoadDFMValues;
  xdsLieferscheinVersanddatum.BeforeLoadDFMValues;
  xdsLieferscheinSkontosatz.BeforeLoadDFMValues;
  xdsLieferscheinNettosummeEK.BeforeLoadDFMValues;
  xdsLieferscheinNettoMarge.BeforeLoadDFMValues;
  xdsLieferscheinSchreibzeichen.BeforeLoadDFMValues;
  xdsLieferscheinUnterzeichner.BeforeLoadDFMValues;
  xdsLieferscheinTelefonMA.BeforeLoadDFMValues;
  xdsLieferscheinEmailMA.BeforeLoadDFMValues;
  xdsLieferscheinUSTID.BeforeLoadDFMValues;
  xdsLieferscheinUmsatzsteuerausweis.BeforeLoadDFMValues;
  xdsLieferscheingebucht.BeforeLoadDFMValues;
  xdsLieferscheinLieferdatum.BeforeLoadDFMValues;
  xdsLieferscheinTransportbedingung.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz3.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuer03.BeforeLoadDFMValues;
  xdsLieferscheinNettosumme03.BeforeLoadDFMValues;
  xdsLieferscheinRundungsDifferenz.BeforeLoadDFMValues;
  xdsLieferscheinEmailText.BeforeLoadDFMValues;
  xdsLieferscheinRabattstufe.BeforeLoadDFMValues;
  xdsLieferscheinDebitorennummer.BeforeLoadDFMValues;
  xdsLieferscheinKostentraeger.BeforeLoadDFMValues;
  xdsLieferscheinRabattsatz.BeforeLoadDFMValues;
  xdsLieferscheinObjektReferenz.BeforeLoadDFMValues;
  xdsLieferscheinBank.BeforeLoadDFMValues;
  xdsLieferscheinBankleitzahl.BeforeLoadDFMValues;
  xdsLieferscheinKontonummer.BeforeLoadDFMValues;
  xdsLieferscheinKontoinhaber.BeforeLoadDFMValues;
  xdsLieferscheinTelefaxnummer.BeforeLoadDFMValues;
  xdsLieferscheinDatumNeu.BeforeLoadDFMValues;
  xdsLieferscheinDatumgeaendert.BeforeLoadDFMValues;
  xdsLieferscheinObjektnummer.BeforeLoadDFMValues;
  xdsLieferscheinLiefertermin.BeforeLoadDFMValues;
  xdsLieferscheinEmailversand.BeforeLoadDFMValues;
  xdsLieferscheinTelefonnummer.BeforeLoadDFMValues;
  xdsLieferscheinMobilTelefon.BeforeLoadDFMValues;
  xdsLieferscheinBenutzerNeu.BeforeLoadDFMValues;
  xdsLieferscheinBenutzerGeaendert.BeforeLoadDFMValues;
  xdsLieferscheinBerechnungBrutto.BeforeLoadDFMValues;
  xdsLieferscheinBruttosumme01.BeforeLoadDFMValues;
  xdsLieferscheinBruttosumme02.BeforeLoadDFMValues;
  xdsLieferscheinBruttosumme03.BeforeLoadDFMValues;
  xdsLieferscheinNotiz.BeforeLoadDFMValues;
  xdsLieferscheinMargeProzent.BeforeLoadDFMValues;
  xdsLieferscheinBelegsprache.BeforeLoadDFMValues;
  xdsLieferscheinDuplicate.BeforeLoadDFMValues;
  xdsLieferscheinAnsprechpartner.BeforeLoadDFMValues;
  xdsLieferscheinKundennummerFremd.BeforeLoadDFMValues;
  xdsLieferscheinProtokoll.BeforeLoadDFMValues;
  xdsLieferscheinVollstaendigFakturiert.BeforeLoadDFMValues;
  xdsLieferscheinVerleihauftrag.BeforeLoadDFMValues;
  xdsLieferscheinVerleihVon.BeforeLoadDFMValues;
  xdsLieferscheinVerleihBis.BeforeLoadDFMValues;
  xdsLieferscheinRueckgabeschein.BeforeLoadDFMValues;
  xdsLieferscheinRueckgabeReferenz.BeforeLoadDFMValues;
  xdsLieferscheingesperrt.BeforeLoadDFMValues;
  xdsLieferscheinVerleihvonText.BeforeLoadDFMValues;
  xdsLieferscheinVerleihbisText.BeforeLoadDFMValues;
  xdsLieferscheinNotizExtern.BeforeLoadDFMValues;
  xdsLieferscheinDruckWHG.BeforeLoadDFMValues;
  xdsLieferscheinDruckWHGKurs.BeforeLoadDFMValues;
  xdsLieferscheinProzentsatzZusatzposition.BeforeLoadDFMValues;
  xdsLieferscheinTextZusatzposition.BeforeLoadDFMValues;
  xdsLieferscheinTextzusatzBeschreibung.BeforeLoadDFMValues;
  xdsLieferscheinExport.BeforeLoadDFMValues;
  xdsLieferscheinExportbescheinigung.BeforeLoadDFMValues;
  xdsLieferscheinBestimmungsland.BeforeLoadDFMValues;
  xdsLieferscheinLayoutname.BeforeLoadDFMValues;
  xdsLieferscheinGesamtgewicht.BeforeLoadDFMValues;
  xdsLieferscheinBetragZusatzposition.BeforeLoadDFMValues;
  xdsLieferscheinPaketdienst.BeforeLoadDFMValues;
  xdsLieferscheinPaketnummer.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz4.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz5.BeforeLoadDFMValues;
  xdsLieferscheinMehrwertsteuersatz6.BeforeLoadDFMValues;
  xdsLieferscheinBesteuerungKey.BeforeLoadDFMValues;
  xdsLieferscheinVertreterMatchcode.BeforeLoadDFMValues;
  xdsLieferscheinVerfasser.BeforeLoadDFMValues;
  xdsLieferscheinLieferadresseID.BeforeLoadDFMValues;
  xdsLieferscheinBelegvariante.BeforeLoadDFMValues;
  xdsLieferscheinBereitstellungDatum.BeforeLoadDFMValues;
  xdsLieferscheinBereitstellungErledigt.BeforeLoadDFMValues;
  xdsLieferscheinFahrzeugID.BeforeLoadDFMValues;
  xdsLieferscheinMonatJahr.BeforeLoadDFMValues;
  xdsLieferscheinLieferanschriftDaten.BeforeLoadDFMValues;
  xdsLieferscheinGesamtVolumen.BeforeLoadDFMValues;
  xdsLieferscheinBelegPKey.BeforeLoadDFMValues;
  xdsLieferscheinDruckParameter.BeforeLoadDFMValues;
  xdsLieferscheinFakturierungNichtVor.BeforeLoadDFMValues;
  xdsLieferscheinQuittungSIG.BeforeLoadDFMValues;
  xdsLieferscheinKundennummerKundennummer.BeforeLoadDFMValues;
  try
    Name := 'ListeLieferschein';
    Width := 174;
    Height := 202;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 1;
    Container1.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    xdsLieferschein.SetParentComponent(Self);
    xdsLieferschein.Name := 'xdsLieferschein';
    xdsLieferschein.AfterOpen := xdsLieferscheinAfterOpen;
    xdsLieferschein.EntitySetName := 'Lieferschein';
    xdsLieferschein.Connection := DM2.XDataWebConnection1;
    xdsLieferschein.SubpropsDepth := 1;
    xdsLieferschein.QueryString := '$expand=Kundennummer&$filter=(VollstaendigFakturiert eq 0)&$orderby=Nummerkomplett DESC';
    xdsLieferschein.QueryTop := 100;
    xdsLieferschein.Left := 36;
    xdsLieferschein.Top := 134;
    xdsLieferscheinNummerkomplett.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNummerkomplett.Name := 'xdsLieferscheinNummerkomplett';
    xdsLieferscheinNummerkomplett.FieldName := 'Nummerkomplett';
    xdsLieferscheinNummerkomplett.Required := True;
    xdsLieferscheinNummerkomplett.Size := 10;
    xdsLieferscheinReferenzAngebot.SetParentComponent(xdsLieferschein);
    xdsLieferscheinReferenzAngebot.Name := 'xdsLieferscheinReferenzAngebot';
    xdsLieferscheinReferenzAngebot.FieldName := 'ReferenzAngebot';
    xdsLieferscheinReferenzAngebot.Size := 10;
    xdsLieferscheinReferenzAuftragbest.SetParentComponent(xdsLieferschein);
    xdsLieferscheinReferenzAuftragbest.Name := 'xdsLieferscheinReferenzAuftragbest';
    xdsLieferscheinReferenzAuftragbest.FieldName := 'ReferenzAuftragbest';
    xdsLieferscheinReferenzAuftragbest.Size := 10;
    xdsLieferscheinReferenzRechnung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinReferenzRechnung.Name := 'xdsLieferscheinReferenzRechnung';
    xdsLieferscheinReferenzRechnung.FieldName := 'ReferenzRechnung';
    xdsLieferscheinReferenzRechnung.Size := 10;
    xdsLieferscheinDatum.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDatum.Name := 'xdsLieferscheinDatum';
    xdsLieferscheinDatum.FieldName := 'Datum';
    xdsLieferscheinDatum.Required := True;
    xdsLieferscheinJahr.SetParentComponent(xdsLieferschein);
    xdsLieferscheinJahr.Name := 'xdsLieferscheinJahr';
    xdsLieferscheinJahr.FieldName := 'Jahr';
    xdsLieferscheinKalenderwoche.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKalenderwoche.Name := 'xdsLieferscheinKalenderwoche';
    xdsLieferscheinKalenderwoche.FieldName := 'Kalenderwoche';
    xdsLieferscheinNachname.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNachname.Name := 'xdsLieferscheinNachname';
    xdsLieferscheinNachname.FieldName := 'Nachname';
    xdsLieferscheinNachname.Size := 50;
    xdsLieferscheinVorname.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVorname.Name := 'xdsLieferscheinVorname';
    xdsLieferscheinVorname.FieldName := 'Vorname';
    xdsLieferscheinVorname.Size := 50;
    xdsLieferscheinPostleitzahl.SetParentComponent(xdsLieferschein);
    xdsLieferscheinPostleitzahl.Name := 'xdsLieferscheinPostleitzahl';
    xdsLieferscheinPostleitzahl.FieldName := 'Postleitzahl';
    xdsLieferscheinPostleitzahl.Size := 10;
    xdsLieferscheinOrt.SetParentComponent(xdsLieferschein);
    xdsLieferscheinOrt.Name := 'xdsLieferscheinOrt';
    xdsLieferscheinOrt.FieldName := 'Ort';
    xdsLieferscheinOrt.Size := 50;
    xdsLieferscheinLaenderkuerzel.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLaenderkuerzel.Name := 'xdsLieferscheinLaenderkuerzel';
    xdsLieferscheinLaenderkuerzel.FieldName := 'Laenderkuerzel';
    xdsLieferscheinLaenderkuerzel.Size := 5;
    xdsLieferscheinLand.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLand.Name := 'xdsLieferscheinLand';
    xdsLieferscheinLand.FieldName := 'Land';
    xdsLieferscheinLand.Size := 50;
    xdsLieferscheinBriefanrede1.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBriefanrede1.Name := 'xdsLieferscheinBriefanrede1';
    xdsLieferscheinBriefanrede1.FieldName := 'Briefanrede1';
    xdsLieferscheinBriefanrede1.Size := 255;
    xdsLieferscheinBriefanrede2.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBriefanrede2.Name := 'xdsLieferscheinBriefanrede2';
    xdsLieferscheinBriefanrede2.FieldName := 'Briefanrede2';
    xdsLieferscheinBriefanrede2.Size := 40;
    xdsLieferscheinEMail.SetParentComponent(xdsLieferschein);
    xdsLieferscheinEMail.Name := 'xdsLieferscheinEMail';
    xdsLieferscheinEMail.FieldName := 'EMail';
    xdsLieferscheinEMail.Size := 50;
    xdsLieferscheinAdresseBild.SetParentComponent(xdsLieferschein);
    xdsLieferscheinAdresseBild.Name := 'xdsLieferscheinAdresseBild';
    xdsLieferscheinAdresseBild.FieldName := 'AdresseBild';
    xdsLieferscheinAdresseBild.Size := 255;
    xdsLieferscheinLieferanschriftBild.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLieferanschriftBild.Name := 'xdsLieferscheinLieferanschriftBild';
    xdsLieferscheinLieferanschriftBild.FieldName := 'LieferanschriftBild';
    xdsLieferscheinLieferanschriftBild.Size := 255;
    xdsLieferscheinZahlungsbedingung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinZahlungsbedingung.Name := 'xdsLieferscheinZahlungsbedingung';
    xdsLieferscheinZahlungsbedingung.FieldName := 'Zahlungsbedingung';
    xdsLieferscheinZahlungsbedingungText.SetParentComponent(xdsLieferschein);
    xdsLieferscheinZahlungsbedingungText.Name := 'xdsLieferscheinZahlungsbedingungText';
    xdsLieferscheinZahlungsbedingungText.FieldName := 'ZahlungsbedingungText';
    xdsLieferscheinZahlungsbedingungText.Size := 250;
    xdsLieferscheinAuftragsbezeichnung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinAuftragsbezeichnung.Name := 'xdsLieferscheinAuftragsbezeichnung';
    xdsLieferscheinAuftragsbezeichnung.FieldName := 'Auftragsbezeichnung';
    xdsLieferscheinAuftragsbezeichnung.Size := 255;
    xdsLieferscheinAuftragnummerKunde.SetParentComponent(xdsLieferschein);
    xdsLieferscheinAuftragnummerKunde.Name := 'xdsLieferscheinAuftragnummerKunde';
    xdsLieferscheinAuftragnummerKunde.FieldName := 'AuftragnummerKunde';
    xdsLieferscheinAuftragnummerKunde.Size := 50;
    xdsLieferscheinBestelldatumKunde.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBestelldatumKunde.Name := 'xdsLieferscheinBestelldatumKunde';
    xdsLieferscheinBestelldatumKunde.FieldName := 'BestelldatumKunde';
    xdsLieferscheinILN.SetParentComponent(xdsLieferschein);
    xdsLieferscheinILN.Name := 'xdsLieferscheinILN';
    xdsLieferscheinILN.FieldName := 'ILN';
    xdsLieferscheinILN.Size := 50;
    xdsLieferscheinKopftext.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKopftext.Name := 'xdsLieferscheinKopftext';
    xdsLieferscheinKopftext.FieldName := 'Kopftext';
    xdsLieferscheinKopftext.Size := 255;
    xdsLieferscheinSchlussbemerkung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinSchlussbemerkung.Name := 'xdsLieferscheinSchlussbemerkung';
    xdsLieferscheinSchlussbemerkung.FieldName := 'Schlussbemerkung';
    xdsLieferscheinSchlussbemerkung.Size := 255;
    xdsLieferscheinGruss.SetParentComponent(xdsLieferschein);
    xdsLieferscheinGruss.Name := 'xdsLieferscheinGruss';
    xdsLieferscheinGruss.FieldName := 'Gruss';
    xdsLieferscheinGruss.Size := 255;
    xdsLieferscheinMehrwertsteuersatz1.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz1.Name := 'xdsLieferscheinMehrwertsteuersatz1';
    xdsLieferscheinMehrwertsteuersatz1.FieldName := 'Mehrwertsteuersatz1';
    xdsLieferscheinMehrwertsteuersatz2.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz2.Name := 'xdsLieferscheinMehrwertsteuersatz2';
    xdsLieferscheinMehrwertsteuersatz2.FieldName := 'Mehrwertsteuersatz2';
    xdsLieferscheinNettosumme01.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettosumme01.Name := 'xdsLieferscheinNettosumme01';
    xdsLieferscheinNettosumme01.FieldName := 'Nettosumme01';
    xdsLieferscheinNettosumme02.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettosumme02.Name := 'xdsLieferscheinNettosumme02';
    xdsLieferscheinNettosumme02.FieldName := 'Nettosumme02';
    xdsLieferscheinNettosumme.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettosumme.Name := 'xdsLieferscheinNettosumme';
    xdsLieferscheinNettosumme.FieldName := 'Nettosumme';
    xdsLieferscheinMehrwertsteuer01.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuer01.Name := 'xdsLieferscheinMehrwertsteuer01';
    xdsLieferscheinMehrwertsteuer01.FieldName := 'Mehrwertsteuer01';
    xdsLieferscheinMehrwertsteuer02.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuer02.Name := 'xdsLieferscheinMehrwertsteuer02';
    xdsLieferscheinMehrwertsteuer02.FieldName := 'Mehrwertsteuer02';
    xdsLieferscheinMehrwertsteuer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuer.Name := 'xdsLieferscheinMehrwertsteuer';
    xdsLieferscheinMehrwertsteuer.FieldName := 'Mehrwertsteuer';
    xdsLieferscheinBruttosumme.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBruttosumme.Name := 'xdsLieferscheinBruttosumme';
    xdsLieferscheinBruttosumme.FieldName := 'Bruttosumme';
    xdsLieferscheinVersanddatum.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVersanddatum.Name := 'xdsLieferscheinVersanddatum';
    xdsLieferscheinVersanddatum.FieldName := 'Versanddatum';
    xdsLieferscheinSkontosatz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinSkontosatz.Name := 'xdsLieferscheinSkontosatz';
    xdsLieferscheinSkontosatz.FieldName := 'Skontosatz';
    xdsLieferscheinNettosummeEK.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettosummeEK.Name := 'xdsLieferscheinNettosummeEK';
    xdsLieferscheinNettosummeEK.FieldName := 'NettosummeEK';
    xdsLieferscheinNettoMarge.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettoMarge.Name := 'xdsLieferscheinNettoMarge';
    xdsLieferscheinNettoMarge.FieldName := 'NettoMarge';
    xdsLieferscheinSchreibzeichen.SetParentComponent(xdsLieferschein);
    xdsLieferscheinSchreibzeichen.Name := 'xdsLieferscheinSchreibzeichen';
    xdsLieferscheinSchreibzeichen.FieldName := 'Schreibzeichen';
    xdsLieferscheinSchreibzeichen.Size := 10;
    xdsLieferscheinUnterzeichner.SetParentComponent(xdsLieferschein);
    xdsLieferscheinUnterzeichner.Name := 'xdsLieferscheinUnterzeichner';
    xdsLieferscheinUnterzeichner.FieldName := 'Unterzeichner';
    xdsLieferscheinUnterzeichner.Size := 50;
    xdsLieferscheinTelefonMA.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTelefonMA.Name := 'xdsLieferscheinTelefonMA';
    xdsLieferscheinTelefonMA.FieldName := 'TelefonMA';
    xdsLieferscheinTelefonMA.Size := 30;
    xdsLieferscheinEmailMA.SetParentComponent(xdsLieferschein);
    xdsLieferscheinEmailMA.Name := 'xdsLieferscheinEmailMA';
    xdsLieferscheinEmailMA.FieldName := 'EmailMA';
    xdsLieferscheinEmailMA.Size := 50;
    xdsLieferscheinUSTID.SetParentComponent(xdsLieferschein);
    xdsLieferscheinUSTID.Name := 'xdsLieferscheinUSTID';
    xdsLieferscheinUSTID.FieldName := 'USTID';
    xdsLieferscheinUmsatzsteuerausweis.SetParentComponent(xdsLieferschein);
    xdsLieferscheinUmsatzsteuerausweis.Name := 'xdsLieferscheinUmsatzsteuerausweis';
    xdsLieferscheinUmsatzsteuerausweis.FieldName := 'Umsatzsteuerausweis';
    xdsLieferscheingebucht.SetParentComponent(xdsLieferschein);
    xdsLieferscheingebucht.Name := 'xdsLieferscheingebucht';
    xdsLieferscheingebucht.FieldName := 'gebucht';
    xdsLieferscheinLieferdatum.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLieferdatum.Name := 'xdsLieferscheinLieferdatum';
    xdsLieferscheinLieferdatum.FieldName := 'Lieferdatum';
    xdsLieferscheinTransportbedingung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTransportbedingung.Name := 'xdsLieferscheinTransportbedingung';
    xdsLieferscheinTransportbedingung.FieldName := 'Transportbedingung';
    xdsLieferscheinTransportbedingung.Size := 100;
    xdsLieferscheinMehrwertsteuersatz3.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz3.Name := 'xdsLieferscheinMehrwertsteuersatz3';
    xdsLieferscheinMehrwertsteuersatz3.FieldName := 'Mehrwertsteuersatz3';
    xdsLieferscheinMehrwertsteuer03.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuer03.Name := 'xdsLieferscheinMehrwertsteuer03';
    xdsLieferscheinMehrwertsteuer03.FieldName := 'Mehrwertsteuer03';
    xdsLieferscheinNettosumme03.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNettosumme03.Name := 'xdsLieferscheinNettosumme03';
    xdsLieferscheinNettosumme03.FieldName := 'Nettosumme03';
    xdsLieferscheinRundungsDifferenz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinRundungsDifferenz.Name := 'xdsLieferscheinRundungsDifferenz';
    xdsLieferscheinRundungsDifferenz.FieldName := 'RundungsDifferenz';
    xdsLieferscheinEmailText.SetParentComponent(xdsLieferschein);
    xdsLieferscheinEmailText.Name := 'xdsLieferscheinEmailText';
    xdsLieferscheinEmailText.FieldName := 'EmailText';
    xdsLieferscheinEmailText.Size := 255;
    xdsLieferscheinRabattstufe.SetParentComponent(xdsLieferschein);
    xdsLieferscheinRabattstufe.Name := 'xdsLieferscheinRabattstufe';
    xdsLieferscheinRabattstufe.FieldName := 'Rabattstufe';
    xdsLieferscheinRabattstufe.Size := 15;
    xdsLieferscheinDebitorennummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDebitorennummer.Name := 'xdsLieferscheinDebitorennummer';
    xdsLieferscheinDebitorennummer.FieldName := 'Debitorennummer';
    xdsLieferscheinDebitorennummer.Size := 13;
    xdsLieferscheinKostentraeger.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKostentraeger.Name := 'xdsLieferscheinKostentraeger';
    xdsLieferscheinKostentraeger.FieldName := 'Kostentraeger';
    xdsLieferscheinKostentraeger.Size := 13;
    xdsLieferscheinRabattsatz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinRabattsatz.Name := 'xdsLieferscheinRabattsatz';
    xdsLieferscheinRabattsatz.FieldName := 'Rabattsatz';
    xdsLieferscheinObjektReferenz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinObjektReferenz.Name := 'xdsLieferscheinObjektReferenz';
    xdsLieferscheinObjektReferenz.FieldName := 'ObjektReferenz';
    xdsLieferscheinObjektReferenz.Size := 13;
    xdsLieferscheinBank.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBank.Name := 'xdsLieferscheinBank';
    xdsLieferscheinBank.FieldName := 'Bank';
    xdsLieferscheinBank.Size := 50;
    xdsLieferscheinBankleitzahl.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBankleitzahl.Name := 'xdsLieferscheinBankleitzahl';
    xdsLieferscheinBankleitzahl.FieldName := 'Bankleitzahl';
    xdsLieferscheinKontonummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKontonummer.Name := 'xdsLieferscheinKontonummer';
    xdsLieferscheinKontonummer.FieldName := 'Kontonummer';
    xdsLieferscheinKontonummer.Size := 50;
    xdsLieferscheinKontoinhaber.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKontoinhaber.Name := 'xdsLieferscheinKontoinhaber';
    xdsLieferscheinKontoinhaber.FieldName := 'Kontoinhaber';
    xdsLieferscheinKontoinhaber.Size := 50;
    xdsLieferscheinTelefaxnummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTelefaxnummer.Name := 'xdsLieferscheinTelefaxnummer';
    xdsLieferscheinTelefaxnummer.FieldName := 'Telefaxnummer';
    xdsLieferscheinTelefaxnummer.Size := 25;
    xdsLieferscheinDatumNeu.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDatumNeu.Name := 'xdsLieferscheinDatumNeu';
    xdsLieferscheinDatumNeu.FieldName := 'DatumNeu';
    xdsLieferscheinDatumgeaendert.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDatumgeaendert.Name := 'xdsLieferscheinDatumgeaendert';
    xdsLieferscheinDatumgeaendert.FieldName := 'Datumgeaendert';
    xdsLieferscheinObjektnummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinObjektnummer.Name := 'xdsLieferscheinObjektnummer';
    xdsLieferscheinObjektnummer.FieldName := 'Objektnummer';
    xdsLieferscheinObjektnummer.Size := 25;
    xdsLieferscheinLiefertermin.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLiefertermin.Name := 'xdsLieferscheinLiefertermin';
    xdsLieferscheinLiefertermin.FieldName := 'Liefertermin';
    xdsLieferscheinLiefertermin.Size := 50;
    xdsLieferscheinEmailversand.SetParentComponent(xdsLieferschein);
    xdsLieferscheinEmailversand.Name := 'xdsLieferscheinEmailversand';
    xdsLieferscheinEmailversand.FieldName := 'Emailversand';
    xdsLieferscheinTelefonnummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTelefonnummer.Name := 'xdsLieferscheinTelefonnummer';
    xdsLieferscheinTelefonnummer.FieldName := 'Telefonnummer';
    xdsLieferscheinTelefonnummer.Size := 35;
    xdsLieferscheinMobilTelefon.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMobilTelefon.Name := 'xdsLieferscheinMobilTelefon';
    xdsLieferscheinMobilTelefon.FieldName := 'MobilTelefon';
    xdsLieferscheinMobilTelefon.Size := 25;
    xdsLieferscheinBenutzerNeu.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBenutzerNeu.Name := 'xdsLieferscheinBenutzerNeu';
    xdsLieferscheinBenutzerNeu.FieldName := 'BenutzerNeu';
    xdsLieferscheinBenutzerNeu.Size := 50;
    xdsLieferscheinBenutzerGeaendert.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBenutzerGeaendert.Name := 'xdsLieferscheinBenutzerGeaendert';
    xdsLieferscheinBenutzerGeaendert.FieldName := 'BenutzerGeaendert';
    xdsLieferscheinBenutzerGeaendert.Size := 50;
    xdsLieferscheinBerechnungBrutto.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBerechnungBrutto.Name := 'xdsLieferscheinBerechnungBrutto';
    xdsLieferscheinBerechnungBrutto.FieldName := 'BerechnungBrutto';
    xdsLieferscheinBruttosumme01.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBruttosumme01.Name := 'xdsLieferscheinBruttosumme01';
    xdsLieferscheinBruttosumme01.FieldName := 'Bruttosumme01';
    xdsLieferscheinBruttosumme02.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBruttosumme02.Name := 'xdsLieferscheinBruttosumme02';
    xdsLieferscheinBruttosumme02.FieldName := 'Bruttosumme02';
    xdsLieferscheinBruttosumme03.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBruttosumme03.Name := 'xdsLieferscheinBruttosumme03';
    xdsLieferscheinBruttosumme03.FieldName := 'Bruttosumme03';
    xdsLieferscheinNotiz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNotiz.Name := 'xdsLieferscheinNotiz';
    xdsLieferscheinNotiz.FieldName := 'Notiz';
    xdsLieferscheinNotiz.Size := 255;
    xdsLieferscheinMargeProzent.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMargeProzent.Name := 'xdsLieferscheinMargeProzent';
    xdsLieferscheinMargeProzent.FieldName := 'MargeProzent';
    xdsLieferscheinBelegsprache.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBelegsprache.Name := 'xdsLieferscheinBelegsprache';
    xdsLieferscheinBelegsprache.FieldName := 'Belegsprache';
    xdsLieferscheinBelegsprache.Size := 10;
    xdsLieferscheinDuplicate.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDuplicate.Name := 'xdsLieferscheinDuplicate';
    xdsLieferscheinDuplicate.FieldName := 'Duplicate';
    xdsLieferscheinAnsprechpartner.SetParentComponent(xdsLieferschein);
    xdsLieferscheinAnsprechpartner.Name := 'xdsLieferscheinAnsprechpartner';
    xdsLieferscheinAnsprechpartner.FieldName := 'Ansprechpartner';
    xdsLieferscheinAnsprechpartner.Size := 105;
    xdsLieferscheinKundennummerFremd.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKundennummerFremd.Name := 'xdsLieferscheinKundennummerFremd';
    xdsLieferscheinKundennummerFremd.FieldName := 'KundennummerFremd';
    xdsLieferscheinProtokoll.SetParentComponent(xdsLieferschein);
    xdsLieferscheinProtokoll.Name := 'xdsLieferscheinProtokoll';
    xdsLieferscheinProtokoll.FieldName := 'Protokoll';
    xdsLieferscheinProtokoll.Size := 255;
    xdsLieferscheinVollstaendigFakturiert.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVollstaendigFakturiert.Name := 'xdsLieferscheinVollstaendigFakturiert';
    xdsLieferscheinVollstaendigFakturiert.FieldName := 'VollstaendigFakturiert';
    xdsLieferscheinVerleihauftrag.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerleihauftrag.Name := 'xdsLieferscheinVerleihauftrag';
    xdsLieferscheinVerleihauftrag.FieldName := 'Verleihauftrag';
    xdsLieferscheinVerleihVon.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerleihVon.Name := 'xdsLieferscheinVerleihVon';
    xdsLieferscheinVerleihVon.FieldName := 'VerleihVon';
    xdsLieferscheinVerleihBis.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerleihBis.Name := 'xdsLieferscheinVerleihBis';
    xdsLieferscheinVerleihBis.FieldName := 'VerleihBis';
    xdsLieferscheinRueckgabeschein.SetParentComponent(xdsLieferschein);
    xdsLieferscheinRueckgabeschein.Name := 'xdsLieferscheinRueckgabeschein';
    xdsLieferscheinRueckgabeschein.FieldName := 'Rueckgabeschein';
    xdsLieferscheinRueckgabeReferenz.SetParentComponent(xdsLieferschein);
    xdsLieferscheinRueckgabeReferenz.Name := 'xdsLieferscheinRueckgabeReferenz';
    xdsLieferscheinRueckgabeReferenz.FieldName := 'RueckgabeReferenz';
    xdsLieferscheinRueckgabeReferenz.Size := 10;
    xdsLieferscheingesperrt.SetParentComponent(xdsLieferschein);
    xdsLieferscheingesperrt.Name := 'xdsLieferscheingesperrt';
    xdsLieferscheingesperrt.FieldName := 'gesperrt';
    xdsLieferscheinVerleihvonText.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerleihvonText.Name := 'xdsLieferscheinVerleihvonText';
    xdsLieferscheinVerleihvonText.FieldName := 'VerleihvonText';
    xdsLieferscheinVerleihvonText.Size := 50;
    xdsLieferscheinVerleihbisText.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerleihbisText.Name := 'xdsLieferscheinVerleihbisText';
    xdsLieferscheinVerleihbisText.FieldName := 'VerleihbisText';
    xdsLieferscheinVerleihbisText.Size := 50;
    xdsLieferscheinNotizExtern.SetParentComponent(xdsLieferschein);
    xdsLieferscheinNotizExtern.Name := 'xdsLieferscheinNotizExtern';
    xdsLieferscheinNotizExtern.FieldName := 'NotizExtern';
    xdsLieferscheinNotizExtern.Size := 255;
    xdsLieferscheinDruckWHG.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDruckWHG.Name := 'xdsLieferscheinDruckWHG';
    xdsLieferscheinDruckWHG.FieldName := 'DruckWHG';
    xdsLieferscheinDruckWHG.Size := 3;
    xdsLieferscheinDruckWHGKurs.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDruckWHGKurs.Name := 'xdsLieferscheinDruckWHGKurs';
    xdsLieferscheinDruckWHGKurs.FieldName := 'DruckWHGKurs';
    xdsLieferscheinProzentsatzZusatzposition.SetParentComponent(xdsLieferschein);
    xdsLieferscheinProzentsatzZusatzposition.Name := 'xdsLieferscheinProzentsatzZusatzposition';
    xdsLieferscheinProzentsatzZusatzposition.FieldName := 'ProzentsatzZusatzposition';
    xdsLieferscheinTextZusatzposition.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTextZusatzposition.Name := 'xdsLieferscheinTextZusatzposition';
    xdsLieferscheinTextZusatzposition.FieldName := 'TextZusatzposition';
    xdsLieferscheinTextZusatzposition.Size := 255;
    xdsLieferscheinTextzusatzBeschreibung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinTextzusatzBeschreibung.Name := 'xdsLieferscheinTextzusatzBeschreibung';
    xdsLieferscheinTextzusatzBeschreibung.FieldName := 'TextzusatzBeschreibung';
    xdsLieferscheinTextzusatzBeschreibung.Size := 50;
    xdsLieferscheinExport.SetParentComponent(xdsLieferschein);
    xdsLieferscheinExport.Name := 'xdsLieferscheinExport';
    xdsLieferscheinExport.FieldName := 'Export';
    xdsLieferscheinExportbescheinigung.SetParentComponent(xdsLieferschein);
    xdsLieferscheinExportbescheinigung.Name := 'xdsLieferscheinExportbescheinigung';
    xdsLieferscheinExportbescheinigung.FieldName := 'Exportbescheinigung';
    xdsLieferscheinBestimmungsland.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBestimmungsland.Name := 'xdsLieferscheinBestimmungsland';
    xdsLieferscheinBestimmungsland.FieldName := 'Bestimmungsland';
    xdsLieferscheinBestimmungsland.Size := 5;
    xdsLieferscheinLayoutname.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLayoutname.Name := 'xdsLieferscheinLayoutname';
    xdsLieferscheinLayoutname.FieldName := 'Layoutname';
    xdsLieferscheinLayoutname.Size := 50;
    xdsLieferscheinGesamtgewicht.SetParentComponent(xdsLieferschein);
    xdsLieferscheinGesamtgewicht.Name := 'xdsLieferscheinGesamtgewicht';
    xdsLieferscheinGesamtgewicht.FieldName := 'Gesamtgewicht';
    xdsLieferscheinBetragZusatzposition.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBetragZusatzposition.Name := 'xdsLieferscheinBetragZusatzposition';
    xdsLieferscheinBetragZusatzposition.FieldName := 'BetragZusatzposition';
    xdsLieferscheinPaketdienst.SetParentComponent(xdsLieferschein);
    xdsLieferscheinPaketdienst.Name := 'xdsLieferscheinPaketdienst';
    xdsLieferscheinPaketdienst.FieldName := 'Paketdienst';
    xdsLieferscheinPaketdienst.Size := 30;
    xdsLieferscheinPaketnummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinPaketnummer.Name := 'xdsLieferscheinPaketnummer';
    xdsLieferscheinPaketnummer.FieldName := 'Paketnummer';
    xdsLieferscheinPaketnummer.Size := 200;
    xdsLieferscheinMehrwertsteuersatz4.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz4.Name := 'xdsLieferscheinMehrwertsteuersatz4';
    xdsLieferscheinMehrwertsteuersatz4.FieldName := 'Mehrwertsteuersatz4';
    xdsLieferscheinMehrwertsteuersatz5.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz5.Name := 'xdsLieferscheinMehrwertsteuersatz5';
    xdsLieferscheinMehrwertsteuersatz5.FieldName := 'Mehrwertsteuersatz5';
    xdsLieferscheinMehrwertsteuersatz6.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMehrwertsteuersatz6.Name := 'xdsLieferscheinMehrwertsteuersatz6';
    xdsLieferscheinMehrwertsteuersatz6.FieldName := 'Mehrwertsteuersatz6';
    xdsLieferscheinBesteuerungKey.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBesteuerungKey.Name := 'xdsLieferscheinBesteuerungKey';
    xdsLieferscheinBesteuerungKey.FieldName := 'BesteuerungKey';
    xdsLieferscheinVertreterMatchcode.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVertreterMatchcode.Name := 'xdsLieferscheinVertreterMatchcode';
    xdsLieferscheinVertreterMatchcode.FieldName := 'VertreterMatchcode';
    xdsLieferscheinVertreterMatchcode.Size := 50;
    xdsLieferscheinVerfasser.SetParentComponent(xdsLieferschein);
    xdsLieferscheinVerfasser.Name := 'xdsLieferscheinVerfasser';
    xdsLieferscheinVerfasser.FieldName := 'Verfasser';
    xdsLieferscheinVerfasser.Size := 10;
    xdsLieferscheinLieferadresseID.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLieferadresseID.Name := 'xdsLieferscheinLieferadresseID';
    xdsLieferscheinLieferadresseID.FieldName := 'LieferadresseID';
    xdsLieferscheinBelegvariante.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBelegvariante.Name := 'xdsLieferscheinBelegvariante';
    xdsLieferscheinBelegvariante.FieldName := 'Belegvariante';
    xdsLieferscheinBelegvariante.Size := 25;
    xdsLieferscheinBereitstellungDatum.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBereitstellungDatum.Name := 'xdsLieferscheinBereitstellungDatum';
    xdsLieferscheinBereitstellungDatum.FieldName := 'BereitstellungDatum';
    xdsLieferscheinBereitstellungErledigt.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBereitstellungErledigt.Name := 'xdsLieferscheinBereitstellungErledigt';
    xdsLieferscheinBereitstellungErledigt.FieldName := 'BereitstellungErledigt';
    xdsLieferscheinFahrzeugID.SetParentComponent(xdsLieferschein);
    xdsLieferscheinFahrzeugID.Name := 'xdsLieferscheinFahrzeugID';
    xdsLieferscheinFahrzeugID.FieldName := 'FahrzeugID';
    xdsLieferscheinMonatJahr.SetParentComponent(xdsLieferschein);
    xdsLieferscheinMonatJahr.Name := 'xdsLieferscheinMonatJahr';
    xdsLieferscheinMonatJahr.FieldName := 'MonatJahr';
    xdsLieferscheinLieferanschriftDaten.SetParentComponent(xdsLieferschein);
    xdsLieferscheinLieferanschriftDaten.Name := 'xdsLieferscheinLieferanschriftDaten';
    xdsLieferscheinLieferanschriftDaten.FieldName := 'LieferanschriftDaten';
    xdsLieferscheinLieferanschriftDaten.Size := 255;
    xdsLieferscheinGesamtVolumen.SetParentComponent(xdsLieferschein);
    xdsLieferscheinGesamtVolumen.Name := 'xdsLieferscheinGesamtVolumen';
    xdsLieferscheinGesamtVolumen.FieldName := 'GesamtVolumen';
    xdsLieferscheinBelegPKey.SetParentComponent(xdsLieferschein);
    xdsLieferscheinBelegPKey.Name := 'xdsLieferscheinBelegPKey';
    xdsLieferscheinBelegPKey.FieldName := 'BelegPKey';
    xdsLieferscheinDruckParameter.SetParentComponent(xdsLieferschein);
    xdsLieferscheinDruckParameter.Name := 'xdsLieferscheinDruckParameter';
    xdsLieferscheinDruckParameter.FieldName := 'DruckParameter';
    xdsLieferscheinDruckParameter.Size := 255;
    xdsLieferscheinFakturierungNichtVor.SetParentComponent(xdsLieferschein);
    xdsLieferscheinFakturierungNichtVor.Name := 'xdsLieferscheinFakturierungNichtVor';
    xdsLieferscheinFakturierungNichtVor.FieldName := 'FakturierungNichtVor';
    xdsLieferscheinQuittungSIG.SetParentComponent(xdsLieferschein);
    xdsLieferscheinQuittungSIG.Name := 'xdsLieferscheinQuittungSIG';
    xdsLieferscheinQuittungSIG.FieldName := 'QuittungSIG';
    xdsLieferscheinKundennummerKundennummer.SetParentComponent(xdsLieferschein);
    xdsLieferscheinKundennummerKundennummer.Name := 'xdsLieferscheinKundennummerKundennummer';
    xdsLieferscheinKundennummerKundennummer.FieldName := 'Kundennummer.Kundennummer';
    xdsLieferscheinKundennummerKundennummer.Size := 13;
  finally
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    xdsLieferschein.AfterLoadDFMValues;
    xdsLieferscheinNummerkomplett.AfterLoadDFMValues;
    xdsLieferscheinReferenzAngebot.AfterLoadDFMValues;
    xdsLieferscheinReferenzAuftragbest.AfterLoadDFMValues;
    xdsLieferscheinReferenzRechnung.AfterLoadDFMValues;
    xdsLieferscheinDatum.AfterLoadDFMValues;
    xdsLieferscheinJahr.AfterLoadDFMValues;
    xdsLieferscheinKalenderwoche.AfterLoadDFMValues;
    xdsLieferscheinNachname.AfterLoadDFMValues;
    xdsLieferscheinVorname.AfterLoadDFMValues;
    xdsLieferscheinPostleitzahl.AfterLoadDFMValues;
    xdsLieferscheinOrt.AfterLoadDFMValues;
    xdsLieferscheinLaenderkuerzel.AfterLoadDFMValues;
    xdsLieferscheinLand.AfterLoadDFMValues;
    xdsLieferscheinBriefanrede1.AfterLoadDFMValues;
    xdsLieferscheinBriefanrede2.AfterLoadDFMValues;
    xdsLieferscheinEMail.AfterLoadDFMValues;
    xdsLieferscheinAdresseBild.AfterLoadDFMValues;
    xdsLieferscheinLieferanschriftBild.AfterLoadDFMValues;
    xdsLieferscheinZahlungsbedingung.AfterLoadDFMValues;
    xdsLieferscheinZahlungsbedingungText.AfterLoadDFMValues;
    xdsLieferscheinAuftragsbezeichnung.AfterLoadDFMValues;
    xdsLieferscheinAuftragnummerKunde.AfterLoadDFMValues;
    xdsLieferscheinBestelldatumKunde.AfterLoadDFMValues;
    xdsLieferscheinILN.AfterLoadDFMValues;
    xdsLieferscheinKopftext.AfterLoadDFMValues;
    xdsLieferscheinSchlussbemerkung.AfterLoadDFMValues;
    xdsLieferscheinGruss.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz1.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz2.AfterLoadDFMValues;
    xdsLieferscheinNettosumme01.AfterLoadDFMValues;
    xdsLieferscheinNettosumme02.AfterLoadDFMValues;
    xdsLieferscheinNettosumme.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuer01.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuer02.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuer.AfterLoadDFMValues;
    xdsLieferscheinBruttosumme.AfterLoadDFMValues;
    xdsLieferscheinVersanddatum.AfterLoadDFMValues;
    xdsLieferscheinSkontosatz.AfterLoadDFMValues;
    xdsLieferscheinNettosummeEK.AfterLoadDFMValues;
    xdsLieferscheinNettoMarge.AfterLoadDFMValues;
    xdsLieferscheinSchreibzeichen.AfterLoadDFMValues;
    xdsLieferscheinUnterzeichner.AfterLoadDFMValues;
    xdsLieferscheinTelefonMA.AfterLoadDFMValues;
    xdsLieferscheinEmailMA.AfterLoadDFMValues;
    xdsLieferscheinUSTID.AfterLoadDFMValues;
    xdsLieferscheinUmsatzsteuerausweis.AfterLoadDFMValues;
    xdsLieferscheingebucht.AfterLoadDFMValues;
    xdsLieferscheinLieferdatum.AfterLoadDFMValues;
    xdsLieferscheinTransportbedingung.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz3.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuer03.AfterLoadDFMValues;
    xdsLieferscheinNettosumme03.AfterLoadDFMValues;
    xdsLieferscheinRundungsDifferenz.AfterLoadDFMValues;
    xdsLieferscheinEmailText.AfterLoadDFMValues;
    xdsLieferscheinRabattstufe.AfterLoadDFMValues;
    xdsLieferscheinDebitorennummer.AfterLoadDFMValues;
    xdsLieferscheinKostentraeger.AfterLoadDFMValues;
    xdsLieferscheinRabattsatz.AfterLoadDFMValues;
    xdsLieferscheinObjektReferenz.AfterLoadDFMValues;
    xdsLieferscheinBank.AfterLoadDFMValues;
    xdsLieferscheinBankleitzahl.AfterLoadDFMValues;
    xdsLieferscheinKontonummer.AfterLoadDFMValues;
    xdsLieferscheinKontoinhaber.AfterLoadDFMValues;
    xdsLieferscheinTelefaxnummer.AfterLoadDFMValues;
    xdsLieferscheinDatumNeu.AfterLoadDFMValues;
    xdsLieferscheinDatumgeaendert.AfterLoadDFMValues;
    xdsLieferscheinObjektnummer.AfterLoadDFMValues;
    xdsLieferscheinLiefertermin.AfterLoadDFMValues;
    xdsLieferscheinEmailversand.AfterLoadDFMValues;
    xdsLieferscheinTelefonnummer.AfterLoadDFMValues;
    xdsLieferscheinMobilTelefon.AfterLoadDFMValues;
    xdsLieferscheinBenutzerNeu.AfterLoadDFMValues;
    xdsLieferscheinBenutzerGeaendert.AfterLoadDFMValues;
    xdsLieferscheinBerechnungBrutto.AfterLoadDFMValues;
    xdsLieferscheinBruttosumme01.AfterLoadDFMValues;
    xdsLieferscheinBruttosumme02.AfterLoadDFMValues;
    xdsLieferscheinBruttosumme03.AfterLoadDFMValues;
    xdsLieferscheinNotiz.AfterLoadDFMValues;
    xdsLieferscheinMargeProzent.AfterLoadDFMValues;
    xdsLieferscheinBelegsprache.AfterLoadDFMValues;
    xdsLieferscheinDuplicate.AfterLoadDFMValues;
    xdsLieferscheinAnsprechpartner.AfterLoadDFMValues;
    xdsLieferscheinKundennummerFremd.AfterLoadDFMValues;
    xdsLieferscheinProtokoll.AfterLoadDFMValues;
    xdsLieferscheinVollstaendigFakturiert.AfterLoadDFMValues;
    xdsLieferscheinVerleihauftrag.AfterLoadDFMValues;
    xdsLieferscheinVerleihVon.AfterLoadDFMValues;
    xdsLieferscheinVerleihBis.AfterLoadDFMValues;
    xdsLieferscheinRueckgabeschein.AfterLoadDFMValues;
    xdsLieferscheinRueckgabeReferenz.AfterLoadDFMValues;
    xdsLieferscheingesperrt.AfterLoadDFMValues;
    xdsLieferscheinVerleihvonText.AfterLoadDFMValues;
    xdsLieferscheinVerleihbisText.AfterLoadDFMValues;
    xdsLieferscheinNotizExtern.AfterLoadDFMValues;
    xdsLieferscheinDruckWHG.AfterLoadDFMValues;
    xdsLieferscheinDruckWHGKurs.AfterLoadDFMValues;
    xdsLieferscheinProzentsatzZusatzposition.AfterLoadDFMValues;
    xdsLieferscheinTextZusatzposition.AfterLoadDFMValues;
    xdsLieferscheinTextzusatzBeschreibung.AfterLoadDFMValues;
    xdsLieferscheinExport.AfterLoadDFMValues;
    xdsLieferscheinExportbescheinigung.AfterLoadDFMValues;
    xdsLieferscheinBestimmungsland.AfterLoadDFMValues;
    xdsLieferscheinLayoutname.AfterLoadDFMValues;
    xdsLieferscheinGesamtgewicht.AfterLoadDFMValues;
    xdsLieferscheinBetragZusatzposition.AfterLoadDFMValues;
    xdsLieferscheinPaketdienst.AfterLoadDFMValues;
    xdsLieferscheinPaketnummer.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz4.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz5.AfterLoadDFMValues;
    xdsLieferscheinMehrwertsteuersatz6.AfterLoadDFMValues;
    xdsLieferscheinBesteuerungKey.AfterLoadDFMValues;
    xdsLieferscheinVertreterMatchcode.AfterLoadDFMValues;
    xdsLieferscheinVerfasser.AfterLoadDFMValues;
    xdsLieferscheinLieferadresseID.AfterLoadDFMValues;
    xdsLieferscheinBelegvariante.AfterLoadDFMValues;
    xdsLieferscheinBereitstellungDatum.AfterLoadDFMValues;
    xdsLieferscheinBereitstellungErledigt.AfterLoadDFMValues;
    xdsLieferscheinFahrzeugID.AfterLoadDFMValues;
    xdsLieferscheinMonatJahr.AfterLoadDFMValues;
    xdsLieferscheinLieferanschriftDaten.AfterLoadDFMValues;
    xdsLieferscheinGesamtVolumen.AfterLoadDFMValues;
    xdsLieferscheinBelegPKey.AfterLoadDFMValues;
    xdsLieferscheinDruckParameter.AfterLoadDFMValues;
    xdsLieferscheinFakturierungNichtVor.AfterLoadDFMValues;
    xdsLieferscheinQuittungSIG.AfterLoadDFMValues;
    xdsLieferscheinKundennummerKundennummer.AfterLoadDFMValues;
  end;
end;

end.