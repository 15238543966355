unit unit_ListeAuftrag;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.Buttons,
  WEBLib.Grids, WEBLib.DBCtrls, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.StdCtrls, XData.Web.Client,
  WEBLib.WebCtrls;

type
  TListeAuftrag = class(TForm)
    xdsAuftrag: TXDataWebDataSet;
    xdsAuftragKundennummer: TXDataWebEntityField;
    xdsAuftragNummerkomplett: TStringField;
    xdsAuftragReferenzAngebot: TStringField;
    xdsAuftragReferenzLieferschein: TStringField;
    xdsAuftragReferenzRechnung: TStringField;
    xdsAuftragDatum: TDateTimeField;
    xdsAuftragJahr: TFloatField;
    xdsAuftragKalenderwoche: TIntegerField;
    xdsAuftragNachname: TStringField;
    xdsAuftragVorname: TStringField;
    xdsAuftragPostleitzahl: TStringField;
    xdsAuftragOrt: TStringField;
    xdsAuftragLaenderkuerzel: TStringField;
    xdsAuftragLand: TStringField;
    xdsAuftragBriefanrede1: TStringField;
    xdsAuftragBriefanrede2: TStringField;
    xdsAuftragEMail: TStringField;
    xdsAuftragAdresseBild: TStringField;
    xdsAuftragLieferanschriftBild: TStringField;
    xdsAuftragZahlungsbedingung: TIntegerField;
    xdsAuftragZahlungsbedingungText: TStringField;
    xdsAuftragAuftragsbezeichnung: TStringField;
    xdsAuftragAuftragnummerKunde: TStringField;
    xdsAuftragBestelldatumKunde: TDateTimeField;
    xdsAuftragILN: TStringField;
    xdsAuftragKopftext: TStringField;
    xdsAuftragSchlussbemerkung: TStringField;
    xdsAuftragGruss: TStringField;
    xdsAuftragMehrwertsteuersatz1: TFloatField;
    xdsAuftragMehrwertsteuersatz2: TFloatField;
    xdsAuftragNettosumme01: TFloatField;
    xdsAuftragNettosumme02: TFloatField;
    xdsAuftragNettosumme: TFloatField;
    xdsAuftragMehrwertsteuer01: TFloatField;
    xdsAuftragMehrwertsteuer02: TFloatField;
    xdsAuftragMehrwertsteuer: TFloatField;
    xdsAuftragBruttosumme: TFloatField;
    xdsAuftragVersanddatum: TDateTimeField;
    xdsAuftragSkontosatz: TFloatField;
    xdsAuftragNettosummeEK: TFloatField;
    xdsAuftragNettoMarge: TFloatField;
    xdsAuftragSchreibzeichen: TStringField;
    xdsAuftragUnterzeichner: TStringField;
    xdsAuftragTelefonMA: TStringField;
    xdsAuftragEmailMA: TStringField;
    xdsAuftragUSTID: TStringField;
    xdsAuftragUmsatzsteuerausweis: TBooleanField;
    xdsAuftraggebucht: TBooleanField;
    xdsAuftragLieferdatum: TDateTimeField;
    xdsAuftragTransportbedingung: TStringField;
    xdsAuftragMehrwertsteuersatz3: TFloatField;
    xdsAuftragMehrwertsteuer03: TFloatField;
    xdsAuftragNettosumme03: TFloatField;
    xdsAuftragRundungsDifferenz: TFloatField;
    xdsAuftragEmailText: TStringField;
    xdsAuftragAuftragerledigt: TBooleanField;
    xdsAuftragRabattstufe: TStringField;
    xdsAuftragKFZKennzeichen: TStringField;
    xdsAuftragFahrgestellnummer: TStringField;
    xdsAuftragKFZHersteller: TStringField;
    xdsAuftragErstzulassungsdatum: TDateTimeField;
    xdsAuftragKMStand: TStringField;
    xdsAuftragKFZType: TStringField;
    xdsAuftragInspektionsdatum: TDateTimeField;
    xdsAuftragDebitorennummer: TStringField;
    xdsAuftragKostentraeger: TStringField;
    xdsAuftragRabattsatz: TFloatField;
    xdsAuftragObjektReferenz: TStringField;
    xdsAuftragBank: TStringField;
    xdsAuftragBankleitzahl: TFloatField;
    xdsAuftragKontonummer: TStringField;
    xdsAuftragKontoinhaber: TStringField;
    xdsAuftragTelefaxnummer: TStringField;
    xdsAuftragDatumNeu: TDateTimeField;
    xdsAuftragDatumgeaendert: TDateTimeField;
    xdsAuftragObjektnummer: TStringField;
    xdsAuftragLiefertermin: TStringField;
    xdsAuftragEmailversand: TBooleanField;
    xdsAuftragTelefonnummer: TStringField;
    xdsAuftragMobilTelefon: TStringField;
    xdsAuftragAuftragFax: TBooleanField;
    xdsAuftragREDatum: TDateTimeField;
    xdsAuftragBenutzerNeu: TStringField;
    xdsAuftragBenutzerGeaendert: TStringField;
    xdsAuftragBerechnungBrutto: TBooleanField;
    xdsAuftragBruttosumme01: TFloatField;
    xdsAuftragBruttosumme02: TFloatField;
    xdsAuftragBruttosumme03: TFloatField;
    xdsAuftragNotiz: TStringField;
    xdsAuftragMargeProzent: TFloatField;
    xdsAuftragWarenkreditgesperrt: TBooleanField;
    xdsAuftragBelegsprache: TStringField;
    xdsAuftragDuplicate: TStringField;
    xdsAuftragperEmail: TBooleanField;
    xdsAuftragAnsprechpartner: TStringField;
    xdsAuftragKundennummerFremd: TStringField;
    xdsAuftragProtokoll: TStringField;
    xdsAuftragVerleihauftrag: TBooleanField;
    xdsAuftragVerleihVon: TDateTimeField;
    xdsAuftragVerleihBis: TDateTimeField;
    xdsAuftragAuftragGenehmigt: TBooleanField;
    xdsAuftraggesperrt: TBooleanField;
    xdsAuftragVerleihvonText: TStringField;
    xdsAuftragVerleihbisText: TStringField;
    xdsAuftragDruckWHG: TStringField;
    xdsAuftragDruckWHGKurs: TFloatField;
    xdsAuftragProzentsatzZusatzposition: TFloatField;
    xdsAuftragTextZusatzposition: TStringField;
    xdsAuftragTextzusatzBeschreibung: TStringField;
    xdsAuftragLayoutname: TStringField;
    xdsAuftragGesamtgewicht: TFloatField;
    xdsAuftragBetragZusatzposition: TFloatField;
    xdsAuftragReferenzBestellung: TStringField;
    xdsAuftragTermin1: TDateTimeField;
    xdsAuftragTermin2: TDateTimeField;
    xdsAuftragZielkontaktnummer: TStringField;
    xdsAuftragZielkontaktname: TStringField;
    xdsAuftragBilddatei1: TStringField;
    xdsAuftragBilddatei2: TStringField;
    xdsAuftragZielkontaktnummer2: TStringField;
    xdsAuftragZielkontaktname2: TStringField;
    xdsAuftragbestaetigt: TDateTimeField;
    xdsAuftragZielkontaktnummer3: TStringField;
    xdsAuftragZielkontaktname3: TStringField;
    xdsAuftragTermin3: TDateTimeField;
    xdsAuftragTermin4: TDateTimeField;
    xdsAuftragMuster: TBooleanField;
    xdsAuftragKW: TIntegerField;
    xdsAuftragDatenlink: TStringField;
    xdsAuftragMehrwertsteuersatz4: TFloatField;
    xdsAuftragMehrwertsteuersatz5: TFloatField;
    xdsAuftragMehrwertsteuersatz6: TFloatField;
    xdsAuftragBesteuerungKey: TIntegerField;
    xdsAuftragVertreterMatchcode: TStringField;
    xdsAuftragVerfasser: TStringField;
    xdsAuftragLieferadresseID: TIntegerField;
    xdsAuftragAuftragStatus: TStringField;
    xdsAuftragMonatJahr: TDateTimeField;
    xdsAuftragBelegvariante: TStringField;
    xdsAuftragProduktionsfreigabe: TBooleanField;
    xdsAuftragReferenzRahmenAuftrag: TStringField;
    xdsAuftragDatumRahmenAuftrag: TDateTimeField;
    xdsAuftragFahrzeugID: TIntegerField;
    xdsAuftragAuslieferungKW: TStringField;
    xdsAuftragBereitstellungDatum: TDateTimeField;
    xdsAuftragVTUID: TStringField;
    xdsAuftragLieferanschriftDaten: TStringField;
    xdsAuftragGesamtVolumen: TFloatField;
    xdsAuftragBelegPKey: TIntegerField;
    xdsAuftragDruckParameter: TStringField;
    xdsAuftragReferenzService: TStringField;
    xdsAuftragZusatzDaten: TStringField;
    xdsAuftragStandPDF: TStringField;
    xdsAuftragPaketdienst: TStringField;
    xdsAuftragMonteur: TIntegerField;
    xdsAuftragKundennummerKundennummer: TStringField;
    WClient: TXDataWebClient;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    Container3: THTMLContainer;
    btnNeuerServiceBericht: TButton;
    btnNeuerAuftrag: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure btnNeuerServiceBerichtClick(Sender: TObject);
    procedure btnNeuerAuftragClick(Sender: TObject);
    procedure xdsAuftragAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    buttonzurueck:TButton;
    EinzelCard:THTMLDiv;
    procedure buttonzurueckclick(Sender:TObject);
    procedure EinzelCardclick(Sender:TObject);
    procedure NeuerAuftrag(Kundennummer:string;Belegvariante:string);
    procedure Auftragholen(Auftragsnummer:string);

  end;

var
  ListeAuftrag: TListeAuftrag;

implementation
  uses unit1, unit2, unit_EinzelAuftrag,unit_Kontaktsuche;

procedure TListeAuftrag.buttonzurueckclick(Sender:TObject);
begin
  xdsAuftrag.Close;
  self.close;
end;

procedure TListeAuftrag.EinzelCardclick(Sender:TObject);
var
  EinzelAuftrag:TEinzelAuftrag;
  MyMerkerAuftrag:string;
begin
  myMerkerAuftrag:=(sender as THTMLDiv).ElementID;
  Container3.HTML.text:='';
  EinzelAuftrag := TEinzelAuftrag.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEinzelAuftrag).MerkerAuftrag:=myMerkerAuftrag;
    end
    );

  EinzelAuftrag.ShowModal(procedure(AValue: TModalResult)
    begin
      xdsAuftrag.close;
      xdsAuftrag.Load;
    end
    );
end;

procedure TListeAuftrag.NeuerAuftrag(Kundennummer:string;Belegvariante:string);
  procedure OnResponse(Response:TXDataClientResponse);
  var
  ergebnis:string;
  begin
    ergebnis:=string(TJSObject(response.Result)['value']);
    if ergebnis='WRONG' then
    begin
      showmessage('Der Auftrag konnte leider nicht erstellt werden');
    end
    else
    begin
      Auftragholen(ergebnis);
    end;
  end;
begin
  WClient.RawInvoke('IServerService.NeuerAuftrag',[Kundennummer,BenutzerPersonalnummer,Belegvariante],@OnResponse);

end;

procedure TListeAuftrag.Auftragholen(Auftragsnummer: string);
   var
  EinzelAuftrag:TEinzelAuftrag;
    procedure AfterCreate(AForm:TObject);
    var
      EinzelAuftrag:TEinzelAuftrag;
    begin
      EinzelAuftrag:=(AForm as TEinzelAuftrag);
      //lform.Connection.URL:=dm2.XDataWebConnection1.URL;
      EinzelAuftrag.MerkerAuftrag:=Auftragsnummer;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      xdsAuftrag.Close;
      xdsAuftrag.Load;
    end;

  begin
  if LizenzOK then
  begin
  EinzelAuftrag:=TEinzelAuftrag.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;

  EinzelAuftrag.ShowModal(@AfterShowmodal);
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;

end;

procedure TListeAuftrag.btnNeuerAuftragClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Kontaktnummer<>'' then
      begin
        NeuerAuftrag(Lfrm.Kontaktnummer,'Auftrag');
      end;
    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TListeAuftrag.btnNeuerServiceBerichtClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Kontaktnummer<>'' then
      begin
        NeuerAuftrag(Lfrm.Kontaktnummer,'Servicebericht');
      end;
    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TListeAuftrag.WebFormShow(Sender: TObject);
begin
  xdsAuftrag.Load;
end;

procedure TListeAuftrag.xdsAuftragAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
  EinzelKarte:string;
begin

  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Aufträge offen</h5></nav>';

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info m-1 text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  btnNeuerServiceBericht.parentelement:=myelement;
  btnNeuerAuftrag.parentelement:=myelement;

  while not xdsAuftrag.eof do
  begin

    Einzelkarte:='<div class="card-header"><h5>'+xdsAuftragNummerkomplett.asstring+' | '+formatdatetime('DD.MM.YYYY',xdsAuftragDatum.AsDateTime)+'</h5><i>'+xdsAuftragBelegvariante.AsString+'</i></div>';
    Einzelkarte:=Einzelkarte+'<div class="card-body ">'+xdsAuftragKundennummerKundennummer.AsString;
    Einzelkarte:=Einzelkarte+' | '+xdsAuftragNachname.asstring+'<br>';
    Einzelkarte:=Einzelkarte+'<b>'+xdsAuftragAuftragsbezeichnung.AsString+'</b><br>';
    Einzelkarte:=Einzelkarte+Formatfloat('#,##0.00 €',xdsAuftragNettosumme.Asfloat)+'<br>';
    Einzelkarte:=Einzelkarte+xdsAuftragMonteur.AsString+'<br>';
    Einzelkarte:=Einzelkarte+'</div>';


    myelement:=Container3.ElementHandle;
    EinzelCard:=THTMLDiv.Create(self);
    EinzelCard.ElementID:=xdsAuftragNummerkomplett.asstring;
    EinzelCard.HeightStyle:=ssauto;
    EinzelCard.Width:=350;
    EinzelCard.HTML.Text:=Einzelkarte;
    EinzelCard.ElementClassName:='card m-1 border-info';
    EinzelCard.OnClick:=@EinzelCardClick;
    EinzelCard.ParentElement:=myelement;

    xdsAuftrag.Next;
  end;


end;

procedure TListeAuftrag.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  btnNeuerServiceBericht := TButton.Create(Self);
  btnNeuerAuftrag := TButton.Create(Self);
  xdsAuftrag := TXDataWebDataSet.Create(Self);
  xdsAuftragKundennummer := TXDataWebEntityField.Create(Self);
  xdsAuftragNummerkomplett := TStringField.Create(Self);
  xdsAuftragReferenzAngebot := TStringField.Create(Self);
  xdsAuftragReferenzLieferschein := TStringField.Create(Self);
  xdsAuftragReferenzRechnung := TStringField.Create(Self);
  xdsAuftragDatum := TDateTimeField.Create(Self);
  xdsAuftragJahr := TFloatField.Create(Self);
  xdsAuftragKalenderwoche := TIntegerField.Create(Self);
  xdsAuftragNachname := TStringField.Create(Self);
  xdsAuftragVorname := TStringField.Create(Self);
  xdsAuftragPostleitzahl := TStringField.Create(Self);
  xdsAuftragOrt := TStringField.Create(Self);
  xdsAuftragLaenderkuerzel := TStringField.Create(Self);
  xdsAuftragLand := TStringField.Create(Self);
  xdsAuftragBriefanrede1 := TStringField.Create(Self);
  xdsAuftragBriefanrede2 := TStringField.Create(Self);
  xdsAuftragEMail := TStringField.Create(Self);
  xdsAuftragAdresseBild := TStringField.Create(Self);
  xdsAuftragLieferanschriftBild := TStringField.Create(Self);
  xdsAuftragZahlungsbedingung := TIntegerField.Create(Self);
  xdsAuftragZahlungsbedingungText := TStringField.Create(Self);
  xdsAuftragAuftragsbezeichnung := TStringField.Create(Self);
  xdsAuftragAuftragnummerKunde := TStringField.Create(Self);
  xdsAuftragBestelldatumKunde := TDateTimeField.Create(Self);
  xdsAuftragILN := TStringField.Create(Self);
  xdsAuftragKopftext := TStringField.Create(Self);
  xdsAuftragSchlussbemerkung := TStringField.Create(Self);
  xdsAuftragGruss := TStringField.Create(Self);
  xdsAuftragMehrwertsteuersatz1 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuersatz2 := TFloatField.Create(Self);
  xdsAuftragNettosumme01 := TFloatField.Create(Self);
  xdsAuftragNettosumme02 := TFloatField.Create(Self);
  xdsAuftragNettosumme := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuer01 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuer02 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuer := TFloatField.Create(Self);
  xdsAuftragBruttosumme := TFloatField.Create(Self);
  xdsAuftragVersanddatum := TDateTimeField.Create(Self);
  xdsAuftragSkontosatz := TFloatField.Create(Self);
  xdsAuftragNettosummeEK := TFloatField.Create(Self);
  xdsAuftragNettoMarge := TFloatField.Create(Self);
  xdsAuftragSchreibzeichen := TStringField.Create(Self);
  xdsAuftragUnterzeichner := TStringField.Create(Self);
  xdsAuftragTelefonMA := TStringField.Create(Self);
  xdsAuftragEmailMA := TStringField.Create(Self);
  xdsAuftragUSTID := TStringField.Create(Self);
  xdsAuftragUmsatzsteuerausweis := TBooleanField.Create(Self);
  xdsAuftraggebucht := TBooleanField.Create(Self);
  xdsAuftragLieferdatum := TDateTimeField.Create(Self);
  xdsAuftragTransportbedingung := TStringField.Create(Self);
  xdsAuftragMehrwertsteuersatz3 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuer03 := TFloatField.Create(Self);
  xdsAuftragNettosumme03 := TFloatField.Create(Self);
  xdsAuftragRundungsDifferenz := TFloatField.Create(Self);
  xdsAuftragEmailText := TStringField.Create(Self);
  xdsAuftragAuftragerledigt := TBooleanField.Create(Self);
  xdsAuftragRabattstufe := TStringField.Create(Self);
  xdsAuftragKFZKennzeichen := TStringField.Create(Self);
  xdsAuftragFahrgestellnummer := TStringField.Create(Self);
  xdsAuftragKFZHersteller := TStringField.Create(Self);
  xdsAuftragErstzulassungsdatum := TDateTimeField.Create(Self);
  xdsAuftragKMStand := TStringField.Create(Self);
  xdsAuftragKFZType := TStringField.Create(Self);
  xdsAuftragInspektionsdatum := TDateTimeField.Create(Self);
  xdsAuftragDebitorennummer := TStringField.Create(Self);
  xdsAuftragKostentraeger := TStringField.Create(Self);
  xdsAuftragRabattsatz := TFloatField.Create(Self);
  xdsAuftragObjektReferenz := TStringField.Create(Self);
  xdsAuftragBank := TStringField.Create(Self);
  xdsAuftragBankleitzahl := TFloatField.Create(Self);
  xdsAuftragKontonummer := TStringField.Create(Self);
  xdsAuftragKontoinhaber := TStringField.Create(Self);
  xdsAuftragTelefaxnummer := TStringField.Create(Self);
  xdsAuftragDatumNeu := TDateTimeField.Create(Self);
  xdsAuftragDatumgeaendert := TDateTimeField.Create(Self);
  xdsAuftragObjektnummer := TStringField.Create(Self);
  xdsAuftragLiefertermin := TStringField.Create(Self);
  xdsAuftragEmailversand := TBooleanField.Create(Self);
  xdsAuftragTelefonnummer := TStringField.Create(Self);
  xdsAuftragMobilTelefon := TStringField.Create(Self);
  xdsAuftragAuftragFax := TBooleanField.Create(Self);
  xdsAuftragREDatum := TDateTimeField.Create(Self);
  xdsAuftragBenutzerNeu := TStringField.Create(Self);
  xdsAuftragBenutzerGeaendert := TStringField.Create(Self);
  xdsAuftragBerechnungBrutto := TBooleanField.Create(Self);
  xdsAuftragBruttosumme01 := TFloatField.Create(Self);
  xdsAuftragBruttosumme02 := TFloatField.Create(Self);
  xdsAuftragBruttosumme03 := TFloatField.Create(Self);
  xdsAuftragNotiz := TStringField.Create(Self);
  xdsAuftragMargeProzent := TFloatField.Create(Self);
  xdsAuftragWarenkreditgesperrt := TBooleanField.Create(Self);
  xdsAuftragBelegsprache := TStringField.Create(Self);
  xdsAuftragDuplicate := TStringField.Create(Self);
  xdsAuftragperEmail := TBooleanField.Create(Self);
  xdsAuftragAnsprechpartner := TStringField.Create(Self);
  xdsAuftragKundennummerFremd := TStringField.Create(Self);
  xdsAuftragProtokoll := TStringField.Create(Self);
  xdsAuftragVerleihauftrag := TBooleanField.Create(Self);
  xdsAuftragVerleihVon := TDateTimeField.Create(Self);
  xdsAuftragVerleihBis := TDateTimeField.Create(Self);
  xdsAuftragAuftragGenehmigt := TBooleanField.Create(Self);
  xdsAuftraggesperrt := TBooleanField.Create(Self);
  xdsAuftragVerleihvonText := TStringField.Create(Self);
  xdsAuftragVerleihbisText := TStringField.Create(Self);
  xdsAuftragDruckWHG := TStringField.Create(Self);
  xdsAuftragDruckWHGKurs := TFloatField.Create(Self);
  xdsAuftragProzentsatzZusatzposition := TFloatField.Create(Self);
  xdsAuftragTextZusatzposition := TStringField.Create(Self);
  xdsAuftragTextzusatzBeschreibung := TStringField.Create(Self);
  xdsAuftragLayoutname := TStringField.Create(Self);
  xdsAuftragGesamtgewicht := TFloatField.Create(Self);
  xdsAuftragBetragZusatzposition := TFloatField.Create(Self);
  xdsAuftragReferenzBestellung := TStringField.Create(Self);
  xdsAuftragTermin1 := TDateTimeField.Create(Self);
  xdsAuftragTermin2 := TDateTimeField.Create(Self);
  xdsAuftragZielkontaktnummer := TStringField.Create(Self);
  xdsAuftragZielkontaktname := TStringField.Create(Self);
  xdsAuftragBilddatei1 := TStringField.Create(Self);
  xdsAuftragBilddatei2 := TStringField.Create(Self);
  xdsAuftragZielkontaktnummer2 := TStringField.Create(Self);
  xdsAuftragZielkontaktname2 := TStringField.Create(Self);
  xdsAuftragbestaetigt := TDateTimeField.Create(Self);
  xdsAuftragZielkontaktnummer3 := TStringField.Create(Self);
  xdsAuftragZielkontaktname3 := TStringField.Create(Self);
  xdsAuftragTermin3 := TDateTimeField.Create(Self);
  xdsAuftragTermin4 := TDateTimeField.Create(Self);
  xdsAuftragMuster := TBooleanField.Create(Self);
  xdsAuftragKW := TIntegerField.Create(Self);
  xdsAuftragDatenlink := TStringField.Create(Self);
  xdsAuftragMehrwertsteuersatz4 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuersatz5 := TFloatField.Create(Self);
  xdsAuftragMehrwertsteuersatz6 := TFloatField.Create(Self);
  xdsAuftragBesteuerungKey := TIntegerField.Create(Self);
  xdsAuftragVertreterMatchcode := TStringField.Create(Self);
  xdsAuftragVerfasser := TStringField.Create(Self);
  xdsAuftragLieferadresseID := TIntegerField.Create(Self);
  xdsAuftragAuftragStatus := TStringField.Create(Self);
  xdsAuftragMonatJahr := TDateTimeField.Create(Self);
  xdsAuftragBelegvariante := TStringField.Create(Self);
  xdsAuftragProduktionsfreigabe := TBooleanField.Create(Self);
  xdsAuftragReferenzRahmenAuftrag := TStringField.Create(Self);
  xdsAuftragDatumRahmenAuftrag := TDateTimeField.Create(Self);
  xdsAuftragFahrzeugID := TIntegerField.Create(Self);
  xdsAuftragAuslieferungKW := TStringField.Create(Self);
  xdsAuftragBereitstellungDatum := TDateTimeField.Create(Self);
  xdsAuftragVTUID := TStringField.Create(Self);
  xdsAuftragLieferanschriftDaten := TStringField.Create(Self);
  xdsAuftragGesamtVolumen := TFloatField.Create(Self);
  xdsAuftragBelegPKey := TIntegerField.Create(Self);
  xdsAuftragDruckParameter := TStringField.Create(Self);
  xdsAuftragReferenzService := TStringField.Create(Self);
  xdsAuftragZusatzDaten := TStringField.Create(Self);
  xdsAuftragStandPDF := TStringField.Create(Self);
  xdsAuftragPaketdienst := TStringField.Create(Self);
  xdsAuftragMonteur := TIntegerField.Create(Self);
  xdsAuftragKundennummerKundennummer := TStringField.Create(Self);
  WClient := TXDataWebClient.Create(Self);

  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  btnNeuerServiceBericht.BeforeLoadDFMValues;
  btnNeuerAuftrag.BeforeLoadDFMValues;
  xdsAuftrag.BeforeLoadDFMValues;
  xdsAuftragKundennummer.BeforeLoadDFMValues;
  xdsAuftragNummerkomplett.BeforeLoadDFMValues;
  xdsAuftragReferenzAngebot.BeforeLoadDFMValues;
  xdsAuftragReferenzLieferschein.BeforeLoadDFMValues;
  xdsAuftragReferenzRechnung.BeforeLoadDFMValues;
  xdsAuftragDatum.BeforeLoadDFMValues;
  xdsAuftragJahr.BeforeLoadDFMValues;
  xdsAuftragKalenderwoche.BeforeLoadDFMValues;
  xdsAuftragNachname.BeforeLoadDFMValues;
  xdsAuftragVorname.BeforeLoadDFMValues;
  xdsAuftragPostleitzahl.BeforeLoadDFMValues;
  xdsAuftragOrt.BeforeLoadDFMValues;
  xdsAuftragLaenderkuerzel.BeforeLoadDFMValues;
  xdsAuftragLand.BeforeLoadDFMValues;
  xdsAuftragBriefanrede1.BeforeLoadDFMValues;
  xdsAuftragBriefanrede2.BeforeLoadDFMValues;
  xdsAuftragEMail.BeforeLoadDFMValues;
  xdsAuftragAdresseBild.BeforeLoadDFMValues;
  xdsAuftragLieferanschriftBild.BeforeLoadDFMValues;
  xdsAuftragZahlungsbedingung.BeforeLoadDFMValues;
  xdsAuftragZahlungsbedingungText.BeforeLoadDFMValues;
  xdsAuftragAuftragsbezeichnung.BeforeLoadDFMValues;
  xdsAuftragAuftragnummerKunde.BeforeLoadDFMValues;
  xdsAuftragBestelldatumKunde.BeforeLoadDFMValues;
  xdsAuftragILN.BeforeLoadDFMValues;
  xdsAuftragKopftext.BeforeLoadDFMValues;
  xdsAuftragSchlussbemerkung.BeforeLoadDFMValues;
  xdsAuftragGruss.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz1.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz2.BeforeLoadDFMValues;
  xdsAuftragNettosumme01.BeforeLoadDFMValues;
  xdsAuftragNettosumme02.BeforeLoadDFMValues;
  xdsAuftragNettosumme.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuer01.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuer02.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuer.BeforeLoadDFMValues;
  xdsAuftragBruttosumme.BeforeLoadDFMValues;
  xdsAuftragVersanddatum.BeforeLoadDFMValues;
  xdsAuftragSkontosatz.BeforeLoadDFMValues;
  xdsAuftragNettosummeEK.BeforeLoadDFMValues;
  xdsAuftragNettoMarge.BeforeLoadDFMValues;
  xdsAuftragSchreibzeichen.BeforeLoadDFMValues;
  xdsAuftragUnterzeichner.BeforeLoadDFMValues;
  xdsAuftragTelefonMA.BeforeLoadDFMValues;
  xdsAuftragEmailMA.BeforeLoadDFMValues;
  xdsAuftragUSTID.BeforeLoadDFMValues;
  xdsAuftragUmsatzsteuerausweis.BeforeLoadDFMValues;
  xdsAuftraggebucht.BeforeLoadDFMValues;
  xdsAuftragLieferdatum.BeforeLoadDFMValues;
  xdsAuftragTransportbedingung.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz3.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuer03.BeforeLoadDFMValues;
  xdsAuftragNettosumme03.BeforeLoadDFMValues;
  xdsAuftragRundungsDifferenz.BeforeLoadDFMValues;
  xdsAuftragEmailText.BeforeLoadDFMValues;
  xdsAuftragAuftragerledigt.BeforeLoadDFMValues;
  xdsAuftragRabattstufe.BeforeLoadDFMValues;
  xdsAuftragKFZKennzeichen.BeforeLoadDFMValues;
  xdsAuftragFahrgestellnummer.BeforeLoadDFMValues;
  xdsAuftragKFZHersteller.BeforeLoadDFMValues;
  xdsAuftragErstzulassungsdatum.BeforeLoadDFMValues;
  xdsAuftragKMStand.BeforeLoadDFMValues;
  xdsAuftragKFZType.BeforeLoadDFMValues;
  xdsAuftragInspektionsdatum.BeforeLoadDFMValues;
  xdsAuftragDebitorennummer.BeforeLoadDFMValues;
  xdsAuftragKostentraeger.BeforeLoadDFMValues;
  xdsAuftragRabattsatz.BeforeLoadDFMValues;
  xdsAuftragObjektReferenz.BeforeLoadDFMValues;
  xdsAuftragBank.BeforeLoadDFMValues;
  xdsAuftragBankleitzahl.BeforeLoadDFMValues;
  xdsAuftragKontonummer.BeforeLoadDFMValues;
  xdsAuftragKontoinhaber.BeforeLoadDFMValues;
  xdsAuftragTelefaxnummer.BeforeLoadDFMValues;
  xdsAuftragDatumNeu.BeforeLoadDFMValues;
  xdsAuftragDatumgeaendert.BeforeLoadDFMValues;
  xdsAuftragObjektnummer.BeforeLoadDFMValues;
  xdsAuftragLiefertermin.BeforeLoadDFMValues;
  xdsAuftragEmailversand.BeforeLoadDFMValues;
  xdsAuftragTelefonnummer.BeforeLoadDFMValues;
  xdsAuftragMobilTelefon.BeforeLoadDFMValues;
  xdsAuftragAuftragFax.BeforeLoadDFMValues;
  xdsAuftragREDatum.BeforeLoadDFMValues;
  xdsAuftragBenutzerNeu.BeforeLoadDFMValues;
  xdsAuftragBenutzerGeaendert.BeforeLoadDFMValues;
  xdsAuftragBerechnungBrutto.BeforeLoadDFMValues;
  xdsAuftragBruttosumme01.BeforeLoadDFMValues;
  xdsAuftragBruttosumme02.BeforeLoadDFMValues;
  xdsAuftragBruttosumme03.BeforeLoadDFMValues;
  xdsAuftragNotiz.BeforeLoadDFMValues;
  xdsAuftragMargeProzent.BeforeLoadDFMValues;
  xdsAuftragWarenkreditgesperrt.BeforeLoadDFMValues;
  xdsAuftragBelegsprache.BeforeLoadDFMValues;
  xdsAuftragDuplicate.BeforeLoadDFMValues;
  xdsAuftragperEmail.BeforeLoadDFMValues;
  xdsAuftragAnsprechpartner.BeforeLoadDFMValues;
  xdsAuftragKundennummerFremd.BeforeLoadDFMValues;
  xdsAuftragProtokoll.BeforeLoadDFMValues;
  xdsAuftragVerleihauftrag.BeforeLoadDFMValues;
  xdsAuftragVerleihVon.BeforeLoadDFMValues;
  xdsAuftragVerleihBis.BeforeLoadDFMValues;
  xdsAuftragAuftragGenehmigt.BeforeLoadDFMValues;
  xdsAuftraggesperrt.BeforeLoadDFMValues;
  xdsAuftragVerleihvonText.BeforeLoadDFMValues;
  xdsAuftragVerleihbisText.BeforeLoadDFMValues;
  xdsAuftragDruckWHG.BeforeLoadDFMValues;
  xdsAuftragDruckWHGKurs.BeforeLoadDFMValues;
  xdsAuftragProzentsatzZusatzposition.BeforeLoadDFMValues;
  xdsAuftragTextZusatzposition.BeforeLoadDFMValues;
  xdsAuftragTextzusatzBeschreibung.BeforeLoadDFMValues;
  xdsAuftragLayoutname.BeforeLoadDFMValues;
  xdsAuftragGesamtgewicht.BeforeLoadDFMValues;
  xdsAuftragBetragZusatzposition.BeforeLoadDFMValues;
  xdsAuftragReferenzBestellung.BeforeLoadDFMValues;
  xdsAuftragTermin1.BeforeLoadDFMValues;
  xdsAuftragTermin2.BeforeLoadDFMValues;
  xdsAuftragZielkontaktnummer.BeforeLoadDFMValues;
  xdsAuftragZielkontaktname.BeforeLoadDFMValues;
  xdsAuftragBilddatei1.BeforeLoadDFMValues;
  xdsAuftragBilddatei2.BeforeLoadDFMValues;
  xdsAuftragZielkontaktnummer2.BeforeLoadDFMValues;
  xdsAuftragZielkontaktname2.BeforeLoadDFMValues;
  xdsAuftragbestaetigt.BeforeLoadDFMValues;
  xdsAuftragZielkontaktnummer3.BeforeLoadDFMValues;
  xdsAuftragZielkontaktname3.BeforeLoadDFMValues;
  xdsAuftragTermin3.BeforeLoadDFMValues;
  xdsAuftragTermin4.BeforeLoadDFMValues;
  xdsAuftragMuster.BeforeLoadDFMValues;
  xdsAuftragKW.BeforeLoadDFMValues;
  xdsAuftragDatenlink.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz4.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz5.BeforeLoadDFMValues;
  xdsAuftragMehrwertsteuersatz6.BeforeLoadDFMValues;
  xdsAuftragBesteuerungKey.BeforeLoadDFMValues;
  xdsAuftragVertreterMatchcode.BeforeLoadDFMValues;
  xdsAuftragVerfasser.BeforeLoadDFMValues;
  xdsAuftragLieferadresseID.BeforeLoadDFMValues;
  xdsAuftragAuftragStatus.BeforeLoadDFMValues;
  xdsAuftragMonatJahr.BeforeLoadDFMValues;
  xdsAuftragBelegvariante.BeforeLoadDFMValues;
  xdsAuftragProduktionsfreigabe.BeforeLoadDFMValues;
  xdsAuftragReferenzRahmenAuftrag.BeforeLoadDFMValues;
  xdsAuftragDatumRahmenAuftrag.BeforeLoadDFMValues;
  xdsAuftragFahrzeugID.BeforeLoadDFMValues;
  xdsAuftragAuslieferungKW.BeforeLoadDFMValues;
  xdsAuftragBereitstellungDatum.BeforeLoadDFMValues;
  xdsAuftragVTUID.BeforeLoadDFMValues;
  xdsAuftragLieferanschriftDaten.BeforeLoadDFMValues;
  xdsAuftragGesamtVolumen.BeforeLoadDFMValues;
  xdsAuftragBelegPKey.BeforeLoadDFMValues;
  xdsAuftragDruckParameter.BeforeLoadDFMValues;
  xdsAuftragReferenzService.BeforeLoadDFMValues;
  xdsAuftragZusatzDaten.BeforeLoadDFMValues;
  xdsAuftragStandPDF.BeforeLoadDFMValues;
  xdsAuftragPaketdienst.BeforeLoadDFMValues;
  xdsAuftragMonteur.BeforeLoadDFMValues;
  xdsAuftragKundennummerKundennummer.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  try
    Name := 'ListeAuftrag';
    Width := 171;
    Height := 217;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 2;
    Container1.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    btnNeuerServiceBericht.SetParentComponent(Self);
    btnNeuerServiceBericht.Name := 'btnNeuerServiceBericht';
    btnNeuerServiceBericht.Left := 73;
    btnNeuerServiceBericht.Top := 16;
    btnNeuerServiceBericht.Width := 96;
    btnNeuerServiceBericht.Height := 25;
    btnNeuerServiceBericht.Caption := '+ Servicebericht';
    btnNeuerServiceBericht.ChildOrder := 7;
    btnNeuerServiceBericht.ElementClassName := 'btn btn-info m-1 text-left';
    btnNeuerServiceBericht.ElementFont := efCSS;
    btnNeuerServiceBericht.HeightStyle := ssAuto;
    btnNeuerServiceBericht.WidthStyle := ssAuto;
    SetEvent(btnNeuerServiceBericht, Self, 'OnClick', 'btnNeuerServiceBerichtClick');
    btnNeuerAuftrag.SetParentComponent(Self);
    btnNeuerAuftrag.Name := 'btnNeuerAuftrag';
    btnNeuerAuftrag.Left := 73;
    btnNeuerAuftrag.Top := 47;
    btnNeuerAuftrag.Width := 96;
    btnNeuerAuftrag.Height := 25;
    btnNeuerAuftrag.Caption := '+ Auftrag';
    btnNeuerAuftrag.ChildOrder := 8;
    btnNeuerAuftrag.ElementClassName := 'btn btn-info m-1 text-left';
    btnNeuerAuftrag.ElementFont := efCSS;
    btnNeuerAuftrag.HeightStyle := ssAuto;
    btnNeuerAuftrag.WidthStyle := ssAuto;
    SetEvent(btnNeuerAuftrag, Self, 'OnClick', 'btnNeuerAuftragClick');
    xdsAuftrag.SetParentComponent(Self);
    xdsAuftrag.Name := 'xdsAuftrag';
    xdsAuftrag.AfterOpen := xdsAuftragAfterOpen;
    xdsAuftrag.EntitySetName := 'Auftrag';
    xdsAuftrag.Connection := DM2.XDataWebConnection1;
    xdsAuftrag.SubpropsDepth := 1;
    xdsAuftrag.QueryString := '$expand=Kundennummer&$filter=(Auftragerledigt eq 0)&$orderby=Nummerkomplett DESC';
    xdsAuftrag.QueryTop := 100;
    xdsAuftrag.Left := 36;
    xdsAuftrag.Top := 118;
    xdsAuftragKundennummer.SetParentComponent(xdsAuftrag);
    xdsAuftragKundennummer.Name := 'xdsAuftragKundennummer';
    xdsAuftragKundennummer.FieldName := 'Kundennummer';
    xdsAuftragKundennummer.Required := True;
    xdsAuftragNummerkomplett.SetParentComponent(xdsAuftrag);
    xdsAuftragNummerkomplett.Name := 'xdsAuftragNummerkomplett';
    xdsAuftragNummerkomplett.FieldName := 'Nummerkomplett';
    xdsAuftragNummerkomplett.Required := True;
    xdsAuftragNummerkomplett.Size := 10;
    xdsAuftragReferenzAngebot.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzAngebot.Name := 'xdsAuftragReferenzAngebot';
    xdsAuftragReferenzAngebot.FieldName := 'ReferenzAngebot';
    xdsAuftragReferenzAngebot.Size := 10;
    xdsAuftragReferenzLieferschein.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzLieferschein.Name := 'xdsAuftragReferenzLieferschein';
    xdsAuftragReferenzLieferschein.FieldName := 'ReferenzLieferschein';
    xdsAuftragReferenzLieferschein.Size := 10;
    xdsAuftragReferenzRechnung.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzRechnung.Name := 'xdsAuftragReferenzRechnung';
    xdsAuftragReferenzRechnung.FieldName := 'ReferenzRechnung';
    xdsAuftragReferenzRechnung.Size := 10;
    xdsAuftragDatum.SetParentComponent(xdsAuftrag);
    xdsAuftragDatum.Name := 'xdsAuftragDatum';
    xdsAuftragDatum.FieldName := 'Datum';
    xdsAuftragDatum.Required := True;
    xdsAuftragDatum.DisplayFormat := 'DD.MM.YYYY';
    xdsAuftragJahr.SetParentComponent(xdsAuftrag);
    xdsAuftragJahr.Name := 'xdsAuftragJahr';
    xdsAuftragJahr.FieldName := 'Jahr';
    xdsAuftragKalenderwoche.SetParentComponent(xdsAuftrag);
    xdsAuftragKalenderwoche.Name := 'xdsAuftragKalenderwoche';
    xdsAuftragKalenderwoche.FieldName := 'Kalenderwoche';
    xdsAuftragNachname.SetParentComponent(xdsAuftrag);
    xdsAuftragNachname.Name := 'xdsAuftragNachname';
    xdsAuftragNachname.FieldName := 'Nachname';
    xdsAuftragNachname.Size := 50;
    xdsAuftragVorname.SetParentComponent(xdsAuftrag);
    xdsAuftragVorname.Name := 'xdsAuftragVorname';
    xdsAuftragVorname.FieldName := 'Vorname';
    xdsAuftragVorname.Size := 50;
    xdsAuftragPostleitzahl.SetParentComponent(xdsAuftrag);
    xdsAuftragPostleitzahl.Name := 'xdsAuftragPostleitzahl';
    xdsAuftragPostleitzahl.FieldName := 'Postleitzahl';
    xdsAuftragPostleitzahl.Size := 10;
    xdsAuftragOrt.SetParentComponent(xdsAuftrag);
    xdsAuftragOrt.Name := 'xdsAuftragOrt';
    xdsAuftragOrt.FieldName := 'Ort';
    xdsAuftragOrt.Size := 50;
    xdsAuftragLaenderkuerzel.SetParentComponent(xdsAuftrag);
    xdsAuftragLaenderkuerzel.Name := 'xdsAuftragLaenderkuerzel';
    xdsAuftragLaenderkuerzel.FieldName := 'Laenderkuerzel';
    xdsAuftragLaenderkuerzel.Size := 5;
    xdsAuftragLand.SetParentComponent(xdsAuftrag);
    xdsAuftragLand.Name := 'xdsAuftragLand';
    xdsAuftragLand.FieldName := 'Land';
    xdsAuftragLand.Size := 50;
    xdsAuftragBriefanrede1.SetParentComponent(xdsAuftrag);
    xdsAuftragBriefanrede1.Name := 'xdsAuftragBriefanrede1';
    xdsAuftragBriefanrede1.FieldName := 'Briefanrede1';
    xdsAuftragBriefanrede1.Size := 255;
    xdsAuftragBriefanrede2.SetParentComponent(xdsAuftrag);
    xdsAuftragBriefanrede2.Name := 'xdsAuftragBriefanrede2';
    xdsAuftragBriefanrede2.FieldName := 'Briefanrede2';
    xdsAuftragBriefanrede2.Size := 40;
    xdsAuftragEMail.SetParentComponent(xdsAuftrag);
    xdsAuftragEMail.Name := 'xdsAuftragEMail';
    xdsAuftragEMail.FieldName := 'EMail';
    xdsAuftragEMail.Size := 50;
    xdsAuftragAdresseBild.SetParentComponent(xdsAuftrag);
    xdsAuftragAdresseBild.Name := 'xdsAuftragAdresseBild';
    xdsAuftragAdresseBild.FieldName := 'AdresseBild';
    xdsAuftragAdresseBild.Size := 255;
    xdsAuftragLieferanschriftBild.SetParentComponent(xdsAuftrag);
    xdsAuftragLieferanschriftBild.Name := 'xdsAuftragLieferanschriftBild';
    xdsAuftragLieferanschriftBild.FieldName := 'LieferanschriftBild';
    xdsAuftragLieferanschriftBild.Size := 255;
    xdsAuftragZahlungsbedingung.SetParentComponent(xdsAuftrag);
    xdsAuftragZahlungsbedingung.Name := 'xdsAuftragZahlungsbedingung';
    xdsAuftragZahlungsbedingung.FieldName := 'Zahlungsbedingung';
    xdsAuftragZahlungsbedingungText.SetParentComponent(xdsAuftrag);
    xdsAuftragZahlungsbedingungText.Name := 'xdsAuftragZahlungsbedingungText';
    xdsAuftragZahlungsbedingungText.FieldName := 'ZahlungsbedingungText';
    xdsAuftragZahlungsbedingungText.Size := 250;
    xdsAuftragAuftragsbezeichnung.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragsbezeichnung.Name := 'xdsAuftragAuftragsbezeichnung';
    xdsAuftragAuftragsbezeichnung.FieldName := 'Auftragsbezeichnung';
    xdsAuftragAuftragsbezeichnung.Size := 255;
    xdsAuftragAuftragnummerKunde.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragnummerKunde.Name := 'xdsAuftragAuftragnummerKunde';
    xdsAuftragAuftragnummerKunde.FieldName := 'AuftragnummerKunde';
    xdsAuftragAuftragnummerKunde.Size := 50;
    xdsAuftragBestelldatumKunde.SetParentComponent(xdsAuftrag);
    xdsAuftragBestelldatumKunde.Name := 'xdsAuftragBestelldatumKunde';
    xdsAuftragBestelldatumKunde.FieldName := 'BestelldatumKunde';
    xdsAuftragILN.SetParentComponent(xdsAuftrag);
    xdsAuftragILN.Name := 'xdsAuftragILN';
    xdsAuftragILN.FieldName := 'ILN';
    xdsAuftragILN.Size := 50;
    xdsAuftragKopftext.SetParentComponent(xdsAuftrag);
    xdsAuftragKopftext.Name := 'xdsAuftragKopftext';
    xdsAuftragKopftext.FieldName := 'Kopftext';
    xdsAuftragKopftext.Size := 255;
    xdsAuftragSchlussbemerkung.SetParentComponent(xdsAuftrag);
    xdsAuftragSchlussbemerkung.Name := 'xdsAuftragSchlussbemerkung';
    xdsAuftragSchlussbemerkung.FieldName := 'Schlussbemerkung';
    xdsAuftragSchlussbemerkung.Size := 255;
    xdsAuftragGruss.SetParentComponent(xdsAuftrag);
    xdsAuftragGruss.Name := 'xdsAuftragGruss';
    xdsAuftragGruss.FieldName := 'Gruss';
    xdsAuftragGruss.Size := 255;
    xdsAuftragMehrwertsteuersatz1.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz1.Name := 'xdsAuftragMehrwertsteuersatz1';
    xdsAuftragMehrwertsteuersatz1.FieldName := 'Mehrwertsteuersatz1';
    xdsAuftragMehrwertsteuersatz2.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz2.Name := 'xdsAuftragMehrwertsteuersatz2';
    xdsAuftragMehrwertsteuersatz2.FieldName := 'Mehrwertsteuersatz2';
    xdsAuftragNettosumme01.SetParentComponent(xdsAuftrag);
    xdsAuftragNettosumme01.Name := 'xdsAuftragNettosumme01';
    xdsAuftragNettosumme01.FieldName := 'Nettosumme01';
    xdsAuftragNettosumme02.SetParentComponent(xdsAuftrag);
    xdsAuftragNettosumme02.Name := 'xdsAuftragNettosumme02';
    xdsAuftragNettosumme02.FieldName := 'Nettosumme02';
    xdsAuftragNettosumme.SetParentComponent(xdsAuftrag);
    xdsAuftragNettosumme.Name := 'xdsAuftragNettosumme';
    xdsAuftragNettosumme.FieldName := 'Nettosumme';
    xdsAuftragMehrwertsteuer01.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuer01.Name := 'xdsAuftragMehrwertsteuer01';
    xdsAuftragMehrwertsteuer01.FieldName := 'Mehrwertsteuer01';
    xdsAuftragMehrwertsteuer02.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuer02.Name := 'xdsAuftragMehrwertsteuer02';
    xdsAuftragMehrwertsteuer02.FieldName := 'Mehrwertsteuer02';
    xdsAuftragMehrwertsteuer.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuer.Name := 'xdsAuftragMehrwertsteuer';
    xdsAuftragMehrwertsteuer.FieldName := 'Mehrwertsteuer';
    xdsAuftragBruttosumme.SetParentComponent(xdsAuftrag);
    xdsAuftragBruttosumme.Name := 'xdsAuftragBruttosumme';
    xdsAuftragBruttosumme.FieldName := 'Bruttosumme';
    xdsAuftragVersanddatum.SetParentComponent(xdsAuftrag);
    xdsAuftragVersanddatum.Name := 'xdsAuftragVersanddatum';
    xdsAuftragVersanddatum.FieldName := 'Versanddatum';
    xdsAuftragSkontosatz.SetParentComponent(xdsAuftrag);
    xdsAuftragSkontosatz.Name := 'xdsAuftragSkontosatz';
    xdsAuftragSkontosatz.FieldName := 'Skontosatz';
    xdsAuftragNettosummeEK.SetParentComponent(xdsAuftrag);
    xdsAuftragNettosummeEK.Name := 'xdsAuftragNettosummeEK';
    xdsAuftragNettosummeEK.FieldName := 'NettosummeEK';
    xdsAuftragNettoMarge.SetParentComponent(xdsAuftrag);
    xdsAuftragNettoMarge.Name := 'xdsAuftragNettoMarge';
    xdsAuftragNettoMarge.FieldName := 'NettoMarge';
    xdsAuftragSchreibzeichen.SetParentComponent(xdsAuftrag);
    xdsAuftragSchreibzeichen.Name := 'xdsAuftragSchreibzeichen';
    xdsAuftragSchreibzeichen.FieldName := 'Schreibzeichen';
    xdsAuftragSchreibzeichen.Size := 10;
    xdsAuftragUnterzeichner.SetParentComponent(xdsAuftrag);
    xdsAuftragUnterzeichner.Name := 'xdsAuftragUnterzeichner';
    xdsAuftragUnterzeichner.FieldName := 'Unterzeichner';
    xdsAuftragUnterzeichner.Size := 50;
    xdsAuftragTelefonMA.SetParentComponent(xdsAuftrag);
    xdsAuftragTelefonMA.Name := 'xdsAuftragTelefonMA';
    xdsAuftragTelefonMA.FieldName := 'TelefonMA';
    xdsAuftragTelefonMA.Size := 30;
    xdsAuftragEmailMA.SetParentComponent(xdsAuftrag);
    xdsAuftragEmailMA.Name := 'xdsAuftragEmailMA';
    xdsAuftragEmailMA.FieldName := 'EmailMA';
    xdsAuftragEmailMA.Size := 50;
    xdsAuftragUSTID.SetParentComponent(xdsAuftrag);
    xdsAuftragUSTID.Name := 'xdsAuftragUSTID';
    xdsAuftragUSTID.FieldName := 'USTID';
    xdsAuftragUmsatzsteuerausweis.SetParentComponent(xdsAuftrag);
    xdsAuftragUmsatzsteuerausweis.Name := 'xdsAuftragUmsatzsteuerausweis';
    xdsAuftragUmsatzsteuerausweis.FieldName := 'Umsatzsteuerausweis';
    xdsAuftraggebucht.SetParentComponent(xdsAuftrag);
    xdsAuftraggebucht.Name := 'xdsAuftraggebucht';
    xdsAuftraggebucht.FieldName := 'gebucht';
    xdsAuftragLieferdatum.SetParentComponent(xdsAuftrag);
    xdsAuftragLieferdatum.Name := 'xdsAuftragLieferdatum';
    xdsAuftragLieferdatum.FieldName := 'Lieferdatum';
    xdsAuftragTransportbedingung.SetParentComponent(xdsAuftrag);
    xdsAuftragTransportbedingung.Name := 'xdsAuftragTransportbedingung';
    xdsAuftragTransportbedingung.FieldName := 'Transportbedingung';
    xdsAuftragTransportbedingung.Size := 100;
    xdsAuftragMehrwertsteuersatz3.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz3.Name := 'xdsAuftragMehrwertsteuersatz3';
    xdsAuftragMehrwertsteuersatz3.FieldName := 'Mehrwertsteuersatz3';
    xdsAuftragMehrwertsteuer03.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuer03.Name := 'xdsAuftragMehrwertsteuer03';
    xdsAuftragMehrwertsteuer03.FieldName := 'Mehrwertsteuer03';
    xdsAuftragNettosumme03.SetParentComponent(xdsAuftrag);
    xdsAuftragNettosumme03.Name := 'xdsAuftragNettosumme03';
    xdsAuftragNettosumme03.FieldName := 'Nettosumme03';
    xdsAuftragRundungsDifferenz.SetParentComponent(xdsAuftrag);
    xdsAuftragRundungsDifferenz.Name := 'xdsAuftragRundungsDifferenz';
    xdsAuftragRundungsDifferenz.FieldName := 'RundungsDifferenz';
    xdsAuftragEmailText.SetParentComponent(xdsAuftrag);
    xdsAuftragEmailText.Name := 'xdsAuftragEmailText';
    xdsAuftragEmailText.FieldName := 'EmailText';
    xdsAuftragEmailText.Size := 255;
    xdsAuftragAuftragerledigt.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragerledigt.Name := 'xdsAuftragAuftragerledigt';
    xdsAuftragAuftragerledigt.FieldName := 'Auftragerledigt';
    xdsAuftragRabattstufe.SetParentComponent(xdsAuftrag);
    xdsAuftragRabattstufe.Name := 'xdsAuftragRabattstufe';
    xdsAuftragRabattstufe.FieldName := 'Rabattstufe';
    xdsAuftragRabattstufe.Size := 15;
    xdsAuftragKFZKennzeichen.SetParentComponent(xdsAuftrag);
    xdsAuftragKFZKennzeichen.Name := 'xdsAuftragKFZKennzeichen';
    xdsAuftragKFZKennzeichen.FieldName := 'KFZKennzeichen';
    xdsAuftragKFZKennzeichen.Size := 13;
    xdsAuftragFahrgestellnummer.SetParentComponent(xdsAuftrag);
    xdsAuftragFahrgestellnummer.Name := 'xdsAuftragFahrgestellnummer';
    xdsAuftragFahrgestellnummer.FieldName := 'Fahrgestellnummer';
    xdsAuftragKFZHersteller.SetParentComponent(xdsAuftrag);
    xdsAuftragKFZHersteller.Name := 'xdsAuftragKFZHersteller';
    xdsAuftragKFZHersteller.FieldName := 'KFZHersteller';
    xdsAuftragErstzulassungsdatum.SetParentComponent(xdsAuftrag);
    xdsAuftragErstzulassungsdatum.Name := 'xdsAuftragErstzulassungsdatum';
    xdsAuftragErstzulassungsdatum.FieldName := 'Erstzulassungsdatum';
    xdsAuftragKMStand.SetParentComponent(xdsAuftrag);
    xdsAuftragKMStand.Name := 'xdsAuftragKMStand';
    xdsAuftragKMStand.FieldName := 'KMStand';
    xdsAuftragKMStand.Size := 6;
    xdsAuftragKFZType.SetParentComponent(xdsAuftrag);
    xdsAuftragKFZType.Name := 'xdsAuftragKFZType';
    xdsAuftragKFZType.FieldName := 'KFZType';
    xdsAuftragKFZType.Size := 25;
    xdsAuftragInspektionsdatum.SetParentComponent(xdsAuftrag);
    xdsAuftragInspektionsdatum.Name := 'xdsAuftragInspektionsdatum';
    xdsAuftragInspektionsdatum.FieldName := 'Inspektionsdatum';
    xdsAuftragDebitorennummer.SetParentComponent(xdsAuftrag);
    xdsAuftragDebitorennummer.Name := 'xdsAuftragDebitorennummer';
    xdsAuftragDebitorennummer.FieldName := 'Debitorennummer';
    xdsAuftragDebitorennummer.Size := 13;
    xdsAuftragKostentraeger.SetParentComponent(xdsAuftrag);
    xdsAuftragKostentraeger.Name := 'xdsAuftragKostentraeger';
    xdsAuftragKostentraeger.FieldName := 'Kostentraeger';
    xdsAuftragKostentraeger.Size := 13;
    xdsAuftragRabattsatz.SetParentComponent(xdsAuftrag);
    xdsAuftragRabattsatz.Name := 'xdsAuftragRabattsatz';
    xdsAuftragRabattsatz.FieldName := 'Rabattsatz';
    xdsAuftragObjektReferenz.SetParentComponent(xdsAuftrag);
    xdsAuftragObjektReferenz.Name := 'xdsAuftragObjektReferenz';
    xdsAuftragObjektReferenz.FieldName := 'ObjektReferenz';
    xdsAuftragObjektReferenz.Size := 13;
    xdsAuftragBank.SetParentComponent(xdsAuftrag);
    xdsAuftragBank.Name := 'xdsAuftragBank';
    xdsAuftragBank.FieldName := 'Bank';
    xdsAuftragBank.Size := 50;
    xdsAuftragBankleitzahl.SetParentComponent(xdsAuftrag);
    xdsAuftragBankleitzahl.Name := 'xdsAuftragBankleitzahl';
    xdsAuftragBankleitzahl.FieldName := 'Bankleitzahl';
    xdsAuftragKontonummer.SetParentComponent(xdsAuftrag);
    xdsAuftragKontonummer.Name := 'xdsAuftragKontonummer';
    xdsAuftragKontonummer.FieldName := 'Kontonummer';
    xdsAuftragKontonummer.Size := 50;
    xdsAuftragKontoinhaber.SetParentComponent(xdsAuftrag);
    xdsAuftragKontoinhaber.Name := 'xdsAuftragKontoinhaber';
    xdsAuftragKontoinhaber.FieldName := 'Kontoinhaber';
    xdsAuftragKontoinhaber.Size := 50;
    xdsAuftragTelefaxnummer.SetParentComponent(xdsAuftrag);
    xdsAuftragTelefaxnummer.Name := 'xdsAuftragTelefaxnummer';
    xdsAuftragTelefaxnummer.FieldName := 'Telefaxnummer';
    xdsAuftragTelefaxnummer.Size := 25;
    xdsAuftragDatumNeu.SetParentComponent(xdsAuftrag);
    xdsAuftragDatumNeu.Name := 'xdsAuftragDatumNeu';
    xdsAuftragDatumNeu.FieldName := 'DatumNeu';
    xdsAuftragDatumgeaendert.SetParentComponent(xdsAuftrag);
    xdsAuftragDatumgeaendert.Name := 'xdsAuftragDatumgeaendert';
    xdsAuftragDatumgeaendert.FieldName := 'Datumgeaendert';
    xdsAuftragObjektnummer.SetParentComponent(xdsAuftrag);
    xdsAuftragObjektnummer.Name := 'xdsAuftragObjektnummer';
    xdsAuftragObjektnummer.FieldName := 'Objektnummer';
    xdsAuftragObjektnummer.Size := 25;
    xdsAuftragLiefertermin.SetParentComponent(xdsAuftrag);
    xdsAuftragLiefertermin.Name := 'xdsAuftragLiefertermin';
    xdsAuftragLiefertermin.FieldName := 'Liefertermin';
    xdsAuftragLiefertermin.Size := 50;
    xdsAuftragEmailversand.SetParentComponent(xdsAuftrag);
    xdsAuftragEmailversand.Name := 'xdsAuftragEmailversand';
    xdsAuftragEmailversand.FieldName := 'Emailversand';
    xdsAuftragTelefonnummer.SetParentComponent(xdsAuftrag);
    xdsAuftragTelefonnummer.Name := 'xdsAuftragTelefonnummer';
    xdsAuftragTelefonnummer.FieldName := 'Telefonnummer';
    xdsAuftragTelefonnummer.Size := 35;
    xdsAuftragMobilTelefon.SetParentComponent(xdsAuftrag);
    xdsAuftragMobilTelefon.Name := 'xdsAuftragMobilTelefon';
    xdsAuftragMobilTelefon.FieldName := 'MobilTelefon';
    xdsAuftragMobilTelefon.Size := 25;
    xdsAuftragAuftragFax.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragFax.Name := 'xdsAuftragAuftragFax';
    xdsAuftragAuftragFax.FieldName := 'AuftragFax';
    xdsAuftragREDatum.SetParentComponent(xdsAuftrag);
    xdsAuftragREDatum.Name := 'xdsAuftragREDatum';
    xdsAuftragREDatum.FieldName := 'REDatum';
    xdsAuftragBenutzerNeu.SetParentComponent(xdsAuftrag);
    xdsAuftragBenutzerNeu.Name := 'xdsAuftragBenutzerNeu';
    xdsAuftragBenutzerNeu.FieldName := 'BenutzerNeu';
    xdsAuftragBenutzerNeu.Size := 50;
    xdsAuftragBenutzerGeaendert.SetParentComponent(xdsAuftrag);
    xdsAuftragBenutzerGeaendert.Name := 'xdsAuftragBenutzerGeaendert';
    xdsAuftragBenutzerGeaendert.FieldName := 'BenutzerGeaendert';
    xdsAuftragBenutzerGeaendert.Size := 50;
    xdsAuftragBerechnungBrutto.SetParentComponent(xdsAuftrag);
    xdsAuftragBerechnungBrutto.Name := 'xdsAuftragBerechnungBrutto';
    xdsAuftragBerechnungBrutto.FieldName := 'BerechnungBrutto';
    xdsAuftragBruttosumme01.SetParentComponent(xdsAuftrag);
    xdsAuftragBruttosumme01.Name := 'xdsAuftragBruttosumme01';
    xdsAuftragBruttosumme01.FieldName := 'Bruttosumme01';
    xdsAuftragBruttosumme02.SetParentComponent(xdsAuftrag);
    xdsAuftragBruttosumme02.Name := 'xdsAuftragBruttosumme02';
    xdsAuftragBruttosumme02.FieldName := 'Bruttosumme02';
    xdsAuftragBruttosumme03.SetParentComponent(xdsAuftrag);
    xdsAuftragBruttosumme03.Name := 'xdsAuftragBruttosumme03';
    xdsAuftragBruttosumme03.FieldName := 'Bruttosumme03';
    xdsAuftragNotiz.SetParentComponent(xdsAuftrag);
    xdsAuftragNotiz.Name := 'xdsAuftragNotiz';
    xdsAuftragNotiz.FieldName := 'Notiz';
    xdsAuftragNotiz.Size := 255;
    xdsAuftragMargeProzent.SetParentComponent(xdsAuftrag);
    xdsAuftragMargeProzent.Name := 'xdsAuftragMargeProzent';
    xdsAuftragMargeProzent.FieldName := 'MargeProzent';
    xdsAuftragWarenkreditgesperrt.SetParentComponent(xdsAuftrag);
    xdsAuftragWarenkreditgesperrt.Name := 'xdsAuftragWarenkreditgesperrt';
    xdsAuftragWarenkreditgesperrt.FieldName := 'Warenkreditgesperrt';
    xdsAuftragBelegsprache.SetParentComponent(xdsAuftrag);
    xdsAuftragBelegsprache.Name := 'xdsAuftragBelegsprache';
    xdsAuftragBelegsprache.FieldName := 'Belegsprache';
    xdsAuftragBelegsprache.Size := 10;
    xdsAuftragDuplicate.SetParentComponent(xdsAuftrag);
    xdsAuftragDuplicate.Name := 'xdsAuftragDuplicate';
    xdsAuftragDuplicate.FieldName := 'Duplicate';
    xdsAuftragperEmail.SetParentComponent(xdsAuftrag);
    xdsAuftragperEmail.Name := 'xdsAuftragperEmail';
    xdsAuftragperEmail.FieldName := 'perEmail';
    xdsAuftragAnsprechpartner.SetParentComponent(xdsAuftrag);
    xdsAuftragAnsprechpartner.Name := 'xdsAuftragAnsprechpartner';
    xdsAuftragAnsprechpartner.FieldName := 'Ansprechpartner';
    xdsAuftragAnsprechpartner.Size := 105;
    xdsAuftragKundennummerFremd.SetParentComponent(xdsAuftrag);
    xdsAuftragKundennummerFremd.Name := 'xdsAuftragKundennummerFremd';
    xdsAuftragKundennummerFremd.FieldName := 'KundennummerFremd';
    xdsAuftragProtokoll.SetParentComponent(xdsAuftrag);
    xdsAuftragProtokoll.Name := 'xdsAuftragProtokoll';
    xdsAuftragProtokoll.FieldName := 'Protokoll';
    xdsAuftragProtokoll.Size := 255;
    xdsAuftragVerleihauftrag.SetParentComponent(xdsAuftrag);
    xdsAuftragVerleihauftrag.Name := 'xdsAuftragVerleihauftrag';
    xdsAuftragVerleihauftrag.FieldName := 'Verleihauftrag';
    xdsAuftragVerleihVon.SetParentComponent(xdsAuftrag);
    xdsAuftragVerleihVon.Name := 'xdsAuftragVerleihVon';
    xdsAuftragVerleihVon.FieldName := 'VerleihVon';
    xdsAuftragVerleihBis.SetParentComponent(xdsAuftrag);
    xdsAuftragVerleihBis.Name := 'xdsAuftragVerleihBis';
    xdsAuftragVerleihBis.FieldName := 'VerleihBis';
    xdsAuftragAuftragGenehmigt.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragGenehmigt.Name := 'xdsAuftragAuftragGenehmigt';
    xdsAuftragAuftragGenehmigt.FieldName := 'AuftragGenehmigt';
    xdsAuftraggesperrt.SetParentComponent(xdsAuftrag);
    xdsAuftraggesperrt.Name := 'xdsAuftraggesperrt';
    xdsAuftraggesperrt.FieldName := 'gesperrt';
    xdsAuftragVerleihvonText.SetParentComponent(xdsAuftrag);
    xdsAuftragVerleihvonText.Name := 'xdsAuftragVerleihvonText';
    xdsAuftragVerleihvonText.FieldName := 'VerleihvonText';
    xdsAuftragVerleihvonText.Size := 50;
    xdsAuftragVerleihbisText.SetParentComponent(xdsAuftrag);
    xdsAuftragVerleihbisText.Name := 'xdsAuftragVerleihbisText';
    xdsAuftragVerleihbisText.FieldName := 'VerleihbisText';
    xdsAuftragVerleihbisText.Size := 50;
    xdsAuftragDruckWHG.SetParentComponent(xdsAuftrag);
    xdsAuftragDruckWHG.Name := 'xdsAuftragDruckWHG';
    xdsAuftragDruckWHG.FieldName := 'DruckWHG';
    xdsAuftragDruckWHG.Size := 3;
    xdsAuftragDruckWHGKurs.SetParentComponent(xdsAuftrag);
    xdsAuftragDruckWHGKurs.Name := 'xdsAuftragDruckWHGKurs';
    xdsAuftragDruckWHGKurs.FieldName := 'DruckWHGKurs';
    xdsAuftragProzentsatzZusatzposition.SetParentComponent(xdsAuftrag);
    xdsAuftragProzentsatzZusatzposition.Name := 'xdsAuftragProzentsatzZusatzposition';
    xdsAuftragProzentsatzZusatzposition.FieldName := 'ProzentsatzZusatzposition';
    xdsAuftragTextZusatzposition.SetParentComponent(xdsAuftrag);
    xdsAuftragTextZusatzposition.Name := 'xdsAuftragTextZusatzposition';
    xdsAuftragTextZusatzposition.FieldName := 'TextZusatzposition';
    xdsAuftragTextZusatzposition.Size := 255;
    xdsAuftragTextzusatzBeschreibung.SetParentComponent(xdsAuftrag);
    xdsAuftragTextzusatzBeschreibung.Name := 'xdsAuftragTextzusatzBeschreibung';
    xdsAuftragTextzusatzBeschreibung.FieldName := 'TextzusatzBeschreibung';
    xdsAuftragTextzusatzBeschreibung.Size := 50;
    xdsAuftragLayoutname.SetParentComponent(xdsAuftrag);
    xdsAuftragLayoutname.Name := 'xdsAuftragLayoutname';
    xdsAuftragLayoutname.FieldName := 'Layoutname';
    xdsAuftragLayoutname.Size := 50;
    xdsAuftragGesamtgewicht.SetParentComponent(xdsAuftrag);
    xdsAuftragGesamtgewicht.Name := 'xdsAuftragGesamtgewicht';
    xdsAuftragGesamtgewicht.FieldName := 'Gesamtgewicht';
    xdsAuftragBetragZusatzposition.SetParentComponent(xdsAuftrag);
    xdsAuftragBetragZusatzposition.Name := 'xdsAuftragBetragZusatzposition';
    xdsAuftragBetragZusatzposition.FieldName := 'BetragZusatzposition';
    xdsAuftragReferenzBestellung.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzBestellung.Name := 'xdsAuftragReferenzBestellung';
    xdsAuftragReferenzBestellung.FieldName := 'ReferenzBestellung';
    xdsAuftragReferenzBestellung.Size := 10;
    xdsAuftragTermin1.SetParentComponent(xdsAuftrag);
    xdsAuftragTermin1.Name := 'xdsAuftragTermin1';
    xdsAuftragTermin1.FieldName := 'Termin1';
    xdsAuftragTermin2.SetParentComponent(xdsAuftrag);
    xdsAuftragTermin2.Name := 'xdsAuftragTermin2';
    xdsAuftragTermin2.FieldName := 'Termin2';
    xdsAuftragZielkontaktnummer.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktnummer.Name := 'xdsAuftragZielkontaktnummer';
    xdsAuftragZielkontaktnummer.FieldName := 'Zielkontaktnummer';
    xdsAuftragZielkontaktnummer.Size := 13;
    xdsAuftragZielkontaktname.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktname.Name := 'xdsAuftragZielkontaktname';
    xdsAuftragZielkontaktname.FieldName := 'Zielkontaktname';
    xdsAuftragZielkontaktname.Size := 40;
    xdsAuftragBilddatei1.SetParentComponent(xdsAuftrag);
    xdsAuftragBilddatei1.Name := 'xdsAuftragBilddatei1';
    xdsAuftragBilddatei1.FieldName := 'Bilddatei1';
    xdsAuftragBilddatei1.Size := 255;
    xdsAuftragBilddatei2.SetParentComponent(xdsAuftrag);
    xdsAuftragBilddatei2.Name := 'xdsAuftragBilddatei2';
    xdsAuftragBilddatei2.FieldName := 'Bilddatei2';
    xdsAuftragBilddatei2.Size := 255;
    xdsAuftragZielkontaktnummer2.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktnummer2.Name := 'xdsAuftragZielkontaktnummer2';
    xdsAuftragZielkontaktnummer2.FieldName := 'Zielkontaktnummer2';
    xdsAuftragZielkontaktnummer2.Size := 13;
    xdsAuftragZielkontaktname2.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktname2.Name := 'xdsAuftragZielkontaktname2';
    xdsAuftragZielkontaktname2.FieldName := 'Zielkontaktname2';
    xdsAuftragZielkontaktname2.Size := 40;
    xdsAuftragbestaetigt.SetParentComponent(xdsAuftrag);
    xdsAuftragbestaetigt.Name := 'xdsAuftragbestaetigt';
    xdsAuftragbestaetigt.FieldName := 'bestaetigt';
    xdsAuftragZielkontaktnummer3.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktnummer3.Name := 'xdsAuftragZielkontaktnummer3';
    xdsAuftragZielkontaktnummer3.FieldName := 'Zielkontaktnummer3';
    xdsAuftragZielkontaktnummer3.Size := 13;
    xdsAuftragZielkontaktname3.SetParentComponent(xdsAuftrag);
    xdsAuftragZielkontaktname3.Name := 'xdsAuftragZielkontaktname3';
    xdsAuftragZielkontaktname3.FieldName := 'Zielkontaktname3';
    xdsAuftragZielkontaktname3.Size := 40;
    xdsAuftragTermin3.SetParentComponent(xdsAuftrag);
    xdsAuftragTermin3.Name := 'xdsAuftragTermin3';
    xdsAuftragTermin3.FieldName := 'Termin3';
    xdsAuftragTermin4.SetParentComponent(xdsAuftrag);
    xdsAuftragTermin4.Name := 'xdsAuftragTermin4';
    xdsAuftragTermin4.FieldName := 'Termin4';
    xdsAuftragMuster.SetParentComponent(xdsAuftrag);
    xdsAuftragMuster.Name := 'xdsAuftragMuster';
    xdsAuftragMuster.FieldName := 'Muster';
    xdsAuftragKW.SetParentComponent(xdsAuftrag);
    xdsAuftragKW.Name := 'xdsAuftragKW';
    xdsAuftragKW.FieldName := 'KW';
    xdsAuftragDatenlink.SetParentComponent(xdsAuftrag);
    xdsAuftragDatenlink.Name := 'xdsAuftragDatenlink';
    xdsAuftragDatenlink.FieldName := 'Datenlink';
    xdsAuftragDatenlink.Size := 255;
    xdsAuftragMehrwertsteuersatz4.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz4.Name := 'xdsAuftragMehrwertsteuersatz4';
    xdsAuftragMehrwertsteuersatz4.FieldName := 'Mehrwertsteuersatz4';
    xdsAuftragMehrwertsteuersatz5.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz5.Name := 'xdsAuftragMehrwertsteuersatz5';
    xdsAuftragMehrwertsteuersatz5.FieldName := 'Mehrwertsteuersatz5';
    xdsAuftragMehrwertsteuersatz6.SetParentComponent(xdsAuftrag);
    xdsAuftragMehrwertsteuersatz6.Name := 'xdsAuftragMehrwertsteuersatz6';
    xdsAuftragMehrwertsteuersatz6.FieldName := 'Mehrwertsteuersatz6';
    xdsAuftragBesteuerungKey.SetParentComponent(xdsAuftrag);
    xdsAuftragBesteuerungKey.Name := 'xdsAuftragBesteuerungKey';
    xdsAuftragBesteuerungKey.FieldName := 'BesteuerungKey';
    xdsAuftragVertreterMatchcode.SetParentComponent(xdsAuftrag);
    xdsAuftragVertreterMatchcode.Name := 'xdsAuftragVertreterMatchcode';
    xdsAuftragVertreterMatchcode.FieldName := 'VertreterMatchcode';
    xdsAuftragVertreterMatchcode.Size := 50;
    xdsAuftragVerfasser.SetParentComponent(xdsAuftrag);
    xdsAuftragVerfasser.Name := 'xdsAuftragVerfasser';
    xdsAuftragVerfasser.FieldName := 'Verfasser';
    xdsAuftragVerfasser.Size := 10;
    xdsAuftragLieferadresseID.SetParentComponent(xdsAuftrag);
    xdsAuftragLieferadresseID.Name := 'xdsAuftragLieferadresseID';
    xdsAuftragLieferadresseID.FieldName := 'LieferadresseID';
    xdsAuftragAuftragStatus.SetParentComponent(xdsAuftrag);
    xdsAuftragAuftragStatus.Name := 'xdsAuftragAuftragStatus';
    xdsAuftragAuftragStatus.FieldName := 'AuftragStatus';
    xdsAuftragAuftragStatus.Size := 30;
    xdsAuftragMonatJahr.SetParentComponent(xdsAuftrag);
    xdsAuftragMonatJahr.Name := 'xdsAuftragMonatJahr';
    xdsAuftragMonatJahr.FieldName := 'MonatJahr';
    xdsAuftragBelegvariante.SetParentComponent(xdsAuftrag);
    xdsAuftragBelegvariante.Name := 'xdsAuftragBelegvariante';
    xdsAuftragBelegvariante.FieldName := 'Belegvariante';
    xdsAuftragBelegvariante.Size := 25;
    xdsAuftragProduktionsfreigabe.SetParentComponent(xdsAuftrag);
    xdsAuftragProduktionsfreigabe.Name := 'xdsAuftragProduktionsfreigabe';
    xdsAuftragProduktionsfreigabe.FieldName := 'Produktionsfreigabe';
    xdsAuftragReferenzRahmenAuftrag.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzRahmenAuftrag.Name := 'xdsAuftragReferenzRahmenAuftrag';
    xdsAuftragReferenzRahmenAuftrag.FieldName := 'ReferenzRahmenAuftrag';
    xdsAuftragReferenzRahmenAuftrag.Size := 10;
    xdsAuftragDatumRahmenAuftrag.SetParentComponent(xdsAuftrag);
    xdsAuftragDatumRahmenAuftrag.Name := 'xdsAuftragDatumRahmenAuftrag';
    xdsAuftragDatumRahmenAuftrag.FieldName := 'DatumRahmenAuftrag';
    xdsAuftragFahrzeugID.SetParentComponent(xdsAuftrag);
    xdsAuftragFahrzeugID.Name := 'xdsAuftragFahrzeugID';
    xdsAuftragFahrzeugID.FieldName := 'FahrzeugID';
    xdsAuftragAuslieferungKW.SetParentComponent(xdsAuftrag);
    xdsAuftragAuslieferungKW.Name := 'xdsAuftragAuslieferungKW';
    xdsAuftragAuslieferungKW.FieldName := 'AuslieferungKW';
    xdsAuftragAuslieferungKW.Size := 25;
    xdsAuftragBereitstellungDatum.SetParentComponent(xdsAuftrag);
    xdsAuftragBereitstellungDatum.Name := 'xdsAuftragBereitstellungDatum';
    xdsAuftragBereitstellungDatum.FieldName := 'BereitstellungDatum';
    xdsAuftragVTUID.SetParentComponent(xdsAuftrag);
    xdsAuftragVTUID.Name := 'xdsAuftragVTUID';
    xdsAuftragVTUID.FieldName := 'VTUID';
    xdsAuftragVTUID.Size := 38;
    xdsAuftragLieferanschriftDaten.SetParentComponent(xdsAuftrag);
    xdsAuftragLieferanschriftDaten.Name := 'xdsAuftragLieferanschriftDaten';
    xdsAuftragLieferanschriftDaten.FieldName := 'LieferanschriftDaten';
    xdsAuftragLieferanschriftDaten.Size := 255;
    xdsAuftragGesamtVolumen.SetParentComponent(xdsAuftrag);
    xdsAuftragGesamtVolumen.Name := 'xdsAuftragGesamtVolumen';
    xdsAuftragGesamtVolumen.FieldName := 'GesamtVolumen';
    xdsAuftragBelegPKey.SetParentComponent(xdsAuftrag);
    xdsAuftragBelegPKey.Name := 'xdsAuftragBelegPKey';
    xdsAuftragBelegPKey.FieldName := 'BelegPKey';
    xdsAuftragDruckParameter.SetParentComponent(xdsAuftrag);
    xdsAuftragDruckParameter.Name := 'xdsAuftragDruckParameter';
    xdsAuftragDruckParameter.FieldName := 'DruckParameter';
    xdsAuftragDruckParameter.Size := 255;
    xdsAuftragReferenzService.SetParentComponent(xdsAuftrag);
    xdsAuftragReferenzService.Name := 'xdsAuftragReferenzService';
    xdsAuftragReferenzService.FieldName := 'ReferenzService';
    xdsAuftragReferenzService.Size := 10;
    xdsAuftragZusatzDaten.SetParentComponent(xdsAuftrag);
    xdsAuftragZusatzDaten.Name := 'xdsAuftragZusatzDaten';
    xdsAuftragZusatzDaten.FieldName := 'ZusatzDaten';
    xdsAuftragZusatzDaten.Size := 255;
    xdsAuftragStandPDF.SetParentComponent(xdsAuftrag);
    xdsAuftragStandPDF.Name := 'xdsAuftragStandPDF';
    xdsAuftragStandPDF.FieldName := 'StandPDF';
    xdsAuftragStandPDF.Size := 255;
    xdsAuftragPaketdienst.SetParentComponent(xdsAuftrag);
    xdsAuftragPaketdienst.Name := 'xdsAuftragPaketdienst';
    xdsAuftragPaketdienst.FieldName := 'Paketdienst';
    xdsAuftragPaketdienst.Size := 30;
    xdsAuftragMonteur.SetParentComponent(xdsAuftrag);
    xdsAuftragMonteur.Name := 'xdsAuftragMonteur';
    xdsAuftragMonteur.FieldName := 'Monteur';
    xdsAuftragKundennummerKundennummer.SetParentComponent(xdsAuftrag);
    xdsAuftragKundennummerKundennummer.Name := 'xdsAuftragKundennummerKundennummer';
    xdsAuftragKundennummerKundennummer.FieldName := 'Kundennummer.Kundennummer';
    xdsAuftragKundennummerKundennummer.Size := 13;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 40;
    WClient.Top := 176;
  finally
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    btnNeuerServiceBericht.AfterLoadDFMValues;
    btnNeuerAuftrag.AfterLoadDFMValues;
    xdsAuftrag.AfterLoadDFMValues;
    xdsAuftragKundennummer.AfterLoadDFMValues;
    xdsAuftragNummerkomplett.AfterLoadDFMValues;
    xdsAuftragReferenzAngebot.AfterLoadDFMValues;
    xdsAuftragReferenzLieferschein.AfterLoadDFMValues;
    xdsAuftragReferenzRechnung.AfterLoadDFMValues;
    xdsAuftragDatum.AfterLoadDFMValues;
    xdsAuftragJahr.AfterLoadDFMValues;
    xdsAuftragKalenderwoche.AfterLoadDFMValues;
    xdsAuftragNachname.AfterLoadDFMValues;
    xdsAuftragVorname.AfterLoadDFMValues;
    xdsAuftragPostleitzahl.AfterLoadDFMValues;
    xdsAuftragOrt.AfterLoadDFMValues;
    xdsAuftragLaenderkuerzel.AfterLoadDFMValues;
    xdsAuftragLand.AfterLoadDFMValues;
    xdsAuftragBriefanrede1.AfterLoadDFMValues;
    xdsAuftragBriefanrede2.AfterLoadDFMValues;
    xdsAuftragEMail.AfterLoadDFMValues;
    xdsAuftragAdresseBild.AfterLoadDFMValues;
    xdsAuftragLieferanschriftBild.AfterLoadDFMValues;
    xdsAuftragZahlungsbedingung.AfterLoadDFMValues;
    xdsAuftragZahlungsbedingungText.AfterLoadDFMValues;
    xdsAuftragAuftragsbezeichnung.AfterLoadDFMValues;
    xdsAuftragAuftragnummerKunde.AfterLoadDFMValues;
    xdsAuftragBestelldatumKunde.AfterLoadDFMValues;
    xdsAuftragILN.AfterLoadDFMValues;
    xdsAuftragKopftext.AfterLoadDFMValues;
    xdsAuftragSchlussbemerkung.AfterLoadDFMValues;
    xdsAuftragGruss.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz1.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz2.AfterLoadDFMValues;
    xdsAuftragNettosumme01.AfterLoadDFMValues;
    xdsAuftragNettosumme02.AfterLoadDFMValues;
    xdsAuftragNettosumme.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuer01.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuer02.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuer.AfterLoadDFMValues;
    xdsAuftragBruttosumme.AfterLoadDFMValues;
    xdsAuftragVersanddatum.AfterLoadDFMValues;
    xdsAuftragSkontosatz.AfterLoadDFMValues;
    xdsAuftragNettosummeEK.AfterLoadDFMValues;
    xdsAuftragNettoMarge.AfterLoadDFMValues;
    xdsAuftragSchreibzeichen.AfterLoadDFMValues;
    xdsAuftragUnterzeichner.AfterLoadDFMValues;
    xdsAuftragTelefonMA.AfterLoadDFMValues;
    xdsAuftragEmailMA.AfterLoadDFMValues;
    xdsAuftragUSTID.AfterLoadDFMValues;
    xdsAuftragUmsatzsteuerausweis.AfterLoadDFMValues;
    xdsAuftraggebucht.AfterLoadDFMValues;
    xdsAuftragLieferdatum.AfterLoadDFMValues;
    xdsAuftragTransportbedingung.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz3.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuer03.AfterLoadDFMValues;
    xdsAuftragNettosumme03.AfterLoadDFMValues;
    xdsAuftragRundungsDifferenz.AfterLoadDFMValues;
    xdsAuftragEmailText.AfterLoadDFMValues;
    xdsAuftragAuftragerledigt.AfterLoadDFMValues;
    xdsAuftragRabattstufe.AfterLoadDFMValues;
    xdsAuftragKFZKennzeichen.AfterLoadDFMValues;
    xdsAuftragFahrgestellnummer.AfterLoadDFMValues;
    xdsAuftragKFZHersteller.AfterLoadDFMValues;
    xdsAuftragErstzulassungsdatum.AfterLoadDFMValues;
    xdsAuftragKMStand.AfterLoadDFMValues;
    xdsAuftragKFZType.AfterLoadDFMValues;
    xdsAuftragInspektionsdatum.AfterLoadDFMValues;
    xdsAuftragDebitorennummer.AfterLoadDFMValues;
    xdsAuftragKostentraeger.AfterLoadDFMValues;
    xdsAuftragRabattsatz.AfterLoadDFMValues;
    xdsAuftragObjektReferenz.AfterLoadDFMValues;
    xdsAuftragBank.AfterLoadDFMValues;
    xdsAuftragBankleitzahl.AfterLoadDFMValues;
    xdsAuftragKontonummer.AfterLoadDFMValues;
    xdsAuftragKontoinhaber.AfterLoadDFMValues;
    xdsAuftragTelefaxnummer.AfterLoadDFMValues;
    xdsAuftragDatumNeu.AfterLoadDFMValues;
    xdsAuftragDatumgeaendert.AfterLoadDFMValues;
    xdsAuftragObjektnummer.AfterLoadDFMValues;
    xdsAuftragLiefertermin.AfterLoadDFMValues;
    xdsAuftragEmailversand.AfterLoadDFMValues;
    xdsAuftragTelefonnummer.AfterLoadDFMValues;
    xdsAuftragMobilTelefon.AfterLoadDFMValues;
    xdsAuftragAuftragFax.AfterLoadDFMValues;
    xdsAuftragREDatum.AfterLoadDFMValues;
    xdsAuftragBenutzerNeu.AfterLoadDFMValues;
    xdsAuftragBenutzerGeaendert.AfterLoadDFMValues;
    xdsAuftragBerechnungBrutto.AfterLoadDFMValues;
    xdsAuftragBruttosumme01.AfterLoadDFMValues;
    xdsAuftragBruttosumme02.AfterLoadDFMValues;
    xdsAuftragBruttosumme03.AfterLoadDFMValues;
    xdsAuftragNotiz.AfterLoadDFMValues;
    xdsAuftragMargeProzent.AfterLoadDFMValues;
    xdsAuftragWarenkreditgesperrt.AfterLoadDFMValues;
    xdsAuftragBelegsprache.AfterLoadDFMValues;
    xdsAuftragDuplicate.AfterLoadDFMValues;
    xdsAuftragperEmail.AfterLoadDFMValues;
    xdsAuftragAnsprechpartner.AfterLoadDFMValues;
    xdsAuftragKundennummerFremd.AfterLoadDFMValues;
    xdsAuftragProtokoll.AfterLoadDFMValues;
    xdsAuftragVerleihauftrag.AfterLoadDFMValues;
    xdsAuftragVerleihVon.AfterLoadDFMValues;
    xdsAuftragVerleihBis.AfterLoadDFMValues;
    xdsAuftragAuftragGenehmigt.AfterLoadDFMValues;
    xdsAuftraggesperrt.AfterLoadDFMValues;
    xdsAuftragVerleihvonText.AfterLoadDFMValues;
    xdsAuftragVerleihbisText.AfterLoadDFMValues;
    xdsAuftragDruckWHG.AfterLoadDFMValues;
    xdsAuftragDruckWHGKurs.AfterLoadDFMValues;
    xdsAuftragProzentsatzZusatzposition.AfterLoadDFMValues;
    xdsAuftragTextZusatzposition.AfterLoadDFMValues;
    xdsAuftragTextzusatzBeschreibung.AfterLoadDFMValues;
    xdsAuftragLayoutname.AfterLoadDFMValues;
    xdsAuftragGesamtgewicht.AfterLoadDFMValues;
    xdsAuftragBetragZusatzposition.AfterLoadDFMValues;
    xdsAuftragReferenzBestellung.AfterLoadDFMValues;
    xdsAuftragTermin1.AfterLoadDFMValues;
    xdsAuftragTermin2.AfterLoadDFMValues;
    xdsAuftragZielkontaktnummer.AfterLoadDFMValues;
    xdsAuftragZielkontaktname.AfterLoadDFMValues;
    xdsAuftragBilddatei1.AfterLoadDFMValues;
    xdsAuftragBilddatei2.AfterLoadDFMValues;
    xdsAuftragZielkontaktnummer2.AfterLoadDFMValues;
    xdsAuftragZielkontaktname2.AfterLoadDFMValues;
    xdsAuftragbestaetigt.AfterLoadDFMValues;
    xdsAuftragZielkontaktnummer3.AfterLoadDFMValues;
    xdsAuftragZielkontaktname3.AfterLoadDFMValues;
    xdsAuftragTermin3.AfterLoadDFMValues;
    xdsAuftragTermin4.AfterLoadDFMValues;
    xdsAuftragMuster.AfterLoadDFMValues;
    xdsAuftragKW.AfterLoadDFMValues;
    xdsAuftragDatenlink.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz4.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz5.AfterLoadDFMValues;
    xdsAuftragMehrwertsteuersatz6.AfterLoadDFMValues;
    xdsAuftragBesteuerungKey.AfterLoadDFMValues;
    xdsAuftragVertreterMatchcode.AfterLoadDFMValues;
    xdsAuftragVerfasser.AfterLoadDFMValues;
    xdsAuftragLieferadresseID.AfterLoadDFMValues;
    xdsAuftragAuftragStatus.AfterLoadDFMValues;
    xdsAuftragMonatJahr.AfterLoadDFMValues;
    xdsAuftragBelegvariante.AfterLoadDFMValues;
    xdsAuftragProduktionsfreigabe.AfterLoadDFMValues;
    xdsAuftragReferenzRahmenAuftrag.AfterLoadDFMValues;
    xdsAuftragDatumRahmenAuftrag.AfterLoadDFMValues;
    xdsAuftragFahrzeugID.AfterLoadDFMValues;
    xdsAuftragAuslieferungKW.AfterLoadDFMValues;
    xdsAuftragBereitstellungDatum.AfterLoadDFMValues;
    xdsAuftragVTUID.AfterLoadDFMValues;
    xdsAuftragLieferanschriftDaten.AfterLoadDFMValues;
    xdsAuftragGesamtVolumen.AfterLoadDFMValues;
    xdsAuftragBelegPKey.AfterLoadDFMValues;
    xdsAuftragDruckParameter.AfterLoadDFMValues;
    xdsAuftragReferenzService.AfterLoadDFMValues;
    xdsAuftragZusatzDaten.AfterLoadDFMValues;
    xdsAuftragStandPDF.AfterLoadDFMValues;
    xdsAuftragPaketdienst.AfterLoadDFMValues;
    xdsAuftragMonteur.AfterLoadDFMValues;
    xdsAuftragKundennummerKundennummer.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
  end;
end;

end.