unit unit_BarcodeScan;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Devices, WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TBarcodeScan = class(TForm)
    WebBarcodeDecoder1: TBarcodeDecoder;
    WebCamera1: TCamera;
    Container2: THTMLContainer;
    Container1: THTMLContainer;
    btnAbbrechen: TButton;
    btnstart: TButton;
    labelergebnis: TLabel;
    Container3: THTMLContainer;
    procedure WebCamera1CameraDevicesInitialized(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure btnAbbrechenClick(Sender: TObject);
    procedure btnstartClick(Sender: TObject);
    procedure WebBarcodeDecoder1Decoded(Sender: TObject; ADecoded: string);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    ScanErgebnis:string;
  end;

var
  BarcodeScan: TBarcodeScan;

implementation


procedure TBarcodeScan.btnstartClick(Sender: TObject);
begin

WebCamera1.Start;
end;

procedure TBarcodeScan.WebBarcodeDecoder1Decoded(Sender: TObject;
  ADecoded: string);
begin
  ScanErgebnis:= trim(ADecoded);
  labelergebnis.Caption:=ScanErgebnis;
  WebCamera1.Stop;
  self.Close;
end;

procedure TBarcodeScan.btnAbbrechenClick(Sender: TObject);
begin
  ScanErgebnis:='';
  webcamera1.Stop;
  self.Close;
end;

procedure TBarcodeScan.WebCamera1CameraDevicesInitialized(Sender: TObject);
begin
labelergebnis.Caption:='Camera gestartet';
btnstart.Visible:=false;
end;

procedure TBarcodeScan.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Barcode scannen</h5></nav>';
  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  btnabbrechen.ParentElement:=myelement;
  btnstart.ParentElement:=myelement;
  myelement:=container3.ElementHandle;
  labelergebnis.ParentElement:=myelement;
  webcamera1.ParentElement:=myelement;
  btnstartClick(Sender);
end;

procedure TBarcodeScan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  labelergebnis := TLabel.Create(Self);
  WebCamera1 := TCamera.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  btnAbbrechen := TButton.Create(Self);
  btnstart := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  WebBarcodeDecoder1 := TBarcodeDecoder.Create(Self);

  labelergebnis.BeforeLoadDFMValues;
  WebCamera1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  btnAbbrechen.BeforeLoadDFMValues;
  btnstart.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  WebBarcodeDecoder1.BeforeLoadDFMValues;
  try
    Name := 'BarcodeScan';
    Width := 175;
    Height := 348;
    Caption := 'BarcodeScan';
    CSSLibrary := cssBootstrap;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    labelergebnis.SetParentComponent(Self);
    labelergebnis.Name := 'labelergebnis';
    labelergebnis.Left := 8;
    labelergebnis.Top := 140;
    labelergebnis.Width := 64;
    labelergebnis.Height := 14;
    labelergebnis.Caption := 'labelergebnis';
    labelergebnis.ElementClassName := 'h6 m-1';
    labelergebnis.ElementFont := efCSS;
    labelergebnis.HeightStyle := ssAuto;
    WebCamera1.SetParentComponent(Self);
    WebCamera1.Name := 'WebCamera1';
    WebCamera1.Left := 0;
    WebCamera1.Top := 222;
    WebCamera1.Width := 169;
    WebCamera1.Height := 118;
    WebCamera1.HeightStyle := ssAuto;
    WebCamera1.WidthStyle := ssPercent;
    WebCamera1.WidthPercent := 90;
    WebCamera1.CameraType := ctRear;
    SetEvent(WebCamera1, Self, 'OnCameraDevicesInitialized', 'WebCamera1CameraDevicesInitialized');
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 2;
    Container1.ElementFont := efCSS;
    btnAbbrechen.SetParentComponent(Self);
    btnAbbrechen.Name := 'btnAbbrechen';
    btnAbbrechen.Left := 0;
    btnAbbrechen.Top := 160;
    btnAbbrechen.Width := 96;
    btnAbbrechen.Height := 25;
    btnAbbrechen.Caption := 'ABBRECHEN';
    btnAbbrechen.ChildOrder := 3;
    btnAbbrechen.ElementClassName := 'btn btn-info m-1 text-left';
    btnAbbrechen.ElementFont := efCSS;
    btnAbbrechen.HeightStyle := ssAuto;
    btnAbbrechen.WidthStyle := ssAuto;
    SetEvent(btnAbbrechen, Self, 'OnClick', 'btnAbbrechenClick');
    btnstart.SetParentComponent(Self);
    btnstart.Name := 'btnstart';
    btnstart.Left := 0;
    btnstart.Top := 191;
    btnstart.Width := 96;
    btnstart.Height := 25;
    btnstart.Caption := 'START';
    btnstart.ChildOrder := 3;
    btnstart.ElementClassName := 'btn btn-info m-1 text-left';
    btnstart.ElementFont := efCSS;
    btnstart.HeightStyle := ssAuto;
    btnstart.WidthStyle := ssAuto;
    SetEvent(btnstart, Self, 'OnClick', 'btnstartClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    WebBarcodeDecoder1.SetParentComponent(Self);
    WebBarcodeDecoder1.Name := 'WebBarcodeDecoder1';
    WebBarcodeDecoder1.Camera := WebCamera1;
    WebBarcodeDecoder1.EnableTimer := True;
    WebBarcodeDecoder1.Reader := [bcvCode128,bcvEAN,bcvEAN8,bcvCode39,bcvCode39VIN,bcvCodabar,bcvUPC,bcvUPCE,bcvI2OF5,bcv2OF5,bcvCode93];
    SetEvent(WebBarcodeDecoder1, Self, 'OnDecoded', 'WebBarcodeDecoder1Decoded');
    WebBarcodeDecoder1.Left := 120;
    WebBarcodeDecoder1.Top := 26;
  finally
    labelergebnis.AfterLoadDFMValues;
    WebCamera1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    btnAbbrechen.AfterLoadDFMValues;
    btnstart.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    WebBarcodeDecoder1.AfterLoadDFMValues;
  end;
end;

end.