program CadiaWEB;

{$R *.dres}

uses
  WEBLib.Forms,
  WEBLib.WebTools,
  Unit1 in 'Unit1.pas'{*.html},
  Unit2 in 'Unit2.pas',
  unit_Kontaktsuche in 'unit_Kontaktsuche.pas'{*.html},
  unit_Artikelsuche in 'unit_Artikelsuche.pas'{*.html},
  unit_Einzeltermin in 'unit_Einzeltermin.pas'{*.html},
  unit_Objektsuche in 'unit_Objektsuche.pas'{*.html},
  unit_EinzelAuftrag in 'unit_EinzelAuftrag.pas'{*.html},
  unit_Anrufe in 'unit_Anrufe.pas'{*.html},
  Unit_Artikel in 'Unit_Artikel.pas'{*.html},
  unit_Terminkalender in 'unit_Terminkalender.pas'{*.html},
  unit_ListeAuftrag in 'unit_ListeAuftrag.pas'{*.html},
  unit_EinzelKontakt in 'unit_EinzelKontakt.pas'{*.html},
  unit_EinzelAnruf in 'unit_EinzelAnruf.pas'{*.html},
  unit_ListeAngebot in 'unit_ListeAngebot.pas'{*.html},
  unit_PZE in 'unit_PZE.pas'{*.html},
  unit_Karte in 'unit_Karte.pas'{*.html},
  unit_BelegeUndPositionen in 'unit_BelegeUndPositionen.pas'{*.html},
  unit_EmailEingang in 'unit_EmailEingang.pas'{*.html},
  unit_EinzelBeleg in 'unit_EinzelBeleg.pas'{*.html},
  unit_AuftragUnterschriften in 'unit_AuftragUnterschriften.pas'{*.html},
  unit_ListeLieferschein in 'unit_ListeLieferschein.pas'{*.html},
  unit_EinzelLieferschein in 'unit_EinzelLieferschein.pas'{*.html},
  unit_LieferscheinUnterschrift in 'unit_LieferscheinUnterschrift.pas'{*.html},
  unit_AuftragTermine in 'unit_AuftragTermine.pas'{*.html},
  unit_EinzelEmail in 'unit_EinzelEmail.pas'{*.html},
  unit_Preise in 'unit_Preise.pas'{*.html},
  unit_BarcodeScan in 'unit_BarcodeScan.pas'{*.html},
  unit_AuftragPosEinzel in 'unit_AuftragPosEinzel.pas'{*.html},
  Unit_AngebotPosEinzel in 'Unit_AngebotPosEinzel.pas'{*.html},
  unit_EinzelAngebot in 'unit_EinzelAngebot.pas';

{$R *.res}
  var
  s:string;
begin
  Application.Initialize;
  Application.AutoFormRoute:=true;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.CreateForm(TDM2, DM2);
  Application.Run;
end.
