{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Storage;

interface

uses
  Web;

type
  TLocalStorage = class(TObject)
  private
  protected
    procedure SetValues(AKey, AValue: string);
    function GetValues(AKey: string): string;
    function GetKey(AIndex: integer): string;
    function GetCount: integer;
  public
    property Keys[AIndex: integer]: string read GetKey;
    property Count: integer read GetCount;
    property Values[Key: string]: string read GetValues write SetValues;
    class procedure SetValue(AKey, AValue: string);
    class function GetValue(AKey: string): string;
    class procedure RemoveKey(AKey: string);
  end;

  TWebLocalStorage = class(TLocalStorage);

  TSessionStorage = class(TObject)
  private
  protected
    procedure SetValues(AKey, AValue: string);
    function GetValues(AKey: string): string;
    function GetKey(AIndex: integer): string;
    function GetCount: integer;
  public
    property Keys[AIndex: integer]: string read GetKey;
    property Count: integer read GetCount;
    property Values[key: string]: string read GetValues write SetValues;
    class procedure SetValue(AKey, AValue: string);
    class function GetValue(AKey: string): string;
  end;

  TWebSessionStorage = class(TSessionStorage);

implementation

{ TLocalStorage }

class procedure TLocalStorage.SetValue(AKey, AValue: string);
begin
  window.localStorage.setItem(AKey, AValue);
end;

procedure TLocalStorage.SetValues(AKey, AValue: string);
begin
  window.localStorage.setItem(AKey, AValue);
end;

class function TLocalStorage.GetValue(AKey: string): string;
var
  s: string;
begin
  s := window.localStorage.getItem(AKey);
  if not Assigned(s) then
    Result := ''
  else
    Result := s;
end;

function TLocalStorage.GetValues(AKey: string): string;
var
  s: string;
begin
  s := window.localStorage.getItem(AKey);
  if not Assigned(s) then
    Result := ''
  else
    Result := s;
end;

class procedure TLocalStorage.RemoveKey(AKey: string);
begin
  window.localStorage.removeItem(AKey);
end;

function TLocalStorage.GetKey(AIndex: integer): string;
begin
  Result := window.localStorage.Keys[AIndex];
end;

function TLocalStorage.GetCount: integer;
begin
  Result := window.localStorage.length;
end;

{ TSessionStorage }

class procedure TSessionStorage.SetValue(AKey, AValue: string);
begin
  window.sessionStorage.setItem(AKey, AValue);
end;

procedure TSessionStorage.SetValues(AKey, AValue: string);
begin
  window.sessionStorage.setItem(AKey, AValue);
end;

class function TSessionStorage.GetValue(AKey: string): string;
var
  s: string;
begin
  s := window.sessionStorage.getItem(AKey);
  if not Assigned(s) then
    Result := ''
  else
    Result := s;
end;

function TSessionStorage.GetValues(AKey: string): string;
var
  s: string;
begin
  s := window.sessionStorage.getItem(AKey);
  if not Assigned(s) then
    Result := ''
  else
    Result := s;
end;

function TSessionStorage.GetKey(AIndex: integer): string;
begin
  Result := window.sessionStorage.Keys[AIndex];
end;

function TSessionStorage.GetCount: integer;
begin
  Result := window.sessionStorage.length;
end;



end.