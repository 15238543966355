{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2018 - 2020                                 }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDatePicker;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.Controls, WEBLib.TMSFNCCustomSelector,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes, WEBLib.StdCtrls, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCalendar, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCStyles, WEBLib.TMSFNCUtils
  {$IFNDEF LCLLIB}
  ,Types
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ,TypInfo
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 5; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : Property AllowNumericNullValue added
  //         : New : Method Clear added
  //         : Fixed : Issue with OnDateSelected event
  //v1.0.1.1 : Fixed : Issue with applying font
  //v1.0.1.2 : Fixed : Issue with control color in disabled state
  //v1.0.1.3 : Fixed : Issue with keyboard date selection
  //v1.0.1.4 : Improved : Exposed Font property
  //v1.0.1.5 : Improved : Date validation
  //         : Improved : Exposed OnValidate event

type
  TTMSFNCCustomDatePickerDateSelected = procedure(Sender: TObject; ADate: TDate) of object;
  TTMSFNCCustomDatePickerValidateEvent = procedure(Sender: TObject; AEditText: string; var ADate: TDateTime; var ADefault: Boolean) of object;

  TTMSFNCDatePickerInteraction = class(TTMSFNCCalendarCustomInteraction)
  published
    property ReadOnlyMode;
    property SwipeOnHeader;
    property KeyboardSupport;
    property NavigationMode;
  end;

  TTMSFNCCustomDatePicker = class(TTMSFNCDefaultPicker, ITMSFNCBitmapContainer)
  private
    FPrevSelDate: TDate;
    FWrapper: TTMSFNCCustomSelector;
    FDateSelector: TTMSFNCCalendar;
    FOnDateSelected: TTMSFNCCustomDatePickerDateSelected;
    FCloseOnSelection: Boolean;
    FOnCustomize: TNotifyEvent;
    FHeader: TTMSFNCCalendarHeader;
    FInteraction: TTMSFNCDatePickerInteraction;
    FOnAfterDrawBadge: TTMSFNCCalendarAfterDrawBadgeEvent;
    FOnAfterDrawArrowRight: TTMSFNCCalendarAfterDrawArrowRightEvent;
    FOnAfterDrawDayNamesText: TTMSFNCCalendarAfterDrawDayNamesTextEvent;
    FOnAfterDrawWeekNumbersText: TTMSFNCCalendarAfterDrawWeekNumbersTextEvent;
    FOnBeforeDrawHeader: TTMSFNCCalendarBeforeDrawHeaderEvent;
    FOnBeforeDrawDayNumbersText: TTMSFNCCalendarBeforeDrawDayNumbersTextEvent;
    FOnBeforeDrawFooterText: TTMSFNCCalendarBeforeDrawFooterTextEvent;
    FOnBeforeDrawLines: TTMSFNCCalendarBeforeDrawLinesEvent;
    FOnAfterDrawDayNames: TTMSFNCCalendarAfterDrawDayNamesEvent;
    FOnAfterDrawWeekNumbers: TTMSFNCCalendarAfterDrawWeekNumbersEvent;
    FOnAfterDrawHeaderText: TTMSFNCCalendarAfterDrawHeaderTextEvent;
    FOnBeforeDrawArrowLeft: TTMSFNCCalendarBeforeDrawArrowLeftEvent;
    FOnBeforeDrawDayNumbers: TTMSFNCCalendarBeforeDrawDayNumbersEvent;
    FOnBeforeDrawFooter: TTMSFNCCalendarBeforeDrawFooterEvent;
    FOnBeforeDrawBadge: TTMSFNCCalendarBeforeDrawBadgeEvent;
    FOnAfterDrawHeader: TTMSFNCCalendarAfterDrawHeaderEvent;
    FOnBeforeDrawArrowRight: TTMSFNCCalendarBeforeDrawArrowRightEvent;
    FOnAfterDrawDayNumbersText: TTMSFNCCalendarAfterDrawDayNumbersTextEvent;
    FOnBeforeDrawDayNamesText: TTMSFNCCalendarBeforeDrawDayNamesTextEvent;
    FOnBeforeDrawWeekNumbersText: TTMSFNCCalendarBeforeDrawWeekNumbersTextEvent;
    FOnAfterDrawFooterText: TTMSFNCCalendarAfterDrawFooterTextEvent;
    FOnAfterDrawLines: TTMSFNCCalendarAfterDrawLinesEvent;
    FOnAfterDrawArrowLeft: TTMSFNCCalendarAfterDrawArrowLeftEvent;
    FOnAfterDrawDayNumbers: TTMSFNCCalendarAfterDrawDayNumbersEvent;
    FOnBeforeDrawDayNames: TTMSFNCCalendarBeforeDrawDayNamesEvent;
    FOnBeforeDrawWeekNumbers: TTMSFNCCalendarBeforeDrawWeekNumbersEvent;
    FOnAfterDrawFooter: TTMSFNCCalendarAfterDrawFooterEvent;
    FOnBeforeDrawHeaderText: TTMSFNCCalendarBeforeDrawHeaderTextEvent;
    FOnYearChanged: TTMSFNCCalendarYearChangedEvent;
    FOnCustomArrowDraw: TTMSFNCCalendarCustomArrowDrawEvent;
    FOnMonthChanged: TTMSFNCCalendarMonthChangedEvent;
    FOnSelectMultiDate: TTMSFNCCalendarSelectedMultiDateEvent;
    FOnBeforeSelectDate: TTMSFNCCalendarBeforeSelectDateEvent;
    FOnCustomNavigation: TTMSFNCCalendarCustomNavigationEvent;
    FAllowNumericNullValue: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FOnValidate: TTMSFNCCustomDatePickerValidateEvent;
    procedure SetSelectedDate(const Value: TDate);
    procedure SetEvents(const Value: TTMSFNCCalendarEvents);
    procedure SetWeekNumberAppearance(const Value: TTMSFNCCalendarWeekNumberAppearance);
    procedure SetDateAppearance(const Value: TTMSFNCCalendarDateAppearance);
    procedure SetDayNameAppearance(const Value: TTMSFNCCalendarDayNameAppearance);
    procedure SetLineAppearance(const Value: TTMSFNCCalendarLineAppearance);
    procedure SetInteraction(const Value: TTMSFNCDatePickerInteraction);
    procedure SetMaxDate(const Value: TDate);
    procedure SetMinDate(const Value: TDate);
    procedure SetFirstDay(const Value: Integer);
    procedure SetHeader(const Value: TTMSFNCCalendarHeader);
    procedure SetFooter(const Value: TTMSFNCCalendarFooter);
    procedure SetCalendarFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCalendarStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetBadgeSize(const Value: Integer);
    procedure SetDay(const Value: Integer);
    procedure SetMonth(const Value: Integer);
    procedure SetYear(const Value: Integer);
    procedure SetAllowNumericNullValue(const Value: Boolean);
    function GetSelectedDate: TDate;
    function GetEvents: TTMSFNCCalendarEvents;
    function GetWeekNumberAppearance: TTMSFNCCalendarWeekNumberAppearance;
    function GetDateAppearance: TTMSFNCCalendarDateAppearance;
    function GetDayNameAppearance: TTMSFNCCalendarDayNameAppearance;
    function GetLineAppearance: TTMSFNCCalendarLineAppearance;
    function GetMaxDate: TDate;
    function GetMinDate: TDate;
    function GetFirstDay: Integer;
    function GetFooter: TTMSFNCCalendarFooter;
    function GetCalendarFill: TTMSFNCGraphicsFill;
    function GetCalendarStroke: TTMSFNCGraphicsStroke;
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function GetBadgeSize: Integer;
    function GetDay: Integer;
    function GetMonth: Integer;
    function GetYear: Integer;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
  protected
    procedure DoDateSelected; virtual;
    procedure DoValidate; virtual;
    procedure DoEditExit(Sender: TObject); virtual;
    procedure Loaded; override;
    procedure FontChange(Sender: TObject);
    procedure InitializePopup; override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure SetEditable(const Value: Boolean); override;
    procedure UpdateEditText(AText: string); override;
    procedure DateSelected(Sender: TObject; ADate: TDate); virtual;
    procedure DoBeforeDrawHeader(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeader(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawHeaderText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeaderText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawFooter(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawFooter(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawFooterText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawFooterText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawDayNames(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNames(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawDayNamesText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNamesText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawDayNumbers(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TrectF; ADate: TDate; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNumbers(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate); virtual;
    procedure DoBeforeDrawDayNumbersText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNumbersText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawLines(Sender: TObject; AGraphics: TTMSFNCGraphics; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawLines(Sender: TObject; AGraphics: TTMSFNCGraphics); virtual;
    procedure DoBeforeDrawWeekNumbers(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWeekNumbers(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawWeekNumbersText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWeekNumbersText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawBadge(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawBadge(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem); virtual;
    procedure DoBeforeDrawArrowLeft(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrowLeft(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF); virtual;
    procedure DoBeforeDrawArrowRight(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrowRight(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF); virtual;
    procedure DoBeforeSelectDate(Sender: TObject; ADate: TDate); virtual;
    procedure DoSelectMultiDate(Sender: TObject; AStartDate: TDate; AEndDate: TDate); virtual;
    procedure DoChangeYear(Sender: TObject; AYear: Integer); virtual;
    procedure DoChangeMonth(Sender: TObject; AMonth: Integer); virtual;
    procedure DoCustomNavigation(Sender: TObject; ADate: TDate; AFocusedDate: TDate; ADirection: Boolean); virtual;
    procedure DoCustomArrowDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ADirection: Boolean); virtual;
    function IsAppearanceProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; override;
    function GetVersion: string; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetFocusedControl: TControl; override;
    property AllowNumericNullValue: Boolean read FAllowNumericNullValue write SetAllowNumericNullValue default True;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default True;
    property OnDateSelected: TTMSFNCCustomDatePickerDateSelected read FOnDateSelected write FOnDateSelected;
    property SelectedDate: TDate read GetSelectedDate write SetSelectedDate;
    property OnCustomize: TNotifyEvent read FOnCustomize write FOnCustomize;
    property WeekNumberAppearance: TTMSFNCCalendarWeekNumberAppearance read GetWeekNumberAppearance write SetWeekNumberAppearance;
    property DayNameAppearance: TTMSFNCCalendarDayNameAppearance read GetDayNameAppearance write SetDayNameAppearance;
    property DateAppearance: TTMSFNCCalendarDateAppearance read GetDateAppearance write SetDateAppearance;
    property LineAppearance: TTMSFNCCalendarLineAppearance read GetLineAppearance write SetLineAppearance;
    property Interaction: TTMSFNCDatePickerInteraction read FInteraction write SetInteraction;
    property CalendarFill: TTMSFNCGraphicsFill read GetCalendarFill write SetCalendarFill;
    property CalendarStroke: TTMSFNCGraphicsStroke read GetCalendarStroke write SetCalendarStroke;
    property Events: TTMSFNCCalendarEvents read GetEvents write SetEvents;
    property BadgeSize: Integer read GetBadgeSize write SetBadgeSize;
    property FirstDay: Integer read GetFirstDay write SetFirstDay;
    property Header: TTMSFNCCalendarHeader read FHeader write SetHeader;
    property Footer: TTMSFNCCalendarFooter read GetFooter write SetFooter;
    property MaxDate: TDate read GetMaxDate write SetMaxDate;
    property MinDate: TDate read GetMinDate write SetMinDate;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property OnBeforeDrawHeader: TTMSFNCCalendarBeforeDrawHeaderEvent read FOnBeforeDrawHeader write FOnBeforeDrawHeader;
    property OnAfterDrawHeader: TTMSFNCCalendarAfterDrawHeaderEvent read FOnAfterDrawHeader write FOnAfterDrawHeader;
    property OnBeforeDrawHeaderText: TTMSFNCCalendarBeforeDrawHeaderTextEvent read FOnBeforeDrawHeaderText write FOnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText: TTMSFNCCalendarAfterDrawHeaderTextEvent read FOnAfterDrawHeaderText write FOnAfterDrawHeaderText;
    property OnBeforeDrawFooter: TTMSFNCCalendarBeforeDrawFooterEvent read FOnBeforeDrawFooter write FOnBeforeDrawFooter;
    property OnAfterDrawFooter: TTMSFNCCalendarAfterDrawFooterEvent read FOnAfterDrawFooter write FOnAfterDrawFooter;
    property OnBeforeDrawFooterText: TTMSFNCCalendarBeforeDrawFooterTextEvent read FOnBeforeDrawFooterText write FOnBeforeDrawFooterText;
    property OnAfterDrawFooterText: TTMSFNCCalendarAfterDrawFooterTextEvent read FOnAfterDrawFooterText write FOnAfterDrawFooterText;
    property OnBeforeDrawDayNames: TTMSFNCCalendarBeforeDrawDayNamesEvent read FOnBeforeDrawDayNames write FOnBeforeDrawDayNames;
    property OnAfterDrawDayNames: TTMSFNCCalendarAfterDrawDayNamesEvent read FOnAfterDrawDayNames write FOnAfterDrawDayNames;
    property OnBeforeDrawDayNamesText: TTMSFNCCalendarBeforeDrawDayNamesTextEvent read FOnBeforeDrawDayNamesText write FOnBeforeDrawDayNamesText;
    property OnAfterDrawDayNamesText: TTMSFNCCalendarAfterDrawDayNamesTextEvent read FOnAfterDrawDayNamesText write FOnAfterDrawDayNamesText;
    property OnBeforeDrawDayNumbers: TTMSFNCCalendarBeforeDrawDayNumbersEvent read FOnBeforeDrawDayNumbers write FOnBeforeDrawDayNumbers;
    property OnAfterDrawDayNumbers: TTMSFNCCalendarAfterDrawDayNumbersEvent read FOnAfterDrawDayNumbers write FOnAfterDrawDayNumbers;
    property OnBeforeDrawDayNumbersText: TTMSFNCCalendarBeforeDrawDayNumbersTextEvent read FOnBeforeDrawDayNumbersText write FOnBeforeDrawDayNumbersText;
    property OnAfterDrawDayNumbersText: TTMSFNCCalendarAfterDrawDayNumbersTextEvent read FOnAfterDrawDayNumbersText write FOnAfterDrawDayNumbersText;
    property OnBeforeDrawLines: TTMSFNCCalendarBeforeDrawLinesEvent read FOnBeforeDrawLines write FOnBeforeDrawLines;
    property OnAfterDrawLines: TTMSFNCCalendarAfterDrawLinesEvent read FOnAfterDrawLines write FOnAfterDrawLines;
    property OnBeforeDrawWeekNumbers: TTMSFNCCalendarBeforeDrawWeekNumbersEvent read FOnBeforeDrawWeekNumbers write FOnBeforeDrawWeekNumbers;
    property OnAfterDrawWeekNumbers: TTMSFNCCalendarAfterDrawWeekNumbersEvent read FOnAfterDrawWeekNumbers write FOnAfterDrawWeekNumbers;
    property OnBeforeDrawWeekNumbersText: TTMSFNCCalendarBeforeDrawWeekNumbersTextEvent read FOnBeforeDrawWeekNumbersText write FOnBeforeDrawWeekNumbersText;
    property OnAfterDrawWeekNumbersText: TTMSFNCCalendarAfterDrawWeekNumbersTextEvent read FOnAfterDrawWeekNumbersText write FOnAfterDrawWeekNumbersText;
    property OnBeforeDrawBadge: TTMSFNCCalendarBeforeDrawBadgeEvent read FOnBeforeDrawBadge write FOnBeforeDrawBadge;
    property OnAfterDrawBadge: TTMSFNCCalendarAfterDrawBadgeEvent read FOnAfterDrawBadge write FOnAfterDrawBadge;
    property OnBeforeDrawArrowLeft: TTMSFNCCalendarBeforeDrawArrowLeftEvent read FOnBeforeDrawArrowLeft write FOnBeforeDrawArrowLeft;
    property OnAfterDrawArrowLeft: TTMSFNCCalendarAfterDrawArrowLeftEvent read FOnAfterDrawArrowLeft write FOnAfterDrawArrowLeft;
    property OnBeforeDrawArrowRight: TTMSFNCCalendarBeforeDrawArrowRightEvent read FOnBeforeDrawArrowRight write FOnBeforeDrawArrowRight;
    property OnAfterDrawArrowRight: TTMSFNCCalendarAfterDrawArrowRightEvent read FOnAfterDrawArrowRight write FOnAfterDrawArrowRight;
    property OnBeforeSelectDate: TTMSFNCCalendarBeforeSelectDateEvent read FOnBeforeSelectDate write FOnBeforeSelectDate;
    property OnSelectMultiDate: TTMSFNCCalendarSelectedMultiDateEvent read FOnSelectMultiDate write FOnSelectMultiDate;
    property OnYearChanged: TTMSFNCCalendarYearChangedEvent read FOnYearChanged write FOnYearChanged;
    property OnMonthChanged: TTMSFNCCalendarMonthChangedEvent read FOnMonthChanged write FOnMonthChanged;
    property OnCustomNavigation: TTMSFNCCalendarCustomNavigationEvent read FOnCustomNavigation write FOnCustomNavigation;
    property OnCustomArrowDraw: TTMSFNCCalendarCustomArrowDrawEvent read FOnCustomArrowDraw write FOnCustomArrowDraw;
    property OnValidate: TTMSFNCCustomDatePickerValidateEvent read FOnValidate write FOnValidate;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear;
    property Day: Integer read GetDay write SetDay;
    property Month: Integer read GetMonth write SetMonth;
    property Year: Integer read GetYear write SetYear;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDatePicker = class(TTMSFNCCustomDatePicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AllowNumericNullValue;
    property BitmapContainer;
    property Editable;
    property FirstDay;
    property MaxDate;
    property MinDate;
    property Events;
    property BadgeSize;
    property Header;
    property Footer;
    property Font;
    property Interaction;
    property DropDownWidth;
    property DropDownHeight;
    property CloseOnSelection;
    property OnDateSelected;
    property SelectedDate;
    property WeekNumberAppearance;
    property DateAppearance;
    property DayNameAppearance;
    property LineAppearance;
    property CalendarFill;
    property CalendarStroke;
    property OnCustomize;
    property OnBeforeDrawHeader;
    property OnAfterDrawHeader;
    property OnBeforeDrawFooter;
    property OnAfterDrawFooter;
    property OnBeforeDrawDayNames;
    property OnAfterDrawDayNames;
    property OnBeforeDrawDayNumbers;
    property OnAfterDrawDayNumbers;
    property OnBeforeDrawLines;
    property OnAfterDrawLines;
    property OnBeforeDrawWeekNumbers;
    property OnAfterDrawWeekNumbers;
    property OnBeforeDrawArrowLeft;
    property OnAfterDrawArrowLeft;
    property OnBeforeDrawArrowRight;
    property OnAfterDrawArrowRight;
    property OnBeforeDrawBadge;
    property OnAfterDrawBadge;
    property OnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText;
    property OnBeforeDrawFooterText;
    property OnAfterDrawFooterText;
    property OnBeforeDrawDayNamesText;
    property OnAfterDrawDayNamesText;
    property OnBeforeDrawDayNumbersText;
    property OnAfterDrawDayNumbersText;
    property OnBeforeDrawWeekNumbersText;
    property OnAfterDrawWeekNumbersText;
    property OnBeforeSelectDate;
    property OnSelectMultiDate;
    property OnYearChanged;
    property OnMonthChanged;
    property OnCustomNavigation;
    property OnCustomArrowDraw;
    property OnValidate;
  end;

implementation

uses
  DateUtils, SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  ;

type
  TCustomEditProtected = class(TCustomEdit);
  TTMSFNCCustomSelectorOpen = class(TTMSFNCCustomSelector);

{ TTMSFNCCustomDatePicker }

procedure TTMSFNCCustomDatePicker.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
    FHeader.Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColorTo(c) then
  begin
    FHeader.Fill.Kind := gfkGradient;
    FHeader.Fill.ColorTo := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    Header.Stroke.Color := c;
    Header.Arrow.Color := c;
  end;
end;

procedure TTMSFNCCustomDatePicker.Clear;
begin
  SelectedDate := 0;
  Invalidate;
end;

constructor TTMSFNCCustomDatePicker.Create(AOwner: TComponent);
begin
  inherited;
  SelectedDate := Int(Now);
  FHeader := TTMSFNCCalendarHeader.Create(Self);
  FInteraction := TTMSFNCDatePickerInteraction.Create;
  FCloseOnSelection := True;
  FAllowNumericNullValue := True;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChange;
  Width := 90;
  DropDownWidth := 200;
  DropDownHeight := 180;
  {$IFDEF FMXLIB}
  Edit.StyledSettings := TCustomEditProtected(Edit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
  {$IFDEF ANDROID}
  Edit.TextSettings.VertAlign := TTextAlign.Leading;
  {$ENDIF}
  Edit.OnExit := @DoEditExit;
  {$ENDIF}
end;

function TTMSFNCCustomDatePicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FWrapper := TTMSFNCCustomSelector.Create(Self);
  FDateSelector := TTMSFNCCalendar.Create(FWrapper);
  FDateSelector.Parent := FWrapper;

  FDateSelector.OnSelectDate := DateSelected;
  FDateSelector.OnBeforeDrawHeader := DoBeforeDrawHeader;
  FDateSelector.OnAfterDrawHeader := DoAfterDrawHeader;
  FDateSelector.OnBeforeDrawFooter := DoBeforeDrawFooter;
  FDateSelector.OnAfterDrawFooter := DoAfterDrawFooter;
  FDateSelector.OnBeforeDrawDayNames := DoBeforeDrawDayNames;
  FDateSelector.OnAfterDrawDayNames := DoAfterDrawDayNames;
  FDateSelector.OnBeforeDrawDayNumbers := DoBeforeDrawDayNumbers;
  FDateSelector.OnAfterDrawDayNumbers := DoAfterDrawDayNumbers;
  FDateSelector.OnBeforeDrawLines := DoBeforeDrawLines;
  FDateSelector.OnAfterDrawLines := DoAfterDrawLines;
  FDateSelector.OnBeforeDrawWeekNumbers := DoBeforeDrawWeekNumbers;
  FDateSelector.OnAfterDrawWeekNumbers := DoAfterDrawWeekNumbers;
  FDateSelector.OnBeforeDrawArrowLeft := DoBeforeDrawArrowLeft;
  FDateSelector.OnAfterDrawArrowLeft := DoAfterDrawArrowLeft;
  FDateSelector.OnBeforeDrawArrowRight := DoBeforeDrawArrowRight;
  FDateSelector.OnAfterDrawArrowRight := DoAfterDrawArrowRight;
  FDateSelector.OnBeforeDrawBadge := DoBeforeDrawBadge;
  FDateSelector.OnAfterDrawBadge := DoAfterDrawBadge;
  FDateSelector.OnBeforeDrawHeaderText := DoBeforeDrawHeaderText;
  FDateSelector.OnAfterDrawHeaderText := DoAfterDrawHeaderText;
  FDateSelector.OnBeforeDrawFooterText := DoBeforeDrawFooterText;
  FDateSelector.OnAfterDrawFooterText := DoAfterDrawFooterText;
  FDateSelector.OnBeforeDrawDayNamesText := DoBeforeDrawDayNamesText;
  FDateSelector.OnAfterDrawDayNamesText := DoAfterDrawDayNamesText;
  FDateSelector.OnBeforeDrawDayNumbersText := DoBeforeDrawDayNumbersText;
  FDateSelector.OnAfterDrawDayNumbersText := DoAfterDrawDayNumbersText;
  FDateSelector.OnBeforeDrawWeekNumbersText := DoBeforeDrawWeekNumbersText;
  FDateSelector.OnAfterDrawWeekNumbersText := DoAfterDrawWeekNumbersText;
  FDateSelector.OnBeforeSelectDate := DoBeforeSelectDate;
  FDateSelector.OnSelectMultiDate := DoSelectMultiDate;
  FDateSelector.OnYearChanged := DoChangeYear;
  FDateSelector.OnMonthChanged := DoChangeMonth;
  FDateSelector.OnCustomNavigation := DoCustomNavigation;
  FDateSelector.OnCustomArrowDraw := DoCustomArrowDraw;

  Result := FWrapper;
end;

procedure TTMSFNCCustomDatePicker.DateSelected(Sender: TObject; ADate: TDate);
begin
  if CloseOnSelection then
    DropDown;

  SelectedDate := FDateSelector.SelectedDate;

  DoDateSelected;
end;

destructor TTMSFNCCustomDatePicker.Destroy;
begin
  FFont.Free;
  FHeader.Free;
  FInteraction.Free;
  inherited;
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawArrowLeft(Sender: TObject;
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF);
begin
  if Assigned(OnAfterDrawArrowLeft) then
    OnAfterDrawArrowLeft(Self, AGraphics, AArrowRect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawArrowRight(Sender: TObject;
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF);
begin
  if Assigned(OnAfterDrawArrowRight) then
    OnAfterDrawArrowRight(Self, AGraphics, AArrowRect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawBadge(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem);
begin
  if Assigned(OnAfterDrawBadge) then
    OnAfterDrawBadge(Self, AGraphics, ARect, ABadge);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawDayNames(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawDayNames) then
    OnAfterDrawDayNames(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawDayNamesText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawDayNamesText) then
    OnAfterDrawDayNamesText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawDayNumbers(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate);
begin
  if Assigned(OnAfterDrawDayNumbers) then
    OnAfterDrawDayNumbers(Self, AGraphics, ARect, ADate);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawDayNumbersText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawDayNumbersText) then
    OnAfterDrawDayNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawFooter(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawFooter) then
    OnAfterDrawFooter(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawFooterText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawFooterText) then
    OnAfterDrawFooterText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawHeader(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawHeader) then
    OnAfterDrawHeader(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawHeaderText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawHeaderText) then
    OnAfterDrawHeaderText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawLines(Sender: TObject;  AGraphics: TTMSFNCGraphics);
begin
  if Assigned(OnAfterDrawLines) then
    OnAfterDrawLines(Self, AGraphics);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawWeekNumbers(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawWeekNumbers) then
    OnAfterDrawWeekNumbers(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomDatePicker.DoAfterDrawWeekNumbersText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawWeekNumbersText) then
    OnAfterDrawWeekNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawArrowLeft(Sender: TObject;
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrowLeft) then
    OnBeforeDrawArrowLeft(Self, AGraphics, AArrowRect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawArrowRight(Sender: TObject;
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrowRight) then
    OnBeforeDrawArrowRight(Self, AGraphics, AArrowRect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawBadge(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBadge) then
    OnBeforeDrawBadge(Self, AGraphics, ARect, ABadge, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawDayNames(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNames) then
    OnBeforeDrawDayNames(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawDayNamesText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNamesText) then
    OnBeforeDrawDayNamesText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawDayNumbers(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TrectF; ADate: TDate; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNumbers) then
    OnBeforeDrawDayNumbers(Self, AGraphics, ARect, ADate, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawDayNumbersText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNumbersText) then
    OnBeforeDrawDayNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawFooter(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFooter) then
    OnBeforeDrawFooter(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawFooterText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFooterText) then
    OnBeforeDrawFooterText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawHeader(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeader) then
    OnBeforeDrawHeader(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawHeaderText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeaderText) then
    OnBeforeDrawHeaderText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawLines(Sender: TObject;
  AGraphics: TTMSFNCGraphics; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawLines) then
    OnBeforeDrawLines(Self, AGraphics, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawWeekNumbers(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawWeekNumbers) then
    OnBeforeDrawWeekNumbers(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeDrawWeekNumbersText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawWeekNumbersText) then
    OnBeforeDrawWeekNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDatePicker.DoBeforeSelectDate(Sender: TObject;
  ADate: TDate);
begin
  if Assigned(OnBeforeSelectDate) then
    OnBeforeSelectDate(Self, ADate);
end;

procedure TTMSFNCCustomDatePicker.DoChangeMonth(Sender: TObject;
  AMonth: Integer);
begin
  if Assigned(OnMonthChanged) then
    OnMonthChanged(Self, AMonth);
end;

procedure TTMSFNCCustomDatePicker.DoChangeYear(Sender: TObject; AYear: Integer);
begin
  if Assigned(OnYearChanged) then
    OnYearChanged(Self, AYear);
end;

procedure TTMSFNCCustomDatePicker.DoCustomArrowDraw(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ADirection: Boolean);
begin
  if Assigned(OnCustomArrowDraw) then
    OnCustomArrowDraw(Self, AGraphics, ARect, ADirection);
end;

procedure TTMSFNCCustomDatePicker.DoCustomNavigation(Sender: TObject; ADate,
  AFocusedDate: TDate; ADirection: Boolean);
begin
  if Assigned(OnCustomNavigation) then
    OnCustomNavigation(Self, ADate, AFocusedDate, ADirection);
end;

procedure TTMSFNCCustomDatePicker.DoDateSelected;
begin
  if Assigned(OnDateSelected) then
    OnDateSelected(Self, SelectedDate);

  Invalidate;
end;

procedure TTMSFNCCustomDatePicker.DoEditExit(Sender: TObject);
begin
  DoValidate;
end;

procedure TTMSFNCCustomDatePicker.DoSelectMultiDate(Sender: TObject; AStartDate,
  AEndDate: TDate);
begin
  if Assigned(OnSelectMultiDate) then
    OnSelectMultiDate(Self, AStartDate, AEndDate);
end;

procedure TTMSFNCCustomDatePicker.DoValidate;
var
  d: TDateTime;
  df: Boolean;
begin
  df := True;
  d := SelectedDate;
  if Assigned(OnValidate) then
    OnValidate(Self, Edit.Text, d, df);

  if df then
  begin
    if TryStrToDate(Edit.Text, d) then
      SelectedDate := Int(d)
    else
      SelectedDate := Int(Now);
  end
  else
    SelectedDate := Int(d);
end;

procedure TTMSFNCCustomDatePicker.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
begin
  inherited;
  if not Editable then
  begin
    AGraphics.Font.AssignSource(Font);

    if not Enabled then
      AGraphics.Font.Color := gcSilver;

    r := ARect;
    InflateRectEx(r, -3, -3);
    AGraphics.DrawText(r, Content);
  end;
end;

procedure TTMSFNCCustomDatePicker.FontChange(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCCustomDatePicker.GetBadgeSize: Integer;
begin
  Result := FDateSelector.BadgeSize;
end;

function TTMSFNCCustomDatePicker.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FDateSelector.BitmapContainer;
end;

function TTMSFNCCustomDatePicker.GetCalendarFill: TTMSFNCGraphicsFill;
begin
  Result := FDateSelector.Fill;
end;

function TTMSFNCCustomDatePicker.GetCalendarStroke: TTMSFNCGraphicsStroke;
begin
  Result := FDateSelector.Stroke;
end;

function TTMSFNCCustomDatePicker.GetDateAppearance: TTMSFNCCalendarDateAppearance;
begin
  Result := FDateSelector.DateAppearance;
end;

function TTMSFNCCustomDatePicker.GetDay: Integer;
begin
  Result := FDateSelector.Day;
end;

function TTMSFNCCustomDatePicker.GetDayNameAppearance: TTMSFNCCalendarDayNameAppearance;
begin
  Result := FDateSelector.DayNameAppearance;
end;

function TTMSFNCCustomDatePicker.GetEvents: TTMSFNCCalendarEvents;
begin
  Result := FDateSelector.Events;
end;

function TTMSFNCCustomDatePicker.GetFirstDay: Integer;
begin
  Result := FDateSelector.FirstDay;
end;

function TTMSFNCCustomDatePicker.GetFocusedControl: TControl;
begin
  Result := FDateSelector;
end;

function TTMSFNCCustomDatePicker.GetFooter: TTMSFNCCalendarFooter;
begin
  Result := FDateSelector.Footer;
end;

function TTMSFNCCustomDatePicker.GetLineAppearance: TTMSFNCCalendarLineAppearance;
begin
  Result := FDateSelector.LineAppearance;
end;

function TTMSFNCCustomDatePicker.GetMaxDate: TDate;
begin
  Result := FDateSelector.MaxDate;
end;

function TTMSFNCCustomDatePicker.GetMinDate: TDate;
begin
  Result := FDateSelector.MinDate;
end;

function TTMSFNCCustomDatePicker.GetMonth: Integer;
begin
  Result := FDateSelector.Month;
end;

function TTMSFNCCustomDatePicker.GetSelectedDate: TDate;
begin
  Result := 0;
  if (FDateSelector.SelectedDates.Count > 0) then
    Result := TDate(FDateSelector.SelectedDate);
end;

function TTMSFNCCustomDatePicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomDatePicker.GetWeekNumberAppearance: TTMSFNCCalendarWeekNumberAppearance;
begin
  Result := FDateSelector.WeekNumberAppearance;
end;

function TTMSFNCCustomDatePicker.GetYear: Integer;
begin
  Result := FDateSelector.Year;
end;

procedure TTMSFNCCustomDatePicker.HandleKeyDown(var Key: Word;
  Shift: TShiftState);
begin
  inherited;
  case Key of
    KEY_PRIOR:
    begin
      if FDateSelector.Month <> 1 then
        SelectedDate := EncodeDate(FDateSelector.Year, FDateSelector.PreviousMonth, 1)
      else
        SelectedDate := EncodeDate(FDateSelector.PreviousYear, FDateSelector.PreviousMonth, 1);
    end;
    KEY_NEXT:
    begin
      if FDateSelector.Month <> 12 then
        SelectedDate := EncodeDate(FDateSelector.Year, FDateSelector.NextMonth, 1)
      else
        SelectedDate := EncodeDate(FDateSelector.NextYear, FDateSelector.NextMonth, 1);
    end;
  end;
end;

procedure TTMSFNCCustomDatePicker.InitializePopup;
begin
  inherited;
  if FAllowNumericNullValue and (Int(SelectedDate) = 0) then
  begin
    FDateSelector.Date := Int(Now)
  end
  else if Editable and Assigned(Edit.Parent) and Edit.Visible then
  begin
    DoValidate;
  end;

  TTMSFNCCustomSelectorOpen(FWrapper).Fill.Assign(FDateSelector.Fill);
  TTMSFNCCustomSelectorOpen(FWrapper).Stroke.Assign(FDateSelector.Stroke);
  FDateSelector.Width := Round(DropDownWidth);
  FDateSelector.Height := Round(DropDownHeight);
  FDateSelector.Header.Assign(FHeader);
  FDateSelector.Interaction.Assign(FInteraction);
  FDateSelector.Invalidate;
end;

function TTMSFNCCustomDatePicker.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Header') or (APropertyName = 'Footer');
end;

procedure TTMSFNCCustomDatePicker.Loaded;
begin
  inherited;
  FDateSelector.Width := Round(DropDownWidth);
  FDateSelector.Height := Round(DropDownHeight);
  FDateSelector.UpdateControlAfterResize;
end;

procedure TTMSFNCCustomDatePicker.ResetToDefaultStyle;
begin
  inherited;
  FHeader.Fill.Kind := gfkSolid;
  FHeader.Stroke.Kind := gskSolid;
  FHeader.Fill.Color := MakeGraphicsColor(228, 228, 228);
  FHeader.Stroke.Color := gcSilver;
  FHeader.Font.Color := gcBlack;
  FHeader.Arrow.Color := gcDimgray;
end;

procedure TTMSFNCCustomDatePicker.SelectFirstValue;
var
  d: TDate;
begin
  inherited;
  d := EncodeDate(FDateSelector.Year, FDateSelector.Month, 1);
  if (Int(MinDate) < Int(d)) and (CompareDate(SelectedDate, d) <> 0) then
  begin
    SelectedDate := d;
    DoDateSelected;
  end
  else if (CompareDate(SelectedDate, MinDate) <> 0) and (CompareDate(SelectedDate, d) <> 0) then
  begin
    SelectedDate := MinDate;
    DoDateSelected;
  end;
end;

procedure TTMSFNCCustomDatePicker.SelectLastValue;
var
  d: TDate;
  dim: Integer;
begin
  inherited;
  dim := DaysInAMonth(FDateSelector.Year, FDateSelector.Month);
  d := EncodeDate(FDateSelector.Year, FDateSelector.Month, dim);

  if ((MaxDate <> 0) and (Int(MaxDate) > Int(d))) or (MaxDate = 0) then
  begin
    if CompareDate(SelectedDate, d) <> 0 then
    begin
      SelectedDate := d;
      DoDateSelected;
    end;
  end
  else if (CompareDate(SelectedDate, MaxDate) <> 0) and (CompareDate(SelectedDate, d) <> 0) then
  begin
    SelectedDate := MaxDate;
    DoDateSelected;
  end;
end;

procedure TTMSFNCCustomDatePicker.SelectNextValue;
var
  d: TDate;
begin
  inherited;
  d := IncDay(FDateSelector.Date, 1);
  if Int(MaxDate + 1) <> Int(d) then
  begin
    SelectedDate := IncDay(FDateSelector.Date, 1);
    DoDateSelected;
  end;
end;

procedure TTMSFNCCustomDatePicker.SelectPreviousValue;
var
  d: TDate;
begin
  inherited;
  d := IncDay(FDateSelector.Date, -1);
  if Int(MinDate) - 1 <> Int(d) then
  begin
    SelectedDate := d;
    DoDateSelected;
  end;
end;

procedure TTMSFNCCustomDatePicker.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FWrapper) then
    FWrapper.AdaptToStyle := AdaptToStyle;
  if Assigned(FDateSelector) then
    FDateSelector.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomDatePicker.SetAllowNumericNullValue(
  const Value: Boolean);
begin
  if FAllowNumericNullValue <> Value then
  begin
    FAllowNumericNullValue := Value;
    if FAllowNumericNullValue and (Int(SelectedDate) = 0) then
      Content := ''
    else
      Content := DateToStr(SelectedDate);
  end;
  Invalidate;
end;

procedure TTMSFNCCustomDatePicker.SetBadgeSize(const Value: Integer);
begin
  FDateSelector.BadgeSize := Value;
end;

procedure TTMSFNCCustomDatePicker.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FDateSelector.BitmapContainer := Value;
end;

procedure TTMSFNCCustomDatePicker.SetCalendarFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDateSelector.Fill.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetCalendarStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDateSelector.Stroke.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetDateAppearance(
  const Value: TTMSFNCCalendarDateAppearance);
begin
  FDateSelector.DateAppearance.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetDay(const Value: Integer);
begin
  FDateSelector.Day := Value;
end;

procedure TTMSFNCCustomDatePicker.SetDayNameAppearance(
  const Value: TTMSFNCCalendarDayNameAppearance);
begin
  FDateSelector.DayNameAppearance.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetEditable(const Value: Boolean);
begin
  inherited;
  if FAllowNumericNullValue and (Int(FPrevSelDate) = 0) then
    Content := ''
  else
    Content := DateToStr(FPrevSelDate);

  Invalidate;
end;

procedure TTMSFNCCustomDatePicker.SetEvents(const Value: TTMSFNCCalendarEvents);
begin
  FDateSelector.Events.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetFirstDay(const Value: Integer);
begin
  FDateSelector.FirstDay := Value;
end;

procedure TTMSFNCCustomDatePicker.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomDatePicker.SetFooter(const Value: TTMSFNCCalendarFooter);
begin
  FDateSelector.Footer.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetHeader(const Value: TTMSFNCCalendarHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetInteraction(
  const Value: TTMSFNCDatePickerInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetLineAppearance(
  const Value: TTMSFNCCalendarLineAppearance);
begin
  FDateSelector.LineAppearance.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetMaxDate(const Value: TDate);
begin
  FDateSelector.MaxDate := Value;
  if CompareDate(SelectedDate, Value) = 1 then
    SelectedDate := Value;
end;

procedure TTMSFNCCustomDatePicker.SetMinDate(const Value: TDate);
begin
  FDateSelector.MinDate := Value;
  if CompareDate(SelectedDate, Value) = -1 then
    SelectedDate := Value;
end;

procedure TTMSFNCCustomDatePicker.SetMonth(const Value: Integer);
begin
  FDateSelector.Month := Value;
end;

procedure TTMSFNCCustomDatePicker.SetSelectedDate(const Value: TDate);
begin
  if FAllowNumericNullValue and (Int(Value) = 0) then
    Content := ''
  else
    Content := DateToStr(Value);

  FPrevSelDate := Value;
  Invalidate;
end;

procedure TTMSFNCCustomDatePicker.SetWeekNumberAppearance(
  const Value: TTMSFNCCalendarWeekNumberAppearance);
begin
  FDateSelector.WeekNumberAppearance.Assign(Value);
end;

procedure TTMSFNCCustomDatePicker.SetYear(const Value: Integer);
begin
  FDateSelector.Year := Value;
end;

procedure TTMSFNCCustomDatePicker.UpdateEditText(AText: string);
var
  d: TDateTime;
begin
  inherited;
  if AText = '' then
  begin
    if (0 = Int(FPrevSelDate)) then
    begin
      FDateSelector.SelectADate(0);
      DoDateSelected;
    end;
  end
  else if TryStrToDate(AText, d) then
  begin
    if (Int(d) <> Int(SelectedDate)) and (Int(d) >= Int(MinDate)) and (((Int(d) <= Int(MaxDate)) and (MaxDate <> 0)) or (MaxDate = 0)) then
    begin
      FDateSelector.SelectADate(Int(d));
      DoDateSelected;
    end;
  end;
end;

{ TTMSFNCDatePicker }

procedure TTMSFNCDatePicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCDatePicker);
end;

end.
