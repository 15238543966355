unit unit_AuftragUnterschriften;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.StdCtrls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCSignatureCapture, WEBLib.ExtCtrls, DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TAuftragUnterschriften = class(TForm)
    panelUnterschreiben: TPanel;
    WebLabel2: TLabel;
    SigMonteur: TTMSFNCSignatureCapture;
    SigKunde: TTMSFNCSignatureCapture;
    btnUAbbrechen: TButton;
    btnUSpeichern: TButton;
    dsUnterschriften: TXDataWebDataSet;
    dsUnterschriftenNummerkomplett: TStringField;
    dsUnterschriftenDatum: TDateTimeField;
    dsUnterschriftenUnterschriftMonteur: TBlobField;
    dsUnterschriftenUnterschriftKunde: TBlobField;
    dsUnterschriftenUnterschriftMonteurText: TStringField;
    dsUnterschriftenUnterschriftKundeText: TStringField;
    sourceUnterschriften: TDataSource;
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel3: TLabel;
    WebEdit2: TEdit;
    dsUnterschriftenNachnameKunde: TStringField;
    dsUnterschriftenNachnameMonteur: TStringField;
    procedure btnUSpeichernClick(Sender: TObject);
    procedure btnUAbbrechenClick(Sender: TObject);
    procedure dsUnterschriftenAfterPost(DataSet: TDataSet);
    procedure dsUnterschriftenAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    UMerkerAuftrag:string;
    procedure TouchMove1(Sender: TObject; X,Y: integer);
    procedure TouchMove2(Sender: TObject; X,Y: integer);
  end;

var
  AuftragUnterschriften: TAuftragUnterschriften;

implementation
  uses unit1, unit2, unit_Einzelauftrag;

procedure TAuftragUnterschriften.TouchMove1(Sender: TObject; X, Y: integer);
begin
  SigMonteur.PreventDefault;
end;
procedure TAuftragUnterschriften.TouchMove2(Sender: TObject; X, Y: integer);
begin
  SigKunde.PreventDefault;
end;


procedure TAuftragUnterschriften.btnUAbbrechenClick(Sender: TObject);
begin
  self.Close;
end;

procedure TAuftragUnterschriften.btnUSpeichernClick(Sender: TObject);
begin
  dsunterschriften.Append;
  dsUnterschriftenNummerkomplett.AsString:=UMerkerAuftrag;
  dsUnterschriftenDatum.AsDateTime:=date+time;
//WebImageControl1.URL := TMSFNCSignatureCapture1.GetBase64Img;
//WebDBImageControl1.Base64Image := TMSFNCSignatureCapture1.GetBase64Img;
  dsUnterschriftenUnterschriftMonteurText.asstring:=sigmonteur.getbase64img;
  dsUnterschriftenUnterschriftKundeText.AsString:=sigkunde.getbase64img;
  dsUnterschriftenNachnameMonteur.AsString:=WebEdit1.Text;
  dsUnterschriftenNachnameKunde.AsString:=WebEdit2.Text;
  dsUnterschriften.Post;

end;

procedure TAuftragUnterschriften.dsUnterschriftenAfterApplyUpdates(
  Sender: TDataSet; Info: TResolveResults);
begin
  self.Close;
end;

procedure TAuftragUnterschriften.dsUnterschriftenAfterPost(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    dsUnterschriften.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TAuftragUnterschriften.WebFormCreate(Sender: TObject);
begin
  SigMonteur.OnTouchMove := @TouchMove1;
  SigKunde.OnTouchMove := @TouchMove2;
end;

procedure TAuftragUnterschriften.WebFormShow(Sender: TObject);
begin

  dsUnterschriften.Close;
  dsUnterschriften.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(UMerkerAuftrag);
  dsUnterschriften.Load;
end;

procedure TAuftragUnterschriften.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panelUnterschreiben := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  SigMonteur := TTMSFNCSignatureCapture.Create(Self);
  SigKunde := TTMSFNCSignatureCapture.Create(Self);
  btnUAbbrechen := TButton.Create(Self);
  btnUSpeichern := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  dsUnterschriften := TXDataWebDataSet.Create(Self);
  dsUnterschriftenNummerkomplett := TStringField.Create(Self);
  dsUnterschriftenDatum := TDateTimeField.Create(Self);
  dsUnterschriftenUnterschriftMonteur := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftKunde := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftMonteurText := TStringField.Create(Self);
  dsUnterschriftenUnterschriftKundeText := TStringField.Create(Self);
  dsUnterschriftenNachnameKunde := TStringField.Create(Self);
  dsUnterschriftenNachnameMonteur := TStringField.Create(Self);
  sourceUnterschriften := TDataSource.Create(Self);

  panelUnterschreiben.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  SigMonteur.BeforeLoadDFMValues;
  SigKunde.BeforeLoadDFMValues;
  btnUAbbrechen.BeforeLoadDFMValues;
  btnUSpeichern.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  dsUnterschriften.BeforeLoadDFMValues;
  dsUnterschriftenNummerkomplett.BeforeLoadDFMValues;
  dsUnterschriftenDatum.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftMonteur.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKunde.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftMonteurText.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKundeText.BeforeLoadDFMValues;
  dsUnterschriftenNachnameKunde.BeforeLoadDFMValues;
  dsUnterschriftenNachnameMonteur.BeforeLoadDFMValues;
  sourceUnterschriften.BeforeLoadDFMValues;
  try
    Name := 'AuftragUnterschriften';
    Width := 640;
    Height := 382;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panelUnterschreiben.SetParentComponent(Self);
    panelUnterschreiben.Name := 'panelUnterschreiben';
    panelUnterschreiben.Left := 0;
    panelUnterschreiben.Top := 0;
    panelUnterschreiben.Width := 640;
    panelUnterschreiben.Height := 382;
    panelUnterschreiben.ElementClassName := 'card m-1 border-info';
    panelUnterschreiben.Align := alClient;
    panelUnterschreiben.BorderColor := clSilver;
    panelUnterschreiben.BorderStyle := bsSingle;
    panelUnterschreiben.Caption := 'panelUnterschreiben';
    panelUnterschreiben.Color := 12557152;
    panelUnterschreiben.ElementFont := efCSS;
    panelUnterschreiben.ShowCaption := False;
    WebLabel2.SetParentComponent(panelUnterschreiben);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 8;
    WebLabel2.Width := 69;
    WebLabel2.Height := 13;
    WebLabel2.Caption := 'Unterschriften';
    WebLabel2.ElementClassName := 'h4';
    WebLabel1.SetParentComponent(panelUnterschreiben);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 18;
    WebLabel1.Top := 301;
    WebLabel1.Width := 105;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'Name ausgeschrieben';
    WebLabel1.ElementClassName := 'h6';
    WebLabel3.SetParentComponent(panelUnterschreiben);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 330;
    WebLabel3.Top := 301;
    WebLabel3.Width := 105;
    WebLabel3.Height := 13;
    WebLabel3.Caption := 'Name ausgeschrieben';
    WebLabel3.ElementClassName := 'h6';
    SigMonteur.SetParentComponent(panelUnterschreiben);
    SigMonteur.Name := 'SigMonteur';
    SigMonteur.Left := 18;
    SigMonteur.Top := 101;
    SigMonteur.Width := 295;
    SigMonteur.Height := 186;
    SigMonteur.ParentDoubleBuffered := False;
    SigMonteur.DoubleBuffered := True;
    SigMonteur.Font.Charset := DEFAULT_CHARSET;
    SigMonteur.Font.Color := clWindowText;
    SigMonteur.Font.Height := -11;
    SigMonteur.Font.Name := 'Tahoma';
    SigMonteur.Font.Style := [];
    SigMonteur.TabOrder := 0;
    SigMonteur.Pen.Color := clBlack;
    SigMonteur.Text := 'Unterschrift Monteur/Techniker';
    SigMonteur.TextPosition.Line.Color := clGray;
    SigMonteur.ClearSig.Image.LoadFromFile('unit_AuftragUnterschriften.panelUnterschreiben.SigMonteur.ClearSig.Image.png');
    SigKunde.SetParentComponent(panelUnterschreiben);
    SigKunde.Name := 'SigKunde';
    SigKunde.Left := 330;
    SigKunde.Top := 101;
    SigKunde.Width := 295;
    SigKunde.Height := 186;
    SigKunde.ParentDoubleBuffered := False;
    SigKunde.DoubleBuffered := True;
    SigKunde.Font.Charset := DEFAULT_CHARSET;
    SigKunde.Font.Color := clWindowText;
    SigKunde.Font.Height := -11;
    SigKunde.Font.Name := 'Tahoma';
    SigKunde.Font.Style := [];
    SigKunde.TabOrder := 1;
    SigKunde.Pen.Color := clBlack;
    SigKunde.Text := 'Unterschrift Kunde';
    SigKunde.TextPosition.Line.Color := clGray;
    SigKunde.ClearSig.Image.LoadFromFile('unit_AuftragUnterschriften.panelUnterschreiben.SigKunde.ClearSig.Image.png');
    btnUAbbrechen.SetParentComponent(panelUnterschreiben);
    btnUAbbrechen.Name := 'btnUAbbrechen';
    btnUAbbrechen.Left := 336;
    btnUAbbrechen.Top := 6;
    btnUAbbrechen.Width := 104;
    btnUAbbrechen.Height := 25;
    btnUAbbrechen.Caption := 'Abbrechen';
    btnUAbbrechen.ChildOrder := 3;
    btnUAbbrechen.ElementClassName := 'btn btn-info btn-sm text-left';
    btnUAbbrechen.ElementFont := efCSS;
    btnUAbbrechen.HeightStyle := ssAuto;
    btnUAbbrechen.WidthStyle := ssAuto;
    SetEvent(btnUAbbrechen, Self, 'OnClick', 'btnUAbbrechenClick');
    btnUSpeichern.SetParentComponent(panelUnterschreiben);
    btnUSpeichern.Name := 'btnUSpeichern';
    btnUSpeichern.Left := 464;
    btnUSpeichern.Top := 5;
    btnUSpeichern.Width := 105;
    btnUSpeichern.Height := 25;
    btnUSpeichern.Caption := 'speichern';
    btnUSpeichern.ChildOrder := 4;
    btnUSpeichern.ElementClassName := 'btn btn-info btn-sm text-left';
    btnUSpeichern.ElementFont := efCSS;
    btnUSpeichern.HeightStyle := ssAuto;
    btnUSpeichern.WidthStyle := ssAuto;
    SetEvent(btnUSpeichern, Self, 'OnClick', 'btnUSpeichernClick');
    WebEdit1.SetParentComponent(panelUnterschreiben);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 16;
    WebEdit1.Top := 322;
    WebEdit1.Width := 295;
    WebEdit1.Height := 21;
    WebEdit1.AutoSize := True;
    WebEdit1.ChildOrder := 4;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.HeightStyle := ssAuto;
    WebEdit1.TextHint := 'Name Monteur/Techniker';
    WebEdit2.SetParentComponent(panelUnterschreiben);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 330;
    WebEdit2.Top := 322;
    WebEdit2.Width := 295;
    WebEdit2.Height := 21;
    WebEdit2.AutoSize := True;
    WebEdit2.ChildOrder := 4;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.HeightStyle := ssAuto;
    WebEdit2.TextHint := 'Name des Kunden';
    dsUnterschriften.SetParentComponent(Self);
    dsUnterschriften.Name := 'dsUnterschriften';
    dsUnterschriften.AfterApplyUpdates := dsUnterschriftenAfterApplyUpdates;
    dsUnterschriften.AfterPost := dsUnterschriftenAfterPost;
    dsUnterschriften.EntitySetName := 'AuftragUnterschriften';
    dsUnterschriften.Connection := DM2.XDataWebConnection1;
    dsUnterschriften.Left := 564;
    dsUnterschriften.Top := 240;
    dsUnterschriftenNummerkomplett.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNummerkomplett.Name := 'dsUnterschriftenNummerkomplett';
    dsUnterschriftenNummerkomplett.FieldName := 'Nummerkomplett';
    dsUnterschriftenNummerkomplett.Required := True;
    dsUnterschriftenNummerkomplett.Size := 10;
    dsUnterschriftenDatum.SetParentComponent(dsUnterschriften);
    dsUnterschriftenDatum.Name := 'dsUnterschriftenDatum';
    dsUnterschriftenDatum.FieldName := 'Datum';
    dsUnterschriftenUnterschriftMonteur.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftMonteur.Name := 'dsUnterschriftenUnterschriftMonteur';
    dsUnterschriftenUnterschriftMonteur.FieldName := 'UnterschriftMonteur';
    dsUnterschriftenUnterschriftKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKunde.Name := 'dsUnterschriftenUnterschriftKunde';
    dsUnterschriftenUnterschriftKunde.FieldName := 'UnterschriftKunde';
    dsUnterschriftenUnterschriftMonteurText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftMonteurText.Name := 'dsUnterschriftenUnterschriftMonteurText';
    dsUnterschriftenUnterschriftMonteurText.FieldName := 'UnterschriftMonteurText';
    dsUnterschriftenUnterschriftMonteurText.Size := 255;
    dsUnterschriftenUnterschriftKundeText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKundeText.Name := 'dsUnterschriftenUnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.FieldName := 'UnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.Size := 255;
    dsUnterschriftenNachnameKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNachnameKunde.Name := 'dsUnterschriftenNachnameKunde';
    dsUnterschriftenNachnameKunde.FieldName := 'NachnameKunde';
    dsUnterschriftenNachnameKunde.Size := 50;
    dsUnterschriftenNachnameMonteur.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNachnameMonteur.Name := 'dsUnterschriftenNachnameMonteur';
    dsUnterschriftenNachnameMonteur.FieldName := 'NachnameMonteur';
    dsUnterschriftenNachnameMonteur.Size := 50;
    sourceUnterschriften.SetParentComponent(Self);
    sourceUnterschriften.Name := 'sourceUnterschriften';
    sourceUnterschriften.DataSet := dsUnterschriften;
    sourceUnterschriften.Left := 464;
    sourceUnterschriften.Top := 244;
  finally
    panelUnterschreiben.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    SigMonteur.AfterLoadDFMValues;
    SigKunde.AfterLoadDFMValues;
    btnUAbbrechen.AfterLoadDFMValues;
    btnUSpeichern.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    dsUnterschriften.AfterLoadDFMValues;
    dsUnterschriftenNummerkomplett.AfterLoadDFMValues;
    dsUnterschriftenDatum.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftMonteur.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKunde.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftMonteurText.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKundeText.AfterLoadDFMValues;
    dsUnterschriftenNachnameKunde.AfterLoadDFMValues;
    dsUnterschriftenNachnameMonteur.AfterLoadDFMValues;
    sourceUnterschriften.AfterLoadDFMValues;
  end;
end;

end.
