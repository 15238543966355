unit unit_EinzelBeleg;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Grids, strutils, XData.Web.Client;

type
  TEinzelBeleg = class(TForm)
    WebPanel3: TPanel;
    WebLabel10: TLabel;
    lblVersion: TLabel;
    WebPanel2: TPanel;
    WebBitBtn2: TBitBtn;
    xdsBeleg: TXDataWebDataSet;
    TC: TTableControl;
    PTC: TTableControl;
    xdsPositionen: TXDataWebDataSet;
    btnPDF: TBitBtn;
    WClient: TXDataWebClient;
    procedure WebBitBtn2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure xdsBelegAfterOpen(DataSet: TDataSet);
    procedure xdsPositionenAfterOpen(DataSet: TDataSet);
    procedure btnPDFClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Belegnummer:string;
    Belegart:string;

  end;

var
  EinzelBeleg: TEinzelBeleg;
  Einkauf:boolean;

implementation
  uses unit1, unit2, unit_BelegeUndPositionen;

procedure TEinzelBeleg.btnPDFClick(Sender: TObject);
  procedure OnResponse(Response:TXDataClientResponse);
  var
    mydownload:string;
  begin
    mydownload:=string(TJSObject(response.Result)['value']);
    if mydownload='WRONG' then
    begin
      showmessage('Download kann nicht bereitgestellt werden');
    end
    else
    begin
      application.Navigate(downloadconnection+mydownload,ntblank);
    end;
  end;
begin
  if LizenzOK then
  begin
    WClient.RawInvoke('IServerService.BelegPDF',[Belegnummer],@OnResponse);
  end
  else
  begin
    showmessage('Für das Abrufen von Dateien sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;

end;

procedure TEinzelBeleg.WebBitBtn2Click(Sender: TObject);
begin
  self.Close;
end;

procedure TEinzelBeleg.WebFormShow(Sender: TObject);
begin
  lblversion.Caption:=versionslabel;
  if Belegart='Angebote' then xdsbeleg.EntitySetName:='Angebote';
  if Belegart='Aufträge' then xdsbeleg.EntitySetName:='Auftrag';
  if Belegart='Lieferscheine' then xdsbeleg.EntitySetName:='Lieferschein';
  if Belegart='Rechnungen' then xdsbeleg.EntitySetName:='Rechnung';
  if Belegart='Anfragen' then xdsbeleg.EntitySetName:='Anfrage';
  if Belegart='Bestellungen' then xdsbeleg.EntitySetName:='Bestellung';
  if Belegart='Wareneingänge' then xdsbeleg.EntitySetName:='Wareneingang';
  if Belegart='EingangsRechnungen' then xdsbeleg.EntitySetName:='EingangsRE';

  if Belegart='Angebote' then Einkauf:=false;
  if Belegart='Aufträge' then Einkauf:=false;
  if Belegart='Lieferscheine' then Einkauf:=false;
  if Belegart='Rechnungen' then Einkauf:=false;
  if Belegart='Anfragen' then Einkauf:=true;
  if Belegart='Bestellungen' then Einkauf:=true;
  if Belegart='Wareneingänge' then Einkauf:=true;
  if Belegart='EingangsRechnungen' then Einkauf:=true;

  xdsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(Belegnummer);
  xdsBeleg.Load;
end;

procedure TEinzelBeleg.xdsBelegAfterOpen(DataSet: TDataSet);
begin
  TC.RowCount:=8;
  TC.Cells[0,0]:='Bezeichnung';
  TC.Cells[1,0]:='Wert';
  if Belegart='Angebote' then TC.Cells[0,1]:='Angebot | Datum';
  if Belegart='Aufträge' then TC.Cells[0,1]:='Auftrrag | Datum';
  if Belegart='Lieferscheine' then TC.Cells[0,1]:='Lieferschein | Datum';
  if Belegart='Rechnungen' then TC.Cells[0,1]:='Rechnung | Datum';
  if Belegart='Anfragen' then TC.Cells[0,1]:='Anfrage | Datum';
  if Belegart='Bestellungen' then TC.Cells[0,1]:='Bestellung | Datum';
  if Belegart='Wareneingänge' then TC.Cells[0,1]:='Wareneingang | Datum';
  if Belegart='EingangsRechnungen' then TC.Cells[0,1]:='Eingangsrechnung | Datum';
  TC.Cells[1,1]:='<h5>'+xdsbeleg.FieldByName('Nummerkomplett').asstring +' | '+xdsbeleg.FieldByName('Datum').asstring;

  TC.Cells[0,2]:='Kontaktnummer';
  TC.Cells[1,2]:='<h6>'+xdsBeleg.FieldByName('Kundennummer.Kundennummer').asstring;

  TC.Cells[0,3]:='Anschrift';
  TC.Cells[1,3]:='<h6>'+ReplaceStr(xdsbeleg.FieldByName('Adressebild').asstring,#13+#10,'<BR>');

  TC.Cells[0,4]:='Auftragsbezeichnung';
  TC.Cells[1,4]:='<h6>'+xdsBeleg.FieldByName('Auftragsbezeichnung').asstring;

  TC.Cells[0,5]:='Objektnummer';
  TC.Cells[1,5]:='<h6>'+xdsBeleg.FieldByName('Objektnummer').asstring;

  TC.Cells[0,6]:='Lieferanschrift';
  try
  TC.Cells[1,6]:='<h6>'+ReplaceStr(xdsbeleg.FieldByName('Lieferanschriftbild').asstring,#13+#10,'<BR>');
  except
  end;
  TC.Cells[0,7]:='Nettosumme';
  TC.Cells[1,7]:=formatfloat('#,##0.00',xdsbeleg.fieldbyname('Nettosumme').asfloat);

  xdspositionen.Close;
  if Belegart='Angebote' then xdsPositionen.EntitySetName:='PositionenAngebote';
  if Belegart='Aufträge' then xdsPositionen.EntitySetName:='PositionenAuftrag';
  if Belegart='Lieferscheine' then xdsPositionen.EntitySetName:='PositionenLieferschein';
  if Belegart='Rechnungen' then xdsPositionen.EntitySetName:='PositionenRechnung';
  if Belegart='Anfragen' then xdsPositionen.EntitySetName:='PositionenAnfrage';
  if Belegart='Bestellungen' then xdsPositionen.EntitySetName:='PositionenBestellung';
  if Belegart='Wareneingänge' then xdsPositionen.EntitySetName:='PositionenWareneingang';
  if Belegart='EingangsRechnungen' then xdsPositionen.EntitySetName:='PositionenEingangsRE';
  xdsPositionen.QueryString:='$expand=Artikelnummer&$filter=Nummerkomplett eq '+QuotedStr(Belegnummer)+'&$orderby=PosNr';
  xdsPositionen.Load;
end;

procedure TEinzelBeleg.xdsPositionenAfterOpen(DataSet: TDataSet);
var
  I: Integer;
begin
  PTC.RowCount:=xdsPositionen.RecordCount+1;
  PTC.ColCount:=8;
  PTC.Cells[0,0]:='PosNr';
  PTC.Cells[1,0]:='PosUNr';
  PTC.Cells[2,0]:='Artikelnummer';
  PTC.Cells[3,0]:='Artikelüberschrift';
  PTC.Cells[4,0]:='Beschreibung';
  PTC.Cells[5,0]:='Menge';
  PTC.Cells[6,0]:='Einzel-VKP';
  if Einkauf then PTC.Cells[6,0]:='Einzel-EKP';
  PTC.Cells[7,0]:='Endpreis';

  xdspositionen.First;
  for I := 1 to xdspositionen.RecordCount do
  begin
    PTC.Cells[0,i]:=xdspositionen.FieldByName('PosNr').AsString;
    PTC.Cells[1,i]:=xdspositionen.FieldByName('PosUNr').AsString;
    PTC.Cells[2,i]:=xdspositionen.FieldByName('Artikelnummer.Artikelnummer').AsString;
    PTC.Cells[3,i]:=xdspositionen.FieldByName('Artikelueberschrift').AsString;
    PTC.Cells[4,i]:=xdspositionen.FieldByName('Artikelbeschreibung').AsString;
    PTC.Cells[5,i]:='<p class="text-right">'+xdspositionen.FieldByName('Menge').AsString;
    if not einkauf then PTC.Cells[6,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Verkaufspreis').asfloat);
    if einkauf then PTC.Cells[6,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Einkaufspreis').asfloat);
    if not einkauf then PTC.Cells[7,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Endpreis').asfloat);
    if einkauf then PTC.Cells[7,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Endpreis').asfloat);



    xdspositionen.Next;
  end;
end;

procedure TEinzelBeleg.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel3 := TPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  lblVersion := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebBitBtn2 := TBitBtn.Create(Self);
  btnPDF := TBitBtn.Create(Self);
  TC := TTableControl.Create(Self);
  PTC := TTableControl.Create(Self);
  xdsBeleg := TXDataWebDataSet.Create(Self);
  xdsPositionen := TXDataWebDataSet.Create(Self);
  WClient := TXDataWebClient.Create(Self);

  WebPanel3.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  lblVersion.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebBitBtn2.BeforeLoadDFMValues;
  btnPDF.BeforeLoadDFMValues;
  TC.BeforeLoadDFMValues;
  PTC.BeforeLoadDFMValues;
  xdsBeleg.BeforeLoadDFMValues;
  xdsPositionen.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  try
    Name := 'EinzelBeleg';
    Left := 0;
    Top := 0;
    Width := 364;
    Height := 480;
    Caption := 'EinzelBeleg';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 0;
    WebPanel3.Width := 364;
    WebPanel3.Height := 50;
    WebPanel3.Align := alTop;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.Caption := 'Serviceauftrag';
    WebPanel3.Color := 8340248;
    WebPanel3.ShowCaption := False;
    WebLabel10.SetParentComponent(WebPanel3);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.AlignWithMargins := True;
    WebLabel10.Left := 1;
    WebLabel10.Top := 1;
    WebLabel10.Width := 288;
    WebLabel10.Height := 25;
    WebLabel10.AutoSize := False;
    WebLabel10.Caption := 'CadiaWEB - EinzelBeleg';
    WebLabel10.ElementClassName := 'h4 text-light';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -19;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.ParentFont := False;
    lblVersion.SetParentComponent(WebPanel3);
    lblVersion.Name := 'lblVersion';
    lblVersion.Left := 1;
    lblVersion.Top := 28;
    lblVersion.Width := 511;
    lblVersion.Height := 13;
    lblVersion.Caption := #169'2019 Langwald-EDV-Beratung <a href="https://www.cadia.de" target="_blank">(cadia-Webseite)</a>';
    lblVersion.ElementClassName := 'p text-light';
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 50;
    WebPanel2.Width := 364;
    WebPanel2.Height := 39;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.Caption := 'WebPanel2';
    WebPanel2.ChildOrder := 1;
    WebPanel2.Color := 12557152;
    WebPanel2.ShowCaption := False;
    WebBitBtn2.SetParentComponent(WebPanel2);
    WebBitBtn2.Name := 'WebBitBtn2';
    WebBitBtn2.Left := 3;
    WebBitBtn2.Top := 4;
    WebBitBtn2.Width := 30;
    WebBitBtn2.Height := 25;
    WebBitBtn2.Color := clBtnFace;
    WebBitBtn2.ElementClassName := 'btn btn-light text-left';
    WebBitBtn2.MaterialGlyph := 'first_page';
    WebBitBtn2.TabOrder := 0;
    SetEvent(WebBitBtn2, Self, 'OnClick', 'WebBitBtn2Click');
    btnPDF.SetParentComponent(WebPanel2);
    btnPDF.Name := 'btnPDF';
    btnPDF.Left := 39;
    btnPDF.Top := 4;
    btnPDF.Width := 82;
    btnPDF.Height := 25;
    btnPDF.Caption := 'PDF';
    btnPDF.Color := clBtnFace;
    btnPDF.ElementClassName := 'btn btn-light text-left';
    btnPDF.MaterialGlyph := 'open_in_browser';
    btnPDF.TabOrder := 1;
    SetEvent(btnPDF, Self, 'OnClick', 'btnPDFClick');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 89;
    TC.Width := 364;
    TC.Height := 143;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 2;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    PTC.SetParentComponent(Self);
    PTC.Name := 'PTC';
    PTC.Left := 0;
    PTC.Top := 232;
    PTC.Width := 364;
    PTC.Height := 248;
    PTC.ElementClassName := 'table table-hover table-bordered table-striped';
    PTC.Align := alBottom;
    PTC.BorderColor := clSilver;
    PTC.ChildOrder := 2;
    PTC.ColHeader := False;
    PTC.ElementHeaderClassName := 'table-info';
    PTC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    PTC.Options.ScrollVertical := True;
    PTC.ColCount := 2;
    xdsBeleg.SetParentComponent(Self);
    xdsBeleg.Name := 'xdsBeleg';
    xdsBeleg.AfterOpen := xdsBelegAfterOpen;
    xdsBeleg.EntitySetName := 'Angebote';
    xdsBeleg.Connection := DM2.XDataWebConnection1;
    xdsBeleg.SubpropsDepth := 1;
    xdsBeleg.QueryString := '$expand=Kundennummer';
    xdsBeleg.Left := 476;
    xdsBeleg.Top := 72;
    xdsPositionen.SetParentComponent(Self);
    xdsPositionen.Name := 'xdsPositionen';
    xdsPositionen.AfterOpen := xdsPositionenAfterOpen;
    xdsPositionen.EntitySetName := 'PositionenAnfrage';
    xdsPositionen.Connection := DM2.XDataWebConnection1;
    xdsPositionen.SubpropsDepth := 1;
    xdsPositionen.QueryString := '$expand=Artikelnummer';
    xdsPositionen.Left := 476;
    xdsPositionen.Top := 128;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 256;
    WClient.Top := 58;
  finally
    WebPanel3.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    lblVersion.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebBitBtn2.AfterLoadDFMValues;
    btnPDF.AfterLoadDFMValues;
    TC.AfterLoadDFMValues;
    PTC.AfterLoadDFMValues;
    xdsBeleg.AfterLoadDFMValues;
    xdsPositionen.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
  end;
end;

end.