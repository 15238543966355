{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2018 - 2020                                 }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCAnalogTimePicker;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics,
  WEBLib.Controls, WEBLib.TMSFNCAnalogTimeSelector, WEBLib.TMSFNCTypes, WEBLib.StdCtrls,
  SysUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLLIB}
  ,Types
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 3; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : Property AllowNumericNullValue added
  //         : New : Method Clear added
  //         : Fixed : Issue with OnTimeSelected event
  //v1.0.1.1 : Fixed : Issue with setting the SelectedTime to 00:00:00
  //v1.0.1.2 : Fixed : Issue with control color in disabled state
  //v1.0.1.3 : Improved : Exposed Font property

type
  TTMSFNCTimePickerMode = (tpmAnalog, tpmDigital);

  TTMSFNCCustomAnalogTimePickerTimeSelectedEvent = procedure(Sender: TObject; ATime: TTime) of object;

  TTMSFNCCustomAnalogTimePicker = class(TTMSFNCDefaultPicker)
  private
    FPrevSelTime: TTime;
    FWrapper: TTMSFNCCustomSelector;
    FTimeSelector: TTMSFNCAnalogTimeSelector;
    FCloseOnSelection: Boolean;
    FOnTimeSelected: TTMSFNCCustomAnalogTimePickerTimeSelectedEvent;
    FMode: TTMSFNCTimePickerMode;
    FTimeFormat: string;
    FAllowNumericNullValue: Boolean;
    FFont: TTMSFNCGraphicsFont;
    procedure SetSelectedTime(const Value: TTime);
    procedure SetAnalogTimeSelectorAppearance(const Value: TTMSFNCAnalogTimeSelectorAppearance);
    procedure SetMode(const Value: TTMSFNCTimePickerMode);
    procedure SetTimeFormat(const Value: string);
    procedure SetAllowNumericNullValue(const Value: Boolean);
    function GetSelectedTime: TTime;
    function GetAnalogTimeSelectorAppearance: TTMSFNCAnalogTimeSelectorAppearance;
    function IsTimeFormatStored: Boolean;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
  protected
    procedure FontChange(Sender: TObject);
    procedure SetEditable(const Value: Boolean); override;
    procedure UpdateEditText(AText: string); override;
    procedure Loaded; override;
    procedure SetGraphicColors; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure InitializePopup; override;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    procedure DoSelectTime; virtual;
    procedure TimeSelected(Sender: TObject; ATime: TTime);
    function GetVersion: string; override;
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetFocusedControl: TControl; override;
    property AllowNumericNullValue: Boolean read FAllowNumericNullValue write SetAllowNumericNullValue default True;
    property TimeFormat: string read FTimeFormat write SetTimeFormat stored IsTimeFormatStored nodefault;
    property Mode: TTMSFNCTimePickerMode read FMode write SetMode default tpmAnalog;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property CloseOnSelection: Boolean read FCloseOnSelection write FCloseOnSelection default False;
    property SelectedTime: TTime read GetSelectedTime write SetSelectedTime;
    property OnTimeSelected: TTMSFNCCustomAnalogTimePickerTimeSelectedEvent read FOnTimeSelected write FOnTimeSelected;
    property SelectorAppearance: TTMSFNCAnalogTimeSelectorAppearance read GetAnalogTimeSelectorAppearance write SetAnalogTimeSelectorAppearance;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCAnalogTimePicker = class(TTMSFNCCustomAnalogTimePicker)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AllowNumericNullValue;
    property Editable;
    property DropDownWidth;
    property DropDownHeight;
    property Font;
    property CloseOnSelection;
    property SelectorAppearance;
    property SelectedTime;
    property TimeFormat;
    property Mode;
    property OnTimeSelected;
  end;

implementation

uses
  DateUtils
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  ;

type
  TCustomEditProtected = class(TCustomEdit);

{ TTMSFNCCustomTimePicker }

procedure TTMSFNCCustomAnalogTimePicker.Clear;
begin
  SelectedTime := 0.0;
end;

constructor TTMSFNCCustomAnalogTimePicker.Create(AOwner: TComponent);
begin
  inherited;
  FTimeFormat := 'hh:nn:ss';
  FCloseOnSelection := False;
  FAllowNumericNullValue := True;
  Width := 90;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChange;
  DropDownHeight := 180;
  DropDownWidth := 180;
  {$IFDEF FMXLIB}
  Edit.StyledSettings := TCustomEditProtected(Edit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
  {$IFDEF ANDROID}
  Edit.TextSettings.VertAlign := TTextAlign.Leading;
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCCustomAnalogTimePicker.CreateSelector: TTMSFNCCustomSelector;
begin
  FWrapper := TTMSFNCCustomSelector.Create(Self);
  FTimeSelector := TTMSFNCAnalogTimeSelector.Create(FWrapper);
  FTimeSelector.Parent := FWrapper;

  FTimeSelector.OnTimeChanged := TimeSelected;

  Result := FWrapper;
end;

destructor TTMSFNCCustomAnalogTimePicker.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCCustomAnalogTimePicker.DoSelectTime;
begin
  if Assigned(OnTimeSelected) then
    OnTimeSelected(Self, SelectedTime);

  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
begin
  inherited;
  if not Editable then
  begin
    AGraphics.Font.AssignSource(Font);

    if not Enabled then
      AGraphics.Font.Color := gcSilver;

    r := ARect;
    InflateRectEx(r, -3, -3);
    AGraphics.DrawText(r, Content);
  end;
end;

procedure TTMSFNCCustomAnalogTimePicker.FontChange(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCCustomAnalogTimePicker.GetAnalogTimeSelectorAppearance: TTMSFNCAnalogTimeSelectorAppearance;
begin
  Result := FTimeSelector.Appearance;
end;

function TTMSFNCCustomAnalogTimePicker.GetFocusedControl: TControl;
begin
  Result := FTimeSelector;
end;

function TTMSFNCCustomAnalogTimePicker.GetSelectedTime: TTime;
begin
  Result := FTimeSelector.Time
end;

function TTMSFNCCustomAnalogTimePicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomAnalogTimePicker.InitializePopup;
var
  t: TDateTime;
begin
  inherited;
  if Editable and Assigned(Edit.Parent) and Edit.Visible then
  begin
    t := SelectedTime;
    if TryStrToTime(Edit.Text, t) then
      SelectedTime := t;
  end;

  FTimeSelector.Initialize;
  FTimeSelector.Width := Round(DropDownWidth);
  FTimeSelector.Height := Round(DropDownHeight);
  FTimeSelector.Fill.Assign(SelectorAppearance.Fill);
end;

function TTMSFNCCustomAnalogTimePicker.IsTimeFormatStored: Boolean;
begin
  Result := FTimeFormat <> 'hh:nn:ss';
end;

procedure TTMSFNCCustomAnalogTimePicker.Loaded;
begin
  inherited;
  FTimeSelector.Width := Round(DropDownWidth);
  FTimeSelector.Height := Round(DropDownHeight);
end;

procedure TTMSFNCCustomAnalogTimePicker.SelectFirstValue;
var
  h, m, s, ms: Word;
begin
  inherited;
  DecodeTime(SelectedTime, h, m, s, ms);
  FTimeSelector.Time := EncodeTime(h, 0, 0, 0);
  DoSelectTime;
  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SelectLastValue;
var
  h, m, s, ms: Word;
begin
  inherited;
  DecodeTime(SelectedTime, h, m, s, ms);
  FTimeSelector.Time := EncodeTime(h, 59, 0, 0);
  DoSelectTime;
  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SelectNextValue;
begin
  inherited;
  FTimeSelector.Time := IncMinute(SelectedTime, 1);
  DoSelectTime;
  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SelectPreviousValue;
begin
  inherited;
  FTimeSelector.Time := IncMinute(SelectedTime, -1);
  DoSelectTime;
  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FWrapper) then
    FWrapper.AdaptToStyle := AdaptToStyle;
  if Assigned(FTimeSelector) then
    FTimeSelector.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetAllowNumericNullValue(
  const Value: Boolean);
begin
  if FAllowNumericNullValue <> Value then
  begin
    FAllowNumericNullValue := Value;
    if FAllowNumericNullValue and (Frac(SelectedTime) = 0.0) then
      Content := ''
    else
      Content := FormatDateTime(TimeFormat, SelectedTime);

    Invalidate;
  end;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetAnalogTimeSelectorAppearance(
  const Value: TTMSFNCAnalogTimeSelectorAppearance);
begin
  FTimeSelector.Appearance.Assign(Value);
end;

procedure TTMSFNCCustomAnalogTimePicker.SetEditable(const Value: Boolean);
begin
  inherited;
  if FAllowNumericNullValue and (Frac(FPrevSelTime) = 0.0) then
    Content := ''
  else
    Content := FormatDateTime(TimeFormat, FPrevSelTime);

  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomAnalogTimePicker.SetGraphicColors;
begin
  inherited;
  TTMSFNCGraphics.DefaultPopupFillColor := SelectorAppearance.Fill.Color;
  TTMSFNCGraphics.DefaultPopupStrokeColor := SelectorAppearance.Stroke.Color;
  TTMSFNCGraphics.DefaultButtonFillColor := SelectorAppearance.Fill.Color;
  TTMSFNCGraphics.DefaultButtonStrokeColor := SelectorAppearance.Stroke.Color;
  TTMSFNCGraphics.DefaultButtonFillColorFocused := Lighter(SelectorAppearance.Fill.Color, 20);
  TTMSFNCGraphics.DefaultButtonStrokeColorFocused := Lighter(SelectorAppearance.Stroke.Color, 20);
  TTMSFNCGraphics.DefaultButtonStrokeColorDisabled := gcDarkGray;
  TTMSFNCGraphics.DefaultButtonFillColorDisabled := gcLightgray;
  TTMSFNCGraphics.DefaultTextFontColor := Font.Color;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetMode(const Value: TTMSFNCTimePickerMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
  end;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetSelectedTime(const Value: TTime);
begin
  if FAllowNumericNullValue and (Frac(Value) = 0.0) then
    Content := ''
  else
    Content := FormatDateTime(TimeFormat, Value);

  FPrevSelTime := Value;
  Invalidate;
end;

procedure TTMSFNCCustomAnalogTimePicker.SetTimeFormat(const Value: string);
begin
  if FTimeFormat <> Value then
  begin
    FTimeFormat := Value;
    Content := FormatDateTime(TimeFormat, SelectedTime);
    Invalidate;
  end;
end;

procedure TTMSFNCCustomAnalogTimePicker.TimeSelected(Sender: TObject; ATime: TTime);
begin
  if CloseOnSelection then
    DropDown;

  SelectedTime := FTimeSelector.Time;

  DoSelectTime;
end;

procedure TTMSFNCCustomAnalogTimePicker.UpdateEditText(AText: string);
var
  t: TDateTime;
begin
  inherited;
  if AText = '' then
  begin
    if (0.0 <> FPrevSelTime) then
    begin
      FTimeSelector.Time := 0.0;
      DoSelectTime;
    end;
  end
  else if TryStrToTime(AText, t) then
  begin
    if (Frac(t) <> SelectedTime) then
    begin
      FTimeSelector.Time := Frac(t);
      DoSelectTime;
    end;
  end;
end;

{ TTMSFNCTimePicker }

procedure TTMSFNCAnalogTimePicker.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCAnalogTimePicker);
end;

end.
