unit unit_Karte;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TKarte = class(TForm)
    WebGoogleMaps1: TGoogleMaps;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    btnzurueck: TButton;
    WebLabel2: TLabel;
    edtFromAddress: TEdit;
    btnroute: TButton;
    WebLabel1: TLabel;
    edtToAddress: TEdit;
    btnAnzeigen: TButton;
    Container3: THTMLContainer;
    container4: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure btnAnzeigenClick(Sender: TObject);
    procedure WebGoogleMaps1GeoCoded(Sender: TObject; Lon, Lat: Double);
    procedure btnrouteClick(Sender: TObject);
    procedure btnzurueckClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    mykundehint:string;
  end;

var
  Karte: TKarte;
  mylongitude:double;
  mylatitude:double;

implementation
  uses unit1, unit2;

procedure TKarte.btnrouteClick(Sender: TObject);
begin
  if (edtFromAddress.Text <> '') and (edtToAddress.Text <> '')  then
  begin
    WebGoogleMaps1.RemoveDirections;
    WebGoogleMaps1.ShowDirections(edtFromAddress.Text, edtToAddress.Text);
  end
  else
    MessageDlg('Sie müssen Start- und Zieladresse eingeben',mtWarning, [MBOK]);
end;

procedure TKarte.btnzurueckClick(Sender: TObject);
begin
  self.Close;
end;

procedure TKarte.btnAnzeigenClick(Sender: TObject);
begin
  if edtToAddress.text<>'' then
  begin
    webgooglemaps1.GeoCode(edtToAddress.text);
  end;

end;

procedure TKarte.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Karte / Route</h5></nav>';
  myelement:=container1.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;

  container3.parentelement:=myelement;
  container4.parentelement:=myelement;

  myelement:=container3.ElementHandle;
  weblabel2.ParentElement:=myelement;
  edtFromAddress.ParentElement:=myelement;
  btnroute.ParentElement:=myelement;

  myelement:=container4.ElementHandle;
  weblabel1.ParentElement:=myelement;
  edtToAddress.ParentElement:=myelement;
  btnAnzeigen.ParentElement:=myelement;

  edtFromAddress.Text:=dm2.tableSYSDATPostleitzahl.AsString+' '+dm2.tableSYSDATOrt.AsString+', '+dm2.tableSYSDATStrasse.AsString;
  btnAnzeigenClick(Sender);
end;

procedure TKarte.WebGoogleMaps1GeoCoded(Sender: TObject; Lon, Lat: Double);
begin
  try

  mylatitude:=lat;
  mylongitude:=lon;
  WebGoogleMaps1.SetCenter(Lat,Lon);
  WebGoogleMaps1.SetZoom(16);
  if mykundehint='' then mykundehint:=edtToAddress.Text;

  WebGoogleMaps1.AddMarker(Lat,Lon,mykundehint);
  except

  end;
  mykundehint:='';
end;

procedure TKarte.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebGoogleMaps1 := TGoogleMaps.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  edtFromAddress := TEdit.Create(Self);
  btnroute := TButton.Create(Self);
  edtToAddress := TEdit.Create(Self);
  btnAnzeigen := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  container4 := THTMLContainer.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebGoogleMaps1.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  edtFromAddress.BeforeLoadDFMValues;
  btnroute.BeforeLoadDFMValues;
  edtToAddress.BeforeLoadDFMValues;
  btnAnzeigen.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  container4.BeforeLoadDFMValues;
  try
    Name := 'Karte';
    Width := 249;
    Height := 351;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 15;
    WebLabel2.Top := 131;
    WebLabel2.Width := 24;
    WebLabel2.Height := 13;
    WebLabel2.AutoSize := False;
    WebLabel2.Caption := 'Start';
    WebLabel2.ElementClassName := 'h5 text-primary m-1';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.WidthStyle := ssAuto;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 15;
    WebLabel1.Top := 188;
    WebLabel1.Width := 20;
    WebLabel1.Height := 13;
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Ziel';
    WebLabel1.ElementClassName := 'h5 text-primary m-1';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebGoogleMaps1.SetParentComponent(Self);
    WebGoogleMaps1.Name := 'WebGoogleMaps1';
    WebGoogleMaps1.AlignWithMargins := True;
    WebGoogleMaps1.Left := 3;
    WebGoogleMaps1.Top := 85;
    WebGoogleMaps1.Width := 243;
    WebGoogleMaps1.Height := 263;
    WebGoogleMaps1.Align := alClient;
    WebGoogleMaps1.APIKey := 'AIzaSyAtf_nj105Bqk_KakmimUR7WjrEqCUkHlU';
    WebGoogleMaps1.ChildOrder := 2;
    WebGoogleMaps1.ElementPosition := epRelative;
    WebGoogleMaps1.Options.DefaultLatitude := -34.397000000000000000;
    WebGoogleMaps1.Options.DefaultLongitude := 150.644000000000000000;
    SetEvent(WebGoogleMaps1, Self, 'OnGeoCoded', 'WebGoogleMaps1GeoCoded');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 249;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 41;
    Container2.Width := 249;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.Align := alTop;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 0;
    btnzurueck.Top := 94;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    edtFromAddress.SetParentComponent(Self);
    edtFromAddress.Name := 'edtFromAddress';
    edtFromAddress.Left := 16;
    edtFromAddress.Top := 157;
    edtFromAddress.Width := 329;
    edtFromAddress.Height := 19;
    edtFromAddress.ChildOrder := 6;
    edtFromAddress.ElementClassName := 'form-control  input-sm';
    edtFromAddress.ElementFont := efCSS;
    edtFromAddress.HeightStyle := ssAuto;
    edtFromAddress.Text := 'edtFromAddress';
    btnroute.SetParentComponent(Self);
    btnroute.Name := 'btnroute';
    btnroute.Left := 16;
    btnroute.Top := 207;
    btnroute.Width := 96;
    btnroute.Height := 25;
    btnroute.Caption := 'Route';
    btnroute.ChildOrder := 8;
    btnroute.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnroute.ElementFont := efCSS;
    btnroute.HeightStyle := ssAuto;
    SetEvent(btnroute, Self, 'OnClick', 'btnrouteClick');
    edtToAddress.SetParentComponent(Self);
    edtToAddress.Name := 'edtToAddress';
    edtToAddress.Left := 16;
    edtToAddress.Top := 182;
    edtToAddress.Width := 329;
    edtToAddress.Height := 19;
    edtToAddress.ChildOrder := 1;
    edtToAddress.ElementClassName := 'form-control  input-sm';
    edtToAddress.ElementFont := efCSS;
    edtToAddress.HeightStyle := ssAuto;
    edtToAddress.Text := 'edtToAddress';
    btnAnzeigen.SetParentComponent(Self);
    btnAnzeigen.Name := 'btnAnzeigen';
    btnAnzeigen.Left := 16;
    btnAnzeigen.Top := 238;
    btnAnzeigen.Width := 96;
    btnAnzeigen.Height := 25;
    btnAnzeigen.Caption := 'anzeigen';
    btnAnzeigen.ChildOrder := 2;
    btnAnzeigen.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnAnzeigen.ElementFont := efCSS;
    btnAnzeigen.HeightStyle := ssAuto;
    SetEvent(btnAnzeigen, Self, 'OnClick', 'btnAnzeigenClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 8;
    Container3.Top := 102;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    container4.SetParentComponent(Self);
    container4.Name := 'container4';
    container4.Left := 16;
    container4.Top := 110;
    container4.Width := 169;
    container4.Height := 41;
    container4.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    container4.HeightStyle := ssAuto;
    container4.WidthStyle := ssAuto;
    container4.ChildOrder := 2;
    container4.ElementFont := efCSS;
    container4.ElementPosition := epRelative;
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebGoogleMaps1.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    edtFromAddress.AfterLoadDFMValues;
    btnroute.AfterLoadDFMValues;
    edtToAddress.AfterLoadDFMValues;
    btnAnzeigen.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    container4.AfterLoadDFMValues;
  end;
end;

end.