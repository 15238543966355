unit unit_LieferscheinUnterschrift;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.StdCtrls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCSignatureCapture, WEBLib.ExtCtrls;

type
  TLieferscheinUnterschrift = class(TForm)
    sourceUnterschriften: TDataSource;
    dsUnterschriften: TXDataWebDataSet;
    dsUnterschriftenNummerkomplett: TStringField;
    dsUnterschriftenDatum: TDateTimeField;
    dsUnterschriftenUnterschriftKunde: TBlobField;
    dsUnterschriftenUnterschriftKundeText: TStringField;
    panelUnterschreiben: TPanel;
    WebLabel2: TLabel;
    SigKunde: TTMSFNCSignatureCapture;
    btnUAbbrechen: TButton;
    btnUSpeichern: TButton;
    WebEdit1: TEdit;
    WebLabel1: TLabel;
    dsUnterschriftenNachnameKunde: TStringField;
    procedure btnUAbbrechenClick(Sender: TObject);
    procedure btnUSpeichernClick(Sender: TObject);
    procedure dsUnterschriftenAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure dsUnterschriftenAfterPost(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    UMerkerLieferschein:string;
    procedure TouchMove1(Sender: TObject; X,Y: integer);
  end;

var
  LieferscheinUnterschrift: TLieferscheinUnterschrift;

implementation
  uses unit1, unit2;

procedure TLieferscheinUnterschrift.TouchMove1(Sender: TObject; X, Y: integer);
begin
  SigKunde.PreventDefault;
end;

procedure TLieferscheinUnterschrift.btnUAbbrechenClick(Sender: TObject);
begin
  self.Close;
end;

procedure TLieferscheinUnterschrift.btnUSpeichernClick(Sender: TObject);
begin
  dsunterschriften.Append;
  dsUnterschriftenNummerkomplett.AsString:=UMerkerLieferschein;
  dsUnterschriftenDatum.AsDateTime:=date+time;
  dsUnterschriftenUnterschriftKundeText.AsString:=sigkunde.getbase64img;
  dsUnterschriftenNachnameKunde.AsString:=WebEdit1.Text;
  dsUnterschriften.Post;
end;

procedure TLieferscheinUnterschrift.dsUnterschriftenAfterApplyUpdates(
  Sender: TDataSet; Info: TResolveResults);
begin
  self.Close;
end;

procedure TLieferscheinUnterschrift.dsUnterschriftenAfterPost(
  DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    dsUnterschriften.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TLieferscheinUnterschrift.WebFormCreate(Sender: TObject);
begin
SigKunde.OnTouchMove := @TouchMove1;
end;

procedure TLieferscheinUnterschrift.WebFormShow(Sender: TObject);
begin
  dsUnterschriften.Close;
  dsUnterschriften.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(UMerkerLieferschein);
  dsUnterschriften.Load;
end;

procedure TLieferscheinUnterschrift.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panelUnterschreiben := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  SigKunde := TTMSFNCSignatureCapture.Create(Self);
  btnUAbbrechen := TButton.Create(Self);
  btnUSpeichern := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  sourceUnterschriften := TDataSource.Create(Self);
  dsUnterschriften := TXDataWebDataSet.Create(Self);
  dsUnterschriftenNummerkomplett := TStringField.Create(Self);
  dsUnterschriftenDatum := TDateTimeField.Create(Self);
  dsUnterschriftenUnterschriftKunde := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftKundeText := TStringField.Create(Self);
  dsUnterschriftenNachnameKunde := TStringField.Create(Self);

  panelUnterschreiben.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  SigKunde.BeforeLoadDFMValues;
  btnUAbbrechen.BeforeLoadDFMValues;
  btnUSpeichern.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  sourceUnterschriften.BeforeLoadDFMValues;
  dsUnterschriften.BeforeLoadDFMValues;
  dsUnterschriftenNummerkomplett.BeforeLoadDFMValues;
  dsUnterschriftenDatum.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKunde.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKundeText.BeforeLoadDFMValues;
  dsUnterschriftenNachnameKunde.BeforeLoadDFMValues;
  try
    Name := 'LieferscheinUnterschrift';
    Width := 348;
    Height := 342;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panelUnterschreiben.SetParentComponent(Self);
    panelUnterschreiben.Name := 'panelUnterschreiben';
    panelUnterschreiben.Left := 0;
    panelUnterschreiben.Top := 0;
    panelUnterschreiben.Width := 348;
    panelUnterschreiben.Height := 342;
    panelUnterschreiben.ElementClassName := 'card m-1 border-info';
    panelUnterschreiben.Align := alClient;
    panelUnterschreiben.BorderColor := clSilver;
    panelUnterschreiben.BorderStyle := bsSingle;
    panelUnterschreiben.Caption := 'panelUnterschreiben';
    panelUnterschreiben.Color := 12557152;
    panelUnterschreiben.ElementFont := efCSS;
    panelUnterschreiben.ShowCaption := False;
    WebLabel2.SetParentComponent(panelUnterschreiben);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 8;
    WebLabel2.Width := 57;
    WebLabel2.Height := 13;
    WebLabel2.Caption := 'Unterschrift';
    WebLabel2.ElementClassName := 'h4';
    WebLabel1.SetParentComponent(panelUnterschreiben);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 288;
    WebLabel1.Width := 105;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'Name ausgeschrieben';
    WebLabel1.ElementClassName := 'h6';
    WebLabel1.ElementFont := efCSS;
    SigKunde.SetParentComponent(panelUnterschreiben);
    SigKunde.Name := 'SigKunde';
    SigKunde.Left := 16;
    SigKunde.Top := 96;
    SigKunde.Width := 295;
    SigKunde.Height := 186;
    SigKunde.ParentDoubleBuffered := False;
    SigKunde.DoubleBuffered := True;
    SigKunde.Font.Charset := DEFAULT_CHARSET;
    SigKunde.Font.Color := clWindowText;
    SigKunde.Font.Height := -11;
    SigKunde.Font.Name := 'Tahoma';
    SigKunde.Font.Style := [];
    SigKunde.TabOrder := 0;
    SigKunde.Pen.Color := clBlack;
    SigKunde.Text := 'Unterschrift Kunde';
    SigKunde.TextPosition.Line.Color := clGray;
    SigKunde.ClearSig.Image.LoadFromFile('unit_LieferscheinUnterschrift.panelUnterschreiben.SigKunde.ClearSig.Image.png');
    btnUAbbrechen.SetParentComponent(panelUnterschreiben);
    btnUAbbrechen.Name := 'btnUAbbrechen';
    btnUAbbrechen.Left := 16;
    btnUAbbrechen.Top := 54;
    btnUAbbrechen.Width := 104;
    btnUAbbrechen.Height := 25;
    btnUAbbrechen.Caption := 'Abbrechen';
    btnUAbbrechen.ChildOrder := 3;
    btnUAbbrechen.ElementClassName := 'btn btn-info btn-sm text-left';
    btnUAbbrechen.ElementFont := efCSS;
    btnUAbbrechen.HeightStyle := ssAuto;
    btnUAbbrechen.WidthStyle := ssAuto;
    SetEvent(btnUAbbrechen, Self, 'OnClick', 'btnUAbbrechenClick');
    btnUSpeichern.SetParentComponent(panelUnterschreiben);
    btnUSpeichern.Name := 'btnUSpeichern';
    btnUSpeichern.Left := 206;
    btnUSpeichern.Top := 54;
    btnUSpeichern.Width := 105;
    btnUSpeichern.Height := 25;
    btnUSpeichern.Caption := 'speichern';
    btnUSpeichern.ChildOrder := 4;
    btnUSpeichern.ElementClassName := 'btn btn-info btn-sm text-left';
    btnUSpeichern.ElementFont := efCSS;
    btnUSpeichern.HeightStyle := ssAuto;
    btnUSpeichern.WidthStyle := ssAuto;
    SetEvent(btnUSpeichern, Self, 'OnClick', 'btnUSpeichernClick');
    WebEdit1.SetParentComponent(panelUnterschreiben);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 16;
    WebEdit1.Top := 309;
    WebEdit1.Width := 295;
    WebEdit1.Height := 21;
    WebEdit1.AutoSize := True;
    WebEdit1.ChildOrder := 4;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ElementFont := efCSS;
    WebEdit1.HeightStyle := ssAuto;
    WebEdit1.TextHint := 'Name des Kunden';
    sourceUnterschriften.SetParentComponent(Self);
    sourceUnterschriften.Name := 'sourceUnterschriften';
    sourceUnterschriften.DataSet := dsUnterschriften;
    sourceUnterschriften.Left := 32;
    sourceUnterschriften.Top := 132;
    dsUnterschriften.SetParentComponent(Self);
    dsUnterschriften.Name := 'dsUnterschriften';
    dsUnterschriften.AfterApplyUpdates := dsUnterschriftenAfterApplyUpdates;
    dsUnterschriften.AfterPost := dsUnterschriftenAfterPost;
    dsUnterschriften.EntitySetName := 'LieferscheinUnterschriften';
    dsUnterschriften.Connection := DM2.XDataWebConnection1;
    dsUnterschriften.Left := 36;
    dsUnterschriften.Top := 56;
    dsUnterschriftenNummerkomplett.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNummerkomplett.Name := 'dsUnterschriftenNummerkomplett';
    dsUnterschriftenNummerkomplett.FieldName := 'Nummerkomplett';
    dsUnterschriftenNummerkomplett.Required := True;
    dsUnterschriftenNummerkomplett.Size := 10;
    dsUnterschriftenDatum.SetParentComponent(dsUnterschriften);
    dsUnterschriftenDatum.Name := 'dsUnterschriftenDatum';
    dsUnterschriftenDatum.FieldName := 'Datum';
    dsUnterschriftenUnterschriftKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKunde.Name := 'dsUnterschriftenUnterschriftKunde';
    dsUnterschriftenUnterschriftKunde.FieldName := 'UnterschriftKunde';
    dsUnterschriftenUnterschriftKundeText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKundeText.Name := 'dsUnterschriftenUnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.FieldName := 'UnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.Size := 255;
    dsUnterschriftenNachnameKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNachnameKunde.Name := 'dsUnterschriftenNachnameKunde';
    dsUnterschriftenNachnameKunde.FieldName := 'NachnameKunde';
    dsUnterschriftenNachnameKunde.Size := 50;
  finally
    panelUnterschreiben.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    SigKunde.AfterLoadDFMValues;
    btnUAbbrechen.AfterLoadDFMValues;
    btnUSpeichern.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    sourceUnterschriften.AfterLoadDFMValues;
    dsUnterschriften.AfterLoadDFMValues;
    dsUnterschriftenNummerkomplett.AfterLoadDFMValues;
    dsUnterschriftenDatum.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKunde.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKundeText.AfterLoadDFMValues;
    dsUnterschriftenNachnameKunde.AfterLoadDFMValues;
  end;
end;

end.
