unit libglmatrix;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  classes, JS, Web;

type
  TJSGLMat4 = class external name 'mat4' (TJSObject)
    class function create: TJSObject;
    class procedure perspective(aout: TJSObject; fovy, aspect, anear, afar: double);
    class procedure translate(aout: TJSObject; a: TJSObject; v: TJSFloat32Array);
    class procedure rotate(aout: TJSObject; a: TJSObject; rad: double; axis: TJSFloat32Array);
    class procedure copy(aout: TJSObject; a: TJSObject);
    class function clone(a: TJSObject): TJSObject;
  end;

  TFrameRequestMethodCallback = procedure (aTime: TJSDOMHighResTimeStamp) of object;

  TJSAnimWindow = class external name 'window' (TJSObject)
  public
    class function requestAnimationFrame(aCallback: TFrameRequestMethodCallback): Integer;
  end;


implementation


end.
