{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Modules;

interface

uses
  Classes;

type
  TDataModuleClass = class of TDataModule;
  TDataModule = class(TComponent)
  strict private
    FHeight: Integer;
    FOldCreateOrder: Boolean;
    FOnCreate: TNotifyEvent;
    FOnDestroy: TNotifyEvent;
    FWidth: Integer;
  strict protected
    procedure DoCreate; virtual;
    procedure DoDestroy; virtual;
    procedure LoadDFMValues; virtual;
  public
    constructor CreateNew(AOwner: TComponent; Dummy: Integer = 0); virtual;
    procedure AfterConstruction; override;
    procedure BeforeDestruction; override;
    property Height: Integer read FHeight write FHeight;
    property OldCreateOrder: Boolean read FOldCreateOrder write FOldCreateOrder;
    property Width: Integer read FWidth write FWidth;
  published
    property OnCreate: TNotifyEvent read FOnCreate write FOnCreate;
    property OnDestroy: TNotifyEvent read FOnDestroy write FOnDestroy;
  end;
  TWebDataModule = TDataModule;

implementation

constructor TDataModule.CreateNew(AOwner: TComponent; Dummy: Integer);
begin
  inherited Create(AOwner);
end;

{ TDataModule }

procedure TDataModule.AfterConstruction;
begin
  inherited AfterConstruction;
  if not OldCreateOrder then
    DoCreate;
end;

procedure TDataModule.BeforeDestruction;
begin
  inherited BeforeDestruction;
  if not OldCreateOrder then
    DoDestroy;
end;

procedure TDataModule.DoCreate;
begin
  LoadDFMValues;
  if Assigned(FOnCreate) then
    FOnCreate(Self);
end;

procedure TDataModule.DoDestroy;
begin
  if Assigned(FOnDestroy) then
    FOnDestroy(Self);
end;

procedure TDataModule.LoadDFMValues;
begin
end;

end.
