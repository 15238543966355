unit unit_EinzelEmail;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Grids, DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.StdCtrls, strutils;

type
  TEinzelEmail = class(TForm)
    TC: TTableControl;
    xdsEmails: TXDataWebDataSet;
    xdsEmailsEmailEmpfangenUhrzeit: TDateTimeField;
    xdsEmailsID: TIntegerField;
    xdsEmailsarchiviert: TStringField;
    xdsEmailsEmailFrom: TStringField;
    xdsEmailsEmailFromName: TStringField;
    xdsEmailsEmailTo: TStringField;
    xdsEmailsEmailCC: TStringField;
    xdsEmailsEmailBetreff: TStringField;
    xdsEmailsEmailBody: TStringField;
    xdsEmailsEmailPriority: TIntegerField;
    xdsEmailsMSG: TStringField;
    xdsEmailsEmailOrganization: TStringField;
    xdsEmailsEmailbearbeitet: TDateTimeField;
    xdsEmailsEmailbearbeitetvon: TStringField;
    xdsEmailsKundennummer: TStringField;
    xdsEmailsPosteingang: TBooleanField;
    xdsEmailsEmailID: TStringField;
    xdsEmailsEmailReceipt: TStringField;
    xdsEmailsEmailgelesen: TDateTimeField;
    xdsEmailsEmailContentType: TStringField;
    xdsEmailsEmailgelesenvon: TStringField;
    xdsEmailsEmailReplyTo: TStringField;
    xdsEmailsEmailReplyToName: TStringField;
    xdsEmailsEmailUIDL: TStringField;
    xdsEmailsEmailBCC: TStringField;
    xdsEmailsEmailBest: TBooleanField;
    xdsEmailsSchreibzeichen: TStringField;
    xdsEmailsGesendete: TBooleanField;
    xdsEmailsGesendetUhrzeit: TDateTimeField;
    xdsEmailsPersNo: TIntegerField;
    xdsEmailsPersColor: TIntegerField;
    xdsEmailsBearbeitungsnotiz: TStringField;
    xdsEmailsNurText: TStringField;
    xdsEmailsObjektReferenz: TStringField;
    xdsEmailsObjektnummer: TStringField;
    xdsEmailsEmailKonto: TStringField;
    xdsEmailsFreigabe: TStringField;
    sourceEmails: TDataSource;
    procedure WebFormShow(Sender: TObject);
    procedure xdsEmailsAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    buttonzurueck:TButton;
    EmailID:string;
    procedure buttonzurueckclick(Sender:TObject);
  end;

var
  EinzelEmail: TEinzelEmail;

implementation
  uses unit1, unit2;

procedure TEinzelEmail.buttonzurueckclick(Sender:TObject);
begin
  self.close;
end;

procedure TEinzelEmail.WebFormShow(Sender: TObject);
begin
  xdsEmails.Close;
  xdsEmails.querystring:='$filter=ID eq '+EmailID;
  xdsemails.Load;
end;

procedure TEinzelEmail.xdsEmailsAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin
  TC.Colcount:=2;
  TC.Cells[1,i]:='<h4>Einzel-Email</h4>'+xdsemailsid.asstring;
  inc(i);
  myelement:=TC.cellelements[1,i];
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  tc.rowcount:=8;

  inc(i);
  tc.cells[0,i]:='von';
  tc.cells[1,i]:=xdsemailsEmailFromName.asstring+' ('+xdsemailsEmailFrom.asstring+')';

  inc(i);
  tc.cells[0,i]:='an';
  tc.cells[1,i]:=xdsemailsEmailKonto.asstring;

  inc(i);
  tc.cells[0,i]:='cc';
  tc.cells[1,i]:=xdsemailsEmailCC.asstring;

  inc(i);
  tc.cells[1,i]:=xdsemailsEmailEmpfangenUhrzeit.asstring;
  tc.cells[0,i]:='um';

  inc(i);
  tc.cells[1,i]:='<b>'+xdsemailsemailbetreff.asstring+'</b><br>'+ replacestr(xdsemailsNurText.asstring,#13+#10,'<BR>');


end;

procedure TEinzelEmail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  xdsEmails := TXDataWebDataSet.Create(Self);
  xdsEmailsEmailEmpfangenUhrzeit := TDateTimeField.Create(Self);
  xdsEmailsID := TIntegerField.Create(Self);
  xdsEmailsarchiviert := TStringField.Create(Self);
  xdsEmailsEmailFrom := TStringField.Create(Self);
  xdsEmailsEmailFromName := TStringField.Create(Self);
  xdsEmailsEmailTo := TStringField.Create(Self);
  xdsEmailsEmailCC := TStringField.Create(Self);
  xdsEmailsEmailBetreff := TStringField.Create(Self);
  xdsEmailsEmailBody := TStringField.Create(Self);
  xdsEmailsEmailPriority := TIntegerField.Create(Self);
  xdsEmailsMSG := TStringField.Create(Self);
  xdsEmailsEmailOrganization := TStringField.Create(Self);
  xdsEmailsEmailbearbeitet := TDateTimeField.Create(Self);
  xdsEmailsEmailbearbeitetvon := TStringField.Create(Self);
  xdsEmailsKundennummer := TStringField.Create(Self);
  xdsEmailsPosteingang := TBooleanField.Create(Self);
  xdsEmailsEmailID := TStringField.Create(Self);
  xdsEmailsEmailReceipt := TStringField.Create(Self);
  xdsEmailsEmailgelesen := TDateTimeField.Create(Self);
  xdsEmailsEmailContentType := TStringField.Create(Self);
  xdsEmailsEmailgelesenvon := TStringField.Create(Self);
  xdsEmailsEmailReplyTo := TStringField.Create(Self);
  xdsEmailsEmailReplyToName := TStringField.Create(Self);
  xdsEmailsEmailUIDL := TStringField.Create(Self);
  xdsEmailsEmailBCC := TStringField.Create(Self);
  xdsEmailsEmailBest := TBooleanField.Create(Self);
  xdsEmailsSchreibzeichen := TStringField.Create(Self);
  xdsEmailsGesendete := TBooleanField.Create(Self);
  xdsEmailsGesendetUhrzeit := TDateTimeField.Create(Self);
  xdsEmailsPersNo := TIntegerField.Create(Self);
  xdsEmailsPersColor := TIntegerField.Create(Self);
  xdsEmailsBearbeitungsnotiz := TStringField.Create(Self);
  xdsEmailsNurText := TStringField.Create(Self);
  xdsEmailsObjektReferenz := TStringField.Create(Self);
  xdsEmailsObjektnummer := TStringField.Create(Self);
  xdsEmailsEmailKonto := TStringField.Create(Self);
  xdsEmailsFreigabe := TStringField.Create(Self);
  sourceEmails := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  xdsEmails.BeforeLoadDFMValues;
  xdsEmailsEmailEmpfangenUhrzeit.BeforeLoadDFMValues;
  xdsEmailsID.BeforeLoadDFMValues;
  xdsEmailsarchiviert.BeforeLoadDFMValues;
  xdsEmailsEmailFrom.BeforeLoadDFMValues;
  xdsEmailsEmailFromName.BeforeLoadDFMValues;
  xdsEmailsEmailTo.BeforeLoadDFMValues;
  xdsEmailsEmailCC.BeforeLoadDFMValues;
  xdsEmailsEmailBetreff.BeforeLoadDFMValues;
  xdsEmailsEmailBody.BeforeLoadDFMValues;
  xdsEmailsEmailPriority.BeforeLoadDFMValues;
  xdsEmailsMSG.BeforeLoadDFMValues;
  xdsEmailsEmailOrganization.BeforeLoadDFMValues;
  xdsEmailsEmailbearbeitet.BeforeLoadDFMValues;
  xdsEmailsEmailbearbeitetvon.BeforeLoadDFMValues;
  xdsEmailsKundennummer.BeforeLoadDFMValues;
  xdsEmailsPosteingang.BeforeLoadDFMValues;
  xdsEmailsEmailID.BeforeLoadDFMValues;
  xdsEmailsEmailReceipt.BeforeLoadDFMValues;
  xdsEmailsEmailgelesen.BeforeLoadDFMValues;
  xdsEmailsEmailContentType.BeforeLoadDFMValues;
  xdsEmailsEmailgelesenvon.BeforeLoadDFMValues;
  xdsEmailsEmailReplyTo.BeforeLoadDFMValues;
  xdsEmailsEmailReplyToName.BeforeLoadDFMValues;
  xdsEmailsEmailUIDL.BeforeLoadDFMValues;
  xdsEmailsEmailBCC.BeforeLoadDFMValues;
  xdsEmailsEmailBest.BeforeLoadDFMValues;
  xdsEmailsSchreibzeichen.BeforeLoadDFMValues;
  xdsEmailsGesendete.BeforeLoadDFMValues;
  xdsEmailsGesendetUhrzeit.BeforeLoadDFMValues;
  xdsEmailsPersNo.BeforeLoadDFMValues;
  xdsEmailsPersColor.BeforeLoadDFMValues;
  xdsEmailsBearbeitungsnotiz.BeforeLoadDFMValues;
  xdsEmailsNurText.BeforeLoadDFMValues;
  xdsEmailsObjektReferenz.BeforeLoadDFMValues;
  xdsEmailsObjektnummer.BeforeLoadDFMValues;
  xdsEmailsEmailKonto.BeforeLoadDFMValues;
  xdsEmailsFreigabe.BeforeLoadDFMValues;
  sourceEmails.BeforeLoadDFMValues;
  try
    Name := 'EinzelEmail';
    Left := 0;
    Top := 0;
    Width := 225;
    Height := 297;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 225;
    TC.Height := 297;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 1;
    TC.RowCount := 5;
    xdsEmails.SetParentComponent(Self);
    xdsEmails.Name := 'xdsEmails';
    xdsEmails.AfterOpen := xdsEmailsAfterOpen;
    xdsEmails.EntitySetName := 'EmailEingang';
    xdsEmails.Connection := DM2.XDataWebConnection1;
    xdsEmails.QueryString := '$filter=archiviert eq null&$orderby=ID DESC';
    xdsEmails.QueryTop := 1;
    xdsEmails.Left := 144;
    xdsEmails.Top := 72;
    xdsEmailsEmailEmpfangenUhrzeit.SetParentComponent(xdsEmails);
    xdsEmailsEmailEmpfangenUhrzeit.Name := 'xdsEmailsEmailEmpfangenUhrzeit';
    xdsEmailsEmailEmpfangenUhrzeit.FieldName := 'EmailEmpfangenUhrzeit';
    xdsEmailsID.SetParentComponent(xdsEmails);
    xdsEmailsID.Name := 'xdsEmailsID';
    xdsEmailsID.FieldName := 'ID';
    xdsEmailsID.Required := True;
    xdsEmailsarchiviert.SetParentComponent(xdsEmails);
    xdsEmailsarchiviert.Name := 'xdsEmailsarchiviert';
    xdsEmailsarchiviert.FieldName := 'archiviert';
    xdsEmailsarchiviert.Size := 250;
    xdsEmailsEmailFrom.SetParentComponent(xdsEmails);
    xdsEmailsEmailFrom.Name := 'xdsEmailsEmailFrom';
    xdsEmailsEmailFrom.FieldName := 'EmailFrom';
    xdsEmailsEmailFrom.Size := 250;
    xdsEmailsEmailFromName.SetParentComponent(xdsEmails);
    xdsEmailsEmailFromName.Name := 'xdsEmailsEmailFromName';
    xdsEmailsEmailFromName.FieldName := 'EmailFromName';
    xdsEmailsEmailFromName.Size := 250;
    xdsEmailsEmailTo.SetParentComponent(xdsEmails);
    xdsEmailsEmailTo.Name := 'xdsEmailsEmailTo';
    xdsEmailsEmailTo.FieldName := 'EmailTo';
    xdsEmailsEmailTo.Size := 250;
    xdsEmailsEmailCC.SetParentComponent(xdsEmails);
    xdsEmailsEmailCC.Name := 'xdsEmailsEmailCC';
    xdsEmailsEmailCC.FieldName := 'EmailCC';
    xdsEmailsEmailCC.Size := 250;
    xdsEmailsEmailBetreff.SetParentComponent(xdsEmails);
    xdsEmailsEmailBetreff.Name := 'xdsEmailsEmailBetreff';
    xdsEmailsEmailBetreff.FieldName := 'EmailBetreff';
    xdsEmailsEmailBetreff.Size := 250;
    xdsEmailsEmailBody.SetParentComponent(xdsEmails);
    xdsEmailsEmailBody.Name := 'xdsEmailsEmailBody';
    xdsEmailsEmailBody.FieldName := 'EmailBody';
    xdsEmailsEmailBody.Size := 255;
    xdsEmailsEmailPriority.SetParentComponent(xdsEmails);
    xdsEmailsEmailPriority.Name := 'xdsEmailsEmailPriority';
    xdsEmailsEmailPriority.FieldName := 'EmailPriority';
    xdsEmailsMSG.SetParentComponent(xdsEmails);
    xdsEmailsMSG.Name := 'xdsEmailsMSG';
    xdsEmailsMSG.FieldName := 'MSG';
    xdsEmailsMSG.Size := 255;
    xdsEmailsEmailOrganization.SetParentComponent(xdsEmails);
    xdsEmailsEmailOrganization.Name := 'xdsEmailsEmailOrganization';
    xdsEmailsEmailOrganization.FieldName := 'EmailOrganization';
    xdsEmailsEmailOrganization.Size := 100;
    xdsEmailsEmailbearbeitet.SetParentComponent(xdsEmails);
    xdsEmailsEmailbearbeitet.Name := 'xdsEmailsEmailbearbeitet';
    xdsEmailsEmailbearbeitet.FieldName := 'Emailbearbeitet';
    xdsEmailsEmailbearbeitetvon.SetParentComponent(xdsEmails);
    xdsEmailsEmailbearbeitetvon.Name := 'xdsEmailsEmailbearbeitetvon';
    xdsEmailsEmailbearbeitetvon.FieldName := 'Emailbearbeitetvon';
    xdsEmailsEmailbearbeitetvon.Size := 50;
    xdsEmailsKundennummer.SetParentComponent(xdsEmails);
    xdsEmailsKundennummer.Name := 'xdsEmailsKundennummer';
    xdsEmailsKundennummer.FieldName := 'Kundennummer';
    xdsEmailsKundennummer.Size := 13;
    xdsEmailsPosteingang.SetParentComponent(xdsEmails);
    xdsEmailsPosteingang.Name := 'xdsEmailsPosteingang';
    xdsEmailsPosteingang.FieldName := 'Posteingang';
    xdsEmailsEmailID.SetParentComponent(xdsEmails);
    xdsEmailsEmailID.Name := 'xdsEmailsEmailID';
    xdsEmailsEmailID.FieldName := 'EmailID';
    xdsEmailsEmailID.Size := 100;
    xdsEmailsEmailReceipt.SetParentComponent(xdsEmails);
    xdsEmailsEmailReceipt.Name := 'xdsEmailsEmailReceipt';
    xdsEmailsEmailReceipt.FieldName := 'EmailReceipt';
    xdsEmailsEmailReceipt.Size := 100;
    xdsEmailsEmailgelesen.SetParentComponent(xdsEmails);
    xdsEmailsEmailgelesen.Name := 'xdsEmailsEmailgelesen';
    xdsEmailsEmailgelesen.FieldName := 'Emailgelesen';
    xdsEmailsEmailContentType.SetParentComponent(xdsEmails);
    xdsEmailsEmailContentType.Name := 'xdsEmailsEmailContentType';
    xdsEmailsEmailContentType.FieldName := 'EmailContentType';
    xdsEmailsEmailContentType.Size := 100;
    xdsEmailsEmailgelesenvon.SetParentComponent(xdsEmails);
    xdsEmailsEmailgelesenvon.Name := 'xdsEmailsEmailgelesenvon';
    xdsEmailsEmailgelesenvon.FieldName := 'Emailgelesenvon';
    xdsEmailsEmailgelesenvon.Size := 50;
    xdsEmailsEmailReplyTo.SetParentComponent(xdsEmails);
    xdsEmailsEmailReplyTo.Name := 'xdsEmailsEmailReplyTo';
    xdsEmailsEmailReplyTo.FieldName := 'EmailReplyTo';
    xdsEmailsEmailReplyTo.Size := 100;
    xdsEmailsEmailReplyToName.SetParentComponent(xdsEmails);
    xdsEmailsEmailReplyToName.Name := 'xdsEmailsEmailReplyToName';
    xdsEmailsEmailReplyToName.FieldName := 'EmailReplyToName';
    xdsEmailsEmailReplyToName.Size := 100;
    xdsEmailsEmailUIDL.SetParentComponent(xdsEmails);
    xdsEmailsEmailUIDL.Name := 'xdsEmailsEmailUIDL';
    xdsEmailsEmailUIDL.FieldName := 'EmailUIDL';
    xdsEmailsEmailUIDL.Size := 250;
    xdsEmailsEmailBCC.SetParentComponent(xdsEmails);
    xdsEmailsEmailBCC.Name := 'xdsEmailsEmailBCC';
    xdsEmailsEmailBCC.FieldName := 'EmailBCC';
    xdsEmailsEmailBCC.Size := 50;
    xdsEmailsEmailBest.SetParentComponent(xdsEmails);
    xdsEmailsEmailBest.Name := 'xdsEmailsEmailBest';
    xdsEmailsEmailBest.FieldName := 'EmailBest';
    xdsEmailsSchreibzeichen.SetParentComponent(xdsEmails);
    xdsEmailsSchreibzeichen.Name := 'xdsEmailsSchreibzeichen';
    xdsEmailsSchreibzeichen.FieldName := 'Schreibzeichen';
    xdsEmailsSchreibzeichen.Size := 10;
    xdsEmailsGesendete.SetParentComponent(xdsEmails);
    xdsEmailsGesendete.Name := 'xdsEmailsGesendete';
    xdsEmailsGesendete.FieldName := 'Gesendete';
    xdsEmailsGesendetUhrzeit.SetParentComponent(xdsEmails);
    xdsEmailsGesendetUhrzeit.Name := 'xdsEmailsGesendetUhrzeit';
    xdsEmailsGesendetUhrzeit.FieldName := 'GesendetUhrzeit';
    xdsEmailsPersNo.SetParentComponent(xdsEmails);
    xdsEmailsPersNo.Name := 'xdsEmailsPersNo';
    xdsEmailsPersNo.FieldName := 'PersNo';
    xdsEmailsPersColor.SetParentComponent(xdsEmails);
    xdsEmailsPersColor.Name := 'xdsEmailsPersColor';
    xdsEmailsPersColor.FieldName := 'PersColor';
    xdsEmailsBearbeitungsnotiz.SetParentComponent(xdsEmails);
    xdsEmailsBearbeitungsnotiz.Name := 'xdsEmailsBearbeitungsnotiz';
    xdsEmailsBearbeitungsnotiz.FieldName := 'Bearbeitungsnotiz';
    xdsEmailsBearbeitungsnotiz.Size := 255;
    xdsEmailsNurText.SetParentComponent(xdsEmails);
    xdsEmailsNurText.Name := 'xdsEmailsNurText';
    xdsEmailsNurText.FieldName := 'NurText';
    xdsEmailsNurText.Size := 255;
    xdsEmailsObjektReferenz.SetParentComponent(xdsEmails);
    xdsEmailsObjektReferenz.Name := 'xdsEmailsObjektReferenz';
    xdsEmailsObjektReferenz.FieldName := 'ObjektReferenz';
    xdsEmailsObjektReferenz.Size := 13;
    xdsEmailsObjektnummer.SetParentComponent(xdsEmails);
    xdsEmailsObjektnummer.Name := 'xdsEmailsObjektnummer';
    xdsEmailsObjektnummer.FieldName := 'Objektnummer';
    xdsEmailsObjektnummer.Size := 25;
    xdsEmailsEmailKonto.SetParentComponent(xdsEmails);
    xdsEmailsEmailKonto.Name := 'xdsEmailsEmailKonto';
    xdsEmailsEmailKonto.FieldName := 'EmailKonto';
    xdsEmailsEmailKonto.Size := 50;
    xdsEmailsFreigabe.SetParentComponent(xdsEmails);
    xdsEmailsFreigabe.Name := 'xdsEmailsFreigabe';
    xdsEmailsFreigabe.FieldName := 'Freigabe';
    xdsEmailsFreigabe.Size := 50;
    sourceEmails.SetParentComponent(Self);
    sourceEmails.Name := 'sourceEmails';
    sourceEmails.DataSet := xdsEmails;
    sourceEmails.Left := 72;
    sourceEmails.Top := 72;
  finally
    TC.AfterLoadDFMValues;
    xdsEmails.AfterLoadDFMValues;
    xdsEmailsEmailEmpfangenUhrzeit.AfterLoadDFMValues;
    xdsEmailsID.AfterLoadDFMValues;
    xdsEmailsarchiviert.AfterLoadDFMValues;
    xdsEmailsEmailFrom.AfterLoadDFMValues;
    xdsEmailsEmailFromName.AfterLoadDFMValues;
    xdsEmailsEmailTo.AfterLoadDFMValues;
    xdsEmailsEmailCC.AfterLoadDFMValues;
    xdsEmailsEmailBetreff.AfterLoadDFMValues;
    xdsEmailsEmailBody.AfterLoadDFMValues;
    xdsEmailsEmailPriority.AfterLoadDFMValues;
    xdsEmailsMSG.AfterLoadDFMValues;
    xdsEmailsEmailOrganization.AfterLoadDFMValues;
    xdsEmailsEmailbearbeitet.AfterLoadDFMValues;
    xdsEmailsEmailbearbeitetvon.AfterLoadDFMValues;
    xdsEmailsKundennummer.AfterLoadDFMValues;
    xdsEmailsPosteingang.AfterLoadDFMValues;
    xdsEmailsEmailID.AfterLoadDFMValues;
    xdsEmailsEmailReceipt.AfterLoadDFMValues;
    xdsEmailsEmailgelesen.AfterLoadDFMValues;
    xdsEmailsEmailContentType.AfterLoadDFMValues;
    xdsEmailsEmailgelesenvon.AfterLoadDFMValues;
    xdsEmailsEmailReplyTo.AfterLoadDFMValues;
    xdsEmailsEmailReplyToName.AfterLoadDFMValues;
    xdsEmailsEmailUIDL.AfterLoadDFMValues;
    xdsEmailsEmailBCC.AfterLoadDFMValues;
    xdsEmailsEmailBest.AfterLoadDFMValues;
    xdsEmailsSchreibzeichen.AfterLoadDFMValues;
    xdsEmailsGesendete.AfterLoadDFMValues;
    xdsEmailsGesendetUhrzeit.AfterLoadDFMValues;
    xdsEmailsPersNo.AfterLoadDFMValues;
    xdsEmailsPersColor.AfterLoadDFMValues;
    xdsEmailsBearbeitungsnotiz.AfterLoadDFMValues;
    xdsEmailsNurText.AfterLoadDFMValues;
    xdsEmailsObjektReferenz.AfterLoadDFMValues;
    xdsEmailsObjektnummer.AfterLoadDFMValues;
    xdsEmailsEmailKonto.AfterLoadDFMValues;
    xdsEmailsFreigabe.AfterLoadDFMValues;
    sourceEmails.AfterLoadDFMValues;
  end;
end;

end.