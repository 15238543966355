unit unit_Terminkalender;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, WEBLib.StdCtrls,
  WEBLib.TMSFNCCustomControl,
  WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCDatePicker, WEBLib.ExtCtrls,
 dateutils,
  WEBLib.Buttons, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.WebCtrls;

type
  TTerminkalender = class(TForm)
    sourceTermine: TDataSource;
    XDTermine: TXDataWebDataSet;
    XDTermineArtikelnummer: TXDataWebEntityField;
    XDTermineUID: TStringField;
    XDTermineBeginn: TDateTimeField;
    XDTermineEnde: TDateTimeField;
    XDTermineBetreff: TStringField;
    XDTermineQuelle: TStringField;
    XDTermineGUIDOutlook: TStringField;
    XDTermineShape: TIntegerField;
    XDTermineColor: TIntegerField;
    XDTermineAlarm: TBooleanField;
    XDTermineResource: TIntegerField;
    XDTermineLastModificationTime: TDateTimeField;
    XDTermineGeburtstag: TBooleanField;
    XDTermineAlarmBefore: TIntegerField;
    XDTerminePersonalnummer: TIntegerField;
    XDTermineKundennummer: TStringField;
    XDTermineAllDayEventOutlook: TBooleanField;
    XDTermineTMSCaption: TBooleanField;
    XDTermineRECURRENCY: TStringField;
    XDTermineMINTIME: TDateTimeField;
    XDTermineMAXTIME: TDateTimeField;
    XDTermineNachname: TStringField;
    XDTermineObjektReferenz: TStringField;
    XDTermineObjektnummer: TStringField;
    XDTermineTerminArt: TIntegerField;
    XDTermineBeginnDatum: TDateTimeField;
    XDTermineBeginnUhrzeit: TDateTimeField;
    XDTermineEndeDatum: TDateTimeField;
    XDTermineEndeUhrzeit: TDateTimeField;
    XDTermineKalenderAnzeige: TBooleanField;
    XDTermineStundensatz: TFloatField;
    XDTermineDauerStunden: TFloatField;
    XDTerminegefahrenekm: TFloatField;
    XDTermineAufwand: TFloatField;
    XDTermineabgerechnet: TBooleanField;
    XDTermineverteilt: TDateTimeField;
    XDTermineTerminErledigt: TDateTimeField;
    XDTermineAnsprechpartner: TStringField;
    XDTermineLocation: TStringField;
    XDTermineGoogleID: TStringField;
    XDTermineGoogleCreatedUTC: TDateTimeField;
    XDTermineGoogleUpdatedUTC: TDateTimeField;
    XDTermineGoogleStatus: TStringField;
    XDTermineVorname: TStringField;
    XDTerminePNachname: TStringField;
    XDTerminePVorname: TStringField;
    XDTermineArtikelueberschrift: TStringField;
    XDTerminePFarbe: TIntegerField;
    XDTermineKontaktart: TStringField;
    XDTermineTerminNotiz: TStringField;
    XDTermineArtikelnummerArtikelnummer: TStringField;
    TC: TTableControl;
    comboMonat: TComboBox;
    comboJahr: TComboBox;
    procedure WebFormShow(Sender: TObject);
    procedure XDTermineAfterOpen(DataSet: TDataSet);
    procedure comboMonatChange(Sender: TObject);
    procedure comboJahrChange(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    buttonzurueck:TButton;
    NeuerTermin:THTMLDiv;
    einzeltermin:THTMLDiv;
    procedure Termineladen;
    procedure buttonzurueckclick(Sender:TObject);
    procedure NeuerTerminclick(Sender:TObject);
    procedure einzelterminclick(Sender:TObject);
  end;

var
  Terminkalender: TTerminkalender;
  Startdatum:TDateTime;
  EndeDatum:TDateTime;
  mymonat:integer;

implementation
  uses unit1, unit2, unit_artikelsuche, unit_kontaktsuche, unit_einzeltermin, unit_Objektsuche;

procedure TTerminkalender.buttonzurueckclick(Sender:TObject);
begin
  XDTermine.Close;
  self.Close;
end;

procedure TTerminkalender.comboJahrChange(Sender: TObject);
begin
  Termineladen;
end;

procedure TTerminkalender.comboMonatChange(Sender: TObject);
begin
  Termineladen;
end;

procedure TTerminkalender.einzelterminclick(Sender:TObject);
  var
  LFrm:TfrmEinzeltermin;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmEinzeltermin;
    begin
      LForm:=(AForm as TfrmEinzeltermin);
      lform.TerminUID:=(sender as THTMLDiv).ElementID;;
      tc.Visible:=false;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      Termineladen;
    end;

  begin
  LFrm:=TfrmEinzeltermin.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TTerminkalender.NeuerTerminclick(Sender:TObject);
    var
  LFrm:TfrmEinzeltermin;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmEinzeltermin;
    begin
      LForm:=(AForm as TfrmEinzeltermin);
      lform.TerminUID:='';
      decodedatetime(date+time,ajahr,amonat,atag,astunde,aminute,asekunde,ams);
      decodedate((sender as THTMLDiv).Tag,ajahr,amonat,atag);
      lform.NeuStartDateTime:=encodedatetime(ajahr, amonat,atag,astunde,0,0,0);
      lform.NeuEndDateTime:=encodedatetime(ajahr, amonat,atag,astunde+1,0,0,0);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      Termineladen;
    end;

  begin
  LFrm:=TfrmEinzeltermin.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);
end;

procedure TTerminkalender.Termineladen;
begin
  mymonat:=combomonat.ItemIndex+1;
  Startdatum:=encodedate(strtoint(combojahr.Text),mymonat,1);
  EndeDatum:=EndOfTheMonth(Startdatum);
  xdtermine.Close;
  xdtermine.QueryString:='$Filter=(Beginn ge '+formatdatetime('yyyy-mm-dd',Startdatum-1)+') AND (Beginn le '+formatdatetime('yyyy-mm-dd',endedatum+1)+') &$orderby=Beginn';
  XDTermine.Load;
end;

procedure TTerminkalender.WebFormShow(Sender: TObject);
begin
  comboJahr.Items.Clear;
  decodedate(date,ajahr,amonat,atag);
  mymonat:=amonat;
  combojahr.Items.Add(inttostr(ajahr-5));
  combojahr.Items.Add(inttostr(ajahr-4));
  combojahr.Items.Add(inttostr(ajahr-3));
  combojahr.Items.Add(inttostr(ajahr-2));
  combojahr.Items.Add(inttostr(ajahr-1));
  combojahr.Items.Add(inttostr(ajahr));
  combojahr.Items.Add(inttostr(ajahr+1));
  combojahr.Items.Add(inttostr(ajahr+2));
  combojahr.Items.Add(inttostr(ajahr+3));
  combojahr.Items.Add(inttostr(ajahr+4));
  combojahr.Items.Add(inttostr(ajahr+5));
  combojahr.ItemIndex:=5;
  combomonat.ItemIndex:=amonat-1;

  Termineladen;
end;

procedure TTerminkalender.XDTermineAfterOpen(DataSet: TDataSet);
var
  i:integer;
  terminstring:string;
  myelement:TJSHTMLElement;
  laufendesdatum:TDateTime;
  myTag:integer;
begin
  TC.Visible:=true;
  TC.RowCount:=35;
  TC.ColCount:=1;
  i:=0;
  TC.Cells[0,i]:='<h4>Terminübersicht';
  inc(i);
  TC.Cells[0,i]:='<i>Neuer Termin > Tagesdatum anklicken</i><br>';
  myelement:=TC.cellelements[0,i];
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  inc(i);
  TC.Cells[0,i]:='<i> Jahr und Monat für die Anzeige wählen </i><br>';
  myelement:=TC.cellelements[0,i];
  comboJahr.ParentElement:=myelement;
  comboMonat.ParentElement:=myelement;
  inc(i);
  laufendesdatum:=Startdatum;
  while laufendesdatum<endedatum do
  begin

    myelement:=TC.cellelements[0,i];
    myelement.innerHTML:='';
    NeuerTermin:=THTMLDiv.Create(self);

    NeuerTermin.HeightStyle:=ssauto;
    NeuerTermin.WidthStyle:=ssauto;
    NeuerTermin.tag:=trunc(laufendesdatum);
    NeuerTermin.ElementID:=inttostr(trunc(laufendesdatum));
    if DayOfTheWeek(laufendesdatum)>5 then
    begin
      NeuerTermin.ElementClassName:='text-danger';
      NeuerTermin.HTML.Text:= '<b>'+formatdatetime('DDD DD.MM.YYYY',laufendesdatum)+'</b>';
    end
    else
    begin
      NeuerTermin.ElementClassName:='text-dark';
      NeuerTermin.HTML.Text:= '<b>'+formatdatetime('DDD DD.MM.YYYY',laufendesdatum)+'</b>';
    end;

    NeuerTermin.OnClick:=@NeuerTerminClick;
    NeuerTermin.ParentElement:=myelement;


    laufendesdatum:=laufendesdatum+1;
    inc(i);

  end;
  TC.RowCount:=i;
  xdtermine.First;
  while not xdtermine.eof do
  begin
    DecodeDateTime(xdterminebeginn.asdatetime,ajahr,amonat,atag,astunde,aminute,asekunde,ams);
    mytag:=atag;
    if amonat=mymonat then
    begin
      // Termin (0)
      if XDTermineTerminArt.AsInteger=0 then
      begin
        If trunc(XDTermineBeginn.AsDateTime)=trunc(XDTermineEnde.AsDateTime) then
        begin
        terminstring:=formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineBeginn.Asdatetime)+' - '+formatdatetime('hh:mm',XDTermineEndeUhrzeit.Asdatetime);
        terminstring:=terminstring+'<br>'+ XDTermineBetreff.AsString+'<br>';
        end
        else
        begin
        terminstring:=formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineBeginn.Asdatetime)+' - '+formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineEnde.Asdatetime);
        terminstring:=terminstring+'<br>'+ XDTermineBetreff.AsString+'<br>';
        end;
        if XDTermineTerminNotiz.AsString<>'' then
        begin
          terminstring:=terminstring+XDTermineTerminNotiz.AsString+'<br>';
        end;

        myelement:=TC.cellelements[0,mytag+2];
        einzeltermin:=THTMLDiv.Create(self);
        einzeltermin.ElementID:=xdtermineuid.asstring;
        einzeltermin.HeightStyle:=ssauto;
        einzeltermin.Width:=350;
        einzeltermin.AlignWithMargins:=true;
        einzeltermin.HTML.Text:=terminstring;
        einzeltermin.ElementClassName:='card my-2 bg-info';
        Einzeltermin.OnClick:=@EinzelterminClick;
        einzeltermin.ParentElement:=myelement;
      end;
      // Wiedervorlage (1)
      if XDTermineTerminArt.AsInteger=1 then
      begin
        terminstring:='<I>Wiedervorlage</I>'+ XDTermineBetreff.AsString;
        if XDTermineTerminNotiz.AsString<>'' then
        begin
          terminstring:=terminstring+'<br>'+XDTermineTerminNotiz.AsString;

        end;
        terminstring:=terminstring+'<br>'+XDTermineKundennummer.AsString+' '+XDTermineNachname.AsString;
        myelement:=TC.cellelements[0,mytag+2];
        einzeltermin:=THTMLDiv.Create(self);
        einzeltermin.ElementID:=xdtermineuid.asstring;
        einzeltermin.HeightStyle:=ssauto;
        einzeltermin.Width:=350;
        einzeltermin.AlignWithMargins:=true;
        einzeltermin.HTML.Text:=terminstring;
        einzeltermin.ElementClassName:='card my-2 bg-success';
        Einzeltermin.OnClick:=@EinzelterminClick;
        einzeltermin.ParentElement:=myelement;
      end;
      //Geburtstag(2)
      if XDTermineTerminArt.AsInteger=2 then
      begin
        terminstring:='<I>Geburtstag</I>'+ XDTermineBetreff.AsString;
        myelement:=TC.cellelements[0,mytag+2];
        einzeltermin:=THTMLDiv.Create(self);
        einzeltermin.ElementID:=xdtermineuid.asstring;
        einzeltermin.HeightStyle:=ssauto;
        einzeltermin.Width:=350;
        einzeltermin.AlignWithMargins:=true;
        einzeltermin.HTML.Text:=terminstring;
        einzeltermin.ElementClassName:='card my-2 bg-danger';
        Einzeltermin.OnClick:=@EinzelterminClick;
        einzeltermin.ParentElement:=myelement;
      end;
      // Aktivität (3)
      if XDTermineTerminArt.AsInteger=3 then
      begin
        terminstring:='<I>Aktivität</I>';
        If trunc(XDTermineBeginn.AsDateTime)=trunc(XDTermineEnde.AsDateTime) then
        begin
        terminstring:=terminstring+formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineBeginn.Asdatetime)+' - '+formatdatetime('hh:mm',XDTermineEndeUhrzeit.Asdatetime);
        terminstring:=terminstring+'<br>'+ XDTermineBetreff.AsString+'<br>';
        end
        else
        begin
        terminstring:=formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineBeginn.Asdatetime)+' - '+formatdatetime('DDD DD.MM.YYYY hh:mm',XDTermineEnde.Asdatetime);
        terminstring:=terminstring+'<br>'+ XDTermineBetreff.AsString+'<br>';
        end;
        if XDTermineTerminNotiz.AsString<>'' then
        begin
          terminstring:=terminstring+XDTermineTerminNotiz.AsString+'<br>';
        end;
        terminstring:=terminstring+'<br>'+XDTermineKundennummer.AsString+' '+XDTermineNachname.AsString;
        terminstring:=terminstring+'<br>'+XDTermineKontaktart.AsString;
        myelement:=TC.cellelements[0,mytag+2];
        einzeltermin:=THTMLDiv.Create(self);
        einzeltermin.ElementID:=xdtermineuid.asstring;
        einzeltermin.HeightStyle:=ssauto;
        einzeltermin.Width:=350;
        einzeltermin.AlignWithMargins:=true;
        einzeltermin.HTML.Text:=terminstring;
        einzeltermin.ElementClassName:='card my-2 bg-primary';
        Einzeltermin.OnClick:=@EinzelterminClick;
        einzeltermin.ParentElement:=myelement;
      end;
      // Feiertag (5)
      if XDTermineTerminArt.AsInteger=5 then
      begin
        myelement:=TC.cellelements[0,atag+2];
        myelement.innerHTML:='';
        NeuerTermin:=THTMLDiv.Create(self);
        NeuerTermin.HeightStyle:=ssauto;
        NeuerTermin.WidthStyle:=ssauto;
        NeuerTermin.tag:=trunc(XDTermineBeginn.Asdatetime);
        NeuerTermin.ElementID:=inttostr(trunc(XDTermineBeginn.Asdatetime));
        NeuerTermin.ElementClassName:='text-danger';
        NeuerTermin.HTML.Text:='<b>'+formatdatetime('DDD DD.MM.YYYY',XDTermineBeginn.Asdatetime)+' '+XDTermineBetreff.AsString+'</b>';
        NeuerTermin.OnClick:=@NeuerTerminClick;
        NeuerTermin.ParentElement:=myelement;
      end;

    end;
      xdtermine.Next;
  end;
end;

procedure TTerminkalender.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  comboMonat := TComboBox.Create(Self);
  comboJahr := TComboBox.Create(Self);
  sourceTermine := TDataSource.Create(Self);
  XDTermine := TXDataWebDataSet.Create(Self);
  XDTermineArtikelnummer := TXDataWebEntityField.Create(Self);
  XDTermineUID := TStringField.Create(Self);
  XDTermineBeginn := TDateTimeField.Create(Self);
  XDTermineEnde := TDateTimeField.Create(Self);
  XDTermineBetreff := TStringField.Create(Self);
  XDTermineQuelle := TStringField.Create(Self);
  XDTermineGUIDOutlook := TStringField.Create(Self);
  XDTermineShape := TIntegerField.Create(Self);
  XDTermineColor := TIntegerField.Create(Self);
  XDTermineAlarm := TBooleanField.Create(Self);
  XDTermineResource := TIntegerField.Create(Self);
  XDTermineLastModificationTime := TDateTimeField.Create(Self);
  XDTermineGeburtstag := TBooleanField.Create(Self);
  XDTermineAlarmBefore := TIntegerField.Create(Self);
  XDTerminePersonalnummer := TIntegerField.Create(Self);
  XDTermineKundennummer := TStringField.Create(Self);
  XDTermineAllDayEventOutlook := TBooleanField.Create(Self);
  XDTermineTMSCaption := TBooleanField.Create(Self);
  XDTermineRECURRENCY := TStringField.Create(Self);
  XDTermineMINTIME := TDateTimeField.Create(Self);
  XDTermineMAXTIME := TDateTimeField.Create(Self);
  XDTermineNachname := TStringField.Create(Self);
  XDTermineObjektReferenz := TStringField.Create(Self);
  XDTermineObjektnummer := TStringField.Create(Self);
  XDTermineTerminArt := TIntegerField.Create(Self);
  XDTermineBeginnDatum := TDateTimeField.Create(Self);
  XDTermineBeginnUhrzeit := TDateTimeField.Create(Self);
  XDTermineEndeDatum := TDateTimeField.Create(Self);
  XDTermineEndeUhrzeit := TDateTimeField.Create(Self);
  XDTermineKalenderAnzeige := TBooleanField.Create(Self);
  XDTermineStundensatz := TFloatField.Create(Self);
  XDTermineDauerStunden := TFloatField.Create(Self);
  XDTerminegefahrenekm := TFloatField.Create(Self);
  XDTermineAufwand := TFloatField.Create(Self);
  XDTermineabgerechnet := TBooleanField.Create(Self);
  XDTermineverteilt := TDateTimeField.Create(Self);
  XDTermineTerminErledigt := TDateTimeField.Create(Self);
  XDTermineAnsprechpartner := TStringField.Create(Self);
  XDTermineLocation := TStringField.Create(Self);
  XDTermineGoogleID := TStringField.Create(Self);
  XDTermineGoogleCreatedUTC := TDateTimeField.Create(Self);
  XDTermineGoogleUpdatedUTC := TDateTimeField.Create(Self);
  XDTermineGoogleStatus := TStringField.Create(Self);
  XDTermineVorname := TStringField.Create(Self);
  XDTerminePNachname := TStringField.Create(Self);
  XDTerminePVorname := TStringField.Create(Self);
  XDTermineArtikelueberschrift := TStringField.Create(Self);
  XDTerminePFarbe := TIntegerField.Create(Self);
  XDTermineKontaktart := TStringField.Create(Self);
  XDTermineTerminNotiz := TStringField.Create(Self);
  XDTermineArtikelnummerArtikelnummer := TStringField.Create(Self);

  TC.BeforeLoadDFMValues;
  comboMonat.BeforeLoadDFMValues;
  comboJahr.BeforeLoadDFMValues;
  sourceTermine.BeforeLoadDFMValues;
  XDTermine.BeforeLoadDFMValues;
  XDTermineArtikelnummer.BeforeLoadDFMValues;
  XDTermineUID.BeforeLoadDFMValues;
  XDTermineBeginn.BeforeLoadDFMValues;
  XDTermineEnde.BeforeLoadDFMValues;
  XDTermineBetreff.BeforeLoadDFMValues;
  XDTermineQuelle.BeforeLoadDFMValues;
  XDTermineGUIDOutlook.BeforeLoadDFMValues;
  XDTermineShape.BeforeLoadDFMValues;
  XDTermineColor.BeforeLoadDFMValues;
  XDTermineAlarm.BeforeLoadDFMValues;
  XDTermineResource.BeforeLoadDFMValues;
  XDTermineLastModificationTime.BeforeLoadDFMValues;
  XDTermineGeburtstag.BeforeLoadDFMValues;
  XDTermineAlarmBefore.BeforeLoadDFMValues;
  XDTerminePersonalnummer.BeforeLoadDFMValues;
  XDTermineKundennummer.BeforeLoadDFMValues;
  XDTermineAllDayEventOutlook.BeforeLoadDFMValues;
  XDTermineTMSCaption.BeforeLoadDFMValues;
  XDTermineRECURRENCY.BeforeLoadDFMValues;
  XDTermineMINTIME.BeforeLoadDFMValues;
  XDTermineMAXTIME.BeforeLoadDFMValues;
  XDTermineNachname.BeforeLoadDFMValues;
  XDTermineObjektReferenz.BeforeLoadDFMValues;
  XDTermineObjektnummer.BeforeLoadDFMValues;
  XDTermineTerminArt.BeforeLoadDFMValues;
  XDTermineBeginnDatum.BeforeLoadDFMValues;
  XDTermineBeginnUhrzeit.BeforeLoadDFMValues;
  XDTermineEndeDatum.BeforeLoadDFMValues;
  XDTermineEndeUhrzeit.BeforeLoadDFMValues;
  XDTermineKalenderAnzeige.BeforeLoadDFMValues;
  XDTermineStundensatz.BeforeLoadDFMValues;
  XDTermineDauerStunden.BeforeLoadDFMValues;
  XDTerminegefahrenekm.BeforeLoadDFMValues;
  XDTermineAufwand.BeforeLoadDFMValues;
  XDTermineabgerechnet.BeforeLoadDFMValues;
  XDTermineverteilt.BeforeLoadDFMValues;
  XDTermineTerminErledigt.BeforeLoadDFMValues;
  XDTermineAnsprechpartner.BeforeLoadDFMValues;
  XDTermineLocation.BeforeLoadDFMValues;
  XDTermineGoogleID.BeforeLoadDFMValues;
  XDTermineGoogleCreatedUTC.BeforeLoadDFMValues;
  XDTermineGoogleUpdatedUTC.BeforeLoadDFMValues;
  XDTermineGoogleStatus.BeforeLoadDFMValues;
  XDTermineVorname.BeforeLoadDFMValues;
  XDTerminePNachname.BeforeLoadDFMValues;
  XDTerminePVorname.BeforeLoadDFMValues;
  XDTermineArtikelueberschrift.BeforeLoadDFMValues;
  XDTerminePFarbe.BeforeLoadDFMValues;
  XDTermineKontaktart.BeforeLoadDFMValues;
  XDTermineTerminNotiz.BeforeLoadDFMValues;
  XDTermineArtikelnummerArtikelnummer.BeforeLoadDFMValues;
  try
    Name := 'Terminkalender';
    Width := 220;
    Height := 247;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 220;
    TC.Height := 247;
    TC.ElementClassName := 'table table-hover table-bordered';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 5;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    comboMonat.SetParentComponent(Self);
    comboMonat.Name := 'comboMonat';
    comboMonat.Left := 40;
    comboMonat.Top := 104;
    comboMonat.Width := 145;
    comboMonat.Height := 21;
    comboMonat.ElementClassName := 'custom-select';
    comboMonat.ElementFont := efCSS;
    comboMonat.HeightStyle := ssAuto;
    comboMonat.Text := 'Ganzes Jahr';
    SetEvent(comboMonat, Self, 'OnChange', 'comboMonatChange');
    comboMonat.ItemIndex := -1;
    comboMonat.Items.BeginUpdate;
    try
      comboMonat.Items.Clear;
      comboMonat.Items.Add('Januar');
      comboMonat.Items.Add('Februar');
      comboMonat.Items.Add('M'#228'rz');
      comboMonat.Items.Add('April');
      comboMonat.Items.Add('Mai');
      comboMonat.Items.Add('Juni');
      comboMonat.Items.Add('Juli');
      comboMonat.Items.Add('August');
      comboMonat.Items.Add('September');
      comboMonat.Items.Add('Oktober');
      comboMonat.Items.Add('November');
      comboMonat.Items.Add('Dezember');
    finally
      comboMonat.Items.EndUpdate;
    end;
    comboJahr.SetParentComponent(Self);
    comboJahr.Name := 'comboJahr';
    comboJahr.Left := 39;
    comboJahr.Top := 131;
    comboJahr.Width := 145;
    comboJahr.Height := 21;
    comboJahr.ElementClassName := 'custom-select';
    comboJahr.ElementFont := efCSS;
    comboJahr.HeightStyle := ssAuto;
    comboJahr.Text := '2020';
    SetEvent(comboJahr, Self, 'OnChange', 'comboJahrChange');
    comboJahr.ItemIndex := 5;
    comboJahr.Items.BeginUpdate;
    try
      comboJahr.Items.Clear;
      comboJahr.Items.Add('2015');
      comboJahr.Items.Add('2016');
      comboJahr.Items.Add('2017');
      comboJahr.Items.Add('2018');
      comboJahr.Items.Add('2019');
      comboJahr.Items.Add('2020');
      comboJahr.Items.Add('2021');
      comboJahr.Items.Add('2022');
    finally
      comboJahr.Items.EndUpdate;
    end;
    sourceTermine.SetParentComponent(Self);
    sourceTermine.Name := 'sourceTermine';
    sourceTermine.DataSet := XDTermine;
    sourceTermine.Left := 52;
    sourceTermine.Top := 16;
    XDTermine.SetParentComponent(Self);
    XDTermine.Name := 'XDTermine';
    XDTermine.AfterOpen := XDTermineAfterOpen;
    XDTermine.EntitySetName := 'TermineNeu';
    XDTermine.Connection := DM2.XDataWebConnection1;
    XDTermine.SubpropsDepth := 1;
    XDTermine.QueryString := '$Filter=(Year(Beginn) eq 2019) &$orderby=Beginn';
    XDTermine.Left := 124;
    XDTermine.Top := 14;
    XDTermineArtikelnummer.SetParentComponent(XDTermine);
    XDTermineArtikelnummer.Name := 'XDTermineArtikelnummer';
    XDTermineArtikelnummer.FieldName := 'Artikelnummer';
    XDTermineUID.SetParentComponent(XDTermine);
    XDTermineUID.Name := 'XDTermineUID';
    XDTermineUID.FieldName := 'UID';
    XDTermineUID.Required := True;
    XDTermineUID.Size := 38;
    XDTermineBeginn.SetParentComponent(XDTermine);
    XDTermineBeginn.Name := 'XDTermineBeginn';
    XDTermineBeginn.FieldName := 'Beginn';
    XDTermineEnde.SetParentComponent(XDTermine);
    XDTermineEnde.Name := 'XDTermineEnde';
    XDTermineEnde.FieldName := 'Ende';
    XDTermineBetreff.SetParentComponent(XDTermine);
    XDTermineBetreff.Name := 'XDTermineBetreff';
    XDTermineBetreff.FieldName := 'Betreff';
    XDTermineBetreff.Size := 255;
    XDTermineQuelle.SetParentComponent(XDTermine);
    XDTermineQuelle.Name := 'XDTermineQuelle';
    XDTermineQuelle.FieldName := 'Quelle';
    XDTermineQuelle.Size := 10;
    XDTermineGUIDOutlook.SetParentComponent(XDTermine);
    XDTermineGUIDOutlook.Name := 'XDTermineGUIDOutlook';
    XDTermineGUIDOutlook.FieldName := 'GUIDOutlook';
    XDTermineGUIDOutlook.Size := 250;
    XDTermineShape.SetParentComponent(XDTermine);
    XDTermineShape.Name := 'XDTermineShape';
    XDTermineShape.FieldName := 'Shape';
    XDTermineColor.SetParentComponent(XDTermine);
    XDTermineColor.Name := 'XDTermineColor';
    XDTermineColor.FieldName := 'Color';
    XDTermineAlarm.SetParentComponent(XDTermine);
    XDTermineAlarm.Name := 'XDTermineAlarm';
    XDTermineAlarm.FieldName := 'Alarm';
    XDTermineResource.SetParentComponent(XDTermine);
    XDTermineResource.Name := 'XDTermineResource';
    XDTermineResource.FieldName := 'Resource';
    XDTermineLastModificationTime.SetParentComponent(XDTermine);
    XDTermineLastModificationTime.Name := 'XDTermineLastModificationTime';
    XDTermineLastModificationTime.FieldName := 'LastModificationTime';
    XDTermineGeburtstag.SetParentComponent(XDTermine);
    XDTermineGeburtstag.Name := 'XDTermineGeburtstag';
    XDTermineGeburtstag.FieldName := 'Geburtstag';
    XDTermineAlarmBefore.SetParentComponent(XDTermine);
    XDTermineAlarmBefore.Name := 'XDTermineAlarmBefore';
    XDTermineAlarmBefore.FieldName := 'AlarmBefore';
    XDTerminePersonalnummer.SetParentComponent(XDTermine);
    XDTerminePersonalnummer.Name := 'XDTerminePersonalnummer';
    XDTerminePersonalnummer.FieldName := 'Personalnummer';
    XDTermineKundennummer.SetParentComponent(XDTermine);
    XDTermineKundennummer.Name := 'XDTermineKundennummer';
    XDTermineKundennummer.FieldName := 'Kundennummer';
    XDTermineKundennummer.Size := 13;
    XDTermineAllDayEventOutlook.SetParentComponent(XDTermine);
    XDTermineAllDayEventOutlook.Name := 'XDTermineAllDayEventOutlook';
    XDTermineAllDayEventOutlook.FieldName := 'AllDayEventOutlook';
    XDTermineTMSCaption.SetParentComponent(XDTermine);
    XDTermineTMSCaption.Name := 'XDTermineTMSCaption';
    XDTermineTMSCaption.FieldName := 'TMSCaption';
    XDTermineRECURRENCY.SetParentComponent(XDTermine);
    XDTermineRECURRENCY.Name := 'XDTermineRECURRENCY';
    XDTermineRECURRENCY.FieldName := 'RECURRENCY';
    XDTermineRECURRENCY.Size := 255;
    XDTermineMINTIME.SetParentComponent(XDTermine);
    XDTermineMINTIME.Name := 'XDTermineMINTIME';
    XDTermineMINTIME.FieldName := 'MINTIME';
    XDTermineMAXTIME.SetParentComponent(XDTermine);
    XDTermineMAXTIME.Name := 'XDTermineMAXTIME';
    XDTermineMAXTIME.FieldName := 'MAXTIME';
    XDTermineNachname.SetParentComponent(XDTermine);
    XDTermineNachname.Name := 'XDTermineNachname';
    XDTermineNachname.FieldName := 'Nachname';
    XDTermineNachname.Size := 40;
    XDTermineObjektReferenz.SetParentComponent(XDTermine);
    XDTermineObjektReferenz.Name := 'XDTermineObjektReferenz';
    XDTermineObjektReferenz.FieldName := 'ObjektReferenz';
    XDTermineObjektReferenz.Size := 13;
    XDTermineObjektnummer.SetParentComponent(XDTermine);
    XDTermineObjektnummer.Name := 'XDTermineObjektnummer';
    XDTermineObjektnummer.FieldName := 'Objektnummer';
    XDTermineObjektnummer.Size := 25;
    XDTermineTerminArt.SetParentComponent(XDTermine);
    XDTermineTerminArt.Name := 'XDTermineTerminArt';
    XDTermineTerminArt.FieldName := 'TerminArt';
    XDTermineBeginnDatum.SetParentComponent(XDTermine);
    XDTermineBeginnDatum.Name := 'XDTermineBeginnDatum';
    XDTermineBeginnDatum.FieldName := 'BeginnDatum';
    XDTermineBeginnUhrzeit.SetParentComponent(XDTermine);
    XDTermineBeginnUhrzeit.Name := 'XDTermineBeginnUhrzeit';
    XDTermineBeginnUhrzeit.FieldName := 'BeginnUhrzeit';
    XDTermineEndeDatum.SetParentComponent(XDTermine);
    XDTermineEndeDatum.Name := 'XDTermineEndeDatum';
    XDTermineEndeDatum.FieldName := 'EndeDatum';
    XDTermineEndeUhrzeit.SetParentComponent(XDTermine);
    XDTermineEndeUhrzeit.Name := 'XDTermineEndeUhrzeit';
    XDTermineEndeUhrzeit.FieldName := 'EndeUhrzeit';
    XDTermineKalenderAnzeige.SetParentComponent(XDTermine);
    XDTermineKalenderAnzeige.Name := 'XDTermineKalenderAnzeige';
    XDTermineKalenderAnzeige.FieldName := 'KalenderAnzeige';
    XDTermineStundensatz.SetParentComponent(XDTermine);
    XDTermineStundensatz.Name := 'XDTermineStundensatz';
    XDTermineStundensatz.FieldName := 'Stundensatz';
    XDTermineDauerStunden.SetParentComponent(XDTermine);
    XDTermineDauerStunden.Name := 'XDTermineDauerStunden';
    XDTermineDauerStunden.FieldName := 'DauerStunden';
    XDTerminegefahrenekm.SetParentComponent(XDTermine);
    XDTerminegefahrenekm.Name := 'XDTerminegefahrenekm';
    XDTerminegefahrenekm.FieldName := 'gefahrenekm';
    XDTermineAufwand.SetParentComponent(XDTermine);
    XDTermineAufwand.Name := 'XDTermineAufwand';
    XDTermineAufwand.FieldName := 'Aufwand';
    XDTermineabgerechnet.SetParentComponent(XDTermine);
    XDTermineabgerechnet.Name := 'XDTermineabgerechnet';
    XDTermineabgerechnet.FieldName := 'abgerechnet';
    XDTermineverteilt.SetParentComponent(XDTermine);
    XDTermineverteilt.Name := 'XDTermineverteilt';
    XDTermineverteilt.FieldName := 'verteilt';
    XDTermineTerminErledigt.SetParentComponent(XDTermine);
    XDTermineTerminErledigt.Name := 'XDTermineTerminErledigt';
    XDTermineTerminErledigt.FieldName := 'TerminErledigt';
    XDTermineAnsprechpartner.SetParentComponent(XDTermine);
    XDTermineAnsprechpartner.Name := 'XDTermineAnsprechpartner';
    XDTermineAnsprechpartner.FieldName := 'Ansprechpartner';
    XDTermineAnsprechpartner.Size := 50;
    XDTermineLocation.SetParentComponent(XDTermine);
    XDTermineLocation.Name := 'XDTermineLocation';
    XDTermineLocation.FieldName := 'Location';
    XDTermineLocation.Size := 100;
    XDTermineGoogleID.SetParentComponent(XDTermine);
    XDTermineGoogleID.Name := 'XDTermineGoogleID';
    XDTermineGoogleID.FieldName := 'GoogleID';
    XDTermineGoogleID.Size := 50;
    XDTermineGoogleCreatedUTC.SetParentComponent(XDTermine);
    XDTermineGoogleCreatedUTC.Name := 'XDTermineGoogleCreatedUTC';
    XDTermineGoogleCreatedUTC.FieldName := 'GoogleCreatedUTC';
    XDTermineGoogleUpdatedUTC.SetParentComponent(XDTermine);
    XDTermineGoogleUpdatedUTC.Name := 'XDTermineGoogleUpdatedUTC';
    XDTermineGoogleUpdatedUTC.FieldName := 'GoogleUpdatedUTC';
    XDTermineGoogleStatus.SetParentComponent(XDTermine);
    XDTermineGoogleStatus.Name := 'XDTermineGoogleStatus';
    XDTermineGoogleStatus.FieldName := 'GoogleStatus';
    XDTermineGoogleStatus.Size := 50;
    XDTermineVorname.SetParentComponent(XDTermine);
    XDTermineVorname.Name := 'XDTermineVorname';
    XDTermineVorname.FieldName := 'Vorname';
    XDTermineVorname.Size := 40;
    XDTerminePNachname.SetParentComponent(XDTermine);
    XDTerminePNachname.Name := 'XDTerminePNachname';
    XDTerminePNachname.FieldName := 'PNachname';
    XDTerminePNachname.Size := 40;
    XDTerminePVorname.SetParentComponent(XDTermine);
    XDTerminePVorname.Name := 'XDTerminePVorname';
    XDTerminePVorname.FieldName := 'PVorname';
    XDTerminePVorname.Size := 40;
    XDTermineArtikelueberschrift.SetParentComponent(XDTermine);
    XDTermineArtikelueberschrift.Name := 'XDTermineArtikelueberschrift';
    XDTermineArtikelueberschrift.FieldName := 'Artikelueberschrift';
    XDTermineArtikelueberschrift.Size := 50;
    XDTerminePFarbe.SetParentComponent(XDTermine);
    XDTerminePFarbe.Name := 'XDTerminePFarbe';
    XDTerminePFarbe.FieldName := 'PFarbe';
    XDTermineKontaktart.SetParentComponent(XDTermine);
    XDTermineKontaktart.Name := 'XDTermineKontaktart';
    XDTermineKontaktart.FieldName := 'Kontaktart';
    XDTermineKontaktart.Size := 25;
    XDTermineTerminNotiz.SetParentComponent(XDTermine);
    XDTermineTerminNotiz.Name := 'XDTermineTerminNotiz';
    XDTermineTerminNotiz.FieldName := 'TerminNotiz';
    XDTermineTerminNotiz.Size := 255;
    XDTermineArtikelnummerArtikelnummer.SetParentComponent(XDTermine);
    XDTermineArtikelnummerArtikelnummer.Name := 'XDTermineArtikelnummerArtikelnummer';
    XDTermineArtikelnummerArtikelnummer.FieldName := 'Artikelnummer.Artikelnummer';
    XDTermineArtikelnummerArtikelnummer.Size := 25;
  finally
    TC.AfterLoadDFMValues;
    comboMonat.AfterLoadDFMValues;
    comboJahr.AfterLoadDFMValues;
    sourceTermine.AfterLoadDFMValues;
    XDTermine.AfterLoadDFMValues;
    XDTermineArtikelnummer.AfterLoadDFMValues;
    XDTermineUID.AfterLoadDFMValues;
    XDTermineBeginn.AfterLoadDFMValues;
    XDTermineEnde.AfterLoadDFMValues;
    XDTermineBetreff.AfterLoadDFMValues;
    XDTermineQuelle.AfterLoadDFMValues;
    XDTermineGUIDOutlook.AfterLoadDFMValues;
    XDTermineShape.AfterLoadDFMValues;
    XDTermineColor.AfterLoadDFMValues;
    XDTermineAlarm.AfterLoadDFMValues;
    XDTermineResource.AfterLoadDFMValues;
    XDTermineLastModificationTime.AfterLoadDFMValues;
    XDTermineGeburtstag.AfterLoadDFMValues;
    XDTermineAlarmBefore.AfterLoadDFMValues;
    XDTerminePersonalnummer.AfterLoadDFMValues;
    XDTermineKundennummer.AfterLoadDFMValues;
    XDTermineAllDayEventOutlook.AfterLoadDFMValues;
    XDTermineTMSCaption.AfterLoadDFMValues;
    XDTermineRECURRENCY.AfterLoadDFMValues;
    XDTermineMINTIME.AfterLoadDFMValues;
    XDTermineMAXTIME.AfterLoadDFMValues;
    XDTermineNachname.AfterLoadDFMValues;
    XDTermineObjektReferenz.AfterLoadDFMValues;
    XDTermineObjektnummer.AfterLoadDFMValues;
    XDTermineTerminArt.AfterLoadDFMValues;
    XDTermineBeginnDatum.AfterLoadDFMValues;
    XDTermineBeginnUhrzeit.AfterLoadDFMValues;
    XDTermineEndeDatum.AfterLoadDFMValues;
    XDTermineEndeUhrzeit.AfterLoadDFMValues;
    XDTermineKalenderAnzeige.AfterLoadDFMValues;
    XDTermineStundensatz.AfterLoadDFMValues;
    XDTermineDauerStunden.AfterLoadDFMValues;
    XDTerminegefahrenekm.AfterLoadDFMValues;
    XDTermineAufwand.AfterLoadDFMValues;
    XDTermineabgerechnet.AfterLoadDFMValues;
    XDTermineverteilt.AfterLoadDFMValues;
    XDTermineTerminErledigt.AfterLoadDFMValues;
    XDTermineAnsprechpartner.AfterLoadDFMValues;
    XDTermineLocation.AfterLoadDFMValues;
    XDTermineGoogleID.AfterLoadDFMValues;
    XDTermineGoogleCreatedUTC.AfterLoadDFMValues;
    XDTermineGoogleUpdatedUTC.AfterLoadDFMValues;
    XDTermineGoogleStatus.AfterLoadDFMValues;
    XDTermineVorname.AfterLoadDFMValues;
    XDTerminePNachname.AfterLoadDFMValues;
    XDTerminePVorname.AfterLoadDFMValues;
    XDTermineArtikelueberschrift.AfterLoadDFMValues;
    XDTerminePFarbe.AfterLoadDFMValues;
    XDTermineKontaktart.AfterLoadDFMValues;
    XDTermineTerminNotiz.AfterLoadDFMValues;
    XDTermineArtikelnummerArtikelnummer.AfterLoadDFMValues;
  end;
end;

end.
