unit Unit1;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.ComCtrls,
  strutils, WEBLib.StdCtrls, dateutils,
  WEBLib.Login, DB, WEBLib.json, WEBLib.Storage,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Buttons,
  WEBLib.DB, WEBLib.WebCtrls, WEBLib.DBCtrls, WEBLib.REST, XData.Web.Client,
  XData.Web.Connection, WEBLib.Menus;


type
  TForm1 = class(TForm)
    XDPersonal: TXDataWebDataSet;
    XDPersonalPersonalnummer: TIntegerField;
    XDPersonalNachname: TStringField;
    XDPersonalVorname: TStringField;
    XDPersonalSchreibzeichen: TStringField;
    XDPersonalLoginName: TStringField;
    XDPersonalPassword: TStringField;
    XDPersonalEnabled: TBooleanField;
    XDPersonalEmail: TStringField;
    XDPersonalFarbe: TIntegerField;
    lblAnmeldung: TLabel;
    WebPanelMenue: TPanel;
    panelAnmeldung: TPanel;
    WebLabel3: TLabel;
    edtBenutzer: TEdit;
    edtPassword: TEdit;
    btnAnmelden: TButton;
    labelonlinestatus: TLabel;
    WebDBLabel1: TDBLabel;
    XDPersonalPersonalGUID: TStringField;
    WebHttpRequest1: THttpRequest;
    WClient: TXDataWebClient;
    AuthConnection: TXDataWebConnection;
    XDPersonalRechteCadiaWEB: TStringField;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    lblchecklogin: TLabel;
    btnLogout: TButton;
    btnKontakte: TButton;
    btnArtikel: TButton;
    btnAngebote: TButton;
    btnAuftrag: TButton;
    btnLieferscheine: TButton;
    btnTermine: TButton;
    btnEmails: TButton;
    btnBelegePositionen: TButton;
    btnAnrufe: TButton;
    btnPZE: TButton;
    procedure setcontrols;
    procedure XDPersonalAfterOpen(DataSet: TDataSet);
    procedure btnLogoutClick(Sender: TObject);
    procedure btnTermineClick(Sender: TObject);
    procedure btnArtikelClick(Sender: TObject);
    procedure btnAnrufeClick(Sender: TObject);
    procedure btnKontakteClick(Sender: TObject);
    procedure btnAuftragClick(Sender: TObject);
    procedure btnAnmeldenClick(Sender: TObject);
    procedure edtBenutzerKeyPress(Sender: TObject; var Key: Char);
    procedure edtPasswordKeyPress(Sender: TObject; var Key: Char);
    procedure WebHttpRequest1Response(Sender: TObject; AResponse: string);
    procedure AuthConnectionConnect(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure btnAngeboteClick(Sender: TObject);
    procedure btnPZEClick(Sender: TObject);
    procedure btnEmailsClick(Sender: TObject);
    procedure btnBelegePositionenClick(Sender: TObject);
    procedure btnLieferscheineClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  protected
    procedure LoadDFMValues; override;



  public
    type
      TRechteCadiaWebR = Record
        Zugelassen:boolean;
        Kontakte:boolean;
        Artikel:boolean;
        OffeneAngebote:boolean;
        OffeneAuftraege:boolean;
        OffeneLieferscheine:boolean;
        Termine:boolean;
        Emails:boolean;
        BelegePositionen:boolean;
        Anrufe:boolean;
        Zeiterfassung:boolean;
        Einkaufspreise:boolean;
    End;
    var RCW:TRechteCadiaWebR;
    var RCWLIST:TStringList;
    procedure RCWLISTtoRECORD;
    procedure RCWleeren;
    procedure AppOnlineChange(Sender:TObject; AStatus: TOnlineStatus);
    procedure Tokenholen(Username, Password: string);
    function XorStr(const Text: string; Key: Integer): string;


  end;

const
  Versionslabel1='©2020 Langwald-EDV-Beratung ';
  Versionslabel2='Version 3.00 vom ';
  Versionslabel3='30.10.2020';
  Versionslabel4=' <a href="https://www.cadia.de" target="_blank">(cadia-Webseite)</a>';
  CadiaAppKey='991783241';
var
  Form1: TForm1;
  Versionslabel:string;
  BenutzerAngemeldet:Boolean;
  BenutzerLoginName:string;
  BenutzerPasswort:string;
  BenutzerPersonalnummer:string;
  BenutzerNachname:string;
  BenutzerVorname:string;
  BenutzerSchreibzeichen:string;
  BenutzerEmail:string;
  BenutzerFarbe:integer;
  BenutzerID:string;
  TextAbsenderzeile:string;
  xdataDATASERVER:string;
  xdataconnectionstring:string;
  xdataauthconnection:string;
  downloadconnection:string;
  xdatausername:string;
  xdatapassword:string;
  Merkerkontaktnummer:string;
  MerkerArtikelnummer:string;
  LizenzOK:boolean;
  vtoken:string;
  ajahr, amonat, atag,astunde,aminute,asekunde,ams:word;

implementation
  uses unit2, unit_Kontaktsuche, unit_Artikelsuche, unit_Objektsuche, unit_Einzeltermin, unit_EinzelAuftrag, unit_Anrufe, unit_Artikel,
  unit_Terminkalender, unit_ListeAuftrag, unit_EinzelKontakt, unit_ListeAngebot, unit_PZE, unit_EmailEingang, unit_BelegeUndPositionen,
  unit_ListeLieferschein;

function TForm1.XorStr(const Text: string; Key: Integer): string;
var
  i: Integer;
begin
  SetLength(Result, Length(Text));
  for i:=1 to Length(Text) do
  begin
    if Text[i] > #31 then
      Result[i] := Chr(Ord(Text[i]) xor Key)
    else
      Result[i] := Text[i];
  end;
end;

procedure TForm1.AppOnlineChange(Sender:TObject; AStatus: TOnlineStatus);
begin
  if (Application.IsOnline) then labelonlinestatus.Caption:='online' else labelonlinestatus.Caption:='offline';
end;

procedure TForm1.Tokenholen(Username, Password: string);
  procedure OnResponse(Response:TXDataClientResponse);
  begin
    vtoken:=string(TJSObject(response.Result)['value']);
    if xdataconnectionstring<>'' then
    begin
      dm2.XDataWebConnection1.url:=xdataconnectionstring;
      dm2.xdatawebconnection1.Connected:=true;
    end;
  end;
begin
  labelonlinestatus.Caption:='hole token';
  WClient.RawInvoke('ILoginService.Login',[Username,Password],@OnResponse);
end;




procedure TForm1.AuthConnectionConnect(Sender: TObject);
begin
  Tokenholen(xdatausername,xdatapassword);
end;

procedure TForm1.btnAngeboteClick(Sender: TObject);
var
  ListeAngebot:TListeAngebot;
begin
  webpanelmenue.Visible:=false;


  ListeAngebot := TListeAngebot.CreateNew(procedure(AForm: TObject)
    begin

    end
    );

  ListeAngebot.ShowModal(procedure(AValue: TModalResult)
    begin
      webpanelmenue.Visible:=true;
    end
    );
end;

procedure TForm1.btnAnmeldenClick(Sender: TObject);
begin
  lblchecklogin.Caption:='';
  if edtbenutzer.text='' then
  begin
    lblchecklogin.Caption:='Sie müssen Ihren Benutzernamen eingeben';
    edtbenutzer.SetFocus;
    exit;
  end;
  if edtPassword.text='' then
  begin
    lblchecklogin.Caption:='Sie müssen Ihr Passwort eingeben';
    edtpassword.SetFocus;
    exit;
  end;
  if edtbenutzer.text='superuser' then
  begin
    lblchecklogin.Caption:='Diesen Benutzer dürfen Sie im WebPortal nicht verwenden';
    edtbenutzer.SetFocus;
    exit;
  end;
  BenutzerLoginName:=edtbenutzer.text;
  BenutzerPasswort:=edtpassword.text;
  xdpersonal.Close;
  xdpersonal.QueryString:='$filter=LoginName eq '''+BenutzerLoginName+'''';
  XDPersonal.Load;
end;

procedure TForm1.btnAnrufeClick(Sender: TObject);
var
  newform:TfrmAnrufe;
begin
  newform := TfrmAnrufe.CreateNew;
end;

procedure TForm1.btnArtikelClick(Sender: TObject);
var
  LFrm:TfrmArtikelsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmArtikelsuche;
    begin
      LForm:=(AForm as TfrmArtikelsuche);
      lform.weiterzueinzelartikel:=true;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //if LFrm.Artikelnummer<>'' then showeinzelartikel(Lfrm.Artikelnummer);
    end;

  begin
  LFrm:=TfrmArtikelsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TForm1.btnAuftragClick(Sender: TObject);
var
  ListeAuftrag:TListeAuftrag;
begin
  webpanelmenue.Visible:=false;


ListeAuftrag := TListeAuftrag.CreateNew(procedure(AForm: TObject)
    begin

    end
    );

  ListeAuftrag.ShowModal(procedure(AValue: TModalResult)
    begin
      webpanelmenue.Visible:=true;
    end
    );


end;

procedure TForm1.btnBelegePositionenClick(Sender: TObject);
var
  LFrm:TBelegePositionen;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TBelegePositionen;
    begin
      LForm:=(AForm as TBelegePositionen);
      LForm.BPKundennummer:='';
      LForm.BPName:='';
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //MerkerKontaktnummer:=lfrm.Kontaktnummer;
      //edtkontaktnummer.Text:=lfrm.Kontaktnummer;
      //edtkontaktnummerChange(nil);
      //noch implementieren
    end;
  begin
    LFrm:=TBelegePositionen.CreateNew(@AfterCreate);
    LFrm.ShowModal(@AfterShowmodal);
end;

procedure TForm1.btnEmailsClick(Sender: TObject);
var
  newform:TEmailEingang;
begin
  newform := TEmailEingang.CreateNew;
end;

procedure TForm1.btnKontakteClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
      lform.weiterzuEinzelkontakt:=true;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Kontaktnummer<>'' then
      begin
       //showeinzelkontakt(Lfrm.Kontaktnummer);
      end;
    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);
end;

procedure TForm1.btnLieferscheineClick(Sender: TObject);
var
  newform:TListeLieferschein;
begin
  newform := TListeLieferschein.CreateNew;
end;

procedure TForm1.btnLogoutClick(Sender: TObject);
begin
  btnlogout.Visible:=false;
  dm2.XDataWebConnection1.Connected:=false;
  lblchecklogin.Caption:='';
  BenutzerAngemeldet:=false;
  BenutzerLoginName:='';
  BenutzerPasswort:='';
  BenutzerPersonalnummer:='';
  BenutzerNachname:='';
  BenutzerVorname:='';
  BenutzerSchreibzeichen:='';
  BenutzerEmail:='';
  SetControls;

end;

procedure TForm1.btnPZEClick(Sender: TObject);
var
  newform:TPZE;
begin
  newform := TPZE.CreateNew;
end;

procedure TForm1.btnTermineClick(Sender: TObject);
var
  Terminkalender:TTerminkalender;
begin
  webpanelmenue.Visible:=false;
  Terminkalender := TTerminkalender.CreateNew(procedure(AForm: TObject)
    begin

    end
    );

  Terminkalender.ShowModal(procedure(AValue: TModalResult)
    begin
      webpanelmenue.Visible:=true;
    end
    );
end;

procedure TForm1.edtBenutzerKeyPress(Sender: TObject; var Key: Char);
begin
if key=#13 then edtPassword.SetFocus;

end;

procedure TForm1.edtPasswordKeyPress(Sender: TObject; var Key: Char);
begin
if key=#13 then btnAnmeldenClick(sender);

end;

procedure TForm1.setcontrols;
var
  mylocalstorage:TLocalStorage;
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h4>CadiaWEB</h4><small>'+Versionslabel+'</small></nav>';

  myelement:=Container1.ElementHandle;
  lblAnmeldung.ParentElement:=myelement;
  labelonlinestatus.ParentElement:=myelement;
  WebDBLabel1.ParentElement:=myelement;
  container2.ParentElement:=myelement;
  myelement:=container2.ElementHandle;
  panelanmeldung.ParentElement:=myelement;
  WebPanelMenue.ParentElement:=myelement;


  if BenutzerAngemeldet then
  begin
    btnLogout.Visible:=true;
    lblAnmeldung.Caption:='User: '+BenutzerPersonalnummer+' | '+BenutzerLoginName+' | '+BenutzerNachname+', '+BenutzerVorname;
    WebPanelMenue.Visible:=true;
    panelAnmeldung.Visible:=false;
    btnkontakte.enabled:=rcw.Kontakte;
    btnartikel.enabled:=rcw.Artikel;
    btnangebote.enabled:=rcw.OffeneAngebote;
    btnauftrag.enabled:=rcw.offeneAuftraege;
    btnlieferscheine.enabled:=rcw.offeneLieferscheine;
    btntermine.enabled:=rcw.termine;
    btnemails.enabled:=rcw.emails;
    btnbelegepositionen.enabled:=rcw.belegepositionen;
    btnAnrufe.enabled:=rcw.Anrufe;
    btnPZE.enabled:=rcw.Zeiterfassung;
  end
  else
  begin
    btnLogout.Visible:=false;
    lblanmeldung.Caption:='Sie sind nicht angemeldet';
    WebPanelMenue.Visible:=false;
    panelanmeldung.Visible:=true;
    mylocalstorage:=Tlocalstorage.Create;
    edtBenutzer.text:=mylocalstorage.GetValue('CadiaWEBBenutzer');
    mylocalstorage.Free;
    edtbenutzer.SetFocus;
    if edtbenutzer.Text<>'' then edtPassword.SetFocus;
  end;
end;

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  RCWLIST:=TStringList.Create;
  RCWLIST.NameValueSeparator:='|';
end;

procedure TForm1.WebFormShow(Sender: TObject);
begin
  application.OnOnlineChange:=AppOnlineChange;
  Versionslabel:=Versionslabel1+Versionslabel2+Versionslabel3+Versionslabel4;
  lblchecklogin.Caption:='';
  setcontrols;
  LizenzOK:=false;
  WebHttpRequest1.Execute;
end;

procedure TForm1.WebHttpRequest1Response(Sender: TObject; AResponse: string);
var
  RStringList:TStringList;
  s:string;
begin
  if aresponse='' then
  begin
    showmessage('Die Konfigurationsdatei ist nicht vorhanden oder fehlerhaft');
    exit;
  end;
  if aresponse<>'' then
  begin
    RStringList:=TStringList.Create;
    RStringList.Text:=aresponse;
    s:= XorStr(RStringList.Strings[0],25);
    xdataDATASERVER:=trim(ReplaceStr(s,'DATASERVER=',''));
    if RightStr(xdataDATASERVER,1)<>'/' then xdataDATASERVER:=xdataDATASERVER+'/';
    xdataconnectionstring:=xdataDATASERVER+'cadiaserver/xdata';
    downloadconnection:=xdataDATASERVER+'cadiaserver/filedownload?FileName=';
    xdataauthconnection:=xdataDATASERVER+'cadiaserverauth';
    s:= XorStr(RStringList.Strings[1],25);
    xdatausername:=trim(ReplaceStr(s,'USERNAME=',''));
    s:= XorStr(RStringList.Strings[2],25);
    xdatapassword:=trim(ReplaceStr(s,'PASSWORD=',''));
    AuthConnection.Connected:=false;
    AuthConnection.URL:=xdataauthconnection;
    authconnection.Connected:=true;
  end;
end;

procedure TForm1.XDPersonalAfterOpen(DataSet: TDataSet);
var
  logbuchurl:string;
  mylocalstorage:TLocalStorage;
begin
  if xdpersonal.RecordCount<1 then
  begin
    lblchecklogin.Caption:='Anmeldung ist fehlgeschlagen, bitte versuchen Sie es erneut mit den richtigen Eingaben';
    xdpersonal.Close;
    exit;
  end;
  xdpersonal.First;

    if (XDPersonalPassword.AsString=BenutzerPasswort) AND (XDPersonalLoginName.AsString=BenutzerLoginName) then
    begin
      if XDPersonalEnabled.AsBoolean=true then
      begin
        BenutzerAngemeldet:=true;
        BenutzerPersonalnummer:=XDPersonalPersonalnummer.AsString;
        BenutzerLoginName:=XDPersonalLoginName.AsString;
        BenutzerNachname:=XDPersonalNachname.AsString;
        BenutzerVorname:=XDPersonalVorname.AsString;
        BenutzerSchreibzeichen:=XDPersonalSchreibzeichen.AsString;
        BenutzerEmail:=XDPersonalEmail.AsString;
        BenutzerFarbe:=XDPersonalFarbe.AsInteger;
        BenutzerID:=XDPersonalPersonalGUID.AsString;

        RCWlist.text:=xdpersonalrechtecadiaweb.asstring;
        RCWleeren;
        RCWLISTtoRECORD;
        mylocalstorage:=Tlocalstorage.Create;
        mylocalstorage.SetValue('CadiaWEBBenutzer',BenutzerLoginName);
        mylocalstorage.Free;
        lblchecklogin.Caption:='Anmeldung erfolgreich'+booltostr(rcw.kontakte);


        //(Kundennummer:string;AppKey:string;UserName:string;UserID:string;Absenderzeile:string;Versiondate:string):string;
        logbuchurl:='https://langwald-edv.net/cadiaautoserver/xdata/InformationService/cwlogbuch?Kundennummer='+dm2.tableSYSDATCadiaKundennummer.AsString+'&AppKey='+CadiaAppKey+'&UserName='+BenutzerNachname;
        logbuchurl:=logbuchurl+'&UserID='+BenutzerID;
        //logbuchurl:=logbuchurl+'&Absenderzeile='+Leftstr(dm2.tableSYSDATAbsenderzeile.asstring,70);
        if textabsenderzeile='' then TextAbsenderzeile:='unbekannt';

        logbuchurl:=logbuchurl+'&Absenderzeile='+TextAbsenderzeile;
        logbuchurl:=logbuchurl+'&Versiondate='+Versionslabel3;
        dm2.WebHttpRequestLogbuch.URL:=logbuchurl;

        dm2.WebHttpRequestLogbuch.Execute;
      end
      else
      begin
        lblchecklogin.Caption:='Dieser Benutzer ist nicht zugelassen';
      end;
    end
    else
    begin
      lblchecklogin.Caption:='Anmeldung ist fehlgeschlagen, bitte versuchen Sie es erneut mit den richtigen Eingaben';
    end;

  xdpersonal.Close;
  form1.SetControls;
end;


procedure TForm1.RCWLISTtoRECORD;
begin

  if RCWLIST.Values['Zugelassen']<>'' then RCW.Zugelassen:=strtobool(RCWLIST.Values['Zugelassen']) else RCW.Zugelassen:=TRUE;
  if RCWLIST.Values['Kontakte']<>'' then RCW.Kontakte:=strtobool(RCWLIST.Values['Kontakte']) else RCW.Kontakte:=TRUE;
  if RCWLIST.Values['Artikel']<>'' then RCW.Artikel:=strtobool(RCWLIST.Values['Artikel']) else RCW.Artikel:=TRUE;
  if RCWLIST.Values['OffeneAngebote']<>'' then RCW.OffeneAngebote:=strtobool(RCWLIST.Values['OffeneAngebote']) else RCW.OffeneAngebote:=TRUE;
  if RCWLIST.Values['OffeneAuftraege']<>'' then RCW.OffeneAuftraege:=strtobool(RCWLIST.Values['OffeneAuftraege']) else RCW.OffeneAuftraege:=TRUE;
  if RCWLIST.Values['OffeneLieferscheine']<>'' then RCW.OffeneLieferscheine:=strtobool(RCWLIST.Values['OffeneLieferscheine']) else RCW.OffeneLieferscheine:=TRUE;
  if RCWLIST.Values['Termine']<>'' then RCW.Termine:=strtobool(RCWLIST.Values['Termine']) else RCW.Termine:=TRUE;
  if RCWLIST.Values['Emails']<>'' then RCW.Emails:=strtobool(RCWLIST.Values['Emails']) else RCW.Emails:=TRUE;
  if RCWLIST.Values['BelegePositionen']<>'' then RCW.BelegePositionen:=strtobool(RCWLIST.Values['BelegePositionen']) else RCW.BelegePositionen:=TRUE;
  if RCWLIST.Values['Anrufe']<>'' then RCW.Anrufe:=strtobool(RCWLIST.Values['Anrufe']) else RCW.Anrufe:=TRUE;
  if RCWLIST.Values['Zeiterfassung']<>'' then RCW.Zeiterfassung:=strtobool(RCWLIST.Values['Zeiterfassung']) else RCW.Zeiterfassung:=TRUE;
  if RCWLIST.Values['Einkaufspreise']<>'' then RCW.Einkaufspreise:=strtobool(RCWLIST.Values['Einkaufspreise']) else RCW.Einkaufspreise:=TRUE;
end;

procedure TForm1.RCWleeren;
begin
  RCW.Zugelassen:=True;
  RCW.Kontakte:=True;
  RCW.Artikel:=True;
  RCW.OffeneAngebote:=True;
  RCW.OffeneAuftraege:=True;
  RCW.OffeneLieferscheine:=True;
  RCW.Termine:=True;
  RCW.Emails:=True;
  RCW.BelegePositionen:=True;
  RCW.Anrufe:=True;
  RCW.Zeiterfassung:=True;
  RCW.Einkaufspreise:=True;
end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblAnmeldung := TLabel.Create(Self);
  labelonlinestatus := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebPanelMenue := TPanel.Create(Self);
  btnLogout := TButton.Create(Self);
  btnKontakte := TButton.Create(Self);
  btnArtikel := TButton.Create(Self);
  btnAngebote := TButton.Create(Self);
  btnAuftrag := TButton.Create(Self);
  btnLieferscheine := TButton.Create(Self);
  btnTermine := TButton.Create(Self);
  btnEmails := TButton.Create(Self);
  btnBelegePositionen := TButton.Create(Self);
  btnAnrufe := TButton.Create(Self);
  btnPZE := TButton.Create(Self);
  panelAnmeldung := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  lblchecklogin := TLabel.Create(Self);
  edtBenutzer := TEdit.Create(Self);
  edtPassword := TEdit.Create(Self);
  btnAnmelden := TButton.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  XDPersonal := TXDataWebDataSet.Create(Self);
  XDPersonalPersonalnummer := TIntegerField.Create(Self);
  XDPersonalNachname := TStringField.Create(Self);
  XDPersonalVorname := TStringField.Create(Self);
  XDPersonalSchreibzeichen := TStringField.Create(Self);
  XDPersonalLoginName := TStringField.Create(Self);
  XDPersonalPassword := TStringField.Create(Self);
  XDPersonalEnabled := TBooleanField.Create(Self);
  XDPersonalEmail := TStringField.Create(Self);
  XDPersonalFarbe := TIntegerField.Create(Self);
  XDPersonalPersonalGUID := TStringField.Create(Self);
  XDPersonalRechteCadiaWEB := TStringField.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  WClient := TXDataWebClient.Create(Self);
  AuthConnection := TXDataWebConnection.Create(Self);

  lblAnmeldung.BeforeLoadDFMValues;
  labelonlinestatus.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebPanelMenue.BeforeLoadDFMValues;
  btnLogout.BeforeLoadDFMValues;
  btnKontakte.BeforeLoadDFMValues;
  btnArtikel.BeforeLoadDFMValues;
  btnAngebote.BeforeLoadDFMValues;
  btnAuftrag.BeforeLoadDFMValues;
  btnLieferscheine.BeforeLoadDFMValues;
  btnTermine.BeforeLoadDFMValues;
  btnEmails.BeforeLoadDFMValues;
  btnBelegePositionen.BeforeLoadDFMValues;
  btnAnrufe.BeforeLoadDFMValues;
  btnPZE.BeforeLoadDFMValues;
  panelAnmeldung.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  lblchecklogin.BeforeLoadDFMValues;
  edtBenutzer.BeforeLoadDFMValues;
  edtPassword.BeforeLoadDFMValues;
  btnAnmelden.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  XDPersonal.BeforeLoadDFMValues;
  XDPersonalPersonalnummer.BeforeLoadDFMValues;
  XDPersonalNachname.BeforeLoadDFMValues;
  XDPersonalVorname.BeforeLoadDFMValues;
  XDPersonalSchreibzeichen.BeforeLoadDFMValues;
  XDPersonalLoginName.BeforeLoadDFMValues;
  XDPersonalPassword.BeforeLoadDFMValues;
  XDPersonalEnabled.BeforeLoadDFMValues;
  XDPersonalEmail.BeforeLoadDFMValues;
  XDPersonalFarbe.BeforeLoadDFMValues;
  XDPersonalPersonalGUID.BeforeLoadDFMValues;
  XDPersonalRechteCadiaWEB.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  AuthConnection.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 264;
    Height := 536;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblAnmeldung.SetParentComponent(Self);
    lblAnmeldung.Name := 'lblAnmeldung';
    lblAnmeldung.Left := -1;
    lblAnmeldung.Top := 368;
    lblAnmeldung.Width := 73;
    lblAnmeldung.Height := 13;
    lblAnmeldung.Margins.Top := 0;
    lblAnmeldung.Margins.Bottom := 0;
    lblAnmeldung.Caption := 'lblAnmeldung';
    lblAnmeldung.ElementClassName := 'h6 m-2';
    lblAnmeldung.ElementFont := efCSS;
    labelonlinestatus.SetParentComponent(Self);
    labelonlinestatus.Name := 'labelonlinestatus';
    labelonlinestatus.Left := 0;
    labelonlinestatus.Top := 403;
    labelonlinestatus.Width := 89;
    labelonlinestatus.Height := 13;
    labelonlinestatus.Caption := 'labelonlinestatus';
    labelonlinestatus.ElementClassName := 'h6 small m-2';
    labelonlinestatus.ElementFont := efCSS;
    WebDBLabel1.SetParentComponent(Self);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 1;
    WebDBLabel1.Top := 422;
    WebDBLabel1.Width := 71;
    WebDBLabel1.Height := 13;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementClassName := 'h6 small m-2';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.DataField := 'CadiaKundennummer';
    WebDBLabel1.DataSource := DM2.sourceSysdat;
    WebPanelMenue.SetParentComponent(Self);
    WebPanelMenue.Name := 'WebPanelMenue';
    WebPanelMenue.Left := 0;
    WebPanelMenue.Top := 112;
    WebPanelMenue.Width := 257;
    WebPanelMenue.Height := 416;
    WebPanelMenue.ElementClassName := 'card m-1 border-info';
    WebPanelMenue.BorderColor := clSilver;
    WebPanelMenue.BorderStyle := bsSingle;
    WebPanelMenue.Caption := 'WebPanelMenue';
    WebPanelMenue.Color := 8340248;
    WebPanelMenue.ElementFont := efCSS;
    WebPanelMenue.ShowCaption := False;
    btnLogout.SetParentComponent(WebPanelMenue);
    btnLogout.Name := 'btnLogout';
    btnLogout.Left := 16;
    btnLogout.Top := 369;
    btnLogout.Width := 225;
    btnLogout.Height := 25;
    btnLogout.Caption := 'Abmelden';
    btnLogout.ChildOrder := 7;
    btnLogout.ElementClassName := 'btn btn-info btn-sm text-center';
    btnLogout.ElementFont := efCSS;
    btnLogout.HeightStyle := ssAuto;
    SetEvent(btnLogout, Self, 'OnClick', 'btnLogoutClick');
    btnKontakte.SetParentComponent(WebPanelMenue);
    btnKontakte.Name := 'btnKontakte';
    btnKontakte.Left := 16;
    btnKontakte.Top := 8;
    btnKontakte.Width := 225;
    btnKontakte.Height := 25;
    btnKontakte.Caption := 'Kontakte';
    btnKontakte.ChildOrder := 11;
    btnKontakte.ElementClassName := 'btn btn-info btn-sm text-center';
    btnKontakte.ElementFont := efCSS;
    btnKontakte.HeightStyle := ssAuto;
    SetEvent(btnKontakte, Self, 'OnClick', 'btnKontakteClick');
    btnArtikel.SetParentComponent(WebPanelMenue);
    btnArtikel.Name := 'btnArtikel';
    btnArtikel.Left := 16;
    btnArtikel.Top := 44;
    btnArtikel.Width := 225;
    btnArtikel.Height := 25;
    btnArtikel.Caption := 'Artikel';
    btnArtikel.ChildOrder := 11;
    btnArtikel.ElementClassName := 'btn btn-info btn-sm text-center';
    btnArtikel.ElementFont := efCSS;
    btnArtikel.HeightStyle := ssAuto;
    SetEvent(btnArtikel, Self, 'OnClick', 'btnArtikelClick');
    btnAngebote.SetParentComponent(WebPanelMenue);
    btnAngebote.Name := 'btnAngebote';
    btnAngebote.Left := 16;
    btnAngebote.Top := 80;
    btnAngebote.Width := 225;
    btnAngebote.Height := 25;
    btnAngebote.Caption := 'Angebote (offen)';
    btnAngebote.ChildOrder := 11;
    btnAngebote.ElementClassName := 'btn btn-info btn-sm text-center';
    btnAngebote.ElementFont := efCSS;
    btnAngebote.HeightStyle := ssAuto;
    SetEvent(btnAngebote, Self, 'OnClick', 'btnAngeboteClick');
    btnAuftrag.SetParentComponent(WebPanelMenue);
    btnAuftrag.Name := 'btnAuftrag';
    btnAuftrag.Left := 16;
    btnAuftrag.Top := 116;
    btnAuftrag.Width := 225;
    btnAuftrag.Height := 25;
    btnAuftrag.Caption := 'Auftr'#228'ge (offen)';
    btnAuftrag.ChildOrder := 11;
    btnAuftrag.ElementClassName := 'btn btn-info btn-sm text-center';
    btnAuftrag.ElementFont := efCSS;
    btnAuftrag.HeightStyle := ssAuto;
    SetEvent(btnAuftrag, Self, 'OnClick', 'btnAuftragClick');
    btnLieferscheine.SetParentComponent(WebPanelMenue);
    btnLieferscheine.Name := 'btnLieferscheine';
    btnLieferscheine.Left := 16;
    btnLieferscheine.Top := 152;
    btnLieferscheine.Width := 225;
    btnLieferscheine.Height := 25;
    btnLieferscheine.Caption := 'Lieferscheine (offen)';
    btnLieferscheine.ChildOrder := 11;
    btnLieferscheine.ElementClassName := 'btn btn-info btn-sm text-center';
    btnLieferscheine.ElementFont := efCSS;
    btnLieferscheine.HeightStyle := ssAuto;
    SetEvent(btnLieferscheine, Self, 'OnClick', 'btnLieferscheineClick');
    btnTermine.SetParentComponent(WebPanelMenue);
    btnTermine.Name := 'btnTermine';
    btnTermine.Left := 16;
    btnTermine.Top := 188;
    btnTermine.Width := 225;
    btnTermine.Height := 25;
    btnTermine.Caption := 'Termine';
    btnTermine.ChildOrder := 11;
    btnTermine.ElementClassName := 'btn btn-info btn-sm text-center';
    btnTermine.ElementFont := efCSS;
    btnTermine.HeightStyle := ssAuto;
    SetEvent(btnTermine, Self, 'OnClick', 'btnTermineClick');
    btnEmails.SetParentComponent(WebPanelMenue);
    btnEmails.Name := 'btnEmails';
    btnEmails.Left := 16;
    btnEmails.Top := 224;
    btnEmails.Width := 225;
    btnEmails.Height := 25;
    btnEmails.Caption := 'Emails';
    btnEmails.ChildOrder := 11;
    btnEmails.ElementClassName := 'btn btn-info btn-sm text-center';
    btnEmails.ElementFont := efCSS;
    btnEmails.HeightStyle := ssAuto;
    SetEvent(btnEmails, Self, 'OnClick', 'btnEmailsClick');
    btnBelegePositionen.SetParentComponent(WebPanelMenue);
    btnBelegePositionen.Name := 'btnBelegePositionen';
    btnBelegePositionen.Left := 16;
    btnBelegePositionen.Top := 260;
    btnBelegePositionen.Width := 225;
    btnBelegePositionen.Height := 25;
    btnBelegePositionen.Caption := 'Belege / Positionen';
    btnBelegePositionen.ChildOrder := 11;
    btnBelegePositionen.ElementClassName := 'btn btn-info btn-sm text-center';
    btnBelegePositionen.ElementFont := efCSS;
    btnBelegePositionen.HeightStyle := ssAuto;
    SetEvent(btnBelegePositionen, Self, 'OnClick', 'btnBelegePositionenClick');
    btnAnrufe.SetParentComponent(WebPanelMenue);
    btnAnrufe.Name := 'btnAnrufe';
    btnAnrufe.Left := 16;
    btnAnrufe.Top := 296;
    btnAnrufe.Width := 225;
    btnAnrufe.Height := 25;
    btnAnrufe.Caption := 'Anrufe';
    btnAnrufe.ChildOrder := 11;
    btnAnrufe.ElementClassName := 'btn btn-info btn-sm text-center';
    btnAnrufe.ElementFont := efCSS;
    btnAnrufe.HeightStyle := ssAuto;
    SetEvent(btnAnrufe, Self, 'OnClick', 'btnAnrufeClick');
    btnPZE.SetParentComponent(WebPanelMenue);
    btnPZE.Name := 'btnPZE';
    btnPZE.Left := 16;
    btnPZE.Top := 332;
    btnPZE.Width := 225;
    btnPZE.Height := 25;
    btnPZE.Caption := 'Zeiterfassung (PZE)';
    btnPZE.ChildOrder := 11;
    btnPZE.ElementClassName := 'btn btn-info btn-sm text-center';
    btnPZE.ElementFont := efCSS;
    btnPZE.HeightStyle := ssAuto;
    SetEvent(btnPZE, Self, 'OnClick', 'btnPZEClick');
    panelAnmeldung.SetParentComponent(Self);
    panelAnmeldung.Name := 'panelAnmeldung';
    panelAnmeldung.Left := 0;
    panelAnmeldung.Top := 99;
    panelAnmeldung.Width := 213;
    panelAnmeldung.Height := 238;
    panelAnmeldung.ElementClassName := 'card m-1 border-info';
    panelAnmeldung.BorderColor := clSilver;
    panelAnmeldung.BorderStyle := bsSingle;
    panelAnmeldung.Caption := 'panelAnmeldung';
    panelAnmeldung.Color := 8340248;
    panelAnmeldung.ElementFont := efCSS;
    panelAnmeldung.ShowCaption := False;
    WebLabel3.SetParentComponent(panelAnmeldung);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 13;
    WebLabel3.Width := 60;
    WebLabel3.Height := 13;
    WebLabel3.Caption := 'Anmeldung';
    WebLabel3.ElementClassName := 'h3 text-info';
    WebLabel3.ElementFont := efCSS;
    lblchecklogin.SetParentComponent(panelAnmeldung);
    lblchecklogin.Name := 'lblchecklogin';
    lblchecklogin.Left := 0;
    lblchecklogin.Top := 198;
    lblchecklogin.Width := 69;
    lblchecklogin.Height := 13;
    lblchecklogin.Caption := 'lblchecklogin';
    lblchecklogin.ElementClassName := 'h6 text-danger';
    lblchecklogin.ElementFont := efCSS;
    lblchecklogin.HeightStyle := ssAuto;
    lblchecklogin.WordWrap := True;
    lblchecklogin.WidthStyle := ssAuto;
    edtBenutzer.SetParentComponent(panelAnmeldung);
    edtBenutzer.Name := 'edtBenutzer';
    edtBenutzer.Left := 24;
    edtBenutzer.Top := 54;
    edtBenutzer.Width := 153;
    edtBenutzer.Height := 25;
    edtBenutzer.ElementClassName := 'form-control';
    edtBenutzer.HeightStyle := ssAuto;
    edtBenutzer.TextHint := 'Benutzername';
    SetEvent(edtBenutzer, Self, 'OnKeyPress', 'edtBenutzerKeyPress');
    edtPassword.SetParentComponent(panelAnmeldung);
    edtPassword.Name := 'edtPassword';
    edtPassword.Left := 24;
    edtPassword.Top := 105;
    edtPassword.Width := 153;
    edtPassword.Height := 25;
    edtPassword.ChildOrder := 5;
    edtPassword.ElementClassName := 'form-control';
    edtPassword.HeightStyle := ssAuto;
    edtPassword.PasswordChar := '*';
    edtPassword.TabOrder := 1;
    edtPassword.TextHint := 'Passwort';
    SetEvent(edtPassword, Self, 'OnKeyPress', 'edtPasswordKeyPress');
    btnAnmelden.SetParentComponent(panelAnmeldung);
    btnAnmelden.Name := 'btnAnmelden';
    btnAnmelden.Left := 80;
    btnAnmelden.Top := 152;
    btnAnmelden.Width := 96;
    btnAnmelden.Height := 41;
    btnAnmelden.Caption := 'Anmelden';
    btnAnmelden.ElementClassName := 'btn btn-info m-1 text-left';
    btnAnmelden.HeightStyle := ssAuto;
    btnAnmelden.TabOrder := 2;
    SetEvent(btnAnmelden, Self, 'OnClick', 'btnAnmeldenClick');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ChildOrder := 1;
    Container1.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'col d-flex justify-content-center';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    XDPersonal.SetParentComponent(Self);
    XDPersonal.Name := 'XDPersonal';
    XDPersonal.AfterOpen := XDPersonalAfterOpen;
    XDPersonal.EntitySetName := 'Personal';
    XDPersonal.Connection := DM2.XDataWebConnection1;
    XDPersonal.Left := 11;
    XDPersonal.Top := 91;
    XDPersonalPersonalnummer.SetParentComponent(XDPersonal);
    XDPersonalPersonalnummer.Name := 'XDPersonalPersonalnummer';
    XDPersonalPersonalnummer.FieldName := 'Personalnummer';
    XDPersonalPersonalnummer.Required := True;
    XDPersonalNachname.SetParentComponent(XDPersonal);
    XDPersonalNachname.Name := 'XDPersonalNachname';
    XDPersonalNachname.FieldName := 'Nachname';
    XDPersonalNachname.Size := 40;
    XDPersonalVorname.SetParentComponent(XDPersonal);
    XDPersonalVorname.Name := 'XDPersonalVorname';
    XDPersonalVorname.FieldName := 'Vorname';
    XDPersonalVorname.Size := 40;
    XDPersonalSchreibzeichen.SetParentComponent(XDPersonal);
    XDPersonalSchreibzeichen.Name := 'XDPersonalSchreibzeichen';
    XDPersonalSchreibzeichen.FieldName := 'Schreibzeichen';
    XDPersonalSchreibzeichen.Size := 10;
    XDPersonalLoginName.SetParentComponent(XDPersonal);
    XDPersonalLoginName.Name := 'XDPersonalLoginName';
    XDPersonalLoginName.FieldName := 'LoginName';
    XDPersonalLoginName.Size := 50;
    XDPersonalPassword.SetParentComponent(XDPersonal);
    XDPersonalPassword.Name := 'XDPersonalPassword';
    XDPersonalPassword.FieldName := 'Password';
    XDPersonalPassword.Size := 50;
    XDPersonalEnabled.SetParentComponent(XDPersonal);
    XDPersonalEnabled.Name := 'XDPersonalEnabled';
    XDPersonalEnabled.FieldName := 'Enabled';
    XDPersonalEmail.SetParentComponent(XDPersonal);
    XDPersonalEmail.Name := 'XDPersonalEmail';
    XDPersonalEmail.FieldName := 'Email';
    XDPersonalEmail.Size := 50;
    XDPersonalFarbe.SetParentComponent(XDPersonal);
    XDPersonalFarbe.Name := 'XDPersonalFarbe';
    XDPersonalFarbe.FieldName := 'Farbe';
    XDPersonalPersonalGUID.SetParentComponent(XDPersonal);
    XDPersonalPersonalGUID.Name := 'XDPersonalPersonalGUID';
    XDPersonalPersonalGUID.FieldName := 'PersonalGUID';
    XDPersonalPersonalGUID.Size := 38;
    XDPersonalRechteCadiaWEB.SetParentComponent(XDPersonal);
    XDPersonalRechteCadiaWEB.Name := 'XDPersonalRechteCadiaWEB';
    XDPersonalRechteCadiaWEB.FieldName := 'RechteCadiaWEB';
    XDPersonalRechteCadiaWEB.Size := 255;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    WebHttpRequest1.URL := './config/CadiaWebConfig.cnf';
    SetEvent(WebHttpRequest1, Self, 'OnResponse', 'WebHttpRequest1Response');
    WebHttpRequest1.Left := 88;
    WebHttpRequest1.Top := 88;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := AuthConnection;
    WClient.Left := 192;
    WClient.Top := 160;
    AuthConnection.SetParentComponent(Self);
    AuthConnection.Name := 'AuthConnection';
    AuthConnection.URL := 'https://langwald-edv.net/cadiaserverauth';
    SetEvent(AuthConnection, Self, 'OnConnect', 'AuthConnectionConnect');
    AuthConnection.Left := 168;
    AuthConnection.Top := 96;
  finally
    lblAnmeldung.AfterLoadDFMValues;
    labelonlinestatus.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebPanelMenue.AfterLoadDFMValues;
    btnLogout.AfterLoadDFMValues;
    btnKontakte.AfterLoadDFMValues;
    btnArtikel.AfterLoadDFMValues;
    btnAngebote.AfterLoadDFMValues;
    btnAuftrag.AfterLoadDFMValues;
    btnLieferscheine.AfterLoadDFMValues;
    btnTermine.AfterLoadDFMValues;
    btnEmails.AfterLoadDFMValues;
    btnBelegePositionen.AfterLoadDFMValues;
    btnAnrufe.AfterLoadDFMValues;
    btnPZE.AfterLoadDFMValues;
    panelAnmeldung.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    lblchecklogin.AfterLoadDFMValues;
    edtBenutzer.AfterLoadDFMValues;
    edtPassword.AfterLoadDFMValues;
    btnAnmelden.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    XDPersonal.AfterLoadDFMValues;
    XDPersonalPersonalnummer.AfterLoadDFMValues;
    XDPersonalNachname.AfterLoadDFMValues;
    XDPersonalVorname.AfterLoadDFMValues;
    XDPersonalSchreibzeichen.AfterLoadDFMValues;
    XDPersonalLoginName.AfterLoadDFMValues;
    XDPersonalPassword.AfterLoadDFMValues;
    XDPersonalEnabled.AfterLoadDFMValues;
    XDPersonalEmail.AfterLoadDFMValues;
    XDPersonalFarbe.AfterLoadDFMValues;
    XDPersonalPersonalGUID.AfterLoadDFMValues;
    XDPersonalRechteCadiaWEB.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
    AuthConnection.AfterLoadDFMValues;
  end;
end;

end.
