unit unit_ListeAngebot;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.Buttons,
  WEBLib.Grids, WEBLib.DBCtrls, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.StdCtrls, XData.Web.Client,
  WEBLib.WebCtrls, strutils;

type
  TListeAngebot = class(TForm)
    xdsAngebot: TXDataWebDataSet;
    WClient: TXDataWebClient;
    xdsAngebotNummerkomplett: TStringField;
    xdsAngebotDatum: TDateTimeField;
    xdsAngebotKundennummerKundennummer: TStringField;
    xdsAngebotNachname: TStringField;
    xdsAngebotAuftragsbezeichnung: TStringField;
    xdsAngebotBelegvariante: TStringField;
    xdsAngebotNettosumme: TFloatField;
    xdsAngebotAuftragerhaltenam: TDateTimeField;
    xdsAngebotAuftragnichterhalten: TBooleanField;
    Container3: THTMLContainer;
    Container2: THTMLContainer;
    btnNeuesAngebot: TButton;
    btnNeuerKostenvoranschlag: TButton;
    xdsAngebotAdresseBild: TStringField;
    Container1: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure btnNeuesAngebotClick(Sender: TObject);
    procedure btnNeuerKostenvoranschlagClick(Sender: TObject);
    procedure xdsAngebotAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    buttonzurueck:TButton;
    EinzelCard:THTMLDiv;
    procedure buttonzurueckclick(Sender:TObject);
    procedure EinzelCardclick(Sender:TObject);
    procedure NeuesAngebot(Kundennummer:string;Belegvariante:string);
    procedure Angebotholen(Angebotsnummer:string);
  end;

var
  ListeAngebot: TListeAngebot;

implementation
  uses unit1, unit2, unit_EinzelAngebot,unit_Kontaktsuche;

procedure TListeAngebot.buttonzurueckclick(Sender:TObject);
begin
  xdsAngebot.Close;
  self.close;
end;

procedure TListeAngebot.EinzelCardclick(Sender:TObject);
var
  EinzelAngebot:TEinzelAngebot;
  MyMerkerAngebot:string;
begin
  myMerkerAngebot:=(sender as THTMLDiv).ElementID;
  Container3.HTML.text:='';
  EinzelAngebot := TEinzelAngebot.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEinzelAngebot).MerkerAngebot:=myMerkerAngebot;
    end
    );

  EinzelAngebot.ShowModal(procedure(AValue: TModalResult)
    begin
      xdsAngebot.close;
      xdsAngebot.Load;
    end
    );
end;




procedure TListeAngebot.NeuesAngebot(Kundennummer:string;Belegvariante:string);
  procedure OnResponse(Response:TXDataClientResponse);
  var
  ergebnis:string;
  begin
    ergebnis:=string(TJSObject(response.Result)['value']);
    if ergebnis='WRONG' then
    begin
      showmessage('Das Angebot konnte leider nicht erstellt werden');
    end
    else
    begin
      Angebotholen(ergebnis);
    end;
  end;
begin
  WClient.RawInvoke('IServerService.NeuesAngebot',[Kundennummer,BenutzerPersonalnummer,Belegvariante],@OnResponse);

end;

procedure TListeAngebot.Angebotholen(Angebotsnummer: string);
   var
  EinzelAngebot:TEinzelAngebot;
    procedure AfterCreate(AForm:TObject);
    var
      EinzelAngebot:TEinzelAngebot;
    begin
      EinzelAngebot:=(AForm as TEinzelAngebot);
      //lform.Connection.URL:=dm2.XDataWebConnection1.URL;
      EinzelAngebot.MerkerAngebot:=Angebotsnummer;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      xdsangebot.Close;
      xdsangebot.Load;
    end;

  begin
  if LizenzOK then
  begin
  EinzelAngebot:=TEinzelAngebot.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;

 EinzelAngebot.ShowModal(@AfterShowmodal);
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;

end;

procedure TListeAngebot.btnNeuerKostenvoranschlagClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Kontaktnummer<>'' then
      begin
        NeuesAngebot(Lfrm.Kontaktnummer,'Kostenvoranschlag');
      end;
    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TListeAngebot.btnNeuesAngebotClick(Sender: TObject);
var
  LFrm:TfrmKontaktsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmKontaktsuche;
    begin
      LForm:=(AForm as TfrmKontaktsuche);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if LFrm.Kontaktnummer<>'' then
      begin
        NeuesAngebot(Lfrm.Kontaktnummer,'Angebot');
      end;
    end;

  begin
  LFrm:=TfrmKontaktsuche.CreateNew(@AfterCreate);
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TListeAngebot.WebFormShow(Sender: TObject);
begin
  xdsAngebot.Load;
end;

procedure TListeAngebot.xdsAngebotAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
  EinzelKarte:string;
begin

  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Angebote offen</h5></nav>';

  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info m-1 text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  btnNeuesAngebot.parentelement:=myelement;
  btnNeuerKostenvoranschlag.parentelement:=myelement;

  while not xdsAngebot.eof do
  begin

    Einzelkarte:='<div class="card-header"><h5>'+xdsAngebotNummerkomplett.asstring+' | '+formatdatetime('DD.MM.YYYY',xdsAngebotDatum.AsDateTime)+'</h5><i>'+xdsAngebotBelegvariante.AsString+'</i></div>';
    Einzelkarte:=Einzelkarte+'<div class="card-body ">'+xdsAngebotKundennummerKundennummer.AsString;
    Einzelkarte:=Einzelkarte+' | '+xdsAngebotNachname.asstring+'<br>';
    Einzelkarte:=Einzelkarte+'<b>'+xdsAngebotAuftragsbezeichnung.AsString+'</b><br>';
    Einzelkarte:=Einzelkarte+Formatfloat('#,##0.00 €',xdsAngebotNettosumme.Asfloat)+'<br>';
    Einzelkarte:=Einzelkarte+xdsAngebotAuftragerhaltenam.AsString+'<br>';
    Einzelkarte:=Einzelkarte+'</div>';


    myelement:=Container3.ElementHandle;
    EinzelCard:=THTMLDiv.Create(self);
    EinzelCard.ElementID:=xdsAngebotNummerkomplett.asstring;
    EinzelCard.HeightStyle:=ssauto;
    EinzelCard.Width:=350;
    EinzelCard.HTML.Text:=Einzelkarte;
    EinzelCard.ElementClassName:='card m-1 border-info';
    EinzelCard.OnClick:=@EinzelCardClick;
    EinzelCard.ParentElement:=myelement;

    xdsAngebot.Next;
  end;
end;

procedure TListeAngebot.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Container3 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnNeuesAngebot := TButton.Create(Self);
  btnNeuerKostenvoranschlag := TButton.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  xdsAngebot := TXDataWebDataSet.Create(Self);
  xdsAngebotNummerkomplett := TStringField.Create(Self);
  xdsAngebotDatum := TDateTimeField.Create(Self);
  xdsAngebotKundennummerKundennummer := TStringField.Create(Self);
  xdsAngebotNachname := TStringField.Create(Self);
  xdsAngebotAuftragsbezeichnung := TStringField.Create(Self);
  xdsAngebotBelegvariante := TStringField.Create(Self);
  xdsAngebotNettosumme := TFloatField.Create(Self);
  xdsAngebotAuftragerhaltenam := TDateTimeField.Create(Self);
  xdsAngebotAuftragnichterhalten := TBooleanField.Create(Self);
  xdsAngebotAdresseBild := TStringField.Create(Self);
  WClient := TXDataWebClient.Create(Self);

  Container3.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnNeuesAngebot.BeforeLoadDFMValues;
  btnNeuerKostenvoranschlag.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  xdsAngebot.BeforeLoadDFMValues;
  xdsAngebotNummerkomplett.BeforeLoadDFMValues;
  xdsAngebotDatum.BeforeLoadDFMValues;
  xdsAngebotKundennummerKundennummer.BeforeLoadDFMValues;
  xdsAngebotNachname.BeforeLoadDFMValues;
  xdsAngebotAuftragsbezeichnung.BeforeLoadDFMValues;
  xdsAngebotBelegvariante.BeforeLoadDFMValues;
  xdsAngebotNettosumme.BeforeLoadDFMValues;
  xdsAngebotAuftragerhaltenam.BeforeLoadDFMValues;
  xdsAngebotAuftragnichterhalten.BeforeLoadDFMValues;
  xdsAngebotAdresseBild.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  try
    Name := 'ListeAngebot';
    Width := 170;
    Height := 205;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 94;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 5;
    Container3.ElementFont := efCSS;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 5;
    Container2.ElementFont := efCSS;
    btnNeuesAngebot.SetParentComponent(Self);
    btnNeuesAngebot.Name := 'btnNeuesAngebot';
    btnNeuesAngebot.Left := 8;
    btnNeuesAngebot.Top := 8;
    btnNeuesAngebot.Width := 96;
    btnNeuesAngebot.Height := 25;
    btnNeuesAngebot.Caption := '+ Angebot';
    btnNeuesAngebot.ChildOrder := 5;
    btnNeuesAngebot.ElementClassName := 'btn btn-info m-1 text-left';
    btnNeuesAngebot.ElementFont := efCSS;
    btnNeuesAngebot.HeightStyle := ssAuto;
    btnNeuesAngebot.WidthStyle := ssAuto;
    SetEvent(btnNeuesAngebot, Self, 'OnClick', 'btnNeuesAngebotClick');
    btnNeuerKostenvoranschlag.SetParentComponent(Self);
    btnNeuerKostenvoranschlag.Name := 'btnNeuerKostenvoranschlag';
    btnNeuerKostenvoranschlag.Left := 110;
    btnNeuerKostenvoranschlag.Top := 8;
    btnNeuerKostenvoranschlag.Width := 96;
    btnNeuerKostenvoranschlag.Height := 25;
    btnNeuerKostenvoranschlag.Caption := '+ Kostenvoranschlag';
    btnNeuerKostenvoranschlag.ChildOrder := 5;
    btnNeuerKostenvoranschlag.ElementClassName := 'btn btn-info m-1 text-left';
    btnNeuerKostenvoranschlag.ElementFont := efCSS;
    btnNeuerKostenvoranschlag.HeightStyle := ssAuto;
    btnNeuerKostenvoranschlag.WidthStyle := ssAuto;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ElementFont := efCSS;
    xdsAngebot.SetParentComponent(Self);
    xdsAngebot.Name := 'xdsAngebot';
    xdsAngebot.AfterOpen := xdsAngebotAfterOpen;
    xdsAngebot.EntitySetName := 'Angebote';
    xdsAngebot.Connection := DM2.XDataWebConnection1;
    xdsAngebot.SubpropsDepth := 1;
    xdsAngebot.QueryString := '$expand=Kundennummer&$filter=((Auftragnichterhalten eq 0) AND (Auftragerhaltenam eq null))&$orderby=Nummerkomplett DESC';
    xdsAngebot.QueryTop := 100;
    xdsAngebot.Left := 20;
    xdsAngebot.Top := 142;
    xdsAngebotNummerkomplett.SetParentComponent(xdsAngebot);
    xdsAngebotNummerkomplett.Name := 'xdsAngebotNummerkomplett';
    xdsAngebotNummerkomplett.FieldName := 'Nummerkomplett';
    xdsAngebotNummerkomplett.Required := True;
    xdsAngebotNummerkomplett.Size := 10;
    xdsAngebotDatum.SetParentComponent(xdsAngebot);
    xdsAngebotDatum.Name := 'xdsAngebotDatum';
    xdsAngebotDatum.FieldName := 'Datum';
    xdsAngebotDatum.Required := True;
    xdsAngebotDatum.DisplayFormat := 'DD.MM.YYYY';
    xdsAngebotKundennummerKundennummer.SetParentComponent(xdsAngebot);
    xdsAngebotKundennummerKundennummer.Name := 'xdsAngebotKundennummerKundennummer';
    xdsAngebotKundennummerKundennummer.FieldName := 'Kundennummer.Kundennummer';
    xdsAngebotKundennummerKundennummer.Size := 13;
    xdsAngebotNachname.SetParentComponent(xdsAngebot);
    xdsAngebotNachname.Name := 'xdsAngebotNachname';
    xdsAngebotNachname.FieldName := 'Nachname';
    xdsAngebotNachname.Size := 50;
    xdsAngebotAuftragsbezeichnung.SetParentComponent(xdsAngebot);
    xdsAngebotAuftragsbezeichnung.Name := 'xdsAngebotAuftragsbezeichnung';
    xdsAngebotAuftragsbezeichnung.FieldName := 'Auftragsbezeichnung';
    xdsAngebotAuftragsbezeichnung.Size := 255;
    xdsAngebotBelegvariante.SetParentComponent(xdsAngebot);
    xdsAngebotBelegvariante.Name := 'xdsAngebotBelegvariante';
    xdsAngebotBelegvariante.FieldName := 'Belegvariante';
    xdsAngebotBelegvariante.Size := 25;
    xdsAngebotNettosumme.SetParentComponent(xdsAngebot);
    xdsAngebotNettosumme.Name := 'xdsAngebotNettosumme';
    xdsAngebotNettosumme.FieldName := 'Nettosumme';
    xdsAngebotAuftragerhaltenam.SetParentComponent(xdsAngebot);
    xdsAngebotAuftragerhaltenam.Name := 'xdsAngebotAuftragerhaltenam';
    xdsAngebotAuftragerhaltenam.FieldName := 'Auftragerhaltenam';
    xdsAngebotAuftragerhaltenam.DisplayFormat := 'DD.MM.YYYY';
    xdsAngebotAuftragnichterhalten.SetParentComponent(xdsAngebot);
    xdsAngebotAuftragnichterhalten.Name := 'xdsAngebotAuftragnichterhalten';
    xdsAngebotAuftragnichterhalten.FieldName := 'Auftragnichterhalten';
    xdsAngebotAdresseBild.SetParentComponent(xdsAngebot);
    xdsAngebotAdresseBild.Name := 'xdsAngebotAdresseBild';
    xdsAngebotAdresseBild.FieldName := 'AdresseBild';
    xdsAngebotAdresseBild.Size := 255;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 112;
    WClient.Top := 136;
  finally
    Container3.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnNeuesAngebot.AfterLoadDFMValues;
    btnNeuerKostenvoranschlag.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    xdsAngebot.AfterLoadDFMValues;
    xdsAngebotNummerkomplett.AfterLoadDFMValues;
    xdsAngebotDatum.AfterLoadDFMValues;
    xdsAngebotKundennummerKundennummer.AfterLoadDFMValues;
    xdsAngebotNachname.AfterLoadDFMValues;
    xdsAngebotAuftragsbezeichnung.AfterLoadDFMValues;
    xdsAngebotBelegvariante.AfterLoadDFMValues;
    xdsAngebotNettosumme.AfterLoadDFMValues;
    xdsAngebotAuftragerhaltenam.AfterLoadDFMValues;
    xdsAngebotAuftragnichterhalten.AfterLoadDFMValues;
    xdsAngebotAdresseBild.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
  end;
end;

end.