unit unit_Artikelsuche;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, WEBLib.Buttons;

type
  TfrmArtikelsuche = class(TForm)
    DataSet: TXDataWebDataSet;
    Source: TDataSource;
    DataSetArtikelnummer: TStringField;
    DataSetArtikelueberschrift: TStringField;
    DataSetArtikelbeschreibung: TStringField;
    DataSetVerkaufspreis: TFloatField;
    DataSetBestand: TFloatField;
    TC: TTableControl;
    DataSetArtikelmatchcode: TStringField;
    DataSetEAN: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure DataSetAfterOpen(DataSet: TDataSet);
  protected
    procedure LoadDFMValues; override;

    public
    Artikelnummer:string;
    weiterzueinzelartikel:boolean;
    infolabel:TLabel;
    buttonzurueck:TButton;
    buttonscan:TButton;
    buttonartikelnummer:TButton;
    edtsuchbegriff: TEdit;
    procedure buttonzurueckclick(Sender:TObject);
    procedure edtsuchbegriffChange(Sender: TObject);
    procedure buttonscanclick(Sender:TObject);
    procedure buttonartikelnummerclick(Sender:TObject);
    //procedure buttonmehrclick(Sender:TObject);
  end;

var
  frmArtikelsuche: TfrmArtikelsuche;

implementation
  uses unit1, unit2, unit_Artikel, unit_BarcodeScan;

procedure TfrmArtikelsuche.buttonzurueckclick(Sender:TObject);
begin
  self.close;
end;

procedure TfrmArtikelsuche.buttonartikelnummerclick(Sender:TObject);
var
  MFrm:TfrmArtikel;
    procedure AfterCreate(AForm:TObject);
    begin
      (AForm as TfrmArtikel).edtArtikelnummer.text:=Artikelnummer;
      (AForm as TfrmArtikel).edtArtikelnummerChange(nil);
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      //nothing to do
    end;

  begin
  Artikelnummer:=(sender as TButton).Caption;

  if weiterzueinzelartikel then
  begin
  MFrm:=TfrmArtikel.CreateNew(@AfterCreate);

  MFrm.ShowModal(@AfterShowmodal);
  end
  else
  begin

  self.close;
  end;
end;

procedure TfrmArtikelsuche.DataSetAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin
  i:=3;

  tc.RowCount:=dataset.RecordCount+4;
  dataset.First;
  if dataset.RecordCount=0 then infolabel.Caption:='nichts gefunden';
  if dataset.RecordCount>0 then infolabel.Caption:='Übereinstimmungen: '+inttostr(dataset.RecordCount);
  if dataset.RecordCount>20 then infolabel.Caption:='mehr als 20 Übereinstimmungen';
  tc.BeginUpdate;
  while not dataset.eof do
  begin
    inc(i);
    myelement:=TC.cellelements[0,i];
    myelement.innerHTML:='';
    buttonartikelnummer:=TButton.Create(self);
    buttonartikelnummer.ElementClassName:='btn btn-sm btn-outline-info';
    buttonartikelnummer.HeightStyle:=ssauto;
    buttonartikelnummer.Width:=150;
    buttonartikelnummer.tag:=0;
    buttonartikelnummer.Caption:=DataSetArtikelnummer.AsString;
    buttonartikelnummer.OnClick:=@buttonartikelnummerClick;
    buttonartikelnummer.ParentElement:=myelement;
    TC.Cells[1,i]:=DataSetArtikelueberschrift.AsString;
    TC.Cells[2,i]:=DataSetArtikelbeschreibung.AsString;
    TC.Cells[3,i]:=DataSetBestand.AsString;
    TC.Cells[4,i]:=DataSetEAN.AsString;
    TC.Cells[5,i]:=DataSetArtikelmatchcode.AsString;
    dataset.Next;
  end;
  Tc.EndUpdate;
end;

procedure TfrmArtikelsuche.buttonscanclick(Sender:TObject);
var
  LFrm:TBarcodeScan;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TBarcodeScan;
    begin
      LForm:=(AForm as TBarcodeScan);
      //lform.mykundehint:=xdsEinzelkontaktKundennummer.AsString+' '+xdsEinzelkontaktNachname.AsString+', '+xdsEinzelkontaktVorname.asstring;
      //lForm.edttoaddress.Text:= xdsEinzelkontaktPostleitzahl.AsString+' '+xdsEinzelkontaktort.AsString+', '+xdsEinzelkontaktStrasse.AsString;

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      edtsuchbegriff.text:=lfrm.ScanErgebnis;
      edtsuchbegriffChange(self);
    end;
  begin
    if LizenzOK then
    begin
      LFrm:=TBarcodeScan.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);
    end
    else
    begin
      showmessage('Für diese Funktion sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;
end;

procedure TfrmArtikelsuche.edtsuchbegriffChange(Sender: TObject);
var
  suchtext:string;
begin
  suchtext:=edtsuchbegriff.Text;
  if length(suchtext)>0 then
  begin
  dataset.Close;
  dataset.QueryString:='$filter=contains(Artikelueberschrift, '''+suchtext+''') OR contains(Artikelnummer, '''+suchtext+''') OR contains(EAN, '''+suchtext+''') OR contains(Artikelmatchcode, '''+suchtext+''') OR contains(Artikelbeschreibung, '''+suchtext+''')';
  dataset.Load;
  end;
end;

procedure TfrmArtikelsuche.WebFormCreate(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  TC.ColCount:=6;
  TC.RowCount:=5;
  TC.Cells[0,0]:='<h4>Artikelsuche';

  myelement:=TC.cellelements[0,1];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  myelement:=TC.cellelements[0,2];
  myelement.innerHTML:='';
  infolabel:=TLabel.Create(self);
  infolabel.Caption:='Suchbegriff eingeben oder SCAN klicken';
  infolabel.ElementFont:=efCSS;
  infolabel.ParentElement:=myelement;
  edtsuchbegriff:=TEdit.Create(self);
  edtsuchbegriff.ElementClassName:='form-control';
  edtsuchbegriff.ElementFont:=efCSS;
  edtsuchbegriff.HeightStyle:=ssauto;
  edtsuchbegriff.Width:=150;
  edtsuchbegriff.OnChange:=@edtsuchbegriffChange;
  edtsuchbegriff.ParentElement:=myelement;
  myelement:=TC.cellelements[1,2];
  myelement.innerHTML:='';
  buttonscan:=TButton.Create(self);
  buttonscan.ElementClassName:='btn btn-info text-left';
  buttonscan.HeightStyle:=ssauto;
  buttonscan.WidthStyle:=ssauto;
  buttonscan.tag:=0;
  buttonscan.Caption:='Scan';
  buttonscan.OnClick:=@buttonscanClick;
  buttonscan.ParentElement:=myelement;

  tc.Cells[0,3]:='<b>Artikelnummer';
  tc.Cells[1,3]:='<b>Artikelüberschrift';
  tc.Cells[2,3]:='<b>Artikelbeschreibung';
  tc.Cells[3,3]:='<b>Bestand';
  tc.Cells[4,3]:='<b>EAN';
  tc.Cells[5,3]:='<b>Matchcode';
end;

procedure TfrmArtikelsuche.WebFormShow(Sender: TObject);
begin
  artikelnummer:='';
  edtsuchbegriff.SetFocus;
end;

procedure TfrmArtikelsuche.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  DataSet := TXDataWebDataSet.Create(Self);
  DataSetArtikelnummer := TStringField.Create(Self);
  DataSetArtikelueberschrift := TStringField.Create(Self);
  DataSetArtikelbeschreibung := TStringField.Create(Self);
  DataSetVerkaufspreis := TFloatField.Create(Self);
  DataSetBestand := TFloatField.Create(Self);
  DataSetArtikelmatchcode := TStringField.Create(Self);
  DataSetEAN := TStringField.Create(Self);
  Source := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  DataSet.BeforeLoadDFMValues;
  DataSetArtikelnummer.BeforeLoadDFMValues;
  DataSetArtikelueberschrift.BeforeLoadDFMValues;
  DataSetArtikelbeschreibung.BeforeLoadDFMValues;
  DataSetVerkaufspreis.BeforeLoadDFMValues;
  DataSetBestand.BeforeLoadDFMValues;
  DataSetArtikelmatchcode.BeforeLoadDFMValues;
  DataSetEAN.BeforeLoadDFMValues;
  Source.BeforeLoadDFMValues;
  try
    Name := 'frmArtikelsuche';
    Width := 231;
    Height := 335;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormStyle := fsStayOnTop;
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 231;
    TC.Height := 335;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 1;
    TC.RowCount := 5;
    DataSet.SetParentComponent(Self);
    DataSet.Name := 'DataSet';
    DataSet.AfterOpen := DataSetAfterOpen;
    DataSet.EntitySetName := 'Artikel';
    DataSet.Connection := DM2.XDataWebConnection1;
    DataSet.QueryTop := 21;
    DataSet.Left := 56;
    DataSet.Top := 168;
    DataSetArtikelnummer.SetParentComponent(DataSet);
    DataSetArtikelnummer.Name := 'DataSetArtikelnummer';
    DataSetArtikelnummer.FieldName := 'Artikelnummer';
    DataSetArtikelnummer.Required := True;
    DataSetArtikelnummer.Size := 25;
    DataSetArtikelueberschrift.SetParentComponent(DataSet);
    DataSetArtikelueberschrift.Name := 'DataSetArtikelueberschrift';
    DataSetArtikelueberschrift.FieldName := 'Artikelueberschrift';
    DataSetArtikelueberschrift.Size := 50;
    DataSetArtikelbeschreibung.SetParentComponent(DataSet);
    DataSetArtikelbeschreibung.Name := 'DataSetArtikelbeschreibung';
    DataSetArtikelbeschreibung.FieldName := 'Artikelbeschreibung';
    DataSetArtikelbeschreibung.Size := 255;
    DataSetVerkaufspreis.SetParentComponent(DataSet);
    DataSetVerkaufspreis.Name := 'DataSetVerkaufspreis';
    DataSetVerkaufspreis.FieldName := 'Verkaufspreis';
    DataSetBestand.SetParentComponent(DataSet);
    DataSetBestand.Name := 'DataSetBestand';
    DataSetBestand.FieldName := 'Bestand';
    DataSetArtikelmatchcode.SetParentComponent(DataSet);
    DataSetArtikelmatchcode.Name := 'DataSetArtikelmatchcode';
    DataSetArtikelmatchcode.FieldName := 'Artikelmatchcode';
    DataSetArtikelmatchcode.Size := 30;
    DataSetEAN.SetParentComponent(DataSet);
    DataSetEAN.Name := 'DataSetEAN';
    DataSetEAN.FieldName := 'EAN';
    DataSetEAN.Size := 15;
    Source.SetParentComponent(Self);
    Source.Name := 'Source';
    Source.DataSet := DataSet;
    Source.Left := 56;
    Source.Top := 224;
  finally
    TC.AfterLoadDFMValues;
    DataSet.AfterLoadDFMValues;
    DataSetArtikelnummer.AfterLoadDFMValues;
    DataSetArtikelueberschrift.AfterLoadDFMValues;
    DataSetArtikelbeschreibung.AfterLoadDFMValues;
    DataSetVerkaufspreis.AfterLoadDFMValues;
    DataSetBestand.AfterLoadDFMValues;
    DataSetArtikelmatchcode.AfterLoadDFMValues;
    DataSetEAN.AfterLoadDFMValues;
    Source.AfterLoadDFMValues;
  end;
end;

end.
