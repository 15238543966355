unit unit_Objektsuche;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, WEBLib.Buttons;

type
  TfrmObjektsuche = class(TForm)
    DataSet: TXDataWebDataSet;
    Source: TDataSource;
    DataSetObjektReferenz: TStringField;
    DataSetKurzbezeichnung: TStringField;
    DataSetBezeichnung: TStringField;
    DataSetNachname: TStringField;
    DataSetObjektnummer: TStringField;
    DataSetKundennummer: TStringField;
    TC: TTableControl;
    procedure WebFormCreate(Sender: TObject);
    procedure DataSetAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
  protected
    procedure LoadDFMValues; override;

    public
    Objektreferenz:string;
    edtsuchbegriff:TEdit;
    infolabel:TLabel;
    buttonzurueck:TButton;
    buttonscan:TButton;
    buttonobjektreferenz:TButton;
    procedure buttonzurueckclick(Sender:TObject);
    procedure edtsuchbegriffChange(Sender: TObject);
    procedure buttonscanclick(Sender:TObject);
    procedure buttonobjektreferenzclick(Sender:TObject);
  end;

var
  frmObjektsuche: TfrmObjektsuche;

implementation
  uses unit1, unit2, unit_BarcodeScan;

procedure TfrmObjektsuche.buttonzurueckclick(Sender:TObject);
begin
  self.close;
end;

procedure TfrmObjektsuche.buttonscanclick(Sender:TObject);
var
  LFrm:TBarcodeScan;
  procedure AfterCreate(AForm:TObject);
  var
    LForm:TBarcodeScan;
    begin
      LForm:=(AForm as TBarcodeScan);
      //lform.mykundehint:=xdsEinzelkontaktKundennummer.AsString+' '+xdsEinzelkontaktNachname.AsString+', '+xdsEinzelkontaktVorname.asstring;
      //lForm.edttoaddress.Text:= xdsEinzelkontaktPostleitzahl.AsString+' '+xdsEinzelkontaktort.AsString+', '+xdsEinzelkontaktStrasse.AsString;

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      edtsuchbegriff.text:=lfrm.ScanErgebnis;
      edtsuchbegriffChange(self);
    end;
  begin
    if LizenzOK then
    begin
      LFrm:=TBarcodeScan.CreateNew(@AfterCreate);
      LFrm.ShowModal(@AfterShowmodal);
    end
    else
    begin
      showmessage('Für diese Funktion sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;
end;

procedure TfrmObjektsuche.DataSetAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin
  i:=3;

  tc.RowCount:=dataset.RecordCount+4;
  dataset.First;
  if dataset.RecordCount=0 then infolabel.Caption:='nichts gefunden';
  if dataset.RecordCount>0 then infolabel.Caption:='Übereinstimmungen: '+inttostr(dataset.RecordCount);
  if dataset.RecordCount>20 then infolabel.Caption:='mehr als 20 Übereinstimmungen';
  tc.BeginUpdate;
  while not dataset.eof do
  begin
    inc(i);
    myelement:=TC.cellelements[0,i];
    myelement.innerHTML:='';
    buttonobjektreferenz:=TButton.Create(self);
    buttonobjektreferenz.ElementClassName:='btn btn-sm btn-outline-info';
    buttonobjektreferenz.HeightStyle:=ssauto;
    buttonobjektreferenz.Width:=150;
    buttonobjektreferenz.tag:=0;
    buttonobjektreferenz.Caption:=DataSetObjektReferenz.AsString;
    buttonobjektreferenz.OnClick:=@buttonobjektreferenzClick;
    buttonobjektreferenz.ParentElement:=myelement;
    TC.Cells[1,i]:=DataSetObjektReferenz.AsString;
    TC.Cells[2,i]:=DataSetObjektnummer.AsString;
    TC.Cells[3,i]:=DataSetKurzbezeichnung.AsString;
    TC.Cells[4,i]:=DataSetKundennummer.AsString;
    TC.Cells[5,i]:=DataSetNachname.AsString;
    TC.Cells[65,i]:=DataSetBezeichnung.AsString;
    dataset.Next;
  end;
  Tc.EndUpdate;

end;

procedure TfrmObjektsuche.buttonobjektreferenzclick(Sender:TObject);
begin
  Objektreferenz:=(sender AS Tbutton).Caption;
  self.close;
end;

procedure TfrmObjektsuche.edtsuchbegriffChange(Sender: TObject);
var
  suchtext:string;
begin
  suchtext:=edtsuchbegriff.Text;
  if length(suchtext)>0 then
  begin
  dataset.Close;
  dataset.QueryString:='$filter=contains(Kurzbezeichnung, '+QuotedStr(suchtext)+') OR contains(Objektnummer, '+QuotedStr(suchtext)+')';
  dataset.Load;
  end;
end;

procedure TfrmObjektsuche.WebFormCreate(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  TC.ColCount:=6;
  TC.RowCount:=5;
  TC.Cells[0,0]:='<h4>Objektsuche';

  myelement:=TC.cellelements[0,1];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  myelement:=TC.cellelements[0,2];
  myelement.innerHTML:='';
  infolabel:=TLabel.Create(self);
  infolabel.Caption:='Suchbegriff eingeben oder SCAN klicken';
  infolabel.ElementFont:=efCSS;
  infolabel.ParentElement:=myelement;
  edtsuchbegriff:=TEdit.Create(self);
  edtsuchbegriff.ElementClassName:='form-control';
  edtsuchbegriff.ElementFont:=efCSS;
  edtsuchbegriff.HeightStyle:=ssauto;
  edtsuchbegriff.Width:=150;
  edtsuchbegriff.OnChange:=@edtsuchbegriffChange;
  edtsuchbegriff.ParentElement:=myelement;
  myelement:=TC.cellelements[1,2];
  myelement.innerHTML:='';
  buttonscan:=TButton.Create(self);
  buttonscan.ElementClassName:='btn btn-info text-left';
  buttonscan.HeightStyle:=ssauto;
  buttonscan.WidthStyle:=ssauto;
  buttonscan.tag:=0;
  buttonscan.Caption:='Scan';
  buttonscan.OnClick:=@buttonscanClick;
  buttonscan.ParentElement:=myelement;

  tc.Cells[0,3]:='<b>Objektreferenz';
  tc.Cells[1,3]:='<b>Objektnummer';
  tc.Cells[2,3]:='<b>Kurzbezeichnung';
  tc.Cells[3,3]:='<b>Kundennummer';
  tc.Cells[4,3]:='<b>Nachname';
  tc.Cells[5,3]:='<b>Bezeichnung';
end;


procedure TfrmObjektsuche.WebFormShow(Sender: TObject);
begin
  edtsuchbegriff.SetFocus;
  edtsuchbegriff.Text:='';
end;

procedure TfrmObjektsuche.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  DataSet := TXDataWebDataSet.Create(Self);
  DataSetObjektnummer := TStringField.Create(Self);
  DataSetKurzbezeichnung := TStringField.Create(Self);
  DataSetBezeichnung := TStringField.Create(Self);
  DataSetNachname := TStringField.Create(Self);
  DataSetObjektReferenz := TStringField.Create(Self);
  DataSetKundennummer := TStringField.Create(Self);
  Source := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  DataSet.BeforeLoadDFMValues;
  DataSetObjektnummer.BeforeLoadDFMValues;
  DataSetKurzbezeichnung.BeforeLoadDFMValues;
  DataSetBezeichnung.BeforeLoadDFMValues;
  DataSetNachname.BeforeLoadDFMValues;
  DataSetObjektReferenz.BeforeLoadDFMValues;
  DataSetKundennummer.BeforeLoadDFMValues;
  Source.BeforeLoadDFMValues;
  try
    Name := 'frmObjektsuche';
    Left := 0;
    Top := 0;
    Width := 243;
    Height := 318;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormStyle := fsStayOnTop;
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 243;
    TC.Height := 318;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 1;
    TC.RowCount := 5;
    DataSet.SetParentComponent(Self);
    DataSet.Name := 'DataSet';
    DataSet.AfterOpen := DataSetAfterOpen;
    DataSet.EntitySetName := 'Objekte';
    DataSet.Connection := DM2.XDataWebConnection1;
    DataSet.Left := 56;
    DataSet.Top := 168;
    DataSetObjektnummer.SetParentComponent(DataSet);
    DataSetObjektnummer.Name := 'DataSetObjektnummer';
    DataSetObjektnummer.FieldName := 'Objektnummer';
    DataSetObjektnummer.Size := 25;
    DataSetKurzbezeichnung.SetParentComponent(DataSet);
    DataSetKurzbezeichnung.Name := 'DataSetKurzbezeichnung';
    DataSetKurzbezeichnung.FieldName := 'Kurzbezeichnung';
    DataSetKurzbezeichnung.Size := 25;
    DataSetBezeichnung.SetParentComponent(DataSet);
    DataSetBezeichnung.Name := 'DataSetBezeichnung';
    DataSetBezeichnung.FieldName := 'Bezeichnung';
    DataSetBezeichnung.Size := 255;
    DataSetNachname.SetParentComponent(DataSet);
    DataSetNachname.Name := 'DataSetNachname';
    DataSetNachname.FieldName := 'Nachname';
    DataSetNachname.Size := 40;
    DataSetObjektReferenz.SetParentComponent(DataSet);
    DataSetObjektReferenz.Name := 'DataSetObjektReferenz';
    DataSetObjektReferenz.FieldName := 'ObjektReferenz';
    DataSetObjektReferenz.Required := True;
    DataSetObjektReferenz.Size := 13;
    DataSetKundennummer.SetParentComponent(DataSet);
    DataSetKundennummer.Name := 'DataSetKundennummer';
    DataSetKundennummer.FieldName := 'Kundennummer';
    DataSetKundennummer.Size := 13;
    Source.SetParentComponent(Self);
    Source.Name := 'Source';
    Source.DataSet := DataSet;
    Source.Left := 56;
    Source.Top := 224;
  finally
    TC.AfterLoadDFMValues;
    DataSet.AfterLoadDFMValues;
    DataSetObjektnummer.AfterLoadDFMValues;
    DataSetKurzbezeichnung.AfterLoadDFMValues;
    DataSetBezeichnung.AfterLoadDFMValues;
    DataSetNachname.AfterLoadDFMValues;
    DataSetObjektReferenz.AfterLoadDFMValues;
    DataSetKundennummer.AfterLoadDFMValues;
    Source.AfterLoadDFMValues;
  end;
end;

end.
