unit Unit_Artikel;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  WEBLib.ExtCtrls, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Buttons, WEBLib.Grids, XData.Web.Client, strutils;

type
  TfrmArtikel = class(TForm)
    xdsEinzelartikel: TXDataWebDataSet;
    sourceEinzelartikel: TDataSource;
    TC: TTableControl;
    xdsEinzelartikelHersteller: TXDataWebEntityField;
    xdsEinzelartikelArtderMenge: TXDataWebEntityField;
    xdsEinzelartikelPfandArt: TXDataWebEntityField;
    xdsEinzelartikelWarengruppe: TXDataWebEntityField;
    xdsEinzelartikelWarenuntergruppe: TXDataWebEntityField;
    xdsEinzelartikelArtikelnummer: TStringField;
    xdsEinzelartikelArtikelueberschrift: TStringField;
    xdsEinzelartikelArtikelbeschreibung: TStringField;
    xdsEinzelartikelVerkaufspreis: TFloatField;
    xdsEinzelartikelMindestbestand: TFloatField;
    xdsEinzelartikelPreisliste: TStringField;
    xdsEinzelartikelGewicht: TFloatField;
    xdsEinzelartikelBreite: TFloatField;
    xdsEinzelartikelArtikelZusatz: TStringField;
    xdsEinzelartikelLagerort: TStringField;
    xdsEinzelartikelBuchungskonto: TIntegerField;
    xdsEinzelartikelEingangMenge: TFloatField;
    xdsEinzelartikelEingangEndpreis: TFloatField;
    xdsEinzelartikelAusgangMenge: TFloatField;
    xdsEinzelartikelAusgangEndpreisEK: TFloatField;
    xdsEinzelartikelAusgangEndpreisVK: TFloatField;
    xdsEinzelartikelBestand: TFloatField;
    xdsEinzelartikelBestandswert: TFloatField;
    xdsEinzelartikelMarge: TFloatField;
    xdsEinzelartikelMargeProzent: TFloatField;
    xdsEinzelartikelArtikelnummer2: TStringField;
    xdsEinzelartikelArtikelmatchcode: TStringField;
    xdsEinzelartikelRabattpreis1: TFloatField;
    xdsEinzelartikelRabattpreis2: TFloatField;
    xdsEinzelartikelRabattpreis3: TFloatField;
    xdsEinzelartikelRabattpreis4: TFloatField;
    xdsEinzelartikelRabattpreis5: TFloatField;
    xdsEinzelartikelArtikelnichtmehrgueltig: TBooleanField;
    xdsEinzelartikelEinkaufspreis: TFloatField;
    xdsEinzelartikelAufschlag: TFloatField;
    xdsEinzelartikelWarenbestand: TBooleanField;
    xdsEinzelartikelTextfrei10: TStringField;
    xdsEinzelartikelTextfrei20: TStringField;
    xdsEinzelartikelArtikelbeschreibungRTF: TStringField;
    xdsEinzelartikelArtikeldatei1: TStringField;
    xdsEinzelartikelArtikeldatei2: TStringField;
    xdsEinzelartikelArtikeldatei3: TStringField;
    xdsEinzelartikelArtikeldatei4: TStringField;
    xdsEinzelartikelArtikeldatei5: TStringField;
    xdsEinzelartikelEinkaufspreisVom: TDateTimeField;
    xdsEinzelartikelEAN: TStringField;
    xdsEinzelartikelVGPFaktor: TFloatField;
    xdsEinzelartikelVGPMengenart: TStringField;
    xdsEinzelartikelVerleihpreis: TFloatField;
    xdsEinzelartikelZusatzPreis: TFloatField;
    xdsEinzelartikelVerpackungsart: TStringField;
    xdsEinzelartikelVerpackungsMenge: TFloatField;
    xdsEinzelartikelQuellartikel: TStringField;
    xdsEinzelartikelrabattierfaehig: TBooleanField;
    xdsEinzelartikelAngebotAlternativeAN: TStringField;
    xdsEinzelartikelPreisanteilNetto: TFloatField;
    xdsEinzelartikelFarbe: TIntegerField;
    xdsEinzelartikelAEinkauf: TBooleanField;
    xdsEinzelartikelAVerkauf: TBooleanField;
    xdsEinzelartikelArtikelnummerLieferant: TStringField;
    xdsEinzelartikelLieferantnummer: TStringField;
    xdsEinzelartikelVerkaufspreisBrutto: TFloatField;
    xdsEinzelartikelArtikelSet: TBooleanField;
    xdsEinzelartikelProvisionssatz: TFloatField;
    xdsEinzelartikelWarentarifnummer: TStringField;
    xdsEinzelartikelHandelsartikel: TBooleanField;
    xdsEinzelartikelUrsprungsland: TStringField;
    xdsEinzelartikelKategorie: TStringField;
    xdsEinzelartikelStandardRabattsatzEK: TFloatField;
    xdsEinzelartikelStandardRabattsatzVK: TFloatField;
    xdsEinzelartikelRabattpreiseManuell: TBooleanField;
    xdsEinzelartikelMengeBestellt: TFloatField;
    xdsEinzelartikelMengeBeauftragt: TFloatField;
    xdsEinzelartikelBestandDisponiert: TFloatField;
    xdsEinzelartikelMengeAngeboten: TFloatField;
    xdsEinzelartikelBestandDispInclAngebot: TFloatField;
    xdsEinzelartikelMWStKey: TIntegerField;
    xdsEinzelartikelShopAmazon: TBooleanField;
    xdsEinzelartikelShopQuelle: TBooleanField;
    xdsEinzelartikelGeraet: TBooleanField;
    xdsEinzelartikelArtgewicht: TFloatField;
    xdsEinzelartikelVolumen: TFloatField;
    xdsEinzelartikelPacklisteNichtAnzeigen: TBooleanField;
    xdsEinzelartikelKeineAufschlagskalkulation: TBooleanField;
    xdsEinzelartikelKorrekturMenge: TFloatField;
    xdsEinzelartikelKorrekturWert: TFloatField;
    xdsEinzelartikelNachfolgeartikel: TStringField;
    xdsEinzelartikelMindestbestellmenge: TFloatField;
    xdsEinzelartikelSchnittEK: TFloatField;
    xdsEinzelartikelVerkaufspreisVom: TDateTimeField;
    xdsEinzelartikelPruefungChargeSNR: TIntegerField;
    xdsEinzelartikelArtikelbeschreibungHTML: TStringField;
    xdsEinzelartikelProduktKurzbeschreibungHTML: TStringField;
    xdsEinzelartikelStatusFuerShop: TStringField;
    xdsEinzelartikelAmpelFuerShop: TStringField;
    xdsEinzelartikelEXP_Anzahl: TFloatField;
    xdsEinzelartikelEXP_Inhalt: TFloatField;
    xdsEinzelartikelEXP_Faktor: TFloatField;
    xdsEinzelartikelFach: TStringField;
    xdsEinzelartikelExportKasse: TIntegerField;
    xdsEinzelartikelWKNR: TIntegerField;
    xdsEinzelartikelArtikelAttribute: TStringField;
    xdsEinzelartikelErstelltAm: TDateTimeField;
    xdsEinzelartikelVarianteVonAN: TStringField;
    xdsEinzelartikelGurtmassWerte: TStringField;
    xdsEinzelartikelGurtmass: TFloatField;
    xdsEinzelartikelAltersgrenze: TIntegerField;
    xdsEinzelartikelMWStKeyImHaus: TIntegerField;
    xdsEinzelartikelKasseBewegung: TFloatField;
    xdsEinzelartikelKasseBewegungEK: TFloatField;
    xdsEinzelartikelKasseBewegungVK: TFloatField;
    xdsEinzelartikelProduktionBewegung: TFloatField;
    xdsEinzelartikelProduktionBewegungEK: TFloatField;
    xdsEinzelartikelProduktionBewegungVK: TFloatField;
    xdsEinzelartikelWaWiAB: TIntegerField;
    xdsEinzelartikelGeaendertAm: TDateTimeField;
    xdsEinzelartikelGefahrgutKennzeichen: TStringField;
    xdsEinzelartikelVerpackG: TBooleanField;
    xdsEinzelartikelUPE: TFloatField;
    xdsEinzelartikelArtikelbeschreibungEinkauf: TStringField;
    xdsEinzelartikelHerstellerArtikelnummer: TStringField;
    xdsEinzelartikelNatura: TStringField;
    xdsEinzelartikelArbeitszeit: TBooleanField;
    xdsEinzelartikelWarengruppeWarengruppe: TStringField;
    xdsEinzelartikelLaenge: TFloatField;
    xdsEinzelartikelHoehe: TFloatField;
    xdsEinzelartikelArtderMengeArtderMenge: TStringField;
    xdsEinzelartikelHerstellerHersteller: TStringField;
    xdsEinzelartikelHerstellerHerstellerID: TStringField;
    xdsEinzelartikelHerstellerHerstellerURL: TStringField;
    xdsEinzelartikelArtderMengeISO3: TStringField;
    xdsEinzelartikelArtderMengeMengenauswahl: TBooleanField;
    xdsEinzelartikelArtderMengeVGPauswahl: TBooleanField;
    xdsEinzelartikelArtderMengeUVPauswahl: TBooleanField;
    xdsEinzelartikelArtderMengeVKPDivisor: TFloatField;
    xdsEinzelartikelPfandArtPfandArt: TStringField;
    xdsEinzelartikelPfandArtPfandWert: TFloatField;
    xdsEinzelartikelPfandArtPfandwertEK: TFloatField;
    xdsEinzelartikelWarengruppeWGNR: TIntegerField;
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe: TStringField;
    xdsEinzelartikelWarenuntergruppeWarengruppe: TStringField;
    xdsEinzelartikelWarenuntergruppeWUGNR: TIntegerField;
    xdsEinzelartikelWarenuntergruppeWGNR: TIntegerField;
    WClient: TXDataWebClient;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    btnzurueck: TButton;
    WebLabel1: TLabel;
    btnSpeichern: TButton;
    edtBMenge: TEdit;
    edtArtikelnummer: TEdit;
    btnEtikettendruck: TButton;
    Container3: THTMLContainer;
    procedure edtArtikelnummerChange(Sender: TObject);
    procedure xdsEinzelartikelAfterOpen(DataSet: TDataSet);
    procedure btnEtikettendruckClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure btnSpeichernClick(Sender: TObject);
    procedure btnzurueckClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  frmArtikel: TfrmArtikel;

implementation
  uses unit1, unit2, unit_artikelsuche;

procedure TfrmArtikel.btnEtikettendruckClick(Sender: TObject);
  procedure OnResponse(Response:TXDataClientResponse);
  var
  ergebnis:string;
  begin
    ergebnis:=string(TJSObject(response.Result)['value']);
    if ergebnis='WRONG' then
    begin
      showmessage('Druckvormerkung für Etiketten konnte leider nicht durchgeführt werden');
    end
    else
    begin
      if ergebnis='OK' then
      begin
        showmessage('Etikettendruck wurde vorgemerkt');
      end
      else
      begin
        if ergebnis='Bereits vorhanden' then showmessage('Dieser Eintrag war bereits vorhanden');

      end;
    end;
  end;
begin
  if LizenzOK then
  begin
    WClient.RawInvoke('IServerService.ArtikelEtikettenDruck',[xdseinzelartikelartikelnummer.asstring],@OnResponse);
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TfrmArtikel.btnzurueckClick(Sender: TObject);
begin
  self.Close;
end;

procedure TfrmArtikel.edtArtikelnummerChange(Sender: TObject);
begin
  xdsEinzelArtikel.Close;
  xdseinzelArtikel.QueryString:='$filter=Artikelnummer eq '+QuotedStr(edtArtikelnummer.Text)+'&$expand=ArtderMenge,Hersteller';
  xdseinzelArtikel.Load;
end;

procedure TfrmArtikel.btnSpeichernClick(Sender: TObject);
  procedure OnResponse(Response:TXDataClientResponse);
  var
  ergebnis:string;
  begin
    ergebnis:=string(TJSObject(response.Result)['value']);
    if ergebnis='WRONG' then
    begin
      showmessage('Bestellvormerkung konnte leider nicht durchgeführt werden');
    end
    else
    begin
      showmessage('Bestellvormkerung erfolgreich');
      edtBMenge.Text:='';
    end;
  end;
begin
  if edtBMenge.Text='' then
  begin
    showmessage('Bestellmenge muß angegeben werden');
  end
  else
  begin
    if LizenzOK then
    begin
      WClient.RawInvoke('IServerService.ArtikelBestellvormerkung',[xdseinzelartikelartikelnummer.asstring,edtBMenge.text,xdseinzelartikelartikelueberschrift.asstring],@OnResponse);
    end
    else
    begin
      showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
    end;

  end;
end;

procedure TfrmArtikel.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Einzelartikel</h5></nav>';
  myelement:=container1.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  btnEtikettendruck.ParentElement:=myelement;
  container3.parentelement:=myelement;
  myelement:=container3.ElementHandle;
  weblabel1.ParentElement:=myelement;
  edtBMenge.ParentElement:=myelement;
  btnSpeichern.ParentElement:=myelement;
end;

procedure TfrmArtikel.xdsEinzelartikelAfterOpen(DataSet: TDataSet);
begin
  TC.RowCount:=20;
  TC.Cells[0,0]:='Bezeichnung';
  TC.Cells[1,0]:='Wert';
  TC.Cells[0,1]:='Artikelnummer';
  TC.Cells[1,1]:='<h5>'+xdsEinzelartikelArtikelnummer.asstring;
  TC.Cells[0,2]:='Artikelüberschrift';
  TC.Cells[1,2]:='<h5>'+xdsEinzelartikelArtikelueberschrift.asstring;
  TC.Cells[0,3]:='Artikelbeschreibung';
  TC.Cells[1,3]:='<h6>'+ReplaceStr(xdsEinzelartikelArtikelbeschreibung.asstring,#13+#10,'<BR>');
  TC.Cells[0,4]:='Einkaufspreis';
  if form1.rcw.Einkaufspreise=true then TC.Cells[1,4]:='<h5>'+formatfloat('#,##0.00',xdsEinzelartikelEinkaufspreis.asfloat);
  TC.Cells[0,5]:='Verkaufspreis';
  TC.Cells[1,5]:='<h5>'+formatfloat('#,##0.00',xdsEinzelartikelVerkaufspreis.asfloat);
  TC.Cells[0,6]:='Verkaufspreis (brutto)';
  TC.Cells[1,6]:='<h5><B>'+formatfloat('#,##0.00',xdsEinzelartikelVerkaufspreisBrutto.asfloat);
  TC.Cells[0,7]:='Bestand';
  TC.Cells[1,7]:='<h5>'+formatfloat('#,##0.00',xdsEinzelartikelBestand.asfloat);
  TC.Cells[0,8]:='Gewicht';
  TC.Cells[1,8]:='<h6>'+formatfloat('#,##0.0000',xdsEinzelartikelGewicht.asfloat);
  TC.Cells[0,9]:='Länge';
  TC.Cells[1,9]:='<h6>'+formatfloat('#,##0.0000',xdsEinzelartikelLaenge.asfloat);
  TC.Cells[0,10]:='Breite';
  TC.Cells[1,10]:='<h6>'+formatfloat('#,##0.0000',xdsEinzelartikelBreite.asfloat);
  TC.Cells[0,11]:='Höhe';
  TC.Cells[1,11]:='<h6>'+formatfloat('#,##0.0000',xdsEinzelartikelHoehe.asfloat);
  TC.Cells[0,12]:='Hersteller|Art.Nr.';
  TC.Cells[1,12]:='<h6>'+xdsEinzelartikelHerstellerHersteller.AsString+' | '+xdsEinzelartikelHerstellerArtikelnummer.AsString;
  TC.Cells[0,13]:='Lieferant|Art.Nr.';
  TC.Cells[1,13]:='<h6>'+xdsEinzelartikelLieferantnummer.AsString+' | '+xdsEinzelartikelArtikelnummerLieferant.asstring;
  TC.Cells[0,14]:='Lager|Fach';
  TC.Cells[1,14]:='<h6>'+xdsEinzelartikelLagerort.AsString+' | '+xdsEinzelartikelFach.asstring;
  TC.Cells[0,15]:='GTIN/EAN';
  TC.Cells[1,15]:='<h6>'+xdsEinzelartikelEAN.AsString;
  TC.Cells[0,16]:='Mindestbestand';
  TC.Cells[1,16]:='<h6>'+xdsEinzelartikelMindestbestand.AsString;
  TC.Cells[0,17]:='Mindestbestellmenge';
  TC.Cells[1,17]:='<h6>'+xdsEinzelartikelMindestbestellmenge.AsString;
  TC.Cells[0,18]:='Mengenart';
  TC.Cells[1,18]:='<h6>'+xdsEinzelartikelArtderMengeArtderMenge.AsString;
end;

procedure TfrmArtikel.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  TC := TTableControl.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  btnSpeichern := TButton.Create(Self);
  edtBMenge := TEdit.Create(Self);
  edtArtikelnummer := TEdit.Create(Self);
  btnEtikettendruck := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  xdsEinzelartikel := TXDataWebDataSet.Create(Self);
  xdsEinzelartikelHersteller := TXDataWebEntityField.Create(Self);
  xdsEinzelartikelArtderMenge := TXDataWebEntityField.Create(Self);
  xdsEinzelartikelPfandArt := TXDataWebEntityField.Create(Self);
  xdsEinzelartikelWarengruppe := TXDataWebEntityField.Create(Self);
  xdsEinzelartikelWarenuntergruppe := TXDataWebEntityField.Create(Self);
  xdsEinzelartikelArtikelnummer := TStringField.Create(Self);
  xdsEinzelartikelArtikelueberschrift := TStringField.Create(Self);
  xdsEinzelartikelArtikelbeschreibung := TStringField.Create(Self);
  xdsEinzelartikelVerkaufspreis := TFloatField.Create(Self);
  xdsEinzelartikelMindestbestand := TFloatField.Create(Self);
  xdsEinzelartikelPreisliste := TStringField.Create(Self);
  xdsEinzelartikelGewicht := TFloatField.Create(Self);
  xdsEinzelartikelBreite := TFloatField.Create(Self);
  xdsEinzelartikelArtikelZusatz := TStringField.Create(Self);
  xdsEinzelartikelLagerort := TStringField.Create(Self);
  xdsEinzelartikelBuchungskonto := TIntegerField.Create(Self);
  xdsEinzelartikelEingangMenge := TFloatField.Create(Self);
  xdsEinzelartikelEingangEndpreis := TFloatField.Create(Self);
  xdsEinzelartikelAusgangMenge := TFloatField.Create(Self);
  xdsEinzelartikelAusgangEndpreisEK := TFloatField.Create(Self);
  xdsEinzelartikelAusgangEndpreisVK := TFloatField.Create(Self);
  xdsEinzelartikelBestand := TFloatField.Create(Self);
  xdsEinzelartikelBestandswert := TFloatField.Create(Self);
  xdsEinzelartikelMarge := TFloatField.Create(Self);
  xdsEinzelartikelMargeProzent := TFloatField.Create(Self);
  xdsEinzelartikelArtikelnummer2 := TStringField.Create(Self);
  xdsEinzelartikelArtikelmatchcode := TStringField.Create(Self);
  xdsEinzelartikelRabattpreis1 := TFloatField.Create(Self);
  xdsEinzelartikelRabattpreis2 := TFloatField.Create(Self);
  xdsEinzelartikelRabattpreis3 := TFloatField.Create(Self);
  xdsEinzelartikelRabattpreis4 := TFloatField.Create(Self);
  xdsEinzelartikelRabattpreis5 := TFloatField.Create(Self);
  xdsEinzelartikelArtikelnichtmehrgueltig := TBooleanField.Create(Self);
  xdsEinzelartikelEinkaufspreis := TFloatField.Create(Self);
  xdsEinzelartikelAufschlag := TFloatField.Create(Self);
  xdsEinzelartikelWarenbestand := TBooleanField.Create(Self);
  xdsEinzelartikelTextfrei10 := TStringField.Create(Self);
  xdsEinzelartikelTextfrei20 := TStringField.Create(Self);
  xdsEinzelartikelArtikelbeschreibungRTF := TStringField.Create(Self);
  xdsEinzelartikelArtikeldatei1 := TStringField.Create(Self);
  xdsEinzelartikelArtikeldatei2 := TStringField.Create(Self);
  xdsEinzelartikelArtikeldatei3 := TStringField.Create(Self);
  xdsEinzelartikelArtikeldatei4 := TStringField.Create(Self);
  xdsEinzelartikelArtikeldatei5 := TStringField.Create(Self);
  xdsEinzelartikelEinkaufspreisVom := TDateTimeField.Create(Self);
  xdsEinzelartikelEAN := TStringField.Create(Self);
  xdsEinzelartikelVGPFaktor := TFloatField.Create(Self);
  xdsEinzelartikelVGPMengenart := TStringField.Create(Self);
  xdsEinzelartikelVerleihpreis := TFloatField.Create(Self);
  xdsEinzelartikelZusatzPreis := TFloatField.Create(Self);
  xdsEinzelartikelVerpackungsart := TStringField.Create(Self);
  xdsEinzelartikelVerpackungsMenge := TFloatField.Create(Self);
  xdsEinzelartikelQuellartikel := TStringField.Create(Self);
  xdsEinzelartikelrabattierfaehig := TBooleanField.Create(Self);
  xdsEinzelartikelAngebotAlternativeAN := TStringField.Create(Self);
  xdsEinzelartikelPreisanteilNetto := TFloatField.Create(Self);
  xdsEinzelartikelFarbe := TIntegerField.Create(Self);
  xdsEinzelartikelAEinkauf := TBooleanField.Create(Self);
  xdsEinzelartikelAVerkauf := TBooleanField.Create(Self);
  xdsEinzelartikelArtikelnummerLieferant := TStringField.Create(Self);
  xdsEinzelartikelLieferantnummer := TStringField.Create(Self);
  xdsEinzelartikelVerkaufspreisBrutto := TFloatField.Create(Self);
  xdsEinzelartikelArtikelSet := TBooleanField.Create(Self);
  xdsEinzelartikelProvisionssatz := TFloatField.Create(Self);
  xdsEinzelartikelWarentarifnummer := TStringField.Create(Self);
  xdsEinzelartikelHandelsartikel := TBooleanField.Create(Self);
  xdsEinzelartikelUrsprungsland := TStringField.Create(Self);
  xdsEinzelartikelKategorie := TStringField.Create(Self);
  xdsEinzelartikelStandardRabattsatzEK := TFloatField.Create(Self);
  xdsEinzelartikelStandardRabattsatzVK := TFloatField.Create(Self);
  xdsEinzelartikelRabattpreiseManuell := TBooleanField.Create(Self);
  xdsEinzelartikelMengeBestellt := TFloatField.Create(Self);
  xdsEinzelartikelMengeBeauftragt := TFloatField.Create(Self);
  xdsEinzelartikelBestandDisponiert := TFloatField.Create(Self);
  xdsEinzelartikelMengeAngeboten := TFloatField.Create(Self);
  xdsEinzelartikelBestandDispInclAngebot := TFloatField.Create(Self);
  xdsEinzelartikelMWStKey := TIntegerField.Create(Self);
  xdsEinzelartikelShopAmazon := TBooleanField.Create(Self);
  xdsEinzelartikelShopQuelle := TBooleanField.Create(Self);
  xdsEinzelartikelGeraet := TBooleanField.Create(Self);
  xdsEinzelartikelArtgewicht := TFloatField.Create(Self);
  xdsEinzelartikelVolumen := TFloatField.Create(Self);
  xdsEinzelartikelPacklisteNichtAnzeigen := TBooleanField.Create(Self);
  xdsEinzelartikelKeineAufschlagskalkulation := TBooleanField.Create(Self);
  xdsEinzelartikelKorrekturMenge := TFloatField.Create(Self);
  xdsEinzelartikelKorrekturWert := TFloatField.Create(Self);
  xdsEinzelartikelNachfolgeartikel := TStringField.Create(Self);
  xdsEinzelartikelMindestbestellmenge := TFloatField.Create(Self);
  xdsEinzelartikelSchnittEK := TFloatField.Create(Self);
  xdsEinzelartikelVerkaufspreisVom := TDateTimeField.Create(Self);
  xdsEinzelartikelPruefungChargeSNR := TIntegerField.Create(Self);
  xdsEinzelartikelArtikelbeschreibungHTML := TStringField.Create(Self);
  xdsEinzelartikelProduktKurzbeschreibungHTML := TStringField.Create(Self);
  xdsEinzelartikelStatusFuerShop := TStringField.Create(Self);
  xdsEinzelartikelAmpelFuerShop := TStringField.Create(Self);
  xdsEinzelartikelEXP_Anzahl := TFloatField.Create(Self);
  xdsEinzelartikelEXP_Inhalt := TFloatField.Create(Self);
  xdsEinzelartikelEXP_Faktor := TFloatField.Create(Self);
  xdsEinzelartikelFach := TStringField.Create(Self);
  xdsEinzelartikelExportKasse := TIntegerField.Create(Self);
  xdsEinzelartikelWKNR := TIntegerField.Create(Self);
  xdsEinzelartikelArtikelAttribute := TStringField.Create(Self);
  xdsEinzelartikelErstelltAm := TDateTimeField.Create(Self);
  xdsEinzelartikelVarianteVonAN := TStringField.Create(Self);
  xdsEinzelartikelGurtmassWerte := TStringField.Create(Self);
  xdsEinzelartikelGurtmass := TFloatField.Create(Self);
  xdsEinzelartikelAltersgrenze := TIntegerField.Create(Self);
  xdsEinzelartikelMWStKeyImHaus := TIntegerField.Create(Self);
  xdsEinzelartikelKasseBewegung := TFloatField.Create(Self);
  xdsEinzelartikelKasseBewegungEK := TFloatField.Create(Self);
  xdsEinzelartikelKasseBewegungVK := TFloatField.Create(Self);
  xdsEinzelartikelProduktionBewegung := TFloatField.Create(Self);
  xdsEinzelartikelProduktionBewegungEK := TFloatField.Create(Self);
  xdsEinzelartikelProduktionBewegungVK := TFloatField.Create(Self);
  xdsEinzelartikelWaWiAB := TIntegerField.Create(Self);
  xdsEinzelartikelGeaendertAm := TDateTimeField.Create(Self);
  xdsEinzelartikelGefahrgutKennzeichen := TStringField.Create(Self);
  xdsEinzelartikelVerpackG := TBooleanField.Create(Self);
  xdsEinzelartikelUPE := TFloatField.Create(Self);
  xdsEinzelartikelArtikelbeschreibungEinkauf := TStringField.Create(Self);
  xdsEinzelartikelHerstellerArtikelnummer := TStringField.Create(Self);
  xdsEinzelartikelNatura := TStringField.Create(Self);
  xdsEinzelartikelArbeitszeit := TBooleanField.Create(Self);
  xdsEinzelartikelWarengruppeWarengruppe := TStringField.Create(Self);
  xdsEinzelartikelLaenge := TFloatField.Create(Self);
  xdsEinzelartikelHoehe := TFloatField.Create(Self);
  xdsEinzelartikelArtderMengeArtderMenge := TStringField.Create(Self);
  xdsEinzelartikelHerstellerHersteller := TStringField.Create(Self);
  xdsEinzelartikelHerstellerHerstellerID := TStringField.Create(Self);
  xdsEinzelartikelHerstellerHerstellerURL := TStringField.Create(Self);
  xdsEinzelartikelArtderMengeISO3 := TStringField.Create(Self);
  xdsEinzelartikelArtderMengeMengenauswahl := TBooleanField.Create(Self);
  xdsEinzelartikelArtderMengeVGPauswahl := TBooleanField.Create(Self);
  xdsEinzelartikelArtderMengeUVPauswahl := TBooleanField.Create(Self);
  xdsEinzelartikelArtderMengeVKPDivisor := TFloatField.Create(Self);
  xdsEinzelartikelPfandArtPfandArt := TStringField.Create(Self);
  xdsEinzelartikelPfandArtPfandWert := TFloatField.Create(Self);
  xdsEinzelartikelPfandArtPfandwertEK := TFloatField.Create(Self);
  xdsEinzelartikelWarengruppeWGNR := TIntegerField.Create(Self);
  xdsEinzelartikelWarenuntergruppeWarenuntergruppe := TStringField.Create(Self);
  xdsEinzelartikelWarenuntergruppeWarengruppe := TStringField.Create(Self);
  xdsEinzelartikelWarenuntergruppeWUGNR := TIntegerField.Create(Self);
  xdsEinzelartikelWarenuntergruppeWGNR := TIntegerField.Create(Self);
  sourceEinzelartikel := TDataSource.Create(Self);
  WClient := TXDataWebClient.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  TC.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  btnSpeichern.BeforeLoadDFMValues;
  edtBMenge.BeforeLoadDFMValues;
  edtArtikelnummer.BeforeLoadDFMValues;
  btnEtikettendruck.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  xdsEinzelartikel.BeforeLoadDFMValues;
  xdsEinzelartikelHersteller.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMenge.BeforeLoadDFMValues;
  xdsEinzelartikelPfandArt.BeforeLoadDFMValues;
  xdsEinzelartikelWarengruppe.BeforeLoadDFMValues;
  xdsEinzelartikelWarenuntergruppe.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelnummer.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelueberschrift.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelbeschreibung.BeforeLoadDFMValues;
  xdsEinzelartikelVerkaufspreis.BeforeLoadDFMValues;
  xdsEinzelartikelMindestbestand.BeforeLoadDFMValues;
  xdsEinzelartikelPreisliste.BeforeLoadDFMValues;
  xdsEinzelartikelGewicht.BeforeLoadDFMValues;
  xdsEinzelartikelBreite.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelZusatz.BeforeLoadDFMValues;
  xdsEinzelartikelLagerort.BeforeLoadDFMValues;
  xdsEinzelartikelBuchungskonto.BeforeLoadDFMValues;
  xdsEinzelartikelEingangMenge.BeforeLoadDFMValues;
  xdsEinzelartikelEingangEndpreis.BeforeLoadDFMValues;
  xdsEinzelartikelAusgangMenge.BeforeLoadDFMValues;
  xdsEinzelartikelAusgangEndpreisEK.BeforeLoadDFMValues;
  xdsEinzelartikelAusgangEndpreisVK.BeforeLoadDFMValues;
  xdsEinzelartikelBestand.BeforeLoadDFMValues;
  xdsEinzelartikelBestandswert.BeforeLoadDFMValues;
  xdsEinzelartikelMarge.BeforeLoadDFMValues;
  xdsEinzelartikelMargeProzent.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelnummer2.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelmatchcode.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreis1.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreis2.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreis3.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreis4.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreis5.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelnichtmehrgueltig.BeforeLoadDFMValues;
  xdsEinzelartikelEinkaufspreis.BeforeLoadDFMValues;
  xdsEinzelartikelAufschlag.BeforeLoadDFMValues;
  xdsEinzelartikelWarenbestand.BeforeLoadDFMValues;
  xdsEinzelartikelTextfrei10.BeforeLoadDFMValues;
  xdsEinzelartikelTextfrei20.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelbeschreibungRTF.BeforeLoadDFMValues;
  xdsEinzelartikelArtikeldatei1.BeforeLoadDFMValues;
  xdsEinzelartikelArtikeldatei2.BeforeLoadDFMValues;
  xdsEinzelartikelArtikeldatei3.BeforeLoadDFMValues;
  xdsEinzelartikelArtikeldatei4.BeforeLoadDFMValues;
  xdsEinzelartikelArtikeldatei5.BeforeLoadDFMValues;
  xdsEinzelartikelEinkaufspreisVom.BeforeLoadDFMValues;
  xdsEinzelartikelEAN.BeforeLoadDFMValues;
  xdsEinzelartikelVGPFaktor.BeforeLoadDFMValues;
  xdsEinzelartikelVGPMengenart.BeforeLoadDFMValues;
  xdsEinzelartikelVerleihpreis.BeforeLoadDFMValues;
  xdsEinzelartikelZusatzPreis.BeforeLoadDFMValues;
  xdsEinzelartikelVerpackungsart.BeforeLoadDFMValues;
  xdsEinzelartikelVerpackungsMenge.BeforeLoadDFMValues;
  xdsEinzelartikelQuellartikel.BeforeLoadDFMValues;
  xdsEinzelartikelrabattierfaehig.BeforeLoadDFMValues;
  xdsEinzelartikelAngebotAlternativeAN.BeforeLoadDFMValues;
  xdsEinzelartikelPreisanteilNetto.BeforeLoadDFMValues;
  xdsEinzelartikelFarbe.BeforeLoadDFMValues;
  xdsEinzelartikelAEinkauf.BeforeLoadDFMValues;
  xdsEinzelartikelAVerkauf.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelnummerLieferant.BeforeLoadDFMValues;
  xdsEinzelartikelLieferantnummer.BeforeLoadDFMValues;
  xdsEinzelartikelVerkaufspreisBrutto.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelSet.BeforeLoadDFMValues;
  xdsEinzelartikelProvisionssatz.BeforeLoadDFMValues;
  xdsEinzelartikelWarentarifnummer.BeforeLoadDFMValues;
  xdsEinzelartikelHandelsartikel.BeforeLoadDFMValues;
  xdsEinzelartikelUrsprungsland.BeforeLoadDFMValues;
  xdsEinzelartikelKategorie.BeforeLoadDFMValues;
  xdsEinzelartikelStandardRabattsatzEK.BeforeLoadDFMValues;
  xdsEinzelartikelStandardRabattsatzVK.BeforeLoadDFMValues;
  xdsEinzelartikelRabattpreiseManuell.BeforeLoadDFMValues;
  xdsEinzelartikelMengeBestellt.BeforeLoadDFMValues;
  xdsEinzelartikelMengeBeauftragt.BeforeLoadDFMValues;
  xdsEinzelartikelBestandDisponiert.BeforeLoadDFMValues;
  xdsEinzelartikelMengeAngeboten.BeforeLoadDFMValues;
  xdsEinzelartikelBestandDispInclAngebot.BeforeLoadDFMValues;
  xdsEinzelartikelMWStKey.BeforeLoadDFMValues;
  xdsEinzelartikelShopAmazon.BeforeLoadDFMValues;
  xdsEinzelartikelShopQuelle.BeforeLoadDFMValues;
  xdsEinzelartikelGeraet.BeforeLoadDFMValues;
  xdsEinzelartikelArtgewicht.BeforeLoadDFMValues;
  xdsEinzelartikelVolumen.BeforeLoadDFMValues;
  xdsEinzelartikelPacklisteNichtAnzeigen.BeforeLoadDFMValues;
  xdsEinzelartikelKeineAufschlagskalkulation.BeforeLoadDFMValues;
  xdsEinzelartikelKorrekturMenge.BeforeLoadDFMValues;
  xdsEinzelartikelKorrekturWert.BeforeLoadDFMValues;
  xdsEinzelartikelNachfolgeartikel.BeforeLoadDFMValues;
  xdsEinzelartikelMindestbestellmenge.BeforeLoadDFMValues;
  xdsEinzelartikelSchnittEK.BeforeLoadDFMValues;
  xdsEinzelartikelVerkaufspreisVom.BeforeLoadDFMValues;
  xdsEinzelartikelPruefungChargeSNR.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelbeschreibungHTML.BeforeLoadDFMValues;
  xdsEinzelartikelProduktKurzbeschreibungHTML.BeforeLoadDFMValues;
  xdsEinzelartikelStatusFuerShop.BeforeLoadDFMValues;
  xdsEinzelartikelAmpelFuerShop.BeforeLoadDFMValues;
  xdsEinzelartikelEXP_Anzahl.BeforeLoadDFMValues;
  xdsEinzelartikelEXP_Inhalt.BeforeLoadDFMValues;
  xdsEinzelartikelEXP_Faktor.BeforeLoadDFMValues;
  xdsEinzelartikelFach.BeforeLoadDFMValues;
  xdsEinzelartikelExportKasse.BeforeLoadDFMValues;
  xdsEinzelartikelWKNR.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelAttribute.BeforeLoadDFMValues;
  xdsEinzelartikelErstelltAm.BeforeLoadDFMValues;
  xdsEinzelartikelVarianteVonAN.BeforeLoadDFMValues;
  xdsEinzelartikelGurtmassWerte.BeforeLoadDFMValues;
  xdsEinzelartikelGurtmass.BeforeLoadDFMValues;
  xdsEinzelartikelAltersgrenze.BeforeLoadDFMValues;
  xdsEinzelartikelMWStKeyImHaus.BeforeLoadDFMValues;
  xdsEinzelartikelKasseBewegung.BeforeLoadDFMValues;
  xdsEinzelartikelKasseBewegungEK.BeforeLoadDFMValues;
  xdsEinzelartikelKasseBewegungVK.BeforeLoadDFMValues;
  xdsEinzelartikelProduktionBewegung.BeforeLoadDFMValues;
  xdsEinzelartikelProduktionBewegungEK.BeforeLoadDFMValues;
  xdsEinzelartikelProduktionBewegungVK.BeforeLoadDFMValues;
  xdsEinzelartikelWaWiAB.BeforeLoadDFMValues;
  xdsEinzelartikelGeaendertAm.BeforeLoadDFMValues;
  xdsEinzelartikelGefahrgutKennzeichen.BeforeLoadDFMValues;
  xdsEinzelartikelVerpackG.BeforeLoadDFMValues;
  xdsEinzelartikelUPE.BeforeLoadDFMValues;
  xdsEinzelartikelArtikelbeschreibungEinkauf.BeforeLoadDFMValues;
  xdsEinzelartikelHerstellerArtikelnummer.BeforeLoadDFMValues;
  xdsEinzelartikelNatura.BeforeLoadDFMValues;
  xdsEinzelartikelArbeitszeit.BeforeLoadDFMValues;
  xdsEinzelartikelWarengruppeWarengruppe.BeforeLoadDFMValues;
  xdsEinzelartikelLaenge.BeforeLoadDFMValues;
  xdsEinzelartikelHoehe.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeArtderMenge.BeforeLoadDFMValues;
  xdsEinzelartikelHerstellerHersteller.BeforeLoadDFMValues;
  xdsEinzelartikelHerstellerHerstellerID.BeforeLoadDFMValues;
  xdsEinzelartikelHerstellerHerstellerURL.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeISO3.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeMengenauswahl.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeVGPauswahl.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeUVPauswahl.BeforeLoadDFMValues;
  xdsEinzelartikelArtderMengeVKPDivisor.BeforeLoadDFMValues;
  xdsEinzelartikelPfandArtPfandArt.BeforeLoadDFMValues;
  xdsEinzelartikelPfandArtPfandWert.BeforeLoadDFMValues;
  xdsEinzelartikelPfandArtPfandwertEK.BeforeLoadDFMValues;
  xdsEinzelartikelWarengruppeWGNR.BeforeLoadDFMValues;
  xdsEinzelartikelWarenuntergruppeWarenuntergruppe.BeforeLoadDFMValues;
  xdsEinzelartikelWarenuntergruppeWarengruppe.BeforeLoadDFMValues;
  xdsEinzelartikelWarenuntergruppeWUGNR.BeforeLoadDFMValues;
  xdsEinzelartikelWarenuntergruppeWGNR.BeforeLoadDFMValues;
  sourceEinzelartikel.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  try
    Name := 'frmArtikel';
    Width := 269;
    Height := 290;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 166;
    WebLabel1.Width := 127;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'Bestellvormerkung Menge:';
    WebLabel1.ElementClassName := 'h6 text-primary m-2';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.WidthStyle := ssAuto;
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 82;
    TC.Width := 269;
    TC.Height := 208;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.HeightStyle := ssAuto;
    TC.WidthStyle := ssAuto;
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 2;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementPosition := epRelative;
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 269;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 41;
    Container2.Width := 269;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.Align := alTop;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 8;
    btnzurueck.Top := 135;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    btnSpeichern.SetParentComponent(Self);
    btnSpeichern.Name := 'btnSpeichern';
    btnSpeichern.Left := 8;
    btnSpeichern.Top := 210;
    btnSpeichern.Width := 96;
    btnSpeichern.Height := 25;
    btnSpeichern.Caption := 'speichern';
    btnSpeichern.ChildOrder := 6;
    btnSpeichern.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnSpeichern.ElementFont := efCSS;
    btnSpeichern.HeightStyle := ssAuto;
    btnSpeichern.WidthStyle := ssAuto;
    SetEvent(btnSpeichern, Self, 'OnClick', 'btnSpeichernClick');
    edtBMenge.SetParentComponent(Self);
    edtBMenge.Name := 'edtBMenge';
    edtBMenge.Left := 8;
    edtBMenge.Top := 185;
    edtBMenge.Width := 121;
    edtBMenge.Height := 19;
    edtBMenge.ChildOrder := 2;
    edtBMenge.EditType := weFloat;
    edtBMenge.ElementClassName := 'form-control input-sm';
    edtBMenge.ElementFont := efCSS;
    edtBMenge.HeightStyle := ssAuto;
    edtBMenge.TextHint := 'eintragen';
    edtArtikelnummer.SetParentComponent(Self);
    edtArtikelnummer.Name := 'edtArtikelnummer';
    edtArtikelnummer.Left := 110;
    edtArtikelnummer.Top := 210;
    edtArtikelnummer.Width := 121;
    edtArtikelnummer.Height := 19;
    edtArtikelnummer.ChildOrder := 8;
    edtArtikelnummer.ElementClassName := 'form-control';
    edtArtikelnummer.ElementFont := efCSS;
    edtArtikelnummer.Text := 'edtArtikelnummer';
    edtArtikelnummer.Visible := False;
    SetEvent(edtArtikelnummer, Self, 'OnChange', 'edtArtikelnummerChange');
    btnEtikettendruck.SetParentComponent(Self);
    btnEtikettendruck.Name := 'btnEtikettendruck';
    btnEtikettendruck.Left := 110;
    btnEtikettendruck.Top := 149;
    btnEtikettendruck.Width := 96;
    btnEtikettendruck.Height := 25;
    btnEtikettendruck.Caption := 'Etikettendruck';
    btnEtikettendruck.ChildOrder := 4;
    btnEtikettendruck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnEtikettendruck.ElementFont := efCSS;
    btnEtikettendruck.HeightStyle := ssAuto;
    btnEtikettendruck.WidthStyle := ssAuto;
    SetEvent(btnEtikettendruck, Self, 'OnClick', 'btnEtikettendruckClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 8;
    Container3.Top := 102;
    Container3.Width := 169;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    xdsEinzelartikel.SetParentComponent(Self);
    xdsEinzelartikel.Name := 'xdsEinzelartikel';
    xdsEinzelartikel.AfterOpen := xdsEinzelartikelAfterOpen;
    xdsEinzelartikel.EntitySetName := 'Artikel';
    xdsEinzelartikel.Connection := DM2.XDataWebConnection1;
    xdsEinzelartikel.SubpropsDepth := 1;
    xdsEinzelartikel.QueryTop := 1;
    xdsEinzelartikel.Left := 100;
    xdsEinzelartikel.Top := 21;
    xdsEinzelartikelHersteller.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHersteller.Name := 'xdsEinzelartikelHersteller';
    xdsEinzelartikelHersteller.FieldName := 'Hersteller';
    xdsEinzelartikelHersteller.Required := True;
    xdsEinzelartikelArtderMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMenge.Name := 'xdsEinzelartikelArtderMenge';
    xdsEinzelartikelArtderMenge.FieldName := 'ArtderMenge';
    xdsEinzelartikelArtderMenge.Required := True;
    xdsEinzelartikelPfandArt.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPfandArt.Name := 'xdsEinzelartikelPfandArt';
    xdsEinzelartikelPfandArt.FieldName := 'PfandArt';
    xdsEinzelartikelWarengruppe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarengruppe.Name := 'xdsEinzelartikelWarengruppe';
    xdsEinzelartikelWarengruppe.FieldName := 'Warengruppe';
    xdsEinzelartikelWarenuntergruppe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenuntergruppe.Name := 'xdsEinzelartikelWarenuntergruppe';
    xdsEinzelartikelWarenuntergruppe.FieldName := 'Warenuntergruppe';
    xdsEinzelartikelArtikelnummer.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelnummer.Name := 'xdsEinzelartikelArtikelnummer';
    xdsEinzelartikelArtikelnummer.FieldName := 'Artikelnummer';
    xdsEinzelartikelArtikelnummer.Required := True;
    xdsEinzelartikelArtikelnummer.Size := 25;
    xdsEinzelartikelArtikelueberschrift.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelueberschrift.Name := 'xdsEinzelartikelArtikelueberschrift';
    xdsEinzelartikelArtikelueberschrift.FieldName := 'Artikelueberschrift';
    xdsEinzelartikelArtikelueberschrift.Size := 50;
    xdsEinzelartikelArtikelbeschreibung.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelbeschreibung.Name := 'xdsEinzelartikelArtikelbeschreibung';
    xdsEinzelartikelArtikelbeschreibung.FieldName := 'Artikelbeschreibung';
    xdsEinzelartikelArtikelbeschreibung.Size := 255;
    xdsEinzelartikelVerkaufspreis.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerkaufspreis.Name := 'xdsEinzelartikelVerkaufspreis';
    xdsEinzelartikelVerkaufspreis.FieldName := 'Verkaufspreis';
    xdsEinzelartikelMindestbestand.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMindestbestand.Name := 'xdsEinzelartikelMindestbestand';
    xdsEinzelartikelMindestbestand.FieldName := 'Mindestbestand';
    xdsEinzelartikelPreisliste.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPreisliste.Name := 'xdsEinzelartikelPreisliste';
    xdsEinzelartikelPreisliste.FieldName := 'Preisliste';
    xdsEinzelartikelPreisliste.Size := 150;
    xdsEinzelartikelGewicht.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGewicht.Name := 'xdsEinzelartikelGewicht';
    xdsEinzelartikelGewicht.FieldName := 'Gewicht';
    xdsEinzelartikelBreite.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBreite.Name := 'xdsEinzelartikelBreite';
    xdsEinzelartikelBreite.FieldName := 'Breite';
    xdsEinzelartikelArtikelZusatz.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelZusatz.Name := 'xdsEinzelartikelArtikelZusatz';
    xdsEinzelartikelArtikelZusatz.FieldName := 'ArtikelZusatz';
    xdsEinzelartikelArtikelZusatz.Size := 255;
    xdsEinzelartikelLagerort.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelLagerort.Name := 'xdsEinzelartikelLagerort';
    xdsEinzelartikelLagerort.FieldName := 'Lagerort';
    xdsEinzelartikelLagerort.Size := 50;
    xdsEinzelartikelBuchungskonto.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBuchungskonto.Name := 'xdsEinzelartikelBuchungskonto';
    xdsEinzelartikelBuchungskonto.FieldName := 'Buchungskonto';
    xdsEinzelartikelEingangMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEingangMenge.Name := 'xdsEinzelartikelEingangMenge';
    xdsEinzelartikelEingangMenge.FieldName := 'EingangMenge';
    xdsEinzelartikelEingangEndpreis.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEingangEndpreis.Name := 'xdsEinzelartikelEingangEndpreis';
    xdsEinzelartikelEingangEndpreis.FieldName := 'EingangEndpreis';
    xdsEinzelartikelAusgangMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAusgangMenge.Name := 'xdsEinzelartikelAusgangMenge';
    xdsEinzelartikelAusgangMenge.FieldName := 'AusgangMenge';
    xdsEinzelartikelAusgangEndpreisEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAusgangEndpreisEK.Name := 'xdsEinzelartikelAusgangEndpreisEK';
    xdsEinzelartikelAusgangEndpreisEK.FieldName := 'AusgangEndpreisEK';
    xdsEinzelartikelAusgangEndpreisVK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAusgangEndpreisVK.Name := 'xdsEinzelartikelAusgangEndpreisVK';
    xdsEinzelartikelAusgangEndpreisVK.FieldName := 'AusgangEndpreisVK';
    xdsEinzelartikelBestand.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBestand.Name := 'xdsEinzelartikelBestand';
    xdsEinzelartikelBestand.FieldName := 'Bestand';
    xdsEinzelartikelBestandswert.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBestandswert.Name := 'xdsEinzelartikelBestandswert';
    xdsEinzelartikelBestandswert.FieldName := 'Bestandswert';
    xdsEinzelartikelMarge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMarge.Name := 'xdsEinzelartikelMarge';
    xdsEinzelartikelMarge.FieldName := 'Marge';
    xdsEinzelartikelMargeProzent.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMargeProzent.Name := 'xdsEinzelartikelMargeProzent';
    xdsEinzelartikelMargeProzent.FieldName := 'MargeProzent';
    xdsEinzelartikelArtikelnummer2.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelnummer2.Name := 'xdsEinzelartikelArtikelnummer2';
    xdsEinzelartikelArtikelnummer2.FieldName := 'Artikelnummer2';
    xdsEinzelartikelArtikelnummer2.Size := 25;
    xdsEinzelartikelArtikelmatchcode.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelmatchcode.Name := 'xdsEinzelartikelArtikelmatchcode';
    xdsEinzelartikelArtikelmatchcode.FieldName := 'Artikelmatchcode';
    xdsEinzelartikelArtikelmatchcode.Size := 30;
    xdsEinzelartikelRabattpreis1.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreis1.Name := 'xdsEinzelartikelRabattpreis1';
    xdsEinzelartikelRabattpreis1.FieldName := 'Rabattpreis1';
    xdsEinzelartikelRabattpreis2.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreis2.Name := 'xdsEinzelartikelRabattpreis2';
    xdsEinzelartikelRabattpreis2.FieldName := 'Rabattpreis2';
    xdsEinzelartikelRabattpreis3.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreis3.Name := 'xdsEinzelartikelRabattpreis3';
    xdsEinzelartikelRabattpreis3.FieldName := 'Rabattpreis3';
    xdsEinzelartikelRabattpreis4.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreis4.Name := 'xdsEinzelartikelRabattpreis4';
    xdsEinzelartikelRabattpreis4.FieldName := 'Rabattpreis4';
    xdsEinzelartikelRabattpreis5.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreis5.Name := 'xdsEinzelartikelRabattpreis5';
    xdsEinzelartikelRabattpreis5.FieldName := 'Rabattpreis5';
    xdsEinzelartikelArtikelnichtmehrgueltig.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelnichtmehrgueltig.Name := 'xdsEinzelartikelArtikelnichtmehrgueltig';
    xdsEinzelartikelArtikelnichtmehrgueltig.FieldName := 'Artikelnichtmehrgueltig';
    xdsEinzelartikelEinkaufspreis.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEinkaufspreis.Name := 'xdsEinzelartikelEinkaufspreis';
    xdsEinzelartikelEinkaufspreis.FieldName := 'Einkaufspreis';
    xdsEinzelartikelAufschlag.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAufschlag.Name := 'xdsEinzelartikelAufschlag';
    xdsEinzelartikelAufschlag.FieldName := 'Aufschlag';
    xdsEinzelartikelWarenbestand.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenbestand.Name := 'xdsEinzelartikelWarenbestand';
    xdsEinzelartikelWarenbestand.FieldName := 'Warenbestand';
    xdsEinzelartikelTextfrei10.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelTextfrei10.Name := 'xdsEinzelartikelTextfrei10';
    xdsEinzelartikelTextfrei10.FieldName := 'Textfrei10';
    xdsEinzelartikelTextfrei10.Size := 50;
    xdsEinzelartikelTextfrei20.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelTextfrei20.Name := 'xdsEinzelartikelTextfrei20';
    xdsEinzelartikelTextfrei20.FieldName := 'Textfrei20';
    xdsEinzelartikelTextfrei20.Size := 50;
    xdsEinzelartikelArtikelbeschreibungRTF.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelbeschreibungRTF.Name := 'xdsEinzelartikelArtikelbeschreibungRTF';
    xdsEinzelartikelArtikelbeschreibungRTF.FieldName := 'ArtikelbeschreibungRTF';
    xdsEinzelartikelArtikelbeschreibungRTF.Size := 255;
    xdsEinzelartikelArtikeldatei1.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikeldatei1.Name := 'xdsEinzelartikelArtikeldatei1';
    xdsEinzelartikelArtikeldatei1.FieldName := 'Artikeldatei1';
    xdsEinzelartikelArtikeldatei1.Size := 255;
    xdsEinzelartikelArtikeldatei2.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikeldatei2.Name := 'xdsEinzelartikelArtikeldatei2';
    xdsEinzelartikelArtikeldatei2.FieldName := 'Artikeldatei2';
    xdsEinzelartikelArtikeldatei2.Size := 255;
    xdsEinzelartikelArtikeldatei3.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikeldatei3.Name := 'xdsEinzelartikelArtikeldatei3';
    xdsEinzelartikelArtikeldatei3.FieldName := 'Artikeldatei3';
    xdsEinzelartikelArtikeldatei3.Size := 255;
    xdsEinzelartikelArtikeldatei4.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikeldatei4.Name := 'xdsEinzelartikelArtikeldatei4';
    xdsEinzelartikelArtikeldatei4.FieldName := 'Artikeldatei4';
    xdsEinzelartikelArtikeldatei4.Size := 255;
    xdsEinzelartikelArtikeldatei5.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikeldatei5.Name := 'xdsEinzelartikelArtikeldatei5';
    xdsEinzelartikelArtikeldatei5.FieldName := 'Artikeldatei5';
    xdsEinzelartikelArtikeldatei5.Size := 255;
    xdsEinzelartikelEinkaufspreisVom.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEinkaufspreisVom.Name := 'xdsEinzelartikelEinkaufspreisVom';
    xdsEinzelartikelEinkaufspreisVom.FieldName := 'EinkaufspreisVom';
    xdsEinzelartikelEAN.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEAN.Name := 'xdsEinzelartikelEAN';
    xdsEinzelartikelEAN.FieldName := 'EAN';
    xdsEinzelartikelEAN.Size := 15;
    xdsEinzelartikelVGPFaktor.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVGPFaktor.Name := 'xdsEinzelartikelVGPFaktor';
    xdsEinzelartikelVGPFaktor.FieldName := 'VGPFaktor';
    xdsEinzelartikelVGPMengenart.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVGPMengenart.Name := 'xdsEinzelartikelVGPMengenart';
    xdsEinzelartikelVGPMengenart.FieldName := 'VGPMengenart';
    xdsEinzelartikelVGPMengenart.Size := 50;
    xdsEinzelartikelVerleihpreis.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerleihpreis.Name := 'xdsEinzelartikelVerleihpreis';
    xdsEinzelartikelVerleihpreis.FieldName := 'Verleihpreis';
    xdsEinzelartikelZusatzPreis.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelZusatzPreis.Name := 'xdsEinzelartikelZusatzPreis';
    xdsEinzelartikelZusatzPreis.FieldName := 'ZusatzPreis';
    xdsEinzelartikelVerpackungsart.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerpackungsart.Name := 'xdsEinzelartikelVerpackungsart';
    xdsEinzelartikelVerpackungsart.FieldName := 'Verpackungsart';
    xdsEinzelartikelVerpackungsart.Size := 50;
    xdsEinzelartikelVerpackungsMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerpackungsMenge.Name := 'xdsEinzelartikelVerpackungsMenge';
    xdsEinzelartikelVerpackungsMenge.FieldName := 'VerpackungsMenge';
    xdsEinzelartikelQuellartikel.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelQuellartikel.Name := 'xdsEinzelartikelQuellartikel';
    xdsEinzelartikelQuellartikel.FieldName := 'Quellartikel';
    xdsEinzelartikelQuellartikel.Size := 25;
    xdsEinzelartikelrabattierfaehig.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelrabattierfaehig.Name := 'xdsEinzelartikelrabattierfaehig';
    xdsEinzelartikelrabattierfaehig.FieldName := 'rabattierfaehig';
    xdsEinzelartikelAngebotAlternativeAN.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAngebotAlternativeAN.Name := 'xdsEinzelartikelAngebotAlternativeAN';
    xdsEinzelartikelAngebotAlternativeAN.FieldName := 'AngebotAlternativeAN';
    xdsEinzelartikelAngebotAlternativeAN.Size := 25;
    xdsEinzelartikelPreisanteilNetto.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPreisanteilNetto.Name := 'xdsEinzelartikelPreisanteilNetto';
    xdsEinzelartikelPreisanteilNetto.FieldName := 'PreisanteilNetto';
    xdsEinzelartikelFarbe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelFarbe.Name := 'xdsEinzelartikelFarbe';
    xdsEinzelartikelFarbe.FieldName := 'Farbe';
    xdsEinzelartikelAEinkauf.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAEinkauf.Name := 'xdsEinzelartikelAEinkauf';
    xdsEinzelartikelAEinkauf.FieldName := 'AEinkauf';
    xdsEinzelartikelAVerkauf.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAVerkauf.Name := 'xdsEinzelartikelAVerkauf';
    xdsEinzelartikelAVerkauf.FieldName := 'AVerkauf';
    xdsEinzelartikelArtikelnummerLieferant.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelnummerLieferant.Name := 'xdsEinzelartikelArtikelnummerLieferant';
    xdsEinzelartikelArtikelnummerLieferant.FieldName := 'ArtikelnummerLieferant';
    xdsEinzelartikelLieferantnummer.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelLieferantnummer.Name := 'xdsEinzelartikelLieferantnummer';
    xdsEinzelartikelLieferantnummer.FieldName := 'Lieferantnummer';
    xdsEinzelartikelLieferantnummer.Size := 13;
    xdsEinzelartikelVerkaufspreisBrutto.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerkaufspreisBrutto.Name := 'xdsEinzelartikelVerkaufspreisBrutto';
    xdsEinzelartikelVerkaufspreisBrutto.FieldName := 'VerkaufspreisBrutto';
    xdsEinzelartikelArtikelSet.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelSet.Name := 'xdsEinzelartikelArtikelSet';
    xdsEinzelartikelArtikelSet.FieldName := 'ArtikelSet';
    xdsEinzelartikelProvisionssatz.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelProvisionssatz.Name := 'xdsEinzelartikelProvisionssatz';
    xdsEinzelartikelProvisionssatz.FieldName := 'Provisionssatz';
    xdsEinzelartikelWarentarifnummer.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarentarifnummer.Name := 'xdsEinzelartikelWarentarifnummer';
    xdsEinzelartikelWarentarifnummer.FieldName := 'Warentarifnummer';
    xdsEinzelartikelHandelsartikel.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHandelsartikel.Name := 'xdsEinzelartikelHandelsartikel';
    xdsEinzelartikelHandelsartikel.FieldName := 'Handelsartikel';
    xdsEinzelartikelUrsprungsland.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelUrsprungsland.Name := 'xdsEinzelartikelUrsprungsland';
    xdsEinzelartikelUrsprungsland.FieldName := 'Ursprungsland';
    xdsEinzelartikelUrsprungsland.Size := 5;
    xdsEinzelartikelKategorie.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKategorie.Name := 'xdsEinzelartikelKategorie';
    xdsEinzelartikelKategorie.FieldName := 'Kategorie';
    xdsEinzelartikelKategorie.Size := 25;
    xdsEinzelartikelStandardRabattsatzEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelStandardRabattsatzEK.Name := 'xdsEinzelartikelStandardRabattsatzEK';
    xdsEinzelartikelStandardRabattsatzEK.FieldName := 'StandardRabattsatzEK';
    xdsEinzelartikelStandardRabattsatzVK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelStandardRabattsatzVK.Name := 'xdsEinzelartikelStandardRabattsatzVK';
    xdsEinzelartikelStandardRabattsatzVK.FieldName := 'StandardRabattsatzVK';
    xdsEinzelartikelRabattpreiseManuell.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelRabattpreiseManuell.Name := 'xdsEinzelartikelRabattpreiseManuell';
    xdsEinzelartikelRabattpreiseManuell.FieldName := 'RabattpreiseManuell';
    xdsEinzelartikelMengeBestellt.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMengeBestellt.Name := 'xdsEinzelartikelMengeBestellt';
    xdsEinzelartikelMengeBestellt.FieldName := 'MengeBestellt';
    xdsEinzelartikelMengeBeauftragt.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMengeBeauftragt.Name := 'xdsEinzelartikelMengeBeauftragt';
    xdsEinzelartikelMengeBeauftragt.FieldName := 'MengeBeauftragt';
    xdsEinzelartikelBestandDisponiert.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBestandDisponiert.Name := 'xdsEinzelartikelBestandDisponiert';
    xdsEinzelartikelBestandDisponiert.FieldName := 'BestandDisponiert';
    xdsEinzelartikelMengeAngeboten.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMengeAngeboten.Name := 'xdsEinzelartikelMengeAngeboten';
    xdsEinzelartikelMengeAngeboten.FieldName := 'MengeAngeboten';
    xdsEinzelartikelBestandDispInclAngebot.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelBestandDispInclAngebot.Name := 'xdsEinzelartikelBestandDispInclAngebot';
    xdsEinzelartikelBestandDispInclAngebot.FieldName := 'BestandDispInclAngebot';
    xdsEinzelartikelMWStKey.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMWStKey.Name := 'xdsEinzelartikelMWStKey';
    xdsEinzelartikelMWStKey.FieldName := 'MWStKey';
    xdsEinzelartikelShopAmazon.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelShopAmazon.Name := 'xdsEinzelartikelShopAmazon';
    xdsEinzelartikelShopAmazon.FieldName := 'ShopAmazon';
    xdsEinzelartikelShopQuelle.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelShopQuelle.Name := 'xdsEinzelartikelShopQuelle';
    xdsEinzelartikelShopQuelle.FieldName := 'ShopQuelle';
    xdsEinzelartikelGeraet.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGeraet.Name := 'xdsEinzelartikelGeraet';
    xdsEinzelartikelGeraet.FieldName := 'Geraet';
    xdsEinzelartikelArtgewicht.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtgewicht.Name := 'xdsEinzelartikelArtgewicht';
    xdsEinzelartikelArtgewicht.FieldName := 'Artgewicht';
    xdsEinzelartikelVolumen.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVolumen.Name := 'xdsEinzelartikelVolumen';
    xdsEinzelartikelVolumen.FieldName := 'Volumen';
    xdsEinzelartikelPacklisteNichtAnzeigen.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPacklisteNichtAnzeigen.Name := 'xdsEinzelartikelPacklisteNichtAnzeigen';
    xdsEinzelartikelPacklisteNichtAnzeigen.FieldName := 'PacklisteNichtAnzeigen';
    xdsEinzelartikelKeineAufschlagskalkulation.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKeineAufschlagskalkulation.Name := 'xdsEinzelartikelKeineAufschlagskalkulation';
    xdsEinzelartikelKeineAufschlagskalkulation.FieldName := 'KeineAufschlagskalkulation';
    xdsEinzelartikelKorrekturMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKorrekturMenge.Name := 'xdsEinzelartikelKorrekturMenge';
    xdsEinzelartikelKorrekturMenge.FieldName := 'KorrekturMenge';
    xdsEinzelartikelKorrekturWert.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKorrekturWert.Name := 'xdsEinzelartikelKorrekturWert';
    xdsEinzelartikelKorrekturWert.FieldName := 'KorrekturWert';
    xdsEinzelartikelNachfolgeartikel.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelNachfolgeartikel.Name := 'xdsEinzelartikelNachfolgeartikel';
    xdsEinzelartikelNachfolgeartikel.FieldName := 'Nachfolgeartikel';
    xdsEinzelartikelNachfolgeartikel.Size := 25;
    xdsEinzelartikelMindestbestellmenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMindestbestellmenge.Name := 'xdsEinzelartikelMindestbestellmenge';
    xdsEinzelartikelMindestbestellmenge.FieldName := 'Mindestbestellmenge';
    xdsEinzelartikelSchnittEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelSchnittEK.Name := 'xdsEinzelartikelSchnittEK';
    xdsEinzelartikelSchnittEK.FieldName := 'SchnittEK';
    xdsEinzelartikelVerkaufspreisVom.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerkaufspreisVom.Name := 'xdsEinzelartikelVerkaufspreisVom';
    xdsEinzelartikelVerkaufspreisVom.FieldName := 'VerkaufspreisVom';
    xdsEinzelartikelPruefungChargeSNR.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPruefungChargeSNR.Name := 'xdsEinzelartikelPruefungChargeSNR';
    xdsEinzelartikelPruefungChargeSNR.FieldName := 'PruefungChargeSNR';
    xdsEinzelartikelArtikelbeschreibungHTML.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelbeschreibungHTML.Name := 'xdsEinzelartikelArtikelbeschreibungHTML';
    xdsEinzelartikelArtikelbeschreibungHTML.FieldName := 'ArtikelbeschreibungHTML';
    xdsEinzelartikelArtikelbeschreibungHTML.Size := 255;
    xdsEinzelartikelProduktKurzbeschreibungHTML.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelProduktKurzbeschreibungHTML.Name := 'xdsEinzelartikelProduktKurzbeschreibungHTML';
    xdsEinzelartikelProduktKurzbeschreibungHTML.FieldName := 'ProduktKurzbeschreibungHTML';
    xdsEinzelartikelProduktKurzbeschreibungHTML.Size := 255;
    xdsEinzelartikelStatusFuerShop.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelStatusFuerShop.Name := 'xdsEinzelartikelStatusFuerShop';
    xdsEinzelartikelStatusFuerShop.FieldName := 'StatusFuerShop';
    xdsEinzelartikelStatusFuerShop.Size := 25;
    xdsEinzelartikelAmpelFuerShop.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAmpelFuerShop.Name := 'xdsEinzelartikelAmpelFuerShop';
    xdsEinzelartikelAmpelFuerShop.FieldName := 'AmpelFuerShop';
    xdsEinzelartikelAmpelFuerShop.Size := 25;
    xdsEinzelartikelEXP_Anzahl.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEXP_Anzahl.Name := 'xdsEinzelartikelEXP_Anzahl';
    xdsEinzelartikelEXP_Anzahl.FieldName := 'EXP_Anzahl';
    xdsEinzelartikelEXP_Inhalt.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEXP_Inhalt.Name := 'xdsEinzelartikelEXP_Inhalt';
    xdsEinzelartikelEXP_Inhalt.FieldName := 'EXP_Inhalt';
    xdsEinzelartikelEXP_Faktor.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelEXP_Faktor.Name := 'xdsEinzelartikelEXP_Faktor';
    xdsEinzelartikelEXP_Faktor.FieldName := 'EXP_Faktor';
    xdsEinzelartikelFach.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelFach.Name := 'xdsEinzelartikelFach';
    xdsEinzelartikelFach.FieldName := 'Fach';
    xdsEinzelartikelExportKasse.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelExportKasse.Name := 'xdsEinzelartikelExportKasse';
    xdsEinzelartikelExportKasse.FieldName := 'ExportKasse';
    xdsEinzelartikelWKNR.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWKNR.Name := 'xdsEinzelartikelWKNR';
    xdsEinzelartikelWKNR.FieldName := 'WKNR';
    xdsEinzelartikelArtikelAttribute.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelAttribute.Name := 'xdsEinzelartikelArtikelAttribute';
    xdsEinzelartikelArtikelAttribute.FieldName := 'ArtikelAttribute';
    xdsEinzelartikelArtikelAttribute.Size := 255;
    xdsEinzelartikelErstelltAm.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelErstelltAm.Name := 'xdsEinzelartikelErstelltAm';
    xdsEinzelartikelErstelltAm.FieldName := 'ErstelltAm';
    xdsEinzelartikelVarianteVonAN.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVarianteVonAN.Name := 'xdsEinzelartikelVarianteVonAN';
    xdsEinzelartikelVarianteVonAN.FieldName := 'VarianteVonAN';
    xdsEinzelartikelVarianteVonAN.Size := 25;
    xdsEinzelartikelGurtmassWerte.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGurtmassWerte.Name := 'xdsEinzelartikelGurtmassWerte';
    xdsEinzelartikelGurtmassWerte.FieldName := 'GurtmassWerte';
    xdsEinzelartikelGurtmassWerte.Size := 250;
    xdsEinzelartikelGurtmass.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGurtmass.Name := 'xdsEinzelartikelGurtmass';
    xdsEinzelartikelGurtmass.FieldName := 'Gurtmass';
    xdsEinzelartikelAltersgrenze.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelAltersgrenze.Name := 'xdsEinzelartikelAltersgrenze';
    xdsEinzelartikelAltersgrenze.FieldName := 'Altersgrenze';
    xdsEinzelartikelMWStKeyImHaus.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelMWStKeyImHaus.Name := 'xdsEinzelartikelMWStKeyImHaus';
    xdsEinzelartikelMWStKeyImHaus.FieldName := 'MWStKeyImHaus';
    xdsEinzelartikelKasseBewegung.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKasseBewegung.Name := 'xdsEinzelartikelKasseBewegung';
    xdsEinzelartikelKasseBewegung.FieldName := 'KasseBewegung';
    xdsEinzelartikelKasseBewegungEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKasseBewegungEK.Name := 'xdsEinzelartikelKasseBewegungEK';
    xdsEinzelartikelKasseBewegungEK.FieldName := 'KasseBewegungEK';
    xdsEinzelartikelKasseBewegungVK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelKasseBewegungVK.Name := 'xdsEinzelartikelKasseBewegungVK';
    xdsEinzelartikelKasseBewegungVK.FieldName := 'KasseBewegungVK';
    xdsEinzelartikelProduktionBewegung.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelProduktionBewegung.Name := 'xdsEinzelartikelProduktionBewegung';
    xdsEinzelartikelProduktionBewegung.FieldName := 'ProduktionBewegung';
    xdsEinzelartikelProduktionBewegungEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelProduktionBewegungEK.Name := 'xdsEinzelartikelProduktionBewegungEK';
    xdsEinzelartikelProduktionBewegungEK.FieldName := 'ProduktionBewegungEK';
    xdsEinzelartikelProduktionBewegungVK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelProduktionBewegungVK.Name := 'xdsEinzelartikelProduktionBewegungVK';
    xdsEinzelartikelProduktionBewegungVK.FieldName := 'ProduktionBewegungVK';
    xdsEinzelartikelWaWiAB.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWaWiAB.Name := 'xdsEinzelartikelWaWiAB';
    xdsEinzelartikelWaWiAB.FieldName := 'WaWiAB';
    xdsEinzelartikelGeaendertAm.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGeaendertAm.Name := 'xdsEinzelartikelGeaendertAm';
    xdsEinzelartikelGeaendertAm.FieldName := 'GeaendertAm';
    xdsEinzelartikelGefahrgutKennzeichen.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelGefahrgutKennzeichen.Name := 'xdsEinzelartikelGefahrgutKennzeichen';
    xdsEinzelartikelGefahrgutKennzeichen.FieldName := 'GefahrgutKennzeichen';
    xdsEinzelartikelGefahrgutKennzeichen.Size := 50;
    xdsEinzelartikelVerpackG.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelVerpackG.Name := 'xdsEinzelartikelVerpackG';
    xdsEinzelartikelVerpackG.FieldName := 'VerpackG';
    xdsEinzelartikelUPE.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelUPE.Name := 'xdsEinzelartikelUPE';
    xdsEinzelartikelUPE.FieldName := 'UPE';
    xdsEinzelartikelArtikelbeschreibungEinkauf.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtikelbeschreibungEinkauf.Name := 'xdsEinzelartikelArtikelbeschreibungEinkauf';
    xdsEinzelartikelArtikelbeschreibungEinkauf.FieldName := 'ArtikelbeschreibungEinkauf';
    xdsEinzelartikelArtikelbeschreibungEinkauf.Size := 255;
    xdsEinzelartikelHerstellerArtikelnummer.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHerstellerArtikelnummer.Name := 'xdsEinzelartikelHerstellerArtikelnummer';
    xdsEinzelartikelHerstellerArtikelnummer.FieldName := 'HerstellerArtikelnummer';
    xdsEinzelartikelHerstellerArtikelnummer.Size := 25;
    xdsEinzelartikelNatura.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelNatura.Name := 'xdsEinzelartikelNatura';
    xdsEinzelartikelNatura.FieldName := 'Natura';
    xdsEinzelartikelNatura.Size := 2;
    xdsEinzelartikelArbeitszeit.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArbeitszeit.Name := 'xdsEinzelartikelArbeitszeit';
    xdsEinzelartikelArbeitszeit.FieldName := 'Arbeitszeit';
    xdsEinzelartikelWarengruppeWarengruppe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarengruppeWarengruppe.Name := 'xdsEinzelartikelWarengruppeWarengruppe';
    xdsEinzelartikelWarengruppeWarengruppe.FieldName := 'Warengruppe.Warengruppe';
    xdsEinzelartikelWarengruppeWarengruppe.Size := 70;
    xdsEinzelartikelLaenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelLaenge.Name := 'xdsEinzelartikelLaenge';
    xdsEinzelartikelLaenge.FieldName := 'Laenge';
    xdsEinzelartikelHoehe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHoehe.Name := 'xdsEinzelartikelHoehe';
    xdsEinzelartikelHoehe.FieldName := 'Hoehe';
    xdsEinzelartikelArtderMengeArtderMenge.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeArtderMenge.Name := 'xdsEinzelartikelArtderMengeArtderMenge';
    xdsEinzelartikelArtderMengeArtderMenge.FieldName := 'ArtderMenge.ArtderMenge';
    xdsEinzelartikelArtderMengeArtderMenge.Size := 50;
    xdsEinzelartikelHerstellerHersteller.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHerstellerHersteller.Name := 'xdsEinzelartikelHerstellerHersteller';
    xdsEinzelartikelHerstellerHersteller.FieldName := 'Hersteller.Hersteller';
    xdsEinzelartikelHerstellerHersteller.Size := 25;
    xdsEinzelartikelHerstellerHerstellerID.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHerstellerHerstellerID.Name := 'xdsEinzelartikelHerstellerHerstellerID';
    xdsEinzelartikelHerstellerHerstellerID.FieldName := 'Hersteller.HerstellerID';
    xdsEinzelartikelHerstellerHerstellerID.Size := 50;
    xdsEinzelartikelHerstellerHerstellerURL.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelHerstellerHerstellerURL.Name := 'xdsEinzelartikelHerstellerHerstellerURL';
    xdsEinzelartikelHerstellerHerstellerURL.FieldName := 'Hersteller.HerstellerURL';
    xdsEinzelartikelHerstellerHerstellerURL.Size := 100;
    xdsEinzelartikelArtderMengeISO3.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeISO3.Name := 'xdsEinzelartikelArtderMengeISO3';
    xdsEinzelartikelArtderMengeISO3.FieldName := 'ArtderMenge.ISO3';
    xdsEinzelartikelArtderMengeISO3.Size := 3;
    xdsEinzelartikelArtderMengeMengenauswahl.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeMengenauswahl.Name := 'xdsEinzelartikelArtderMengeMengenauswahl';
    xdsEinzelartikelArtderMengeMengenauswahl.FieldName := 'ArtderMenge.Mengenauswahl';
    xdsEinzelartikelArtderMengeVGPauswahl.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeVGPauswahl.Name := 'xdsEinzelartikelArtderMengeVGPauswahl';
    xdsEinzelartikelArtderMengeVGPauswahl.FieldName := 'ArtderMenge.VGPauswahl';
    xdsEinzelartikelArtderMengeUVPauswahl.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeUVPauswahl.Name := 'xdsEinzelartikelArtderMengeUVPauswahl';
    xdsEinzelartikelArtderMengeUVPauswahl.FieldName := 'ArtderMenge.UVPauswahl';
    xdsEinzelartikelArtderMengeVKPDivisor.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelArtderMengeVKPDivisor.Name := 'xdsEinzelartikelArtderMengeVKPDivisor';
    xdsEinzelartikelArtderMengeVKPDivisor.FieldName := 'ArtderMenge.VKPDivisor';
    xdsEinzelartikelPfandArtPfandArt.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPfandArtPfandArt.Name := 'xdsEinzelartikelPfandArtPfandArt';
    xdsEinzelartikelPfandArtPfandArt.FieldName := 'PfandArt.PfandArt';
    xdsEinzelartikelPfandArtPfandArt.Size := 50;
    xdsEinzelartikelPfandArtPfandWert.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPfandArtPfandWert.Name := 'xdsEinzelartikelPfandArtPfandWert';
    xdsEinzelartikelPfandArtPfandWert.FieldName := 'PfandArt.PfandWert';
    xdsEinzelartikelPfandArtPfandwertEK.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelPfandArtPfandwertEK.Name := 'xdsEinzelartikelPfandArtPfandwertEK';
    xdsEinzelartikelPfandArtPfandwertEK.FieldName := 'PfandArt.PfandwertEK';
    xdsEinzelartikelWarengruppeWGNR.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarengruppeWGNR.Name := 'xdsEinzelartikelWarengruppeWGNR';
    xdsEinzelartikelWarengruppeWGNR.FieldName := 'Warengruppe.WGNR';
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe.Name := 'xdsEinzelartikelWarenuntergruppeWarenuntergruppe';
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe.FieldName := 'Warenuntergruppe.Warenuntergruppe';
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe.Size := 70;
    xdsEinzelartikelWarenuntergruppeWarengruppe.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenuntergruppeWarengruppe.Name := 'xdsEinzelartikelWarenuntergruppeWarengruppe';
    xdsEinzelartikelWarenuntergruppeWarengruppe.FieldName := 'Warenuntergruppe.Warengruppe';
    xdsEinzelartikelWarenuntergruppeWarengruppe.Size := 70;
    xdsEinzelartikelWarenuntergruppeWUGNR.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenuntergruppeWUGNR.Name := 'xdsEinzelartikelWarenuntergruppeWUGNR';
    xdsEinzelartikelWarenuntergruppeWUGNR.FieldName := 'Warenuntergruppe.WUGNR';
    xdsEinzelartikelWarenuntergruppeWGNR.SetParentComponent(xdsEinzelartikel);
    xdsEinzelartikelWarenuntergruppeWGNR.Name := 'xdsEinzelartikelWarenuntergruppeWGNR';
    xdsEinzelartikelWarenuntergruppeWGNR.FieldName := 'Warenuntergruppe.WGNR';
    sourceEinzelartikel.SetParentComponent(Self);
    sourceEinzelartikel.Name := 'sourceEinzelartikel';
    sourceEinzelartikel.DataSet := xdsEinzelartikel;
    sourceEinzelartikel.Left := 20;
    sourceEinzelartikel.Top := 21;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 144;
    WClient.Top := 24;
  finally
    WebLabel1.AfterLoadDFMValues;
    TC.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    btnSpeichern.AfterLoadDFMValues;
    edtBMenge.AfterLoadDFMValues;
    edtArtikelnummer.AfterLoadDFMValues;
    btnEtikettendruck.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    xdsEinzelartikel.AfterLoadDFMValues;
    xdsEinzelartikelHersteller.AfterLoadDFMValues;
    xdsEinzelartikelArtderMenge.AfterLoadDFMValues;
    xdsEinzelartikelPfandArt.AfterLoadDFMValues;
    xdsEinzelartikelWarengruppe.AfterLoadDFMValues;
    xdsEinzelartikelWarenuntergruppe.AfterLoadDFMValues;
    xdsEinzelartikelArtikelnummer.AfterLoadDFMValues;
    xdsEinzelartikelArtikelueberschrift.AfterLoadDFMValues;
    xdsEinzelartikelArtikelbeschreibung.AfterLoadDFMValues;
    xdsEinzelartikelVerkaufspreis.AfterLoadDFMValues;
    xdsEinzelartikelMindestbestand.AfterLoadDFMValues;
    xdsEinzelartikelPreisliste.AfterLoadDFMValues;
    xdsEinzelartikelGewicht.AfterLoadDFMValues;
    xdsEinzelartikelBreite.AfterLoadDFMValues;
    xdsEinzelartikelArtikelZusatz.AfterLoadDFMValues;
    xdsEinzelartikelLagerort.AfterLoadDFMValues;
    xdsEinzelartikelBuchungskonto.AfterLoadDFMValues;
    xdsEinzelartikelEingangMenge.AfterLoadDFMValues;
    xdsEinzelartikelEingangEndpreis.AfterLoadDFMValues;
    xdsEinzelartikelAusgangMenge.AfterLoadDFMValues;
    xdsEinzelartikelAusgangEndpreisEK.AfterLoadDFMValues;
    xdsEinzelartikelAusgangEndpreisVK.AfterLoadDFMValues;
    xdsEinzelartikelBestand.AfterLoadDFMValues;
    xdsEinzelartikelBestandswert.AfterLoadDFMValues;
    xdsEinzelartikelMarge.AfterLoadDFMValues;
    xdsEinzelartikelMargeProzent.AfterLoadDFMValues;
    xdsEinzelartikelArtikelnummer2.AfterLoadDFMValues;
    xdsEinzelartikelArtikelmatchcode.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreis1.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreis2.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreis3.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreis4.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreis5.AfterLoadDFMValues;
    xdsEinzelartikelArtikelnichtmehrgueltig.AfterLoadDFMValues;
    xdsEinzelartikelEinkaufspreis.AfterLoadDFMValues;
    xdsEinzelartikelAufschlag.AfterLoadDFMValues;
    xdsEinzelartikelWarenbestand.AfterLoadDFMValues;
    xdsEinzelartikelTextfrei10.AfterLoadDFMValues;
    xdsEinzelartikelTextfrei20.AfterLoadDFMValues;
    xdsEinzelartikelArtikelbeschreibungRTF.AfterLoadDFMValues;
    xdsEinzelartikelArtikeldatei1.AfterLoadDFMValues;
    xdsEinzelartikelArtikeldatei2.AfterLoadDFMValues;
    xdsEinzelartikelArtikeldatei3.AfterLoadDFMValues;
    xdsEinzelartikelArtikeldatei4.AfterLoadDFMValues;
    xdsEinzelartikelArtikeldatei5.AfterLoadDFMValues;
    xdsEinzelartikelEinkaufspreisVom.AfterLoadDFMValues;
    xdsEinzelartikelEAN.AfterLoadDFMValues;
    xdsEinzelartikelVGPFaktor.AfterLoadDFMValues;
    xdsEinzelartikelVGPMengenart.AfterLoadDFMValues;
    xdsEinzelartikelVerleihpreis.AfterLoadDFMValues;
    xdsEinzelartikelZusatzPreis.AfterLoadDFMValues;
    xdsEinzelartikelVerpackungsart.AfterLoadDFMValues;
    xdsEinzelartikelVerpackungsMenge.AfterLoadDFMValues;
    xdsEinzelartikelQuellartikel.AfterLoadDFMValues;
    xdsEinzelartikelrabattierfaehig.AfterLoadDFMValues;
    xdsEinzelartikelAngebotAlternativeAN.AfterLoadDFMValues;
    xdsEinzelartikelPreisanteilNetto.AfterLoadDFMValues;
    xdsEinzelartikelFarbe.AfterLoadDFMValues;
    xdsEinzelartikelAEinkauf.AfterLoadDFMValues;
    xdsEinzelartikelAVerkauf.AfterLoadDFMValues;
    xdsEinzelartikelArtikelnummerLieferant.AfterLoadDFMValues;
    xdsEinzelartikelLieferantnummer.AfterLoadDFMValues;
    xdsEinzelartikelVerkaufspreisBrutto.AfterLoadDFMValues;
    xdsEinzelartikelArtikelSet.AfterLoadDFMValues;
    xdsEinzelartikelProvisionssatz.AfterLoadDFMValues;
    xdsEinzelartikelWarentarifnummer.AfterLoadDFMValues;
    xdsEinzelartikelHandelsartikel.AfterLoadDFMValues;
    xdsEinzelartikelUrsprungsland.AfterLoadDFMValues;
    xdsEinzelartikelKategorie.AfterLoadDFMValues;
    xdsEinzelartikelStandardRabattsatzEK.AfterLoadDFMValues;
    xdsEinzelartikelStandardRabattsatzVK.AfterLoadDFMValues;
    xdsEinzelartikelRabattpreiseManuell.AfterLoadDFMValues;
    xdsEinzelartikelMengeBestellt.AfterLoadDFMValues;
    xdsEinzelartikelMengeBeauftragt.AfterLoadDFMValues;
    xdsEinzelartikelBestandDisponiert.AfterLoadDFMValues;
    xdsEinzelartikelMengeAngeboten.AfterLoadDFMValues;
    xdsEinzelartikelBestandDispInclAngebot.AfterLoadDFMValues;
    xdsEinzelartikelMWStKey.AfterLoadDFMValues;
    xdsEinzelartikelShopAmazon.AfterLoadDFMValues;
    xdsEinzelartikelShopQuelle.AfterLoadDFMValues;
    xdsEinzelartikelGeraet.AfterLoadDFMValues;
    xdsEinzelartikelArtgewicht.AfterLoadDFMValues;
    xdsEinzelartikelVolumen.AfterLoadDFMValues;
    xdsEinzelartikelPacklisteNichtAnzeigen.AfterLoadDFMValues;
    xdsEinzelartikelKeineAufschlagskalkulation.AfterLoadDFMValues;
    xdsEinzelartikelKorrekturMenge.AfterLoadDFMValues;
    xdsEinzelartikelKorrekturWert.AfterLoadDFMValues;
    xdsEinzelartikelNachfolgeartikel.AfterLoadDFMValues;
    xdsEinzelartikelMindestbestellmenge.AfterLoadDFMValues;
    xdsEinzelartikelSchnittEK.AfterLoadDFMValues;
    xdsEinzelartikelVerkaufspreisVom.AfterLoadDFMValues;
    xdsEinzelartikelPruefungChargeSNR.AfterLoadDFMValues;
    xdsEinzelartikelArtikelbeschreibungHTML.AfterLoadDFMValues;
    xdsEinzelartikelProduktKurzbeschreibungHTML.AfterLoadDFMValues;
    xdsEinzelartikelStatusFuerShop.AfterLoadDFMValues;
    xdsEinzelartikelAmpelFuerShop.AfterLoadDFMValues;
    xdsEinzelartikelEXP_Anzahl.AfterLoadDFMValues;
    xdsEinzelartikelEXP_Inhalt.AfterLoadDFMValues;
    xdsEinzelartikelEXP_Faktor.AfterLoadDFMValues;
    xdsEinzelartikelFach.AfterLoadDFMValues;
    xdsEinzelartikelExportKasse.AfterLoadDFMValues;
    xdsEinzelartikelWKNR.AfterLoadDFMValues;
    xdsEinzelartikelArtikelAttribute.AfterLoadDFMValues;
    xdsEinzelartikelErstelltAm.AfterLoadDFMValues;
    xdsEinzelartikelVarianteVonAN.AfterLoadDFMValues;
    xdsEinzelartikelGurtmassWerte.AfterLoadDFMValues;
    xdsEinzelartikelGurtmass.AfterLoadDFMValues;
    xdsEinzelartikelAltersgrenze.AfterLoadDFMValues;
    xdsEinzelartikelMWStKeyImHaus.AfterLoadDFMValues;
    xdsEinzelartikelKasseBewegung.AfterLoadDFMValues;
    xdsEinzelartikelKasseBewegungEK.AfterLoadDFMValues;
    xdsEinzelartikelKasseBewegungVK.AfterLoadDFMValues;
    xdsEinzelartikelProduktionBewegung.AfterLoadDFMValues;
    xdsEinzelartikelProduktionBewegungEK.AfterLoadDFMValues;
    xdsEinzelartikelProduktionBewegungVK.AfterLoadDFMValues;
    xdsEinzelartikelWaWiAB.AfterLoadDFMValues;
    xdsEinzelartikelGeaendertAm.AfterLoadDFMValues;
    xdsEinzelartikelGefahrgutKennzeichen.AfterLoadDFMValues;
    xdsEinzelartikelVerpackG.AfterLoadDFMValues;
    xdsEinzelartikelUPE.AfterLoadDFMValues;
    xdsEinzelartikelArtikelbeschreibungEinkauf.AfterLoadDFMValues;
    xdsEinzelartikelHerstellerArtikelnummer.AfterLoadDFMValues;
    xdsEinzelartikelNatura.AfterLoadDFMValues;
    xdsEinzelartikelArbeitszeit.AfterLoadDFMValues;
    xdsEinzelartikelWarengruppeWarengruppe.AfterLoadDFMValues;
    xdsEinzelartikelLaenge.AfterLoadDFMValues;
    xdsEinzelartikelHoehe.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeArtderMenge.AfterLoadDFMValues;
    xdsEinzelartikelHerstellerHersteller.AfterLoadDFMValues;
    xdsEinzelartikelHerstellerHerstellerID.AfterLoadDFMValues;
    xdsEinzelartikelHerstellerHerstellerURL.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeISO3.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeMengenauswahl.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeVGPauswahl.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeUVPauswahl.AfterLoadDFMValues;
    xdsEinzelartikelArtderMengeVKPDivisor.AfterLoadDFMValues;
    xdsEinzelartikelPfandArtPfandArt.AfterLoadDFMValues;
    xdsEinzelartikelPfandArtPfandWert.AfterLoadDFMValues;
    xdsEinzelartikelPfandArtPfandwertEK.AfterLoadDFMValues;
    xdsEinzelartikelWarengruppeWGNR.AfterLoadDFMValues;
    xdsEinzelartikelWarenuntergruppeWarenuntergruppe.AfterLoadDFMValues;
    xdsEinzelartikelWarenuntergruppeWarengruppe.AfterLoadDFMValues;
    xdsEinzelartikelWarenuntergruppeWUGNR.AfterLoadDFMValues;
    xdsEinzelartikelWarenuntergruppeWGNR.AfterLoadDFMValues;
    sourceEinzelartikel.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
  end;
end;

end.