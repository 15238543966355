unit unit_EinzelAnruf;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Buttons, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.DBCtrls, WEBLib.Grids;

type
  TEinzelAnruf = class(TForm)
    xdsAnruf: TXDataWebDataSet;
    xdsAnrufID: TStringField;
    xdsAnrufAnrufTyp: TIntegerField;
    xdsAnrufDatum: TDateTimeField;
    xdsAnrufRufnummer: TStringField;
    xdsAnrufNameFritzBox: TStringField;
    xdsAnrufNebenstelle: TStringField;
    xdsAnrufAnrufArt: TStringField;
    xdsAnrufDauer: TDateTimeField;
    xdsAnrufKundennummer: TStringField;
    xdsAnrufTeilnehmer: TStringField;
    xdsAnrufNotiz: TStringField;
    xdsAnrufbis: TDateTimeField;
    xdsAnrufDauerMinuten: TIntegerField;
    xdsAnrufJahr: TIntegerField;
    xdsAnrufMonat: TIntegerField;
    xdsAnrufTag: TIntegerField;
    xdsAnrufMonatJahr: TDateTimeField;
    xdsAnrufDatumKurz: TDateTimeField;
    xdsAnrufAPID: TStringField;
    xdsAnrufFBox: TStringField;
    xdsAnrufabrechnen: TBooleanField;
    xdsAnrufAbgerechnetAm: TDateTimeField;
    xdsAnrufStundensatz: TFloatField;
    xdsAnrufKosten: TFloatField;
    xdsAnruferstellt: TDateTimeField;
    xdsAnrufgeaendert: TDateTimeField;
    xdsAnrufAnrufArtText: TStringField;
    sourceAnruf: TDataSource;
    TC: TTableControl;
    procedure WebFormShow(Sender: TObject);
    procedure xdsAnrufAfterPost(DataSet: TDataSet);
    procedure xdsAnrufAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure xdsAnrufAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    MerkerAnruf:string;
    buttonzurueck:TButton;
    buttonSpeichern:TButton;
    buttonAbbrechen:TButton;
    procedure buttonzurueckclick(Sender:TObject);
    procedure buttonspeichernclick(Sender:TObject);
    procedure buttonAbbrechenclick(Sender:TObject);
  end;

var
  EinzelAnruf: TEinzelAnruf;
  edtTeilnehmer:TDBEdit;
  edtNotiz:TDBMemo;

implementation
  uses unit1, unit2;

procedure TEinzelAnruf.buttonzurueckclick(Sender:TObject);
begin
  self.close;
end;

procedure TEinzelAnruf.buttonspeichernclick(Sender:TObject);
begin
   if xdsanruf.State in dseditmodes then
  begin
    ModalResult:=mrOk;
    xdsanruf.Post;
  end
  else
  begin
    ModalResult:=mrCancel;
    xdsanruf.Close;
    self.Close;
  end;
end;

procedure TEinzelAnruf.buttonAbbrechenclick(Sender:TObject);
begin
  ModalResult:=mrCancel;
  xdsanruf.Close;
  self.Close;
end;

procedure TEinzelAnruf.WebFormShow(Sender: TObject);
begin
  if MerkerAnruf<>'' then
  begin
    xdsAnruf.Close;
    xdsAnruf.QueryString:='$filter=ID eq '+QuotedStr(MerkerAnruf);
    xdsAnruf.Load;

  end;

end;

procedure TEinzelAnruf.xdsAnrufAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  self.Close;
end;

procedure TEinzelAnruf.xdsAnrufAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin

  i:=0;
  TC.ColCount:=2;
  tc.rowcount:=10;
  TC.Cells[0,i]:='<h4>Einzel-Anruf';
  inc(i);
  myelement:=TC.cellelements[0,i];
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;

  inc(i);
  TC.Cells[0,i]:='<small>Datum';
  TC.Cells[1,i]:=xdsAnrufDatum.asstring;
  inc(i);
  TC.Cells[0,i]:='<small>Dauer (Min.)';
  TC.Cells[1,i]:=xdsAnrufDauerMinuten.asstring;
  inc(i);
  TC.Cells[0,i]:='<small>Rufnummer';
  TC.Cells[1,i]:=xdsAnrufRufnummer.asstring;
  inc(i);
  TC.Cells[0,i]:='<small>Kundennummer';
  TC.Cells[1,i]:=xdsAnrufKundennummer.asstring;
  inc(i);
  TC.Cells[0,i]:='<small>Teilnehmer';
  TC.Cells[1,i]:=xdsAnrufTeilnehmer.asstring;

  if xdsanrufkundennummer.asstring='' then
  begin
    myelement:=TC.CellElements[1,i];
    edtTeilnehmer:=TDBEdit.Create(self);
    edtTeilnehmer.DataSource:=sourceAnruf;
    edtTeilnehmer.DataField:='Teilnehmer';
    edtTeilnehmer.ElementClassName:='form-control';
    edtTeilnehmer.width:=450;
    edtTeilnehmer.Height:=35;
    myelement.innerHTML:='';
    edtTeilnehmer.ParentElement:=myelement;
  end;


  inc(i);
  TC.Cells[0,i]:='<small>Notiz';

  myelement:=TC.CellElements[1,i];
  edtNotiz:=TDBMemo.Create(self);
  edtNotiz.DataSource:=sourceAnruf;
  edtNotiz.DataField:='Notiz';
  edtNotiz.ElementClassName:='form-control';
  edtNotiz.width:=450;
  edtNotiz.Height:=135;
  myelement.innerHTML:='';
  edtNotiz.ParentElement:=myelement;


  inc(i);
  myelement:=TC.cellelements[0,i];
  buttonAbbrechen:=TButton.Create(self);
  buttonAbbrechen.ElementClassName:='btn btn-info text-left';
  buttonAbbrechen.HeightStyle:=ssauto;
  buttonAbbrechen.WidthStyle:=ssauto;
  buttonAbbrechen.tag:=0;
  buttonAbbrechen.Caption:='abbrechen';
  buttonAbbrechen.OnClick:=@buttonAbbrechenClick;
  buttonAbbrechen.ParentElement:=myelement;

  myelement:=TC.cellelements[1,i];
  buttonSpeichern:=TButton.Create(self);
  buttonSpeichern.ElementClassName:='btn btn-info text-left';
  buttonSpeichern.HeightStyle:=ssauto;
  buttonSpeichern.WidthStyle:=ssauto;
  buttonSpeichern.tag:=0;
  buttonSpeichern.Caption:='speichern';
  buttonSpeichern.OnClick:=@buttonSpeichernClick;
  buttonSpeichern.ParentElement:=myelement;
  xdsAnruf.First;
end;

procedure TEinzelAnruf.xdsAnrufAfterPost(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    xdsanruf.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TEinzelAnruf.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  xdsAnruf := TXDataWebDataSet.Create(Self);
  xdsAnrufID := TStringField.Create(Self);
  xdsAnrufAnrufTyp := TIntegerField.Create(Self);
  xdsAnrufDatum := TDateTimeField.Create(Self);
  xdsAnrufRufnummer := TStringField.Create(Self);
  xdsAnrufNameFritzBox := TStringField.Create(Self);
  xdsAnrufNebenstelle := TStringField.Create(Self);
  xdsAnrufAnrufArt := TStringField.Create(Self);
  xdsAnrufDauer := TDateTimeField.Create(Self);
  xdsAnrufKundennummer := TStringField.Create(Self);
  xdsAnrufTeilnehmer := TStringField.Create(Self);
  xdsAnrufNotiz := TStringField.Create(Self);
  xdsAnrufbis := TDateTimeField.Create(Self);
  xdsAnrufDauerMinuten := TIntegerField.Create(Self);
  xdsAnrufJahr := TIntegerField.Create(Self);
  xdsAnrufMonat := TIntegerField.Create(Self);
  xdsAnrufTag := TIntegerField.Create(Self);
  xdsAnrufMonatJahr := TDateTimeField.Create(Self);
  xdsAnrufDatumKurz := TDateTimeField.Create(Self);
  xdsAnrufAPID := TStringField.Create(Self);
  xdsAnrufFBox := TStringField.Create(Self);
  xdsAnrufabrechnen := TBooleanField.Create(Self);
  xdsAnrufAbgerechnetAm := TDateTimeField.Create(Self);
  xdsAnrufStundensatz := TFloatField.Create(Self);
  xdsAnrufKosten := TFloatField.Create(Self);
  xdsAnruferstellt := TDateTimeField.Create(Self);
  xdsAnrufgeaendert := TDateTimeField.Create(Self);
  xdsAnrufAnrufArtText := TStringField.Create(Self);
  sourceAnruf := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  xdsAnruf.BeforeLoadDFMValues;
  xdsAnrufID.BeforeLoadDFMValues;
  xdsAnrufAnrufTyp.BeforeLoadDFMValues;
  xdsAnrufDatum.BeforeLoadDFMValues;
  xdsAnrufRufnummer.BeforeLoadDFMValues;
  xdsAnrufNameFritzBox.BeforeLoadDFMValues;
  xdsAnrufNebenstelle.BeforeLoadDFMValues;
  xdsAnrufAnrufArt.BeforeLoadDFMValues;
  xdsAnrufDauer.BeforeLoadDFMValues;
  xdsAnrufKundennummer.BeforeLoadDFMValues;
  xdsAnrufTeilnehmer.BeforeLoadDFMValues;
  xdsAnrufNotiz.BeforeLoadDFMValues;
  xdsAnrufbis.BeforeLoadDFMValues;
  xdsAnrufDauerMinuten.BeforeLoadDFMValues;
  xdsAnrufJahr.BeforeLoadDFMValues;
  xdsAnrufMonat.BeforeLoadDFMValues;
  xdsAnrufTag.BeforeLoadDFMValues;
  xdsAnrufMonatJahr.BeforeLoadDFMValues;
  xdsAnrufDatumKurz.BeforeLoadDFMValues;
  xdsAnrufAPID.BeforeLoadDFMValues;
  xdsAnrufFBox.BeforeLoadDFMValues;
  xdsAnrufabrechnen.BeforeLoadDFMValues;
  xdsAnrufAbgerechnetAm.BeforeLoadDFMValues;
  xdsAnrufStundensatz.BeforeLoadDFMValues;
  xdsAnrufKosten.BeforeLoadDFMValues;
  xdsAnruferstellt.BeforeLoadDFMValues;
  xdsAnrufgeaendert.BeforeLoadDFMValues;
  xdsAnrufAnrufArtText.BeforeLoadDFMValues;
  sourceAnruf.BeforeLoadDFMValues;
  try
    Name := 'EinzelAnruf';
    Width := 159;
    Height := 226;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 159;
    TC.Height := 226;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 3;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 1;
    TC.RowCount := 5;
    xdsAnruf.SetParentComponent(Self);
    xdsAnruf.Name := 'xdsAnruf';
    xdsAnruf.AfterApplyUpdates := xdsAnrufAfterApplyUpdates;
    xdsAnruf.AfterOpen := xdsAnrufAfterOpen;
    xdsAnruf.AfterPost := xdsAnrufAfterPost;
    xdsAnruf.EntitySetName := 'FritzBoxAnrufe';
    xdsAnruf.Connection := DM2.XDataWebConnection1;
    xdsAnruf.QueryTop := 1;
    xdsAnruf.Left := 93;
    xdsAnruf.Top := 101;
    xdsAnrufID.SetParentComponent(xdsAnruf);
    xdsAnrufID.Name := 'xdsAnrufID';
    xdsAnrufID.FieldName := 'ID';
    xdsAnrufID.Required := True;
    xdsAnrufID.Size := 38;
    xdsAnrufAnrufTyp.SetParentComponent(xdsAnruf);
    xdsAnrufAnrufTyp.Name := 'xdsAnrufAnrufTyp';
    xdsAnrufAnrufTyp.FieldName := 'AnrufTyp';
    xdsAnrufDatum.SetParentComponent(xdsAnruf);
    xdsAnrufDatum.Name := 'xdsAnrufDatum';
    xdsAnrufDatum.FieldName := 'Datum';
    xdsAnrufRufnummer.SetParentComponent(xdsAnruf);
    xdsAnrufRufnummer.Name := 'xdsAnrufRufnummer';
    xdsAnrufRufnummer.FieldName := 'Rufnummer';
    xdsAnrufRufnummer.Size := 50;
    xdsAnrufNameFritzBox.SetParentComponent(xdsAnruf);
    xdsAnrufNameFritzBox.Name := 'xdsAnrufNameFritzBox';
    xdsAnrufNameFritzBox.FieldName := 'NameFritzBox';
    xdsAnrufNameFritzBox.Size := 50;
    xdsAnrufNebenstelle.SetParentComponent(xdsAnruf);
    xdsAnrufNebenstelle.Name := 'xdsAnrufNebenstelle';
    xdsAnrufNebenstelle.FieldName := 'Nebenstelle';
    xdsAnrufNebenstelle.Size := 50;
    xdsAnrufAnrufArt.SetParentComponent(xdsAnruf);
    xdsAnrufAnrufArt.Name := 'xdsAnrufAnrufArt';
    xdsAnrufAnrufArt.FieldName := 'AnrufArt';
    xdsAnrufAnrufArt.Size := 50;
    xdsAnrufDauer.SetParentComponent(xdsAnruf);
    xdsAnrufDauer.Name := 'xdsAnrufDauer';
    xdsAnrufDauer.FieldName := 'Dauer';
    xdsAnrufKundennummer.SetParentComponent(xdsAnruf);
    xdsAnrufKundennummer.Name := 'xdsAnrufKundennummer';
    xdsAnrufKundennummer.FieldName := 'Kundennummer';
    xdsAnrufKundennummer.Size := 13;
    xdsAnrufTeilnehmer.SetParentComponent(xdsAnruf);
    xdsAnrufTeilnehmer.Name := 'xdsAnrufTeilnehmer';
    xdsAnrufTeilnehmer.FieldName := 'Teilnehmer';
    xdsAnrufTeilnehmer.Size := 150;
    xdsAnrufNotiz.SetParentComponent(xdsAnruf);
    xdsAnrufNotiz.Name := 'xdsAnrufNotiz';
    xdsAnrufNotiz.FieldName := 'Notiz';
    xdsAnrufNotiz.Size := 255;
    xdsAnrufbis.SetParentComponent(xdsAnruf);
    xdsAnrufbis.Name := 'xdsAnrufbis';
    xdsAnrufbis.FieldName := 'bis';
    xdsAnrufDauerMinuten.SetParentComponent(xdsAnruf);
    xdsAnrufDauerMinuten.Name := 'xdsAnrufDauerMinuten';
    xdsAnrufDauerMinuten.FieldName := 'DauerMinuten';
    xdsAnrufJahr.SetParentComponent(xdsAnruf);
    xdsAnrufJahr.Name := 'xdsAnrufJahr';
    xdsAnrufJahr.FieldName := 'Jahr';
    xdsAnrufMonat.SetParentComponent(xdsAnruf);
    xdsAnrufMonat.Name := 'xdsAnrufMonat';
    xdsAnrufMonat.FieldName := 'Monat';
    xdsAnrufTag.SetParentComponent(xdsAnruf);
    xdsAnrufTag.Name := 'xdsAnrufTag';
    xdsAnrufTag.FieldName := 'Tag';
    xdsAnrufMonatJahr.SetParentComponent(xdsAnruf);
    xdsAnrufMonatJahr.Name := 'xdsAnrufMonatJahr';
    xdsAnrufMonatJahr.FieldName := 'MonatJahr';
    xdsAnrufDatumKurz.SetParentComponent(xdsAnruf);
    xdsAnrufDatumKurz.Name := 'xdsAnrufDatumKurz';
    xdsAnrufDatumKurz.FieldName := 'DatumKurz';
    xdsAnrufAPID.SetParentComponent(xdsAnruf);
    xdsAnrufAPID.Name := 'xdsAnrufAPID';
    xdsAnrufAPID.FieldName := 'APID';
    xdsAnrufAPID.Size := 38;
    xdsAnrufFBox.SetParentComponent(xdsAnruf);
    xdsAnrufFBox.Name := 'xdsAnrufFBox';
    xdsAnrufFBox.FieldName := 'FBox';
    xdsAnrufFBox.Size := 50;
    xdsAnrufabrechnen.SetParentComponent(xdsAnruf);
    xdsAnrufabrechnen.Name := 'xdsAnrufabrechnen';
    xdsAnrufabrechnen.FieldName := 'abrechnen';
    xdsAnrufAbgerechnetAm.SetParentComponent(xdsAnruf);
    xdsAnrufAbgerechnetAm.Name := 'xdsAnrufAbgerechnetAm';
    xdsAnrufAbgerechnetAm.FieldName := 'AbgerechnetAm';
    xdsAnrufStundensatz.SetParentComponent(xdsAnruf);
    xdsAnrufStundensatz.Name := 'xdsAnrufStundensatz';
    xdsAnrufStundensatz.FieldName := 'Stundensatz';
    xdsAnrufKosten.SetParentComponent(xdsAnruf);
    xdsAnrufKosten.Name := 'xdsAnrufKosten';
    xdsAnrufKosten.FieldName := 'Kosten';
    xdsAnruferstellt.SetParentComponent(xdsAnruf);
    xdsAnruferstellt.Name := 'xdsAnruferstellt';
    xdsAnruferstellt.FieldName := 'erstellt';
    xdsAnrufgeaendert.SetParentComponent(xdsAnruf);
    xdsAnrufgeaendert.Name := 'xdsAnrufgeaendert';
    xdsAnrufgeaendert.FieldName := 'geaendert';
    xdsAnrufAnrufArtText.SetParentComponent(xdsAnruf);
    xdsAnrufAnrufArtText.Name := 'xdsAnrufAnrufArtText';
    xdsAnrufAnrufArtText.FieldKind := fkCalculated;
    xdsAnrufAnrufArtText.FieldName := 'AnrufArtText';
    xdsAnrufAnrufArtText.Calculated := True;
    sourceAnruf.SetParentComponent(Self);
    sourceAnruf.Name := 'sourceAnruf';
    sourceAnruf.DataSet := xdsAnruf;
    sourceAnruf.Left := 29;
    sourceAnruf.Top := 101;
  finally
    TC.AfterLoadDFMValues;
    xdsAnruf.AfterLoadDFMValues;
    xdsAnrufID.AfterLoadDFMValues;
    xdsAnrufAnrufTyp.AfterLoadDFMValues;
    xdsAnrufDatum.AfterLoadDFMValues;
    xdsAnrufRufnummer.AfterLoadDFMValues;
    xdsAnrufNameFritzBox.AfterLoadDFMValues;
    xdsAnrufNebenstelle.AfterLoadDFMValues;
    xdsAnrufAnrufArt.AfterLoadDFMValues;
    xdsAnrufDauer.AfterLoadDFMValues;
    xdsAnrufKundennummer.AfterLoadDFMValues;
    xdsAnrufTeilnehmer.AfterLoadDFMValues;
    xdsAnrufNotiz.AfterLoadDFMValues;
    xdsAnrufbis.AfterLoadDFMValues;
    xdsAnrufDauerMinuten.AfterLoadDFMValues;
    xdsAnrufJahr.AfterLoadDFMValues;
    xdsAnrufMonat.AfterLoadDFMValues;
    xdsAnrufTag.AfterLoadDFMValues;
    xdsAnrufMonatJahr.AfterLoadDFMValues;
    xdsAnrufDatumKurz.AfterLoadDFMValues;
    xdsAnrufAPID.AfterLoadDFMValues;
    xdsAnrufFBox.AfterLoadDFMValues;
    xdsAnrufabrechnen.AfterLoadDFMValues;
    xdsAnrufAbgerechnetAm.AfterLoadDFMValues;
    xdsAnrufStundensatz.AfterLoadDFMValues;
    xdsAnrufKosten.AfterLoadDFMValues;
    xdsAnruferstellt.AfterLoadDFMValues;
    xdsAnrufgeaendert.AfterLoadDFMValues;
    xdsAnrufAnrufArtText.AfterLoadDFMValues;
    sourceAnruf.AfterLoadDFMValues;
  end;
end;

end.