{*************************************************************************}
{ TAdvTreeNodes                                                           }
{ for Delphi & C++Builder                                                 }
{                                                                         }
{ written by TMS Software                                                 }
{           copyright © 2019 - 2020                                       }
{           Email : info@tmssoftware.com                                  }
{           Website : http://www.tmssoftware.com/                         }
{                                                                         }
{ The source code is given as is. The author is not responsible           }
{ for any possible damage done due to the use of this code.               }
{ The component can be freely used in any application. The complete       }
{ source code remains property of the author and may not be distributed,  }
{ published, given or sold in any form as such. No parts of the source    }
{ code can be included in any other component or application without      }
{ written authorization of the author.                                    }
{*************************************************************************}

unit WEBLib.TreeNodes;


interface
uses
  Classes, Types, SysUtils, Web;

type
  TTreeNode = class;
  TTreeNodes = class;

  TDbgList = class(TList)
  private
    function GetItemsEx(Index: Integer): TTreeNode;
    procedure SetItemsEx(Index: Integer; const Value: TTreeNode);
  public
    procedure AssignList(ListA: TList);
    property Items[Index: Integer]: TTreeNode read GetItemsEx write SetItemsEx; default;
  end;

{ TTreeNode }

  TNodeType = (ntText, ntCheckbox, ntRadioButton);

  TImageIndex = integer;

  PNodeInfo = ^TNodeInfo;
  TNodeInfo = packed record
    SelectedIndex: Integer;
    StateIndex: Integer;
    OverlayIndex: Integer;
    Data: Pointer;
    Count: Integer;
    Text: string;
    NodeType: TNodeType;
    Checked: Boolean;
    ImageURL: string;
    ImageCollapsedURL: string;
    URL: string;
    Tag: Integer;
    Hint: string;
    IsExpanded: Boolean;
  end;

  TNodeDataInfo = packed record
    SelectedIndex: Integer;
    StateIndex: Integer;
    OverlayIndex: Integer;
    Data: Pointer;
    Count: Integer;
    NodeType: TNodeType;
    Checked: Boolean;
    Tag: Integer;
    IsExpanded: Boolean;
    TextLen: Byte;
    HintLen: Byte;
    URLLen: Byte;
    ImageURLLen: Byte;
    ImageCollapsedURLLen: Byte;
  end;

  TTreeNodeClass = class of TTreeNode;

  TTreeNode = class(TPersistent)
  private
    FOwner: TTreeNodes;
    FText: string;
    FData: TObject;
    FDeleting: Boolean;
    FIsExpanded: Boolean;
    FFirstChild: TTreeNode;
    FNextSibling: TTreeNode;
    FPrevSibling: TTreeNode;
    FParentNode: TTreeNode;
    FShowText: Boolean;
    FImageCollapsedURL: string;
    FImageURL: string;
    FNodeType: TNodeType;
    FURL: string;
    FChecked: boolean;
    FTag: Integer;
    FHint: string;
    function GetLevel: Integer;
    function GetParent: TTreeNode;
    function GetIndex: Integer;
    function GetItem(Index: Integer): TTreeNode;
    function GetCount: Integer;
    procedure SetData(Value: TObject);
    procedure SetItem(Index: Integer; Value: TTreeNode);
    procedure SetText(const S: string);
    function GetHasChildren: Boolean;
    procedure SetShowText(const Value: Boolean);
    //procedure ReadData(Stream: TStream; var Info: TNodeDataInfo);
    //procedure WriteData(Stream: TStream; var Info: TNodeDataInfo);
    procedure SetChecked(const Value: boolean);
    procedure SetImageCollapsedURL(const Value: string);
    procedure SetImageURL(const Value: string);
    procedure SetNodeType(const Value: TNodeType);
    procedure SetURL(const Value: string);
    procedure SetTag(const Value: Integer);
    procedure SetHint(const Value: string);
    procedure SetIsExpanded(const Value: Boolean);
  protected
    function CompareCount(CompareMe: Integer): Boolean;
    property Owner: TTreeNodes read FOwner;
    function IsEqual(Node: TTreeNode): Boolean;
  public
    property Item[Index: Integer]: TTreeNode read GetItem write SetItem; default;
    function IndexOf(Value: TTreeNode): Integer;
    property Count: Integer read GetCount;
    property Index: Integer read GetIndex;

    constructor Create(AOwner: TTreeNodes);
    destructor Destroy; override;

    function AddFirstChild(const S: string): TTreeNode; overload;
    function AddFirstChild(const S: string; Data: TObject): TTreeNode; overload;
    function AddChild(const S: string): TTreeNode; overload;
    function AddChild(const S: string; Data: TObject): TTreeNode; overload;
    procedure Assign(Source: TPersistent); override;

    procedure Expand(Recurse: boolean); virtual;
    procedure Collapse(Recurse: boolean); virtual;

    procedure Delete;
    procedure DeleteChildren;

    function GetFirstChild: TTreeNode;
    function GetLastChild: TTreeNode;
    function GetNextChild(Value: TTreeNode): TTreeNode;
    function GetNextSibling: TTreeNode;
    function GetPrevChild(Value: TTreeNode): TTreeNode;
    function GetPrevSibling: TTreeNode;
    function HasAsParent(Value: TTreeNode): Boolean;
    function IsFirstNode: Boolean;
    function IsFirstChild: Boolean;

    property Deleting: Boolean read FDeleting;
    property Expanded: Boolean read FIsExpanded write SetIsExpanded;
    property HasChildren: Boolean read GetHasChildren;
    property Level: Integer read GetLevel;
    property Parent: TTreeNode read GetParent;
    property Text: string read FText write SetText;
    property Data: TObject read FData write SetData;
    property ShowText: Boolean read FShowText write SetShowText default true;
    property NodeType: TNodeType read FNodeType write SetNodeType default ntText;
    property Checked: boolean read FChecked write SetChecked default False;
    property URL: string read FURL write SetURL;

    property ImageCollapsedURL: string read FImageCollapsedURL write SetImageCollapsedURL;
    property ImageURL: string read FImageURL write SetImageURL;
    property Tag: Integer read FTag write SetTag default 0;
    property Hint: string read FHint write SetHint;
  end;


  TNodeEvent = procedure(Sender: TObject; ANode: TTreeNode) of object;

{ TTreeNodes }

  TTreeNodes = class(TPersistent)
  private
    FOwner: TComponent;
    FFirstNode: TTreeNode;
    FNodeList: TDbgList;
    FDeleting: Boolean;
    FReading: Boolean;
    FOnChange: TNotifyEvent;
    FOnExpandedChange: TNodeEvent;
    function GetNodeFromIndex(Index: Integer): TTreeNode;
    //procedure ReadData(Stream: TStream);
    //procedure WriteData(Stream: TStream);
  protected
    //procedure DefineProperties(Filer: TFiler); override;
    procedure SetItem(Index: Integer; Value: TTreeNode);
    function GetCount: Integer;
    function InsertNode(ParentNode: TTreeNode; DesNode: TTreeNode; InsertBefore: Boolean): TTreeNode;
    function IsVeryFirstNode(Node: TTreeNode): Boolean;
    function CreateNode: TTreeNode;
    property Reading: Boolean read FReading;
  public
    constructor Create(AOwner: TComponent);
    destructor Destroy; override;
    function AddFirst(const S: string): TTreeNode; overload;
    function AddFirst(Data: TObject; const S: string): TTreeNode; overload;
    function Add(Sibling: TTreeNode; const S: string): TTreeNode; overload;
    function Add(Sibling: TTreeNode; const S: string; Data: TObject): TTreeNode; overload;
    function Add(const S: string): TTreeNode; overload;
    function Add(const S: string; Data: TObject): TTreeNode; overload;
    function AddChild(Parent: TTreeNode; const S: string): TTreeNode; overload;
    function AddChild(Parent: TTreeNode; const S: string; Data: TObject): TTreeNode; overload;
    function AddChildFirst(Parent: TTreeNode; const S: string): TTreeNode; overload;
    function AddChildFirst(Parent: TTreeNode; const S: string; Data: TObject): TTreeNode; overload;

    procedure Assign(Source: TPersistent); override;
    procedure Clear;
    procedure Delete(Node: TTreeNode);
    function GetFirstNode: TTreeNode;
    function GetLastNode: TTreeNode;
    function Insert(Sibling: TTreeNode; const S: string): TTreeNode;
    function InsertObject(Sibling: TTreeNode; const S: string; Data: TObject): TTreeNode;
    property Count: Integer read GetCount;
    property Item[Index: Integer]: TTreeNode read GetNodeFromIndex; default;
    property Owner: TComponent read FOwner;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnExpandedChange: TNodeEvent read FOnExpandedChange write FOnExpandedChange;
    procedure Changed;
  end;

implementation

uses
  Math;

//------------------------------------------------------------------------------

{ TDbgList }

procedure TDbgList.AssignList(ListA: TList);
var
  I: Integer;
begin
  Clear;
  for I := 0 to ListA.Count - 1 do
    Add(ListA[I]);
end;

//------------------------------------------------------------------------------

function TDbgList.GetItemsEx(Index: Integer): TTreeNode;
begin
  if (Index >= Count) then
  begin
    raise Exception.Create('Index out of bounds in list read access');
  end;

  if Index < Count then
    Result := TTreeNode(inherited Items[Index])
  else
    Result := nil;
end;

//------------------------------------------------------------------------------

procedure TDbgList.SetItemsEx(Index: Integer; const Value: TTreeNode);
begin
  if (Index >= Count) then
  begin
    raise Exception.Create('Index out of bounds in list write access');
  end;
  if Index < Count then
    inherited Items[Index] := value;
end;

//------------------------------------------------------------------------------

{ TTreeNode }

constructor TTreeNode.Create(AOwner: TTreeNodes);
begin
  inherited Create;
  FOwner := AOwner;
  FShowText := true;
  FNodeType := ntText;
  FChecked := False;
  FIsExpanded := False;
  FTag := 0;
end;

//------------------------------------------------------------------------------

destructor TTreeNode.Destroy;
var
  N, PN, NN: TTreeNode;
  i: Integer;
begin
  FDeleting := True;

  DeleteChildren;

  N := Parent;
  if (((N <> nil) and (not N.Deleting)) or (N = nil)) and (not Owner.FDeleting) and Assigned(Owner) and Assigned(Owner.Owner) and not (csDestroying in Owner.Owner.ComponentState) then
  begin
    PN := getPrevSibling;
    NN := getNextSibling;

    if not Assigned(NN) then
    begin
      if IsFirstNode then
        Owner.FFirstNode := nil
      else if Assigned(Parent) and IsFirstChild then
        Parent.FFirstChild := nil
      else
      begin
        if Assigned(PN) then
          PN.FNextSibling := nil;
      end;
    end
    else if not Assigned(PN) then
    begin
      if IsFirstNode then
      begin
        Owner.FFirstNode := NN;
        NN.FPrevSibling := PN;
      end
      else if Assigned(Parent) and IsFirstChild then
        Parent.FFirstChild := NN;
    end
    else
    begin
      PN.FNextSibling := NN;
      NN.FPrevSibling := PN;
    end;
  end;

  if not Assigned(Parent) then
  begin
    i := Owner.FNodeList.IndexOf(Self);
    if (i >= 0) then
    begin
      Owner.FNodeList.Delete(i);
    end;
  end;

  Data := nil;
  inherited Destroy;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.Expand(Recurse: boolean);
var
  i: integer;
begin
  Expanded := true;

  if recurse then
  begin
    for i := 0 to Count - 1 do
      Item[i].Expand(Recurse);
  end;
end;

//------------------------------------------------------------------------------

function TTreeNode.HasAsParent(Value: TTreeNode): Boolean;
begin
  if Value <> Nil then
  begin
    if Parent = nil then Result := False
    else if Parent = Value then Result := True
    else Result := Parent.HasAsParent(Value);
  end
  else Result := True;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetTag(const Value: Integer);
begin
  FTag := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetText(const S: string);
begin
  if not Deleting and (S <> Text) then
  begin
    FText := S;
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetURL(const Value: string);
begin
  FURL := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetChecked(const Value: boolean);
begin
  FChecked := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetData(Value: TObject);
begin
  if not Deleting and (Value <> Data) then
  begin
    FData := Value;
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetHint(const Value: string);
begin
  FHint := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetImageCollapsedURL(const Value: string);
begin
  FImageCollapsedURL := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetImageURL(const Value: string);
begin
  FImageURL := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetIsExpanded(const Value: Boolean);
begin
  FIsExpanded := Value;
  if Assigned(FOwner) then
    if Assigned(FOwner.OnExpandedChange) then
      FOwner.OnExpandedChange(FOwner, Self);
end;

//------------------------------------------------------------------------------

procedure TTreeNode.Collapse(Recurse: boolean);
var
  i: integer;
begin
  Expanded := false;

  if recurse then
  begin
    for i := 0 to Count - 1 do
      Item[i].Collapse(Recurse);
  end;
end;

//------------------------------------------------------------------------------

function TTreeNode.CompareCount(CompareMe: Integer): Boolean;
var
  Count: integer;
  Node: TTreeNode;
Begin
  Count := 0;
  Result := False;
  Node := GetFirstChild;
  while Node <> nil do
  begin
    Inc(Count);
    Node := Node.GetNextChild(Node);
    if Count > CompareMe then
      Exit;
  end;
  if Count = CompareMe then
    Result := True;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetHasChildren: Boolean;
begin
  Result := Assigned(FFirstChild);
end;

//------------------------------------------------------------------------------

function TTreeNode.GetParent: TTreeNode;
begin
  Result := FParentNode;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetNextSibling: TTreeNode;
begin
  Result := FNextSibling;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetPrevSibling: TTreeNode;
begin
  Result := FPrevSibling;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetNextChild(Value: TTreeNode): TTreeNode;
begin
  if Value <> nil then Result := Value.GetNextSibling
  else Result := nil;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetPrevChild(Value: TTreeNode): TTreeNode;
begin
  if Value <> nil then Result := Value.GetPrevSibling
  else Result := nil;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetFirstChild: TTreeNode;
begin
  Result := FFirstChild;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetLastChild: TTreeNode;
var
  Node: TTreeNode;
begin
  Result := GetFirstChild;
  if Result <> nil then
  begin
    Node := Result;
    repeat
      Result := Node;
      Node := Result.GetNextSibling;
    until Node = nil;
  end;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetIndex: Integer;
var
  Node: TTreeNode;
begin
  Result := -1;
  Node := Self;
//  while Node <> nil do
  while Assigned(Node) do
  begin
    Inc(Result);
    Node := Node.GetPrevSibling;
  end;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetItem(Index: Integer): TTreeNode;
begin
  Result := GetFirstChild;
  while (Result <> nil) and (Index > 0) do
  begin
    Result := GetNextChild(Result);
    Dec(Index);
  end;
  if Result = nil then raise Exception.Create('Invalid index');
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetItem(Index: Integer; Value: TTreeNode);
begin
  item[Index].Assign(Value);
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetNodeType(const Value: TNodeType);
begin
  FNodeType := Value;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.SetShowText(const Value: Boolean);
begin
  FShowText := Value;
end;

//------------------------------------------------------------------------------

function TTreeNode.IndexOf(Value: TTreeNode): Integer;
var
  Node: TTreeNode;
begin
  Result := -1;
  Node := GetFirstChild;
  while (Node <> nil) do
  begin
    Inc(Result);
    if Node = Value then Break;
    Node := GetNextChild(Node);
  end;
  if Node = nil then Result := -1;
end;

//------------------------------------------------------------------------------

function TTreeNode.GetCount: Integer;
var
  Node: TTreeNode;
begin
  Result := 0;
  Node := GetFirstChild;
  while Node <> nil do
  begin
    Inc(Result);
    Node := Node.GetNextChild(Node);
  end;
end;

//------------------------------------------------------------------------------

function TTreeNode.IsFirstNode: Boolean;
begin
  Result := Self = Owner.FFirstNode; // not Deleting and (Parent = nil) and (GetPrevSibling = nil);
end;

//------------------------------------------------------------------------------

function TTreeNode.IsFirstChild: Boolean;
begin
  Result := IsFirstNode or (Assigned(Parent) and (Parent.getFirstChild = Self));
end;

//------------------------------------------------------------------------------

function TTreeNode.GetLevel: Integer;
var
  Node: TTreeNode;
begin
  Result := 0;
  Node := Parent;
  while Node <> nil do
  begin
    Inc(Result);
    Node := Node.Parent;
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.Delete;
begin
  if not Deleting then
    Free;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.DeleteChildren;
var
  ChildList: TDbgList;
  N: TTreeNode;
  i: Integer;
begin
  ChildList := TDbgList.Create;
  N := GetFirstChild;
  if N <> nil then
  begin
    repeat
      ChildList.Add(N);
      N := N.GetNextSibling;
    until N = nil;
  end;

  for i := ChildList.Count - 1 downto 0 do
  begin
    N := ChildList[i];
    N.Free;
    ChildList[i].Delete;
  end;
  ChildList.Free;
end;

//------------------------------------------------------------------------------

function TTreeNode.AddChild(const S: string): TTreeNode;
begin
  Result := AddChild(S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNode.AddChild(const S: string; Data: TObject): TTreeNode;
var
  N: TTreeNode;
begin
  N := GetLastChild;
  if Assigned(N) then
    Result := Owner.InsertNode(nil, N, False)
  else
    Result := Owner.InsertNode(Self, nil, False);
  Result.Text := S;
  Result.Data := Data;
end;

//------------------------------------------------------------------------------

function TTreeNode.AddFirstChild(const S: string): TTreeNode;
begin
  Result := AddFirstChild(S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNode.AddFirstChild(const S: string; Data: TObject): TTreeNode;
begin
  if not Assigned(FFirstChild) then
    Result := Owner.InsertNode(Self, nil, True)
  else
    Result := Owner.InsertNode(nil, FFirstChild, True);
  Result.Text := S;
  Result.Data := Data;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.Assign(Source: TPersistent);
var
  Node: TTreeNode;
begin
  if not Deleting and (Source is TTreeNode) then
  begin
    Node := TTreeNode(Source);
    Text := Node.Text;
    Data := Node.Data;
    ShowText := Node.ShowText;
    NodeType := Node.NodeType;
    Checked := Node.Checked;
    URL := Node.URL;
    Hint := Node.Hint;
    ImageURL := Node.ImageURL;
    ImageCollapsedURL := Node.ImageCollapsedURL;
    Tag := Node.Tag;
    FIsExpanded := Node.Expanded;
  end
  else
    inherited Assign(Source);
end;

//------------------------------------------------------------------------------

function TTreeNode.IsEqual(Node: TTreeNode): Boolean;
begin
  Result := (Text = Node.Text) and (Data = Node.Data);
end;

//------------------------------------------------------------------------------
(*
procedure TTreeNode.ReadData(Stream: TStream; var Info: TNodeDataInfo);
var
  I, Size, ItemCount, SzM: Integer;
  LNode: TTreeNode;
  s: string;
begin
  Stream.ReadBuffer(Size, SizeOf(Size));
  Stream.ReadBuffer(Info, SizeOf(TNodeDataInfo));

  {$IFDEF DELPHI_UNICODE}
  SzM := 2;
  {$ENDIF}

  {$IFNDEF DELPHI_UNICODE}
  SzM := 1;
  {$ENDIF}

  SetLength(S, Info.TextLen);
  Stream.ReadBuffer(S[1], SzM * Info.TextLen);
  Text := S;

  SetLength(S, Info.HintLen);
  Stream.ReadBuffer(S[1], SzM * Info.HintLen);
  Hint := S;

  SetLength(S, Info.URLLen);
  Stream.ReadBuffer(S[1], SzM * Info.URLLen);
  URL := S;

  SetLength(S, Info.ImageURLLen);
  Stream.ReadBuffer(S[1], SzM * Info.ImageURLLen);
  ImageURL := S;

  SetLength(S, Info.ImageCollapsedURLLen);
  Stream.ReadBuffer(S[1], SzM * Info.ImageCollapsedURLLen);
  ImageCollapsedURL := S;

  NodeType := Info.NodeType;
  Checked := Info.Checked;
  Tag := Info.Tag;
  IsExpanded := Info.IsExpanded;

  ItemCount := Info.Count;
  for I := 0 to ItemCount - 1 do
  begin
    LNode := AddChild(''); //Owner.Add(Self, '');
    LNode.FParentNode := Self;
    LNode.ReadData(Stream, Info);
    //Owner.Owner.Added(LNode);
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNode.WriteData(Stream: TStream; var Info: TNodeDataInfo);
var
  I, Size, ItemCount, SzM: Integer;
begin
  //L := Length(Text);
  //if L > 255 then L := 255;
  //Size := SizeOf(TNodeInfo) + L - 255;

  //L := Min(255, Length(Text)) + Min(255, Length(URL)) + Min(255, Length(Hint)) + Min(255, Length(ImageURL)) + Min(255, Length(ImageCollapsedURL));
  //Size := SizeOf(TNodeInfo) + L - 255 * 5;

  {$IFDEF DELPHI_UNICODE}
  SzM := 2;
  {$ENDIF}

  {$IFNDEF DELPHI_UNICODE}
  SzM := 1;
  {$ENDIF}

  Info.TextLen := Min(255, Length(Text));
  Info.HintLen := Min(255, Length(Hint));
  Info.URLLen := Min(255, Length(URL));
  Info.ImageURLLen := Min(255, Length(ImageURL));
  Info.ImageCollapsedURLLen := Min(255, Length(ImageCollapsedURL));

  Info.NodeType := NodeType;
  Info.Checked := Checked;
  Info.Tag := Tag;
  Info.IsExpanded := IsExpanded;

  ItemCount := Count;
  Info.Count := ItemCount;

  Size := SizeOf(TNodeDataInfo) + (Info.TextLen + Info.HintLen + Info.URLLen + Info.ImageURLLen + Info.ImageCollapsedURLLen);

  Stream.WriteBuffer(Size, SizeOf(Size));
  Stream.WriteBuffer(Info, SizeOf(TNodeDataInfo));
  Stream.WriteBuffer(Text[1], SzM * Info.TextLen);
  Stream.WriteBuffer(Hint[1], SzM * Info.HintLen);
  Stream.WriteBuffer(URL[1], SzM * Info.URLLen);
  Stream.WriteBuffer(ImageURL[1], SzM * Info.ImageURLLen);
  Stream.WriteBuffer(ImageCollapsedURL[1], SzM * Info.ImageCollapsedURLLen);

  for I := 0 to ItemCount - 1 do
    Item[I].WriteData(Stream, Info);
end;
*)
//------------------------------------------------------------------------------

{ TTreeNodes }

constructor TTreeNodes.Create(AOwner: TComponent);
begin
  inherited Create;
  FOwner := AOwner;
  FFirstNode := nil;
  FNodeList :=  TDbgList.Create;
end;

//------------------------------------------------------------------------------

function TTreeNodes.CreateNode: TTreeNode;
var
  LClass: TTreeNodeClass;
begin
  LClass := TTreeNode;
  Result := LClass.Create(Self);
end;

//------------------------------------------------------------------------------

destructor TTreeNodes.Destroy;
begin
  FDeleting := True;
  Clear;
  FNodeList.Free;
  inherited Destroy;
end;

//------------------------------------------------------------------------------

procedure TTreeNodes.Delete(Node: TTreeNode);
begin
  Node.Delete;
end;

//------------------------------------------------------------------------------

procedure TTreeNodes.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTreeNodes.Clear;
var
  i: Integer;
begin
  for i := FNodeList.Count - 1 downto 0 do
    FNodeList[i].Delete;

  FNodeList.Clear;
end;

//------------------------------------------------------------------------------

function TTreeNodes.GetCount: Integer;
begin
  Result := FNodeList.Count;
end;

//------------------------------------------------------------------------------

function TTreeNodes.GetNodeFromIndex(Index: Integer): TTreeNode;
begin
  Result := nil;
  if (Index >= 0) and (Index < FNodeList.Count) then
    Result := FNodeList[Index];
end;

//------------------------------------------------------------------------------

procedure TTreeNodes.SetItem(Index: Integer; Value: TTreeNode);
begin
  if (Index >= 0) and (Index < FNodeList.Count) then
    GetNodeFromIndex(Index).Assign(Value);
end;

//------------------------------------------------------------------------------

function TTreeNodes.Insert(Sibling: TTreeNode; const S: string): TTreeNode;
begin
  Result := InsertObject(Sibling, S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNodes.InsertObject(Sibling: TTreeNode; const S: string;
  Data: TObject): TTreeNode;
begin
  Result := InsertNode(nil, Sibling, True);
  if Assigned(Result) then
  begin
    Result.Text := S;
    Result.Data := Data;
  end;
end;

//------------------------------------------------------------------------------

function TTreeNodes.IsVeryFirstNode(Node: TTreeNode): Boolean;
begin
  Result := Assigned(Node) and (Node = FFirstNode);  
end;

//------------------------------------------------------------------------------

function TTreeNodes.GetFirstNode: TTreeNode;
begin
  Result := FFirstNode;
end;

//------------------------------------------------------------------------------

function TTreeNodes.GetLastNode: TTreeNode;
begin
  Result := FFirstNode;
  if Assigned(Result) then
    while (Result.getNextSibling <> nil) do
      Result := Result.getNextSibling;
end;

//------------------------------------------------------------------------------

function TTreeNodes.Add(Sibling: TTreeNode; const S: string): TTreeNode;
begin
  Result := Add(Sibling, S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNodes.Add(Sibling: TTreeNode; const S: string; Data: TObject): TTreeNode;
begin
  Result := InsertNode(Sibling, GetLastNode, False);
  Result.Text := S;
  Result.Data := Data;
end;

//------------------------------------------------------------------------------

function TTreeNodes.Add(const S: string): TTreeNode;
begin
  Result := Add(S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNodes.Add(const S: string; Data: TObject): TTreeNode;
begin
  Result := InsertNode(nil, GetLastNode, False);
  Result.Text := S;
  Result.Data := Data;
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddChild(Parent: TTreeNode; const S: string): TTreeNode;
begin
  Result := AddChild(Parent, S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddChild(Parent: TTreeNode;
  const S: string; Data: TObject): TTreeNode;
begin
  Result := nil;

  if Assigned(Parent) then
    Result := Parent.AddChild(S, Data);
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddChildFirst(Parent: TTreeNode;
  const S: string): TTreeNode;
begin
  Result := AddChildFirst(Parent, S, nil);
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddChildFirst(Parent: TTreeNode;
  const S: string; Data: TObject): TTreeNode;
begin
  Result := nil;
  if Assigned(Parent) then
    Result := Parent.AddFirstChild(S, Data)
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddFirst(const S: string): TTreeNode;
begin
  Result := AddFirst(nil, S);
end;

//------------------------------------------------------------------------------

function TTreeNodes.AddFirst(Data: TObject; const S: string): TTreeNode;
begin
  if not Assigned(FFirstNode) then
  begin
    //Result := CreateNode;
    //FFirstNode := Result
    Result := InsertNode(nil, nil, True);
  end
  else
  begin
    Result := InsertNode(nil, FFirstNode, True);
  end;
  Result.Text := S;
  Result.Data := Data;
end;

//------------------------------------------------------------------------------

function TTreeNodes.InsertNode(ParentNode: TTreeNode; DesNode: TTreeNode; InsertBefore: Boolean): TTreeNode;
var
  N: TTreeNode;
begin
  Result := nil;
  if not Assigned(DesNode) then
  begin
    if Assigned(ParentNode) then
    begin
      N := ParentNode.GetLastChild;
      if Assigned(N) then
      begin
        InsertNode(nil, N, False);
        Exit;
      end
      else  // First Child Node
      begin
        Result := CreateNode;
        ParentNode.FFirstChild := Result;
        Result.FParentNode := ParentNode;
        Exit;
        {
        if Assigned(ParentNode.GetNextSibling) then
           FNodeList.Insert(ParentNode.GetNextSibling.Index, Result)
        else
          FNodeList.Add(Result);
        }
      end;
    end
    else
    begin
      if not Assigned(FFirstNode) then
      begin
        Result := CreateNode;
        FFirstNode := Result;  // very first Node
        FNodeList.Add(Result);
      end;
    end;
  end
  else
  begin
    Result := CreateNode;
    if InsertBefore then
    begin
      if IsVeryFirstNode(DesNode) then
      begin
        FFirstNode := Result;
        Result.FNextSibling := DesNode;
        DesNode.FPrevSibling := Result;
        FNodeList.Insert(0,Result);
      end
      else
      begin
//fix: if DesNode is not the first node in a treeview, but a first child in a parent node (with DesNode.FPrevSibling = nil),
//then execution continues on "else" branch and DesNode.Parent.FFirstChild does not change
//- it remains pointing to DesNode, not on newly inserted node
//        if DesNode.IsFirstNode and Assigned(DesNode.Parent) then
        if DesNode.IsFirstChild and Assigned(DesNode.Parent) then
        begin
          DesNode.Parent.FFirstChild := Result;
          Result.FNextSibling := DesNode;
          DesNode.FPrevSibling := Result;
          Result.FParentNode := DesNode.Parent;
        end
        else
        begin
          Result.FPrevSibling := DesNode.FPrevSibling;
          Result.FNextSibling := DesNode;
          DesNode.FPrevSibling := Result;
          Result.FParentNode := DesNode.Parent;
          if Assigned(Result.FPrevSibling) then
            Result.FPrevSibling.FNextSibling := Result;
        end;
      end;
    end
    else // Insert after DesNode
    begin
      Result.FNextSibling := DesNode.FNextSibling;
      Result.FPrevSibling := DesNode;
      DesNode.FNextSibling := Result;
      if Assigned(Result.FNextSibling) then
        Result.FNextSibling.FPrevSibling := Result;
      Result.FParentNode := DesNode.Parent;
      if not Assigned(DesNode.Parent) then  // Root Node
        FNodeList.Add(Result);
    end;
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNodes.Assign(Source: TPersistent);
var
//  TreeNodes: TTreeNodes;
//  MemStream: TMemoryStream;
begin

  if Source is TTreeNodes then
  begin
//    TreeNodes := TTreeNodes(Source);
    Clear;
    (*
    MemStream := TMemoryStream.Create;
    try
      TreeNodes.WriteData(MemStream);
      MemStream.Position := 0;
      ReadData(MemStream);
    finally
      MemStream.Free;
    end;
    *)
  end
  else inherited Assign(Source);
end;

//------------------------------------------------------------------------------
(*
procedure TTreeNodes.DefineProperties(Filer: TFiler);

  function WriteNodes: Boolean;
  var
    I: Integer;
    Nodes: TTreeNodes;
  begin
    Nodes := TTreeNodes(Filer.Ancestor);
    if Nodes = nil then
      Result := Count > 0
    else if Nodes.Count <> Count then
      Result := True
    else
    begin
      Result := False;
      for I := 0 to Count - 1 do
      begin
        Result := not Item[I].IsEqual(Nodes[I]);
        if Result then
          Break;
      end
    end;
  end;

begin
  inherited DefineProperties(Filer);
  Filer.DefineBinaryProperty('Data', ReadData, WriteData, WriteNodes);
end;
*)
//------------------------------------------------------------------------------
(*
procedure TTreeNodes.ReadData(Stream: TStream);
var
  I, Count: Integer;
  NodeInfo: TNodeDataInfo;
  LNode: TTreeNode;
begin
  FReading := True;
  try
    Clear;
    Stream.ReadBuffer(Count, SizeOf(Count));
    for I := 0 to Count - 1 do
    begin
      LNode := Add('');
      LNode.ReadData(Stream, NodeInfo);
    end;
  finally
    FReading := False;
  end;
end;

//------------------------------------------------------------------------------

procedure TTreeNodes.WriteData(Stream: TStream);
var
  I: Integer;
  Node: TTreeNode;
  NodeInfo: TNodeDataInfo;
begin
  I := 0;
  Node := GetFirstNode;
  while Node <> nil do
  begin
    Inc(I);
    Node := Node.GetNextSibling;
  end;
  Stream.WriteBuffer(I, SizeOf(I));
  Node := GetFirstNode;
  while Node <> nil do
  begin
    Node.WriteData(Stream, NodeInfo);
    Node := Node.GetNextSibling;
  end;
end;
*)
//------------------------------------------------------------------------------


end.
