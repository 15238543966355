unit unit_Preise;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Grids, DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.StdCtrls;

type
  TPreise = class(TForm)
    TC: TTableControl;
    xdsKundenpreise: TXDataWebDataSet;
    xdsKundenpreiseArtikelnummer: TXDataWebEntityField;
    xdsKundenpreiseKundennummer: TXDataWebEntityField;
    xdsKundenpreiseVerkaufspreis: TFloatField;
    xdsKundenpreiseNachname: TStringField;
    xdsKundenpreiseRabattsatz: TFloatField;
    xdsKundenpreiseVerleihpreis: TFloatField;
    xdsKundenpreiseZusatzPreis: TFloatField;
    xdsKundenpreiseArtikelnummerKunde: TStringField;
    xdsKundenpreiseDatumGeaendert: TDateTimeField;
    xdsKundenpreiseArtikelueberschrift: TStringField;
    xdsKundenpreiseArtikelnummerArtikelnummer: TStringField;
    xdsKundenpreiseArtikelnummerArtikelueberschrift: TStringField;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung: TStringField;
    xdsLieferantenpreise: TXDataWebDataSet;
    xdsLieferantenpreiseArtikelnummer: TXDataWebEntityField;
    xdsLieferantenpreiseEinkaufspreis: TFloatField;
    xdsLieferantenpreiseNachname: TStringField;
    xdsLieferantenpreiseDatumGeaendert: TDateTimeField;
    xdsLieferantenpreiseArtikelnummerLieferant: TStringField;
    xdsLieferantenpreiseERabattsatz: TFloatField;
    xdsLieferantenpreiseMindestbestellmenge: TFloatField;
    xdsLieferantenpreiseKundennummer: TXDataWebEntityField;
    xdsLieferantenpreiseArtikelnummerArtikelnummer: TStringField;
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift: TStringField;
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure xdsKundenpreiseAfterOpen(DataSet: TDataSet);
    procedure xdsLieferantenpreiseAfterOpen(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    mykontaktnummer:string;
    mykontaktname:string;
    buttonzurueck:TButton;
    procedure buttonzurueckclick(Sender:TObject);



  end;

var
  Preise: TPreise;

implementation
  uses unit1, unit2;

procedure TPreise.buttonzurueckclick(Sender:TObject);
begin
  xdsKundenpreise.Close;
  xdsLieferantenpreise.Close;
  self.Close;
end;

procedure TPreise.WebFormShow(Sender: TObject);
begin
  xdsKundenpreise.Close;
  xdskundenpreise.QueryString:='$expand=Artikelnummer&$filter=Kundennummer eq '+QuotedStr(mykontaktnummer);
  xdsKundenpreise.Load;
end;

procedure TPreise.xdsKundenpreiseAfterOpen(DataSet: TDataSet);
begin
  xdsLieferantenpreise.Close;
  xdsLieferantenpreise.QueryString:='$expand=Artikelnummer&$filter=Kundennummer eq '+QuotedStr(mykontaktnummer);
  xdsLieferantenpreise.Load;
end;

procedure TPreise.xdsLieferantenpreiseAfterOpen(DataSet: TDataSet);
var
  i:integer;
  myelement:TJSHTMLElement;
begin
  i:=0;
  TC.ColCount:=3;
  TC.Rowcount:=xdsKundenpreise.RecordCount+xdsLieferantenpreise.RecordCount+6;

  TC.Cells[0,i]:='<h4>Preise';
  inc(i);
  myelement:=TC.cellelements[0,i];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
  inc(i);
  TC.Cells[0,i]:='<h5>'+mykontaktname+' ('+mykontaktnummer+')';
  if xdsKundenpreise.RecordCount>0 then
  begin
    inc(i);
    TC.Cells[0,i]:='<h6><b>Kundenpreise';
    TC.Cells[1,i]:='<h6><b>Verkaufspreis';
    TC.Cells[2,i]:='<h6><b>Rabattsatz';
    xdsKundenpreise.First;
    while not xdskundenpreise.eof do
    begin
      inc(i);
      TC.Cells[0,i]:=xdsKundenpreiseArtikelnummerArtikelnummer.AsString+'<br>'+xdsKundenpreiseArtikelnummerArtikelueberschrift.asstring;
      TC.Cells[1,i]:=formatfloat('#,##0.00',xdsKundenpreiseVerkaufspreis.Asfloat);
      TC.Cells[2,i]:=formatfloat('#,##0.00 %',xdsKundenpreiseRabattsatz.Asfloat);
      xdskundenpreise.Next;
    end;
  end;

  if xdsLieferantenpreise.RecordCount>0 then
  begin
    inc(i);
    TC.Cells[0,i]:='<h6><b>Lieferantenpreise';
    TC.Cells[1,i]:='<h6><b>Einkaufspreis';
    TC.Cells[2,i]:='<h6><b>Rabattsatz';
    xdsLieferantenpreise.First;
    while not xdsLieferantenpreise.eof do
    begin
      inc(i);
      TC.Cells[0,i]:=xdsLieferantenpreiseArtikelnummerArtikelnummer.AsString+'<br>'+xdsLieferantenpreiseArtikelnummerArtikelnummer.asstring;
      if xdsLieferantenpreiseArtikelnummerLieferant.AsString<>'' then
      begin
        TC.Cells[0,i]:=TC.Cells[0,i]+'<br>AN-Lieferant: '+xdsLieferantenpreiseArtikelnummerLieferant.AsString;
      end;
      if xdsLieferantenpreiseMindestbestellmenge.Asfloat>0 then
      begin
        TC.Cells[0,i]:=TC.Cells[0,i]+'<br>Mindestbestellmenge: '+xdsLieferantenpreiseMindestbestellmenge.AsString;
      end;
      TC.Cells[1,i]:=formatfloat('#,##0.00',xdsLieferantenpreiseEinkaufspreis.Asfloat);
      TC.Cells[2,i]:=formatfloat('#,##0.00 %',xdsLieferantenpreiseERabattsatz.Asfloat);
      xdsLieferantenpreise.Next;
    end;
  end;

  inc(i);
  myelement:=TC.cellelements[0,i];
  myelement.innerHTML:='';
  buttonzurueck:=TButton.Create(self);
  buttonzurueck.ElementClassName:='btn btn-info text-left';
  buttonzurueck.HeightStyle:=ssauto;
  buttonzurueck.WidthStyle:=ssauto;
  buttonzurueck.tag:=0;
  buttonzurueck.Caption:='<< zurück';
  buttonzurueck.OnClick:=@buttonzurueckClick;
  buttonzurueck.ParentElement:=myelement;
end;

procedure TPreise.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  xdsKundenpreise := TXDataWebDataSet.Create(Self);
  xdsKundenpreiseArtikelnummer := TXDataWebEntityField.Create(Self);
  xdsKundenpreiseKundennummer := TXDataWebEntityField.Create(Self);
  xdsKundenpreiseVerkaufspreis := TFloatField.Create(Self);
  xdsKundenpreiseNachname := TStringField.Create(Self);
  xdsKundenpreiseRabattsatz := TFloatField.Create(Self);
  xdsKundenpreiseVerleihpreis := TFloatField.Create(Self);
  xdsKundenpreiseZusatzPreis := TFloatField.Create(Self);
  xdsKundenpreiseArtikelnummerKunde := TStringField.Create(Self);
  xdsKundenpreiseDatumGeaendert := TDateTimeField.Create(Self);
  xdsKundenpreiseArtikelueberschrift := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelnummer := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelueberschrift := TStringField.Create(Self);
  xdsKundenpreiseArtikelnummerArtikelbeschreibung := TStringField.Create(Self);
  xdsLieferantenpreise := TXDataWebDataSet.Create(Self);
  xdsLieferantenpreiseArtikelnummer := TXDataWebEntityField.Create(Self);
  xdsLieferantenpreiseKundennummer := TXDataWebEntityField.Create(Self);
  xdsLieferantenpreiseEinkaufspreis := TFloatField.Create(Self);
  xdsLieferantenpreiseNachname := TStringField.Create(Self);
  xdsLieferantenpreiseDatumGeaendert := TDateTimeField.Create(Self);
  xdsLieferantenpreiseArtikelnummerLieferant := TStringField.Create(Self);
  xdsLieferantenpreiseERabattsatz := TFloatField.Create(Self);
  xdsLieferantenpreiseMindestbestellmenge := TFloatField.Create(Self);
  xdsLieferantenpreiseArtikelnummerArtikelnummer := TStringField.Create(Self);
  xdsLieferantenpreiseArtikelnummerArtikelueberschrift := TStringField.Create(Self);
  xdsLieferantenpreiseArtikelnummerArtikelbeschreibung := TStringField.Create(Self);

  TC.BeforeLoadDFMValues;
  xdsKundenpreise.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummer.BeforeLoadDFMValues;
  xdsKundenpreiseKundennummer.BeforeLoadDFMValues;
  xdsKundenpreiseVerkaufspreis.BeforeLoadDFMValues;
  xdsKundenpreiseNachname.BeforeLoadDFMValues;
  xdsKundenpreiseRabattsatz.BeforeLoadDFMValues;
  xdsKundenpreiseVerleihpreis.BeforeLoadDFMValues;
  xdsKundenpreiseZusatzPreis.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerKunde.BeforeLoadDFMValues;
  xdsKundenpreiseDatumGeaendert.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelueberschrift.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelnummer.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelueberschrift.BeforeLoadDFMValues;
  xdsKundenpreiseArtikelnummerArtikelbeschreibung.BeforeLoadDFMValues;
  xdsLieferantenpreise.BeforeLoadDFMValues;
  xdsLieferantenpreiseArtikelnummer.BeforeLoadDFMValues;
  xdsLieferantenpreiseKundennummer.BeforeLoadDFMValues;
  xdsLieferantenpreiseEinkaufspreis.BeforeLoadDFMValues;
  xdsLieferantenpreiseNachname.BeforeLoadDFMValues;
  xdsLieferantenpreiseDatumGeaendert.BeforeLoadDFMValues;
  xdsLieferantenpreiseArtikelnummerLieferant.BeforeLoadDFMValues;
  xdsLieferantenpreiseERabattsatz.BeforeLoadDFMValues;
  xdsLieferantenpreiseMindestbestellmenge.BeforeLoadDFMValues;
  xdsLieferantenpreiseArtikelnummerArtikelnummer.BeforeLoadDFMValues;
  xdsLieferantenpreiseArtikelnummerArtikelueberschrift.BeforeLoadDFMValues;
  xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.BeforeLoadDFMValues;
  try
    Name := 'Preise';
    Left := 0;
    Top := 0;
    Width := 171;
    Height := 212;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 0;
    TC.Width := 171;
    TC.Height := 212;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.Align := alClient;
    TC.BorderColor := clSilver;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    xdsKundenpreise.SetParentComponent(Self);
    xdsKundenpreise.Name := 'xdsKundenpreise';
    xdsKundenpreise.AfterOpen := xdsKundenpreiseAfterOpen;
    xdsKundenpreise.EntitySetName := 'ArtikelKundenpreise';
    xdsKundenpreise.Connection := DM2.XDataWebConnection1;
    xdsKundenpreise.SubpropsDepth := 1;
    xdsKundenpreise.QueryString := '$expand=Artikelnummer';
    xdsKundenpreise.Left := 71;
    xdsKundenpreise.Top := 22;
    xdsKundenpreiseArtikelnummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummer.Name := 'xdsKundenpreiseArtikelnummer';
    xdsKundenpreiseArtikelnummer.FieldName := 'Artikelnummer';
    xdsKundenpreiseArtikelnummer.Required := True;
    xdsKundenpreiseKundennummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseKundennummer.Name := 'xdsKundenpreiseKundennummer';
    xdsKundenpreiseKundennummer.FieldName := 'Kundennummer';
    xdsKundenpreiseKundennummer.Required := True;
    xdsKundenpreiseVerkaufspreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseVerkaufspreis.Name := 'xdsKundenpreiseVerkaufspreis';
    xdsKundenpreiseVerkaufspreis.FieldName := 'Verkaufspreis';
    xdsKundenpreiseNachname.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseNachname.Name := 'xdsKundenpreiseNachname';
    xdsKundenpreiseNachname.FieldName := 'Nachname';
    xdsKundenpreiseNachname.Size := 40;
    xdsKundenpreiseRabattsatz.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseRabattsatz.Name := 'xdsKundenpreiseRabattsatz';
    xdsKundenpreiseRabattsatz.FieldName := 'Rabattsatz';
    xdsKundenpreiseVerleihpreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseVerleihpreis.Name := 'xdsKundenpreiseVerleihpreis';
    xdsKundenpreiseVerleihpreis.FieldName := 'Verleihpreis';
    xdsKundenpreiseZusatzPreis.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseZusatzPreis.Name := 'xdsKundenpreiseZusatzPreis';
    xdsKundenpreiseZusatzPreis.FieldName := 'ZusatzPreis';
    xdsKundenpreiseArtikelnummerKunde.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerKunde.Name := 'xdsKundenpreiseArtikelnummerKunde';
    xdsKundenpreiseArtikelnummerKunde.FieldName := 'ArtikelnummerKunde';
    xdsKundenpreiseArtikelnummerKunde.Size := 25;
    xdsKundenpreiseDatumGeaendert.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseDatumGeaendert.Name := 'xdsKundenpreiseDatumGeaendert';
    xdsKundenpreiseDatumGeaendert.FieldName := 'DatumGeaendert';
    xdsKundenpreiseArtikelueberschrift.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelueberschrift.Name := 'xdsKundenpreiseArtikelueberschrift';
    xdsKundenpreiseArtikelueberschrift.FieldName := 'Artikelueberschrift';
    xdsKundenpreiseArtikelueberschrift.Size := 50;
    xdsKundenpreiseArtikelnummerArtikelnummer.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelnummer.Name := 'xdsKundenpreiseArtikelnummerArtikelnummer';
    xdsKundenpreiseArtikelnummerArtikelnummer.FieldName := 'Artikelnummer.Artikelnummer';
    xdsKundenpreiseArtikelnummerArtikelnummer.Size := 25;
    xdsKundenpreiseArtikelnummerArtikelueberschrift.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelueberschrift.Name := 'xdsKundenpreiseArtikelnummerArtikelueberschrift';
    xdsKundenpreiseArtikelnummerArtikelueberschrift.FieldName := 'Artikelnummer.Artikelueberschrift';
    xdsKundenpreiseArtikelnummerArtikelueberschrift.Size := 50;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.SetParentComponent(xdsKundenpreise);
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.Name := 'xdsKundenpreiseArtikelnummerArtikelbeschreibung';
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.FieldName := 'Artikelnummer.Artikelbeschreibung';
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.Size := 255;
    xdsLieferantenpreise.SetParentComponent(Self);
    xdsLieferantenpreise.Name := 'xdsLieferantenpreise';
    xdsLieferantenpreise.AfterOpen := xdsLieferantenpreiseAfterOpen;
    xdsLieferantenpreise.EntitySetName := 'ArtikelLieferantenpreise';
    xdsLieferantenpreise.Connection := DM2.XDataWebConnection1;
    xdsLieferantenpreise.SubpropsDepth := 1;
    xdsLieferantenpreise.QueryString := '$expand=Artikelnummer';
    xdsLieferantenpreise.Left := 71;
    xdsLieferantenpreise.Top := 134;
    xdsLieferantenpreiseArtikelnummer.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseArtikelnummer.Name := 'xdsLieferantenpreiseArtikelnummer';
    xdsLieferantenpreiseArtikelnummer.FieldName := 'Artikelnummer';
    xdsLieferantenpreiseArtikelnummer.Required := True;
    xdsLieferantenpreiseKundennummer.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseKundennummer.Name := 'xdsLieferantenpreiseKundennummer';
    xdsLieferantenpreiseKundennummer.FieldName := 'Kundennummer';
    xdsLieferantenpreiseKundennummer.Required := True;
    xdsLieferantenpreiseEinkaufspreis.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseEinkaufspreis.Name := 'xdsLieferantenpreiseEinkaufspreis';
    xdsLieferantenpreiseEinkaufspreis.FieldName := 'Einkaufspreis';
    xdsLieferantenpreiseNachname.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseNachname.Name := 'xdsLieferantenpreiseNachname';
    xdsLieferantenpreiseNachname.FieldName := 'Nachname';
    xdsLieferantenpreiseNachname.Size := 40;
    xdsLieferantenpreiseDatumGeaendert.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseDatumGeaendert.Name := 'xdsLieferantenpreiseDatumGeaendert';
    xdsLieferantenpreiseDatumGeaendert.FieldName := 'DatumGeaendert';
    xdsLieferantenpreiseArtikelnummerLieferant.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseArtikelnummerLieferant.Name := 'xdsLieferantenpreiseArtikelnummerLieferant';
    xdsLieferantenpreiseArtikelnummerLieferant.FieldName := 'ArtikelnummerLieferant';
    xdsLieferantenpreiseArtikelnummerLieferant.Size := 25;
    xdsLieferantenpreiseERabattsatz.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseERabattsatz.Name := 'xdsLieferantenpreiseERabattsatz';
    xdsLieferantenpreiseERabattsatz.FieldName := 'ERabattsatz';
    xdsLieferantenpreiseMindestbestellmenge.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseMindestbestellmenge.Name := 'xdsLieferantenpreiseMindestbestellmenge';
    xdsLieferantenpreiseMindestbestellmenge.FieldName := 'Mindestbestellmenge';
    xdsLieferantenpreiseArtikelnummerArtikelnummer.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseArtikelnummerArtikelnummer.Name := 'xdsLieferantenpreiseArtikelnummerArtikelnummer';
    xdsLieferantenpreiseArtikelnummerArtikelnummer.FieldName := 'Artikelnummer.Artikelnummer';
    xdsLieferantenpreiseArtikelnummerArtikelnummer.Size := 25;
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift.Name := 'xdsLieferantenpreiseArtikelnummerArtikelueberschrift';
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift.FieldName := 'Artikelnummer.Artikelueberschrift';
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift.Size := 50;
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.SetParentComponent(xdsLieferantenpreise);
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.Name := 'xdsLieferantenpreiseArtikelnummerArtikelbeschreibung';
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.FieldName := 'Artikelnummer.Artikelbeschreibung';
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.Size := 255;
  finally
    TC.AfterLoadDFMValues;
    xdsKundenpreise.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummer.AfterLoadDFMValues;
    xdsKundenpreiseKundennummer.AfterLoadDFMValues;
    xdsKundenpreiseVerkaufspreis.AfterLoadDFMValues;
    xdsKundenpreiseNachname.AfterLoadDFMValues;
    xdsKundenpreiseRabattsatz.AfterLoadDFMValues;
    xdsKundenpreiseVerleihpreis.AfterLoadDFMValues;
    xdsKundenpreiseZusatzPreis.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerKunde.AfterLoadDFMValues;
    xdsKundenpreiseDatumGeaendert.AfterLoadDFMValues;
    xdsKundenpreiseArtikelueberschrift.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelnummer.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelueberschrift.AfterLoadDFMValues;
    xdsKundenpreiseArtikelnummerArtikelbeschreibung.AfterLoadDFMValues;
    xdsLieferantenpreise.AfterLoadDFMValues;
    xdsLieferantenpreiseArtikelnummer.AfterLoadDFMValues;
    xdsLieferantenpreiseKundennummer.AfterLoadDFMValues;
    xdsLieferantenpreiseEinkaufspreis.AfterLoadDFMValues;
    xdsLieferantenpreiseNachname.AfterLoadDFMValues;
    xdsLieferantenpreiseDatumGeaendert.AfterLoadDFMValues;
    xdsLieferantenpreiseArtikelnummerLieferant.AfterLoadDFMValues;
    xdsLieferantenpreiseERabattsatz.AfterLoadDFMValues;
    xdsLieferantenpreiseMindestbestellmenge.AfterLoadDFMValues;
    xdsLieferantenpreiseArtikelnummerArtikelnummer.AfterLoadDFMValues;
    xdsLieferantenpreiseArtikelnummerArtikelueberschrift.AfterLoadDFMValues;
    xdsLieferantenpreiseArtikelnummerArtikelbeschreibung.AfterLoadDFMValues;
  end;
end;

end.