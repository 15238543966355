unit unit_PZE;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.DBCtrls, WEBLib.Buttons, WEBLib.StdCtrls, WEBLib.ExtCtrls, DB, strutils,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, WEBLib.Grids, dateutils;

type
  TPZE = class(TForm)
    xdsSingleArbeitszeit: TXDataWebDataSet;
    xdsSingleArbeitszeitID: TStringField;
    sourceSingleArbeitszeit: TDataSource;
    xdsSingleArbeitszeitPersonalnummer: TIntegerField;
    xdsSingleArbeitszeitDatum: TDateTimeField;
    xdsSingleArbeitszeitEintragsArt: TIntegerField;
    xdsScan: TXDataWebDataSet;
    sourceScan: TDataSource;
    xdsScanPersonalnummer: TIntegerField;
    xdsScanScanDatumZeit: TDateTimeField;
    xdsScanDatum: TDateTimeField;
    xdsScanScanArt: TIntegerField;
    xdsScanObjektreferenz: TStringField;
    xdsScanScanBegin: TDateTimeField;
    xdsScanScanEnde: TDateTimeField;
    xdsScanausgewertet: TBooleanField;
    xdsScanAMS: TFloatField;
    xdsScanStundenArt: TStringField;
    xdsScanFAID: TXDataWebEntityField;
    xdsScanID: TStringField;
    panelfunktionen: TPanel;
    btnAnmelden: TButton;
    btnPauseBeginn: TButton;
    btnPauseEnde: TButton;
    btnAbmelden: TButton;
    btnObjektBeginn: TButton;
    btnObjektEnde: TButton;
    btnErneutAnmelden: TButton;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    btnzurueck: TButton;
    lblstatus: TLabel;
    lblPInfo: TLabel;
    WebDBLabel2: TDBLabel;
    TC: TTableControl;
    procedure WebFormShow(Sender: TObject);
    procedure xdsSingleArbeitszeitAfterOpen(DataSet: TDataSet);
    procedure btnAnmeldenClick(Sender: TObject);
    procedure xdsScanNewRecord(DataSet: TDataSet);
    procedure btnPauseBeginnClick(Sender: TObject);
    procedure btnPauseEndeClick(Sender: TObject);
    procedure xdsScanAfterPost(DataSet: TDataSet);
    procedure xdsScanAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure xdsScanAfterOpen(DataSet: TDataSet);
    procedure btnAbmeldenClick(Sender: TObject);
    procedure btnErneutAnmeldenClick(Sender: TObject);
    procedure btnObjektEndeClick(Sender: TObject);
    procedure btnObjektBeginnClick(Sender: TObject);
    procedure btnzurueckClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    procedure showstatus;
  end;

var
  PZE: TPZE;
  ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms:word;
  datumohnezeit:string;
  mytempdatumzeit:TDateTime;
  myScanArt:integer;
  myDoApply:integer;
  myObjektReferenz:string;
implementation
  uses unit1, unit2, unit_objektsuche;

procedure TPZE.btnAbmeldenClick(Sender: TObject);
begin
  myDoApply:=0;
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  myScanArt:=9;
  xdsscan.Last;
  xdsscan.Edit;
  xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsscan.Post;
  myDoApply:=1;
  xdsscan.Append;
end;

procedure TPZE.btnAnmeldenClick(Sender: TObject);
begin
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  mydoapply:=1;
  myscanart:=1;
  xdsSCAN.Append;
end;

procedure TPZE.btnErneutAnmeldenClick(Sender: TObject);
begin
  myDoApply:=0;
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  myScanArt:=3;
  xdsscan.Last;
  xdsscan.Edit;
  xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsScanScanArt.AsInteger:=2;
  xdsscan.Post;
  myDoApply:=1;
  xdsscan.Append;
end;

procedure TPZE.btnObjektBeginnClick(Sender: TObject);
var
  LFrm:TfrmObjektsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmObjektsuche;
    begin
      LForm:=(AForm as TfrmObjektsuche);
      lform.Objektreferenz:='';
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if lfrm.Objektreferenz<>'' then
      begin
        myobjektreferenz:=lfrm.Objektreferenz;
        myDoApply:=0;
        mytempdatumzeit:=date+time;
        decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
        myScanArt:=4;
        xdsscan.Last;
        xdsscan.Edit;
        xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
        xdsscan.Post;
        myDoApply:=1;
        xdsscan.Append;
      end;
      if lfrm.Objektreferenz='' then
      begin
        showmessage('Der Vorgang wird abgebrochen, da Sie kein Objekt ausgewählt haben');
      end;

    end;

  begin
  LFrm:=TfrmObjektsuche.CreateNew(@AfterCreate);
 // LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TPZE.btnObjektEndeClick(Sender: TObject);
begin
  myDoApply:=0;
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  myScanArt:=5;
  xdsscan.Last;
  xdsscan.Edit;
  xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsscan.Post;
  myDoApply:=1;
  xdsscan.Append;
end;

procedure TPZE.btnPauseBeginnClick(Sender: TObject);
begin
  myDoApply:=0;
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  myScanArt:=2;
  xdsscan.Last;
  xdsscan.Edit;
  xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsscan.Post;
  myDoApply:=1;
  xdsscan.Append;
end;

procedure TPZE.btnPauseEndeClick(Sender: TObject);
begin
  myDoApply:=0;
  mytempdatumzeit:=date+time;
  decodedatetime(mytempdatumzeit,ftjahr,ftmonat,fttag,ftstunde,ftminute,ftsekunde,ftms);
  myScanArt:=3;
  xdsscan.Last;
  xdsscan.Edit;
  xdsScanScanEnde.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsscan.Post;
  myDoApply:=1;
  xdsscan.Append;
end;

procedure TPZE.btnzurueckClick(Sender: TObject);
begin
  self.Close;
end;

procedure TPZE.showstatus;
begin
  xdsscan.Close;
  xdsSingleArbeitszeit.Close;
  xdssingleArbeitszeit.QueryString:='$filter=(EintragsArt eq 0) AND (Personalnummer eq '+Benutzerpersonalnummer+') AND (Datum eq '+formatdatetime('yyyy-mm-dd',date)+')';
  xdsSingleArbeitszeit.load;
end;

procedure TPZE.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>PZE-Zeiterfassung</h5></nav>';
  myelement:=container1.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  lblpinfo.ParentElement:=myelement;
  webdblabel2.ParentElement:=myelement;
  lblstatus.ParentElement:=myelement;

  myelement:=container2.ElementHandle;
  panelfunktionen.ParentElement:=myelement;
  lblpinfo.Caption:=BenutzerPersonalnummer+' '+BenutzerVorname+' '+BenutzerNachname;
  myDoApply:=0;
  showstatus;

end;

procedure TPZE.xdsScanAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
  var
  i:integer;
begin
  for i := 0 to High(info.Records) do
  begin
    if info.Records[i].Error<>'' then showmessage(info.Records[i].Error);
  end;
  showstatus;
end;

procedure TPZE.xdsScanAfterOpen(DataSet: TDataSet);
var
  i:integer;
begin
  xdsscan.First;
  tc.RowCount:=xdsscan.RecordCount+1;
  tc.ColCount:=6;
  i:=0;
  tc.Cells[0,i]:='DatumZeit';
  tc.Cells[1,i]:='Beginn';
  tc.Cells[2,i]:='Ende';
  tc.Cells[3,i]:='Art';
  tc.Cells[4,i]:='Objektreferenz';
  tc.Cells[5,i]:='StundenArt';
  while not xdsscan.eof do
  begin
    inc(i);
    tc.Cells[0,i]:=formatdatetime('DD.MM.YYYY hh:mm:ss', xdsScanScanDatumZeit.asdatetime);
    tc.Cells[1,i]:=formatdatetime('hh:mm:ss', xdsScanScanBegin.asdatetime);
    tc.Cells[2,i]:=formatdatetime('hh:mm:ss', xdsScanScanEnde.asdatetime);
    tc.Cells[3,i]:=xdsScanScanArt.asstring;
    tc.Cells[4,i]:=xdsScanObjektreferenz.asstring;
    tc.Cells[5,i]:=xdsScanStundenArt.asstring;
    xdsscan.Next;
  end;

  xdsscan.last;
  btnanmelden.Visible:=false;
  btnabmelden.Visible:=false;
  btnPauseBeginn.Visible:=false;
  btnpauseende.Visible:=false;
  btnobjektbeginn.Visible:=false;
  btnobjektende.Visible:=false;
  btnErneutAnmelden.Visible:=false;

  if xdsScanScanArt.AsString='' then
  begin
    btnAnmelden.Visible:=true;
    lblstatus.Caption:='Sie haben sich heute noch nicht angemeldet';
  end;
  if xdsScanScanArt.AsInteger=1 then
  begin
    btnabmelden.visible:=true;
    btnPauseBeginn.visible:=true;
    btnObjektBeginn.visible:=true;
    lblstatus.Caption:='Sie sind in der Zeiterfassung angemeldet';
  end;

  if xdsScanScanArt.AsInteger=2 then
  begin
    btnPauseEnde.Visible:=true;
    lblstatus.Caption:='Sie sind momentan in einer Pause';
  end;

  if xdsScanScanArt.AsInteger=3 then
  begin
    btnObjektBeginn.visible:=true;
    btnPauseBeginn.visible:=true;
    btnabmelden.visible:=true;
    lblstatus.Caption:='Sie sind aus der Pause zurück';
  end;

  if xdsScanScanArt.AsInteger=4 then
  begin
    btnObjektEnde.visible:=true;
    lblstatus.Caption:='Sie arbeiten am Objekt: '+xdsSCANObjektreferenz.AsString;
  end;

  if xdsScanScanArt.AsInteger=5 then
  begin
    btnabmelden.visible:=true;
    btnPauseBeginn.visible:=true;
    btnObjektBeginn.visible:=true;
    lblstatus.Caption:='Sie haben die Arbeit an einem speziellen Objekt abgeschlossen';
  end;

  if xdsScanScanArt.AsInteger=9 then
  begin

    lblstatus.Caption:='Sie haben sich für heute bereits abgemeldet';
    btnErneutAnmelden.Visible:=true;
  end;


end;

procedure TPZE.xdsScanAfterPost(DataSet: TDataSet);
begin
  if myDoApply=1 then xdsScan.ApplyUpdates;

end;

procedure TPZE.xdsScanNewRecord(DataSet: TDataSet);
var
  guid:TGUID;
  guidstring:string;
begin
  createguid(guid);
  guidstring:=guidtostring(guid);
  guidstring:=ReplaceStr(guidstring,'{','');
  guidstring:=ReplaceStr(guidstring,'}','');
  //xdsScanID.Asstring:=guidstring;
  xdsScanFAID.Value:=xdsSingleArbeitszeit.CurrentData;
  xdsSCANPersonalnummer.AsString:=Benutzerpersonalnummer;
  xdsScanScanDatumZeit.AsDateTime:=date+time;
  xdsSCANDatum.AsDateTime:=encodedate(ftjahr,ftmonat,fttag);
  xdsSCANScanArt.AsInteger:=myScanArt;
  xdsSCANScanBegin.AsDateTime:=encodetime(ftstunde,ftminute,0,0);
  xdsScanausgewertet.AsBoolean:=false;
  xdsScanAMS.AsFloat:=ftms;
  xdsScanObjektreferenz.AsString:=myObjektReferenz;
  xdsScanStundenArt.AsString:='[-]';
  myObjektReferenz:='';
  xdsscan.Post;
end;

procedure TPZE.xdsSingleArbeitszeitAfterOpen(DataSet: TDataSet);
begin
  if xdsSingleArbeitszeit.RecordCount<1 then
  begin
    showmessage('CadiaPZE scheint für Sie nicht eingerichtet zu sein, sprechen Sie bitte Ihren Administrator an');
  end
  else
  begin
    xdsScan.Close;
    xdsscan.QueryString:='$filter=FAID eq '+ xdsSingleArbeitszeitID.AsString+' &$orderby=ScanDatumZeit,ams';
    xdsscan.load;
  end;
end;

procedure TPZE.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblstatus := TLabel.Create(Self);
  lblPInfo := TLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  panelfunktionen := TPanel.Create(Self);
  btnAnmelden := TButton.Create(Self);
  btnPauseBeginn := TButton.Create(Self);
  btnPauseEnde := TButton.Create(Self);
  btnAbmelden := TButton.Create(Self);
  btnObjektBeginn := TButton.Create(Self);
  btnObjektEnde := TButton.Create(Self);
  btnErneutAnmelden := TButton.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  TC := TTableControl.Create(Self);
  xdsSingleArbeitszeit := TXDataWebDataSet.Create(Self);
  xdsSingleArbeitszeitID := TStringField.Create(Self);
  xdsSingleArbeitszeitPersonalnummer := TIntegerField.Create(Self);
  xdsSingleArbeitszeitDatum := TDateTimeField.Create(Self);
  xdsSingleArbeitszeitEintragsArt := TIntegerField.Create(Self);
  sourceSingleArbeitszeit := TDataSource.Create(Self);
  xdsScan := TXDataWebDataSet.Create(Self);
  xdsScanPersonalnummer := TIntegerField.Create(Self);
  xdsScanScanDatumZeit := TDateTimeField.Create(Self);
  xdsScanDatum := TDateTimeField.Create(Self);
  xdsScanScanArt := TIntegerField.Create(Self);
  xdsScanObjektreferenz := TStringField.Create(Self);
  xdsScanScanBegin := TDateTimeField.Create(Self);
  xdsScanScanEnde := TDateTimeField.Create(Self);
  xdsScanausgewertet := TBooleanField.Create(Self);
  xdsScanAMS := TFloatField.Create(Self);
  xdsScanStundenArt := TStringField.Create(Self);
  xdsScanFAID := TXDataWebEntityField.Create(Self);
  xdsScanID := TStringField.Create(Self);
  sourceScan := TDataSource.Create(Self);

  lblstatus.BeforeLoadDFMValues;
  lblPInfo.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  panelfunktionen.BeforeLoadDFMValues;
  btnAnmelden.BeforeLoadDFMValues;
  btnPauseBeginn.BeforeLoadDFMValues;
  btnPauseEnde.BeforeLoadDFMValues;
  btnAbmelden.BeforeLoadDFMValues;
  btnObjektBeginn.BeforeLoadDFMValues;
  btnObjektEnde.BeforeLoadDFMValues;
  btnErneutAnmelden.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  TC.BeforeLoadDFMValues;
  xdsSingleArbeitszeit.BeforeLoadDFMValues;
  xdsSingleArbeitszeitID.BeforeLoadDFMValues;
  xdsSingleArbeitszeitPersonalnummer.BeforeLoadDFMValues;
  xdsSingleArbeitszeitDatum.BeforeLoadDFMValues;
  xdsSingleArbeitszeitEintragsArt.BeforeLoadDFMValues;
  sourceSingleArbeitszeit.BeforeLoadDFMValues;
  xdsScan.BeforeLoadDFMValues;
  xdsScanPersonalnummer.BeforeLoadDFMValues;
  xdsScanScanDatumZeit.BeforeLoadDFMValues;
  xdsScanDatum.BeforeLoadDFMValues;
  xdsScanScanArt.BeforeLoadDFMValues;
  xdsScanObjektreferenz.BeforeLoadDFMValues;
  xdsScanScanBegin.BeforeLoadDFMValues;
  xdsScanScanEnde.BeforeLoadDFMValues;
  xdsScanausgewertet.BeforeLoadDFMValues;
  xdsScanAMS.BeforeLoadDFMValues;
  xdsScanStundenArt.BeforeLoadDFMValues;
  xdsScanFAID.BeforeLoadDFMValues;
  xdsScanID.BeforeLoadDFMValues;
  sourceScan.BeforeLoadDFMValues;
  try
    Name := 'PZE';
    Width := 261;
    Height := 490;
    Caption := 'PZE';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblstatus.SetParentComponent(Self);
    lblstatus.Name := 'lblstatus';
    lblstatus.Left := 8;
    lblstatus.Top := 125;
    lblstatus.Width := 40;
    lblstatus.Height := 13;
    lblstatus.Caption := 'lblstatus';
    lblstatus.ElementClassName := 'h6 text-primary m-1';
    lblstatus.ElementFont := efCSS;
    lblstatus.HeightStyle := ssAuto;
    lblstatus.WidthStyle := ssAuto;
    lblPInfo.SetParentComponent(Self);
    lblPInfo.Name := 'lblPInfo';
    lblPInfo.AlignWithMargins := True;
    lblPInfo.Left := -2;
    lblPInfo.Top := 144;
    lblPInfo.Width := 59;
    lblPInfo.Height := 13;
    lblPInfo.Alignment := taCenter;
    lblPInfo.Caption := 'lblPInfo';
    lblPInfo.ElementClassName := 'h6 text-primary m-1';
    lblPInfo.ElementFont := efCSS;
    lblPInfo.HeightStyle := ssAuto;
    lblPInfo.WidthStyle := ssAuto;
    WebDBLabel2.SetParentComponent(Self);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.AlignWithMargins := True;
    WebDBLabel2.Left := 11;
    WebDBLabel2.Top := 160;
    WebDBLabel2.Width := 66;
    WebDBLabel2.Height := 13;
    WebDBLabel2.Caption := 'WebDBLabel2';
    WebDBLabel2.ElementClassName := 'h6 text-primary m-1';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.HTMLType := tP;
    WebDBLabel2.WidthStyle := ssAuto;
    WebDBLabel2.DataField := 'Datum';
    WebDBLabel2.DataSource := sourceSingleArbeitszeit;
    panelfunktionen.SetParentComponent(Self);
    panelfunktionen.Name := 'panelfunktionen';
    panelfunktionen.Left := 0;
    panelfunktionen.Top := 179;
    panelfunktionen.Width := 257;
    panelfunktionen.Height := 202;
    panelfunktionen.ElementClassName := 'card m-1 border-info';
    panelfunktionen.BorderColor := clSilver;
    panelfunktionen.BorderStyle := bsSingle;
    panelfunktionen.Caption := 'panelfunktionen';
    panelfunktionen.ChildOrder := 9;
    panelfunktionen.Color := 8340248;
    panelfunktionen.ElementFont := efCSS;
    panelfunktionen.ShowCaption := False;
    btnAnmelden.SetParentComponent(panelfunktionen);
    btnAnmelden.Name := 'btnAnmelden';
    btnAnmelden.AlignWithMargins := True;
    btnAnmelden.Left := 3;
    btnAnmelden.Top := 3;
    btnAnmelden.Width := 251;
    btnAnmelden.Height := 40;
    btnAnmelden.Align := alTop;
    btnAnmelden.Caption := 'Anmelden';
    btnAnmelden.ChildOrder := 7;
    btnAnmelden.ElementClassName := 'btn btn-info btn-sm text-center';
    btnAnmelden.ElementFont := efCSS;
    btnAnmelden.HeightStyle := ssAuto;
    SetEvent(btnAnmelden, Self, 'OnClick', 'btnAnmeldenClick');
    btnPauseBeginn.SetParentComponent(panelfunktionen);
    btnPauseBeginn.Name := 'btnPauseBeginn';
    btnPauseBeginn.AlignWithMargins := True;
    btnPauseBeginn.Left := 3;
    btnPauseBeginn.Top := 49;
    btnPauseBeginn.Width := 251;
    btnPauseBeginn.Height := 40;
    btnPauseBeginn.Align := alTop;
    btnPauseBeginn.Caption := 'Pause Beginn';
    btnPauseBeginn.ChildOrder := 9;
    btnPauseBeginn.ElementClassName := 'btn btn-info btn-sm text-center';
    btnPauseBeginn.ElementFont := efCSS;
    btnPauseBeginn.HeightStyle := ssAuto;
    SetEvent(btnPauseBeginn, Self, 'OnClick', 'btnPauseBeginnClick');
    btnPauseEnde.SetParentComponent(panelfunktionen);
    btnPauseEnde.Name := 'btnPauseEnde';
    btnPauseEnde.AlignWithMargins := True;
    btnPauseEnde.Left := 3;
    btnPauseEnde.Top := 95;
    btnPauseEnde.Width := 251;
    btnPauseEnde.Height := 40;
    btnPauseEnde.Align := alTop;
    btnPauseEnde.Caption := 'Pause Ende';
    btnPauseEnde.ChildOrder := 10;
    btnPauseEnde.ElementClassName := 'btn btn-info btn-sm text-center';
    btnPauseEnde.ElementFont := efCSS;
    btnPauseEnde.HeightStyle := ssAuto;
    SetEvent(btnPauseEnde, Self, 'OnClick', 'btnPauseEndeClick');
    btnAbmelden.SetParentComponent(panelfunktionen);
    btnAbmelden.Name := 'btnAbmelden';
    btnAbmelden.AlignWithMargins := True;
    btnAbmelden.Left := 3;
    btnAbmelden.Top := 233;
    btnAbmelden.Width := 251;
    btnAbmelden.Height := 40;
    btnAbmelden.Align := alTop;
    btnAbmelden.Caption := 'Abmelden';
    btnAbmelden.ChildOrder := 11;
    btnAbmelden.ElementClassName := 'btn btn-info btn-sm text-center';
    btnAbmelden.ElementFont := efCSS;
    btnAbmelden.HeightStyle := ssAuto;
    SetEvent(btnAbmelden, Self, 'OnClick', 'btnAbmeldenClick');
    btnObjektBeginn.SetParentComponent(panelfunktionen);
    btnObjektBeginn.Name := 'btnObjektBeginn';
    btnObjektBeginn.AlignWithMargins := True;
    btnObjektBeginn.Left := 3;
    btnObjektBeginn.Top := 141;
    btnObjektBeginn.Width := 251;
    btnObjektBeginn.Height := 40;
    btnObjektBeginn.Align := alTop;
    btnObjektBeginn.Caption := 'Beginne Objekt';
    btnObjektBeginn.ChildOrder := 13;
    btnObjektBeginn.ElementClassName := 'btn btn-info btn-sm text-center';
    btnObjektBeginn.ElementFont := efCSS;
    btnObjektBeginn.HeightStyle := ssAuto;
    SetEvent(btnObjektBeginn, Self, 'OnClick', 'btnObjektBeginnClick');
    btnObjektEnde.SetParentComponent(panelfunktionen);
    btnObjektEnde.Name := 'btnObjektEnde';
    btnObjektEnde.AlignWithMargins := True;
    btnObjektEnde.Left := 3;
    btnObjektEnde.Top := 187;
    btnObjektEnde.Width := 251;
    btnObjektEnde.Height := 40;
    btnObjektEnde.Align := alTop;
    btnObjektEnde.Caption := 'Ende Objekt';
    btnObjektEnde.ChildOrder := 14;
    btnObjektEnde.ElementClassName := 'btn btn-info btn-sm text-center';
    btnObjektEnde.ElementFont := efCSS;
    btnObjektEnde.HeightStyle := ssAuto;
    SetEvent(btnObjektEnde, Self, 'OnClick', 'btnObjektEndeClick');
    btnErneutAnmelden.SetParentComponent(panelfunktionen);
    btnErneutAnmelden.Name := 'btnErneutAnmelden';
    btnErneutAnmelden.AlignWithMargins := True;
    btnErneutAnmelden.Left := 3;
    btnErneutAnmelden.Top := 279;
    btnErneutAnmelden.Width := 251;
    btnErneutAnmelden.Height := 40;
    btnErneutAnmelden.Align := alTop;
    btnErneutAnmelden.Caption := 'erneut anmelden';
    btnErneutAnmelden.ChildOrder := 12;
    btnErneutAnmelden.ElementClassName := 'btn btn-info btn-sm text-center';
    btnErneutAnmelden.ElementFont := efCSS;
    btnErneutAnmelden.HeightStyle := ssAuto;
    SetEvent(btnErneutAnmelden, Self, 'OnClick', 'btnErneutAnmeldenClick');
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 169;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 47;
    Container2.Width := 169;
    Container2.Height := 41;
    Container2.ElementClassName := 'col d-flex justify-content-center';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 0;
    btnzurueck.Top := 94;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 399;
    TC.Width := 247;
    TC.Height := 81;
    TC.ElementClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.HeightStyle := ssAuto;
    TC.WidthStyle := ssPercent;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 2;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementPosition := epRelative;
    TC.ElementTableClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.Options.ScrollVertical := True;
    TC.ColCount := 2;
    xdsSingleArbeitszeit.SetParentComponent(Self);
    xdsSingleArbeitszeit.Name := 'xdsSingleArbeitszeit';
    xdsSingleArbeitszeit.AfterOpen := xdsSingleArbeitszeitAfterOpen;
    xdsSingleArbeitszeit.EntitySetName := 'PZE_Arbeitszeiten';
    xdsSingleArbeitszeit.Connection := DM2.XDataWebConnection1;
    xdsSingleArbeitszeit.Left := 112;
    xdsSingleArbeitszeit.Top := 16;
    xdsSingleArbeitszeitID.SetParentComponent(xdsSingleArbeitszeit);
    xdsSingleArbeitszeitID.Name := 'xdsSingleArbeitszeitID';
    xdsSingleArbeitszeitID.FieldName := 'ID';
    xdsSingleArbeitszeitID.Required := True;
    xdsSingleArbeitszeitID.Size := 38;
    xdsSingleArbeitszeitPersonalnummer.SetParentComponent(xdsSingleArbeitszeit);
    xdsSingleArbeitszeitPersonalnummer.Name := 'xdsSingleArbeitszeitPersonalnummer';
    xdsSingleArbeitszeitPersonalnummer.FieldName := 'Personalnummer';
    xdsSingleArbeitszeitPersonalnummer.Required := True;
    xdsSingleArbeitszeitDatum.SetParentComponent(xdsSingleArbeitszeit);
    xdsSingleArbeitszeitDatum.Name := 'xdsSingleArbeitszeitDatum';
    xdsSingleArbeitszeitDatum.FieldName := 'Datum';
    xdsSingleArbeitszeitDatum.Required := True;
    xdsSingleArbeitszeitDatum.DisplayFormat := 'DDD DD.MM.YYYY';
    xdsSingleArbeitszeitEintragsArt.SetParentComponent(xdsSingleArbeitszeit);
    xdsSingleArbeitszeitEintragsArt.Name := 'xdsSingleArbeitszeitEintragsArt';
    xdsSingleArbeitszeitEintragsArt.FieldName := 'EintragsArt';
    xdsSingleArbeitszeitEintragsArt.Required := True;
    sourceSingleArbeitszeit.SetParentComponent(Self);
    sourceSingleArbeitszeit.Name := 'sourceSingleArbeitszeit';
    sourceSingleArbeitszeit.DataSet := xdsSingleArbeitszeit;
    sourceSingleArbeitszeit.Left := 32;
    sourceSingleArbeitszeit.Top := 16;
    xdsScan.SetParentComponent(Self);
    xdsScan.Name := 'xdsScan';
    xdsScan.AfterApplyUpdates := xdsScanAfterApplyUpdates;
    xdsScan.AfterOpen := xdsScanAfterOpen;
    xdsScan.AfterPost := xdsScanAfterPost;
    SetEvent(xdsScan, Self, 'OnNewRecord', 'xdsScanNewRecord');
    xdsScan.EntitySetName := 'PZE_ArbeitszeitenScan';
    xdsScan.Connection := DM2.XDataWebConnection1;
    xdsScan.Left := 112;
    xdsScan.Top := 64;
    xdsScanPersonalnummer.SetParentComponent(xdsScan);
    xdsScanPersonalnummer.Name := 'xdsScanPersonalnummer';
    xdsScanPersonalnummer.FieldName := 'Personalnummer';
    xdsScanPersonalnummer.Required := True;
    xdsScanScanDatumZeit.SetParentComponent(xdsScan);
    xdsScanScanDatumZeit.Name := 'xdsScanScanDatumZeit';
    xdsScanScanDatumZeit.FieldName := 'ScanDatumZeit';
    xdsScanScanDatumZeit.DisplayFormat := 'DD.MM.YYYY hh.mm.ss';
    xdsScanDatum.SetParentComponent(xdsScan);
    xdsScanDatum.Name := 'xdsScanDatum';
    xdsScanDatum.FieldName := 'Datum';
    xdsScanDatum.Required := True;
    xdsScanScanArt.SetParentComponent(xdsScan);
    xdsScanScanArt.Name := 'xdsScanScanArt';
    xdsScanScanArt.FieldName := 'ScanArt';
    xdsScanObjektreferenz.SetParentComponent(xdsScan);
    xdsScanObjektreferenz.Name := 'xdsScanObjektreferenz';
    xdsScanObjektreferenz.FieldName := 'Objektreferenz';
    xdsScanObjektreferenz.Size := 13;
    xdsScanScanBegin.SetParentComponent(xdsScan);
    xdsScanScanBegin.Name := 'xdsScanScanBegin';
    xdsScanScanBegin.FieldName := 'ScanBegin';
    xdsScanScanBegin.DisplayFormat := 'hh:mm';
    xdsScanScanEnde.SetParentComponent(xdsScan);
    xdsScanScanEnde.Name := 'xdsScanScanEnde';
    xdsScanScanEnde.FieldName := 'ScanEnde';
    xdsScanScanEnde.DisplayFormat := 'hh:mm';
    xdsScanausgewertet.SetParentComponent(xdsScan);
    xdsScanausgewertet.Name := 'xdsScanausgewertet';
    xdsScanausgewertet.FieldName := 'ausgewertet';
    xdsScanAMS.SetParentComponent(xdsScan);
    xdsScanAMS.Name := 'xdsScanAMS';
    xdsScanAMS.FieldName := 'AMS';
    xdsScanStundenArt.SetParentComponent(xdsScan);
    xdsScanStundenArt.Name := 'xdsScanStundenArt';
    xdsScanStundenArt.FieldName := 'StundenArt';
    xdsScanStundenArt.Size := 50;
    xdsScanFAID.SetParentComponent(xdsScan);
    xdsScanFAID.Name := 'xdsScanFAID';
    xdsScanFAID.FieldName := 'FAID';
    xdsScanFAID.Required := True;
    xdsScanID.SetParentComponent(xdsScan);
    xdsScanID.Name := 'xdsScanID';
    xdsScanID.FieldName := 'ID';
    xdsScanID.Required := True;
    xdsScanID.Size := 38;
    sourceScan.SetParentComponent(Self);
    sourceScan.Name := 'sourceScan';
    sourceScan.DataSet := xdsScan;
    sourceScan.Left := 32;
    sourceScan.Top := 56;
  finally
    lblstatus.AfterLoadDFMValues;
    lblPInfo.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    panelfunktionen.AfterLoadDFMValues;
    btnAnmelden.AfterLoadDFMValues;
    btnPauseBeginn.AfterLoadDFMValues;
    btnPauseEnde.AfterLoadDFMValues;
    btnAbmelden.AfterLoadDFMValues;
    btnObjektBeginn.AfterLoadDFMValues;
    btnObjektEnde.AfterLoadDFMValues;
    btnErneutAnmelden.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    TC.AfterLoadDFMValues;
    xdsSingleArbeitszeit.AfterLoadDFMValues;
    xdsSingleArbeitszeitID.AfterLoadDFMValues;
    xdsSingleArbeitszeitPersonalnummer.AfterLoadDFMValues;
    xdsSingleArbeitszeitDatum.AfterLoadDFMValues;
    xdsSingleArbeitszeitEintragsArt.AfterLoadDFMValues;
    sourceSingleArbeitszeit.AfterLoadDFMValues;
    xdsScan.AfterLoadDFMValues;
    xdsScanPersonalnummer.AfterLoadDFMValues;
    xdsScanScanDatumZeit.AfterLoadDFMValues;
    xdsScanDatum.AfterLoadDFMValues;
    xdsScanScanArt.AfterLoadDFMValues;
    xdsScanObjektreferenz.AfterLoadDFMValues;
    xdsScanScanBegin.AfterLoadDFMValues;
    xdsScanScanEnde.AfterLoadDFMValues;
    xdsScanausgewertet.AfterLoadDFMValues;
    xdsScanAMS.AfterLoadDFMValues;
    xdsScanStundenArt.AfterLoadDFMValues;
    xdsScanFAID.AfterLoadDFMValues;
    xdsScanID.AfterLoadDFMValues;
    sourceScan.AfterLoadDFMValues;
  end;
end;

end.
