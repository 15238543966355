{********************************************************************}
{ written by TMS Software                                            }
{            copyright © 2018                                        }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCSignatureCapture;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  Generics.Collections, UITypes,
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  Contnrs, WEBLib.Controls,
  {$ENDIF}
  {$IFDEF LCLLIB}
  fgl, Controls,
  {$ENDIF}
  Classes, Types, WEBLib.TMSFNCCustomControl, WEBLib.Graphics,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes
  ,TypInfo;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release

type
  TTMSFNCSignatureCaptureStoredPoint = class(TObject)
  public
    X, Y, PenWidth: Single;
    DrawLine: Boolean;
    PenColor: TTMSFNCGraphicsColor;
  end;

  TTMSFNCSignatureCaptureTextHintPosition = (tpBottomCenter, tpBottomLeft, tpBottomRight, tpTopCenter, tpTopLeft, tpTopRight, tpXY, tpNone);
  TTMSFNCSignatureCaptureClearSignPosition = (cpBottomCenter, cpBottomLeft, cpBottomRight, cpTopCenter, cpTopLeft, cpTopRight, cpXY, cpNone);

  TTMSFNCSignatureCaptureClearPosition = class(TPersistent)
  private
    FImage: TTMSFNCBitmap;
    FX: Single;
    FY: Single;
    FPosition: TTMSFNCSignatureCaptureClearSignPosition;
    FOnChange: TNotifyEvent;
    procedure SetImage(const Value: TTMSFNCBitmap);
    procedure SetPosition(const Value: TTMSFNCSignatureCaptureClearSignPosition);
    procedure SetX(const Value: Single);
    procedure SetY(const Value: Single);
    function IsXStored: Boolean;
    function IsYStored: Boolean;
  protected
    procedure ImageChanged(Sender: TObject);
    procedure Changed; virtual;
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property X: Single read FX write SetX stored IsXStored nodefault;
    property Y: Single read FY write SetY stored IsYStored nodefault;
    property Position: TTMSFNCSignatureCaptureClearSignPosition read FPosition write SetPosition default cpBottomLeft;
    property Image: TTMSFNCBitmap read FImage write SetImage;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCSignatureCaptureTextPosition = class(TPersistent)
  private
    FX: Single;
    FY: Single;
    FOnChange: TNotifyEvent;
    FLine: TTMSFNCGraphicsStroke;
    FPosition: TTMSFNCSignatureCaptureTextHintPosition;
    procedure SetX(const Value: Single);
    procedure SetY(const Value: Single);
    procedure SetPosition(const Value: TTMSFNCSignatureCaptureTextHintPosition);
    procedure SetLine(const Value: TTMSFNCGraphicsStroke);
    function IsXStored: Boolean;
    function IsYStored: Boolean;
  protected
    procedure Changed; virtual;
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property X: Single read FX write SetX stored IsXStored nodefault;
    property Y: Single read FY write SetY stored IsYStored nodefault;
    property Position: TTMSFNCSignatureCaptureTextHintPosition read FPosition write SetPosition default tpBottomCenter;
    property Line: TTMSFNCGraphicsStroke read FLine write SetLine;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  {$IFNDEF WEBLIB}
  TTMSFNCSignatureCapturePoints = class(TObjectList<TTMSFNCSignatureCaptureStoredPoint>);
  {$ENDIF}
  {$IFDEF WEBLIB}
  TTMSFNCSignatureCapturePoints = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCSignatureCaptureStoredPoint;
    procedure SetItem(Index: Integer; const Value: TTMSFNCSignatureCaptureStoredPoint);
  public
    property Items[Index: Integer]: TTMSFNCSignatureCaptureStoredPoint read GetItem write SetItem; default;
  end;
  {$ENDIF}

  TTMSFNCCustomSignatureCapture = class(TTMSFNCCustomControl)
  private
    FBmpStream: TBitmap;
    FDrawing: Boolean;
    FSignaturePoints: TTMSFNCSignatureCapturePoints;
    FPen: TTMSFNCGraphicsStroke;
    FTextPosition: TTMSFNCSignatureCaptureTextPosition;
    FText: string;
    FClearSig: TTMSFNCSignatureCaptureClearPosition;
    FClearRect: TRectF;
    FFont: TTMSFNCGraphicsFont;
    procedure SetPen(const Value: TTMSFNCGraphicsStroke);
    procedure SetTextPosition(const Value: TTMSFNCSignatureCaptureTextPosition);
    procedure SetText(const Value: string);
    procedure SetClearSig(const Value: TTMSFNCSignatureCaptureClearPosition);
    procedure SetEmpty(const Value: Boolean);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    {$IFNDEF WEBLIB}
    procedure WriteStringToStream(const Stream: TStream; const S: string);
    function ReadStringFromStream(const Stream: TStream): string;
    {$ENDIF}
    function IsTextStored: Boolean;
    function GetEmpty: Boolean;
  protected
    procedure ClearSignChanged(Sender: TObject);
    procedure TextPositionChanged(Sender: TObject);
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure Draw({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure DrawSignText(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ACRect: TRectF);
    procedure DrawClearIcon(AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawSignature(AGraphics: TTMSFNCGraphics);
    function GetVersion: string; override;
    function GetClearRect(ARect: TRectF): TRectF;
    function IsAppearanceProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; override;
    property Pen: TTMSFNCGraphicsStroke read FPen write SetPen;
    property Text: string read FText write SetText stored IsTextStored nodefault;
    property TextPosition: TTMSFNCSignatureCaptureTextPosition read FTextPosition write SetTextPosition;
    property ClearSig: TTMSFNCSignatureCaptureClearPosition read FClearSig write SetClearSig;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    {$IFNDEF WEBLIB}
    procedure LoadFromStream(AStream: TStream);
    procedure LoadFromFile(FileName: string);
    procedure SaveToImageFile(FileName: string);
    procedure SaveToImageStream(AStream: TStream);
    procedure SaveToStream(AStream: TStream);
    procedure SaveToFile(FileName: string);
    function GetImageFromStream(AStream: TStream): TBitmap;
    {$ENDIF}
    {$IFDEF WEBLIB}
    function GetBase64Img: string;
    {$ENDIF}
    property Empty: Boolean read GetEmpty write SetEmpty;
    property SignaturePoints: TTMSFNCSignatureCapturePoints read FSignaturePoints;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCSignatureCapture = class(TTMSFNCCustomSignatureCapture)
  published
    property Fill;
    property Stroke;
    property Font;
    property Pen;
    property Text;
    property TextPosition;
    property ClearSig;
  end;

{$IFDEF WEBLIB}
const
  TTMSFNCSIGNATURECAPTURECLEAR = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABl0lEQVRIidWVsVnDMBCFf0JD6RFcUj' +
                                 'obKBvABCQTgCfgMUGygd1RBiZAG2A6OsIEZAQKnb8oQnYCVHmVrLt77+4kneHUcdYvJJXAEiiAZ6CVtP2vwCRaL4ErwNn6Q5L+KxBXsDaBFB0wO7YaSUXPI6mNK6iBHE' +
                                 'lFqKY6QOwkNcAXcAv4vQrMqQTWRprDQlKbxMyBG0JrAVaS6t6+JxAFCbgfE5HkgAYobX9jNh87ZwVMpDKCXDVdsr8CHnLnNCgQCYnhajZkso4xGTIkArMBsx8jBzg/Qu' +
                                 'AKeAQuMubKOffpve+G4kcrkLQk3KrCtrbAU+LWjF3hrICkQtIrcBdtbwkP7hpYJCFre2CHBSybD/ZvSU/emU+biJSEG/cDe2dg5C/sWvKDvIf3vnPOlVEil865N+/9e+' +
                                 'yXTtPXhBzgWlLa9zipBpjb5waYxu8hblGTIa/HyE1gAbT2WZK8mTNzqiz7GK0FH4Wkkmnf0r6CdEz/itxQE0YIhP8JsUDLblSv/kCO9X1mIs6G4eFZ9AehgnAOpb2ZE8' +
                                 'c3BUakH/cxNusAAAAASUVORK5CYII=';
{$ENDIF}

implementation

{$R TMSFNCSignatureCapture.res}

uses
  WEBLib.TMSFNCUtils;

const
  TEXTMARGIN = 3;
  CLEARMARGIN = 4;

{ TTMSFNCCustomSignatureCapture }

procedure TTMSFNCCustomSignatureCapture.ClearSignChanged(Sender: TObject);
begin
  Invalidate;
end;

constructor TTMSFNCCustomSignatureCapture.Create(AOwner: TComponent);
begin
  inherited;
  Width := 400;
  Height := 300;
  FDrawing := False;
  FText := 'Sign here.';

  FBmpStream := TBitmap.Create;
  FSignaturePoints := TTMSFNCSignatureCapturePoints.Create;
  FPen := TTMSFNCGraphicsStroke.Create;
  FPen.Color := gcBlack;
  FTextPosition := TTMSFNCSignatureCaptureTextPosition.Create;
  FClearSig := TTMSFNCSignatureCaptureClearPosition.Create;
  FClearRect := RectF(0, 0, 0, 0);
  FFont := TTMSFNCGraphicsFont.Create;
  FClearSig.OnChange := @ClearSignChanged;
  FTextPosition.OnChange := @TextPositionChanged;

  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  {$ENDIF}
  {$ENDIF}
end;

destructor TTMSFNCCustomSignatureCapture.Destroy;
begin
  FBmpStream.Free;
  FSignaturePoints.Free;
  FPen.Free;
  FTextPosition.Free;
  FClearSig.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCCustomSignatureCapture.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  cr: TRectF;
begin
  inherited;
  cr := ARect;
  DrawSignText(AGraphics, ARect, cr);
  FClearRect := GetClearRect(cr);
  DrawClearIcon(AGraphics, FClearRect);
  DrawSignature(AGraphics);
end;

procedure TTMSFNCCustomSignatureCapture.DrawClearIcon(
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(FClearSig.Image) and not IsBitmapEmpty(FClearSig.Image) and (FClearSig.Position <> cpNone) then
  begin
    AGraphics.DrawBitmap(ARect.Left, ARect.Top, FClearSig.Image);
  end;
end;

procedure TTMSFNCCustomSignatureCapture.DrawSignature(
  AGraphics: TTMSFNCGraphics);
var
  I: Integer;
  sp: TTMSFNCSignatureCaptureStoredPoint;
begin
  if FSignaturePoints.Count >= 2 then
  begin
    for I := 0 to FSignaturePoints.Count - 2 do
    begin
      if FSignaturePoints.Items[I+1].DrawLine then
      begin
        sp := FSignaturePoints.Items[I];
        AGraphics.Stroke.Color := sp.PenColor;
        AGraphics.Stroke.Width := sp.PenWidth;
        AGraphics.DrawLine(PointF(FSignaturePoints.Items[I].X, FSignaturePoints.Items[I].Y), PointF(FSignaturePoints.Items[I+1].X, FSignaturePoints.Items[I+1].Y));
      end;
    end;
  end;
end;

procedure TTMSFNCCustomSignatureCapture.DrawSignText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var ACRect: TRectF);
var
  th, tw: Single;
  tp: TPointF;
begin
  th := AGraphics.CalculateTextHeight(Text) + 2 * TEXTMARGIN;
  tw := AGraphics.CalculateTextWidth(Text);

  AGraphics.Font.AssignSource(Font);

  case FTextPosition.Position of
    tpBottomCenter, tpBottomLeft, tpBottomRight:
    begin
      AGraphics.Stroke.Assign(FTextPosition.Line);
      AGraphics.DrawLine(PointF(0, ARect.Bottom - th), PointF(ARect.Right, ARect.Bottom - th));
      ACRect.Bottom := ACRect.Bottom - th;
    end;
    tpTopCenter, tpTopLeft, tpTopRight:
    begin
      AGraphics.Stroke.Assign(FTextPosition.Line);
      AGraphics.DrawLine(PointF(0, ARect.Top + th), PointF(ARect.Right, ARect.Top + th));
      ACRect.Top := ACRect.Top + th;
    end;
  end;

  if FTextPosition.Position <> tpNone then
  begin
    case FTextPosition.Position of
      tpBottomCenter: tp := PointF((ARect.Right - ARect.Left) / 2 - tw / 2, ARect.Bottom - th + TEXTMARGIN);
      tpBottomLeft: tp := PointF(TEXTMARGIN, ARect.Bottom - th + TEXTMARGIN);
      tpBottomRight: tp := PointF(ARect.Right - tw -  TEXTMARGIN, ARect.Bottom - th + TEXTMARGIN);
      tpTopCenter: tp := PointF((ARect.Right - ARect.Left) / 2 - tw / 2, ARect.Top + TEXTMARGIN);
      tpTopLeft: tp := PointF(TEXTMARGIN, ARect.Top + TEXTMARGIN);
      tpTopRight: tp := PointF(ARect.Right - tw - TEXTMARGIN, ARect.Top + TEXTMARGIN);
      tpXY: tp := PointF(FTextPosition.X, FTextPosition.Y);
    end;

    AGraphics.DrawText(tp, Text);
  end;
end;

function TTMSFNCCustomSignatureCapture.GetClearRect(ARect: TRectF): TRectF;
var
  w, h: Single;
begin
  w := FClearSig.Image.Width;
  h := FClearSig.Image.Height;

  case FClearSig.Position of
    cpBottomCenter:
    begin
      Result.Top := ARect.Bottom - h - CLEARMARGIN;
      Result.Left := (ARect.Right - ARect.Left - w) / 2;
    end;
    cpBottomLeft:
    begin
      Result.Top := ARect.Bottom - h - CLEARMARGIN;
      Result.Left := ARect.Left + CLEARMARGIN;
    end;
    cpBottomRight:
    begin
      Result.Top := ARect.Bottom - h - CLEARMARGIN;
      Result.Left := ARect.Right - CLEARMARGIN - w;
    end;
    cpTopCenter:
    begin
      Result.Top := ARect.Top + CLEARMARGIN;
      Result.Left := (ARect.Right - ARect.Left - w) / 2;
    end;
    cpTopLeft:
    begin
      Result.Top := ARect.Top + CLEARMARGIN;
      Result.Left := ARect.Left + CLEARMARGIN;
    end;
    cpTopRight:
    begin
      Result.Top := ARect.Top + CLEARMARGIN;
      Result.Left := ARect.Right - CLEARMARGIN - w;
    end;
    cpXY:
    begin
      Result.Top := FClearSig.Y;
      Result.Left := FClearSig.X;
    end;
  end;

  Result.Right := Result.Left + w;
  Result.Bottom := Result.Top + h;

  FClearRect := Result;
end;

{$IFDEF WEBLIB}
function TTMSFNCCustomSignatureCapture.GetBase64Img: string;
var
  bmp: TBitmap;
  g: TTMSFNCGraphics;
begin
  bmp := TBitmap.Create;
  bmp.Width := Width;
  bmp.Height := Height;
  g := TTMSFNCGraphics.Create(bmp.Canvas);

  DrawSignature(g);
  Result := bmp.GetBase64Image;
end;
{$ENDIF}

function TTMSFNCCustomSignatureCapture.GetEmpty: Boolean;
begin
  Result := FSignaturePoints.Count = 0;
end;

function TTMSFNCCustomSignatureCapture.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomSignatureCapture.HandleMouseDown(
  Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  np: TTMSFNCSignatureCaptureStoredPoint;
begin
  inherited;
  if PtInRectEx(FClearRect, PointF(X, Y)) then
  begin
    FSignaturePoints.Clear;
  end
  else
  begin
    FDrawing := True;
    CaptureEx;
    np := TTMSFNCSignatureCaptureStoredPoint.Create;
    np.X := X;
    np.Y := Y;
    np.PenWidth := Pen.Width;
    np.PenColor := Pen.Color;
    np.DrawLine := False;
    FSignaturePoints.Add(np);
  end;
end;

procedure TTMSFNCCustomSignatureCapture.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
var
  np: TTMSFNCSignatureCaptureStoredPoint;
begin
  inherited;
  if FDrawing then
  begin
    np := TTMSFNCSignatureCaptureStoredPoint.Create;
    np.X := X;
    np.Y := Y;
    np.PenWidth := Pen.Width;
    np.PenColor := Pen.Color;
    np.DrawLine := True;
    FSignaturePoints.Add(np);
    Invalidate;
  end
  else if PtInRectEx(FClearRect, PointF(X, Y)) then
  begin
    Cursor := crHandPoint;
  end
  else
    Cursor := crDefault;
end;

procedure TTMSFNCCustomSignatureCapture.HandleMouseUp(
  Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  np: TTMSFNCSignatureCaptureStoredPoint;
begin
  inherited;
  np := TTMSFNCSignatureCaptureStoredPoint.Create;
  np.X := X;
  np.Y := Y;
  np.PenWidth := Pen.Width;
  np.PenColor := Pen.Color;
  np.DrawLine := True;
  FSignaturePoints.Add(np);
  FDrawing := False;
  ReleaseCaptureEx;
  Invalidate;
end;

function TTMSFNCCustomSignatureCapture.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Pen') or (APropertyName = 'TextPosition');
end;

function TTMSFNCCustomSignatureCapture.IsTextStored: Boolean;
begin
  Result := FText <> 'Sign here.';
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomSignatureCapture.LoadFromFile(FileName: string);
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    ms.LoadFromFile(FileName);
    ms.Position := 0;
    LoadFromStream(ms);
  finally
    ms.Free;
  end;
end;

procedure TTMSFNCCustomSignatureCapture.LoadFromStream(AStream: TStream);
var
  I, sizeTest: Integer;
  point: TTMSFNCSignatureCaptureStoredPoint;
  {$IFDEF CMNLIB}
  x, y, w, f: Integer;
  {$ENDIF}
  {$IFDEF FMXLIB}
  x, y, w, f: Single;
  {$ENDIF}
  c: TTMSFNCGraphicsColor;
  d: boolean;
  sTest: string;
begin
  sizeTest := Random(50);
  AStream.Position := 0;
  AStream.Read(c, SizeOf(c));
  Fill.Color := c;

  AStream.Read(sizeTest, SizeOf(sizeTest));
  FSignaturePoints.Clear;
  for I := 0 to sizeTest - 1 do
  begin
    point := TTMSFNCSignatureCaptureStoredPoint.Create();

    AStream.Read(x, SizeOf(x));
    AStream.Read(y, SizeOf(y));
    AStream.Read(w, SizeOf(w));
    AStream.Read(c, SizeOf(c));
    AStream.Read(d, SizeOf(d));

    point.X := x;
    point.Y := y;
    point.PenWidth := w;
    point.PenColor := c;
    point.DrawLine := d;

    FSignaturePoints.Add(point);
  end;

  FText := ReadStringFromStream(AStream);
  AStream.Read(f, SizeOf(f));
  Font.Size := f;
  AStream.Read(c, SizeOf(TTMSFNCGraphicsColor));
  Font.Color := c;
  AStream.Read(i, SizeOf(Integer));
  {$IFNDEF LCLLIB}
  Font.Style := TFontStyles(Byte(I));
  {$ENDIF}
  {$IFDEF LCLLIB}
  Font.Style := TFontStyles(Integer(I));
  {$ENDIF}
  sTest := ReadStringFromStream(AStream);
  Font.Name := TFontName(sTest);
  Invalidate;
end;

function TTMSFNCCustomSignatureCapture.ReadStringFromStream(
  const Stream: TStream): string;
var
  n: LongInt;
  size: Integer;
begin
  Stream.Read(n, SizeOf(n));
  SetLength(Result, n);
  {$IFDEF ZEROSTRINGINDEX}
  size := 0;
  {$ELSE}
  size := 1;
  {$ENDIF}
  if n > 0 then Stream.Read(Result[size], n * SizeOf(Char));
end;

procedure TTMSFNCCustomSignatureCapture.SaveToFile(FileName: string);
var
  ms: TMemoryStream;
begin
  ms := TMemoryStream.Create;
  try
    SaveToStream(ms);
    ms.SaveToFile(FileName);
  finally
    ms.Free;
  end;
end;

procedure TTMSFNCCustomSignatureCapture.SaveToImageFile(FileName: string);
var
  bmp: TBitmap;
  g: TTMSFNCGraphics;
begin
  bmp := TBitmap.Create;
  bmp.Width := Round(Width);
  bmp.Height := Round(Height);
  g := TTMSFNCGraphics.Create(bmp.Canvas);
  try
    g.BeginScene;
    {$IFDEF LCLLIB}
    g.Fill.Color := Color;
    g.DrawRectangle(RectF(0, 0, Width, Height));
    {$ENDIF}
    DrawSignature(g);
    g.EndScene;
    bmp.SaveToFile(FileName);
  finally
    bmp.Free;
    g.Free;
  end;
end;

procedure TTMSFNCCustomSignatureCapture.SaveToImageStream(AStream: TStream);
var
  bmp: TBitmap;
  g: TTMSFNCGraphics;
begin
  AStream.Position := 0;
  bmp := TBitmap.Create;
  bmp.Width := Round(Width);
  bmp.Height := Round(Height);
  g := TTMSFNCGraphics.Create(bmp.Canvas);
  try
    g.BeginScene;
    DrawSignature(g);
    g.EndScene;
    bmp.SaveToStream(AStream);
  finally
    bmp.Free;
    g.Free;
  end;
end;

procedure TTMSFNCCustomSignatureCapture.SaveToStream(AStream: TStream);
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
  {$IFDEF FMXLIB}
  f: Single;
  {$ENDIF}
  {$IFDEF CMNLIB}
  xi, yi, wi, f: Integer;
  {$ENDIF}
  point: TTMSFNCSignatureCaptureStoredPoint;
begin
  AStream.Position := 0;
  c := Fill.Color;
  AStream.Write(c, SizeOf(c));
  AStream.Write(FSignaturePoints.Count, SizeOf(FSignaturePoints.Count));

  for I := 0 to FSignaturePoints.Count - 1 do
  begin
    point := FSignaturePoints.Items[I];
    {$IFDEF CMNLIB}
    xi := Round(point.X);
    AStream.Write(xi, SizeOf(xi));
    yi := Round(point.Y);
    AStream.Write(yi, SizeOf(yi));
    wi := Round(point.PenWidth);
    AStream.Write(wi, SizeOf(wi));
    {$ENDIF}
    {$IFDEF FMXLIB}
    AStream.Write(point.X, SizeOf(point.X));
    AStream.Write(point.Y, SizeOf(point.Y));
    AStream.Write(point.PenWidth, SizeOf(point.PenWidth));
    {$ENDIF}
    AStream.Write(point.PenColor, SizeOf(point.PenColor));
    AStream.Write(point.DrawLine, SizeOf(point.DrawLine));
  end;
  WriteStringToStream(AStream, FText);
  {$IFDEF CMNLIB}
  f := Round(Font.Size);
  {$ENDIF}
  {$IFDEF FMXLIB}
  f := Font.Size;
  {$ENDIF}
  AStream.Write(f, SizeOf(f));
  AStream.Write(Font.Color, SizeOf(Font.Color));
  {$IFNDEF LCLLIB}
  i := Byte(Font.Style);
  {$ENDIF}
  {$IFDEF LCLLIB}
  i := Integer(Font.Style);
  {$ENDIF}
  AStream.Write(I, SizeOf(I));
  WriteStringToStream(AStream, Font.Name);
end;

function TTMSFNCCustomSignatureCapture.GetImageFromStream(
  AStream: TStream): TBitmap;
begin
  AStream.Position := 0;
  FBmpStream.Width := Round(Width);
  FBmpStream.Height := Round(Height);
  FBmpStream.LoadFromStream(AStream);
  Result := FBmpStream;
end;

{$ENDIF}

procedure TTMSFNCCustomSignatureCapture.SetClearSig(
  const Value: TTMSFNCSignatureCaptureClearPosition);
begin
  if FClearSig <> Value then
    FClearSig.Assign(Value);
end;

procedure TTMSFNCCustomSignatureCapture.SetEmpty(const Value: Boolean);
begin
  if Value then
    FSignaturePoints.Clear;
end;

procedure TTMSFNCCustomSignatureCapture.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomSignatureCapture.SetPen(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FPen <> Value then
    FPen.Assign(Value);
end;

procedure TTMSFNCCustomSignatureCapture.SetText(const Value: string);
begin
  if FText <> Value then
    FText := Value;
end;

procedure TTMSFNCCustomSignatureCapture.SetTextPosition(
  const Value: TTMSFNCSignatureCaptureTextPosition);
begin
  if FTextPosition <> Value then
    FTextPosition.Assign(Value);
end;

procedure TTMSFNCCustomSignatureCapture.TextPositionChanged(Sender: TObject);
begin
  Invalidate;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomSignatureCapture.WriteStringToStream(
  const Stream: TStream; const S: string);
var
  n: LongInt;
  size: Integer;
begin
  n := Length(S);
  Stream.Write(n, SizeOf(n));
  {$IFDEF ZEROSTRINGINDEX}
  size := 0;
  {$ELSE}
  size := 1;
  {$ENDIF}
  if n > 0 then Stream.Write(S[size], n * SizeOf(Char));
end;
{$ENDIF}

{ TTMSFNCSignatureCaptureTextPosition }

procedure TTMSFNCSignatureCaptureTextPosition.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCSignatureCaptureTextPosition then
  begin
    FX := (Source as TTMSFNCSignatureCaptureTextPosition).X;
    FY := (Source as TTMSFNCSignatureCaptureTextPosition).Y;
    FPosition := (Source as TTMSFNCSignatureCaptureTextPosition).Position;
    FLine.Assign((Source as TTMSFNCSignatureCaptureTextPosition).Line);
  end;
end;

procedure TTMSFNCSignatureCaptureTextPosition.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCSignatureCaptureTextPosition.Create;
begin
  FX := 25;
  FY := 25;
  FPosition := tpBottomCenter;
  FLine := TTMSFNCGraphicsStroke.Create;
  FLine.Color := gcGray;
  FLine.Width := 1;
end;

destructor TTMSFNCSignatureCaptureTextPosition.Destroy;
begin
  FLine.Free;
  inherited;
end;

function TTMSFNCSignatureCaptureTextPosition.IsXStored: Boolean;
begin
  Result := FX <> 25.0;
end;

function TTMSFNCSignatureCaptureTextPosition.IsYStored: Boolean;
begin
  Result := FY <> 25.0;
end;

procedure TTMSFNCSignatureCaptureTextPosition.SetLine(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FLine <> Value then
    FLine.Assign(Value);
end;

procedure TTMSFNCSignatureCaptureTextPosition.SetPosition(
  const Value: TTMSFNCSignatureCaptureTextHintPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    Changed;
  end;
end;

procedure TTMSFNCSignatureCaptureTextPosition.SetX(const Value: Single);
begin
  if FX <> Value then
  begin
    FX := Value;
    Changed;
  end;
end;

procedure TTMSFNCSignatureCaptureTextPosition.SetY(const Value: Single);
begin
  if FY <> Value then
  begin
    FY := Value;
    Changed;
  end;
end;

{ TTMSFNCSignatureCapturePoints }

{$IFDEF WEBLIB}
function TTMSFNCSignatureCapturePoints.GetItem(Index: Integer): TTMSFNCSignatureCaptureStoredPoint;
begin
  Result := TTMSFNCSignatureCaptureStoredPoint(inherited Items[Index]);
end;

procedure TTMSFNCSignatureCapturePoints.SetItem(Index: Integer; const Value: TTMSFNCSignatureCaptureStoredPoint);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{ TTMSFNCSignatureCaptureClearPosition }

procedure TTMSFNCSignatureCaptureClearPosition.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCSignatureCaptureClearPosition) then
  begin
    FX := (Source as TTMSFNCSignatureCaptureClearPosition).X;
    FY := (Source as TTMSFNCSignatureCaptureClearPosition).Y;
    FPosition := (Source as TTMSFNCSignatureCaptureClearPosition).Position;
    FImage.Assign((Source as TTMSFNCSignatureCaptureClearPosition).Image);
  end;
end;

procedure TTMSFNCSignatureCaptureClearPosition.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCSignatureCaptureClearPosition.Create;
begin
  FPosition := cpBottomLeft;
  FImage := TTMSFNCBitmap.Create;
  {$IFNDEF WEBLIB}
  FImage.LoadFromResource('TMSFNCSIGNATURECAPTURECLEAR', HInstance);
  {$ENDIF}
  {$IFDEF WEBLIB}
  FImage.LoadFromResource(TTMSFNCSIGNATURECAPTURECLEAR, HInstance);
  {$ENDIF}
  FImage.OnChange := @ImageChanged;
end;

destructor TTMSFNCSignatureCaptureClearPosition.Destroy;
begin
  FImage.Free;
  inherited;
end;

procedure TTMSFNCSignatureCaptureClearPosition.ImageChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCSignatureCaptureClearPosition.IsXStored: Boolean;
begin
  Result := FX <> 0.0;
end;

function TTMSFNCSignatureCaptureClearPosition.IsYStored: Boolean;
begin
  Result := FY <> 0.0;
end;

procedure TTMSFNCSignatureCaptureClearPosition.SetImage(
  const Value: TTMSFNCBitmap);
begin
  FImage.Assign(Value);
end;

procedure TTMSFNCSignatureCaptureClearPosition.SetPosition(
  const Value: TTMSFNCSignatureCaptureClearSignPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    Changed;
  end;
end;

procedure TTMSFNCSignatureCaptureClearPosition.SetX(const Value: Single);
begin
  if FX <> Value then
  begin
    FX := Value;
    Changed;
  end;
end;

procedure TTMSFNCSignatureCaptureClearPosition.SetY(const Value: Single);
begin
  if FY <> Value then
  begin
    FY := Value;
    Changed;
  end;
end;

{$IFDEF WEBLIB}
initialization
begin
  TTMSFNCBitmap.CreateFromResource(TTMSFNCSIGNATURECAPTURECLEAR);
end;
{$ENDIF}

end.
