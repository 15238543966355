unit unit_EinzelAuftrag;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, strutils,WEBLib.json,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTableView, WEBLib.Buttons, WEBLib.TMSFNCSignatureCapture, WEBLib.Mask, dateutils,
  WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCDatePicker, XData.Web.Client, WEBLib.REST;

type
  TEinzelAuftrag = class(TForm)
    dsBeleg: TXDataWebDataSet;
    SourceBeleg: TDataSource;
    dsBelegKundennummer: TXDataWebEntityField;
    dsBelegNummerkomplett: TStringField;
    dsBelegReferenzAngebot: TStringField;
    dsBelegReferenzLieferschein: TStringField;
    dsBelegReferenzRechnung: TStringField;
    dsBelegDatum: TDateTimeField;
    dsBelegJahr: TFloatField;
    dsBelegKalenderwoche: TIntegerField;
    dsBelegNachname: TStringField;
    dsBelegVorname: TStringField;
    dsBelegPostleitzahl: TStringField;
    dsBelegOrt: TStringField;
    dsBelegLaenderkuerzel: TStringField;
    dsBelegLand: TStringField;
    dsBelegBriefanrede1: TStringField;
    dsBelegBriefanrede2: TStringField;
    dsBelegEMail: TStringField;
    dsBelegAdresseBild: TStringField;
    dsBelegLieferanschriftBild: TStringField;
    dsBelegZahlungsbedingung: TIntegerField;
    dsBelegZahlungsbedingungText: TStringField;
    dsBelegAuftragsbezeichnung: TStringField;
    dsBelegAuftragnummerKunde: TStringField;
    dsBelegBestelldatumKunde: TDateTimeField;
    dsBelegILN: TStringField;
    dsBelegKopftext: TStringField;
    dsBelegSchlussbemerkung: TStringField;
    dsBelegGruss: TStringField;
    dsBelegMehrwertsteuersatz1: TFloatField;
    dsBelegMehrwertsteuersatz2: TFloatField;
    dsBelegNettosumme01: TFloatField;
    dsBelegNettosumme02: TFloatField;
    dsBelegNettosumme: TFloatField;
    dsBelegMehrwertsteuer01: TFloatField;
    dsBelegMehrwertsteuer02: TFloatField;
    dsBelegMehrwertsteuer: TFloatField;
    dsBelegBruttosumme: TFloatField;
    dsBelegVersanddatum: TDateTimeField;
    dsBelegSkontosatz: TFloatField;
    dsBelegNettosummeEK: TFloatField;
    dsBelegNettoMarge: TFloatField;
    dsBelegSchreibzeichen: TStringField;
    dsBelegUnterzeichner: TStringField;
    dsBelegTelefonMA: TStringField;
    dsBelegEmailMA: TStringField;
    dsBelegUSTID: TStringField;
    dsBelegUmsatzsteuerausweis: TBooleanField;
    dsBeleggebucht: TBooleanField;
    dsBelegLieferdatum: TDateTimeField;
    dsBelegTransportbedingung: TStringField;
    dsBelegMehrwertsteuersatz3: TFloatField;
    dsBelegMehrwertsteuer03: TFloatField;
    dsBelegNettosumme03: TFloatField;
    dsBelegRundungsDifferenz: TFloatField;
    dsBelegEmailText: TStringField;
    dsBelegAuftragerledigt: TBooleanField;
    dsBelegRabattstufe: TStringField;
    dsBelegKFZKennzeichen: TStringField;
    dsBelegFahrgestellnummer: TStringField;
    dsBelegKFZHersteller: TStringField;
    dsBelegErstzulassungsdatum: TDateTimeField;
    dsBelegKMStand: TStringField;
    dsBelegKFZType: TStringField;
    dsBelegInspektionsdatum: TDateTimeField;
    dsBelegDebitorennummer: TStringField;
    dsBelegKostentraeger: TStringField;
    dsBelegRabattsatz: TFloatField;
    dsBelegObjektReferenz: TStringField;
    dsBelegBank: TStringField;
    dsBelegBankleitzahl: TFloatField;
    dsBelegKontonummer: TStringField;
    dsBelegKontoinhaber: TStringField;
    dsBelegTelefaxnummer: TStringField;
    dsBelegDatumNeu: TDateTimeField;
    dsBelegDatumgeaendert: TDateTimeField;
    dsBelegObjektnummer: TStringField;
    dsBelegLiefertermin: TStringField;
    dsBelegEmailversand: TBooleanField;
    dsBelegTelefonnummer: TStringField;
    dsBelegMobilTelefon: TStringField;
    dsBelegAuftragFax: TBooleanField;
    dsBelegREDatum: TDateTimeField;
    dsBelegBenutzerNeu: TStringField;
    dsBelegBenutzerGeaendert: TStringField;
    dsBelegBerechnungBrutto: TBooleanField;
    dsBelegBruttosumme01: TFloatField;
    dsBelegBruttosumme02: TFloatField;
    dsBelegBruttosumme03: TFloatField;
    dsBelegNotiz: TStringField;
    dsBelegMargeProzent: TFloatField;
    dsBelegWarenkreditgesperrt: TBooleanField;
    dsBelegBelegsprache: TStringField;
    dsBelegDuplicate: TStringField;
    dsBelegperEmail: TBooleanField;
    dsBelegAnsprechpartner: TStringField;
    dsBelegKundennummerFremd: TStringField;
    dsBelegProtokoll: TStringField;
    dsBelegVerleihauftrag: TBooleanField;
    dsBelegVerleihVon: TDateTimeField;
    dsBelegVerleihBis: TDateTimeField;
    dsBelegAuftragGenehmigt: TBooleanField;
    dsBeleggesperrt: TBooleanField;
    dsBelegVerleihvonText: TStringField;
    dsBelegVerleihbisText: TStringField;
    dsBelegDruckWHG: TStringField;
    dsBelegDruckWHGKurs: TFloatField;
    dsBelegProzentsatzZusatzposition: TFloatField;
    dsBelegTextZusatzposition: TStringField;
    dsBelegTextzusatzBeschreibung: TStringField;
    dsBelegLayoutname: TStringField;
    dsBelegGesamtgewicht: TFloatField;
    dsBelegBetragZusatzposition: TFloatField;
    dsBelegReferenzBestellung: TStringField;
    dsBelegTermin1: TDateTimeField;
    dsBelegTermin2: TDateTimeField;
    dsBelegZielkontaktnummer: TStringField;
    dsBelegZielkontaktname: TStringField;
    dsBelegBilddatei1: TStringField;
    dsBelegBilddatei2: TStringField;
    dsBelegZielkontaktnummer2: TStringField;
    dsBelegZielkontaktname2: TStringField;
    dsBelegbestaetigt: TDateTimeField;
    dsBelegZielkontaktnummer3: TStringField;
    dsBelegZielkontaktname3: TStringField;
    dsBelegTermin3: TDateTimeField;
    dsBelegTermin4: TDateTimeField;
    dsBelegMuster: TBooleanField;
    dsBelegKW: TIntegerField;
    dsBelegDatenlink: TStringField;
    dsBelegMehrwertsteuersatz4: TFloatField;
    dsBelegMehrwertsteuersatz5: TFloatField;
    dsBelegMehrwertsteuersatz6: TFloatField;
    dsBelegBesteuerungKey: TIntegerField;
    dsBelegVertreterMatchcode: TStringField;
    dsBelegVerfasser: TStringField;
    dsBelegLieferadresseID: TIntegerField;
    dsBelegAuftragStatus: TStringField;
    dsBelegMonatJahr: TDateTimeField;
    dsBelegBelegvariante: TStringField;
    dsBelegProduktionsfreigabe: TBooleanField;
    dsBelegReferenzRahmenAuftrag: TStringField;
    dsBelegDatumRahmenAuftrag: TDateTimeField;
    dsBelegFahrzeugID: TIntegerField;
    dsBelegAuslieferungKW: TStringField;
    dsBelegBereitstellungDatum: TDateTimeField;
    dsBelegVTUID: TStringField;
    dsBelegLieferanschriftDaten: TStringField;
    dsBelegGesamtVolumen: TFloatField;
    dsBelegBelegPKey: TIntegerField;
    dsBelegDruckParameter: TStringField;
    dsBelegReferenzService: TStringField;
    dsBelegZusatzDaten: TStringField;
    dsBelegStandPDF: TStringField;
    dsBelegPaketdienst: TStringField;
    dsBelegMonteur: TIntegerField;
    dsPositionen: TXDataWebDataSet;
    sourcePositionen: TDataSource;
    dsPositionenArtikelnummer: TXDataWebEntityField;
    dsPositionenNummerkomplett: TXDataWebEntityField;
    dsPositionenID: TIntegerField;
    dsPositionenPosNr: TFloatField;
    dsPositionenPosUNr: TFloatField;
    dsPositionenSumme: TBooleanField;
    dsPositionenArtikelueberschrift: TStringField;
    dsPositionenArtikelbeschreibung: TStringField;
    dsPositionenMenge: TFloatField;
    dsPositionenArtderMenge: TStringField;
    dsPositionenVerkaufspreis: TFloatField;
    dsPositionenEinkaufspreis: TFloatField;
    dsPositionenSeriennummer: TStringField;
    dsPositionenWarengruppe: TStringField;
    dsPositionenWarenuntergruppe: TStringField;
    dsPositionenArtikelnummerKunde: TStringField;
    dsPositionenLieferant: TStringField;
    dsPositionenGewicht: TFloatField;
    dsPositionenLaenge: TFloatField;
    dsPositionenBreite: TFloatField;
    dsPositionenHoehe: TFloatField;
    dsPositionenMehrwertsteuersatz: TFloatField;
    dsPositionenEndpreis: TFloatField;
    dsPositionenPosMWST: TFloatField;
    dsPositionenBruttoEndpreis: TFloatField;
    dsPositionenEndpreis_EK: TFloatField;
    dsPositionenRabattsatz: TFloatField;
    dsPositionenPreisvorRabatt: TFloatField;
    dsPositionenPKZ: TFloatField;
    dsPositionenTextfrei10: TStringField;
    dsPositionenTextfrei20: TStringField;
    dsPositionenProduktionAbgeschlossen: TDateTimeField;
    dsPositionenEinzelpreisBrutto: TFloatField;
    dsPositionenPosMarge: TFloatField;
    dsPositionenPosMargeProzent: TFloatField;
    dsPositionenBereitsGeliefert: TFloatField;
    dsPositionenausliefern: TFloatField;
    dsPositionenBestellungErledigt: TBooleanField;
    dsPositionenLager: TStringField;
    dsPositionenbestellen: TBooleanField;
    dsPositionenWarenbestand: TBooleanField;
    dsPositionenArtikelbeschreibungRTF: TStringField;
    dsPositionenQuellartikel: TStringField;
    dsPositionenArtikelnummerWB: TStringField;
    dsPositionenPreisanteilNetto: TFloatField;
    dsPositionenSetHPos: TStringField;
    dsPositionenSetUPos: TStringField;
    dsPositionenProvisionssatz: TFloatField;
    dsPositionenProvisionsbetrag: TFloatField;
    dsPositionenArtikelsprache: TStringField;
    dsPositionenEKvom: TDateTimeField;
    dsPositionenMengenberechnungID: TStringField;
    dsPositionenTaraID: TStringField;
    dsPositionenTara: TFloatField;
    dsPositionenBruttoGewicht: TFloatField;
    dsPositionenAussenflaeche: TFloatField;
    dsPositionenLadeort: TStringField;
    dsPositionenSeitenumbruch: TBooleanField;
    dsPositionenPlanerFarbe: TIntegerField;
    dsPositionenMWStKey: TIntegerField;
    dsPositionenZSnachPos: TBooleanField;
    dsPositionenLieferzeit: TStringField;
    dsPositionenPreisVorRabattWHG: TFloatField;
    dsPositionenReferenzKalkulation: TStringField;
    dsPositionenPKey: TIntegerField;
    dsPositionenAuftragsnummer: TStringField;
    dsPositionenlwbID: TStringField;
    dsPositionenBelegdatum: TDateTimeField;
    dsPositionenWarenkontrollnummern: TStringField;
    dsPositionenVolumen: TFloatField;
    dsPositionenPosVolumen: TFloatField;
    dsPositionenArtikelAttribute: TStringField;
    dsPositionenMonteurP: TIntegerField;
    dsPositionenArbeitszeitDatum: TDateTimeField;
    dsPositionenArbeitszeitVON: TDateTimeField;
    dsPositionenArbeitszeitBIS: TDateTimeField;
    TC: TTableControl;
    dsBelegKundennummerKundennummer: TStringField;
    dsUnterschriften: TXDataWebDataSet;
    dsUnterschriftenNummerkomplett: TStringField;
    dsUnterschriftenDatum: TDateTimeField;
    dsUnterschriftenUnterschriftMonteur: TBlobField;
    dsUnterschriftenUnterschriftKunde: TBlobField;
    sourceUnterschriften: TDataSource;
    dsPositionenArtikelnummerArtikelnummer: TStringField;
    dsPositionenArtikelnummerArbeitszeit: TBooleanField;
    dsUnterschriftenUnterschriftMonteurText: TStringField;
    dsUnterschriftenUnterschriftKundeText: TStringField;
    dsSumBeleg: TXDataWebDataSet;
    dsSumBelegPosVolumen: TFloatField;
    dsSumBelegBruttoGewicht: TFloatField;
    dsSumBelegMehrwertsteuersatz: TFloatField;
    dsSumBelegEndpreis: TFloatField;
    dsSumBelegPosMWST: TFloatField;
    dsSumBelegBruttoEndpreis: TFloatField;
    dsSumBelegEndpreis_EK: TFloatField;
    dsSumBelegPKZ: TFloatField;
    dsBelegServiceBemerkung: TStringField;
    WClient: TXDataWebClient;
    WebHttpRequest1: THttpRequest;
    dsObjekt: TXDataWebDataSet;
    dsObjektObjektReferenz: TStringField;
    dsObjektKurzbezeichnung: TStringField;
    dsObjektObjektnummer: TStringField;
    Container1: THTMLContainer;
    btnzurueck: TButton;
    btnUnterschreiben: TButton;
    btnPDF: TButton;
    btnTermine: TButton;
    Container3: THTMLContainer;
    Container2: THTMLContainer;
    PTC: TTableControl;
    btnSpeichern: TButton;
    pickLieferdatum: TDBDateTimePicker;
    edtAuftragsbezeichnung: TDBEdit;
    edtLiefertermin: TDBEdit;
    btnNeuePosition: TButton;
    Container4: THTMLContainer;
    webDBComboAuftragStatus: TDBComboBox;
    belegComboMonteur: TComboBox;
    belegMemoServiceBemerkung: TDBMemo;
    procedure WebFormShow(Sender: TObject);
    procedure dsBelegAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure dsBelegAfterPost(DataSet: TDataSet);
    procedure dsBelegAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure btnNeuePositionClick(Sender: TObject);
    procedure dsUnterschriftenAfterOpen(DataSet: TDataSet);
    procedure btnUnterschreibenClick(Sender: TObject);
    procedure dsUnterschriftenAfterPost(DataSet: TDataSet);
    procedure dsUnterschriftenAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
    procedure dsBelegBeforePost(DataSet: TDataSet);
    procedure dsSumBelegAfterOpen(DataSet: TDataSet);
    procedure btnPDFClick(Sender: TObject);
    procedure btnTermineClick(Sender: TObject);
    procedure dsObjektAfterOpen(DataSet: TDataSet);
    procedure btnzurueckClick(Sender: TObject);
    procedure dsPositionenAfterOpen(DataSet: TDataSet);
    procedure SourceBelegStateChange(Sender: TObject);
    procedure btnSpeichernClick(Sender: TObject);
    procedure belegComboMonteurEnter(Sender: TObject);
  protected
    procedure LoadDFMValues; override;

    public

    imgUnterschriftMonteur:TImageControl;
    imgUnterschriftKunde:TImageControl;
    MerkerAuftrag:string;
    NeuePosition:boolean;
    LetztePosNr:double;
    PositionsID:string;
    Belegart:string;
    Belegvariante:string;
    Belegnummer:string;
    Artikelnummer:string;

    Monteurstringlist:TStringList;
    btnObjektWahl:TButton;
    buttonBearbeiten:TButton;


    procedure btnObjektWahlClick(Sender:TObject);
    procedure buttonBearbeitenclick(Sender:TObject);
  end;

var
  EinzelAuftrag: TEinzelAuftrag;


implementation
  uses unit1, unit2, unit_ArtikelSuche, unit_AuftragUnterschriften, unit_AuftragTermine, unit_Objektsuche, unit_AuftragPosEinzel;



procedure TEinzelAuftrag.buttonBearbeitenclick(sender:TObject);
var
  AuftragPosEinzel:TAuftragPosEinzel;
  MyPositionsID:string;
  myArtikelnummer:string;
begin
  myPositionsID:=inttostr((sender as TButton).tag);
  myArtikelnummer:=(sender as TButton).Hint;
  Container4.HTML.text:='';
  Container3.HTML.Text:='';
  AuftragPosEinzel:=TAuftragPosEinzel.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAuftragPosEinzel).PositionsID:=myPositionsID;
      (AForm as TAuftragPosEinzel).tmpArtikelnummer:=myArtikelnummer;
      (AForm as TAuftragPosEinzel).tmpKundennummer:=dsBelegKundennummerKundennummer.AsString;
      (AForm as TAuftragPosEinzel).tmpMerkerAuftrag:=MerkerAuftrag;
      (AForm as TAuftragPosEinzel).NeuePosition:=False;
    end
    );
      AuftragPosEinzel.ShowModal(procedure(AValue: TModalResult)
    begin
      dsSumBeleg.Close;
      dsSumBeleg.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
      dsSumBeleg.Load;
    end
    );
end;




procedure TEinzelAuftrag.btnObjektWahlClick(Sender:TObject);
var
  LFrm:TfrmObjektsuche;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TfrmObjektsuche;
    begin
      LForm:=(AForm as TfrmObjektsuche);
      lform.Objektreferenz:='';

    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      if lfrm.Objektreferenz<>'' then
      begin
        dsobjekt.Close;
        dsobjekt.QueryString:='$filter=Objektreferenz eq '+QuotedStr(lfrm.Objektreferenz);
        dsobjekt.Load;
      end;


    end;

  begin
  LFrm:=TfrmObjektsuche.CreateNew(@AfterCreate);
 // LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TEinzelAuftrag.dsBelegAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
    dsPositionen.Close;
    dsunterschriften.Close;
    dsBeleg.Close;
    dsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
    dsBeleg.Load;
end;

procedure TEinzelAuftrag.dsBelegAfterOpen(DataSet: TDataSet);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Einzel-Auftrag</h5></nav>';
  //Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  btnspeichern.ParentElement:=myelement;
  btnUnterschreiben.ParentElement:=myelement;
  btnpdf.ParentElement:=myelement;
  btntermine.ParentElement:=myelement;
  myelement:=container3.elementhandle;
  TC.ParentElement:=myelement;
  myelement:=container4.elementhandle;
  PTC.ParentElement:=myelement;



  TC.RowCount:=19;
  TC.Cells[0,0]:='<small><i>Auftragsnummer | Datum';
  TC.Cells[0,1]:='<small><i>Kundennummer';
  TC.Cells[0,2]:='<small><i>Anschrift';
  TC.Cells[0,3]:='<small><i>Auftragsdatum | -Nr.';
  TC.Cells[0,4]:='<small><i>Objektnr.';
  TC.Cells[0,5]:='<small><i>Auftragsbezeichnung';
  TC.Cells[0,6]:='<small><i>Lieferdatum';
  TC.Cells[0,7]:='<small><i>Liefertermin';
  TC.Cells[0,8]:='<small><i>MonteurIn';
  TC.Cells[0,9]:='<small><i>Status';
  TC.Cells[0,10]:='<small><i>Netto';
  TC.Cells[0,11]:='<small><i>Lieferanschrift';
  TC.Cells[0,12]:='<small><i>Versanddatum';
  TC.Cells[0,13]:='<small><i>Rechnung Nr. | -Datum';
  TC.Cells[0,14]:='<small><i>Unterzeichner';
  TC.Cells[0,15]:='<small><i>Bemerkung';
  TC.Cells[0,16]:='<small><i>Unterschrift Monteur';
  TC.Cells[0,17]:='<small><i>Unterschrift Kunde';
  TC.Cells[0,18]:='<small><i>Unterschriften vom';

  myelement:=tc.CellElements[1,5];
  edtAuftragsbezeichnung.ParentElement:=myelement;

  myelement:=tc.CellElements[1,6];
  picklieferdatum.ParentElement:=myelement;

  myelement:=tc.CellElements[1,7];
  edtLiefertermin.ParentElement:=myelement;

  myelement:=tc.CellElements[1,9];

  webDBComboAuftragStatus.ParentElement:=myelement;

  myelement:=tc.CellElements[1,8];
  belegComboMonteur.ParentElement:=myelement;

  myelement:=tc.CellElements[1,15];
  belegMemoServiceBemerkung.ParentElement:=myelement;
  if dsBeleggesperrt.AsBoolean=true then
  begin
    TC.Cells[1,0]:='<h5>'+dsbelegNummerkomplett.asstring +' | '+formatdatetime('DD.MM.YYYY',dsbelegDatum.asdatetime)+' | GESPERRT';
  end
  else
  begin
    TC.Cells[1,0]:='<h5>'+dsbelegNummerkomplett.asstring +' | '+formatdatetime('DD.MM.YYYY',dsbelegDatum.asdatetime)+' | Bearbeitung möglich';
  end;
  TC.Cells[1,1]:='<h6>'+dsBelegKundennummerKundennummer.asstring;
  TC.Cells[1,2]:='<h6>'+ReplaceStr(dsbelegAdresseBild.asstring,#13+#10,'<BR>');
  TC.Cells[1,3]:=formatdatetime('DD.MM.YYYY',dsbelegBestelldatumKunde.Asdatetime)+' | '+dsbelegAuftragnummerKunde.Asstring;
  TC.Cells[1,4]:=dsbelegObjektnummer.Asstring;
  if dsbeleggesperrt.asboolean=false then
  begin
    myelement:=TC.cellelements[1,4];
    btnObjektWahl:=TButton.Create(self);
    btnObjektWahl.ElementClassName:='btn btn-info btn-sm text-left';
    btnObjektWahl.HeightStyle:=ssauto;
    btnObjektWahl.WidthStyle:=ssauto;
    btnObjektWahl.tag:=0;
    btnObjektWahl.Caption:='ändern';
    btnObjektWahl.OnClick:=@btnObjektWahlClick;
    btnObjektWahl.ParentElement:=myelement;
  end;

  belegComboMonteur.ItemIndex:=2;

  if dsBeleggesperrt.AsBoolean=true then TC.Cells[1,9]:=dsbelegauftragstatus.asstring;
  TC.Cells[1,10]:=formatfloat('#,##0.00',dsbelegNettosumme.Asfloat);
  TC.Cells[1,11]:=ReplaceStr(dsBelegLieferanschriftBild.Asstring,#13+#10,'<BR>');
  TC.Cells[1,12]:=formatdatetime('DD.MM.YYYY',dsbelegVersanddatum.Asdatetime);
  TC.Cells[1,13]:=dsbelegreferenzrechnung.AsString+' | '+dsbelegREDatum.AsString;
  TC.Cells[1,14]:=dsbelegUnterzeichner.AsString;
  if dsBelegMonteur.AsString<>'' then
  begin
    dm2.queryMonteur.Locate('Personalnummer',dsBelegMonteur.AsString,[]);
    belegcombomonteur.Text:=dm2.queryMonteurPersonalnummer.asstring+'| '+dm2.queryMonteurNachname.asstring+', '+dm2.queryMonteurVorname.asstring;
  end
  else
  begin
    belegComboMonteur.Text:='';
  end;
  dsUnterschriften.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
  dsUnterschriften.Load;
end;

procedure TEinzelAuftrag.dsBelegAfterPost(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    dsBeleg.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TEinzelAuftrag.dsBelegBeforePost(DataSet: TDataSet);
var
  tempnettosumme:double;
  tempnettosumme01:double;
  tempnettosumme02:double;
  tempnettosumme03:double;
  tempnettosummeEK:double;
begin
  if belegcombomonteur.Text='' then
  begin
    dsBelegMonteur.AsString:='';
  end
  else
  begin
    Monteurstringlist.DelimitedText:=belegComboMonteur.Text;
    dsBelegMonteur.AsString:=Monteurstringlist[0];
  end;

  if dssumBeleg.Active then
  begin
  if dsSumBeleg.RecordCount>0 then
  begin
    tempnettosumme:=0;
    tempnettosumme01:=0;
    tempnettosumme02:=0;
    tempnettosumme03:=0;
    tempnettosummeEK:=0;
    dsSumBeleg.First;

    while not dsSumBeleg.eof do
    begin
      tempnettosumme:=tempnettosumme+dsSumBelegEndpreis.AsFloat;
      if dsSumBelegPKZ.AsFloat=0 then tempnettosummeEK:=tempnettosummeEK+dsSumBelegEndpreis_EK.AsFloat;
      if dsBelegMehrwertsteuersatz1.AsFloat=dsSumBelegMehrwertsteuersatz.asfloat then
      begin
        tempnettosumme01:=tempnettosumme01+dsSumBelegEndpreis.AsFloat;
      end;
      if dsBelegMehrwertsteuersatz2.AsFloat=dsSumBelegMehrwertsteuersatz.asfloat then
      begin
        tempnettosumme02:=tempnettosumme02+dsSumBelegEndpreis.AsFloat;
      end;
      if dsBelegMehrwertsteuersatz3.AsFloat=dsSumBelegMehrwertsteuersatz.asfloat then
      begin
        tempnettosumme03:=tempnettosumme03+dsSumBelegEndpreis.AsFloat;
      end;
      dsSumBeleg.Next;
    end;

    dsBelegNettosumme.AsFloat:=tempnettosumme;
    dsBelegNettosumme01.AsFloat:=tempnettosumme01;
    dsBelegNettosumme02.AsFloat:=tempnettosumme02;
    dsBelegNettosumme03.AsFloat:=tempnettosumme03;
    dsBelegNettosummeEK.AsFloat:=tempnettosummeEK;


  end;
  end;
  dsSumBeleg.Close;
  dsbelegprotokoll.asstring:=dsbelegprotokoll.asstring+datetimetostr(date+time)+' CadiaWEB '+ BenutzerSchreibzeichen+#13+#10;

end;

procedure TEinzelAuftrag.dsObjektAfterOpen(DataSet: TDataSet);
begin
  dsbeleg.Edit;
  dsbelegObjektReferenz.AsString:=dsObjektObjektReferenz.AsString;
  dsbelegObjektnummer.AsString:=dsObjektObjektnummer.AsString;
  dsobjekt.Close;
  dsbeleg.post;
end;

procedure TEinzelAuftrag.dsPositionenAfterOpen(DataSet: TDataSet);
var
  I: Integer;
  myelement:TJSHTMLElement;
begin
  PTC.RowCount:=dsPositionen.RecordCount+1;
  PTC.ColCount:=4;
  myelement:=PTC.CellElements[0,0];
  btnNeuePosition.ParentElement:=myelement;
  PTC.Cells[1,0]:='Positionen/Artikel';
  PTC.Cells[2,0]:='Menge';
  PTC.Cells[3,0]:='Preise';
  //PTC.Cells[7,0]:='Endpreis';

  dspositionen.First;
  for I := 1 to dspositionen.RecordCount do
  begin
    PTC.Cells[0,i]:=dspositionen.FieldByName('PosNr').AsString+'.'+dspositionen.FieldByName('PosUNr').AsString;

    myelement:=PTC.cellelements[0,i];
    buttonBearbeiten:=TButton.Create(self);
    buttonBearbeiten.ElementClassName:='btn btn-info btn-sm text-left';
    buttonBearbeiten.HeightStyle:=ssauto;
    buttonBearbeiten.WidthStyle:=ssauto;
    buttonBearbeiten.tag:=dsPositionenID.asinteger;
    buttonBearbeiten.Hint:=dsPositionenArtikelnummerArtikelnummer.AsString;
    buttonBearbeiten.Caption:='bearbeiten';
    buttonBearbeiten.OnClick:=@buttonBearbeitenClick;
    buttonBearbeiten.ParentElement:=myelement;

    PTC.Cells[1,i]:=dspositionen.FieldByName('Artikelnummer.Artikelnummer').AsString+'<br><b>'+dspositionen.FieldByName('Artikelueberschrift').AsString+'</b><br>'+leftstr(dspositionenArtikelbeschreibung.AsString,250)+'<br>'+dsPositionenMonteurP.AsString;;
    PTC.Cells[2,i]:='<p class="text-right">'+dspositionen.FieldByName('Menge').AsString + '<br>'+dsPositionenArtderMenge.asstring;
    PTC.Cells[3,i]:='<p class="text-right">'+formatfloat('#,##0.00',dsPositionenVerkaufspreis.Asfloat)+'<br><b>'+formatfloat('#,##0.00',dsPositionenEndpreis.Asfloat);

    //if not einkauf then PTC.Cells[6,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Verkaufspreis').asfloat);
    //if not einkauf then PTC.Cells[7,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Endpreis').asfloat);
    dspositionen.Next;
  end;
end;

procedure TEinzelAuftrag.dsSumBelegAfterOpen(DataSet: TDataSet);
begin
  dsbeleg.Edit;
  dsbeleg.Post;
end;

procedure TEinzelAuftrag.dsUnterschriftenAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
    dsPositionen.Close;
    dsbeleg.Edit;
    dsbeleg.Post;
end;

procedure TEinzelAuftrag.dsUnterschriftenAfterOpen(DataSet: TDataSet);
 var
  myelement:TJSHTMLElement;
begin
  if dsunterschriften.RecordCount=1 then
  begin
    try
      //TC.Cells[1,15]:='<img id="imageMonteur" src="'+xdataconnectionstring+'/AuftragUnterschriften('+quotedstr(dsbelegnummerkomplett.asstring)+')/UnterschriftMonteurText'+'">';
      //TC.Cells[1,16]:='<img id="imageKunde" src="'+xdataconnectionstring+'/AuftragUnterschriften('+quotedstr(dsbelegnummerkomplett.asstring)+')/UnterschriftKunde'+'">';


      myelement:=tc.CellElements[1,16];
      imgUnterschriftMonteur:=TImageControl.Create(self);
      imgUnterschriftMonteur.height:=100;
      imgUnterschriftMonteur.width:=200;
      imgunterschriftmonteur.url:=dsUnterschriftenUnterschriftMonteurText.asstring;
      myelement.innerHTML:='';
      imgUnterschriftMonteur.ParentElement:=myelement;

      myelement:=tc.CellElements[1,17];
      imgUnterschriftKunde:=TImageControl.Create(self);
      imgUnterschriftKunde.height:=100;
      imgUnterschriftKunde.Width:=200;
      imgunterschriftKunde.url:=dsUnterschriftenUnterschriftKundeText.asstring;
      myelement.innerHTML:='';
      imgUnterschriftKunde.ParentElement:=myelement;
      TC.Cells[1,18]:=formatdatetime('DD.MM.YYYY hh:mm:ss',dsUnterschriftenDatum.Asdatetime);

      btnUnterschreiben.enabled:=false;
    except
    end;
  end;
  dsPositionen.QueryString:='$expand=Artikelnummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag)+'&$orderby=PosNr';
  dsPositionen.Load;
end;

procedure TEinzelAuftrag.dsUnterschriftenAfterPost(DataSet: TDataSet);
begin
  if LizenzOK then
  begin
    dsUnterschriften.ApplyUpdates;
  end
  else
  begin
    showmessage('Für das Speichern von Daten sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TEinzelAuftrag.SourceBelegStateChange(Sender: TObject);
begin
  if dsBeleg.State in dseditmodes then btnspeichern.Enabled:=true else btnspeichern.Enabled:=false;
end;

procedure TEinzelAuftrag.belegComboMonteurEnter(Sender: TObject);
begin
  dsbeleg.Edit;
end;

procedure TEinzelAuftrag.btnNeuePositionClick(Sender: TObject);
var
  AuftragPosEinzel:TAuftragPosEinzel;
begin
  if dspositionen.RecordCount>0 then
  begin
    dsPositionen.Last;
    positionsid:=dsPositionenID.AsString;
    LetztePosNr:=dsPositionenPosNr.AsFloat;
  end
  else
  begin
    LetztePosNr:=0;
    positionsid:='';
  end;
  Container4.HTML.text:='';
  Container3.HTML.Text:='';

  AuftragPosEinzel:=TAuftragPosEinzel.CreateNew(procedure(BForm: TObject)
    begin
      (BForm as TAuftragPosEinzel).PositionsID:='';
      (BForm as TAuftragPosEinzel).tmpArtikelnummer:='';
      (BForm as TAuftragPosEinzel).tmpKundennummer:=dsBelegKundennummerKundennummer.AsString;
      (BForm as TAuftragPosEinzel).tmpMerkerAuftrag:=MerkerAuftrag;
      (BForm as TAuftragPosEinzel).NeuePosition:=True;
      (BForm as TAuftragPosEinzel).tmpletztePosNr:=LetztePosNr;
    end
    );
      AuftragPosEinzel.ShowModal(procedure(AValue: TModalResult)
    begin
      dsSumBeleg.Close;
      dsSumBeleg.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
      dsSumBeleg.Load;
    end
    );

end;

procedure TEinzelAuftrag.btnPDFClick(Sender: TObject);
  procedure OnResponse(Response:TXDataClientResponse);
  var
    mydownload:string;
  begin
    mydownload:=string(TJSObject(response.Result)['value']);
    if mydownload='WRONG' then
    begin
      showmessage('Download kann nicht bereitgestellt werden');
    end
    else
    begin
      application.Navigate(downloadconnection+mydownload,ntblank);
    end;
  end;
begin
  if LizenzOK then
  begin
    WClient.RawInvoke('IServerService.ServiceBerichtPDF',[MerkerAuftrag],@OnResponse);
  end
  else
  begin
    showmessage('Für das Abrufen von Dateien sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;
end;

procedure TEinzelAuftrag.btnSpeichernClick(Sender: TObject);
begin
  dsbeleg.Post;
end;

procedure TEinzelAuftrag.btnTermineClick(Sender: TObject);
var
  LFrm:TAuftragTermine;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TAuftragTermine;
    begin
      LForm:=(AForm as TAuftragTermine);
      lform.MerkerAuftragTermine:=MerkerAuftrag;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin

    end;

  begin

  LFrm:=TAuftragTermine.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TEinzelAuftrag.btnUnterschreibenClick(Sender: TObject);
var
  LFrm:TAuftragUnterschriften;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TAuftragUnterschriften;
    begin
      LForm:=(AForm as TAuftragUnterschriften);
      lform.UMerkerAuftrag:=MerkerAuftrag;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      dsUnterschriften.Close;
      dsPositionen.Close;
      dsBeleg.Close;
      dsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
      dsBeleg.Load;
    end;

  begin

  LFrm:=TAuftragUnterschriften.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TEinzelAuftrag.btnzurueckClick(Sender: TObject);
begin
dspositionen.Close;
dsbeleg.Close;
self.Close;
end;

procedure TEinzelAuftrag.WebFormCloseQuery(Sender: TObject;
  var CanClose: Boolean);
begin
  monteurstringlist.Free;

end;

procedure TEinzelAuftrag.WebFormCreate(Sender: TObject);
begin
  Monteurstringlist:=TStringList.Create;
  Monteurstringlist.Delimiter:='|';
  Monteurstringlist.StrictDelimiter:=true;
  webDBComboAuftragStatus.Items.clear;
  dm2.tableAuftragStatus.First;
  while not dm2.tableAuftragStatus.eof do
  begin
    webDBComboAuftragStatus.Items.Add(dm2.tableAuftragStatusAuftragStatus.asstring);
    dm2.tableAuftragStatus.Next;
  end;
  belegComboMonteur.Items.clear;
  dm2.queryMonteur.First;
  while not dm2.queryMonteur.eof do
  begin
    belegComboMonteur.Items.Add(dm2.queryMonteurPersonalnummer.asstring+'| '+dm2.queryMonteurNachname.asstring+', '+dm2.queryMonteurVorname.asstring);
    dm2.queryMonteur.Next;
  end;
  belegComboMonteur.Items.Add('');

end;

procedure TEinzelAuftrag.WebFormShow(Sender: TObject);
 var
  myelement:TJSHTMLElement;
begin


  if MerkerAuftrag<>'' then
  begin
    dsUnterschriften.Close;
    dsPositionen.Close;
    dsBeleg.Close;
    dsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerAuftrag);
    dsBeleg.Load;
  end;
end;

procedure TEinzelAuftrag.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  btnUnterschreiben := TButton.Create(Self);
  btnPDF := TButton.Create(Self);
  btnTermine := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  PTC := TTableControl.Create(Self);
  btnSpeichern := TButton.Create(Self);
  pickLieferdatum := TDBDateTimePicker.Create(Self);
  edtAuftragsbezeichnung := TDBEdit.Create(Self);
  edtLiefertermin := TDBEdit.Create(Self);
  btnNeuePosition := TButton.Create(Self);
  Container4 := THTMLContainer.Create(Self);
  webDBComboAuftragStatus := TDBComboBox.Create(Self);
  belegComboMonteur := TComboBox.Create(Self);
  belegMemoServiceBemerkung := TDBMemo.Create(Self);
  dsBeleg := TXDataWebDataSet.Create(Self);
  dsBelegKundennummer := TXDataWebEntityField.Create(Self);
  dsBelegNummerkomplett := TStringField.Create(Self);
  dsBelegReferenzAngebot := TStringField.Create(Self);
  dsBelegReferenzLieferschein := TStringField.Create(Self);
  dsBelegReferenzRechnung := TStringField.Create(Self);
  dsBelegDatum := TDateTimeField.Create(Self);
  dsBelegJahr := TFloatField.Create(Self);
  dsBelegKalenderwoche := TIntegerField.Create(Self);
  dsBelegNachname := TStringField.Create(Self);
  dsBelegVorname := TStringField.Create(Self);
  dsBelegPostleitzahl := TStringField.Create(Self);
  dsBelegOrt := TStringField.Create(Self);
  dsBelegLaenderkuerzel := TStringField.Create(Self);
  dsBelegLand := TStringField.Create(Self);
  dsBelegBriefanrede1 := TStringField.Create(Self);
  dsBelegBriefanrede2 := TStringField.Create(Self);
  dsBelegEMail := TStringField.Create(Self);
  dsBelegAdresseBild := TStringField.Create(Self);
  dsBelegLieferanschriftBild := TStringField.Create(Self);
  dsBelegZahlungsbedingung := TIntegerField.Create(Self);
  dsBelegZahlungsbedingungText := TStringField.Create(Self);
  dsBelegAuftragsbezeichnung := TStringField.Create(Self);
  dsBelegAuftragnummerKunde := TStringField.Create(Self);
  dsBelegBestelldatumKunde := TDateTimeField.Create(Self);
  dsBelegILN := TStringField.Create(Self);
  dsBelegKopftext := TStringField.Create(Self);
  dsBelegSchlussbemerkung := TStringField.Create(Self);
  dsBelegGruss := TStringField.Create(Self);
  dsBelegMehrwertsteuersatz1 := TFloatField.Create(Self);
  dsBelegMehrwertsteuersatz2 := TFloatField.Create(Self);
  dsBelegNettosumme01 := TFloatField.Create(Self);
  dsBelegNettosumme02 := TFloatField.Create(Self);
  dsBelegNettosumme := TFloatField.Create(Self);
  dsBelegMehrwertsteuer01 := TFloatField.Create(Self);
  dsBelegMehrwertsteuer02 := TFloatField.Create(Self);
  dsBelegMehrwertsteuer := TFloatField.Create(Self);
  dsBelegBruttosumme := TFloatField.Create(Self);
  dsBelegVersanddatum := TDateTimeField.Create(Self);
  dsBelegSkontosatz := TFloatField.Create(Self);
  dsBelegNettosummeEK := TFloatField.Create(Self);
  dsBelegNettoMarge := TFloatField.Create(Self);
  dsBelegSchreibzeichen := TStringField.Create(Self);
  dsBelegUnterzeichner := TStringField.Create(Self);
  dsBelegTelefonMA := TStringField.Create(Self);
  dsBelegEmailMA := TStringField.Create(Self);
  dsBelegUSTID := TStringField.Create(Self);
  dsBelegUmsatzsteuerausweis := TBooleanField.Create(Self);
  dsBeleggebucht := TBooleanField.Create(Self);
  dsBelegLieferdatum := TDateTimeField.Create(Self);
  dsBelegTransportbedingung := TStringField.Create(Self);
  dsBelegMehrwertsteuersatz3 := TFloatField.Create(Self);
  dsBelegMehrwertsteuer03 := TFloatField.Create(Self);
  dsBelegNettosumme03 := TFloatField.Create(Self);
  dsBelegRundungsDifferenz := TFloatField.Create(Self);
  dsBelegEmailText := TStringField.Create(Self);
  dsBelegAuftragerledigt := TBooleanField.Create(Self);
  dsBelegRabattstufe := TStringField.Create(Self);
  dsBelegKFZKennzeichen := TStringField.Create(Self);
  dsBelegFahrgestellnummer := TStringField.Create(Self);
  dsBelegKFZHersteller := TStringField.Create(Self);
  dsBelegErstzulassungsdatum := TDateTimeField.Create(Self);
  dsBelegKMStand := TStringField.Create(Self);
  dsBelegKFZType := TStringField.Create(Self);
  dsBelegInspektionsdatum := TDateTimeField.Create(Self);
  dsBelegDebitorennummer := TStringField.Create(Self);
  dsBelegKostentraeger := TStringField.Create(Self);
  dsBelegRabattsatz := TFloatField.Create(Self);
  dsBelegObjektReferenz := TStringField.Create(Self);
  dsBelegBank := TStringField.Create(Self);
  dsBelegBankleitzahl := TFloatField.Create(Self);
  dsBelegKontonummer := TStringField.Create(Self);
  dsBelegKontoinhaber := TStringField.Create(Self);
  dsBelegTelefaxnummer := TStringField.Create(Self);
  dsBelegDatumNeu := TDateTimeField.Create(Self);
  dsBelegDatumgeaendert := TDateTimeField.Create(Self);
  dsBelegObjektnummer := TStringField.Create(Self);
  dsBelegLiefertermin := TStringField.Create(Self);
  dsBelegEmailversand := TBooleanField.Create(Self);
  dsBelegTelefonnummer := TStringField.Create(Self);
  dsBelegMobilTelefon := TStringField.Create(Self);
  dsBelegAuftragFax := TBooleanField.Create(Self);
  dsBelegREDatum := TDateTimeField.Create(Self);
  dsBelegBenutzerNeu := TStringField.Create(Self);
  dsBelegBenutzerGeaendert := TStringField.Create(Self);
  dsBelegBerechnungBrutto := TBooleanField.Create(Self);
  dsBelegBruttosumme01 := TFloatField.Create(Self);
  dsBelegBruttosumme02 := TFloatField.Create(Self);
  dsBelegBruttosumme03 := TFloatField.Create(Self);
  dsBelegNotiz := TStringField.Create(Self);
  dsBelegMargeProzent := TFloatField.Create(Self);
  dsBelegWarenkreditgesperrt := TBooleanField.Create(Self);
  dsBelegBelegsprache := TStringField.Create(Self);
  dsBelegDuplicate := TStringField.Create(Self);
  dsBelegperEmail := TBooleanField.Create(Self);
  dsBelegAnsprechpartner := TStringField.Create(Self);
  dsBelegKundennummerFremd := TStringField.Create(Self);
  dsBelegProtokoll := TStringField.Create(Self);
  dsBelegVerleihauftrag := TBooleanField.Create(Self);
  dsBelegVerleihVon := TDateTimeField.Create(Self);
  dsBelegVerleihBis := TDateTimeField.Create(Self);
  dsBelegAuftragGenehmigt := TBooleanField.Create(Self);
  dsBeleggesperrt := TBooleanField.Create(Self);
  dsBelegVerleihvonText := TStringField.Create(Self);
  dsBelegVerleihbisText := TStringField.Create(Self);
  dsBelegDruckWHG := TStringField.Create(Self);
  dsBelegDruckWHGKurs := TFloatField.Create(Self);
  dsBelegProzentsatzZusatzposition := TFloatField.Create(Self);
  dsBelegTextZusatzposition := TStringField.Create(Self);
  dsBelegTextzusatzBeschreibung := TStringField.Create(Self);
  dsBelegLayoutname := TStringField.Create(Self);
  dsBelegGesamtgewicht := TFloatField.Create(Self);
  dsBelegBetragZusatzposition := TFloatField.Create(Self);
  dsBelegReferenzBestellung := TStringField.Create(Self);
  dsBelegTermin1 := TDateTimeField.Create(Self);
  dsBelegTermin2 := TDateTimeField.Create(Self);
  dsBelegZielkontaktnummer := TStringField.Create(Self);
  dsBelegZielkontaktname := TStringField.Create(Self);
  dsBelegBilddatei1 := TStringField.Create(Self);
  dsBelegBilddatei2 := TStringField.Create(Self);
  dsBelegZielkontaktnummer2 := TStringField.Create(Self);
  dsBelegZielkontaktname2 := TStringField.Create(Self);
  dsBelegbestaetigt := TDateTimeField.Create(Self);
  dsBelegZielkontaktnummer3 := TStringField.Create(Self);
  dsBelegZielkontaktname3 := TStringField.Create(Self);
  dsBelegTermin3 := TDateTimeField.Create(Self);
  dsBelegTermin4 := TDateTimeField.Create(Self);
  dsBelegMuster := TBooleanField.Create(Self);
  dsBelegKW := TIntegerField.Create(Self);
  dsBelegDatenlink := TStringField.Create(Self);
  dsBelegMehrwertsteuersatz4 := TFloatField.Create(Self);
  dsBelegMehrwertsteuersatz5 := TFloatField.Create(Self);
  dsBelegMehrwertsteuersatz6 := TFloatField.Create(Self);
  dsBelegBesteuerungKey := TIntegerField.Create(Self);
  dsBelegVertreterMatchcode := TStringField.Create(Self);
  dsBelegVerfasser := TStringField.Create(Self);
  dsBelegLieferadresseID := TIntegerField.Create(Self);
  dsBelegAuftragStatus := TStringField.Create(Self);
  dsBelegMonatJahr := TDateTimeField.Create(Self);
  dsBelegBelegvariante := TStringField.Create(Self);
  dsBelegProduktionsfreigabe := TBooleanField.Create(Self);
  dsBelegReferenzRahmenAuftrag := TStringField.Create(Self);
  dsBelegDatumRahmenAuftrag := TDateTimeField.Create(Self);
  dsBelegFahrzeugID := TIntegerField.Create(Self);
  dsBelegAuslieferungKW := TStringField.Create(Self);
  dsBelegBereitstellungDatum := TDateTimeField.Create(Self);
  dsBelegVTUID := TStringField.Create(Self);
  dsBelegLieferanschriftDaten := TStringField.Create(Self);
  dsBelegGesamtVolumen := TFloatField.Create(Self);
  dsBelegBelegPKey := TIntegerField.Create(Self);
  dsBelegDruckParameter := TStringField.Create(Self);
  dsBelegReferenzService := TStringField.Create(Self);
  dsBelegZusatzDaten := TStringField.Create(Self);
  dsBelegStandPDF := TStringField.Create(Self);
  dsBelegPaketdienst := TStringField.Create(Self);
  dsBelegMonteur := TIntegerField.Create(Self);
  dsBelegKundennummerKundennummer := TStringField.Create(Self);
  dsBelegServiceBemerkung := TStringField.Create(Self);
  SourceBeleg := TDataSource.Create(Self);
  dsPositionen := TXDataWebDataSet.Create(Self);
  dsPositionenArtikelnummer := TXDataWebEntityField.Create(Self);
  dsPositionenNummerkomplett := TXDataWebEntityField.Create(Self);
  dsPositionenID := TIntegerField.Create(Self);
  dsPositionenPosNr := TFloatField.Create(Self);
  dsPositionenPosUNr := TFloatField.Create(Self);
  dsPositionenSumme := TBooleanField.Create(Self);
  dsPositionenArtikelueberschrift := TStringField.Create(Self);
  dsPositionenArtikelbeschreibung := TStringField.Create(Self);
  dsPositionenMenge := TFloatField.Create(Self);
  dsPositionenArtderMenge := TStringField.Create(Self);
  dsPositionenVerkaufspreis := TFloatField.Create(Self);
  dsPositionenEinkaufspreis := TFloatField.Create(Self);
  dsPositionenSeriennummer := TStringField.Create(Self);
  dsPositionenWarengruppe := TStringField.Create(Self);
  dsPositionenWarenuntergruppe := TStringField.Create(Self);
  dsPositionenArtikelnummerKunde := TStringField.Create(Self);
  dsPositionenLieferant := TStringField.Create(Self);
  dsPositionenGewicht := TFloatField.Create(Self);
  dsPositionenLaenge := TFloatField.Create(Self);
  dsPositionenBreite := TFloatField.Create(Self);
  dsPositionenHoehe := TFloatField.Create(Self);
  dsPositionenMehrwertsteuersatz := TFloatField.Create(Self);
  dsPositionenEndpreis := TFloatField.Create(Self);
  dsPositionenPosMWST := TFloatField.Create(Self);
  dsPositionenBruttoEndpreis := TFloatField.Create(Self);
  dsPositionenEndpreis_EK := TFloatField.Create(Self);
  dsPositionenRabattsatz := TFloatField.Create(Self);
  dsPositionenPreisvorRabatt := TFloatField.Create(Self);
  dsPositionenPKZ := TFloatField.Create(Self);
  dsPositionenTextfrei10 := TStringField.Create(Self);
  dsPositionenTextfrei20 := TStringField.Create(Self);
  dsPositionenProduktionAbgeschlossen := TDateTimeField.Create(Self);
  dsPositionenEinzelpreisBrutto := TFloatField.Create(Self);
  dsPositionenPosMarge := TFloatField.Create(Self);
  dsPositionenPosMargeProzent := TFloatField.Create(Self);
  dsPositionenBereitsGeliefert := TFloatField.Create(Self);
  dsPositionenausliefern := TFloatField.Create(Self);
  dsPositionenBestellungErledigt := TBooleanField.Create(Self);
  dsPositionenLager := TStringField.Create(Self);
  dsPositionenbestellen := TBooleanField.Create(Self);
  dsPositionenWarenbestand := TBooleanField.Create(Self);
  dsPositionenArtikelbeschreibungRTF := TStringField.Create(Self);
  dsPositionenQuellartikel := TStringField.Create(Self);
  dsPositionenArtikelnummerWB := TStringField.Create(Self);
  dsPositionenPreisanteilNetto := TFloatField.Create(Self);
  dsPositionenSetHPos := TStringField.Create(Self);
  dsPositionenSetUPos := TStringField.Create(Self);
  dsPositionenProvisionssatz := TFloatField.Create(Self);
  dsPositionenProvisionsbetrag := TFloatField.Create(Self);
  dsPositionenArtikelsprache := TStringField.Create(Self);
  dsPositionenEKvom := TDateTimeField.Create(Self);
  dsPositionenMengenberechnungID := TStringField.Create(Self);
  dsPositionenTaraID := TStringField.Create(Self);
  dsPositionenTara := TFloatField.Create(Self);
  dsPositionenBruttoGewicht := TFloatField.Create(Self);
  dsPositionenAussenflaeche := TFloatField.Create(Self);
  dsPositionenLadeort := TStringField.Create(Self);
  dsPositionenSeitenumbruch := TBooleanField.Create(Self);
  dsPositionenPlanerFarbe := TIntegerField.Create(Self);
  dsPositionenMWStKey := TIntegerField.Create(Self);
  dsPositionenZSnachPos := TBooleanField.Create(Self);
  dsPositionenLieferzeit := TStringField.Create(Self);
  dsPositionenPreisVorRabattWHG := TFloatField.Create(Self);
  dsPositionenReferenzKalkulation := TStringField.Create(Self);
  dsPositionenPKey := TIntegerField.Create(Self);
  dsPositionenAuftragsnummer := TStringField.Create(Self);
  dsPositionenlwbID := TStringField.Create(Self);
  dsPositionenBelegdatum := TDateTimeField.Create(Self);
  dsPositionenWarenkontrollnummern := TStringField.Create(Self);
  dsPositionenVolumen := TFloatField.Create(Self);
  dsPositionenPosVolumen := TFloatField.Create(Self);
  dsPositionenArtikelAttribute := TStringField.Create(Self);
  dsPositionenMonteurP := TIntegerField.Create(Self);
  dsPositionenArbeitszeitDatum := TDateTimeField.Create(Self);
  dsPositionenArbeitszeitVON := TDateTimeField.Create(Self);
  dsPositionenArbeitszeitBIS := TDateTimeField.Create(Self);
  dsPositionenArtikelnummerArtikelnummer := TStringField.Create(Self);
  dsPositionenArtikelnummerArbeitszeit := TBooleanField.Create(Self);
  sourcePositionen := TDataSource.Create(Self);
  dsUnterschriften := TXDataWebDataSet.Create(Self);
  dsUnterschriftenNummerkomplett := TStringField.Create(Self);
  dsUnterschriftenDatum := TDateTimeField.Create(Self);
  dsUnterschriftenUnterschriftMonteur := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftKunde := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftMonteurText := TStringField.Create(Self);
  dsUnterschriftenUnterschriftKundeText := TStringField.Create(Self);
  sourceUnterschriften := TDataSource.Create(Self);
  dsSumBeleg := TXDataWebDataSet.Create(Self);
  dsSumBelegPosVolumen := TFloatField.Create(Self);
  dsSumBelegBruttoGewicht := TFloatField.Create(Self);
  dsSumBelegMehrwertsteuersatz := TFloatField.Create(Self);
  dsSumBelegEndpreis := TFloatField.Create(Self);
  dsSumBelegPosMWST := TFloatField.Create(Self);
  dsSumBelegBruttoEndpreis := TFloatField.Create(Self);
  dsSumBelegEndpreis_EK := TFloatField.Create(Self);
  dsSumBelegPKZ := TFloatField.Create(Self);
  WClient := TXDataWebClient.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  dsObjekt := TXDataWebDataSet.Create(Self);
  dsObjektObjektReferenz := TStringField.Create(Self);
  dsObjektKurzbezeichnung := TStringField.Create(Self);
  dsObjektObjektnummer := TStringField.Create(Self);

  TC.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  btnUnterschreiben.BeforeLoadDFMValues;
  btnPDF.BeforeLoadDFMValues;
  btnTermine.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  PTC.BeforeLoadDFMValues;
  btnSpeichern.BeforeLoadDFMValues;
  pickLieferdatum.BeforeLoadDFMValues;
  edtAuftragsbezeichnung.BeforeLoadDFMValues;
  edtLiefertermin.BeforeLoadDFMValues;
  btnNeuePosition.BeforeLoadDFMValues;
  Container4.BeforeLoadDFMValues;
  webDBComboAuftragStatus.BeforeLoadDFMValues;
  belegComboMonteur.BeforeLoadDFMValues;
  belegMemoServiceBemerkung.BeforeLoadDFMValues;
  dsBeleg.BeforeLoadDFMValues;
  dsBelegKundennummer.BeforeLoadDFMValues;
  dsBelegNummerkomplett.BeforeLoadDFMValues;
  dsBelegReferenzAngebot.BeforeLoadDFMValues;
  dsBelegReferenzLieferschein.BeforeLoadDFMValues;
  dsBelegReferenzRechnung.BeforeLoadDFMValues;
  dsBelegDatum.BeforeLoadDFMValues;
  dsBelegJahr.BeforeLoadDFMValues;
  dsBelegKalenderwoche.BeforeLoadDFMValues;
  dsBelegNachname.BeforeLoadDFMValues;
  dsBelegVorname.BeforeLoadDFMValues;
  dsBelegPostleitzahl.BeforeLoadDFMValues;
  dsBelegOrt.BeforeLoadDFMValues;
  dsBelegLaenderkuerzel.BeforeLoadDFMValues;
  dsBelegLand.BeforeLoadDFMValues;
  dsBelegBriefanrede1.BeforeLoadDFMValues;
  dsBelegBriefanrede2.BeforeLoadDFMValues;
  dsBelegEMail.BeforeLoadDFMValues;
  dsBelegAdresseBild.BeforeLoadDFMValues;
  dsBelegLieferanschriftBild.BeforeLoadDFMValues;
  dsBelegZahlungsbedingung.BeforeLoadDFMValues;
  dsBelegZahlungsbedingungText.BeforeLoadDFMValues;
  dsBelegAuftragsbezeichnung.BeforeLoadDFMValues;
  dsBelegAuftragnummerKunde.BeforeLoadDFMValues;
  dsBelegBestelldatumKunde.BeforeLoadDFMValues;
  dsBelegILN.BeforeLoadDFMValues;
  dsBelegKopftext.BeforeLoadDFMValues;
  dsBelegSchlussbemerkung.BeforeLoadDFMValues;
  dsBelegGruss.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz1.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz2.BeforeLoadDFMValues;
  dsBelegNettosumme01.BeforeLoadDFMValues;
  dsBelegNettosumme02.BeforeLoadDFMValues;
  dsBelegNettosumme.BeforeLoadDFMValues;
  dsBelegMehrwertsteuer01.BeforeLoadDFMValues;
  dsBelegMehrwertsteuer02.BeforeLoadDFMValues;
  dsBelegMehrwertsteuer.BeforeLoadDFMValues;
  dsBelegBruttosumme.BeforeLoadDFMValues;
  dsBelegVersanddatum.BeforeLoadDFMValues;
  dsBelegSkontosatz.BeforeLoadDFMValues;
  dsBelegNettosummeEK.BeforeLoadDFMValues;
  dsBelegNettoMarge.BeforeLoadDFMValues;
  dsBelegSchreibzeichen.BeforeLoadDFMValues;
  dsBelegUnterzeichner.BeforeLoadDFMValues;
  dsBelegTelefonMA.BeforeLoadDFMValues;
  dsBelegEmailMA.BeforeLoadDFMValues;
  dsBelegUSTID.BeforeLoadDFMValues;
  dsBelegUmsatzsteuerausweis.BeforeLoadDFMValues;
  dsBeleggebucht.BeforeLoadDFMValues;
  dsBelegLieferdatum.BeforeLoadDFMValues;
  dsBelegTransportbedingung.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz3.BeforeLoadDFMValues;
  dsBelegMehrwertsteuer03.BeforeLoadDFMValues;
  dsBelegNettosumme03.BeforeLoadDFMValues;
  dsBelegRundungsDifferenz.BeforeLoadDFMValues;
  dsBelegEmailText.BeforeLoadDFMValues;
  dsBelegAuftragerledigt.BeforeLoadDFMValues;
  dsBelegRabattstufe.BeforeLoadDFMValues;
  dsBelegKFZKennzeichen.BeforeLoadDFMValues;
  dsBelegFahrgestellnummer.BeforeLoadDFMValues;
  dsBelegKFZHersteller.BeforeLoadDFMValues;
  dsBelegErstzulassungsdatum.BeforeLoadDFMValues;
  dsBelegKMStand.BeforeLoadDFMValues;
  dsBelegKFZType.BeforeLoadDFMValues;
  dsBelegInspektionsdatum.BeforeLoadDFMValues;
  dsBelegDebitorennummer.BeforeLoadDFMValues;
  dsBelegKostentraeger.BeforeLoadDFMValues;
  dsBelegRabattsatz.BeforeLoadDFMValues;
  dsBelegObjektReferenz.BeforeLoadDFMValues;
  dsBelegBank.BeforeLoadDFMValues;
  dsBelegBankleitzahl.BeforeLoadDFMValues;
  dsBelegKontonummer.BeforeLoadDFMValues;
  dsBelegKontoinhaber.BeforeLoadDFMValues;
  dsBelegTelefaxnummer.BeforeLoadDFMValues;
  dsBelegDatumNeu.BeforeLoadDFMValues;
  dsBelegDatumgeaendert.BeforeLoadDFMValues;
  dsBelegObjektnummer.BeforeLoadDFMValues;
  dsBelegLiefertermin.BeforeLoadDFMValues;
  dsBelegEmailversand.BeforeLoadDFMValues;
  dsBelegTelefonnummer.BeforeLoadDFMValues;
  dsBelegMobilTelefon.BeforeLoadDFMValues;
  dsBelegAuftragFax.BeforeLoadDFMValues;
  dsBelegREDatum.BeforeLoadDFMValues;
  dsBelegBenutzerNeu.BeforeLoadDFMValues;
  dsBelegBenutzerGeaendert.BeforeLoadDFMValues;
  dsBelegBerechnungBrutto.BeforeLoadDFMValues;
  dsBelegBruttosumme01.BeforeLoadDFMValues;
  dsBelegBruttosumme02.BeforeLoadDFMValues;
  dsBelegBruttosumme03.BeforeLoadDFMValues;
  dsBelegNotiz.BeforeLoadDFMValues;
  dsBelegMargeProzent.BeforeLoadDFMValues;
  dsBelegWarenkreditgesperrt.BeforeLoadDFMValues;
  dsBelegBelegsprache.BeforeLoadDFMValues;
  dsBelegDuplicate.BeforeLoadDFMValues;
  dsBelegperEmail.BeforeLoadDFMValues;
  dsBelegAnsprechpartner.BeforeLoadDFMValues;
  dsBelegKundennummerFremd.BeforeLoadDFMValues;
  dsBelegProtokoll.BeforeLoadDFMValues;
  dsBelegVerleihauftrag.BeforeLoadDFMValues;
  dsBelegVerleihVon.BeforeLoadDFMValues;
  dsBelegVerleihBis.BeforeLoadDFMValues;
  dsBelegAuftragGenehmigt.BeforeLoadDFMValues;
  dsBeleggesperrt.BeforeLoadDFMValues;
  dsBelegVerleihvonText.BeforeLoadDFMValues;
  dsBelegVerleihbisText.BeforeLoadDFMValues;
  dsBelegDruckWHG.BeforeLoadDFMValues;
  dsBelegDruckWHGKurs.BeforeLoadDFMValues;
  dsBelegProzentsatzZusatzposition.BeforeLoadDFMValues;
  dsBelegTextZusatzposition.BeforeLoadDFMValues;
  dsBelegTextzusatzBeschreibung.BeforeLoadDFMValues;
  dsBelegLayoutname.BeforeLoadDFMValues;
  dsBelegGesamtgewicht.BeforeLoadDFMValues;
  dsBelegBetragZusatzposition.BeforeLoadDFMValues;
  dsBelegReferenzBestellung.BeforeLoadDFMValues;
  dsBelegTermin1.BeforeLoadDFMValues;
  dsBelegTermin2.BeforeLoadDFMValues;
  dsBelegZielkontaktnummer.BeforeLoadDFMValues;
  dsBelegZielkontaktname.BeforeLoadDFMValues;
  dsBelegBilddatei1.BeforeLoadDFMValues;
  dsBelegBilddatei2.BeforeLoadDFMValues;
  dsBelegZielkontaktnummer2.BeforeLoadDFMValues;
  dsBelegZielkontaktname2.BeforeLoadDFMValues;
  dsBelegbestaetigt.BeforeLoadDFMValues;
  dsBelegZielkontaktnummer3.BeforeLoadDFMValues;
  dsBelegZielkontaktname3.BeforeLoadDFMValues;
  dsBelegTermin3.BeforeLoadDFMValues;
  dsBelegTermin4.BeforeLoadDFMValues;
  dsBelegMuster.BeforeLoadDFMValues;
  dsBelegKW.BeforeLoadDFMValues;
  dsBelegDatenlink.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz4.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz5.BeforeLoadDFMValues;
  dsBelegMehrwertsteuersatz6.BeforeLoadDFMValues;
  dsBelegBesteuerungKey.BeforeLoadDFMValues;
  dsBelegVertreterMatchcode.BeforeLoadDFMValues;
  dsBelegVerfasser.BeforeLoadDFMValues;
  dsBelegLieferadresseID.BeforeLoadDFMValues;
  dsBelegAuftragStatus.BeforeLoadDFMValues;
  dsBelegMonatJahr.BeforeLoadDFMValues;
  dsBelegBelegvariante.BeforeLoadDFMValues;
  dsBelegProduktionsfreigabe.BeforeLoadDFMValues;
  dsBelegReferenzRahmenAuftrag.BeforeLoadDFMValues;
  dsBelegDatumRahmenAuftrag.BeforeLoadDFMValues;
  dsBelegFahrzeugID.BeforeLoadDFMValues;
  dsBelegAuslieferungKW.BeforeLoadDFMValues;
  dsBelegBereitstellungDatum.BeforeLoadDFMValues;
  dsBelegVTUID.BeforeLoadDFMValues;
  dsBelegLieferanschriftDaten.BeforeLoadDFMValues;
  dsBelegGesamtVolumen.BeforeLoadDFMValues;
  dsBelegBelegPKey.BeforeLoadDFMValues;
  dsBelegDruckParameter.BeforeLoadDFMValues;
  dsBelegReferenzService.BeforeLoadDFMValues;
  dsBelegZusatzDaten.BeforeLoadDFMValues;
  dsBelegStandPDF.BeforeLoadDFMValues;
  dsBelegPaketdienst.BeforeLoadDFMValues;
  dsBelegMonteur.BeforeLoadDFMValues;
  dsBelegKundennummerKundennummer.BeforeLoadDFMValues;
  dsBelegServiceBemerkung.BeforeLoadDFMValues;
  SourceBeleg.BeforeLoadDFMValues;
  dsPositionen.BeforeLoadDFMValues;
  dsPositionenArtikelnummer.BeforeLoadDFMValues;
  dsPositionenNummerkomplett.BeforeLoadDFMValues;
  dsPositionenID.BeforeLoadDFMValues;
  dsPositionenPosNr.BeforeLoadDFMValues;
  dsPositionenPosUNr.BeforeLoadDFMValues;
  dsPositionenSumme.BeforeLoadDFMValues;
  dsPositionenArtikelueberschrift.BeforeLoadDFMValues;
  dsPositionenArtikelbeschreibung.BeforeLoadDFMValues;
  dsPositionenMenge.BeforeLoadDFMValues;
  dsPositionenArtderMenge.BeforeLoadDFMValues;
  dsPositionenVerkaufspreis.BeforeLoadDFMValues;
  dsPositionenEinkaufspreis.BeforeLoadDFMValues;
  dsPositionenSeriennummer.BeforeLoadDFMValues;
  dsPositionenWarengruppe.BeforeLoadDFMValues;
  dsPositionenWarenuntergruppe.BeforeLoadDFMValues;
  dsPositionenArtikelnummerKunde.BeforeLoadDFMValues;
  dsPositionenLieferant.BeforeLoadDFMValues;
  dsPositionenGewicht.BeforeLoadDFMValues;
  dsPositionenLaenge.BeforeLoadDFMValues;
  dsPositionenBreite.BeforeLoadDFMValues;
  dsPositionenHoehe.BeforeLoadDFMValues;
  dsPositionenMehrwertsteuersatz.BeforeLoadDFMValues;
  dsPositionenEndpreis.BeforeLoadDFMValues;
  dsPositionenPosMWST.BeforeLoadDFMValues;
  dsPositionenBruttoEndpreis.BeforeLoadDFMValues;
  dsPositionenEndpreis_EK.BeforeLoadDFMValues;
  dsPositionenRabattsatz.BeforeLoadDFMValues;
  dsPositionenPreisvorRabatt.BeforeLoadDFMValues;
  dsPositionenPKZ.BeforeLoadDFMValues;
  dsPositionenTextfrei10.BeforeLoadDFMValues;
  dsPositionenTextfrei20.BeforeLoadDFMValues;
  dsPositionenProduktionAbgeschlossen.BeforeLoadDFMValues;
  dsPositionenEinzelpreisBrutto.BeforeLoadDFMValues;
  dsPositionenPosMarge.BeforeLoadDFMValues;
  dsPositionenPosMargeProzent.BeforeLoadDFMValues;
  dsPositionenBereitsGeliefert.BeforeLoadDFMValues;
  dsPositionenausliefern.BeforeLoadDFMValues;
  dsPositionenBestellungErledigt.BeforeLoadDFMValues;
  dsPositionenLager.BeforeLoadDFMValues;
  dsPositionenbestellen.BeforeLoadDFMValues;
  dsPositionenWarenbestand.BeforeLoadDFMValues;
  dsPositionenArtikelbeschreibungRTF.BeforeLoadDFMValues;
  dsPositionenQuellartikel.BeforeLoadDFMValues;
  dsPositionenArtikelnummerWB.BeforeLoadDFMValues;
  dsPositionenPreisanteilNetto.BeforeLoadDFMValues;
  dsPositionenSetHPos.BeforeLoadDFMValues;
  dsPositionenSetUPos.BeforeLoadDFMValues;
  dsPositionenProvisionssatz.BeforeLoadDFMValues;
  dsPositionenProvisionsbetrag.BeforeLoadDFMValues;
  dsPositionenArtikelsprache.BeforeLoadDFMValues;
  dsPositionenEKvom.BeforeLoadDFMValues;
  dsPositionenMengenberechnungID.BeforeLoadDFMValues;
  dsPositionenTaraID.BeforeLoadDFMValues;
  dsPositionenTara.BeforeLoadDFMValues;
  dsPositionenBruttoGewicht.BeforeLoadDFMValues;
  dsPositionenAussenflaeche.BeforeLoadDFMValues;
  dsPositionenLadeort.BeforeLoadDFMValues;
  dsPositionenSeitenumbruch.BeforeLoadDFMValues;
  dsPositionenPlanerFarbe.BeforeLoadDFMValues;
  dsPositionenMWStKey.BeforeLoadDFMValues;
  dsPositionenZSnachPos.BeforeLoadDFMValues;
  dsPositionenLieferzeit.BeforeLoadDFMValues;
  dsPositionenPreisVorRabattWHG.BeforeLoadDFMValues;
  dsPositionenReferenzKalkulation.BeforeLoadDFMValues;
  dsPositionenPKey.BeforeLoadDFMValues;
  dsPositionenAuftragsnummer.BeforeLoadDFMValues;
  dsPositionenlwbID.BeforeLoadDFMValues;
  dsPositionenBelegdatum.BeforeLoadDFMValues;
  dsPositionenWarenkontrollnummern.BeforeLoadDFMValues;
  dsPositionenVolumen.BeforeLoadDFMValues;
  dsPositionenPosVolumen.BeforeLoadDFMValues;
  dsPositionenArtikelAttribute.BeforeLoadDFMValues;
  dsPositionenMonteurP.BeforeLoadDFMValues;
  dsPositionenArbeitszeitDatum.BeforeLoadDFMValues;
  dsPositionenArbeitszeitVON.BeforeLoadDFMValues;
  dsPositionenArbeitszeitBIS.BeforeLoadDFMValues;
  dsPositionenArtikelnummerArtikelnummer.BeforeLoadDFMValues;
  dsPositionenArtikelnummerArbeitszeit.BeforeLoadDFMValues;
  sourcePositionen.BeforeLoadDFMValues;
  dsUnterschriften.BeforeLoadDFMValues;
  dsUnterschriftenNummerkomplett.BeforeLoadDFMValues;
  dsUnterschriftenDatum.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftMonteur.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKunde.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftMonteurText.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKundeText.BeforeLoadDFMValues;
  sourceUnterschriften.BeforeLoadDFMValues;
  dsSumBeleg.BeforeLoadDFMValues;
  dsSumBelegPosVolumen.BeforeLoadDFMValues;
  dsSumBelegBruttoGewicht.BeforeLoadDFMValues;
  dsSumBelegMehrwertsteuersatz.BeforeLoadDFMValues;
  dsSumBelegEndpreis.BeforeLoadDFMValues;
  dsSumBelegPosMWST.BeforeLoadDFMValues;
  dsSumBelegBruttoEndpreis.BeforeLoadDFMValues;
  dsSumBelegEndpreis_EK.BeforeLoadDFMValues;
  dsSumBelegPKZ.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  dsObjekt.BeforeLoadDFMValues;
  dsObjektObjektReferenz.BeforeLoadDFMValues;
  dsObjektKurzbezeichnung.BeforeLoadDFMValues;
  dsObjektObjektnummer.BeforeLoadDFMValues;
  try
    Name := 'EinzelAuftrag';
    Width := 236;
    Height := 484;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 0;
    TC.Top := 173;
    TC.Width := 236;
    TC.Height := 62;
    TC.ElementClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.HeightStyle := ssAuto;
    TC.WidthStyle := ssAuto;
    TC.BorderColor := clSilver;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-sm table-hover table-bordered table-striped';
    TC.ColCount := 2;
    TC.RowCount := 17;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 236;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 0;
    btnzurueck.Top := 88;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    btnUnterschreiben.SetParentComponent(Self);
    btnUnterschreiben.Name := 'btnUnterschreiben';
    btnUnterschreiben.Left := 239;
    btnUnterschreiben.Top := 88;
    btnUnterschreiben.Width := 96;
    btnUnterschreiben.Height := 25;
    btnUnterschreiben.Caption := 'unterschreiben';
    btnUnterschreiben.ChildOrder := 4;
    btnUnterschreiben.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnUnterschreiben.ElementFont := efCSS;
    btnUnterschreiben.HeightStyle := ssAuto;
    btnUnterschreiben.WidthStyle := ssAuto;
    SetEvent(btnUnterschreiben, Self, 'OnClick', 'btnUnterschreibenClick');
    btnPDF.SetParentComponent(Self);
    btnPDF.Name := 'btnPDF';
    btnPDF.Left := 341;
    btnPDF.Top := 88;
    btnPDF.Width := 96;
    btnPDF.Height := 25;
    btnPDF.Caption := 'PDF';
    btnPDF.ChildOrder := 4;
    btnPDF.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnPDF.ElementFont := efCSS;
    btnPDF.HeightStyle := ssAuto;
    btnPDF.WidthStyle := ssAuto;
    SetEvent(btnPDF, Self, 'OnClick', 'btnPDFClick');
    btnTermine.SetParentComponent(Self);
    btnTermine.Name := 'btnTermine';
    btnTermine.Left := 443;
    btnTermine.Top := 88;
    btnTermine.Width := 96;
    btnTermine.Height := 25;
    btnTermine.Caption := 'Termine';
    btnTermine.ChildOrder := 4;
    btnTermine.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnTermine.ElementFont := efCSS;
    btnTermine.HeightStyle := ssAuto;
    btnTermine.WidthStyle := ssAuto;
    SetEvent(btnTermine, Self, 'OnClick', 'btnTermineClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 82;
    Container3.Width := 236;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-column';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.Align := alTop;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 41;
    Container2.Width := 236;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.Align := alTop;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    PTC.SetParentComponent(Self);
    PTC.Name := 'PTC';
    PTC.Left := 0;
    PTC.Top := 241;
    PTC.Width := 236;
    PTC.Height := 80;
    PTC.ElementClassName := 'table table-hover table-bordered table-striped';
    PTC.HeightStyle := ssAuto;
    PTC.WidthStyle := ssAuto;
    PTC.BorderColor := clSilver;
    PTC.ChildOrder := 5;
    PTC.ElementHeaderClassName := 'table-info';
    PTC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    PTC.ColCount := 2;
    btnSpeichern.SetParentComponent(Self);
    btnSpeichern.Name := 'btnSpeichern';
    btnSpeichern.Left := 8;
    btnSpeichern.Top := 280;
    btnSpeichern.Width := 96;
    btnSpeichern.Height := 25;
    btnSpeichern.Caption := 'speichern';
    btnSpeichern.ChildOrder := 4;
    btnSpeichern.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnSpeichern.ElementFont := efCSS;
    btnSpeichern.HeightStyle := ssAuto;
    btnSpeichern.WidthStyle := ssAuto;
    SetEvent(btnSpeichern, Self, 'OnClick', 'btnSpeichernClick');
    pickLieferdatum.SetParentComponent(Self);
    pickLieferdatum.Name := 'pickLieferdatum';
    pickLieferdatum.Left := 8;
    pickLieferdatum.Top := 342;
    pickLieferdatum.Width := 170;
    pickLieferdatum.Height := 22;
    pickLieferdatum.ElementClassName := 'form-control';
    pickLieferdatum.HeightStyle := ssAuto;
    pickLieferdatum.WidthStyle := ssPercent;
    pickLieferdatum.WidthPercent := 75;
    pickLieferdatum.BorderStyle := bsSingle;
    pickLieferdatum.ChildOrder := 13;
    pickLieferdatum.Color := clWhite;
    pickLieferdatum.Date := 44128.305122870370000000;
    pickLieferdatum.ElementFont := efCSS;
    pickLieferdatum.Role := '';
    pickLieferdatum.Text := '';
    pickLieferdatum.DataField := 'Lieferdatum';
    pickLieferdatum.DataSource := SourceBeleg;
    edtAuftragsbezeichnung.SetParentComponent(Self);
    edtAuftragsbezeichnung.Name := 'edtAuftragsbezeichnung';
    edtAuftragsbezeichnung.Left := 8;
    edtAuftragsbezeichnung.Top := 370;
    edtAuftragsbezeichnung.Width := 121;
    edtAuftragsbezeichnung.Height := 22;
    edtAuftragsbezeichnung.AutoSelect := False;
    edtAuftragsbezeichnung.ChildOrder := 12;
    edtAuftragsbezeichnung.ElementClassName := 'form-control form-control-sm';
    edtAuftragsbezeichnung.ElementFont := efCSS;
    edtAuftragsbezeichnung.HeightStyle := ssAuto;
    edtAuftragsbezeichnung.TextHint := 'Auftragsbezeichnung';
    edtAuftragsbezeichnung.WidthStyle := ssPercent;
    edtAuftragsbezeichnung.WidthPercent := 75;
    edtAuftragsbezeichnung.DataField := 'Auftragsbezeichnung';
    edtAuftragsbezeichnung.DataSource := SourceBeleg;
    edtLiefertermin.SetParentComponent(Self);
    edtLiefertermin.Name := 'edtLiefertermin';
    edtLiefertermin.Left := 8;
    edtLiefertermin.Top := 398;
    edtLiefertermin.Width := 121;
    edtLiefertermin.Height := 22;
    edtLiefertermin.AutoSelect := False;
    edtLiefertermin.ChildOrder := 12;
    edtLiefertermin.ElementClassName := 'form-control';
    edtLiefertermin.ElementFont := efCSS;
    edtLiefertermin.HeightStyle := ssAuto;
    edtLiefertermin.TextHint := 'Lieferzeitraum';
    edtLiefertermin.WidthStyle := ssPercent;
    edtLiefertermin.WidthPercent := 75;
    edtLiefertermin.DataField := 'Liefertermin';
    edtLiefertermin.DataSource := SourceBeleg;
    btnNeuePosition.SetParentComponent(Self);
    btnNeuePosition.Name := 'btnNeuePosition';
    btnNeuePosition.Left := 8;
    btnNeuePosition.Top := 311;
    btnNeuePosition.Width := 96;
    btnNeuePosition.Height := 25;
    btnNeuePosition.Caption := '+ NEU';
    btnNeuePosition.ChildOrder := 4;
    btnNeuePosition.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnNeuePosition.ElementFont := efCSS;
    btnNeuePosition.HeightStyle := ssAuto;
    btnNeuePosition.WidthStyle := ssAuto;
    SetEvent(btnNeuePosition, Self, 'OnClick', 'btnNeuePositionClick');
    Container4.SetParentComponent(Self);
    Container4.Name := 'Container4';
    Container4.Left := 0;
    Container4.Top := 123;
    Container4.Width := 236;
    Container4.Height := 41;
    Container4.ElementClassName := 'd-flex flex-column';
    Container4.HeightStyle := ssAuto;
    Container4.WidthStyle := ssAuto;
    Container4.Align := alTop;
    Container4.ChildOrder := 4;
    Container4.ElementFont := efCSS;
    Container4.ElementPosition := epRelative;
    webDBComboAuftragStatus.SetParentComponent(Self);
    webDBComboAuftragStatus.Name := 'webDBComboAuftragStatus';
    webDBComboAuftragStatus.Left := 8;
    webDBComboAuftragStatus.Top := 424;
    webDBComboAuftragStatus.Width := 145;
    webDBComboAuftragStatus.Height := 21;
    webDBComboAuftragStatus.ElementClassName := 'form-control w-75';
    webDBComboAuftragStatus.ElementFont := efCSS;
    webDBComboAuftragStatus.HeightStyle := ssAuto;
    webDBComboAuftragStatus.Text := 'webDBComboAuftragStatus';
    webDBComboAuftragStatus.WidthStyle := ssPercent;
    webDBComboAuftragStatus.WidthPercent := 75;
    webDBComboAuftragStatus.ItemIndex := -1;
    webDBComboAuftragStatus.DataField := 'AuftragStatus';
    webDBComboAuftragStatus.DataSource := SourceBeleg;
    belegComboMonteur.SetParentComponent(Self);
    belegComboMonteur.Name := 'belegComboMonteur';
    belegComboMonteur.Left := 8;
    belegComboMonteur.Top := 448;
    belegComboMonteur.Width := 145;
    belegComboMonteur.Height := 21;
    belegComboMonteur.ElementClassName := 'form-control w-75';
    belegComboMonteur.ElementFont := efCSS;
    belegComboMonteur.HeightStyle := ssAuto;
    belegComboMonteur.Text := 'belegComboMonteur';
    belegComboMonteur.WidthStyle := ssPercent;
    belegComboMonteur.WidthPercent := 75;
    SetEvent(belegComboMonteur, Self, 'OnEnter', 'belegComboMonteurEnter');
    belegComboMonteur.ItemIndex := -1;
    belegMemoServiceBemerkung.SetParentComponent(Self);
    belegMemoServiceBemerkung.Name := 'belegMemoServiceBemerkung';
    belegMemoServiceBemerkung.Left := 8;
    belegMemoServiceBemerkung.Top := 342;
    belegMemoServiceBemerkung.Width := 185;
    belegMemoServiceBemerkung.Height := 80;
    belegMemoServiceBemerkung.AutoSize := False;
    belegMemoServiceBemerkung.BorderStyle := bsNone;
    belegMemoServiceBemerkung.ElementClassName := 'form-control form-control-sm';
    belegMemoServiceBemerkung.ElementFont := efCSS;
    belegMemoServiceBemerkung.SelLength := 0;
    belegMemoServiceBemerkung.SelStart := 0;
    belegMemoServiceBemerkung.WidthStyle := ssPercent;
    belegMemoServiceBemerkung.WidthPercent := 75;
    belegMemoServiceBemerkung.DataField := 'ServiceBemerkung';
    belegMemoServiceBemerkung.DataSource := SourceBeleg;
    dsBeleg.SetParentComponent(Self);
    dsBeleg.Name := 'dsBeleg';
    dsBeleg.AfterApplyUpdates := dsBelegAfterApplyUpdates;
    dsBeleg.AfterOpen := dsBelegAfterOpen;
    dsBeleg.BeforePost := dsBelegBeforePost;
    dsBeleg.AfterPost := dsBelegAfterPost;
    dsBeleg.EntitySetName := 'Auftrag';
    dsBeleg.Connection := DM2.XDataWebConnection1;
    dsBeleg.SubpropsDepth := 1;
    dsBeleg.QueryString := '$expand=Kundennummer';
    dsBeleg.Left := 80;
    dsBeleg.Top := 128;
    dsBelegKundennummer.SetParentComponent(dsBeleg);
    dsBelegKundennummer.Name := 'dsBelegKundennummer';
    dsBelegKundennummer.FieldName := 'Kundennummer';
    dsBelegKundennummer.Required := True;
    dsBelegNummerkomplett.SetParentComponent(dsBeleg);
    dsBelegNummerkomplett.Name := 'dsBelegNummerkomplett';
    dsBelegNummerkomplett.FieldName := 'Nummerkomplett';
    dsBelegNummerkomplett.Required := True;
    dsBelegNummerkomplett.Size := 10;
    dsBelegReferenzAngebot.SetParentComponent(dsBeleg);
    dsBelegReferenzAngebot.Name := 'dsBelegReferenzAngebot';
    dsBelegReferenzAngebot.FieldName := 'ReferenzAngebot';
    dsBelegReferenzAngebot.Size := 10;
    dsBelegReferenzLieferschein.SetParentComponent(dsBeleg);
    dsBelegReferenzLieferschein.Name := 'dsBelegReferenzLieferschein';
    dsBelegReferenzLieferschein.FieldName := 'ReferenzLieferschein';
    dsBelegReferenzLieferschein.Size := 10;
    dsBelegReferenzRechnung.SetParentComponent(dsBeleg);
    dsBelegReferenzRechnung.Name := 'dsBelegReferenzRechnung';
    dsBelegReferenzRechnung.FieldName := 'ReferenzRechnung';
    dsBelegReferenzRechnung.Size := 10;
    dsBelegDatum.SetParentComponent(dsBeleg);
    dsBelegDatum.Name := 'dsBelegDatum';
    dsBelegDatum.FieldName := 'Datum';
    dsBelegDatum.Required := True;
    dsBelegJahr.SetParentComponent(dsBeleg);
    dsBelegJahr.Name := 'dsBelegJahr';
    dsBelegJahr.FieldName := 'Jahr';
    dsBelegKalenderwoche.SetParentComponent(dsBeleg);
    dsBelegKalenderwoche.Name := 'dsBelegKalenderwoche';
    dsBelegKalenderwoche.FieldName := 'Kalenderwoche';
    dsBelegNachname.SetParentComponent(dsBeleg);
    dsBelegNachname.Name := 'dsBelegNachname';
    dsBelegNachname.FieldName := 'Nachname';
    dsBelegNachname.Size := 50;
    dsBelegVorname.SetParentComponent(dsBeleg);
    dsBelegVorname.Name := 'dsBelegVorname';
    dsBelegVorname.FieldName := 'Vorname';
    dsBelegVorname.Size := 50;
    dsBelegPostleitzahl.SetParentComponent(dsBeleg);
    dsBelegPostleitzahl.Name := 'dsBelegPostleitzahl';
    dsBelegPostleitzahl.FieldName := 'Postleitzahl';
    dsBelegPostleitzahl.Size := 10;
    dsBelegOrt.SetParentComponent(dsBeleg);
    dsBelegOrt.Name := 'dsBelegOrt';
    dsBelegOrt.FieldName := 'Ort';
    dsBelegOrt.Size := 50;
    dsBelegLaenderkuerzel.SetParentComponent(dsBeleg);
    dsBelegLaenderkuerzel.Name := 'dsBelegLaenderkuerzel';
    dsBelegLaenderkuerzel.FieldName := 'Laenderkuerzel';
    dsBelegLaenderkuerzel.Size := 5;
    dsBelegLand.SetParentComponent(dsBeleg);
    dsBelegLand.Name := 'dsBelegLand';
    dsBelegLand.FieldName := 'Land';
    dsBelegLand.Size := 50;
    dsBelegBriefanrede1.SetParentComponent(dsBeleg);
    dsBelegBriefanrede1.Name := 'dsBelegBriefanrede1';
    dsBelegBriefanrede1.FieldName := 'Briefanrede1';
    dsBelegBriefanrede1.Size := 255;
    dsBelegBriefanrede2.SetParentComponent(dsBeleg);
    dsBelegBriefanrede2.Name := 'dsBelegBriefanrede2';
    dsBelegBriefanrede2.FieldName := 'Briefanrede2';
    dsBelegBriefanrede2.Size := 40;
    dsBelegEMail.SetParentComponent(dsBeleg);
    dsBelegEMail.Name := 'dsBelegEMail';
    dsBelegEMail.FieldName := 'EMail';
    dsBelegEMail.Size := 50;
    dsBelegAdresseBild.SetParentComponent(dsBeleg);
    dsBelegAdresseBild.Name := 'dsBelegAdresseBild';
    dsBelegAdresseBild.FieldName := 'AdresseBild';
    dsBelegAdresseBild.Size := 255;
    dsBelegLieferanschriftBild.SetParentComponent(dsBeleg);
    dsBelegLieferanschriftBild.Name := 'dsBelegLieferanschriftBild';
    dsBelegLieferanschriftBild.FieldName := 'LieferanschriftBild';
    dsBelegLieferanschriftBild.Size := 255;
    dsBelegZahlungsbedingung.SetParentComponent(dsBeleg);
    dsBelegZahlungsbedingung.Name := 'dsBelegZahlungsbedingung';
    dsBelegZahlungsbedingung.FieldName := 'Zahlungsbedingung';
    dsBelegZahlungsbedingungText.SetParentComponent(dsBeleg);
    dsBelegZahlungsbedingungText.Name := 'dsBelegZahlungsbedingungText';
    dsBelegZahlungsbedingungText.FieldName := 'ZahlungsbedingungText';
    dsBelegZahlungsbedingungText.Size := 250;
    dsBelegAuftragsbezeichnung.SetParentComponent(dsBeleg);
    dsBelegAuftragsbezeichnung.Name := 'dsBelegAuftragsbezeichnung';
    dsBelegAuftragsbezeichnung.FieldName := 'Auftragsbezeichnung';
    dsBelegAuftragsbezeichnung.Size := 255;
    dsBelegAuftragnummerKunde.SetParentComponent(dsBeleg);
    dsBelegAuftragnummerKunde.Name := 'dsBelegAuftragnummerKunde';
    dsBelegAuftragnummerKunde.FieldName := 'AuftragnummerKunde';
    dsBelegAuftragnummerKunde.Size := 50;
    dsBelegBestelldatumKunde.SetParentComponent(dsBeleg);
    dsBelegBestelldatumKunde.Name := 'dsBelegBestelldatumKunde';
    dsBelegBestelldatumKunde.FieldName := 'BestelldatumKunde';
    dsBelegILN.SetParentComponent(dsBeleg);
    dsBelegILN.Name := 'dsBelegILN';
    dsBelegILN.FieldName := 'ILN';
    dsBelegILN.Size := 50;
    dsBelegKopftext.SetParentComponent(dsBeleg);
    dsBelegKopftext.Name := 'dsBelegKopftext';
    dsBelegKopftext.FieldName := 'Kopftext';
    dsBelegKopftext.Size := 255;
    dsBelegSchlussbemerkung.SetParentComponent(dsBeleg);
    dsBelegSchlussbemerkung.Name := 'dsBelegSchlussbemerkung';
    dsBelegSchlussbemerkung.FieldName := 'Schlussbemerkung';
    dsBelegSchlussbemerkung.Size := 255;
    dsBelegGruss.SetParentComponent(dsBeleg);
    dsBelegGruss.Name := 'dsBelegGruss';
    dsBelegGruss.FieldName := 'Gruss';
    dsBelegGruss.Size := 255;
    dsBelegMehrwertsteuersatz1.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz1.Name := 'dsBelegMehrwertsteuersatz1';
    dsBelegMehrwertsteuersatz1.FieldName := 'Mehrwertsteuersatz1';
    dsBelegMehrwertsteuersatz2.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz2.Name := 'dsBelegMehrwertsteuersatz2';
    dsBelegMehrwertsteuersatz2.FieldName := 'Mehrwertsteuersatz2';
    dsBelegNettosumme01.SetParentComponent(dsBeleg);
    dsBelegNettosumme01.Name := 'dsBelegNettosumme01';
    dsBelegNettosumme01.FieldName := 'Nettosumme01';
    dsBelegNettosumme02.SetParentComponent(dsBeleg);
    dsBelegNettosumme02.Name := 'dsBelegNettosumme02';
    dsBelegNettosumme02.FieldName := 'Nettosumme02';
    dsBelegNettosumme.SetParentComponent(dsBeleg);
    dsBelegNettosumme.Name := 'dsBelegNettosumme';
    dsBelegNettosumme.FieldName := 'Nettosumme';
    dsBelegMehrwertsteuer01.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuer01.Name := 'dsBelegMehrwertsteuer01';
    dsBelegMehrwertsteuer01.FieldName := 'Mehrwertsteuer01';
    dsBelegMehrwertsteuer02.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuer02.Name := 'dsBelegMehrwertsteuer02';
    dsBelegMehrwertsteuer02.FieldName := 'Mehrwertsteuer02';
    dsBelegMehrwertsteuer.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuer.Name := 'dsBelegMehrwertsteuer';
    dsBelegMehrwertsteuer.FieldName := 'Mehrwertsteuer';
    dsBelegBruttosumme.SetParentComponent(dsBeleg);
    dsBelegBruttosumme.Name := 'dsBelegBruttosumme';
    dsBelegBruttosumme.FieldName := 'Bruttosumme';
    dsBelegVersanddatum.SetParentComponent(dsBeleg);
    dsBelegVersanddatum.Name := 'dsBelegVersanddatum';
    dsBelegVersanddatum.FieldName := 'Versanddatum';
    dsBelegSkontosatz.SetParentComponent(dsBeleg);
    dsBelegSkontosatz.Name := 'dsBelegSkontosatz';
    dsBelegSkontosatz.FieldName := 'Skontosatz';
    dsBelegNettosummeEK.SetParentComponent(dsBeleg);
    dsBelegNettosummeEK.Name := 'dsBelegNettosummeEK';
    dsBelegNettosummeEK.FieldName := 'NettosummeEK';
    dsBelegNettoMarge.SetParentComponent(dsBeleg);
    dsBelegNettoMarge.Name := 'dsBelegNettoMarge';
    dsBelegNettoMarge.FieldName := 'NettoMarge';
    dsBelegSchreibzeichen.SetParentComponent(dsBeleg);
    dsBelegSchreibzeichen.Name := 'dsBelegSchreibzeichen';
    dsBelegSchreibzeichen.FieldName := 'Schreibzeichen';
    dsBelegSchreibzeichen.Size := 10;
    dsBelegUnterzeichner.SetParentComponent(dsBeleg);
    dsBelegUnterzeichner.Name := 'dsBelegUnterzeichner';
    dsBelegUnterzeichner.FieldName := 'Unterzeichner';
    dsBelegUnterzeichner.Size := 50;
    dsBelegTelefonMA.SetParentComponent(dsBeleg);
    dsBelegTelefonMA.Name := 'dsBelegTelefonMA';
    dsBelegTelefonMA.FieldName := 'TelefonMA';
    dsBelegTelefonMA.Size := 30;
    dsBelegEmailMA.SetParentComponent(dsBeleg);
    dsBelegEmailMA.Name := 'dsBelegEmailMA';
    dsBelegEmailMA.FieldName := 'EmailMA';
    dsBelegEmailMA.Size := 50;
    dsBelegUSTID.SetParentComponent(dsBeleg);
    dsBelegUSTID.Name := 'dsBelegUSTID';
    dsBelegUSTID.FieldName := 'USTID';
    dsBelegUmsatzsteuerausweis.SetParentComponent(dsBeleg);
    dsBelegUmsatzsteuerausweis.Name := 'dsBelegUmsatzsteuerausweis';
    dsBelegUmsatzsteuerausweis.FieldName := 'Umsatzsteuerausweis';
    dsBeleggebucht.SetParentComponent(dsBeleg);
    dsBeleggebucht.Name := 'dsBeleggebucht';
    dsBeleggebucht.FieldName := 'gebucht';
    dsBelegLieferdatum.SetParentComponent(dsBeleg);
    dsBelegLieferdatum.Name := 'dsBelegLieferdatum';
    dsBelegLieferdatum.FieldName := 'Lieferdatum';
    dsBelegTransportbedingung.SetParentComponent(dsBeleg);
    dsBelegTransportbedingung.Name := 'dsBelegTransportbedingung';
    dsBelegTransportbedingung.FieldName := 'Transportbedingung';
    dsBelegTransportbedingung.Size := 100;
    dsBelegMehrwertsteuersatz3.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz3.Name := 'dsBelegMehrwertsteuersatz3';
    dsBelegMehrwertsteuersatz3.FieldName := 'Mehrwertsteuersatz3';
    dsBelegMehrwertsteuer03.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuer03.Name := 'dsBelegMehrwertsteuer03';
    dsBelegMehrwertsteuer03.FieldName := 'Mehrwertsteuer03';
    dsBelegNettosumme03.SetParentComponent(dsBeleg);
    dsBelegNettosumme03.Name := 'dsBelegNettosumme03';
    dsBelegNettosumme03.FieldName := 'Nettosumme03';
    dsBelegRundungsDifferenz.SetParentComponent(dsBeleg);
    dsBelegRundungsDifferenz.Name := 'dsBelegRundungsDifferenz';
    dsBelegRundungsDifferenz.FieldName := 'RundungsDifferenz';
    dsBelegEmailText.SetParentComponent(dsBeleg);
    dsBelegEmailText.Name := 'dsBelegEmailText';
    dsBelegEmailText.FieldName := 'EmailText';
    dsBelegEmailText.Size := 255;
    dsBelegAuftragerledigt.SetParentComponent(dsBeleg);
    dsBelegAuftragerledigt.Name := 'dsBelegAuftragerledigt';
    dsBelegAuftragerledigt.FieldName := 'Auftragerledigt';
    dsBelegRabattstufe.SetParentComponent(dsBeleg);
    dsBelegRabattstufe.Name := 'dsBelegRabattstufe';
    dsBelegRabattstufe.FieldName := 'Rabattstufe';
    dsBelegRabattstufe.Size := 15;
    dsBelegKFZKennzeichen.SetParentComponent(dsBeleg);
    dsBelegKFZKennzeichen.Name := 'dsBelegKFZKennzeichen';
    dsBelegKFZKennzeichen.FieldName := 'KFZKennzeichen';
    dsBelegKFZKennzeichen.Size := 13;
    dsBelegFahrgestellnummer.SetParentComponent(dsBeleg);
    dsBelegFahrgestellnummer.Name := 'dsBelegFahrgestellnummer';
    dsBelegFahrgestellnummer.FieldName := 'Fahrgestellnummer';
    dsBelegKFZHersteller.SetParentComponent(dsBeleg);
    dsBelegKFZHersteller.Name := 'dsBelegKFZHersteller';
    dsBelegKFZHersteller.FieldName := 'KFZHersteller';
    dsBelegErstzulassungsdatum.SetParentComponent(dsBeleg);
    dsBelegErstzulassungsdatum.Name := 'dsBelegErstzulassungsdatum';
    dsBelegErstzulassungsdatum.FieldName := 'Erstzulassungsdatum';
    dsBelegKMStand.SetParentComponent(dsBeleg);
    dsBelegKMStand.Name := 'dsBelegKMStand';
    dsBelegKMStand.FieldName := 'KMStand';
    dsBelegKMStand.Size := 6;
    dsBelegKFZType.SetParentComponent(dsBeleg);
    dsBelegKFZType.Name := 'dsBelegKFZType';
    dsBelegKFZType.FieldName := 'KFZType';
    dsBelegKFZType.Size := 25;
    dsBelegInspektionsdatum.SetParentComponent(dsBeleg);
    dsBelegInspektionsdatum.Name := 'dsBelegInspektionsdatum';
    dsBelegInspektionsdatum.FieldName := 'Inspektionsdatum';
    dsBelegDebitorennummer.SetParentComponent(dsBeleg);
    dsBelegDebitorennummer.Name := 'dsBelegDebitorennummer';
    dsBelegDebitorennummer.FieldName := 'Debitorennummer';
    dsBelegDebitorennummer.Size := 13;
    dsBelegKostentraeger.SetParentComponent(dsBeleg);
    dsBelegKostentraeger.Name := 'dsBelegKostentraeger';
    dsBelegKostentraeger.FieldName := 'Kostentraeger';
    dsBelegKostentraeger.Size := 13;
    dsBelegRabattsatz.SetParentComponent(dsBeleg);
    dsBelegRabattsatz.Name := 'dsBelegRabattsatz';
    dsBelegRabattsatz.FieldName := 'Rabattsatz';
    dsBelegObjektReferenz.SetParentComponent(dsBeleg);
    dsBelegObjektReferenz.Name := 'dsBelegObjektReferenz';
    dsBelegObjektReferenz.FieldName := 'ObjektReferenz';
    dsBelegObjektReferenz.Size := 13;
    dsBelegBank.SetParentComponent(dsBeleg);
    dsBelegBank.Name := 'dsBelegBank';
    dsBelegBank.FieldName := 'Bank';
    dsBelegBank.Size := 50;
    dsBelegBankleitzahl.SetParentComponent(dsBeleg);
    dsBelegBankleitzahl.Name := 'dsBelegBankleitzahl';
    dsBelegBankleitzahl.FieldName := 'Bankleitzahl';
    dsBelegKontonummer.SetParentComponent(dsBeleg);
    dsBelegKontonummer.Name := 'dsBelegKontonummer';
    dsBelegKontonummer.FieldName := 'Kontonummer';
    dsBelegKontonummer.Size := 50;
    dsBelegKontoinhaber.SetParentComponent(dsBeleg);
    dsBelegKontoinhaber.Name := 'dsBelegKontoinhaber';
    dsBelegKontoinhaber.FieldName := 'Kontoinhaber';
    dsBelegKontoinhaber.Size := 50;
    dsBelegTelefaxnummer.SetParentComponent(dsBeleg);
    dsBelegTelefaxnummer.Name := 'dsBelegTelefaxnummer';
    dsBelegTelefaxnummer.FieldName := 'Telefaxnummer';
    dsBelegTelefaxnummer.Size := 25;
    dsBelegDatumNeu.SetParentComponent(dsBeleg);
    dsBelegDatumNeu.Name := 'dsBelegDatumNeu';
    dsBelegDatumNeu.FieldName := 'DatumNeu';
    dsBelegDatumgeaendert.SetParentComponent(dsBeleg);
    dsBelegDatumgeaendert.Name := 'dsBelegDatumgeaendert';
    dsBelegDatumgeaendert.FieldName := 'Datumgeaendert';
    dsBelegObjektnummer.SetParentComponent(dsBeleg);
    dsBelegObjektnummer.Name := 'dsBelegObjektnummer';
    dsBelegObjektnummer.FieldName := 'Objektnummer';
    dsBelegObjektnummer.Size := 25;
    dsBelegLiefertermin.SetParentComponent(dsBeleg);
    dsBelegLiefertermin.Name := 'dsBelegLiefertermin';
    dsBelegLiefertermin.FieldName := 'Liefertermin';
    dsBelegLiefertermin.Size := 50;
    dsBelegEmailversand.SetParentComponent(dsBeleg);
    dsBelegEmailversand.Name := 'dsBelegEmailversand';
    dsBelegEmailversand.FieldName := 'Emailversand';
    dsBelegTelefonnummer.SetParentComponent(dsBeleg);
    dsBelegTelefonnummer.Name := 'dsBelegTelefonnummer';
    dsBelegTelefonnummer.FieldName := 'Telefonnummer';
    dsBelegTelefonnummer.Size := 35;
    dsBelegMobilTelefon.SetParentComponent(dsBeleg);
    dsBelegMobilTelefon.Name := 'dsBelegMobilTelefon';
    dsBelegMobilTelefon.FieldName := 'MobilTelefon';
    dsBelegMobilTelefon.Size := 25;
    dsBelegAuftragFax.SetParentComponent(dsBeleg);
    dsBelegAuftragFax.Name := 'dsBelegAuftragFax';
    dsBelegAuftragFax.FieldName := 'AuftragFax';
    dsBelegREDatum.SetParentComponent(dsBeleg);
    dsBelegREDatum.Name := 'dsBelegREDatum';
    dsBelegREDatum.FieldName := 'REDatum';
    dsBelegBenutzerNeu.SetParentComponent(dsBeleg);
    dsBelegBenutzerNeu.Name := 'dsBelegBenutzerNeu';
    dsBelegBenutzerNeu.FieldName := 'BenutzerNeu';
    dsBelegBenutzerNeu.Size := 50;
    dsBelegBenutzerGeaendert.SetParentComponent(dsBeleg);
    dsBelegBenutzerGeaendert.Name := 'dsBelegBenutzerGeaendert';
    dsBelegBenutzerGeaendert.FieldName := 'BenutzerGeaendert';
    dsBelegBenutzerGeaendert.Size := 50;
    dsBelegBerechnungBrutto.SetParentComponent(dsBeleg);
    dsBelegBerechnungBrutto.Name := 'dsBelegBerechnungBrutto';
    dsBelegBerechnungBrutto.FieldName := 'BerechnungBrutto';
    dsBelegBruttosumme01.SetParentComponent(dsBeleg);
    dsBelegBruttosumme01.Name := 'dsBelegBruttosumme01';
    dsBelegBruttosumme01.FieldName := 'Bruttosumme01';
    dsBelegBruttosumme02.SetParentComponent(dsBeleg);
    dsBelegBruttosumme02.Name := 'dsBelegBruttosumme02';
    dsBelegBruttosumme02.FieldName := 'Bruttosumme02';
    dsBelegBruttosumme03.SetParentComponent(dsBeleg);
    dsBelegBruttosumme03.Name := 'dsBelegBruttosumme03';
    dsBelegBruttosumme03.FieldName := 'Bruttosumme03';
    dsBelegNotiz.SetParentComponent(dsBeleg);
    dsBelegNotiz.Name := 'dsBelegNotiz';
    dsBelegNotiz.FieldName := 'Notiz';
    dsBelegNotiz.Size := 255;
    dsBelegMargeProzent.SetParentComponent(dsBeleg);
    dsBelegMargeProzent.Name := 'dsBelegMargeProzent';
    dsBelegMargeProzent.FieldName := 'MargeProzent';
    dsBelegWarenkreditgesperrt.SetParentComponent(dsBeleg);
    dsBelegWarenkreditgesperrt.Name := 'dsBelegWarenkreditgesperrt';
    dsBelegWarenkreditgesperrt.FieldName := 'Warenkreditgesperrt';
    dsBelegBelegsprache.SetParentComponent(dsBeleg);
    dsBelegBelegsprache.Name := 'dsBelegBelegsprache';
    dsBelegBelegsprache.FieldName := 'Belegsprache';
    dsBelegBelegsprache.Size := 10;
    dsBelegDuplicate.SetParentComponent(dsBeleg);
    dsBelegDuplicate.Name := 'dsBelegDuplicate';
    dsBelegDuplicate.FieldName := 'Duplicate';
    dsBelegperEmail.SetParentComponent(dsBeleg);
    dsBelegperEmail.Name := 'dsBelegperEmail';
    dsBelegperEmail.FieldName := 'perEmail';
    dsBelegAnsprechpartner.SetParentComponent(dsBeleg);
    dsBelegAnsprechpartner.Name := 'dsBelegAnsprechpartner';
    dsBelegAnsprechpartner.FieldName := 'Ansprechpartner';
    dsBelegAnsprechpartner.Size := 105;
    dsBelegKundennummerFremd.SetParentComponent(dsBeleg);
    dsBelegKundennummerFremd.Name := 'dsBelegKundennummerFremd';
    dsBelegKundennummerFremd.FieldName := 'KundennummerFremd';
    dsBelegProtokoll.SetParentComponent(dsBeleg);
    dsBelegProtokoll.Name := 'dsBelegProtokoll';
    dsBelegProtokoll.FieldName := 'Protokoll';
    dsBelegProtokoll.Size := 255;
    dsBelegVerleihauftrag.SetParentComponent(dsBeleg);
    dsBelegVerleihauftrag.Name := 'dsBelegVerleihauftrag';
    dsBelegVerleihauftrag.FieldName := 'Verleihauftrag';
    dsBelegVerleihVon.SetParentComponent(dsBeleg);
    dsBelegVerleihVon.Name := 'dsBelegVerleihVon';
    dsBelegVerleihVon.FieldName := 'VerleihVon';
    dsBelegVerleihBis.SetParentComponent(dsBeleg);
    dsBelegVerleihBis.Name := 'dsBelegVerleihBis';
    dsBelegVerleihBis.FieldName := 'VerleihBis';
    dsBelegAuftragGenehmigt.SetParentComponent(dsBeleg);
    dsBelegAuftragGenehmigt.Name := 'dsBelegAuftragGenehmigt';
    dsBelegAuftragGenehmigt.FieldName := 'AuftragGenehmigt';
    dsBeleggesperrt.SetParentComponent(dsBeleg);
    dsBeleggesperrt.Name := 'dsBeleggesperrt';
    dsBeleggesperrt.FieldName := 'gesperrt';
    dsBelegVerleihvonText.SetParentComponent(dsBeleg);
    dsBelegVerleihvonText.Name := 'dsBelegVerleihvonText';
    dsBelegVerleihvonText.FieldName := 'VerleihvonText';
    dsBelegVerleihvonText.Size := 50;
    dsBelegVerleihbisText.SetParentComponent(dsBeleg);
    dsBelegVerleihbisText.Name := 'dsBelegVerleihbisText';
    dsBelegVerleihbisText.FieldName := 'VerleihbisText';
    dsBelegVerleihbisText.Size := 50;
    dsBelegDruckWHG.SetParentComponent(dsBeleg);
    dsBelegDruckWHG.Name := 'dsBelegDruckWHG';
    dsBelegDruckWHG.FieldName := 'DruckWHG';
    dsBelegDruckWHG.Size := 3;
    dsBelegDruckWHGKurs.SetParentComponent(dsBeleg);
    dsBelegDruckWHGKurs.Name := 'dsBelegDruckWHGKurs';
    dsBelegDruckWHGKurs.FieldName := 'DruckWHGKurs';
    dsBelegProzentsatzZusatzposition.SetParentComponent(dsBeleg);
    dsBelegProzentsatzZusatzposition.Name := 'dsBelegProzentsatzZusatzposition';
    dsBelegProzentsatzZusatzposition.FieldName := 'ProzentsatzZusatzposition';
    dsBelegTextZusatzposition.SetParentComponent(dsBeleg);
    dsBelegTextZusatzposition.Name := 'dsBelegTextZusatzposition';
    dsBelegTextZusatzposition.FieldName := 'TextZusatzposition';
    dsBelegTextZusatzposition.Size := 255;
    dsBelegTextzusatzBeschreibung.SetParentComponent(dsBeleg);
    dsBelegTextzusatzBeschreibung.Name := 'dsBelegTextzusatzBeschreibung';
    dsBelegTextzusatzBeschreibung.FieldName := 'TextzusatzBeschreibung';
    dsBelegTextzusatzBeschreibung.Size := 50;
    dsBelegLayoutname.SetParentComponent(dsBeleg);
    dsBelegLayoutname.Name := 'dsBelegLayoutname';
    dsBelegLayoutname.FieldName := 'Layoutname';
    dsBelegLayoutname.Size := 50;
    dsBelegGesamtgewicht.SetParentComponent(dsBeleg);
    dsBelegGesamtgewicht.Name := 'dsBelegGesamtgewicht';
    dsBelegGesamtgewicht.FieldName := 'Gesamtgewicht';
    dsBelegBetragZusatzposition.SetParentComponent(dsBeleg);
    dsBelegBetragZusatzposition.Name := 'dsBelegBetragZusatzposition';
    dsBelegBetragZusatzposition.FieldName := 'BetragZusatzposition';
    dsBelegReferenzBestellung.SetParentComponent(dsBeleg);
    dsBelegReferenzBestellung.Name := 'dsBelegReferenzBestellung';
    dsBelegReferenzBestellung.FieldName := 'ReferenzBestellung';
    dsBelegReferenzBestellung.Size := 10;
    dsBelegTermin1.SetParentComponent(dsBeleg);
    dsBelegTermin1.Name := 'dsBelegTermin1';
    dsBelegTermin1.FieldName := 'Termin1';
    dsBelegTermin2.SetParentComponent(dsBeleg);
    dsBelegTermin2.Name := 'dsBelegTermin2';
    dsBelegTermin2.FieldName := 'Termin2';
    dsBelegZielkontaktnummer.SetParentComponent(dsBeleg);
    dsBelegZielkontaktnummer.Name := 'dsBelegZielkontaktnummer';
    dsBelegZielkontaktnummer.FieldName := 'Zielkontaktnummer';
    dsBelegZielkontaktnummer.Size := 13;
    dsBelegZielkontaktname.SetParentComponent(dsBeleg);
    dsBelegZielkontaktname.Name := 'dsBelegZielkontaktname';
    dsBelegZielkontaktname.FieldName := 'Zielkontaktname';
    dsBelegZielkontaktname.Size := 40;
    dsBelegBilddatei1.SetParentComponent(dsBeleg);
    dsBelegBilddatei1.Name := 'dsBelegBilddatei1';
    dsBelegBilddatei1.FieldName := 'Bilddatei1';
    dsBelegBilddatei1.Size := 255;
    dsBelegBilddatei2.SetParentComponent(dsBeleg);
    dsBelegBilddatei2.Name := 'dsBelegBilddatei2';
    dsBelegBilddatei2.FieldName := 'Bilddatei2';
    dsBelegBilddatei2.Size := 255;
    dsBelegZielkontaktnummer2.SetParentComponent(dsBeleg);
    dsBelegZielkontaktnummer2.Name := 'dsBelegZielkontaktnummer2';
    dsBelegZielkontaktnummer2.FieldName := 'Zielkontaktnummer2';
    dsBelegZielkontaktnummer2.Size := 13;
    dsBelegZielkontaktname2.SetParentComponent(dsBeleg);
    dsBelegZielkontaktname2.Name := 'dsBelegZielkontaktname2';
    dsBelegZielkontaktname2.FieldName := 'Zielkontaktname2';
    dsBelegZielkontaktname2.Size := 40;
    dsBelegbestaetigt.SetParentComponent(dsBeleg);
    dsBelegbestaetigt.Name := 'dsBelegbestaetigt';
    dsBelegbestaetigt.FieldName := 'bestaetigt';
    dsBelegZielkontaktnummer3.SetParentComponent(dsBeleg);
    dsBelegZielkontaktnummer3.Name := 'dsBelegZielkontaktnummer3';
    dsBelegZielkontaktnummer3.FieldName := 'Zielkontaktnummer3';
    dsBelegZielkontaktnummer3.Size := 13;
    dsBelegZielkontaktname3.SetParentComponent(dsBeleg);
    dsBelegZielkontaktname3.Name := 'dsBelegZielkontaktname3';
    dsBelegZielkontaktname3.FieldName := 'Zielkontaktname3';
    dsBelegZielkontaktname3.Size := 40;
    dsBelegTermin3.SetParentComponent(dsBeleg);
    dsBelegTermin3.Name := 'dsBelegTermin3';
    dsBelegTermin3.FieldName := 'Termin3';
    dsBelegTermin4.SetParentComponent(dsBeleg);
    dsBelegTermin4.Name := 'dsBelegTermin4';
    dsBelegTermin4.FieldName := 'Termin4';
    dsBelegMuster.SetParentComponent(dsBeleg);
    dsBelegMuster.Name := 'dsBelegMuster';
    dsBelegMuster.FieldName := 'Muster';
    dsBelegKW.SetParentComponent(dsBeleg);
    dsBelegKW.Name := 'dsBelegKW';
    dsBelegKW.FieldName := 'KW';
    dsBelegDatenlink.SetParentComponent(dsBeleg);
    dsBelegDatenlink.Name := 'dsBelegDatenlink';
    dsBelegDatenlink.FieldName := 'Datenlink';
    dsBelegDatenlink.Size := 255;
    dsBelegMehrwertsteuersatz4.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz4.Name := 'dsBelegMehrwertsteuersatz4';
    dsBelegMehrwertsteuersatz4.FieldName := 'Mehrwertsteuersatz4';
    dsBelegMehrwertsteuersatz5.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz5.Name := 'dsBelegMehrwertsteuersatz5';
    dsBelegMehrwertsteuersatz5.FieldName := 'Mehrwertsteuersatz5';
    dsBelegMehrwertsteuersatz6.SetParentComponent(dsBeleg);
    dsBelegMehrwertsteuersatz6.Name := 'dsBelegMehrwertsteuersatz6';
    dsBelegMehrwertsteuersatz6.FieldName := 'Mehrwertsteuersatz6';
    dsBelegBesteuerungKey.SetParentComponent(dsBeleg);
    dsBelegBesteuerungKey.Name := 'dsBelegBesteuerungKey';
    dsBelegBesteuerungKey.FieldName := 'BesteuerungKey';
    dsBelegVertreterMatchcode.SetParentComponent(dsBeleg);
    dsBelegVertreterMatchcode.Name := 'dsBelegVertreterMatchcode';
    dsBelegVertreterMatchcode.FieldName := 'VertreterMatchcode';
    dsBelegVertreterMatchcode.Size := 50;
    dsBelegVerfasser.SetParentComponent(dsBeleg);
    dsBelegVerfasser.Name := 'dsBelegVerfasser';
    dsBelegVerfasser.FieldName := 'Verfasser';
    dsBelegVerfasser.Size := 10;
    dsBelegLieferadresseID.SetParentComponent(dsBeleg);
    dsBelegLieferadresseID.Name := 'dsBelegLieferadresseID';
    dsBelegLieferadresseID.FieldName := 'LieferadresseID';
    dsBelegAuftragStatus.SetParentComponent(dsBeleg);
    dsBelegAuftragStatus.Name := 'dsBelegAuftragStatus';
    dsBelegAuftragStatus.FieldName := 'AuftragStatus';
    dsBelegAuftragStatus.Size := 30;
    dsBelegMonatJahr.SetParentComponent(dsBeleg);
    dsBelegMonatJahr.Name := 'dsBelegMonatJahr';
    dsBelegMonatJahr.FieldName := 'MonatJahr';
    dsBelegBelegvariante.SetParentComponent(dsBeleg);
    dsBelegBelegvariante.Name := 'dsBelegBelegvariante';
    dsBelegBelegvariante.FieldName := 'Belegvariante';
    dsBelegBelegvariante.Size := 25;
    dsBelegProduktionsfreigabe.SetParentComponent(dsBeleg);
    dsBelegProduktionsfreigabe.Name := 'dsBelegProduktionsfreigabe';
    dsBelegProduktionsfreigabe.FieldName := 'Produktionsfreigabe';
    dsBelegReferenzRahmenAuftrag.SetParentComponent(dsBeleg);
    dsBelegReferenzRahmenAuftrag.Name := 'dsBelegReferenzRahmenAuftrag';
    dsBelegReferenzRahmenAuftrag.FieldName := 'ReferenzRahmenAuftrag';
    dsBelegReferenzRahmenAuftrag.Size := 10;
    dsBelegDatumRahmenAuftrag.SetParentComponent(dsBeleg);
    dsBelegDatumRahmenAuftrag.Name := 'dsBelegDatumRahmenAuftrag';
    dsBelegDatumRahmenAuftrag.FieldName := 'DatumRahmenAuftrag';
    dsBelegFahrzeugID.SetParentComponent(dsBeleg);
    dsBelegFahrzeugID.Name := 'dsBelegFahrzeugID';
    dsBelegFahrzeugID.FieldName := 'FahrzeugID';
    dsBelegAuslieferungKW.SetParentComponent(dsBeleg);
    dsBelegAuslieferungKW.Name := 'dsBelegAuslieferungKW';
    dsBelegAuslieferungKW.FieldName := 'AuslieferungKW';
    dsBelegAuslieferungKW.Size := 25;
    dsBelegBereitstellungDatum.SetParentComponent(dsBeleg);
    dsBelegBereitstellungDatum.Name := 'dsBelegBereitstellungDatum';
    dsBelegBereitstellungDatum.FieldName := 'BereitstellungDatum';
    dsBelegVTUID.SetParentComponent(dsBeleg);
    dsBelegVTUID.Name := 'dsBelegVTUID';
    dsBelegVTUID.FieldName := 'VTUID';
    dsBelegVTUID.Size := 38;
    dsBelegLieferanschriftDaten.SetParentComponent(dsBeleg);
    dsBelegLieferanschriftDaten.Name := 'dsBelegLieferanschriftDaten';
    dsBelegLieferanschriftDaten.FieldName := 'LieferanschriftDaten';
    dsBelegLieferanschriftDaten.Size := 255;
    dsBelegGesamtVolumen.SetParentComponent(dsBeleg);
    dsBelegGesamtVolumen.Name := 'dsBelegGesamtVolumen';
    dsBelegGesamtVolumen.FieldName := 'GesamtVolumen';
    dsBelegBelegPKey.SetParentComponent(dsBeleg);
    dsBelegBelegPKey.Name := 'dsBelegBelegPKey';
    dsBelegBelegPKey.FieldName := 'BelegPKey';
    dsBelegDruckParameter.SetParentComponent(dsBeleg);
    dsBelegDruckParameter.Name := 'dsBelegDruckParameter';
    dsBelegDruckParameter.FieldName := 'DruckParameter';
    dsBelegDruckParameter.Size := 255;
    dsBelegReferenzService.SetParentComponent(dsBeleg);
    dsBelegReferenzService.Name := 'dsBelegReferenzService';
    dsBelegReferenzService.FieldName := 'ReferenzService';
    dsBelegReferenzService.Size := 10;
    dsBelegZusatzDaten.SetParentComponent(dsBeleg);
    dsBelegZusatzDaten.Name := 'dsBelegZusatzDaten';
    dsBelegZusatzDaten.FieldName := 'ZusatzDaten';
    dsBelegZusatzDaten.Size := 255;
    dsBelegStandPDF.SetParentComponent(dsBeleg);
    dsBelegStandPDF.Name := 'dsBelegStandPDF';
    dsBelegStandPDF.FieldName := 'StandPDF';
    dsBelegStandPDF.Size := 255;
    dsBelegPaketdienst.SetParentComponent(dsBeleg);
    dsBelegPaketdienst.Name := 'dsBelegPaketdienst';
    dsBelegPaketdienst.FieldName := 'Paketdienst';
    dsBelegPaketdienst.Size := 30;
    dsBelegMonteur.SetParentComponent(dsBeleg);
    dsBelegMonteur.Name := 'dsBelegMonteur';
    dsBelegMonteur.FieldName := 'Monteur';
    dsBelegKundennummerKundennummer.SetParentComponent(dsBeleg);
    dsBelegKundennummerKundennummer.Name := 'dsBelegKundennummerKundennummer';
    dsBelegKundennummerKundennummer.FieldName := 'Kundennummer.Kundennummer';
    dsBelegKundennummerKundennummer.Size := 13;
    dsBelegServiceBemerkung.SetParentComponent(dsBeleg);
    dsBelegServiceBemerkung.Name := 'dsBelegServiceBemerkung';
    dsBelegServiceBemerkung.FieldName := 'ServiceBemerkung';
    dsBelegServiceBemerkung.Size := 255;
    SourceBeleg.SetParentComponent(Self);
    SourceBeleg.Name := 'SourceBeleg';
    SourceBeleg.DataSet := dsBeleg;
    SetEvent(SourceBeleg, Self, 'OnStateChange', 'SourceBelegStateChange');
    SourceBeleg.Left := 16;
    SourceBeleg.Top := 128;
    dsPositionen.SetParentComponent(Self);
    dsPositionen.Name := 'dsPositionen';
    dsPositionen.AfterOpen := dsPositionenAfterOpen;
    dsPositionen.EntitySetName := 'PositionenAuftrag';
    dsPositionen.Connection := DM2.XDataWebConnection1;
    dsPositionen.SubpropsDepth := 1;
    dsPositionen.QueryString := '$expand=Artikelnummer';
    dsPositionen.Left := 216;
    dsPositionen.Top := 128;
    dsPositionenArtikelnummer.SetParentComponent(dsPositionen);
    dsPositionenArtikelnummer.Name := 'dsPositionenArtikelnummer';
    dsPositionenArtikelnummer.FieldName := 'Artikelnummer';
    dsPositionenNummerkomplett.SetParentComponent(dsPositionen);
    dsPositionenNummerkomplett.Name := 'dsPositionenNummerkomplett';
    dsPositionenNummerkomplett.FieldName := 'Nummerkomplett';
    dsPositionenID.SetParentComponent(dsPositionen);
    dsPositionenID.Name := 'dsPositionenID';
    dsPositionenID.FieldName := 'ID';
    dsPositionenID.Required := True;
    dsPositionenPosNr.SetParentComponent(dsPositionen);
    dsPositionenPosNr.Name := 'dsPositionenPosNr';
    dsPositionenPosNr.FieldName := 'PosNr';
    dsPositionenPosNr.Required := True;
    dsPositionenPosUNr.SetParentComponent(dsPositionen);
    dsPositionenPosUNr.Name := 'dsPositionenPosUNr';
    dsPositionenPosUNr.FieldName := 'PosUNr';
    dsPositionenSumme.SetParentComponent(dsPositionen);
    dsPositionenSumme.Name := 'dsPositionenSumme';
    dsPositionenSumme.FieldName := 'Summe';
    dsPositionenArtikelueberschrift.SetParentComponent(dsPositionen);
    dsPositionenArtikelueberschrift.Name := 'dsPositionenArtikelueberschrift';
    dsPositionenArtikelueberschrift.FieldName := 'Artikelueberschrift';
    dsPositionenArtikelueberschrift.Size := 50;
    dsPositionenArtikelbeschreibung.SetParentComponent(dsPositionen);
    dsPositionenArtikelbeschreibung.Name := 'dsPositionenArtikelbeschreibung';
    dsPositionenArtikelbeschreibung.FieldName := 'Artikelbeschreibung';
    dsPositionenArtikelbeschreibung.Size := 255;
    dsPositionenMenge.SetParentComponent(dsPositionen);
    dsPositionenMenge.Name := 'dsPositionenMenge';
    dsPositionenMenge.FieldName := 'Menge';
    dsPositionenArtderMenge.SetParentComponent(dsPositionen);
    dsPositionenArtderMenge.Name := 'dsPositionenArtderMenge';
    dsPositionenArtderMenge.FieldName := 'ArtderMenge';
    dsPositionenArtderMenge.Size := 50;
    dsPositionenVerkaufspreis.SetParentComponent(dsPositionen);
    dsPositionenVerkaufspreis.Name := 'dsPositionenVerkaufspreis';
    dsPositionenVerkaufspreis.FieldName := 'Verkaufspreis';
    dsPositionenEinkaufspreis.SetParentComponent(dsPositionen);
    dsPositionenEinkaufspreis.Name := 'dsPositionenEinkaufspreis';
    dsPositionenEinkaufspreis.FieldName := 'Einkaufspreis';
    dsPositionenSeriennummer.SetParentComponent(dsPositionen);
    dsPositionenSeriennummer.Name := 'dsPositionenSeriennummer';
    dsPositionenSeriennummer.FieldName := 'Seriennummer';
    dsPositionenSeriennummer.Size := 30;
    dsPositionenWarengruppe.SetParentComponent(dsPositionen);
    dsPositionenWarengruppe.Name := 'dsPositionenWarengruppe';
    dsPositionenWarengruppe.FieldName := 'Warengruppe';
    dsPositionenWarengruppe.Size := 50;
    dsPositionenWarenuntergruppe.SetParentComponent(dsPositionen);
    dsPositionenWarenuntergruppe.Name := 'dsPositionenWarenuntergruppe';
    dsPositionenWarenuntergruppe.FieldName := 'Warenuntergruppe';
    dsPositionenWarenuntergruppe.Size := 50;
    dsPositionenArtikelnummerKunde.SetParentComponent(dsPositionen);
    dsPositionenArtikelnummerKunde.Name := 'dsPositionenArtikelnummerKunde';
    dsPositionenArtikelnummerKunde.FieldName := 'ArtikelnummerKunde';
    dsPositionenLieferant.SetParentComponent(dsPositionen);
    dsPositionenLieferant.Name := 'dsPositionenLieferant';
    dsPositionenLieferant.FieldName := 'Lieferant';
    dsPositionenLieferant.Size := 13;
    dsPositionenGewicht.SetParentComponent(dsPositionen);
    dsPositionenGewicht.Name := 'dsPositionenGewicht';
    dsPositionenGewicht.FieldName := 'Gewicht';
    dsPositionenLaenge.SetParentComponent(dsPositionen);
    dsPositionenLaenge.Name := 'dsPositionenLaenge';
    dsPositionenLaenge.FieldName := 'Laenge';
    dsPositionenBreite.SetParentComponent(dsPositionen);
    dsPositionenBreite.Name := 'dsPositionenBreite';
    dsPositionenBreite.FieldName := 'Breite';
    dsPositionenHoehe.SetParentComponent(dsPositionen);
    dsPositionenHoehe.Name := 'dsPositionenHoehe';
    dsPositionenHoehe.FieldName := 'Hoehe';
    dsPositionenMehrwertsteuersatz.SetParentComponent(dsPositionen);
    dsPositionenMehrwertsteuersatz.Name := 'dsPositionenMehrwertsteuersatz';
    dsPositionenMehrwertsteuersatz.FieldName := 'Mehrwertsteuersatz';
    dsPositionenEndpreis.SetParentComponent(dsPositionen);
    dsPositionenEndpreis.Name := 'dsPositionenEndpreis';
    dsPositionenEndpreis.FieldName := 'Endpreis';
    dsPositionenPosMWST.SetParentComponent(dsPositionen);
    dsPositionenPosMWST.Name := 'dsPositionenPosMWST';
    dsPositionenPosMWST.FieldName := 'PosMWST';
    dsPositionenBruttoEndpreis.SetParentComponent(dsPositionen);
    dsPositionenBruttoEndpreis.Name := 'dsPositionenBruttoEndpreis';
    dsPositionenBruttoEndpreis.FieldName := 'BruttoEndpreis';
    dsPositionenEndpreis_EK.SetParentComponent(dsPositionen);
    dsPositionenEndpreis_EK.Name := 'dsPositionenEndpreis_EK';
    dsPositionenEndpreis_EK.FieldName := 'Endpreis_EK';
    dsPositionenRabattsatz.SetParentComponent(dsPositionen);
    dsPositionenRabattsatz.Name := 'dsPositionenRabattsatz';
    dsPositionenRabattsatz.FieldName := 'Rabattsatz';
    dsPositionenPreisvorRabatt.SetParentComponent(dsPositionen);
    dsPositionenPreisvorRabatt.Name := 'dsPositionenPreisvorRabatt';
    dsPositionenPreisvorRabatt.FieldName := 'PreisvorRabatt';
    dsPositionenPKZ.SetParentComponent(dsPositionen);
    dsPositionenPKZ.Name := 'dsPositionenPKZ';
    dsPositionenPKZ.FieldName := 'PKZ';
    dsPositionenTextfrei10.SetParentComponent(dsPositionen);
    dsPositionenTextfrei10.Name := 'dsPositionenTextfrei10';
    dsPositionenTextfrei10.FieldName := 'Textfrei10';
    dsPositionenTextfrei10.Size := 50;
    dsPositionenTextfrei20.SetParentComponent(dsPositionen);
    dsPositionenTextfrei20.Name := 'dsPositionenTextfrei20';
    dsPositionenTextfrei20.FieldName := 'Textfrei20';
    dsPositionenTextfrei20.Size := 50;
    dsPositionenProduktionAbgeschlossen.SetParentComponent(dsPositionen);
    dsPositionenProduktionAbgeschlossen.Name := 'dsPositionenProduktionAbgeschlossen';
    dsPositionenProduktionAbgeschlossen.FieldName := 'ProduktionAbgeschlossen';
    dsPositionenEinzelpreisBrutto.SetParentComponent(dsPositionen);
    dsPositionenEinzelpreisBrutto.Name := 'dsPositionenEinzelpreisBrutto';
    dsPositionenEinzelpreisBrutto.FieldName := 'EinzelpreisBrutto';
    dsPositionenPosMarge.SetParentComponent(dsPositionen);
    dsPositionenPosMarge.Name := 'dsPositionenPosMarge';
    dsPositionenPosMarge.FieldName := 'PosMarge';
    dsPositionenPosMargeProzent.SetParentComponent(dsPositionen);
    dsPositionenPosMargeProzent.Name := 'dsPositionenPosMargeProzent';
    dsPositionenPosMargeProzent.FieldName := 'PosMargeProzent';
    dsPositionenBereitsGeliefert.SetParentComponent(dsPositionen);
    dsPositionenBereitsGeliefert.Name := 'dsPositionenBereitsGeliefert';
    dsPositionenBereitsGeliefert.FieldName := 'BereitsGeliefert';
    dsPositionenausliefern.SetParentComponent(dsPositionen);
    dsPositionenausliefern.Name := 'dsPositionenausliefern';
    dsPositionenausliefern.FieldName := 'ausliefern';
    dsPositionenBestellungErledigt.SetParentComponent(dsPositionen);
    dsPositionenBestellungErledigt.Name := 'dsPositionenBestellungErledigt';
    dsPositionenBestellungErledigt.FieldName := 'BestellungErledigt';
    dsPositionenLager.SetParentComponent(dsPositionen);
    dsPositionenLager.Name := 'dsPositionenLager';
    dsPositionenLager.FieldName := 'Lager';
    dsPositionenLager.Size := 50;
    dsPositionenbestellen.SetParentComponent(dsPositionen);
    dsPositionenbestellen.Name := 'dsPositionenbestellen';
    dsPositionenbestellen.FieldName := 'bestellen';
    dsPositionenWarenbestand.SetParentComponent(dsPositionen);
    dsPositionenWarenbestand.Name := 'dsPositionenWarenbestand';
    dsPositionenWarenbestand.FieldName := 'Warenbestand';
    dsPositionenArtikelbeschreibungRTF.SetParentComponent(dsPositionen);
    dsPositionenArtikelbeschreibungRTF.Name := 'dsPositionenArtikelbeschreibungRTF';
    dsPositionenArtikelbeschreibungRTF.FieldName := 'ArtikelbeschreibungRTF';
    dsPositionenArtikelbeschreibungRTF.Size := 255;
    dsPositionenQuellartikel.SetParentComponent(dsPositionen);
    dsPositionenQuellartikel.Name := 'dsPositionenQuellartikel';
    dsPositionenQuellartikel.FieldName := 'Quellartikel';
    dsPositionenQuellartikel.Size := 25;
    dsPositionenArtikelnummerWB.SetParentComponent(dsPositionen);
    dsPositionenArtikelnummerWB.Name := 'dsPositionenArtikelnummerWB';
    dsPositionenArtikelnummerWB.FieldName := 'ArtikelnummerWB';
    dsPositionenArtikelnummerWB.Size := 25;
    dsPositionenPreisanteilNetto.SetParentComponent(dsPositionen);
    dsPositionenPreisanteilNetto.Name := 'dsPositionenPreisanteilNetto';
    dsPositionenPreisanteilNetto.FieldName := 'PreisanteilNetto';
    dsPositionenSetHPos.SetParentComponent(dsPositionen);
    dsPositionenSetHPos.Name := 'dsPositionenSetHPos';
    dsPositionenSetHPos.FieldName := 'SetHPos';
    dsPositionenSetHPos.Size := 38;
    dsPositionenSetUPos.SetParentComponent(dsPositionen);
    dsPositionenSetUPos.Name := 'dsPositionenSetUPos';
    dsPositionenSetUPos.FieldName := 'SetUPos';
    dsPositionenSetUPos.Size := 38;
    dsPositionenProvisionssatz.SetParentComponent(dsPositionen);
    dsPositionenProvisionssatz.Name := 'dsPositionenProvisionssatz';
    dsPositionenProvisionssatz.FieldName := 'Provisionssatz';
    dsPositionenProvisionsbetrag.SetParentComponent(dsPositionen);
    dsPositionenProvisionsbetrag.Name := 'dsPositionenProvisionsbetrag';
    dsPositionenProvisionsbetrag.FieldName := 'Provisionsbetrag';
    dsPositionenArtikelsprache.SetParentComponent(dsPositionen);
    dsPositionenArtikelsprache.Name := 'dsPositionenArtikelsprache';
    dsPositionenArtikelsprache.FieldName := 'Artikelsprache';
    dsPositionenArtikelsprache.Size := 255;
    dsPositionenEKvom.SetParentComponent(dsPositionen);
    dsPositionenEKvom.Name := 'dsPositionenEKvom';
    dsPositionenEKvom.FieldName := 'EKvom';
    dsPositionenMengenberechnungID.SetParentComponent(dsPositionen);
    dsPositionenMengenberechnungID.Name := 'dsPositionenMengenberechnungID';
    dsPositionenMengenberechnungID.FieldName := 'MengenberechnungID';
    dsPositionenMengenberechnungID.Size := 38;
    dsPositionenTaraID.SetParentComponent(dsPositionen);
    dsPositionenTaraID.Name := 'dsPositionenTaraID';
    dsPositionenTaraID.FieldName := 'TaraID';
    dsPositionenTaraID.Size := 38;
    dsPositionenTara.SetParentComponent(dsPositionen);
    dsPositionenTara.Name := 'dsPositionenTara';
    dsPositionenTara.FieldName := 'Tara';
    dsPositionenBruttoGewicht.SetParentComponent(dsPositionen);
    dsPositionenBruttoGewicht.Name := 'dsPositionenBruttoGewicht';
    dsPositionenBruttoGewicht.FieldName := 'BruttoGewicht';
    dsPositionenAussenflaeche.SetParentComponent(dsPositionen);
    dsPositionenAussenflaeche.Name := 'dsPositionenAussenflaeche';
    dsPositionenAussenflaeche.FieldName := 'Aussenflaeche';
    dsPositionenLadeort.SetParentComponent(dsPositionen);
    dsPositionenLadeort.Name := 'dsPositionenLadeort';
    dsPositionenLadeort.FieldName := 'Ladeort';
    dsPositionenLadeort.Size := 50;
    dsPositionenSeitenumbruch.SetParentComponent(dsPositionen);
    dsPositionenSeitenumbruch.Name := 'dsPositionenSeitenumbruch';
    dsPositionenSeitenumbruch.FieldName := 'Seitenumbruch';
    dsPositionenPlanerFarbe.SetParentComponent(dsPositionen);
    dsPositionenPlanerFarbe.Name := 'dsPositionenPlanerFarbe';
    dsPositionenPlanerFarbe.FieldName := 'PlanerFarbe';
    dsPositionenMWStKey.SetParentComponent(dsPositionen);
    dsPositionenMWStKey.Name := 'dsPositionenMWStKey';
    dsPositionenMWStKey.FieldName := 'MWStKey';
    dsPositionenZSnachPos.SetParentComponent(dsPositionen);
    dsPositionenZSnachPos.Name := 'dsPositionenZSnachPos';
    dsPositionenZSnachPos.FieldName := 'ZSnachPos';
    dsPositionenLieferzeit.SetParentComponent(dsPositionen);
    dsPositionenLieferzeit.Name := 'dsPositionenLieferzeit';
    dsPositionenLieferzeit.FieldName := 'Lieferzeit';
    dsPositionenLieferzeit.Size := 50;
    dsPositionenPreisVorRabattWHG.SetParentComponent(dsPositionen);
    dsPositionenPreisVorRabattWHG.Name := 'dsPositionenPreisVorRabattWHG';
    dsPositionenPreisVorRabattWHG.FieldName := 'PreisVorRabattWHG';
    dsPositionenReferenzKalkulation.SetParentComponent(dsPositionen);
    dsPositionenReferenzKalkulation.Name := 'dsPositionenReferenzKalkulation';
    dsPositionenReferenzKalkulation.FieldName := 'ReferenzKalkulation';
    dsPositionenReferenzKalkulation.Size := 10;
    dsPositionenPKey.SetParentComponent(dsPositionen);
    dsPositionenPKey.Name := 'dsPositionenPKey';
    dsPositionenPKey.FieldName := 'PKey';
    dsPositionenAuftragsnummer.SetParentComponent(dsPositionen);
    dsPositionenAuftragsnummer.Name := 'dsPositionenAuftragsnummer';
    dsPositionenAuftragsnummer.FieldName := 'Auftragsnummer';
    dsPositionenlwbID.SetParentComponent(dsPositionen);
    dsPositionenlwbID.Name := 'dsPositionenlwbID';
    dsPositionenlwbID.FieldName := 'lwbID';
    dsPositionenlwbID.Size := 38;
    dsPositionenBelegdatum.SetParentComponent(dsPositionen);
    dsPositionenBelegdatum.Name := 'dsPositionenBelegdatum';
    dsPositionenBelegdatum.FieldName := 'Belegdatum';
    dsPositionenWarenkontrollnummern.SetParentComponent(dsPositionen);
    dsPositionenWarenkontrollnummern.Name := 'dsPositionenWarenkontrollnummern';
    dsPositionenWarenkontrollnummern.FieldName := 'Warenkontrollnummern';
    dsPositionenWarenkontrollnummern.Size := 255;
    dsPositionenVolumen.SetParentComponent(dsPositionen);
    dsPositionenVolumen.Name := 'dsPositionenVolumen';
    dsPositionenVolumen.FieldName := 'Volumen';
    dsPositionenPosVolumen.SetParentComponent(dsPositionen);
    dsPositionenPosVolumen.Name := 'dsPositionenPosVolumen';
    dsPositionenPosVolumen.FieldName := 'PosVolumen';
    dsPositionenArtikelAttribute.SetParentComponent(dsPositionen);
    dsPositionenArtikelAttribute.Name := 'dsPositionenArtikelAttribute';
    dsPositionenArtikelAttribute.FieldName := 'ArtikelAttribute';
    dsPositionenArtikelAttribute.Size := 255;
    dsPositionenMonteurP.SetParentComponent(dsPositionen);
    dsPositionenMonteurP.Name := 'dsPositionenMonteurP';
    dsPositionenMonteurP.FieldName := 'MonteurP';
    dsPositionenArbeitszeitDatum.SetParentComponent(dsPositionen);
    dsPositionenArbeitszeitDatum.Name := 'dsPositionenArbeitszeitDatum';
    dsPositionenArbeitszeitDatum.FieldName := 'ArbeitszeitDatum';
    dsPositionenArbeitszeitVON.SetParentComponent(dsPositionen);
    dsPositionenArbeitszeitVON.Name := 'dsPositionenArbeitszeitVON';
    dsPositionenArbeitszeitVON.FieldName := 'ArbeitszeitVON';
    dsPositionenArbeitszeitVON.DisplayFormat := 'hh:mm';
    dsPositionenArbeitszeitBIS.SetParentComponent(dsPositionen);
    dsPositionenArbeitszeitBIS.Name := 'dsPositionenArbeitszeitBIS';
    dsPositionenArbeitszeitBIS.FieldName := 'ArbeitszeitBIS';
    dsPositionenArbeitszeitBIS.DisplayFormat := 'hh:mm';
    dsPositionenArtikelnummerArtikelnummer.SetParentComponent(dsPositionen);
    dsPositionenArtikelnummerArtikelnummer.Name := 'dsPositionenArtikelnummerArtikelnummer';
    dsPositionenArtikelnummerArtikelnummer.FieldName := 'Artikelnummer.Artikelnummer';
    dsPositionenArtikelnummerArtikelnummer.Size := 25;
    dsPositionenArtikelnummerArbeitszeit.SetParentComponent(dsPositionen);
    dsPositionenArtikelnummerArbeitszeit.Name := 'dsPositionenArtikelnummerArbeitszeit';
    dsPositionenArtikelnummerArbeitszeit.FieldName := 'Artikelnummer.Arbeitszeit';
    sourcePositionen.SetParentComponent(Self);
    sourcePositionen.Name := 'sourcePositionen';
    sourcePositionen.DataSet := dsPositionen;
    sourcePositionen.Left := 136;
    sourcePositionen.Top := 128;
    dsUnterschriften.SetParentComponent(Self);
    dsUnterschriften.Name := 'dsUnterschriften';
    dsUnterschriften.AfterApplyUpdates := dsUnterschriftenAfterApplyUpdates;
    dsUnterschriften.AfterOpen := dsUnterschriftenAfterOpen;
    dsUnterschriften.AfterPost := dsUnterschriftenAfterPost;
    dsUnterschriften.EntitySetName := 'AuftragUnterschriften';
    dsUnterschriften.Connection := DM2.XDataWebConnection1;
    dsUnterschriften.Left := 80;
    dsUnterschriften.Top := 176;
    dsUnterschriftenNummerkomplett.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNummerkomplett.Name := 'dsUnterschriftenNummerkomplett';
    dsUnterschriftenNummerkomplett.FieldName := 'Nummerkomplett';
    dsUnterschriftenNummerkomplett.Required := True;
    dsUnterschriftenNummerkomplett.Size := 10;
    dsUnterschriftenDatum.SetParentComponent(dsUnterschriften);
    dsUnterschriftenDatum.Name := 'dsUnterschriftenDatum';
    dsUnterschriftenDatum.FieldName := 'Datum';
    dsUnterschriftenUnterschriftMonteur.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftMonteur.Name := 'dsUnterschriftenUnterschriftMonteur';
    dsUnterschriftenUnterschriftMonteur.FieldName := 'UnterschriftMonteur';
    dsUnterschriftenUnterschriftKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKunde.Name := 'dsUnterschriftenUnterschriftKunde';
    dsUnterschriftenUnterschriftKunde.FieldName := 'UnterschriftKunde';
    dsUnterschriftenUnterschriftMonteurText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftMonteurText.Name := 'dsUnterschriftenUnterschriftMonteurText';
    dsUnterschriftenUnterschriftMonteurText.FieldName := 'UnterschriftMonteurText';
    dsUnterschriftenUnterschriftMonteurText.Size := 255;
    dsUnterschriftenUnterschriftKundeText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKundeText.Name := 'dsUnterschriftenUnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.FieldName := 'UnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.Size := 255;
    sourceUnterschriften.SetParentComponent(Self);
    sourceUnterschriften.Name := 'sourceUnterschriften';
    sourceUnterschriften.DataSet := dsUnterschriften;
    sourceUnterschriften.Left := 16;
    sourceUnterschriften.Top := 180;
    dsSumBeleg.SetParentComponent(Self);
    dsSumBeleg.Name := 'dsSumBeleg';
    dsSumBeleg.AfterOpen := dsSumBelegAfterOpen;
    dsSumBeleg.EntitySetName := 'PositionenAuftrag';
    dsSumBeleg.Connection := DM2.XDataWebConnection1;
    dsSumBeleg.Left := 136;
    dsSumBeleg.Top := 176;
    dsSumBelegPosVolumen.SetParentComponent(dsSumBeleg);
    dsSumBelegPosVolumen.Name := 'dsSumBelegPosVolumen';
    dsSumBelegPosVolumen.FieldName := 'PosVolumen';
    dsSumBelegBruttoGewicht.SetParentComponent(dsSumBeleg);
    dsSumBelegBruttoGewicht.Name := 'dsSumBelegBruttoGewicht';
    dsSumBelegBruttoGewicht.FieldName := 'BruttoGewicht';
    dsSumBelegMehrwertsteuersatz.SetParentComponent(dsSumBeleg);
    dsSumBelegMehrwertsteuersatz.Name := 'dsSumBelegMehrwertsteuersatz';
    dsSumBelegMehrwertsteuersatz.FieldName := 'Mehrwertsteuersatz';
    dsSumBelegEndpreis.SetParentComponent(dsSumBeleg);
    dsSumBelegEndpreis.Name := 'dsSumBelegEndpreis';
    dsSumBelegEndpreis.FieldName := 'Endpreis';
    dsSumBelegPosMWST.SetParentComponent(dsSumBeleg);
    dsSumBelegPosMWST.Name := 'dsSumBelegPosMWST';
    dsSumBelegPosMWST.FieldName := 'PosMWST';
    dsSumBelegBruttoEndpreis.SetParentComponent(dsSumBeleg);
    dsSumBelegBruttoEndpreis.Name := 'dsSumBelegBruttoEndpreis';
    dsSumBelegBruttoEndpreis.FieldName := 'BruttoEndpreis';
    dsSumBelegEndpreis_EK.SetParentComponent(dsSumBeleg);
    dsSumBelegEndpreis_EK.Name := 'dsSumBelegEndpreis_EK';
    dsSumBelegEndpreis_EK.FieldName := 'Endpreis_EK';
    dsSumBelegPKZ.SetParentComponent(dsSumBeleg);
    dsSumBelegPKZ.Name := 'dsSumBelegPKZ';
    dsSumBelegPKZ.FieldName := 'PKZ';
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 80;
    WClient.Top := 8;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    WebHttpRequest1.Left := 16;
    WebHttpRequest1.Top := 65;
    dsObjekt.SetParentComponent(Self);
    dsObjekt.Name := 'dsObjekt';
    dsObjekt.AfterOpen := dsObjektAfterOpen;
    dsObjekt.EntitySetName := 'Objekte';
    dsObjekt.Connection := DM2.XDataWebConnection1;
    dsObjekt.Left := 16;
    dsObjekt.Top := 7;
    dsObjektObjektReferenz.SetParentComponent(dsObjekt);
    dsObjektObjektReferenz.Name := 'dsObjektObjektReferenz';
    dsObjektObjektReferenz.FieldName := 'ObjektReferenz';
    dsObjektObjektReferenz.Required := True;
    dsObjektObjektReferenz.Size := 13;
    dsObjektKurzbezeichnung.SetParentComponent(dsObjekt);
    dsObjektKurzbezeichnung.Name := 'dsObjektKurzbezeichnung';
    dsObjektKurzbezeichnung.FieldName := 'Kurzbezeichnung';
    dsObjektKurzbezeichnung.Size := 25;
    dsObjektObjektnummer.SetParentComponent(dsObjekt);
    dsObjektObjektnummer.Name := 'dsObjektObjektnummer';
    dsObjektObjektnummer.FieldName := 'Objektnummer';
    dsObjektObjektnummer.Size := 25;
  finally
    TC.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    btnUnterschreiben.AfterLoadDFMValues;
    btnPDF.AfterLoadDFMValues;
    btnTermine.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    PTC.AfterLoadDFMValues;
    btnSpeichern.AfterLoadDFMValues;
    pickLieferdatum.AfterLoadDFMValues;
    edtAuftragsbezeichnung.AfterLoadDFMValues;
    edtLiefertermin.AfterLoadDFMValues;
    btnNeuePosition.AfterLoadDFMValues;
    Container4.AfterLoadDFMValues;
    webDBComboAuftragStatus.AfterLoadDFMValues;
    belegComboMonteur.AfterLoadDFMValues;
    belegMemoServiceBemerkung.AfterLoadDFMValues;
    dsBeleg.AfterLoadDFMValues;
    dsBelegKundennummer.AfterLoadDFMValues;
    dsBelegNummerkomplett.AfterLoadDFMValues;
    dsBelegReferenzAngebot.AfterLoadDFMValues;
    dsBelegReferenzLieferschein.AfterLoadDFMValues;
    dsBelegReferenzRechnung.AfterLoadDFMValues;
    dsBelegDatum.AfterLoadDFMValues;
    dsBelegJahr.AfterLoadDFMValues;
    dsBelegKalenderwoche.AfterLoadDFMValues;
    dsBelegNachname.AfterLoadDFMValues;
    dsBelegVorname.AfterLoadDFMValues;
    dsBelegPostleitzahl.AfterLoadDFMValues;
    dsBelegOrt.AfterLoadDFMValues;
    dsBelegLaenderkuerzel.AfterLoadDFMValues;
    dsBelegLand.AfterLoadDFMValues;
    dsBelegBriefanrede1.AfterLoadDFMValues;
    dsBelegBriefanrede2.AfterLoadDFMValues;
    dsBelegEMail.AfterLoadDFMValues;
    dsBelegAdresseBild.AfterLoadDFMValues;
    dsBelegLieferanschriftBild.AfterLoadDFMValues;
    dsBelegZahlungsbedingung.AfterLoadDFMValues;
    dsBelegZahlungsbedingungText.AfterLoadDFMValues;
    dsBelegAuftragsbezeichnung.AfterLoadDFMValues;
    dsBelegAuftragnummerKunde.AfterLoadDFMValues;
    dsBelegBestelldatumKunde.AfterLoadDFMValues;
    dsBelegILN.AfterLoadDFMValues;
    dsBelegKopftext.AfterLoadDFMValues;
    dsBelegSchlussbemerkung.AfterLoadDFMValues;
    dsBelegGruss.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz1.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz2.AfterLoadDFMValues;
    dsBelegNettosumme01.AfterLoadDFMValues;
    dsBelegNettosumme02.AfterLoadDFMValues;
    dsBelegNettosumme.AfterLoadDFMValues;
    dsBelegMehrwertsteuer01.AfterLoadDFMValues;
    dsBelegMehrwertsteuer02.AfterLoadDFMValues;
    dsBelegMehrwertsteuer.AfterLoadDFMValues;
    dsBelegBruttosumme.AfterLoadDFMValues;
    dsBelegVersanddatum.AfterLoadDFMValues;
    dsBelegSkontosatz.AfterLoadDFMValues;
    dsBelegNettosummeEK.AfterLoadDFMValues;
    dsBelegNettoMarge.AfterLoadDFMValues;
    dsBelegSchreibzeichen.AfterLoadDFMValues;
    dsBelegUnterzeichner.AfterLoadDFMValues;
    dsBelegTelefonMA.AfterLoadDFMValues;
    dsBelegEmailMA.AfterLoadDFMValues;
    dsBelegUSTID.AfterLoadDFMValues;
    dsBelegUmsatzsteuerausweis.AfterLoadDFMValues;
    dsBeleggebucht.AfterLoadDFMValues;
    dsBelegLieferdatum.AfterLoadDFMValues;
    dsBelegTransportbedingung.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz3.AfterLoadDFMValues;
    dsBelegMehrwertsteuer03.AfterLoadDFMValues;
    dsBelegNettosumme03.AfterLoadDFMValues;
    dsBelegRundungsDifferenz.AfterLoadDFMValues;
    dsBelegEmailText.AfterLoadDFMValues;
    dsBelegAuftragerledigt.AfterLoadDFMValues;
    dsBelegRabattstufe.AfterLoadDFMValues;
    dsBelegKFZKennzeichen.AfterLoadDFMValues;
    dsBelegFahrgestellnummer.AfterLoadDFMValues;
    dsBelegKFZHersteller.AfterLoadDFMValues;
    dsBelegErstzulassungsdatum.AfterLoadDFMValues;
    dsBelegKMStand.AfterLoadDFMValues;
    dsBelegKFZType.AfterLoadDFMValues;
    dsBelegInspektionsdatum.AfterLoadDFMValues;
    dsBelegDebitorennummer.AfterLoadDFMValues;
    dsBelegKostentraeger.AfterLoadDFMValues;
    dsBelegRabattsatz.AfterLoadDFMValues;
    dsBelegObjektReferenz.AfterLoadDFMValues;
    dsBelegBank.AfterLoadDFMValues;
    dsBelegBankleitzahl.AfterLoadDFMValues;
    dsBelegKontonummer.AfterLoadDFMValues;
    dsBelegKontoinhaber.AfterLoadDFMValues;
    dsBelegTelefaxnummer.AfterLoadDFMValues;
    dsBelegDatumNeu.AfterLoadDFMValues;
    dsBelegDatumgeaendert.AfterLoadDFMValues;
    dsBelegObjektnummer.AfterLoadDFMValues;
    dsBelegLiefertermin.AfterLoadDFMValues;
    dsBelegEmailversand.AfterLoadDFMValues;
    dsBelegTelefonnummer.AfterLoadDFMValues;
    dsBelegMobilTelefon.AfterLoadDFMValues;
    dsBelegAuftragFax.AfterLoadDFMValues;
    dsBelegREDatum.AfterLoadDFMValues;
    dsBelegBenutzerNeu.AfterLoadDFMValues;
    dsBelegBenutzerGeaendert.AfterLoadDFMValues;
    dsBelegBerechnungBrutto.AfterLoadDFMValues;
    dsBelegBruttosumme01.AfterLoadDFMValues;
    dsBelegBruttosumme02.AfterLoadDFMValues;
    dsBelegBruttosumme03.AfterLoadDFMValues;
    dsBelegNotiz.AfterLoadDFMValues;
    dsBelegMargeProzent.AfterLoadDFMValues;
    dsBelegWarenkreditgesperrt.AfterLoadDFMValues;
    dsBelegBelegsprache.AfterLoadDFMValues;
    dsBelegDuplicate.AfterLoadDFMValues;
    dsBelegperEmail.AfterLoadDFMValues;
    dsBelegAnsprechpartner.AfterLoadDFMValues;
    dsBelegKundennummerFremd.AfterLoadDFMValues;
    dsBelegProtokoll.AfterLoadDFMValues;
    dsBelegVerleihauftrag.AfterLoadDFMValues;
    dsBelegVerleihVon.AfterLoadDFMValues;
    dsBelegVerleihBis.AfterLoadDFMValues;
    dsBelegAuftragGenehmigt.AfterLoadDFMValues;
    dsBeleggesperrt.AfterLoadDFMValues;
    dsBelegVerleihvonText.AfterLoadDFMValues;
    dsBelegVerleihbisText.AfterLoadDFMValues;
    dsBelegDruckWHG.AfterLoadDFMValues;
    dsBelegDruckWHGKurs.AfterLoadDFMValues;
    dsBelegProzentsatzZusatzposition.AfterLoadDFMValues;
    dsBelegTextZusatzposition.AfterLoadDFMValues;
    dsBelegTextzusatzBeschreibung.AfterLoadDFMValues;
    dsBelegLayoutname.AfterLoadDFMValues;
    dsBelegGesamtgewicht.AfterLoadDFMValues;
    dsBelegBetragZusatzposition.AfterLoadDFMValues;
    dsBelegReferenzBestellung.AfterLoadDFMValues;
    dsBelegTermin1.AfterLoadDFMValues;
    dsBelegTermin2.AfterLoadDFMValues;
    dsBelegZielkontaktnummer.AfterLoadDFMValues;
    dsBelegZielkontaktname.AfterLoadDFMValues;
    dsBelegBilddatei1.AfterLoadDFMValues;
    dsBelegBilddatei2.AfterLoadDFMValues;
    dsBelegZielkontaktnummer2.AfterLoadDFMValues;
    dsBelegZielkontaktname2.AfterLoadDFMValues;
    dsBelegbestaetigt.AfterLoadDFMValues;
    dsBelegZielkontaktnummer3.AfterLoadDFMValues;
    dsBelegZielkontaktname3.AfterLoadDFMValues;
    dsBelegTermin3.AfterLoadDFMValues;
    dsBelegTermin4.AfterLoadDFMValues;
    dsBelegMuster.AfterLoadDFMValues;
    dsBelegKW.AfterLoadDFMValues;
    dsBelegDatenlink.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz4.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz5.AfterLoadDFMValues;
    dsBelegMehrwertsteuersatz6.AfterLoadDFMValues;
    dsBelegBesteuerungKey.AfterLoadDFMValues;
    dsBelegVertreterMatchcode.AfterLoadDFMValues;
    dsBelegVerfasser.AfterLoadDFMValues;
    dsBelegLieferadresseID.AfterLoadDFMValues;
    dsBelegAuftragStatus.AfterLoadDFMValues;
    dsBelegMonatJahr.AfterLoadDFMValues;
    dsBelegBelegvariante.AfterLoadDFMValues;
    dsBelegProduktionsfreigabe.AfterLoadDFMValues;
    dsBelegReferenzRahmenAuftrag.AfterLoadDFMValues;
    dsBelegDatumRahmenAuftrag.AfterLoadDFMValues;
    dsBelegFahrzeugID.AfterLoadDFMValues;
    dsBelegAuslieferungKW.AfterLoadDFMValues;
    dsBelegBereitstellungDatum.AfterLoadDFMValues;
    dsBelegVTUID.AfterLoadDFMValues;
    dsBelegLieferanschriftDaten.AfterLoadDFMValues;
    dsBelegGesamtVolumen.AfterLoadDFMValues;
    dsBelegBelegPKey.AfterLoadDFMValues;
    dsBelegDruckParameter.AfterLoadDFMValues;
    dsBelegReferenzService.AfterLoadDFMValues;
    dsBelegZusatzDaten.AfterLoadDFMValues;
    dsBelegStandPDF.AfterLoadDFMValues;
    dsBelegPaketdienst.AfterLoadDFMValues;
    dsBelegMonteur.AfterLoadDFMValues;
    dsBelegKundennummerKundennummer.AfterLoadDFMValues;
    dsBelegServiceBemerkung.AfterLoadDFMValues;
    SourceBeleg.AfterLoadDFMValues;
    dsPositionen.AfterLoadDFMValues;
    dsPositionenArtikelnummer.AfterLoadDFMValues;
    dsPositionenNummerkomplett.AfterLoadDFMValues;
    dsPositionenID.AfterLoadDFMValues;
    dsPositionenPosNr.AfterLoadDFMValues;
    dsPositionenPosUNr.AfterLoadDFMValues;
    dsPositionenSumme.AfterLoadDFMValues;
    dsPositionenArtikelueberschrift.AfterLoadDFMValues;
    dsPositionenArtikelbeschreibung.AfterLoadDFMValues;
    dsPositionenMenge.AfterLoadDFMValues;
    dsPositionenArtderMenge.AfterLoadDFMValues;
    dsPositionenVerkaufspreis.AfterLoadDFMValues;
    dsPositionenEinkaufspreis.AfterLoadDFMValues;
    dsPositionenSeriennummer.AfterLoadDFMValues;
    dsPositionenWarengruppe.AfterLoadDFMValues;
    dsPositionenWarenuntergruppe.AfterLoadDFMValues;
    dsPositionenArtikelnummerKunde.AfterLoadDFMValues;
    dsPositionenLieferant.AfterLoadDFMValues;
    dsPositionenGewicht.AfterLoadDFMValues;
    dsPositionenLaenge.AfterLoadDFMValues;
    dsPositionenBreite.AfterLoadDFMValues;
    dsPositionenHoehe.AfterLoadDFMValues;
    dsPositionenMehrwertsteuersatz.AfterLoadDFMValues;
    dsPositionenEndpreis.AfterLoadDFMValues;
    dsPositionenPosMWST.AfterLoadDFMValues;
    dsPositionenBruttoEndpreis.AfterLoadDFMValues;
    dsPositionenEndpreis_EK.AfterLoadDFMValues;
    dsPositionenRabattsatz.AfterLoadDFMValues;
    dsPositionenPreisvorRabatt.AfterLoadDFMValues;
    dsPositionenPKZ.AfterLoadDFMValues;
    dsPositionenTextfrei10.AfterLoadDFMValues;
    dsPositionenTextfrei20.AfterLoadDFMValues;
    dsPositionenProduktionAbgeschlossen.AfterLoadDFMValues;
    dsPositionenEinzelpreisBrutto.AfterLoadDFMValues;
    dsPositionenPosMarge.AfterLoadDFMValues;
    dsPositionenPosMargeProzent.AfterLoadDFMValues;
    dsPositionenBereitsGeliefert.AfterLoadDFMValues;
    dsPositionenausliefern.AfterLoadDFMValues;
    dsPositionenBestellungErledigt.AfterLoadDFMValues;
    dsPositionenLager.AfterLoadDFMValues;
    dsPositionenbestellen.AfterLoadDFMValues;
    dsPositionenWarenbestand.AfterLoadDFMValues;
    dsPositionenArtikelbeschreibungRTF.AfterLoadDFMValues;
    dsPositionenQuellartikel.AfterLoadDFMValues;
    dsPositionenArtikelnummerWB.AfterLoadDFMValues;
    dsPositionenPreisanteilNetto.AfterLoadDFMValues;
    dsPositionenSetHPos.AfterLoadDFMValues;
    dsPositionenSetUPos.AfterLoadDFMValues;
    dsPositionenProvisionssatz.AfterLoadDFMValues;
    dsPositionenProvisionsbetrag.AfterLoadDFMValues;
    dsPositionenArtikelsprache.AfterLoadDFMValues;
    dsPositionenEKvom.AfterLoadDFMValues;
    dsPositionenMengenberechnungID.AfterLoadDFMValues;
    dsPositionenTaraID.AfterLoadDFMValues;
    dsPositionenTara.AfterLoadDFMValues;
    dsPositionenBruttoGewicht.AfterLoadDFMValues;
    dsPositionenAussenflaeche.AfterLoadDFMValues;
    dsPositionenLadeort.AfterLoadDFMValues;
    dsPositionenSeitenumbruch.AfterLoadDFMValues;
    dsPositionenPlanerFarbe.AfterLoadDFMValues;
    dsPositionenMWStKey.AfterLoadDFMValues;
    dsPositionenZSnachPos.AfterLoadDFMValues;
    dsPositionenLieferzeit.AfterLoadDFMValues;
    dsPositionenPreisVorRabattWHG.AfterLoadDFMValues;
    dsPositionenReferenzKalkulation.AfterLoadDFMValues;
    dsPositionenPKey.AfterLoadDFMValues;
    dsPositionenAuftragsnummer.AfterLoadDFMValues;
    dsPositionenlwbID.AfterLoadDFMValues;
    dsPositionenBelegdatum.AfterLoadDFMValues;
    dsPositionenWarenkontrollnummern.AfterLoadDFMValues;
    dsPositionenVolumen.AfterLoadDFMValues;
    dsPositionenPosVolumen.AfterLoadDFMValues;
    dsPositionenArtikelAttribute.AfterLoadDFMValues;
    dsPositionenMonteurP.AfterLoadDFMValues;
    dsPositionenArbeitszeitDatum.AfterLoadDFMValues;
    dsPositionenArbeitszeitVON.AfterLoadDFMValues;
    dsPositionenArbeitszeitBIS.AfterLoadDFMValues;
    dsPositionenArtikelnummerArtikelnummer.AfterLoadDFMValues;
    dsPositionenArtikelnummerArbeitszeit.AfterLoadDFMValues;
    sourcePositionen.AfterLoadDFMValues;
    dsUnterschriften.AfterLoadDFMValues;
    dsUnterschriftenNummerkomplett.AfterLoadDFMValues;
    dsUnterschriftenDatum.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftMonteur.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKunde.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftMonteurText.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKundeText.AfterLoadDFMValues;
    sourceUnterschriften.AfterLoadDFMValues;
    dsSumBeleg.AfterLoadDFMValues;
    dsSumBelegPosVolumen.AfterLoadDFMValues;
    dsSumBelegBruttoGewicht.AfterLoadDFMValues;
    dsSumBelegMehrwertsteuersatz.AfterLoadDFMValues;
    dsSumBelegEndpreis.AfterLoadDFMValues;
    dsSumBelegPosMWST.AfterLoadDFMValues;
    dsSumBelegBruttoEndpreis.AfterLoadDFMValues;
    dsSumBelegEndpreis_EK.AfterLoadDFMValues;
    dsSumBelegPKZ.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    dsObjekt.AfterLoadDFMValues;
    dsObjektObjektReferenz.AfterLoadDFMValues;
    dsObjektKurzbezeichnung.AfterLoadDFMValues;
    dsObjektObjektnummer.AfterLoadDFMValues;
  end;
end;

end.
