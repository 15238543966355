unit unit_EinzelLieferschein;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, XData.Web.Client, WEBLib.Buttons, DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Grids, strutils, WEBLib.DB;

type
  TEinzelLieferschein = class(TForm)
    xdsBeleg: TXDataWebDataSet;
    xdsPositionen: TXDataWebDataSet;
    WClient: TXDataWebClient;
    TC: TTableControl;
    PTC: TTableControl;
    dsUnterschriften: TXDataWebDataSet;
    dsUnterschriftenNummerkomplett: TStringField;
    dsUnterschriftenDatum: TDateTimeField;
    dsUnterschriftenUnterschriftKunde: TBlobField;
    dsUnterschriftenUnterschriftKundeText: TStringField;
    sourceUnterschriften: TDataSource;
    Container1: THTMLContainer;
    Container2: THTMLContainer;
    btnzurueck: TButton;
    btnUnterschreiben: TButton;
    btnPDF: TButton;
    Container3: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure btnPDFClick(Sender: TObject);
    procedure xdsBelegAfterOpen(DataSet: TDataSet);
    procedure xdsPositionenAfterOpen(DataSet: TDataSet);
    procedure btnUnterschreibenClick(Sender: TObject);
    procedure dsUnterschriftenAfterOpen(DataSet: TDataSet);
    procedure btnzurueckClick(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    MerkerLieferschein:string;
    imgUnterschriftKunde:TImageControl;
  end;

var
  EinzelLieferschein: TEinzelLieferschein;

implementation
  uses unit1, unit2, unit_LieferscheinUnterschrift;

procedure TEinzelLieferschein.btnPDFClick(Sender: TObject);
  procedure OnResponse(Response:TXDataClientResponse);
  var
    mydownload:string;
  begin
    mydownload:=string(TJSObject(response.Result)['value']);
    if mydownload='WRONG' then
    begin
      showmessage('Download kann nicht bereitgestellt werden');
    end
    else
    begin
      application.Navigate(downloadconnection+mydownload,ntBlank);
    end;
  end;
begin
  if LizenzOK then
  begin
    WClient.RawInvoke('IServerService.WarenempfangPDF',[MerkerLieferschein],@OnResponse);
  end
  else
  begin
    showmessage('Für das Abrufen von Dateien sind Sie noch nicht freigeschaltet, bitte erwerben Sie eine Lizenz');
  end;

end;

procedure TEinzelLieferschein.btnUnterschreibenClick(Sender: TObject);
var
  LFrm:TLieferscheinUnterschrift;
    procedure AfterCreate(AForm:TObject);
    var
      LForm:TLieferscheinUnterschrift;
    begin
      LForm:=(AForm as TLieferscheinUnterschrift);
      lform.UMerkerLieferschein:=MerkerLieferschein;
    end;

    procedure AfterShowmodal(Avalue:TModalResult);
    begin
      dsUnterschriften.Close;
      xdsPositionen.Close;
      xdsBeleg.Close;
      xdsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerLieferschein);
      xdsBeleg.Load;
    end;

  begin

  LFrm:=TLieferscheinUnterschrift.CreateNew(@AfterCreate);
  //LFrm.Popup:=true;
  LFrm.ShowModal(@AfterShowmodal);

end;

procedure TEinzelLieferschein.btnzurueckClick(Sender: TObject);
begin
  self.Close;
end;

procedure TEinzelLieferschein.dsUnterschriftenAfterOpen(DataSet: TDataSet);
 var
  myelement:TJSHTMLElement;
begin
  if dsunterschriften.RecordCount=1 then
  begin
    try
      TC.Cells[0,7]:='Unterschrift Kunde';
      TC.Cells[0,8]:='Unterschrift vom';


      myelement:=tc.CellElements[1,7];
      imgUnterschriftKunde:=TImageControl.Create(self);
      imgUnterschriftKunde.height:=100;
      imgUnterschriftKunde.Width:=200;
      imgunterschriftKunde.url:=dsUnterschriftenUnterschriftKundeText.asstring;
      myelement.innerHTML:='';
      imgUnterschriftKunde.ParentElement:=myelement;
      TC.Cells[1,8]:=formatdatetime('DD.MM.YYYY hh:mm:ss', dsUnterschriftenDatum.Asdatetime);

      btnUnterschreiben.Visible:=false;
    except
    end;
  end;
  xdspositionen.Close;
  xdsPositionen.QueryString:='$expand=Artikelnummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerLieferschein)+'&$orderby=PosNr';
  xdsPositionen.Load;
end;

procedure TEinzelLieferschein.WebFormShow(Sender: TObject);
var
  myelement:TJSHTMLElement;
begin
  Container1.html.text:='<nav class="navbar navbar-light m-1 rounded" style="background-color: #d0eff2;"><h5>Einzel-Lieferschein</h5></nav>';
  Container2.HTML.Text:='';
  myelement:=container2.ElementHandle;
  btnzurueck.ParentElement:=myelement;
  btnUnterschreiben.ParentElement:=myelement;
  btnPDF.ParentElement:=myelement;
  myelement:=container3.elementhandle;
  TC.ParentElement:=myelement;
  PTC.ParentElement:=myelement;
  xdsBeleg.QueryString:='$expand=Kundennummer&$filter=Nummerkomplett eq '+QuotedStr(MerkerLieferschein);
  xdsBeleg.Load;
end;

procedure TEinzelLieferschein.xdsBelegAfterOpen(DataSet: TDataSet);
begin
  TC.RowCount:=9;
  TC.Cells[0,0]:='Bezeichnung';
  TC.Cells[1,0]:='Wert';
  TC.Cells[0,1]:='Lieferschein | Datum';
  TC.Cells[1,1]:='<h5>'+xdsbeleg.FieldByName('Nummerkomplett').asstring +' | '+formatdatetime('DD.MM.YYYY', xdsbeleg.FieldByName('Datum').asdatetime);
  TC.Cells[0,2]:='Kontaktnummer';
  TC.Cells[1,2]:='<h6>'+xdsBeleg.FieldByName('Kundennummer.Kundennummer').asstring;
  TC.Cells[0,3]:='Anschrift';
  TC.Cells[1,3]:='<h6>'+ReplaceStr(xdsbeleg.FieldByName('Adressebild').asstring,#13+#10,'<BR>');
  TC.Cells[0,4]:='Auftragsbezeichnung';
  TC.Cells[1,4]:='<h6>'+xdsBeleg.FieldByName('Auftragsbezeichnung').asstring;
  TC.Cells[0,5]:='Objektnummer';
  TC.Cells[1,5]:='<h6>'+xdsBeleg.FieldByName('Objektnummer').asstring;
  TC.Cells[0,6]:='Lieferanschrift';
  try
  TC.Cells[1,6]:='<h6>'+ReplaceStr(xdsbeleg.FieldByName('Lieferanschriftbild').asstring,#13+#10,'<BR>');
  except
  end;
  //TC.Cells[0,7]:='Nettosumme';
  //TC.Cells[1,7]:=formatfloat('#,##0.00',xdsbeleg.fieldbyname('Nettosumme').asfloat);
  dsUnterschriften.QueryString:='$filter=Nummerkomplett eq '+QuotedStr(MerkerLieferschein);
  dsUnterschriften.Load;

end;

procedure TEinzelLieferschein.xdsPositionenAfterOpen(DataSet: TDataSet);
var
  I: Integer;
begin
  PTC.RowCount:=xdsPositionen.RecordCount+1;
  PTC.ColCount:=3;
  PTC.Cells[0,0]:='Pos';
  PTC.Cells[1,0]:='Artikel';
  PTC.Cells[2,0]:='Menge';
  //PTC.Cells[6,0]:='Einzel-VKP';
  //PTC.Cells[7,0]:='Endpreis';

  xdspositionen.First;
  for I := 1 to xdspositionen.RecordCount do
  begin
    PTC.Cells[0,i]:=xdspositionen.FieldByName('PosNr').AsString+'.'+xdspositionen.FieldByName('PosUNr').AsString;
    PTC.Cells[1,i]:=xdspositionen.FieldByName('Artikelnummer.Artikelnummer').AsString+'<br><b>'+xdspositionen.FieldByName('Artikelueberschrift').AsString+'</b><br>'+xdspositionen.FieldByName('Artikelbeschreibung').AsString;
    PTC.Cells[2,i]:='<p class="text-right">'+xdspositionen.FieldByName('Menge').AsString;
    //if not einkauf then PTC.Cells[6,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Verkaufspreis').asfloat);
    //if not einkauf then PTC.Cells[7,i]:='<p class="text-right">'+formatfloat('#,##0.00',xdsPositionen.fieldbyname('Endpreis').asfloat);
    xdspositionen.Next;
  end;

end;

procedure TEinzelLieferschein.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TC := TTableControl.Create(Self);
  PTC := TTableControl.Create(Self);
  Container1 := THTMLContainer.Create(Self);
  Container2 := THTMLContainer.Create(Self);
  btnzurueck := TButton.Create(Self);
  btnUnterschreiben := TButton.Create(Self);
  btnPDF := TButton.Create(Self);
  Container3 := THTMLContainer.Create(Self);
  xdsBeleg := TXDataWebDataSet.Create(Self);
  xdsPositionen := TXDataWebDataSet.Create(Self);
  WClient := TXDataWebClient.Create(Self);
  dsUnterschriften := TXDataWebDataSet.Create(Self);
  dsUnterschriftenNummerkomplett := TStringField.Create(Self);
  dsUnterschriftenDatum := TDateTimeField.Create(Self);
  dsUnterschriftenUnterschriftKunde := TBlobField.Create(Self);
  dsUnterschriftenUnterschriftKundeText := TStringField.Create(Self);
  sourceUnterschriften := TDataSource.Create(Self);

  TC.BeforeLoadDFMValues;
  PTC.BeforeLoadDFMValues;
  Container1.BeforeLoadDFMValues;
  Container2.BeforeLoadDFMValues;
  btnzurueck.BeforeLoadDFMValues;
  btnUnterschreiben.BeforeLoadDFMValues;
  btnPDF.BeforeLoadDFMValues;
  Container3.BeforeLoadDFMValues;
  xdsBeleg.BeforeLoadDFMValues;
  xdsPositionen.BeforeLoadDFMValues;
  WClient.BeforeLoadDFMValues;
  dsUnterschriften.BeforeLoadDFMValues;
  dsUnterschriftenNummerkomplett.BeforeLoadDFMValues;
  dsUnterschriftenDatum.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKunde.BeforeLoadDFMValues;
  dsUnterschriftenUnterschriftKundeText.BeforeLoadDFMValues;
  sourceUnterschriften.BeforeLoadDFMValues;
  try
    Name := 'EinzelLieferschein';
    Width := 223;
    Height := 429;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TC.SetParentComponent(Self);
    TC.Name := 'TC';
    TC.Left := 8;
    TC.Top := 177;
    TC.Width := 200;
    TC.Height := 143;
    TC.ElementClassName := 'table table-hover table-bordered table-striped';
    TC.HeightStyle := ssAuto;
    TC.WidthStyle := ssAuto;
    TC.BorderColor := clSilver;
    TC.ChildOrder := 2;
    TC.ColHeader := False;
    TC.ElementHeaderClassName := 'table-info';
    TC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    TC.ColCount := 2;
    PTC.SetParentComponent(Self);
    PTC.Name := 'PTC';
    PTC.Left := 8;
    PTC.Top := 344;
    PTC.Width := 200;
    PTC.Height := 80;
    PTC.ElementClassName := 'table table-hover table-bordered table-striped';
    PTC.HeightStyle := ssAuto;
    PTC.WidthStyle := ssAuto;
    PTC.BorderColor := clSilver;
    PTC.ChildOrder := 2;
    PTC.ColHeader := False;
    PTC.ElementHeaderClassName := 'table-info';
    PTC.ElementTableClassName := 'table table-hover table-bordered table-striped';
    PTC.ColCount := 2;
    Container1.SetParentComponent(Self);
    Container1.Name := 'Container1';
    Container1.Left := 0;
    Container1.Top := 0;
    Container1.Width := 223;
    Container1.Height := 41;
    Container1.HeightStyle := ssAuto;
    Container1.WidthStyle := ssPercent;
    Container1.Align := alTop;
    Container1.ElementFont := efCSS;
    Container1.ElementPosition := epRelative;
    Container2.SetParentComponent(Self);
    Container2.Name := 'Container2';
    Container2.Left := 0;
    Container2.Top := 41;
    Container2.Width := 223;
    Container2.Height := 41;
    Container2.ElementClassName := 'd-flex flex-wrap m-1';
    Container2.HeightStyle := ssAuto;
    Container2.WidthStyle := ssAuto;
    Container2.Align := alTop;
    Container2.ChildOrder := 1;
    Container2.ElementFont := efCSS;
    Container2.ElementPosition := epRelative;
    btnzurueck.SetParentComponent(Self);
    btnzurueck.Name := 'btnzurueck';
    btnzurueck.Left := 0;
    btnzurueck.Top := 115;
    btnzurueck.Width := 96;
    btnzurueck.Height := 25;
    btnzurueck.Caption := '<< zur'#252'ck';
    btnzurueck.ChildOrder := 4;
    btnzurueck.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnzurueck.ElementFont := efCSS;
    btnzurueck.HeightStyle := ssAuto;
    btnzurueck.WidthStyle := ssAuto;
    SetEvent(btnzurueck, Self, 'OnClick', 'btnzurueckClick');
    btnUnterschreiben.SetParentComponent(Self);
    btnUnterschreiben.Name := 'btnUnterschreiben';
    btnUnterschreiben.Left := 0;
    btnUnterschreiben.Top := 146;
    btnUnterschreiben.Width := 96;
    btnUnterschreiben.Height := 25;
    btnUnterschreiben.Caption := 'unterschreiben';
    btnUnterschreiben.ChildOrder := 4;
    btnUnterschreiben.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnUnterschreiben.ElementFont := efCSS;
    btnUnterschreiben.HeightStyle := ssAuto;
    btnUnterschreiben.WidthStyle := ssAuto;
    SetEvent(btnUnterschreiben, Self, 'OnClick', 'btnUnterschreibenClick');
    btnPDF.SetParentComponent(Self);
    btnPDF.Name := 'btnPDF';
    btnPDF.Left := 102;
    btnPDF.Top := 127;
    btnPDF.Width := 96;
    btnPDF.Height := 25;
    btnPDF.Caption := 'PDF';
    btnPDF.ChildOrder := 4;
    btnPDF.ElementClassName := 'btn btn-info btn-sm m-1 text-left';
    btnPDF.ElementFont := efCSS;
    btnPDF.HeightStyle := ssAuto;
    btnPDF.WidthStyle := ssAuto;
    SetEvent(btnPDF, Self, 'OnClick', 'btnPDFClick');
    Container3.SetParentComponent(Self);
    Container3.Name := 'Container3';
    Container3.Left := 0;
    Container3.Top := 82;
    Container3.Width := 223;
    Container3.Height := 41;
    Container3.ElementClassName := 'd-flex flex-wrap m-1 border border-info';
    Container3.HeightStyle := ssAuto;
    Container3.WidthStyle := ssAuto;
    Container3.Align := alTop;
    Container3.ChildOrder := 2;
    Container3.ElementFont := efCSS;
    Container3.ElementPosition := epRelative;
    xdsBeleg.SetParentComponent(Self);
    xdsBeleg.Name := 'xdsBeleg';
    xdsBeleg.AfterOpen := xdsBelegAfterOpen;
    xdsBeleg.EntitySetName := 'Lieferschein';
    xdsBeleg.Connection := DM2.XDataWebConnection1;
    xdsBeleg.SubpropsDepth := 1;
    xdsBeleg.QueryString := '$expand=Kundennummer';
    xdsBeleg.Left := 20;
    xdsBeleg.Top := 8;
    xdsPositionen.SetParentComponent(Self);
    xdsPositionen.Name := 'xdsPositionen';
    xdsPositionen.AfterOpen := xdsPositionenAfterOpen;
    xdsPositionen.EntitySetName := 'PositionenLieferschein';
    xdsPositionen.Connection := DM2.XDataWebConnection1;
    xdsPositionen.SubpropsDepth := 1;
    xdsPositionen.QueryString := '$expand=Artikelnummer';
    xdsPositionen.Left := 52;
    xdsPositionen.Top := 64;
    WClient.SetParentComponent(Self);
    WClient.Name := 'WClient';
    WClient.Connection := DM2.XDataWebConnection1;
    WClient.Left := 88;
    WClient.Top := 8;
    dsUnterschriften.SetParentComponent(Self);
    dsUnterschriften.Name := 'dsUnterschriften';
    dsUnterschriften.AfterOpen := dsUnterschriftenAfterOpen;
    dsUnterschriften.EntitySetName := 'LieferscheinUnterschriften';
    dsUnterschriften.Connection := DM2.XDataWebConnection1;
    dsUnterschriften.Left := 124;
    dsUnterschriften.Top := 176;
    dsUnterschriftenNummerkomplett.SetParentComponent(dsUnterschriften);
    dsUnterschriftenNummerkomplett.Name := 'dsUnterschriftenNummerkomplett';
    dsUnterschriftenNummerkomplett.FieldName := 'Nummerkomplett';
    dsUnterschriftenNummerkomplett.Required := True;
    dsUnterschriftenNummerkomplett.Size := 10;
    dsUnterschriftenDatum.SetParentComponent(dsUnterschriften);
    dsUnterschriftenDatum.Name := 'dsUnterschriftenDatum';
    dsUnterschriftenDatum.FieldName := 'Datum';
    dsUnterschriftenUnterschriftKunde.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKunde.Name := 'dsUnterschriftenUnterschriftKunde';
    dsUnterschriftenUnterschriftKunde.FieldName := 'UnterschriftKunde';
    dsUnterschriftenUnterschriftKundeText.SetParentComponent(dsUnterschriften);
    dsUnterschriftenUnterschriftKundeText.Name := 'dsUnterschriftenUnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.FieldName := 'UnterschriftKundeText';
    dsUnterschriftenUnterschriftKundeText.Size := 255;
    sourceUnterschriften.SetParentComponent(Self);
    sourceUnterschriften.Name := 'sourceUnterschriften';
    sourceUnterschriften.DataSet := dsUnterschriften;
    sourceUnterschriften.Left := 24;
    sourceUnterschriften.Top := 180;
  finally
    TC.AfterLoadDFMValues;
    PTC.AfterLoadDFMValues;
    Container1.AfterLoadDFMValues;
    Container2.AfterLoadDFMValues;
    btnzurueck.AfterLoadDFMValues;
    btnUnterschreiben.AfterLoadDFMValues;
    btnPDF.AfterLoadDFMValues;
    Container3.AfterLoadDFMValues;
    xdsBeleg.AfterLoadDFMValues;
    xdsPositionen.AfterLoadDFMValues;
    WClient.AfterLoadDFMValues;
    dsUnterschriften.AfterLoadDFMValues;
    dsUnterschriftenNummerkomplett.AfterLoadDFMValues;
    dsUnterschriftenDatum.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKunde.AfterLoadDFMValues;
    dsUnterschriftenUnterschriftKundeText.AfterLoadDFMValues;
    sourceUnterschriften.AfterLoadDFMValues;
  end;
end;

end.