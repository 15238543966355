unit XData.Bind.Converter;

interface

uses
  TypInfo, SysUtils,
  XData.Model.Classes;

function BindValueToUrl(const Value: JSValue; const Info: TTypeInfo;
  UrlConverter: TXDataTypeConverter): string;

implementation

function BindValueToUrl(const Value: JSValue; const Info: TTypeInfo;
  UrlConverter: TXDataTypeConverter): string;
begin
  Result := UrlConverter.ValueToUrl(Value, nil);
end;

end.
