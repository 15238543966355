unit Unit2;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Modules, WEBLib.CDS, strutils,
  XData.Web.Connection, WEBLib.REST, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, WEBLib.JSON, XData.Web.Client;

type
  TDM2 = class(TDataModule)
    XDataWebConnection1: TXDataWebConnection;
    tableAuftragStatus: TXDataWebDataSet;
    sourceAuftragStatus: TDataSource;
    tableAuftragStatusAuftragStatus: TStringField;
    tableSYSDAT: TXDataWebDataSet;
    sourceSysdat: TDataSource;
    tableSYSDATDBKEY: TStringField;
    tableSYSDATNachname: TStringField;
    tableSYSDATVorname: TStringField;
    tableSYSDATNamenzeile2: TStringField;
    tableSYSDATNamenzeile3: TStringField;
    tableSYSDATStrasse: TStringField;
    tableSYSDATPostleitzahl: TStringField;
    tableSYSDATOrt: TStringField;
    tableSYSDATLaenderkuerzel: TStringField;
    tableSYSDATLand: TStringField;
    tableSYSDATTelefonnummer: TStringField;
    tableSYSDATTelefaxnummer: TStringField;
    tableSYSDATMobiltelefon: TStringField;
    tableSYSDATEmail: TStringField;
    tableSYSDATCadiaKundennummer: TStringField;
    queryMonteur: TXDataWebDataSet;
    sourceMonteur: TDataSource;
    queryMonteurPersonalnummer: TIntegerField;
    queryMonteurNachname: TStringField;
    queryMonteurVorname: TStringField;
    queryMonteurSchreibzeichen: TStringField;
    queryMonteurServicetechniker: TBooleanField;
    tableMengenarten: TXDataWebDataSet;
    sourceMengenarten: TDataSource;
    tableMengenartenArtderMenge: TStringField;
    tableMengenartenISO3: TStringField;
    tableMengenartenMengenauswahl: TBooleanField;
    tableMengenartenVGPauswahl: TBooleanField;
    tableMengenartenUVPauswahl: TBooleanField;
    tableMengenartenVKPDivisor: TFloatField;
    tableSYSDATRundungIT: TBooleanField;
    tableSYSDATNachkommastellen: TIntegerField;
    queryMonteurPersonalGUID: TStringField;
    WebHttpRequest2: THttpRequest;
    WebHttpRequestLogbuch: THttpRequest;
    WClientAbsenderzeile: TXDataWebClient;
    tableSYSDATAbsenderzeile: TStringField;
    procedure XDataWebConnection1Connect(Sender: TObject);
    procedure tableAuftragStatusAfterOpen(DataSet: TDataSet);
    procedure tableSYSDATAfterOpen(DataSet: TDataSet);
    procedure queryMonteurAfterOpen(DataSet: TDataSet);
    procedure tableMengenartenAfterOpen(DataSet: TDataSet);
    procedure WebHttpRequest2Response(Sender: TObject; AResponse: string);
    procedure XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
  protected
    procedure LoadDFMValues; override;

  public
    function roundkfm(alterwert:double;kommastellen:integer):double;
    function holevkpdivisor(Mengenart:string):double;
    function checkrabattierung(Artikelnummer:string):boolean;
  end;

var
  DM2: TDM2;
  tempvgpdivisor:double;
  frund:integer;

implementation
  uses unit1,unit_Einzelauftrag;
{%CLASSGROUP 'Vcl.Controls.TControl'}


procedure TDM2.queryMonteurAfterOpen(DataSet: TDataSet);
begin
  tableMengenarten.Load;
end;

function TDM2.roundkfm(alterwert:double;kommastellen:integer):double;
var
faktor:integer;
neuerwert:double;
begin
  result:=0;
  neuerwert:=0;
  if kommastellen=0 then faktor:=1;
  if kommastellen=-1 then faktor:=10;
  if kommastellen=-2 then faktor:=100;
  if kommastellen=-3 then faktor:=1000;
  if kommastellen=-4 then faktor:=10000;
  if kommastellen=-5 then faktor:=100000;
  if kommastellen=-6 then faktor:=1000000;
  // wenn die zahl positiv ist
  if alterwert>0 then neuerwert:=(alterwert*faktor + 0.5000001);
  // wenn die zahl negativ ist
  if alterwert<0 then neuerwert:=(alterwert*faktor - 0.5000001);
  result:=int(neuerwert);
  result:=result/faktor;
end;

function TDM2.holevkpdivisor(Mengenart:string):double;
begin
  result:=1;
  //dm2.tablemengenarten.Open;
  if dm2.tablemengenarten.locate('ArtderMenge',Mengenart,[])=true then
  begin
     if dm2.tablemengenartenVKPDivisor.AsFloat<>0 then result:=dm2.tablemengenartenVKPDivisor.AsFloat;
  end;
end;

function TDM2.checkrabattierung(Artikelnummer:string):boolean;
begin
  result:=false;
  {query.close;
  query.sql.clear;
  query.sql.append('select rabattierfaehig from artikel where artikelnummer='''+Artikelnummer+'''');
  query.open;
  if query.fieldbyname('rabattierfaehig').asboolean=true then result:=TRUE;
  query.close;
  }
end;

procedure TDM2.tableAuftragStatusAfterOpen(DataSet: TDataSet);
begin
  tablesysdat.Load;
end;

procedure TDM2.tableMengenartenAfterOpen(DataSet: TDataSet);
begin
  WebHttpRequest2.URL:='https://langwald-edv.net/cadiaautoserver/xdata/InformationService/cwlizenzonly?Kundennummer='+tableSYSDATCadiaKundennummer.AsString+'&AppKey='+CadiaAppKey;
  WebHttpRequest2.Execute;
end;

procedure TDM2.tableSYSDATAfterOpen(DataSet: TDataSet);
  procedure OnResponse(Response:TXDataClientResponse);
  var
    mydownload:string;
  begin
    mydownload:=string(TJSObject(response.Result)['value']);
    TextAbsenderzeile:=leftstr(mydownload,70);
    queryMonteur.load;


  end;
begin
  frund:=dm2.tablesysdatnachkommastellen.asinteger*-1;
  WClientAbsenderzeile.RawInvoke('IServerService.RTFTOTEXT',[tableSYSDATAbsenderzeile.AsString],@OnResponse);
end;

procedure TDM2.WebHttpRequest2Response(Sender: TObject; AResponse: string);
var
  JS:TJSON;
  JO:TJSONObject;
begin
  JS:=TJSON.Create;
  JO:=JS.Parse(AResponse);
  if JO.GetJSONValue('value')='OK' then LizenzOK:=true;

  form1.labelonlinestatus.Caption:='Lizenz vorhanden: '+JO.GetJSONValue('value');
end;

procedure TDM2.XDataWebConnection1Connect(Sender: TObject);
begin
  tableAuftragStatus.Load;
end;

procedure TDM2.XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
begin
  args.Request.Headers.SetValue('Authorization','Bearer ' + vToken);
end;

procedure TDM2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  tableAuftragStatus := TXDataWebDataSet.Create(Self);
  tableAuftragStatusAuftragStatus := TStringField.Create(Self);
  sourceAuftragStatus := TDataSource.Create(Self);
  tableSYSDAT := TXDataWebDataSet.Create(Self);
  tableSYSDATDBKEY := TStringField.Create(Self);
  tableSYSDATNachname := TStringField.Create(Self);
  tableSYSDATVorname := TStringField.Create(Self);
  tableSYSDATNamenzeile2 := TStringField.Create(Self);
  tableSYSDATNamenzeile3 := TStringField.Create(Self);
  tableSYSDATStrasse := TStringField.Create(Self);
  tableSYSDATPostleitzahl := TStringField.Create(Self);
  tableSYSDATOrt := TStringField.Create(Self);
  tableSYSDATLaenderkuerzel := TStringField.Create(Self);
  tableSYSDATLand := TStringField.Create(Self);
  tableSYSDATTelefonnummer := TStringField.Create(Self);
  tableSYSDATTelefaxnummer := TStringField.Create(Self);
  tableSYSDATMobiltelefon := TStringField.Create(Self);
  tableSYSDATEmail := TStringField.Create(Self);
  tableSYSDATCadiaKundennummer := TStringField.Create(Self);
  tableSYSDATRundungIT := TBooleanField.Create(Self);
  tableSYSDATNachkommastellen := TIntegerField.Create(Self);
  tableSYSDATAbsenderzeile := TStringField.Create(Self);
  sourceSysdat := TDataSource.Create(Self);
  queryMonteur := TXDataWebDataSet.Create(Self);
  queryMonteurPersonalnummer := TIntegerField.Create(Self);
  queryMonteurNachname := TStringField.Create(Self);
  queryMonteurVorname := TStringField.Create(Self);
  queryMonteurSchreibzeichen := TStringField.Create(Self);
  queryMonteurServicetechniker := TBooleanField.Create(Self);
  queryMonteurPersonalGUID := TStringField.Create(Self);
  sourceMonteur := TDataSource.Create(Self);
  tableMengenarten := TXDataWebDataSet.Create(Self);
  tableMengenartenArtderMenge := TStringField.Create(Self);
  tableMengenartenISO3 := TStringField.Create(Self);
  tableMengenartenMengenauswahl := TBooleanField.Create(Self);
  tableMengenartenVGPauswahl := TBooleanField.Create(Self);
  tableMengenartenUVPauswahl := TBooleanField.Create(Self);
  tableMengenartenVKPDivisor := TFloatField.Create(Self);
  sourceMengenarten := TDataSource.Create(Self);
  WebHttpRequest2 := THttpRequest.Create(Self);
  WebHttpRequestLogbuch := THttpRequest.Create(Self);
  WClientAbsenderzeile := TXDataWebClient.Create(Self);

  XDataWebConnection1.BeforeLoadDFMValues;
  tableAuftragStatus.BeforeLoadDFMValues;
  tableAuftragStatusAuftragStatus.BeforeLoadDFMValues;
  sourceAuftragStatus.BeforeLoadDFMValues;
  tableSYSDAT.BeforeLoadDFMValues;
  tableSYSDATDBKEY.BeforeLoadDFMValues;
  tableSYSDATNachname.BeforeLoadDFMValues;
  tableSYSDATVorname.BeforeLoadDFMValues;
  tableSYSDATNamenzeile2.BeforeLoadDFMValues;
  tableSYSDATNamenzeile3.BeforeLoadDFMValues;
  tableSYSDATStrasse.BeforeLoadDFMValues;
  tableSYSDATPostleitzahl.BeforeLoadDFMValues;
  tableSYSDATOrt.BeforeLoadDFMValues;
  tableSYSDATLaenderkuerzel.BeforeLoadDFMValues;
  tableSYSDATLand.BeforeLoadDFMValues;
  tableSYSDATTelefonnummer.BeforeLoadDFMValues;
  tableSYSDATTelefaxnummer.BeforeLoadDFMValues;
  tableSYSDATMobiltelefon.BeforeLoadDFMValues;
  tableSYSDATEmail.BeforeLoadDFMValues;
  tableSYSDATCadiaKundennummer.BeforeLoadDFMValues;
  tableSYSDATRundungIT.BeforeLoadDFMValues;
  tableSYSDATNachkommastellen.BeforeLoadDFMValues;
  tableSYSDATAbsenderzeile.BeforeLoadDFMValues;
  sourceSysdat.BeforeLoadDFMValues;
  queryMonteur.BeforeLoadDFMValues;
  queryMonteurPersonalnummer.BeforeLoadDFMValues;
  queryMonteurNachname.BeforeLoadDFMValues;
  queryMonteurVorname.BeforeLoadDFMValues;
  queryMonteurSchreibzeichen.BeforeLoadDFMValues;
  queryMonteurServicetechniker.BeforeLoadDFMValues;
  queryMonteurPersonalGUID.BeforeLoadDFMValues;
  sourceMonteur.BeforeLoadDFMValues;
  tableMengenarten.BeforeLoadDFMValues;
  tableMengenartenArtderMenge.BeforeLoadDFMValues;
  tableMengenartenISO3.BeforeLoadDFMValues;
  tableMengenartenMengenauswahl.BeforeLoadDFMValues;
  tableMengenartenVGPauswahl.BeforeLoadDFMValues;
  tableMengenartenUVPauswahl.BeforeLoadDFMValues;
  tableMengenartenVKPDivisor.BeforeLoadDFMValues;
  sourceMengenarten.BeforeLoadDFMValues;
  WebHttpRequest2.BeforeLoadDFMValues;
  WebHttpRequestLogbuch.BeforeLoadDFMValues;
  WClientAbsenderzeile.BeforeLoadDFMValues;
  try
    Name := 'DM2';
    Height := 628;
    Width := 652;
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'https://langwald-edv.net:443/cadiaserver/xdata';
    SetEvent(XDataWebConnection1, Self, 'OnConnect', 'XDataWebConnection1Connect');
    SetEvent(XDataWebConnection1, Self, 'OnRequest', 'XDataWebConnection1Request');
    XDataWebConnection1.Left := 64;
    XDataWebConnection1.Top := 32;
    tableAuftragStatus.SetParentComponent(Self);
    tableAuftragStatus.Name := 'tableAuftragStatus';
    tableAuftragStatus.AfterOpen := tableAuftragStatusAfterOpen;
    tableAuftragStatus.EntitySetName := 'AuftragStatus';
    tableAuftragStatus.Connection := XDataWebConnection1;
    tableAuftragStatus.Left := 168;
    tableAuftragStatus.Top := 200;
    tableAuftragStatusAuftragStatus.SetParentComponent(tableAuftragStatus);
    tableAuftragStatusAuftragStatus.Name := 'tableAuftragStatusAuftragStatus';
    tableAuftragStatusAuftragStatus.FieldName := 'AuftragStatus';
    tableAuftragStatusAuftragStatus.Required := True;
    tableAuftragStatusAuftragStatus.Size := 30;
    sourceAuftragStatus.SetParentComponent(Self);
    sourceAuftragStatus.Name := 'sourceAuftragStatus';
    sourceAuftragStatus.DataSet := tableAuftragStatus;
    sourceAuftragStatus.Left := 64;
    sourceAuftragStatus.Top := 200;
    tableSYSDAT.SetParentComponent(Self);
    tableSYSDAT.Name := 'tableSYSDAT';
    tableSYSDAT.AfterOpen := tableSYSDATAfterOpen;
    tableSYSDAT.EntitySetName := 'sysdat';
    tableSYSDAT.Connection := XDataWebConnection1;
    tableSYSDAT.Left := 160;
    tableSYSDAT.Top := 272;
    tableSYSDATDBKEY.SetParentComponent(tableSYSDAT);
    tableSYSDATDBKEY.Name := 'tableSYSDATDBKEY';
    tableSYSDATDBKEY.FieldName := 'DBKEY';
    tableSYSDATDBKEY.Size := 38;
    tableSYSDATNachname.SetParentComponent(tableSYSDAT);
    tableSYSDATNachname.Name := 'tableSYSDATNachname';
    tableSYSDATNachname.FieldName := 'Nachname';
    tableSYSDATNachname.Size := 40;
    tableSYSDATVorname.SetParentComponent(tableSYSDAT);
    tableSYSDATVorname.Name := 'tableSYSDATVorname';
    tableSYSDATVorname.FieldName := 'Vorname';
    tableSYSDATVorname.Size := 40;
    tableSYSDATNamenzeile2.SetParentComponent(tableSYSDAT);
    tableSYSDATNamenzeile2.Name := 'tableSYSDATNamenzeile2';
    tableSYSDATNamenzeile2.FieldName := 'Namenzeile2';
    tableSYSDATNamenzeile2.Size := 40;
    tableSYSDATNamenzeile3.SetParentComponent(tableSYSDAT);
    tableSYSDATNamenzeile3.Name := 'tableSYSDATNamenzeile3';
    tableSYSDATNamenzeile3.FieldName := 'Namenzeile3';
    tableSYSDATNamenzeile3.Size := 40;
    tableSYSDATStrasse.SetParentComponent(tableSYSDAT);
    tableSYSDATStrasse.Name := 'tableSYSDATStrasse';
    tableSYSDATStrasse.FieldName := 'Strasse';
    tableSYSDATStrasse.Size := 40;
    tableSYSDATPostleitzahl.SetParentComponent(tableSYSDAT);
    tableSYSDATPostleitzahl.Name := 'tableSYSDATPostleitzahl';
    tableSYSDATPostleitzahl.FieldName := 'Postleitzahl';
    tableSYSDATPostleitzahl.Size := 6;
    tableSYSDATOrt.SetParentComponent(tableSYSDAT);
    tableSYSDATOrt.Name := 'tableSYSDATOrt';
    tableSYSDATOrt.FieldName := 'Ort';
    tableSYSDATOrt.Size := 40;
    tableSYSDATLaenderkuerzel.SetParentComponent(tableSYSDAT);
    tableSYSDATLaenderkuerzel.Name := 'tableSYSDATLaenderkuerzel';
    tableSYSDATLaenderkuerzel.FieldName := 'Laenderkuerzel';
    tableSYSDATLaenderkuerzel.Size := 5;
    tableSYSDATLand.SetParentComponent(tableSYSDAT);
    tableSYSDATLand.Name := 'tableSYSDATLand';
    tableSYSDATLand.FieldName := 'Land';
    tableSYSDATLand.Size := 40;
    tableSYSDATTelefonnummer.SetParentComponent(tableSYSDAT);
    tableSYSDATTelefonnummer.Name := 'tableSYSDATTelefonnummer';
    tableSYSDATTelefonnummer.FieldName := 'Telefonnummer';
    tableSYSDATTelefonnummer.Size := 25;
    tableSYSDATTelefaxnummer.SetParentComponent(tableSYSDAT);
    tableSYSDATTelefaxnummer.Name := 'tableSYSDATTelefaxnummer';
    tableSYSDATTelefaxnummer.FieldName := 'Telefaxnummer';
    tableSYSDATTelefaxnummer.Size := 25;
    tableSYSDATMobiltelefon.SetParentComponent(tableSYSDAT);
    tableSYSDATMobiltelefon.Name := 'tableSYSDATMobiltelefon';
    tableSYSDATMobiltelefon.FieldName := 'Mobiltelefon';
    tableSYSDATMobiltelefon.Size := 25;
    tableSYSDATEmail.SetParentComponent(tableSYSDAT);
    tableSYSDATEmail.Name := 'tableSYSDATEmail';
    tableSYSDATEmail.FieldName := 'Email';
    tableSYSDATEmail.Size := 70;
    tableSYSDATCadiaKundennummer.SetParentComponent(tableSYSDAT);
    tableSYSDATCadiaKundennummer.Name := 'tableSYSDATCadiaKundennummer';
    tableSYSDATCadiaKundennummer.FieldName := 'CadiaKundennummer';
    tableSYSDATCadiaKundennummer.Size := 13;
    tableSYSDATRundungIT.SetParentComponent(tableSYSDAT);
    tableSYSDATRundungIT.Name := 'tableSYSDATRundungIT';
    tableSYSDATRundungIT.FieldName := 'RundungIT';
    tableSYSDATNachkommastellen.SetParentComponent(tableSYSDAT);
    tableSYSDATNachkommastellen.Name := 'tableSYSDATNachkommastellen';
    tableSYSDATNachkommastellen.FieldName := 'Nachkommastellen';
    tableSYSDATAbsenderzeile.SetParentComponent(tableSYSDAT);
    tableSYSDATAbsenderzeile.Name := 'tableSYSDATAbsenderzeile';
    tableSYSDATAbsenderzeile.FieldName := 'Absenderzeile';
    tableSYSDATAbsenderzeile.Size := 255;
    sourceSysdat.SetParentComponent(Self);
    sourceSysdat.Name := 'sourceSysdat';
    sourceSysdat.DataSet := tableSYSDAT;
    sourceSysdat.Left := 56;
    sourceSysdat.Top := 272;
    queryMonteur.SetParentComponent(Self);
    queryMonteur.Name := 'queryMonteur';
    queryMonteur.AfterOpen := queryMonteurAfterOpen;
    queryMonteur.EntitySetName := 'Personal';
    queryMonteur.Connection := XDataWebConnection1;
    queryMonteur.QueryString := '$filter=Servicetechniker eq True';
    queryMonteur.Left := 160;
    queryMonteur.Top := 360;
    queryMonteurPersonalnummer.SetParentComponent(queryMonteur);
    queryMonteurPersonalnummer.Name := 'queryMonteurPersonalnummer';
    queryMonteurPersonalnummer.FieldName := 'Personalnummer';
    queryMonteurPersonalnummer.Required := True;
    queryMonteurNachname.SetParentComponent(queryMonteur);
    queryMonteurNachname.Name := 'queryMonteurNachname';
    queryMonteurNachname.FieldName := 'Nachname';
    queryMonteurNachname.Required := True;
    queryMonteurNachname.Size := 40;
    queryMonteurVorname.SetParentComponent(queryMonteur);
    queryMonteurVorname.Name := 'queryMonteurVorname';
    queryMonteurVorname.FieldName := 'Vorname';
    queryMonteurVorname.Size := 40;
    queryMonteurSchreibzeichen.SetParentComponent(queryMonteur);
    queryMonteurSchreibzeichen.Name := 'queryMonteurSchreibzeichen';
    queryMonteurSchreibzeichen.FieldName := 'Schreibzeichen';
    queryMonteurSchreibzeichen.Size := 10;
    queryMonteurServicetechniker.SetParentComponent(queryMonteur);
    queryMonteurServicetechniker.Name := 'queryMonteurServicetechniker';
    queryMonteurServicetechniker.FieldName := 'Servicetechniker';
    queryMonteurPersonalGUID.SetParentComponent(queryMonteur);
    queryMonteurPersonalGUID.Name := 'queryMonteurPersonalGUID';
    queryMonteurPersonalGUID.FieldName := 'PersonalGUID';
    queryMonteurPersonalGUID.Size := 38;
    sourceMonteur.SetParentComponent(Self);
    sourceMonteur.Name := 'sourceMonteur';
    sourceMonteur.DataSet := queryMonteur;
    sourceMonteur.Left := 56;
    sourceMonteur.Top := 360;
    tableMengenarten.SetParentComponent(Self);
    tableMengenarten.Name := 'tableMengenarten';
    tableMengenarten.AfterOpen := tableMengenartenAfterOpen;
    tableMengenarten.EntitySetName := 'Mengenarten';
    tableMengenarten.Connection := XDataWebConnection1;
    tableMengenarten.Left := 160;
    tableMengenarten.Top := 432;
    tableMengenartenArtderMenge.SetParentComponent(tableMengenarten);
    tableMengenartenArtderMenge.Name := 'tableMengenartenArtderMenge';
    tableMengenartenArtderMenge.FieldName := 'ArtderMenge';
    tableMengenartenArtderMenge.Required := True;
    tableMengenartenArtderMenge.Size := 50;
    tableMengenartenISO3.SetParentComponent(tableMengenarten);
    tableMengenartenISO3.Name := 'tableMengenartenISO3';
    tableMengenartenISO3.FieldName := 'ISO3';
    tableMengenartenISO3.Size := 3;
    tableMengenartenMengenauswahl.SetParentComponent(tableMengenarten);
    tableMengenartenMengenauswahl.Name := 'tableMengenartenMengenauswahl';
    tableMengenartenMengenauswahl.FieldName := 'Mengenauswahl';
    tableMengenartenVGPauswahl.SetParentComponent(tableMengenarten);
    tableMengenartenVGPauswahl.Name := 'tableMengenartenVGPauswahl';
    tableMengenartenVGPauswahl.FieldName := 'VGPauswahl';
    tableMengenartenUVPauswahl.SetParentComponent(tableMengenarten);
    tableMengenartenUVPauswahl.Name := 'tableMengenartenUVPauswahl';
    tableMengenartenUVPauswahl.FieldName := 'UVPauswahl';
    tableMengenartenVKPDivisor.SetParentComponent(tableMengenarten);
    tableMengenartenVKPDivisor.Name := 'tableMengenartenVKPDivisor';
    tableMengenartenVKPDivisor.FieldName := 'VKPDivisor';
    sourceMengenarten.SetParentComponent(Self);
    sourceMengenarten.Name := 'sourceMengenarten';
    sourceMengenarten.DataSet := tableMengenarten;
    sourceMengenarten.Left := 56;
    sourceMengenarten.Top := 432;
    WebHttpRequest2.SetParentComponent(Self);
    WebHttpRequest2.Name := 'WebHttpRequest2';
    WebHttpRequest2.Headers.BeginUpdate;
    try
      WebHttpRequest2.Headers.Clear;
      WebHttpRequest2.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest2.Headers.EndUpdate;
    end;
    WebHttpRequest2.URL := 'https://langwald-edv.net/cadiaserver/xdata/$model';
    SetEvent(WebHttpRequest2, Self, 'OnResponse', 'WebHttpRequest2Response');
    WebHttpRequest2.Left := 64;
    WebHttpRequest2.Top := 104;
    WebHttpRequestLogbuch.SetParentComponent(Self);
    WebHttpRequestLogbuch.Name := 'WebHttpRequestLogbuch';
    WebHttpRequestLogbuch.Headers.BeginUpdate;
    try
      WebHttpRequestLogbuch.Headers.Clear;
      WebHttpRequestLogbuch.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequestLogbuch.Headers.EndUpdate;
    end;
    WebHttpRequestLogbuch.Left := 208;
    WebHttpRequestLogbuch.Top := 104;
    WClientAbsenderzeile.SetParentComponent(Self);
    WClientAbsenderzeile.Name := 'WClientAbsenderzeile';
    WClientAbsenderzeile.Connection := XDataWebConnection1;
    WClientAbsenderzeile.Left := 56;
    WClientAbsenderzeile.Top := 512;
  finally
    XDataWebConnection1.AfterLoadDFMValues;
    tableAuftragStatus.AfterLoadDFMValues;
    tableAuftragStatusAuftragStatus.AfterLoadDFMValues;
    sourceAuftragStatus.AfterLoadDFMValues;
    tableSYSDAT.AfterLoadDFMValues;
    tableSYSDATDBKEY.AfterLoadDFMValues;
    tableSYSDATNachname.AfterLoadDFMValues;
    tableSYSDATVorname.AfterLoadDFMValues;
    tableSYSDATNamenzeile2.AfterLoadDFMValues;
    tableSYSDATNamenzeile3.AfterLoadDFMValues;
    tableSYSDATStrasse.AfterLoadDFMValues;
    tableSYSDATPostleitzahl.AfterLoadDFMValues;
    tableSYSDATOrt.AfterLoadDFMValues;
    tableSYSDATLaenderkuerzel.AfterLoadDFMValues;
    tableSYSDATLand.AfterLoadDFMValues;
    tableSYSDATTelefonnummer.AfterLoadDFMValues;
    tableSYSDATTelefaxnummer.AfterLoadDFMValues;
    tableSYSDATMobiltelefon.AfterLoadDFMValues;
    tableSYSDATEmail.AfterLoadDFMValues;
    tableSYSDATCadiaKundennummer.AfterLoadDFMValues;
    tableSYSDATRundungIT.AfterLoadDFMValues;
    tableSYSDATNachkommastellen.AfterLoadDFMValues;
    tableSYSDATAbsenderzeile.AfterLoadDFMValues;
    sourceSysdat.AfterLoadDFMValues;
    queryMonteur.AfterLoadDFMValues;
    queryMonteurPersonalnummer.AfterLoadDFMValues;
    queryMonteurNachname.AfterLoadDFMValues;
    queryMonteurVorname.AfterLoadDFMValues;
    queryMonteurSchreibzeichen.AfterLoadDFMValues;
    queryMonteurServicetechniker.AfterLoadDFMValues;
    queryMonteurPersonalGUID.AfterLoadDFMValues;
    sourceMonteur.AfterLoadDFMValues;
    tableMengenarten.AfterLoadDFMValues;
    tableMengenartenArtderMenge.AfterLoadDFMValues;
    tableMengenartenISO3.AfterLoadDFMValues;
    tableMengenartenMengenauswahl.AfterLoadDFMValues;
    tableMengenartenVGPauswahl.AfterLoadDFMValues;
    tableMengenartenUVPauswahl.AfterLoadDFMValues;
    tableMengenartenVKPDivisor.AfterLoadDFMValues;
    sourceMengenarten.AfterLoadDFMValues;
    WebHttpRequest2.AfterLoadDFMValues;
    WebHttpRequestLogbuch.AfterLoadDFMValues;
    WClientAbsenderzeile.AfterLoadDFMValues;
  end;
end;

end.
