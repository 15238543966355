{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Dialogs;

interface

uses
  Classes, WEBLib.Controls, Web, SysUtils, WebLib.WebTools,
  WEBLib.WebCtrls, WEBLib.Graphics;

type
  TMsgDlgType = (mtWarning, mtError, mtInformation, mtConfirmation, mtCustom);
  TMsgDlgBtn = (mbYes, mbNo, mbOK, mbCancel, mbAbort, mbRetry, mbIgnore,
    mbAll, mbNoToAll, mbYesToAll, mbHelp, mbClose);
  TMsgDlgButtons = set of TMsgDlgBtn;

  TDialogResult = Integer;
  TDialogResultProc = reference to procedure(AValue: TDialogResult);

  TCustomDialogButton = class(TCollectionItem)
  private
    FCaption: string;
    FTag: integer;
    FElementClassName: TElementClassName;
  published
    property Caption: string read FCaption write FCaption;
    property ElementClassName: TElementClassName read FElementClassName write FElementClassName;
    property Tag: integer read FTag write FTag default 0;
  end;

  TCustomDialogButtons = class(TOwnedCollection)
  private
    function GetItem(AIndex: integer): TCustomDialogButton; reintroduce;
    procedure SetItem(AIndex: integer; const Value: TCustomDialogButton); reintroduce;
  public
    function Add: TCustomDialogButton; reintroduce;
    function Insert(AIndex: integer): TCustomDialogButton; reintroduce;
    property Items[AIndex: integer]: TCustomDialogButton read GetItem write SetItem; default;
  end;

  TMessageDlg = class(TCustomControl)
  private
    FOpacity: double;
    FLayer: TJSElement;
    FDlg: TJSElement;
    FCancel: TJSElement;
    FMessage: string;
    FTitle: string;
    FMsgDlgType: TMSgDlgType;
    FOnButtonClick: TNotifyEvent;
    FOnClose: TNotifyEvent;
    FButtons: TMsgDlgButtons;
    FDialogProc: TDialogResultProc;
    FDialogResult: TDialogResult;
    FMdx, FMdy: integer;
    FDlgX, FDlgY: integer;
    FCaptured: boolean;
    FDown: boolean;
    FElementTitleClassName: TElementClassName;
    FElementButtonClassName: TElementClassName;
    FElementContentClassName: TElementClassName;
    FDialogText: TStringList;
    FCustomButtons: TCustomDialogButtons;
    FElementDialogClassName: TElementClassName;
    procedure SetDialogText(const Value: TStringList);
    procedure SetCustomButtons(const Value: TCustomDialogButtons);
  protected
    function CreateElement: TJSElement; override;
    function GetDialogText(Index: integer): string; virtual;
    procedure BindEvents; override;
    procedure SetDialogResult(const Value: TDialogResult);
    function CreateButton(Caption: string; var DoFocus: boolean; AClass: string = ''): TJSHTMLElement;
    function HandleDocMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDocMouseUp(Event: TJSMouseEvent): Boolean; virtual;
    function HandleMouseDown(Event: TJSMouseEvent): Boolean; virtual;
    function HandleButtonClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleKeyDown(Event: TJSKeyBoardEvent): Boolean; virtual;
    procedure InitBootstrapStyle;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Close;
    property DialogResult: TDialogResult read FDialogResult write SetDialogResult;
    procedure Show;
    function ShowDialog: Integer; overload; virtual;
    function ShowDialog(Msg: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons; AProc: TDialogResultProc = nil): TDialogResult; overload; virtual;
  published
    property Buttons: TMsgDlgButtons read FButtons write FButtons default [];
    property CustomButtons: TCustomDialogButtons read FCustomButtons write SetCustomButtons;
    property DialogText: TStringList read FDialogText write SetDialogText;
    property DialogType: TMSgDlgType read FMsgDlgType write FMsgDlgType default mtCustom;
    property &Message: string read FMessage write FMessage;
    property Opacity: double read FOpacity write FOpacity;
    property ElementButtonClassName: TElementClassName read FElementButtonClassName write FElementButtonClassName;
    property ElementDialogClassName: TElementClassName read FElementDialogClassName write FElementDialogClassName;
    property ElementTitleClassName: TElementClassName read FElementTitleClassName write FElementTitleClassName;
    property ElementContentClassName: TElementClassName read FElementContentClassName write FElementContentClassName;
    property Title: string read FTitle write FTitle;
    property OnButtonClick: TNotifyEvent read FOnButtonClick write FOnButtonClick;
    property OnClose: TNotifyEvent read FOnClose write FOnClose;
  end;

  TWebMessageDlg = class(TMessageDlg);

  TWaitMessage = class(TCustomControl)
  private
    FLayer: TJSElement;
    FPicture: TURLPicture;
    FOpacity: double;
    FOnShow: TNotifyEvent;
    FIsWaiting: boolean;
    procedure SetPicture(const Value: TURLPicture);
  protected
    procedure WaitDisplayed;
    function CreateElement: TJSElement; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Show;
    procedure Hide;
    property IsWaiting: boolean read FIsWaiting write FIsWaiting;
    property Showing: boolean read FIsWaiting;
  published
    property Opacity: double read FOpacity write FOpacity;
    property Picture: TURLPicture read FPicture write SetPicture;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
  end;

  TWebWaitMessage = class(TWaitMessage);

  TOpenDialogProc = reference to procedure(AFileName: string);

  TOpenOption = (ofReadOnly, ofOverwritePrompt, ofHideReadOnly,
    ofNoChangeDir, ofShowHelp, ofNoValidate, ofAllowMultiSelect,
    ofExtensionDifferent, ofPathMustExist, ofFileMustExist, ofCreatePrompt,
    ofShareAware, ofNoReadOnlyReturn, ofNoTestFileCreate, ofNoNetworkButton,
    ofNoLongNames, ofOldStyleDialog, ofNoDereferenceLinks, ofEnableIncludeNotify,
    ofEnableSizing, ofDontAddToRecent, ofForceShowHidden);
  TOpenOptions = set of TOpenOption;

  TOpenDialog = class(TComponent)
  private
    FFiles: TFiles;
    FOnChange: TNotifyEvent;
    FFileName: string;
    FMultiFile: boolean;
    FFilter: string;
    FAccept: string;
    FOpenDialogProc: TOpenDialogProc;
    FOptions: TOpenOptions;
    FFilterIndex: Integer;
  protected
    procedure HandleInputChange(Event: TJSEvent); virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Execute; overload;
    procedure Execute(AProc: TOpenDialogProc); overload;
    property FileName: string read FFileName;
    property Files: TFiles read FFiles;
    property Filter: string read FFilter write FFilter;
    property Options: TOpenOptions read FOptions write FOptions;
    property FilterIndex: Integer read FFilterIndex write FFilterIndex;
  published
    property Accept: string read FAccept write FAccept;
    property MultiFile: boolean read FMultiFile write FMultiFile default false;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebOpenDialog = class(TOpenDialog);


procedure MessageDlg(const Msg: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons;  AResultProc: TDialogResultProc = nil);
procedure ShowMessage(AMsg: string);
function InputBox(const ACaption, APrompt, ADefault: string): string;
function InputQuery(const ACaption, APrompt: string; var Value: string): Boolean; overload;
function Confirm(const Value: string): boolean;

implementation

uses
  WEBLib.Forms, WebLib.Consts, JS;

const
  lMaterial = 'https://fonts.googleapis.com/icon?family=Material+Icons';

  lWaitCursor =
    '0954474946496D61676547494638396190012C01A20000FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF21FF0B4E45545343415045322E30030100000021F90405050004002C0000000090012C0182FFFFFFDDDDD'+
    'DBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FF48BADCFE30CA49ABBD38EBCDBBFF60288E64699E68AAAE6CEBBE702CCF746DDF78AEEF7CEFFFC0A070482C1A8FC8A472C96C3A9FD0A8744AAD5AAFD8AC76CBED7ABFE0B078'+
    '4C2E9BCFE8B47ACD6EBBDFF0B87C4EAFDBEFF8BC7ECFEFFBFF808182838485868788898A8B8C8D8E8F909192939495969798999A9B9C9D9E9FA0A1A2A3A4A5A6A7A8A9AAABACADAEAFB0B1B2B3B4B5B6B7B8B9BABBBCBDBEBFC'+
    '0C1C2C3C4C5C6C7C8C9CACBCCCDCECFD0D1D2D3D4D5D6D7D8D9DADBDCDDDEDFE0E1E2E3E4E5E6E7E8E9EAEBECEDEEEFF0F1F2F3F4F5F6F7F8F9FAFBFCFDFEFF00030A1C48B0A0C18308132A5CC8B0A1C3871023861A407180C4'+
    '44152B5E349431FFE346421D357E141492E24892254F062A6951E51F962EC978F400D383809B02621E09493365079C387512A9B981A806A040850A319A812906A441950261D95283530B50A34AF541B52A86AB14B26ADDDA836'+
    'A519F19C4E6243B15AC04B711D4B20D62B629DA0B72E7B6BD5B01EE83BC7A7F74FDCA7782DAB58105FB6DB09801E0C45CEBF62D1CF731E4B28D15642660F93266CA0F3277F6CC43F204D162492F6DDC78B4EA1D834F8376ECFA'+
    'B50ED31016D7B69D237604BF8779AF9EBD80A7E1D4C24BCCFC803BB44809BB2F04981E20B9F19EC48F22F7409D3AEFE676AF87D8CEA1BB79DB5DBD86EF48827C06F3F0D1A7577F41BC08A8E5E1C77F3DDFE459FFFF2624B5817'+
    'EFA7DD71F7D4E1058A0700702D884820B26474083081601E17E1216D7A012179E97A103141AD161771FBEB56110237A57A26C070291E2742B5A70220F2FC6B81E78378C682307E9F970E18ED865370384408220240D111669E4'+
    '733F90A8E493504629E594545669E5955866A9A54014FA960400608629E698648EB955973D7E59E69A6C86A9149AF321D1E69C6C0A05679A46D0A9A7993ADDE925117B060A809D7E6E9683A07BBE59287B6A225AA7548B3229A'+
    '7A3656E69E9A59866AAE9A69C76EAE9A7A086AAC5728A39E8E99133A06AE59FBDE198259EB0B18A657F91C1BA6A8B9FC5596588A5D22A25AF7BF9AA24B04310BBA2B145206BDD8CFF4928CB1FB34C387B19B44F503BADB0EBA1'+
    '402A61BAAA862DB79286605F7DDD92662BB8DB32A72A88B226E6AA8CEB5260A873F1129AAE55F3B2382EBEF77AD6EF8DFF6A682ABDFBF25BE1AC8B15CCC0BBA2FEC6B05FED36ECB09009E71B6AC402336AA2C59F32ACD9911E4'+
    'B3C61662473CC69C8232BCC98C99AA29CB2C6F2B27C29C62BAB5C73BD2D1BAAB3CC5AD27C73C0ECF28C70BCF3BA9C73BDF91A8DA9D22F034D30CC0D33DD74B893E13C34CE16FBBC34C75C0B1DA5C9605B7DABCD1B438D2ED5A7'+
    '9A7DF6C0678BFC31DB6B1F0C2FDC6E77E075DDCA898D770A77EF2D42DF7E2F4976E02F004E7890741F9E2ADA8A37EEF8E390472EF9E494576EF9E5649867AEF9E69C77EEF9E7A0872EFAE8A4976EFAE9A8A7AEFAEAACB7EEFAE'+
    'BB0C72EFBECB4D76EFBEDB8E7AEFBEEBCF7EEFBEFC0072FFCF0C4176FFCF1C827AFFCF2CC37EFFCF3D0472FFDF4D4576FFDF5D867AFFDF6DC77EFFDF7E0872FFEF8E497DF7D020021F90405050007002CD60060003000570082'+
    'FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03EC78BABCF3A3C9492B85D0EA7D71E6E0E67D61298DA4A9A2A95AB291BBB2B20BD7E68D8770BC73BA9F2828B4108B1D1472A85C568E4E462F9A1C5127D36B037AE'+
    '5AA04608191B60B879FCD9A795DC5A8D7ECB6CF04AF8F1FB2BA9DAADF3BFB7E4880707C83665186877F8960858C8E865A895A0A83940B80970C819A95679DA0A1A2A3A4A5A6A7A8A9AA2A01ADAEAFB0B1B035B2B5B6AF2EB7BA'+
    'B6ACBBBEB826BFC201BDC3BBB9C6B7B4C9B2ABCECFD0D1D2D3D4D53200D800A5D9DCA2DCDFDA9DE0DFE2E3E494E6E3E8E9E05AECEDEEEFDDF1F2D8F4F5EBF5E157FAFBFCF8F9D8954B178AA0B773A3E65148000021F90405050'+
    '007002CD60075003000570082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03E978BADCFE6EC801ABBD6D4ECCFBD1A0274660388E657A8AA9BA62ADFB56B13C93B576D3B9BEE33DCA2F13940C81BD2331A844B6'+
    '9A8F1C14529BF24CD6AB30CBED7ABFE0B0784C2E9BCF5C817ACD6EBBDBB3B77CCE5ED1EFF3137E0F1FF1FF027A807B76837471866F688B8C8D8E8F909192930A01960166979A639A9D98609E9DA0A1A25DA4A1A6A79E5CAAABA'+
    'CAD9BAFB096B2B3A9B39F59B8B91000BE002FB815BFBFC1B0BDC4C5C6A7C8C9C037CC0FCECAD0A5D2D3CF3BB1C3D862D8BEDEDD61DFD95FDF63E7E3E2EAD3E1EBE6EF5EE4EECEE8F15DE9ECF5FAFB60F9FEEDE81123D3CF1EB8'+
    '2909000021F90405050007002CC40092004200420082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03F978BADCFE508D49ABBD36EA1DB1FF15277260F98DE863AE59EA4A6CFCBEB13CBBB579CFF9B9FFC0A0704'+
    '82C1A8FC8A472C96C3A9FD0A8747A105805D4D7759B1D6DBFD8AE06FC1547C860B3034D5633D86D77155E96CFE957FB1D1F76F3F3767F567A8246018701377F4488888A78438D8D3B74919287406C96978999751100A1001C9C'+
    '98415C1AA2A2A4A54FAAAA1BA5A64CAFABB1AD4DB5A1ACB84BBAA3BC97B9BA23B2B4C422C6BEC8C9BD48BF28B29DCFCCCD9C4AD0D1CA47D9DAD7D4B52FDB45DDDEC2DCD5E692E8E1E2E342E529EF41F1F2DF44F5EA8EE4E92EE'+
    '7FCED764C62F74A8F837C7610CA51E886A11A870F0B1A7C006B6283040021F90405050007002CA700A4005700300082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FF78BADCFE30CA37EA9838EBCD8FFD5D28'+
    '8ECD675E64AA4AA7B9BEB0D7BA711DCEADAD6B78BEFF90DE0948740869C5E411942C2E2D4DE51315054E35802CE027E80A464F8C56ABF37AC1C7C9785C339B4942F53ADB76775538C99C1CB39FF148107B7413018601187E772'+
    'B4C11835B858786898A518F90119292138A8B458F189A87947E4997A1A2889C9D9F8319A9AAABA540A0AFA91AAC3FB5A8A2B8953BBBBC9A1BB935A71AB0C4C530C1C29BCAB3CCCD91BDD0D12BD3D4C31CCB29D9DACFDCBF2ADF'+
    '12C91DDD22E5E6B721E91CC71DE7E8EF58AE22F3F4D71DF7F8ED22E342F40B914F9F1B6F6B521434E889041B85FF46BCA9E26C14C5280B2FFEC8A8511307C78E353E82842172E48B6A2689844BA920010021F90405050007002'+
    'C9200A4005700300082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FF78BADC0E10B849ABBD38B7C8B5FFE0C78D52689E2139A26C4BA9A42BB7B03ADF606DE33CA6C7BDE0E4B7121A17C4CE71998C2C8F4DC8'+
    '13DA9C5289A78036201C7807996476ABED7DBF3E1D8A4CC69DCF975A8BBD75BFBD69258B5EBFDDD1714E2E7C6517028702187F785607845C8688878A8B568F90159292178B8C4B8F189A88947F4F97A1A2899C9D9F8419A9AAA'+
    'BA546A0AFA91AAC42B5A8A2B89541BBBC9A1EB938A71AB0C4C533C1C29BCAB3CCCD91BDD0D183AE1FC91FCB7BD9DAB720DD637C21DBDCBFDEE5E6E1E2E926C720E7E8EF20D3C8EDEED7F6EB26F3FA6FD6F46357CD443D7E6D50'+
    'FC03E849602185F90C026A446D18452B0B2F0AC9A8B11207C78E383E829C2172A48C82268D3CE391000021F90405050007002C8A0092004200420082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03F57807DCF'+
    'EF0A949ABBD98C6CD5DFEE0D58D5C6882642A9DACA5BE402B4FB03ADFF578EFF9B6FFC0A070482C1A8FC8A472C96C3A9FD0A8744A3D05AE81AA0CCBD59AB8E0AC3713068F2FE5F099922EAF0F6DF73AAE3ED3CDF63BF6ADDFE7'+
    'FB7C8081773B028602467A85878645743F8C8C446D4191878E784096979857439B8D2003A30352A088A2A4A350A7A819AAAA4FA721B0A44EADB4B5A54DB3B9B5BCA027BABB4BBDBEB04CC6C7B14AB8C2BAC5C12CC3CDCACBB64'+
    '9D6D7AB48DADBC446DEA9BF47E2E3C8E1E61FD4E99B3BEC45D237F044F233F4F59140F8F9A13FFC6F26000C78606040836F10AE5178866143740431304B000021F90405050007002C8A0075003000570082FFFFFFDDDDDDBBBB'+
    'BB999999FFFFFFFFFFFFFFFFFFFFFFFF03E778BA0C0E2CCA496B7BCEEA4DB1E7E0E68D61298DA8A9A2A95AB2ADCBC1B1ACD1A47DE3982EF20FDF0E0811768845E304A9AC009B161C340A9B0E7BD65F26CBED7ABFE0B0784C2E9'+
    'BCFA1807ACD6EBBDBB6B77CCE76D1EF73157E0F37F1FF017A807B76837471866F688B8C8D8E8F909192936802960266979A639A9D98609E9D5FA19E5DA4A159A7A856AAA553ADA2AFB097A9B396ACB69FB2B35CB63203C00316'+
    'BC2EC1C115AD36C6C614A73ECBC7CDB13AD0D1D2B742D5C062DAC261DDDE60DDDCE3DFDAE4D563E5E2E7E6EDECE9EEF1F0D0E8F5F6CBF8CCF2F7F4F9FADBD4CDD377669F86040021F90405050007002C8A0060003000570082F'+
    'FFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03EB78BADCFE0B4800ABBD6D4ECCBBD29A2756E0369E4C69A2A7BAB2A24BC1AD4B8FF28DDB7A27CF3D4C2E281C122DC62389A784249B8E2734229D1EAAD39F35CA84'+
    '04BE811EF60006EBB0E5F4ADCB48BB61EC857BCE2A55E67454C882CF4FFB7E50806F7F83655B8687568966858C888F90835B7280949578976D849A9B5F9DA0A1A2A3A4A5A6A7A8A93A02ACADAEAFB0AF30B1B4B5AE28B6B9B52'+
    '7BABDB223BEC102BCC2BDB8C5B6B3C8B1AACDCECFD0D1D2D3D4CF03D703A3D8DBA0DBDED997DFDE94E2DF56E5E250E8E94DEBE64AEEE3F0F1D8EAF4D7EDF7E0F3F453F75BFD00AED3842ED43B51DCAC24000021F90405050007'+
    '002C8A0058004200420082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FD78BADCFE30CA062A9838EB69EDFE20D77961698EA4A96E68B5BE597BC13424D738D5E64BE007AF1BEFF75B097144A2EA584B164FB'+
    'B9CF31962D2A6D48FF58A2D6DB9D36A74D8057DC14EF3985CD69CD149ED9A1D76CFE9713B8AE7C0026377784A807B7C0D7E842386878822858B3D6D116F528D93818696971D13029E027083129C129F9F309A2BA6AB2F92AAAB'+
    'AC26AE2AB0B5B27934B5B625A235BABB9020BFC0C11BC3B0C51FC7B1C91ACBA6CDC6CF9ED119D3D4D518D7D9D6CBDCCEC3DFE0BAE2D2CCE5E3A0E8EBECEDEEEFF0F1F2F3F4F5F68603F9FAFBFCFDFCD5FE020ADCD76CA04181C'+
    '90E2AFC576CA1C301091F2A2C287120C08AFEA225000021F90405050007002C920058005700300082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FF78BADCFE30BE4083BC38EB3D2BE560284A9E379E285756'+
    '69EB76EB2BBFEB37DF676DE13CA8F723815090FAA10048406B38441947C9248AC9CCD54ED1E889DA1C3D435969902BF45E4561B188DCF59D4169F59A2DFA6EE2CA149BE88EC1E32E7B217619782F822A6F1A8687881A8412787'+
    '98D64897E8B80337B7C19901092388E189E0F8CA1749D8A91993CA224AA11A6ADA817A40CB2B3B411B60AA0400AAE302518B83D9BB5B00DBEBFC0BA1097B1AC1003D40381CEC2C4D20FD5D54BD847DB0DDDE47A9532E2E3E4E5'+
    '635C33E90CEBF2ED6DE85918F2F373653C5A19F9FA9809041850E02F82EB0C3243C84E211086DD1C1E84484D620F8A152DF2C0A8F11322C38E0F09820C996FE4C48626495A4BA921010021F90401050007002CA700580057003'+
    '00082FFFFFFDDDDDDBBBBBB999999FFFFFFFFFFFFFFFFFFFFFFFF03FF78BADCFE4B4801ABBD38EB36E7FE60A8759D689E22E9A16C5BA9922BCF074CD1F869E77CB8F7A28170C0FA9D02C8406B38441945C9248AC9D4C18E51A9'+
    '89DA343D41596D902BF45EA161E489DC057D3569F1989C3A83E34BB6CFBE892BF3746E7C70782E6C441F6F177E33878823838B8C8D7A1B8A1593949519970F7E7F34879691988539A29CA49EA6A79B179D0D993CA8AFAAB1ACB'+
    '3B42FB60B9F400BBA10B00AB2408EB52A92B8C6AEC22418C5BF07C10F25CA612E00DA0018D428D121DBDBDD8132CB20E2E917CD2CE71AE9F0E45538D822F0F719F3395126F7F8D2D2FCFD03D843E04082380CC643C843A13A86'+
    '091D8E834843E2448A332C6AC358D11422C7880A3FE6082972A4C0920517A24CB9D14402003B1';

var
  WebLibDlg: TMessageDlg;

function StringToHTML(AValue: string): string;
begin
  Result := StringReplace(AValue,#13,'<BR>',[rfReplaceAll]);
  Result := StringReplace(Result,#10,'',[rfReplaceAll]);
end;

procedure ShowMessage(AMsg: string);
begin
  if (Application <> nil) and (Application.MainForm <> nil) and (Application.MainForm.CSSLibrary = cssBootstrap) then
  begin
    MessageDlg(AMsg, mtInformation, [mbOK]);
  end
  else
  begin
    asm
      alert(AMsg);
    end;
  end;
end;

function InputBox(const ACaption, APrompt, ADefault: string): string;
var
  retvalue: string;
begin
//
  asm
    retvalue = prompt(APrompt, ADefault);
    if (retvalue == null) {
      retvalue = "";
    }
  end;

  Result := retvalue;
end;

function InputQuery(const ACaption, APrompt: string; var Value: string): Boolean; overload;
var
  retvalue: boolean;
  s: string;
begin
  retvalue := true;
  s := Value;

  asm
    var text = s;
    s = prompt(APrompt, text);
    if (s == null) {
      retvalue = false;
    }
  end;

  if retvalue then
    Value := s;

  Result := retvalue;
end;

function Confirm(const Value: string): boolean;
var
  res: boolean;
begin
  asm
    res = confirm(Value);
  end;
  Result := res;
end;

procedure MessageDlg(const Msg: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons;  AResultProc: TDialogResultProc = nil);
begin
  if not Assigned(WebLibDlg) then
  begin
    WebLibDlg := TMessageDlg.Create(nil);
    if Application.MainForm.CSSLibrary = cssBootstrap then
    begin
      TMessageDlg(WebLibDlg).InitBootstrapStyle;
    end;
  end;

  WebLibDlg.ShowDialog(Msg, DlgType, Buttons, AResultProc);
end;

{ TMessageDlg }

procedure TMessageDlg.CreateInitialize;
begin
  inherited;
  FOpacity := 0.2;
  FMessage := '';
  FMsgDlgType := mtInformation;
  FDialogText := TStringList.Create;
  FDialogText.Add(SWarning);     // 0
  FDialogText.Add(SError);       // 1
  FDialogText.Add(SInformation); // 2
  FDialogText.Add(SConfirm);     // 3
  FDialogText.Add('Custom');     // 4
  FDialogText.Add(SOK);          // 5
  FDialogText.Add(SCancel);      // 6
  FDialogText.Add(SYes);         // 7
  FDialogText.Add(SNo);          // 8
  FDialogText.Add(SAbort);       // 9
  FDialogText.Add(SRetry);       // 10
  FDialogText.Add(SIgnore);      // 11
  FDialogText.Add(SAll);         // 12
  FDialogText.Add(SYestoAll);    // 13
  FDialogText.Add(SNotoAll);     // 14
  FDialogText.Add(SHelp);        // 15
  FDialogText.Add(SClose);       // 16

  FMsgDlgType := mtCustom;

  FCustomButtons := TCustomDialogButtons.Create(Self, TCustomDialogButton);
  FCustomButtons.PropName := 'CustomButtons';
end;

destructor TMessageDlg.Destroy;
begin
  FDialogText.Free;
  FCustomButtons.Free;
  inherited;
end;

function TMessageDlg.GetDialogText(Index: integer): string;
begin
  Result := 'Undefined';

  if Index < FDialogText.Count then
    Result := FDialogText.Strings[Index];
end;

procedure TMessageDlg.BindEvents;
begin
//
end;

function TMessageDlg.ShowDialog: integer;
begin
  Result := mrNone;
  Show;
end;

function TMessageDlg.ShowDialog(Msg: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons; AProc: TDialogResultProc = nil): TDialogResult;
begin
  Result := mrNone;
  FButtons := Buttons;
  FMessage := Msg;
  FMsgDlgType := DlgType;
  FDialogProc := AProc;
  DialogResult := mrNone;
  Show;
end;

function TMessageDlg.CreateButton(Caption: string; var DoFocus: boolean; AClass: string = ''): TJSHTMLElement;
begin
  Result := TJSHTMLElement(document.createElement('BUTTON'));
  Result.innerHTML := Caption;

  if Application.MainForm.CSSLibrary = cssBootstrap then
  begin
    Result['type'] := 'BUTTON';
    Result['class'] := 'btn ' + AClass;
    Result['data-dismiss'] := 'modal-dialog';
  end
  else
  begin
    Result.style.setProperty('width', '100px');
    Result.style.setProperty('margin-left', '5px');
    if ElementButtonClassName <> '' then
      Result['class'] := ElementButtonClassName + ' ' + AClass
    else
      if Application.ThemeButtonClassName <> '' then
        Result['class'] := Application.ThemeButtonClassName + ' ' + AClass;
  end;

  Result.id := Caption;
  Result.addEventListener('click', @HandleButtonClick);
  Result.addEventListener('keydown', @HandleKeyDown);

  if DoFocus then
  begin
    asm
      setTimeout(function() {Result.focus();}, 1);
    end;
    DoFocus := false;
  end;
end;

function TMessageDlg.CreateElement: TJSElement;
begin
  if (csDesigning in ComponentState) then
    Result := nil
  else
    inherited;
end;

procedure TMessageDlg.Show;
var
  title, msg, icon, content, contentdiv, bar, edlg, eh, cancel: TJSHTMLElement;
  adiv,ldiv,cdiv: TJSElement;
  MsgSymbol, MsgColor, MsgTitle, BorderColor, BarBGColor, BarHeight, Padding: string;
  btnFocus: boolean;
  i: Integer;


begin
  if (Application = nil) or (Application.MainForm = nil) then
    Exit;

  // verify material icons
  if not Application.HasCSS(lMaterial) then
    AddControlLink('material',lMaterial);

  Application.LockForm(Application.MainForm);

  FLayer := document.createElement('SPAN');
  document.body.appendChild(FLayer);
  FLayer['tabindex'] := '0';
  FLayer.addEventListener('keydown', @HandleKeyDown);

  btnFocus := true;
  MsgTitle := FTitle;
  FMessage := StringToHTML(FMessage);

  case FMsgDlgType of
    mtWarning: begin MsgSymbol := 'warning'; MsgColor := 'orange'; MsgTitle := GetDialogText(0); end;
    mtError: begin MsgSymbol := 'cancel'; MsgColor := 'red'; MsgTitle := GetDialogText(1); end;
    mtInformation: begin MsgSymbol := 'info'; MsgColor := 'blue'; MsgTitle := GetDialogText(2); end;
    mtConfirmation: begin MsgSymbol := 'help'; MsgColor := 'blue'; MsgTitle := GetDialogText(3); end;
    mtCustom: begin MsgSymbol := ''; MsgColor := ''; MsgTitle := GetDialogText(4); end;
  end;

  if Application.MainForm.CSSLibrary = cssBootstrap then
  begin
    FDlg := document.createElement('DIV');
    FDlg['class'] := 'modal-dialog'; //  modal-dialog-centered';
    FDlg['role'] := 'document';

    // app wide highest z-index
    TJSHTMLElement(FDlg).style.setProperty('z-index', '1999998');

    // insert opaque background on entire window
    eh := TJSHTMLElement(document.createElement('SPAN'));
    eh.style.setProperty('position', 'absolute');
    eh.style.setProperty('top', '0');
    eh.style.setProperty('left', '0');
    eh.style.setProperty('right', '0');
    eh.style.setProperty('bottom', '0');
    eh.style.setProperty('z-index', '1999997');
    document.body.appendChild(eh);
    eh.appendChild(FDlg);

    ldiv := FDlg;

    adiv := document.createElement('DIV');
    adiv['class'] := 'modal-content ' + ElementDialogClassName;
    ldiv.appendChild(adiv);

    cdiv := adiv;
    ldiv := adiv;

    adiv := document.createElement('DIV');
    adiv['class'] := 'modal-header';
    cdiv.appendChild(adiv);

    ldiv := adiv;

    adiv := document.createElement('H5');
    adiv['class'] := 'modal-title';
    adiv.innerHTML := MsgTitle;

    ldiv.appendChild(adiv);

    if (mbCancel in Buttons) then
    begin
      adiv := document.createElement('BUTTON');
      adiv['type'] := 'button';
      adiv['class'] := 'close';
      adiv['data-dismiss'] := 'modal-dialog';
      adiv['aria-label'] := 'Close';
      adiv['id'] := GetDialogText(6);
      adiv.addEventListener('click', @HandleButtonClick);

      ldiv.appendChild(adiv);
      ldiv := adiv;

      adiv := document.createElement('SPAN');
      adiv['aria-hidden'] := 'true';
      adiv.innerHTML := '&times;';

      ldiv.appendChild(adiv);
    end;

    adiv := document.createElement('DIV');
    adiv['class'] := 'modal-body';
    cdiv.appendChild(adiv);

    ldiv := adiv;

    if FMsgDlgType <> mtCustom then
    begin
      icon := TJSHTMLElement(document.createElement('SPAN'));
      icon.innerHTML := '<i class="material-icons" style="color:' + MsgColor + '!important;font-size:48px!important">' + MsgSymbol + '</i>';
      icon.style.setProperty('display', 'inline-block');
      icon.style.setProperty('float', 'left');
      icon.style.setProperty('padding-right', '15px');
      ldiv.appendChild(icon);
    end;

    adiv := document.createElement('H6');
    adiv.innerHTML := FMessage;

    ldiv.appendChild(adiv);

    adiv := document.createElement('DIV');
    adiv['class'] := 'modal-footer';
    cdiv.appendChild(adiv);

    ldiv := adiv;

    bar := TJSHTMLElement(ldiv);

    FDlg := eh;
  end
  else
  begin
    Padding := '5';
    BarHeight := '26';
    BarBGColor := '#f1f1f1';
    BorderColor := '#ababab';

    FDlg := document.createElement('DIV');
    document.body.appendChild(FDlg);

    edlg := TJSHTMLElement(FDlg);

    if ElementDialogClassName <> '' then
     edlg['class'] := ElementDialogClassName;

    edlg.style.setProperty('background-color', 'white');
    edlg.style.setProperty('cursor', 'default');
    edlg.style.setProperty('border', '1px solid ' + BorderColor);
    edlg.style.setProperty('position', 'fixed');
    edlg.style.setProperty('font-family', 'Arial');
    edlg.style.setProperty('font-size', '10pt');
    edlg.style.setProperty('min-width', '300px');
    edlg.style.setProperty('min-height', '125px');
    edlg.style.setProperty('max-width', '100%');
    edlg.style.setProperty('max-height', '100%');
    edlg.style.setProperty('top', '50%');
    edlg.style.setProperty('left', '50%');
    edlg.style.setProperty('transform', 'translate(-50%, -50%)');
    edlg.style.setProperty('z-index', '1999998');
    edlg.style.setProperty('box-shadow','5px 5px 5px gray');

    cancel := TJSHTMLElement(document.createElement('DIV'));
    FCancel := cancel;
    cancel.innerHTML := '<i id="Cancel" class="material-icons" style="font-size:16px!important">clear</i>';
    cancel.id := 'Cancel';
    cancel.style.setProperty('color', ColorToHTML(Application.ThemeTextColor));
    cancel.style.setProperty('position', 'fixed');
    cancel.style.setProperty('top', '0');
    cancel.style.setProperty('right', '0');
    cancel.style.setProperty('padding', Padding + 'px');
    cancel.style.setProperty('height', BarHeight + 'px');
    cancel.style.setProperty('line-height', BarHeight + 'px');
    cancel.addEventListener('click', @HandleButtonClick);
    edlg.appendChild(cancel);

    title := TJSHTMLElement(document.createElement('DIV'));
    title.innerHTML := MsgTitle;

    if FElementTitleClassName <> '' then
    begin
      title['class'] := FElementTitleClassName;
    end
    else
    begin
      title.style.setProperty('background-color', ColorToHTML(Application.ThemeColor));
      title.style.setProperty('color', ColorToHTML(Application.ThemeTextColor));
    end;

    title.style.setProperty('cursor', 'move');
    title.style.setProperty('padding-left', Padding + 'px');
    title.style.setProperty('height', BarHeight + 'px');
    title.style.setProperty('vertical-align','middle');
    title.style.setProperty('line-height', BarHeight + 'px');
    title.style.setProperty('border-bottom', '1px solid ' + BorderColor);

    title.style.setProperty('-moz-user-select','none');
    title.style.setProperty('-webkit-user-select','none');
    title.style.setProperty('-ms-user-select','none');
    title.style.setProperty('user-select','none');
    title.style.setProperty('-o-user-select','none');

    title.addEventListener('mousedown',@HandleMouseDown);

    edlg.appendChild(title);

    msg := TJSHTMLElement(document.createElement('DIV'));

    msg.style.setProperty('padding', Padding + 'px');
    msg.style.setProperty('height', 'auto');
    msg.style.setProperty('min-height', '50px');
    msg.style.setProperty('border-bottom', '1px solid ' + BorderColor);

    if FMsgDlgType <> mtCustom then
    begin
      icon := TJSHTMLElement(document.createElement('DIV'));
      icon.innerHTML := '<i class="material-icons" style="color:' + MsgColor + '!important;font-size:48px!important">' + MsgSymbol + '</i>';
      icon.style.setProperty('display', 'inline-block');
      icon.style.setProperty('float', 'left');
      icon.style.setProperty('padding-right', '15px');
      msg.appendChild(icon);
    end;

    FMessage := StringReplace(FMessage, sLineBreak, '<BR>', [rfReplaceAll]);

    contentdiv := TJSHTMLElement(document.createElement('DIV'));
    contentdiv.innerHTML := FMessage;
    contentdiv.style.setProperty('height','auto');
    contentdiv.style.setProperty('min-height','50px');
    contentdiv.style.setProperty('white-space','pre-wrap');

    content := TJSHTMLElement(document.createElement('LABEL'));
    content.innerHTML := FMessage;

    if FElementTitleClassName <> '' then
    begin
      content['class'] := FElementContentClassName;
    end;

    contentdiv.appendChild(content);

    msg.appendChild(content);

    edlg.appendChild(msg);

    bar := TJSHTMLElement(document.createElement('DIV'));
    bar.style.setProperty('background-color', BarBGColor);
    bar.style.setProperty('padding', Padding + 'px');
    bar.style.setProperty('min-height', BarHeight + 'px');
    bar.style.setProperty('height', 'auto');
    bar.style.setProperty('line-height', BarHeight + 'px');
    bar.style.setProperty('text-align', 'right');
    edlg.appendChild(bar);
  end;

  if (FButtons = []) and (CustomButtons.Count = 0) then
    bar.appendChild(CreateButton(GetDialogText(5), btnFocus))
  else
  begin
    if mbYes in FButtons then
      bar.appendChild(CreateButton(GetDialogText(7), btnFocus));

    if mbNo in FButtons then
      bar.appendChild(CreateButton(GetDialogText(8), btnFocus));

    if mbOK in FButtons then
      bar.appendChild(CreateButton(GetDialogText(5), btnFocus));

    if mbCancel in FButtons then
      bar.appendChild(CreateButton(GetDialogText(6), btnFocus));

    if mbAbort in FButtons then
      bar.appendChild(CreateButton(GetDialogText(9), btnFocus));

    if mbRetry in FButtons then
      bar.appendChild(CreateButton(GetDialogText(10), btnFocus));

    if mbIgnore in FButtons then
      bar.appendChild(CreateButton(GetDialogText(11), btnFocus));

    if mbAll in FButtons then
      bar.appendChild(CreateButton(GetDialogText(12), btnFocus));

    if mbYesToAll in FButtons then
      bar.appendChild(CreateButton(GetDialogText(13), btnFocus));

    if mbNoToAll in FButtons then
      bar.appendChild(CreateButton(GetDialogText(14), btnFocus));

    if mbHelp in FButtons then
      bar.appendChild(CreateButton(GetDialogText(15), btnFocus));

    if mbClose in FButtons then
      bar.appendChild(CreateButton(GetDialogText(16), btnFocus));
  end;

  for i := 0 to FCustomButtons.Count - 1 do
  begin
    bar.appendChild(CreateButton(FCustomButtons[i].Caption, btnFocus, FCustomButtons[i].ElementClassName));
  end;

  eh := TJSHTMLElement(FLayer);
  eh.style.setProperty('background-color', 'black');
  eh.style.setProperty('opacity', DoubleToHTML(Opacity));

  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');
  eh.style.setProperty('z-index', '19990');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.SetProperty('position','fixed');
end;

procedure TMessageDlg.SetCustomButtons(const Value: TCustomDialogButtons);
begin
  FCustomButtons.Assign(Value);
end;

procedure TMessageDlg.SetDialogResult(const Value: TDialogResult);
begin
  FDialogResult := Value;
  if (FDialogResult <> mrNone) then
    Close;
end;

procedure TMessageDlg.SetDialogText(const Value: TStringList);
begin
  FDialogText.Assign(Value);
end;

procedure TMessageDlg.Close;
begin
  Application.UnlockForm(Application.MainForm);

  document.body.removeChild(FLayer);
  document.body.removeChild(FDlg);

  FDown := false;
  FCaptured := false;

  if Assigned(OnClose) then
    OnClose(Self);

  if Assigned(FDialogProc) then
    FDialogProc(FDialogResult);
end;

function TMessageDlg.HandleButtonClick(Event: TJSMouseEvent): Boolean;
var
  id: string;
  i: integer;
begin
  DialogResult := mrNone;

  id := event.target['id'];

  if id = GetDialogText(7) then
    DialogResult := mrYes
  else if id = GetDialogText(8) then
    DialogResult := mrNo
  else if id = GetDialogText(5) then
    DialogResult := mrOK
  else if id = GetDialogText(6) then
    DialogResult := mrCancel
  else if id = GetDialogText(9) then
    DialogResult := mrAbort
  else if id = GetDialogText(10) then
    DialogResult := mrRetry
  else if id = GetDialogText(11) then
    DialogResult := mrIgnore
  else if id = GetDialogText(12) then
    DialogResult := mrAll
  else if id = GetDialogText(13) then
    DialogResult := mrNoToAll
  else if id = GetDialogText(14) then
    DialogResult := mrYesToAll
  else if id = GetDialogText(15) then
    DialogResult := mrHelp
  else if id = GetDialogText(16) then
    DialogResult := mrClose;

  if (DialogResult = mrNone) and (CustomButtons.Count > 0) then
  begin
    for i := 0 to CustomButtons.Count - 1 do
    begin
      if id = CustomButtons[i].Caption then
      begin
        DialogResult := 100 + i;
        break;
      end;

    end;
  end;

  if Assigned(OnButtonClick) then
    OnButtonClick(Self);

  Result := true;
end;

function TMessageDlg.HandleDocMouseMove(Event: TJSMouseEvent): Boolean;
var
  deltax,deltay: double;
  el: TJSHTMLElement;
begin
  if FDown then
  begin
    deltax := Event.screenX - FMdx;
    deltay := Event.screenY - FMdy;

    el := TJSHTMLElement(FDlg);

    el.style.setProperty('transform','');
    el.style.setProperty('position','absolute');
    el.style.setProperty('left', inttostr(round(FDlgX + deltax))+'px');
    el.style.setProperty('top', inttostr(round(FDlgY + deltay))+'px');

    el := TJSHTMLElement(FCancel);
    el.style.setProperty('position', 'absolute');
    el.style.setProperty('top', '0');
    el.style.setProperty('right', '0');
  end;

  Result := true;
end;

function TMessageDlg.HandleDocMouseUp(Event: TJSMouseEvent): Boolean;
begin
  FDown := false;
  document.body.removeEventListener('mousemove', @HandleDocMouseMove);
  document.body.removeEventListener('mouseup', @HandleDocMouseUp);

  Result := true;
end;

function TMessageDlg.HandleMouseDown(Event: TJSMouseEvent): Boolean;
var
  r: TJSDOMRect;
begin
  FMdx := Round(Event.screenX);
  FMdy := Round(Event.screenY);

  r := FDlg.getBoundingClientRect();

  FDlgX := Round(int(r.left));
  FDlgY := Round(int(r.top));

  FDown := true;

  if not FCaptured then
  begin
    FCaptured := true;
    document.body.addEventListener('mousemove', @HandleDocMouseMove);
    document.body.addEventListener('mouseup', @HandleDocMouseUp);
  end;

  Result := true;
end;

procedure TMessageDlg.InitBootstrapStyle;
begin
  FElementButtonClassName := 'btn';
  FElementContentClassName := 'text-body';
  FElementTitleClassName := 'text-body';
  FElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
end;

function TMessageDlg.HandleKeyDown(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
begin
  k := GetKeyCode(Event.Key);
  if not Assigned(k) then
    Exit;
  if k = VK_ESCAPE then
    DialogResult := mrCancel;
  Result := true;
end;

{ TWaitMessage }

function TWaitMessage.CreateElement: TJSElement;
begin
  if (csDesigning in ComponentState) then
    Result := nil
  else
    inherited;
end;

procedure TWaitMessage.CreateInitialize;
begin
  inherited;
  FOpacity := 0.2;
  FPicture := TURLPicture.Create;
  FIsWaiting := false;
  FPicture.Data := lWaitCursor;
end;

destructor TWaitMessage.Destroy;
begin
  FPicture.Free;
  inherited;
end;

procedure TWaitMessage.Hide;
begin
  if FIsWaiting then
    document.body.removeChild(FLayer);
  FIsWaiting := false;
end;

procedure TWaitMessage.SetPicture(const Value: TURLPicture);
begin
  FPicture.Assign(Value);
end;

procedure TWaitMessage.Show;
var
  eh,img,dv: TJSHTMLElement;
  el: TJSElement;
begin
  FLayer := document.createElement('SPAN');

  eh := TJSHTMLElement(FLayer);
  eh.style.setProperty('background-color', 'black');
  eh.style.setProperty('opacity', DoubleToHTML(Opacity));
  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');
  eh.style.setProperty('z-index', '19999');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.SetProperty('position','fixed');

  document.body.appendChild(FLayer);
  FLayer['tabindex'] := '0';

  el := document.createElement('DIV');
  dv := TJSHTMLElement(el);
  FLayer.appendChild(el);
  dv.style.SetProperty('position','relative');
  dv.style.SetProperty('width','100%');
  dv.style.SetProperty('height','100%');

  el := document.createElement('IMG');
  img := TJSHTMLElement(el);
  dv.appendChild(img);
  img['src'] := FPicture.FileName;
  img.style.setProperty('position','absolute');
  img.style.setProperty('top', '0');
  img.style.setProperty('left', '0');
  img.style.setProperty('right', '0');
  img.style.setProperty('bottom', '0');
  img.style.setProperty('margin', 'auto');

  FIsWaiting := true;

  if Assigned(OnShow) then
  begin
    window.setTimeout(@WaitDisplayed, 100);
  end;
end;

procedure TWaitMessage.WaitDisplayed;
begin
  if Assigned(OnShow) then
    OnShow(Self);
end;

{ TCustomDialogButtons }

function TCustomDialogButtons.Add: TCustomDialogButton;
begin
  Result := TCustomDialogButton(inherited Add);
end;

function TCustomDialogButtons.GetItem(AIndex: integer): TCustomDialogButton;
begin
  Result := TCustomDialogButton(inherited Items[AIndex]);
end;

function TCustomDialogButtons.Insert(AIndex: integer): TCustomDialogButton;
begin
  Result := TCustomDialogButton(inherited Insert(AIndex));
end;

procedure TCustomDialogButtons.SetItem(AIndex: integer;
  const Value: TCustomDialogButton);
begin
  inherited Items[AIndex] := Value;
end;

{ TOpenDialog }

constructor TOpenDialog.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  FFiles := TFiles.Create(Self);
end;

destructor TOpenDialog.Destroy;
begin
  FFiles.Free;
  inherited;
end;

procedure TOpenDialog.Execute(AProc: TOpenDialogProc);
begin
  FOpenDialogProc := AProc;
  Execute;
end;

{$HINTS OFF}
procedure TOpenDialog.Execute;
var
  ic: pointer;
  fp: TJSHTMLElement;
begin
  ic := @HandleInputChange;
  fp := TJSHTMLElement(document.createElement('input'));
  fp.setAttribute('type','file');
  if FMultiFile then
    fp.setAttribute('multiple','');

  if FAccept <> '' then
    fp.SetAttribute('accept',FAccept);

  fp.addEventListener('change', ic);

  asm
    fp.click();
  end;
end;
{$HINTS ON}

procedure TOpenDialog.HandleInputChange(Event: TJSEvent);
var
  i,l,sz: integer;
  s,m: string;
  f: TFile;
  d: TJSDate;
  jsfile: TJSHTMLFile;

begin
  // clear existing collection
  while FFiles.Count > 0 do
    FFiles.Delete(0);

  asm
    var curFiles = Event.target.files;
    l = curFiles.length;
  end;

  for i := 0 to l - 1 do
  begin
    f := FFiles.Add;

    asm
      jsfile = curFiles[i];
      s = curFiles[i].name;
      m = curFiles[i].type;
      sz = curFiles[i].size;
      d = new Date(curFiles[i].lastModified);
    end;

    // get the first file
    if (i = 0) then
      FFileName := s;

    f.FileObject := jsfile;
    f.Name := s;
    f.MimeType := m;
    f.Size := sz;
    f.Modified := EncodeDate(d.Year + 1900, d.Month + 1, d.Date)+EncodeTime(d.Hours, d.Minutes, d.Seconds, d.Milliseconds);
  end;

  if Assigned(FOpenDialogProc) then
    FOpenDialogProc(FFileName);

  FOpenDialogProc := nil;

  if Assigned(OnChange) then
    OnChange(Self);
end;

initialization
  WebLibDlg := nil;

end.